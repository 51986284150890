import React from "react";
import Icon from "react-icons-kit";
import "./loader.css"

const DFcars_Preloader = ({

}) => {
    return (
        <React.Fragment>
            <section className="dfcars_preloader_body">
                <div id="dfcars_preloader">
                    <div id="dfcars_loader"></div>
                    <span id="dfcars_loader_text">LOADING ...</span>
                </div>
            </section>
        </React.Fragment>
    );
};

export default DFcars_Preloader;
