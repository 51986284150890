import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import DfCarsEnquiry from "../Evaluator/DfCarsEnquiry";
import SingleSelect from "../../../../components/Form/SingleSelect";
import moment from "moment";

class DfcarsdeliverySeller extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      customerId: localStorage.getItem("userId"),
      alertVisible: false,
      textalert: "",
      serialnumber: "",
      date: "",
      textfield: "",
      make: "",
      model: "",
      kms: "",
      chassis: "",
      engine: "",
      Registration: "",
      price: "",
      balance_price: "",
      rcCerificate: false,
      noc: false,
      to_forms: false,
      tax_token: false,
      invoice: false,
      manualBook: false,
      insurancePolicy: false,
      duplicatekey: false,
      policyDate: ""
    }
  }
  handlechangeData = async (e) => {
    if (e.target.type == "checkbox") {
      if (this.state[e.target.name] == false) {
        this.setState({ [e.target.name]: true });
      } else {
        this.setState({ [e.target.name]: false });
      }

    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  render() {
    return (<React.Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h1>Delivery Note For Seller</h1>
              </div>
              <div className="card-body">
                <div className="row form-group">
                  <div className="col-sm-1">
                    <label htmlFor="exampleInputEmail1">SI.No</label>
                  </div>
                  <div className="col-sm-2">
                    <input
                      type="text"
                      className="form-control"
                      id="ColourName"
                      name="serialnumber"
                      placeholder="Enter SI.No"
                      style={{
                        border: 0,
                        outline: 0,
                        backGround: "transparent",
                        borderBottom: "1px solid black"
                      }}
                      value={this.state.serialnumber}
                      onChange={this.handlechangeData}
                    />
                  </div>
                  <div className="col-sm-6" />
                  <div className="col-sm-1">
                    <label htmlFor="exampleInputEmail1">Date</label>
                  </div>
                  <div className="col-sm-2">
                    <input
                      type="text"
                      className="form-control"
                      id="ColourName"
                      name="date"
                      placeholder="Enter the Date"
                      value={this.state.date}
                      style={{
                        border: 0,
                        outline: 0,
                        backGround: "transparent",
                        borderBottom: "1px solid black"
                      }}
                      onChange={this.handlechangeData}
                    />
                  </div>
                </div>

                <div className="row form-group">
                  <div className="col-sm-4">
                    <p style={{ color: "black" }}>I/We hereby confirm having taken delivery from M/s./Mrs./Mr</p>
                  </div>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      id="ColourName"
                      name="textfield"
                      style={{
                        border: 0,
                        outline: 0,
                        backGround: "transparent",
                        borderBottom: "1px solid black"
                      }}
                      value={this.state.textfield}
                      onChange={this.handlechangeData}
                    />
                  </div>
                  <div className="col-sm-2">Used vehicle Make</div>
                  <div className="col-sm-2">
                    <input
                      type="text"
                      className="form-control"
                      id="ColourName"
                      name="make"
                      style={{
                        border: 0,
                        outline: 0,
                        backGround: "transparent",
                        borderBottom: "1px solid black"
                      }}
                      value={this.state.make}
                      onChange={this.handlechangeData}
                    />
                  </div>
                  <div className="col-sm-1">Model</div>
                  <div className="col-sm-2">
                    <input
                      type="text"
                      className="form-control"
                      id="ColourName"
                      name="model"
                      style={{
                        border: 0,
                        outline: 0,
                        backGround: "transparent",
                        borderBottom: "1px solid black"
                      }}
                      value={this.state.model}
                      onChange={this.handlechangeData}
                    />
                  </div>
                  <div className="col-sm-1">Kms</div>
                  <div className="col-sm-2">
                    <input
                      type="text"
                      className="form-control"
                      id="ColourName"
                      name="kms"
                      style={{
                        border: 0,
                        outline: 0,
                        backGround: "transparent",
                        borderBottom: "1px solid black"
                      }}
                      value={this.state.kms}
                      onChange={this.handlechangeData}
                    />
                  </div>
                  <div className="col-sm-2">Chassis No</div>
                  <div className="col-sm-2">
                    <input
                      type="text"
                      className="form-control"
                      id="ColourName"
                      style={{
                        border: 0,
                        outline: 0,
                        backGround: "transparent",
                        borderBottom: "1px solid black"
                      }}
                      name="chassis"
                      value={this.state.chassis}
                      onChange={this.handlechangeData}
                    />
                  </div>
                  <div className="col-sm-1">Engine No</div>
                  <div className="col-sm-2">
                    <input
                      type="text"
                      className="form-control"
                      id="ColourName"
                      style={{
                        border: 0,
                        outline: 0,
                        backGround: "transparent",
                        borderBottom: "1px solid black"
                      }}
                      name="engine"
                      value={this.state.engine}
                      onChange={this.handlechangeData}
                    />
                  </div>
                  <div className="col-sm-2">Registration No</div>
                  <div className="col-sm-2">
                    <input
                      type="text"
                      className="form-control"
                      id="ColourName"
                      name="Registration"
                      value={this.state.Registration}
                      style={{
                        border: 0,
                        outline: 0,
                        backGround: "transparent",
                        borderBottom: "1px solid black"
                      }}
                      onChange={this.handlechangeData}
                    />
                  </div>
                </div>

                <div className="row form-group">
                  <div className="col-sm-6">
                    <p style={{ color: "black" }}>
                      with available documents as seen tried and approved after paying full payments of Rs.
                    </p>
                  </div>
                  <div className="col-sm-2">
                    <input
                      type="text"
                      className="form-control"
                      id="ColourName"
                      name="price"
                      value={this.state.price}
                      style={{
                        border: 0,
                        outline: 0,
                        backGround: "transparent",
                        borderBottom: "1px solid black"
                      }}
                      onChange={this.handlechangeData}
                    />
                  </div>
                  <div className="col-sm-1">Balance Rs.</div>
                  <div className="col-sm-2">
                    <input
                      type="text"
                      className="form-control"
                      id="ColourName"
                      name="balance_price"
                      value={this.state.balance_price}
                      style={{
                        border: 0,
                        outline: 0,
                        backGround: "transparent",
                        borderBottom: "1px solid black"
                      }}
                      onChange={this.handlechangeData}
                    />
                  </div>
                </div>
                <h4>Document available</h4><br />
                <div className="row form-group">
                  <div className="col-sm-2">Rc Certificate</div>
                  <div className="col-sm-1">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="rcCerificate"
                      name="rcCerificate"
                      onClick={this.handlechangeData}
                      checked={this.state.rcCerificate}
                    />
                  </div>
                  <div className="col-sm-2">NOC (if applicable)</div>
                  <div className="col-sm-1">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="rcCerificate"
                      name="noc"
                      onClick={this.handlechangeData}
                      checked={this.state.noc}
                    />
                  </div>
                  <div className="col-sm-2">T.O Forms</div>
                  <div className="col-sm-1">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="to_forms"
                      name="to_forms"
                      onClick={this.handlechangeData}
                      checked={this.state.to_forms}
                    />
                  </div>
                  <div className="col-sm-2">Tax Token</div>
                  <div className="col-sm-1">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="to_forms"
                      name="tax_token"
                      onClick={this.handlechangeData}
                      checked={this.state.tax_token}
                    />
                  </div>
                  <div className="col-sm-2">Invoice Copy</div>
                  <div className="col-sm-1">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="to_forms"
                      name="invoice"
                      onClick={this.handlechangeData}
                      checked={this.state.invoice}
                    />
                  </div>
                  <div className="col-sm-2">Manual/Service Book</div>
                  <div className="col-sm-1">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="to_forms"
                      name="manualBook"
                      onClick={this.handlechangeData}
                      checked={this.state.manualBook}
                    />
                  </div>
                  <div className="col-sm-2">Insurance Valid Till</div>
                  <div className="col-sm-1">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="to_forms"
                      name="insurancePolicy"
                      onClick={this.handlechangeData}
                      checked={this.state.insurancePolicy}
                    />
                  </div>
                  <div className="col-sm-2">Duplicate Key</div>
                  <div className="col-sm-1">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="to_forms"
                      name="duplicatekey"
                      onClick={this.handlechangeData}
                      checked={this.state.duplicatekey}
                    />
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-sm-3">Insurance Policy Date if available</div>
                  <div className="col-sm-2">
                    <input
                      type="text"
                      className="form-control"
                      id="ColourName"
                      name="policyDate"
                      onClick={this.handlechangeData}
                      checked={this.state.policyDate}
                    />
                  </div>
                </div>

                <div className="row form-group">
                  <div className="col-sm-12">
                    <p style={{ color: "black" }}>
                      We find the vechicle is as per the condition agreed upon and its documents are complete as per our knowledge and information shared by the seller.<br />
                      We shall be responsible for all types of traffic offences/Police cases/Legal Litigations/Accident/RTO taxes/Insurance premium occuring or due from this hour & day onwards.<br />
                      However, any of the above mentioned offences or acts , prior to this hour & day shall be an absolute and unconditional responsibility of the above named previous registered owned of the subject vehicle.
                    </p>
                  </div>
                </div>

                <div className="row form-group">
                  <div className="col-sm-5" />
                  <div className="col-sm-6">
                    <button type="button"
                      className="btn btn-success"
                    >Submit</button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>)
  }
}
export default DfcarsdeliverySeller;