import React, { useEffect } from "react";
import Tesseract from "tesseract.js";
import Cameracrop from "./cameraCrop";
// import Img from '../images/CMS/certificatePDF.jpg'
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

// import Geolocationfun from "./Geolocation";
const axios = require('axios');

registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginImageResize
);
export default class Imagereader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imgsrc: null, result: null, imageread: [], camstate: false,
            progress: "0%", scantype: 1,
            src: null,
            crop: {
                // aspect: 16 / 9, // you can adjust the aspect ratio according to your requirement
                unit: 'px', // default, can be 'px' or '%'
                width: 200,
                height: 100,
            },
            croppedImageUrl: null,
        }
    }
    async componentDidMount() {

    }
    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    getdata = async (Img) => {
        this.setState({ Imgsrc: Img, progress: "15%", scantype: 1 })

        let OnlineOCR = true
        console.log(OnlineOCR, "OnlineOCR");

        await this.toBase64(Img)
            .then(async (dataUrl) => {
                if (dataUrl) {
                    this.setState({ progress: "25%", scantype: 1 })

                    let data = new FormData();
                    data.append('language', 'eng');
                    data.append('isOverlayRequired', 'true');
                    data.append('Base64Image', dataUrl)

                    let config = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        url: 'https://api.ocr.space/Parse/Image',
                        headers: {
                            'apikey': 'K84120831588957',
                            'Content-Type': 'multipart/form-data',
                            'Connection': 'keep-alive'
                        },
                        data: data
                    };
                    this.setState({ progress: "35%", scantype: 1 })

                    await axios(config)
                        .then(response => {
                            this.setState({ progress: "50%", scantype: 1 })

                            if (response && response.data && response.data.ParsedResults && response.data.ParsedResults[0] && response.data.ParsedResults[0].ParsedText) {
                                this.setState({ progress: "70%", scantype: 1 })

                                let text = response.data.ParsedResults[0].ParsedText
                                text = text.replace(/[^\w\s]/gi, '')
                                if (text) {
                                    this.setState({ progress: "90%", scantype: 1 })

                                    console.log(text, "OnlineOCR text");
                                    setTimeout(() => {
                                        this.props.Imagereader(text)
                                        this.setState({ result: text, progress: "100%", scantype: 1 })

                                    }, 2000);
                                }
                                else {
                                    OnlineOCR = false
                                }
                            }
                            else {
                                OnlineOCR = false
                            }
                        })
                        .catch(function (error) {
                            OnlineOCR = false
                            console.log(error, "error");
                        });
                } else {
                    OnlineOCR = false
                }

            }).catch(function (error) {
                OnlineOCR = false
                console.log(error, "error");
            });

        console.log(OnlineOCR, "OnlineOCR");
        if (OnlineOCR == false) {
            this.setState({ progress: "75%", scantype: 2 })

            Tesseract.recognize(
                Img, 'eng', { logger: m => console.log("m") }).then(({ data: { text } }) => {
                    if (text) {
                        this.setState({ progress: "90%", scantype: 2 })
                        text = text.replace(/[^\w\s]/gi, '')
                        setTimeout(() => {
                            this.props.Imagereader(text)
                            this.setState({ result: text, progress: "100%", scantype: 2 })
                        }, 2000);
                    }
                })
        }
    }
    send = () => {
        if (this.state.result) {
            this.props.Imagereader(this.state.result)
        }
    }
    recrop = () => {
        this.setState({ result: null, Imgsrc: null, imageread: [] })
    }
    retake = () => {
        this.setState({ result: null, Imgsrc: null, imageread: [], src: "", croppedImageUrl: "" })
    }
    filechange = (e) => {
        let img = e.map(fileItem => fileItem.file)

        // console.log(e);
        this.setState({ imageread: img, preViewImage: img[0], imgView: true })
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({ preViewImage: reader.result })
            );
            reader.readAsDataURL(e.target.files[0]);
        }

        // this.getdata(e[0].source)

    }
    setCrop = (e) => {
        this.setState({ crop: e })
    }

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({ src: reader.result })
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    onImageLoaded = image => {
        if (image !== null) {
            this.imageRef = image;
        }
    };

    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };

    onCropComplete = crop => {

        if (this.imageRef && crop.width && crop.height) {
            this.getCroppedImg(this.imageRef, crop);
        }
    };

    getCroppedImg(image, crop) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        const croppedImageUrl = canvas.toDataURL('image/jpeg');
        // console.log(croppedImageUrl,"croppedImageUrl fun");
        this.setState({ croppedImageUrl });
    }

    render() {
        let { Imgsrc, result, camstate, imageread, preViewImage, imgView, crop, croppedImageUrl, src } = this.state

        return (
            <div >
                {/* <Geolocationfun location={false} address={(e) => { console.log(e); }} coords={(e) => { console.log(e); }} /> */}
                <div className="col-sm-12 text-center">
                    <div className="col-sm-1 text-center" />
                    <div className="col-sm-10 text-center" >
                        <label>Number Capture</label>
                    </div>
                    <div className="col-sm-1 text-center" />
                </div>
                <div className="col-sm-12 text-center">
                    <div className="col-sm-1 text-center" />
                    <div className="col-sm-10 text-center">
                        {Imgsrc == null ?
                            <>
                                <center>
                                    <button className={`btn ${camstate && camstate ? 'btn-primary' : ''}`} style={{ margin: 10 }} onClick={() => { this.setState({ camstate: true }) }}>Open Camera </button>
                                    <button className={`btn ${camstate && camstate ? "" : 'btn-primary'}`} style={{ margin: 10 }} onClick={() => { this.setState({ camstate: false }) }}>File Upload </button>
                                </center>
                                {camstate && camstate ?
                                    <>

                                        <Cameracrop id="numberread" width={400} height={550} image={this.getdata} />
                                    </>
                                    :
                                    <div>
                                        <div>
                                            {/* <input type="file" onChange={this.onSelectFile} /> */}
                                            <input id="file-upload" type="file" onChange={this.onSelectFile}></input>
                                            <br></br>
                                            {src && (
                                                <>
                                                    <br />
                                                    <p style={{ color: "red", marginLeft: "10px", fontSize: "14px" }}>
                                                        Note : Please crop this image number plate only
                                                    </p>
                                                    <br />
                                                    <ReactCrop
                                                        src={src}
                                                        crop={crop}
                                                        onImageLoaded={this.onImageLoaded}
                                                        onComplete={this.onCropComplete}
                                                        onChange={this.onCropChange}
                                                    >
                                                        <img onLoad={this.onImageLoaded} />

                                                    </ReactCrop>
                                                </>
                                            )}
                                            <br></br>

                                            {croppedImageUrl && (
                                                <div>
                                                    <label style={{ fontSize: "20px", fontWeight: "500" }}>Cropped Image Preview</label>
                                                    <br></br>
                                                    <br></br>
                                                    <img src={croppedImageUrl} alt="Cropped" />
                                                    <br></br>
                                                    <br></br>

                                                    <button className="btn btn-primary" onClick={() => this.getdata(croppedImageUrl)}>
                                                        Save crop Image
                                                    </button>
                                                </div>
                                            )}
                                        </div>

                                        {/* <FilePond
                                            files={this.state.imageread && this.state.imageread}
                                            allowReorder={true}
                                            allowImageCrop={true}
                                            imageCropAspectRatio="1:1"
                                            // allowMultiple={true}
                                            allowImageResize={true}
                                            imagePreviewMinHeight="50"
                                            imagePreviewMaxHeight="50"
                                            imageCrop={true}
                                            //    maxFileSize={'3072KB'}
                                            // imageResizeMode="cover"
                                            acceptedFileTypes={["image/*"]}
                                            onupdatefiles={this.filechange}
                                            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                        /> */}
                                        {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label> &nbsp;&nbsp;
                              <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                                    </div>}
                            </> :
                            result !== null ?
                                <>
                                    <p>
                                        {result}
                                    </p>
                                    <br></br>
                                    <div className="cropBtn-group">
                                        <button className="btn btn-primary" onClick={this.send} >Ok</button>
                                        <button className="btn btn-primary" onClick={this.recrop} >Recrop</button>
                                        <button className="btn btn-primary" onClick={this.retake} >Retake</button>
                                    </div>

                                </>
                                :

                                <>
                                    <div class="progress" style={{ height: "20px" }}>
                                        <div class="progress-bar progress-bar-striped progress-bar-animated bg-danger" role="progressbar"
                                            style={{ width: this.state.progress ? this.state.progress : "25%", fontWeight: "600" }}
                                            aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"
                                        >{this.state.progress ? this.state.progress : "25%"}</div>
                                    </div>
                                    <h6 style={{ color: "red", fontSize: "15px", textTransform: "capitalize" }}>Please Wait.. {this.state.scantype == 1 ? "OCR" : "TES"} is Scanning Number Plate</h6>

                                </>
                        }
                    </div>
                    <div className="col-sm-1 text-center" />
                </div>
            </div >

        )
    }
}


