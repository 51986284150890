import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../components/Form/FormMiddleware";
import Datatable from "../../../components/Datatable/Datatable";
import SingleSelect from "../../../components/Form/SingleSelect";
import LoginModal from "../../../components/Modal/Modal";
import ChangeFormOrder from "./ChangeFormOrder";
import moment from "moment";

class DfCarsFormOrder extends FormMiddleWare {
    constructor(props) {
      super(props);
      this.state = {
        data: [],
        customerId: "",
        alertVisible: false,
        textalert: "",
        FormData:{},
        FormQuestions:[],
        column: [
            {
              Header: "Question",
              accessor: "question"
            },
            {
              Header: "Type",
              accessor: "type"
            },
            {
              Header: "Orderby",
              accessor: "Orderby"
            },
            {
              Header: "ChangeOrder",
              accessor: "Orderby",
              Cell: d => this.ChangeOrder(d)
            },
        ],
        Editdata:{},
        Index:null
      }
    }

    ChangeOrder=(d)=>{
        return (
            <button
              type="button"
              className="btn btn-primary"
              data-toggle="modal"
              data-target={"#status"}
              onClick={() => this.ChangeFormOrder(d)}
            >
              Change
            </button>
          );
    }

    ChangeFormOrder=async(d)=>{
       this.setState({Editdata:d.original,Index : d.index})
    } 

    async componentWillMount() {
        try {
           
            let result = await cmsContent.getFreedom(
                `name as label,id as value`,
                `tbl_form`,
                `serviceid=10`,
                "id",
                "id"
              );
              if(result){
                  let Fulldata=[];
                result.data.map((ival,i)=>{
                    //console.log(ival);
                    if(ival.label != null && ival.label != "" ){
                        Fulldata.push(ival)
                    }
                })
                // console.log(Fulldata);
                  this.setState({data:Fulldata});
                 // this.AlpabeticCodes(1234);
              }
        }catch(error){
            console.log(error);
        }
    }

    handleForm=async(e)=>{
        this.setState({FormData:e});
        this.FormQuestions(e.value);
    }
 
    FormQuestions=async(id)=>{
        try{

            let result = await cmsContent.getFreedom(
                `*`,
                `tbl_formquestion`,
                `parentid=${id}`,
                "id",
                "id"
              );
              if(result){
                  console.log(result);
                  this.setState({FormQuestions:result.data})
              }

        }catch(error){
            console.log(error);
        }
    }

    ChangestateData=async(s,v)=>{
        this.setState({[s]:v})
    }

  

   

    render(){
        return(
            <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Form Listing Order</h1>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-7">
                        <Alert
                          className="badge-content"
                          color={this.state.color}
                          isOpen={this.state.alertVisible}
                          toggle={this.onDismiss}
                        >
                          {this.state.textalert}
                        </Alert>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    
                    <div className="row form-group">
                        <div className="col-sm-1" />
                        <div className="col-sm-3">
                          <label htmlFor="exampleInputEmail1">Form</label>
                        </div>
                        <div className="col-sm-5">
                          <SingleSelect
                            name="make"
                            options={this.state.data}
                            handleChange={d => this.handleForm(d)}
                            selectedService={this.state.FormData}
                          />
                        </div>
                        <div className="col-sm-3" />
                      </div>

                      <div className="row form-group">
                    <div className="col-sm-4">
                      <LoginModal
                        buttonTitle="Add Enquiry"
                        title="Update Form Order"
                        id="status"
                        extraClass="btn btn-primary"
                        extraStyle={{width:'1000px'}}
                        onClick={this.reset}
                        buttonStyle={{ backgroundColor: "white", border: "white" }}
                        bodyText={
                          <ChangeFormOrder
                          FormQuestions={this.state.FormQuestions}
                          Editdata={this.state.Editdata}
                          Index={this.state.Index}
                          these={this.ChangestateData}
                          />}
                      />
                    </div>
                  </div>

                      {this.state.FormQuestions.length ? (
                      <Datatable
                        data={this.state.FormQuestions}
                        columnHeading={this.state.column}
                      />
                    ) : null}

                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </main>
                    </React.Fragment>
        )

    }
}

export default DfCarsFormOrder;

