import React, { Component } from 'react'
import CmsContent from '../../../../MiddleWare/CmsContent';
import Datatable from "../../../../components/Datatable/Datatable";
import { FormMiddleWare, MultiSelect } from "../../../../components/Form";
import cmsContent from "../../../../MiddleWare/CmsContent";

class DfcarzHistory extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            page: 1,
            themeId: null,
            changenumber: null,
            fullData: [],
            MakeOptions: [],
            coloropt: [],
            variantopt: [],
            MakeOptions: [],
            coloropt: [],
            variantopt: [],
            modelopt: [],
            index: null,
            alertVisible: false,
            customerId: localStorage.getItem("userId"),
            usertypelog: JSON.parse(localStorage.getItem('usertypelog')),
            column1: [
                {
                    Header: "Customer Name",
                    accessor: "customerName"
                },
                {
                    Header: "Form",
                    accessor: "tablename",
                    Cell: (d) => this.getValueFromArray(d.original.tablename, this.state.FormOpt, 'tbl')
                },
                {
                    Header: "Created At",
                    accessor: "createdAt",
                },
                {
                    Header: "View Details",
                    Cell: d => d.original.data.length ? this.dataTableButton('primary btn-warning', 'View Changes', () => this.ViewOne(d.original, 3, 'FormChangeData')) : '-',

                }

            ],
            column: [
                {
                    Header: "Customer Name",
                    accessor: "customerName"
                },
                {
                    Header: "User Name",
                    accessor: "userName"
                },
                {
                    Header: "User Id",
                    accessor: "userid"
                },
                {
                    Header: "Register Number",
                    accessor: "reg_no",
                    Cell: d => d.original.reg_no ? d.original.reg_no : '-'
                },
                {
                    Header: "Mobile",
                    accessor: "mobile",
                },
                {
                    Header: "Make",
                    accessor: "make",
                    Cell: d => this.getValueFromArray(d.original.make, this.state.MakeOptions)
                },
                {
                    Header: "Model",
                    accessor: "model",
                    Cell: d => this.getValueFromArray(d.original.model, this.state.modopt)
                },
                {
                    Header: "Location",
                    accessor: "location",
                    Cell: d => this.getValueFromArray(d.original.location, this.state.LocationOptions)
                },
                {
                    Header: "Type",
                    accessor: "EnqType",
                },
                {
                    Header: "Current Stage",
                    // accessor: "",
                    Cell: d => this.getValueFromArray(d.original?.data[0]?.tablename, this.state.FormOpt, d.original.tablename == 'tbl_BProof' ? 'tbl1' : "tbl")
                },
                {
                    Header: "View Details",
                    accessor: "process_type",
                    Cell: d => this.dataTableButton('primary btn-sm', 'View Details', () => this.ViewOne(d.original, 2, 'formGroupData')),
                }

            ],
            column2: [
                {
                    Header: "Customer Name",
                    accessor: "customerName"
                },
                {
                    Header: "Fieldname",
                    accessor: "fieldname",
                    Cell: d => d.original.fieldname ? d.original.fieldname : '-'
                },
                {
                    Header: "Before",
                    accessor: "beforeupdate",
                    Cell: d => d.original.beforeupdate ? d.original.beforeupdate : d.original.process_type == 'update' ? 'No change' : '-'
                },
                {
                    Header: "After",
                    accessor: "afterupdate",
                    Cell: d => d.original.afterupdate ? d.original.afterupdate : d.original.process_type == 'update' ? 'No change' : '-'
                },
                {
                    Header: "Updated By",
                    accessor: "userName",
                },
                {
                    Header: "Process Type",
                    accessor: "process_type",
                },

            ],
            filteredLoc: "",
            filteredMake: "",
            LocationOptions: [],
            FilterData: [],

        };
    }
    async componentDidMount() {
        this.MainFun()
        let allOpt = await this.decryption('getcar')
        if (allOpt) {
            this.setState({
                MakeOptions: allOpt.makeopt,
                coloropt: allOpt.coloropt,
                varopt: allOpt.variantopt,
                modopt: allOpt.modelopt,
                LocationOptions: allOpt.locopt,
                yearopn: allOpt.yearopt
            })
        }
    }
    MainFun = async () => {
        try {
            this.setState({ loading: true })
            let body = {}
            body.orderBy = this.state.usertypelog.orderBy
            let result = await cmsContent.BUYDFCARZ(this.state.customerId, 'Viewhistory_prospective', body)
            if (result) {
                // console.log(result, "result");
                let FormOpt = []
                if (result.process && result.process.length) {
                    FormOpt = [...result.process]
                }

                await this.setState({ fullData: result.history, FilterData: result.history, FormOpt })
            }
            this.setState({ loading: false })
        } catch (err) {
            console.log(err);
            this.setState({ loading: false })
        }
    }

    ViewOne = async (val, page, field) => {
        if (val && val.data && val.data.length) {
            this.setState({
                [field]: val.data,
                page: page,
                ProcessTitle: page == 3 ? this.getValueFromArray(val.tablename, this.state.FormOpt, 'tbl') : null
            })
        } else alert('No Data Found')
    }
    OnBack = val => {
        this.setState({ page: val - 1 })
    }
    makeFilter = async (d) => {
        let { filteredMake, MakeOptions, filteredLoc, FilterData } = this.state;

        //   console.log(d, "d")

        await this.setState({ filteredMake: d })

        if (d && d.length == 0 && filteredLoc.length == 0) {
            await this.setState({ fullData: this.state.FilterData })
        }
        else if (d && d.length == 0 && filteredLoc.length != 0) {
            var filtered_data = await FilterData.filter(x => filteredLoc.some(y => x.location == y.value))
            await this.setState({ fullData: filtered_data })
        }
        else if (d && d.length != 0 && filteredLoc && filteredLoc.length != 0) {
            var filtered_make = await FilterData.filter(fd => d.some(d => fd.make == d.value))
            var filtered_loc = await FilterData.filter(fd => filteredLoc.some(fl => fd.location == fl.value))
            var filtered_data = await filtered_make.filter(fm => filtered_loc.some(fl => fm.make == fl.make && fm.location == fl.location))
            await this.setState({ fullData: filtered_data })
        }
        else if (d && d.length != 0 && filteredLoc.length == 0) {
            var filtered_data = await FilterData.filter(x => d.some(y => x.make == y.value))
            await this.setState({ fullData: filtered_data })
        }
        else {
            //nothing
        }
    }
    locationFilter = async (d) => {
        let { locationOpt, filteredLoc, FilterData, filteredMake, EnquiryDatas } = this.state
        await this.setState({ filteredLoc: d })

        if (d && d.length == 0 && filteredMake.length == 0) {
            await this.setState({ fullData: this.state.FilterData })
        }
        else if (d && d.length == 0 && filteredMake.length != 0) {
            var filtered_data = await FilterData.filter(x => filteredMake.some(y => x.make == y.value))
            await this.setState({ fullData: filtered_data })
        }
        else if (d && d.length != 0 && filteredMake.length != 0) {
            var filtered_loc = await FilterData.filter(fd => d.some(d => fd.location == d.value))
            var filtered_make = await FilterData.filter(fd => filteredMake.some(d => fd.make == d.value))
            var filtered_data = await filtered_loc.filter(fl => filtered_make.some(fm => fl.location == fm.location && fl.make == fm.make))
            await this.setState({ fullData: filtered_data })
        }
        else if (d && d.length != 0 && filteredMake.length == 0) {
            var filtered_data = await FilterData.filter(x => d.some(y => x.location == y.value))
            await this.setState({ fullData: filtered_data })
        }
        else {
            //nothing
        }

    }
    clearFilter = async () => {
        await this.setState({ filteredMake: "", filteredLoc: "", fullData: this.state.FilterData })
    }
    render() {
        let { fullData, formGroupData, FormChangeData, ProcessTitle, page, } = this.state;
        let { LocationOptions, MakeOptions} = this.state

        var tableData = null
        var column = null
        if (page === 1) {
            tableData = fullData;
            column = this.state.column;
        } else if (page === 2) {
            tableData = formGroupData;
            column = this.state.column1;
        } else if (page === 3) {
            tableData = FormChangeData;
            column = this.state.column2;
        }
        console.log(tableData, "tableData")
        return (
            <main className="main my-4">
                <main className="container-fluid">

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h1>History</h1>
                                </div>
                                <div className="row form-group">
                                    <div className="col-sm-2" />
                                    <div className="col-sm-7">

                                    </div>
                                    <div className="col-sm-3" />
                                </div>
                                <div className="card-body">
                                    <div className="row form-group ml-0 mb-0">
                                        <div className="col-sm-1 p-2" >
                                            {page !== 1 && this.dataTableButton('danger', 'Back', () => {
                                                this.OnBack(page);
                                            })}
                                        </div>
                                        <div className="col-sm-11 h2"><center>{page == 3 && `${ProcessTitle}`}</center></div>
                                    </div>
                                    {
                                        this.state.FilterData && this.state.FilterData.length ?
                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <label htmlFor="locationFilter" style={{ fontWeight: 500, fontSize: "medium" }} > Location filter: </label>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <label htmlFor="makefilter" style={{ fontWeight: 500, fontSize: "medium" }}> Make filter: </label>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <MultiSelect
                                                                id="locationFilter"
                                                                name="location"
                                                                placeholder="Filter Location"
                                                                options={LocationOptions}
                                                                handleChange={d => this.locationFilter(d)}
                                                                selectedService={this.state.filteredLoc}
                                                            />
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <MultiSelect
                                                                id="makefilter"
                                                                name="make"
                                                                placeholder="Filter Make"
                                                                options={MakeOptions}
                                                                handleChange={d => this.makeFilter(d)}
                                                                selectedService={this.state.filteredMake}
                                                            />
                                                        </div>
                                                    </div>
                                                    <br></br>
                                                    <div className="row">
                                                        <div className="col-sm-5" />
                                                        <div className="col-sm-2">
                                                            <button type="button" className="btn btn-sm btn-primary" onClick={() => {
                                                                this.clearFilter()
                                                            }}> Clear filter</button>
                                                        </div>
                                                        <div className="col-sm-5"></div>
                                                    </div>
                                                </div>
                                                <br></br>
                                            </>
                                            :
                                            ""

                                    }
                                    {this.state.loading
                                        ?
                                        <div className="text-center"> <i
                                            className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                                        ></i></div>
                                        :
                                        tableData && tableData.length
                                            ?
                                            (
                                                <Datatable data={tableData} columnHeading={column} />
                                            )
                                            :
                                            <div className="text-center">No data Found</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </main>
        );
    }
}

export default DfcarzHistory;