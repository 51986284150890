import React, { Component } from "react";
import { Alert, ListGroupItemHeading } from "reactstrap";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import SingleSelect from "../../../../components/Form/SingleSelect";
import redisMiddleware from "../../../../MiddleWare/redisMiddleware";
import iconClose from "../../../../images/CMS/icon-hamburger-close.svg";
import CmsContent from "../../../../MiddleWare/CmsContent";
import Preloader from "../preloader.js";
import { ACCESS_POINT } from "../../../../config/index";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import moment from "moment";
import { MDBBtn, MDBIcon } from "mdbreact";
import Camera from '../../../../MiddleWare/camera'
var converter = require("number-to-words");


class AT_Delivery_Note_Buyer_Reproof extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      respect: [{ label: "Sir", value: 1 }, { label: "Madam", value: 1 }],
      // source: [
      //   { label: "Walk- In", value: 1 },
      //   { label: "Tele in", value: 2 },
      //   { label: "Advt", value: 3 },
      //   { label: "Ref", value: 4 },
      //   { label: "Web", value: 5 },
      //   { label: "Others", value: 6 }
      // ],
      source: [{ label: "Walkin Showroom", value: 1 }, { label: "Walk in Event", value: 2 }, { label: "Tele in", value: 3 }, { label: "Tele Out / Sms", value: 4 }, { label: "Web Leads", value: 5 }, { label: "OLX", value: 6 }, { label: "Car Trade", value: 7 }, { label: "Car Dekho", value: 8 }, { label: "Workshop", value: 9 }, { label: "Other Portals", value: 10 }],
      Kmtoptions: [
        { label: "10000 KMs & Less", value: 1 },
        { label: "30000 KMs & Less", value: 2 },
        { label: "50000 KMs & Less", value: 3 },
        { label: "75000 KMs & Less", value: 4 },
        { label: "100000 KMs & Less", value: 5 },
      ],
      answerJson: [],
      json: [],
      filesArray: [],
      files: [],
      userid: JSON.parse(localStorage.getItem("userlog")),
      usertypelog: JSON.parse(localStorage.getItem("usertypelog")),
      button: "Save",
      alertVisible: false,
      Docs: []
    };
  }
  Mainfunc = async () => {
    try {
      let service = 10;

      let serviceid = 10;
      let colopt = [];
      let makopt = [];
      let modopt = [];
      let varopt = [];

      let allOpt = await this.decryption('getcar')
      if (allOpt) {
        this.setState({
          makopt: allOpt.makeopt,
          colopt: allOpt.coloropt,
          varopt: allOpt.variantopt,
          modopt: allOpt.modelopt,
          makeYearOption: allOpt.yearopt
        })
      }

      if (this.props.cusid) {
        this.setState({ customerId: this.props.cusid, global: false })
      }
      else if (this.props.match.params.cusid) {
        this.setState({ customerId: this.props.match.params.cusid, global: true });
      }

      let salesopt = [];
      let sales = await CmsContent.getFreedom(
        "tbl_user_web.*,tbl_UserType.orderBy",
        "tbl_user_web,tbl_MaptoLocation,tbl_UserType",
        `tbl_UserType.orderBy = 8 and tbl_MaptoLocation.id = tbl_user_web.userportid and tbl_UserType.id =tbl_MaptoLocation.userTypeId `,
        "tbl_user_web.id",
        "tbl_user_web.id DESC"
      );
      //console.log(sales)
      if (sales) {
        sales.data.map((ival, n) => {
          salesopt.push({ label: ival.userName, value: ival.id });
        });
      }
      // const formQues = await redisMiddleware.singleTable(
      //   "tbl_formquestion",
      //   80,
      //   "parentid"
      // );
      const formQues = await CmsContent.getFreedom(
        "*",
        "tbl_formquestion",
        `parentid = 80`,
        "id"
      );
      formQues.data.map((ival, i) => {
        if (ival.Orderby == null) {
          ival.Orderby = 1000 + i;
        }
      });

      formQues.data = formQues.data.sort(await CmsContent.compareValues("Orderby", "int", "asc"));
      this.setState({ data: formQues.data });
      console.log(formQues.data)
      let answerJson = this.state.answerJson;
      const prosp = await CmsContent.getFreedom(
        "*",
        "tbl_dfcarz_prosperities",
        `id = ${this.state.customerId}`,
        "id",
        "id"
      );
      const enquiry = await CmsContent.getFreedom(
        "*",
        "tbl_dfcarz_enquiryform",
        `id=${prosp.data[0].enquiry_id}`,
        1,
        1
      );
      let { Kmtoptions } = this.state
      if (enquiry && Kmtoptions) {
        console.log(enquiry, 'enq');
        let enq = enquiry.data[0];
        this.setState({ Salesid: enq.salesid })
        answerJson[416] = enq.yom;
        answerJson[417] = enq.owner_type;
        Kmtoptions.map((ival, i) => {
          if (enq.Km == ival.value) {
            answerJson[419] = ival.label
          }
        })
        this.state.makopt.map((ival, i) => {
          if (ival.value == enq.make) {

            answerJson[404] = ival;
          }
        });
        if (enq.sources) {
          this.state.source.map((ival, i) => {
            if (ival.label == enq.sources) {
              answerJson[421] = ival;
            }
          })
        }
        salesopt.map((sval, n) => {

          if (sval.value == enq.salesid) {
            answerJson[422] = sval;
          }
        });
        this.state.makopt.map((ival, i) => {
          if (ival.value == enq.Make) {
            answerJson[414] = ival.label;

          }
        });

        this.state.modopt.map((jval, m) => {
          if (jval.value == enq.Model) {
            answerJson[415] = jval.label;
          }
        });
        this.state.varopt.map((jval, m) => {
          if (jval.value == enq.Variant) {
            answerJson[545] = jval.label;
          }
        });
        if (enq.sources) {
          this.state.source.map((ival, i) => {
            // console.log(ival)
            if (ival.label == enq.sources) {
              answerJson[421] = ival;
            }
          })
        }

        this.setState({ salesopt })
      }
      let mak = [];
      let mod = [];
      let col = [];
      if (prosp && prosp.data.length > 0) {
        prosp.data.map((ival, n) => {
          this.state.modopt.map((mval, m) => {
            if (ival.model == mval.value) {
              mod.push(mval);
            }
          });
        });
        prosp.data.map((ival, m) => {
          this.state.makopt.map((kval, v) => {
            if (ival.make == kval.value) {
              mak.push(kval);
            }
          });
        });
        prosp.data.map((cval, n) => {
          this.state.colopt.map((kval, j) => {
            if (cval.value == kval.color) {
              col.push(kval);
            }
          });
        });
        // prosp.data.map((cval, n) => {
        //   this.state.colopt.map((kval, j) => {
        //     if (kval.value == prosp.color) {
        //       col.push(kval);
        //     }
        //   });
        // });
      }
      console.log(formQues, "formQues");
      if (formQues) {
        formQues.data.map((ival, i) => {
          if (ival.id == 414) {
            ival.options = this.state.makopt;
          }
          if (ival.id == 415) {
            ival.options = this.state.modopt;
          }
          if (ival.id == 418) {
            ival.options = this.state.colopt;
          }
          if (ival.id == 422) {
            ival.options = salesopt;
          }
          if (ival.id == 421) {
            ival.options = this.state.source;
          }
          if (ival.id == 545) {
            ival.options = this.state.varopt;
          }
        });
      }
      const book = await CmsContent.getFreedom(
        "*",
        "tbl_Booking_Confirmation_Reproof",
        `customerid = ${this.state.customerId}`,
        "id",
        "id desc"
      );
      this.setState({ book: book.data });
      //    categoryArray.regname = answerJson[406];
      // categoryArray.regaddress = answerJson[407];
      if (book && book.data.length > 0) {
        let book = this.state.book[0];
        console.log(book, 'bok');
        var d = new Date();
        // console.log(d)
        var date = d.getDate();
        var month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
        var year = d.getFullYear();
        var hr = d.getHours();
        var min = d.getMinutes();
        var sec = d.getSeconds();
        if (date < 10) date = "0" + date;
        if (month < 10) month = "0" + month;
        var dateStr = year + "-" + month + "-" + date;
        // console.log(dateStr)
        answerJson[399] = dateStr;
        answerJson[400] = book.name;
        answerJson[401] = book.sonof;
        answerJson[407] = book.address;
        answerJson[403] = book.mobile;
        answerJson[405] = book.regno;
        answerJson[406] = book.name;
        answerJson[402] = book.address;
        answerJson[410] = book.vech_sales_price;
        answerJson[411] = converter.toWords(answerJson[410]);
        answerJson[420] = book.finance;
        answerJson[412] = hr + ":" + min + ":" + sec;
        if (answerJson[413] && book.exp_deli_date && book.exp_deli_date != "undefined") {
          let date = book.exp_deli_date.split(" ")[0] == 'NaN-NaN-NaN' ? new Date() : new Date(book.exp_deli_date.split(" ")[0])

          answerJson[413] = moment(date).format('YYYY-MM-DD');
        } else answerJson[413] = moment(new Date()).format('YYYY-MM-DD');
        // if (answerJson[413]) {
        //   if (book.exp_deli_date) {
        //     answerJson[413] = dateStr
        //   } else {
        //     answerJson[413] = dateStr
        //   }
        // }
        // answerJson[413] = book.exp_deli_date ? moment(new Date(book.exp_deli_date.split(" ")[0])).format('YYYY-MM-DD') : dateStr;
        this.state.colopt.map((cval, n) => {
          if (cval.value == book.color) {
            answerJson[418] = cval.label;
          }
        });
      }
      if (book && book.data.length > 0) {
        const book1 = await CmsContent.getFreedom(
          "*",
          "tbl_Dfcars_Garage",
          `Reg_no LIKE "${book.data[0].regno}" `,
          "id",
          "id desc"
        );
        console.log(book1);
        if (book1 && book1.data.length) {
          answerJson[409] = book1.data[0] && book1.data[0].Engine_No && book1.data[0].Engine_No;
          answerJson[408] = book1.data[0] && book1.data[0].Chassis_no && book1.data[0].Chassis_no;
        }
      }

      // const auto = await CmsContent.getFreedom(
      //   "*",
      //   "tbl_Specifications_Sheet_Proof",
      //   `customerid = ${this.state.customerId}`,
      //   "id",
      //   "id"
      // );
      // this.setState({ auto: auto.data })
      // if (auto && auto.data.length > 0) {

      //   let auto = this.state.auto[0];
      //   answerJson[416] = auto.year
      //   answerJson[417] = auto.owernship;
      //   answerJson[419] = auto.kms
      //   answerJson[420] = auto.finance;
      //   makopt.map((ival, i) => {
      //     if (ival.value == auto.brand) {
      //       answerJson[414] = ival
      //     }
      //   })
      //   modopt.map((jval, m) => {
      //     if (jval.refid == auto.model) {
      //       answerJson[415] = jval
      //     }
      //   })

      // }

      const deliver = await CmsContent.getFreedom(
        "*",
        "tbl_Delivery_Note_Buyer_Reproof",
        `customerId=${this.state.customerId}`,
        "id",
        "id desc"
      );
      //  console.log(deliver);
      let isfillform = true

      if (deliver && deliver.data.length > 0) {
        isfillform = false

        this.setState({ deliver: deliver.data });
        this.formfilled();
      }
      // console.log(answerJson);
      this.setState({ answerJson, isfillform });
      if (isfillform) {
        if (localStorage.getItem("formFillData")) {
          let formFillData = JSON.parse(localStorage.getItem("formFillData"))
          const Data = formFillData.filter(e => e.prosid == this.state.customerId && e.ref == 'SALES_DELIVERY')
          if (Data && Data.length) {
            answerJson = Data[0].data
            this.setState({ answerJson })
          }
        }
      }
      this.process();

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            this.handleSuccess,
            this.handleError
        );
     } else {
        this.setState({ error: 'Geolocation is not supported' });
     }

    } catch (error) {
      console.error(error);
    }
  }
  async componentDidMount() {
    try {
      await this.Mainfunc()
    } catch (error) {
      console.error(error);
    }
  }

  handleSuccess = (position) => {
    this.setState({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        error: null
    });
};

handleError = (error) => {
    this.setState({ error: error.message });
};

  formfilled = () => {
    let { Kmtoptions } = this.state
    let answerJson = this.state.answerJson;
    let del = this.state.deliver[0];
    console.log("de", del);
    answerJson.id = del.id;
    // if (del.date) {
    //   let date1 = del.date.split(" ")[0]
    //   answerJson[399] = date1
    // }
    if (del.date) {
      var d = new Date(del.date);
      var date = d.getDate();
      var month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      var year = d.getFullYear();
      var hr = d.getHours();
      var min = d.getMinutes();
      var sec = d.getSeconds();
      if (date < 10) date = "0" + date;
      if (month < 10) month = "0" + month;
      var dateStr =
        year + "-" + month + "-" + date;
      answerJson[399] = dateStr
    }
    answerJson[401] = del.sonof;
    answerJson[402] = del.address;
    answerJson[407] = del.regaddress;
    answerJson[405] = del.regno;
    answerJson[406] = del.regname;
    answerJson[404] = del.carname;
    answerJson[417] = del.ownership;
    answerJson[408] = del.chassisno;
    answerJson[409] = del.enggno;
    Kmtoptions.map((kmval, i) => {
      if (del.kms == kmval.value) {
        answerJson[419] = kmval.label
      }
    })
    //answerJson[410] = del.price;
    //answerJson[411] = del.price_word
    if (del.deli_time && del.deli_time != 'undefined') {
      answerJson[412] = del.deli_time;
    } else {
      var today = new Date()
      answerJson[412] = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    }
    //console.log(del.deli_time, 'time');
    // deli_date
    if (del.deli_date && del.deli_date != "undefined") {
      let date = del.deli_date.split(" ")[0] == 'NaN-NaN-NaN' ? new Date() : new Date(del.deli_date.split(" ")[0])
      // console.log(date,'date');     
      answerJson[413] = moment(date).format('YYYY-MM-DD');
    } else answerJson[413] = moment(new Date()).format('YYYY-MM-DD');
    //answerJson[413] = del.deli_date
    this.state.colopt.map((val, m) => {
      if (val.value == del.color) {
        answerJson[418] = val.label;
      }
    });
    if (this.state.makopt) {
      this.state.makopt.map((mval, i) => {
        if (mval.value == del.make) {
          answerJson[414] = mval.label;
        }
      });
    }
    if (this.state.modopt) {
      this.state.modopt.map((moval, i) => {
        if (moval.value == del.model) {
          answerJson[415] = moval.label;
        }
      });
    }
    if (this.state.varopt) {
      this.state.varopt.map((val, n) => {
        if (val.value == del.variant) {
          answerJson[545] = val.label;
        }
      });
    }
    answerJson[420] = del.finance;
    // answerJson[421] = del.source;
    if (this.state.salesopt) {
      this.state.salesopt.map((ival, i) => {
        if (ival.label == del.constl_name) {
          answerJson[422] = ival;
        }
      });
    }
    let images = this.state.images;
    images = JSON.parse(del.Files);

    let img = [];
    if (images != null) {
      images.map((ival, i) => {

        if (ival) {
          let spliting = ival.split(".");
          if (['pdf', 'doc', 'xlsx', 'csv'].includes(spliting[1])) {
            let a = null
            a = "false/pdfIconTrans_1599478202382.png";

            let wait = img.push(
              <div>
                <div style={{ paddingLeft: '160px', position: 'relative' }} >
                  <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                    onClick={() => this.removeimg(i)}></img>
                </div>
                <img onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }}
                  src={ACCESS_POINT + "/superAdmin/file?fileurl=" + a}
                  style={{
                    width: "200px", height: "200px", display: "inline-flex", padding: "10px"

                  }} />
              </div>
            )
          } else {

            let wait = img.push(
              <div>
                <div style={{ paddingLeft: '160px', position: 'relative' }} >
                  <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                    onClick={() => this.removeimg(i)}></img>
                </div>
                <img onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }}
                  src={ACCESS_POINT + "/superAdmin/file?fileurl=" + ival}
                  style={{
                    width: "200px", height: "200px", display: "inline-flex", padding: "10px"

                  }} />
              </div>
            )
          }
        }
      })




      //   let wait = img.push(
      //     <div>
      //       <div style={{ paddingLeft: '160px', position: 'relative' }} >
      //         <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
      //           onClick={() => this.removeimg(i)}></img>
      //       </div>
      //       <img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + ival}
      //         style={{
      //           width: "200px", height: "200px", display: "inline-flex", padding: "10px"

      //         }} />
      //     </div>
      //   )
      // })
      this.setState({ img, oldFiles: images })
    }

    // console.log("images,", images);

    // let img = [];
    // if (images != null) {
    //   images.map((ival) => {
    //     // console.log(ival);
    //     // console.log(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival);
    //     img.push(<img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + ival}
    //       style={{
    //         width: "200px", height: "200px", display: "inline-flex", padding: "10px"

    //       }} />

    //     )
    //   })
    //   this.setState({ img,oldFiles:images })
    // }
    console.log(img, "img");
    // answerJson[422] = del.constl_name;
    //console.log(answerJson)
    this.setState({ button: "Update", answerJson, images });
    this.process();
    this.imgFun()
  };
  imgFun = () => {
    let { images } = this.state
    let img = [];
    if (images != null) {
      images.map((ival, i) => {

        if (ival) {
          let spliting = ival.split(".");
          if (['pdf', 'doc', 'xlsx', 'csv'].includes(spliting[1])) {
            let a = null
            a = "false/pdfIconTrans_1599478202382.png";

            let wait = img.push(
              <div>
                <div style={{ paddingLeft: '160px', position: 'relative' }} >
                  <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                    onClick={() => this.removeimg(i)}></img>
                </div>
                <img onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }}
                  src={ACCESS_POINT + "/superAdmin/file?fileurl=" + a}
                  style={{
                    width: "200px", height: "200px", display: "inline-flex", padding: "10px"

                  }} />
              </div>
            )
          } else {

            let wait = img.push(
              <div>
                <div style={{ paddingLeft: '160px', position: 'relative' }} >
                  <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                    onClick={() => this.removeimg(i)}></img>
                </div>
                <img onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }}
                  src={ACCESS_POINT + "/superAdmin/file?fileurl=" + ival}
                  style={{
                    width: "200px", height: "200px", display: "inline-flex", padding: "10px"

                  }} />
              </div>
            )
          }
        }
      })



      this.setState({ img, oldFiles: images })
    }
  }
  removeimg = (i) => {
    let { oldFiles, img, images } = this.state;
    if (oldFiles || img) {
      images && images.splice(i, 1)
      img && img.splice(i, 1)
      this.setState({ images, img })
      console.log(images, img,);
      this.imgFun()
    }
  }
  // update = async () => {
  //   const { answerJson } = this.state;
  //   let categoryArray = {};
  //   let id = answerJson.id;
  //   if (answerJson[399]) {
  //     var d = new Date(answerJson[399]);
  //     var date = d.getDate();
  //     var month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
  //     var year = d.getFullYear();
  //     var hr = d.getHours();
  //     var min = d.getMinutes();
  //     var sec = d.getSeconds();
  //     var dateStr =
  //       year + "-" + month + "-" + date + " " + hr + ":" + min + ":" + sec;
  //     categoryArray.date = dateStr;
  //   }
  //   //categoryArray.date = answerJson[399];
  //   categoryArray.from_name = answerJson[400];
  //   categoryArray.sonof = answerJson[401];
  //   categoryArray.address = answerJson[402];
  //   categoryArray.mobile = answerJson[403];
  //   categoryArray.carname = answerJson[404];
  //   categoryArray.regno = answerJson[405];
  //   categoryArray.regname = answerJson[406];
  //   categoryArray.regaddress = answerJson[407];
  //   categoryArray.chassisno = answerJson[408];
  //   categoryArray.enggno = answerJson[409];
  //   categoryArray.price = answerJson[410];
  //   categoryArray.price_word = answerJson[411];
  //   categoryArray.deli_time = answerJson[412];
  //   if (answerJson[413]) {
  //     var d1 = new Date(answerJson[413]);
  //     var date1 = d1.getDate();
  //     var month1 = d1.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
  //     var year1 = d1.getFullYear();
  //     var hr1 = d1.getHours();
  //     var min1 = d1.getMinutes();
  //     var sec1 = d1.getSeconds();
  //     var dateStr1 =
  //       year1 +
  //       "-" +
  //       month1 +
  //       "-" +
  //       date1 +
  //       " " +
  //       hr1 +
  //       ":" +
  //       min1 +
  //       ":" +
  //       sec1;
  //     categoryArray.deli_date = dateStr1;
  //   }
  //   // categoryArray.deli_date = answerJson[413];
  //   if (answerJson[414]) {
  //     categoryArray.make = answerJson[414].value;
  //   }
  //   if (answerJson[415]) {
  //     categoryArray.model = answerJson[415].value;
  //   }
  //   categoryArray.year = answerJson[416];
  //   categoryArray.ownership = answerJson[417];
  //   if (answerJson[418]) {
  //     categoryArray.color = answerJson[418].value;
  //   }
  //   categoryArray.kms = answerJson[419];
  //   categoryArray.finance = answerJson[420];
  //   if (answerJson[421]) {
  //     categoryArray.source = answerJson[421].label;
  //   }
  //   if (answerJson[422]) {
  //     categoryArray.constl_name = answerJson[422].label;
  //   }
  //   console.log(categoryArray);
  //   let result = await CmsContent.updateMaster(
  //     "tbl_Delivery_Note_Buyer_Reproof",
  //     id,
  //     categoryArray
  //   );
  //   if (result) {
  //     window.scroll({
  //       top: 0,
  //       left: 0,
  //       behavior: "smooth"
  //     });
  //     await this.setState({
  //       json: [],
  //       answerJson: "",
  //       alertVisible: true,
  //       textalert: "Your form has been updated successfully",
  //       color: "success"
  //     });
  //     this.process();
  //     setTimeout(() => window.location.reload(), 3000);
  //   }
  // };

  update1 = async () => {
    const { answerJson, files, filesArray } = this.state;

    // let imagealeart = ''
    // if (filesArray && filesArray.length) {
    //   const fileSize = filesArray[0].size / 1024 / 1024;
    //   const maxFileSize = 3; // Maximum file size in MB
    //   // Validate file size
    //   if (fileSize > maxFileSize) {
    //     alert("Please Check Image is Greater than 3MB");
    //     imagealeart = true
    //     return true;
    //   }
    // }
    // if (imagealeart == true) {
    //   return false;
    // }
    // console.log(this.state.latitude,"latitude",this.state.longitude,"longitude");
    if (this.state.latitude == '' ||this.state.longitude == ''  || this.state.latitude == undefined ||this.state.longitude == undefined ) {
            alert('Please allow permission to access your location');;
      // return false;
    }
    const formData = new FormData()
    let id = answerJson.id;
    if (!answerJson[405]) {
      this.setState({
        alertVisible: true,
        textalert: "Please Enter Reg No",
        color: "warning"
      })
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      return false
    }
    if (!answerJson[415]) {
      this.setState({
        alertVisible: true,
        textalert: "Please Enter Model ",
        color: "warning"
      })
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      return false
    }
    if (!answerJson[418]) {
      this.setState({
        alertVisible: true,
        textalert: "Please Enter Color ",
        color: "warning"
      })
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      return false
    }
    this.setState({ btnDisable: true })
    let categoryArray = {};

    if (answerJson[399]) {
      var d = new Date(answerJson[399]);
      var date = d.getDate();
      var month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      var year = d.getFullYear();
      var hr = d.getHours();
      var min = d.getMinutes();
      var sec = d.getSeconds();
      if (date < 10) date = "0" + date;
      if (month < 10) month = "0" + month;
      var dateStr =
        year + "-" + month + "-" + date;
      // categoryArray.date = dateStr;
      formData.append("date", dateStr)
    }
    //formData.append('id',id)
    if (answerJson[400]) {
      formData.append("from_name", answerJson[400])
    }
    if (answerJson[401]) {
      formData.append("sonof", answerJson[401])
    }
    if (answerJson[402]) {
      formData.append("address", answerJson[402])
    }
    if (answerJson[403]) {
      formData.append("mobile", answerJson[403])
    }
    if (answerJson[404]) {
      formData.append("carname", answerJson[404])
    }
    if (answerJson[405]) {
      formData.append("regno", answerJson[405])
    }
    if (answerJson[406]) {
      formData.append("regname", answerJson[406])
    }
    if (answerJson[407]) {
      formData.append("regaddress", answerJson[407])
    }
    if (answerJson[408]) {
      formData.append("chassisno", answerJson[408])
    }
    if (answerJson[409]) {
      formData.append("enggno", answerJson[409])
    }
    if (answerJson[410]) {
      formData.append("price", answerJson[410])
    }
    if (answerJson[411]) {
      formData.append("price_word", answerJson[411])
    }
    if (answerJson[412]) {
      formData.append("deli_time", answerJson[412])
    }
    if (answerJson[413]) {
      var d1 = new Date(answerJson[413]);
      var date1 = d1.getDate();
      var month1 = d1.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      var year1 = d1.getFullYear();
      var hr1 = d1.getHours();
      var min1 = d1.getMinutes();
      var sec1 = d1.getSeconds();
      if (date1 < 10) date1 = "0" + date1;
      if (month1 < 10) month1 = "0" + month1;
      var dateStr1 =
        year1 +
        "-" +
        month1 +
        "-" +
        date1 +
        " " +
        hr1 +
        ":" +
        min1 +
        ":" +
        sec1;

      formData.append("deli_date", dateStr1)
    }

    if (answerJson[414]) {
      formData.append("make", answerJson[414].value)
    }
    if (answerJson[545]) {
      formData.append("variant", answerJson[545].value)
    }
    if (answerJson[415]) {
      formData.append("model", answerJson[415].value)
    }
    formData.append("year", answerJson[416])
    if (answerJson[417]) {
      console.log(answerJson[417], 'answerJson[417]');
      formData.append("ownership", answerJson[417] != null && answerJson[417])
    }
    if (answerJson[418]) {
      // console.log(answerJson[418], 'answerJson[418]');
      formData.append("color", answerJson[418].value)
    }
    // if (answerJson[418]) { 
    //   formData.append("ownership", answerJson[417])
    // }

    formData.append("kms", answerJson[419])
    formData.append("finance", answerJson[420])
    if (answerJson[421]) {
      formData.append("source", answerJson[421].label)
    }
    if (answerJson[422]) {
      formData.append("constl_name", answerJson[422].label)
    }
    formData.append("customerid", parseInt(this.state.customerId))
    // formData.append("userid", parseInt(this.state.userid.id))
    formData.append("approval", 0)
    formData.append("status", "active")
    if ([1, 2, 3].includes(this.state.usertypelog.orderBy)) {
      formData.append("userid", this.state.Salesid);
    } else {
      formData.append("userid", this.state.userid.id);
    }
    if ([1, 2, 3].includes(this.state.usertypelog.orderBy)) {
      formData.append("UpdatedBy", this.state.userid.id)
    }
    if (this.state.filesArray) {
      this.state.filesArray.map((ival, i) => {
        formData.append(`files`, ival);
      })
    }
    if (this.state.oldFiles) {
      formData.append(`oldFiles`, JSON.stringify(this.state.oldFiles))
    }
           
    formData.append("latitude", this.state.latitude);

    formData.append("longitude", this.state.longitude);
   
    console.log([...formData]);
    try {
      let result = await CmsContent.siteintegratedstatus(
        "tbl_Delivery_Note_Buyer_Reproof",
        formData,
        id
      );
      if (result) {
        await this.setState({
          btnDisable: false,
          json: "",
          answerJson: "",
          alertVisible: true,
          textalert: "Your form have been Updated successfully",
          color: "success"
        });
        setTimeout(() => window.location.reload(), 3000);

        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
        // await this.setState({
        //   json: "",
        //   answerJson: "",
        //   alertVisible: true,
        //   textalert: "Your form have been submitted successfully",
        //   color: "success"
        // });
        this.process();
        // setTimeout(() => window.location.reload(), 3000);

        // if (this.state.alertVisible == true) {
        //   setTimeout(
        //     () => window.location.reload(),
        //     2000
        //   );
        // }
      }

    } catch (error) {
      console.log(error);
      this.setState({ btnDisable: false })
    }
  };

  async answers(
    label,
    index,
    options = null,
    key = null,
    type,
    questionId,
    formid
  ) {
    let { answerJson, data, isfillform } = this.state;
    console.log(label, questionId, 'ans');

    let get = [];
    if (questionId == 414) {

      answerJson[415] = []
      answerJson[545] = []

      await this.state.modopt.map((mval, i) => {
        if (mval.makeid == label.value) {
          get.push(mval);
        }
      });
      await data.map((ival, k) => {
        if (ival.id == 415) {
          ival.options = get;
        }
        if (ival.id == 545) {
          ival.options = []
        }
      });

    }

    if (questionId == 415) {
      let varNewOPT = []
      answerJson[545] = []
      await this.state.varopt.map((vval, i) => {
        if (vval.modelid == label.value) {
          varNewOPT.push(vval);
        }
      });
      await data.map((ival, k) => {
        if (ival.id == 545) {
          ival.options = varNewOPT;
        }
      });

    }


    if (type == "text") {
      answerJson[questionId] = label;
    } else if (type == "date") {
      var d = new Date(label);
      // var date = d.getDate();
      // var month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      // var year = d.getFullYear();
      // var hr = d.getHours();
      // var min = d.getMinutes();
      // var sec = d.getSeconds();
      // var dateStr =
      //   year + "-" + month + "-" + date + " " + hr + ":" + min + ":" + sec;
      answerJson[questionId] = label;
    } else if (type == "textarea") {
      answerJson[questionId] = label;
    } else if (type == "selectbox") {
      answerJson[questionId] = label;
    } else if (type == "radio") {
      answerJson[questionId] = label;
    } else if (type == "checkbox") {
      // let check = [];
      // options.map((ival, i) => {
      //   var checkBox = document.getElementById(`inlineCheckbox${index}${i}`);
      //   if (checkBox.checked == true) {
      //     check.push(ival);
      //   }
      // });
      //answerJson[questionId] = check.toString();
      answerJson[questionId] = label;
    }
    // let get = []
    // if (questionId == 414) {
    //   this.state.modopt.map((mval, i) => {
    //     if (mval.value == label.value) {
    //       get.push(mval);
    //     }
    //   });
    //   data.map((ival, k) => {
    //     if (ival.id == 415) {
    //       ival.options = get;
    //     }
    //   });

    // }
    if (isfillform) { await this.formSave('SALES_DELIVERY', this.state.customerId, answerJson) }
    await this.setState({ answerJson, data });
    this.process();
  }
  process = async () => {
    let data = await this.state.data;
    let { usertypelog } = this.state
    if (data) {
      let arr = data;
      let contentJson = [];
      if (arr && arr.length) {
        let answerJson = await this.state.answerJson;
        let answerJsons = answerJson.length;
        let json = await arr.map(async (item, index) => {
          if (item.type == "text") {
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="position">{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      disabled
                      className="form-control"
                      placeholder={item.placeholder}
                      // onChange={e =>
                      //   this.answers(
                      //     e.target.value,
                      //     index,
                      //     null,
                      //     null,
                      //     "text",
                      //     item.id,
                      //     item.parentid
                      //   )
                      // }
                      onBlur={e =>
                        this.answers(
                          e.target.value,
                          index,
                          null,
                          null,
                          "text",
                          item.id,
                          item.parentid
                        )
                      }
                      defaultValue={answerJson[item.id]}
                    // value={answerJson[item.id]}
                    />
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "date") {
            this.state.date = answerJson[item.id];
            console.log(item.id);
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="position">{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    {item.id == 399 || item.id == 413 ? <input
                      type="date"
                      id="myDate"
                      className="form-control"
                      disabled={usertypelog.orderBy == 2 ? false : true}
                      onChange={e =>
                        this.answers(
                          e.target.value,
                          index,
                          null,
                          null,
                          "date",
                          item.id,
                          item.parentid
                        )
                      }
                      value={this.state.date}
                    // onChange={this.fromDateSelect}
                    // value={selectedstartdate}
                    /> :
                      <input
                        type="date"
                        id="myDate"
                        className="form-control"
                        onChange={e =>
                          this.answers(
                            e.target.value,
                            index,
                            null,
                            null,
                            "date",
                            item.id,
                            item.parentid
                          )
                        }
                        value={this.state.date}
                      // onChange={this.fromDateSelect}
                      // value={selectedstartdate}
                      />
                    }
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "textarea") {
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder={item.placeholder}
                      // onChange={e =>
                      //   this.answers(
                      //     e.target.value,
                      //     index,
                      //     null,
                      //     null,
                      //     "textarea",
                      //     item.id,
                      //     item.parentid
                      //   )
                      // }
                      onBlur={e =>
                        this.answers(
                          e.target.value,
                          index,
                          null,
                          null,
                          "textarea",
                          item.id,
                          item.parentid
                        )
                      }
                      defaultValue={answerJson[item.id]}
                    // value={answerJson[item.id]}
                    ></textarea>
                  </div>
                  <div className="col-sm-3">
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                </div>
              </div>
            );
          }
          if (item.type == "radio") {
            let arr1 = [];
            await item.options.split(",").map((element, key) => {
              arr1.push(
                <div className="bgcolor">
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      name={`element${index}`}
                      // value={`${element}`}
                      id={`inlineCheckbox${key}`}
                      onChange={e =>
                        this.answers(
                          element,
                          key,
                          null,
                          key,
                          "radio",
                          item.id,
                          item.parentid
                        )
                      }
                      checked={element == answerJson[item.id]}
                    />
                    <label
                      className="form-check-label"
                      for={`inlineCheckbox${key}`}
                      style={{ marginLeft: "10px" }}
                    >
                      {`${element}`}{" "}
                    </label>
                  </div>
                </div>
              );
            });
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    {arr1}
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "selectbox") {
            let option = [];
            if (item.options != "" && item.options != null) {
              item.options.map((ival, i) => {
                option.push(ival);
              });
            }
            // item.options.split(",").map((ival, i) => {
            //   option.push({ label: ival, value: i });
            // });
            this.state.selectbox = answerJson[item.id];
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <SingleSelect
                      options={option}
                      placeholder={item.placeholder}
                      handleChange={e => {
                        this.answers(
                          e,
                          index,
                          null,
                          null,
                          "selectbox",
                          item.id,
                          item.parentid
                        );
                      }}
                      selectedService={this.state.selectbox}
                    />
                  </div>
                </div>
                <span className="error-shows" id={`${index}`}>
                  {/* {this.state.error[index]} */}
                </span>
                <div className="col-sm-3"></div>
              </div>
            );
          }
          if (item.type == "checkbox") {
            let arr1 = [];
            await item.options.split(",").map((element, key) => {
              arr1.push(
                <div className="bgcolor">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={`inlineCheckbox${index}${key}`}
                      onChange={e => {
                        this.answers(
                          element,
                          index,
                          item.options.split(","),
                          key,
                          "checkbox",
                          item.id,
                          item.parentid
                        );
                      }}
                      checked={element == answerJson[item.id]}
                    //value={element}
                    />
                    <label
                      className="form-check-label"
                      for={`inlineCheckbox${index}${key}`}
                      style={{ marginLeft: "10px" }}
                    >{`${element}`}</label>
                  </div>
                </div>
              );
            });
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">{arr1}</div>
                  <div className="col-sm-3">
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                </div>
              </div>
            );
          }
        });
        await Promise.all(json);
        await this.setState({ json: contentJson, answerJson });
      }
    }
  };
  Siteongoing = async () => {
    let answerJson = this.state.answerJson;
    console.log(answerJson);
    let groupArray1 = {}
    if (answerJson[418] && answerJson[415] && answerJson[405]) {
      if (answerJson[418]) {
        groupArray1.colour = answerJson[418].value
      }
      if (answerJson[415]) {
        groupArray1.model = answerJson[415].value
      }
      if (answerJson[405]) {
        groupArray1.Reg_no = answerJson[405]
      }
      let result = await CmsContent.siteintegratedstatus("status", groupArray1)
      if (result) {
        await this.setState({
          json: "",
          answerJson: "",
          alertVisible: true,
          textalert: "Your form have been submitted successfully",
          color: "success"
        });
        setTimeout(() => window.location.reload(), 3000);
      }
      else {
        this.setState({
          alertVisible: true,
          textalert: "Your form has been not submit properly",
          color: "danger"
        });
      }
      // }
      // catch(err) {
      //   console.log(err);
      // }
      // }
    }
    else {
      this.setState({
        alertVisible: true,
        textalert: "Your form has been not submit properly",
        color: "danger"
      });
    }
  }
  formSubmit = async () => {
    // let error = [...this.state.error];
    // let e = [];
    // this.state.data.map((item, i) => {
    //   this.state.answerJson.map((element, key) => {
    //     if (
    //       item.required == 1 &&
    //       item.id == element.questionId &&
    //       (element.answer == null || element.answer == "-")
    //     ) {
    //       error[i] = "Value must not be empty";
    //       e.push(error[i]);
    //     } else if (
    //       item.required == 1 &&
    //       item.id == element.questionId &&
    //       (element.answer != null || element.answer != "-")
    //     ) {
    //       error[i] = null;
    //       e.push(error[i]);
    //     }
    //   });
    // });
    // this.setState({ error: error });
    // this.process();

    // for (var i = 0; i < e.length; i++) {
    //   if (e[i] != null) {
    //     return false;
    //   }
    // }
    const { answerJson, filesArray } = this.state;

    // let imagealeart = ''
    // if (filesArray && filesArray.length) {
    //   const fileSize = filesArray[0].size / 1024 / 1024;
    //   const maxFileSize = 3; // Maximum file size in MB
    //   // Validate file size
    //   if (fileSize > maxFileSize) {
    //     alert("Please Check Image is Greater than 3MB");
    //     imagealeart = true
    //     return true;
    //   }
    // }
    // if (imagealeart == true) {
    //   return false;
    // }
    // console.log(this.state.latitude,"latitude",this.state.longitude,"longitude");
    if (this.state.latitude == '' ||this.state.longitude == ''  || this.state.latitude == undefined ||this.state.longitude == undefined ) {
            alert('Please allow permission to access your location');;
      // return false;
    }
    const formData = new FormData()

    let categoryArray = {};
    if (answerJson[399]) {
      var d = new Date(answerJson[399]);
      var date = d.getDate();
      var month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      var year = d.getFullYear();
      var hr = d.getHours();
      var min = d.getMinutes();
      var sec = d.getSeconds();
      if (date < 10) date = "0" + date;
      if (month < 10) month = "0" + month;
      var dateStr =
        year + "-" + month + "-" + date;
      // categoryArray.date = dateStr;
      formData.append("date", dateStr)
    }

    if (!answerJson[405]) {
      this.setState({
        alertVisible: true,
        textalert: "Please Enter Reg No",
        color: "warning"
      })
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      return false
    }
    if (!answerJson[415]) {
      this.setState({
        alertVisible: true,
        textalert: "Please Enter Model ",
        color: "warning"
      })
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      return false
    }
    if (!answerJson[418]) {
      this.setState({
        alertVisible: true,
        textalert: "Please Enter Color ",
        color: "warning"
      })
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      return false
    }
    this.setState({ btnDisable: true })
    // categoryArray.from_name = answerJson[400];
    // categoryArray.sonof = answerJson[401];
    // categoryArray.address = answerJson[402];
    // categoryArray.mobile = answerJson[403];
    // categoryArray.carname = answerJson[404];
    // categoryArray.regno = answerJson[405];
    // categoryArray.regname = answerJson[406];
    // categoryArray.regaddress = answerJson[407];
    // categoryArray.chassisno = answerJson[408];
    // categoryArray.enggno = answerJson[409];
    // categoryArray.price = answerJson[410];
    // categoryArray.price_word = answerJson[411];
    // categoryArray.deli_time = answerJson[412];
    if (answerJson[400]) {
      formData.append("from_name", answerJson[400])
    }
    if (answerJson[401]) {
      formData.append("sonof", answerJson[401])
    }
    if (answerJson[402]) {
      formData.append("address", answerJson[402])
    }
    if (answerJson[403]) {
      formData.append("mobile", answerJson[403])
    }
    if (answerJson[404]) {
      formData.append("carname", answerJson[404])
    }
    if (answerJson[405]) {
      formData.append("regno", answerJson[405])
    }
    if (answerJson[406]) {
      formData.append("regname", answerJson[406])
    }
    if (answerJson[407]) {
      formData.append("regaddress", answerJson[407])
    }
    if (answerJson[408]) {
      formData.append("chassisno", answerJson[408])
    }
    if (answerJson[409]) {
      formData.append("enggno", answerJson[409])
    }
    if (answerJson[410]) {
      formData.append("price", answerJson[410])
    }
    if (answerJson[411]) {
      formData.append("price_word", answerJson[411])
    }
    if (answerJson[412]) {
      formData.append("deli_time", answerJson[412])
    }
    if (answerJson[413]) {
      var d1 = new Date(answerJson[413]);
      var date1 = d1.getDate();
      var month1 = d1.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      var year1 = d1.getFullYear();
      var hr1 = d1.getHours();
      var min1 = d1.getMinutes();
      var sec1 = d1.getSeconds();
      if (date1 < 10) date1 = "0" + date1;
      if (month1 < 10) month1 = "0" + month1;
      var dateStr1 =
        year1 +
        "-" +
        month1 +
        "-" +
        date1 +
        " " +
        hr1 +
        ":" +
        min1 +
        ":" +
        sec1;
      // categoryArray.deli_date = dateStr1;
      formData.append("deli_date", dateStr1)
    }

    if (answerJson[414]) {
      // categoryArray.make = answerJson[414].value;
      formData.append("make", answerJson[414].value)
    }
    if (answerJson[545]) {
      formData.append("variant", answerJson[545].value)
    }
    if (answerJson[415]) {
      // categoryArray.model = answerJson[415].refid;
      formData.append("model", answerJson[415].value)
    }
    if (answerJson[418]) {
      formData.append("color", answerJson[418].value)
    }
    formData.append("year", answerJson[416])
    if (answerJson[417]) {
      formData.append("ownership", answerJson[417])
    }
    // categoryArray.year = answerJson[416];
    // categoryArray.ownership = answerJson[417];   
    // categoryArray.kms = answerJson[419];
    // categoryArray.finance = answerJson[420];
    formData.append("kms", answerJson[419])
    formData.append("finance", answerJson[420])
    if (answerJson[421]) {
      // categoryArray.source = answerJson[421].label;
      formData.append("source", answerJson[421].label)
    }
    if (answerJson[422]) {
      // categoryArray.constl_name = answerJson[422].label;
      formData.append("constl_name", answerJson[422].label)
    }
    // categoryArray.customerid = this.state.customerId;
    // categoryArray.userid = this.state.userid.id;
    // categoryArray.approval = 0;
    // categoryArray.status = "active";
    formData.append("customerid", parseInt(this.state.customerId))
    formData.append("approval", 0)
    formData.append("status", "active")

    if ([1, 2, 3].includes(this.state.usertypelog.orderBy)) {
      formData.append("userid", parseInt(this.state.Salesid));
    } else {
      formData.append("userid", parseInt(this.state.userid.id));
    }
    if ([1, 2, 3].includes(this.state.usertypelog.orderBy)) {
      formData.append("UpdatedBy", this.state.userid.id)
    }
    if (this.state.filesArray) {
      this.state.filesArray.map((ival, i) => {
        formData.append(`files`, ival);
      })
    }
           
    formData.append("latitude", this.state.latitude);

    formData.append("longitude", this.state.longitude);
   
    console.log([...formData]);
    try {
      let result = await CmsContent.siteintegratedstatus(
        "tbl_Delivery_Note_Buyer_Reproof",
        formData
      );
      if (result) {
        let formJson = await JSON.parse(localStorage.getItem("formFillData"))
        if (formJson && formJson.length) {
          let Index = await formJson.findIndex(e => e.prosid == this.state.customerId && e.ref == 'SALES_DELIVERY')
          if (Index > -1) formJson.splice(Index, 1)
          localStorage.setItem("formFillData", JSON.stringify(formJson))
        }
        await this.setState({
          btnDisable: false,
          json: "",
          answerJson: "",
          alertVisible: true,
          textalert: "Your form have been submitted successfully",
          color: "success"
        });
        setTimeout(() => window.location.reload(), 3000);

        this.process();
        // setTimeout(() => window.location.reload(), 3000);
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
        // if (this.state.alertVisible == true) {
        //   setTimeout(
        //     () => window.location.reload(),
        //     2000
        //   );
        // }
      }

    } catch (error) {
      console.log(error);
      this.setState({ btnDisable: false })
    }
  };
  download = (name) => {
    // window.open(`${ACCESS_POINT}/cmsContent/downloadbuyer/${name}`, '_self')
    window.open(`${ACCESS_POINT}/cmsContent/downloadbuyer/${name}`, '_self')
  }
  generatepdf = async (id, name) => {
    let exportpdf = await CmsContent.Deliverybuyer("tbl_Delivery_Note_Buyer_Reproof", id);
    if (exportpdf.data == "success") {
      await this.download(name)
    }
  }
  formSubmitPdf = async () => {

    const { answerJson } = this.state;
    //const formData = new FormData();
    let categoryArray = {};
    if (answerJson[399]) {
      var d = new Date(answerJson[399]);
      var date = d.getDate();
      var month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      var year = d.getFullYear();
      var hr = d.getHours();
      var min = d.getMinutes();
      var sec = d.getSeconds();
      if (date < 10) date = "0" + date;
      if (month < 10) month = "0" + month;
      var dateStr =
        year + "-" + month + "-" + date + " " + hr + ":" + min + ":" + sec;
      categoryArray.date = dateStr;
    }
    categoryArray.from_name = answerJson[400];
    categoryArray.sonof = answerJson[401];
    categoryArray.address = answerJson[402];
    categoryArray.mobile = answerJson[403];
    categoryArray.carname = answerJson[404];
    categoryArray.regno = answerJson[405];
    categoryArray.regname = answerJson[406];
    categoryArray.regaddress = answerJson[407];
    categoryArray.chassisno = answerJson[408];
    categoryArray.enggno = answerJson[409];
    categoryArray.price = answerJson[410];
    categoryArray.price_word = answerJson[411];
    categoryArray.deli_time = answerJson[412];
    if (answerJson[413]) {
      var d1 = new Date(answerJson[413]);
      var date1 = d1.getDate();
      var month1 = d1.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      var year1 = d1.getFullYear();
      var hr1 = d1.getHours();
      var min1 = d1.getMinutes();
      var sec1 = d1.getSeconds();
      if (date1 < 10) date1 = "0" + date1;
      if (month1 < 10) month1 = "0" + month1;
      var dateStr1 =
        year1 +
        "-" +
        month1 +
        "-" +
        date1 +
        " " +
        hr1 +
        ":" +
        min1 +
        ":" +
        sec1;
      categoryArray.deli_date = dateStr1;
    }

    if (answerJson[414]) {
      categoryArray.make = answerJson[414].value;
    }
    if (answerJson[415]) {
      categoryArray.model = answerJson[415].value;
    }
    categoryArray.year = answerJson[416];
    categoryArray.ownership = answerJson[417];
    if (answerJson[418]) {
      categoryArray.color = answerJson[418].value;
    }
    categoryArray.kms = answerJson[419];
    categoryArray.finance = answerJson[420];
    if (answerJson[421]) {
      categoryArray.source = answerJson[421].label;
    }
    // categoryArray.source = answerJson[421];
    if (answerJson[422]) {
      categoryArray.constl_name = answerJson[422].label;
    }
    categoryArray.customerid = this.state.customerId;
    categoryArray.userid = this.state.userid.id;
    categoryArray.approval = 0;
    categoryArray.status = "active";
    try {
      let result = await CmsContent.addMaster(
        "tbl_Delivery_Note_Buyer_Reproof",
        categoryArray
      );
      if (result) {
        // this.generatepdf(result.data.insertId, answerJson[406])
        this.generatepdf(this.state.customerId, answerJson[406]);
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
        await this.setState({
          json: [],
          answerJson: [],
          alertVisible: true,
          textalert: "Your form have been submitted successfully",
          color: "success"
        });
        this.process();
        setTimeout(() => window.location.reload(), 3000);
      }

    } catch (error) {
      console.log(error);
    }
  };
  exportpdf = () => {
    let answerJson = this.state.answerJson;
    let name = answerJson[406]
    window.open(`${ACCESS_POINT}/cmsContent/downloadbuyer/${name}`, '_self')
  }
  vehicleupload = (e) => {
    let filesArray = this.state.filesArray;
    if (e) {
      filesArray = e.map(fileItem => fileItem.file)
      this.setState({ filesArray });
      console.log(filesArray);
    }
  }
  webcam2 = (e, field, ival = null) => {
    let Files = this.state[field];
    if (e) {
      Files = Files ? Files : []
      Files.push({ source: e })
      this.setState({ [field]: Files })
      if (ival) this.setState({ [ival]: false })
    }
  }
  render() {
    const { resSelected, json, button, filesArray } = this.state;
    console.log(this.state.oldFiles);
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div
                  className="card"
                  style={{
                    margin: "-20px",
                    marginTop: "5px",
                    boxShadow: "0px 0px 10px #b9b3b3",
                    borderRadius: "8px"
                  }}
                >
                  <div className="card-header">
                    <h1>Delivery Note Seller</h1>
                  </div>
                  <div className="card-body">
                    <div>
                      <Alert
                        className="badge-content"
                        color={this.state.color}
                        isOpen={this.state.alertVisible}
                        toggle={this.onDismiss}
                      >
                        {this.state.textalert}
                      </Alert>
                      <form id="json">{json && json.length ? json : <div>Loading...</div>}
                        {json.length > 0 ? (
                          <div className="bgcolor">
                            <div className="row form-group">
                              <div className="col-sm-2"></div>
                              <div className="col-sm-2">
                                <label htmlFor="">
                                  File Upload {<div className="btn btn-default btn-sm" value={this.state.IsfilesArrayCam} onClick={() => this.setState({ IsfilesArrayCam: !this.state.IsfilesArrayCam })}>
                                    <MDBIcon icon="camera" size="lg" />
                                  </div>} :
                                </label>
                              </div>
                              <div className="col-sm-3">
                                {this.state.IsfilesArrayCam ? <Camera
                                  image={(e) => { this.webcam2(e, 'filesArray', 'IsfilesArrayCam') }}
                                  width={300}
                                  height={400}
                                  id={'IsfilesArrayCam'}
                                />
                                  :
                                  <div><FilePond
                                    files={filesArray}
                                    allowReorder={true}
                                    allowMultiple={true}
                                    allowImageResize={true}
                                    imagePreviewMinHeight="50"
                                    imagePreviewMaxHeight="50"
                                  //  maxFileSize={'3072KB'}
                                    acceptedFileTypes={["image/*"]}
                                    onupdatefiles={fileItems => {
                                      this.vehicleupload(fileItems);
                                    }}
                                    labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                  />
                                    {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label> &nbsp;&nbsp;
                                    <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                                  </div>}
                              </div>
                            </div>
                            <div className="row form-group">
                              <div className="col-sm-2"></div>
                              <div className="col-sm-1"></div>
                              <div className="col-sm-9">
                                {this.state.img ? this.state.img : null}
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </form>
                      {json.length > 0 && (
                        <div>
                          {this.state.global && (
                            <div className="d-flex justify-content-center">
                              <div className="">
                                {[1, 2, 3,14].includes(this.state.usertypelog.orderBy) ? <button
                                  type="button"
                                  style={{ minWidth: 100 }}
                                  className="btn btn-primary"
                                  disabled={this.state.btnDisable}
                                  onClick={
                                    button == "Save" ? this.formSubmit : this.update1
                                  }
                                >
                                  {this.state.btnDisable ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;
                                  {button == "Save" ? "Save" : "Update"}
                                </button> : button == "Save" ? <button
                                  type="button"
                                  style={{ minWidth: 100 }}
                                  className="btn btn-primary"
                                  disabled={this.state.btnDisable}
                                  onClick={
                                    button == "Save" ? this.formSubmit : this.update1
                                  }
                                >
                                  {this.state.btnDisable ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;
                                  {button == "Save" ? "Save" : "Update"}
                                </button> : null}
                              </div>
                            </div>
                          )}
                          {this.state.global && (
                            <div className="row form-group">
                              <div className="col-sm-2" />
                              <div className="col-sm-2" />
                              <div className="col-sm-5">
                                {/* {this.state.button == "Save" && (
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={
                                      button == "Save" ? this.formSubmitPdf : null
                                    }
                                  // onClick={this.formSubmit}
                                  >
                                    {button == "Save" ? "Save and Export PDF" : null}
                                  </button>
                                )} */}
                              </div>
                            </div>
                          )}
                          {/* {this.state.global == false && (
                            <div className="row form-group">
                              <div className="col-sm-2" />
                              <div className="co  l-sm-2" />
                              <div className="col-sm-5">
                                <button
                                  style={{ marginLeft: "200px" }}
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => this.print()}
                                >
                                  Print
                                </button>
                              </div>
                            </div>
                          )} */}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment >
    );

    // else {
    //   return <Preloader />
    // }
  }
}

export default AT_Delivery_Note_Buyer_Reproof;
