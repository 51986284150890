import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import DfCarsEnquiry from "../Evaluator/DfCarsEnquiry";
import SingleSelect from "../../../../components/Form/SingleSelect";
import moment from "moment";
import Autocomplete from "../../../../components/Autocomplete/Autocomplete";
import CmsContent from "../../../../MiddleWare/CmsContent";
import state_city from "../../../Json/state_city";
import state from "../../../Json/state";
import LoginModal from "../../../../components/Modal/Modal";
import Modelmaster from "../Master/Modelmaster"
import { result } from "lodash-es";
import Datatable from "../../../../components/Datatable/Datatable";
import InventoryList from "../SalesMaster/InventoryList";
import { Link } from "react-router-dom";
import swal from "sweetalert";

class DfCarsAddEnquiry extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      formFilled: {},
      data: [],
      customerId: "",
      others: false,
      otherModel: "",
      alertVisible: false,
      mobvalLoader: false,
      regvalLoader: false,
      status2visible: false,
      status3visible: false,
      textalert: "",
      leadname: null,
      editid: null,
      mobile: "",
      regno: "",
      email: "",
      ownertype: "",
      colour: "",
      salesopt_filtered: [],
      make: "",
      errormobile: "",
      errorregno: "",
      erroremail: "",
      errorleadname: "",
      stateselected: "",
      errorlocation: "",
      isEdit: false,
      Index: null,
      disableValue: false,
      status: {},
      status1: {},
      status2: {},
      status3: {},
      errorstatus: "",
      errorstatus: "",
      errorstatus1: "",
      emailmessage: "",
      mobilemessage: "",
      Typelist: [{ label: "seller", value: "1" }, { label: "buyer", value: 2 }],
      usertypelog: JSON.parse(localStorage.getItem("usertypelog")),
      SourcingList: [{ label: "Walkin Showroom", value: 1 }, { label: "Walk in Event", value: 2 }, { label: "Tele in", value: 3 }, { label: "Tele Out / Sms", value: 4 }, { label: "Web Leads", value: 5 }, { label: "OLX", value: 6 }, { label: "Car Trade", value: 7 }, { label: "Car Dekho", value: 8 }, { label: "Workshop", value: 9 }, { label: "Other Portals", value: 10 }],
      // SourcingList: [
      //   { value: 0, label: "Advt" },
      //   { value: 1, label: "Telein" },
      //   { value: 2, label: "Walk-In" },
      //   { value: 3, label: "Ref" },
      //   { value: 4, label: "Web" },
      //   { value: 5, label: "Showroom" },
      //   { value: 6, label: "Workshop" },
      //   { value: 7, label: "event" },
      //   { value: 8, label: "Others" }
      // ],
      status1options: [
        { value: 0, label: "Scheduled Follow up" },
        { label: "Scheduled Evaluation", value: 1 },
        { value: 2, label: "Mark Closed" },
        // {value:2,label:"Evaluated"},
      ],
      process_tbl: [],
      makeYearOption: [],
      btnDisablecsv: false,
      makeyearSelected: [],
      statusArray: [],
      statusoption: [{ value: 0, label: "Hot" }, { value: 1, label: "Warm" }],
      ownershipOpt: [
        {
          label: "1",
          value: 1
        },
        {
          label: "2",
          value: 2
        },
        {
          label: "3",
          value: 3
        },
        {
          label: "4 and above",
          value: 4
        },
        // {
        //   label: "5",
        //   value: 5
        // }, {
        //   label: "6",
        //   value: 6
        // }, {
        //   label: "7",
        //   value: 7
        // },
      ],
      fueloptions: [
        { label: "PETROL", value: 1 },
        { label: "DIESEL", value: 2 },
        // { label: "HYBRID", value: 3 },
        // { label: "LPG", value: 4 },
        // { label: "CNG", value: 5 },
      ],
      NewMakeData: [
        { label: "HONDA", value: 9 },
        { label: "KIA", value: 26 },
        { label: "SKODA", value: 18 },
        { label: "VW", value: 21 },
        { label: "RENAULT", value: 17 },
        { label: "OTHERS", value: "OTHERS" }
      ],
      type: {},
      LocationDetail: null,
      LocationID: localStorage.getItem(`LocationID`),
      EnquiryData: [],
      Budget: "",
      sources: {},
      Next_Contact_date: "",
      errorNext_Contact_date: "",
      Model: "",
      model: "",
      CloseEnquiry: false,
      comments1: "",
      newmake: [],
      newmodel: [],
      ModelData: [],
      MakeData: [],
      ColourList: [],
      colour: {},
      userid: JSON.parse(localStorage.getItem("userlog")),
      usertypelog: JSON.parse(localStorage.getItem("usertypelog")),
      CustomerList: [],
      CustomerPresent: false,
      modelPresent: false,
      LocationOpt: [],
      enquiry: true
    };
    this.nextWindow = this.nextWindow.bind(this);
  }
  emailval = async (e) => {
    let { email, formFilled } = this.state;
    let emailmessage = this.state
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (pattern.test(e)) {
      console.log("email:   ok");
      emailmessage = (
        <div >
          <em style={{ color: "green" }}><i className="fa fa-check"></i>Valid Email</em>
        </div>
      )
      this.setState({ emailmessage })
      if (emailmessage) {
        formFilled.email = e
        email = e

        await this.setState({ email, formFilled })
        //console.log("validate email:", email);
      }
      setTimeout(
        () => this.setState({ emailmessage: false }),
        3000
      );
    } else {
      // console.log("email :  notok")
      emailmessage = (
        <div >
          <em style={{ color: "red" }}><i className="fa fa-times "></i>Invalid Email</em>
        </div>
      )
      this.setState({ emailmessage })
      //this.setState({ emailmessage })
      await this.setState({ email: null })
      return false
    }
  }
  handlechangeData = async e => {
    let { formFilled } = this.state
    formFilled[e.target.name] = e.target.value
    this.setState({ [e.target.name]: e.target.value, formFilled });
  };
  handleDescription = async e => {
    this.setState({ type: e });
  };
  handlestatus = e => {
    this.setState({ status: e });
  };

  submitCategory = async () => {
    const {
      leadname,
      usertypelog,
      mobile,
      email,
      type,
      status,
      customerId,
      Budget,
      sources,
      Model,
      Next_Contact_date,
      Register,
      Km,
      year,
      ownertype,
      Expirydate,
      Location,
      makeyearSelected,
      cityselected,
      stateselected,
      regno,
      status1,
      status2,
      status3,
      userid,
      salesname,
      newcarmodel,
      newmodel,
      statusArray
    } = this.state;

    // console.log(this.state.latitude,"latitude",this.state.longitude,"longitude");
    if (this.state.latitude == '' || this.state.longitude == '' || this.state.latitude == undefined || this.state.longitude == undefined) {
      alert('Please allow permission to access your location');;
      // return false;
    }

    this.setState({ btnDisable: true })
    var typelabel = usertypelog.orderBy == 9 ? "seller" : "buyer";
    let groupArray = {};
    groupArray.latitude = this.state.latitude;
    groupArray.longitude = this.state.longitude;
    groupArray.name = leadname;
    groupArray.enquiry_Source = "web"
    groupArray.mobile = mobile;
    groupArray.Reg_no = regno;
    groupArray.email = email;
    groupArray.type = typelabel;
    groupArray.status = status.label;
    groupArray.Budget = Budget;
    groupArray.Reg_no = Register;
    if (userid) {
      groupArray.downline = userid.downline
      groupArray.downline_type = userid.userType
    }
    // groupArray.salesid = salesname && salesname.value;
    groupArray.sales_consultant = salesname && salesname.value;
    groupArray.pendingStatus1 = status1 && status1.label == "Scheduled Evaluation" ? 'Schedule walkin' : status1.label;
    // groupArray.pendingStatus1 = status1 && status1.label;
    groupArray.pendingStatus2 = status2 ? status2.label : null;
    groupArray.pendingStatus3 = status3 ? status3.label : null;
    // groupArray.newcarmodel = this.state.newmodel ? this.state.newmodel.value : null
    groupArray.Km = Km ? Km : null;
    if (ownertype) {
      groupArray.owner_type = ownertype.label;
    }
    // groupArray.location = Location;
    groupArray.yom = makeyearSelected.label;
    groupArray.Model = this.state.model ? this.state.model.value : null;
    groupArray.Make = this.state.make ? this.state.make.value : null;
    groupArray.variant = this.state.variant
      ? this.state.variant.value
      : null;
    groupArray.newcarmake = this.state.newmake.value
      ? this.state.newmake.value
      : null;
    groupArray.newcarmodel = this.state.newmodel && this.state.newmodel.value
      ? this.state.newmodel.value
      : null;
    groupArray.othernewcarmodel = this.state.otherModel
      ? this.state.otherModel
      : null;
    // groupArray.state = stateselected.label;
    // groupArray.city = cityselected.label;
    let Userlog = JSON.parse(localStorage.getItem("userlog"))
    if (statusArray && statusArray.length == 0) {
      let obj = {}
      obj.status1 = status1 && status1.label == "Scheduled Evaluation" ? 'Schedule walkin' : status1.label;
      obj.status2 = status2 ? status2.label : null;
      obj.status3 = status3 ? status3.label : null;
      if (status2 && status2.label == undefined) {
        obj.status2 = "-"
      }
      if (Next_Contact_date != "") {
        obj.Next_Contact_date = Next_Contact_date && Next_Contact_date.length && moment(Next_Contact_date).format('YYYY-MM-DD')
      }
      else {
        obj.Next_Contact_date = "-"
      }
      obj.sino = statusArray.length + 1
      obj.userName = Userlog.userName
      if (obj) {
        statusArray.unshift(obj)
      }
    }
    console.log(statusArray, "statusArray ");
    if (Next_Contact_date) {
      groupArray.Next_Contact_date = Next_Contact_date;
    }
    groupArray.insurance_expiry = Expirydate;
    if (sources) {
      groupArray.sources = sources.label;
    }
    groupArray.companyid = this.state.customerId;
    groupArray.enquiry_status = "active";
    groupArray.color = this.state.colour ? this.state.colour.value : null;
    groupArray.fuel = this.state.fuel ? this.state.fuel.label : null;

    if (usertypelog.orderBy == 1) {
      if (stateselected) {
        groupArray.location = stateselected.value;
      }
    }
    else {
      groupArray.location = this.state.LocationID
    }
    groupArray.statusArray = JSON.stringify(statusArray);
    groupArray.evaluatorid = usertypelog.orderBy == 9 ? this.state.userid.id : null;
    groupArray.updatedby = usertypelog.orderBy != 9 ? this.state.userid.id : null;
    groupArray.branch = usertypelog.orderBy == 1 || usertypelog.orderBy == 2 || usertypelog.orderBy == 3 ? this.state.userid.sub_loc_id : null;
    console.log(groupArray, "grouparray ");
    if (this.validate()) {
      try {
        console.log("enter");
        this.setState({ disableValue: true });
        const res = await cmsContent.DfCarzAddEnquiry(groupArray)

        if (res) {
          localStorage.removeItem('filled')
          let newData = [];
          if (this.state.EnquiryData.length) {
            groupArray.createdAt = new Date();
            groupArray.createdAt = moment(groupArray.createdAt).format(
              "DD-MM-YYYY"
            );
            newData = [groupArray, ...this.state.EnquiryData];
          } else {
            newData.push(groupArray);
          }
          this.props.these("EnquiryDatas", newData);
          this.props.these("EnquiryData", newData);
          this.props.these("CloseEnquiry", false);
          this.props.these("isEdit", false);
          this.setState({
            formFilled: {},
            // data: newData,
            btnDisable: false,
            alertVisible: true,
            disableValue: false,
            textalert: "New Enquiry Added",
            color: "success",
            errorColourName: "",
            leadname: "",
            emailmessage: "",
            ownertype: "",
            Expirydate: "",
            Km: "",
            makeyearSelected: "",
            stateselected: "",
            erroremail: "",
            errorstatus: "",
            errormobile: "",
            errorlocation: "",
            errorregno: "",
            erroremail: "",
            errorsources: "",
            errorstatus1: "",
            errorstatus2: "",
            errorstatus3: "",
            status: "",
            mobile: "",
            status1: "",
            status2: "",
            status3: "",
            salesname: "",
            newcarmodel: "",
            newmake: "",
            newmodel: "",
            otherModel: "",
            Register: "",
            email: "",
            type: {},
            ModelData: [],
            model: {},
            make: {},
            colour: {},
            variant: {},
            suggestion: [],
            modelPresent: false
          });
          window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
          setTimeout(() => this.setState({ alertVisible: false }), 3000);
          this.props.mainFun()
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      this.setState({
        btnDisable: false,
        alertVisible: true,
        textalert: "Please fill mandatory field", color: "danger"
      })
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
      setTimeout(() => this.setState({
        alertVisible: false,
        btnDisable: false,
        errorstatus1: "",
        errorsources: "",
        errorregno: "",
        errormobile: "",
        errorleadname: "",
        errorlocation: "",
        errorNext_Contact_date: "",
        errorstatus: ""
      }), 5000)
    }
  };

  onDismiss = () => {
    this.setState({ alertVisible: false, formAlertdelete: false });
  };

  async componentWillReceiveProps(props) {
    try {
      let clear = localStorage.getItem('clearForm')
      let filled = JSON.parse(localStorage.getItem('filled'))
      if (filled && ('mobile' in filled || 'Register' in filled)) {
        let keys = Object.keys(filled)
        for (let index = 0; index < keys.length; index++) {
          let element = keys[index];
          // console.log(element, 'element');
          // console.log(filled[element], 'filled[element]')
          if (element && element == 'mobile' && filled[element]) {
            this.setState({ [element]: filled[element] });
            this.handleMobileInput(filled[element], 'mobile');
          } else if (element && element == 'email' && filled[element]) {
            await this.setState({ [element]: filled[element] });
            this.emailval(filled[element])
          }
          else if (element && element == 'makeyearSelected' && filled[element]) {
            await this.setState({ [element]: filled[element], model: {} });
            this.makeyearSelect(filled[element])
          }
          else if (element && element == 'newmake' && filled[element]) {
            await this.setState({ [element]: filled[element] });
            await this.NewMakeChanger(filled[element])
          }
          else if (element && element == 'model' && filled[element]) {
            await this.handleModel(filled[element])
          }
          else if (element && element == 'Register' && filled[element]) {
            await this.handleRegisterInput(filled[element], element)
          }
          else {
            await this.setState({ [element]: filled[element] });
          }
        }
        await this.setState({ formFilled: filled })
      } else if (clear && clear == 'yes') {
        this.setState({
          formFilled: {},
          leadname: "",
          mobile: "",
          email: "",
          status: "",
          editid: "",
          comments: "",
          isEdit: false,
          EditData: {},
          ownertype: "",
          colour: "",
          fuel: '',
          Expirydate: "",
          makeyearSelected: "",
          Km: "",
          stateselected: "",
          otherModel: "",
          newmodel: "",
          comments: "",
          newmake: "",
          type: {},
          Budget: "",
          sources: "",
          Model: {},
          model: {},
          Next_Contact_date: "",
          make: {},
          newcarmodel: "",
          Register: "",
          salesname: [],
          status3: [],
          status2: [],
          status1: [],
          suggest: null,
          suggestOne: null,
          suggest1: null,
          suggestOne1: null,
        });
        localStorage.removeItem('clearForm')
      } else if (props) {
        // console.log('propsseyyys');
        let user = JSON.parse(localStorage.getItem(`userlog`));
        this.setState({ customerId: user.id });
        this.setState({
          EnquiryData: props.EnquiryData,
          isEdit: props.isEdit,
          // suggestion: props.suggestion,
          // suggestion1: props.suggestion1,
          CustomerList: props.CustomerList
        });

        if (props.EnquiryData) {
          await this.setState({ isEdit: true })
        }
        let locaMaster = []


        this.setState({
          CloseEnquiry: props.CloseEnquiry,
          LocationDetail: props.LocationDetail
        });

        ////console.log(this.state.MakeData);
        if (props.CloseEnquiry == true) {
          let d = props.EditData;
          this.setState({
            CloseEnquiry: props.CloseEnquiry,
            EditData1: d,
            comments: ""
          });
        } else if (props.CloseEnquiry == false) {
          if (props.isEdit == true) {
            console.log(props.EditData, 'edit data');
            //this.setState({EditData:props.EditData});
            let d = props.EditData;
            console.log(d, 'EditData');
            if (d.newcarmake == "OTHERS") {
              this.setState({ newmake: this.state.NewMakeData.filter(x => x.value == d.newcarmake)[0] })
            } else {
              let w = this.state.NewMakeData.map(async (ival, i) => {
                if (d.newcarmake == ival.value) {
                  this.setState({ newmake: ival })
                  let Model = await cmsContent.getFreedom(
                    `Model as label , id as value`,
                    `tbl_dfcarsmodel`,
                    `makeid=${d.newcarmake}`
                  );
                  if (Model.data.length) {
                    // Model.data.push({ label: "OTHERS", value: "OTHERS" });
                    let wait1 = await Model.data.map(async (jval, j) => {
                      if (d.newcarmodel == jval.value) {
                        this.setState({ newmodel: jval });
                      }
                    });

                    await Promise.all(wait1);
                  }
                }
              })
              await Promise.all(w);
            }
            this.state.statusoption.map((ival, i) => {

              if (d.status == ival.label) {

                this.setState({ status: ival })
              }
            });

            this.state.makeYearOption.map((ival, i) => {
              if (d.yom == ival.label) {
                this.setState({ makeyearSelected: ival })
              }
            })

            this.state.LocationOpt.map((ival, i) => {
              if (d.location == ival.value) {
                this.setState({ stateselected: ival })
              }
            })
            this.state.Typelist.map((ival, i) => {
              if (d.type == ival.label) {
                this.setState({ type: ival });
              }
            });

            let wait3 = await this.state.ColourList.map(async (ival, i) => {
              if (ival.value == d.color) {
                this.setState({ colour: ival });
              }
            });
            let fuel = this.state.fueloptions.filter(x => x.label == d.fuel)
            if (fuel) {
              this.setState({ fuel });
            }


            let variant = await this.state.variantopt.filter(x => x.value == d.Variant)
            if (variant) {
              await this.setState({ variant })
            }
            await Promise.all(wait3);
            this.state.SourcingList.map((ival, i) => {
              if (d.sources == ival.label) {
                this.setState({ sources: ival });
              }
            });
            let wait = await this.state.MakeData.map(async (ival, i) => {
              if (ival.value == d.Make) {
                this.setState({ make: ival });
                let Model = await cmsContent.getFreedom(
                  `CONCAT(Model,'(',syear,'-',eyear,')' ) as label , id as value,syear,eyear`,
                  `tbl_dfcarsmodel`,
                  `makeid=${d.Make}`
                );
                if (Model.data.length) {
                  let wait1 = await Model.data.map(async (jval, j) => {
                    if (d.Model == jval.value) {
                      this.setState({ model: jval });
                    }
                  });
                  await Promise.all(wait1);
                  let wait2 = await
                    Model.data.map(async (jval, j) => {
                      if (d.newcarmodel == jval.value) {
                        this.setState({ newmodel: jval });
                      }
                    })
                  await Promise.all(wait2);
                }
                this.setState({ ModelData: Model.data });
              }
            });
            await Promise.all(wait);
            if (d.newcarmake == "OTHERS") {
              if (d.othernewcarmodel) {
                this.setState({ others: true, otherModel: d.othernewcarmodel })
              } else {
                this.setState({ others: true, otherModel: "" })
              }
            } else {
              this.setState({ others: false, otherModel: "" })
            }
            this.setState({ Register: d.Reg_no })
            this.setState({ status1: [{ label: d.pendingStatus1 == 'Schedule walkin' ? 'Scheduled Evaluation' : d.pendingStatus1 }], status2visible: true })
            this.setState({ status2: [{ label: d.pendingStatus2 }], status3visible: true })
            this.setState({ status3: [{ label: d.pendingStatus3 }] })
            this.setState({ salesname: [{ label: this.getValueFromArray(d.sales_consultant, this.state.salesopt), value: d.sales_consultant }] })
            this.setState({ newcarmodel: d.newcarmodel })
            //this.handlestatus1(this.state.status1)

            this.state.ownershipOpt.map((ival, i) => {
              if (d.owner_type == ival.label) {
                this.setState({ ownertype: ival });
              }
            });
            if (d.Next_Contact_date !== null) {
              let date = new Date(d.Next_Contact_date);
              //console.log(date);
              let DD = moment(date).format("YYYY-MM-DD")
              //console.log(DD);
              this.setState({ Next_Contact_date: DD });
              //console.log(this.state.Next_Contact_date);
            }

            this.nextWindow(d.name)

            this.setState({
              //leadname: d.name,
              mobile: d.mobile,
              email: d.email,
              Expirydate: d.Insurance_expiry,
              editid: d.id,
              Index: props.Index,
              DataEDit: d,
              comments: d.comments,
              Km: d.Km,
              Budget: d.Budget,

              // Model:d.Model,
              //Next_Contact_date: d.Next_Contact_date
            });
          } else {
            this.setState({
              leadname: "",
              mobile: "",
              email: "",
              status: "",
              editid: "",
              comments: "",
              isEdit: false,
              EditData: {},
              ownertype: "",
              colour: "",
              fuel: '',
              Expirydate: "",
              makeyearSelected: "",
              Km: "",
              stateselected: "",
              otherModel: "",
              newmodel: "",
              comments: "",
              newmake: "",
              type: {},
              Budget: "",
              sources: "",
              Model: {},
              model: {},
              Next_Contact_date: "",
              make: {},
              newcarmodel: "",
              Register: "",
              salesname: [],
              status3: [],
              status2: [],
              status1: [],
              suggest: null,
              suggestOne: null,
              suggest1: null,
              suggestOne1: null,
            });
          }
        }
      } else {
        console.log('else');
        this.setState({
          leadname: "",
          mobile: "",
          email: "",
          status: "",
          editid: "",
          comments: "",
          isEdit: false,
          EditData: {},
          ownertype: "",
          colour: "",
          fuel: '',
          Expirydate: "",
          makeyearSelected: "",
          Km: "",
          stateselected: "",
          otherModel: "",
          newmodel: "",
          comments: "",
          newmake: "",
          type: {},
          Budget: "",
          sources: "",
          Model: {},
          model: {},
          Next_Contact_date: "",
          make: {},
          newcarmodel: "",
          Register: "",
          salesname: [],
          status3: [],
          status2: [],
          status1: [],
          suggest: null,
          suggestOne: null,
          suggest1: null,
          suggestOne1: null,
        });
      }
    } catch (error) {
      //console.log(error);
    }
  }

  async componentDidMount() {
    let { salesopt_filtered } = this.state
    try {
      let usertypelog = this.state.usertypelog;
      let date = new Date();
      var year = date.getFullYear();
      let mini_year = 1990;

      var difference = year - mini_year;
      let typeLabel = this.state.usertypelog.orderBy == 9 ? "seller" : "buyer";
      // let result = await cmsContent.getFreedom(
      //   `*`,
      //   `tbl_dfcarz_customers`,
      //   `type="${typeLabel}"`,
      //   "id",
      //   "id"
      // );
      // // console.log(result);
      // let suggestion = [];

      // if (result) {
      //   result.data.map((ival, i) => {
      //     if (ival.name !== null) {
      //       suggestion.push(ival.name);
      //     }
      //   });
      // }
      // this.setState({ suggestion });

      let makeYearOption = [];
      for (var i = 0; i <= difference; i++) {
        makeYearOption.push({
          label: mini_year + i,
          value: i
        });
      }
      if (makeYearOption.length) {
        makeYearOption.reverse()
      }
      let decr = this.Decrypt(localStorage.getItem('getcar'))
      let allOpt = JSON.parse(decr)
      console.log(allOpt, "LocalStorage");
      if (allOpt) {
        await this.setState({
          MakeData: allOpt.makeopt,
          ColourList: allOpt.coloropt,
          variantopt: allOpt.variantopt,
          ModelData: allOpt.modelopt,
          // NewMakeData: allOpt.newmakeopt,
          LocationOpt: allOpt.locopt
        })

      }

      const getnewsales = await CmsContent.getFreedom(
        "name as label,id as value,brandid as brandid,branchId as branch,locationId as location,managerId as manager",
        "tbl_dfcars_sales_consultant",
        "status = 'active'",
        // 1,
        // 1
      );
      if (getnewsales && getnewsales.data.length) {
        await this.setState({ salesopt: getnewsales.data })
      }
      this.setState({ makeYearOption });
      if (usertypelog.orderBy !== 1) {
        let UserId = this.state.userid
        console.log("1")
        salesopt_filtered = this.state.salesopt.filter(x => x.location == UserId.locationid)
        if (salesopt_filtered.length) {
          this.setState({ salesopt_filtered, salesname: "" });
        }
      }
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.handleSuccess,
          this.handleError
        );
      } else {
        this.setState({ error: 'Geolocation is not supported' });
      }

    } catch (err) { console.log(err); }
  }

  handleSuccess = (position) => {
    this.setState({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      error: null
    });
  };

  handleError = (error) => {
    this.setState({ error: error.message });
  };

  UpdateCategory = async () => {
    const {
      leadname,
      Budget,
      sources,
      model,
      Next_Contact_date,
      mobile,
      email,
      ownertype,
      Index,
      makeyearSelected,
      comments,
      type,
      Km,
      status,
      Expirydate,
      customerId,
      regno,
      status1,
      status2,
      status3,
      salesname,
      stateselected,
      usertypelog,
      newmodel,
      newmake,
      otherModel,
      userid,
      Register
    } = this.state;

    // console.log(this.state.latitude,"latitude",this.state.longitude,"longitude");
    if (this.state.latitude == '' || this.state.longitude == '' || this.state.latitude == undefined || this.state.longitude == undefined) {
      alert('Please allow permission to access your location');
      // return false;
    }
    this.setState({ btnDisable: true })
    var d = new Date();
    d = moment(d).format("DD-MM-YYYY");
    let subgroupArray = {};
    subgroupArray.latitude = this.state.latitude;
    subgroupArray.longitude = this.state.longitude;
    subgroupArray.status = status && status.label;
    subgroupArray.comments = comments;
    // subgroupArray.salesid = salesname && salesname.value;
    subgroupArray.sales_consultant = salesname && salesname.value;
    subgroupArray.pendingStatus1 = status1 && status1.label == "Scheduled Evaluation" ? 'Schedule walkin' : status1.label;
    subgroupArray.pendingStatus2 = status2 && status2.label;
    subgroupArray.pendingStatus3 = status3 && status3.label;
    if (userid) {
      subgroupArray.downline = userid.downline
      subgroupArray.downline_type = userid.userType
    }
    //    subgroupArray.newcarmodel=newcarmodel;
    subgroupArray.Reg_no = Register;
    subgroupArray.type = type ? type.label : null;
    subgroupArray.Model = this.state.model ? this.state.model.value : null;
    subgroupArray.Make = this.state.make ? this.state.make.value : null;
    subgroupArray.variant = this.state.variant ? this.state.variant.value : null;
    subgroupArray.newcarmake = this.state.newmake ?
      this.state.newmake.value : null;
    subgroupArray.newcarmodel = this.state.newmodel && this.state.newmodel ? this.state.newmodel.value : null;
    subgroupArray.othernewcarmodel = this.state.otherModel ? this.state.otherModel : null;
    subgroupArray.Next_Contact_date = Next_Contact_date;
    subgroupArray.Budget = Budget;
    subgroupArray.sources = sources && sources.label;
    subgroupArray.color = this.state.colour && this.state.colour.value;
    subgroupArray.fuel = this.state.fuel && this.state.fuel.label;
    subgroupArray.owner_type = ownertype ? ownertype.label : null;
    subgroupArray.yom = makeyearSelected && makeyearSelected.label;
    subgroupArray.Insurance_expiry = Expirydate ? Expirydate : null;
    subgroupArray.Km = Km;
    if (usertypelog.orderBy == 1) {
      if (stateselected) {
        subgroupArray.location = stateselected.value;
      }
    }
    else {
      subgroupArray.location = this.state.LocationID
    }
    //subgroupArray.enquiry_status='active'
    // subgroupArray.evaluatorid = usertypelog.orderBy == 9 ? this.state.userid.id : null;
    subgroupArray.updatedby = this.state.userid.id;
    let id = this.state.editid
    console.log(subgroupArray, "subgroupArray");
    try {
      const result = await cmsContent.UpdateDfcarzEnquiry(
        id,
        subgroupArray
      );
      if (result) {
        localStorage.removeItem('filled')
        subgroupArray.name = leadname;
        subgroupArray.mobile = mobile;
        subgroupArray.email = email;
        subgroupArray.type = type.label;

        subgroupArray.id = this.state.editid;
        // subgroupArray.Next_Contact_date=Next_Contact_date;
        subgroupArray.companyid = this.state.customerId;
        subgroupArray.Next_Contact_date = this.state.Next_Contact_date;
        //subgroupArray.enquiry_status='active'
        let previousdata = [...this.state.EnquiryData];
        previousdata[Index] = subgroupArray;
        let groupArray = {};
        groupArray.status = status;
        groupArray.comments = comments;

        groupArray.enquiryid = this.state.editid;
        groupArray.userid = this.state.customerId;
        groupArray.Next_Contact_date = Next_Contact_date;
        groupArray.Budget = Budget;
        groupArray.sources = sources;
        groupArray.Model = this.state.model ? this.state.model.value : null;
        const history = await cmsContent.addMaster(
          "tbl_dfcars_enquiryhistory",
          groupArray
        );
        await this.props.these("isEdit", false);
        await this.props.these("EditData", {});
        await this.props.these("EnquiryData", previousdata);
        await this.props.these("CloseEnquiry", false);
        this.setState({
          formFilled: {},
          // data: newData,
          btnDisable: false,
          alertVisible: true,
          disableValue: false,
          textalert: "Enquiry Updated",
          color: "success",
          errorColourName: "",
          leadname: "",
          emailmessage: "",
          ownertype: "",
          Expirydate: "",
          Km: "",
          makeyearSelected: "",
          stateselected: "",
          leadname: "",
          errorstatus: "",
          errormobile: "",
          errorregno: "",
          erroremail: "",
          status: "",
          mobile: "",
          regno: "",
          email: "",
          type: {},
          comments: "",
          Model: {},
          model: {},
          make: {},
          newmake: {},
          newmodel: {},
          Budget: "",
          errorlocation: "",
          errorsources: "",
          erroremail: "",
          errorstatus1: "",
          errorstatus2: "",
          errorstatus3: "",
          status: "",
          mobile: "",
          status1: "",
          status2: "",
          status3: "",
          salesname: "",
          newcarmodel: "",
          otherModel: "",
          Register: "",
          email: "",
          type: {},
          ModelData: [],
          colour: {},
          variant: {},
          suggestion: [],
          modelPresent: false,
          isEdit: false,
          Next_Contact_date: "",
          sources: "",
          colour: {}
        });
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
        this.props.mainFun()
      }
    } catch (error) {
      console.log(error);
      this.setState({ btnDisable: false })
    }
  };

  CloseEnquiry = async () => {
    const { comments1, CloseEnquiry, EditData1 } = this.state;
    let groupArray = {};
    groupArray.enquiry_status = "inactive";
    groupArray.comments = comments1;
    try {
      const result = await cmsContent.updateMaster(
        "tbl_dfcarz_enquiryform",
        EditData1.id,
        groupArray
      );
      if (result) {
        let groupArray1 = {};
        groupArray1.comments = comments1;
        groupArray1.enquiryid = EditData1.id;
        groupArray1.userid = this.state.customerId;
        const history = await cmsContent.addMaster(
          "tbl_dfcars_enquiryhistory",
          groupArray1
        );
        let previousdata = [];
        this.state.EnquiryData.map((ival, i) => {
          if (ival.id != EditData1.id) {
            previousdata.push(ival);
          }
        });
        this.props.these("EnquiryData", previousdata);
        this.props.these("CloseEnquiry", false);
        this.props.these("isEdit", false);
        this.setState({
          comments: "",
          CloseEnquiry: false
        });
      }
    } catch (error) {
      ////console.log(error);
    }
  };
  NewMakeChanger = async e => {
    let { salesopt_filtered, salesopt, userid, usertypelog, formFilled } = this.state
    console.log(e, "e")
    console.log(salesopt, "salesopt")
    console.log(userid, "userid")
    formFilled.newmake = e
    if (e.label === "OTHERS") {
      this.setState({ newmake: e, others: true, salesopt_filtered: salesopt, salesname: "", formFilled })
    }
    else {
      this.setState({ newmake: e, others: false, formFilled });
      // console.log("newmake", this.state.newmake);
      let Curyear = moment(new Date()).format('YYYY')
      let Model = await cmsContent.getFreedom(
        `Model as label , id as value,syear,eyear`,
        `tbl_dfcarsmodel`,
        `makeid=${e.value} and eyear  >=${Curyear}`
      );
      if (Model.data.length) {
        // Model.data.push({ label: "OTHERS", value: "OTHERS" });
        await Model.data.map((ival) => {
          if (ival.syear && ival.eyear) {
            ival.label = ival.label + '(' + ival.syear + '-' + ival.eyear + ')'
          }
        })
        await this.setState({ NewModelData: Model.data, newmodel: "" })
      } else {
        this.setState({ NewModelData: [], newmodel: "" });
      }

      // if (usertypelog.orderBy == 1) {
      //   console.log("1")
      //   salesopt_filtered = this.state.salesopt.filter(x => x.brandid == e.value && userid.locationid == x.location)
      // }
      // else if (usertypelog.orderBy == 2) {
      //   console.log("2")
      //   salesopt_filtered = this.state.salesopt.filter(x => x.brandid == e.value)
      // }
      // else {
      //   console.log("3")
      //   console.log(this.state.salesopt, "this.state.salesopt")
      //   salesopt_filtered = this.state.salesopt.filter(x => x.brandid == e.value && userid.locationid == x.location && userid.sub_loc_id == x.branch)
      // }

      // console.log(salesopt_filtered, "salesopt_filtered");
      // if (salesopt_filtered.length) {
      //   this.setState({ salesopt_filtered, salesname: "" });
      // } else {
      //   this.setState({ salesopt_filtered: [], salesname: "" });
      // }
    }
  };
  handleModel1 = async e => {
    let { formFilled } = this.state
    formFilled.newmodel = e
    console.log(e, "ee");
    this.setState({ newmodel: e, formFilled })
    // if (e.label === "OTHERS") {
    //   this.setState({ newmodel: e, others: true })
    // } else this.setState({ newmodel: e, others: false });

  };

  handleDescription1 = async e => {
    let { formFilled } = this.state
    formFilled.make = e
    this.setState({ make: e, newmake: [], formFilled });
    let modeldata = []
    let Model = await cmsContent.getFreedom(
      `Model as label , id as value,syear,eyear`,
      `tbl_dfcarsmodel`,
      `makeid=${e.value}`
    );
    if (Model.data.length) {
      Model.data.map((ival) => {
        if (ival.syear && ival.eyear) {
          ival.label = ival.label + '(' + ival.syear + '-' + ival.eyear + ')'
          modeldata.push(ival)
        } else {
          modeldata.push(ival)
        }
      })
    }
    if (modeldata.length) {
      if (this.state.makeyearSelected) {
        let ModelData = [];
        const manfuc = this.state.makeyearSelected.label;
        ModelData = modeldata.filter(e => e.syear <= manfuc && e.eyear >= manfuc)
        this.setState({ ModelData });
        // else this.setState({ ModelData: modeldata });

      } else {
        this.setState({ ModelData: modeldata });
      }
    } else {
      this.setState({ ModelData: [] });
    }
  };

  handleModel = async e => {
    let { formFilled } = this.state
    formFilled.model = e
    this.setState({ model: e, modelPresent: true, formFilled });
    let variant = await cmsContent.getFreedom(
      `Variant as label , id as value`,
      "tbl_variant_master",
      `modelid=${e.value}`
    );
    if (variant) {
      console.log("variant", variant);
      this.setState({ variantopt: variant.data });
    }
    // let variantopt = [];
    // if (this.state.variant) {
    //   this.state.ModelData && this.state.ModelData.map((ival, i) => {
    //     this.state.variant && this.state.variant.map((jval, k) => {
    //       console.log("jvallll",jval);
    //       if (ival.value == jval.modelid) {
    //         variantopt.push({ label: jval.variant, value: jval.id });
    //       }
    //     });
    //   });
    // }
    // this.setState({ variantopt });
  };
  handlevariant = async e => {
    let { formFilled } = this.state
    formFilled.variant = e
    this.setState({ variant: e, formFilled });
  };
  handleColour = async e => {
    let { formFilled } = this.state
    formFilled.colour = e
    this.setState({ colour: e, formFilled });
  };

  handlesources = async e => {
    let { formFilled } = this.state
    formFilled.sources = e
    this.setState({ sources: e, formFilled });
  };
  handlestatus1 = async e => {
    let { formFilled } = this.state
    formFilled.status1 = e
    this.setState({ status1: e, status2: {}, formFilled });
    if (e.label == "Scheduled Follow up") {
      this.setState({
        status2visible: true, status2options: [
          { label: "Under Follow up", value: 1 },
          { label: " Ringing no response", value: 2 },
          { label: "Researching", value: 3 },
          { label: "  Not attempted", value: 4 }
        ], status3options: [{ label: "Negotitation", value: 0 }, { label: " consideration", value: 1 }], status3visible: true
      })
    } else if (e.label == "Scheduled Evaluation") {
      this.setState({
        status2visible: false, status2options: [{ label: "Appointment taken", value: 1 }], status2: { label: "Appointment taken", value: 1 },
        status3visible: false
      })
    } else if (e.label == "Mark Closed") {
      this.setState({
        status2visible: true, status2options: [{ label: " Plan dropped", value: 0 },
        { label: "Non contactable", value: 1 },
        { label: "Lost", value: 2 },
        { label: "Wrong capture", value: 3 },
        { label: "No response", value: 4 },
        { label: "Huge Price Gap", value: 5 },
        ], status3visible: false
      })
    } else if (e.label == "Evaluated") {
      this.setState({
        status2visible: true, status2options: [{ label: " Trade in", value: 0 },
        { label: "Follow-up", value: 1 },
        { label: "Researching", value: 2 },
        { label: "Postponned", value: 3 },
        { label: "Dropped", value: 4 },
        { label: "Not attempted", value: 5 },
        { label: "Non contactable", value: 6 },
        { label: "Price not offered", value: 7 },
        { label: "Huge Price gap", value: 8 },
        { label: "Lost", value: 9 },
        ], status3visible: false
      })
    }
    // else{this.setState({status2visible:false,status3visible:false})}
  };

  handlestatus2 = async e => {
    let { formFilled } = this.state
    formFilled.status2 = e
    this.setState({ status2: e, formFilled });
    if (e.label == "Lost") {
      this.setState({
        status3visible: true, status3options: [{ label: " Co dealer", value: 0 },
        { label: "Competition", value: 1 },
        { label: "End user", value: 2 },
        { label: "Broker", value: 3 },
        { label: "Not known", value: 4 }
        ]
      })
    } else if (e.label == "Non contactable") {
      this.setState({
        status3visible: true, status3options: [{ label: "RNR-3", value: 0 },
        { label: "Wrong number", value: 1 },
        ]
      })
    } else {
      // this.setState({status3visible:false})
    }
  };
  handlestatus3 = async e => {
    this.setState({ status3: e });
  };
  handlesales = async e => {
    let { formFilled } = this.state
    formFilled.salesname = e
    this.setState({ salesname: e });
  };
  handleowner = e => {
    let { formFilled } = this.state
    formFilled.ownertype = e
    this.setState({ ownertype: e, formFilled });
  };
  makeyearSelect = e => {
    let { ModelData, make, MakeData, formFilled } = this.state;
    formFilled.makeyearSelected = e
    this.setState({ makeyearSelected: e, formFilled });
    console.log(this.state.model, 'model')
    if (this.state.model) {
      console.log(make, 'make')
      // ModelData = ModelData.filter(y => y.syear <= e.label && y.eyear >= e.label)
      if (make) this.handleDescription1(make)
      this.setState({ model: [], make: [], variant: [], colour: [], Makeopt: MakeData })
      // else this.setState({ model: [] })
    }
  };

  onChangeAuto = e => {
    const { suggestion, name } = this.props;
    const userInput = e.currentTarget.value;
    //console.log(suggestion);
    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = suggestion.filter(
      suggestion =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    // Update the user input and filtered suggestions, reset the active
    // suggestion and make sure the suggestions are shown
    if (suggestion)
      this.setState({
        activeSuggestion: 0,
        filteredSuggestions,
        showSuggestions: true,
        userInput: e.currentTarget.value,
        [e.currentTarget.name]: e.currentTarget.value
      });
    // return userInput;
  };
  nextWindow = d => {
    // console.log(d);

    const { CustomerList } = this.state;
    ////console.log(CustomerList);
    let CustomerPresent = false;
    let CustomerPresent1 = false;
    CustomerList.map((ival, i) => {
      if (ival.customer == d) {
        // this.setState({
        CustomerPresent = true;
        this.setState({ email: ival.email, mobile: ival.mobile });
        //});
      } else if (ival.mobile == d) {
        CustomerPresent1 = true;
        this.setState({ email: ival.email, mobile: ival.mobile });
      }
      else if (!d || d == null) {
        this.setState({ email: "", mobile: "" });
      }
    });

    this.setState({ leadname: d, CustomerPresent, CustomerPresent1 });
  };
  nextWindow1 = async (d) => {
    //console.log(d);
    const { CustomerList } = this.state;
    // console.log(CustomerList);
    let CustomerPresent = false;
    let CustomerPresent1 = false;
    await Promise.all(CustomerList.map(async (ival, i) => {
      if (ival.customer == d) {
        // this.setState({
        CustomerPresent = true;
        this.setState({ email: ival.email, mobile: ival.mobile });
        //});
      } else if (ival.mobile == d) {
        let result = await CmsContent.GetUserHistory(ival.mobile);
        CustomerPresent1 = true;
        this.setState({ email: ival.email, mobile: ival.mobile, process_tbl: result });
      }
    }));

    this.setState({ mobile: d, CustomerPresent, CustomerPresent1 });
  };

  new2 = () => {
    const { make, model } = this.state
    console.log(make.value, model.value, "model");
    localStorage.setItem("makeid", make.value);
    localStorage.setItem("modelid", model.value);
    window.open(`/DFCars/InventoryList/${make.value}`)
    //const  Inventory = new InventoryList();
    //Inventory.View(make,model);  
  };

  new1 = async () => {
    let a = JSON.stringify(this.state.mobile);
    let getid = await CmsContent.getFreedom(
      "*",
      "tbl_dfcarz_prosperities",
      `mobile=${a}`,
      1,
      1
    );
    ////console.log(a,getid);
    if (getid.data.length) {
      window.open(`/DFCars/TransactionHistory/${getid.data[0].id}`);
    }
  };
  new = async () => {
    let a = JSON.stringify(this.state.leadname);
    let getid = await CmsContent.getFreedom(
      "*",
      "tbl_dfcarz_prosperities",
      `name=${a}`,
      1,
      1
    );
    if (getid.data.length) {
      window.open(`/DFCars/TransactionHistory/${getid.data[0].id}`);
    }
  };
  handlecity = e => {
    this.setState({ cityselected: e });
  };
  handlestate = e => {
    let { formFilled, usertypelog, userid, salesopt_filtered } = this.state
    formFilled.stateselected = e
    this.setState({
      formFilled,
      stateselected: e
      // cityoptions: city
    });
    console.log("e", e);
    if (usertypelog.orderBy == 1) {
      console.log("1")
      salesopt_filtered = this.state.salesopt.filter(x => x.location == e.value)
    }
    console.log(salesopt_filtered, "salesopt_filtered");
    if (salesopt_filtered.length) {
      this.setState({ salesopt_filtered, salesname: "" });
    } else {
      this.setState({ salesopt_filtered: [], salesname: "" });
    }


  };
  single = () => {
    this.setState({ enquiry: true })
  }
  multi = () => {
    this.setState({ enquiry: false })
  }
  fileupload = e => {
    this.setState({ csv: e.target.files[0] })
  }
  submitcsv = async () => {
    try {
      await this.setState({ btnDisablecsv: true })
      const { usertypelog, status1, status2, status3 } = this.state;
      const files = this.state.csv;
      // var typelabel = usertypelog.orderBy == 9 ? "seller" : "buyer";
      const customerId = this.state.customerId;
      const ServiceId = 10;
      const formData = new FormData();
      formData.append("customerId", customerId);
      formData.append("ServiceId", ServiceId);
      // formData.append("type", typelabel)
      // formData.append('pendingStatus1', status1 && status1.label == "Scheduled Evaluation" ? 'Schedule walkin' : status1.label);
      // formData.append('pendingStatus2', status2 ? status2.label : null);
      // formData.append('pendingStatus3', null);
      // formData.append('pendingStatus1', "Schedule walkin");
      // formData.append('pendingStatus2', "Appointment taken");
      // formData.append('enquiry_Source', "csv");
      formData.append('enquiry_status', "active");
      if (usertypelog.orderBy == 9) {
        formData.append('evaluatorid', usertypelog.orderBy == 9 ? this.state.userid.id : null);
      }
      if (usertypelog.orderBy == 8) {
        formData.append('salesid', usertypelog.orderBy == 9 ? this.state.userid.id : null);
      }
      formData.append("file", files);
      let decr = await this.Decrypt(localStorage.getItem('Downline_Details'))
      if (decr) {
        // console.log('log',);
        decr = await JSON.parse(decr)
        // console.log(decr, "decr");
        if (decr && decr.Current_User) {
          formData.append('location', decr.Current_User[0].locationId)
          formData.append("downline_type", decr.Current_User[0].userType);
          formData.append("downline", decr.Current_User[0].downline);
          formData.append("companyid", decr.Current_User[0].id);
          formData.append("updatedby", decr.Current_User[0].id);
          formData.append("branch", decr.Current_User && decr.Current_User[0] && decr.Current_User[0].sub_loc_id ? decr.Current_User[0].sub_loc_id : null);
          // console.log(...formData);
        }
      }
      const result = await CmsContent.Multienquiry("tbl_dfcarz_enquiryform", formData);
      if (result.data) {
        await this.setState({ btnDisablecsv: false })
        console.log(result.data, "result.data");
        let error = false
        if (result && result.data && result.data.Errname.length > 0) error = true
        let resnameError = result.data.Errname.toString();
        let resname_Success = result.data.resname.toString();
        swal({
          className: "dfcarsEnquiryCSV",
          title: result && result.data.Message == "success" ? `CSV Successfully Added` : `${result.data.Message}`,
          icon: result && result.data.Message == "success" ? "success" : "error",
          button: {
            text: "Ok",
            closeModal: false,
          },
          text: error == false ? `Note       Enquiry Names : ${resname_Success} are Successfully Added. Totally  ${result.data.resname.length} Enquiries Added.` : `Note       Enquiry Names :${resnameError} Enquiries are having Issues. Please Check.`
        }).then(async (willDelete) => {
          window.location.reload();
        });
      } else {
        await this.setState({ btnDisablecsv: false })
        swal({
          title: `Something Went Wrong! Please try Again`,
          icon: "error",
          className: "dfcarsEnquiryCSV",
          button: {
            text: "Ok",
            closeModal: false,
          },
        }).then(async (willDelete) => {
          window.location.reload();
        });
      }
    } catch (error) {
      await this.setState({ btnDisablecsv: false })
      console.log(error);
    }

  };
  handleMobileInput = async (value, name) => {
    let { usertypelog, userid, formFilled } = this.state;
    value = value.slice(0, 10)
    await this.setState({ [name]: value });
    if (value.length > 9) {
      formFilled[name] = value
      await this.setState({ mobvalLoader: true, formFilled });

      let query = ''
      // if (![1, 2, 3].includes(usertypelog.orderBy)) {
      //   query = `and userid=${userid.id}`
      // }
      let { data: suggest } = await cmsContent.getFreedom('id as prosid,enquiry_id,userid,mobile', 'tbl_dfcarz_prosperities', `mobile='${value}'  ${query}`)
      if (suggest.length > 0) {
        await this.setState({ suggest })
      } else {
        await this.setState({ suggest: null })
        let { data: suggestOne } = await cmsContent.getFreedom('tbl_user_web.userName,tbl_dfcarz_enquiryform.Make as make,tbl_dfcarz_enquiryform.Model as model,tbl_dfcarz_enquiryform.type as EnqType,tbl_dfcarz_enquiryform.reg_no as reg_no,tbl_dfcarz_enquiryform.name as customerName,tbl_dfcarz_enquiryform.companyid as userid,tbl_dfcarz_enquiryform.mobile', 'tbl_dfcarz_enquiryform,tbl_user_web', `tbl_user_web.id=tbl_dfcarz_enquiryform.companyid and tbl_dfcarz_enquiryform.mobile='${value}'`, 'tbl_dfcarz_enquiryform.id', 'tbl_dfcarz_enquiryform.id desc')
        if (suggestOne.length > 0) {
          await this.setState({ suggestOne })
        } else this.setState({ suggestOne: null })
      }
      await this.setState({ mobvalLoader: false });
    } else this.setState({ suggest: null, suggestOne: null, mobvalLoader: false })

  }
  handleRegisterInput = async (value, name) => {
    let { usertypelog, userid, formFilled } = this.state;
    // value = value.slice(0, 10)
    await this.setState({ [name]: value });
    if (value.length) {
      formFilled[name] = value
      await this.setState({ regvalLoader: true, formFilled });

      let query = ''
      // if (![1, 2, 3].includes(usertypelog.orderBy)) {
      //   query = `and userid=${userid.id}`
      // }
      let { data: suggest1 } = await cmsContent.getFreedom('id as prosid,enquiry_id,userid,reg_no', 'tbl_dfcarz_prosperities', `reg_no='${value}'  ${query}`)
      if (suggest1.length > 0) {
        await this.setState({ suggest1 })
      } else {
        await this.setState({ suggest1: null })
        let { data: suggestOne1 } = await cmsContent.getFreedom('tbl_user_web.userName,tbl_dfcarz_enquiryform.Make as make,tbl_dfcarz_enquiryform.Model as model,tbl_dfcarz_enquiryform.type as EnqType,tbl_dfcarz_enquiryform.Reg_no as reg_no,tbl_dfcarz_enquiryform.name as customerName,tbl_dfcarz_enquiryform.companyid as userid,tbl_dfcarz_enquiryform.Reg_no', 'tbl_dfcarz_enquiryform,tbl_user_web', `tbl_user_web.id=tbl_dfcarz_enquiryform.companyid and tbl_dfcarz_enquiryform.Reg_no='${value}'`, 'tbl_dfcarz_enquiryform.id', 'tbl_dfcarz_enquiryform.id desc')
        if (suggestOne1.length > 0) {
          await this.setState({ suggestOne1 })
        } else this.setState({ suggestOne1: null })
      }
      await this.setState({ regvalLoader: false });
    } else this.setState({ suggest1: null, suggestOne1: null, regvalLoader: false })

  }
  handleUserInput = e => {
    let { formFilled } = this.state
    // console.log(e.target.name, e.target.value, "e.target.name, e.target.value ")
    if (e.target.name === 'email') {
      this.setState({ updateEmail: true });
    }
    if (e.target.name == "mobile") {
      e.target.value = e.target.value.slice(0, 10)
    }
    formFilled[e.target.name] = e.target.value;
    this.setState({ [e.target.name]: e.target.value, formFilled });
  };
  handleSelect = (e, name) => {
    let { formFilled } = this.state
    formFilled[name] = e
    this.setState({ [name]: e, formFilled })
  }
  render() {
    // console.log(this.state.formFilled, 'filled');
    const {
      process_tbl,
      alertVisible,
      textalert,
      CloseEnquiry,
      makeyearSelected,
      suggestion,
      suggestion1,
      leadname,
      stateselected,
      cityselected,
      LocationOpt,
      ModelData
    } = this.state;
    const body = process_tbl.map(e => (
      <tr><td>{e.process}</td>
        <td>{e.location}</td>
        <td>{e.evaluator}</td></tr>
    ))
    if (CloseEnquiry) {
      return (
        <React.Fragment>
          <main>
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h1>Close Enquiry</h1>
                    </div>
                    <div className="card-body">
                      <div className="row form-group">
                        <div className="col-sm-1" />
                        <div className="col-sm-3">
                          <label htmlFor="exampleInputEmail1">
                            Reason for Closing
                          </label>
                        </div>
                        <div className="col-sm-5">
                          <textarea
                            type="text"
                            className="form-control"
                            id="ColourName"
                            name="comments1"
                            placeholder="Enter Comments"
                            value={this.state.comments1}
                            onChange={this.handlechangeData}
                          />
                        </div>
                        <div className="col-sm-3" />
                      </div>
                      <div className="row form-group">
                        <div className="col-sm-2" />
                        <div className="col-sm-3" />
                        <div className="col-sm-5">
                          <button
                            type="button"
                            className={`btn btn-danger`}
                            disabled={this.state.disableValue}
                            onClick={() => this.CloseEnquiry()}
                          >
                            Close Enquiry
                          </button>
                        </div>
                        <div className="col-sm-2" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <main>
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h1>Enquiry Master</h1>
                    </div>
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item waves-effect waves-light">
                        <a class="nav-link" id="single" data-toggle="tab" href="#single" role="tab" aria-controls="home" aria-selected="false" onClick={() => this.single()}>Single Enquiry</a>
                      </li>
                      <li class="nav-item waves-effect waves-light">
                        <a class="nav-link" id="multi" data-toggle="tab" href="#multi" role="tab" aria-controls="profile" aria-selected="false" onClick={() => this.multi()}>Multi Enquiry</a>
                      </li>
                    </ul>
                    {this.state.enquiry ? (
                      <div className="card-body">
                        {/* <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-7">
                            <Alert
                              className="badge-content"
                              color={this.state.color}
                              isOpen={this.state.alertVisible}
                              toggle={this.onDismiss}
                            >
                              {this.state.textalert}
                            </Alert>
                          </div>
                          <div className="col-sm-3" />
                        </div> */}


                        {this.state.isEdit == false || true ? (
                          <React.Fragment>
                            {/* Seller type */}
                            <div className="row form-group">
                              <div className="col-sm-1" />
                              <div className="col-sm-3">
                                <label htmlFor="exampleInputEmail1" style={{ color: 'orange' }}>
                                  {this.state.usertypelog.orderBy == 9 || this.state.usertypelog.orderBy == 1
                                    ? "Seller Name*"
                                    : "Buyer Name*"}

                                </label>
                              </div>
                              <div className="col-sm-5">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="leadname"
                                  onChange={this.handleUserInput}
                                  disabled={this.state.isEdit}
                                  value={this.state.leadname}
                                />
                              </div>
                              {/* <div className="col-sm-5">
                                <Autocomplete
                                  name="leadname"
                                  type="text"
                                  suggestions={suggestion}
                                  value={this.state.leadname}
                                  nextWindow={this.nextWindow}
                                // onChange={this.onChangeAuto}
                                />
                              </div>
                              <div className="col-sm-3">
                                {leadname !== "" &&
                                  this.state.CustomerPresent == true ? (
                                  <div onClick={() => this.new()}>View</div>
                                ) : null}                         
                              </div> */}
                            </div>
                            <div className="row form-group">
                              <div className="col-sm-4" />
                              <span className="error-show ">
                                {this.state.errorleadname}
                              </span>
                            </div>

                            {/* Mobile number */}
                            <div className="row form-group">
                              <div className="col-sm-1" />
                              <div className="col-sm-3">
                                <label htmlFor="exampleInputEmail1" style={{ color: 'orange' }}>
                                  Mobile Number*
                                </label>
                              </div>
                              <div className="col-sm-5">
                                <input
                                  type="number"
                                  className="form-control"
                                  name="mobile"
                                  onChange={(e) => this.handleMobileInput(e.target.value, 'mobile')}
                                  disabled={this.state.isEdit}
                                  value={this.state.mobile}
                                />
                                {
                                  this.state.mobvalLoader
                                    ?
                                    "Checking. Please Wait"
                                    :
                                    this.state.suggest
                                      ?
                                      <Link to={{
                                        pathname: "/DFCars/CustomerHistory",
                                        state: { suggest: this.state.suggest, type: 'seller' }
                                      }}
                                        onClick={() => {
                                          window.$('#status').modal('hide')
                                          let filled = JSON.stringify(this.state.formFilled)
                                          localStorage.setItem('filled', filled)
                                        }}
                                      >Customer already exists, view more</Link>
                                      // <span onClick={() => window.open(`/DFCars/CustomerHistory?userid=${this.state.suggest[0].userid}`)}>Customer already exixts, view more</span>
                                      :
                                      this.state.suggestOne
                                        ?
                                        <Link to={{
                                          pathname: "/DFCars/CustomerHistory",
                                          state: { suggestOne: this.state.suggestOne, suggest: null, type: 'seller' }
                                        }}
                                          onClick={() => window.$('#status').modal('hide')}
                                        >Customer already exists, view more</Link>
                                        :
                                        this.state.mobile && this.state.mobile.length == 10
                                          ?
                                          "It's a new customer"
                                          :
                                          ''
                                }
                              </div>
                              {/* <div className="col-sm-5">
                                <Autocomplete
                                  name="mobile"
                                  suggestions={suggestion1}
                                  value={this.state.mobile}
                                  nextWindow={this.nextWindow1}
                                  onChange={this.handlechangeData}
                                // onChange={this.onChangeAuto}
                                />
                              </div>
                              <div className="col-sm-3" >
                                {this.state.mobile !== "" &&
                                  this.state.CustomerPresent1 == true ? (
                                  <a onClick={() => this.new1()}>View</a>
                                ) : null}                             
                              </div> */}
                              {/* {this.state.mobile !== "" &&
                                this.state.CustomerPresent1 == true ?
                                (<table class="table">
                                  <thead>
                                    <tr>
                                      <th scope="col">Process</th>
                                      <th scope="col">Location</th>
                                      <th scope="col">Evaluator Name</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {body}

                                  </tbody>
                                </table>) : null} */}
                            </div>
                            <div className="row form-group">
                              <div className="col-sm-4" />
                              <span className="error-show ">
                                {this.state.errormobile}
                              </span>
                            </div>


                            {/* Email */}
                            <div className="row form-group">
                              <div className="col-sm-1" />
                              <div className="col-sm-3">
                                <label htmlFor="exampleInputEmail1">
                                  Email-Id
                                </label>
                              </div>
                              <div className="col-sm-5">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="ColourName"
                                  name="email"
                                  placeholder="Enter Email-Address"
                                  value={this.state.email}
                                  onChange={this.handlechangeData} //
                                  onBlur={(e) => { this.emailval(e.target.value) }}
                                />
                              </div>
                              <div className="col-sm-3" />
                            </div>
                            <div className="row form-group">
                              <div className="col-sm-4" />
                              <span className="error-show ">
                                {this.state.emailmessage}
                              </span>
                            </div>
                            {/* <div className="row form-group">
                            <div className="col-sm-1" />
                            <div className="col-sm-3">
                              <label htmlFor="exampleInputEmail1">Type</label>
                            </div>
                            <div className="col-sm-5">
                              <SingleSelect
                                name="TaxList"
                                options={this.state.Typelist}
                                handleChange={d => this.handleDescription(d)}
                                selectedService={this.state.type}
                              />
                            </div>
                          </div>{" "} */}
                          </React.Fragment>
                        ) : null}
                        {/* Make */}
                        {this.state.usertypelog.orderBy == 8 && (
                          <div>
                            <div className="row form-group">
                              <div className="col-sm-1" />
                              <div className="col-sm-3">
                                <label htmlFor="exampleInputEmail1">
                                  Preferred Make
                                </label>
                              </div>
                              <div className="col-sm-5">
                                <SingleSelect
                                  name="make"
                                  placeholder="Select..."
                                  options={this.state.MakeData}
                                  handleChange={d => this.handleDescription1(d)}
                                  selectedService={this.state.make}
                                />
                              </div>
                              <div className="col-sm-3" />
                            </div>

                            <div className="row form-group">
                              <div className="col-sm-1" />
                              <div className="col-sm-3">
                                <label htmlFor="exampleInputEmail1">
                                  Preferred Model
                                </label>
                              </div>
                              <div className="col-sm-5">
                                <SingleSelect
                                  name="model"
                                  placeholder="Select..."
                                  options={this.state.ModelData}
                                  handleChange={d => this.handleModel(d)}
                                  selectedService={this.state.model}
                                />
                              </div>
                              <div className="col-sm-3" />
                            </div>
                            <div className="row form-group">
                              <div className="col-sm-1" />
                              <div className="col-sm-3">
                                <label htmlFor="exampleInputEmail1">
                                  Preferred Variant
                                </label>
                              </div>
                              <div className="col-sm-5">
                                <SingleSelect
                                  name="model"
                                  placeholder="Select..."
                                  options={this.state.variantopt}
                                  handleChange={d => this.handlevariant(d)}
                                  selectedService={this.state.variant}
                                />
                              </div>
                              <div className="col-sm-3" />
                            </div>

                            <div className="row form-group">
                              <div className="col-sm-1" />
                              <div className="col-sm-3">
                                <label htmlFor="exampleInputEmail1">
                                  Preferred Colour
                                </label>
                              </div>
                              <div className="col-sm-5">
                                <SingleSelect
                                  name="model"
                                  placeholder="Select..."
                                  options={this.state.ColourList}
                                  handleChange={d => this.handleColour(d)}
                                  selectedService={this.state.colour}
                                />
                              </div>
                              <div className="col-sm-3" />
                            </div>

                            <div className="row form-group">
                              <div className="col-sm-1" />
                              <div className="col-sm-3">
                                <label htmlFor="exampleInputEmail1">
                                  Preferred K.M. Travelled
                                </label>
                              </div>
                              <div className="col-sm-5">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="registerName"
                                  name="Km"
                                  placeholder="Km Travelled"
                                  value={this.state.Km}
                                  onChange={this.handlechangeData}
                                />
                              </div>
                              <div className="col-sm-3" />
                            </div>
                            <div className="row form-group">
                              <div className="col-sm-1" />
                              <div className="col-sm-3">
                                <label htmlFor="exampleInputEmail1">
                                  Preferred Year of Manufacture
                                </label>
                              </div>
                              <div className="col-sm-5">
                                <SingleSelect
                                  placeholder="Select..."
                                  options={this.state.makeYearOption}
                                  handleChange={e => this.makeyearSelect(e)}
                                  selectedService={makeyearSelected}
                                />
                                {/* <input
                          type="text"
                          className="form-control"
                          id="registerName"
                          name="year"
                          placeholder="Year manufacutred"
                          value={this.state.year}
                          onChange={this.handlechangeData}
                        /> */}
                              </div>
                              <div className="col-sm-3" />
                            </div>
                          </div>

                        )}
                        {/* Register Number       */}
                        <div className="row form-group">
                          <div className="col-sm-1" />
                          <div className="col-sm-3">
                            <label htmlFor="exampleInputEmail1" style={{ color: 'orange' }}>
                              Register Number*
                            </label>
                          </div>
                          <div className="col-sm-5">
                            <input
                              type="text"
                              className="form-control"
                              id="registerName"
                              name="Register"
                              placeholder="Enter Register Number"
                              value={this.state.Register}
                              // onChange={this.handlechangeData}
                              onChange={(e) => this.handleRegisterInput(e.target.value, 'Register')}
                            />
                            {
                              this.state.regvalLoader
                                ?
                                "Checking. Please Wait"
                                :
                                this.state.suggest1
                                  ?
                                  <Link to={{
                                    pathname: "/DFCars/CustomerHistory",
                                    state: { suggest1: this.state.suggest1, type: 'seller' }
                                  }}
                                    onClick={() => {
                                      window.$('#status').modal('hide')
                                      let filled = JSON.stringify(this.state.formFilled)
                                      localStorage.setItem('filled', filled)
                                    }}
                                  >Register number already exists.</Link>
                                  :
                                  this.state.suggestOne1
                                    ?
                                    <Link to={{
                                      pathname: "/DFCars/CustomerHistory",
                                      state: { suggestOne1: this.state.suggestOne1, suggest1: null, type: 'seller' }
                                    }}
                                      onClick={() => window.$('#status').modal('hide')}
                                    >Register number already exists.</Link>
                                    :
                                    // this.state.Register && this.state.Register.length
                                    //   ?
                                    //   "It's a new customer"
                                    //   :
                                    ' '
                            }
                          </div>
                          <div className="col-sm-3" />
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-4" />
                          <span className="error-show ">
                            {this.state.errorregno}
                          </span>
                        </div>
                        {/* {this.state.usertypelog.orderBy == 9 && ( */}
                        <div>

                          {/* <div className="row form-group">
                            <div className="col-sm-1" />
                            <div className="col-sm-3">
                              <label htmlFor="exampleInputEmail1">
                                {this.state.usertypelog.orderBy == 9
                                  ? "Seller Name"
                                  : "Buyer Name"}
                              </label>
                            </div>
                            <div className="col-sm-5">
                              <Autocomplete
                                name="leadname"
                                suggestions={suggestion}
                                value={this.state.leadname}
                                nextWindow={this.nextWindow}
                                // onChange={this.onChangeAuto}
                              />
                          </div>
                            <div className="col-sm-3">
                              {leadname !== "" &&
                              this.state.CustomerPresent == true ? (
                                <div onClick={() => this.new()}>View</div>
                              ) : null}
                             </div>
                          </div> */}
                          {this.state.usertypelog.orderBy == 1 && <div className="row form-group">
                            <div className="col-sm-1" />
                            <div className="col-sm-3">
                              <label htmlFor="exampleInputEmail1" style={{ color: 'orange' }}>Location*</label>
                            </div>
                            <div className="col-sm-5">
                              <SingleSelect
                                placeholder="Select..."
                                options={LocationOpt}
                                handleChange={e => this.handlestate(e)}
                                selectedService={this.state.stateselected}
                              />
                            </div>
                            <div className="col-sm-3" />
                          </div>}
                          <div className="row form-group">
                            <div className="col-sm-1" />
                            <div className="col-sm-3">
                              <label htmlFor="exampleInputEmail1">
                                ownertype
                              </label>
                            </div>
                            <div className="col-sm-5">
                              <SingleSelect
                                name="owner" placeholder="Select..."
                                options={this.state.ownershipOpt}
                                handleChange={d => this.handleowner(d)}
                                selectedService={this.state.ownertype}
                              />
                            </div>
                            <div className="col-sm-3" />
                          </div>
                          <div className="row form-group">
                            <div className="col-sm-1" />
                            <div className="col-sm-3">
                              <label htmlFor="exampleInputEmail1">
                                Year of Manufacture
                              </label>
                            </div>
                            <div className="col-sm-5">
                              <SingleSelect
                                placeholder="Select..."
                                options={this.state.makeYearOption}
                                handleChange={e => this.makeyearSelect(e)}
                                selectedService={makeyearSelected}
                                isSearchable={false}
                              />

                            </div>
                            <div className="col-sm-3" />
                          </div>

                          <div className="row form-group">
                            <div className="col-sm-1" />
                            <div className="col-sm-3">
                              <label htmlFor="exampleInputEmail1">Make</label>
                            </div>
                            <div className="col-sm-5">
                              <SingleSelect
                                name="make"
                                placeholder="Select..."
                                options={this.state.Makeopt}
                                handleChange={d => this.handleDescription1(d)}
                                selectedService={this.state.make}
                              />
                            </div>
                            <div className="col-sm-3" />
                          </div>

                          <div className="row form-group">
                            <div className="col-sm-1" />
                            <div className="col-sm-3">
                              <label htmlFor="exampleInputEmail1">Model</label>
                            </div>
                            <div className="col-sm-5">
                              <SingleSelect
                                name="model"
                                placeholder="Select..."
                                options={makeyearSelected && ModelData ? ModelData.filter(y => y.syear <= makeyearSelected.label && y.eyear >= makeyearSelected.label) : this.state.ModelData}
                                handleChange={d => this.handleModel(d)}
                                selectedService={this.state.model}
                              />
                            </div>
                            {/* <div className="col-sm-3" >
                              {this.state.model !== "" &&
                                  this.state.modelPresent == true ? (
                                    <a onClick={() => this.new2()}>View</a>
                                  ) : null}
                                </div> */}
                          </div>
                          <div className="row form-group">
                            <div className="col-sm-1" />
                            <div className="col-sm-3">
                              <label htmlFor="exampleInputEmail1">
                                Variant
                              </label>
                            </div>
                            <div className="col-sm-5">
                              <SingleSelect
                                name="Variant"
                                placeholder="Select..."
                                options={this.state.variantopt}
                                handleChange={d => this.handlevariant(d)}
                                selectedService={this.state.variant}
                              />
                            </div>
                            <div className="col-sm-3" />
                          </div>

                          <div className="row form-group">
                            <div className="col-sm-1" />
                            <div className="col-sm-3">
                              <label htmlFor="exampleInputEmail1">Colour</label>
                            </div>
                            <div className="col-sm-5">
                              <SingleSelect
                                name="color"
                                placeholder="Select..."
                                options={this.state.ColourList}
                                handleChange={d => this.handleColour(d)}
                                selectedService={this.state.colour}
                              />
                            </div>
                            <div className="col-sm-3" />
                          </div>

                          <div className="row form-group">
                            <div className="col-sm-1" />
                            <div className="col-sm-3">
                              <label htmlFor="exampleInputEmail1">Fuel Type</label>
                            </div>
                            <div className="col-sm-5">
                              <SingleSelect
                                name="Fuel type"
                                placeholder="Select..."
                                options={this.state.fueloptions}
                                handleChange={d => this.handleSelect(d, 'fuel')}
                                selectedService={this.state.fuel}
                              />
                            </div>
                            <div className="col-sm-3" />
                          </div>

                          <div className="row form-group">
                            <div className="col-sm-1" />
                            <div className="col-sm-3">
                              <label htmlFor="exampleInputEmail1">
                                Insurance Expiry Date
                              </label>
                            </div>
                            <div className="col-sm-5">
                              <input
                                type="date"
                                className="form-control"
                                id="ColourName"
                                name="Expirydate"
                                min={moment().format('YYYY-MM-DD')}
                                placeholder="Enter price amount"
                                value={this.state.Expirydate}
                                onChange={this.handlechangeData}
                              />
                            </div>
                            <div className="col-sm-3" />
                          </div>
                          <div className="row form-group">
                            <div className="col-sm-1" />
                            <div className="col-sm-3">
                              <label htmlFor="exampleInputEmail1">
                                K.M. Travelled
                              </label>
                            </div>
                            <div className="col-sm-5">
                              <input
                                type="number"
                                className="form-control"
                                id="registerName"
                                name="Km"
                                placeholder="Km Travelled"
                                value={this.state.Km}
                                onChange={this.handlechangeData}
                              />
                            </div>
                            <div className="col-sm-3" />
                          </div>

                          <div className="row form-group">
                            <div className="col-sm-1" />
                            <div className="col-sm-3">
                              <label htmlFor="exampleInputEmail1" style={{ color: 'orange' }}>Sources</label>
                            </div>
                            <div className="col-sm-5">
                              <SingleSelect
                                name="model"
                                placeholder="Select..."
                                options={this.state.SourcingList}
                                handleChange={d => this.handlesources(d)}
                                selectedService={this.state.sources}
                              />
                            </div>

                            <div className="col-sm-3" />

                          </div>
                          <div className="row form-group">
                            <div className="col-sm-4" />
                            <span className="error-show ">
                              {this.state.errorsources}
                            </span>
                          </div>
                          {/* Status 1 */}
                          <div className="row form-group">
                            <div className="col-sm-1" />
                            <div className="col-sm-3">
                              <label htmlFor="exampleInputEmail1" style={{ color: 'orange' }}>Status 1*</label>
                            </div>
                            <div className="col-sm-5">
                              <SingleSelect
                                placeholder="Select..."
                                options={this.state.status1options}

                                handleChange={d => this.handlestatus1(d)}
                                selectedService={this.state.status1}
                              />
                            </div>
                            <div className="col-sm-3" />
                          </div>
                          <div className="row form-group">
                            <div className="col-sm-4" />
                            <span className="error-show ">
                              {this.state.errorstatus1}
                            </span>
                          </div>

                          {/* status2options */}
                          {
                            this.state.status2visible == true ? (
                              <div>
                                <div className="row form-group">
                                  <div className="col-sm-1" />
                                  <div className="col-sm-3">
                                    <label htmlFor="exampleInputEmail1">Status 2</label>
                                  </div>
                                  <div className="col-sm-5">
                                    <SingleSelect
                                      placeholder="Select..."

                                      options={this.state.status2options}
                                      handleChange={d => this.handlestatus2(d)}
                                      selectedService={this.state.status2}
                                    />
                                  </div>
                                  <div className="col-sm-3" />
                                </div>

                              </div>
                            ) : null
                          }
                          {/* status3options */}
                          {/* {
                              this.state.status3visible == true ? (
                                <div>
                                  <div className="row form-group">
                                    <div className="col-sm-1" />
                                    <div className="col-sm-3">
                                      <label htmlFor="exampleInputEmail1">Status 3</label>
                                    </div>
                                    <div className="col-sm-5">
                                      <SingleSelect
                                        placeholder="Select..."
                                        options={this.state.status3options}
                                        handleChange={d => this.handlestatus3(d)}
                                        selectedService={this.state.status3}
                                      />
                                    </div>
                                    <div className="col-sm-3" />
                                  </div>

                                </div>
                              ) : null
                            } */}
                          {/* Next-Followup */}

                          {this.state.status1.label !== "Mark Closed" ?
                            (<div><div className="row form-group">
                              <div className="col-sm-1" />
                              <div className="col-sm-3">
                                <label htmlFor="exampleInputEmail1" style={{ color: 'orange' }}>
                                  Next-Followup*
                                </label>
                              </div>
                              <div className="col-sm-5">
                                <input
                                  type="date"
                                  className="form-control"
                                  id="ColourName"
                                  name="Next_Contact_date"
                                  placeholder="Enter price amount"
                                  min={moment().format('YYYY-MM-DD')}
                                  value={this.state.Next_Contact_date}
                                  onChange={this.handlechangeData}
                                />
                              </div>
                              <div className="col-sm-3" />
                            </div>
                              <div className="row form-group">
                                <div className="col-sm-4" />
                                <span className="error-show ">
                                  {this.state.errorNext_Contact_date}
                                </span>
                              </div></div>) : null}

                          {/* new car make */}

                          <div className="row form-group">
                            <div className="col-sm-1" />
                            <div className="col-sm-3">
                              <label htmlFor="exampleInputEmail1">New Make</label>
                            </div>
                            <div className="col-sm-5">
                              <SingleSelect
                                name="make"
                                placeholder="Select..."
                                options={this.state.NewMakeData}
                                handleChange={d => this.NewMakeChanger(d)}
                                selectedService={this.state.newmake}
                              />
                            </div>
                            <div className="col-sm-3" />
                          </div>
                          {/* New Car model */}
                          {this.state.others === true ?
                            (<div className="row form-group">
                              <div className="col-sm-1" />
                              <div className="col-sm-3">
                                <label htmlFor="exampleInputEmail1"> Other Make</label>
                              </div>
                              <div className="col-sm-5">

                                {<input
                                  type="text"
                                  className="form-control"
                                  // id="registerName"
                                  name="otherModel"
                                  placeholder="Other Make"
                                  value={this.state.otherModel}
                                  onChange={this.handlechangeData}
                                />}
                              </div>
                            </div>) :

                            <div className="row form-group">
                              <div className="col-sm-1" />
                              <div className="col-sm-3">
                                <label htmlFor="exampleInputEmail1"> New Car Model</label>
                              </div>
                              <div className="col-sm-5">
                                {<SingleSelect

                                  options={this.state.NewModelData}
                                  handleChange={d => this.handleModel1(d)}
                                  selectedService={this.state.newmodel}
                                />}
                                {/* <input
                                  type="text"
                                  className="form-control"
                                 // id="registerName"
                                  name="newcarmodel"
                                  placeholder="New car model"
                                  value={this.state.newcarmodel}
                                  onChange={this.handlechangeData}
                                /> */}
                              </div>
                            </div>

                          }
                        </div>
                        {/* )} */}
                        <div className="row form-group">
                          <div className="col-sm-1" />
                          <div className="col-sm-3">
                            <label htmlFor="exampleInputEmail1">SALES CONSULTANT NAME</label>
                          </div>
                          <div className="col-sm-5">
                            <SingleSelect
                              placeholder="Select..."
                              options={this.state.salesopt_filtered}
                              // options={this.state.newmake ? this.state.salesopt.filter(x => x.brandid == this.state.newmake.value) : this.state.salesopt}
                              handleChange={d => this.handlesales(d)}
                              selectedService={this.state.salesname}
                            // disabled={this.state.newmake ? false : true}
                            />
                          </div>
                          <div className="col-sm-3" />
                        </div>

                        <div className="row form-group">
                          <div className="col-sm-4" />
                          <span className="error-show ">
                            {this.state.errorlocation}
                          </span>
                        </div>


                        {/* <div className="row form-group">
                        <div className="col-sm-1" />
                        <div className="col-sm-3">
                          <label htmlFor="exampleInputEmail1">City</label>
                        </div>
                        <div className="col-sm-5">
                          <SingleSelect
                            options={this.state.cityoptions}
                            handleChange={e => this.handlecity(e)}
                            selectedService={cityselected}
                          />
                        </div>
                        <div className="col-sm-3" />
                      </div> */}
                        {/* <div className="row form-group">
                      <div className="col-sm-1" />
                      <div className="col-sm-3">
                        <label htmlFor="exampleInputEmail1">Sources</label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type="text"
                          className="form-control"
                          id="ColourName"
                          name="sources"
                          placeholder="Enter sources"
                          value={this.state.sources}
                          onChange={this.handlechangeData}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div> */}

                        {/* <div className="row form-group">
                          <div className="col-sm-1" />
                          <div className="col-sm-3">
                            <label htmlFor="exampleInputEmail1">Budget</label>
                          </div>
                          <div className="col-sm-5">
                            <input
                              type="text"
                              className="form-control"
                              id="ColourName"
                              name="Budget"
                              placeholder="Enter price amount"
                              value={this.state.Budget}
                              onChange={this.handlechangeData}
                            />
                          </div>
                          <div className="col-sm-3" />
                        </div> */}

                        <div className="row form-group">
                          {/* <div className="col-sm-1" />
                          <div className="col-sm-3">
                            <label htmlFor="exampleInputEmail1">Status</label>
                          </div>
                          <div className="col-sm-5">
                            <SingleSelect
                              name="status"
                              placeholder="Select..."
                              options={this.state.statusoption}
                              handleChange={d => this.handlestatus(d)}
                              selectedService={this.state.status}
                            /> */}
                          {/* <input
                            type="text"
                            className="form-control"
                            id="ColourName"
                            name="status"
                            placeholder="Enter status"
                            value={this.state.status}
                            onChange={this.handlechangeData}
                          /> */}
                          {/* </div> */}
                          <div className="col-sm-3" />
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-4" />
                          <span className="error-show ">
                            {this.state.errorstatus}
                          </span>
                        </div>

                        {/* {this.state.isEdit ? (
                          <React.Fragment>
                            <div className="row form-group">
                              <div className="col-sm-1" />
                              <div className="col-sm-3">
                                <label htmlFor="exampleInputEmail1">
                                  Comments
                                </label>
                              </div>
                              <div className="col-sm-5">
                                <textarea
                                  type="text"
                                  className="form-control"
                                  id="ColourName"
                                  name="comments"
                                  placeholder="Enter Comments"
                                  value={this.state.comments}
                                  onChange={this.handlechangeData}
                                />
                              </div>
                              <div className="col-sm-3" />
                            </div>
                            <div className="row form-group">
                              <div className="col-sm-4" />
                              <span className="error-show ">
                                {this.state.errorcommet}
                              </span>
                            </div>
                          </React.Fragment>
                        ) : null} */}

                        {[1, 2, 3].includes(this.state.usertypelog.orderBy) ? <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2" />
                          <div className="col-sm-5">
                            <button
                              type="button"
                              className={`btn btn-${this.state.isEdit ? "secondary" : "primary"
                                }`}
                              disabled={this.state.btnDisable}
                              onClick={
                                this.state.isEdit
                                  ? this.UpdateCategory
                                  : this.submitCategory
                              }
                            >
                              {this.state.btnDisable ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;
                              {this.state.isEdit
                                ? "Update Enquiry"
                                : "Add New Enquiry"}
                            </button>
                          </div>
                          <div className="col-sm-2" />&nbsp;&nbsp;
                        </div> : !this.state.isEdit ? <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2" />
                          <div className="col-sm-5">
                            <button
                              type="button"
                              className={`btn btn-${this.state.isEdit ? "secondary" : "primary"
                                }`}
                              disabled={this.state.btnDisable}
                              onClick={this.submitCategory}
                            >
                              {this.state.btnDisable ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;
                              Add New Enquiry
                            </button>
                          </div>
                          <div className="col-sm-2" />&nbsp;&nbsp;
                        </div> : null}
                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-7">
                            <Alert
                              className="badge-content"
                              color={this.state.color}
                              isOpen={this.state.alertVisible}
                              toggle={this.onDismiss}
                            >
                              {this.state.textalert}
                            </Alert>
                          </div>
                          <div className="col-sm-3" />
                        </div>
                      </div>
                    ) : (
                      <div className="card-body">
                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-7">
                            <Alert
                              className="badge-content"
                              color={this.state.color}
                              isOpen={alertVisible}
                              toggle={this.onDismiss}
                            >
                              {textalert}
                            </Alert>
                          </div>
                          <div className="col-sm-3" />
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-1" />
                          <div className="col-sm-3">
                            <label htmlFor="exampleInputEmail1">
                              Upload Files:
                            </label>
                          </div>
                          <div className="col-sm-5 custom-file">
                            <input
                              type="file"
                              className="custom-file-input"
                              id="customFile"
                              accept='.csv'
                              // value={this.state.csv}
                              onChange={this.fileupload}
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="customFile"
                            >
                              {/* Choose File */}
                              {/* {this.state.fileName1 ? this.state.fileName1.split("/")[2] : null} */}
                              {this.state.csv ? this.state.csv.name : 'Choose File'}
                            </label>
                          </div>
                        </div>

                        {/* Status 1 */}
                        {/* <div className="row form-group">
                          <div className="col-sm-1" />
                          <div className="col-sm-3">
                            <label htmlFor="exampleInputEmail1" style={{ color: 'orange' }}>Status 1*</label>
                          </div>
                          <div className="col-sm-5">
                            <SingleSelect
                              placeholder="Select..."
                              options={this.state.status1options}

                              handleChange={d => this.handlestatus1(d)}
                              selectedService={this.state.status1}
                            />
                          </div>
                          <div className="col-sm-3" />
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-4" />
                          <span className="error-show ">
                            {this.state.errorstatus1}
                          </span>
                        </div>
                        <br /> */}
                        {/* status2options */}
                        {/* {
                          this.state.status2visible == true ? (
                            <div>
                              <div className="row form-group">
                                <div className="col-sm-1" />
                                <div className="col-sm-3">
                                  <label htmlFor="exampleInputEmail1">Status 2</label>
                                </div>
                                <div className="col-sm-5">
                                  <SingleSelect
                                    placeholder="Select..."

                                    options={this.state.status2options}
                                    handleChange={d => this.handlestatus2(d)}
                                    selectedService={this.state.status2}
                                  />
                                </div>
                                <div className="col-sm-3" />
                              </div>

                            </div>
                          ) : null
                        } */}
                        <br />

                        <div className="row-form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-3" />
                          <div className="col-sm-4">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => this.submitcsv(e)}
                              disabled={this.state.btnDisablecsv ? true : false}
                            // style={{ marginLeft: "250px" }}
                            >
                              {this.state.btnDisablecsv ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;
                              {this.state.btnDisablecsv ? "Please Wait.." : "Submit CSV"}
                            </button>
                          </div>
                        </div>
                        <br />
                        <div className="row form-group">
                          <div className="col-md-6" >
                            <table class="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">SI NO</th>
                                  <th scope="col">Source Names</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th scope="row">1</th>
                                  <td>Walkin Showroom</td>
                                </tr>
                                <tr>
                                  <th scope="row">2</th>
                                  <td>Walk in Event</td>
                                </tr>
                                <tr>
                                  <th scope="row">3</th>
                                  <td>Tele in</td>
                                </tr>
                                <tr>
                                  <th scope="row">4</th>
                                  <td>Tele Out / Sms</td>
                                </tr>
                                <tr>
                                  <th scope="row">5</th>
                                  <td>Web Leads</td>
                                </tr>
                                <tr>
                                  <th scope="row">6</th>
                                  <td>OLX</td>
                                </tr>
                                <tr>
                                  <th scope="row">7</th>
                                  <td>Car Trade</td>
                                </tr>
                                <tr>
                                  <th scope="row">8</th>
                                  <td>Car Dekho</td>
                                </tr>
                                <tr>
                                  <th scope="row">9</th>
                                  <td>Workshop</td>
                                </tr>
                                <tr>
                                  <th scope="row">10</th>
                                  <td>Other Portals</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-md-6">
                            <a href="../../files/EnquirySample.csv" download="Enquiry.csv">
                              download sample.csv
                            </a>
                            {/* {uploadPercentage > 0 && (
                                <Progress percentage={uploadPercentage} />
                              )} */}
                          </div>
                        </div>
                        <div className="row form-group">

                          <div className="col-md-12">
                            Note: Pick up one of the above on Source in CSV.
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </React.Fragment>
      );
    }
  }
}

export default DfCarsAddEnquiry;
