import React, { Component } from 'react'
import { Alert } from "reactstrap";
import { Datatable } from '../../components/Datatable';
import LoginModal from '../../components/Modal/Modal';
import { SingleSelect } from '../../components/Form';
import AddUser from '../Modal/AddCustomer';
import { FormMiddleWare } from '../../components/Form';
import UserData from '../../MiddleWare/User'
import CmsContent from '../../MiddleWare/CmsContent';
import { confirmAlert } from "react-confirm-alert";

// import UserD

class DemoAdduser extends FormMiddleWare {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            userName: "",
            loading: true,
            mobileNumber: "",
            UserPassword: '',
            email: "",
            formAlertdelete: false,
            errorUserName: "",
            errorMobileNumber: "",
            errorEmail: "",
            errorPassword: '',
            alertVisible: false,
            button: "Submit",
            updateData: false,
            updateEmail: true,
            uploadimg: [],
            logoname: "",
            textview: null,
            color: "success",
            customerId: localStorage.getItem("userId"),
            serviceId: localStorage.getItem("currentService"),
            usertype: [{ label: 'L4 user manager', value: 201 }, { label: 'L4 user clerk', value: 202 }],
            usertypeSelected: null,
            User_Type: ''


        }
    }
    async componentDidMount() {
        this.userlist();
        // this.setState({ loading: true })
    }
    userlist = async () => {

        let userlog = JSON.parse(localStorage.getItem("userlog"));;
        let User_Type = userlog.userType;
        let serviceEnabled = userlog.serviceEnabled;
        let usercount = userlog.usercount;
        let counting = [];
        serviceEnabled.split(",").map((item, i) => {
            if (this.state.serviceId == item) {
                counting.push(usercount.split(",")[i]);
            }
        });
        this.setState({ User_Type })

        const datas = await CmsContent.getFreedom(
            "*",
            "tbl_user_web",
            `customerId =${this.state.customerId} and serviceId =${this.state.serviceId}`,
            1,
            "id desc"
        );
        console.log(datas.data, "datas");
        if (datas && datas.data) {
            this.setState({ data: datas.data, loading: false });
        }
    }
    column = [

        {
            Header: "ID",
            accessor: "id"
        },
        {
            Header: "UserName",
            accessor: "userName",
            Cell: d => this.getvalue(d.original, 'Userview'),
            width: 270
        },
        {
            Header: "User Type",
            accessor: "userType",
            Cell: d =>
                this.getValueFromArray(d.original.userType, this.state.usertype)
        },
        {
            Header: "MobileNumber",
            accessor: "mobileNumber"
        },
        {
            Header: "Status",
            accessor: "status"
        },
        {
            Header: "Visibility",
            accessor: "edit",
            Cell: d => this.showVisibility(d)
        },
        {
            Header: "Edit",
            accessor: "edit",
            Cell: d => this.edit(d, 'customer')
        },
        {
            Header: 'Delete',
            accessor: 'delete',
            Cell: d => this.delete(d),
            width: 120
          }

    ];
    getvalue = (value, modalWindowId) => {

        return (
            <button type="button" className="btn btn-warning" style={{ width: '250px' }} >
                {value.userName}
            </button>
        );
    };
    edit = (value, modalWindowId) => {
        return (
            <center>
                <button type="button" data-toggle="modal" data-target={`#${modalWindowId}`} style={{ width: 80 }} className="btn btn-info"
                    onClick={() => this.buttonEdit(value)}
                >
                    Edit
                </button>
            </center>
        );
    };

    delete = value => {
        return (
            <center>
                <button type="button" className="btn btn-danger" style={{ width: 80 }} onClick={() => this.buttonDelete(value)}>
                    Delete
                </button>
            </center>
        );
    };
    onDismiss = () => {
        this.setState({ formAlertdelete: false });
    };
    uploadimg = e => {


        //this.setState({ FileName: e.target.files[0] });
        this.setState({ logoname: e.target.files[0].name });
        this.setState({ imgupload: e.target.files[0] });


    };
    usertypeSelect = async e => {
        this.setState({ usertypeSelected: e })
    }
    onSubmit = async () => {
        console.log("enterd");
        let userName = this.state.userName;
        let mobileNumber = this.state.mobileNumber;
        let email = this.state.email;
        let usertypeSelected = this.state.usertypeSelected;
        let serviceId = this.state.serviceId;
        let imgupload = this.state.imgupload;
        let customerId = this.state.customerId;

        if (!usertypeSelected) {
            this.setState({ errorType: "Select User Type" });
            return false;
        }
        const formData = new FormData();

        formData.append("serviceId", serviceId);
        formData.append("userName", userName);
        formData.append("mobileNumber", mobileNumber);
        formData.append("email", email);
        formData.append("status", "active");
        formData.append("userType", usertypeSelected.value);
        formData.append("customerId", customerId);
        if (imgupload && imgupload.size) {
            formData.append("image", imgupload);
        }
        formData.append("withoutmd5", this.state.UserPassword);
        console.log(...formData, "formData");

        try {
            const result = await UserData.adminuserInsert("tbl_user_web", formData);
            if (result) {
                this.setState({
                    alertVisible: true,
                    UserPassword: "",
                    usertypeSelected: "",
                    userName: "",
                    mobileNumber: "",
                    email: "",
                    errorUserName: "",
                    errorMobileNumber: "",
                    errorEmail: "",
                    errorPassword:'',
                    imgupload: "",
                    logoname: "",
                    updateData:false
                });
                this.userlist();
                setTimeout(() => this.setState({ alertVisible: false }), 2000);
            }
        } catch (error) {
            console.log(error);
        }

    };
    buttonEdit = (value) => {
        let values = value.original;
        console.log(values, "values form button edit");
        const index = value.index;
        const editid = value.original.id;
        const previousData = [...this.state.data];
        console.log(previousData, "previousData");
        const getData = { ...previousData[index] };
        console.log(getData, "getData");

        const { userName, mobileNumber, email, image, withoutmd5, serviceEnabled } = { ...previousData[index] };
        let usertypeSelected = {};
        if (values && values.userType !== null) {
            this.state.usertype.map((ival, index) => {
                if (ival.value === parseInt(values.userType)) {
                    usertypeSelected.value = ival.value;
                    usertypeSelected.label = ival.label;
                }
            });
        }
        console.log(usertypeSelected.label, "usertypeSelected");
        var logoname = '';
        if (image != '' && image != undefined && image != null) {
            var logoname = image.split('SDAS/Video/');
        } else {
            var logoname = 'difuzaWebLogo.2dcc7030.png';
        }
        this.setState({
            userIndex: index,
            editid,
            userName: userName,
            usertypeSelected: usertypeSelected,
            mobileNumber: mobileNumber,
            email: email,
            UserPassword: withoutmd5,
            errorUserName: '',
            errorMobileNumber: '',
            errorEmail: '',
            errorPassword: '',
            alertVisible: false,
            updateEmail: false,
            imgupload: image,
            logoname: logoname,
            updateData: true,

        });
    }
    onUpdate = async () => {

        let id = this.state.editid;

        let usertypeSelected = this.state.usertypeSelected;
        let serviceId = this.state.serviceId;
        let userName = this.state.userName;
        let mobileNumber = this.state.mobileNumber;
        let email = this.state.email;
        let imgupload = this.state.imgupload;

        const formData = new FormData();

        formData.append("customerId", this.state.customerId);
        formData.append("serviceId", serviceId);
        if (imgupload && imgupload.size) {
            formData.append("image", imgupload);
        }
        formData.append("status", "active");
        formData.append("userType", usertypeSelected.value);

        formData.append("userName", userName);
        formData.append("mobileNumber", mobileNumber);
        formData.append("email", email);
        formData.append("withoutmd5", this.state.UserPassword);


        console.log(...formData,"formData");
        // get only the edit user from the list of user

        try {
            const result = await UserData.updateadminuserInsert(id, formData);

            if (result) {
                await this.setState({
                    alertVisible: true,
                    userName: "",
                    mobileNumber: "",
                    email: "",
                    UserPassword:"",
                    userIndex: "",
                    errorUserName: "",
                    errorMobileNumber: "",
                    errorEmail: "",
                    errorPassword:'',
                    imgupload: "",
                    logoname: "",
                    usertypeSelected:"",
                    updateData:false,

                    
                });
                this.userlist();
                setTimeout(() => this.setState({ alertVisible: false }), 2000);
            }
        } catch (error) {
            console.log(error);
        }
    };
    buttonDelete = value => {

        confirmAlert({
          title: "Confirmation to Delete",
          message: "Are you sure you want to delete this?",
          buttons: [
            {
              label: "Yes",
              onClick: async () => {
                const id = value.original.id;
                const previousData = [...this.state.data];
                const data = previousData.filter(value => value.id !== id);
                this.setState({
                  data
                });
                try {
                  await UserData.deleteSelectedUser(id);
                } catch (error) {
                  this.setState({
                    data: previousData
                  });
                  console.log(error);
                }
              }
            },
            {
              label: "No"
            }
          ]
        });
      };
      

    render() {
        console.log(this.state.editid, "editid");
        const {
            usertype,
            User_Type,
            formAlertdelete,
            errorType,
            usertypeSelected,
            updateData,
            textalert,
        } = this.state;
        const userData = {
            userName: this.state.userName,
            mobileNumber: this.state.mobileNumber,
            email: this.state.email,
            UserPassword: this.state.UserPassword
        };

        const errorData = {
            errorUserName: this.state.errorUserName,
            errorMobileNumber: this.state.errorMobileNumber,
            errorEmail: this.state.errorEmail,
            errorPassword: this.state.errorPassword
        };
        let title = ""

        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h1>Add User</h1>
                                    </div>

                                    <div className="card-body">
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-7">
                                                <Alert
                                                    className="badge-content"
                                                    color={this.state.color}
                                                    isOpen={formAlertdelete}
                                                    toggle={this.onDismiss}
                                                >
                                                    {/* Deleted Successfully */}
                                                    {textalert}
                                                </Alert>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2"></div>
                                            <div className="col-sm-5">

                                            </div>
                                            <div className="col-sm-3" />
                                        </div>

                                        <LoginModal
                                            buttonTitle="Add User"
                                            title={title}
                                            id="customer"
                                            onClick={this.reset}
                                            extraStyle={{
                                                width: "125%",
                                                position: "relative",
                                                top: 90
                                            }}
                                            bodyText={
                                                <div>
                                                    <div className="row form-group">
                                                        <div className="col-sm-12">
                                                            <div className="row ">
                                                                <div className="col-sm-2">
                                                                    <label for="exampleInputEmail1">Usertype</label>
                                                                </div>
                                                                <div className="col-sm-10">
                                                                    <SingleSelect
                                                                        options={usertype}
                                                                        // disabled={disableUsertype}
                                                                        handleChange={this.usertypeSelect}
                                                                        selectedService={usertypeSelected}
                                                                        placeholder={"User Type"}
                                                                    />
                                                                    <span className="error-shows">{errorType}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <AddUser
                                                        userData={userData}
                                                        errorData={errorData}
                                                        userType={User_Type}
                                                        handleInput={this.handleUserInput}
                                                        handleSubmit={this.handleSubmit}
                                                        onDismiss={this.onDismiss}
                                                        alertVisible={this.state.alertVisible}
                                                        formAlertdelete={this.state.formAlertdelete}
                                                        checkMobileNumber={this.checkMobileNumber}
                                                        checkEmail={this.checkEmail}
                                                        updateData={updateData}
                                                        updateEmail={this.state.updateEmail}
                                                        uploadimg={this.uploadimg}
                                                        logoname={this.state.logoname}
                                                    />
                                                </div>
                                            }
                                        />
                                        <br></br>
                                        <span style={{ marginLeft: "5px" }}>
                                            Total of {Object.keys(this.state.data).length} records
                                        </span>
                                        <br></br>
                                        {
                                            this.state.loading
                                                ?
                                                <div className="text-center">
                                                    <i className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                                                    ></i>
                                                </div>
                                                :
                                                this.state.data.length
                                                    ? (
                                                        <Datatable
                                                            data={this.state.data}
                                                            columnHeading={this.column}
                                                        />
                                                    )
                                                    :
                                                    null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

            </React.Fragment>

        );
    }
}

export default DemoAdduser;