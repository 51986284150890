import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import { FormMiddleWare, MultiSelect } from "../../../../components/Form";
//import DfCarsEnquiry from "./DfCarsEnquiry";
import UsertypeComments from "../UserMaster/UsertypeComments";
import LoginModal from "../../../../components/Modal/Modal";
import moment from "moment";
import Datatable from "../../../../components/Datatable/Datatable";
import Icon from "react-icons-kit";
import { calendar_ok } from 'react-icons-kit/ikons/calendar_ok'
import { checkmark } from 'react-icons-kit/icomoon/checkmark'
import { confirmAlert } from "react-confirm-alert";
import { ACCESS_POINT } from "../../../../config";
import FormModal from "../../../../components/Modal/FormModal";

class TLphysicalprocess extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            User: JSON.parse(localStorage.getItem("userlog")),
            usertypelog: JSON.parse(localStorage.getItem("usertypelog")),
            LocationID: localStorage.getItem(`LocationID`),
            EnquiryData: [],
            loading: false,
            Datas: [],
            MakeOptions: [],
            coloropt: [],
            variantopt: [],
            modelopt: [],
            alertVisible: false,
            StatusupdatealertVisible: false,
            feedback: "",
            column: [
                {
                    Header: "Form",
                    accessor: "mobile",
                    minWidth: 110,
                    Cell: d => this.Document(d)
                },
                {
                    Header: "Feedback",
                    accessor: "edit",
                    width: 110,
                    Cell: d => this.Feedback(d),
                    className: "text-center"
                },
                // {
                //     Header: "View Feedback",
                //     accessor: "view",
                //     width: 120,
                //     Cell: d => this.viewFeedback(d),
                //     className: "text-center"
                // },
                {
                    Header: "Name",
                    accessor: "name"
                },
                {
                    Header: "REG NO",
                    accessor: "reg_no"
                },
                {
                    Header: "Date",
                    accessor: "created_At"
                },
                // {
                //   Header: "Mobile",
                //   accessor: "mobile"
                // },
                {
                    Header: "Evaluator Name ",
                    minWidth: 140,
                    accessor: "jobroleName"
                },
                {
                    Header: "Make",
                    accessor: "make",
                    Cell: (d) => this.getValueFromArray(d.original.make, this.state.MakeOptions)
                },
                {
                    Header: "Model",
                    minWidth: 140,
                    accessor: "model",
                    Cell: (d) => this.getValueFromArray(d.original.model, this.state.modelopt)
                },
                {
                    Header: "Variant",
                    minWidth: 140,
                    accessor: "variant",
                    Cell: (d) => this.getValueFromArray(d.original.variant, this.state.variantopt)
                },
                {
                    Header: "Color",
                    accessor: "color",
                    Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)
                },
                // {
                //     Header: "Data Type",
                //     accessor: "Auction",
                //     minWidth: 140,
                //     Cell: (d) => this.Auction(d.original),
                //     className: "text-center"
                // },

                // {
                //     Header: "Print PDF",
                //     accessor: "pdf",
                //     Cell: d => this.viewpdf(d)
                // },
            ],
            excelColumn: [
                {
                    label: "Name",
                    key: "name"
                },
                {
                    label: "REG NO",
                    key: "reg_no"
                },
                {
                    label: "Date",
                    key: "created_At"
                },
                {
                    label: "Evaluator Name ",
                    key: "jobroleName"
                },
                {
                    label: "Make",
                    key: "makeName",
                },
                {
                    label: "Model",
                    key: "modelName",
                },
                {
                    label: "Variant",
                    key: "variantName",
                },
                {
                    label: "Color",
                    key: "colourName",
                },
                {
                    label: "Data Type",
                    key: "Auction_Status",
                },

            ],
            data_for_f: [{ "filled": 0, "nonfilled": 0 }],
            stage: "",
            CustomerId: "",
            filteredLoc: "",
            filteredMake: "",
            LocationOptions: [],
            FilterData: [],
        };
        this.vals()
    }
    async vals() {
        let allOpt = await this.decryption('getcar')
        if (allOpt) {
            await this.setState({
                MakeOptions: allOpt.makeopt, coloropt: allOpt.coloropt, variantopt: allOpt.variantopt, modelopt: allOpt.modelopt, LocationOptions: allOpt.locopt,
            })
        }
    }
    viewpdf = d => {
        if (d.original.FormFilled == "yes" || d.original.FormFilled == "approved") {
            return (<center><button type="button" className="btn btn-sm btn-warning"
                onClick={() => this.generatepdf(d)}>Print PDF</button></center>)
        }
        else {
            return (
                <div> <span className="label" style={{ backgroundColor: "white" }}></span></div>)
        }
    }
    generatepdf = async (d) => {
        const seller = await cmsContent.getFreedom("*", "tbl_Delivery_Seller_Proof", `customerId=${d.original.id}`, 1, 1);
        console.log(seller)
        if (seller) {
            let sellpdf = await cmsContent.deliverseller("tbl_Delivery_Seller_Proof", seller.data[0].customerid);
            console.log(sellpdf)
            if (sellpdf.data == "success") {
                window.open(`${ACCESS_POINT}/cmsContent/downloadsellpdf/${seller.data[0].reg_name}`, '_self')
            }
        }
    }
    Document = (d) => {
        return (
            <center>
                <button
                    type="button"
                    className="btn btn-sm btn-warning"
                    onClick={() => this.F_proofForm1(d)}
                >
                    View Form
                </button>
            </center>
        );
    }
    Auction = (d) => {
        if (d && d.Auction_Status) {
            return (
                <div>
                    <span className={`btn btn-${d.Auction == 0 ? "secondary" : d.Auction == 1 ? "warning" : d.Auction == 2 ? "success" : "danger"} btn-sm text-center`}
                        style={{ minWidth: "130px" }}>
                        {d.Auction_Status}
                    </span>
                </div>
            )
        } else {
            return (
                <div>
                    <span className={`btn btn-${d.Auction == 0 ? "secondary" : d.Auction == 1 ? "warning" : d.Auction == 2 ? "success" : "danger"} btn-sm text-center`}
                        style={{ minWidth: "130px" }}>
                        {d.Auction_Status}
                    </span>
                </div>
            )
        }
    };
    F_proofForm1 = async (d) => {
        window.open(`/DFCars/Delivery_Note_Seller_Proof/${d.original.id}`, '_self')
    }
    Feedback = (d) => {
        return (
            <button
                type="button"
                className="btn btn-sm btn-primary"
                data-toggle="modal"
                data-target={"#Feedback"}
                onClick={() => this.Status(d)}
            >
                Send
            </button>
        );
    }
    viewFeedback = (d) => {
        return (<button type="button" className="btn btn-sm btn-success"
            data-toggle="modal" data-target={"#fullview"}
            onClick={() => this.Status(d)}
        >View</button>)
    }
    Status = async (d) => {
        let statusArray = []
        if (d.original.statusArray) {
            statusArray = JSON.parse(d.original.statusArray)
        }
        await this.setState({ StatusEditData: d.original, statusArray })
    }
    MainFunction = async () => {
        try {
            this.setState({ loading: true, Datas: [] })
console.log("enter physical");
            let results = await cmsContent.BUYDFCARZ(this.state.User.id, 'physical')
            console.log(results,"results");
            if (results) {
                let fulldata = []
                if (results && results.Physical_Prospective) {
                    fulldata = results.Physical_Prospective;
                }
                let logos = results;
                let Data = logos.Physical_Prospective;
                // console.log(Data, 'apiii');
                this.setState({ Data, loading: false });

                await this.setState({
                    data2: fulldata.length ? fulldata.filter(e => e.filternon_filled == true) : [],
                    data3: fulldata.length ? fulldata.filter(e => e.filter_filled == true) : [],
                    userId: results.userid,
                    data_for_f: results.data_for_f, loading: false
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
    async componentDidMount() {
        await this.MainFunction()

    }
    edash = () => {
        return (<div className="">
            <div className="card">
                <div className="card-header">
                    <div className="col-sm-6" >
                        <h2 className="mt-2 black">Dashboard</h2>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row col-12 mt-4 text-center">
                        <div className="col ">
                            <div className="dashboardCard1" onClick={() => this.onClk(1)} >
                                <Icon icon={checkmark} style={{ color: 'green' }} size={35} />
                                <h4 className="mt-2 black" >Filled</h4>
                                <div >
                                    <h1 className="bold mt-4 mb-2 black"><b> {this.state.data_for_f[0].filled ? this.state.data_for_f[0].filled : 0}</b></h1>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="dashboardCard1" onClick={() => this.onClk(2)} >
                                <Icon icon={calendar_ok} style={{ color: 'red' }} size={35} />
                                <h4 className="mt-2 black" >Non-Filled</h4>
                                <div >
                                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.data_for_f[0].nonfilled ? this.state.data_for_f[0].nonfilled : 0}</b></h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
    onClk = (val) => {
        if (val === 1) { this.setState({ Datas: this.state.data3, FilterData: this.state.data3, filteredLoc: "", filteredMake: "" }) }
        if (val === 2) { this.setState({ Datas: this.state.data2, FilterData: this.state.data2, filteredLoc: "", filteredMake: "" }) }
        window.scroll({
            top: 400,
            left: 0,
            behavior: "smooth",
        });
    }
    makeFilter = async (d) => {
        let { filteredMake, MakeOptions, filteredLoc, FilterData } = this.state;

        //   console.log(d, "d")

        await this.setState({ filteredMake: d })

        if (d && d.length == 0 && filteredLoc.length == 0) {
            await this.setState({ Datas: this.state.FilterData })
        }
        else if (d && d.length == 0 && filteredLoc.length != 0) {
            var filtered_data = await FilterData.filter(x => filteredLoc.some(y => x.location == y.value))
            await this.setState({ Datas: filtered_data })
        }
        else if (d && d.length != 0 && filteredLoc && filteredLoc.length != 0) {
            var filtered_make = await FilterData.filter(fd => d.some(d => fd.make == d.value))
            var filtered_loc = await FilterData.filter(fd => filteredLoc.some(fl => fd.location == fl.value))
            var filtered_data = await filtered_make.filter(fm => filtered_loc.some(fl => fm.make == fl.make && fm.location == fl.location))
            await this.setState({ Datas: filtered_data })
        }
        else if (d && d.length != 0 && filteredLoc.length == 0) {
            var filtered_data = await FilterData.filter(x => d.some(y => x.make == y.value))
            await this.setState({ Datas: filtered_data })
        }
        else {
            //nothing
        }
    }
    locationFilter = async (d) => {
        let { locationOpt, filteredLoc, FilterData, filteredMake, EnquiryDatas } = this.state
        await this.setState({ filteredLoc: d })

        if (d && d.length == 0 && filteredMake.length == 0) {
            await this.setState({ Datas: this.state.FilterData })
        }
        else if (d && d.length == 0 && filteredMake.length != 0) {
            var filtered_data = await FilterData.filter(x => filteredMake.some(y => x.make == y.value))
            await this.setState({ Datas: filtered_data })
        }
        else if (d && d.length != 0 && filteredMake.length != 0) {
            var filtered_loc = await FilterData.filter(fd => d.some(d => fd.location == d.value))
            var filtered_make = await FilterData.filter(fd => filteredMake.some(d => fd.make == d.value))
            var filtered_data = await filtered_loc.filter(fl => filtered_make.some(fm => fl.location == fm.location && fl.make == fm.make))
            await this.setState({ Datas: filtered_data })
        }
        else if (d && d.length != 0 && filteredMake.length == 0) {
            var filtered_data = await FilterData.filter(x => d.some(y => x.location == y.value))
            await this.setState({ Datas: filtered_data })
        }
        else {
            //nothing
        }

    }
    clearFilter = async () => {
        await this.setState({ filteredMake: "", filteredLoc: "", Datas: this.state.FilterData })
    }
    ans = (e, name) => {
        let { feedback } = this.state
        // const name = e.target.value
        // const value = e.target.value

        console.log(e.target.value, "e")
        this.setState({ [name]: e.target.value })
    }
    SendFeedback = async (StatusEditData) => {
        console.log(StatusEditData, "StatusEditData");
        console.log(this.state.feedback, "feedback");
        let { feedback } = this.state

        let feedbackObject = {}
        feedbackObject.feedback = feedback
        try {
            const result = await cmsContent.updateMaster(
                "tbl_dfcarz_prosperities",
                StatusEditData.id,
                feedbackObject

            );
            if (result) {
                await this.setState({
                    statusbtnDisable: false,
                    StatusupdatealertVisible: true,
                    Statusupdataetextalert: "Feedback have been Submit successfully",
                    Statusupdatecolor: "success",
                    EvalutorSelectError: "", SelectedEnquiries: [], EnquiryDatas1: [],
                    feedback: ""
                });
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: "smooth"
                });
                this.MainFunction()
                setTimeout(() => { this.setState({ StatusupdatealertVisible: false }); }, 3000);
            }
        } catch (error) {

        }


    }
    render() {
        let { Edit_Data, Datas, LocationOptions, MakeOptions, StatusEditData } = this.state
        console.log(Datas, "Datas")
        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        {this.edash()}
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h1>Vehicle Physical Process</h1>
                                    </div>
                                    <div className="card-body">
                                        {this.state.StatusEditData != null && <div>
                                            <LoginModal
                                                title={StatusEditData.name}
                                                id="Feedback"
                                                extraStyle={{ width: '100%' }}
                                                reset={this.StatusReset}
                                                buttonStyle={{ backgroundColor: "white", border: "white" }}
                                                bodyText={
                                                    <React.Fragment>
                                                        <main>
                                                            <div className="container-fluid">
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <div className="card">
                                                                            <div className="card-header">
                                                                                <h1> Send Feedback </h1>
                                                                            </div>
                                                                            <div className="card-body">
                                                                                <div className="row form-group">
                                                                                    <div className="col-sm-2" />
                                                                                    <div className="col-sm-7">
                                                                                        <Alert
                                                                                            className="badge-content"
                                                                                            color={this.state.Statusupdatecolor}
                                                                                            isOpen={this.state.StatusupdatealertVisible}
                                                                                            toggle={this.onDismiss}
                                                                                        >
                                                                                            {this.state.Statusupdataetextalert}
                                                                                        </Alert>
                                                                                    </div>
                                                                                    <div className="col-sm-3" />
                                                                                </div>

                                                                                <div className="row" style={{ justifyContent: 'center' }}>
                                                                                    <div className="col-sm-7"
                                                                                    // style={{ left: "25%" }}
                                                                                    >
                                                                                        <label><b>FEEDBACK  :</b></label>
                                                                                        <textarea
                                                                                            type="textarea"
                                                                                            name="feedback"
                                                                                            className="form-control"
                                                                                            placeholder="Enter remarks"
                                                                                            id="feedback"
                                                                                            value={this.state.StatusEditData.feedback != null ? this.state.StatusEditData.feedback : this.state.feedback}
                                                                                            onChange={e => {
                                                                                                this.ans(e, "feedback");
                                                                                            }}
                                                                                            // value={followJson[index] && followJson[index].remarks ? followJson[index].remarks : null}
                                                                                            style={{ width: "100%" }}
                                                                                        ></textarea>
                                                                                        <br />
                                                                                        {this.state.StatusEditData.feedback == null ?
                                                                                            <>
                                                                                                <button className="btn btn-sm btn-block btn-warning" onClick={() => this.SendFeedback(StatusEditData)}>SEND</button>
                                                                                                
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </main>
                                                    </React.Fragment>
                                                }
                                            /></div>
                                        }
                                        <FormModal
                                            extraStyle={{ width: '100%' }}
                                            id={"fullview"}
                                            bodyText={
                                                <div>
                                                    {this.state.StatusEditData &&
                                                        
                                                        <div className="container-fluid">
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <div className="card">
                                                                        <div className="card-header">
                                                                            <h1> View Feedback </h1>
                                                                        </div>
                                                                        <div className="card-body">

                                                                            <div className="row">
                                                                                <div className="col-2" />
                                                                                {/* <div className="card"> */}
                                                                                <div className="col-2">
                                                                                    <label>
                                                                                        <b>Your Feedback  :</b>
                                                                                    </label>

                                                                                    {/* <td class="column2"> {StatusEditData.feedback ? StatusEditData.feedback : "No Feedback"} </td> */}
                                                                                </div>
                                                                                <div className="col-5" >

                                                                                    <textarea
                                                                                        type="textarea"
                                                                                        name="viewfeedback"
                                                                                        className="form-control"
                                                                                        placeholder="Enter remarks"
                                                                                        id="viewfeedback"
                                                                                        value={StatusEditData.feedback ? StatusEditData.feedback : "No Feedback"}
                                                                                        // onChange={e => {
                                                                                        //     this.ans(e, "feedback");
                                                                                        // }}
                                                                                        // value={followJson[index] && followJson[index].remarks ? followJson[index].remarks : null}
                                                                                        style={{ width: "100%" }}
                                                                                    ></textarea>
                                                                                </div>
                                                                                {/* </div> */}
                                                                                <div className="col-3" />
                                                                            </div>

                                                                        


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        
                                                    }
                                                </div>
                                            }
                                        />
                                        {
                                            this.state.FilterData && this.state.FilterData.length ?
                                                <>
                                                    <div className="container">
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <label htmlFor="locationFilter" style={{ fontWeight: 500, fontSize: "medium" }} > Location filter: </label>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <label htmlFor="makefilter" style={{ fontWeight: 500, fontSize: "medium" }}> Make filter: </label>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <MultiSelect
                                                                    id="locationFilter"
                                                                    name="location"
                                                                    placeholder="Filter Location"
                                                                    options={LocationOptions}
                                                                    handleChange={d => this.locationFilter(d)}
                                                                    selectedService={this.state.filteredLoc}
                                                                />
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <MultiSelect
                                                                    id="makefilter"
                                                                    name="make"
                                                                    placeholder="Filter Make"
                                                                    options={MakeOptions}
                                                                    handleChange={d => this.makeFilter(d)}
                                                                    selectedService={this.state.filteredMake}
                                                                />
                                                            </div>
                                                        </div>
                                                        <br></br>
                                                        <div className="row">
                                                            <div className="col-sm-5" />
                                                            <div className="col-sm-2">
                                                                <button type="button" className="btn btn-sm btn-primary" onClick={() => {
                                                                    this.clearFilter()
                                                                }}> Clear filter</button>
                                                            </div>
                                                            <div className="col-sm-5"></div>
                                                        </div>
                                                    </div>
                                                    <br></br>
                                                </>
                                                :
                                                ""

                                        }

                                        {this.state.loading
                                            ? <div className="text-center"> <i
                                                className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                                            ></i></div>
                                            :
                                            this.state.Data && this.state.Data.length

                                                ?
                                                (this.state.Datas &&
                                                    this.state.Datas.length
                                                    ?
                                                    (<Datatable
                                                        data={this.state.Datas}
                                                        columnHeading={this.state.column}
                                                        excelColumn={this.state.excelColumn}
                                                    />)
                                                    :
                                                    <>
                                                        <div style={{ textAlign: "center", fontSize: "larger" }} ><b>No rows to show</b></div>
                                                        <br></br>
                                                    </>
                                                )
                                                :
                                                <h1>No Data found</h1>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        )
    }
}
export default TLphysicalprocess;