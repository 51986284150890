import React, { Component } from "react";
import { Alert } from "reactstrap";
import CmsContent from "../../../../MiddleWare/CmsContent";
import Datatable from "../../../../components/Datatable/Datatable";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import { confirmAlert } from "react-confirm-alert";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import LoginModal from "../../../../components/Modal/Modal";
//import SFAPDF from "../../../components/PDF/SFAPDF";
import redisMiddleware from "../../../../MiddleWare/redisMiddleware";
import moment from "moment";
import DfCarsAddEnquiry from "../Screen/DfCarsAddEnquiry";
//import CheckListJSON from "./Dfcars350checklist.json"
import Icon from "react-icons-kit";
import { calendar_ok } from 'react-icons-kit/ikons/calendar_ok'

class PocEnquiryreport extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      alertVisible: false,
      alertVisible1: false,
      editvalue: "",
      editid: null,
      isEdit: false,
      EnquiryData: [],
      EditData: {},
      Index: null,
      Show: false,
      customerId: localStorage.getItem("userId"),
      CloseEnquiry: false,
      column: [
        {
          Header: "Leads",
          accessor: "name"
        },
        {
          Header: "MobileNumber",
          accessor: "mobile"
        },
        // {
        //   Header: "Email_id",
        //   accessor: "email"
        // },
        {
          Header: "Type",
          accessor: "type"
        },
        {
          Header: "EnquriryDate",
          accessor: "createdAt"
        },
        {
          Header: "Price",
          accessor: "Budget"
        },
        {
          Header: "Location",
          accessor: "LOcation"
        },
        {
          Header: "UpdateStatus",
          accessor: "edit",
          Cell: d => this.UpdateStatus(d)
        },
        {
          Header: "View History",
          accessor: "edit",
          Cell: d => this.ViewLead(d)
        },
        //   {
        //     Header: "Convert to Prospect",
        //     accessor: "edit",
        //     Cell: d => this.ViewLead1(d)
        //   },
        //   {
        //     Header: "Close Enquiry",
        //     accessor: "type",
        //     Cell: d => this.CloseEnquiry(d)
        //   },

      ],
      LocationID: localStorage.getItem(`LocationID`),
      valueofstatus: [{ "live": 0, "close": 0, "evaluated": 0, "today": 0, "MTD": 0 }]
    }
  }


  async componentWillMount() {
    try {
      // console.log(DataJSOn);
      let user = JSON.parse(localStorage.getItem(`userlog`));
      let userType = JSON.parse(localStorage.getItem(`usertypelog`));
      this.setState({ customerId: user.customerId, UserId: user.id });
      const Product = await CmsContent.getFreedom(
        'tbl_dfcarz_enquiryform.*,tbl_location.name as LOcation',
        'tbl_dfcarz_enquiryform,tbl_location',
        `tbl_dfcarz_enquiryform.status='active' and tbl_dfcarz_enquiryform.location = tbl_location.id `,
        1,
        `tbl_dfcarz_enquiryform.id DESC`
      );
      console.log(Product.data, 'dat')
      if (Product) {
        let AllData = [];
        Product.data.map((ival, i) => {
          ival.createdAt = moment(ival.createdAt).format("DD-MM-YYYY");
          ival.intabel = 0;
        });
        const Product1 = await CmsContent.getFreedom(
          '*',
          'tbl_dfcarz_prosperities',
          `companyid=${this.state.customerId}`
        );

        // console.log(Product1.data);
        Product.data.map((ival, i) => {
          Product1.data.map((jval, j) => {
            if (ival.id == parseInt(jval.carz_buyied)) {
              // console.log(ival);
              ival.intabel = 1;
            }
          })
        })
        console.log(Product.data, 'enq')
        let data = Product.data;
        if (userType.orderBy == 9) {
          const result = await data.filter(word => word.type == "seller");
          // console.log("ssaa", result.length);
          data = result
        } else if (userType.orderBy == 8) {
          const result = await data.filter(word => word.type == "buyer");
          // console.log("ssaa", result.length);
          data = result


          let s1 = [{ label: "Yes, Interested in Buying", value: "Yes, Interested in Buying" },
          { label: "Yes, but not interested, Mark closed", value: "Yes, but not interested, Mark closed" },
          { label: "Couldn’t speak to customer", value: "Couldn’t speak to customer" },
          ]

          this.setState({ options: s1 })
        }

        //  console.log(data.length);
        // if(data)
        // {
        //   data.map((ival,i)=>
        //   {
        //     ival.pendingStatus="Live"
        //   })
        // }
        let date1 = new Date();
        var year = date1.getFullYear();
        let mini_year = 1990;

        var difference = year - mini_year;

        let makeYearOption = [];
        for (var i = 0; i <= difference; i++) {
          let a = mini_year + i;
          makeYearOption.push({
            label: a.toString(),
            value: i
          });
        }

        let v1 = 0
        let v2 = 0
        let v3 = 0
        let v4 = 0
        let v5 = 0
        let v6 = 0
        let data1 = []
        let dd = this.state.Current_Date;
        console.log(dd);
        let DA = await dd.getDate();
        if (DA < 10) {
          DA = await "0" + DA
        }
        let DT = await dd.getMonth() + 1;
        let DT1 = DT
        if (DT < 10) {
          DT = await "0" + DT
        }
        let YR = await dd.getFullYear();
        let dataof = []
        let livedata = []
        let closeddata = []
        let remainder = []
        let FullDate = await YR + "-" + DT + "-" + DA

        await data.map((ival, i) => {

          if ("Live" == ival.pendingStatus1 ||
            "Yes, Interested in Buying" == ival.pendingStatus1
            || "Scheduled Follow up" == ival.pendingStatus1
            || "Schedule walkin" == ival.pendingStatus1
          ) {
            v1 = ++v1
            livedata.push(ival)
          } else if ("Closed" == ival.pendingStatus1 || "Yes, but not interested, Mark closed" == ival.pendingStatus1) {
            // console.log("Closed", "Closed" == ival.pendingStatus1);
            v2 = ++v2
            closeddata.push(ival)
          } else if ("Evaluated" == ival.pendingStatus1) {
            v3 = ++v3
          }
          if (ival.remainder == 1) {
            v6 = ++v6
            remainder.push(ival)
          }

          //  })
          if (ival.Next_Contact_date) {
            let date = ival.Next_Contact_date.split(" ");
            let monthof = date[0].split("-")
            if (date[0] == FullDate) {
              v4 = ++v4;
              data1.push(ival)
            }
            // console.log(monthof[1], DT, DT1);
            if (monthof[1] == DT || monthof[1] == DT1) {
              v5 = ++v5;
              dataof.push(ival)
            }
          }
        })
        // console.log(v4);
        let valueofstatus = [{ "live": v1, "close": v2, "evaluated": v3, "today": v4, "MTD": v5, "remainder": v6 }]
        console.log(valueofstatus);
        this.setState({
          livedata, closeddata, data, data1, dataof, valueofstatus,
          UserId: user.userid, makeYearOption, remainder,
          orberby: userType.orderBy, loading: false
        });

        this.setState({ EnquiryData: Product.data });
      }


    } catch (error) {
      console.log(error);
    }
  }

  ViewLead = (d) => {
    return (<button type="button" className="btn btn-primary"
      onClick={() => this.ViewHistory(d)}
    >View</button>)
  }

  CloseEnquiry = (d) => {
    return (<button type="button" className="btn btn-danger"
      data-toggle="modal" data-target={"#status"}
      onClick={() => this.ClosethisEnquiry(d)}
    >Close</button>)
  }

  ViewLead1 = (d) => {
    // if(d.original.intabel==1){
    //   return(<button type="button" className="btn btn-danger"
    //   >Added</button>)
    // }else{
    return (<button type="button" className="btn btn-success"
      onClick={() => this.ViewHistory2(d)}
    >Add</button>)
    //  }
  }

  ClosethisEnquiry = async (d) => {
    this.setState({ CloseEnquiry: true })
    this.setState({ Index: d.index, EditData: d.original });
  }

  UpdateStatus = (d) => {
    return (<button type="button" className="btn btn-warning"
      data-toggle="modal" data-target={"#status"}
      onClick={() => this.ViewHistory1(d)}
    >Edit</button>)
  }

  changeModelstate = async (s, v) => {
    this.setState({ [s]: v });
  }

  ViewHistory2 = async (d) => {
    let data = d.original;
    let groupArray = {};
    groupArray.name = data.name;
    groupArray.mobile = data.mobile;
    groupArray.email = data.email;
    groupArray.status = "active";
    groupArray.companyid = this.state.customerId;
    groupArray.enquiry_id = data.id;
    groupArray.userid = this.state.UserId;
    groupArray.type = data.type;
    groupArray.model = data.Model;
    groupArray.make = data.Make;
    groupArray.variant = data.variant;
    groupArray.budget = data.Budget;
    groupArray.color = data.color;
    groupArray.prospective_status = "active";
    groupArray.location = data.location;
    groupArray.salesid = data.salesid;
    groupArray.variant = data.variant;
    groupArray.evaluatorid = data.evaluatorid;
    let DaTE = new Date();
    groupArray.followup = moment(DaTE).format("YYYY-MM-DD");
    console.log(groupArray)
    try {
      const history = await CmsContent.addMaster("tbl_dfcarz_prosperities", groupArray);
      if (history) {
        let subgroupArray = {}
        subgroupArray.enquiry_status = 'inactive';
        const result = await CmsContent.updateMaster(
          "tbl_dfcarz_enquiryform",
          data.id,
          subgroupArray
        );
        let newData = []
        this.state.EnquiryData.map((ival, i) => {
          if (ival.id != data.id) {
            newData.push(ival);
          }
        })
        // console.log(d.index)
        //newData[d.index].intabel=1;
        this.setState({
          EnquiryData: newData
        })
      }
    } catch (error) {
      console.log(error)
    }

  }

  ViewHistory1 = async (d) => {
    this.setState({ Index: d.index });
    this.setState({ isEdit: true, EditData: d.original })
  }

  ViewHistory = async (d) => {
    window.open(`/DFCars/DfCarsViewhistory/${d.original.id}`)
  }
  reset = async () => {
    this.setState({ isEdit: false })
  }


  edash = () => {
    return (<div className="container-fluid">
      <div className="card">
        {/* < div className="row" > */}
        <div className="card-header">
          <div className="col-sm-6" >
            <h2 className="mt-2 black">Dashboard</h2>
          </div>
        </div>
        <div className="card-body">
          <div className="row col-12 mt-4 text-center">
            <div className="col-sm-2 ">
              <div className="dashboardCard1" onClick={() => this.onClk(1)} >
                <Icon  icon={calendar_ok} style={{ color: 'green' }} size={35} />
                <h4 className="mt-2 black" >Scheduled Appointments</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b> {this.state.valueofstatus[0].live ? this.state.dashboard[0].live : 0}</b></h1>
                </div>
              </div>
            </div>
            <div className="col-sm-2 ">
              <div className="dashboardCard1" onClick={() => this.onClk(2)} >
                <Icon  icon={calendar_ok} style={{ color: 'green' }} size={35} />
                <h4 className="mt-2 black" >Closed Enquiry</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b> {this.state.valueofstatus[0].close ? this.state.dashboard[0].close : 0}</b></h1>
                </div>
              </div>
            </div>
            <div className="col-sm-2 ">
              <div className="dashboardCard1" onClick={() => this.onClk(3)} >
                <Icon  icon={calendar_ok} style={{ color: 'green' }} size={35} />
                <h4 className="mt-2 black" >Remainders</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b> {this.state.valueofstatus[0].remainder ? this.state.dashboard[0].remainder : 0}</b></h1>
                </div>
              </div>
            </div>
            <div className="col-sm-2 ">
              <div className="dashboardCard1" onClick={() => this.onClk(4)} >
                <Icon  icon={calendar_ok} style={{ color: 'green' }} size={35} />
                <h4 className="mt-2 black" >Today's Follow up</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b> {this.state.valueofstatus[0].today ? this.state.dashboard[0].today : 0}</b></h1>
                </div>
              </div>
            </div>
            <div className="col-sm-2 ">
              <div className="dashboardCard1" onClick={() => this.onClk(5)} >
                <Icon  icon={calendar_ok} style={{ color: 'green' }} size={35} />
                <h4 className="mt-2 black" >MTD Enquiry</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b> {this.state.valueofstatus[0].MTD ? this.state.dashboard[0].MTD : 0}</b></h1>
                </div>
              </div>
            </div>
            <div className="col-sm-2 ">
              <div className="dashboardCard1" onClick={() => this.onClk(6)} >
                <Icon  icon={calendar_ok} style={{ color: 'green' }} size={35} />
                <h4 className="mt-2 black" >FTD Enquiry</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b> {this.state.ftd ? this.state.ftd : 0}</b></h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }

  onClk = (val) => {
    // if (val === 1) { this.setState({ Data: this.state.datalive, ftd: 0 }) }
    // if (val === 3) { this.setState({ Data: this.state.dataof, ftd: 0 }) }
    // if (val === 4) { this.setState({ Data: this.state.data1, ftd: 0 }) }
    // if (val === 5) { this.setState({ Data: this.state.data3, ftd: 0 }) }
    // if (val === 6) { this.setState({ Data: this.state.data2, ftd: 0 }) }
    // if (val === 2) { this.setState({ dateVisible: true, Data: [] }) }
    window.scroll({
      top: 400,
      left: 0,
      behavior: "smooth",
    });
  }
  render() {
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            {this.edash()}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Enquiry Report</h1>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-7">
                        <Alert
                          className="badge-content"
                          color={this.state.color}
                          isOpen={this.state.alertVisible}
                          toggle={this.onDismiss}
                        >
                          {this.state.textalert}
                        </Alert>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" style={{}}>
                        <LoginModal
                          //buttonTitle="Add Enquiry"
                          title="Enquiry"
                          id="status"
                          extraClass="btn btn-primary"
                          onClick={this.reset}
                          buttonStyle={{ backgroundColor: "white", border: "white" }}
                          bodyText={<DfCarsAddEnquiry
                            EnquiryData={this.state.EnquiryData}
                            these={this.changeModelstate}
                            isEdit={this.state.isEdit}
                            EditData={this.state.EditData}
                            Index={this.state.Index}
                            CloseEnquiry={this.state.CloseEnquiry} />}
                        />
                      </div>
                    </div>
                    {this.state.EnquiryData.length ? (
                      <Datatable
                        data={this.state.EnquiryData}
                        columnHeading={this.state.column}
                      />
                    ) : (<div style={{ textAlign: "center" }} ><b>No Data Found</b></div>)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>)
  }
}
export default PocEnquiryreport;