import React, { Component } from "react";
import { Alert, Label } from "reactstrap";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import SingleSelect from "../../../../components/Form/SingleSelect";
import redisMiddleware from "../../../../MiddleWare/redisMiddleware";
import CmsContent from "../../../../MiddleWare/CmsContent";
import Preloader from "../preloader.js";
import { FilePond, File, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import { ACCESS_POINT } from "../../../../config";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
class AT_Inventory_Sheet_Reproof extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      respect: [{ label: "Sir", value: 1 }, { label: "Madam", value: 1 }],
      answerJson: [],
      json: [],
      json2: [],
      alertVisible: false,
      userid: JSON.parse(localStorage.getItem("userlog")),
      button: "Save",
      checkbox: false,
      ViewHistory: false,
      Relationshipopt:
        [
          {
            label: "Mother",
            value: 1,
          },
          {
            label: "Father",
            value: 2
          },
          {
            label: "Brother",
            value: 3
          },
          {
            label: "Sister",
            value: 4
          },
          {
            label: "Wife",
            value: 5
          },
          {
            label: "Uncle",
            value: 6
          },
          {
            label: "Aunt",
            value: 7
          },
          {
            label: "GrandFather",
            value: 8
          },
          {
            label: "GrandMother",
            value: 9
          },
          {
            label: "Mother-in-law",
            value: 10
          },
          {
            label: "Sister-in-law",
            value: 11
          },
          {
            label: "Friend",
            value: 12
          },
          {
            label: "Brother-in-law",
            value: 13
          },
          {
            label: "Husband",
            value: 14
          }
        ],
      structural_flood: [{ label: "YES", value: 1 }, { label: "NO", value: 2 }],
      modeofpayment: [{ label: "Payable", value: 1 }, { label: "payment to reg owner", value: 2 }],
      total: 0,
      ViewHistoryData: [],
      ViewHistoryData2: [],
      history: [{ label: "YES", value: 1 }, { label: "NO", value: 2 }],
      hp: [{ label: "HP", value: 1 }, { label: "Preclosure Amount", value: 2 }],
      options: [{ label: "NOC", value: 1 }, { label: "Foreclosure", value: 2 }, { label: "Bank", value: 3 }, { label: "Not Available", value: 4 }],
      vech: [{ label: "Excellent", value: 1 }, { label: "Good", value: 2 }, { label: "Average", value: 3 }, { label: "Bad", value: 4 }],

    };
  }

  async componentDidMount() {
    try {
      let makeopt = [];
      let modelopt = [];
      let varopt = [];
      let colopt = [];
      let salesopt = [];
      console.log(this.state.userid)
      //let service = this.state.userid.serviceId;
      let service = 10;
      let sales = await CmsContent.getFreedom(
        "tbl_user_web.*,tbl_UserType.orderBy",
        "tbl_user_web,tbl_MaptoLocation,tbl_UserType",
        `tbl_UserType.orderBy = 8 and tbl_MaptoLocation.id = tbl_user_web.userportid and tbl_UserType.id =tbl_MaptoLocation.userTypeId `,
        "tbl_user_web.id",
        "tbl_user_web.id DESC"
      );
      //console.log(sales)
      if (sales) {
        sales.data.map((ival, n) => {
          salesopt.push({ label: ival.userName, value: ival.id });
        });
        this.setState({ salesopt });
      }
      let inopt = [];
      let incharge = await CmsContent.getFreedom(
        "tbl_user_web.*,tbl_UserType.orderBy",
        "tbl_user_web,tbl_MaptoLocation,tbl_UserType",
        `tbl_UserType.orderBy = 5 and tbl_MaptoLocation.id = tbl_user_web.userportid and tbl_UserType.id =tbl_MaptoLocation.userTypeId `,
        "tbl_user_web.id",
        "tbl_user_web.id DESC"
      );
      // console.log(incharge)
      if (incharge) {
        incharge.data.map((jval, n) => {
          inopt.push({ label: jval.userName, value: jval.id });
        });
        this.setState({ inopt });
      }
      let manopt = [];
      let manager = await CmsContent.getFreedom(
        "tbl_user_web.*,tbl_UserType.orderBy",
        "tbl_user_web,tbl_MaptoLocation,tbl_UserType",
        `tbl_UserType.orderBy = 2 and tbl_MaptoLocation.id = tbl_user_web.userportid and tbl_UserType.id =tbl_MaptoLocation.userTypeId `,
        "tbl_user_web.id",
        "tbl_user_web.id DESC"
      );
      if (manager) {
        manager.data.map((mval, j) => {
          manopt.push({ label: mval.userName, value: mval.id });
        });
        this.setState({ manopt });
      }
      //const form = await redisMiddleware.singleTable("tbl_form", 75, "id");

      const make = await redisMiddleware.singleTable("tbl_make_master", service, "ServiceId");
      if (make && make.data.length) {
        make.data.map((ival, b) => {
          makeopt.push({ label: ival.Make, value: ival.id })
        })
        this.setState({ makeopt })
      }






      //Model master
      const model = await redisMiddleware.singleTable(
        "tbl_dfcarsmodel",
        service,
        "ServiceId"
      );

      if (model && model.data.length) {
        model.data.map((jval, j) => {
          modelopt.push({ label: jval.Model, value: jval.id, refid: jval.id });
        });
        this.setState({ modelopt });
      }
      //Color Master
      const color = await redisMiddleware.singleTable(
        "tbl_colour",
        service,
        "ServiceId"
      );
      if (color && color.data.length) {
        color.data.map((cval, b) => {
          colopt.push({ label: cval.colour, value: cval.id });
        });
        this.setState({ colopt });
      }
      //Variant Master
      const variant = await redisMiddleware.singleTable(
        "tbl_variant_master",
        service,
        "ServiceId"
      );
      if (variant && variant.data.length) {
        variant.data.map((cval, b) => {
          varopt.push({
            label: cval.variant,
            value: cval.modelid,
            valid: cval.id
          });
        });
        this.setState({ varopt });
      }

      const section1 = await CmsContent.getFreedom(
        "*",
        "tbl_formquestion",
        `parentid = 96`,
        "id",
        "id"
      );
      const enclosureopt = await CmsContent.getFreedom("*", "tbl_formquestion", `id=571`, 1, 1);
      // console.log(enclosureopt.data[0])
      this.setState({ encopt: enclosureopt.data[0].options })
      section1.data.map((ival, i) => {
        if (ival.Orderby == null) {
          ival.Orderby = 1000 + i;
        }
      });

      section1.data = section1.data.sort(await CmsContent.compareValues("Orderby", "int", "asc"));

      this.setState({ section1Data: section1.data });
      if (section1.data.length) {
        section1.data.map((ival, n) => {
          if (ival.id == 549) {
            ival.options = makeopt
          }
          if (ival.id == 550) {
            ival.options = modelopt
          }
          if (ival.id == 552) {
            ival.options = colopt;
          }
          if (ival.id == 558) {
            ival.options = this.state.history;
          }
          if (ival.id == 557) {
            ival.options = this.state.structural_flood;
          }

          if (ival.id == 627) {
            ival.options = this.state.modeofpayment;
          }
          if (ival.id == 564) {
            ival.options = this.state.hp;
          }
          if (ival.id == 565) {
            //ival.type="";
            ival.options = this.state.options;
          }
          if (ival.id == 570) {
            ival.options = this.state.vech;
          }
        })
      }

      const section2 = await CmsContent.getFreedom(
        "*",
        "tbl_formquestion",
        `parentid = 97`,
        "id",
        "id"
      );
      if (section2.data.length) {
        section2.data.map((ival, j) => {
          if (ival.id == 575) {
            ival.options = this.state.Relationshipopt
          }
          if (ival.id == 581) {
            ival.options = salesopt;
          }
          if (ival.id == 584) {
            ival.options = inopt;
          }
          if (ival.id == 586) {
            ival.options = manopt;
          }
        })
      }

      this.setState({ section2Data: section2.data });

      const formQues = await redisMiddleware.singleTable(
        "tbl_formquestion",
        75,
        "parentid"
      );
      this.setState({ data: formQues.data });
      if (formQues) {
        formQues.data.map((qval, i) => {
          if (qval.id == 552) {
            qval.options = colopt;
          }
          if (qval.id == 550) {
            qval.options = modelopt;
          }
          if (qval.id == 581) {
            qval.options = salesopt;
          }
          if (qval.id == 584) {
            qval.options = inopt;
          }
          if (qval.id == 586) {
            qval.options = manopt;
          }

          // if (qval.id == 266) {
          //   qval.options = varopt
          // }
        });
      }
      if (this.props.cusid) {
        this.setState({ CustomerId: this.props.cusid, global: false })
      }
      else if (this.props.match.params.cusid) {
        this.setState({ CustomerId: this.props.match.params.cusid, global: true });
      }
      // if (this.props.match.params.cusid) {
      //   this.setState({ CustomerId: this.props.match.params.cusid });
      // }
      const bookform = await CmsContent.getFreedom(
        "*",
        "tbl_formquestion",
        `parentid=81 and id=450`,
        1,
        1
      );

      let fproof = await CmsContent.getFreedom(
        "*",
        "tbl_FProof",
        `customerid = ${this.state.CustomerId}`,
        "id",
        "id"
      );
      if (fproof && fproof.data.length > 0) {
        let answerJson = this.state.answerJson;
        let val = fproof.data[0];
        answerJson[574] = val.customer_name;
        answerJson[576] = val.mobile;
        answerJson[577] = val.address;
        answerJson[562] = val.ins_validity;
        answerJson[556] = val.chassis_no;
        //answerJson[562] =val.insurance;
        var d = new Date();
        var date = d.getDate();
        var month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
        var year = d.getFullYear();
        var hr = d.getHours();
        var min = d.getMinutes();
        var sec = d.getSeconds();
        if (date < 10) date = "0" + date;
        if (month < 10) month = "0" + month;
        var dateStr = year + "-" + month + "-" + date;
        answerJson[260] = dateStr;

        answerJson[554] = val.km_travel;
        answerJson[561] = val.trade_in_price;
        answerJson[569] = val.std_sell_price;
        //answerJson[274] = val.trade_in_price;
        //answerJson[278] = val.std_sell_price;
        // answerJson[288] = val.mobile;
        // answerJson[289] = val.address;
        answerJson[567] = val.recondition_est;
        this.state.salesopt.map((sval, i) => {
          console.log(sval);
          if (sval.label == val.saleCons_name) {
            answerJson[581] = sval;
          }
        });

        // if (ival.id == 564) {
        //   ival.options = this.state.hp;
        // }


        answerJson[555] = val.ownership_no;
        answerJson[582] = this.state.userid.userName;
        this.setState({ answerJson });
      }
      let contract = await CmsContent.getFreedom(
        "*",
        "tbl_AT_Booklet_Purchase_Contract_Proof",
        `customerid = ${this.state.CustomerId}`,
        "id",
        "id"
      );

      let inventory = await CmsContent.getFreedom(
        "*",
        " tbl_Inventory_Sheet_Reproof",
        `customerid=${this.state.CustomerId}`,
        "id",
        "id"
      );
      if (inventory && inventory.data.length > 0) {
        this.setState({ inventory: inventory.data });
        this.form_filled();
      }
      let answerJson = this.state.answerJson;
      if (contract && contract.data.length > 0) {
        let val = contract.data[0];
        console.log('booklet', contract.data[0])
        answerJson[547] = val.name;
        // answerJson[261] = val.name;
        //answerJson[289] = val.To_address; KARAN
        answerJson[548] = val.Reg_No;
        let registerPlugin = await CmsContent.getFreedom(
          "*",
          "tbl_dfcarz_prosperities",
          `reg_no='${val.Reg_No}' and name != '${val.name}'`,
          "id",
          "id"
        );

        let registerPlugin2 = await CmsContent.getFreedom(
          "*",
          "tbl_AT_Booklet_Purchase_Contract_Proof",
          `Reg_no='${val.Reg_No}' and name != '${val.name}'`,
          "id",
          "id"
        );

        if (registerPlugin.data.length) {
          console.log(registerPlugin)
          this.setState({ ViewHistory: true, ViewHistoryData: registerPlugin.data })
        } else if (registerPlugin2.data.length) {
          console.log(registerPlugin2)
          this.setState({ ViewHistory: true, ViewHistoryData2: registerPlugin2.data })
        }
        let book = bookform.data[0];
        let documentBook = JSON.parse(val.documentsfiles);

        let pendingDocument = []
        documentBook.map((ival, i) => {
          console.log(ival);
          if (!ival.DuplicateKeys.length) {
            pendingDocument.push("DuplicateKeys")
          }
          if (!ival.InsurancePolicy.length) {
            pendingDocument.push("InsurancePolicy")
          }
          if (!ival.NoObjectionCertificate.length) {
            pendingDocument.push("NoObjectionCertificate")
          }
          if (!ival.Ownership.length) {
            pendingDocument.push("Ownership")
          }
          if (!ival.Rcbook.length) {
            pendingDocument.push("Rcbook")
          }
          if (!ival.TaxToken.length) {
            pendingDocument.push("TaxToken")
          }

        });
        console.log(pendingDocument);
        answerJson[572] = pendingDocument.join();
        book.options.split(",").map((ival, i) => {
          if (ival != val.documents) {

          }
        });
        //answerJson[284] = val.documents;
        //answerJson[263] = val.color;
        //answerJson[264] = val.model;
        //answerJson[266] = val.Model_Variant;
        this.state.colopt.map((cval, j) => {
          if (cval.value == val.color) {
            answerJson[552] = cval;
          }
        });
        this.state.makeopt.map((kval, n) => {
          if (kval.value == val.make) {
            answerJson[549] = kval
          }
        })
        this.state.modelopt.map((mval, m) => {
          if (mval.refid == val.model) {
            answerJson[550] = mval;
          }
        });
        this.state.varopt.map((jval, n) => {
          if (jval.valid == val.variant) {
            answerJson[551] = jval;
          }
        });
        answerJson[571] = val.enclosure;
        answerJson[553] = val.yom;
        answerJson[560] = val.Engine_No;
        //answerJson[286] = val.name;
        answerJson[579] = val.Exchange_Bonus;
      }
      this.setState({ answerJson, transcationtype: fproof.transaction });

      console.log(fproof.data[0].transaction);
      //fproof.data[0].transaction = "Non-Exchange"
      if (fproof.data[0].transaction == "Non-Exchange") {
        this.process();
      } else {
        this.process();
        this.process_section2();
      }
    } catch (error) {
      console.error(error);
    }
  }

  form_filled = () => {
    let answerJson = this.state.answerJson;
    let inv = this.state.inventory[0];
    // console.log(inv)
    this.setState({ checkbox: true })
    let opt = this.state.encopt.split(",");

    let closure = inv.enclosure.split(",")
    this.setState({ closure, opt })

    answerJson.id = inv.id;

    if (inv.date) {
      let date = inv.date.split(" ")[0];
      answerJson[260] = date;
    }
    // answerJson[261] = inv.reg_ownerName;
    //answerJson[262] = inv.reg_no;
    //answerJson[263] = inv.color;
    //answerJson[264] = inv.model;
    this.state.colopt.map((cval, j) => {
      if (cval.value == inv.color) {
        answerJson[552] = cval;
      }
    });
    this.state.modelopt.map((mval, m) => {
      if (mval.refid == inv.model) {
        answerJson[550] = mval;
      }
    });
    this.state.varopt.map((val, n) => {
      if (val.valid == inv.variant) {
        answerJson[551] = val;
      }
    });
    this.state.makeopt.map((mval, n) => {
      if (mval.value == inv.make) {
        answerJson[549] = mval
      }
    })
    //answerJson[263] = val.color;
    //answerJson[264] = val.model;
    answerJson[554] = inv.km;
    if (inv.hp) {
      this.state.hp.map((ival, i) => {
        if (ival.label == inv.hp) {
          answerJson[564] = ival;
        }
      })
    }
    if (inv.modeofpayment) {
      this.state.modeofpayment.map((ival, i) => {
        if (ival.label == inv.modeofpayment) {
          answerJson[627] = ival;
        }
      })
    }
    if (inv.service_histaory) {
      this.state.history.map((ival, i) => {
        if (ival.label == inv.service_histaory) {
          answerJson[558] = ival
        }
      })
    }
    if (inv.vech_condition) {
      this.state.vech.map((ival, i) => {
        if (ival.label == inv.vech_condition) {
          answerJson[570] = ival
        }
      })
    }
    if (inv.acc_hold_noc) {
      this.state.options.map((ival, i) => {
        if (ival.label == inv.acc_hold_noc) {
          answerJson[565] = ival
        }
      })
    }


    //answerJson[266] = inv.variant;
    answerJson[557] = inv.structural_flood;
    answerJson[553] = inv.model_year;
    // answerJson[558] = inv.service_histaory;
    // answerJson[270] = inv.ownership;
    answerJson[559] = inv.last_service_kms;
    answerJson[326] = inv.vin_no;
    //answerJson[273] = inv.eng_no;
    answerJson[561] = inv.buying_price;
    answerJson[562] = inv.inc_exp_date;
    //answerJson[276] = inv.refurish_cost;
    // answerJson[277] = inv.hp;
    answerJson[278] = inv.exp_selling_price;

    this.state.Relationshipopt.map((ival, n) => {
      if (ival.label == inv.relation) {
        console.log(ival)
        answerJson[575] = ival
      }
      this.process_section2();
    })

    answerJson[569] = inv.exp_selling_price;
    answerJson[568] = inv.final_payable;
    //answerJson[279] = inv.preclosure_amt;
    //answerJson[281] = inv.acc_hold_noc;
    // answerJson[570] = inv.vech_condition;

    // answerJson[284] = inv.pnding_docs;
    answerJson[573] = inv.accessories;
    answerJson[574] = inv.customerName;
    //answerJson[575] = inv.relation;
    //answerJson[286] = inv.customerName;
    answerJson[576] = inv.contact_no;
    answerJson[577] = inv.address;
    answerJson[578] = inv.exchange_again;
    if (inv.delivery_date) {
      let date1 = inv.delivery_date.split(" ")[0];
      answerJson[580] = date1;
    }
    //answerJson[291] = inv.delivery_date
    // categoryArray.delivery_date = answerJson[291];
    answerJson[579] = inv.exchange_bonus;
    //answerJson[293] = inv.sales_consultant;
    if (inv.sales_consultant) {
      this.state.salesopt.map((sval, n) => {
        if (sval.label == inv.sales_consultant) {
          answerJson[581] = sval;
        }
      });
    }
    answerJson[582] = inv.buyerName;
    //answerJson[295] = inv.byer_sign;
    answerJson[583] = inv.buyer_remarks;
    this.state.inopt.map((ival, i) => {
      if (ival.label == inv.incharge_name) {
        answerJson[584] = ival;
      }
    });
    answerJson[564] = inv.preclosure_amt;
    answerJson[563] = inv.New_Car;
    //this.state.options.map
    answerJson[566] = inv.Holding_amount;
    //answerJson[565] = inv.acc_hold_noc;
    //answerJson[297] = inv.incharge_name;
    // answerJson[298] = inv.incharge_sign;
    answerJson[585] = inv.incharge_remarks;
    this.state.manopt.map((ival, i) => {
      if (ival.label == inv.manager) {
        answerJson[586] = ival;
      }
    });
    // answerJson[300] = inv.manager;
    answerJson[587] = inv.manager_remarks;
    this.setState({
      button: "Update",
      answerJson
    });
    this.process();
    this.process_section2();
  };
  async answers(
    label,
    index,
    options = null,
    key = null,
    type,
    questionId,
    formid
  ) {

    const { data, varopt, section1Data } = this.state;
    let get = [];
    if (questionId == 264) {
      varopt.map((mval, i) => {
        if (mval.value == label.value) {
          get.push(mval);
        }
      });
      data.map((ival, k) => {
        if (ival.id == 266) {
          ival.options = get;
        }
      });
    }
    if (questionId == 287) {
      if (label == "No") {
        // console.log("nooo")
        data.map((ival, k) => {
          if (ival.id == 288) {
            console.log(ival);
            ival.type = null;
          }
          if (ival.id == 289) {
            ival.type = null;
          }
        });
      }
      if (label == "Yes") {
        // console.log("yooo")
        data.map((ival, k) => {
          if (ival.id == 288) {
            ival.type = "json";
          }
          if (ival.id == 289) {
            ival.type = "jsonarea";
          }
        });
      }

    }
    if (questionId == 565) {
      if (label.value == 2) {
        section1Data.map((ival, i) => {
          if (ival.id == 566) {
            //         // alert("1")
            ival.type = "text";
          }
        });
      } else {
        section1Data.map((ival, i) => {
          if (ival.id == 566) {
            ival.type = "";
          }
        });
      } console.log(label);
      //   //   console.log(questionId);
    }
    console.log(label);
    console.log(questionId);
    if (questionId == 565) {
      if (label.value == 4) {
        section1Data.map((ival, i) => {
          console.log(ival.id)
          if (ival.id == 572) {
            ival.type = "textarea";
          }
        });
      } else {
        section1Data.map((ival, i) => {
          if (ival.id == 572) {
            ival.type = "";
          }
        });
      }
    }
    let answerJson = await this.state.answerJson;
    if (
      questionId == 561 ||
      questionId == 563 ||
      questionId == 564 ||
      questionId == 566

    ) {
      let total = this.state.total;
      // console.log("Margin");

      // console.log(parseInt(answerJson[345]));



      let A = answerJson[561] ? parseInt(answerJson[561]) : 0;
      console.log(A);
      let B = answerJson[563] ? parseInt(answerJson[563]) : 0;
      console.log(B);
      let C = answerJson[564] ? parseInt(answerJson[564]) : 0;

      console.log(C);
      let D = answerJson[566] ? parseInt(answerJson[566]) : 0;
      console.log(D);
      total = A + B - C - D;
      answerJson[568] = total;
      console.log(total);
    }


    if (type == "text") {
      answerJson[questionId] = label;
    } else if (type == "date") {
      var d = new Date(label);
      // var date = d.getDate();
      // var month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      // var year = d.getFullYear();
      // var hr = d.getHours();
      // var min = d.getMinutes();
      // var sec = d.getSeconds();
      // var dateStr =
      //   year + "-" + month + "-" + date + " " + hr + ":" + min + ":" + sec;
      // console.log(dateStr)
      answerJson[questionId] = label;
    } else if (type == "textarea") {
      answerJson[questionId] = label;
    } else if (type == "selectbox") {
      answerJson[questionId] = label;
    } else if (type == "radio") {
      answerJson[questionId] = label;
    } else if (type == "checkbox") {
      if (this.state.checkbox == false) {
        let check = [];
        options.map((ival, i) => {
          var checkBox = document.getElementById(`inlineCheckbox${index}${i}`);
          if (checkBox.checked == true) {
            check.push(ival);
          }
        });
        answerJson[questionId] = check.toString();
      }
    }
    if (this.state.checkbox == true) {
      let check = [];
      console.log(questionId)
      console.log(options)
      if (options) {
        options.map((ival, i) => {
          var checkBox = document.getElementById(`inlineCheckbox${index}${i}`);

          if (checkBox.checked == true) {
            check.push(ival);
            // reg.push({ label: ival, value: i })
          }
          else {
            checkBox.checked = false;
          }

        });
      }
      console.log(check)
      this.setState({ closure: check })
      answerJson[questionId] = check.toString();

    }

    await this.setState({ answerJson });
    this.process();


    //console.log(total);







  }

  GotoViewhistory = async () => {
    // console.log(2);
    const { ViewHistoryData2, ViewHistoryData } = this.state;
    if (ViewHistoryData2.length) {
      // console.log(ViewHistoryData2);
      // window.location.href=;
      window.open(`/DFCars/TransactionHistory/${ViewHistoryData2[0].customerid}`, '_self');
    }

    if (ViewHistoryData.length) {
      // console.log(ViewHistoryData)
      //  window.location.href=`/DFCars/TransactionHistory/${ViewHistoryData[0].id}`
      window.open(`/DFCars/TransactionHistory/${ViewHistoryData[0].id}`, '_self');
    }
  }

  process = async () => {
    let data = await this.state.section1Data;
    if (data) {
      let arr = data;
      let contentJson = [];
      if (arr && arr.length) {
        let answerJson = await this.state.answerJson;
        let json = await arr.map(async (item, index) => {
          // console.log(item);
          if (item.type == "text") {
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="position">{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={item.placeholder}
                      onChange={e =>
                        this.answers(
                          e.target.value,
                          index,
                          null,
                          null,
                          "text",
                          item.id,
                          item.parentid
                        )
                      }
                      value={answerJson[item.id]}
                    />
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                  <div className="col-sm-3">
                    {item.id == 548 && this.state.ViewHistory == true ? <button type="button" onClick={() => this.GotoViewhistory()} className="btn btn-primary" > View History </button> : null}
                  </div>
                </div>
              </div>
            );
          }
          if (item.type == "date") {
            this.state.date = answerJson[item.id];
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="position">{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <input
                      type="date"
                      id="myDate"
                      className="form-control"
                      onChange={e =>
                        this.answers(
                          e.target.value,
                          index,
                          null,
                          null,
                          "date",
                          item.id,
                          item.parentid
                        )
                      }
                      // onChange={this.fromDateSelect}
                      value={this.state.date}
                    />
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "textarea") {
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder={item.placeholder}
                      onChange={e =>
                        this.answers(
                          e.target.value,
                          index,
                          null,
                          null,
                          "textarea",
                          item.id,
                          item.parentid
                        )
                      }
                      value={answerJson[item.id]}
                    ></textarea>
                  </div>
                  <div className="col-sm-3">
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                </div>
              </div>
            );
          }
          if (item.type == "radio") {
            let arr1 = [];
            await item.options.split(",").map((element, key) => {
              arr1.push(
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      name={`element${index}`}
                      // value={`${element}`}
                      id={`inlineCheckbox${key}`}
                      onChange={e =>
                        this.answers(
                          element,
                          key,
                          null,
                          key,
                          "radio",
                          item.id,
                          item.parentid
                        )
                      }
                      checked={element == answerJson[item.id]}
                    />
                    <label
                      className="form-check-label"
                      for={`inlineCheckbox${key}`}
                      style={{ marginLeft: "10px" }}
                    >
                      {`${element}`}{" "}
                    </label>
                  </div>
                  {/* {element == answerJson[item.id] && (
                    <FilePond
                      // files={this.state.filesget}
                      allowReorder={true}
                      allowMultiple={true}
                      acceptedFileTypes={'(fileItem => fileItem.file)
                        });
                      }}
                      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                    />
                  )} */}
                </div>
              );
            });
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    {arr1}
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "selectbox") {
            let option = [];
            if (item.options) {
              item.options.map((ival, i) => {
                option.push(ival);
              });
            }
            this.state.selectbox = answerJson[item.id];
            // item.options.split(",").map((ival, i) => {
            //   option.push({ label: ival, value: i });
            // });
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <SingleSelect
                      options={option}
                      placeholder={item.placeholder}
                      handleChange={e => {
                        this.answers(
                          e,
                          index,
                          null,
                          null,
                          "selectbox",
                          item.id,
                          item.parentid
                        );
                      }}
                      selectedService={this.state.selectbox}
                    />
                  </div>
                  <span className="error-shows" id={`${index}`}>
                    {/* {this.state.error[index]} */}
                  </span>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "checkbox") {
            let arr1 = [];

            await item.options.split(",").map((element, key) => {
              arr1.push(
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`inlineCheckbox${index}${key}`}
                    onChange={e => {
                      this.answers(
                        element,
                        index,
                        item.options.split(","),
                        key,
                        "checkbox",
                        item.id,
                        item.parentid
                      );
                    }}
                    value={element}
                  //checked={element == answerJson[item.id]}
                  />
                  <label
                    className="form-check-label"
                    for={`inlineCheckbox${index}${key}`}

                  >{`${element}`}</label>
                </div>
              );
            });
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">{arr1}</div>
                  <div className="col-sm-3">
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                </div>
              </div>
            );
          }
        });
        json = await arr.map(async (item, index) => {
          if (item.type == "json") {
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="position">{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={item.placeholder}
                      onChange={e =>
                        this.answers(
                          e.target.value,
                          index,
                          null,
                          null,
                          "text",
                          item.id,
                          item.parentid
                        )
                      }
                      value={answerJson[item.id]}
                    />
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }

          if (item.type == "jsonarea") {
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder={item.placeholder}
                      onChange={e =>
                        this.answers(
                          e.target.value,
                          index,
                          null,
                          null,
                          "textarea",
                          item.id,
                          item.parentid
                        )
                      }
                      value={answerJson[item.id]}
                    ></textarea>
                  </div>
                  <div className="col-sm-3">
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                </div>
              </div>
            );
          }
        });

        await Promise.all(json);
        await this.setState({ json: contentJson, answerJson });
      }
    }
  };
  process_section2 = async () => {
    let data = await this.state.section2Data;
    if (data) {
      let arr = data;
      let contentJson_Section2 = [];
      if (arr && arr.length) {
        let answerJson = await this.state.answerJson;
        let json2 = await arr.map(async (item, index) => {
          if (item.type == "text") {
            await contentJson_Section2.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="position">{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={item.placeholder}
                      onChange={e =>
                        this.answers(
                          e.target.value,
                          index,
                          null,
                          null,
                          "text",
                          item.id,
                          item.parentid
                        )
                      }
                      value={answerJson[item.id]}
                    />
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "date") {
            this.state.date = answerJson[item.id];
            await contentJson_Section2.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="position">{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <input
                      type="date"
                      id="myDate"
                      className="form-control"
                      onChange={e =>
                        this.answers(
                          e.target.value,
                          index,
                          null,
                          null,
                          "date",
                          item.id,
                          item.parentid
                        )
                      }
                      // onChange={this.fromDateSelect}
                      value={this.state.date}
                    />
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "textarea") {
            await contentJson_Section2.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder={item.placeholder}
                      onChange={e =>
                        this.answers(
                          e.target.value,
                          index,
                          null,
                          null,
                          "textarea",
                          item.id,
                          item.parentid
                        )
                      }
                      value={answerJson[item.id]}
                    ></textarea>
                  </div>
                  <div className="col-sm-3">
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                </div>
              </div>
            );
          }
          if (item.type == "radio") {
            let arr1 = [];
            await item.options.split(",").map((element, key) => {
              arr1.push(
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      name={`element${index}`}
                      // value={`${element}`}
                      id={`inlineCheckbox${key}`}
                      onChange={e =>
                        this.answers(
                          element,
                          key,
                          null,
                          key,
                          "radio",
                          item.id,
                          item.parentid
                        )
                      }
                      checked={element == answerJson[item.id]}
                    />
                    <label
                      className="form-check-label"
                      for={`inlineCheckbox${key}`}
                      style={{ marginLeft: "10px" }}
                    >
                      {`${element}`}{" "}
                    </label>
                  </div>
                  {/* {element == answerJson[item.id] && (
                    <FilePond
                      // files={this.state.filesget}
                      allowReorder={true}
                      allowMultiple={true}
                      acceptedFileTypes={'image/png'}
                      allowFileTypeValidation={true}
                      onupdatefiles={fileItems => {
                        // Set currently active file objects to this.state
                        this.setState({
                          files: fileItems.map(fileItem => fileItem.file)
                        });
                      }}
                      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                    />
                  )} */}
                </div>
              );
            });
            await contentJson_Section2.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    {arr1}
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "selectbox") {
            let option = [];
            if (item.options) {
              item.options.map((ival, i) => {
                option.push(ival);
              });
            }
            // console.log(answerJson[item.id])
            this.state.selectbox = answerJson[item.id];
            // item.options.split(",").map((ival, i) => {
            //   option.push({ label: ival, value: i });
            // });
            await contentJson_Section2.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <SingleSelect
                      options={option}
                      placeholder={item.placeholder}
                      handleChange={e => {
                        this.answers(
                          e,
                          index,
                          null,
                          null,
                          "selectbox",
                          item.id,
                          item.parentid
                        );
                      }}
                      selectedService={this.state.selectbox}
                    />
                  </div>
                  <span className="error-shows" id={`${index}`}>
                    {/* {this.state.error[index]} */}
                  </span>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "checkbox") {
            let arr1 = [];
            await item.options.split(",").map((element, key) => {
              arr1.push(
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`inlineCheckbox${index}${key}`}
                    onChange={e => {
                      this.answers(
                        element,
                        index,
                        item.options.split(","),
                        key,
                        "checkbox",
                        item.id,
                        item.parentid
                      );
                    }}
                    // value={element}
                    checked={element == answerJson[item.id]}
                  />
                  <label
                    className="form-check-label"
                    for={`inlineCheckbox${index}${key}`}
                    style={{ marginLeft: "10px" }}
                  >{`${element}`}</label>
                </div>
              );
            });
            await contentJson_Section2.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <label>{item.question}</label>
                  <div className="col-sm-2"></div>
                  <div className="col-sm-5">{arr1}</div>
                  <div className="col-sm-3">
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                </div>
              </div>
            );
          }
        });
        json2 = await arr.map(async (item, index) => {
          if (item.type == "json") {
            await contentJson_Section2.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="position">{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={item.placeholder}
                      onChange={e =>
                        this.answers(
                          e.target.value,
                          index,
                          null,
                          null,
                          "text",
                          item.id,
                          item.parentid
                        )
                      }
                      value={answerJson[item.id]}
                    />
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }

          if (item.type == "jsonarea") {
            await contentJson_Section2.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder={item.placeholder}
                      onChange={e =>
                        this.answers(
                          e.target.value,
                          index,
                          null,
                          null,
                          "textarea",
                          item.id,
                          item.parentid
                        )
                      }
                      value={answerJson[item.id]}
                    ></textarea>
                  </div>
                  <div className="col-sm-3">
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                </div>
              </div>
            );
          }
        });

        await Promise.all(json2);
        await this.setState({ json2: contentJson_Section2, answerJson });
      }
    }
  };
  update = async () => {
    const { answerJson } = this.state;
    let categoryArray = {};
    let id = answerJson.id;
    // categoryArray.date = answerJson[260];
    if (answerJson[260]) {
      var d = new Date(answerJson[260]);
      var date = d.getDate();
      var month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      var year = d.getFullYear();
      var hr = d.getHours();
      var min = d.getMinutes();
      var sec = d.getSeconds();
      var dateStr =
        year + "-" + month + "-" + date + " " + hr + ":" + min + ":" + sec;
      //console.log(dateStr)
      // formData.append("date", dateStr);
      categoryArray.date = dateStr;
    }
    // formData.append("reg_ownerName", answerJson[547]);
    categoryArray.reg_ownerName = answerJson[547];
    //formData.append("reg_no", answerJson[548]);
    categoryArray.reg_no = answerJson[548];
    if (answerJson[549]) {
      //formData.append("make", answerJson[549].value)
      categoryArray.make = answerJson[549].value;
    }
    if (answerJson[550]) {
      categoryArray.model = answerJson[550].refid;
    }
    categoryArray.km = answerJson[554];
    if (answerJson[551]) {
      categoryArray.variant = answerJson[551].valid;
    }
    categoryArray.structural_flood = answerJson[557];
    categoryArray.model_year = answerJson[553];
    if (answerJson[558]) {
      categoryArray.service_histaory = answerJson[558].label;
    }
    categoryArray.ownership = answerJson[555];
    categoryArray.last_service_kms = answerJson[559];
    categoryArray.vin_no = answerJson[326];
    categoryArray.eng_no = answerJson[560];
    categoryArray.buying_price = answerJson[561];
    categoryArray.inc_exp_date = answerJson[562];
    categoryArray.refurish_cost = answerJson[567];
    if (answerJson[564]) {
      categoryArray.hp = answerJson[564];
    }
    if (answerJson[627]) {
      categoryArray.modeofpayment = answerJson[627];
    }
    categoryArray.exp_selling_price = answerJson[569];
    categoryArray.final_payable = answerJson[568];
    categoryArray.preclosure_amt = answerJson[564];
    if (answerJson[565]) {
      categoryArray.acc_hold_noc = answerJson[565].label;
    }
    //categoryArray.preclosure_amt = answerJson[279];
    // categoryArray.acc_hold_noc = answerJson[281];
    if (answerJson[570]) {
      categoryArray.vech_condition = answerJson[570].label;
    }
    categoryArray.enclosure = answerJson[571];
    categoryArray.pnding_docs = answerJson[572];
    categoryArray.accessories = answerJson[573];
    categoryArray.customerName = answerJson[574];
    categoryArray.relation = answerJson[575].label;
    categoryArray.contact_no = answerJson[576];
    categoryArray.address = answerJson[577];
    categoryArray.exchange_again = answerJson[578];
    if (answerJson[580]) {
      var d1 = new Date(answerJson[580]);
      var date1 = d.getDate();
      var month1 = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      var year1 = d.getFullYear();
      var hr1 = d.getHours();
      var min1 = d.getMinutes();
      var sec1 = d.getSeconds();
      var dateStr1 =
        year1 +
        "-" +
        month1 +
        "-" +
        date1 +
        " " +
        hr1 +
        ":" +
        min1 +
        ":" +
        sec1;
      //console.log(dateStr)
      categoryArray.delivery_date = dateStr1;
    }
    // categoryArray.delivery_date = answerJson[287];
    categoryArray.exchange_bonus = answerJson[579];
    if (answerJson[581]) {
      categoryArray.sales_consultant = answerJson[581].label;
    }
    categoryArray.New_Car = answerJson[563];
    categoryArray.holding_amount = answerJson[566];
    categoryArray.final_payable = answerJson[568];
    categoryArray.buyerName = answerJson[582];
    //categoryArray.byer_sign = answerJson[295];
    categoryArray.buyer_remarks = answerJson[583];
    if (answerJson[584]) {
      categoryArray.incharge_name = answerJson[584].label;
    }
    //categoryArray.incharge_sign = answerJson[298];
    categoryArray.incharge_remarks = answerJson[585];
    if (answerJson[586]) {
      categoryArray.manager = answerJson[586].label;
    }
    categoryArray.manager_remarks = answerJson[587];
    //console.log(categoryArray);

    let result = await CmsContent.updateMaster(
      "tbl_Inventory_Sheet_Reproof",
      id,
      categoryArray
    );
    if (result) {
      await this.setState({
        answerJson: "",
        json: "",
        alertVisible: true,
        textalert: "Your form has been updated successfully",
        color: "success"
      });
      setTimeout(() => this.setState({ alertVisible: false }), 3000);
      this.process();
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }
  };
  formSubmit = async () => {
    // let error = [...this.state.error];
    // let e = [];
    // this.state.data.map((item, i) => {
    //   this.state.answerJson.map((element, key) => {
    //     if (
    //       item.required == 1 &&
    //       item.id == element.questionId &&
    //       (element.answer == null || element.answer == "-")
    //     ) {
    //       error[i] = "Value must not be empty";
    //       e.push(error[i]);
    //     } else if (
    //       item.required == 1 &&
    //       item.id == element.questionId &&
    //       (element.answer != null || element.answer != "-")
    //     ) {
    //       error[i] = null;
    //       e.push(error[i]);
    //     }
    //   });
    // });
    // this.setState({ error: error });
    // this.process();

    // for (var i = 0; i < e.length; i++) {
    //   if (e[i] != null) {
    //     return false;
    //   }
    // }
    const { answerJson } = this.state;
    let categoryArray = {};
    const formData = new FormData();
    // categoryArray.date = answerJson[260];
    if (answerJson[260]) {
      var d = new Date(answerJson[260]);
      var date = d.getDate();
      var month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      var year = d.getFullYear();
      var hr = d.getHours();
      var min = d.getMinutes();
      var sec = d.getSeconds();
      var dateStr =
        year + "-" + month + "-" + date + " " + hr + ":" + min + ":" + sec;
      //console.log(dateStr)
      // formData.append("date", dateStr);
      categoryArray.date = dateStr;
    }
    // formData.append("reg_ownerName", answerJson[547]);
    categoryArray.reg_ownerName = answerJson[547];
    //formData.append("reg_no", answerJson[548]);
    categoryArray.reg_no = answerJson[548];
    if (answerJson[549]) {
      //formData.append("make", answerJson[549].value)
      categoryArray.make = answerJson[549].value;
    }
    if (answerJson[550]) {
      categoryArray.model = answerJson[550].refid;
    }
    categoryArray.km = answerJson[554];
    if (answerJson[551]) {
      categoryArray.variant = answerJson[551].valid;
    }
    categoryArray.structural_flood = answerJson[557];
    categoryArray.model_year = answerJson[553];
    if (answerJson[558]) {
      categoryArray.service_histaory = answerJson[558].label;
    }
    categoryArray.ownership = answerJson[555];
    categoryArray.last_service_kms = answerJson[559];
    categoryArray.vin_no = answerJson[556];
    categoryArray.structural_flood = answerJson[557];
    categoryArray.eng_no = answerJson[560];
    categoryArray.buying_price = answerJson[561];
    categoryArray.inc_exp_date = answerJson[562];
    categoryArray.refurish_cost = answerJson[567];
    if (answerJson[564]) {
      categoryArray.hp = answerJson[564].label;
    }
    if (answerJson[627]) {
      categoryArray.modeofpayment = answerJson[627].label;
    }
    categoryArray.exp_selling_price = answerJson[569];
    categoryArray.final_payable = answerJson[568];
    categoryArray.preclosure_amt = answerJson[564];
    if (answerJson[565]) {
      categoryArray.acc_hold_noc = answerJson[565].label;
    }
    if (answerJson[570]) {
      categoryArray.vech_condition = answerJson[570].label;
    }
    categoryArray.enclosure = answerJson[571];
    categoryArray.pnding_docs = answerJson[572];
    categoryArray.accessories = answerJson[573];
    categoryArray.customerName = answerJson[574];
    if (answerJson[575]) {
      categoryArray.relation = answerJson[575].label;
    }
    categoryArray.contact_no = answerJson[576];
    categoryArray.address = answerJson[577];
    categoryArray.exchange_again = answerJson[578];
    if (answerJson[580]) {
      var d1 = new Date(answerJson[580]);
      var date1 = d.getDate();
      var month1 = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      var year1 = d.getFullYear();
      var hr1 = d.getHours();
      var min1 = d.getMinutes();
      var sec1 = d.getSeconds();
      var dateStr1 =
        year1 +
        "-" +
        month1 +
        "-" +
        date1 +
        " " +
        hr1 +
        ":" +
        min1 +
        ":" +
        sec1;
      //console.log(dateStr)
      categoryArray.delivery_date = dateStr1;
    }
    // categoryArray.delivery_date = answerJson[287];
    categoryArray.exchange_bonus = answerJson[579];
    if (answerJson[581]) {
      categoryArray.sales_consultant = answerJson[581].label;
    }
    categoryArray.buyerName = answerJson[582];
    categoryArray.New_Car = answerJson[563];
    categoryArray.holding_amount = answerJson[566];
    categoryArray.final_payable = answerJson[568];
    //  answerJson[563] = inv.New_Car;
    // answerJson[566] = inv.Holding_amount;
    //categoryArray.byer_sign = answerJson[295];
    categoryArray.buyer_remarks = answerJson[583];
    if (answerJson[584]) {
      categoryArray.incharge_name = answerJson[584].label;
    }
    //categoryArray.incharge_sign = answerJson[298];
    categoryArray.incharge_remarks = answerJson[585];
    if (answerJson[586]) {
      categoryArray.manager = answerJson[586].label;
    }
    categoryArray.manager_remarks = answerJson[587];

    categoryArray.customerid = this.state.CustomerId;
    categoryArray.userid = this.state.userid.id;
    categoryArray.status = "active";
    categoryArray.approval = 0;
    console.log(categoryArray);
    try {
      let result = await CmsContent.addMaster(
        "tbl_Inventory_Sheet_Reproof",
        categoryArray
      );
      if (result) {
        await this.setState({
          answerJson: "",
          json: "",
          alertVisible: true,
          textalert: "Your form has been submitted successfully",
          color: "success"
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
        this.process();
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  download = async (name) => {
    window.open(`${ACCESS_POINT}/cmsContent/downloadInventory/${name}`, '_self')
  }
  generatepdf = async (id, name) => {
    let exportpdf = await CmsContent.inventory("tbl_Inventory_Sheet_Reproof", id);
    console.log(exportpdf)
    if (exportpdf.data == "success") {
      await this.download(name)
    }
  }
  formSubmitPdf = async () => {
    // let error = [...this.state.error];
    // let e = [];
    // this.state.data.map((item, i) => {
    //   this.state.answerJson.map((element, key) => {
    //     if (
    //       item.required == 1 &&
    //       item.id == element.questionId &&
    //       (element.answer == null || element.answer == "-")
    //     ) {
    //       error[i] = "Value must not be empty";
    //       e.push(error[i]);
    //     } else if (
    //       item.required == 1 &&
    //       item.id == element.questionId &&
    //       (element.answer != null || element.answer != "-")
    //     ) {
    //       error[i] = null;
    //       e.push(error[i]);
    //     }
    //   });
    // });
    // this.setState({ error: error });
    // this.process();

    // for (var i = 0; i < e.length; i++) {
    //   if (e[i] != null) {
    //     return false;
    //   }
    // }
    const { answerJson } = this.state;
    let categoryArray = {};
    const formData = new FormData();
    // categoryArray.date = answerJson[260];
    if (answerJson[260]) {
      var d = new Date(answerJson[260]);
      var date = d.getDate();
      var month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      var year = d.getFullYear();
      var hr = d.getHours();
      var min = d.getMinutes();
      var sec = d.getSeconds();
      var dateStr =
        year + "-" + month + "-" + date + " " + hr + ":" + min + ":" + sec;
      //console.log(dateStr)
      // formData.append("date", dateStr);
      categoryArray.date = dateStr;
    }
    // formData.append("reg_ownerName", answerJson[547]);
    categoryArray.reg_ownerName = answerJson[547];
    //formData.append("reg_no", answerJson[548]);
    categoryArray.reg_no = answerJson[548];
    if (answerJson[549]) {
      //formData.append("make", answerJson[549].value)
      categoryArray.make = answerJson[549].value;
    }
    if (answerJson[550]) {
      categoryArray.model = answerJson[550].refid;
    }
    categoryArray.km = answerJson[554];
    if (answerJson[551]) {
      categoryArray.variant = answerJson[551].valid;
    }
    categoryArray.structural_flood = answerJson[557];
    categoryArray.model_year = answerJson[553];
    if (answerJson[558]) {
      categoryArray.service_histaory = answerJson[558].label;
    }
    categoryArray.ownership = answerJson[555];
    categoryArray.last_service_kms = answerJson[559];
    categoryArray.vin_no = answerJson[556];
    categoryArray.eng_no = answerJson[560];
    categoryArray.buying_price = answerJson[561];
    categoryArray.inc_exp_date = answerJson[562];
    categoryArray.refurish_cost = answerJson[567];
    if (answerJson[564]) {
      categoryArray.hp = answerJson[564].label;
    }
    if (answerJson[627]) {
      categoryArray.modeofpayment = answerJson[627].label;
    }
    categoryArray.exp_selling_price = answerJson[569];
    categoryArray.final_payable = answerJson[568];
    categoryArray.preclosure_amt = answerJson[564];
    if (answerJson[565]) {
      categoryArray.acc_hold_noc = answerJson[565].label;
    }
    if (answerJson[570]) {
      categoryArray.vech_condition = answerJson[570].label;
    }
    categoryArray.enclosure = answerJson[571];
    categoryArray.pnding_docs = answerJson[572];
    categoryArray.accessories = answerJson[573];
    categoryArray.customerName = answerJson[574];
    categoryArray.relation = answerJson[575].label;
    categoryArray.contact_no = answerJson[576];
    categoryArray.address = answerJson[577];
    categoryArray.exchange_again = answerJson[578];
    if (answerJson[580]) {
      var d1 = new Date(answerJson[580]);
      var date1 = d.getDate();
      var month1 = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      var year1 = d.getFullYear();
      var hr1 = d.getHours();
      var min1 = d.getMinutes();
      var sec1 = d.getSeconds();
      var dateStr1 =
        year1 +
        "-" +
        month1 +
        "-" +
        date1 +
        " " +
        hr1 +
        ":" +
        min1 +
        ":" +
        sec1;
      //console.log(dateStr)
      categoryArray.delivery_date = dateStr1;
    }
    // categoryArray.delivery_date = answerJson[287];
    categoryArray.exchange_bonus = answerJson[579];
    if (answerJson[581]) {
      categoryArray.sales_consultant = answerJson[581].label;
    }
    categoryArray.buyerName = answerJson[582];
    categoryArray.New_Car = answerJson[563];
    categoryArray.holding_amount = answerJson[566];
    categoryArray.final_payable = answerJson[568];
    //  answerJson[563] = inv.New_Car;
    // answerJson[566] = inv.Holding_amount;
    //categoryArray.byer_sign = answerJson[295];
    categoryArray.buyer_remarks = answerJson[583];
    if (answerJson[584]) {
      categoryArray.incharge_name = answerJson[584].label;
    }
    //categoryArray.incharge_sign = answerJson[298];
    categoryArray.incharge_remarks = answerJson[585];
    if (answerJson[586]) {
      categoryArray.manager = answerJson[586].label;
    }
    categoryArray.manager_remarks = answerJson[587];

    categoryArray.customerid = this.state.CustomerId;
    categoryArray.userid = this.state.userid.id;
    categoryArray.status = "active";
    categoryArray.approval = 0;
    console.log(categoryArray);
    try {
      let result = await CmsContent.addMaster(
        "tbl_Inventory_Sheet_Reproof",
        categoryArray
      );
      console.log(result)
      if (result) {
        // await this.generatepdf(result.data.insertId, answerJson[547])
        await this.generatepdf(this.state.CustomerId, answerJson[547])
        await this.setState({
          answerJson: "",
          json: "",
          alertVisible: true,
          textalert: "Your form has been submitted successfully",
          color: "success"
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
        this.process();
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });

      }
    } catch (error) {
      console.log(error);
    }
  };
  fileupload = async e => {
    let fileObj = [];
    let fileArray = [];
    fileObj.push(e.target.files);
    console.log(fileObj);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]));
    }
    console.log(fileArray);
    await this.setState({ file: fileArray });
  };
  // fileupload = async (e) => {
  //   //console.log(e.target.files.length)
  //   let len = e.target.files;
  //   let file = [];
  //   for (var i = 0; i < len[0].length; i++) {
  //     file.push(len[0][i])
  //   }
  //   console.log(file)
  //   await this.setState({ img: file })
  //   this.FileReader();
  // }
  FileReader = async () => {
    console.log(this.state.img);
    await this.state.img.map((ival, n) => {
      //console.log(ival)

      let reader = new FileReader();
      //let file = e.target.files[0];
      let file = this.state.img;
      let arr = [];
      reader.onloadend = () => {
        console.log(reader);
        //arr.push({ data: reader.result })

        this.setState({
          file: ival,
          imagePreview: reader.result
        });
      };
      reader.readAsDataURL(ival);
    });
  };
  newtab = e => {
    window.open(e, "_self");
  };
  exportpdf = async () => {
    let answerJson = this.state.answerJson;
    let name = answerJson[547]
    window.open(`${ACCESS_POINT}/cmsContent/downloadInventory/${name}`, '_self')
  }
  render() {
    const { resSelected, json, json2, button } = this.state;
    if (this.state.checkbox == true) {
      if (this.state.opt && this.state.closure) {
        this.state.opt.map((ival, k) => {
          this.state.closure.map((jval, j) => {
            var checkBox = document.getElementById(`inlineCheckbox${24}${k}`);
            //console.log(checkBox)
            if (ival == jval) {
              //checkBox.checked = true;
              if (checkBox != null) {
                checkBox.checked = true
              }
            }
          })
        })
      }
      // answerJson[450] = check.toString();
    }
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div
                  className="card"
                  style={{
                    margin: "-20px",
                    marginTop: "5px",
                    boxShadow: "0px 0px 10px #b9b3b3",
                    borderRadius: "8px"
                  }}
                >
                  <div className="card-header">
                    <h1>Inventory Sheet Reproof</h1>
                  </div>
                  <div className="card-body">
                    <div>
                      <Alert
                        className="badge-content"
                        color={this.state.color}
                        isOpen={this.state.alertVisible}
                        toggle={this.onDismiss}
                      >
                        {this.state.textalert}
                      </Alert>
                      {/* {json && json.length && json} */}
                   
                      {json && json.length ? (
                        <div>
                          <h3 style={{ textAlign: "center" }}>
                            Used Cars Details
                          </h3>
                          {json}{" "}
                        </div>
                      ) : <div>Loading...</div>}
                      {json2 && json2.length ? (
                        <div>
                          <h3 style={{ textAlign: "center" }}>
                            New Car Details
                          </h3>
                          {json2}
                        </div>
                      ) : null}

                      {/* <div className="bgcolor">
                          <div className="row form-group">
                            <div className="col-sm-2" />
                            <div className="col-sm-2">
                              <label>File Upload:</label>
                            </div>
                            <div className="col-sm-5">
                              <input
                                type="file"
                                className="form-control"
                                placeholder="Upload Files"
                                multiple
                                onChange={e => this.fileupload(e)}
                                style={{ padding: 2 }}

                              ></input>
                            </div>
                            <div className="col-sm-3">
                              {/* <span className="error-shows" id={`${index}`}>
                              {/* {this.state.error[index]} */}
                      {/* </span> */}
                      {/* </div>
                    </div> */}
                      {/* </div> */}
                      {json.length > 0 && (
                        <div>
                          {this.state.global && (
                            <div className="row form-group">
                              <div className="col-sm-2" />
                              <div className="col-sm-2" />
                              <div className="col-sm-5">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  style={{ minWidth: 100 }}
                                  onClick={
                                    button == "Save" ? this.formSubmit : this.update
                                  }
                                // onClick={this.formSubmit}
                                >
                                  {button == "Save" ? "Save" : "Update"}
                                </button>
                              </div>
                            </div>
                          )}
                          {this.state.global && (
                            <div className="row form-group">
                              <div className="col-sm-2" />
                              <div className="col-sm-2" />
                              <div className="col-sm-5">
                                {this.state.button == "Save" && (
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    //onClick={this.update}
                                    //onClick={this.formSubmit}
                                    onClick={
                                      button == "Save" ? this.formSubmitPdf : null
                                    }
                                  >
                                    {button == "Save" ? "Save and Export PDF" : null}
                                  </button>
                                )}
                              </div>
                            </div>
                          )}
                          {/* {this.state.global == false && (
                            <div className="row form-group">
                              <div className="col-sm-2" />
                              <div className="co  l-sm-2" />
                              <div className="col-sm-5">
                                <button
                                  style={{ marginLeft: "200px" }}
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => this.print()}
                                >
                                  Print
                                </button>
                              </div>
                            </div>
                          )} */}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
  // else {
  //   return <Preloader />
  // }
}

export default AT_Inventory_Sheet_Reproof;
