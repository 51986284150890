import React, { Component } from 'react';
import { Alert } from "reactstrap";
import LoginModal from "../../../../components/Modal/Modal";
import CmsContent from "../../../../MiddleWare/CmsContent";
import Datatable from "../../../../components/Datatable/Datatable";
import FormMiddleware from '../../../../components/Form/FormMiddleware'
import socketIOClient from 'socket.io-client';
import { ACCESS_POINT } from '../../../../config';
import Carousel from 'react-multi-carousel';
import moment from 'moment';
import Timerfuntion from './timers';
import { confirmAlert } from 'react-confirm-alert';

class ExternalForm extends FormMiddleware {
  socket = socketIOClient.connect(ACCESS_POINT);
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      lastIndex: 0,
      Alldata: [],
      userData: [],
      thisPage: 1,
      alertVisible: false,
      customerId: localStorage.getItem("userId"),
      usertypelog: JSON.parse(localStorage.getItem("usertypelog")),
      ServiceId: JSON.parse(localStorage.getItem("userlog")),
      column: [
        {
          Header: "Make",
          accessor: "make",
          Cell: (d) => this.getValueFromArray(d.original.make, this.state.MakeOptions)
        },
        {
          Header: "Model",
          accessor: "model",
          Cell: (d) => this.getValueFromArray(d.original.model, this.state.modelopt)
        },
        {
          Header: "Variant",
          accessor: "variant",
          Cell: (d) => this.getValueFromArray(d.original.variant, this.state.variantopt)
        },
        {
          Header: "Color",
          accessor: "color",
          Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)
        },
        {
          Header: "Bid Amount",
          accessor: "Bidamount"
        },
        {
          Header: "Year Of Manufacture",
          accessor: "year_of_manu"
        },
        {
          Header: "View Form",
          minWidth: 140,
          accessor: "viewmodel",
          Cell: (d) => this.formview(d.original)
        },
        // {
        //   Header: "Bid",
        //   accessor: "bid",
        //   Cell: d => this.auction(d)
        // }
      ],
      column2: [
        {
          Header: "Make",
          accessor: "make",
          Cell: (d) => this.getValueFromArray(d.original.make, this.state.MakeOptions)
        },
        {
          Header: "Model",
          accessor: "model",
          Cell: (d) => this.getValueFromArray(d.original.model, this.state.modelopt)
        },
        {
          Header: "Variant",
          accessor: "variant",
          Cell: (d) => this.getValueFromArray(d.original.variant, this.state.variantopt)
        },
        {
          Header: "Color",
          accessor: "color",
          Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)
        },
        {
          Header: "Bid Amount",
          accessor: "Bidamount"
        },
        {
          Header: "Year Of Manufacture",
          accessor: "year_of_manu"
        },
        {
          Header: "Status",
          minWidth: 140,
          accessor: "viewmodel",
          Cell: (d) => this.Statusview(d.original)
        },
        // {
        //   Header: "Bid",
        //   accessor: "bid",
        //   Cell: d => this.auction(d)
        // }
      ]
    }
    this.vals()
  }
  async vals() {
    let allOpt = await this.decryption('getcar')
    if (allOpt) {
      await this.setState({
        MakeOptions: allOpt.makeopt, coloropt: allOpt.coloropt, variantopt: allOpt.variantopt, modelopt: allOpt.modelopt
      })
    }
  }
  mainfun = async () => {
    let body = {}
    body.lastid = this.state.lastIndex;
    body.current = this.state.thisPage;
    let Alldata = await CmsContent.BUYDFCARZ("id", 'Auction', body)
    console.log(Alldata, "Alldata");
    if (Alldata) {

      await this.setState({ Alldata, data: Alldata.data, bidData: Alldata.biddata })
      let userData = []
      let data_user = []
      if (this.state.usertypelog.orderBy == 10) {
        Alldata.data.map((ival) => {
          if (ival.Auctionown == this.state.ServiceId.id || ival.Auction == 1) {
            data_user.push(ival)
          }
        })
        Alldata.data.map((ival) => {
          if (ival.Auctionown == this.state.ServiceId.id) {
            userData.push(ival)
          }
        })
        await this.setState({ userData, data: data_user })
      }
      // console.log(this.state.ServiceId, this.state.usertypelog);
    }
    // this.socket.emit('DfcarzBit', { id: 2, auction: true })
    // this.socket.emit('DfcarzBit', { id: 3, auction: true })

  }

  async componentDidMount() {
    //    console.log( this.state.ServiceId);
    this.mainfun()
  }

  async componentDidUpdate() {
    let { bidData } = this.state
    this.socket.on('DfcarzBid', async (Bid) => {
      if (Bid.type == "loadtimers") {
        let Alldata = await CmsContent.BUYDFCARZ("bid", 'Auction', { id: Bid.id })
        if (Alldata) {
          if (Bid.id && bidData && bidData[Bid.id]) {
            bidData[Bid.id] = Alldata.biddata
            this.setState({ bidData })
          }
        }
      } else if (Bid.type == "loadcarz") {
        this.mainfun()
      }
      if (Bid.time == 'true') {

      }
    })
  }

  getValueFromArray1 = (d, array) => {
    if (array.length > 0) {
      if (array.length !== 0) {
        let filtered = array.filter(function (item) {
          return item.value == d;
        });
        let v = filtered[0];
        if (v != undefined) {
          return v.label.split('(')[0];
        } else {
          return '-';
        }
      }
    } else {
      return '-';
    }
  };
  Statusview = (d) => {
    return (
      <button type="button" className={`btn btn-success btn-sm text-center`}
      >Won</button>
    )
  }
  formview = (d) => {
    return (
      <button type="button" className={`btn btn-${d.Auction == 1 ? "warning" : d.Auction == 2 ? "success" : "danger"} btn-sm text-center`}
      >
        {
          d.Auction == 1 ?
            "Auction in Live"
            : d.Auction == 2 ?
              `Auction Won`
              : d.Auction == 3 ?
                "Auction Rejected" : d.Auction == 4 ?
                  "Auction Rejected" : ""


        }
      </button>
    )
  }

  F_proofForm = async (d) => {
    //console.log(d);
    window.open(`/DFCars/Evaluation_Checklist_F_Proof/${d.original.customerid}/${true}`, '_blank')
  }
  auction = d => {
    console.log(d, "dddd");
    return (
      <div>
        <LoginModal
          buttonTitle="Bid"
          title="Bid"
          id={"auction" + d.id}
          extraClass="btn btn-primary"
          onClick={() => this.reset(d)}
          bodyText={
            <div>
              <div className="card-body">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-7">
                    <Alert
                      className="badge-content"
                      color={this.state.color}
                      isOpen={this.state.alertVisible}
                      toggle={this.onDismiss}
                    >
                      {this.state.textalert}
                    </Alert>
                  </div>
                  <div className="col-sm-3" />
                </div>
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="exampleInputEmail1">
                      Bid Amount
                    </label>
                  </div>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      className="form-control"
                      // id="registerName"
                      name="amount"
                      placeholder="Enter Amount"
                      value={this.state.amount}
                      onChange={(e) => this.handlechangeData(e)}
                    />
                    <div className="col-sm-3" />
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-8">
                    <center>
                      <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={() => this.submitauction(d)}
                      // style={{ marginLeft: "250px" }}
                      >Submit</button>
                    </center>
                  </div>
                  <div className="col-sm-2" />
                </div>
              </div>
            </div>
          }
        /></div >
    )
  }

  auction2 = (d, type) => {
    return (
      <div>
        <LoginModal
          buttonTitle={type == "activate" ? "Push price" : "Time expired"}
          title="Push Price"
          id={"auction" + d.id}
          extraClass="btn btn-sm btn-primary"
          onClick={() => this.resets(d)}
          bodyText={
            <div >
              <div className="card-body">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-7">
                    <Alert
                      className="badge-content"
                      color={this.state.color}
                      isOpen={this.state.alertVisible}
                      toggle={this.onDismiss}
                    >
                      {this.state.textalert}
                    </Alert>
                  </div>
                  <div className="col-sm-3" />
                </div>
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="exampleInputEmail1">
                      Push Amount
                    </label>
                  </div>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      className="form-control"
                      name="amount"
                      placeholder="Enter Amount"
                      value={this.state.amount}
                      onChange={this.handlechangeData}
                    />
                    <div className="col-sm-3" />
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="exampleInputEmail1">
                      Push Timer
                    </label>
                  </div>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      className="form-control"
                      name="timers"
                      placeholder="Enter Minutes"
                      value={this.state.timers}
                      onChange={this.handlechangeData}
                    />
                    <div className="col-sm-3" />
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-8">
                    <center>
                      <button
                        type="button"
                        style={{ marginRight: 20 }}
                        className="btn btn-sm btn-primary"
                        onClick={() => this.submitauction1(d, type == "activate" ? "fake" : "timeexpired")}
                      >Submit</button>
                    </center>
                  </div>
                  <div className="col-sm-2" />
                </div>
              </div>
            </div>
          }
        /></div >
    )
  }
  reset = async (d) => {
    // console.log(d.original);
    this.setState({
      fproof_id_of_d: d.id,
      color: "",
      alertVisible: false,
      textalert: ""
    })
  }
  resets = async (d) => {
    // console.log(d.original);
    this.setState({ fproof_id_of_d: d.id })
  }
  auction3 = (d, type) => {
    let dat = ''
    if (type == 'Successfull') {
      if (this.state.bidData && this.state.bidData[d.id].customerid == "Price push") {
        dat = <h4>Higher bid genenrated by price push?</h4>
      } else {
        dat = <h4>Are you sure you want to approve this ?</h4>
      }
    } else {
      dat = <h4>Are you sure you want to approve this ?</h4>
    }
    return <button class={`btn btn-sm btn-${type == 'Successfull' ? 'success' : 'danger'}`} onClick={() => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return <div style={{ textAlign: "center" }}>
            <h1>Confirmation</h1>
            {dat}
            <br />
            <button className="btn btn-sm btn-primary" onClick={() => {
              if (type == 'Successfull') {
                this.submitauction1(d, 1);
              } else {
                this.submitauction1(d, 2);
              }
              onClose();
            }}  >Yes</button> &nbsp;  &nbsp;
            <button className="btn btn-sm btn-danger" onClick={onClose} >No</button>
          </div>
        }
      })
    }}>{type == "Successfull" ? "sold" : type}</button>

  };
  onDismiss = () => {
    this.setState({ alertVisible: false, formAlertdelete: false });
  };
  submitauction1 = async (d, type) => {
    const { amount, customerId,
      ServiceId,
      fproof_id_of_d
    } = this.state;
    let CategoryArray = {}
    try {
      if (type == 'fake') {
        CategoryArray.new_bid_amount = parseInt(amount);
        CategoryArray.customerid = "Price push"
        CategoryArray.fproof_id = d.id
        CategoryArray.ServiceId = ServiceId.serviceId;
        CategoryArray.Time = this.state.timers
        let result = await CmsContent.addMaster(
          "tbl_bid",
          CategoryArray
        );
        if (result) {
          this.socketfunction(d.id)
          this.setState({
            amount: "",
            timers: "",
            alertVisible: true,
            color: "success",
            textalert: "Your Bid has been submitted successfully",
          })

        }
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
        window.location.reload()
      }
      else if (type == "timeexpired") {
        var minutes = 0;
        if (this.state.bidData[d.id].Timer) {
          minutes = Math.floor(-this.state.bidData[d.id].Timer / 60)
        }
        CategoryArray.new_bid_amount = parseInt(amount);
        CategoryArray.customerid = "Price push"
        CategoryArray.fproof_id = d.id
        CategoryArray.ServiceId = ServiceId.serviceId;
        CategoryArray.Time = parseInt(this.state.timers) + minutes
        let result = await CmsContent.addMaster(
          "tbl_bid",
          CategoryArray
        );
        if (result) {
          this.socketfunction(d.id)
          this.setState({
            amount: "",
            timers: "",
            alertVisible: true,
            color: "success",
            textalert: "Your Bid has been submitted successfully",
          })

        }
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
        window.location.reload()
      }

      else {
        if (type == 1) {
          CategoryArray.status = 2
          CategoryArray.Auction = 2
          CategoryArray.id = d.id
          CategoryArray.data = this.state.bidData[d.id]
        } else if (type == 2) {
          CategoryArray.status = 1
          CategoryArray.Auction = 3
          CategoryArray.id = d.id
        }
        let Alldata = await CmsContent.BUYDFCARZ("submit", 'Auction', CategoryArray)
        if (Alldata) {
          this.socket.emit('DfcarzBid', { "auction": true, "id": d.id })
          // this.mainfun()
        }
      }
    } catch (error) {
      console.log(error);
    }

  }
  submitauction = async d => {
    const { amount, customerId,
      ServiceId,
      bidData,
      fproof_id_of_d
    } = this.state;
    if (amount < this.state.bidData[d.id].high) {
      await this.setState({
        color: "danger",
        alertVisible: true,
        textalert: "Your Bid Amount should not be lesser than higer Bid Amount!"
      })
    } else if (amount == this.state.bidData[d.id].high) {
      await this.setState({
        color: "danger",
        alertVisible: true,
        textalert: "Your Bid Amount should not be equal to higer Bid Amount!"
      })
    } else {
      let CategoryArray = {};
      CategoryArray.new_bid_amount = parseInt(amount);
      CategoryArray.customerid = ServiceId.id;
      CategoryArray.fproof_id = fproof_id_of_d;
      CategoryArray.ServiceId = ServiceId.serviceId;
      console.log(CategoryArray);
      try {
        let result = await CmsContent.addMaster(
          "tbl_bid",
          CategoryArray
        );
        if (result) {

          this.socketfunction(fproof_id_of_d)
          this.setState({
            amount: "",
            alertVisible: true,
            color: "success",
            textalert: "Your Bid has been submitted successfully",

          })
        }
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });

      } catch (error) {
        console.log(error);
      }
    }
  }
  handlechangeData = async e => {
    if (e.target.name) {
      await this.setState({ [e.target.name]: e.target.value });
    }

  };
  socketfunction = async (id) => {
    let body = {}
    body.submit = true
    body.id = id
    this.socket.emit('DfcarzBid', body)
  }
  page = async (p) => {
    let thisPage = this.state.thisPage
    if ('back' == p) {
      thisPage = this.state.thisPage - 1
    } else if ('next' == p) {
      thisPage = this.state.thisPage + 1
    } else {
      thisPage = p
    }
    await this.setState({ thisPage })
    await this.mainfun()
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  BidShare = async (d) => {
    window.open(`/DFCars/BidShare/${d.customerid}`)
  }
  render() {
    let { Alldata, thisPage, usertypelog } = this.state;
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 600 },
        items: 1,
        slidesToSlide: 1
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
        slidesToSlide: 1
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1
      }
    };
    let Pagearray = []
    if (Alldata && Alldata.totalpage) {
      for (let index = 1; index <= Alldata.totalpage; index++) {
        if (index == 1 && index !== Alldata.current) {
          Pagearray.push(
            <li class={`page-item ${thisPage == index ? "active" : ''} `}><a class="page-link" href="#" onClick={() => { this.page(index) }}>{index}</a></li>
          )
        } else if (index == Alldata.current) {
          if (2 <= index - 1) {
            Pagearray.push(<li class='inactive' onClick={() => { this.page(index - 1) }} ><a>{`<<`}</a></li>)
          }
          Pagearray.push(
            <li class={`page-item ${thisPage == index ? "active" : ''} `}>  <a class="page-link" href="#" onClick={() => { this.page(index) }}> {index} </a>  </li>
          )
          if (Alldata.totalpage > index + 1) {
            Pagearray.push(<li class='inactive' onClick={() => { this.page(index + 1) }} ><a>{`>>`}</a></li>)
          }
        } else if (index == Alldata.totalpage && index !== Alldata.current) {
          Pagearray.push(
            <li class={`page-item ${thisPage == index ? "active" : ''} `}><a class="page-link" href="#" onClick={() => { this.page(index) }}>{index}</a></li>
          )
        }
      }
    }
    return (
      <React.Fragment>
        <main className="main my-10">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <ul class="nav nav-tabs" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" href="#tabs-1" role="tab">Live Auction</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#tabs-2" role="tab">View All Auction</a>
                  </li>

                </ul>
                <div class="tab-content">
                  <div class="tab-pane active" id="tabs-1" role="tabpanel">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="card">
                          <div className="card-header">
                            <h3>Live Auction Car List</h3>
                          </div>
                          <div className="card-body">
                            {/* <button onClick={() => { this.checl() }}>View</button> */}
                            {this.state.data && this.state.data.length > 0 && (
                              <>
                                {/* <Datatable
                          data={this.state.data}
                          columnHeading={this.state.column}
                        /> */}
                                {this.state.data.map((ival, i) => {
                                  let datavals = ''
                                  if (this.state.bidData[ival.id] && this.state.bidData[ival.id].status == 'live' && (this.state.ServiceId.userType == 160 || this.state.ServiceId.userType == "160")) {
                                    datavals = <tr>
                                      <td><Timerfuntion seconds={this.state.bidData[ival.id].Timer} expired={() => this.socketfunction(ival.id)} /></td >
                                      <th>{this.auction(ival)}</th>
                                      <td colSpan={2}>{parseInt(this.state.ServiceId.id) == parseInt(this.state.bidData[ival.id].customerid) ? "Your higher Bidder" : ""}</td>
                                    </tr>
                                  } else if (this.state.bidData[ival.id] && this.state.bidData[ival.id].status == 'live') {
                                    datavals = <>
                                      <tr>
                                        <td colSpan={2} style={{ textAlign: "center" }}><Timerfuntion seconds={this.state.bidData[ival.id].Timer} expired={() => this.socketfunction(ival.id)} /></td >
                                        <td></td>
                                        <td></td>
                                      </tr>
                                      <tr>
                                        <th>{this.auction2(ival, "activate")}</th>
                                        <td></td>
                                        <td>{this.auction3(ival, "Close Bid")}</td>
                                        <td>{(this.state.bidData && this.state.bidData[ival.id].customerid == "Price push") ? "Price pushed" : this.auction3(ival, "Successfull")}</td>
                                      </tr>
                                    </>
                                  } else if (this.state.bidData[ival.id] && this.state.bidData[ival.id].status == 'Closed' && (this.state.ServiceId.userType == 160 || this.state.ServiceId.userType == "160")) {
                                    datavals =
                                      <>
                                        <tr>

                                          <th>{parseInt(this.state.ServiceId.id) == parseInt(this.state.bidData[ival.id].customerid) ? "" : "Time Expired"}</th>
                                          <th>{parseInt(this.state.ServiceId.id) == parseInt(this.state.bidData[ival.id].customerid) ? "Car owned" : ""}</th>
                                          <th colSpan={2}>{parseInt(this.state.ServiceId.id) == parseInt(this.state.bidData[ival.id].customerid) ? "Waiting for approval" : ""}</th>

                                        </tr></>

                                  } else if (this.state.bidData[ival.id] && this.state.bidData[ival.id].status == 'Closed') {
                                    datavals =
                                      <>
                                        <tr>
                                          <td style={{ color: "red", fontWeight: "600" }}>
                                            {
                                              moment.utc((-this.state.bidData[ival.id].Timer) * 1000).format('HH') + " Hours "
                                            }
                                          </td>
                                        </tr>
                                        <tr>
                                          {/* <td><Timerfuntion seconds={(this.state.bidData[ival.id].Timer)} expired={() => this.socketfunction(ival.id)} /></td > */}
                                          <th>{this.auction2(ival, "")}</th>
                                          <th>Time Expired</th>
                                          <td>{this.auction3(ival, "Close Bid")}</td>
                                          <td>{(this.state.bidData && this.state.bidData[ival.id].customerid == "Price push") ? "Price pushed" : this.auction3(ival, "Successfull")}</td>
                                        </tr>
                                      </>
                                  }
                                  return (<>
                                    <div className="row">
                                      <div className="col-sm-1" />
                                      <div className="col-sm-4">
                                        <Carousel
                                          draggable={false}
                                          showDots={true}
                                          arrows={false}
                                          responsive={responsive}
                                          ssr={true}
                                          infinite={true}
                                          autoPlay={true}
                                          autoPlaySpeed={1000}
                                          keyBoardControl={true}
                                          customTransition="all .5"
                                          transitionDuration={1000}
                                        // containerClass="carousel-container"
                                        // dotListClass="custom-dot-list-style"
                                        >
                                          {ival.Fileslist && ival.Fileslist.map((file => {
                                            return <div style={{ alignSelf: "center" }}>
                                              <center>
                                                {file &&
                                                  <img style={{ width: 400, height: 300 }}
                                                    src={ACCESS_POINT + "/superAdmin/file?fileurl=" + file}
                                                    onError={({ currentTarget }) => {
                                                      currentTarget.onerror = null;
                                                      currentTarget.src = "https://steerimages.difuza.com/DFCARS_daa63c54_4b15d8d6_1662965206825.jpg";
                                                    }}
                                                  />}
                                              </center>
                                            </div>
                                          }))}
                                        </Carousel>
                                        <br />
                                        <center>
                                          <span><b>{this.getValueFromArray(ival.make, this.state.MakeOptions)} </b>
                                            {this.getValueFromArray1(ival.model, this.state.modelopt)} {this.getValueFromArray(ival.variant, this.state.variantopt)}
                                          </span>
                                        </center>
                                      </div>
                                      <div className="col-sm-6">
                                        <table class="table table-responsive">
                                          <tr><th>Color : </th>
                                            <td>{this.getValueFromArray(ival.color, this.state.coloropt)}</td>
                                            <th>Owner : </th><td>{ival.ownership_no}</td>
                                          </tr>
                                          <tr>
                                            <th>Year : </th><td>{ival.year_of_manu}</td>
                                            <th>Km : </th><td>{ival.km_travel}</td>
                                          </tr>
                                          <tr>
                                            <th>Fuel : </th><td>{ival.fueloption}</td>
                                            <th>Bid's count : </th><td>{this.state.bidData && isNaN(this.state.bidData[ival.id]) ? "0" : this.state.bidData[ival.id].count - 1}</td>
                                          </tr>
                                          <tr>
                                            <th>Start Bit</th><td>{ival.Bidamount}</td>
                                            <th>Higher Bid</th><td>{this.state.bidData[ival.id] && this.state.bidData[ival.id].high ? this.state.bidData[ival.id].high : ival.AuctionPrice ? ival.AuctionPrice : ""}</td>
                                          </tr>
                                          {datavals}

                                        </table>
                                        <center>
                                          <button class="btn btn-sm btn-primary d-flex justify-content-between"
                                            onClick={() => this.BidShare(ival)}
                                          >Share Auction</button>
                                        </center>

                                        <br />
                                        {
                                          ival.Auction == "1" ?
                                            <h3 style={{ color: "#fc4c0d" }}>Auction in Live</h3>
                                            : ival.Auction == "2" ?
                                              <h3 style={{ color: "#07278f" }}>{ival.auctionowner ? ival.auctionowner : ""} won this auction for ₹{ival.AuctionPrice ? ival.AuctionPrice : ""}.</h3>
                                              : ival.Auction == "3" ?
                                                <h3 style={{ color: "#fc4c0d" }}>Auction Rejected</h3>
                                                :
                                                ival.Auction == "4" ?
                                                  <h3 style={{ color: "#fc4c0d" }}>Auction Rejected</h3>
                                                  :
                                                  ""
                                        }

                                      </div>
                                      <div className="col-1" />
                                    </div>
                                    <hr />
                                  </>)
                                })}
                                <br />
                                <div className="row">

                                  <div className="col-sm-1" />
                                  <div className="col-sm-10">
                                    <center>
                                      <nav aria-label="Page navigation example">
                                        <ul class="pagination justify-content-center">
                                          <li class={`page-item ${Alldata.isfirst ? 'disabled' : ''}`} >
                                            <a class="page-link" href="#" tabindex="-1" onClick={() => { this.page("back") }}>&laquo;</a>
                                          </li>
                                          {Pagearray}
                                          <li class={`page-item ${Alldata.islast ? 'disabled' : ''}`} >
                                            <a class="page-link" href="#" onClick={() => { this.page("next") }} >&raquo;</a>
                                          </li>
                                        </ul>
                                      </nav>
                                    </center>
                                  </div>
                                  <div className="col-sm-1" />
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="tabs-2" role="tabpanel">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="card">
                          <div className="card-header">
                            <h3>View Auction Car List</h3>
                          </div>
                          <div className="card-body">
                            {usertypelog.orderBy == 10 ?
                              this.state.userData.length > 0 ?
                                <Datatable
                                  data={this.state.userData}
                                  columnHeading={this.state.column2}
                                /> :
                                <h3 style={{ color: "#07278f" }}>You won't win anything</h3>
                              :
                              this.state.data.length > 0 ?
                                <Datatable
                                  data={this.state.data}
                                  columnHeading={this.state.column}
                                />
                                :
                                <h3 style={{ color: "#07278f" }}>You won't Auction Live anything</h3>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>


      </React.Fragment >
    )
  }
}

export default ExternalForm;