import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import { ACCESS_POINT } from "../../../../config"
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import Camera from "../../../../MiddleWare/camera";
import { MDBIcon } from "mdbreact";
import { FilePond, File, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import iconClose from "../../../../images/CMS/icon-hamburger-close.svg";
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
const ImgSTEERAUTO = 'https://steerimages.rem.coach/';
const Homeimg1 = 'https://steerimages.difuza.com/DFCARS_daa63c54_4b15d8d6_1662965206825.jpg';


class DfCarsImageUpload extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            editid: null,
            User: JSON.parse(localStorage.getItem("userlog")),
            button_loading: true,
            filesContent: {
                LHS: [{ name: "", files: [] }],
                RHS: [{ name: "", files: [] }],
                FRONT: [{ name: "", files: [] }],
                BACK: [{ name: "", files: [] }]
            },
            imagesname: {
                LHS: [
                    { label: "Right Front Diagonal", show: true, value: 1 },
                    { label: "Front", show: true, value: 2 },
                    { label: "Right Side View", show: true, value: 3 },
                    { label: "Right Back Diagonal", show: true, value: 4 },
                    { label: "Rear/Back", show: true, value: 5 },
                    { label: "Left Back Diagonal", show: true, value: 6 },
                    { label: "Left side", show: true, value: 7 },
                    { label: "Left Front Diagonal", show: true, value: 8 }
                ],

                RHS: [
                    { label: "Open Bonot", show: true, value: 9 },
                    { label: "Right Front wheel", show: true, value: 10 },
                    { label: "Right Rear wheel", show: true, value: 11 },
                    { label: "Spare Tyre", show: true, value: 12 },
                    { label: "Left Front Wheel", show: true, value: 13 },
                    { label: "Left Rear Wheel", show: true, value: 14 },
                    { label: "Rear Under Body", show: true, value: 15 }],

                FRONT: [
                    { label: "Dashboard", show: true, value: 16 },
                    { label: "Odometre", show: true, value: 17 },
                    { label: "Infotainment System", show: true, value: 18 },
                    { label: "Right side Front cabin", show: true, value: 19 },
                    { label: "Right side rear Door Cabin", show: true, value: 20 }],
                BACK: [
                    { label: "Driverside Door Panels contol", show: true, value: 21 },
                    { label: "Boot Inside", show: true, value: 22 },
                    { label: "Key Closeup", show: true, value: 23 },
                    { label: "Left side Front cabin", show: true, value: 24 },
                    { label: "Left side rear Door Cabin", show: true, value: 25 }]

            },
            vehicleImg: [],
            alertVisible: false
        }
    }

    async componentWillReceiveProps(props) {
        try {
            console.log(props, "propss");
            if (props && props.EditData && props.EditData.id) {
                this.buttonEdit(props.EditData)
            }
        } catch (error) {
            console.log(error);
        }
    }
    buttonEdit = async (value) => {
        try {
            let editdata = value
            console.log(editdata, "editdataeditdataeditdataeditdata", value);
            if (editdata.source == "web" && !editdata.image) {
                editdata.filesArray = JSON.stringify({
                    LHS: [{ name: "", files: [] }],
                    RHS: [{ name: "", files: [] }],
                    FRONT: [{ name: "", files: [] }],
                    BACK: [{ name: "", files: [] }]
                },)
            }
            let filesContent = this.state.filesContent;
            if (editdata.filesArray) {
                this.imgFun('multi', "vehicleImg", "filesContent2", 'filesArray', editdata)
                let obj = JSON.parse(editdata.filesArray)
                if (obj) {
                    let w = await Object.keys(obj).map((key, i) => {
                        if (obj[key].length) {
                            obj[key].map((ival) => {
                                ival.files = []
                            })
                        }
                    })
                    await Promise.all(w)
                    filesContent = obj
                }
            }
            let original_imagesname = {
                LHS: [
                    { label: "Right Front Diagonal", show: true, value: 1 },
                    { label: "Front", show: true, value: 2 },
                    { label: "Right Side View", show: true, value: 3 },
                    { label: "Right Back Diagonal", show: true, value: 4 },
                    { label: "Rear/Back", show: true, value: 5 },
                    { label: "Left Back Diagonal", show: true, value: 6 },
                    { label: "Left side", show: true, value: 7 },
                    { label: "Left Front Diagonal", show: true, value: 8 }
                ],

                RHS: [
                    { label: "Open Bonot", show: true, value: 9 },
                    { label: "Right Front wheel", show: true, value: 10 },
                    { label: "Right Rear wheel", show: true, value: 11 },
                    { label: "Spare Tyre", show: true, value: 12 },
                    { label: "Left Front Wheel", show: true, value: 13 },
                    { label: "Left Rear Wheel", show: true, value: 14 },
                    { label: "Rear Under Body", show: true, value: 15 }],

                FRONT: [
                    { label: "Dashboard", show: true, value: 16 },
                    { label: "Odometre", show: true, value: 17 },
                    { label: "Infotainment System", show: true, value: 18 },
                    { label: "Right side Front cabin", show: true, value: 19 },
                    { label: "Right side rear Door Cabin", show: true, value: 20 }],
                BACK: [
                    { label: "Driverside Door Panels contol", show: true, value: 21 },
                    { label: "Boot Inside", show: true, value: 22 },
                    { label: "Key Closeup", show: true, value: 23 },
                    { label: "Left side Front cabin", show: true, value: 24 },
                    { label: "Left side rear Door Cabin", show: true, value: 25 }]

            }

            Object.keys(original_imagesname).map((orikey) => {
                original_imagesname[orikey].map((ival) => {
                    filesContent[orikey].map((jval) => {
                        if (ival && ival.label == jval.name) {
                            ival.show = false
                        }
                    })

                })
            })
            let vehiclejsonArray = []
            if (editdata.filesArray) {
                let obj = JSON.parse(editdata.filesArray)
                let array = Object.values(obj).map(e => e && e.length && e.map(x => x.files))
                vehiclejsonArray = array.flat(Infinity)

            }

            await this.setState({
                editdata,
                button: "Update",
                active: 1,
                imagesname: original_imagesname,
                editid: editdata.id,
                vehiclejson: editdata.filesArray ? JSON.parse(editdata.filesArray) : null,
                filesContent2: editdata.filesArray ? JSON.parse(editdata.filesArray) : null,
                filesContent,
                button_loading: false,
                vehiclejsonArray
            })
        } catch (error) {
            console.log(error);
        }
    }
    removeimg = async (key, i, section, file, type = 'single', jsonKey = null, j = null) => {
        let images = null
        let img = null

        images = this.state[section] //images field
        img = this.state[file] //old file field
        if (type == 'multi') {
            if (images && img) {
                images[jsonKey] && images[jsonKey][i] && images[jsonKey][i].files.splice(j, 1)
                img[jsonKey] && img[jsonKey][i] && img[jsonKey][i].files.splice(j, 1)
                await this.setState({ [section]: images, [file]: img })
                this.imgFun(type, section, file, key)

            }
        }

    }
    imgFun = async (type, imagestate, jsonstate, field = null, d = null) => {
        let { imagesname } = this.state
        let ImageArray = []
        let images = d && d[field] ? JSON.parse(d[field]) : this.state[jsonstate];
        if (type == 'multi') {
            ImageArray = {}
            if (images) {
                await Object.keys(images).map(async (key) => {
                    ImageArray[key] = []
                    let w = await images[key].map(async (ival, i) => {
                        ImageArray[key][i] = {}
                        ImageArray[key][i].name = ival.name
                        ImageArray[key][i].files = []
                        if (ival.files && ival.files.length > 0) {
                            ival.files.map((kval, j) => {
                                ImageArray[key][i].files.push(<div className="col-md-4 text-center">
                                    <div style={{ paddingLeft: '160px', position: 'relative' }} >
                                        <i className="fa fa-window-close fa-3x"
                                            aria-hidden="true"
                                            style={{ height: 39, width: 40, position: 'absolute', background: 'white' }}
                                            onClick={() => this.removeimg(field, i, imagestate, jsonstate, 'multi', key, j)}
                                        />
                                    </div>
                                    <img src={ImgSTEERAUTO + kval}
                                        style={{
                                            objectFit: "contain",
                                            width: "200px", height: "200px", display: "inline-flex", padding: "10px"
                                        }}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = "https://steerimages.difuza.com/DFCARS_daa63c54_4b15d8d6_1662965206825.jpg";
                                        }}
                                        onClick={() => { window.open(ImgSTEERAUTO + kval) }} />
                                </div>
                                )
                            })
                        }
                    })
                    await Promise.all(w)
                })


            }
        }
        this.setState({ [imagestate]: ImageArray })
    }
    vehiclenameupload = (e, i, key, type) => {
        console.log(e, i, key);
        let { imagesname, filesContent, filesContent2, } = this.state
        let original_imagesname = {
            LHS: [
                { label: "Right Front Diagonal", show: true, value: 1 },
                { label: "Front", show: true, value: 2 },
                { label: "Right Side View", show: true, value: 3 },
                { label: "Right Back Diagonal", show: true, value: 4 },
                { label: "Rear/Back", show: true, value: 5 },
                { label: "Left Back Diagonal", show: true, value: 6 },
                { label: "Left side", show: true, value: 7 },
                { label: "Left Front Diagonal", show: true, value: 8 }
            ],

            RHS: [
                { label: "Open Bonot", show: true, value: 9 },
                { label: "Right Front wheel", show: true, value: 10 },
                { label: "Right Rear wheel", show: true, value: 11 },
                { label: "Spare Tyre", show: true, value: 12 },
                { label: "Left Front Wheel", show: true, value: 13 },
                { label: "Left Rear Wheel", show: true, value: 14 },
                { label: "Rear Under Body", show: true, value: 15 }],

            FRONT: [
                { label: "Dashboard", show: true, value: 16 },
                { label: "Odometre", show: true, value: 17 },
                { label: "Infotainment System", show: true, value: 18 },
                { label: "Right side Front cabin", show: true, value: 19 },
                { label: "Right side rear Door Cabin", show: true, value: 20 }],
            BACK: [
                { label: "Driverside Door Panels contol", show: true, value: 21 },
                { label: "Boot Inside", show: true, value: 22 },
                { label: "Key Closeup", show: true, value: 23 },
                { label: "Left side Front cabin", show: true, value: 24 },
                { label: "Left side rear Door Cabin", show: true, value: 25 }]

        }

        if (e && type == "select") {
            if (filesContent && filesContent[key] && filesContent[key][i]) {
                let name = imagesname[key].filter(x => x.value == e)[0]
                filesContent[key][i].name = name && name.label ? name.label : ""
                filesContent2[key][i].name = name && name.label ? name.label : ""

                Object.keys(original_imagesname).map((orikey) => {
                    original_imagesname[orikey].map((ival) => {
                        filesContent[orikey].map((jval) => {
                            if (ival && ival.label == jval.name) {
                                ival.show = false
                            }
                        })

                    })
                })


            }
            // console.log(imagesname, 'imagesname');
            this.setState({ filesContent, imagesname: original_imagesname })
        } else {
            if (filesContent && filesContent[key] && filesContent[key][i]) {
                let name = e ? e : ""
                let process = true
                if (e) {
                    Object.keys(filesContent).map((key) => {
                        filesContent[key].map((ival) => {
                            if (ival.name == name) {
                                process = false
                            }
                        })
                    })

                }
                if (process) {
                    filesContent[key][i].name = name
                    filesContent2[key][i].name = name
                    Object.keys(original_imagesname).map((orikey) => {
                        original_imagesname[orikey].map((ival) => {
                            filesContent[orikey].map((jval) => {
                                if (ival && ival.label == jval.name) {
                                    ival.show = false
                                }
                            })

                        })
                    })
                }
                this.setState({ filesContent, filesContent2, imagesname: original_imagesname })
            }
        }
    }
    vehicleimageUpload = (e, side, i) => {
        let { filesContent } = this.state;

        if (e) {
            if (filesContent[side][i].files) {
                if (e.map(fileItem => fileItem.file)) {
                    let file = e.map(fileItem => fileItem.file)
                    if (file && file.length > 0) {
                        filesContent[side][i].files = file
                    } else {
                        filesContent[side][i].files = []
                    }
                }
                this.setState({ filesContent });
            }
        }

    }
    addMore = async (type = null, i, key) => {
        let { filesContent, filesContent2, imagesname } = this.state;


        if (type) {

            if (imagesname) {
                imagesname[key].map((ival) => {
                    if (ival && filesContent && filesContent[key][i] && filesContent[key][i].name && ival.label == filesContent[key][i].name) {
                        ival.show = true
                    }
                })
                this.setState({ imagesname })
            }
            if (filesContent[key] && filesContent[key].length > 1) {
                filesContent[key].splice(i, 1)
            } else if (filesContent[key] && filesContent[key].length == 1) {
                filesContent[key].splice(i, 1, { name: '', files: [] })
            }

            if (filesContent2[key] && filesContent2[key].length > 1) {
                filesContent2[key].splice(i, 1)
            } else if (filesContent2[key] && filesContent2[key].length == 1) {
                filesContent2[key].splice(i, 1, { name: '', files: [] })
            }


        } else {
            if (filesContent[key] && filesContent[key].length > 0) {
                filesContent[key].push({ name: '', files: [] })
            }
            if (filesContent2[key] && filesContent2[key].length > 0) {
                filesContent2[key].push({ name: '', files: [] })
            }
        }
        await this.setState({ filesContent, filesContent2 })
        this.imgFun('multi', "vehicleImg", "filesContent2", 'filesArray')
    }
    submit = async () => {
        let { filesContent, filesContent2, vehiclejsonArray, editid, Certified, } = this.state
        try {
            await this.setState({ button_loading: true })
            let array = {}
            const formData = new FormData()
            if (filesContent && typeof filesContent === "object") {
                let obj = Object.values(filesContent).map(e => e && e.length && e.map(x => x.files))
                let newArray = obj.flat(Infinity)
                if (newArray && newArray.length) {
                    let val = newArray.every(x => x.size <= 300000)
                    if (!val) {
                        this.setState({ button_loading: false })
                        alert("Please Check Car Image is Greater than 300KB")
                        return true
                    }
                }
            }


            formData.append(`image`, 1)
            if (this.state.filesContent) {
                if (this.state.filesContent.LHS) {
                    this.state.filesContent.LHS.map((ival, i) => {
                        ival.files.map((jval) => {
                            if (jval) {
                                formData.append(`${ival.name}`, jval);
                            }
                        })
                    })
                }
            }
            if (this.state.filesContent) {
                if (this.state.filesContent.RHS) {
                    this.state.filesContent.RHS.map((ival, i) => {
                        ival.files.map((jval) => {
                            if (jval) {
                                formData.append(`${ival.name}`, jval);
                            }
                        })
                    })
                }
            }
            if (this.state.filesContent) {
                if (this.state.filesContent.FRONT) {
                    this.state.filesContent.FRONT.map((ival, i) => {
                        ival.files.map((jval) => {
                            if (jval) {
                                formData.append(`${ival.name}`, jval);
                            }
                        })
                    })
                }
            }
            if (this.state.filesContent) {
                if (this.state.filesContent.BACK) {
                    this.state.filesContent.BACK.map((ival, i) => {
                        ival.files.map((jval) => {
                            if (jval) {
                                formData.append(`${ival.name}`, jval);
                            }
                        })
                    })
                }
            }


            if (filesContent2) {
                formData.append('filesArray', JSON.stringify(filesContent2))
            }
            if (filesContent2 && vehiclejsonArray && vehiclejsonArray.length) {
                let obj = Object.values(filesContent2).map(e => e && e.length && e.map(x => x.files))
                let newArray = obj.flat(Infinity)
                const DeleteArray = vehiclejsonArray.filter(x => !newArray.some(y => y == x))
                if (DeleteArray && DeleteArray.length) {
                    formData.append('deleteArray', JSON.stringify(DeleteArray))
                }
            }


            let id = editid
            console.log(editid, "editid");
            let result = await cmsContent.SteerVehicleFileUpload("tbl_Dfcars_Garage", formData, id, "SteerAuto_Cars");
            if (result) {
                // window.$("#thanks").modal("show")


                setTimeout(async () => {
                    window.location.reload()
                }, 3000);

            } else {
                await this.setState({ button_loading: false })
            }

        } catch (error) {
            await this.setState({ button_loading: false })
            console.log(error)
        }
    }
    render() {

        let { filesArray, filesContent, vehicleImg, imagesname, button_loading } = this.state;
        return (
            <React.Fragment>
                <main >
                    <div className="container-fluid">
                        <div className="row">
                            {button_loading ?
                                <div className="d-flex justify-content-center">

                                    <div className="text-center">
                                        <i className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}></i>
                                    </div>
                                </div>

                                :
                                <div className="col-sm-12">

                                    {filesContent && typeof filesContent === "object" ?
                                        Object.keys(filesContent).map((key, i) => {
                                            return (
                                                <>
                                                    <label className="from-label">
                                                        {`Car Image Upload(${imagesname[key][0].value} -${imagesname[key][0].value + imagesname[key].length - 1})`}
                                                    </label>
                                                    <br />
                                                    <br />
                                                    <br />
                                                    {
                                                        filesContent[key] && filesContent[key].map((ival, i) => {
                                                            return <>
                                                                <div className="row form-group">

                                                                    <div className="col-sm-2">
                                                                        <label className="from-label">{"Name"}</label>
                                                                    </div>
                                                                    <div className="col-sm-5 inputtxtarea">
                                                                        <select className="form-select form-select-sm input-material__input input-material--dropdown"
                                                                            onChange={e => { this.vehiclenameupload(e.target.value, i, key, "select") }}
                                                                            value={imagesname[key].filter(x => x.label == filesContent[key][i].name)[0] ? imagesname[key].filter(x => x.label == filesContent[key][i].name)[0].value : []}
                                                                        >
                                                                            <option>Select</option>
                                                                            {imagesname && imagesname[key].map(jval => {
                                                                                return <option id={jval.show ? "" : "disabled_option"} disabled={jval.show ? false : true} value={jval.value} key={jval.value} >{jval.label}</option>
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-sm-1">
                                                                        <label className="from-label">{"OR"}</label>
                                                                    </div>
                                                                    <div className="col-sm-4">
                                                                        <input type="text" className="form-control" id="inputtext" name="Name" placeholder="Enter Name" onChange={e => { this.vehiclenameupload(e.target.value, i, key, "input") }} value={filesContent[key][i].name} />

                                                                    </div>
                                                                </div>
                                                                {filesContent[key][i].name &&
                                                                    <div className="row form-group">
                                                                        <div className="col-sm-1" />
                                                                        <div className="col-sm-3">
                                                                            <label className="from-label">
                                                                                {`Files-${i + 1}`}
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-sm-7">
                                                                            <FilePond
                                                                                files={ival && ival.files}
                                                                                allowReorder={true}
                                                                                allowMultiple={true}
                                                                                allowImageResize={true}
                                                                                maxFileSize={'300KB'}
                                                                                imagePreviewMinHeight="150"
                                                                                imagePreviewMaxHeight="150"
                                                                                acceptedFileTypes={["image/*"]}
                                                                                onupdatefiles={fileItems => {
                                                                                    this.vehicleimageUpload(fileItems, key, i);
                                                                                }}
                                                                                onreorderfiles={fileItems => {
                                                                                    this.vehicleimageUpload(fileItems, key, i);
                                                                                }}
                                                                                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                                                            />
                                                                            <label style={{ color: "black" }} >Dimension : {`315 * 235`}</label><br />
                                                                            <label style={{ color: "black" }} >Maximum Size : 300KB</label><br />

                                                                        </div>
                                                                    </div>
                                                                }
                                                                <div className="row form-group">
                                                                    {vehicleImg && vehicleImg[key] && vehicleImg[key][i] && vehicleImg[key][i].files}
                                                                </div>

                                                                <div className="row form-group">
                                                                    <div className="col-sm-2" />
                                                                    <div className="col-sm-2" />
                                                                    <div className="col-sm-6" >
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-danger"
                                                                            onClick={() => this.addMore("delete", i, key)}
                                                                        >
                                                                            Delete
                                                                        </button>
                                                                    </div>
                                                                    {filesContent[key].length == i + 1 && <div className="col-sm-2">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-success"
                                                                            onClick={() => this.addMore(null, i, key)}
                                                                        >
                                                                            Add More
                                                                        </button>
                                                                    </div>
                                                                    }
                                                                </div>
                                                                <br />
                                                                <br />
                                                                <br />

                                                            </>

                                                        })
                                                    }
                                                    <hr />

                                                </>
                                            )
                                        })

                                        :

                                        null

                                    }
                                    <div className="d-flex justify-content-center">
                                        <button className="btn btn-sm btn-warning text-center"
                                            onClick={() => { this.submit() }}>Update</button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </main>
            </React.Fragment >
        )
    }
}

export default DfCarsImageUpload;