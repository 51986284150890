import React, { Component } from "react";
import CmsContent from "../../../MiddleWare/CmsContent";
import SingleSelect from "../../../components/Form/SingleSelect";
import MultiSelect from "../../../components/Form/MultiSelect";
import Datatable from "../../../components/Datatable/Datatable";
import FormMiddleWare from "../../../components/Form/FormMiddleware";
import Switch from "react-switch";
import LoginModal from "../../../components/Modal/Modal";
import { ACCESS_POINT } from "../../../config";
import { async } from "q";
import { Alert } from "reactstrap";
import Progress from "../../../components/Extra/Progress";
import http from "../../../MiddleWare/httpMiddleWare";

class PortletsView extends FormMiddleWare {
  constructor(props) {
    super(props);

    this.state = {
      contentId: "",
      groupId: "",
      tabledata: [],
      dataTableData: [],
      commonportlet: [],
      button: "Submit",
      box: null,
      tray: false,
      disablePortletType: false,
      disablePortletName: false,
      alertVisible: false,
      alertVisible1: false,
      buttonVideo: [],
      selectedEvents: [],
      events: null,
      showRes: false,
      eventType: [
        { value: "new", label: "Customised" },
        { value: "lob", label: "From LOB" }
      ],
      CustomerId: localStorage.getItem("userId"),
      vid: null,
      resourceAlert: false,
      uploadPercentage: 0,
      appendOver: [],
      overLayOriginal: []
    };
  }

  async componentDidMount() {
    try {
      const {
        match: { params }
      } = this.props;

      let groupId = atob(params["groupId"]);

      let contentId = atob(params["contentId"]);

      let result1 = await CmsContent.getMasterValues("tbl_portletname");
      if (result1) {
      }

      let result2 = await CmsContent.getMasteractive("tbl_resources");
      if (result2) {
      }

      let result3 = await CmsContent.getMasterValues("tbl_boxtype");
      if (result3) {
      }

      let result4 = await CmsContent.getValuebyColumn(
        "tbl_map_grouptoportlet",
        "customerId",
        this.state.CustomerId,
        "groupid",
        groupId
      );
      if (result4) {
      }

      let result5 = await CmsContent.getMasterValues("tbl_group");
      if (result5) {
      }

      const category = await CmsContent.getConditionedValuewithStatus(
        "tbl_domain",
        "companyid",
        this.state.CustomerId
      );
      if (category) {
        this.setState({ domainOptions: category.data });
      }

      let result6 = await CmsContent.getValuebyColumn(
        "tbl_boxcontent",
        "customerId",
        this.state.CustomerId,
        "name",
        contentId
      );
      if (result6) {
        console.log(result6);
        this.setState({
          commonportlet: result6.data,
          getGroup: result5.data,
          groupId,
          contentId,
          portletData: result1.data,
          resourceOptions: result2.data,
          boxtype: result3.data,
          tabledata: result4.data
        });
      }

      const locationList = await CmsContent.getConditionedValuewithStatus(
        "tbl_location",
        "customerId",
        this.state.CustomerId,
        "id",
        "name"
      );
      if (locationList) {
        this.setState({ locationOptions: locationList.data });
      }
      const programList = await CmsContent.getConditionedValuewithStatus(
        "tbl_program",
        "customerId",
        this.state.CustomerId,
        "id",
        "name"
      );
      if (programList) {
        this.setState({ programOptions: programList.data });
      }
      const quizList = await CmsContent.getTwoConditionedValue(
        "tbl_question",
        "type",
        "active",
        "customerid",
        this.state.CustomerId,
        "id as value ,quizName as label"
      );
      if (quizList) {
        this.setState({ quizOptions: quizList.data });
      }
      const formList = await CmsContent.getTwoConditionedValue(
        "tbl_form",
        "status",
        "active",
        "companyid",
        this.state.CustomerId,
        "id as value ,name as label"
      );
      if (formList) {
        this.setState({ formOptions: formList.data });
      }

      let SurveyList = await CmsContent.getTwoConditionedValue(
        "tbl_survey",
        "customerid",
        this.state.CustomerId,
        1,
        1,
        "id as value ,surveyname as label"
      );
      if (SurveyList) {
        this.setState({ SurveyList: SurveyList.data });
      }

      let groupName = this.getValueFromArray(groupId, this.state.getGroup);

      let contentName = this.getValueFromArray(
        contentId,
        this.state.portletData
      );
      this.setState({ contentName, groupName });

      let dateObj = new Date();
      let month = dateObj.getUTCMonth() + 1; //months from 1-12
      let day = dateObj.getUTCDate();
      let year = dateObj.getUTCFullYear();
      let newdate = year + "-" + month + "-" + day;
      this.setState({ newdate });
    } catch (error) {
      console.log(error);
    }
  }

  column = [
    {
      Header: "Portlet Name",
      accessor: "name",
      Cell: d => this.call(d.original.name, this.state.portletData)
    },
    {
      Header: "Portlet Alias",
      accessor: "boxtitle",
      Cell: d => this.getValueFromArrays(d.original.name, this.state.tabledata)
    },
    {
      Header: "Portlet Heading",
      accessor: "heading"
    },
    {
      id: "type",
      Header: "Portlet Type",
      accessor: d => this.getValueFromArray(d.type, this.state.boxtype),
      Cell: d => this.getValueFromArray(d.original.type, this.state.boxtype)
    },
    {
      Header: "Text",
      accessor: "text",
      Cell: d => this.check1(d)
    },
    {
      Header: "Images/Videos",
      accessor: "media",
      Cell: d => this.check(d, "video")
    },
    {
      Header: "Thumbnail",
      accessor: "thumbnail",
      Cell: d => this.viewThumbnail(d)
    },
    {
      Header: "Link",
      accessor: "url",
      Cell: d => this.view(d)
    },
    {
      Header: "Extension",
      accessor: "extension",
      Cell: d =>
        this.getValueNull(d.original.extension, this.state.commonportlet)
    },
    {
      Header: "Add Content",
      accessor: "content",
      Cell: d =>
        this.dataTableModalButton(d, "group", "warning", "Add Content"),
      width: 150
    },
    {
      Header: "Edit",
      accessor: "edit",
      Cell: d => this.edit(d, "group")
    },
    {
      Header: "Visibility",
      accessor: "edit",
      Cell: d => this.showVisibility(d)
    }
  ];

  columns = [
    {
      Header: "Video Link",
      accessor: "label"
    }
  ];

  check1 = d => {
    if (d.original.type == 4) {
      var value = d.original.text;
      var result = "";
      if (value != "" && value != null && value != undefined) {
        let res = value.split(",");
        for (var i = 0; i < res.length; i++) {
          result +=
            this.getValueFromArray(res[i], this.state.resourceOptions) + ",";
        }
        result = result.replace(/,\s*$/, "");
        return result;
      }
      return result;
    } else {
      return d.original.text;
    }
  };

  check = (d, modalWindowId) => {
    if (d.original.media == null || d.original.media == "") {
      return "NA";
    } else {
      let ext = d.original.extension;
      if (d.original.media != null) {
        if (
          d.original.extension == "mp4" ||
          d.original.extension == "mov" ||
          d.original.extension == "3gp" ||
          d.original.extension == "flv" ||
          d.original.extension == "avi"
        ) {
          return (
            <center>
              <button
                type="button"
                data-toggle="modal"
                data-target={`#${modalWindowId}`}
                className="btn btn-warning"
                onClick={() => this.buttonVideo(d)}
              >
                Link
              </button>
            </center>
            // this.dataTableButton('warning', 'Link', () => {
            //   this.buttonVideo(d);
            // })
          );
        } else if (
          ext == "tif" ||
          ext == "jpg" ||
          ext == "gif" ||
          ext == "png" ||
          ext == "image files" ||
          ext == "images" ||
          ext == "jpeg" ||
          ext == "photos" ||
          ext == "raw" ||
          ext == "tiff"
        ) {
          let link =
            ACCESS_POINT + "/superAdmin/file?fileurl=" + d.original.media;

          return (
            <center>
              <img
                className="navbar-brand-full"
                src={link}
                width="30"
                height="30"
                alt="Logo"
              />
            </center>
          );
        }
      }
    }
  };

  edit = (value, modalWindowId) => {
    return (
      <center>
        <button
          type="button"
          data-toggle="modal"
          data-target={`#${modalWindowId}`}
          className="btn btn-warning"
          onClick={() => this.buttonEdit(value)}
        >
          Edit
        </button>
      </center>
    );
  };

  view = d => {
    if (
      d.original.url == null ||
      d.original.url == "" ||
      d.original.url == undefined ||
      d.original.url == "null"
    ) {
      return "NA";
    } else {
      return this.dataTableButton("warning", "Link", () => {
        this.viewLink(d);
      });
    }
  };

  views = d => {
    if (
      d.original.media == null ||
      d.original.media == "null" ||
      d.original.media == undefined
    ) {
      return "NA";
    } else {
      // let ext = d.original.media.split('.').pop();
      let ext = d.original.extension;

      if (
        ext == "tif" ||
        ext == "jpg" ||
        ext == "gif" ||
        ext == "png" ||
        ext == "image files" ||
        ext == "images" ||
        ext == "jpeg" ||
        ext == "photos" ||
        ext == "raw" ||
        ext == "tiff"
      ) {
        let link =
          ACCESS_POINT + "/superAdmin/file?fileurl=" + d.original.media;

        return (
          <center>
            <img
              className="navbar-brand-full"
              src={link}
              width="30"
              height="30"
              alt="Logo"
            />
          </center>
        );
      }
    }
  };
  // viewVideo = (d,modalWindowId) => {
  //    let link = ACCESS_POINT + '/superAdmin/file?fileurl=' + d.original.media;

  //    let ext = d.original.media.split('.').pop();

  //    if (d.original.media == null) {
  //      return 'NA';
  //    }else{
  //      return (
  //       <div data-toggle="modal"
  //       data-target={`#${modalWindowId}`}
  //       onClick={() => this.buttonVideo(d)}
  //       >
  //        {/* <center>

  //          <video width="320" height="240" controls>
  //          <source src={link} />
  //        </video>
  //        <video className="navbar-brand-full" src={link} width="50" height="50" alt="Logo" />
  //        </center> */}
  //        </div>
  //         );
  //    }
  //  };

  buttonVideo = value => {
    let video = this.getValueVideoLink(
      value.original.id,
      this.state.commonportlet
    );
    this.setState({ video });
  };
  getValueVideoLink = (d, array) => {
    if (array.length > 0) {
      if (array.length !== 0) {
        let filtered = array.filter(function (item) {
          return item.id == d;
        });
        let v = filtered[0];
        if (v != undefined) {
          return v.media;
        } else {
          return "-";
        }
      }
    } else {
      return "-";
    }
  };

  getValueFromArrays = (d, array) => {
    if (array.length > 0) {
      if (array.length !== 0) {
        let filtered = array.filter(function (item) {
          return item.contentid == d;
        });
        let v = filtered[0];
        if (v != undefined) {
          return v.boxtitle;
        } else {
          return "-";
        }
      }
    } else {
      return "-";
    }
  };

  getValueNull = (d, array) => {
    if (d == "null" || d == null) {
      return "NA";
    } else {
      if (array.length > 0) {
        if (array.length !== 0) {
          if (d == null) {
            return "NA";
          } else {
            let filtered = array.filter(function (item) {
              return item.extension == d;
            });
            let v = filtered[0];
            if (v != null) {
              return v.extension;
            } else {
              return "-";
            }
          }
        }
      } else {
        return "-";
      }
    }
  };

  dataTableModalButton = (value, modalWindowId, info, con) => {
    let classes = "btn btn-";
    classes += info;
    return (
      <center>
        <button
          type="button"
          data-toggle="modal"
          data-target={`#${modalWindowId}`}
          className={classes}
          onClick={() => this.buttonView(value)}
        >
          {con}
        </button>
      </center>
    );
  };

  showVisibility = id => {
    let status = id.original.status == "active" ? "Active" : "Inactive";
    let colour = id.original.status == "active" ? "warning" : "danger";
    return this.dataTableButton(colour, status, () =>
      this.updateVisibility(id)
    );
  };

  updateVisibility = async value => {
    let values = value.original;
    // let id = values.id;
    const index = value.index;
    const previousData = [...this.state.commonportlet];
    const newData = { ...previousData[index] };
    let subCategoryArray = {};
    if (newData.status === "active") {
      newData.status = "Inactive";
      subCategoryArray.status = "Inactive";
      this.setState({ button: "Hide" });
    } else {
      newData.status = "active";
      subCategoryArray.status = "active";
      this.setState({ button: "Show" });
    }
    const id = newData.id;

    const data = previousData.filter(value => value.id !== id);
    data.splice(index, 0, newData);
    this.setState({ data });

    try {
      const result = await CmsContent.updateMaster(
        "tbl_boxcontent",
        newData.id,
        subCategoryArray,
        "id"
      );
      if (result) {
        this.setState({ commonportlet: data, alertVisible1: true });
      }
      var that = this;
      setTimeout(function () {
        that.onDismiss();
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };

  viewThumbnail = d => {
    let link =
      ACCESS_POINT + "/superAdmin/file?fileurl=" + d.original.thumbnail;
    if (d.original.thumbnail == null) {
      return "NA";
    } else {
      return (
        // <a target="_blank" href={link}>
        //   {d.original.thumbnail}
        // </a>
        <center>
          <img
            className="navbar-brand-full"
            src={link}
            width="30"
            height="30"
            alt="Logo"
          />
        </center>
      );
    }
  };

  viewLink = d => {
    let link = "https://" + d.original.url;

    if (d.original.url == null) {
      return "NA";
    } else {
      window.open(link);
    }
  };

  submittingCommon = async (formData, tableName) => {
    try {
      const result = await CmsContent.mappingportlet(formData, tableName);
      if (result) {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
        this.setState({ alertVisible: true });
        this.componentDidMount();
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  buttonEdit = value => {
    console.log(value.original);
    let values = value.original;
    let id = values.id;

    // let eventType = values.eventType;

    // let getname = this.getValueFromArray(eventType , this.state.eventType);

    let selectedEvents = {};
    selectedEvents.value = values.eventType;
    selectedEvents.label = this.getValueFromArray(
      values.eventType,
      this.state.eventType
    );

    this.eventsChange(selectedEvents);

    let boxvalue = values.type;

    let box = {};
    box.value = value.original.type;
    box.label = this.getValueFromArray(value.original.type, this.state.boxtype);
    let descriptionText = values.description;
    let extension = values.extension;

    let portletHeading = values.heading;
    let boxfile = values.media;

    let boxname = this.call(values.name, this.state.portletData);

    let traytext = values.notificationtext;

    if (traytext === null || traytext === "" || traytext === undefined) {
      this.setState({ tray: false });
      this.setState({ traytext: "" });
    } else {
      this.setState({ tray: true });
      this.setState({ traytext });
    }

    let size = values.size;
    let boxtext;
    if (boxvalue == 4) {
      let boxtext = values.text.split(",");

      let resourceval = boxtext
        .map((ival, i) => {
          if (ival == boxtext[i]) {
            let returnArray = {};
            returnArray.label = this.getValueFromArray(
              ival,
              this.state.resourceOptions
            );
            returnArray.value = ival;
            return returnArray;
          }
        })
        .filter(function (element) {
          return element !== undefined;
        });
      this.resourceSelect(resourceval);
    } else {
      boxtext = values.text;
    }
    let thumbnail = values.thumbnail;
    let thumbnailname;
    if (thumbnail != null || thumbnail != undefined || thumbnail != "") {
      thumbnailname = thumbnail.split("SDAS/Video/").pop();
    }
    let type = values.type;
    let link = values.url;
    let boxfilename;
    if (boxvalue == 1 || boxvalue == 2 || boxvalue == 3) {
      boxfilename = values.media.split("SDAS/Video/").pop();
    }

    let portletids = values.name;
    let programName = values.programname;
    let fromDateSelect = values.fromdate;
    let toDateSelect = values.todate;
    let batchName = values.batchname;
    let locationSelect = {};
    locationSelect.label = values.location;
    let boxwidth = values.size;
    let boxurljson = values.url;

    let quizSelect = {};
    quizSelect.value = values.evaluation;
    quizSelect.label = this.getValueFromArray(
      values.evaluation,
      this.state.quizOptions
    );

    let formSelect = {};
    formSelect.value = values.form;
    formSelect.label = this.getValueFromArray(
      values.form,
      this.state.formOptions
    );

    let selectSurvey = {};
    selectSurvey.value = values.survey;
    selectSurvey.label = this.getValueFromArray(
      values.survey,
      this.state.SurveyList
    );
    if (values.type === 2) {
      let appendData = [];
      {
        this.showOverLay(values.overlay, id);
      }
    }

    this.setState({
      disablePortletType: true,
      disablePortletName: true,
      boxfilename,
      quizSelect,
      selectedEvents,
      boxtext,
      programName,
      boxurljson,
      boxwidth,
      locationSelect,
      fromDateSelect,
      thumbnail,
      batchName,
      toDateSelect,
      portletids,
      boxvalue,
      box,
      id,
      descriptionText,
      extension,
      portletHeading,
      boxfile,
      boxname,
      size,
      thumbnailname,
      type,
      link,
      editvalue: value,
      formSelect,
      selectSurvey,
      button: "Update"
    });
  };

  showOverLay = (appendData, id) => {
    let appendOver = [];
    let appendOverlayList = JSON.parse(appendData);
    let overLayOriginal = this.state.overLayOriginal;
    this.setState({ overLayOriginal: appendOverlayList });
    appendOverlayList.map((appendOverlayList, index) => {
      let selectOverlay = {
        label: appendOverlayList.overlayType,
        value: appendOverlayList.quizId
      };
      appendOver.push(
        <div className="quiz-align my-3">
          <div className="row form-group mt-3">
            <div className="col-sm-2" />
            <div className="col-sm-2">
              <label htmlFor="exampleInputEmail1">Overlay Name</label>
            </div>
            <div className="col-sm-5">
              <input
                type="text"
                name={`overlayName${index}`}
                placeholder="Enter overlay Name"
                className="form-control"
                onChange={e => this.json_refill(e, `overlayName`, index)}
                value={appendOverlayList.overlayName}
              />
            </div>
            <div className="col-sm-3">
              <i
                class="fa fa-times-circle"
                style={{ fontSize: "25px" }}
                onClick={() => this.removeAppend(index, appendOverlayList)}
              ></i>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-sm-2" />
            <div className="col-sm-2">
              <label htmlFor="exampleInputEmail1">Overlay Type</label>
            </div>
            <div className="col-sm-5">
              <SingleSelect
                name={`overlayType${index}`}
                options={this.state.overlayOption}
                handleChange={e => this.appendOverlayData(e, index)}
                selectedService={selectOverlay}
              />
            </div>
            <div className="col-sm-3" />
          </div>
          {/* {appendData && appendData[index]} */}
        </div>
      );
    });
    this.setState({ appendOver });
  };

  removeAppend = async index => {
    const { overLayOriginal, appendOver } = this.state;
    appendOver.splice(index, 1);
    overLayOriginal.splice(index, 1);
    this.setState({ overLayOriginal, appendOver });
  };

  buttonView = value => {
    if (value.original.type == 2) {
      console.log(value);
      localStorage.setItem("editval", JSON.stringify(value.original))
      window.open(`/DF/EditBoxTypes/${value.original.id}`)
      return true
    }
    // /DF/EditBoxTypes/
    this.setState({
      id: "",
      boxfilename: "",
      quizSelect: "",
      selectedEvents: "",
      boxtext: "",
      programName: "",
      boxurljson: "",
      boxwidth: "",
      locationSelect: "",
      fromDateSelect: "",
      thumbnail: "",
      batchName: "",
      toDateSelect: "",
      portletids: "",
      boxvalue: "",
      box: "",
      descriptionText: "",
      extension: "",
      portletHeading: "",
      boxfile: "",
      boxname: "",
      traytext: "",
      size: "",
      thumbnailname: "",
      link: "",
      formSelect: "",
      selectSurvey: ""
    });

    let values = value.original;
    let boxvalue = values.type;
    let box = {};
    box.value = value.original.type;
    box.label = this.getValueFromArray(value.original.type, this.state.boxtype);
    let portletids = value.original.name;
    let boxname = this.getValueFromArray(
      value.original.name,
      this.state.portletData
    );
    this.setState({
      boxvalue,
      box,
      portletids,
      boxname,
      disablePortletName: true,
      disablePortletType: true,
      button: "Submit"
    });
  };

  call(d, array) {
    if (array.length > 0) {
      if (array.length !== 0) {
        let filtered = array.filter(function (item) {
          return item.value == d;
        });
        let v = filtered[0];
        if (v != undefined) {
          return v.label;
        } else {
          return "-";
        }
      }
    } else {
      return "-";
    }
  }

  update = async () => {
    const {
      boxfilename,
      quizSelect,
      programName,
      thumbnail,
      commonportlet,
      newdate,
      boxurljson,
      boxwidth,
      locationSelect,
      fromDateSelect,
      boxtext,
      batchName,
      toDateSelect,
      portletids,
      boxvalue,
      box,
      id,
      descriptionText,
      extension,
      portletHeading,
      boxfile,
      boxname,
      traytext,
      size,
      thumbnailname,
      type,
      link,
      editvalue: value,
      button,
      selectedEvents,
      formSelect,
      selectSurvey,
      overLayOriginal
    } = this.state;

    if (thumbnail != null) {
      let validationImage = ["jpeg", "jpg", "png", "gif"];
      var ext1 = thumbnailname.split(".").pop();
      let ImageExtension = ext1.toLowerCase();
      var test = validationImage.filter(obj => {
        return obj == ImageExtension;
      });
      if (test.length == 0) {
        this.setState({ errorfile: "Please Choose Valid Image File" });
        return false;
      } else {
        this.setState({ errorfile: "" });
      }
    }

    switch (boxvalue) {
      case 1:
        {
          // if (!boxfile.name) {
          //   this.setState({ errorfile: 'Please Select boxfile', errorname: '' });
          //   return false;
          // } else if (!boxtext) {
          //   this.setState({ errortext: 'Please enter content', errorfile: '' });
          //   return false;
          // }

          let validationImage = ["jpeg", "jpg", "png", "gif"];
          //var extension = boxfile.name.split('.').pop();
          // let ext1 = extension[1];
          let ImageExtension = extension.toLowerCase();
          // var test = validationImage.filter((obj)=>{
          //   return obj == ImageExtension;
          // })
          if (validationImage.indexOf(ImageExtension) == -1) {
            this.setState({ errorfile: "Please Choose Valid Image File" });
            return false;
          } else {
            this.setState({ errorfile: "" });
          }

          const formDatas = new FormData();
          formDatas.append("id", id);
          formDatas.append("name", portletids);
          formDatas.append("text", boxtext);
          formDatas.append("media", boxfile);
          formDatas.append("extension", extension);
          formDatas.append("type", boxvalue);
          formDatas.append("status", "active");
          formDatas.append("customerId", this.state.CustomerId);
          formDatas.append("thumbnail", thumbnail);
          formDatas.append("notificationtext", traytext);
          formDatas.append("heading", portletHeading);
          formDatas.append("description", descriptionText);
          formDatas.append("createdAt", this.state.newdate);

          try {
            const result = await CmsContent.updateBox(
              "tbl_boxcontent",
              formDatas
            );
            if (result) {
              window.scrollTo({
                top: 0,
                behavior: "smooth"
              });
              this.componentDidMount();

              this.setState({ alertVisible1: true, commonportlet });
              this.setState({
                boxname: "",
                boxtext: "",
                extension: "",
                type: "",
                descriptionText: "",
                thumbnail: null,
                thumbnailname: null,
                tray: null,
                boxfilename: null,
                traytext: "",
                portletHeading: "",
                button: "Submit",
                commonportlet: []
              });
            }
            this.componentDidMount()
            var that = this;
            setTimeout(function () {
              that.onDismiss();
            }, 3000);
          } catch (error) {
            console.log(error);
          }
        }
        break;

      case 2:
        {
          // if (!boxfile.name) {
          //   this.setState({ errorVideo: 'Please Select boxfile', errorname: '' });
          //   return false;
          // }
          // this.setState({ errorVideo: '' });
          if (boxfilename != null) {
            let validationVideo = [
              "ogg",
              "ogv",
              "mpg",
              "mpeg",
              "mp4",
              "mp3",
              "dat",
              "avi"
            ];
            //var extension = boxfile.name.split('.').pop();
            //let ext1 = extension[1];
            let VideoExtension = extension.toLowerCase();
            // var test = validationVideo.filter((obj)=>{
            //   return obj == ImageExtension;
            // })
            if (validationVideo.indexOf(VideoExtension) == -1) {
              this.setState({ errorVideo: "Please Choose Valid Image File" });

              return false;
            } else {
              this.setState({ errorVideo: "" });
            }
          }

          // let filename = boxfilename.split('.');

          //let medias = boxfile.split('SDAS/Video/').pop();

          const formDatas = new FormData();
          formDatas.append("id", id);
          formDatas.append("name", portletids);
          formDatas.append("media", boxfile);
          formDatas.append("text", boxtext);
          formDatas.append("extension", extension);
          formDatas.append("type", boxvalue);
          formDatas.append("status", "active");
          formDatas.append("customerId", this.state.CustomerId);
          formDatas.append("thumbnail", thumbnail);
          formDatas.append("notificationtext", traytext);
          formDatas.append("description", descriptionText);
          formDatas.append("heading", portletHeading);
          formDatas.append("createdAt", this.state.newdate);
          formDatas.append("overlay", JSON.stringify(overLayOriginal));

          try {
            // const result = await CmsContent.updateBox('tbl_boxcontent',formDatas);

            const result = await http.put(
              ACCESS_POINT + `/cmsContent/updateBox/tbl_boxcontent`,
              formDatas,
              {
                headers: {
                  "Content-Type": "multipart/form-data"
                },
                onUploadProgress: progressEvent => {
                  this.setState({
                    uploadPercentage: parseInt(
                      Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                      )
                    )
                  });
                  setTimeout(
                    () => this.setState({ uploadPercentage: 0 }),
                    10000
                  );
                }
              }
            );
            if (result) {
              window.scrollTo({
                top: 0,
                behavior: "smooth"
              });
              this.setState({
                alertVisible1: true,
                portletids: "",
                boxfile: "",
                filename: "",
                boxfilename: null,
                descriptionText: "",
                thumbnail: null,
                thumbnailname: null,
                // errorfile:'',
                tray: null,
                traytext: "",
                portletHeading: "",
                button: "Submit",
                commonportlet: []
              });

              this.componentDidMount()
              var that = this;
              setTimeout(function () {
                that.onDismiss();
              }, 3000);
              // alert('Video Display Successfully Added');

              this.componentDidMount();
              this.setState({ alertVisible1: true });
            }
            var that = this;
            setTimeout(function () {
              that.onDismiss();
            }, 3000);
          } catch (error) {
            console.log(error);
          }
        }
        break;
      case 3:
        {
          // if (!boxfile.name) {
          //   this.setState({ errorfile: 'Please Select boxfile', errorname: '' });
          //   return false;
          // } else
          if (!this.is_url(link)) {
            this.setState({ errortext: "Please enter a valid Link" });
            return false;
          }

          let validationImage = ["jpeg", "jpg", "png", "gif"];
          // var extension = boxfile.name.split('.').pop();
          // let ext1 = extension[1];
          let ImageExtension = extension.toLowerCase();
          // var test = validationImage.filter((obj)=>{
          //   return obj == ImageExtension;
          // })
          if (validationImage.indexOf(ImageExtension) == -1) {
            this.setState({ errorfile: "Please Choose Valid Image File" });

            return false;
          } else {
            this.setState({ errorfile: "" });
          }

          // else if (!boxtext) {
          //   this.setState({ errortext: 'Please enter content', errorfile: '' });
          //   return false;
          // }
          this.setState({ errortext: "" });
          // let filename = boxfilename.split('.');

          // let medias = boxfile.split('SDAS/Video/').pop();

          const formDatas = new FormData();
          formDatas.append("id", id);
          formDatas.append("name", portletids);
          formDatas.append("url", link);
          formDatas.append("text", boxtext);
          formDatas.append("media", boxfile);
          formDatas.append("extension", extension);
          formDatas.append("type", boxvalue);
          formDatas.append("status", "active");
          formDatas.append("customerId", this.state.CustomerId);
          formDatas.append("thumbnail", thumbnail);
          formDatas.append("notificationtext", traytext);
          formDatas.append("heading", portletHeading);
          formDatas.append("description", descriptionText);
          formDatas.append("createdAt", this.state.newdate);

          try {
            const result = await CmsContent.updateBox(
              "tbl_boxcontent",
              formDatas
            );
            if (result) {
              window.scrollTo({
                top: 0,
                behavior: "smooth"
              });
              this.setState({ alertVisible1: true });
              this.setState({
                commonportlet: [],
                portletids: "",
                link: "",
                boxfile: "",
                filename: "",
                descriptionText: "",
                thumbnail: null,
                thumbnailname: null,
                tray: null,
                traytext: "",
                portletHeading: "",
                button: "Submit"
              });
              this.componentDidMount();
            }
            var that = this;
            setTimeout(function () {
              that.onDismiss();
            }, 3000);
          } catch (error) {
            console.log(error);
          }
        }
        break;
      case 4:
        {
          const { selectedresource } = this.state;
          // let selectedValue = '';
          // const value = selectedresource.map(values => (selectedValue += `${values.value},`));
          // selectedValue = selectedValue.replace(/,\s*$/, '');

          // if (selecteddomain == null || selecteddomain == '' || selecteddomain == undefined) {
          //   this.setState({ error5: 'Please Select Domain', error4: '' });
          //   return false;
          // }
          try {
            const formDatas = new FormData();
            formDatas.append("id", id);
            formDatas.append("name", portletids);
            formDatas.append(
              "text",
              this.getMultiSelectValue(selectedresource, "1")
            );
            formDatas.append("type", boxvalue);
            formDatas.append("status", "active");
            formDatas.append("customerId", this.state.CustomerId);
            formDatas.append("thumbnail", thumbnail);
            formDatas.append("notificationtext", traytext);
            formDatas.append("heading", portletHeading);
            formDatas.append("description", descriptionText);
            formDatas.append("createdAt", this.state.newdate);
            //formDatas.append('domainid', this.getMultiSelectValue(selecteddomain, '1'));

            const result = await CmsContent.updateBox(
              "tbl_boxcontent",
              formDatas
            );
            if (result) {
              window.scrollTo({
                top: 0,
                behavior: "smooth"
              });

              this.setState({ alertVisible1: true });

              this.setState({
                commonportlet: [],
                portletids: "",
                selectedValue: "",
                boxfile: "",
                filename: "",
                descriptionText: "",
                thumbnail: null,
                thumbnailname: null,
                tray: null,
                traytext: "",
                portletHeading: "",
                button: "Submit"
              });
              var that = this;
              setTimeout(function () {
                that.onDismiss();
              }, 3000);
              this.componentDidMount();
            }
            var that = this;
            setTimeout(function () {
              that.onDismiss();
            }, 3000);
          } catch (error) {
            console.log(error);
          }
        }
        break;
      case 5:
        {
          // if (!boxtext) {
          //   this.setState({ errortext: 'Please Enter Notification', errorname: '' });
          //   return false;
          // }
          // else{
          //   this.setState({ errortext: ' ' });
          // }
          const formData = new FormData();
          formData.append("id", id);
          formData.append("name", portletids);
          formData.append("type", boxvalue);
          formData.append("text", boxtext);
          formData.append("status", "active");
          formData.append("customerId", this.state.CustomerId);
          formData.append("thumbnail", thumbnail);
          formData.append("notificationtext", traytext);
          formData.append("heading", portletHeading);
          formData.append("description", descriptionText);
          formData.append("createdAt", this.state.newdate);

          try {
            const result = await CmsContent.updateBox(
              "tbl_boxcontent",
              formData
            );
            if (result) {
              window.scrollTo({
                top: 0,
                behavior: "smooth"
              });
              this.setState({ alertVisible1: true });
              this.setState({
                portletids: "",
                boxfile: "",
                filename: "",
                descriptionText: "",
                thumbnail: null,
                thumbnailname: null,
                tray: null,
                traytext: "",
                portletHeading: "",
                commonportlet: [],
                button: "Submit"
              });
              var that = this;
              setTimeout(function () {
                that.onDismiss();
              }, 3000);
              this.componentDidMount();
            }
            var that = this;
            setTimeout(function () {
              that.onDismiss();
            }, 3000);
          } catch (error) {
            console.log(error);
          }
        }
        break;

      case 6:
        {
          // if (!boxname) {
          //   this.setState({ errortext: 'Please Enter Notification', errorname: '' });
          //   return false;
          // }
          const formData1 = new FormData();
          formData1.append("id", id);
          formData1.append("name", portletids);
          formData1.append("type", boxvalue);
          formData1.append("status", "active");
          formData1.append("customerId", this.state.CustomerId);
          formData1.append("thumbnail", thumbnail);
          formData1.append("notificationtext", traytext);
          formData1.append("heading", portletHeading);
          formData1.append("description", descriptionText);
          formData1.append("createdAt", this.state.newdate);
          formData1.append("programname", programName);
          formData1.append("fromdate", fromDateSelect);
          formData1.append("todate", toDateSelect);
          formData1.append("batchname", batchName);
          formData1.append("location", locationSelect.label);
          formData1.append("eventType", selectedEvents.value);

          try {
            const result = await CmsContent.updateBox(
              "tbl_boxcontent",
              formData1
            );
            if (result) {
              window.scrollTo({
                top: 0,
                behavior: "smooth"
              });

              this.setState({ alertVisible1: true });

              this.setState({
                portletids: "",
                // boxvalue:'',
                thumbnail: "",
                traytext: "",
                portletHeading: "",
                descriptionText: "",
                programName: "",
                fromDateSelect: "",
                toDateSelect: "",
                batchName: "",
                commonportlet: [],
                locationSelect: "",
                selectedEvents: "",
                button: "Submit"
              });
              this.componentDidMount();
            }
            setTimeout(() => {
              this.onDismiss();
            }, 3000);
          } catch (error) {
            console.log(error);
          }
        }
        break;

      case 7:
        {
          if (!this.is_url(boxurljson)) {
            this.setState({ errorurljson: "Please enter a valid URL" });
            return false;
          }

          let mappingArray = {};
          mappingArray.name = boxname;
          mappingArray.type = boxvalue;
          mappingArray.url = boxurljson;
          mappingArray.text = boxtext;
          mappingArray.size = boxwidth.value;
          mappingArray.status = "active";
          mappingArray.customerId = this.state.CustomerId;
          try {
            const result = await CmsContent.updateMaster(
              "tbl_boxcontent",
              mappingArray
            );
            if (result) {
              window.scrollTo({
                top: 0,
                behavior: "smooth"
              });

              this.setState({ alertVisible1: true });
              this.setState({
                portletids: "",
                boxfile: "",
                filename: "",
                // boxvalue:'',
                boxurljson: "",
                boxwidth: "",
                descriptionText: "",
                thumbnail: null,
                thumbnailname: null,
                tray: null,
                commonportlet: [],
                traytext: "",
                portletHeading: "",
                button: "Submit"
              });
              var that = this;
              setTimeout(function () {
                that.onDismiss();
              }, 3000);
              this.componentDidMount();
            }
            var that = this;
            setTimeout(function () {
              that.onDismiss();
            }, 3000);
          } catch (error) {
            console.log(error);
          }
        }
        break;
      case 8:
        {
          const formData = new FormData();
          formData.append("id", id);
          formData.append("name", portletids);
          formData.append("type", boxvalue);
          formData.append("thumbnail", thumbnail);
          formData.append("notificationtext", traytext);
          formData.append("heading", portletHeading);
          formData.append("description", descriptionText);
          formData.append("evaluation", quizSelect.value);
          formData.append("customerId", this.state.CustomerId);
          formData.append("status", "active");
          formData.append("createdAt", newdate);

          this.updating("tbl_boxcontent", formData);
        }
        break;
      case 9:
        {
          const formData = new FormData();
          formData.append("id", id);
          formData.append("name", portletids);
          formData.append("type", boxvalue);
          formData.append("thumbnail", thumbnail);
          formData.append("notificationtext", traytext);
          formData.append("heading", portletHeading);
          formData.append("description", descriptionText);
          formData.append("form", formSelect.value);
          formData.append("customerId", this.state.CustomerId);
          formData.append("status", "active");
          formData.append("createdAt", newdate);

          this.updating("tbl_boxcontent", formData);
        }
        break;
      case 10:
        {
          const formData = new FormData();
          formData.append("id", id);
          formData.append("name", portletids);
          formData.append("type", boxvalue);
          formData.append("thumbnail", thumbnail);
          formData.append("heading", portletHeading);
          formData.append("description", descriptionText);
          formData.append("survey", selectSurvey.value);
          formData.append("notificationtext", traytext);
          formData.append("customerId", this.state.CustomerId);
          formData.append("status", "active");
          formData.append("createdAt", newdate);
          this.updating("tbl_boxcontent", formData);
        }
        break;
      default:
        console.log("Out Of Range");
    }
  };

  updating = async (tableName, body) => {
    try {
      const result = await CmsContent.updateBox(tableName, body);
      if (result) {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
        this.setState({ alertVisible1: true });
        this.setState({ commonportlet: [], })
        this.componentDidMount();
      }
      var that = this;
      setTimeout(function () {
        that.onDismiss();
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };

  getMultiSelectValue = (data, type) => {
    let selectedValue = "0";
    if (data.length !== 0) {
      selectedValue = "";

      if (type === "0") {
        const value = data.map(
          values => (selectedValue += `'${values.value}',`)
        );
        selectedValue = selectedValue.replace(/,\s*$/, "");
        var lastChar = selectedValue.slice(-1);
        if (lastChar === ",") {
          selectedValue = selectedValue.slice(0, -1);
        }
      } else if (type === "1") {
        const value = data.map(values => (selectedValue += `${values.value},`));
        selectedValue = selectedValue.replace(/,\s*$/, "");
      }
    }
    return selectedValue;
  };

  addcontent = async () => {
    let {
      boxvalue,
      boxlabel,
      errorImage,
      boxtext,
      portletids,
      boxname,
      boxwidth,
      boxfile,
      boxfilename,
      boxurljson,
      selectedresource,
      thumbnail,
      thumbnailname,
      traytext,
      descriptionText,
      portletHeading,
      link,
      selectedEvents,
      locationSelect,
      batchName,
      programName,
      fromDateSelect,
      toDateSelect,
      newdate,
      quizSelect,
      formSelect,
      selectSurvey
    } = this.state;

    if (!portletHeading) {
      this.setState({ errorheading: "Please Enter Portlet Heading" });
      return false;
    }

    if (thumbnailname != null && thumbnailname != "") {
      let validationImage = ["jpeg", "jpg", "png", "gif"];
      var extension = thumbnailname.split(".").pop();

      let ImageExtension = extension.toLowerCase();
      // var test = validationImage.filter((obj)=>{
      //   return obj == ImageExtension;
      // })
      if (validationImage.indexOf(ImageExtension) == -1) {
        this.setState({ errorthumbnail: "Please Choose Valid Image File" });
        return false;
      } else {
        this.setState({ errorthumbnail: "" });
      }
    }
    this.setState({ errorthumbnail: "" });

    let portletnametable = {};
    portletnametable.label = null;
    portletnametable.value = null;
    let boxcontenttable = {};
    boxcontenttable.name = null;
    boxcontenttable.text = "";
    boxcontenttable.media = null;
    boxcontenttable.extension = null;
    boxcontenttable.url = null;
    boxcontenttable.size = null;
    boxcontenttable.type = null;
    boxcontenttable.status = "active";
    boxcontenttable.customerId = this.state.CustomerId;

    descriptionText = descriptionText == null ? "  " : descriptionText;

    switch (boxvalue) {
      case 1:
        {
          if (!boxfile.name) {
            this.setState({
              errorImage: "Please Select boxfile",
              errorname: ""
            });
            return false;
          } else if (!boxtext) {
            this.setState({
              errortext: "Please enter content",
              errorImage: ""
            });
            // return false;
          }

          this.setState({ errortext: "" });

          let validationImage = ["jpeg", "jpg", "png", "gif"];
          var extension = boxfile.name.split(".").pop();
          let ImageExtension = extension.toLowerCase();
          if (validationImage.indexOf(ImageExtension) == -1) {
            this.setState({ errorImage: "Please Choose Valid Image File" });
            return false;
          } else {
            this.setState({ errorImage: "" });
          }

          let filename = boxfilename.split(".");

          const formDatas = new FormData();
          formDatas.append("name", portletids);
          formDatas.append("text", boxtext);
          formDatas.append("media", boxfile);
          formDatas.append("extension", filename[1]);
          formDatas.append("type", boxvalue);
          formDatas.append("status", "active");
          formDatas.append("customerId", this.state.CustomerId);
          formDatas.append("thumbnail", thumbnail);
          formDatas.append("notificationtext", traytext);
          formDatas.append("description", descriptionText);
          formDatas.append("heading", portletHeading);
          let value = boxvalue;

          try {
            const result = await CmsContent.authorinserts(
              formDatas,
              "tbl_boxcontent"
            );
            if (result) {
              window.scrollTo({
                top: 0,
                behavior: "smooth"
              });
              this.setState({ alertVisible: true });
              this.setState({
                boxname: "",
                boxtext: "",
                extension: "",
                type: "",
                descriptionText: "",
                thumbnail: null,
                thumbnailname: null,
                tray: null,
                boxfilename: null,
                traytext: "",
                portletHeading: "",
                button: "Submit"
              });
              var that = this;
              setTimeout(function () {
                that.onDismiss();
              }, 3000);
              this.componentDidMount();
            }
          } catch (error) {
            console.log(error);
          }
        }
        break;
      case 2:
        {
          if (!boxfile.name) {
            this.setState({
              errorVideo: "Please Select boxfile",
              errorname: ""
            });
            return false;
          }

          let validationVideo = [
            "ogg",
            "ogv",
            "mpg",
            "mpeg",
            "mp4",
            "mp3",
            "dat",
            "avi"
          ];
          let extension = boxfile.name.split(".").pop();
          let ImageExtension = extension.toLowerCase();
          if (validationVideo.indexOf(ImageExtension) == -1) {
            this.setState({ errorVideo: "Please Choose Valid Video File" });
            this.setState({ closeModal: false });
            return false;
          } else {
            this.setState({ errorVideo: "" });
          }
          let filename = boxfile.name.split(".");

          const formDatas = new FormData();

          formDatas.append("name", portletids);
          formDatas.append("media", boxfile);
          formDatas.append("extension", filename[1]);
          formDatas.append("type", boxvalue);
          formDatas.append("status", "active");
          formDatas.append("customerId", this.state.CustomerId);
          formDatas.append("thumbnail", thumbnail);
          formDatas.append("notificationtext", traytext);
          formDatas.append("description", descriptionText);
          formDatas.append("heading", portletHeading);

          try {
            const authorinsert = await http.post(
              ACCESS_POINT + `/cmsContent/addauthor/tbl_boxcontent`,
              formDatas,
              {
                headers: {
                  "Content-Type": "multipart/form-data"
                },
                onUploadProgress: progressEvent => {
                  this.setState({
                    uploadPercentage: parseInt(
                      Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                      )
                    )
                  });
                  setTimeout(
                    () => this.setState({ uploadPercentage: 0 }),
                    10000
                  );
                }
              }
            );
            if (authorinsert) {
              window.scrollTo({
                top: 0,
                behavior: "smooth"
              });
              this.setState({ alertVisible: true });
              this.setState({
                portletids: "",
                boxfile: "",
                filename: "",
                // boxvalue:'',
                descriptionText: "",
                thumbnail: null,
                thumbnailname: null,
                tray: null,
                traytext: "",
                portletHeading: "",
                button: "Submit"
              });
              var that = this;
              setTimeout(function () {
                that.onDismiss();
              }, 3000);
              this.componentDidMount();
            }
          } catch (error) {
            console.log(error);
          }
        }
        break;
      case 3:
        {
          if (!boxfile.name) {
            this.setState({
              errorImage: "Please Select boxfile",
              errorname: ""
            });
            return false;
          }

          let validationImage = ["jpeg", "jpg", "png", "gif"];
          let ImageExtension = extension.toLowerCase();
          if (validationImage.indexOf(ImageExtension) == -1) {
            this.setState({ errorImage: "Please Choose Valid Image File" });
            this.setState({ closeModal: false });
            return false;
          } else {
            this.setState({ errorImage: "" });
          }
          this.setState({ errortext: "" });

          let filename = boxfilename.split(".");

          const formDatas = new FormData();
          formDatas.append("name", portletids);
          formDatas.append("url", link);
          formDatas.append("media", boxfile);
          formDatas.append("extension", filename[1]);
          formDatas.append("type", boxvalue);
          formDatas.append("status", "active");
          formDatas.append("customerId", this.state.CustomerId);
          formDatas.append("thumbnail", thumbnail);
          formDatas.append("notificationtext", traytext);
          formDatas.append("description", descriptionText);
          formDatas.append("heading", portletHeading);

          try {
            const result = await CmsContent.authorinserts(
              formDatas,
              "tbl_boxcontent"
            );
            if (result) {
              window.scrollTo({
                top: 0,
                behavior: "smooth"
              });
              this.setState({ alertVisible: true });
              this.setState({
                portletids: "",
                link: "",
                boxfile: "",
                filename: "",
                // boxvalue:'',
                descriptionText: "",
                thumbnail: null,
                thumbnailname: null,
                boxfilename: null,
                tray: null,
                traytext: "",
                portletHeading: "",
                button: "Submit"
              });
              var that = this;
              setTimeout(function () {
                that.onDismiss();
              }, 3000);
              this.componentDidMount();
            }
          } catch (error) {
            console.log(error);
          }
        }
        break;
      case 4:
        {
          try {
            const formDatas = new FormData();
            formDatas.append("name", portletids);
            formDatas.append(
              "text",
              this.getMultiSelectValue(selectedresource, "1")
            );
            formDatas.append("type", boxvalue);
            formDatas.append("status", "active");
            formDatas.append("customerId", this.state.CustomerId);
            formDatas.append("thumbnail", thumbnail);
            formDatas.append("notificationtext", traytext);
            formDatas.append("description", descriptionText);
            formDatas.append("heading", portletHeading);

            const result = await CmsContent.authorinserts(
              formDatas,
              "tbl_boxcontent"
            );
            if (result) {
              window.scrollTo({
                top: 0,
                behavior: "smooth"
              });
              this.setState({ alertVisible: true });
              this.setState({
                portletids: "",
                selectedValue: "",
                selectedresource: "",
                boxfile: "",
                filename: "",
                // boxvalue:'',
                descriptionText: "",
                thumbnail: null,
                thumbnailname: null,
                tray: null,
                traytext: "",
                portletHeading: "",
                button: "Submit"
              });
              var that = this;
              setTimeout(function () {
                that.onDismiss();
              }, 3000);
              this.componentDidMount();
            }
          } catch (error) {
            console.log(error);
          }
        }
        break;
      case 5:
        {
          if (!boxtext) {
            this.setState({
              errortext: "Please Enter Notification",
              errorname: ""
            });
            return false;
          }
          let ext = thumbnailname.split(".").pop();

          if (
            ext === "tif" ||
            ext === "gif" ||
            ext === "png" ||
            ext === "image files" ||
            ext === "images" ||
            ext === "jpeg" ||
            ext === "photos" ||
            ext === "raw" ||
            ext === "tiff"
          ) {
            this.setState({ errorext: "" });
          } else {
            this.setState({ errorext: "choose proper image" });
            return false;
          }
          const formData = new FormData();
          formData.append("name", portletids);
          formData.append("type", boxvalue);
          formData.append("text", boxtext);
          formData.append("status", "active");
          formData.append("customerId", this.state.CustomerId);
          formData.append("thumbnail", thumbnail);
          formData.append("notificationtext", traytext);
          formData.append("description", descriptionText);
          formData.append("heading", portletHeading);
          try {
            const result = await CmsContent.authorinserts(
              formData,
              "tbl_boxcontent"
            );
            if (result) {
              window.scrollTo({
                top: 0,
                behavior: "smooth"
              });
              this.setState({ alertVisible: true });
              this.setState({
                portletids: "",
                boxfile: "",
                filename: "",
                // boxvalue:'',
                descriptionText: "",
                thumbnail: null,
                thumbnailname: null,
                tray: null,
                traytext: "",
                portletHeading: "",
                button: "Submit"
              });
              var that = this;
              setTimeout(function () {
                that.onDismiss();
              }, 3000);
              this.componentDidMount();
            }
          } catch (error) {
            console.log(error);
          }
        }
        break;
      case 6:
        {
          if (!boxname) {
            this.setState({
              errortext: "Please Enter Notification",
              errorname: ""
            });
            return false;
          }
          if (this.state.toDateSelect <= this.state.fromDateSelect) {
            await this.setState({ endateerror: "Please Check End Date " });
            return false;
          } else {
            await this.setState({ endateerror: "" });
          }

          if (this.state.locationSelect == undefined) {
            await this.setState({ locationerror: " Please  select Location" });
            return false;
          } else {
            await this.setState({ locationerror: "" });
          }

          if (this.state.toDateSelect == undefined) {
            await this.setState({ endateerror: " Please Select Enddate" });
            return false;
          } else {
            await this.setState({ endateerror: "" });
          }
          let firstdate = new Date(this.state.fromDateSelect);
          let secondate = new Date(this.state.toDateSelect);
          let diffTime = Math.abs(secondate - firstdate);
          let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

          let today = new Date();
          var dateformat = today.getDate();
          if (today.getDate() < 10) {
            dateformat = "0" + today.getDate();
          }
          let todaydate =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1) +
            "-" +
            dateformat;

          if (todaydate > this.state.fromDateSelect) {
            await this.setState({ startdateerror: "Please change start date" });
            return false;
          }
          if (diffDays >= 7) {
            await this.setState({
              calculatedateerror:
                "Please choose the date between 7 days from the start date"
            });
            return false;
          } else {
            await this.setState({ calculatedateerror: "" });
          }
          if (this.state.fromDateSelect > this.state.toDateSelect) {
            await this.setState({ endateerror: "Please Change End Date " });
            return false;
          } else {
            await this.setState({ endateerror: "" });
          }
          const formData1 = new FormData();
          formData1.append("name", boxname);
          formData1.append("type", boxvalue);
          formData1.append("status", "active");
          formData1.append("customerId", this.state.CustomerId);
          formData1.append("thumbnail", thumbnail);
          formData1.append("notificationtext", traytext);
          formData1.append("heading", portletHeading);
          formData1.append("description", descriptionText);
          formData1.append("createdAt", newdate);
          formData1.append("programname", programName);
          formData1.append("fromdate", fromDateSelect);
          formData1.append("todate", toDateSelect);
          formData1.append("batchname", batchName);
          formData1.append("location", locationSelect.label);
          formData1.append("eventType", selectedEvents.value);

          try {
            const result = await CmsContent.mappingportlet(
              formData1,
              "tbl_boxcontent"
            );
            if (result) {
              window.scrollTo({
                top: 0,
                behavior: "smooth"
              });

              this.setState({ alertVisible: true });
              this.componentDidMount();
            }
            setTimeout(() => {
              this.onDismiss();
            }, 3000);
          } catch (error) {
            console.log(error);
          }
        }
        break;

      case 7:
        {
          if (!boxurljson) {
            this.setState({ errorurljson: "Please Enter URL", errorname: "" });
            return false;
          } else if (!boxtext) {
            this.setState({ errortext: "Please Enter JSon", errorurljson: "" });
            return false;
          }
          let mappingArray = {};
          mappingArray.name = boxname;
          mappingArray.type = boxvalue;
          mappingArray.url = boxurljson;
          mappingArray.text = boxtext;
          mappingArray.size = boxwidth.value;
          mappingArray.status = "active";
          mappingArray.description = descriptionText;
          mappingArray.customerId = this.state.CustomerId;
          try {
            const result = await CmsContent.addMaster(
              "tbl_boxcontent",
              mappingArray
            );
            if (result) {
              window.scrollTo({
                top: 0,
                behavior: "smooth"
              });
              this.setState({ alertVisible: true });
              this.setState({
                portletids: "",
                boxfile: "",
                filename: "",
                // boxvalue:'',
                boxurljson: "",
                boxwidth: "",
                descriptionText: "",
                thumbnail: null,
                thumbnailname: null,
                tray: null,
                traytext: "",
                portletHeading: "",
                button: "Submit"
              });
              var that = this;
              setTimeout(function () {
                that.onDismiss();
              }, 3000);
              this.componentDidMount();
            }
          } catch (error) {
            console.log(error);
          }
        }
        break;
      case 8:
        {
          const formData = new FormData();
          formData.append("name", boxname);
          formData.append("type", boxvalue);
          formData.append("thumbnail", thumbnail);
          formData.append("notificationtext", traytext);
          formData.append("heading", portletHeading);
          formData.append("description", descriptionText);
          formData.append("evaluation", quizSelect.value);
          formData.append("customerId", this.state.CustomerId);
          formData.append("status", "active");
          formData.append("createdAt", newdate);

          try {
            const result = await CmsContent.mappingportlet(
              formData,
              "tbl_boxcontent"
            );
            if (result) {
              window.scrollTo({
                top: 0,
                behavior: "smooth"
              });
              this.setState({ alertVisible: true });
              this.componentDidMount();
              setTimeout(() => this.setState({ alertVisible: false }), 3000);
            }
          } catch (error) {
            console.log(error);
          }
        }
        break;
      case 9:
        {
          const formData = new FormData();
          formData.append("name", boxname);
          formData.append("type", boxvalue);
          formData.append("thumbnail", thumbnail);
          formData.append("notificationtext", traytext);
          formData.append("heading", portletHeading);
          formData.append("description", descriptionText);
          formData.append("form", formSelect.value);
          formData.append("customerId", this.state.CustomerId);
          formData.append("status", "active");
          formData.append("createdAt", newdate);
          this.submittingCommon(formData, "tbl_boxcontent");
        }
        break;
      case 10:
        {
          const formData = new FormData();
          formData.append("name", boxname);
          formData.append("type", boxvalue);
          formData.append("thumbnail", thumbnail);
          formData.append("heading", portletHeading);
          formData.append("description", descriptionText);
          formData.append("survey", selectSurvey.value);
          formData.append("notificationtext", traytext);
          formData.append("customerId", this.state.CustomerId);
          formData.append("status", "active");
          formData.append("createdAt", newdate);
          this.submittingCommon(formData, "tbl_boxcontent");
        }
        break;
      default:
        console.log("Out Of Range");
    }
  };

  val = value => { };

  link = e => {
    this.setState({ link: e.target.value });
  };

  tray = async () => {
    await this.setState({ tray: !this.state.tray, traytext: null });
  };
  handleBox = e => {
    this.setState({ boxvalue: e.value, box: e });
  };
  portletname = e => {
    this.setState({ boxname: e.target.value });
  };
  traytext = e => {
    this.setState({ traytext: e.target.value });
  };
  boxtext = e => {
    this.setState({ boxtext: e.target.value });
  };
  thumbnail = e => {
    this.setState({
      thumbnail: e.target.files[0],
      thumbnailname: e.target.files[0].name
    });
  };
  image = e => {
    this.setState({
      boxfile: e.target.files[0],
      boxfilename: e.target.files[0].name
    });
  };
  resourceSelect = async e => {
    if (e) {
      this.setState({ selectedresource: e });
    }
  };
  jsonUrl = e => {
    this.setState({ boxurljson: e.target.value });
  };
  descriptionText = e => {
    this.setState({ descriptionText: e.target.value });
  };
  portletHeading = e => {
    this.setState({ portletHeading: e.target.value });
  };

  lobSelect = async e => {
    let label = e.label;
    let arr = label.split(" to ");
    let getLocation = await CmsContent.getConditionedValuewithStatus(
      "tbl_location",
      "id",
      e.location
    );
    this.setState({
      lobSelect: e,
      fromDateSelect: arr[0],
      toDateSelect: arr[1],
      locationSelect: getLocation.data[0]
    });
  };

  eventsChange = e => {
    this.setState({ events: e.value, selectedEvents: e });
  };
  programName = e => {
    this.setState({ programName: e.target.value });
  };
  fromDateSelect = e => {
    this.setState({ fromDateSelect: e.target.value });
  };
  toDateSelect = e => {
    this.setState({ toDateSelect: e.target.value });
  };
  locationSelect = e => {
    this.setState({ locationSelect: e });
  };
  formSelect = e => {
    this.setState({ formSelect: e });
  };
  selectedprogram = async e => {
    this.setState({ selectedprogram: e });
    let val = e.value;
    try {
      let getBatch = await CmsContent.getConditionedValuewithStatus(
        "tbl_batch",
        "programid",
        val
      );
      if (getBatch) {
        this.setState({ batchOptions: getBatch.data, programName: e.label });
      }
    } catch (error) {
      console.log(error);
    }
  };

  batchSelect = async e => {
    this.setState({ batchSelect: e });
    let val = e.value;
    try {
      let getBatch = await CmsContent.getConditionedValuewithStatus(
        "tbl_learningobject",
        "batchid",
        val,
        "id",
        "programId"
      );
      if (getBatch) {
        let data = getBatch.data;
        let listingDate = [];
        let listingDates = await data.map(async (ival, i) => {
          let value = {};
          value.label = ival.fromdate + " to " + ival.enddate;
          value.value = ival.id;
          value.location = ival.location;
          await listingDate.push(value);
        });
        this.setState({ lobs: listingDate, batchName: e.label });
      }
    } catch (error) {
      console.log(error);
    }
  };

  quizSelect = e => {
    this.setState({ quizSelect: e });
  };

  onDismiss = () => {
    this.setState({ alertVisible: false });
    this.setState({ alertVisible1: false });
  };

  resourceAdding = async () => {
    this.setState({ showRes: true });
  };

  submitresource = async () => {
    const { Authorname, Authoremail } = this.state;
    let userfile = this.state.file1;

    var selectOption = this.state.domainOptions;
    if (
      selectOption != undefined &&
      selectOption != "" &&
      selectOption != null
    ) {
      this.setState({ errordomain: "" });
    } else {
      this.setState({ errordomain: "Please Select " });
      return true;
    }
    if (Authorname != undefined && Authorname != "" && Authorname != null) {
      this.setState({ error: "" });
    } else {
      this.setState({ error: "Please Fill Resource Name" });
      return true;
    }
    if (userfile != undefined && userfile != "" && userfile != null) {
      this.setState({ errorfile: "" });
    } else {
      this.setState({ errorfile: "Please Fill Resource file" });
      return true;
    }

    let username = this.state.Authorname;
    let useremail = this.state.selectedDomain;
    let companyid = this.state.companyid;
    let active = "active";

    const formData = new FormData();
    formData.append("name", username);
    formData.append("domainid", useremail.value);
    formData.append("companyid", this.state.CustomerId);
    formData.append("file", userfile);
    formData.append("status", active);

    let categoryArray = {};
    categoryArray.name = username.trim();
    categoryArray.domainid = useremail.value;
    this.setState({ disableValue: true });

    try {
      this.setState({ disableValue: true });

      // const authorinsert = await CmsContent.authorinsert(formData, 'tbl_resources');

      const authorinsert = await http.post(
        ACCESS_POINT + `/cmsContent/addauthor/tbl_resources`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          onUploadProgress: progressEvent => {
            this.setState({
              uploadPercentage: parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              )
            });
            setTimeout(() => this.setState({ uploadPercentage: 0 }), 10000);
          }
        }
      );

      if (authorinsert) {
        let valueArray = {};
        valueArray.value = authorinsert.data.insertId;
        valueArray.label = categoryArray.name;
        const newData = [valueArray, ...this.state.resourceOptions];
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
        this.setState({
          resourceOptions: newData,
          disableValue: false,
          resourceAlert: true,
          Authorname: "",
          filename1: null,
          selectedDomain: ""
        });
      }
      setTimeout(
        () => this.setState({ resourceAlert: false, showRes: false }),
        3000
      );
    } catch (error) {
      console.log(error);
    }
  };
  authorimage = async e => {
    let filename1 = e.target.files[0];

    let filepath1 = e.target.files[0].name;

    await this.setState({ file1: filename1, filename1: filepath1 });
  };
  authorname = e => {
    let name = e.target.value;
    this.setState({ Authorname: name });
  };
  domain = e => {
    this.setState({ selectedDomain: e });
  };

  render() {
    const {
      boxname,
      resourceOptions,
      boxfilename,
      boxtext,
      errorname,
      errortext,
      errorfile,
      errorImage,
      selectedresource,
      errorsize,
      errorresources,
      errorurljson,
      thumbnail,
      thumbnailname,
      errorthumbnail,
      disableGroup,
      button,
      traytext,
      descriptionText,
      portletHeading,
      errordescription,
      link,
      alertVisible,
      alertVisible1,
      formAlertdelete,
      locationOptions,
      locationSelect,
      programOptions,
      selectedprogram,
      batchOptions,
      batchSelect,
      lobs,
      lobSelect,
      errorVideo,
      quizSelect,
      quizOptions,
      formOptions,
      formSelect,
      SurveyList,
      selectSurvey,
      d,
      errorheading,
      showRes,
      resourceAlert,
      errordomain,
      Authorname,
      error,
      uploadPercentage
    } = this.state;

    let vid = ACCESS_POINT + "/superAdmin/file?fileurl=" + this.state.video;
    let dothis = button == "Submit" ? "Add" : "Edit";
    let title = `${dothis} Content for ${this.state.contentName}`;

    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>
                      List of content under {this.state.contentName} mapped to{" "}
                      {this.state.groupName}
                    </h1>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-7">
                        <Alert
                          className="badge-content"
                          color="success"
                          isOpen={alertVisible}
                          toggle={this.onDismiss}
                        >
                          New Content Added To Portlet
                        </Alert>
                        <Alert
                          className="badge-content"
                          color="success"
                          isOpen={alertVisible1}
                          toggle={this.onDismiss}
                        >
                          New Content Updated to Portlet
                        </Alert>
                      </div>
                    </div>
                  </div>

                  <div>
                    {showRes == true && (
                      <div>
                        <LoginModal id="resource" bodyText={d} />
                      </div>
                    )}
                  </div>

                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2"></div>
                    <div className="col-sm-5">
                      <LoginModal
                        title="Video Link"
                        atagLink={true}
                        id="video"
                        onClick={this.buttonVideo}
                        bodyText={<div>{vid}</div>}
                      />
                    </div>
                    <div className="col-sm-3" />
                  </div>

                  <div>
                    <span>
                      {Object.keys(this.state.commonportlet).length} records
                    </span>
                    {this.state.commonportlet && (
                      <Datatable
                        data={this.state.commonportlet}
                        columnHeading={this.column}
                      />
                    )}
                  </div>

                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2"></div>
                    <div className="col-sm-5">
                      <LoginModal
                        title={title}
                        atagLink={true}
                        id="group"
                        onClick={this.buttonView}
                        maxwidth="modal-lg"
                        bodyText={
                          <div>
                            {(this.state.boxvalue === 1 ||
                              this.state.boxvalue === 2 ||
                              this.state.boxvalue === 3 ||
                              this.state.boxvalue === 4 ||
                              this.state.boxvalue === 5 ||
                              this.state.boxvalue === 6 ||
                              this.state.boxvalue === 7 ||
                              this.state.boxvalue === 8 ||
                              this.state.boxvalue === 9 ||
                              this.state.boxvalue === 10) &&
                              showRes == false && (
                                <div>
                                  <div className="row form-group">
                                    <div className="col-sm-2" />
                                    <div className="col-sm-7">
                                      <Alert
                                        className="badge-content"
                                        color="success"
                                        isOpen={alertVisible}
                                        toggle={this.onDismiss}
                                      >
                                        New Content Added To Portlet
                                      </Alert>
                                      <Alert
                                        className="badge-content"
                                        color="success"
                                        isOpen={alertVisible1}
                                        toggle={this.onDismiss}
                                      >
                                        New Content Updated to Portlet
                                      </Alert>
                                    </div>
                                  </div>
                                  <div className="row form-group">
                                    <div className="col-sm-1" />
                                    <div className="col-sm-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Select Portlet-Type
                                      </label>
                                    </div>
                                    <div className="col-sm-5">
                                      <SingleSelect
                                        disabled={this.state.disablePortletType}
                                        options={this.state.boxtype}
                                        handleChange={this.handleBox}
                                        value={this.state.box}
                                        placeholder="Select BoxType"
                                      />
                                    </div>
                                    <div className="col-sm-3">
                                      <button
                                        type="button"
                                        className="btn btn-warning"
                                        onClick={() =>
                                          window.open("/DF/MapPortletToGroup")
                                        }
                                      >
                                        Manage App
                                      </button>
                                    </div>
                                  </div>
                                  <div className="row form-group">
                                    <div className="col-sm-1" />
                                    <div className="col-sm-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Portlet Name
                                      </label>
                                    </div>
                                    <div className="col-sm-5">
                                      <input
                                        type="text"
                                        disabled={this.state.disablePortletName}
                                        className="form-control"
                                        id="Authoremail"
                                        name="Authoremail"
                                        placeholder="Enter Portlet Name"
                                        onChange={this.portletname}
                                        value={boxname}
                                      />
                                      <span className="error-shows ">
                                        {errorname}
                                      </span>
                                    </div>
                                    <div className="col-sm-3" />
                                  </div>
                                  <div className="row form-group">
                                    <div className="col-sm-1" />
                                    <div className="col-sm-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Portlet Heading
                                      </label>
                                    </div>
                                    <div className="col-sm-5">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="Authoremail"
                                        name="Authoremail"
                                        placeholder="Enter Portlet Heading"
                                        onChange={this.portletHeading}
                                        value={portletHeading}
                                      />
                                      <span className="error-shows ">
                                        {errorheading}
                                      </span>
                                    </div>
                                    <div className="col-sm-3" />
                                  </div>
                                  <div>
                                    <div className="row form-group">
                                      <div className="col-sm-1" />
                                      <div className="col-sm-3">
                                        <label htmlFor="exampleInputEmail1">
                                          Upload Thumbnail
                                        </label>
                                      </div>
                                      <div className="col-sm-5">
                                        <input
                                          type="file"
                                          id="images"
                                          accept="image/*"
                                          onChange={this.thumbnail}
                                        />
                                        <label
                                          style={
                                            ({ overflow: "hidden" },
                                              { marginRight: "14px" })
                                          }
                                          className="custom-file-label lblcross"
                                          htmlFor="images"
                                        >
                                          {thumbnailname}
                                        </label>
                                        <br />
                                        <br />
                                        <span
                                          className="col-sm-3 "
                                          style={{
                                            fontSize: 12,
                                            color: "#007bff"
                                          }}
                                        >
                                          Prescribed Size : 120x120 px
                                        </span>
                                        <br />
                                        <span className="error-shows ">
                                          {errorthumbnail}
                                        </span>
                                      </div>

                                      <div className="col-sm-3" />
                                    </div>
                                    <div>
                                      <div className="row form-group">
                                        <div className="col-sm-1" />
                                        <div className="col-sm-3">
                                          <label>Add Tray: </label>&nbsp;&nbsp;
                                          <Switch
                                            height={15}
                                            width={35}
                                            marginTop={15}
                                            paddingTop={-5}
                                            onChange={this.tray}
                                            checked={this.state.tray}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {this.state.tray && (
                                    <div className="row form-group">
                                      <div className="col-sm-1" />
                                      <div className="col-sm-3">
                                        <label htmlFor="exampleInputEmail1">
                                          Tray Text
                                        </label>
                                      </div>
                                      <div className="col-sm-5">
                                        <textarea
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Notification Text "
                                          onChange={this.traytext}
                                          value={traytext}
                                        />
                                        <span className="error-shows ">
                                          {errortext}
                                        </span>
                                      </div>
                                      <div className="col-sm-3" />
                                    </div>
                                  )}
                                  <div className="row form-group">
                                    <div className="col-sm-1" />
                                    <div className="col-sm-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Descripition
                                      </label>
                                    </div>
                                    <div className="col-sm-5">
                                      <textarea
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Description Text"
                                        onChange={this.descriptionText}
                                        value={descriptionText}
                                      />
                                      <span className="error-shows ">
                                        {errordescription}
                                      </span>
                                    </div>
                                    <div className="col-sm-3" />
                                  </div>
                                </div>
                              )}
                            {showRes == true && (
                              <div>
                                <div className="row form-group">
                                  <div className="col-sm-1" />
                                  <div className="col-sm-10">
                                    <Alert
                                      className="badge-content"
                                      color="success"
                                      isOpen={resourceAlert}
                                      toggle={this.onDismiss}
                                    >
                                      Resource Added Successfully
                                    </Alert>
                                  </div>
                                  <div className="col-sm-1" />
                                </div>
                                <div className="row form-group">
                                  <div className="col-sm-1" />
                                  <div className="col-sm-3">
                                    <label for="exampleInputEmail1">
                                      Select Domain
                                    </label>
                                  </div>
                                  <div className="col-sm-5">
                                    <SingleSelect
                                      options={this.state.domainOptions}
                                      handleChange={this.domain}
                                      selectedService={
                                        this.state.selectedDomain
                                      }
                                    />
                                    <span className="modal-error-show">
                                      {errordomain}
                                    </span>
                                  </div>
                                  <div className="col-sm-3" />
                                </div>
                                <div className="row form-group">
                                  <div className="col-sm-1" />
                                  <div className="col-sm-3">
                                    <label htmlFor="Authorname">
                                      Resource Name
                                    </label>
                                  </div>
                                  <div className="col-sm-5">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="Authorname"
                                      name="Authorname"
                                      placeholder="Resource Name"
                                      value={Authorname}
                                      onChange={this.authorname}
                                    />
                                    <span className="modal-error-show">
                                      {error}
                                    </span>
                                  </div>
                                  <div className="col-sm-3" />
                                </div>
                                <div className="row form-group">
                                  <div className="col-sm-1" />
                                  <div className="col-sm-3">
                                    <label htmlFor="authorimage">
                                      Resource File
                                    </label>
                                  </div>
                                  <div className="col-sm-5 custom-file">
                                    <input
                                      type="file"
                                      accept="
                            image/*,
                            video/*,
                            application/pdf,application/msword,
                            applicationvnd.openxmlformats-officedocument.wordprocessingml.document,
                            .zip"
                                      className="custom-file-input"
                                      id="authorimage1"
                                      onChange={this.authorimage}
                                    />
                                    <label
                                      className="custom-file-label"
                                      htmlFor="authorimage1"
                                    >
                                      <span style={{ fontSize: "12px" }}>
                                        {this.state.filename1}
                                      </span>
                                    </label>
                                    <span className="modal-error-show">
                                      {errorfile}
                                    </span>
                                  </div>
                                  <div className="col-sm-3" />
                                </div>
                                <div className="row form-group">
                                  <div className="col-sm-2" />
                                  <div className="col-sm-7">
                                    {uploadPercentage > 0 && (
                                      <Progress percentage={uploadPercentage} />
                                    )}
                                  </div>
                                  <div className="col-sm-3" />
                                </div>
                                <div className="row form-group">
                                  <div className="col-sm-2" />
                                  <div className="col-sm-2" />
                                  <div className="col-sm-5">
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={this.submitresource}
                                    >
                                      submit
                                    </button>
                                  </div>
                                  <div className="col-sm-3" />
                                </div>
                              </div>
                            )}
                            {(this.state.boxvalue === 1 ||
                              this.state.boxvalue === 3) && (
                                <div>
                                  <div className="row form-group">
                                    <div className="col-sm-1" />
                                    <div className="col-sm-3">
                                      <label htmlFor="exampleInputEmail1">
                                        Upload Image
                                    </label>
                                    </div>
                                    <div className="col-sm-5">
                                      <input
                                        style={{ marginRight: "14px" }}
                                        type="file"
                                        id="image"
                                        accept="image/*"
                                        onChange={this.image}
                                      />
                                      <label
                                        className="custom-file-label lblcross"
                                        htmlFor="image"
                                      >
                                        {boxfilename}
                                      </label>
                                      <br />
                                      <br />
                                      <span
                                        className="col-sm-3 "
                                        style={{ fontSize: 12, color: "#007bff" }}
                                      >
                                        Prescribed Size : 120x120 px
                                    </span>
                                      <span className="error-shows ">
                                        {errorImage}
                                      </span>
                                    </div>
                                    <div className="col-sm-3" />
                                  </div>
                                  {this.state.boxvalue === 1 && (
                                    <div className="row form-group">
                                      <div className="col-sm-1" />
                                      <div className="col-sm-3">
                                        <label htmlFor="exampleInputEmail1">
                                          Text Content
                                      </label>
                                      </div>
                                      <div className="col-sm-5">
                                        <textarea
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Text Content"
                                          onChange={this.boxtext}
                                          value={boxtext}
                                        />
                                        <span className="error-shows ">
                                          {errortext}
                                        </span>
                                      </div>
                                      <div className="col-sm-3" />
                                    </div>
                                  )}
                                  {this.state.boxvalue === 3 && (
                                    <div className="row form-group">
                                      <div className="col-sm-1" />
                                      <div className="col-sm-3">
                                        <label htmlFor="exampleInputEmail1">
                                          link{" "}
                                        </label>
                                      </div>
                                      <div className="col-sm-5">
                                        <textarea
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Link Here"
                                          onChange={this.link}
                                          value={link}
                                        />
                                        <span className="error-shows ">
                                          {errortext}
                                        </span>
                                      </div>
                                      <div className="col-sm-3" />
                                    </div>
                                  )}
                                </div>
                              )}
                            {/* Video */}
                            {this.state.boxvalue === 2 && (
                              <div>
                                <div className="row form-group">
                                  <div className="col-sm-1" />
                                  <div className="col-sm-3">
                                    <label htmlFor="exampleInputEmail1">
                                      Upload Video
                                    </label>
                                  </div>
                                  <div className="col-sm-5">
                                    <input
                                      style={{ marginRight: "14px" }}
                                      className="custom-file-input"
                                      type="file"
                                      id="image"
                                      accept="video/*"
                                      onChange={this.image}
                                    />
                                    <label
                                      className="custom-file-label lblcross"
                                      htmlFor="image"
                                    >
                                      {boxfilename}
                                    </label>
                                  </div>
                                  <span className="error-shows ">
                                    {errorVideo}
                                  </span>
                                  <div className="col-sm-3" />
                                </div>
                                <div className="row form-group">
                                  <div className="col-sm-1" />
                                  <div className="col-sm-2">
                                    <label htmlFor="exampleInputEmail1">
                                      Overlay
                                    </label>
                                  </div>
                                  <div className="col-sm-7">
                                    {this.state.appendOver}
                                  </div>
                                </div>
                              </div>
                            )}

                            {/* Resource */}
                            {this.state.boxvalue === 4 && showRes == false && (
                              <div>
                                <div className="row form-group">
                                  <div className="col-sm-1" />
                                  <div className="col-sm-3">
                                    <label htmlFor="authorimage">
                                      Resource File
                                    </label>
                                  </div>
                                  <div className="col-sm-5 custom-file">
                                    <MultiSelect
                                      options={resourceOptions}
                                      handleChange={this.resourceSelect}
                                      selectedService={selectedresource}
                                    />
                                    <span className="error-shows">
                                      {errorfile}
                                    </span>
                                  </div>
                                  <div className="col-sm-3">
                                    <button
                                      type="button"
                                      className="btn btn-warning"
                                      onClick={this.resourceAdding}
                                    >
                                      Add Resource
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                            {/* Notification */}
                            {this.state.boxvalue === 5 && (
                              <div className="row form-group">
                                <div className="col-sm-1" />
                                <div className="col-sm-3">
                                  <label htmlFor="exampleInputEmail1">
                                    Notifcation Text
                                  </label>
                                </div>
                                <div className="col-sm-5">
                                  <textarea
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Notificatin Text"
                                    onChange={this.boxtext}
                                    value={boxtext}
                                  />
                                  <span className="error-shows ">
                                    {errortext}
                                  </span>
                                </div>
                                <div className="col-sm-3" />
                              </div>
                            )}
                            {/* Events */}
                            {this.state.boxvalue === 6 && (
                              <div>
                                <div className="row form-group">
                                  <div className="col-sm-1" />
                                  <div className="col-sm-3">
                                    <label htmlFor="exampleInputEmail1">
                                      Event Type
                                    </label>
                                  </div>
                                  <div className="col-sm-5">
                                    <SingleSelect
                                      options={this.state.eventType}
                                      handleChange={this.eventsChange}
                                      placeholder="Select Event Type"
                                      value={this.state.selectedEvents}
                                    />
                                    <span className="error-shows ">
                                      {errortext}
                                    </span>
                                  </div>
                                  <div className="col-sm-3" />
                                </div>

                                {this.state.events === "new" && (
                                  <div>
                                    <div className="row form-group">
                                      <div className="col-sm-1" />
                                      <div className="col-sm-3">
                                        <label htmlFor="exampleInputEmail8">
                                          Program Name
                                        </label>
                                      </div>
                                      <div className="col-sm-5">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Program Name"
                                          onChange={this.programName}
                                          value={this.state.programName}
                                        />
                                        {/* <span className="error-shows ">{errorprogramName}</span> */}
                                      </div>
                                      <div className="col-sm-3" />
                                    </div>
                                    <div className="row form-group">
                                      <div className="col-sm-1" />
                                      <div className="col-sm-3">Date</div>
                                      <div className="col-sm-3">
                                        From Date
                                        <input
                                          type="date"
                                          id="myDate"
                                          className="form-control"
                                          onChange={this.fromDateSelect}
                                          value={this.state.fromDateSelect}
                                        />
                                      </div>
                                      <div className="col-sm-3">
                                        To Date
                                        <input
                                          type="date"
                                          className="form-control"
                                          onChange={this.toDateSelect}
                                          value={this.state.toDateSelect}
                                        />
                                      </div>
                                      <div className="col-sm-2 error-shows">
                                        {" "}
                                      </div>
                                    </div>
                                    <div className="row form-group">
                                      <div className="col-sm-1" />
                                      <div className="col-sm-3">
                                        <label htmlFor="exampleInputEmail8">
                                          Location
                                        </label>
                                      </div>
                                      <div className="col-sm-5">
                                        <SingleSelect
                                          options={locationOptions}
                                          handleChange={this.locationSelect}
                                          selectedService={locationSelect}
                                        />
                                        {/* <span className="error-shows">{error9}</span> */}
                                      </div>
                                      <div className="col-sm-3" />
                                    </div>
                                  </div>
                                )}
                                {this.state.events === "lob" && (
                                  <div>
                                    <div className="row form-group">
                                      <div className="col-sm-1" />
                                      <div className="col-sm-3">
                                        <label htmlFor="exampleInputEmail8">
                                          Program Name
                                        </label>
                                      </div>
                                      <div className="col-sm-5">
                                        <SingleSelect
                                          handleChange={this.selectedprogram}
                                          options={programOptions}
                                          selectedService={selectedprogram}
                                        />
                                      </div>
                                      <div className="col-sm-3" />
                                    </div>
                                    <div className="row form-group">
                                      <div className="col-sm-1" />
                                      <div className="col-sm-3">
                                        Select Batch
                                      </div>
                                      <div className="col-sm-5">
                                        <SingleSelect
                                          handleChange={this.batchSelect}
                                          options={batchOptions}
                                          selectedService={batchSelect}
                                        />
                                      </div>
                                      <div className="col-sm-3" />
                                    </div>
                                    <div className="row form-group">
                                      <div className="col-sm-1" />
                                      <div className="col-sm-3">
                                        <label htmlFor="exampleInputEmail8">
                                          Dates
                                        </label>
                                      </div>
                                      <div className="col-sm-5">
                                        <SingleSelect
                                          options={lobs}
                                          handleChange={this.lobSelect}
                                          selectedService={lobSelect}
                                        />
                                      </div>
                                      <div className="col-sm-3" />
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                            {/* URL & Json */}
                            {this.state.boxvalue === 7 && (
                              <div>
                                <div className="row form-group">
                                  <div className="col-sm-1" />
                                  <div className="col-sm-3">
                                    <label htmlFor="exampleInputEmail1">
                                      Url
                                    </label>
                                  </div>
                                  <div className="col-sm-5">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="Authoremail"
                                      name="Authoremail"
                                      placeholder="Enter Url"
                                      onChange={this.jsonUrl}
                                    />
                                    <span className="error-shows ">
                                      {errorurljson}
                                    </span>
                                  </div>
                                  <div className="col-sm-3" />
                                </div>
                                <div className="row form-group">
                                  <div className="col-sm-1" />
                                  <div className="col-sm-3">
                                    <label htmlFor="exampleInputEmail1">
                                      Json
                                    </label>
                                  </div>
                                  <div className="col-sm-5">
                                    <textarea
                                      type="text"
                                      className="form-control"
                                      placeholder="Paste Json Here"
                                      onChange={this.boxtext}
                                      value={boxtext}
                                    />
                                    <span className="error-shows ">
                                      {errortext}
                                    </span>
                                  </div>
                                  <div className="col-sm-3" />
                                </div>
                              </div>
                            )}
                            {/* Evaluation */}
                            {this.state.boxvalue === 8 && (
                              <div className="row form-group">
                                <div className="col-sm-1" />
                                <div className="col-sm-3">
                                  <label htmlFor="exampleInputEmail1">
                                    Evaluation Name
                                  </label>
                                </div>
                                <div className="col-sm-5">
                                  <SingleSelect
                                    options={quizOptions}
                                    handleChange={this.quizSelect}
                                    selectedService={quizSelect}
                                  />
                                </div>
                                <div className="col-sm-3" />
                              </div>
                            )}
                            {/* Forms */}
                            {this.state.boxvalue === 9 && (
                              <div className="row form-group">
                                <div className="col-sm-1" />
                                <div className="col-sm-3">
                                  <label htmlFor="exampleInputEmail1">
                                    Select Form
                                  </label>
                                </div>
                                <div className="col-sm-5">
                                  <SingleSelect
                                    options={formOptions}
                                    handleChange={this.formSelect}
                                    selectedService={formSelect}
                                  />
                                </div>
                                <div className="col-sm-3" />
                              </div>
                            )}
                            {/* Survey */}
                            {this.state.boxvalue === 10 && (
                              <div className="row form-group">
                                <div className="col-sm-1" />
                                <div className="col-sm-3">
                                  <label htmlFor="exampleInputEmail1">
                                    Select Survey
                                  </label>
                                </div>
                                <div className="col-sm-5">
                                  <SingleSelect
                                    options={SurveyList}
                                    handleChange={e =>
                                      this.setState({ selectSurvey: e })
                                    }
                                    selectedService={selectSurvey}
                                  />
                                </div>
                                <div className="col-sm-3" />
                              </div>
                            )}
                            {showRes == false && this.state.boxvalue == 2 && (
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-7">
                                  {uploadPercentage > 0 && (
                                    <Progress percentage={uploadPercentage} />
                                  )}
                                </div>
                                <div className="col-sm-3" />
                              </div>
                            )}
                            {this.state.boxvalue && showRes == false && (
                              <div className="row form-group">
                                <div className="col-sm-4" />
                                <div className="col-sm-4">
                                  <button
                                    type="button"
                                    data-dismiss="modal"
                                    className="btn btn-primary"
                                    onClick={
                                      button === "Update"
                                        ? this.update
                                        : this.addcontent
                                    }
                                  >
                                    {this.state.button}
                                  </button>
                                </div>
                                <div className="col-sm-4" />
                              </div>
                            )}
                          </div>
                        }
                      />
                    </div>
                    <div className="col-sm-3" />
                  </div>
                </div>
                <div className="col-sm-3" />
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default PortletsView;
