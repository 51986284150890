import React, { Component } from "react";
import { ACCESS_POINT } from "../../config";
import User from "../../MiddleWare/User";
import { Alert } from "reactstrap";
import CmsContent from "../../MiddleWare/CmsContent";
import { Link } from "react-router-dom";
import http from "../../MiddleWare/httpMiddleWare";
import redisMiddleware from "../../MiddleWare/redisMiddleware";
import { FormMiddleWare } from "../../components/Form";
import DFcars_Preloader from "../ThirdAdmin/DifuzaCars/assets/DFcars_Preloader";

var imagelogo = require("./difuzaWebLogo.png");
export default class DifuzaCarHeader extends FormMiddleWare {
  constructor(props) {
    super(props);
    var userlog = localStorage.getItem("userlog");

    userlog = JSON.parse(userlog);
    if (userlog == null) {
      window.location.href = "/";
    }

    var link = "";
    if (!userlog.link) {
      link = "https://steerapp.difuza.com/";
    } else {
      link = userlog.link;
    }

    if (link !== "" && link !== undefined && link !== null) {

    } else {
      link = "https://steerapp.difuza.com/";
    }
    var len = userlog.image;

    var imgsrc = "";
    if (len != "" && len != undefined) {
      var imgsrc = ACCESS_POINT + "/superAdmin/file?fileurl=" + userlog.image;
    } else {
      imgsrc = imagelogo;
      // imagelogo 'https://www.grouptravelvideos.com/images/client/00954/resources/you%20logo%20here.png'
    }
    //console.log(imgsrc)
    const date = new Date();
    var month = ("0" + (date.getMonth() + 1)).slice(-2); //months from 1-12
    var day = date.getUTCDate();
    var year = date.getUTCFullYear();
    var newDate = year + "-" + month + "/" + day;
    let dataArray = {};
    dataArray.userId = userlog.id;
    dataArray.date = newDate;
    this.state = {
      userlog,
      userName: userlog.userName,
      //imgsrc: imgsrc,
      userType: userlog.userType,
      link: link,
      show: false,
      UserList: [],
      pageJson: [],
      Page_refresh: false,
      L1Json: [],
      L2Json: [],
      userJson: [],
      portJson: [],
      ispreLoader: true,
      quizJson: [],
      surveyJson: [],
      formJson: [],
      QuizAttend: [],
      SurveyAttend: [],
      User: JSON.parse(localStorage.getItem("userlog")),
      TotalNoti: 0,
      Notifi: null,
      dataArray,
      newDate
    };
    window.$(document).ready(function ($) {
      $(document).on("click", ".nav-item", function (e) {
        e.target.classList.add('effect-7')
        setTimeout(() => {
          e.target.classList.remove('effect-7')
        }, 500);
      });
    });
  }
  logout = async () => {
    await this.setState({ ispreLoader: true })
    localStorage.clear();
    window.location = "/";
  };
  Loading_Contents = async (type) => {
    try {
      if (type == "load") {
        await this.setState({ Page_refresh: true })
        let user = JSON.parse(localStorage.getItem("userlog"));
        let Menu = null
        Menu = await CmsContent.BUYDFCARZ(user.id, 'menu')
        if (Menu) {
          await this.encryption("menu", Menu)
        }
        let getUserRelatedOPT = await CmsContent.BUYDFCARZ(this.state.User.id, "UserList")
        if (getUserRelatedOPT) {
          await this.encryption("Downline_Details", getUserRelatedOPT)
        }
        let getcarmaster = await CmsContent.getAllOption_Web()
        if (getcarmaster) {
          await this.encryption("getcar", getcarmaster)
        }
        await this.setState({ Page_refresh: false })
      }
    } catch (error) {
      await this.setState({ Page_refresh: false })
      console.log(error);
    }
  }
  async componentWillMount() {
    let Userlog = JSON.parse(localStorage.getItem("userlog"));
    let l3img = await CmsContent.getFreedom("*", "tbl_user_web", `id=${Userlog.customerId}`, 1, 1);
    let imgsrc = [];
    if (l3img.data) {
      l3img.data.map((ival, n) => {
        ival.image = ACCESS_POINT + "/superAdmin/file?fileurl=" + ival.image;
        imgsrc.push(ival.image)
        // console.log(ival.image)
      })
      this.setState({ imgsrc })
    }
    this.setState({ ispreLoader: false });
  }
  Profile = async () => {
    try {
      window.location.href = "/DFCars/DfCarsProfile"
    } catch (error) {
      console.log(error);
    }
  }
  render() {
    let { Page_refresh } = this.state
    // setTimeout(() => {
    //   this.setNotiLocal();
    // }, 10000);
    const {
      userName,
      imgsrc,
      link,
      userUnder,
      ispreLoader,
      TotalNoti,
    } = this.state;
    const styles = {
      width: "230px",
      height: "56px",
      marginRight: "15px",
      // paddingRight: "0px",
      marginLeft: "-25px",
      // marginBottom: "0px",
      // paddingBottom: "18px"
    };
    let width = window.innerWidth
    return (
      <React.Fragment>
        {ispreLoader ?
          <DFcars_Preloader />
          :
          width > 980 ?
            <header className="app-header navbar" style={{ paddingTop: "0px" }}>
              <button
                className="navbar-toggler sidebar-toggler d-lg-none mr-auto"
                type="button"
                data-toggle="sidebar-show"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <a className="navbar-brand" href={link} style={{ maxHeight: "56px" }}>
                <img
                  className="navbar-brand-full"
                  src={imgsrc}
                  style={styles}
                  alt="Logo"
                />
                { }
              </a>
              <button
                className="navbar-toggler sidebar-toggler d-md-down-none"
                type="button"
                data-toggle="sidebar-lg-show"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <p>{userName}</p>
              <ul className="nav navbar-nav ml-auto">
                <li className="nav-item text-right">
                  {Page_refresh == false ?
                    <i className="icon-refresh" onClick={() => this.Loading_Contents("load")} />
                    :
                    <div class="DFCARS_REFRESH text-right"></div>
                  }
                </li>
                <li className="nav-item dropdown d-md-down-none">
                  <span
                    className="nav-link"
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"

                  >
                    <i className="icon-bell" />
                    <span className="badge badge-pill badge-danger">{TotalNoti}</span>
                  </span>
                  <div
                    className="dropdown-menu dropdown-menu-right dropdown-menu-lg"
                    style={{
                      // width: "auto"
                      height: '100px',
                      overflowY: 'scroll'
                    }}
                  >

                    <div className="dropdown-header text-center">
                      <strong>You have {TotalNoti} notifications</strong>
                    </div>


                  </div>
                </li>
                <li className="nav-item dropdown">
                  <span
                    className="nav-link nav-link"
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img
                      className="img-avatar"
                      src="https://cdn.pixabay.com/photo/2016/08/20/05/38/avatar-1606916__340.png"
                      alt="userName"

                    />
                  </span>
                  <div className="dropdown-menu dropdown-menu-right" >
                    <Link className="dropdown-item" to={"/DFCars/DfCarsProfile"}>
                      <i className="fa fa-user" /> Profile
                    </Link>
                    {/* <span className="dropdown-item" onClick={() => this.Profile()}>
                      <i className="fa fa-user" onClick={() => this.Profile()} /> Profile
                    </span> */}
                    <span className="dropdown-item" onClick={() => this.logout()}>
                      <i className="fa fa-lock" onClick={() => this.logout()} /> Logout
                    </span>
                  </div>
                </li>
                <p className="mr-auto p-2"></p>
                <p style={{ float: "right", width: "110px" }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {/* Currently Logged in Users <a href="/DF/UserUnder">{userUnder}</a> */}
                </p>
              </ul>
            </header>
            :
            <header className="app-header navbar sticky-top" style={{ paddingTop: "0px", height: "80px" }}>

              <div className="d-flex " style={{ width: "100%" }}>
                <button
                  className="navbar-toggler sidebar-toggler d-sm p-2"
                  type="button"
                  data-toggle="sidebar-show"
                  style={{ outline: "none" }}
                >
                  <span className="navbar-toggler-icon" />
                </button>
                <img
                  className="p-2"
                  onClick={() => window.location.href = link}
                  src={imgsrc}
                  style={{ width: "100px" }}
                  alt="Logo"
                />

                <ul className="nav navbar-nav ml-auto p-2">
                  <li className="nav-item dropdown d-md-down text-center">
                    <span
                      className="nav-link"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"

                    >
                      <i className="icon-bell" />
                      <span className="badge badge-pill badge-danger">{TotalNoti}</span>
                    </span>
                    <div
                      className="dropdown-menu dropdown-menu-right dropdown-menu-md"
                      style={{
                        minWidth: "150px",
                        maxWidth: "150px",
                        height: '100px',
                        overflowY: 'scroll'
                      }}
                    >

                      <div className="dropdown-header" style={{ fontSize: "smaller" }}>
                        <strong>You have {TotalNoti} notifications</strong>
                      </div>


                    </div>
                  </li>
                  <li className="nav-item dropdown text-center">
                    <span
                      className="nav-link nav-link"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img

                        className="img-avatar"
                        src="https://cdn.pixabay.com/photo/2016/08/20/05/38/avatar-1606916__340.png"
                        alt="userName"

                      />
                    </span>
                    <div className="dropdown-menu dropdown-menu-right" >
                      <Link className="dropdown-item" to={"/DFCars/DfCarsProfile"}>
                        <i className="fa fa-user" /> Profile
                      </Link>
                      {/* <span className="dropdown-item" onClick={() => this.Profile()}>
                        <i className="fa fa-user" onClick={() => this.Profile()} /> Profile
                      </span> */}
                      <span className="dropdown-item" onClick={() => this.logout()}>
                        <i className="fa fa-lock" onClick={() => this.logout()} /> Logout
                      </span>
                    </div>

                  </li>
                  <li className="nav-item text-center">
                    {Page_refresh == false ?
                      <i className="icon-refresh" onClick={() => this.Loading_Contents("load")} />
                      :
                      <div class="DFCARS_REFRESH text-right"></div>
                    }
                  </li>

                </ul>
              </div>
              <div className="d-flex " style={{ width: "100%" }}>
                <p className="mr-auto p-2"></p>
                <p className="p-2" >{userName}
                  {/* Currently Logged in Users <a href="/DF/UserUnder">{userUnder}</a> */}
                </p>
              </div>


            </header>
        }

      </React.Fragment>
    );
  }
}
