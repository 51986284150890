import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import { FormMiddleWare, MultiSelect } from "../../../../components/Form";
import DfCarsImageUpload from "./DfCarsImageUpload"
import moment from "moment";
import LoginModal from "../../../../components/Modal/Modal";
import Datatable from "../../../../components/Datatable/Datatable";
import SingleSelect from '../../../../components/Form/SingleSelect'
import { ACCESS_POINT, ImgSTEERAUTO, STEERAUTO } from "../../../../config";
import { ic_check_box } from 'react-icons-kit/md/ic_check_box'
import { basket_remove } from 'react-icons-kit/ikons/basket_remove'
import { tick } from 'react-icons-kit/ikons/tick'
import { close } from 'react-icons-kit/ikons/close'
import { shopping_cart_ok } from 'react-icons-kit/ikons/shopping_cart_ok'
import { clock } from 'react-icons-kit/iconic/clock'
import Icon from "react-icons-kit";
import { Carousel } from 'react-responsive-carousel';
import certified2 from "../../DifuzaCars/certified3.png"
import certified1 from "../../DifuzaCars/certified2.png"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { x } from 'react-icons-kit/iconic/x'


class InventoryList extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Car_Content: [],
            filterdata: [],
            maintence_work: "",
            leadsavailable: [],
            customerId: localStorage.getItem("userId"),
            User: JSON.parse(localStorage.getItem("userlog")),
            EnquiryData: [],
            Completed_data: [],
            Sold_data: [],
            OnGoing_data: [],
            dashboard: [],
            isView: false,
            FullData: [],
            data1: [],
            Rejected_data: [],
            Current_User: [],
            amount: "",
            data2: [],
            AuctionData: [],
            alertVisible: false,
            opt1: [{ label: "SOLD", value: 0 }, { label: "AVAILABLE", value: 1 }],
            opt2: [{ label: "MOVED TO SITE", value: 0 }, { label: "ALREADY MOVED", value: 1 }],
            opt1s: [],
            opt2s: [],
            column: [
                {
                    Header: "VIEW IMAGE",
                    minWidth: 145,
                    accessor: "price",
                    Cell: d => this.Image(d, "view_Image"),
                    className: "text-center"
                },
                // {
                //     Header: "UPLOAD IMAGE",
                //     minWidth: 145,
                //     accessor: "price",
                //     Cell: d => this.Image(d, "image"),
                //     className: "text-center"
                // },
                {
                    Header: "Register No",
                    minWidth: 100,
                    accessor: "Reg_no",
                },
                {
                    Header: "ID",
                    minWidth: 100,
                    accessor: "id"
                },
                {
                    Header: "MAKE",
                    minWidth: 150,
                    accessor: "makename"
                },
                {
                    Header: "MODEL",
                    minWidth: 170,
                    accessor: "modelname"
                },
                {
                    Header: "VARIANT",
                    minWidth: 150,
                    accessor: "variantname"
                },
                {
                    Header: "SOURCE",
                    minWidth: 150,
                    accessor: "source",
                    Cell: d => this.SOURCE(d),
                    className: "text-center"
                },
                {
                    Header: "YEAR",
                    accessor: "make_year"
                },
                {
                    Header: "KMS",
                    accessor: "kms"
                },
                {
                    Header: "COLOUR",
                    accessor: "ColourName"
                },
                {
                    Header: "Date",
                    accessor: "created_At"
                },
                {
                    Header: "OWNER TYPE",
                    accessor: "owner_type"
                },
                {
                    Header: "LOCATION",
                    minWidth: 140,
                    accessor: "locationname"
                },

                {
                    Header: "PRICE",
                    minWidth: 140,
                    accessor: "price"
                },
                {
                    Header: "DATA TYPE",
                    minWidth: 170,
                    accessor: "price",
                    Cell: d => this.datatype(d),
                    className: "text-center"
                },
                // {
                //   Header: "VIEW IMAGE",
                //   minWidth: 155,
                //   accessor: "price",
                //   Cell: d => this.Image(d, "view_Image")
                // },
                // {
                //   Header: "UPLOAD IMAGE",
                //   minWidth: 155,
                //   accessor: "price",
                //   Cell: d => this.Image(d, "image")
                // },
                // {
                //   Header: "MAINTENANCE",
                //   minWidth: 155,
                //   accessor: "price",
                //   Cell: d => this.Image(d, "maintenance")
                // },
                {
                    Header: "STATUS",
                    minWidth: 145,
                    accessor: "status",
                    Cell: d => this.Status(d),
                    className: "text-center"
                },
                // {
                //   Header: "DETAILS",
                //   minWidth: 155,
                //   accessor: "Movetosite",
                //   Cell: d => this.Details(d)
                // },
                // {
                //   Header: "LEADS",
                //      minWidth: 155,
                //   accessor: "",
                //   Cell: d => this.leads(d)
                // },
                {
                    Header: "SHARE",
                    minWidth: 145,
                    accessor: "",
                    Cell: d => this.Share(d)
                }
            ],
            column1: [],
            column2: [
                {
                    Header: "VIEW IMAGE",
                    minWidth: 145,
                    accessor: "price",
                    Cell: d => this.Image(d, "view_Image"),
                    className: "text-center"
                },
                // {
                //     Header: "UPLOAD IMAGE",
                //     minWidth: 145,
                //     accessor: "price",
                //     Cell: d => this.Image(d, "image"),
                //     className: "text-center"
                // },
                {
                    Header: "Register No",
                    minWidth: 100,
                    accessor: "Reg_no",
                },
                {
                    Header: "ID",
                    minWidth: 100,
                    accessor: "id"
                },
                {
                    Header: "MAKE",
                    minWidth: 150,
                    accessor: "makename"
                },
                {
                    Header: "MODEL",
                    minWidth: 170,
                    accessor: "modelname"
                },
                {
                    Header: "VARIANT",
                    minWidth: 150,
                    accessor: "variantname"
                },
                {
                    Header: "SOURCE",
                    minWidth: 150,
                    accessor: "source",
                    Cell: d => this.SOURCE(d),
                    className: "text-center"
                },
                {
                    Header: "YEAR",
                    accessor: "make_year"
                },
                {
                    Header: "KMS",
                    accessor: "kms"
                },
                {
                    Header: "COLOUR",
                    accessor: "ColourName"
                },
                {
                    Header: "Date",
                    accessor: "created_At"
                },
                {
                    Header: "OWNER TYPE",
                    accessor: "owner_type"
                },
                {
                    Header: "LOCATION",
                    minWidth: 140,
                    accessor: "locationname"
                },

                {
                    Header: "PRICE",
                    minWidth: 140,
                    accessor: "price"
                },
                {
                    Header: "DATA TYPE",
                    minWidth: 170,
                    accessor: "price",
                    Cell: d => this.datatype(d),
                    className: "text-center"
                },
                // {
                //   Header: "VIEW IMAGE",
                //   minWidth: 155,
                //   accessor: "price",
                //   Cell: d => this.Image(d, "view_Image")
                // },
                // {
                //   Header: "UPLOAD IMAGE",
                //   minWidth: 155,
                //   accessor: "price",
                //   Cell: d => this.Image(d, "image")
                // },
                // {
                //   Header: "MAINTENANCE",
                //   minWidth: 155,
                //   accessor: "price",
                //   Cell: d => this.Image(d, "maintenance")
                // },
                {
                    Header: "STATUS",
                    minWidth: 145,
                    accessor: "status",
                    Cell: d => this.Status(d),
                    className: "text-center"
                },
                // {
                //   Header: "DETAILS",
                //   minWidth: 155,
                //   accessor: "Movetosite",
                //   Cell: d => this.Details(d)
                // },
                // {
                //   Header: "LEADS",
                //      minWidth: 155,
                //   accessor: "",
                //   Cell: d => this.leads(d)
                // },
                {
                    Header: "SHARE",
                    minWidth: 145,
                    accessor: "",
                    Cell: d => this.Share(d)
                }
            ],
            EditData: {},
            Index: null,
            filteredLoc: "",
            filteredMake: "",
            LocationOptions: [],
            FilterData: [],
            MakeOptions: [],
        };
        this.vals();
    }
    async vals() {
        let allOpt = await this.decryption('getcar')
        console.log(allOpt, "allOpt")
        if (allOpt) {
            await this.setState({
                MakeOptions: allOpt.makeopt, LocationOptions: allOpt.locopt,
            })
        }
    }
    SOURCE = (d) => {
        if (d.original.source == "web") {
            return (<span className="btn btn-sm text-center btn-warning" style={{ minWidth: "130px" }}>Web</span>
            );
        }
        else {
            return (
                <span
                    className="btn btn-success btn-sm text-center"
                    style={{ minWidth: "130px" }}
                >Site</span>
            )
        }
    }
    datatype = (d) => {
        if (d.original.process == 1 || d.original.process == 0) {
            return (<span className="btn btn-sm text-center btn-success" style={{ minWidth: "150px" }}>Normal</span>
            );
        }
        else if (d.original.process == 2) {
            return (<span className="btn btn-sm text-center btn-warning" style={{ minWidth: "150px" }}>Auction Completed</span>

            );
        }
        else if (d.original.process == 3) {
            return (<span className="btn btn-sm text-center btn-danger" style={{ minWidth: "150px" }}>Auction Rejected</span>

            );
        }
        else {
            return (
                <button type="button"
                    className="btn btn-primary btn-sm text-center"
                    style={{ minWidth: "130px" }}
                >{d.original.data_type}</button>
            )
        }
    }
    Image = (d, name) => {
        let { Current_User } = this.state
        // console.log(Current_User, 'Current_User');
        if (name == "image") {
            return (
                <button type="button" className="btn btn-primary btn-sm"
                    style={{ minWidth: "130px" }}
                    onClick={() => this.DataPass(d, "images")}
                >Upload</button>
            )
        }
        else if (name == "view_Image") {
            return (
                <button type="button" className="btn btn-warning btn-sm"
                    style={{ minWidth: "130px" }}
                    data-toggle="modal" data-target={"#Viewimage_modal"}
                    onClick={() => this.view_Image(d)}
                >View Image</button>
            )
        }
        else {
            if (d && d.original && d.original.process == 1 && d.original.customerId && Current_User && Current_User.orderBy && (Current_User.orderBy == 1 || Current_User.orderBy == 2 || Current_User.orderBy == 3)) {
                return (
                    <button type="button" className="btn btn-warning btn-sm"
                        style={{ minWidth: "130px" }}
                        onClick={() => this.DataPass(d, "maintenance_modal")}
                    >Maintenance</button>
                )
            }
            else {
                return (
                    <button type="button" className="btn btn-danger btn-sm" style={{ minWidth: "130px" }}
                    >Not Available</button>
                )
            }
        }
    }
    leads = (d) => {
        if (this.state.leadsavailable.length) {
            return (<div><button type="button" className="btn btn-primary btn-sm"
                onClick={() => this.lead(d)}
                style={{ minWidth: "130px" }}
            >View leads</button></div>)
        } else {
            return (
                <div><span className="label" style={{ backgroundColor: "white" }}></span></div>)
        }
    }
    lead = (d) => {
        let location = ""
        if (d.original.location === null) {
            location = localStorage.getItem("LocationID")
            window.open(`/DFCars/MyInventory/${d.original.make}/${d.original.model}/${location}`)
        } else {
            window.open(`/DFCars/MyInventory/${d.original.make}/${d.original.model}/${d.original.location}`)
        }


    }
    view_Image = async (d) => {
        try {
            let EditData = d.original
            let Index = d.index
            let Car_Content = []
            console.log(EditData, "EditData  view_Image");
            if (EditData && (EditData.source == "site" || EditData.image)) {
                // EditData.map(async (ival, j) => {
                if (EditData && EditData.Cimages && EditData.Cimages.length > 0) {
                    Car_Content.push(
                        <div class="flexslider">
                            <ul class="slides" >
                                <div class="carousel-wrapper" style={{ border: "1px solid #00000012" }}>
                                    <Carousel
                                        arrow={false}
                                        useKeyboardArrows={true}
                                        thumbWidth={100}
                                        swipeable={true}
                                        showThumbs={true}
                                        showIndicators={false}
                                        // showThumbs={true}
                                        infiniteLoop={true} autoPlay={true}
                                        style={{
                                            position: "absolute", objectFit: "contain", maxHeight: '343px', border: "1px solid"
                                        }}>
                                        {EditData.Cimages && EditData.Cimages.length ? EditData.Cimages.map((mval, m) => {
                                            return (
                                                <div style={{ color: "#fff" }} id="singlecarlistimg">
                                                    {EditData && EditData.Certified && EditData.Certified == 1 &&
                                                        <div>
                                                            <img
                                                                id="certifiedimg"
                                                                className='d-flex justify-content-between'
                                                                style={{ position: "fixed", width: "70px" }}
                                                                src={certified2}
                                                                onError={({ currentTarget }) => {
                                                                    currentTarget.onerror = null; // prevents looping
                                                                    currentTarget.src = certified1;
                                                                }} />
                                                        </div>
                                                    }
                                                    <img src={"https://steerimages.rem.coach/" + mval.image}
                                                        alt={`${EditData.makename} ${EditData.modelname}`}
                                                        style={{ objectFit: "contain", maxHeight: '343px', width: "100%", borderRadius: "10px" }}

                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null; // prevents looping
                                                            currentTarget.src = "https://steerimages.difuza.com/DFCARS_daa63c54_4b15d8d6_1662965206825.jpg";
                                                        }}
                                                    />
                                                    {/* {EditData.source == "site" && */}
                                                    <>
                                                        <br />
                                                        <h2 style={{ fontWeight: "600", color: "#07278f", fontFamily: "sans-serif", float: "center", textTransform: "uppercase", fontSize: "16px" }}>{mval && mval.imagesname && mval.imagesname}</h2>
                                                    </>
                                                    {/* } */}
                                                </div>
                                            )
                                        }) : (
                                            <div style={{ height: "350px", color: "#fff" }}>
                                                <li >
                                                    <img src="https://steerimages.difuza.com/DFCARS_daa63c54_4b15d8d6_1662965206825.jpg"
                                                        // style={{ minHeight: "350px", maxHeight: "350px" }}
                                                        style={{ objectFit: "contain", maxHeight: '343px', width: "100%", borderRadius: "10px" }}
                                                        data-webp=''
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null; // prevents looping
                                                            currentTarget.src = "https://steerimages.difuza.com/DFCARS_daa63c54_4b15d8d6_1662965206825.jpg";
                                                        }} />

                                                </li>
                                            </div>)
                                        }
                                    </Carousel>
                                </div>
                            </ul>
                        </div>
                    )
                }
                else {
                    Car_Content.push(<>
                        <h2 style={{ fontWeight: "600", color: "#07278f", fontFamily: "sans-serif", float: "center", textTransform: "uppercase", fontSize: "16px" }}>Image Not Found..</h2>
                    </>
                    )
                }
                // })
            } else {
                let image = JSON.parse(EditData.filesArray)
                let img_Contetnt = []
                let w = Object.keys(image).map((key, i) => {
                    if (image[key] && image[key].length) {
                        image && image[key].map((ival) => {
                            img_Contetnt.push(<>
                                <img
                                    src={`${ACCESS_POINT}/superAdmin/file?fileurl=${ival}`}
                                    style={{ objectFit: "contain", maxHeight: '343px', width: "100%", borderRadius: "10px" }}
                                    onClick={() => window.open(`${ACCESS_POINT}/superAdmin/file?fileurl=${ival}`)}
                                    alt="Error"
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = `https://steerimages.difuza.com/DFCARS_daa63c54_4b15d8d6_1662965206825.jpg`
                                    }}
                                ></img>
                            </>
                            )

                        })
                    }
                })
                console.log(img_Contetnt, "img_Contetnt");
                if (img_Contetnt && img_Contetnt.length > 0) {
                    Car_Content.push(<>
                        <div class="flexslider">
                            <ul class="slides" >
                                <div class="carousel-wrapper" style={{ border: "1px solid #00000012" }}>
                                    <Carousel
                                        arrow={false}
                                        useKeyboardArrows={true}
                                        thumbWidth={100}
                                        swipeable={true}
                                        showThumbs={true}
                                        showIndicators={false}
                                        // showThumbs={true}
                                        infiniteLoop={true} autoPlay={true}
                                        style={{
                                            position: "absolute", objectFit: "contain", maxHeight: '343px', border: "1px solid"
                                        }}>

                                        {img_Contetnt}
                                    </Carousel>
                                </div>
                            </ul>
                        </div>

                    </>
                    )
                } else {
                    Car_Content.push(<>
                        <h2 style={{ fontWeight: "600", color: "#07278f", fontFamily: "sans-serif", float: "center", textTransform: "uppercase", fontSize: "16px" }}>Image Not Found..</h2>
                    </>
                    )
                }
                // let w = Object.keys(image).map((key, i) => {
                //   if (image[key] && image[key].length) {
                //     image && image[key].map((ival) => {
                //       Car_Content.push(<>
                //         <div class="flexslider">
                //           <ul class="slides" >
                //             <div class="carousel-wrapper" style={{ border: "1px solid #00000012" }}>
                //               <Carousel
                //                 arrow={false}
                //                 useKeyboardArrows={true}
                //                 thumbWidth={100}
                //                 swipeable={true}
                //                 showThumbs={true}
                //                 showIndicators={false}
                //                 // showThumbs={true}
                //                 infiniteLoop={true} autoPlay={true}
                //                 style={{
                //                   position: "absolute", objectFit: "contain", maxHeight: '343px', border: "1px solid"
                //                 }}>


                //                 <img
                //                   src={`${ACCESS_POINT}/superAdmin/file?fileurl=${ival}`}
                //                   onClick={() => window.open(`${ACCESS_POINT}/superAdmin/file?fileurl=${ival}`)}
                //                   alt="Error" style={{ width: 200, height: 200, marginRight: "10px" }}
                //                 ></img>
                //               </Carousel>
                //             </div>
                //           </ul>
                //         </div>
                //       </>
                //       )

                //     })
                //   }
                // })
            }
            await this.setState({ Car_Content, EditData })
        } catch (error) {
            console.log(error);
        }
    }
    DataPass = async (d, modals) => {
        await this.setState({ EditData: d.original, Index: d.index })
        window.$(`#${modals}`).modal("show")
    }
    Share = (d) => {
        return (<button type="button" className="btn btn-link btn-sm"
            style={{ minWidth: "130px" }}
            onClick={() => this.Share1(d)}
        >share</button>)

    }
    Share1 = (d) => {
        this.Productnav(d.original.id)
        // window.open(`${STEERAUTO}/SingleListCars/${d.original.id}`)
    }

    Movetosite = async (d, str = null) => {
        try {
            let body = d.original
            let Movetosite = await cmsContent.DFcarsMovedtosite("tbl_Dfcars_Garage", body)
            console.log(Movetosite, "Movetosite");
            if (Movetosite) {
                this.mainfunc();
            }
        } catch (err) { console.log(err); }
        this.mainfunc();

    }
    mainfunc = async () => {
        try {
            let results = await cmsContent.BUYDFCARZ(this.state.User.id, 'Teleinventlist')
            console.log(results, 'results')
            if (results) {
                let Completed_data = []
                let Rejected_data = []
                let Sold_data = []
                let Delete_data = []
                let OnGoing_data = []
                let logos = results.Inventory;
                let data1 = logos;
                console.log(data1, "data1");
                let Data = data1.filter(a => a.status != "inactive")
                Completed_data = data1.filter(a => a.Auction_Complete == true)
                Rejected_data = data1.filter(a => a.Auction_reject == true)
                OnGoing_data = data1.filter(a => a.ongoing == 1 && a.status == null)
                Sold_data = data1.filter(a => a.status == "inactive")
                Delete_data = data1.filter(a => a.car_status == "delete")
                await this.setState({
                    OnGoing_data, Sold_data,
                    Completed_data, all_data: results.Inventory,
                    Data, data1, loading: false, restriction: false,
                    FullData: Data, dashboard: results.data_for_f,
                    Rejected_data,
                    Delete_data
                })
                this.View()
            }
        } catch (error) {
            console.log(error);
        }
    }
    Details = (d) => {
        if (d.original.Movedtosite !== null && d.original.Movedtosite) {
            return (<div><button type="button" className="btn btn-success btn-sm mr-xl-5 w-100"
                style={{ minWidth: "130px" }}
            >Already Moved</button></div>);
        }
        else if (d.original.process == 2) {
            return (<div><button type="button" className="btn btn-secondary  btn-sm mr-xl-5 w-100"
                style={{ minWidth: "130px" }}

            >Sold via Auction</button></div>);
        }
        else if (d.original.process == 3 || d.original.process == 4) {
            return (
                <div><button type="button" className="btn btn-warning  btn-sm mr-xl-5 w-100"
                    style={{ minWidth: "130px" }}

                >Auction Rejected</button></div>
            )
        }
        else if (d.original.process == 1 || d.original.process == 0) {
            return (
                <div><button type="button" className="btn btn-danger  btn-sm mr-xl-5 w-100"
                    style={{ minWidth: "130px" }}
                    onClick={() => this.alert_Submit(this.Movetosite, d)}

                >Move To Site</button></div>
            )
        }
        else {
            return (<div><button type="button" className="btn btn-danger btn-sm mr-xl-5 w-100"
                style={{ minWidth: "130px" }}
                onClick={() => this.alert_Submit(this.Movetosite, d)}
            >Move To Site</button></div>);
        }
    }
    Status = (d) => {
        // console.log(d.original, "skhfskhfskhsfststau");
        if (d.original.status == 'inactive' && d.original.ongoing == 1) {
            return (<div><button type="button" className="btn btn-success  btn-sm mr-xl-5 w-100"
                style={{ minWidth: "130px" }}

            >Sold</button></div>);
        }
        else if (d.original.status == null && d.original.ongoing == 1) {
            return (<div><button type="button" className="btn btn-danger  btn-sm mr-xl-5 w-100"
                style={{ minWidth: "130px" }}

            >Contracted</button></div>);
        }
        else if (d.original.process == 2) {
            return (<div><button type="button" className="btn btn-warning  btn-sm mr-xl-5 w-100"
                style={{ minWidth: "130px" }}

            >Sold via Auction</button></div>);
        }
        else if (d.original.process == 3 || d.original.process == 4) {
            return (

                <LoginModal
                    buttonTitle="Push To Auction"
                    title="Push To Auction"
                    id={`auction${d.original.id}`}
                    extraClass="btn btn-sm btn-primary  btn-sm mr-xl-5 w-100"
                    onClick={() => this.handle_auction(d.original)}
                    buttonStyle={{ minWidth: "160px" }}
                    extraStyle={{ width: 'auto' }}
                    bodyText={
                        <div>
                            <div className="card-body">
                                <div className="row form-group">
                                    <div className="col-sm-2" />
                                    <div className="col-sm-7">
                                        <Alert
                                            className="badge-content"
                                            color={this.state.color}
                                            isOpen={this.state.alertVisible}
                                            toggle={this.onDismiss}
                                        >
                                            {this.state.textalert}
                                        </Alert>
                                    </div>
                                    <div className="col-sm-3" />
                                </div>

                                <div className="row form-group">
                                    <div className="col-sm-1" />
                                    <div className="col-sm-3 text-right">
                                        <label htmlFor="exampleInputEmail1">
                                            Bid Amount
                                        </label>
                                    </div>
                                    <div className="col-sm-5">
                                        <input
                                            type="text"
                                            className="form-control"
                                            // id="registerName"
                                            name="amount"
                                            placeholder="Enter Amount"
                                            value={this.state.amount}
                                            onChange={this.handleUserInput}
                                        />
                                    </div>
                                    <div className="col-sm-3" />
                                </div>
                                <br />
                                <div className="row-form-group">
                                    <div className="col-sm-2" />
                                    <div className="col-sm-4" />
                                    <div className="col-sm-4">
                                        <button
                                            type="button"
                                            className="btn btn-primary btn-sm"
                                            onClick={() => this.alert_Submit(this.submitauction())}
                                            style={{ marginLeft: "250px" }}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    }
                />
            );
        }

        else {
            return (
                <div>
                    <button type="button" className="btn btn-secondary btn-sm mr-xl-5 w-100"
                        style={{ minWidth: "130px" }}
                    >AVAILABLE</button>
                </div>
            );
        }
    }
    handle_auction = async (d) => {
        // console.log(d, "aidctopn data");
        await this.setState({
            amount: "", AuctionData: d

        })
    }
    submitauction = async () => {
        const { amount } = this.state;
        if (!amount) {
            alert("please Enter Bid Amount")
            return false
        }
        let { AuctionData } = this.state

        let d = AuctionData
        let id = AuctionData.formid;
        const fproofid = await cmsContent.getFreedom("*", "tbl_FProof", `id = ${d.formid} `, 1, 1);
        let CategoryArray = {};
        CategoryArray.Bidamount = parseInt(amount);
        CategoryArray.Auction = 1;

        try {
            let result = await cmsContent.updateMaster(
                "tbl_FProof",
                fproofid.data[0].id,
                CategoryArray
            );
            if (result) {
                let CategoryArray1 = {}
                CategoryArray1.fproof_id = d.formid
                CategoryArray1.customerid = d.customerid
                CategoryArray1.new_bid_amount = parseInt(amount);
                CategoryArray1.ServiceId = 10
                let result1 = await cmsContent.addMaster('tbl_bid', CategoryArray1)
                if (result1) {
                    await this.setState({
                        amount: "",
                        alertVisible: true,
                        color: "success",
                        textalert: "Success",
                        Datas: []
                    })
                    await this.mainfunc()

                }
                this.mainfunc()
                window.location.reload()
            }
        } catch (error) {
            console.log(error);
        }

    }
    View = () => {
        try {
            const data = this.state.Data;
            // console.log(data, "data");
            const modelid = localStorage.getItem('modelid');
            const makeid = localStorage.getItem('makeid');


            let result = data.filter(function (v, i) {
                return v.make == makeid && v.model == modelid;
            });

            // console.log(result, "filter");
            this.setState({ filterdata: result });
        } catch (err) { console.log(err); }
    }

    async componentDidMount() {

        await this.setState({ loading: true })
        let ALL_User_Details = await this.decryption('Downline_Details')
        if (ALL_User_Details && ALL_User_Details.Current_User && ALL_User_Details.Current_User[0]) {
            await this.setState({ Current_User: ALL_User_Details.Current_User[0] })
        }
        await this.mainfunc()
    }

    changeModelstate = async (s, v) => {
        this.setState({ [s]: v });
    }
    onClk = (val) => {
        let { column2 } = this.state
        if (val === 1) { this.setState({ Data: this.state.all_data, FilterData: this.state.all_data, filteredLoc: "", filteredMake: "", column: column2 }) }
        if (val === 2) { this.setState({ Data: this.state.Rejected_data, FilterData: this.state.Rejected_data, filteredLoc: "", filteredMake: "", column: column2 }) }
        if (val === 3) { this.setState({ Data: this.state.Completed_data, FilterData: this.state.Completed_data, filteredLoc: "", filteredMake: "", column: column2 }) }
        if (val === 4) { this.setState({ Data: this.state.Sold_data, FilterData: this.state.Sold_data, filteredLoc: "", filteredMake: "" }) }
        if (val === 5) { this.setState({ Data: this.state.OnGoing_data, FilterData: this.state.OnGoing_data, filteredLoc: "", filteredMake: "", column: column2 }) }
        if (val === 6) {
            let filter = this.state.column2.filter(function (el) {
                return el.Header !== "UPLOAD IMAGE" && el.Header !== "STATUS" && el.Header !== "DATA TYPE" && el.Header !== "SHARE";
            })
            let Status = {
                Header: "STATUS",
                minWidth: 145,
                accessor: "delete_status",
            }
            filter.splice(2, 0, Status)

            this.setState({ Data: this.state.Delete_data, FilterData: this.state.Delete_data, filteredLoc: "", filteredMake: "", column: filter })
        }

        window.scroll({
            top: 400,
            left: 0,
            behavior: "smooth",
        });
    }
    edash = () => {
        return (<div className="card">

            <div className="card-header">
                <div className="col-sm-6" >
                    <h2 className="mt-2 black">Dashboard</h2>
                </div>
            </div>
            <div className="card-body">

                <div className="">
                    <div className="row text-center">
                        <div className="col ">
                            <div className="dashboardCard1" onClick={() => this.onClk(1)} >
                                <Icon icon={ic_check_box} style={{ color: 'green' }} size={35} />
                                <h2 className="mt-2 black" >All Cars</h2>
                                <div >
                                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.dashboard && this.state.dashboard[0] && this.state.dashboard[0].all ? this.state.dashboard[0].all : 0}</b></h1>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="dashboardCard1" onClick={() => this.onClk(3)} >
                                <Icon icon={tick} style={{ color: 'green' }} size={35} />
                                <h2 className="mt-2 black" >Auction Completed</h2>
                                <div >
                                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.dashboard && this.state.dashboard[0] && this.state.dashboard[0].Auction_Complete ? this.state.dashboard[0].Auction_Complete : 0}</b></h1>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="dashboardCard1" onClick={() => this.onClk(2)} >
                                <Icon icon={close} style={{ color: 'red' }} size={35} />
                                <h2 className="mt-2 black" >Auction Rejected</h2>
                                <div >
                                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.dashboard && this.state.dashboard[0] && this.state.dashboard[0].Auction_reject ? this.state.dashboard[0].Auction_reject : 0}</b></h1>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="dashboardCard1" onClick={() => this.onClk(4)} >
                                <Icon icon={shopping_cart_ok} style={{ color: 'green' }} size={35} />
                                <h2 className="mt-2 black" >Sold</h2>
                                <div >
                                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.Sold_data && this.state.Sold_data.length && this.state.Sold_data.length > 0 ? this.state.Sold_data.length : 0}</b></h1>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="dashboardCard1" onClick={() => this.onClk(5)} >
                                <Icon icon={clock} style={{ color: '#ffc107' }} size={35} />
                                <h2 className="mt-2 black" >Ongoing</h2>
                                <div >
                                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.OnGoing_data && this.state.OnGoing_data.length && this.state.OnGoing_data.length > 0 ? this.state.OnGoing_data.length : 0}</b></h1>
                                </div>
                            </div>
                        </div>
                        <div className="col ">
                            <div className="dashboardCard1" onClick={() => this.onClk(6)} >
                                <Icon icon={x} style={{ color: 'red' }} size={35} />
                                <h4 className="mt-2 black" >Deleted Ads</h4>
                                <div >
                                    <h1 className="bold mt-4 mb-2 black"><b> {this.state.Delete_data && this.state.Delete_data ? this.state.Delete_data.length : 0}</b></h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row col-12 mt-2">
                    <div className="col-sm-5 ">
                        <h4 className="black">status :</h4>
                        <SingleSelect
                            options={this.state.opt1}
                            handleChange={e => this.cng(e, 1)}
                            selectedService={this.state.opt1s}
                        />
                    </div>
                    <div className="col-sm-5 ">
                        <h4 className="black">Web list :</h4>
                        <SingleSelect
                            options={this.state.opt2}
                            handleChange={e => this.cng(e, 2)}
                            selectedService={this.state.opt2s}
                        />
                    </div>
                    <div className="col-sm-2 " style={{ paddingTop: "17px" }}>
                        {/* <h4 className="black"> </h4> */}
                        <button className="btn btn-primary btn-sm" onClick={this.onall}>ALL</button>
                    </div>
                </div>
            </div>
        </div >)
    }
    onall = () => { this.setState({ Data: this.state.FullData, opt1s: "", opt2s: "" }) }
    cng = (e, val) => {
        let data1 = []
        let data2 = []
        if (val === 1) {
            this.state.FullData.map((ival, i) => {
                if (e.value == 0) {
                    if (ival.status === "inactive") {
                        data1.push(ival)
                    }
                }
                if (e.value == 1) {
                    if (ival.status !== "inactive") {
                        data1.push(ival)
                    }
                }
            })
            this.setState({ opt2s: "", opt1s: e, Data: data1 })
        }
        if (val === 2) {
            this.state.FullData.map((ival, i) => {
                if (e.value == 1) {
                    if (parseInt(ival.Movedtosite) === 1) {
                        data2.push(ival)
                    }
                }
                if (e.value == 0) {
                    if (parseInt(ival.Movedtosite) !== 1) {
                        data2.push(ival)
                    }
                }
            })
            this.setState({ opt1s: "", opt2s: e, Data: data2 })
        }

    }
    maintanence_onchange = async (e, name) => {
        if (e) {
            await this.setState({ [name]: e })
        }
    }
    maintenance_start = async () => {
        let { EditData, maintence_work } = this.state
        // console.log(EditData, "EditData");
        if (!maintence_work) {
            alert("please Enter Maintenance Work")
            return false
        }
        if (maintence_work) {
            let json = maintence_work.split(',')
            let categoryArray = {}
            categoryArray.commit_work = JSON.stringify(json)
            // console.log(categoryArray, "categoryArray");
        }
    }
    makeFilter = async (d) => {
        let { filteredMake, MakeOptions, filteredLoc, FilterData } = this.state;

        //   console.log(d, "d")

        await this.setState({ filteredMake: d })

        if (d && d.length == 0 && filteredLoc.length == 0) {
            await this.setState({ Data: this.state.FilterData })
        }
        else if (d && d.length == 0 && filteredLoc.length != 0) {
            var filtered_data = await FilterData.filter(x => filteredLoc.some(y => x.location == y.value))
            await this.setState({ Data: filtered_data })
        }
        else if (d && d.length != 0 && filteredLoc && filteredLoc.length != 0) {
            var filtered_make = await FilterData.filter(fd => d.some(d => fd.make == d.value))
            var filtered_loc = await FilterData.filter(fd => filteredLoc.some(fl => fd.location == fl.value))
            var filtered_data = await filtered_make.filter(fm => filtered_loc.some(fl => fm.make == fl.make && fm.location == fl.location))
            await this.setState({ Data: filtered_data })
        }
        else if (d && d.length != 0 && filteredLoc.length == 0) {
            var filtered_data = await FilterData.filter(x => d.some(y => x.make == y.value))
            await this.setState({ Data: filtered_data })
        }
        else {
            //nothing
        }
    }
    locationFilter = async (d) => {
        let { locationOpt, filteredLoc, FilterData, filteredMake, EnquiryDatas } = this.state
        await this.setState({ filteredLoc: d })

        if (d && d.length == 0 && filteredMake.length == 0) {
            await this.setState({ Data: this.state.FilterData })
        }
        else if (d && d.length == 0 && filteredMake.length != 0) {
            var filtered_data = await FilterData.filter(x => filteredMake.some(y => x.make == y.value))
            await this.setState({ Data: filtered_data })
        }
        else if (d && d.length != 0 && filteredMake.length != 0) {
            var filtered_loc = await FilterData.filter(fd => d.some(d => fd.location == d.value))
            var filtered_make = await FilterData.filter(fd => filteredMake.some(d => fd.make == d.value))
            var filtered_data = await filtered_loc.filter(fl => filtered_make.some(fm => fl.location == fm.location && fl.make == fm.make))
            await this.setState({ Data: filtered_data })
        }
        else if (d && d.length != 0 && filteredMake.length == 0) {
            var filtered_data = await FilterData.filter(x => d.some(y => x.location == y.value))
            await this.setState({ Data: filtered_data })
        }
        else {
            //nothing
        }

    }
    clearFilter = async () => {
        await this.setState({ filteredMake: "", filteredLoc: "", Datas: this.state.FilterData })
    }
    render() {
        const { match: { params } } = this.props;
        let { loading, EditData, maintence_work, LocationOptions, MakeOptions, Car_Content } = this.state;
        const makeid = localStorage.getItem('makeid');
        return (<React.Fragment>
            <main className="main my-4">
                <div className="container-fluid">
                    {this.edash()}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h1>Inventory List</h1>
                                </div>
                                <div className="card-body">

                                    <div className="row form-group">
                                        <div className="col-sm-4">
                                            <LoginModal
                                                title="View Image"
                                                id="Viewimage_modal"
                                                extraStyle={{ width: 'auto' }}
                                                buttonStyle={{ backgroundColor: "white", border: "white" }}
                                                bodyText={
                                                    <>
                                                        {Car_Content && Car_Content ? Car_Content : ""}
                                                    </>
                                                }
                                            />

                                        </div>
                                    </div>
                                    <div className="row form-group">
                                        <div className="col-sm-4">
                                            <LoginModal
                                                title="MAINTENANCE"
                                                id="maintenance_modal"
                                                extraStyle={{ width: 'auto' }}
                                                buttonStyle={{ backgroundColor: "white", border: "white" }}
                                                bodyText={
                                                    <>
                                                        <div className="row form-group">
                                                            <div className="col-sm-4 text-right">Maintenance Work :</div>
                                                            <div className="col-sm-6">
                                                                <textarea type="text"
                                                                    placeholder="Maintenance Work" value={this.state.maintence_work} className="form-control" name="maintence_work"
                                                                    onChange={(e) => this.maintanence_onchange(e.target.value, "maintence_work")} />
                                                                {<div className="text-center"><label className="error-shows">Please separate work by comma. eg: work1,work2</label></div>}

                                                            </div>
                                                        </div>

                                                        <div className="row form-group">
                                                            <div className="col-sm-4"></div>
                                                            <div className="col-sm-4">
                                                                <button className="btn btn-sm btn-primary text-center" onClick={() => this.maintenance_start()}>
                                                                    Maintenance Start
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </>
                                                }
                                            />

                                        </div>
                                    </div>
                                    <div className="row form-group">
                                        <div className="col-sm-4">
                                            <LoginModal
                                                buttonTitle="Add Enquiry"
                                                title="Upload Images"
                                                id="images"
                                                extraClass="btn btn-primary"
                                                extraStyle={{ width: 'auto' }}
                                                onClick={this.reset}
                                                buttonStyle={{ backgroundColor: "white", border: "white" }}
                                                bodyText={
                                                    <DfCarsImageUpload
                                                        EditData={this.state.EditData}
                                                        these={this.changeModelstate}
                                                        FullData={this.state.Data}
                                                        Index={this.state.Index}
                                                    />}
                                            />
                                        </div>
                                    </div>
                                    {
                                        this.state.FilterData && this.state.FilterData.length ?
                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <label htmlFor="locationFilter" style={{ fontWeight: 500, fontSize: "medium" }} > Location filter: </label>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <label htmlFor="makefilter" style={{ fontWeight: 500, fontSize: "medium" }}> Make filter: </label>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <MultiSelect
                                                                id="locationFilter"
                                                                name="location"
                                                                placeholder="Filter Location"
                                                                options={LocationOptions}
                                                                handleChange={d => this.locationFilter(d)}
                                                                selectedService={this.state.filteredLoc}
                                                            />
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <MultiSelect
                                                                id="makefilter"
                                                                name="make"
                                                                placeholder="Filter Make"
                                                                options={MakeOptions}
                                                                handleChange={d => this.makeFilter(d)}
                                                                selectedService={this.state.filteredMake}
                                                            />
                                                        </div>
                                                    </div>
                                                    <br></br>
                                                    <div className="row">
                                                        <div className="col-sm-5" />
                                                        <div className="col-sm-2">
                                                            <button type="button" className="btn btn-sm btn-primary" onClick={() => {
                                                                this.clearFilter()
                                                            }}> Clear filter</button>
                                                        </div>
                                                        <div className="col-sm-5"></div>
                                                    </div>
                                                </div>
                                                <br></br>
                                            </>
                                            :
                                            ""
                                    }
                                    {loading
                                        ?
                                        <div className="text-center">
                                            <i className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}></i>
                                        </div>
                                        :
                                        params.id === makeid
                                            ? (
                                                <Datatable
                                                    data={this.state.filterdata}
                                                    columnHeading={this.state.column} />
                                            )
                                            :
                                            (this.state.Data && this.state.Data.length
                                                ?
                                                <Datatable
                                                    data={this.state.Data}
                                                    columnHeading={this.state.column}
                                                />
                                                :
                                                <>
                                                    <div style={{ textAlign: "center", fontSize: "larger" }} ><b>No rows to show</b></div>
                                                    <br></br>
                                                </>
                                            )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </React.Fragment>
        )
    }
}
export default InventoryList;