import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
//import UpdateStatus from "./UpdateStatus"
import moment from "moment";
import LoginModal from "../../../../components/Modal/Modal";
import UsertypeComments from "../UserMaster/UsertypeComments";
import Datatable from "../../../../components/Datatable/Datatable";

class DFContracted extends FormMiddleWare {
    constructor(props) {
      super(props);
      this.state = {
        Data: [],
        User: JSON.parse(localStorage.getItem("userlog")),
        EnquiryData:[],
        alertVisible:false,
        column:[
            {
                Header: "Prospect",
                accessor: "name"
              },
              {
                Header: "Contact",
                accessor: "mobile"
              },
            {
              Header: "Status",
              accessor: "status"
            },
            {
                Header: "ApprovedBy",
                accessor: "userName"
            },
            {
                Header: "Specification-Sheet",
                accessor: "mobile",
               Cell: d => this.Specification(d)
              },
            //   {
            //     Header: "Approval",
            //     accessor: "mobile",
            //     Cell: d => this.Approval(d)
            //   },
              {
               Header: "Comments",
               accessor: "mobile",
               Cell: d => this.UpdateSTatus(d)
             },
           ],
           stage:"",
           CustomerId:""
         };
       }
     
       UpdateSTatus = (d) => {
         return (<button type="button" className="btn btn-warning"
           data-toggle="modal" data-target={"#status"}
           onClick={() => this.Editdata(d)}
          >View Comments</button>);
       }
     
       Editdata=async(d)=>{
         
         this.setState({stage:"contracted",CustomerId:d.original.id})
       }

    F_proofForm=async(d)=>{
        window.open(`/DFCars/AT_Booking_Confirmation_Reproof/${d.original.id}`,'_blank')
    }

    Specification=(d)=>{
       // if(d.original.FormFilled=='yes'||d.original.FormFilled=='approved'){
          return(<button type="button" className="btn btn-primary"
          onClick={()=>this.F_proofForm(d)}
          >View Form</button>)
        // }else{
        //   return(<button type="button" className="btn btn-warning"
        //   onClick={()=>this.F_proofForm(d)}
        //   >Fill Form</button>)
        // }
        
      }

      Approval=(d)=>{
        if (d.original.approval == 0) {
            return (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => this.F_proofForm1(d)}
              >
                Waiting for Approval
              </button>
            );
          } else if (d.original.approval == 1) {
            return (
              <button
                type="button"
                className="btn btn-warning"
                onClick={() => this.F_proofForm1(d)}
              >
                Approved
              </button>
            );
          }
        };

        F_proofForm1 = async d1 => {
          // window.open(
          //   `/DFCars/AT_Booklet_Purchase_Contract_Proof/${d.original.id}`,
          //   "_blank"
          // );
          let d = d1.original;
          let approval = d1.original.approval;
         // console.log(d); 
          let sub = {};
          if (approval == 0) {
            sub.approval = "1";
          } else {
            sub.approval = "0";
          };
           //sub.Approved_date
           let CurrentDate  = new Date();
           CurrentDate = moment(CurrentDate).format("YYYY-MM-DD");
          //  sub.Approved_date = CurrentDate;
          //  sub.Approved_by = this.state.User.id;
          // console.log(sub);
          const result = await cmsContent.updateMaster(
            "tbl_Booking_Confirmation_Reproof",
            d.value,
            sub
          );
          if (result) {
            let newData =[...this.state.Data];
                newData[d1.index].approval=sub.approval;
                this.setState({
                    Data:newData
                })
          }
        };


    async componentDidMount() {
        try{

            let Buyer = await cmsContent.getFreedom(
                'tbl_dfcarz_prosperities.*,tbl_user_web.userName,tbl_Booking_Confirmation_Reproof.approval,tbl_Booking_Confirmation_Reproof.id as value',
                'tbl_dfcarz_prosperities,tbl_Booking_Confirmation_Reproof,tbl_user_web',
                `tbl_user_web.id = tbl_Booking_Confirmation_Reproof.userid and tbl_dfcarz_prosperities.type='buyer' and tbl_dfcarz_prosperities.id = tbl_Booking_Confirmation_Reproof.customerid`
            )
            if(Buyer){
                Buyer.data.map((ival,i)=>{
                    ival.FormFilled='no';
                    ival.followup=moment(ival.followup).format("YYYY-MM-DD");
                 })
                // console.log(Buyer);
                // let result = await cmsContent.getFreedom(
                //     `*`,
                //     `tbl_Booking_Confirmation_Reproof`,
                //       1
                // );
                // Buyer.data.map((ival,i)=>{
                //     result.data.map((jval,j)=>{
                //       if(ival.id==jval.customerid && jval.approval==1){
                //           ival.FormFilled='approved';
                //       }else if(ival.id==jval.customerid){
                //         ival.FormFilled='yes';
                //       }
                //     })
                //    })
                this.setState({Data:Buyer.data})
            }

        }catch(error){
            console.log(error);
        }
    }

    render(){
        return( <React.Fragment>
            <main className="main my-4">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h1>Contracted</h1>
                    </div>
                    <div className="card-body">
                    <div className="row form-group">
                    <div className="col-sm-4">
                      <LoginModal
                        buttonTitle="Add Enquiry"
                        title="Update Commets"
                        id="status"
                        extraClass="btn btn-primary"
                        onClick={this.reset}
                        extraStyle={{width:'1000px'}}
                        buttonStyle={{ backgroundColor: "white", border: "white" }}
                        bodyText={
                          <UsertypeComments
                          stage={this.state.stage}
                          customerid={this.state.CustomerId}
                          />}
                      />
                    </div>
                  </div>
                    {this.state.Data.length ? (
                              <Datatable
                                data={this.state.Data}
                                columnHeading={this.state.column}
                              />
                            ) : null}
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </main>
                    </React.Fragment>
                    )
    }
}
export default DFContracted;