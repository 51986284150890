import React, { Component } from "react";
import { Alert } from "reactstrap";
import CmsContent from "../../../../MiddleWare/CmsContent";
import Datatable from "../../../../components/Datatable/Datatable";
import { FormMiddleWare, MultiSelect } from "../../../../components/Form";
import LoginModal from "../../../../components/Modal/Modal";
import moment from "moment";
import SingleSelect from "../../../../components/Form/SingleSelect";
import { calendar_ok } from 'react-icons-kit/ikons/calendar_ok'
import { calendar } from 'react-icons-kit/ikons/calendar'
import { x } from 'react-icons-kit/iconic/x'
import { alarmClock } from 'react-icons-kit/metrize/alarmClock'
import Icon from "react-icons-kit";
import { alert } from 'react-icons-kit/ionicons/alert'
import { ACCESS_POINT } from "../../../../config";
import { time } from "react-icons-kit/ikons/time";
import { user_delete } from "react-icons-kit/ikons/user_delete";
import { close } from "react-icons-kit/ikons/close";
import { shuffle } from "react-icons-kit/ikons/shuffle";

class TeleBuyerEnquiryform extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      statusbtn:false,
      bulkAllocate: false,
      alertVisible: false,
      alertVisible1: false,
      editvalue: "",
      unallocated: [],
      editid: null,
      isEdit: false,
      ftddata: [],
      noncontactdata: [],
      allEnquiry: [],
      EnquiryData: [],
      EditData: {},
      EnquiryDatas: [],
      MakeOptions: [],
      coloropt: [],
      variantopt: [],
      statuschg1: false,
      statuschg2: false,
      status1options: [
        { value: 0, label: "Scheduled Follow up" },
        { label: "Scheduled Evaluation", value: 1 },
        { value: 2, label: "Mark Closed" },
      ],
      modelopt: [],
      Index: null,
      loading: false,
      userid: JSON.parse(localStorage.getItem("userlog")),
      usertypelog: JSON.parse(localStorage.getItem("usertypelog")),
      LocationID: localStorage.getItem(`LocationID`),
      CloseEnquiry: false,
      status: [{ label: "Scheduled Follow up", value: 1 }, { label: "Closed", value: 2 }, { label: "Evaluated", value: 3 }],
      LocationDetail: [],
      suggestion: [],
      suggestion1: [],
      dataof: [],
      remainder: [],
      ftdval: false,
      ftdDashDate: moment(new Date()).format("YYYY-MM-DD"),
      data1: [],
      FullData1: [],
      closeddata: [],
      livedata: [],
      dateVisible: false,
      ftd: null,
      dashboard: [{ "live": 0, "close": 0, "today": 0, "MTD": 0, "remainder": 0 }],
      FullData: [],
      StatusupdatealertVisible: false,
      StatusEditData: {},
      sellerstatus1: {},
      sellerstatus2: {},
      sellerstatus3: {},
      sellerstatus1options: [{ label: "Yes, Interested in Buying", value: 0 },
      { label: "Yes, but not interested, Mark closed", value: 1 },
      { label: "Couldn’t speak to customer", value: 2 }
      ],
      sellerstatus2options: {},
      sellerstatus3options: {},
      workproc: false,
      SalesSelect: [],
      SelectedEnquiries: [],
      statusArray: [],
      remarkVisible: false,
      remarkFollowupVisible: false,
      remarks: "",
      followup: "",
      column1: [
        {
          Header: "Update Status",
          accessor: "edit",
          Cell: d => this.UpdateStatus(d),
          className: "text-center",
          width: 120,

        },
        {
          Header: "View Status",
          accessor: "edit",
          width: 120,
          Cell: d => this.StatusView(d),
          className: "text-center"
        },
        {
          Header: "View Details",
          accessor: "view",
          width: 110,
          Cell: d => this.fullview(d),
          className: "text-center"
        },
        {
          Header: "Name",
          accessor: "name"
        },
        {
          Header: "Mobile number",
          accessor: "mobile",
          Cell: d => this.Call(d.original.mobile)
        },
        {
          Header: "Make",
          accessor: "make",
          Cell: (d) => this.getValueFromArray(d.original.Make, this.state.MakeOptions)

        },
        {
          Header: "Model",
          accessor: "model",
          Cell: (d) => this.getValueFromArray(d.original.Model, this.state.modelopt)
        },
        {
          Header: "Variant",
          accessor: "variant",
          Cell: (d) => this.getValueFromArray(d.original.Variant, this.state.variantopt)

        },
        {
          Header: "Color",
          accessor: "color",
          Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)

        },
        {
          Header: "Type",
          accessor: "type"
        },
        {
          Header: "EnquriryDate",
          accessor: "createdAt"
        },
        // {
        //   Header: "Budget",
        //   accessor: "budget"
        // },
        {
          Header: "Sales Consultant",
          minWidth: 140,
          accessor: "Sales_employee_name"
        },
        {
          Header: "Print pdf",
          accessor: "print",
          Cell: d => this.viewpdf(d),
          width: 110,
          className: "text-center"
        },
      ],

        statuscolumn1: [
          {
            Header: "Update Status",
            accessor: "edit",
            Cell: d => this.UpdateStatus(d)
          },
          {
            Header: "View Status",
            accessor: "edit",
            width: 120,
            Cell: d => this.StatusView(d)
          },
          {
            Header: "Name",
            accessor: "name"
          },
          {
            Header: "Mobile number",
            accessor: "mobile",
            Cell: d => this.Call(d.original.mobile)
          },
          {
            Header: "Make",
            accessor: "make",
            Cell: (d) => this.getValueFromArray(d.original.Make, this.state.MakeOptions)
  
          },
          {
            Header: "Model",
            accessor: "model",
            Cell: (d) => this.getValueFromArray(d.original.Model, this.state.modelopt)
          },
          {
            Header: "Variant",
            accessor: "variant",
            Cell: (d) => this.getValueFromArray(d.original.Variant, this.state.variantopt)
  
          },
          {
            Header: "Color",
            accessor: "color",
            Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)
  
          },
          {
            Header: "Type",
            accessor: "type"
          },
          {
            Header: "EnquriryDate",
            accessor: "createdAt"
          },
          // {
          //   Header: "Budget",
          //   accessor: "budget"
          // },
          {
            Header: "Sales Consultant",
            minWidth: 180,
            accessor: "Sales_employee_name"
          },
          {
            Header: "Status",
            minWidth: 180,
            accessor: "pendingStatus2"
  
          },
          {
            Header: "Print pdf",
            accessor: "print",
            Cell: d => this.viewpdf(d),
            width: 150
          },
  
  
          {
            Header: "View Details",
            accessor: "view",
            width: 150,
            Cell: d => this.fullview(d)
          },
  
        ],
  
       

      
      excelColumn1: [
        {
          label: "Name",
          key: "name"
        },
        {
          label: "Mobile number",
          key: "mobile",
        },
        {
          label: "Make",
          key: "makeName",

        },
        {
          label: "Model",
          key: "modelName",
        },
        {
          label: "Variant",
          key: "variantName",

        },
        {
          label: "Color",
          key: "colourName",

        },
        {
          label: "Type",
          key: "type"
        },
        {
          label: "EnquriryDate",
          key: "createdAt"
        },

        {
          label: "Sales Consultant",
          key: "Sales_employee_name"
        }

      ],
      column2: [
        {
          Header: "Name",
          accessor: "name"
        },
        {
          Header: "Reg No",
          accessor: "Reg_no"
        },
        {
          Header: "Mail",
          accessor: "email"
        },
        {
          Header: "Mobile",
          accessor: "mobile",
          Cell: d => this.Call(d.original.mobile)
        },
        // {
        //   Header: "Contact",
        //   accessor: "mobile"
        // },
        {
          Header: "Type",
          accessor: "type"
        },
        {
          Header: "EnquriryDate",
          accessor: "createdAt"
        },
        {
          Header: "Make",
          accessor: "make",
          Cell: (d) => this.getValueFromArray(d.original.Make, this.state.MakeOptions)

        },
        {
          Header: "Model",
          accessor: "model",
          Cell: (d) => this.getValueFromArray(d.original.Model, this.state.modelopt)
        },
        {
          Header: "Variant",
          accessor: "variant",
          Cell: (d) => this.getValueFromArray(d.original.Variant, this.state.variantopt)

        },
        {
          Header: "Color",
          accessor: "color",
          Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)

        },
        {
          Header: "Enquiry From",
          accessor: "source",
          width: 120,
          Cell: (d) => this.fromEnq(d.original)
        },
        {
          Header: "Allocate",
          accessor: "edit",
          Cell: (d) => this.DatatableCheckboxall(d, this.updateMyData, this.state.SelectedEnquiries)

        }
      ],
      excelColumn2: [
        {
          label: "Name",
          key: "name"
        },
        {
          label: "Reg No",
          key: "Reg_no"
        },
        {
          label: "Mail",
          key: "email"
        },
        {
          label: "Mobile",
          key: "mobile",
        },
        {
          label: "Type",
          key: "type"
        },
        {
          label: "EnquriryDate",
          key: "createdAt"
        },
        {
          label: "Make",
          key: "makeName",

        },
        {
          label: "Model",
          key: "modelName",
        },
        {
          label: "Variant",
          key: "variantName",

        },
        {
          label: "Color",
          key: "colourName",

        },
      ],
      CustomerList: [],
      filteredLoc: "",
      filteredMake: "",
      locationOpt: [],
      FilterData: [],
    };
  }
  fromEnq = (d) => {
    if (d.enquiry_Source == "web") {
      return (
        <button className="btn btn-sm btn-success" style={{ width: "100px" }}>Web</button>
      )
    }
    else if (d.enquiry_Source == "csv") {
      return (
        <button className="btn btn-sm btn-danger" style={{ width: "100px" }}>CSV</button>
      )
    }
    else {
      return (
        <button className="btn btn-sm btn-primary" style={{ width: "100px" }}>Site</button>
      )
    }
  }
  fullview = (d) => {
    return (<button type="button" className="btn btn-sm btn-success"
      data-toggle="modal" data-target={"#fullview"}
      onClick={() => this.ViewHistory1(d)}
    >Details</button>)
  }
  viewpdf = d => {
    // console.log(d.original, 'view pdf');
    if (d.original.pendingStatus1 && d.original.pendingStatus2) {
      return (<button type="button" className="btn btn-sm btn-warning"
        onClick={() => this.generatepdf(d)}>Print PDF</button>)
    }
    else {
      return (
        <span className="label" style={{ backgroundColor: "white" }}></span>)
    }
  }
  generatepdf = async (d) => {
    const fproofid = await CmsContent.getFreedom("*", "tbl_dfcarz_enquiryform", `id=${d.original.id}`, 1, 1);

    if (fproofid) {
      console.log("Enter PDF");
      let fproofpdf = await CmsContent.autoterrace("tbl_dfcarz_enquiryform", fproofid.data[0].id);
      console.log(fproofpdf, "fproofpdf")
      if (fproofpdf.data == "success") {
        window.open(`${ACCESS_POINT}/cmsContent/downloadauto/${fproofid.data[0].name}`, '_self')

      }
    }
  }
  DatatableCheckboxall = (d, func, SelectedEnquiries) => {
    // console.log(d, "SelectedEnquiries[d.original.id]");
    return (
      <div style={{ 'text-align': 'center' }}>
        <input type="checkbox"
          checked={SelectedEnquiries && SelectedEnquiries[d.original.id] &&
            SelectedEnquiries[d.original.id] == true ? true : false}
          onChange={(event) => func(parseInt(d.original.id), d.column.id, event.target.checked ? "Yes" : "No")} />
      </div>)
  };
  followup = (d) => {
    let d1 = [];
    if (d.original.Next_Contact_date) {
      d1 = d.original.Next_Contact_date.split(" ")[0];
      ////console.log(d1)
      d1 = moment(d1).format("DD-MM-YYYY");
    }
    return (<span style={{ cursor: 'pointer', textDecoration: "underline" }} data-toggle="modal" data-target={"#followup"}
      onClick={() => this.Editdata(d)}
    >{d1}</span>)
  }

  Editdata = async (d) => {
    ////console.log(111)
    this.setState({ EditData: d.original, CustomerId: d.original.id, IsClose: false, isEdit: true, Index: d.index })
  }
  updateMyData = async (d, f, event) => {
    let { SelectedEnquiries } = this.state
    // console.log(d, "d", event, "event");
    const index = SelectedEnquiries.indexOf(d)
    if (index > -1 && event == "No") {
      SelectedEnquiries.splice(index, 1)
      SelectedEnquiries[d] = false
      if (index == 0) {
        SelectedEnquiries.length = 0;
      }

    }
    else {
      SelectedEnquiries.push(d)
      SelectedEnquiries[d] = true
    }
    this.setState({ SelectedEnquiries })

  }
  NAPstatus = (d) => {
    if (d.original.pendingStatus == 'Scheduled Follow up') {
      return (<button type="button" className="btn btn-sm btn-success"
        onClick={() => this.ChangeStatus1(d)}
      >Scheduled Follow up</button>)
    }
    else if (d.original.pendingStatus == "Evaluate") {
      return (<button type="button" className="btn btn-sm btn-warning"
        onClick={() => this.ChangeStatus1(d)}
      >Evaluated</button>)
    } else if (d.original.pendingStatus == "Closed") {
      return (<button type="button" className="btn btn-sm btn-danger"
        onClick={() => this.ChangeStatus1(d)}
      >Closed</button>)
    }

  }

  StatusView = (d) => {
    if (d.original.filterclosed == true) {
      return (
        <button
          type="button"
          style={{ width: "100px" }}
          className="btn btn-danger btn-sm"
        >
          Closed
        </button>
      );
    } else {
      return (
        <button
          type="button"
          style={{ width: "100px" }}
          className="btn btn-sm btn-primary"
          data-toggle="modal"
          data-target={"#matchcarmodal"}
          onClick={() => this.Status(d)}
        >
          View
        </button>
      );
    }
  }
  // Status = (d) => {
  //   this.setState({ StatusEditData: d.original })

  // }

  // status1 = () => {
  //   return (
  //     <div>
  //       <SingleSelect
  //         placeholder="Select..."
  //         options={this.state.status1options}
  //         handleChange={d => this.handlestatus1(d)}
  //         selectedService={this.state.status1}
  //       />

  //     </div>)
  // }
  // status2 = () => {
  //   return (
  //     <div>
  //       <SingleSelect
  //         placeholder="Select..."
  //         options={this.state.status2options}
  //         handleChange={d => this.handlestatus2(d)}
  //         selectedService={this.state.status2}
  //       />
  //     </div >
  //   )
  // }
  // handlestatus1 = async e => {
  //   this.setState({ status1: e, status2: {} });
  //   if (e.label == "Schedule follow up") {
  //     this.setState({
  //       status2visible: true, status2options: [
  //         { label: "Under Follow up", value: 1 },
  //         { label: " Ringing no response", value: 2 },
  //         { label: "Researching", value: 3 },
  //         { label: "  Not attempted", value: 4 }
  //       ], status3options: [{ label: "Negotitation", value: 0 }, { label: " consideration", value: 1 }], status3visible: true
  //     })
  //   } else if (e.label == "Scheduled Evaluation") {
  //     this.setState({
  //       status2visible: true, status2options: [{ label: "Appointment taken", value: 1 }],
  //       status3visible: false
  //     })
  //   } else if (e.label == "Mark Closed") {
  //     this.setState({
  //       status2visible: true, status2options: [{ label: " Plan dropped", value: 0 },
  //       { label: "Non contactable", value: 1 },
  //       { label: "Lost", value: 2 },
  //       { label: "Wrong capture", value: 3 },
  //       { label: "No response", value: 4 },
  //       { label: "Huge Price Gap", value: 5 },
  //       ], status3visible: false
  //     })
  //   } else if (e.label == "Evaluated") {
  //     this.setState({
  //       status2visible: true, status2options: [{ label: " Trade in", value: 0 },
  //       { label: "Follow-up", value: 1 },
  //       { label: "Researching", value: 2 },
  //       { label: "Postponned", value: 3 },
  //       { label: "Dropped", value: 4 },
  //       { label: "Not attempted", value: 5 },
  //       { label: "Non contactable", value: 6 },
  //       { label: "Price not offered", value: 7 },
  //       { label: "Huge Price gap", value: 8 },
  //       { label: "Lost", value: 9 },
  //       ], status3visible: false
  //     })
  //   }
  // };

  // handlestatus2 = async e => {
  //   this.setState({ status2: e });
  //   if (e.label == "Lost") {
  //     this.setState({
  //       status3visible: true, status3options: [{ label: " Co dealer", value: 0 },
  //       { label: "Competition", value: 1 },
  //       { label: "End user", value: 2 },
  //       { label: "Broker", value: 3 },
  //       { label: "Not known", value: 4 }
  //       ]
  //     })
  //   } else if (e.label == "Non contactable") {
  //     this.setState({
  //       status3visible: true, status3options: [{ label: "RNR-3", value: 0 },
  //       { label: "Wrong number", value: 1 },
  //       ]
  //     })
  //   } else {
  //   }
  // };
  ChangeStatus1 = async (d) => {
    try {
      let result = await CmsContent.getFreedom(
        `tbl_dfcars_comments.*,tbl_UserType.usertype,tbl_user_web.userName as Name,tbl_user_web.id as UserID`,
        `tbl_dfcars_comments,tbl_user_web,tbl_MaptoLocation,tbl_UserType`,
        `tbl_dfcars_comments.customerid = ${d.original.id} and tbl_dfcars_comments.stage='actionplan'and tbl_user_web.id = tbl_dfcars_comments.userid and tbl_user_web.userportid = tbl_MaptoLocation.id and tbl_UserType.id = tbl_MaptoLocation.userTypeId`,
        1,
        `tbl_dfcars_comments.id DESC`,
      );
      if (result.data.length) {
        let CommentData = result.data[0];
        let arr = {};
        if (d.original.pendingStatus1 == "Scheduled Follow up") {
          arr.status = "Evaluate"
        }
        else if (d.original.pendingStatus1 == "Evaluated") {
          arr.status = "Closed"
        }
        else if (d.original.pendingStatus1 == "Closed") {
          arr.status = "Closed"
        }
        const result1 = await CmsContent.updateMaster(
          "tbl_dfcars_comments",
          CommentData.id,
          arr
        );
        if (result1) {
          let newData = [...this.state.EnquiryData];
          newData[d.index].pendingStatus = arr.status;
          this.setState({
            Data: newData
          })
        }

      }

    } catch (error) {
      console.log(error);
    }
  }

  MainFunction = async () => {
    try {
    console.log("1");
      this.setState({ loading: true, EnquiryDatas: [] })
      console.log("2");
      let user = JSON.parse(localStorage.getItem(`userlog`));
      console.log(user,"user");
      let salesopt;
      if(user){
         salesopt = await CmsContent.getFreedom(
          `tbl_user_web.*,tbl_user_web.userName as label,tbl_user_web.id as value`,
          `tbl_user_web`,
          // `tbl_dfcars_comments.customerid = ${d.original.id} and tbl_dfcars_comments.stage='actionplan'and tbl_user_web.id = tbl_dfcars_comments.userid and tbl_user_web.userportid = tbl_MaptoLocation.id and tbl_UserType.id = tbl_MaptoLocation.userTypeId`,
          `tbl_user_web.downline=${user.downline} and tbl_user_web.userType=124`,
          1,
          `tbl_user_web.id DESC`,
        );
        console.log(salesopt,"salesopt form get freedom");
      }
      console.log(user,"user");
      let logos = await CmsContent.getDfCarsteleEnquiry(user.id, "buyer")
      console.log(logos,"logos");
      const { usertypelog } = this.state;
      let fulldata = []
      if (logos.statusdata[0].fulldata && logos.statusdata[0].fulldata.length > 0) {
        fulldata = logos.statusdata[0].fulldata
        console.log("enter4");
      }

      // let salesopt = await this.decryption("Downline_Details")
      // console.log(salesopt,"salesoptsalesopt");
      let dashboard = logos.status
      let unallocated = []
      if (usertypelog.orderBy == 1) {
        unallocated = fulldata.length ? fulldata.filter(e => e.filterunallocated == true) : []
        console.log("enter5");
      }
      else if (usertypelog.orderBy == 2 ) {
        if (fulldata.length > 0) {
            console.log("enter6");
          unallocated = await fulldata.filter(e => e.filterunallocated == true && (user.id == e.updatedby || user.id == e.downline))
          await fulldata.filter((e) => {
            if (e.filterunallocated == true && e.updatedby == null && user.locationid == e.location && user.sub_loc_id == e.branch) {
              unallocated.push(e)
            }
          })
        } else {
          unallocated = []

          console.log("enter7");
        }
        console.log("enter8");
      }
      else if (usertypelog.orderBy == 3 || usertypelog.orderBy == 14) {
        console.log("enter9");
        if (fulldata.length > 0) {
            console.log("enter11");
          unallocated = await fulldata.filter(e => e.filterunallocated == true && (user.id == e.updatedby || user.downline == e.updatedby))
          await fulldata.filter((e) => {
            if (e.filterunallocated == true && e.updatedby == null && user.locationid == e.location && user.sub_loc_id == e.branch) {
              unallocated.push(e)
            }
          })
        } else {
            console.log("enter12");
          unallocated = []
        }
      }
      else {
        console.log("enter10");
        unallocated = fulldata.length ? fulldata.filter(e => e.filterunallocated == true) : []
      }
      console.log(fulldata, "fulldata");
      let allEnquiry = fulldata.length ? fulldata.filter(e => e.salesid != null) : []
      await this.setState({
        ftddata: fulldata.length ? fulldata.filter(e => e.filterftd == true) : [],
        noncontactdata: fulldata.length ? fulldata.filter(e => e.filternoncontact == true) : [],
        allEnquiry,
        livedata: fulldata.length ? fulldata.filter(e => e.filterfollowup == true) : [],
        closeddata: fulldata.length ? fulldata.filter(e => e.filterclosed == true) : [],
        data1: fulldata.length ? fulldata.filter(e => e.filterToday == true) : [],
        dataof: fulldata.length ? fulldata.filter(e => e.filtermtd == true) : [],
        EnquiryData: fulldata,
        unallocated: unallocated,
        dashboard,
        UserId: logos.user[0].id,
        remainder: logos.statusdata[0].remainder,
        // unallocated: logos.statusdata[0].unallocated,
        orberby: logos.user[0].orderBy,
        saleopt: salesopt.data,
        loading: false
      });
    } catch (error) {
      console.log(error);
    }
  }
  async componentWillMount() {
    try {
      let decr = this.Decrypt(localStorage.getItem('getcar'))
      let allOpt = JSON.parse(decr)
      if (allOpt) {
        console.log(allOpt, "allOpt")
        this.setState({
          MakeOptions: allOpt.makeopt,
          coloropt: allOpt.coloropt,
          variantopt: allOpt.variantopt,
          modelopt: allOpt.modelopt,
          locationOpt: allOpt.locopt
        })
      }
      this.MainFunction()

      this.setState({ loading: false })
    } catch (error) {
      console.log(error);
    }

  }
  cng = async (e) => {
    this.setState({ ftdval: true })
    console.log(e.target.value);
    let { usertypelog } = this.state
    let d = e.target.value
    let d1 = {}
    d1.date = e.target.value
    d1.orderBy = usertypelog.orderBy
    d1.locationId = this.state.LocationID
    // console.log(d1, 'd1');
    let result = await CmsContent.GetFTD(d, d1)
    if (result.data.length) {
      // console.log(result);
      let v4 = 0;
      let data2 = []
      result.data.map((ival, i) => {
        if (ival.createdAt) {
          let date11 = new Date(ival.createdAt)
          let d1 = date11.getDate()
          let m1 = date11.getMonth() + 1
          let y1 = date11.getFullYear()
          if (m1 < 10) {
            m1 = "0" + m1
          }
          let date2 = y1 + "-" + m1 + "-" + d1
          if (d && date2) {
            // console.log("enterr");
            v4 = ++v4;
          }
        }
      })

      await this.setState({ ftd: v4, EnquiryDatas: result.data, ftddate: d, ftdDashDate: d })
    }
    else {
      let data = []
      await this.setState({ ftd: "0", EnquiryDatas: data })
      console.log(this.state.ftd, "ftddd");
    }
    window.scroll({
      top: 400,
      left: 0,
      behavior: "smooth",
    });
  }
  ViewLead = d => {
    return (
      <button
        type="button"
        className="btn btn-sm btn-primary"
        onClick={() => this.ViewHistory(d)}
      >
        View
      </button>
    );
  };

  CloseEnquiry = d => {
    return (
      <button
        type="button"
        className="btn btn-sm btn-danger"
        data-toggle="modal"
        data-target={"#status"}
        onClick={() => this.ClosethisEnquiry(d)}
      >
        Close
      </button>
    );
  };
  Viewbuylead = d => {
    return (
      <button
        type="button"
        className="btn btn-sm btn-success"
        onClick={() => this.ViewHistory2(d)}
      >
        Add
      </button>
    );
  };
  ViewLead1 = d => {
    //console.log(d);
    if (d.original.pendingStatus1 == "Scheduled Follow up") {
      return (
        <button
          type="button"
          className="btn btn-sm btn-success"
          onClick={() => this.ViewHistory2(d)}
        >
          Add
        </button>
      );
    }
    else
      if (d.original.pendingStatus1 == "Scheduled Follow up") {
        return (
          <span className="label" style={{ backgroundColor: "white" }}></span>)
      }
      else {
        return (
          <span className="label" style={{ backgroundColor: "white" }}></span>)
      }
  };

  ClosethisEnquiry = async d => {
    this.setState({ CloseEnquiry: true });
    this.setState({ Index: d.index, EditData: d.original });
  };

  UpdateStatus = d => {

    return (
      <div>
        <button
          type="button"
          className="btn btn-sm btn-warning"
          data-toggle="modal"
          data-target={"#status"}
          onClick={() => this.F_proofForm(d)}
        >
          Edit Form
        </button>
      </div >
    );
  }

  changeModelstate = async (s, v) => {
    this.setState({ [s]: v });
  };

  ChangeStatus = async (data, id) => {
    try {
      let subgroupArray = {};
      //subgroupArray.enquiry_status = "inactive";
      subgroupArray.prosid = id
      const result = await CmsContent.updateMaster(
        "tbl_dfcarz_enquiryform",
        data.id,
        subgroupArray
      );

      // //console.log(result);
    } catch (error) {
      console.log(error);
    }
  };

  ViewHistory2 = async d => {
    let data = d
    // console.log(d);
    let groupArray2 = {};
    groupArray2.name = data.name;
    groupArray2.mobile = data.mobile;
    groupArray2.email = data.email;
    groupArray2.status = "active";
    groupArray2.companyid = data.companyid;
    groupArray2.enquiry_id = data.id;
    groupArray2.userid = data.evaluatorid;
    groupArray2.type = data.type;
    groupArray2.model = data.Model;
    groupArray2.make = data.Make;
    groupArray2.variant = data.variant;
    //groupArray.budget = data.Budget;
    groupArray2.color = data.color;
    groupArray2.prospective_status = "active";
    groupArray2.location = data.location;
    groupArray2.salesid = data.salesid;
    groupArray2.variant = data.variant;
    groupArray2.evaluatorid = data.evaluatorid;
    groupArray2.reg_no = data.Reg_no;
    groupArray2.year = data.yom;
    // let DaTE = new Date();
    // groupArray2.followup = moment(DaTE).format("YYYY-MM-DD");
    console.log(groupArray2);
    try {
      const history = await CmsContent.addMaster(
        "tbl_dfcarz_prosperities",
        groupArray2
      );
      console.log(history);
      if (history) {
        console.log(history, 'history');
        const prosid = history.data.insertId;
        this.ChangeStatus(data, prosid);
      }
    } catch (error) {
      console.log(error);
    }
  };
  F_proofForm = async (d) => {
    window.open(`/DFCars/Autoterrace_enquiry_form/${d.original.id}`, '_self')
  }
  Autoterrace = () => {
    window.open(`/DFCars/Autoterrace_enquiry_form/`, '_self')

  }
  ViewHistory1 = async d => {
    this.setState({ Index: d.index });
    console.log(d.original, 'dd');
    this.setState({ isEdit: true, EditData: d.original });
  };

  ViewHistory = async d => {
    window.open(`/DFCars/DfCarsViewhistory/${d.original.id}`);
  };
  reset = async () => {
    //console.log("reset");
    this.setState({
      isEdit: false,
      Editdata: null,
      CustomerId: null,
      EditData: {},
      FullData: null,
      Index: null,
      IsClose: null
    });
  };

  edash = () => {
    return (

      <div className="card">
        {/* < div className="row" > */}
        <div className="card-header">
          <div className="col-sm-6" >
            <h2 className="mt-2 black">Dashboard</h2>
          </div>
        </div>
        <div className="card-body">
          <div className=''>
            <div className="row col-12">
              <div className="col-sm" ><center>
                <div className="col-sm-3"> <button className="btn btn-sm btn-primary" onClick={this.Autoterrace}  > Add Enquiry</button>
                </div>
                {/* <Link to={'/DFCars/Autoterrace_enquiry_form/', '_self'} className="btn btn-primary">Add Enquiry</Link> */}
              </center>
              </div>
            </div><br />
            <div className="row col-12 mt-2 text-center">

              <div className="col" >
                <div className="dashboardCard1" onClick={() => this.onClk(2)}>
                  <Icon icon={time} style={{ color: '#2F353A' }} size={35} /><br />
                  <h4 className="mt-2 black" >FTD Enquiry</h4>
                  <div >
                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.dashboard[0].FTD ? this.state.dashboard[0].FTD : 0}</b></h1>
                  </div>
                </div>
              </div>
              <div className="col" >
                <div className="dashboardCard1" onClick={() => this.onClk(3)}>
                  <Icon icon={calendar} style={{ color: 'blue' }} size={35} /><br />
                  <h4 className="mt-2 black" >MTD Enquiry</h4>
                  <div >
                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.dashboard[0].MTD ? this.state.dashboard[0].MTD : 0}</b></h1>
                  </div>
                </div>
              </div>




              <div className="col " >
                <div className="dashboardCard1" onClick={() => this.onClk(1)}>
                  <Icon icon={calendar_ok} style={{ color: 'green' }} size={35} /><br />
                  <h4 className="mt-2 black" > Follow up</h4>
                  <div >
                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.dashboard[0].live ? this.state.dashboard[0].live : 0}</b></h1>
                  </div>
                </div>
              </div>
              <div className="col" >
                <div className="dashboardCard1" onClick={() => this.onClk(11)}>
                  <Icon icon={user_delete} style={{ color: 'purple' }} size={35} /><br />
                  <h4 className="mt-2 black" >Non-Contactable</h4>
                  <div >
                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.dashboard[0].noncontact ? this.state.dashboard[0].noncontact : 0}</b></h1>
                  </div>
                </div>
              </div>
              {/* <div className="col " >
                <div className="dashboardCard1" >
                  <Icon icon={calendar} style={{ color: 'red' }} size={35} /><br />
                  <h4 className="mt-2 black" >FTD Enquiry</h4>
                  <div >
                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.ftd && this.state.ftd != null && this.state.ftdval == true ? this.state.ftd : this.state.ftdval == false && this.state.dashboard[0].ftd}</b></h1>

                  </div>
                  <div>
                    <h1 className="bold mt-4 mb-3"><b><input type="date" className="form-control" defaultValue={this.state.ftdDashDate} onChange={this.cng} name="dateval"></input></b></h1>
                  </div>
                </div>
              </div> */}
              {/* <div className="col " >
                <div className="dashboardCard1" onClick={() => this.onClk(3)}>
                  <Icon icon={calendar} style={{ color: 'red' }} size={35} /><br />
                  <h4 className="mt-2 black" >MTD Enquiry</h4>
                  <div >
                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.dashboard[0].MTD ? this.state.dashboard[0].MTD : 0}</b></h1>
                  </div>
                </div>
              </div> */}
              {/* <div className="col " >
                <div className="dashboardCard1" onClick={() => this.onClk(4)}>
                  <Icon icon={calendar_ok} style={{ color: 'red' }} size={35} /><br />
                  <h4 className="mt-2 black" >Today's Followup</h4>
                  <div >
                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.dashboard[0].today ? this.state.dashboard[0].today : 0}</b></h1>
                  </div>
                </div>
              </div> */}
              {/* <div className="col " >
                <div className="dashboardCard1" onClick={() => this.onClk(5)}>
                  <Icon icon={alarmClock} style={{ color: 'red' }} size={35} /><br />
                  <h4 className="mt-2 black">Reminders</h4>
                  <div >
                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.dashboard[0].remainder ? this.state.dashboard[0].remainder : 0}</b></h1>
                  </div>
                </div>
              </div> */}
              <div className="col " >
                <div className="dashboardCard1" onClick={() => this.onClk(6)}>
                  <Icon icon={close} style={{ color: 'red' }} size={35} /><br />
                  <h4 className="mt-2 black" >MTD Closed</h4>
                  <div >
                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.dashboard[0].Closed ? this.state.dashboard[0].Closed : 0}</b></h1>
                  </div>
                </div>
              </div>
              <div className="col " onClick={() => this.onClk(10)} >
                <div className="dashboardCard1">
                  <Icon icon={alert} size={35} style={{ color: 'red' }} />
                  <h4 className="mt-2 black" > Un-Allocated Enquiries</h4>
                  <div>
                    <h1 className="bold mt-4 mb-2 black"><b> {
                      this.state.unallocated && this.state.unallocated.length > 0 ? this.state.unallocated.length : 0

                      // this.state.dashboard[0].unallocated ? this.state.dashboard[0].unallocated : 0
                    }</b></h1>
                  </div>
                </div>
              </div>
              <div className="col" >
                <div className="dashboardCard1" onClick={() => this.onClk(12)}>
                  <Icon icon={shuffle} style={{ color: 'rgb(203 44 146)' }} size={35} /><br />
                  <h4 className="mt-2 black" >All Enquiry</h4>
                  <div >
                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.allEnquiry ? this.state.allEnquiry.length : 0}</b></h1>
                  </div>
                </div>
              </div>
            </div>
          </div >
        </div >
      </div >)
  }
  onClk = (val) => {
    if (val === 1) { this.setState({ EnquiryDatas: this.state.livedata, dateVisible: false, workproc: false, FilterData: this.state.livedata, filteredLoc: "", filteredMake: "", bulkAllocate: false, statusbtn:false }) }
    // if (val === 2) {this.setState({ dateVisible: true, EnquiryData: [] })}
    if (val === 2) { this.setState({ EnquiryDatas: this.state.ftddata, dateVisible: false, workproc: false, FilterData: this.state.ftddata, filteredLoc: "", filteredMake: "", bulkAllocate: false, statusbtn:true  }) }

    if (val === 3) { this.setState({ EnquiryDatas: this.state.dataof, dateVisible: false, workproc: false, FilterData: this.state.dataof, filteredLoc: "", filteredMake: "", bulkAllocate: false , statusbtn:true }) }
    if (val === 4) { this.setState({ EnquiryDatas: this.state.data1, dateVisible: false, workproc: false, FilterData: this.state.data1, filteredLoc: "", filteredMake: "", bulkAllocate: false , statusbtn:false }) }
    if (val === 5) { this.setState({ EnquiryDatas: this.state.remainder, dateVisible: false, workproc: false, FilterData: this.state.remainder, filteredLoc: "", filteredMake: "", bulkAllocate: false, statusbtn:false  }) }
    if (val === 6) { this.setState({ EnquiryDatas: this.state.closeddata, dateVisible: false, workproc: false, FilterData: this.state.closeddata, filteredLoc: "", filteredMake: "", bulkAllocate: false, statusbtn:false  }) }
    if (val === 10) { this.setState({ EnquiryDatas: this.state.unallocated, dateVisible: false, workproc: true, bulkAllocate: true }) }
    if (val === 11) { this.setState({ EnquiryDatas: this.state.noncontactdata, dateVisible: false, workproc: false, FilterData: this.state.noncontactdata, filteredLoc: "", filteredMake: "", bulkAllocate: false, statusbtn:false  }) }
    if (val === 12) { this.setState({ EnquiryDatas: this.state.allEnquiry, dateVisible: false, workproc: false, FilterData: this.state.allEnquiry, filteredLoc: "", filteredMake: "", bulkAllocate: false , statusbtn:false }) }





    window.scroll({
      top: 400,
      left: 0,
      behavior: "smooth",
    });
  }
  // StatusReset = () => {
  //   this.setState({ statuschg1: false, status2visible: false, status2: "", status1: null, StatusEditData: {} })
  // }
  StatusReset = () => {
    this.setState({
      statuschg1: false, status2visible: false, status2: "",
      status1: null, StatusEditData: {}, status3visible: false,
      sellerstatus1: {}, sellerstatus2: {}, sellerstatus3: {}
    })
  }
  // StatusView = (d) => {
  //   return (
  //     <button
  //       type="button"
  //       className="btn btn-sm btn-primary"
  //       data-toggle="modal"
  //       data-target={"#matchcarmodal"}
  //       onClick={() => this.Status(d)}
  //     >
  //       View
  //     </button>
  //   );
  // }
  AllocateModel = (d) => {
    return (
      <center>
        <button
          type="button"
          className="btn btn-sm btn-primary"
          data-toggle="modal"
          data-target={"#AllocateModel"}
        >
          Allocate
        </button>
      </center>
    );
  }
  selectalldata = async (event) => {
    let { unallocated } = this.state
    let SelectedEnquiries = []

    if (event == "Yes") {
      for (let index = 0; index < 10; index++) {
        const element = unallocated[index];
        SelectedEnquiries[element.id] = true
        SelectedEnquiries.push(element.id)
      }

      await this.setState({ isallselect: true })
      // console.log(SelectedEnquiries.length, "SelectedEnquiries length");
    }

    else {
      await this.setState({ isallselect: false })
    }
    await this.setState({ SelectedEnquiries })
  };
  Status = (d) => {
    console.log(d.original, 'd');
    let statusArray = []
    if (d.original.followup) {
      statusArray = JSON.parse(d.original.followup)
    }

    this.setState({ StatusEditData: d.original, SalesSelect: [], SalesSelectError: "", remarks: "", followup: "", statusArray })
    this.form_filled(d)
  }
  form_filled = async (d) => {
    console.log(d.original, "ddd")

    let ans = d.original;

    if (ans.pendingStatus1 == "Yes, Interested in Buying") {
      this.setState({ remarkFollowupVisible: true, remarkVisible: false })
      if (ans.followup) {
        let followup = JSON.parse(ans.followup)
        console.log(followup[0].remarks, "remarks")
        followup[0].remarks && this.setState({ remarks: followup[0].remarks })
        followup[0].follow && this.setState({ followup: followup[0].follow })
      }
    }
    else if (ans.pendingStatus1 == "Yes, but not interested, Mark closed") {
      if (ans.remarks) {
        this.setState({ remarkFollowupVisible: false, remarkVisible: true, remarks: ans.remarks })
      }
    } else if (ans.pendingStatus1 == "Couldn’t speak to customer") {
      this.setState({ remarkFollowupVisible: false, remarkVisible: false, remarks: "", followup: "" })
    }

  }
  AllocateSelect = () => {
    return (
      <div>
        <SingleSelect
          placeholder="Select..."
          // options={this.state.saleopt}
          options={this.state.saleopt}
          handleChange={d => this.handleSalesOPT(d)}
          selectedService={this.state.SalesSelect}
        />
      </div>
    )
  }
  handleSalesOPT = async (e) => {
    console.log('entered', this.state.saleopt);
    this.setState({
      SalesSelect: e, SalesSelectError: ""
    })
  }
  AllocateUpdate = async () => {

    const { SalesSelect, SelectedEnquiries } = this.state
    console.log(SelectedEnquiries, SalesSelect);
    const formData = new FormData();
    if (!SalesSelect.value) {
      await this.setState({ SalesSelectError: "Please Select Sales Consultant" })
      return false
    }
    formData.append("SelectedEnquiries", SelectedEnquiries);
    formData.append("SelectedID", SalesSelect.value);
    this.setState({ statusbtnDisable: true })
    try {
      let result = await CmsContent.DFcarsManagerAllocate("tbl_dfcarz_enquiryform", "buyer", formData)
      if (result) {
        await this.setState({
          statusbtnDisable: false,
          StatusupdatealertVisible: true,
          Statusupdataetextalert: "Enquiry have been Allocated successfully",
          Statusupdatecolor: "success",
          SalesSelectError: "", EnquiryDatas: [], SelectedEnquiries: [], isallselect: false

        });
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
        this.MainFunction()
        window.$('#AllocateModel').modal('hide')
        setTimeout(() => { this.setState({ StatusupdatealertVisible: false }); }, 3000);
      }
    }
    catch (err) {
      console.log(err);
      this.setState({ statusbtnDisable: false })
    }
  }
  status1 = () => {
    return (
      <div>
        <SingleSelect
          placeholder="Select..."
          options={this.state.sellerstatus1options}
          handleChange={d => this.handlestatusseller1(d)}
          selectedService={this.state.sellerstatus1}
        />
      </div>)

  }
  status2 = () => {
    return (
      <div>
        <SingleSelect
          placeholder="Select..."
          options={this.state.sellerstatus2options}
          handleChange={d => this.handlestatusseller2(d)}
          selectedService={this.state.sellerstatus2}
        />
      </div>)

  }
  status3 = () => {
    return (
      <div>
        <SingleSelect
          placeholder="Select..."
          options={this.state.sellerstatus3options}
          handleChange={d => this.handlestatusseller3(d)}
          selectedService={this.state.sellerstatus3}
        />
      </div>)

  }
  handlestatusseller1 = async e => {
    this.setState({ sellerstatus1: e, sellerstatus2: {} });
    if (e.label == "Yes, Interested in Buying") {
      this.setState({
        remarkFollowupVisible: true,
        remarkVisible: false,
        remarks: "",
        followup: "",
        status2visible: true,
        sellerstatus2options: [
          { label: "Schedule walkin", value: 0 },
          { label: "Schedule follow up", value: 0 }
        ]
      })
    } else if (e.label == "Yes, but not interested, Mark closed") {
      this.setState({
        remarkVisible: true,
        remarkFollowupVisible: false,
        remarks: "",
        followup: "",
        status2visible: true,
        sellerstatus2options: [{ label: "Fake Lead", value: 0 },
        { label: "Customer Bought another car", value: 1 },
        { label: "Customer not picking the call", value: 2 },
        { label: "Desired car not available", value: 3 },
        { label: "price issue", value: 4 },
        { label: "Other", value: 5 }],
        status3visible: false, sellerstatus3: {}
      })
    } else if (e.label == "Couldn’t speak to customer") {
      this.setState({
        remarkVisible: false,
        remarkFollowupVisible: false,
        status2visible: true,
        sellerstatus2options: [{ label: "Schedule follow up", value: 0 },
        { label: "Mark closed", value: 1 }
        ], status3visible: false, sellerstatus3: {}, sellerstatus3options: {}
      })
    }
  };
  handlestatusseller2 = async e => {
    this.setState({ sellerstatus2: e });
    if (e.label == "Mark closed") {
      this.setState({
        status3visible: true, sellerstatus3options: [{ label: "Fake Lead", value: 0 },
        { label: "Customer Bought another car", value: 1 },
        { label: "Customer not picking the call", value: 2 },
        { label: "Desired car not available", value: 3 },
        { label: "price issue", value: 4 },
        { label: "Other", value: 5 }
        ], sellerstatus3: {}
      })
    } else {
      this.setState({ status3visible: false })
    }
  };
  handlestatusseller3 = async e => {
    this.setState({ sellerstatus3: e });
  };
  StatusUpdate = async () => {
    console.log("StatusUpdateUser", this.state.StatusEditData);
    const { sellerstatus1, sellerstatus2, sellerstatus3, remarks, followup, statusArray } = this.state
    let groupArray = {}
    console.log(sellerstatus1, "1", sellerstatus2, "2", sellerstatus3, "3", remarks, "remarks", followup, "followup");
    let Userlog = JSON.parse(localStorage.getItem("userlog"))
    if (statusArray && statusArray.length) {
      let obj = {}
      if (sellerstatus1) {
        obj.pendingStatus1 = sellerstatus1 && sellerstatus1.label ? sellerstatus1.label : null
      }
      if (sellerstatus2) {
        obj.pendingStatus2 = sellerstatus2 && sellerstatus2.label ? sellerstatus2.label : null
      }
      if (sellerstatus3) {
        obj.pendingStatus3 = sellerstatus3 && sellerstatus2.label ? sellerstatus3.label : null
      }
      else {
        obj.pendingStatus3 = "-"
      }
      if (remarks && remarks.length && remarks.length > 0) {
        obj.remarks = remarks && remarks.length > 0 && remarks
      }
      else if (remarks == "") {
        obj.remarks = "-"
      }
      if (followup && followup.length && followup.length > 0) {
        obj.Next_Contact_date = followup && followup.length > 0 && moment(followup).format('DD-MM-YYYY')
      }
      else if (followup == "") {
        obj.Next_Contact_date = "-"
      }
      obj.sino = statusArray.length + 1
      obj.userName = Userlog.userName
      if (obj) {
        statusArray.unshift(obj)
      }
    }
    else if (statusArray && statusArray.length == 0) {
      let obj = {}
      if (sellerstatus1) {
        obj.pendingStatus1 = sellerstatus1 && sellerstatus1.label ? sellerstatus1.label : null
      }
      if (sellerstatus2) {
        obj.pendingStatus2 = sellerstatus2 && sellerstatus2.label ? sellerstatus2.label : null
      }
      if (sellerstatus3) {
        obj.pendingStatus3 = sellerstatus3 && sellerstatus2.label ? sellerstatus3.label : null
      }
      else {
        obj.pendingStatus3 = "-"
      }
      if (remarks && remarks.length && remarks.length > 0) {
        obj.remarks = remarks && remarks.length > 0 && remarks
      }
      else if (remarks == "") {
        obj.remarks = "-"
      }
      if (followup && followup.length && followup.length > 0) {
        obj.Next_Contact_date = followup && followup.length > 0 && moment(followup).format('DD-MM-YYYY')
      }
      else if (followup == "") {
        obj.Next_Contact_date = "-"
      }
      obj.sino = statusArray.length + 1
      obj.userName = Userlog.userName
      if (obj) {
        statusArray.unshift(obj)
      }
    }
    else {
      statusArray = []
      let obj = {}
      if (sellerstatus1) {
        obj.pendingStatus1 = sellerstatus1 && sellerstatus1.label ? sellerstatus1.label : null
      }
      if (sellerstatus2) {
        obj.pendingStatus2 = sellerstatus2 && sellerstatus2.label ? sellerstatus2.label : null
      }
      if (sellerstatus3) {
        obj.pendingStatus3 = sellerstatus3 && sellerstatus2.label ? sellerstatus3.label : null
      }
      else {
        obj.pendingStatus3 = "-"
      }
      if (remarks && remarks.length && remarks.length > 0) {
        obj.remarks = remarks && remarks.length > 0 && remarks
      }
      else if (remarks == "") {
        obj.remarks = "-"
      }
      if (followup && followup.length && followup.length > 0) {
        obj.Next_Contact_date = followup && followup.length > 0 && moment(followup).format('DD-MM-YYYY')
      }
      else if (followup == "") {
        obj.Next_Contact_date = "-"
      }
      obj.sino = 1
      obj.userName = Userlog.userName
      if (obj) {
        statusArray.unshift(obj)
      }
    }
    console.log(statusArray, "statusArray ");
    let id = this.state.StatusEditData.id
    if (sellerstatus1) {
      groupArray.pendingStatus1 = sellerstatus1 && sellerstatus1.label ? sellerstatus1.label : null
    }
    if (sellerstatus2) {
      groupArray.pendingStatus2 = sellerstatus2 && sellerstatus2.label ? sellerstatus2.label : null
    }
    if (sellerstatus3) {
      groupArray.pendingStatus3 = sellerstatus3 && sellerstatus2.label ? sellerstatus3.label : null
    }
    // if (remarks && remarks.length && remarks.length > 0 && followup && followup.length && followup.length > 0) {
    //   let followJson = [];
    //   followJson.push({
    //     date: new Date(),
    //     remarks: remarks && remarks.length > 0 && remarks,
    //     follow: followup && followup.length > 0 && followup
    //   })
    //   groupArray.followup = JSON.stringify(followJson)
    // console.log(followJson, "followJson")
    //    groupArray.Next_Contact_date = followJson[0].follow
    // } else if (remarks && remarks.length && remarks.length > 0 && !followup.length) {
    //   groupArray.remarks = remarks
    // }
    groupArray.followup = JSON.stringify(statusArray)
    console.log(groupArray, id)
    this.setState({ statusbtnDisable: true })
    try {
      let result = await CmsContent.UpdateSaleEnquiryadd(
        "tbl_dfcarz_enquiryform",
        id,
        groupArray
      );
      if (result) {
        console.log(result)
      }
      await this.setState({
        statusbtnDisable: false,
        answerJson: "",
        json: "",
        StatusupdatealertVisible: true,
        Statusupdataetextalert: "Your Status have been Updated successfully",
        Statusupdatecolor: "success",
        remarks: "",
        followup: "",
      });
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
      this.MainFunction()
      setTimeout(() => { this.setState({ StatusupdatealertVisible: false }); }, 3000);

    } catch (error) {
      console.log(error);
      this.setState({ statusbtnDisable: false })
    }

  }
  makeFilter = async (d) => {
    let { filteredMake, EnquiryDatas, MakeOptions, filteredLoc, FilterData } = this.state;

    //   console.log(d, "d")

    await this.setState({ filteredMake: d })

    if (d && d.length == 0 && filteredLoc.length == 0) {
      await this.setState({ EnquiryDatas: this.state.FilterData })
    }
    else if (d && d.length == 0 && filteredLoc.length != 0) {
      var filtered_data = await FilterData.filter(x => filteredLoc.some(y => x.location == y.value))
      await this.setState({ EnquiryDatas: filtered_data })
    }
    else if (d && d.length != 0 && filteredLoc && filteredLoc.length != 0) {
      var filtered_make = await FilterData.filter(fd => d.some(d => fd.Make == d.value))
      var filtered_loc = await FilterData.filter(fd => filteredLoc.some(fl => fd.location == fl.value))
      // console.log(filtered_make, "filtered_make", filtered_loc, "filtered_loc'")
      var filtered_data = await filtered_make.filter(fm => filtered_loc.some(fl => fm.Make == fl.Make && fm.location == fl.location))
      // console.log(filtered_data, "filtered_data")
      await this.setState({ EnquiryDatas: filtered_data })
    }

    else if (d && d.length != 0 && filteredLoc.length == 0) {
      var filtered_data = await FilterData.filter(x => d.some(y => x.Make == y.value))
      await this.setState({ EnquiryDatas: filtered_data })
    }
    else {
      //nothing
    }
  }
  locationFilter = async (d) => {
    let { locationOpt, filteredLoc, FilterData, filteredMake, EnquiryDatas } = this.state
    // console.log(d, "d")

    await this.setState({ filteredLoc: d })

    if (d && d.length == 0 && filteredMake.length == 0) {
      await this.setState({ EnquiryDatas: this.state.FilterData })
    }

    else if (d && d.length == 0 && filteredMake.length != 0) {
      var filtered_data = await FilterData.filter(x => filteredMake.some(y => x.Make == y.value))
      await this.setState({ EnquiryDatas: filtered_data })
    }
    else if (d && d.length != 0 && filteredMake.length != 0) {
      var filtered_loc = await FilterData.filter(fd => d.some(d => fd.location == d.value))
      var filtered_make = await FilterData.filter(fd => filteredMake.some(d => fd.Make == d.value))
      // console.log(filtered_make, "filtered_make", filtered_loc, "filtered_loc'")
      var filtered_data = await filtered_loc.filter(fl => filtered_make.some(fm => fl.location == fm.location && fl.Make == fm.Make))
      // console.log(filtered_data, "filtered_dataaa")
      await this.setState({ EnquiryDatas: filtered_data })
    }

    else if (d && d.length != 0 && filteredMake.length == 0) {
      var filtered_data = await FilterData.filter(x => d.some(y => x.location == y.value))
      await this.setState({ EnquiryDatas: filtered_data })
    }
    else {
      //nothing
    }

  }
  clearFilter = async () => {
    await this.setState({ filteredMake: "", filteredLoc: "", EnquiryDatas: this.state.FilterData })
  }
  handleChangeRemark = async (e, name) => {
    console.log(e.target.value, "e")
    await this.setState({ [name]: e.target.value })
  }
  render() {
    let { loading, StatusEditData,saleopt, SelectedEnquiries,statusbtn, EditData, EnquiryDatas, MakeOptions, locationOpt, statusArray } = this.state;
    console.log(saleopt, "saleopt render  ")
    // console.log(SelectedEnquiries,"selectedenquirys");
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            {this.edash()}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Enquiry Report</h1>
                  </div>
                  <div className="card-body">
                    {SelectedEnquiries && SelectedEnquiries.length ? this.AllocateModel() : ""}
                    {this.state.StatusEditData != null && <div>
                      <LoginModal
                        title={StatusEditData.name}
                        id="matchcarmodal"
                        extraStyle={{ width: 'auto' }}
                        reset={this.StatusReset}
                        buttonStyle={{ backgroundColor: "white", border: "white" }}
                        bodyText={
                          <React.Fragment>
                            <main>
                              <div className="container-fluid">
                                <div className="row">
                                  <div className="col-sm-12">
                                    <div className="card">
                                      <div className="card-header">
                                        <h1> Edit Status </h1>
                                      </div>
                                      <div className="card-body">
                                        <div className="row form-group">
                                          <div className="col-sm-2" />
                                          <div className="col-sm-7">
                                            <Alert
                                              className="badge-content"
                                              color={this.state.Statusupdatecolor}
                                              isOpen={this.state.StatusupdatealertVisible}
                                              toggle={this.onDismiss}
                                            >
                                              {this.state.Statusupdataetextalert}
                                            </Alert>
                                          </div>
                                          <div className="col-sm-3" />
                                        </div>
                                        <div>
                                          {statusArray && statusArray.length ?
                                            <div className="row " style={{ overflow: "auto" }}>
                                              <table class="table table-bordered">
                                                <thead style={{ background: '#151E80', color: 'white' }}>
                                                  <tr>
                                                    <th scope="col">SiNo</th>
                                                    <th scope="col">Status1</th>
                                                    <th scope="col">Status2</th>
                                                    <th scope="col">Followup Date</th>
                                                    <th scope="col">Remarks</th>
                                                    <th scope="col">Update By</th>

                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {statusArray && statusArray.map((jval, i) => {
                                                    return (
                                                      < tr >
                                                        <th scope="row">{jval.sino}</th>
                                                        <td>{jval.pendingStatus1}</td>
                                                        <td>{jval.pendingStatus2}</td>
                                                        <td>{jval.Next_Contact_date}</td>
                                                        <td>{jval.remarks}</td>
                                                        <td>{jval.userName}</td>
                                                      </tr>
                                                    )
                                                  })
                                                  }
                                                </tbody>
                                              </table>
                                            </div> : null}
                                        </div>
                                        <div className="row" style={{ justifyContent: 'center' }}>
                                          <div className="col-sm-7"
                                          //style={{ left: "25%" }}
                                          >
                                            <div  >
                                              &nbsp;
                                              {StatusEditData.pendingStatus1 != null && <h4>Status 1 : <span className="btn btn-sm btn-sm btn-primary" >{StatusEditData.pendingStatus1}</span></h4>
                                              }&nbsp;
                                              {this.status1()}
                                              &nbsp;
                                              {this.state.status2visible == true &&
                                                <>
                                                  <h4>Status 2 :
                                                    {
                                                      StatusEditData.pendingStatus2 != null &&
                                                      <span className="btn btn-sm btn-primary" >{StatusEditData.pendingStatus2}</span>
                                                    }
                                                  </h4>
                                                </>

                                              }
                                              &nbsp;
                                              {this.state.status2visible == true && this.status2()}
                                              <br />
                                              {this.state.status3visible == true && <h4>Status 3 :  </h4>
                                              }
                                              &nbsp;
                                              {this.state.status3visible == true && this.status3()}
                                              <br />
                                              {
                                                this.state.remarkVisible &&
                                                <>
                                                  <label style={{ fontWeight: 500 }}> Remarks : </label>
                                                  <br></br>
                                                  <input
                                                    type="textarea"
                                                    placeholder="remarks"
                                                    value={this.state.remarks}
                                                    className="form-control"
                                                    onChange={(e) => { this.handleChangeRemark(e, "remarks") }}
                                                  />
                                                  <br></br>
                                                  <br></br>
                                                </>
                                              }
                                              {
                                                this.state.remarkFollowupVisible == true &&
                                                <>
                                                  <label style={{ fontWeight: 500 }}> Remarks : </label>
                                                  <br></br>
                                                  <input
                                                    type="textarea"
                                                    placeholder="remarks"
                                                    value={this.state.remarks}
                                                    className="form-control"
                                                    onChange={(e) => { this.handleChangeRemark(e, "remarks") }}
                                                  />
                                                  <br></br>
                                                  <label style={{ fontWeight: 500 }}> Next-follow-up : </label>
                                                  <br></br>
                                                  <input
                                                    type="date"
                                                    placeholder="Follow-up date"
                                                    min={moment().format('YYYY-MM-DD')}
                                                    value={this.state.followup}
                                                    className="form-control"
                                                    onChange={(e) => { this.handleChangeRemark(e, "followup") }}
                                                  />
                                                  <br></br>
                                                  <br></br>
                                                </>
                                              }
                                              {this.state.status2visible == true && <button className="btn btn-sm btn-block btn-warning" disabled={this.state.statusbtnDisable} onClick={() => { this.StatusUpdate() }}>{this.state.statusbtnDisable ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;Update</button>
                                              }
                                              &nbsp;
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </main>
                          </React.Fragment>
                        }
                      /></div>
                    }
                    {this.state.StatusEditData != null && <div>
                      <LoginModal
                        title={StatusEditData.name}
                        id="AllocateModel"
                        reset={this.StatusReset}
                        extraStyle={{ width: 'auto' }}
                        buttonStyle={{ backgroundColor: "white", border: "white" }}
                        bodyText={
                          <React.Fragment>
                            <main>
                              <div className="container-fluid">
                                <div className="row">
                                  <div className="col-sm-12">
                                    <div className="card">
                                      <div className="card-header">
                                        <h1> Edit Status </h1>
                                      </div>
                                      <div className="card-body">
                                        <div className="row form-group">
                                          <div className="col-sm-2" />
                                          <div className="col-sm-7">
                                            <Alert
                                              className="badge-content"
                                              color={this.state.Statusupdatecolor}
                                              isOpen={this.state.StatusupdatealertVisible}
                                              toggle={this.onDismiss}
                                            >
                                              {this.state.Statusupdataetextalert}
                                            </Alert>
                                          </div>
                                          <div className="col-sm-3" />
                                        </div>

                                        <div className="row form-group">
                                          <div className="col-sm-2" />
                                          <div className="col-sm-7"
                                          // style={{ left: "25%" }}
                                          >
                                            <h4>Allocate Sales Consultant:</h4>
                                            {this.AllocateSelect()}
                                            <span className="error-shows" style={{ color: "red" }}>
                                              {this.state.SalesSelectError}
                                            </span>
                                            <br />
                                            <br />
                                            <button className="btn btn-sm btn-block btn-warning" disabled={this.state.statusbtnDisable} onClick={() => { this.AllocateUpdate() }}>{this.state.statusbtnDisable ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;Update</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </main>
                          </React.Fragment>
                        }
                      /></div>
                    }
                    {this.state.EditData != null && <div>
                      <LoginModal
                        title={EditData.name}
                        id="fullview"

                        extraStyle={{ width: '100%' }}
                        buttonStyle={{ backgroundColor: "white", border: "white" }}
                        bodyText={
                          <div className="table-responsive">
                            <table class="table  table-striped">
                              <tbody>
                                <tr>
                                  <th scope="row">NAME</th>
                                  <td>{EditData && EditData.name}</td>
                                </tr>
                                <tr>
                                  <th scope="row">MOBILE</th>
                                  <td>{EditData && EditData.mobile}</td>
                                </tr>

                                <tr>
                                  <th scope="row">MAKE</th>
                                  <td>{this.getValueFromArray(EditData && EditData.Make, this.state.MakeOptions)}</td>
                                </tr>
                                <tr>
                                  <th scope="row">MODAL</th>
                                  <td>{this.getValueFromArray(EditData && EditData.Model, this.state.modelopt)}</td>
                                </tr>
                                <tr>
                                  <th scope="row">COLOR</th>
                                  <td>{this.getValueFromArray(EditData && EditData.Variant, this.state.variantopt)}</td>
                                </tr>

                                <tr>
                                  <th scope="row">OWNER</th>
                                  <td>{EditData && EditData.owner_type}</td>
                                </tr>
                                <tr>
                                  <th scope="row">KMS</th>
                                  <td>{EditData && EditData.Km}</td>
                                </tr>

                                <tr>
                                  <th scope="row">EMAIL</th>
                                  <td>{EditData && EditData.email}</td>
                                </tr>
                                <tr>
                                  <th scope="row">REG NO</th>
                                  <td>{EditData && EditData.Reg_no}</td>
                                </tr>
                                <tr>
                                  <th scope="row">STATUS 1</th>
                                  <td>{EditData && EditData.pendingStatus1}</td>
                                </tr>
                                <tr>
                                  <th scope="row">STATUS 2</th>
                                  <td>{EditData && EditData.pendingStatus2}</td>
                                </tr>
                              </tbody>

                            </table>
                          </div>
                        }
                      /></div>
                    }
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-7">
                        <Alert
                          className="badge-content"
                          color={this.state.color}
                          isOpen={this.state.alertVisible}
                          toggle={this.onDismiss}
                        >
                          {this.state.textalert}
                        </Alert>
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    {
                      this.state.FilterData && this.state.FilterData.length && !this.state.workproc
                        ?
                        <div className="container">
                          <div className="row">
                            <div className="col-sm-6">
                              <label htmlFor="locationFilter" style={{ fontWeight: 500, fontSize: "medium" }} > Location filter: </label>
                            </div>
                            <div className="col-sm-6">
                              <label htmlFor="makefilter" style={{ fontWeight: 500, fontSize: "medium" }}> Make filter: </label>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-6">
                              <MultiSelect
                                id="locationFilter"
                                name="location"
                                placeholder="Filter Location"
                                options={locationOpt}
                                handleChange={d => this.locationFilter(d)}
                                selectedService={this.state.filteredLoc}
                              />
                            </div>
                            <div className="col-sm-6">
                              <MultiSelect
                                id="makefilter"
                                name="make"
                                placeholder="Filter Make"
                                options={MakeOptions}
                                handleChange={d => this.makeFilter(d)}
                                selectedService={this.state.filteredMake}
                              />
                            </div>
                          </div>
                          <br></br>
                          <div className="row">
                            <div className="col-sm-5" />
                            <div className="col-sm-2">
                              <button type="button" className="btn btn-sm btn-primary" onClick={() => {
                                this.clearFilter()
                              }}> Clear filter</button>
                            </div>
                            <div className="col-sm-5"></div>
                          </div>
                          <br></br>
                        </div>
                        :
                        null
                    }

                    {loading
                      ?
                      <div className="text-center"> <i
                        className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}></i>
                      </div>
                      :
                      <div>{this.state.EnquiryData.length !== 0
                        ?
                        (this.state.EnquiryDatas.length !== 0
                          ?
                          <>
                            {this.state.bulkAllocate == true ?
                              <div className="d-flex justify-content-end">
                                <h3 style={{ marginTop: '4px', fontWeight: '600px', fontSize: 'larger' }}>Bulk Allocate</h3><span>
                                  &nbsp; &nbsp;

                                  <input type="checkbox"
                                    checked={this.state.isallselect}
                                    onChange={(event) => this.selectalldata(event.target.checked ? "Yes" : "No")}
                                  /></span>
                              </div>
                              : <></>
                            }
                            <br></br>
                            <Datatable
                              data={this.state.EnquiryDatas}
                              columnHeading={this.state.workproc ? this.state.column2 :statusbtn==true? this.state.statuscolumn1 : this.state.column1}
                              // columnHeading={this.state.workproc ? this.state.column2 : this.state.column1}
                              excelColumn={this.state.workproc ? this.state.excelColumn2 : this.state.excelColumn1}
                            />
                          </>

                          :
                          <>
                            <div style={{ textAlign: "center", fontSize: "larger" }} ><b>No rows to show</b></div>
                            <br></br>
                          </>
                        ) :
                        (null)
                      }
                      </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment >
    );
  }
}
export default TeleBuyerEnquiryform;