import React from "react";
import './camera.css'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

export default class Cameracrop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clicked: false,
            camid: this.props.id && this.props.id ? this.props.id : 1,
            src: null,
            crop: {
                // aspect: 16 / 9, // you can adjust the aspect ratio according to your requirement
                unit: 'px', // default, can be 'px' or '%'
                width: 200,
                height: 100,
            },
            croppedImageUrl: null,
        }
        this.videoRef = React.createRef(null)
        this.photoRef = React.createRef(null)
    }
    async componentDidMount() {
        this.getVideo()
    }
    getVideo = () => {
        navigator.mediaDevices
            .getUserMedia({
                video: {
                    facingMode: 'environment'
                }
            })
            .then((stream) => {
                let video = this.videoRef.current;
                video.srcObject = stream;
                video.play();
            })
            .catch((err) => {
                console.error(err);
            });
    };
    takePicture = () => {
        this.setState({ clicked: true })
        const twidth = this.props.width && this.props.width ? this.props.width : 400
        const theight = this.props.height && this.props.height ? this.props.height : twidth * (16 / 9)
        let video = this.videoRef.current
        let photo = this.photoRef.current
        photo.width = twidth
        photo.height = theight
        let ctx = photo.getContext('2d')
        ctx.drawImage(video, 0, 0, twidth, theight)
        var canvas2 = document.getElementById(`image${this.state.camid}`);
        const dataURL = canvas2.toDataURL("image/png");
        this.setState({ src: dataURL });
    }
    clearImage = () => {
        let photo = this.photoRef.current
        let ctx = photo.getContext('2d')
        ctx.clearRect(0, 0, photo.width, photo.height)
        this.setState({ clicked: false })
        this.getVideo()
    }
    sendImage = () => {
        let { croppedImageUrl } = this.state
        // var canvas = document.getElementById(`image${this.state.camid}`);
        // var dataURL = canvas.toDataURL("image/png");
        this.props.image(croppedImageUrl)
        this.clearImage()
    }

    onImageLoaded = image => {
        if (image !== null) {
            this.imageRef = image;
        }
    };

    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };

    onCropComplete = crop => {
        if (this.imageRef && crop.width && crop.height) {
            this.getCroppedImg(this.imageRef, crop);
        }
    };

    getCroppedImg(image, crop) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        const croppedImageUrl = canvas.toDataURL('image/jpeg');
        // console.log(croppedImageUrl,"croppedImageUrl fun");
        this.setState({ croppedImageUrl });
    }
    render() {
        let { clicked, src, crop, croppedImageUrl } = this.state
        return (
            <div className="">
                {/* <div className="row"> */}
                <div className="row">
                    <div id='image'>
                        <canvas className={`${clicked ? 'rest' : 'rest'}`} id={`image${this.state.camid}`} ref={this.photoRef}></canvas>

                    </div>
                </div>
                {clicked ? <>
                    <br />
                    <p style={{ color: "red", marginLeft: "10px", fontSize: "14px" }}>
                        Note : Please crop this image number plate only
                    </p>
                    <br />
                    <div className="text-center">
                        <div id='image'>
                            <ReactCrop
                                src={src}
                                crop={crop}
                                onImageLoaded={this.onImageLoaded}
                                onComplete={this.onCropComplete}
                                onChange={this.onCropChange}
                            >
                                <img onLoad={this.onImageLoaded} />

                            </ReactCrop>

                        </div>
                    </div>
                    {croppedImageUrl && (
                        <div>
                            <label style={{ fontSize: "20px", fontWeight: "500" }}>Cropped Image Preview</label>
                            <br></br>
                            <br></br>
                            <img src={croppedImageUrl} alt="Cropped" />
                            <br></br>
                            <br></br>

                            {/* <button className="btn btn-primary" onClick={() => this.getdata(croppedImageUrl)}>
                Save crop Image
              </button> */}
                        </div>
                    )}
                    <br>
                    </br>
                    <div className="row">
                        <div className="col">
                            <button onClick={this.clearImage} className="btn btn-primary container">Clear </button>
                        </div>
                        <div className="col">
                            <button onClick={this.sendImage} className="btn btn-primary container">OK</button>
                        </div>
                    </div>
                </> : <>
                    <video ref={this.videoRef} className="" style={{ width: '100%' }} ></video>
                    <br />
                    <button onClick={this.takePicture} className="btn btn-danger container">Take Picture</button>
                </>
                }
                {/* </div> */}
            </div>
        )
    }
}
