import React, { Component } from 'react'
import { ACCESS_POINT, ImgSTEERAUTO } from '../../../../../config'
import CmsContent from '../../../../../MiddleWare/CmsContent'
// import FromLibrary from '../../../CMS/CmndlFormLibrary'
import FromdfLibrary from '../../../CMS/dfcarzlibrary'
import Progress from '../../../../../components/Extra/Progress'
import htmlToDraft from 'html-to-draftjs';
import moment from 'moment';
import http from '../../../../../MiddleWare/httpMiddleWare';
import { FormMiddleWare, SingleSelect } from '../../../../../components/Form'
import LoginModal from '../../../../../components/Modal/Modal'
import iconClose from "../../../../../images/CMS/icon-hamburger-close.svg";
//fileupload

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginImageResize,
    FilePondPluginFileValidateType,
    FilePondPluginFileValidateSize,
    FilePondPluginImageValidateSize
);

export default class Theme1 extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            btnDisable: false,
            uploadPercentage: 0,
            pageopt: [{ label: "HOME", value: "/" }, { label: "BUYCAR", value: "/buycars" }, { label: "SELLCAR", value: "/sellyourcars" },
            { label: "SERVICES", value: "/Service" },
            { label: "ABOUTUS", value: "/aboutus" },

            ],
            Show: false,
            getlibrarydataImage: this.props.that.getlibrarydataImage,
            uploadPercentage: 0,
            btnDisable: false,
            alertVisible: false,
            alertVisible1: false,
            customerId: localStorage.getItem("userId"),
            AllContent: [{ name: '', link: '', linkname: '', icon: '', description: '', File: null }],
            Maxlength: 4
        }
    }
    async componentWillReceiveProps(props) {
        try {

            if (props) {
                await this.setState({
                    getlibrarydataImage: this.props.that.getlibrarydataImage,
                    contentTitle: this.props.that.contentTitle1,
                    isEdit: this.props.that.isEdit,
                    editId: this.props.that.editid,
                    fileImg: this.props.that.fileImg,
                    fileJson: this.props.that.fileJson,
                    headDesc: this.props.that.headDesc,
                    content2: this.props.that.content2,
                    content1: this.props.that.content1,
                    radio: this.props.that.subTitleId == 1 ? true : false,
                    AllContent: this.props.that.sheetContent
                });


            }
        } catch (error) {
            console.log(error)
        }
    }
    async componentDidMount(props) {
        console.log('did update didmount1');
        try {
            if (this.props) {
                await this.setState({
                    getlibrarydataImage: this.props.that.getlibrarydataImage,
                    contentTitle: this.props.that.contentTitle1 ? this.props.that.contentTitle1 : null,
                    isEdit: this.props.that.isEdit,
                    editId: this.props.that.editid ? this.props.that.editid : null,
                    fileImg: this.props.that.fileImg ? this.props.that.fileImg : null,
                    fileJson: this.props.that.fileJson ? this.props.that.fileJson : null,
                    headDesc: this.props.that.headDesc ? this.props.that.headDesc : null,
                    content2: this.props.that.content2 ? this.props.that.content2 : null,
                    content1: this.props.that.content1 ? this.props.that.content1 : null,
                    radio: this.props.that.subTitleId == 1 ? true : false,
                });
                if (this.props.that.sheetContent) {
                    this.setState({
                        AllContent: this.props.that.sheetContent
                    })
                }


            }
        } catch (error) {
            console.log(error)
        }
    }
    contentHeading = [
        {
            Header: "Search By Hint",
            accessor: "hint"
        },
        {
            Header: "image",
            accessor: "label",
            Cell: d => d.original.label ? <img src={ImgSTEERAUTO + d.original.label} onClick={() => window.open(ImgSTEERAUTO + d.original.label)} style={{ objectFit: 'contain', width: 100, height: 50 }} /> : null
        },
        {
            Header: "Select",
            accessor: "edit",
            Cell: d => this.checktest(d)
        }
    ];
    checktest = (id) => {
        return (
            <button
                type="button"
                className="btn btn-info"
                data-dismiss="modal"
                onClick={() => {
                    this.JSON_refill(id.original.label, "File", this.state.Clickindex);
                }
                }
            >
                Select
            </button>
        );
    };
    JSON_refill = (value, Field, index) => {

        let { AllContent } = this.state;
        if (Field == 'link') {
            AllContent[index]['linkname'] = value.value;
        }

        AllContent[index][Field] = value;
        this.setState({ AllContent });

    };
    addMore = (type = null, i) => {
        let { AllContent, Maxlength } = this.state;
        if (type) {
            AllContent.splice(i, 1)
        } else {
            if (Maxlength > AllContent.length) {
                AllContent.push({ name: '', link: '', icon: '', description: '', File: null })
            }
        }
        this.setState({ AllContent })
    }
    submitCategory = async () => {

        const { pageName, customerId, pageSelected, orgfileJson, orgsheetContent } = this.props.that;
        const { AllContent, File, editId, fileJson, contentTitle, content2, content1, headDesc } = this.state;
        if (!pageSelected) {
            this.props.these('errorPage', 'Select Page')
            this.ScrollTop()
        }
        else if (!pageName) {
            this.props.these('errorPageName', 'Enter Page name')
            this.ScrollTop()
            return false;
        } else if (this.state.radio && !content1) {
            this.setState({ HeaderErr: 'Enter Header Title' })
            this.ScrollTop()
            return true
        } else if (!contentTitle) {
            this.setState({ conTitleErr: 'Enter Content Title' })
            this.ScrollTop()
            return true
        }

        if (File && File.length) {
            let val = File.every(x => x.size <= 300000)
            if (!val) {
                alert("Please Check Image is Greater than 300KB")
                return true
            }
        }

        try {
            this.props.these('btnDisable', true)
            this.setState({ btnDisable: true, conTitleErr: '' })
            const formData = new FormData();
            File && File.map((ival, i) => {
                formData.append('file', ival)
            });
            if (pageSelected) formData.append("searchTags", pageSelected.label);
            formData.append("name", pageName);
            formData.append("themeId", 1);
            formData.append("contentTitle1", contentTitle);

            formData.append("show", 0);
            if (this.state.radio) formData.append('subTitleId', 1)
            if (fileJson) formData.append('file', JSON.stringify(fileJson))
            if (content2) formData.append('content2', content2)
            formData.append('headDesc', headDesc)

            if (content1) formData.append('content1', content1)

            formData.append("sheetContent", JSON.stringify(AllContent));
            formData.append("Date", moment().format('YYYY-MM-DD'));
            formData.append("status", "show");
            formData.append("customerId", customerId);
            formData.append("requireLogin", 'no');

            let deletefiles = []
            if (fileJson && orgfileJson && orgfileJson.length) {
                console.log(orgfileJson, 'orgfileJson');
                const Files = orgfileJson.filter(e => !fileJson.some(x => e == x))
                if (Files.length) {
                    deletefiles = [...deletefiles, ...Files]
                }
            }
            if (AllContent && orgsheetContent && orgsheetContent.length) {
                let newArry = AllContent.map(e => e.File)
                const Files = orgsheetContent.filter(e => !newArry.some(x => e == x))
                if (Files.length) {
                    deletefiles = [...Files]
                }
            }

            if (deletefiles.length > 0) formData.append('deleteArray', JSON.stringify(deletefiles))

            const id = editId ? editId : 'id'

            const result = await CmsContent.MultiArrayFileUpload('tbl_pages', formData, id)
            if (result) {
                this.setState({
                    contentTitle: '',
                    content2: '',
                    headDesc: '',
                    file: null,
                    AllContent: [],
                    btnDisable: false,
                })
                this.props.these('pageSelected', '');
                this.props.these('pageName', '');
                this.props.these('checked', 0);
                this.props.these('errorPageName', '')
                this.props.these('errorPage', '')
                this.props.these('themeSelected', '')
                this.props.these('themeId', '')
                this.props.these('fileImg', null)
                this.props.these('file2Img', null)
                this.props.these('alertVisible', true)
                this.props.these('btnDisable', false)
                this.props.mainfun()
            }

        } catch (error) {
            console.log(error);
            this.setState({ btnDisable: false })
        }
    }
    selectImage = e => {
        this.setState({ file: e.target.files[0] });
        this.setState({ fileName: e.target.files[0].name });
        // if (e) {
        //     this.setState({
        //         file: e.map(fileItem => fileItem.file),
        //         fileName: e.map(fileItem => fileItem.file)
        //     });

        // }
    };
    submit = async e => {
        e.preventDefault();
        const { file, hint } = this.state;
        if (!file) {
            this.setState({ errorFile: "Select File to Upload" });
            return false;
        } else if (!hint) {
            this.setState({ errorFile: "", errorHint: "Enter Searchable hint" });
            return false;
        } else {
            this.setState({ errorFile: "", btnDisable: true });
            let customerId = this.state.customerId;
            const formData = new FormData();
            // if (file) {
            //     file.map((ival) => {
            //         formData.append("file", ival);
            //     })
            // }
            formData.append("file", file)
            formData.append("type", hint);
            formData.append("customerId", customerId);

            try {
                // let Result = await CmsContent.insertImage(formData, "tbl_image");
                let Result = await CmsContent.SteerImage(formData, 'singleInsert')
                if (Result) {
                    let list = this.state.getlibrarydataImage;
                    const id = list[list.length - 1].id + 1;
                    Result.data.value = id
                    Result.data.label = Result.data.file
                    Result.data.hint = Result.data.type
                    list = [Result.data, ...list]
                    this.setState({
                        getlibrarydataImage: list,
                        file: "",
                        errorFile: "",
                        btnDisable: false,
                        alertVisible1: true,
                        fileName: "",
                        hint: "",
                        errorHint: null
                    });
                    //remove success msg after 3 sec
                    setTimeout(() => this.setState({ alertVisible1: false }), 3000);
                }
            } catch (error) { }
        }
    };
    FILEUPLOAD = (e, doc) => {
        if (e) {
            this.setState({
                [doc]: e.map(fileItem => fileItem.file)
            });
        }
    };
    render() {
        let { AllContent, contentTitle, content2, headDesc, content1, Maxlength } = this.state;

        const { fileImg, editid } = this.props.that;
        return (
            <React.Fragment>
                <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                        <label htmlFor="position" >Header</label>
                    </div>
                    <div className="col-sm-5">
                        <div className="col-sm-6" >
                            <input type="radio" style={{ margin: "12px" }} name='radio' value={this.state.radio} checked={this.state.radio} onChange={() => this.setState({ radio: true })} /> Yes
                            <input type="radio" style={{ margin: "7px", marginLeft: "15px" }} name='radio' value={!this.state.radio} checked={!this.state.radio} onChange={() => this.setState({ radio: false, content1: '' })} /> No
                        </div>
                    </div>
                </div>
                {this.state.radio &&
                    <>
                        <div className="row form-group">
                            <div className="col-sm-2" />
                            <div className="col-sm-2">
                                <label htmlFor="position" >Header Title</label>
                            </div>
                            <div className="col-sm-5">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={'Enter Header Title'}
                                    name="content1"
                                    onChange={this.handleUserInput}
                                    value={content1}
                                />
                            </div>
                            <div className="col-sm-3" >
                                <span className="error-show ">
                                    {this.state.HeaderErr}
                                </span>
                            </div>
                        </div>
                        <br />
                        <div className="row form-group">
                            <div className="col-sm-2" />
                            <div className="col-sm-2">
                                <label htmlFor="exampleInputEmail1">
                                    Header Description
                                </label>
                            </div>
                            <div className="col-sm-5">
                                <textarea
                                    type="text"
                                    className="form-control"
                                    style={{ minHeight: "200px" }}
                                    id="headDesc"
                                    name="headDesc"
                                    placeholder="Enter Header Description"
                                    value={headDesc}
                                    onChange={this.handleUserInput}
                                />
                            </div>
                        </div>

                        <br />
                    </>
                }
                <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                        <label htmlFor="exampleInputEmail1">
                            Section Title
                        </label>
                    </div>
                    <div className="col-sm-5">
                        <input
                            type="text"
                            className="form-control"
                            id="contentTitle"
                            name="contentTitle"
                            placeholder="Enter Title"
                            value={contentTitle}
                            onChange={this.handleUserInput}
                        />
                    </div>
                    <div className="col-sm-3" >
                        <span className="error-show ">
                            {this.state.conTitleErr}
                        </span>
                    </div>
                </div>

                <br />
                <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                        <label htmlFor="exampleInputEmail1">
                            Section Description
                        </label>
                    </div>
                    <div className="col-sm-5">
                        <textarea
                            type="text"
                            className="form-control"
                            style={{ minHeight: "200px" }}
                            id="content2"
                            name="content2"
                            placeholder="Enter Section Description"
                            value={content2}
                            onChange={this.handleUserInput}
                        />
                    </div>
                </div>
                <br />
                <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2" >
                        <label>Images </label>
                    </div>
                    <div className="col-sm-4">
                        {/* width:360px and height:150px */}
                        <FilePond
                            files={this.state.File && this.state.File}
                            allowReorder={true}
                            allowMultiple={true}
                            allowImageResize={true}
                        //    maxFileSize={'3072KB'}
                            // imageValidateSizeMaxWidth={360}
                            // imageValidateSizeMaxHeight={150}
                            imagePreviewMinHeight="50"
                            imagePreviewMaxWidth="50"
                            acceptedFileTypes={["image/*"]}
                            onupdatefiles={fileItems => {
                                this.FILEUPLOAD(fileItems, 'File');

                            }}
                            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                        />
                        {/* <label style={{ color: "black" }} >Dimension : {`360 * 150`}</label><br />
                        <label style={{ color: "black" }} >Maximum Size : 3MB</label><br /> */}
                    </div>
                    <div className="col-sm-3" />
                </div>
                <div className="row form-group">
                    <div className="col-sm-2"></div>
                    <div className="col-sm-2" />
                    <div className="col">
                        {fileImg ? fileImg : null}
                    </div>
                </div>
                <br />
                {AllContent && AllContent.map((ival, i) => {
                    let index = i + 1;

                    return (
                        <React.Fragment>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4>Section -{index}
                                            </h4>
                                        </div>
                                        <div className="card-body">
                                            <br />
                                            <div className="row form-group">
                                                <div className="col-sm-2" />
                                                <div className="col-sm-2">
                                                    {editid == 771 ? null : "Icon (Dimension : 40 * 40)"}
                                                </div>
                                                <div className="col-sm-3" >
                                                    {editid == 771 ? null : <LoginModal
                                                        buttonTitle="Select Icon from Library"
                                                        title="Select Image"
                                                        id="iconu"
                                                        extraClass={'info'}
                                                        bodyText={
                                                            <FromdfLibrary
                                                                selectImage={this.selectImage}
                                                                fileName={this.state.fileName}
                                                                handleChange={e => this.setState({ hint: e.target.value })}
                                                                dismiss={this.onDismiss}
                                                                errorFile={this.state.errorFile}
                                                                disableValue={this.state.btnDisable}
                                                                alertMsg="New Library Added"
                                                                submit={this.submit}
                                                                hint={this.state.hint}
                                                                alertVisible={this.state.alertVisible1}
                                                                datas={this.state.getlibrarydataImage}
                                                                columnHeading={this.contentHeading}
                                                                imgsource={this.state.viewmedia}
                                                                visible={true}
                                                            />
                                                        }

                                                        onClick={() => {
                                                            this.setState({ Clickindex: i });
                                                        }}
                                                    />}
                                                </div>
                                                <div className="col-sm-3">
                                                    {ival.File ?
                                                        <div style={{ position: 'relative', display: 'inline' }}>
                                                            <img src={ImgSTEERAUTO + ival.File} style={{ objectFit: 'contain', height: 76 }} />
                                                            <img src={iconClose} style={{ height: 20, width: 20, position: 'absolute', borderRadius: '70px', backgroundColor: 'black', right: 0 }}
                                                                onClick={() => this.JSON_refill(null, "File", i)}></img>
                                                        </div>
                                                        : null}
                                                </div>
                                                <div className="col-sm-1">
                                                    <i
                                                        className="fa fa-window-close fa-3x"
                                                        aria-hidden="true"
                                                        onClick={() => this.addMore(1, i)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row form-group">
                                                <div className="col-sm-2" />
                                                <div className="col-sm-2">
                                                    <label htmlFor="exampleInputEmail1">
                                                        {editid == 771 ? "Button" : "Link"}Title
                                                    </label>
                                                </div>


                                                <div className="col-sm-5">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Name Title"
                                                        value={ival.name}
                                                        onChange={e => this.JSON_refill(e.target.value, 'name', i)}
                                                    />
                                                </div>
                                                <div className="col-sm-3" />
                                            </div>

                                            <div className="row form-group">
                                                <div className="col-sm-2" />
                                                <div className="col-sm-2">
                                                    <label htmlFor="exampleInputEmail1">
                                                        Link
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <SingleSelect
                                                        options={this.state.pageopt}
                                                        handleChange={e => this.JSON_refill(e, 'link', i)}
                                                        placeholder={'SELECT PAGE'}
                                                        selectedService={ival.link}
                                                    />
                                                </div>
                                                <div className="col-sm-3" />
                                            </div>

                                            {editid == 771 ? null : <div className="row form-group">
                                                <div className="col-sm-2" />
                                                <div className="col-sm-2">
                                                    <label htmlFor="exampleInputEmail1">
                                                        Description
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <textarea
                                                        type="text"
                                                        className="form-control"
                                                        style={{ minHeight: "200px" }}
                                                        placeholder="Enter Description"
                                                        value={ival.description}
                                                        onChange={e => this.JSON_refill(e.target.value, 'description', i)}
                                                    />
                                                </div>
                                                <div className="col-sm-3" />
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                })
                }
                {AllContent && Maxlength == AllContent.length ? null : <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-6" />
                    <div className="col-sm-2">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => this.addMore()}
                        >
                            Add More
                        </button>
                    </div>
                </div>}

                <div className="d-flex justify-content-center">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this.submitCategory()}
                        disabled={this.state.btnDisable}
                    >
                        {this.state.btnDisable ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;
                        {this.state.isEdit ? 'Update' : 'Submit'}
                    </button>

                </div>

            </React.Fragment >
        )
    }
}
