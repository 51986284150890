import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import Datatable from "../../../../components/Datatable/Datatable";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import { confirmAlert } from "react-confirm-alert";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import SFAPDF from "../../../../components/PDF/SFAPDF";
import redisMiddleware from "../../../../MiddleWare/redisMiddleware";
import swal from 'sweetalert';

class CarsColourMaster extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      ServiceId: localStorage.getItem("currentService"),
      customerId: localStorage.getItem("userId"),
      alertVisible: false,
      color: "",
      textalert: "",
      ColourName: "",
      errorColourName: "",
      HistoryColor: "",
      isEdit: false,
      disableValue: false
    };
  }

  column = [
    {
      Header: "Colour",
      accessor: "colour"
    },
    {
      Header: "Edit",
      accessor: "edit",
      Cell: d => this.edit(d)
    },
    // {
    //   Header: <b>Delete</b>,
    //   selector: "delete",
    //   Cell: d => this.deleteview(d)
    // }
  ];
  MainFunc = async () => {
    try {
      this.setState({ loading: true })
      const Color = await cmsContent.getFreedom(
        "*",
        "tbl_colour",
        1,
        1,
        "id desc"
      )
      if (Color.data.length) {
        Color.data.map((ival, i) => {
          if (ival.colour == null) {
            ival.colour = "-"
          }
        })
        this.setState({ data: Color.data });
      }
      if (Color.data.length > 0) {
        const header = ["SI.NO", "COLOR"];
        const excelHead = [{ label: "COLOR", key: "colour" }];
        this.setState({
          excelHead,
          header,
          excel: Color.data,
          title: "SFA COLOR",
          loading: false
        });
      }
    }
    catch (err) {
      console.log(err);
    }
  }
  async componentDidMount() {
    try {
      await this.MainFunc()
      // const Color = await redisMiddleware.singleTable(
      //   "tbl_colour",


      // );
      // console.log(Color);
      // if (Color) {
      //   this.setState({ data: Color.data });
      // }
      // if (Color.data.length > 0) {
      //   const header = ["SI.NO", "COLOR"];
      //   const excelHead = [{ label: "COLOR", key: "colour" }];
      //   this.setState({
      //     excelHead,
      //     header,
      //     excel: Color.data,
      //     title: "SFA COLOR"
      //   });
      // }
    } catch (error) {
      console.log(error);
    }
  }
  deleteview = (value) => {
    if (value.original.status == "inactive") {
      return (
        <center>
          <label>
            Deleted
          </label>
        </center>

      )
    }
    else {
      return (
        <center>
          <button type="button" className="btn btn-danger" onClick={() => this.buttonDeletes(value)}>
            Delete
          </button>
        </center>
      )
    }
  }
  buttonDeletes = (value) => {

    let Index = value.index;
    this.setState({
      Index
    });
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete this?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })

      .then(async (willDelete) => {
        if (willDelete) {
          this.buttonDelete(value)
        } else {
          //   swal(`Course ${d.label} has been not Modified!`);
        }
      });
  }
  buttonDelete = async value => {
    const { Index } = this.state;
    let id = value.original.id;
    try {
      let subgroupArray = {};
      subgroupArray.status = "inactive";

      const result = await cmsContent.updateMaster(
        "tbl_colour",
        id,
        subgroupArray
      );
      if (result) {
        let previousdata = [...this.state.data];
        previousdata[Index].status = "inactive";
        this.setState({
          data: previousdata,
          alertVisible: true,
          textalert: "Selected Colour Deleted",
          color: "danger"
        });
        setTimeout(() => this.setState({ alertVisible: false }), 1000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  edit = d => {
    let value = d;
    return (
      <center>
        <button
          type="button"
          className="btn btn-info"
          onClick={() => this.edition(value)}
        >
          Edit
        </button>
      </center>
    );
  };

  handlechangeData = async e => {
    let value = e.target.value
    this.setState({ [e.target.name]: e.target.value, value, errorColourName: "", error: "" });
  };

  submitCategory = async () => {
    const { ColourName, customerId, value, data, ServiceId } = this.state;
    if (!ColourName) {
      this.setState({ errorColourName: "Enter Colour Name" });
      return false;
    }
    let filtered_item =
      data && data.length > 0
        ? data.filter(i => {
          return i.colour === value;
        })
        : [];

    if (filtered_item.length > 0) {
      this.setState({ error: "Colour not be repeated" });
      return false;
    }
    let groupArray = {};
    groupArray.colour = ColourName.trim();
    groupArray.status = "active";
    groupArray.customerid = customerId;
    groupArray.ServiceId = ServiceId
    try {
      this.setState({ disableValue: true });
      const result = await cmsContent.addMaster("tbl_colour", groupArray);
      if (result) {
        // let valueArray = {};
        // valueArray.id = result.data.insertId;
        // valueArray.colour = groupArray.colour;
        // valueArray.status = "active";
        // valueArray.customerid = customerId;
        // valueArray.ServiceId = ServiceId
        // const newData = [valueArray, ...this.state.data];
        this.setState({
          // data: newData,
          alertVisible: true,
          disableValue: false,
          textalert: "New Colour Added",
          color: "success",
          errorColourName: "",
          ColourName: ""
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  onDismiss = () => {
    this.setState({ alertVisible: false, formAlertdelete: false });
  };

  edition = d => {
    console.log(d);
    let Index = d.index;
    let value = d.original;
    let editid = value.id;
    let ColourName = value.colour;
    this.setState({
      editid,
      Index,
      ColourName,
      HistoryColor: value.colour,
      isEdit: true
    });
  };

  UpdateCategory = async () => {
    const { editid, ColourName, Index, data, value, HistoryColor } = this.state;
    if (!ColourName) {
      this.setState({ errorColourName: "Enter Colour Name" });
      return false;
    }
    if (HistoryColor != ColourName) {
      let filtered_item =
        data && data.length > 0
          ? data.filter(i => {
            return i.colour === value;
          })
          : [];

      if (filtered_item.length > 0) {
        this.setState({ error: "Colour not be repeated" });
        return false;
      }
    }
    let subgroupArray = {};
    subgroupArray.colour = ColourName;
    subgroupArray.status = "active";
    try {
      const result = await cmsContent.updateMaster(
        "tbl_colour",
        editid,
        subgroupArray
      );
      if (result) {
        let previousdata = [...this.state.data];
        previousdata[Index].colour = ColourName;
        previousdata[Index].status = "active";
        setTimeout(() => this.setState({ data: previousdata }), 500);
        this.setState({
          alertVisible: true,
          disableValue: false,
          editid: null,
          ColourName: "",
          HistoryColor: "",
          errorColourName: "",
          Index: null,
          textalert: "Colour Updated",
          color: "success",
          isEdit: false
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { alertVisible, textalert } = this.state;
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Colour Master</h1>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-7">
                        <Alert
                          className="badge-content"
                          color={this.state.color}
                          isOpen={alertVisible}
                          toggle={this.onDismiss}
                        >
                          {textalert}
                        </Alert>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="exampleInputEmail1">Colour Name</label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type="text"
                          className="form-control"
                          id="ColourName"
                          name="ColourName"
                          placeholder="Enter Colour Name"
                          value={this.state.ColourName}
                          onChange={this.handlechangeData}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">
                        {this.state.errorColourName}
                      </span>
                      <span className="error-show ">
                        {this.state.error}
                      </span>
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-3" />
                      <div className="col-sm-5">
                        <button
                          type="button"
                          className={`btn btn-${this.state.isEdit ? "secondary" : "primary"
                            }`}
                          disabled={this.state.disableValue}
                          onClick={
                            this.state.isEdit
                              ? this.UpdateCategory
                              : this.submitCategory
                          }
                        >
                          {this.state.isEdit
                            ? "Update Colour"
                            : "Add New Colour"}
                        </button>
                      </div>
                      <div className="col-sm-2" />
                    </div>
                    <br></br>
                    {this.state.excel && this.state.excel.length > 0 ? (
                      <PDFDownloadLink
                        style={{ color: "white" }}
                        document={
                          <SFAPDF
                            title={this.state.title}
                            header={this.state.header}
                            data={this.state.excel}
                          />
                        }
                        fileName="AddColour.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? "Loading document..." : "Download Pdf"
                        }
                        <button className="btn btn-warning">PDF</button>
                      </PDFDownloadLink>
                    ) : null}
                    {this.state.data && this.state.data.length ? (
                      <CSVLink
                        data={this.state.data}
                        headers={this.state.excelHead}
                      >
                        <button
                          className="btn btn-success"
                          style={{ marginLeft: "5px" }}
                        >
                          Excel
                        </button>
                      </CSVLink>
                    ) : null}
                    <br></br>
                    <br></br>
                    <div className="row form-group">
                      <div className="col-sm-12">
                        {this.state.loading == true ? <div className="text-center"> <i
                          className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                        ></i></div> :
                          this.state.data.length ? (
                            <Datatable
                              data={this.state.data}
                              columnHeading={this.column}
                            />
                          ) : null
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
export default CarsColourMaster;
