import React, { Component } from "react";
import { Alert } from "reactstrap";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import SingleSelect from "../../../../components/Form/SingleSelect";
import redisMiddleware from "../../../../MiddleWare/redisMiddleware";
import CmsContent from "../../../../MiddleWare/CmsContent";
import Preloader from "../preloader.js";
import bankname from "../TL/bank.json";
import { ACCESS_POINT } from "../../../../config/index";
import moment from 'moment'
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import iconClose from "../../../../images/CMS/icon-hamburger-close.svg";
import { MDBBtn, MDBIcon } from "mdbreact";
import Camera from '../../../../MiddleWare/camera'

class AT_Booking_Confirmation_Reproof extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      respect: [{ label: "Sir", value: 1 }, { label: "Madam", value: 1 }],
      answerJson: [],
      json: [],
      userid: JSON.parse(localStorage.getItem("userlog")),
      usertypelog: JSON.parse(localStorage.getItem("usertypelog")),
      button: "Save",
      alertVisible: false,
      disablemodel: false,

      Insurance: [
        { label: "COMP", value: 1 },
        { label: "3RD PARTY", value: 2 },
        { label: "Expired", value: 3 }
      ],
      cardoptions: [{ label: "Cheque", value: 1 }, { label: "Card", value: 2 }],
      finance: [{ label: "Yes", value: 1 }, { label: "No", value: 2 }],
      commitOpt: [{ label: "Yes", value: 1 }, { label: "No", value: 0 }],
      sources: [{ label: "Walkin Showroom", value: 1 }, { label: "Walk in Event", value: 2 }, { label: "Tele in", value: 3 }, { label: "Tele Out / Sms", value: 4 }, { label: "Web Leads", value: 5 }, { label: "OLX", value: 6 }, { label: "Car Trade", value: 7 }, { label: "Car Dekho", value: 8 }, { label: "Workshop", value: 9 }, { label: "Other Portals", value: 10 }],
      bank: bankname,
      modeofpayment: [{ label: "Cheque", value: 1 }, { label: "Card", value: 2 }, { label: "Cash", value: 3 }, { label: "Online payment", value: 4 }],
      filesArray: { aadhar: [], pan: [], recipt: [] }
    };
  }

  async componentDidMount() {
    try {
      let modelopt = [];
      let colopt = [];
      let serviceid = 10;
      let allOpt = await this.decryption('getcar')
      if (allOpt) {
        this.setState({
          makeopt: allOpt.makeopt,
          colopt: allOpt.coloropt,
          varopt: allOpt.variantopt,
          modelopt: allOpt.modelopt,
          makeYearOption: allOpt.yearopt
        })
      }


      const formQues = await CmsContent.getFreedom(
        "*",
        "tbl_formquestion",
        `parentid=83`,
        1,
        1
      );

      formQues.data.map((ival, i) => {
        if (ival.Orderby == null) {
          ival.Orderby = 1000 + i;
        }
        if (ival.id == 483) {
          ival.options = this.state.cardoptions
        } if (ival.id == 486) {
          ival.options = this.state.cardoptions
        }
        if (ival.id == 475) {
          ival.options = this.state.sources;
        }
        if (ival.id == 849) {
          ival.options = this.state.commitOpt;
        }
        if (ival.id == 602) {
          ival.type = '';
        }
        if (ival.id == 850) {
          ival.type = '';
        }
      });

      formQues.data = formQues.data.sort(await CmsContent.compareValues("Orderby", "int", "asc"));

      if (this.props.cusid) {
        this.setState({ customerId: this.props.cusid, global: false })
      }
      else if (this.props.match.params.cusid) {
        this.setState({ customerId: this.props.match.params.cusid, global: true });
      }

      this.setState({ data: formQues.data });
      let answerJson = this.state.answerJson;

      const prosp = await CmsContent.getFreedom(
        "*",
        "tbl_dfcarz_prosperities",
        `id = ${this.state.customerId}`,
        "id",
        "id"
      );
      const enquiry = await CmsContent.getFreedom(
        "*",
        "tbl_dfcarz_enquiryform",
        `id=${prosp.data[0].enquiry_id}`,
        1,
        1
      );
      const lead = await CmsContent.getFreedom(
        "*",
        "tbl_dfcars_lead",
        `enquiry_id=${prosp.data[0].enquiry_id} and prosid=${prosp.data[0].id}`,
        1,
        1
      );

      if (lead && lead.data && lead.data[0]) {

        this.setState({ lead: lead.data[0] })
      }

      if (enquiry) {
        let enq = enquiry.data[0];
        this.setState({ Salesid: enq.salesid })
        answerJson[460] = enq.Reg_no;
        this.state.makeYearOption.map((lval, b) => {
          if (lval.label == enq.yom) {
            answerJson[462] = lval;
          }
        });
        this.state.colopt.map((cval, n) => {
          if (cval.value == enq.color) {
            answerJson[466] = cval;
          }
        });
        answerJson[476] = enq.Budget;
        answerJson[463] = enq.Insurance_expiry;
        if (enq.sources) {
          this.state.sources.map((ival, i) => {
            if (ival.label == enq.sources) {
              answerJson[475] = ival;
            }
          })
        }
        this.state.modelopt.map((val, i) => {
          if (enq.Model == val.value) {
            answerJson[461] = val;
          }
        });


        answerJson[468] = enq.name;
        answerJson[470] = enq.address;
        answerJson[473] = enq.mobile;
        answerJson[474] = enq.email;
        var dateStr = moment(new Date()).format('YYYY-MM-DD');
        answerJson[459] = dateStr;
        answerJson[489] = dateStr;
      }

      // if (items && items.data.length > 0) {
      //   let items = this.state.items[0]
      //   answerJson[460] = items.Reg_no;
      //   answerJson[462] = items.make_year
      //   answerJson[463] = items.Insurance_expiry
      //   answerJson[476] = items.price;
      // }

      if (formQues) {
        formQues.data.map((ival, n) => {
          if (ival.id == 461) {
            ival.options = this.state.modelopt;
          }
          if (ival.id == 466) {
            ival.options = this.state.colopt;
          }
          if (ival.id == 462) {
            ival.options = this.state.makeYearOption;
          }
          if (ival.id == 487) {
            ival.options = this.state.finance;
          }
          if (ival.id == 634) {
            ival.options = this.state.modeofpayment;
          }
          if (ival.id == 609) {
            ival.options = this.state.bank
          }
          if (ival.id == 467) {
            ival.options = this.state.Insurance
          }
        });
      }
      const book = await CmsContent.getFreedom(
        "*",
        "tbl_Booking_Confirmation_Reproof",
        `customerid = ${this.state.customerId}`,
        "id",
        "id"
      );
      console.log(this.state.customerId, "this.state.customerId");
      //CONTRACT- FILL
      let isfillform = true

      if (book && book.data.length > 0) {
        isfillform = false

        this.setState({ book: book.data });
        this.formfilled();
      }
      this.setState({ isfillform });

      if (isfillform) {
        if (localStorage.getItem("formFillData")) {
          let formFillData = JSON.parse(localStorage.getItem("formFillData"))
          const Data = formFillData.filter(e => e.prosid == this.state.customerId && e.ref == 'CONTRACT')
          if (Data && Data.length) {
            answerJson = Data[0].data
            console.log(answerJson, "answerJson");
            if (answerJson && answerJson[467] && answerJson[467].value == 3) {
              let { data } = this.state
              data.map((ival) => {
                if (ival.id == 463) {
                  ival.IsDisabled = 0
                }
              })
              await this.setState({ data })
            }
            this.setState({ answerJson })
          }
        }
      }
      this.process();

      this.setState({ answerJson });

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.handleSuccess,
          this.handleError
        );
      } else {
        this.setState({ error: 'Geolocation is not supported' });
      }

    } catch (error) {
      console.error(error);
    }
  }

  handleSuccess = (position) => {
    this.setState({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      error: null
    });
  };

  handleError = (error) => {
    this.setState({ error: error.message });
  };

  formfilled = async () => {
    let answerJson = this.state.answerJson;
    let book = this.state.book[0];
    // console.log(book.insurance_type, "book.insurance_type");
    if (book.insurance_type == 3) {
      let { data } = this.state
      data.map((ival) => {
        if (ival.id == 463) {
          ival.IsDisabled = 0
        }
      })
      await this.setState({ data })
    }

    answerJson.id = book.id;
    answerJson[460] = book.regno;
    answerJson[463] = book.insurance_val;
    answerJson[464] = book.odometer;
    answerJson[465] = book.owner_si_no;
    this.state.colopt.map((cval, n) => {
      if (cval.value == book.color) {
        answerJson[466] = cval;
      }
    });
    this.state.colopt.map((cval, n) => {
      if (cval.value == book.color) {
        answerJson[466] = cval;
      }
    });
    answerJson[601] = book.cheque_card_no_1;
    answerJson[602] = book.cheque_card_no_2;
    if (book.insurance_type) {
      this.state.Insurance.map((ival, i) => {
        if (ival.value == book.insurance_type) {
          answerJson[467] = ival;
        }
      })
    }

    answerJson[469] = book.sonof;
    answerJson[470] = book.address;
    answerJson[471] = book.pin;
    answerJson[472] = book.telephone;

    answerJson[476] = book.vech_sales_price;
    answerJson[477] = book.rto_fee;
    answerJson[478] = book.details;
    answerJson[479] = book.other_charge;
    answerJson[480] = book.total;
    answerJson[481] = book.advance_amt;
    answerJson[851] = book.materialNumber;
    if (book.date_1 != null) {
      let date1 = book.date_1.split(" ")[0];
      answerJson[482] = date1;
    }

    if (book.type_1) {
      this.state.cardoptions.map((ival, v) => {
        if (ival.label == book.type_1) {
          answerJson[483] = ival
        }
      })
    }
    if (book.type_2) {
      this.state.cardoptions.map((ival, v) => {
        if (ival.label == book.type_2) {
          answerJson[486] = ival
        }
      })
    }

    answerJson[484] = book.blc_amt_1;
    if (book.date_2 != null) {
      let date2 = book.date_2.split(" ")[0];
      answerJson[485] = date2;
    }

    answerJson[487] = book.blc_amt_2;
    if (book.exp_deli_date != null) {
      let deldate = book.exp_deli_date.split(" ")[0];
      console.log(deldate);
      answerJson[489] = deldate;
    }
    if (book.source) {
      this.state.sources.map((ival, i) => {
        if (ival.label == book.source) {
          answerJson[475] = ival
        }
      })
    }

    this.state.finance.map((ival, i) => {
      if (ival.label == book.finance) {
        answerJson[487] = ival;
      }
      if (answerJson[487] && answerJson[487].label == "Yes") {
        this.state.bank.map((kval, i) => {
          if (kval.label == book.Bankname) {
            answerJson[609] = kval;
          }
        })
      }
    })
    this.state.modeofpayment.map((ival, i) => {
      if (ival.label == book.modeofpayment) {
        answerJson[634] = ival;
      }
    })
    //commit
    this.state.commitOpt.map((ival, i) => {
      if (ival.value == book.commit) {
        answerJson[849] = ival;
      }
    })

    if (book.commit_work) {
      let json = JSON.parse(book.commit_work)
      if (json) {
        answerJson[850] = json.join()
      }
    }

    //image render
    let images = book.Files ? JSON.parse(book.Files) : null;
    let img = {};
    if (images) {
      Object.keys(images).map((key) => {
        if (key) {
          img[key] = []
          images[key].map((ival, i) => {
            if (ival) {
              let spliting = ival.split(".");
              if (['pdf', 'doc', 'xlsx', 'csv'].includes(spliting[1])) {
                let a = null
                a = "false/pdfIconTrans_1599478202382.png";

                let wait = img[key].push(
                  <div>
                    <div style={{ paddingLeft: '160px', position: 'relative' }} >
                      <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                        onClick={() => this.removeimg(key, i)}></img>
                    </div>
                    <img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + a}
                      onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }}
                      style={{
                        width: "200px", height: "200px", display: "inline-block", padding: "10px"

                      }} />
                  </div>
                )
              } else {
                let wait = img[key].push(
                  <div>
                    <div style={{ paddingLeft: '160px', position: 'relative' }} >
                      <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                        onClick={() => this.removeimg(key, i)}></img>
                    </div>
                    <img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + ival}
                      onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }}
                      style={{
                        width: "200px", height: "200px", display: "inline-block", padding: "10px"

                      }} />
                  </div>
                )
              }
            }
          })
        }
      })
      this.setState({ img })

    }
    this.setState({ answerJson, button: "Update", oldfiles: images });
    this.process();
    this.answers()

    //--NOT AVAIL --
    // answerJson[475] = book.source;
    // answerJson[487] = book.finance;
    // this.state.sources.map((ival, i) => {
    //   if (ival.label == book.source) {
    //     answerJson[475] = ival;
    //   }
    // })
    //answerJson[485] = book.date_2
    //answerJson[486] = book.cheque_card_no_2;
    // answerJson[483] = book.cheque_card_no_1;
    // if (book.date != null) {
    //   let date = book.date.split(" ")[0]
    //   answerJson[459] = date;
    // }
    //answerJson[461] = book.model;
    // answerJson[462] = book.year;
    // this.state.modelopt.map((mval, n) => {
    //   if (mval.value == book.model) {
    //     answerJson[461] = book.model;
    //   }
    // })
    // categoryArray.type_1 = answerJson[483].label;
    // categoryArray.cheque_card_no_1 = answerJson[601];
    // answerJson[467] = book.insurance_type;
    //answerJson[468] = book.name;
    //answerJson[482] = book.date_1;
    //answerJson[473] = book.mobile;
    //answerJson[474] = book.email;

    //--END--
  };

  imgFun = () => {
    let images = this.state.oldfiles;
    let Docs = []
    let img = {
    };
    if (images != null) {
      Object.keys(images).map((key) => {
        img[key] = []
        if (key != null) {
          images[key].map((ival, i) => {
            if (ival) {
              let spliting = ival.split(".");
              if (['pdf', 'doc', 'xlsx', 'csv'].includes(spliting[1])) {
                let a = null
                a = "false/pdfIconTrans_1599478202382.png";
                let wait = img[key].push(
                  <div>
                    <div style={{ paddingLeft: '160px', position: 'relative' }} >
                      <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black', float: "right" }}
                        onClick={() => this.removeimg(key, i)}></img>
                    </div>
                    <img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + a}
                      onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }}
                      style={{
                        width: "200px", height: "200px", display: "inline-block", padding: "10px"

                      }} />
                  </div>
                )
              } else {
                let wait = img[key].push(
                  <div>
                    <div style={{ paddingLeft: '160px', position: 'relative' }} >
                      <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black', float: "right" }}
                        onClick={() => this.removeimg(key, i)}></img>
                    </div>
                    <img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + ival}
                      onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }}
                      style={{
                        width: "200px", height: "200px", display: "inline-block", padding: "10px"

                      }} />
                  </div>
                )
              }
            }
          })
        }
      })

      this.setState({ img })

    }
  }
  removeimg = async (key, i) => {
    let { img, oldfiles } = this.state;
    if (oldfiles || img) {
      oldfiles[key] && oldfiles[key].splice(i, 1)
      img[key] && img[key].splice(i, 1)
      await this.setState({ oldfiles, img })
      this.imgFun()
    }
  }

  Siteongoing = async () => {
    let answerJson = this.state.answerJson;
    console.log(answerJson);
    let groupArray1 = {}
    if (answerJson[466] && answerJson[461] && answerJson[460]) {
      if (answerJson[466]) {
        groupArray1.colour = answerJson[466].value
      }
      if (answerJson[461]) {
        groupArray1.model = answerJson[461].value
      }
      if (answerJson[460]) {
        groupArray1.Reg_no = answerJson[460]
      }
      try {
        let result = await CmsContent.siteintegratedstatus("ongoing", groupArray1)
        if (result) {
          await this.setState({
            json: [],
            answerJson: "",
            alertVisible: true,
            textalert: "Your form has been Submited successfully",
            color: "success"
          });
          setTimeout(() => window.location.reload(), 3000);
        }
        else {
          this.setState({
            alertVisible: true,
            textalert: "Your form has been not submit properly",
            color: "danger"
          });
        }
      }
      catch (error) {
        console.log(error);
      }


    }
    else {
      this.setState({
        alertVisible: true,
        textalert: "Your form has been not submit properly",
        color: "danger"
      });
    }
  }
  update = async () => {

    const { answerJson, filesArray } = this.state;

    // let imagealeart = ''
    // if (filesArray) {
    //   let obj = Object.values(filesArray).map(e => e && e.length && e.map(x => x.size))
    //   obj.map((ival) => {
    //     if (ival !== undefined) {
    //       const fileSize = ival[0] / 1024 / 1024;
    //       const maxFileSize = 3; // Maximum file size in MB
    //       // Validate file size
    //       if (fileSize > maxFileSize) {
    //         // alert("Please Check Image is Greater than 300KB");
    //         imagealeart = true
    //         return true;
    //       }
    //     }
    //     else { console.log(ival, "ival"); }
    //   })
    // }
    // if (imagealeart == true) {
    //   alert("Please Check Image is Greater than 3MB");
    //   return false;
    // }
    // console.log(this.state.latitude,"latitude",this.state.longitude,"longitude");
    if (this.state.latitude == '' || this.state.longitude == '' || this.state.latitude == undefined || this.state.longitude == undefined) {
      alert('Please allow permission to access your location');;
      // return false;
    }
    let categoryArray = {};
    let id = answerJson.id;
    const formData = new FormData()
    if (answerJson[459]) {
      let dateStr = moment(new Date(answerJson[459])).format('YYYY-MM-DD HH:mm:ss')
      formData.append("date", dateStr);
    }


    if (!answerJson[460]) {
      this.setState({
        alertVisible: true,
        textalert: "Please Enter Reg No",
        color: "warning"
      })
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      return false
    }
    if (!answerJson[461]) {
      this.setState({
        alertVisible: true,
        textalert: "Please Enter Model",
        color: "warning"
      })
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      return false
    }
    if (!answerJson[466]) {
      this.setState({
        alertVisible: true,
        textalert: "Please Enter Color",
        color: "warning"
      })
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      return false
    }
    if (!answerJson[476]) {
      this.setState({
        alertVisible: true,
        textalert: "Please Enter Vehicle Sale Price",
        color: "warning"
      })
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      return false
    }
    if (!answerJson[481]) {
      this.setState({
        alertVisible: true,
        textalert: "Please Enter Advance Amount",
        color: "warning"
      })
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      return false
    }
    // if (this.state.filesArray.recipt.length == 0) {
    if (this.state.oldfiles.length == 0) {
      this.setState({
        alertVisible: true,
        textalert: "Please Upload Receipt File",
        color: "warning"
      })
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      return false
    }
    this.setState({ btnDisable: true })
    if (answerJson[460]) {
      formData.append("regno", answerJson[460]);
    }
    if (answerJson[461]) {
      formData.append("model", answerJson[461].value);
    }
    if (answerJson[462]) {
      formData.append("year", answerJson[462].label);
    }
    if (answerJson[463]) {
      formData.append("insurance_val", answerJson[463]);
    }
    if (answerJson[464]) {
      formData.append("odometer", answerJson[464]);
    }
    if (answerJson[465]) {
      formData.append("owner_si_no", answerJson[465]);
    }
    if (answerJson[466]) {
      formData.append("color", answerJson[466].value);
    }

    if (answerJson[467]) {
      formData.append("insurance_type", answerJson[467].value);
    }
    if (answerJson[468]) {
      formData.append("name", answerJson[468]);
    }
    if (answerJson[469]) {
      formData.append("sonof", answerJson[469]);
    }
    if (answerJson[470]) {
      formData.append("address", answerJson[470]);
    }
    if (answerJson[471]) {
      formData.append("pin", answerJson[471]);
    }
    if (answerJson[472]) {
      formData.append("telephone", answerJson[472]);
    }
    if (answerJson[473]) {
      formData.append("mobile", answerJson[473]);
    }
    if (answerJson[474]) {
      formData.append("email", answerJson[474]);
    }
    if (answerJson[476]) {
      formData.append("vech_sales_price", answerJson[476]);
    }
    if (answerJson[477]) {
      formData.append("rto_fee", answerJson[477]);
    }
    if (answerJson[478]) {
      formData.append("details", answerJson[478]);
    }
    if (answerJson[479]) {
      formData.append("other_charge", answerJson[479]);
    }
    if (answerJson[480]) {
      formData.append("total", answerJson[480]);
    }
    if (answerJson[481]) {
      formData.append("advance_amt", answerJson[481]);
    }
    if (answerJson[482]) {
      let dateStr = moment(new Date(answerJson[482])).format('YYYY-MM-DD HH:mm:ss');
      formData.append("date_1", dateStr);
    }

    if (answerJson[483]) {
      formData.append("type_1", answerJson[483].label);
    }
    formData.append("cheque_card_no_1", answerJson[601]);
    formData.append("blc_amt_1", answerJson[484]);
    if (answerJson[485]) {
      let dateStr = moment(new Date(answerJson[485])).format('YYYY-MM-DD HH:mm:ss')
      formData.append("date_2", dateStr);
    }

    if (answerJson[486]) {
      formData.append("type_2", answerJson[486].label);
    }
    formData.append("cheque_card_no_2", answerJson[602]);
    formData.append("blc_amt_2", answerJson[488]);
    if (answerJson[489]) {
      let dateStr = moment(new Date(answerJson[489])).format('YYYY-MM-DD HH:mm:ss')
      formData.append("exp_deli_date", dateStr);
    }

    if (answerJson[475]) {
      formData.append("source", answerJson[475].label);
    }
    if (answerJson[487]) {
      formData.append("finance", answerJson[487].label);
    }
    if (answerJson[634]) {
      formData.append("modeofpayment", answerJson[634].label);
    }
    if (answerJson[609]) {
      formData.append("Bankname", answerJson[609].label);
    }
    if (answerJson[849]) {
      formData.append("commit", answerJson[849].value);
    }
    if (answerJson[850]) {
      let json = answerJson[850].split(',')
      formData.append("commit_work", JSON.stringify(json));
    }
    if (answerJson[851]) {
      formData.append("materialNumber", answerJson[851]);
    }
    if (answerJson[484]) {
      if (parseInt(answerJson[484]) <= 0) {
        formData.append("paymentStatus", 1);

      }
    }
    formData.append('oldfiles', JSON.stringify(this.state.oldfiles))
    if (this.state.filesArray) {
      Object.keys(filesArray).map(async (key, i) => {
        filesArray[key].map((ival) => {
          formData.append(`${key}`, ival)
        })
      })
    }
    if ([1, 2, 3].includes(this.state.usertypelog.orderBy)) {
      formData.append("userid", this.state.Salesid);
    } else {
      formData.append("userid", this.state.userid.id);
    }
    if ([1, 2, 3].includes(this.state.usertypelog.orderBy)) {
      formData.append("UpdatedBy", this.state.userid.id)
    }

    formData.append("latitude", this.state.latitude);

    formData.append("longitude", this.state.longitude);

    console.log(categoryArray);

    try {
      let result = await CmsContent.siteintegratedstatus(
        "tbl_Booking_Confirmation_Reproof",
        formData,
        id
      );
      if (result) {
        await this.setState({
          btnDisable: false,
          json: [],
          answerJson: "",
          alertVisible: true,
          textalert: "Your form has been Submited successfully",
          color: "success"
        });
        setTimeout(() => window.location.reload(), 3000);
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });

      }
      this.process();

    } catch (err) {
      console.log(err)
      this.setState({ btnDisable: false })
    }
  };

  async answers(
    label,
    index,
    options = null,
    key = null,
    type,
    questionId,
    formid
  ) {
    console.log(label, questionId);
    let answerJson = this.state.answerJson;

    if (this.state.button == "Update") {
      if (answerJson && answerJson[487] && answerJson[487].value && answerJson[487].value == 1 && answerJson[487].label == "Yes") {
        this.state.data.map((ival, i) => {
          if (ival.id == 609) {
            ival.type = "selectbox"
          }
        })
      }
      else {
        this.state.data.map((ival, i) => {
          if (ival.id == 609) {
            ival.type = null
          }
        })
      }
      if (answerJson && answerJson[849] && answerJson[849].value == 1) {
        this.state.data.map((ival, i) => {
          if (ival.id == 850) {
            ival.type = "textarea"
          }
        })
      } else {
        this.state.data.map((ival, i) => {
          if (ival.id == 850) {
            ival.type = ""
          }
        })
      }
      //Card number
      if (answerJson && answerJson[634] && answerJson[634].value == 3) {
        this.state.data.map((ival, i) => {
          if (ival.id == 602) {
            ival.type = ""
          }
        })
      } else {
        this.state.data.map((ival, i) => {
          if (ival.id == 602) {
            ival.type = "text"
          }
        })
      }
    }
    //COMMIT
    if (questionId == 849) {
      if (label.value == 1) {
        this.state.data.map((ival) => {
          if (ival.id == 850) {
            ival.type = "textarea";
          }
        })
      } else {
        this.state.data.map((ival) => {
          if (ival.id == 850) {
            ival.type = "";
          }
        })
      }
    }
    if (questionId == 467) {
      if (label.value == 3) {
        this.state.data.map((ival) => {
          if (ival.id == 463) {
            ival.IsDisabled = "0";
          }
        })
      }
      else {
        this.state.data.map((ival) => {
          if (ival.id == 463) {
            ival.IsDisabled = "1";
          }
        })
      }
    }

    if (questionId == 487) {
      if (label.value == 1) {
        answerJson[609] = null
        this.state.data.map((ival, i) => {
          if (ival.id == 609) {
            ival.type = "selectbox"
          }
        })
      }
      else {
        answerJson[609] = null
        this.state.data.map((ival, i) => {
          if (ival.id == 609) {
            ival.type = ""
          }
        })
      }
    }
    if (questionId == 634) {
      if (label.value == 3) {
        this.state.data.map((ival, i) => {
          if (ival.id == 602) {
            ival.type = ""
          }
        })
      }
      else {
        this.state.data.map((ival, i) => {
          if (ival.id == 602) {
            ival.type = "text"
          }
        })
      }
    }
    if (type == "text") {
      answerJson[questionId] = label;
    } else if (type == "date") {
      var d = new Date(label);

      answerJson[questionId] = label;
    } else if (type == "textarea") {
      answerJson[questionId] = label;
    } else if (type == "selectbox") {
      answerJson[questionId] = label;
    } else if (type == "radio") {
      answerJson[questionId] = label;
    } else if (type == "checkbox") {
      let check = [];
      options.map((ival, i) => {
        var checkBox = document.getElementById(`inlineCheckbox${index}${i}`);
        if (checkBox.checked == true) {
          check.push(ival);
        }
      });
      answerJson[questionId] = check.toString();
    }
    if (
      questionId == 476 ||
      questionId == 477 ||
      questionId == 479 ||
      questionId == 481
    ) {
      let total = this.state.total;
      let A = answerJson[476] ? parseInt(answerJson[476]) : 0;
      let B = answerJson[477] ? parseInt(answerJson[477]) : 0;
      //let C = answerJson[478] ? parseInt(answerJson[478]) : 0;
      let D = answerJson[479] ? parseInt(answerJson[479]) : 0;

      //GST Amount = (Original Cost * GST % ) / 100
      // E = (D * 18 / 100) / 100
      // answerJson[347] = E;
      total = A + B + D;
      // console.log(A, B, D, "sales rto other")

      // console.log(total, "'total");
      answerJson[480] = total;
      let E = answerJson[481] ? parseInt(answerJson[481]) : 0;
      // console.log(E, "Advance")
      let G = [];
      G = total - E;
      answerJson[484] = G;
      // console.log(G, "Balance")
    }
    let { isfillform } = this.state
    if (isfillform) { await this.formSave('CONTRACT', this.state.customerId, answerJson) }
    await this.setState({ answerJson, data: this.state.data });
    if ((type == "textarea" || type == "text") && questionId !== 602) {
      this.TypingControl(this.process);//avoid many time call
    }
    else { this.process() }
  }
  process = async () => {
    let data = await this.state.data;
    let { usertypelog } = this.state
    // console.log(data, "data")

    if (data) {
      let arr = data;
      let contentJson = [];
      if (arr && arr.length) {
        let answerJson = await this.state.answerJson;

        let answerJsons = answerJson.length;
        let json = await arr.map(async (item, index) => {
          if (item.type == "text") {
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="position">{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    {item.id == 602 ? <input
                      type="text"
                      className="form-control"
                      placeholder={item.placeholder}
                      onChange={e =>
                        this.answers(
                          e.target.value,
                          index,
                          null,
                          null,
                          "text",
                          item.id,
                          item.parentid
                        )
                      }
                      value={answerJson && answerJson[item.id] && answerJson[item.id] ? answerJson[item.id] : ''}
                    /> : <input
                      type="text"
                      className="form-control"
                      placeholder={item.placeholder}
                      onChange={e =>
                        this.answers(
                          e.target.value,
                          index,
                          null,
                          null,
                          "text",
                          item.id,
                          item.parentid
                        )
                      }
                      defaultValue={answerJson && answerJson[item.id] && answerJson[item.id] ? answerJson[item.id] : ''}
                    // value={answerJson[item.id] ? answerJson[item.id] : ''}
                    />}
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "number") {
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="position" style={{ color: item.id == 481 || item.id == 476 ? "orange" : '' }}>{item.question}</label>
                    {/* <label htmlFor="position">{item.question}</label> */}
                  </div>
                  <div className="col-sm-5">
                    <input
                      type="number"
                      className="form-control"
                      placeholder={item.placeholder}
                      onChange={e =>
                        this.answers(
                          e.target.value,
                          index,
                          null,
                          null,
                          "text",
                          item.id,
                          item.parentid
                        )
                      }
                      disabled={item.id == 473 ? true : false}
                      defaultValue={answerJson && answerJson[item.id] && answerJson[item.id] ? answerJson[item.id] : ""}
                    />
                  </div>
                  <div className="col-sm-3">
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                </div>
              </div>
            );
          }
          if (item.type == "date") {
            this.state.date = answerJson[item.id];

            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="position">{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    {/* || item.id == 489  */}
                    {item.id == 459 ?
                      <input
                        type="date"
                        id="myDate"
                        // disabled={false}
                        disabled={usertypelog.orderBy == 2 ? false : true}
                        className="form-control"

                        onChange={e =>
                          this.answers(
                            e.target.value,
                            index,
                            null,
                            null,
                            "date",
                            item.id,
                            item.parentid
                          )
                        }
                        value={this.state.date}
                      // onChange={this.fromDateSelect}
                      // value={selectedstartdate}
                      />
                      :
                      <input
                        type="date"
                        id="myDate"
                        className="form-control"
                        disabled={item && item.IsDisabled == 0 ? true : false}
                        min={moment().format("YYYY-MM-DD")}
                        onChange={e =>
                          this.answers(
                            e.target.value,
                            index,
                            null,
                            null,
                            "date",
                            item.id,
                            item.parentid
                          )
                        }
                        value={this.state.date}
                      />
                    }
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "textarea") {
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder={item.placeholder}
                      onChange={e =>
                        this.answers(
                          e.target.value,
                          index,
                          null,
                          null,
                          "textarea",
                          item.id,
                          item.parentid
                        )
                      }

                      defaultValue={answerJson && answerJson[item.id] && answerJson[item.id]}
                    // value={answerJson[item.id]}
                    ></textarea>
                  </div>
                  <div className="col-sm-3">
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                </div>
                {item.id == 850 && <div className="text-center"><label className="error-shows">Please separate work by comma. eg: work1,work2</label></div>}
              </div>
            );
          }
          if (item.type == "radio") {
            let arr1 = [];
            await item.options.split(",").map((element, key) => {
              // console.log(element)
              arr1.push(
                <div className="bgcolor">
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      name={`element${index}`}
                      // value={`${ element }`}
                      id={`inlineCheckbox${key}`}
                      onChange={e =>
                        this.answers(
                          element,
                          key,
                          null,
                          key,
                          "radio",
                          item.id,
                          item.parentid
                        )
                      }
                      checked={element == answerJson[item.id]}
                    />
                    <label
                      className="form-check-label"
                      for={`inlineCheckbox${key}`}
                      style={{ marginLeft: "10px" }}
                    >
                      {`${element}`}{" "}
                    </label>
                  </div>
                </div>
              );
            });
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    {arr1}
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "selectbox") {
            let option = [];
            if (item.options != "") {
              item.options.map((ival, i) => {
                option.push(ival);
              });
            }
            // item.options.split(",").map((ival, i) => {
            //   option.push({ label: ival, value: i });
            // });
            this.state.selectbox = answerJson[item.id];
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label style={{ color: item.id == 849 ? "orange" : '' }}>{item.question}</label>
                    {/* <label>{item.question}</label> */}
                  </div>
                  <div className="col-sm-5">
                    <SingleSelect
                      //disabled={this.state.disablemodel}
                      options={option}
                      placeholder={item.placeholder}
                      handleChange={e => {
                        this.answers(
                          e,
                          index,
                          null,
                          null,
                          "selectbox",
                          item.id,
                          item.parentid
                        );
                      }}
                      selectedService={this.state.selectbox ? this.state.selectbox : ''}
                    />
                  </div>
                  <span className="error-shows" id={`${index}`}>
                    {/* {this.state.error[index]} */}
                  </span>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "checkbox") {
            let arr1 = [];
            await item.options.split(",").map((element, key) => {
              arr1.push(
                <div className="bgcolor">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={`inlineCheckbox${index}${key}`}
                      onChange={e => {
                        this.answers(
                          element,
                          index,
                          item.options.split(","),
                          key,
                          "checkbox",
                          item.id,
                          item.parentid
                        );
                      }}
                      checked={element === answerJson[item.id]}
                    // value={element}
                    //value={answerJson[item.id]}
                    />
                    <label
                      className="form-check-label"
                      for={`inlineCheckbox${index}${key}`}
                      style={{ marginLeft: "10px" }}
                    >{`${element}`}</label>
                  </div>
                </div>
              );
            });
            await contentJson.push(
              <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label>{item.question}</label>
                </div>
                <div className="col-sm-5">{arr1}</div>
                <div className="col-sm-3">
                  <span className="error-shows" id={`${index}`}>
                    {/* {this.state.error[index]} */}
                  </span>
                </div>
              </div>
            );
          }
        });
        await Promise.all(json);
        await this.setState({ json: contentJson, answerJson });
      }
    }
  };

  formSubmit = async () => {
    const { answerJson, filesArray } = this.state;

    // let imagealeart = ''
    // if (filesArray) {
    //   let obj = Object.values(filesArray).map(e => e && e.length && e.map(x => x.size))
    //   obj.map((ival) => {
    //     if (ival !== undefined) {
    //       const fileSize = ival[0] / 1024 / 1024;
    //       const maxFileSize = 3; // Maximum file size in MB
    //       // Validate file size
    //       if (fileSize > maxFileSize) {
    //         // alert("Please Check Image is Greater than 300KB");
    //         imagealeart = true
    //         return true;
    //       }
    //     }
    //     else { console.log(ival, "ival"); }
    //   })
    // }
    // if (imagealeart == true) {
    //   alert("Please Check Image is Greater than 3MB");
    //   return false;
    // }
    // console.log(this.state.latitude,"latitude",this.state.longitude,"longitude");
    if (this.state.latitude == '' || this.state.longitude == '' || this.state.latitude == undefined || this.state.longitude == undefined) {
      alert('Please allow permission to access your location');;
      // return false;
    }
    let categoryArray = {};
    if (!answerJson[460]) {
      this.setState({
        alertVisible: true,
        textalert: "Please Enter Reg No",
        color: "warning"
      })
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      return false
    }
    if (!answerJson[461]) {
      this.setState({
        alertVisible: true,
        textalert: "Please Enter Model",
        color: "warning"
      })
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      return false
    }
    if (!answerJson[466]) {
      this.setState({
        alertVisible: true,
        textalert: "Please Enter Color",
        color: "warning"
      })
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      return false
    }
    if (!answerJson[476]) {
      this.setState({
        alertVisible: true,
        textalert: "Please Enter Vehicle Sale Price",
        color: "warning"
      })
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      return false
    }
    if (!answerJson[481]) {
      this.setState({
        alertVisible: true,
        textalert: "Please Enter Advance Amount",
        color: "warning"
      })
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      return false
    }
    if (this.state.filesArray.recipt.length == 0) {
      this.setState({
        alertVisible: true,
        textalert: "Please Upload Receipt File",
        color: "warning"
      })
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      return false
    }

    this.setState({ btnDisable: true })
    const formData = new FormData()
    if (answerJson[459]) {
      let dateStr = moment(new Date(answerJson[459])).format('YYYY-MM-DD HH:mm:ss');
      formData.append("date", dateStr);
    }
    if (answerJson[460]) {
      formData.append("regno", answerJson[460]);
    }

    if (answerJson[461]) {
      formData.append("model", answerJson[461].value);
    }
    if (answerJson[462]) {
      formData.append("year", answerJson[462].label);
    }
    if (answerJson[463]) {
      formData.append("insurance_val", answerJson[463]);
    }
    if (answerJson[464]) {
      formData.append("odometer", answerJson[464]);
    }
    if (answerJson[465]) {
      formData.append("owner_si_no", answerJson[465]);
    }

    if (answerJson[466]) {
      formData.append("color", answerJson[466].value);
    }
    if (answerJson[467]) {
      formData.append("insurance_type", answerJson[467].value);
    }
    if (answerJson[468]) {
      formData.append("name", answerJson[468]);
    }
    if (answerJson[469]) {
      formData.append("sonof", answerJson[469]);
    }
    if (answerJson[470]) {
      formData.append("address", answerJson[470]);
    }
    if (answerJson[471]) {
      formData.append("pin", answerJson[471]);
    }
    if (answerJson[472]) {
      formData.append("telephone", answerJson[472]);
    }
    if (answerJson[473]) {
      formData.append("mobile", answerJson[473]);
    }
    if (answerJson[474]) {
      formData.append("email", answerJson[474]);
    }
    if (answerJson[476]) {
      formData.append("vech_sales_price", answerJson[476]);
    }
    if (answerJson[477]) {
      formData.append("rto_fee", answerJson[477]);
    }
    if (answerJson[478]) {
      formData.append("details", answerJson[478]);
    }
    if (answerJson[479]) {
      formData.append("other_charge", answerJson[479]);
    }
    if (answerJson[480]) {
      formData.append("total", answerJson[480]);
    }
    if (answerJson[481]) {
      formData.append("advance_amt", answerJson[481]);
    }
    if (answerJson[482]) {
      let dateStr = moment(new Date(answerJson[482])).format('YYYY-MM-DD HH:mm:ss')
      formData.append("date_1", dateStr);
    }
    if (answerJson[483]) {
      formData.append("type_1", answerJson[483].label);
    }
    if (answerJson[601]) {
      formData.append("cheque_card_no_1", answerJson[601]);
    }
    if (answerJson[484]) {
      formData.append("blc_amt_1", answerJson[484]);
    }
    if (answerJson[485]) {
      let dateStr = moment(new Date(answerJson[485])).format('YYYY-MM-DD HH:mm:ss')
      formData.append("date_2", dateStr);
    }
    if (answerJson[486]) {
      formData.append("type_2", answerJson[486].label);
    }
    if (answerJson[602]) {
      formData.append("cheque_card_no_2", answerJson[602]);
    }
    if (answerJson[488]) {
      formData.append("blc_amt_2", answerJson[488]);
    }
    if (answerJson[489]) {
      let dateStr = moment(new Date(answerJson[489])).format('YYYY-MM-DD HH:mm:ss')
      formData.append("exp_deli_date", dateStr);
    }
    if (answerJson[475]) {
      formData.append("source", answerJson[475].label);
    }
    if (answerJson[487]) {
      formData.append("finance", answerJson[487].label);
    }
    if (answerJson[634]) {
      formData.append("modeofpayment", answerJson[634].label);
    }
    if (answerJson[609]) {
      formData.append("Bankname", answerJson[609].label);
    }
    if (answerJson[849]) {
      formData.append("commit", answerJson[849].value);
    }
    if (answerJson[850]) {
      let json = answerJson[850].split(',')
      formData.append("commit_work", JSON.stringify(json));
    }
    if (answerJson[851]) {
      formData.append("materialNumber", answerJson[851]);
    }
    if (answerJson[484]) {
      if (parseInt(answerJson[484]) <= 0) {
        formData.append("paymentStatus", 1);

      }
    }
    formData.append("customerid", this.state.customerId);
    // formData.append("userid", this.state.userid.id);
    if ([1, 2, 3, 14].includes(this.state.usertypelog.orderBy)) {
      formData.append("userid", this.state.Salesid);
    } else {
      formData.append("userid", this.state.userid.id);
    }
    if ([1, 2, 3, 14].includes(this.state.usertypelog.orderBy)) {
      formData.append("UpdatedBy", this.state.userid.id)
    }
    formData.append("approval", 0);
    formData.append("status", "active");

    if (this.state.filesArray) {
      Object.keys(filesArray).map(async (key, i) => {
        filesArray[key].map((ival) => {
          formData.append(`${key}`, ival)
        })
      })
    }

    formData.append("latitude", this.state.latitude);

    formData.append("longitude", this.state.longitude);

    console.log(...formData, "formData");
    // return false
    try {
      let result = await CmsContent.siteintegratedstatus(
        "tbl_Booking_Confirmation_Reproof",
        formData
      );
      if (result) {
        console.log(result, "result");
        let formJson = await JSON.parse(localStorage.getItem("formFillData"))
        if (formJson && formJson.length) {
          let Index = await formJson.findIndex(e => e.prosid == this.state.customerId && e.ref == 'CONTRACT')
          if (Index > -1) formJson.splice(Index, 1)
          localStorage.setItem("formFillData", JSON.stringify(formJson))
        }
        if (result.data.insertId && this.state.lead && this.state.lead.id) {
          let sub = {}
          sub.contractid = result.data.insertId
          let result1 = await CmsContent.updateMaster(
            "tbl_dfcars_lead",
            this.state.lead.id,
            sub
          );
          if (result1) {
            await this.setState({
              btnDisable: false,
              json: [],
              answerJson: "",
              alertVisible: true,
              textalert: "Your form has been Submited successfully",
              color: "success"
            });
            setTimeout(() => window.location.reload(), 3000);
            window.scroll({
              top: 0,
              left: 0,
              behavior: "smooth"
            });
          } else {
            await this.setState({
              btnDisable: false,
              json: [],
              answerJson: "",
              alertVisible: true,
              textalert: "Your form has been Not Submitted Properly. Please Try Again",
              color: "warning"
            });
            setTimeout(() => window.location.reload(), 3000);
            window.scroll({
              top: 0,
              left: 0,
              behavior: "smooth"
            });
          }
        }

      }

    } catch (error) {
      console.log(error);
      this.setState({ btnDisable: false })
    }
  };
  download = (name) => {
    // window.open(`${ ACCESS_POINT } / cmsContent / downloadbookconfirm / ${ name }`, '_self')
    window.open(`${ACCESS_POINT} / cmsContent / downloadbookconfirm / ${name}`, '_self')
  }
  generatepdf = async (id, name) => {
    let exportpdf = await CmsContent.Bookingconfirm("tbl_Booking_Confirmation_Reproof", id);
    if (exportpdf.data == "success") {
      await this.download(name)
    }
  }
  formSubmitPdf = async () => {

    const { answerJson } = this.state;
    let categoryArray = {};
    if (answerJson[459]) {
      var d = new Date(answerJson[459]);
      var date = d.getDate();
      var month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      var year = d.getFullYear();
      var hr = d.getHours();
      var min = d.getMinutes();
      var sec = d.getSeconds();
      var dateStr =
        year + "-" + month + "-" + date + " " + hr + ":" + min + ":" + sec;
      categoryArray.date = dateStr;
    }
    categoryArray.regno = answerJson[460];
    if (answerJson[461]) {
      categoryArray.model = answerJson[461].value;
    }
    categoryArray.year = answerJson[462].label;
    categoryArray.insurance_val = answerJson[463];
    categoryArray.odometer = answerJson[464];
    categoryArray.owner_si_no = answerJson[465];
    if (answerJson[466]) {
      categoryArray.color = answerJson[466].value;
    }
    categoryArray.insurance_type = answerJson[467].value;
    categoryArray.name = answerJson[468];
    categoryArray.sonof = answerJson[469];
    categoryArray.address = answerJson[470];
    categoryArray.pin = answerJson[471];
    categoryArray.telephone = answerJson[472];
    categoryArray.mobile = answerJson[473];
    categoryArray.email = answerJson[474];
    categoryArray.vech_sales_price = answerJson[476];
    categoryArray.rto_fee = answerJson[477];
    categoryArray.details = answerJson[478];
    categoryArray.other_charge = answerJson[479];
    categoryArray.total = answerJson[480];
    categoryArray.advance_amt = answerJson[481];
    if (answerJson[482]) {
      var d1 = new Date(answerJson[482]);
      var date1 = d.getDate();
      var month1 = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      var year1 = d.getFullYear();
      var hr1 = d.getHours();
      var min1 = d.getMinutes();
      var sec1 = d.getSeconds();
      var dateStr1 =
        year1 +
        "-" +
        month1 +
        "-" +
        date1 +
        " " +
        hr1 +
        ":" +
        min1 +
        ":" +
        sec1;
      categoryArray.date_1 = dateStr1;
    }
    categoryArray.type_1 = answerJson[483].label;
    categoryArray.cheque_card_no_1 = answerJson[601];
    categoryArray.blc_amt_1 = answerJson[484];
    if (answerJson[485]) {
      var d2 = new Date(answerJson[485]);
      var date2 = d.getDate();
      var month2 = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      var year2 = d.getFullYear();
      var hr2 = d.getHours();
      var min2 = d.getMinutes();
      var sec2 = d.getSeconds();
      var dateStr2 =
        year2 +
        "-" +
        month2 +
        "-" +
        date2 +
        " " +
        hr2 +
        ":" +
        min2 +
        ":" +
        sec2;
      categoryArray.date_2 = dateStr2;
    }
    categoryArray.type_2 = answerJson[486].label;
    categoryArray.cheque_card_no_2 = answerJson[602];
    categoryArray.blc_amt_2 = answerJson[488];
    if (answerJson[489]) {
      var d3 = new Date(answerJson[489]);
      var date3 = d.getDate();
      var month3 = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      var year3 = d.getFullYear();
      var hr3 = d.getHours();
      var min3 = d.getMinutes();
      var sec3 = d.getSeconds();
      var dateStr3 =
        year3 +
        "-" +
        month3 +
        "-" +
        date3 +
        " " +
        hr3 +
        ":" +
        min3 +
        ":" +
        sec3;
      categoryArray.exp_deli_date = dateStr3;
    }
    // categoryArray.source = answerJson[475];
    // categoryArray.finance = answerJson[487];
    if (answerJson[475]) {
      categoryArray.source = answerJson[475].label;
    }
    if (answerJson[487]) {
      categoryArray.finance = answerJson[487].label;
    }
    if (answerJson[634]) {
      categoryArray.modeofpayment = answerJson[634].label;
    }
    if (answerJson[609]) {
      categoryArray.Bankname = answerJson[609].lable;
    }
    categoryArray.customerid = this.state.customerId;
    categoryArray.userid = this.state.userid.id;
    categoryArray.approval = 0;
    categoryArray.status = "active";
    try {
      let result = await CmsContent.addMaster(
        "tbl_Booking_Confirmation_Reproof",
        categoryArray
      );
      if (result) {
        // this.generatepdf(result.data.insertId, answerJson[468])
        this.generatepdf(this.state.customerId, answerJson[468])
        await this.setState({
          json: "",
          answerJson: "",
          alertVisible: true,
          textalert: "Your form have been submitted successfully",
          color: "success"
        });
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  exportpdf = () => {
    let answerJson = this.state.answerJson;
    let name = answerJson[468]
    window.open(`${ACCESS_POINT} / cmsContent / downloadbookconfirm / ${name}`, '_self')
  }
  FILEUPLOAD = (e, doc) => {
    let filesArray = this.state.filesArray;
    filesArray[doc] = e.map(fileItem => fileItem.file)
    this.setState({
      filesArray
    });
  };

  webcam2 = (e, field, side, ival = null) => {
    let Files = this.state[field];
    if (e) {
      Files[side] = Files[side] ? Files[side] : []
      Files[side].push({ source: e })
      this.setState({ [field]: Files })
      if (ival) this.setState({ [ival]: false })
    }
  }
  render() {
    const { resSelected, json, button } = this.state;
    // console.log(this.state.filesArray.recipt,"this.state.filesArray.recipt");
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div
                  className="card"
                  style={{
                    margin: "-20px",
                    marginTop: "5px",
                    boxShadow: "0px 0px 10px #b9b3b3",
                    borderRadius: "8px"
                  }}
                >
                  <div className="card-header">
                    <h1>Booking Confirmation Reproof</h1>
                  </div>
                  <div className="card-body">
                    <div>
                      <Alert
                        className="badge-content"
                        color={this.state.color}
                        isOpen={this.state.alertVisible}
                        toggle={this.onDismiss}
                      >
                        {this.state.textalert}
                      </Alert>

                      {json && json.length ? json : <div>Loading...</div>}
                      {json && json.length > 0 ? (
                        <div className="bgcolor">
                          <div className="row form-group">
                            <div className="col-sm-2"></div>
                            <div className="col-sm-2">
                              <label htmlFor="exampleInputEmail1">
                                Aadhaar {<button className="btn btn-default btn-sm" value={this.state.IsAadharCam} onClick={e => { this.setState({ IsAadharCam: !this.state.IsAadharCam }) }}>
                                  <MDBIcon icon="camera" size="lg" />
                                </button>} :
                              </label>
                            </div>
                            <div className="col-sm-3">
                              {this.state.IsAadharCam ?
                                <Camera
                                  image={(e) => { this.webcam2(e, 'filesArray', 'aadhar', 'IsAadharCam') }}
                                  width={300}
                                  height={400}
                                  id={'IsAadharCam'}
                                />
                                :
                                <div>
                                  <FilePond
                                    files={this.state.filesArray.aadhar}
                                    allowReorder={true}
                                    allowMultiple={true}
                                    allowImageResize={true}
                                    imagePreviewMinHeight="50"
                                    imagePreviewMaxHeight="50"
                                    //  maxFileSize={'3072KB'}
                                    acceptedFileTypes={["image/*", "application/pdf"]}
                                    onupdatefiles={fileItems => {
                                      this.FILEUPLOAD(fileItems, 'aadhar');
                                    }}
                                    labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                  />
                                  {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label> &nbsp;&nbsp;
                                  <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                                </div>
                              }
                            </div>
                          </div>
                          <div className="row form-group">
                            <div className="col-sm-2"></div>
                            <div className="col-sm-1"></div>
                            <div className="col-sm-9">
                              {this.state.img ? this.state.img.aadhar : null}
                            </div>
                          </div>
                          <br />
                          <div className="row form-group">
                            <div className="col-sm-2"></div>
                            <div className="col-sm-2">
                              <label htmlFor="exampleInputEmail1">
                                PAN{<button className="btn btn-default btn-sm" value={this.state.IsPanCam} onClick={e => { this.setState({ IsPanCam: !this.state.IsPanCam }) }}>
                                  <MDBIcon icon="camera" size="lg" />
                                </button>}:
                              </label>
                            </div>
                            <div className="col-sm-3">
                              {this.state.IsPanCam ?
                                <Camera
                                  image={(e) => { this.webcam2(e, 'filesArray', 'pan', 'IsPanCam') }}
                                  width={300}
                                  height={400}
                                  id={'IsPanCam'}
                                />
                                : <div><FilePond
                                  files={this.state.filesArray.pan}
                                  allowReorder={true}
                                  allowMultiple={true}
                                  allowImageResize={true}
                                  imagePreviewMinHeight="50"
                                  imagePreviewMaxHeight="50"
                                  //  maxFileSize={'3072KB'}
                                  acceptedFileTypes={["image/*", "application/pdf"]}
                                  onupdatefiles={fileItems => {
                                    this.FILEUPLOAD(fileItems, 'pan');
                                  }}
                                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                />
                                  {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label> &nbsp;&nbsp;
                                  <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                                </div>}
                            </div>
                          </div>
                          <div className="row form-group">
                            <div className="col-sm-2"></div>
                            <div className="col-sm-1"></div>
                            <div className="col-sm-9">
                              {this.state.img ? this.state.img.pan : null}
                            </div>
                          </div>
                          <br />
                          <div className="row form-group">
                            <div className="col-sm-2"></div>
                            <div className="col-sm-2">
                              <label htmlFor="exampleInputEmail1" style={{ color: 'orange' }}>
                                RECIPT {<button className="btn btn-default btn-sm" value={this.state.IsReciptCam} onClick={e => { this.setState({ IsReciptCam: !this.state.IsReciptCam }) }}>
                                  <MDBIcon icon="camera" size="lg" />
                                </button>} :
                              </label>
                            </div>
                            <div className="col-sm-3">
                              {this.state.IsReciptCam ?
                                <Camera
                                  image={(e) => { this.webcam2(e, 'filesArray', 'recipt', 'IsReciptCam') }}
                                  width={300}
                                  height={400}
                                  id={'IsReciptCam'}
                                />
                                : <div><FilePond
                                  files={this.state.filesArray.recipt}
                                  allowReorder={true}
                                  allowMultiple={true}
                                  allowImageResize={true}
                                  imagePreviewMinHeight="50"
                                  imagePreviewMaxHeight="50"
                                  //  maxFileSize={'3072KB'}
                                  acceptedFileTypes={["image/*", "application/pdf"]}
                                  onupdatefiles={fileItems => {
                                    this.FILEUPLOAD(fileItems, 'recipt');
                                  }}
                                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                />
                                  {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label> &nbsp;&nbsp;
                                  <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                                </div>}
                            </div>
                          </div>
                          <div className="row form-group">
                            <div className="col-sm-2"></div>
                            <div className="col-sm-1"></div>
                            <div className="col-sm-9">
                              {this.state.img ? this.state.img.recipt[0] : null}
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {json.length > 0 && (
                        <div>
                          {this.state.global && (
                            <div className="row form-group">
                              <div className="col text-center">
                                {[1, 2, 3].includes(this.state.usertypelog.orderBy) ? <button
                                  type="button"
                                  className="btn btn-primary"
                                  disabled={this.state.btnDisable}
                                  onClick={
                                    button == "Save" ? this.formSubmit : this.update
                                  }
                                >
                                  {this.state.btnDisable ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;

                                  {button == "Save" ? "Save" : "Update"}
                                </button> : button == "Save" ? <button
                                  type="button"
                                  className="btn btn-primary"
                                  disabled={this.state.btnDisable}
                                  onClick={
                                    button == "Save" ? this.formSubmit : this.update
                                  }
                                >
                                  {this.state.btnDisable ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;

                                  {button == "Save" ? "Save" : "Update"}
                                </button> : null}
                              </div>
                            </div>
                          )}

                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }

}

export default AT_Booking_Confirmation_Reproof;
