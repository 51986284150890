import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import LoginModal from "../../../../components/Modal/Modal";
import moment from "moment";
import Datatable from "../../../../components/Datatable/Datatable";
import { ACCESS_POINT } from "../../../../config/index"
import Icon from "react-icons-kit";
import { circle_ok } from 'react-icons-kit/ikons/circle_ok'
import { notepad_ok } from 'react-icons-kit/ikons/notepad_ok'
import { notepad_remove } from 'react-icons-kit/ikons/notepad_remove'
import { shuffle } from 'react-icons-kit/ikons/shuffle'
import { shopping_cart_delete } from 'react-icons-kit/ikons/shopping_cart_delete'
import CmsContent from "../../../../MiddleWare/CmsContent";
class Salespendingtask extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            columnbtn:false,
            columnbtn2:false,
            dashboard: [],
            Data: [],
            Datas: [],
            userid: JSON.parse(localStorage.getItem("userlog")),
            loading: true,
            userList: ["enquiry", "lead", "contract", "delivery","rctransfer"]
        };

    }
    async componentDidMount() {
        let allOpt = await this.decryption('getcar')
        if (allOpt) {
            await this.setState({
                MakeOptions: allOpt.makeopt,
                coloropt: allOpt.coloropt,
                variantopt: allOpt.variantopt,
                modelopt: allOpt.modelopt
            })
        }
        await this.mainfun()

    }
    async mainfun(type = 'all') {
        try {
            this.setState({ loading: true })
            let { userList } = this.state
            let CategoryArray = {}
            CategoryArray.id = this.state.userid.id
            let result = await CmsContent.BUYDFCARZ(type, 'TodayTask', CategoryArray)
            // console.log(result,"pending");
            if (result && type == 'all') {
                this.setState({ Data: result })
                userList.map(ival => { this.setState({ [ival]: result[ival] ? result[ival] : [] }) })
            }
            else {
                this.setState({ [type]: result[type], Data: result })
            }
            await this.setState({ loading: false })
        } catch (error) {
            console.log(error);
        }
    }
    column = [
        {
            Header: "Name",
            accessor: "name"
        },
        {
            Header: "Reg no",
            accessor: "Reg_no"
        },
        {
            Header: "Mobile",
            accessor: "mobile"
        },
        {
            Header: "Enquriry date",
            accessor: "createdAt"
        },
        {
            Header: "Make",
            accessor: "make",
            Cell: (d) => this.getValueFromArray(d.original.Make, this.state.MakeOptions)

        },
        {
            Header: "Model",
            accessor: "model",
            Cell: (d) => this.getValueFromArray(d.original.Model, this.state.modelopt)
        },
        {
            Header: "Variant",
            accessor: "variant",
            Cell: (d) => this.getValueFromArray(d.original.Variant, this.state.variantopt)

        },
        {
            Header: "Color",
            accessor: "color",
            Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)

        },
        // {
        //     Header: "View status",
        //     // accessor: "edit",
        //     width: 150,
        //     // Cell: d => this.StatusView(d)
        // },

    ]
    column1 = [
        {
            Header: "Name",
            accessor: "name"
        },
        {
            Header: "Reg no",
            accessor: "Reg_no"
        },
        {
            Header: "Mobile",
            accessor: "mobile"
        },
        {
            Header: "Enquriry date",
            accessor: "created_At"
        },
        {
            Header: "Make",
            accessor: "make",
            Cell: (d) => this.getValueFromArray(d.original.make, this.state.MakeOptions)

        },
        {
            Header: "Model",
            accessor: "model",
            Cell: (d) => this.getValueFromArray(d.original.model, this.state.modelopt)
        },
        {
            Header: "Variant",
            accessor: "variant",
            Cell: (d) => this.getValueFromArray(d.original.variant, this.state.variantopt)

        },
        {
            Header: "Color",
            accessor: "color",
            Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)

        },
        {
            Header: "Approval status",
            // accessor:"contract_Status",
            width:150,
            Cell: (d) => this.ApprovalStatus(d)

        },
    ]
    column2 = [
        {
            Header: "Name",
            accessor: "name"
        },
        {
            Header: "Reg no",
            accessor: "Reg_no"
        },
        {
            Header: "Mobile",
            accessor: "mobile"
        },
        {
            Header: "Enquriry date",
            accessor: "created_At"
        },
        {
            Header: "Make",
            accessor: "make",
            Cell: (d) => this.getValueFromArray(d.original.make, this.state.MakeOptions)

        },
        {
            Header: "Model",
            accessor: "model",
            Cell: (d) => this.getValueFromArray(d.original.model, this.state.modelopt)
        },
        {
            Header: "Variant",
            accessor: "variant",
            Cell: (d) => this.getValueFromArray(d.original.variant, this.state.variantopt)

        },
        {
            Header: "Color",
            accessor: "color",
            Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)

        },
    ]
    onClk = (val) => {
        const { enquiry, lead, contract, delivery,rctransfer } = this.state
        
        if (val === 1) { this.setState({ Datas: enquiry && enquiry.flldata && enquiry.flldata.pending ? enquiry.flldata.pending : [] ,columnbtn:false,columnbtn2:false }) }
        if (val === 2) { this.setState({ Datas: lead && lead.flldata && lead.flldata.pending ? lead.flldata.pending : [] ,columnbtn:false,columnbtn2:false}) }
        if (val === 3) { this.setState({ Datas: contract && contract.flldata && contract.flldata.pending ? contract.flldata.pending : [] ,columnbtn:true,columnbtn2:false}) }
        if (val === 4) { this.setState({ Datas: delivery && delivery.flldata && delivery.flldata.pending ? delivery.flldata.pending : [] ,columnbtn:false,columnbtn2:true}) }
        if (val === 5) { this.setState({ Datas: rctransfer && rctransfer.flldata && rctransfer.flldata.pending ? rctransfer.flldata.pending : [] ,columnbtn:false,columnbtn2:true}) }


        window.scroll({
            top: 400,
            left: 0,
            behavior: "smooth",
        });
    }
    ApprovalStatus = (d) => {
        // console.log(d,"d")
        if( d.original.FormFilled=="no"&& d.original.contract_Status=="Not Need"){
            return(
                <div>
                    <button className="btn btn-sm btn-warning">Form Not Fill</button>
                </div>
            )

        }
        else if( d.original.commit == "0" && d.original.FormFilled=="yes"&& d.original.contract_Status=="pending"){
            return(
                <div>
                    <button className="btn btn-sm btn-primary">Waiting for approval</button>
                </div>
            )

        }
        else if( d.original.commit == "1" && d.original.FormFilled=="yes"&& d.original.contract_Status=="pending"){
            return(
                <div>
                    <button className="btn btn-sm btn-primary">Waiting for approval</button>
                </div>
            )

        }
        else if( d.original.commit == "1" && d.original.approval == 1 && d.original.FormFilled=="yes"&& d.original.contract_Status=="In Progress"&& d.original.Rfstatus == null){
            return(
                <div>
                    <button className="btn btn-sm btn-primary">waitin for approval</button>
                </div>
            )

        }
        else if(d.original.commit == "1" && d.original.FormFilled=="yes"&& d.original.contract_Status=="In Progress"&& d.original.accounts_approval=="0"&&d.original.comment==null ){
            return(
                <div>
                    <button className="btn btn-sm btn-secondary">Accounts Approval</button>
                </div>
            )

        }
        else if(d.original.commit == "0" && d.original.FormFilled=="yes"&& d.original.contract_Status=="In Progress" ){
            return(
                <div>
                    <button className="btn btn-sm btn-secondary">Accounts Approval</button>
                </div>
            )

        }
        else if( d.original.FormFilled=="no"&& d.original.contract_approved_Date ){
            return(
                <div>
                    <button className="btn btn-sm btn-success">Contract Approved</button>
                </div>
            )

        }
        else{
            return(
                <div>
                    -
                </div>
            )

        }
        

    }
    edash = () => {
        const { enquiry, lead, contract, delivery,rctransfer } = this.state
        const evaluation = this.state.eval

        return (<div className="container-fluid">
            <div className="card">
                {/* < div className="row" > */}
                <div className="card-header">
                    <div className="col-sm-6" >
                        <h2 className="mt-2 black">Pending Task</h2>
                    </div>
                </div>
                <div className="card-body">
                    <div className='container-fluid'>
                        <div className="row  mt-4 text-center">
                            <div className="col">
                                <div className="dashboardCard1" onClick={() => this.onClk(1)} >
                                    <Icon icon={notepad_ok} style={{ color: 'green' }} size={35} />
                                    <h2 className="mt-2 black" >Enquiry</h2>
                                    <div >
                                        <h1 className="bold mt-4 mb-2 black"><b>{enquiry && enquiry.flldata && enquiry.flldata.pending ? enquiry?.flldata?.pending.length : 0}</b></h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="dashboardCard1" onClick={() => this.onClk(2)}>
                                    <Icon icon={notepad_remove} size={35} style={{ color: 'red' }} />
                                    <h2 className="mt-2 black" >Lead</h2>
                                    <div>
                                        <h1 className="bold mt-4 mb-2 black" ><b>{lead && lead.flldata && lead.flldata.pending ? lead?.flldata?.pending.length : 0}</b></h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="dashboardCard1" onClick={() => this.onClk(3)}>
                                    <Icon icon={shuffle} size={35} style={{ color:  'rgb(203 44 146)' }} />
                                    <h2 className="mt-2 black" >Contract</h2>
                                    <div>
                                        <h1 className="bold mt-4 mb-2 black"><b>{contract && contract.flldata && contract.flldata.pending ? contract?.flldata?.pending.length : 0}</b></h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="dashboardCard1" onClick={() => this.onClk(4)}>
                                    <Icon icon={shopping_cart_delete} size={35} style={{ color: 'red' }} />
                                    <h2 className="mt-2 black" >Delivery</h2>
                                    <div>
                                        <h1 className="bold mt-4 mb-2 black"><b>{delivery && delivery.flldata && delivery.flldata.pending ? delivery?.flldata?.pending.length : 0}</b></h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="dashboardCard1" onClick={() => this.onClk(5)}>
                                    <Icon icon={circle_ok} size={35} style={{ color: 'green' }} />
                                    <h2 className="mt-2 black" >RC Transfer</h2>
                                    <div>
                                        <h1 className="bold mt-4 mb-2 black"><b>{rctransfer && rctransfer.flldata && rctransfer.flldata.pending ? rctransfer?.flldata?.pending.length : 0}</b></h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }

    render() {
        let { loading,columnbtn ,columnbtn2} = this.state;
        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        {this.edash()}
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h1>Pending Task</h1>
                                    </div>
                                    <div className="card-body">
                                        <div className="row form-group">
                                            <div className="col-sm-4">

                                            </div>
                                        </div>
                                        {loading ? <div className="text-center"> <i
                                            className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                                        ></i></div> : this.state.Data ? (
                                            this.state.Datas.length ?
                                                <Datatable
                                                    data={this.state.Datas}
                                                    columnHeading={columnbtn==true?this.column1:columnbtn2==true?this.column2:this.column}
                                                />
                                                :
                                                null
                                        ) : (<div style={{ textAlign: "center" }} ><b>No Data Found</b></div>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}
export default Salespendingtask;
