import React from "react";
import { Alert } from "reactstrap";
import LoginModal from "../../../../components/Modal/Modal";
import AddUser from "../../../Modal/AddCustomer";
import UserData from "../../../../MiddleWare/User";
import Datatable from "../../../../components/Datatable/Datatable";
import { FormMiddleWare, MultiSelect } from "../../../../components/Form";
import CmsContent from "../../../../MiddleWare/CmsContent";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Redirect } from "react-router-dom";
import { ACCESS_POINT } from "../../../../config";
import SingleSelect from "../../../../components/Form/SingleSelect";
import redisMiddleware from "../../../../MiddleWare/redisMiddleware";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import LearnerPdf from "../../../../components/PDF/LearnerPDF";
import { createTheme } from "react-data-table-component";

class DfCarsAddUsers extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.type,
            mapgroup: {},
            groups: [],
            categorySelected: [],
            userIndex: "",
            userName: "",
            mobileNumber: "",
            UserPassword: '',
            email: "",
            usermake: "",
            errusermake: "",
            errorUserName: "",
            errorMobileNumber: "",
            errorEmail: "",
            alertVisible: false,
            formAlertdelete: false,
            updateData: false,
            updateEmail: false,
            button: "Submit",
            disableValue: false,
            redirect: false,
            data: [],
            NewMakeData: [{ label: "HONDA", value: 9 }, { label: "KIA", value: 26 }, { label: "SKODA", value: 18 }, { label: "VW", value: 21 }, { label: "RENAULT", value: 17 }, { label: "TATA", value: 19 }],
            uploadimg: [],
            logoname: "",
            textview: null,
            color: "success",
            customerId: localStorage.getItem("userId"),
            serviceId: localStorage.getItem("currentService"),
            errorType: null,
            errorRep: null,
            LocaOption: [],
            downlineOption: [],
            jobRoleDesignationOption: [],
            disableUsertype: false,
            disableReportee: false,
            disableReportTo: false,
            disableJobrole: false,
            usertypeSelected: "",
            jobRoleDesignationSelected: "",
            BranchAns: "",
            locationSelected: "",
            downlineSelected: [],
            errordoj: null,
            errorTypeLoca: null,
            type: null,
            port: null,
            rep: null,
            dwn: null,
            Xreport: []
        };
    }

    async componentDidMount() {
        this.userList();
    }

    userList = async () => {
        this.setState({ loading: true })
        let userlog = localStorage.getItem("userlog");
        let User_Type = JSON.parse(userlog).userType;
        let serviceEnabled = JSON.parse(userlog).serviceEnabled;
        let usercount = JSON.parse(userlog).usercount;
        let counting = [];
        serviceEnabled.split(",").map((item, i) => {
            if (this.state.serviceId == item) {
                counting.push(usercount.split(",")[i]);
            }
        });

        try {
            this.setState({ User_Type });
            const location = await CmsContent.getFreedom(
                "name as label,id as value",
                "tbl_location",
                `customerId =${this.state.customerId} and serviceid =${this.state.serviceId} and status='active'`,
                1,
                "id desc"
            );
            let locationData = []
            if (location) {
                locationData = location.data
                this.setState({ location: locationData });
            }
            const branchopt = await CmsContent.getFreedom(
                "name as label,id as value,locationId as locationid",
                "tbl_dfcars_Branch",
                `customerId =${this.state.customerId} and serviceid =${this.state.serviceId} and status='active'`,
                1,
                "id desc"
            );
            let branchData = []
            if (branchopt) {
                branchData = branchopt.data
                this.setState({ branchopt: branchData });
            }
            const typeuser = await CmsContent.getFreedom(
                "usertype as label,id as value,orderBy",
                "tbl_UserType",
                `customerId =${this.state.customerId} and serviceid =${this.state.serviceId} and  orderBy IS NOT NULL`,
                1,
                "id desc"
            );

            let usertypeName = []
            if (typeuser) {
                usertypeName = typeuser.data
                this.setState({ usertype: usertypeName.sort((a, b) => a.orderBy - b.orderBy) });
            }

            const report = await CmsContent.getFreedom(
                "*",
                "tbl_MaptoLocation",
                `customerId =${this.state.customerId}`,
                1,
                "id desc"
            );
            // const report = await redisMiddleware.singleTable(
            //     "tbl_MaptoLocation",
            //     this.state.customerId,
            //     "customerid"
            // );
            this.setState({ report: report.data });
            // console.log('datas1');
            const datas = await CmsContent.getFreedom(
                "*",
                "tbl_user_web",
                `customerId =${this.state.customerId} and serviceId =${this.state.serviceId}`,
                1,
                "id desc"
            );
            const Make = await CmsContent.getFreedom(
                '*',
                'tbl_make_master',
                `customerId =${this.state.customerId} and serviceId =${this.state.serviceId}`,
                1,
                'id desc'
            )
            console.log(Make, 'Make');
            let MakeData = Make.data

            let user = [];
            if (datas) {
                datas.data.map(async (ival, n) => {
                    user.push(ival);
                });

                if (user.length > 0) {
                    for (let ival of user) {
                        for (let jval of locationData) {
                            if (jval.value === ival.locationid) {
                                ival.locationName = jval.label
                            }
                        }
                        for (let kval of branchData) {
                            if (kval.value == ival.sub_loc_id) {
                                ival.branchName = kval.label
                            }
                        }
                        for (let lval of usertypeName) {
                            if (lval.value == ival.userType) {
                                ival.usertypeName = lval.label
                            }

                        }
                        for (let mval of MakeData) {
                            if (mval.id == ival.userMake) {
                                ival.makeName = mval.Make
                            }
                        }
                        for (let pval of user) {
                            if (ival.downline == pval.id) {
                                ival.downlineName = pval.userName
                            }
                        }
                    }

                    user = await user.sort(await CmsContent.compareValues("id", "int", "desc"));

                    const header = ["SI.NO", "USERNAME", "EMAIL", "MOBILE NUMBER"];
                    const excelHead = [
                        { label: 'ID', key: 'id' },
                        { label: "USERNAME", key: "userName" },
                        { label: 'USERTYPE', key: 'usertypeName' },
                        { label: "EMAIL", key: "email" },
                        { label: "MOBILE NUMBER", key: "mobileNumber" },
                        { label: 'LOCATION', key: 'locationName' },
                        { label: 'MAKE', key: 'makeName' },
                        { label: 'BRANCH', key: 'branchName' },
                        { label: 'DownlineName', key: 'downlineName' }
                    ];
                    // {label:"LOCATION",key:"location"}           ];
                    this.setState({
                        excelHead,
                        header,
                        excel: user,
                        title: "DFCarsAddUSERS"
                    });
                }
            }
            this.setState({ data: user, loading: false });
        } catch (error) {
            console.log(error);
        }
    };

    column = [

        {
            Header: "ID",
            accessor: "id"
        },
        {
            Header: "UserName",
            accessor: "userName"
        },
        {
            Header: "image",
            accessor: "image",
            Cell: d => this.viewimage(d, "image")
        },
        {
            Header: "User Type",
            accessor: "userType",
            Cell: d =>
                this.getValueFromArray(d.original.userType, this.state.usertype)
        },
        {
            Header: "UserMake",
            accessor: "userMake",
            Cell: d => this.getValueFromArray(d.original.userMake, this.state.NewMakeData)
        },
        // {
        //   Header: "Downline",
        //   accessor: "downline",
        //   Cell: d => this.check(d.original.downline)
        // },
        // {
        //     Header: "Location",
        //     accessor: "userportid",
        //     Cell: d => this.loca(d.original.userportid)
        // },
        {
            Header: "Location",
            accessor: "locationid",
            Cell: d => this.getValueFromArray(d.original.locationid, this.state.location)
        },
        {
            Header: "Branch",
            accessor: "branch",
            Cell: d => this.getValueFromArray(d.original.sub_loc_id, this.state.branchopt)
        },
        {
            Header: "Email",
            accessor: "email"
        },
        {
            Header: "Password",
            accessor: "withoutmd5"
        },
        {
            Header: "MobileNumber",
            accessor: "mobileNumber"
        },
        {
            Header: "Status",
            accessor: "status"
        },
        {
            Header: "Visibility",
            accessor: "edit",
            Cell: d => this.showVisibility(d)
        },
        {
            Header: "Edit",
            accessor: "edit",
            Cell: d => this.edit(d, "customer")
        },
        // {
        //     Header: "Remap",
        //     accessor: "edit",
        //     Cell: d => this.remap(d, "remap")
        // }
        // {
        //   Header: "Delete",
        //   accessor: "delete",
        //   Cell: d =>
        //     this.dataTableButton("danger", "Delete", () => {
        //       this.buttonDeletes(d.original);
        //     })
        // }
    ];
    // edit = (value, modalWindowId) => {
    //     return (
    //         <center>
    //             <button type="button" className="btn btn-warning" onClick={() => this.buttonEdit(value, `#${modalWindowId}`)}>
    //                 Edit
    //             </button>
    //         </center>
    //     );
    // };
    loca = d => {
        if (d && d !== "-") {
            let a = {};
            this.state.report.map((ival, i) => {
                if (ival.id == d) {
                    a.location = this.getValueFromArray(
                        ival.locationId,
                        this.state.location
                    );
                }
            });
            return a.location;
        } else {
            return "NA";
        }
    };

    // X_remap = async value => {
    //   console.log(value);
    //   const Xrepo = await redisMiddleware.singleTable(
    //     "tbl_user_remap",
    //     value.original.id,
    //     "userid"
    //   );
    //   console.log(Xrepo);
    // };

    remap = (value, modalWindowId) => {
        return (
            <LoginModal
                buttonTitle="Remap"
                title={"Remap User"}
                extraClass="warning"
                id="remap"
                onClick={() => this.buttonEdit(value, "remap")}
                extraStyle={{
                    width: "125%",
                    position: "relative",
                    top: 90
                }}
                bodyText={this.remapDIV()}
            />
        );
    };

    remapDIV = () => {
        const {
            User_Type,
            formAlertdelete,
            counting,
            usertype,
            jobRoleDesignationOption,
            errorType,
            errorRep,
            LocaOption,
            usertypeSelected,
            jobRoleDesignationSelected,
            BranchAns,
            locationSelected,
            categorySelected,
            updateData,
            textalert,
            disableUsertype,
            downlineOption,
            downlineSelected,
            selectedDate,
            disableReportee,
            disableReportTo,
            disableJobrole,
            errordoj,
            errorTypeLoca,
            type
        } = this.state;
        let userType = localStorage.getItem("userType");
        const userData = {
            userName: this.state.userName,
            mobileNumber: this.state.mobileNumber,
            email: this.state.email,
            selectedDate: selectedDate
        };

        const errorData = {
            errorType: this.state.errorType,
            errorRep: this.state.errorRep,
            errorUserName: this.state.errorUserName,
            errorMobileNumber: this.state.errorMobileNumber,
            errorEmail: this.state.errorEmail,
            errordoj: errordoj
        };

        let title =
            type === "remap"
                ? `Remap ${this.state.userName}`
                : this.state.button === "Submit"
                    ? `Add User`
                    : `Edit User`;
        return (
            <div>
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a
                            class="nav-link active"
                            id="home-tab"
                            data-toggle="tab"
                            href="#rem"
                            role="tab"
                            aria-controls="home"
                            aria-selected="true"
                        >
                            Remap
                        </a>
                    </li>
                    <li class="nav-item">
                        <a
                            class="nav-link"
                            id="profile-tab"
                            data-toggle="tab"
                            href="#x-rep"
                            role="tab"
                            aria-controls="profile"
                            aria-selected="false"
                        >
                            X-Reportees
                        </a>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div
                        class="tab-pane fade show active"
                        id="rem"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                    >
                        <div className="row form-group">
                            <div className="col-sm-12">
                                <div className="row ">
                                    <div className="col-sm-3">
                                        <label for="exampleInputEmail1">Usertype</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <SingleSelect
                                            options={usertype}
                                            disabled={disableUsertype}
                                            handleChange={this.usertypeSelect}
                                            selectedService={usertypeSelected}
                                            placeholder={"User Type"}
                                        />
                                        <span className="error-shows">{errorType}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col-sm-12">
                                <div className="row ">
                                    <div className="col-sm-3">
                                        <label for="exampleInputEmail1">Usertype-Location</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <SingleSelect
                                            options={jobRoleDesignationOption}
                                            disabled={disableJobrole}
                                            handleChange={this.jobRoleDesignation}
                                            selectedService={jobRoleDesignationSelected}
                                            placeholder={"Job Role Designation"}
                                        />
                                        <span className="error-shows">{errorTypeLoca}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row form-group">
              <div className="col-sm-12">
                <div className="row ">
                  <div className="col-sm-3">
                    <label for="exampleInputEmail1">Supervisor</label>
                  </div>
                  <div className="col-sm-9">
                    <SingleSelect
                      options={LocaOption}
                      // disabled={disableReportTo}
                      handleChange={this.locationSelect}
                      selectedService={locationSelected}
                      placeholder={"Report To..."}
                    />
                    <span className="error-shows">{errorRep}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row form-group">
              <div className="col-sm-12">
                <div className="row ">
                  <div className="col-sm-3">
                    <label for="exampleInputEmail1">Reportees</label>
                  </div>
                  <div className="col-sm-9">
                    <MultiSelect
                      options={downlineOption}
                      disabled={disableReportee}
                      handleChange={this.downline}
                      selectedService={downlineSelected}
                      placeholder={"Down Line"}
                    />
                    <span className="error-shows">{""}</span>
                  </div>
                </div>
              </div>
            </div> */}
                        <div className="row form-group">
                            <div className="col-sm-12">
                                <div className="row ">
                                    <div className="col-sm-3">
                                        <label for="exampleInputEmail1">Date Of Joining</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <input
                                            type="date"
                                            id="myDate"
                                            className="form-control"
                                            onChange={this.fromDateSelect}
                                            value={selectedDate}
                                        />
                                        <span className="error-shows">{errordoj}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <AddUser
                            userData={userData}
                            errorData={errorData}
                            userType={User_Type}
                            serviceId={this.state.serviceId}
                            handleInput={this.handleUserInput}
                            handleSubmit={this.handleSubmit}
                            onDismiss={this.onDismiss}
                            alertVisible={this.state.alertVisible}
                            formAlertdelete={this.state.formAlertdelete}
                            checkMobileNumber={this.checkMobileNumber}
                            checkEmail={this.checkEmail}
                            updateData={updateData}
                            updateEmail={this.state.updateEmail}
                            uploadimg={this.uploadimg}
                            logoname={this.state.logoname}
                        />
                    </div>
                    <div
                        class="tab-pane fade"
                        id="x-rep"
                        role="tabpanel"
                        aria-labelledby="profile-tab"
                    >
                        <div>
                            {this.state.Xreport && (
                                <Datatable
                                    data={this.state.Xreport}
                                    columnHeading={this.Xcolumn}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    Xcolumn = [
        {
            Header: "User Name",
            accessor: "userName"
        },
        {
            Header: "User Type",
            accessor: "userType",
            Cell: d =>
                this.getValueFromArray(d.original.userType, this.state.usertype)
        },
        {
            Header: "Location",
            accessor: "userportid	",
            Cell: d => this.getuserport(d.original.userportid, this.state.report)
        },
        {
            Header: "Downline",
            accessor: "downline",
            Cell: d => this.check(d.original.downline)
        },
        {
            Header: "Date of Join",
            accessor: "d_of_j"
        }
    ];

    getuserport = (d, array) => {
        if (array.length > 0) {
            if (array.length !== 0) {
                let filtered = array.filter(function (item) {
                    return item.id == d;
                });
                let v = filtered[0];
                if (v != undefined) {
                    // userTypeId
                    // locationId
                    let e = this.getValueFromArray(v.locationId, this.state.location);
                    return e;
                } else {
                    return "-";
                }
            }
        } else {
            return "-";
        }
    };

    check = d => {
        if (d && d !== "-") {
            let value = "";
            value = d;
            let res = value.split(",");
            var result = "";
            for (var i = 0; i < res.length; i++) {
                result += this.getDownline(res[i], this.state.data) + ",";
            }
            result = result.replace(/,\s*$/, "");
            return result;
        } else {
            return "NA";
        }
    };

    getDownline = (d, array) => {
        if (array.length > 0) {
            if (array.length !== 0) {
                let filtered = array.filter(function (item) {
                    return item.id == d;
                });
                let v = filtered[0];
                if (v != undefined) {
                    return v.userName;
                } else {
                    return "-";
                }
            }
        } else {
            return "-";
        }
    };

    columns = [
        {
            Header: "Cohorts",
            accessor: "label"
        },
        {
            Header: "Remove",
            Cell: d => this.remove(d)
        }
    ];
    wait = d => {
        return null;
    };
    showVisibility = id => {
        let status = id.original.status == "active" ? "Active" : "Inactive";
        let colour = id.original.status == "active" ? "warning" : "danger";
        return this.dataTableButton(colour, status, () =>
            this.updateVisibility(id)
        );
    };

    updateVisibility = async value => {
        let values = value.original;
        const index = value.index;
        const previousData = [...this.state.data];
        const newData = { ...previousData[index] };

        let subCategoryArray = {};
        if (newData.status === "active") {
            newData.status = "Inactive";
            subCategoryArray.status = "Inactive";
            this.setState({ button: "Inactive" });
        } else {
            newData.status = "active";
            subCategoryArray.status = "active";
            this.setState({ button: "Active" });
        }

        const id = newData.id;

        const data = previousData.filter(value => value.id !== id);
        data.splice(index, 0, newData);

        this.setState({ data });

        let datas = {};
        datas.id = values.id;
        datas.name = values.userName;
        datas.email = values.email;
        datas.mobileNumber = values.mobileNumber;
        datas.status = newData.status;
        datas.customerId = values.customerId;

        try {
            const result = await CmsContent.updateMaster(
                "tbl_user_web",
                newData.id,
                subCategoryArray,
                "id"
            );
            if (result) {
                this.setState({ data: data });
            }
        } catch (error) {
            this.setState({ data: previousData });
            console.log(error);
        }
    };

    view = (value, modalWindowId) => {
        return (
            <center>
                <button
                    type="button"
                    data-toggle="modal"
                    data-target={`#${modalWindowId}`}
                    className="btn btn-warning"
                    onClick={() => this.buttonView(value)}
                >
                    View Cohorts
                </button>
            </center>
        );
    };
    //   buttonView = value => {
    //     const { mapgroup } = this.state;
    //     let all = mapgroup
    //       .map((ival, i) => {
    //         if (ival.userid == value.original.id) {
    //           let returnArray = {};
    //           returnArray.label = this.getValueFromArray(
    //             ival.groupid,
    //             this.state.groups
    //           );
    //           returnArray.value = ival.groupid;
    //           returnArray.getid = ival.id;

    //           return returnArray;
    //         }
    //       })
    //       .filter(function(element) {
    //         return element !== undefined;
    //       });

    //     this.setState({ buttonView: all });
    //   };

    getValueFromArrays = (d, array) => {
        if (array.length > 0) {
            if (array.length !== 0) {
                let filtered = array.filter(function (item) {
                    return item.userid == d;
                });
                let v = filtered[0];
                if (v != undefined) {
                    return v.groupid;
                } else {
                    return "-";
                }
            }
        } else {
            return "-";
        }
    };

    remove = (value, modalWindowId) => {
        return (
            <center>
                <button
                    type="button"
                    data-toggle="modal"
                    data-target={`#${modalWindowId}`}
                    // className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    className="btn btn-danger"
                    onClick={() => this.buttonRemoves(value)}
                >
                    Remove
                </button>
            </center>
        );
    };
    buttonRemoves = value => {
        this.setState({});
        confirmAlert({
            title: "Confirm to Delete",
            message: "Are you sure to do this.",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => this.buttonRemove(value)
                    //onClick: () => alert("Group removed from")
                },
                {
                    label: "No",
                    onClick: () => alert("Click No")
                }
            ]
        });
    };
    buttonRemove = async value => {
        let values = value.original;

        let index = value.index;

        let id = values.getid;
        let previousData = [...this.state.mapgroup];

        try {
            let result = await CmsContent.getSingleConditionedValue(
                "tbl_user_web",
                "id",
                id,
                "Delete"
            );
            if (result) {
                const datas = previousData.filter(delelteid => delelteid.id !== id);
                this.userList();
            }
        } catch (error) {
            console.log(error);
        }
    };
    handleDescription = async e => {
        this.setState({ usermake: e });
        // let Model = await CmsContent.getFreedom(
        //   `Model as label , id as value`,
        //   `tbl_dfcarsmodel`,
        //   `makeid=${e.value}`
        // );
        // if (Model.data.length) {
        //   Model.data.push({ label: "OTHERS", value: 0 });
        //   this.setState({ ModelData: Model.data });
        // } else {
        //   this.setState({ ModelData: [] });
        // }
    };

    onSubmit = async () => {
        let userArray = {};
        let userName = this.state.userName;
        let mobileNumber = this.state.mobileNumber;
        let email = this.state.email;
        let usertypeSelected = this.state.usertypeSelected;
        let locationSelected = this.state.locationSelected;
        let serviceId = this.state.serviceId;
        let imgupload = this.state.imgupload;
        let downline = this.state.downlineSelected;
        let jobrole = this.state.jobRoleDesignationSelected;
        let d_of_j = this.state.selectedDate;
        let usermake = this.state.usermake
        // if (!usermake) {
        //     this.setState({ errusermake: "Select User Make" });
        //     return false
        // }
        if (!usertypeSelected) {
            this.setState({ errorType: "Select User Type" });
            return false;
        }
        // if (!jobrole) {
        //     this.setState({ errorRep: "Select Usertype-Location" });
        //     return false;
        // }
        if (!email) {
            this.setState({ errorEmail: "Enter Emailid" });
        }
        if (!d_of_j) {
            this.setState({ errordoj: "Select Date of Joining" });
        }
        if (mobileNumber && email && userName) {
            let selectedValue = "";
            let selectedValue1 = "";
            // if (downline) {
            //     const value = downline.map(
            //         values => (selectedValue += `${values.value},`)
            //     );
            //     selectedValue = selectedValue.replace(/,\s*$/, "");
            // }
            if (usermake) {
                const value = usermake.map(
                    values => (selectedValue1 += `${values.value},`)
                );
                selectedValue1 = selectedValue1.replace(/,\s*$/, "");
            }
            if (this.state.UserPassword) {
                this.CheckPassword(this.state.UserPassword)
            }
            const formData = new FormData();

            formData.append("serviceId", serviceId);
            formData.append("userName", userName);
            formData.append("mobileNumber", mobileNumber);
            formData.append("email", email);
            if (this.state.BranchAns && this.state.BranchAns.value) {
                formData.append("sub_loc_id", this.state.BranchAns.value);
            }
            formData.append("status", "active");
            formData.append("userType", usertypeSelected.value);
            formData.append("customerId", this.state.customerId);
            formData.append("image", imgupload);
            // formData.append("userportid", jobrole.value);
            formData.append("userMake", selectedValue1);
            if (jobrole) formData.append("locationid", jobrole.value);
            if (locationSelected) {
                formData.append("reportPort", locationSelected.value);
            }
            if (downline) formData.append("downline", downline.value);
            formData.append("d_of_j", d_of_j);
            formData.append("withoutmd5", this.state.UserPassword);


            const previousData = [...this.state.data];

            try {
                //const result = await UserData.userInsert(userArray);
                const result = await UserData.adminuserInsert("tbl_user_web", formData);
                if (result) {
                    //  this.componentDidMount();
                    const userData = {
                        id: parseInt(result.data.insertId),
                        userName: userName,
                        mobileNumber: mobileNumber,
                        email: email,
                        status: "active",
                        image: result.data.image
                    };
                    const newData = [...this.state.data, userData];
                    this.setState({
                        // data: newData,
                        alertVisible: true,
                        usermake: "",
                        UserPassword: "",
                        locationSelected: "",
                        usertypeSelected: "",
                        userName: "",
                        mobileNumber: "",
                        email: "",
                        errorUserName: "",
                        errorMobileNumber: "",
                        errorEmail: "",
                        imgupload: "",
                        logoname: "",
                        downlineSelected: "",
                        locationSelected: "",
                        jobRoleDesignationSelected: "",
                        BranchAns: "",
                        selectedDate: "",
                        downlineOption: [],
                        LocaOption: [],
                        jobRoleDesignationOption: []
                    });
                    this.userList();
                    setTimeout(() => this.setState({ alertVisible: false }), 3000);
                }
            } catch (error) {
                this.setState({
                    data: previousData
                });
                console.log(error);
            }
        }
    };
    buttonEdit = async (value, type = null) => {

        let port = value.original.userportid;
        let rep = value.original.reportPort;
        let dwn = value.original.downline;
        try {

            this.reset();
            if (type === "remap") {
                this.setState({
                    disableUsertype: false,
                    disableReportee: false,
                    disableReportTo: false,
                    disableJobrole: false
                });

            } else {
                this.setState({
                    disableUsertype: true,
                    // disableReportee: true,
                    disableReportTo: true,
                    // disableJobrole: true,
                    selectedDate: value.original.d_of_j,
                    button: "Update"
                });
            }
            const { location } = this.state;
            const index = value.index;
            const editid = value.original.id;
            // const previousData = [...this.state.data];
            // const getData = { ...previousData[index] };
            const getData = value.original;
            var logoname = "";
            if (!getData.image) {
                logoname = getData.image;
            } else {
                logoname = "";
            }

            let v = {};
            if (getData.userMake) {
                let value = []
                let char = getData.userMake.split(",")
                char.map((ival) => {
                    this.state.NewMakeData.map((jval, i) => {
                        if (jval.value == ival) {
                            value.push(jval)
                        }
                    });

                })
                this.handleDescription(value);
            }
            // let usermake = [];
            // let usertypeSelected = this.state.usermake;
            // this.state.usertype.map((item, i) => {
            //   if (usertypeSelected.order >= item.order) {
            //     usertype.push({
            //       label: item.label,
            //       value: item.value,
            //       order: item.order
            //     });
            //   } else {
            //     usertype.push({
            //       label: item.label,
            //       value: item.value,
            //       order: item.order,
            //       isDisabled: true
            //     });
            //   }
            // });

            let e = {};
            this.state.usertype.map(async (ival, i) => {
                if (ival.value == value.original.userType) {
                    e.value = value.original.userType;
                    e.label = this.getValueFromArray(
                        value.original.userType,
                        this.state.usertype
                    );
                    e.orderBy = ival.orderBy;
                    await this.usertypeSelect(e);
                }
            });

            let usertype = [];
            let usertypeSelected = this.state.usertypeSelected;
            this.state.usertype.map((item, i) => {
                if (usertypeSelected.orderBy >= item.orderBy) {
                    usertype.push({
                        label: item.label,
                        value: item.value,
                        orderBy: item.orderBy
                    });
                } else {
                    usertype.push({
                        label: item.label,
                        value: item.value,
                        orderBy: item.orderBy,
                        // isDisabled: true
                    });
                }
            });

            this.state.report.map(async (item, index) => {
                if (item.id == value.original.userportid) {
                    let value = {};
                    value.value = item.id;
                    value.label =
                        e.label + " - " + this.getValueFromArray(item.locationId, location);
                    value.userTypeId = item.userTypeId;
                    value.orderBy = e.orderBy;
                    await this.jobRoleDesignation(value);
                }
            });

            if (getData.locationid) {
                // console.log(getData.locationid, location,"Button Edit");
                let jobRoleDesignationSelected = this.state.jobRoleDesignationOption.filter(x => x.value == getData.locationid)
                // if (jobRoleDesignationSelected.length > 0) {
                //     console.log(jobRoleDesignationSelected, "Button Edit");
                //     await this.jobRoleDesignation(jobRoleDesignationSelected[0]);
                //     // await this.setState({ jobRoleDesignationSelected: jobRoleDesignationSelected[0] })
                // }
                location.map(async (ival) => {
                    // console.log(location, "location")
                    if (ival.value == getData.locationid) {
                        await this.setState({ jobRoleDesignationSelected: e })
                        await this.jobRoleDesignation(ival);
                    }
                })
            }
            this.state.report.map(async (item, index) => {
                if (value.original.reportPort !== undefined) {
                    if (item.id == value.original.reportPort) {
                        let user = "";
                        user = this.getValueFromArray(item.userTypeId, this.state.usertype);
                        this.state.data.map(async (jval, j) => {
                            if (value.original.reportPort == jval.userportid) {
                                let value = {};
                                value.value = item.id;
                                value.label =
                                    user +
                                    " - " +
                                    this.getValueFromArray(item.locationId, this.state.location) +
                                    " - " +
                                    jval.userName;
                                await this.locationSelect(value);
                            }
                        });
                    }
                } else {
                    console.log()
                    this.setState({ locationSelected: "" });
                }
            });

            if (value.original.downline) {
                console.log(value.original, "value.original.downline")
                let char = value.original.downline.split(",");
                console.log(char, "char");
                // userportid
                let down = [];
                char.map((ival, i) => {
                    this.state.data.map((jval, j) => {
                        if (ival == jval.id) {
                            // this.state.report.map((kval, k) => {
                            // if (jval.userType == kval.id) {
                            let typeuser = this.getValueFromArray(
                                jval.userType,
                                this.state.usertype
                            );
                            let loca = this.getValueFromArray(
                                jval.locationid,
                                this.state.location
                            );
                            // down.push({
                            //     value: jval.id,
                            //     label: typeuser + " - " + loca + " - " + jval.userName
                            // });
                            down = {
                                value: jval.id,
                                label: typeuser + " - " + loca + " - " + jval.userName
                            }
                            this.downline(down);

                            // }
                            // });
                        }
                    });
                });
                // this.downline(down);
            }
            let { branchopt } = this.state
            // console.log(value.original, "value.original");
            if (branchopt && value.original.locationId) {
                let branchopt_Filter = branchopt.filter(x => x.locationid == value.original.locationId)
                if (branchopt_Filter.length > 0) {
                    this.setState({ branchopt_Filter })
                }
            }
            if (branchopt && value.original.sub_loc_id) {
                let BranchAns = branchopt.filter(x => x.value == value.original.sub_loc_id)[0]
                if (BranchAns) {
                    this.setState({ BranchAns })
                }
            }
            await this.setState({
                usertype,
                rep,
                dwn,
                // userIndex: index,
                editid,
                userName: getData.userName,
                mobileNumber: getData.mobileNumber,
                email: getData.email,
                currentmail: getData.email,
                errorUserName: "",
                errorMobileNumber: "",
                errorEmail: "",
                UserPassword: getData.withoutmd5,
                alertVisible: false,
                updateData: true,
                imgupload: getData.image,
                logoname: logoname,
                type,
                port
            });
        } catch (error) {
            console.log(error);
        }
    };

    downline = async e => {
        // console.log(e, "e");
        await this.setState({ downlineSelected: e, BranchAns: "", jobRoleDesignationSelected: "" });
        if (e) {
            let { branchopt, jobRoleDesignationOption } = this.state
            // console.log(branchopt, "branchopt");
            // console.log(jobRoleDesignationOption, "jobRoleDesignationOption");
            let BranchAns = branchopt.filter(x => x.value == e.branch)[0]
            let jobRoleDesignationSelected = jobRoleDesignationOption.filter(x => x.value == e.locationid)[0]
            // console.log(BranchAns, "BranchAns");
            // console.log(jobRoleDesignationSelected, "jobRoleDesignationSelected");
            if (BranchAns) {
                this.setState({ BranchAns, jobRoleDesignationSelected });
            }
        }
    };

    onUpdate = async () => {
        let { type } = this.state;
        const { downlineSelected } = this.state;

        // get all the state value and assign the variable
        let id = this.state.editid;

        let usertypeSelected = this.state.usertypeSelected;
        let serviceId = this.state.serviceId;
        let userIndex = this.state.userIndex;
        let userName = this.state.userName;
        let mobileNumber = this.state.mobileNumber;
        let email = this.state.email;
        let imgupload = this.state.imgupload;
        let locationSelected = this.state.locationSelected;
        let jobrole = this.state.jobRoleDesignationSelected;
        let d_of_j = this.state.selectedDate;
        let usermake = this.state.usermake;


        // let downline = this.state.downlineSelected;
        /**
         * create a new json array with the update value from the form
         * this array will be passed to the backend for updating the user
         */

        let selectedValue = "";
        let selectedValue1 = "";
        // if (downlineSelected) {
        //     const value = downlineSelected.map(
        //         values => (selectedValue += `${values.value},`)
        //     );
        //     selectedValue = selectedValue.replace(/,\s*$/, "");
        // }
        if (usermake) {
            const value = usermake.map(
                values => (selectedValue1 += `${values.value},`)
            );
            selectedValue1 = selectedValue1.replace(/,\s*$/, "");
        }
        let loca = locationSelected ? locationSelected.value : null;
        if (this.state.UserPassword) {
            this.CheckPassword(this.state.UserPassword)
        }

        const formData = new FormData();

        formData.append("customerId", this.state.customerId);
        formData.append("serviceId", serviceId);
        formData.append("image", imgupload);
        console.log(this.state.BranchAns, this.state.BranchAns);
        if (this.state.BranchAns && this.state.BranchAns.value) {
            formData.append("sub_loc_id", this.state.BranchAns.value);
        }
        if (type === "remap") {
            formData.append("userid", id);
            formData.append("status", "active");
            formData.append("userType", usertypeSelected.value);
            formData.append("userportid", jobrole.value);
            formData.append("reportPort", loca);
            formData.append("downline", selectedValue);
            formData.append("d_of_j", d_of_j);

            formData.append("orderBy", usertypeSelected.orderBy);
        } else {
            formData.append("userType", usertypeSelected.value);
            formData.append("userName", userName);
            formData.append("mobileNumber", mobileNumber);
            formData.append("email", email);
            formData.append("withoutmd5", this.state.UserPassword);
            formData.append("userMake", selectedValue1);

            // formData.append("userportid", jobrole.value);
            if (jobrole) {
                formData.append("locationid", jobrole.value);
            }
            if (locationSelected) {
                formData.append("reportPort", locationSelected.value);
            }
            if (downlineSelected) formData.append("downline", downlineSelected.value);
        }

        console.log([...formData]);

        let userArray = {};
        userArray.userName = userName;
        userArray.mobileNumber = mobileNumber;
        userArray.email = email;
        // get all the user
        const previousData = [...this.state.data];
        // get only the edit user from the list of user

        try {
            let result = null;
            if (type === "remap") {
                result = await UserData.adminuserRemapInsert(
                    "tbl_user_remap",
                    formData
                );
            } else {
                result = await UserData.updateadminuserInsert(id, formData);
            }
            if (result) {
                await this.setState({
                    alertVisible: true,
                    userName: "",
                    usermake: "",
                    mobileNumber: "",
                    email: "",
                    userIndex: "",
                    errorUserName: "",
                    errorMobileNumber: "",
                    errorEmail: "",
                    imgupload: "",
                    logoname: "",
                    jobrole: "",
                    downlineSelected: "",
                    locationSelected: "",
                    jobRoleDesignationSelected: "",
                    BranchAns: "",
                    selectedDate: "",
                    downlineOption: [],
                    LocaOption: [],
                    jobRoleDesignationOption: [],
                    currentmail: ''
                });
                this.userList();
                // console.log(result);
                // const getData = { ...previousData[userIndex] };
                // const id = getData.id;
                // getData.userName = userName;
                // getData.mobileNumber = mobileNumber;
                // getData.email = email;
                // getData.image = result.data.image;
                // // get all the user except the edit user
                // const data = previousData.filter(value => value.id !== id);
                // data.splice(userIndex, 0, getData); // update the user value in the list at particular index
                //
                //   data,
                //   // alertVisible: true,
                //   userName: "",
                //   mobileNumber: "",
                //   email: "",
                //   userIndex: "",
                //   errorUserName: "",
                //   errorMobileNumber: "",
                //   errorEmail: "",
                //   imgupload: "",
                //   logoname: "",
                //   jobrole: "",
                //   downlineSelected: "",
                //   locationSelected: "",
                //   jobRoleDesignationSelected: "",
                //   selectedDate: "",
                //   downlineOption: [],
                //   LocaOption: [],
                //   jobRoleDesignationOption: []
                // });
                setTimeout(() => this.setState({ alertVisible: false }), 3000);
            }
        } catch (error) {
            this.setState({
                data: previousData
            });
            console.log(error);
        }
    };

    buttonDeletes = value => {
        this.setState({});
        confirmAlert({
            title: "Confirmation to Delete",
            message: "Are you sure you want to delete this?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => this.buttonDelete(value)
                },
                {
                    label: "No"
                }
            ]
        });
    };
    buttonDelete = async value => {
        const id = value.id;
        const previousData = [...this.state.data];
        const data = previousData.filter(value => value.id !== id);
        this.setState({
            data,
            formAlertdelete: true,
            textalert: "Selected Mentor Deleted",
            color: "danger"
        });
        setTimeout(() => this.setState({ formAlertdelete: false }), 3000);
        try {
            let result = await CmsContent.getSingleConditionedValue(
                "tbl_user_web",
                "id",
                id,
                "Delete"
            );
            if (result) {
                const datas = previousData.filter(delelteid => delelteid.id !== id);
                this.userList();
            }
        } catch (error) {
            console.log(error);
        }
    };
    reset = async () => {
        console.log('reset called');
        await this.setState({
            button: "Submit",
            userIndex: "",
            userName: "",
            mobileNumber: "",
            email: "",
            imgupload: "",
            usermake: "",
            errorusermake: "",
            logoname: "",
            errorUserName: "",
            errorMobileNumber: "",
            errorEmail: "",
            usertypeSelected: "",
            // alertVisible: true,
            selectedDate: "",
            updateData: false,
            disableUsertype: false,
            jobRoleDesignationSelected: "",
            BranchAns: "",
            locationSelected: "",
            downlineSelected: [],
            downlineOption: [],
            UserPassword: '',
            LocaOption: [],
            jobRoleDesignationOption: [],
            disableReportee: false,
            disableReportTo: false,
            disableJobrole: false
        });
    };
    onDismiss = () => {
        this.setState({ formAlertdelete: false });
    };
    programSelect = e => {
        this.setState({ categorySelected: e, groupid: e.value });
    };
    submited = () => {
        confirmAlert({
            title: "Confirm to Delete",
            message: "Are you sure to do this.",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => alert("Click Yes")
                },
                {
                    label: "No",
                    onClick: () => alert("Click No")
                }
            ]
        });
    };

    setRedirect = () => {
        this.setState({
            redirect: true
        });
    };
    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to="/DF/MappingToGroup" />;
        }
    };

    viewimage = d => {
        // console.log(d);
        if (d.original.image) {
            let link = ACCESS_POINT + "/superAdmin/file?fileurl=" + d.original.image;
            return (
                <center>
                    <img
                        className="navbar-brand-full"
                        src={link}
                        width="30"
                        height="30"
                        alt="Logo"
                    />
                </center>
            );
        } else {
            return <center>-</center>
        }
    };

    usertypeSelect = async e => {
        this.setState({ usertypeSelected: e, BranchAns: "" })
        console.log(this.state.data, 'this.state.data')
        const { usertype, location, report, data, usertypeSelected } = this.state;
        let jobRoleDesignationOption = [];
        let val = [];
        let order = []
        let downlineOption = []
        usertype.map((ival, i) => {
            if (e) {
                if (parseInt(e.orderBy) == 9 || parseInt(e.orderBy) == 8) order = [3, 2]
                else if (parseInt(e.orderBy) == 2 || parseInt(e.orderBy) == 4 || parseInt(e.orderBy) == 12) order = [1]
                else if (parseInt(e.orderBy) == 11) { order = [12] }
                else if (parseInt(e.orderBy) == 6 || parseInt(e.orderBy) == 3 || parseInt(e.orderBy) == 14|| parseInt(e.orderBy) == 15) order = [2]
                else if (parseInt(e.orderBy) == 5) order = [6]
                if (order.includes(ival.orderBy)) {
                    this.state.data.map((jval, j) => {
                        if (jval.userType == ival.value) {
                            // console.log(jval, "jval");
                            let type = this.getValueFromArray(
                                jval.userType,
                                usertype
                            );
                            let loca = this.getValueFromArray(
                                jval.locationId,
                                location
                            );
                            downlineOption.push({
                                value: jval.id,
                                label: type + " - " + loca + " - " + jval.userName,
                                locationid: jval.locationid,
                                branch: jval.sub_loc_id
                            });

                        }
                    });
                }
            }
        });

        if (location) {
            jobRoleDesignationOption = location
        }

        await this.setState({
            jobRoleDesignationOption,
            jobRoleDesignationSelected: "",
            locationSelected: "",
            downlineOption,
            downlineSelected: []
        });
    }

    RoleLocation = async (e, type) => {
        let { branchopt, User_Type } = this.state
        // console.log(e, type, User_Type);
        if (e && type == "branch") {
            await this.setState({
                BranchAns: e
            });
        }
        else if (e && type == "location") {
            let branchopt_Filter = []
            branchopt_Filter = branchopt.filter(x => x.locationid == e.value)
            await this.setState({
                jobRoleDesignationSelected: e, branchopt_Filter, BranchAns: ""
            });
        }
        else {
            if (e) {
                await this.setState({
                    jobRoleDesignationSelected: e
                });
            }
        };
    }
    jobRoleDesignation = async e => {
        let LocaOption = [];
        let downlineOption = [];
        // console.log(e, "ee")
        if (e) {
            // console.log(e, "entered");
            await this.setState({
                jobRoleDesignationSelected: e
            });
        };
    }
    locationSelect = async e => {
        await this.setState({ locationSelected: e });
    };

    fromDateSelect = e => {
        this.setState({ selectedDate: e.target.value });
    };

    render() {
        const {
            User_Type,
            formAlertdelete,
            counting,
            usertype,
            jobRoleDesignationOption,
            errorType,
            errorRep,
            LocaOption,
            usertypeSelected,
            jobRoleDesignationSelected,
            locationSelected,
            categorySelected,
            updateData,
            textalert,
            disableUsertype,
            downlineOption,
            downlineSelected,
            selectedDate,
            disableReportee,
            disableReportTo,
            disableJobrole,
            errordoj,
            errorTypeLoca,
            type,
            UserPassword
        } = this.state;
        let userType = localStorage.getItem("userType");
        // console.log(this.state.data, 'dataaaa')
        // console.log(this.state.excelHead, 'excelll');
        // console.log(jobRoleDesignationSelected, "jobRoleDesignationSelected")

        const userData = {
            userName: this.state.userName,
            mobileNumber: this.state.mobileNumber,
            email: this.state.email,
            selectedDate: selectedDate,
            UserPassword: UserPassword
        };

        const errorData = {
            errorType: this.state.errorType,
            errorRep: this.state.errorRep,
            errorUserName: this.state.errorUserName,
            errorMobileNumber: this.state.errorMobileNumber,
            errorEmail: this.state.errorEmail,
            errordoj: errordoj
        };

        let title =
            type === "remap"
                ? `Remap ${this.state.userName}`
                : this.state.button === "Submit"
                    ? `Add User`
                    : `Edit User`;

        return (
            <main className="main my-4">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h1>Add User</h1>
                                </div>

                                <div className="card-body">
                                    <div className="row form-group">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-7">
                                            <Alert
                                                className="badge-content"
                                                color={this.state.color}
                                                isOpen={formAlertdelete}
                                                toggle={this.onDismiss}
                                            >
                                                {/* Deleted Successfully */}
                                                {textalert}
                                            </Alert>
                                        </div>
                                        <div className="col-sm-3" />
                                    </div>
                                    <div className="row form-group">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2"></div>
                                        <div className="col-sm-5">

                                        </div>
                                        <div className="col-sm-3" />
                                    </div>

                                    <LoginModal
                                        buttonTitle="Add User"
                                        title={title}
                                        id="customer"
                                        onClick={this.reset}
                                        extraStyle={{
                                            width: "125%",
                                            position: "relative",
                                            top: 90
                                        }}
                                        bodyText={
                                            <div>
                                                <div className="row form-group">
                                                    <div className="col-sm-12">
                                                        <div className="row ">
                                                            <div className="col-sm-3">
                                                                <label for="exampleInputEmail1">Usertype</label>
                                                            </div>
                                                            <div className="col-sm-9">
                                                                <SingleSelect
                                                                    options={usertype}
                                                                    // disabled={disableUsertype}
                                                                    handleChange={this.usertypeSelect}
                                                                    selectedService={usertypeSelected}
                                                                    placeholder={"User Type"}
                                                                />
                                                                <span className="error-shows">{errorType}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {usertypeSelected && [1, 10].includes(usertypeSelected.orderBy) ? null : <div className="row form-group">
                                                    <div className="col-sm-12">
                                                        <div className="row ">
                                                            <div className="col-sm-3">
                                                                <label for="exampleInputEmail1">
                                                                    Reportees
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-9">
                                                                <SingleSelect
                                                                    options={downlineOption}
                                                                    disabled={disableReportee}
                                                                    handleChange={this.downline}
                                                                    selectedService={downlineSelected}
                                                                    placeholder={"Down Line"}
                                                                />
                                                                <span className="error-shows">{""}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}
                                                {
                                                    usertypeSelected && [10, 12].includes(usertypeSelected.orderBy) ? null :
                                                        <div className="row form-group">
                                                            <div className="col-sm-12">
                                                                <div className="row ">
                                                                    <div className="col-sm-3">
                                                                        <label for="exampleInputEmail1">
                                                                            Location
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-sm-9">
                                                                        <SingleSelect
                                                                            options={jobRoleDesignationOption}
                                                                            // disabled={disableJobrole}
                                                                            handleChange={(e) => this.RoleLocation(e, "location")}
                                                                            selectedService={this.state.jobRoleDesignationSelected}
                                                                            placeholder={"Location"}
                                                                        />
                                                                        <span className="error-shows">
                                                                            {errorTypeLoca}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>}


                                                {
                                                    // jobRoleDesignationSelected && jobRoleDesignationSelected.value &&

                                                    usertypeSelected && usertypeSelected.orderBy && (usertypeSelected.orderBy == 2 || usertypeSelected.orderBy == 3 || usertypeSelected.orderBy == 8 || usertypeSelected.orderBy == 9 || usertypeSelected.orderBy == 14)
                                                        ?
                                                        <div className="row form-group">
                                                            <div className="col-sm-12">
                                                                <div className="row ">
                                                                    <div className="col-sm-3">
                                                                        <label for="exampleInputEmail1">
                                                                            Branch
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-sm-9">
                                                                        <SingleSelect
                                                                            options={this.state.branchopt_Filter}
                                                                            handleChange={(e) => this.RoleLocation(e, "branch")}
                                                                            selectedService={this.state.BranchAns}
                                                                            placeholder={"Branch"}
                                                                        // disabled={usertypeSelected.orderBy == 3 ? true : false}
                                                                        />
                                                                        <span className="error-shows">
                                                                            {errorTypeLoca}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        ""
                                                }
                                                <div className="row form-group">
                                                    <div className="col-sm-12">
                                                        <div className="row ">
                                                            <div className="col-sm-3">
                                                                <label for="exampleInputEmail1">
                                                                    Date Of Joining
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-9">
                                                                <input
                                                                    type="date"
                                                                    id="myDate"
                                                                    className="form-control"
                                                                    onChange={this.fromDateSelect}
                                                                    value={selectedDate}
                                                                />
                                                                <span className="error-shows">{errordoj}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {usertypeSelected && [1, 4, 5, 6, 11, 10, 12].includes(usertypeSelected.orderBy) ? null : (<div className="row form-group">
                                                    <div className="col-sm-12">
                                                        <div className="row ">
                                                            <div className="col-sm-3">
                                                                <label htmlFor="exampleInputEmail1">User Brand</label>
                                                            </div>
                                                            <div className="col-sm-9">
                                                                <MultiSelect
                                                                    name="make"
                                                                    placeholder="Select..."
                                                                    options={this.state.NewMakeData}
                                                                    handleChange={d => this.handleDescription(d)}
                                                                    selectedService={this.state.usermake}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>)}
                                                <AddUser
                                                    NewMakeData={this.state.NewMakeData}
                                                    newmake={this.state.usermake}
                                                    handleDescription={this.handleDescription}
                                                    userData={userData}
                                                    errorData={errorData}
                                                    userType={User_Type}
                                                    UserPassword={UserPassword}
                                                    serviceId={this.state.serviceId}
                                                    handleInput={this.handleUserInput}
                                                    handleSubmit={this.handleSubmit}
                                                    onDismiss={this.onDismiss}
                                                    alertVisible={this.state.alertVisible}
                                                    formAlertdelete={this.state.formAlertdelete}
                                                    checkMobileNumber={this.checkMobileNumber}
                                                    checkEmail={this.checkEmail}
                                                    updateData={updateData}
                                                    updateEmail={this.state.updateEmail}
                                                    uploadimg={this.uploadimg}
                                                    logoname={this.state.logoname}
                                                />
                                            </div>
                                        }
                                    />
                                    <br></br>

                                    {this.state.data && this.state.data.length > 0 ? (
                                        <PDFDownloadLink
                                            style={{ color: "white" }}
                                            document={
                                                <LearnerPdf
                                                    title={this.state.title}
                                                    header={this.state.header}
                                                    data={this.state.data}
                                                />
                                            }
                                            fileName="DFAddUsers.pdf"
                                        >
                                            {({ blob, url, loading, error }) =>
                                                loading ? "Loading document..." : "Download Pdf"
                                            }
                                            <button className="btn btn-warning">PDF</button>
                                        </PDFDownloadLink>
                                    ) : null}
                                    {this.state.data && this.state.data.length ? (
                                        <CSVLink
                                            data={this.state.data}
                                            headers={this.state.excelHead}
                                        >
                                            <button
                                                className="btn btn-success"
                                                style={{ marginLeft: "5px" }}
                                            >
                                                Excel
                                            </button>
                                        </CSVLink>
                                    ) : null}
                                    <br></br>
                                    <br></br>
                                    <span style={{ marginLeft: "5px" }}>
                                        Total of {Object.keys(this.state.data).length} records
                                    </span>
                                    <br></br>
                                    {this.state.loading ? <div className="text-center"> <i
                                        className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                                    ></i></div> : this.state.data.length ? (
                                        <Datatable
                                            data={this.state.data}
                                            columnHeading={this.column}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

export default DfCarsAddUsers;
