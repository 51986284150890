import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
// import DfCarsEnquiry from "..//DfCarsEnquiry";
import moment from "moment";
import Datatable from "../../../../components/Datatable/Datatable";
import LoginModal from "../../../../components/Modal/Modal";
import PaymentModal from '../SalesMaster/ContractPaymentProcess'
import { ACCESS_POINT } from "../../../../config";

class AccContract extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            customerId: localStorage.getItem("userId"),
            userid: JSON.parse(localStorage.getItem("userlog")),
            EnquiryData: [],
            MakeOptions: [],
            WorkData: [],
            alertVisible: false,
            isEdit: false,
            BilluploadChanges: 0,
            EditData: [],
            SelectCurrentStatus: [{ label: "Work yet start", value: 0 }, { label: "Work in progress", value: 1 }, { label: "Waiting for parts", value: 2 }, { label: "Completed", value: 3 }],

            column: [
                {
                    Header: "ID",
                    width: 150,
                    accessor: "id"
                },
                {
                    Header: "Prospect",
                    width: 150,
                    accessor: "name"
                },
                {
                    Header: "Date",
                    accessor: "created_At"
                },
                {
                    Header: "Contact",
                    width: 150,
                    accessor: "mobile",
                    Cell: d => this.Call(d.original.mobile)
                },
                {
                    Header: "Make",
                    accessor: "make",
                    width: 150,
                    Cell: (d) => this.getValueFromArray(d.original.make, this.state.MakeOptions)

                },
                {
                    Header: "Sales name ",
                    width: 150,
                    accessor: "salesname"
                },
                {
                    Header: "Approved By",
                    width: 150,
                    accessor: "approveduser"
                },
                {
                    Header: "Accounts Approved ",
                    width: 150,
                    accessor: "accounts_Approvedname"
                },
                {
                    Header: "Payment Status",
                    minWidth: 100,
                    Cell: d => this.Status(d)

                },
                {
                    Header: "RF Work",
                    accessor: "mobile",
                    width: 150,
                    Cell: d => this.WorkStatus(d)
                },
                {
                    Header: "Contract",
                    accessor: "mobile",
                    width: 150,
                    Cell: d => this.Document(d)
                },
                {
                    Header: "Approval",
                    accessor: "mobile",
                    width: 200,
                    Cell: d => this.Approval(d)
                }
            ]
        };
    }
    Status = (d) => {
        if (d.original.FormFilled == "no") {
            return (
                <button
                    type="button"
                    className="btn btn-sm btn-secondary text-center"
                    style={{ minWidth: "100px" }}
                >
                    Not Filled
                </button>
            )
        } else {
            return (<button className="btn btn-sm btn-primary text-center" style={{ cursor: 'pointer' }}
                onClick={() => this.editdatareset(d)}
            >Payment</button>)
        }
    }
    editdatareset = async (d) => {
        await this.setState({ EditData: null, formid: null, Index: null, isEdit: false })
        await this.Editstatus(d)
    }
    Editstatus = async (d) => {
        if (d.original.paymentStatus == 0) {
            await this.setState({ isEdit: true })
        } else {
            await this.setState({ isEdit: false })
        }
        await this.setState({ EditData: d.original, formid: d.original.formid, Index: d.index })
        window.$('#Payment').modal("show");
    }
    WorkStatus = d => {
        if (d.original.commit == 1) {
            if (parseInt(d.original.status1) == 3) {
                return (
                    this.View(d)
                );
            }
            else {
                return (
                    <span>{this.state.SelectCurrentStatus.map((ival, i) => {
                        if (d.original.status1 == ival.value) {
                            return (
                                <button
                                    type="button"
                                    className="btn btn-sm btn-secondary"
                                >
                                    {ival.label}
                                </button>
                            )
                        }
                    })
                    }</span>
                )
            }
        }
        else {
            return (
                <button
                    type="button"
                    className="btn btn-sm btn-secondary text-center"
                    style={{ minWidth: "75px" }}
                >
                    Normal
                </button>
            )
        }
    };
    pricereset = async () => {
        await this.setState({ pricealert: "", price: "", BilluploadChanges: 0, isToggleOn: false })
    }
    BilluploadChanges = async () => {
        await this.setState(state => ({ isToggleOn: !state.isToggleOn }));
        if (this.state.isToggleOn === true) {
            await this.setState({ BilluploadChanges: 1 })
        } else (
            await this.setState({ BilluploadChanges: 0 })
        )
    };
    StatusAutoPopulate = async (d) => {

        if (d && d) {
            await this.setState({ WorkData: d })

            let images = this.state.images;
            images = JSON.parse(d.bill);
            let img = [];

            if (images != null) {
                images.map((ival, i) => {
                    if (ival) {
                        let spliting = ival.split(".");
                        if (['pdf', 'doc', 'xlsx', 'csv'].includes(spliting[1])) {
                            let a = null
                            a = "false/pdfIconTrans_1599478202382.png";
                            let wait = img.push(<img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + a}
                                style={{
                                    width: "200px", height: "200px", display: "inline-flex", padding: "10px"
                                }}
                                onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }} />
                            )
                        } else {
                            let wait = img.push(<img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + ival}
                                style={{
                                    width: "200px", height: "200px", display: "inline-flex", padding: "10px"
                                }}
                                onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }} />
                            )
                        }
                    }
                })
                this.setState({ img })
            }

        }
    }
    View = (d) => {
        let { orderdata, users, WorkData, BilluploadChanges } = this.state
        console.log(WorkData, "WorkData");
        let data = d.original
        if (d) {
            return (
                <LoginModal
                    buttonTitle={data && data.FormFilled == "approved" ? "Approved" : "Completed"
                    }
                    extraStyle={{ width: 'auto' }}
                    title={`Evaluator Estimation ${WorkData && WorkData.name}`
                    }
                    extraClass={data && data.FormFilled == "approved" ? "btn btn-sm btn-success text-center" : "btn btn-sm btn-primary text-center"}
                    onClick={() => this.StatusAutoPopulate(data)}
                    id="evaluation"
                    reset={() => this.pricereset()}
                    bodyText={
                        < div >
                            < section className="container-fluid" >
                                <div className="row">
                                    <div className="col-sm-12">
                                        {this.state.BilluploadChanges === 0 && (<table class="table table-striped">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">NAME</th>
                                                    <td>{WorkData && WorkData.name}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">MOBILE</th>
                                                    <td>{WorkData && this.Call(WorkData.mobile)}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">EMAIL</th>
                                                    <td>{WorkData && WorkData.email}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">REG NO</th>
                                                    <td>{WorkData && WorkData.reg_no && WorkData.reg_no ? WorkData.reg_no : WorkData.regno}</td>
                                                </tr>
                                                {WorkData && WorkData.make && <tr>
                                                    <th scope="row">MAKE</th>
                                                    <td>{this.getValueFromArray(WorkData && WorkData.make, this.state.MakeOptions)}</td>
                                                </tr>
                                                }
                                                {WorkData && WorkData.model && <tr>
                                                    <th scope="row">MODAL</th>
                                                    <td>{this.getValueFromArray(WorkData && WorkData.model, this.state.modelopt)}</td>
                                                </tr>}
                                                {WorkData && WorkData.color && <tr>
                                                    <th scope="row">COLOR</th>
                                                    <td>{this.getValueFromArray(WorkData && WorkData.color, this.state.coloropt)}</td>
                                                </tr>}

                                                <tr>
                                                    <th scope="row">Manager Name</th>
                                                    <td>{WorkData && WorkData.managername && WorkData.managername}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Employee Name</th>
                                                    <td>{WorkData && WorkData.jobroleName && WorkData.jobroleName}</td>
                                                </tr>

                                                <tr>
                                                    <th scope="row">RF incharge </th>
                                                    <td>{WorkData && WorkData.RFInchargeName && WorkData.RFInchargeName}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">RF Technician</th>
                                                    <td>{WorkData && WorkData.RFTechnicianName && WorkData.RFTechnicianName}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Inspection Type</th>
                                                    <td>{WorkData && WorkData.Data_Inspection_type && WorkData.Data_Inspection_type}</td>
                                                </tr>
                                                {WorkData && WorkData.Data_Inspection_type && WorkData.Data_Inspection_type == "Commit Work" ?
                                                    "" :
                                                    <>
                                                        <tr>
                                                            <th scope="row">TOTAL RECONDITIONING ESTIMATE (B)</th>
                                                            <td>{WorkData && WorkData.recondition_est && WorkData.recondition_est}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">STANDARD SELLING PRICE (A)</th>
                                                            <td>{WorkData && WorkData.std_sell_price && WorkData.std_sell_price}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">MARGIN (C)</th>
                                                            <td>{WorkData && WorkData.margin && WorkData.margin}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">WARRANTY POOL (D)</th>
                                                            <td>{WorkData && WorkData.warranty_pool && WorkData.warranty_pool}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">GST (E)</th>
                                                            <td>{WorkData && WorkData.gst && WorkData.gst}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">EXCHANGE BONUS (F)</th>
                                                            <td>{WorkData && WorkData.exchange_bonus && WorkData.exchange_bonus}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">BODY SHOP (G)</th>
                                                            <td>{WorkData && WorkData.body_shop && WorkData.body_shop}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">TRADE-IN PRICE (A-B-C-D-E+F+G)</th>
                                                            <td>{WorkData && WorkData.trade_in_price && WorkData.trade_in_price}</td>
                                                        </tr>
                                                    </>}
                                                {WorkData && WorkData.FormFilled == "approved" ?
                                                    <React.Fragment>
                                                        <tr>
                                                            <th scope="row">Actual RF Cost</th>
                                                            <td>{WorkData && WorkData.rfcost && WorkData.rfcost}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">PRICE</th>
                                                            <td>{WorkData && WorkData.price && WorkData.price}</td>
                                                        </tr>
                                                    </React.Fragment>
                                                    :
                                                    ""
                                                }
                                            </tbody>

                                        </table>)}
                                        <div class="d-flex justify-content-center">
                                            <button onClick={this.BilluploadChanges}
                                                className="btn btn-sm btn-primary btn-sm " >   {this.state.isToggleOn ? "Close Bill Upload Images" : "View Bill Upload Images"}
                                            </button>
                                        </div>
                                        {
                                            this.state.BilluploadChanges === 1 && (
                                                <div>
                                                    {this.state.img ? this.state.img : null}

                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </ section >

                        </div >
                    }
                />
            )
        } else {
            return true
        }
    }
    Approval = d => {
        if (d.original.accounts_approval == 0) {
            return (
                <button
                    type="button"
                    style={{ minWidth: "150px", maxWidth: "150px" }}
                    className="btn btn-sm btn-primary text-center"
                    onClick={() => this.alert_validation(this.Approved_by_contract, d)}
                >
                    Waiting for Approval
                </button>
            );
        }
        else if (d.original.accounts_approval == 1) {
            return (
                <button
                    type="button"
                    style={{ minWidth: "150px", maxWidth: "150px" }}
                    className="btn btn-sm btn-success text-center"
                >
                    Approved
                </button>
            );
        } else {
            return (
                <button
                    type="button"
                    style={{ minWidth: "150px", maxWidth: "150px" }}
                    className="btn btn-sm btn-danger text-center"
                    onClick={() => this.alert_validation(this.Approved_by_contract, d)}
                >
                    Rejected by Accounts
                </button>
            );
        }
    };

    Document = d => {
        return (
            <button
                type="button"
                className="btn btn-sm btn-warning text-center"
                onClick={() => this.view_f_contract(d)}
            >
                View Form
            </button>
        );
    };
    view_f_contract = d => {
        window.open(
            `/DFCars/AT_Booking_Confirmation_Reproof/${d.original.id}`,
            "_self"
        );
    };

    Approved_by_contract = async (d1, text = "yes") => {
        try {
            let d = d1.original;

            let sub = {};
            let userid = this.state.userid;
            sub.accounts_Approvedby = userid.id
            if (text == "yes") {
                sub.accounts_approval = 1
            } else {
                sub.accounts_approval = 2
            }

            const result = await cmsContent.updateMaster(
                "tbl_Booking_Confirmation_Reproof",
                d.formid,
                sub
            );
            if (result) {
                await this.Mainfunc()
            }
        } catch (error) {
            console.log(error);
        }

    };

    async componentDidMount() {
        try {
            await this.Mainfunc()
            let allOpt = await this.decryption('getcar')
            if (allOpt) {
                await this.setState({
                    MakeOptions: allOpt.makeopt,
                    coloropt: allOpt.coloropt,
                    variantopt: allOpt.variantopt,
                    modelopt: allOpt.modelopt
                })
            }

        } catch (error) {
            console.log(error);
        }
    }
    Mainfunc = async () => {
        try {
            this.setState({ loading: true })
            let results = await cmsContent.BUYDFCARZ(this.state.userid.id, 'AccountsContract')
            if (results) {
                // console.log(results, 'vvresults');
                await this.setState({ Data: results.buying, loading: false })
            }
        } catch (error) {
            console.log(error);
            this.setState({ loading: false })
        }
    }
    resetModal = (Modalid) => {
        window.$(`#${Modalid}`).modal("hide");
        this.setState({
            EditData: [],
            isEdit: false
        })
    }
    render() {
        console.log(this.state.EditData, "EditData");
        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h1>Contract</h1>
                                    </div>
                                    <div className="card-body">
                                        <div className="row form-group">
                                            <div className="col-sm-4">
                                                <LoginModal
                                                    title="Payment Process"
                                                    extraStyle={{ width: 'auto' }}
                                                    id="Payment"
                                                    buttonStyle={{ backgroundColor: "white", border: "white" }}
                                                    // extraStyle={{ width: 1200, marginLeft: -200 }}
                                                    bodyText={
                                                        <PaymentModal
                                                            EditData={this.state.EditData}
                                                            these={this.Mainfunc}
                                                            isEdit={this.state.isEdit}
                                                            FullData={this.state.Data}
                                                            close={this.resetModal}
                                                            statusarray={[{ paymentfrom: "", paymentFrom: '', mop: '', modeofpayment: '', meterialnum: '', amount: "", paymentdate: '', index: 0, error: null }]}
                                                        />}
                                                />
                                            </div>
                                        </div>
                                        {this.state.loading ? <div className="text-center"> <i
                                            className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                                        ></i></div> : this.state.Data.length ? (
                                            <Datatable
                                                data={this.state.Data}
                                                columnHeading={this.state.column}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}
export default AccContract;
