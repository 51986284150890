import React, { Component } from "react";
import { Alert } from "reactstrap";
import LoginModal from "../../../../components/Modal/Modal";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import SingleSelect from "../../../../components/Form/SingleSelect";
import redisMiddleware from "../../../../MiddleWare/redisMiddleware";
import CmsContent from "../../../../MiddleWare/CmsContent";
import checklist350 from "./Dfcars350checklist.json";
import checklist111 from "./111_checkpoints.json";
import Checklist50 from './checklist40.json';
import { ACCESS_POINT } from "../../../../config/index";
import Category from "../../CMS/Category";
import preLoader from "../preloader.js";

class CERTIFICATION_350_CheckPoints extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      respect: [{ label: "Sir", value: 1 }, { label: "Madam", value: 1 }],
      answerJson: [],
      json: [],
      Totaljson: [],
      userid: JSON.parse(localStorage.getItem("userlog")),
      checkListJson: [],
      checklist111json: [],
      checklist50json: [],
      AnswerJSON50: [],
      interiorJson: [],
      exteriorJson: [],
      interiors: [
        "DASHBOARD",
        "INSTRUMENT PANEL",
        "SEATS/COVERS",
        "DOOR PADS",
        "FLOOR CARPET",
        "ROOF LININGS",
        "AVN",
        "MUSIC SYSTEM",
        "SWITCHES KNOBS",
        "ANTENNA",
        "POWER WINDOWS",
        "WIS WIPER & WASHER",
        "AIR CONDITIONER"
      ],
      interiorJson: [],
      exterior: [
        "SMOKE",
        "ENGINE OIL",
        "MISSING",
        "NOISE",
        "OVER HEATING",
        "PICK UP/POWER",
        "RADIATOR",
        "CONDITION",
        "SOOT DEPOSIT",
        "SHIFTING",
        "AXLES",
        "SUSPENSION",
        "STEERING",
        "CLUTCH",
        "BRAKES"
      ],
      bumper: [
        "FRONT",
        "BACK "

      ],
      bumperjson: [],
      lights: [
        "HEAD LAMPS RHS",
        "HEAD LAMPS LHS ",
        "TAIL LAMPS RHS   ",
        "TAIL LAMPS LHS   ",
        "INDICATOR-FRONT RHS   ",
        "INDICATOR-FRONT LHS   ",
        "INDICATOR-REAR RHS   ",
        "INDICATOR-REAR LHS   ",
        "DOOR MIRRORS RHS   ",
        "DOOR MIRRORS LHS   "

      ],
      lightjson: [],

      tyres: [
        "FRONT",
        "REAR"
      ],
      tyrejson: [],
      badsummary: [],
      updateCheckList: [],
      alertVisible: false,
      preLoader: true,
      innervalue: "",
      modaldata1: [],
      displayPoints: "",
      button: "Save",
      status: [
        { label: "Good", value: 1 },
        { label: "Average", value: 2 },
        { label: "Repair", value: 3 }
      ],
      yearfill: true,
      kmsfill: true
    };

  }
  MAinfunc = async () => {
    try {
      let { answerJson } = this.state
      if (this.props.cusid) {
        this.setState({ customerId: this.props.cusid, global: false });
      } else if (this.props.match.params.cusid) {
        this.setState({
          customerId: this.props.match.params.cusid,
          global: true
        });
      }

      let Get_data = await CmsContent.BUYDFCARZ(this.props.match.params.cusid, "Checklist")

      if (Get_data) {
        const checkpoint = await CmsContent.getFreedom(
          "*",
          "tbl_checklist350",
          `customerid = ${this.props.match.params.cusid}`,
          "id",
          "id"
        );
        console.log(Get_data, "this.props.Get_data.params.cusid", checkpoint, "API");
        let filesnap = []
        let checkListJson = [];
        let checklist111json = [];
        let AnswerJSON50 = [];
        let displayPoints;
        let checklist50json = [];
        if (Get_data.Prosperities && Get_data.Prosperities.make == 9) {
          let w1 = await checklist350.map((ival, i) => {
            checkListJson[i] = [];
            ival.data.map((jval, j) => {
              checkListJson[i].push({
                index: j,
                title: ival.title,
                value: jval.value,
                name: jval.name,
                status: null,
                remarks: null,
                estimate: null,
                logo: false,
                files: false,
                work: true,
                workProcess: false
              });
            });
          });
          await Promise.all(w1)
          displayPoints = 350
          this.setState({ displayPoints })
        } else if ((Get_data.kmsfill || Get_data.yearfill)) {
          console.log('log2');
          let w2 = await checklist111.map((ival, i) => {

            checklist111json[i] = [];
            ival.data.map((jval, j) => {
              checklist111json[i].push({
                index: j,
                title: ival.title,
                subtitle: ival.subtitle,
                value: jval.value,
                name: jval.name,
                status: null,
                remarks: null,
                estimate: null,
                logo: false,
                files: false,
                work: true,
                workProcess: false
              });
            });
          });
          await Promise.all(w2)
          displayPoints = 111
          this.setState({ displayPoints })
        }
        else {
          console.log('log3');
          let w3 = await Checklist50.map((ival, i) => {
            checklist50json[i] = [];
            filesnap[i] = []
            ival.data.map((jval, j) => {
              checklist50json[i].push({
                index: j,
                title: ival.title,
                value: jval.value,
                name: jval.name,
                status: null,
                remarks: null,
                estimate: null,
                logo: false,
                files: false,
                work: true,
                workProcess: false
              });
              filesnap[i][j] = []
            });
          });
          await Promise.all(w3)
          displayPoints = 50
          this.setState({ displayPoints })
        }
        await this.setState({ checkListJson, checklist111json, checklist50json });
        await this.setState({
          interiorJson: Get_data.interiorJson,
          exteriorJson: Get_data.exteriorJson,
          bumperjson: Get_data.bumperjson,
          lightjson: Get_data.lightjson,
          tyrejson: Get_data.tyrejson,
          kmsfill: Get_data.kmsfill,
          yearfill: Get_data.yearfill,
          data: Get_data.formQues,
          answerJson: Get_data.answerJson,
          formQues: Get_data.formQues,
          Prosperities: Get_data.Prosperities,
          yearopt: Get_data.yearopt,
          inopt: Get_data.inopt,
          makeopt: Get_data.makeopt,
          modelopt: Get_data.modelopt,
          badsummary: Get_data.badsummary,
        })
        if (Get_data && Get_data.Prosperities && Get_data.Prosperities.from == 'documentation') {
          await this.evalmodal()
        }
        let isfillform = true

        if (Get_data && checkpoint && checkpoint.data.length > 0 && Get_data.badsummary) {
          isfillform = false

          // if (Get_data.Prosperities && Get_data.Prosperities.from == 'contract') {
          //   await this.badsummarymodal(Get_data.badsummary)
          // }
          // else {
          await this.badsummarymodal()
          //   await this.evalmodal()
          // }
          await this.setState({ checkpoint: checkpoint.data });
          await this.form_filled();
        }
        this.setState({ isfillform });
        if (isfillform) {

          if (localStorage.getItem("formFillData")) {
            let formFillData = JSON.parse(localStorage.getItem("formFillData"))
            const Data = formFillData.filter(e => e.prosid == this.state.customerId && e.ref == '350_CHECKLIST')
            if (Data && Data.length) {
              answerJson = Data[0].data
              if (displayPoints) {
                if (displayPoints == 350) {
                  checkListJson = Data[0].checklist
                  this.setState({ checkListJson })
                } else if (displayPoints == 111) {
                  checklist111json = Data[0].checklist
                  this.setState({ checklist111json })

                } else {
                  checklist50json = Data[0].checklist
                  this.setState({ checklist50json })
                }

              }
              this.setState({ answerJson })
            }
          }
        }
      }
      await this.process();

    } catch (error) {
      console.log(error);
    }
  }
  badsummarymodal = async (data) => {
    let { badsummary } = this.state
    let modaldata1 = []
    await modaldata1.push(
      <div className="bgcolor" style={{ width: "100%", overflow: "auto" }}>
        <div class="set-form">
          <table id="myTable" class="table table-bordered">
            <tr>
              <th>Name</th>
              <th>Status</th>
              <th>Remarks</th>
              <th>Estimate</th>
            </tr>
            {badsummary.map((item, i) => {
              return (
                <tbody ><tr>
                  <td className="text-left " >
                    {item.status && item.status == "Repair" ?
                      <p style={{ color: "red", fontSize: "18px" }}>{i + 1 + ")"} {item.name}</p>
                      :
                      <p style={{ fontSize: "18px" }}>{i + 1 + ")"} {item.name}</p>
                    }
                  </td>

                  <td>
                    {item.status && item.status == "Repair" ?
                      <label
                        style={{ color: "red", fontSize: "18px" }}
                      >{
                          item.status
                        }</label>
                      :
                      <label
                        style={{ fontSize: "18px" }}
                      >{
                          item.status
                        }</label>
                    }
                  </td>
                  <td>
                    {item.status && item.status == "Repair" ?
                      <p style={{ color: "red", fontSize: "18px" }}>{item.remarks && item.remarks != null ? item.remarks : "-"}</p>
                      :
                      <p style={{ fontSize: "18px" }}>{item.remarks && item.remarks != null ? item.remarks : "-"}</p>
                    }
                  </td>
                  <td>
                    {item.status && item.status == "Repair" ?
                      <p style={{ color: "red", fontSize: "18px" }}>{item.estimate && item.estimate != null ? item.estimate : "-"}</p>
                      :
                      <p style={{ fontSize: "18px" }}>{item.estimate && item.estimate != null ? item.estimate : "-"}</p>
                    }
                  </td>
                </tr>
                </tbody>
              );
            })}
          </table>
        </div>
      </div >

    )
    await this.setState({ modaldata1 })
  }
  evalmodal = async (int, ext) => {
    let modaldata = []
    let { interiorJson, exteriorJson, lightjson, tyrejson, bumperjson } = this.state;
    if (this.state.interiors.length) {
      await modaldata.push(
        <div className="bgcolor" style={{ width: "100%", overflow: "auto" }} >
          <div class="set-form">
            <table id="myTable" class="table table-bordered">
              <tr>
                <th>SI NO</th>
                <th>INTERIORS</th>
                <th>EVALUATION</th>
                <th>REMARKS</th>
                <th>ESTIMATE</th>
              </tr>
              {this.state.interiors.map((ival, ind) => {
                return (
                  <tbody ><tr>
                    <td>
                      <p style={{ fontSize: "18px", color: interiorJson[ind] && interiorJson[ind].evaluation == "Repair" ? "red" : "black" }}>{ind + 1}) </p>
                    </td>
                    <td>
                      <p style={{ fontSize: "18px", color: interiorJson[ind] && interiorJson[ind].evaluation == "Repair" ? "red" : "black" }}>{ival}</p>
                    </td>
                    <td>
                      <p style={{ fontSize: "18px", color: interiorJson[ind] && interiorJson[ind].evaluation == "Repair" ? "red" : "black" }}>{interiorJson[ind] && interiorJson[ind].evaluation
                        ? interiorJson[ind].evaluation
                        : "GOOD"}</p>
                    </td>
                    <td>
                      <p style={{ fontSize: "18px", color: interiorJson[ind] && interiorJson[ind].evaluation == "Repair" ? "red" : "black" }}>{interiorJson[ind] && interiorJson[ind].remarks
                        ? interiorJson[ind].remarks
                        : "-"}</p>
                    </td>
                    <td>
                      <label

                        style={{ fontSize: "18px", color: interiorJson[ind] && interiorJson[ind].evaluation == "Repair" ? "red" : "black" }}
                      >{
                          interiorJson[ind] && interiorJson[ind].estimate
                            ? interiorJson[ind].estimate
                            : "-"
                        }</label>
                    </td>

                  </tr>

                  </tbody>
                );
              })}
              <tr>
                <th>SI NO</th>
                <th>Exterior</th>
                <th>EVALUATION</th>
                <th>REMARKS</th>
                <th>ESTIMATE</th>
              </tr>
              {this.state.exterior.map((ival, ind) => {
                return (
                  <tbody><tr>
                    <td>
                      <p style={{ fontSize: "18px", color: exteriorJson[ind] && exteriorJson[ind].evaluation == "Repair" ? "red" : "black" }}>{ind + 1}) </p>
                    </td>
                    <td>
                      <p style={{ fontSize: "18px", color: exteriorJson[ind] && exteriorJson[ind].evaluation == "Repair" ? "red" : "black" }}>{ival}</p>
                    </td>
                    <td>
                      <p style={{ fontSize: "18px", color: exteriorJson[ind] && exteriorJson[ind].evaluation == "Repair" ? "red" : "black" }}>{exteriorJson[ind] && exteriorJson[ind].evaluation
                        ? exteriorJson[ind].evaluation
                        : "GOOD"}</p>
                    </td>
                    <td>
                      <p style={{ fontSize: "18px", color: exteriorJson[ind] && exteriorJson[ind].evaluation == "Repair" ? "red" : "black" }}>{exteriorJson[ind] && exteriorJson[ind].remarks
                        ? exteriorJson[ind].remarks
                        : "-"}</p>
                    </td>

                    <td>
                      <label

                        style={{ fontSize: "18px", color: exteriorJson[ind] && exteriorJson[ind].evaluation == "Repair" ? "red" : "black" }}
                      >{
                          exteriorJson[ind] && exteriorJson[ind].estimate
                            ? exteriorJson[ind].estimate
                            : "-"
                        }</label>
                    </td>

                  </tr>

                  </tbody>
                );
              })}
              <tr>
                <th>SI NO</th>
                <th>Bumper</th>
                <th>EVALUATION</th>
                <th>REMARKS</th>
                <th>ESTIMATE</th>
              </tr>
              {this.state.bumper.map((ival, ind) => {
                return (
                  <tbody><tr>
                    <td>
                      <p style={{ fontSize: "18px", color: bumperjson[ind] && bumperjson[ind].evaluation == "Repair" ? "red" : "black" }}>{ind + 1}) </p>
                    </td>
                    <td>
                      <p style={{ fontSize: "18px", color: bumperjson[ind] && bumperjson[ind].evaluation == "Repair" ? "red" : "black" }}>{ival}</p>
                    </td>
                    <td>
                      <p style={{ fontSize: "18px", color: bumperjson[ind] && bumperjson[ind].evaluation == "Repair" ? "red" : "black" }}>{bumperjson[ind] && bumperjson[ind].evaluation
                        ? bumperjson[ind].evaluation
                        : "GOOD"}</p>
                    </td>
                    <td>
                      <p style={{ fontSize: "18px", color: bumperjson[ind] && bumperjson[ind].evaluation == "Repair" ? "red" : "black" }}>{bumperjson[ind] && bumperjson[ind].remarks
                        ? bumperjson[ind].remarks
                        : "-"}</p>
                    </td>

                    <td>
                      <label

                        style={{ fontSize: "18px", color: bumperjson[ind] && bumperjson[ind].evaluation == "Repair" ? "red" : "black" }}
                      >{
                          bumperjson[ind] && bumperjson[ind].estimate
                            ? bumperjson[ind].estimate
                            : "-"
                        }</label>
                    </td>

                  </tr>

                  </tbody>
                );
              })}
              <tr>

                <th>Tyres</th>
                <th>LHS</th>
                <th>RHS</th>
                <th>REMARKS</th>
                <th>ESTIMATE</th>
              </tr>
              {this.state.tyres.map((ival, ind) => {
                return (
                  <tbody><tr>
                    {/* <td>
                    <p style={{ fontSize: "18px", color: tyrejson[ind] && tyrejson[ind].evaluation == "Bad" ? "red" : "black" }}>{ind + 1}) </p>
                  </td> */}
                    <td>
                      <p style={{ fontSize: "18px", color: tyrejson[ind] && tyrejson[ind].evaluation == "Repair" ? "red" : "black" }}>{ival}</p>
                    </td>
                    <td>
                      <p style={{ fontSize: "18px", color: tyrejson[ind] && tyrejson[ind].evaluation == "Repair" ? "red" : "black" }}>{tyrejson[ind] && tyrejson[ind].lhs
                        ? tyrejson[ind].lhs
                        : ">75%"}</p>
                    </td>
                    <td>
                      <p style={{ fontSize: "18px", color: tyrejson[ind] && tyrejson[ind].evaluation == "Repair" ? "red" : "black" }}>{tyrejson[ind] && tyrejson[ind].rhs
                        ? tyrejson[ind].rhs
                        : ">75%"}</p>
                    </td>

                    <td>
                      <p style={{ fontSize: "18px", color: tyrejson[ind] && tyrejson[ind].evaluation == "Repair" ? "red" : "black" }}>{tyrejson[ind] && tyrejson[ind].remarks
                        ? tyrejson[ind].remarks
                        : "-"}</p>
                    </td>

                    <td>
                      <label

                        style={{ fontSize: "18px", color: tyrejson[ind] && tyrejson[ind].evaluation == "Repair" ? "red" : "black" }}
                      >{
                          tyrejson[ind] && tyrejson[ind].estimate
                            ? tyrejson[ind].estimate
                            : "-"
                        }</label>
                    </td>

                  </tr>

                  </tbody>
                );
              })}
              <tr>
                <th>SI NO</th>
                <th>Lights</th>
                <th>EVALUATION</th>
                <th>REMARKS</th>
                <th>ESTIMATE</th>
              </tr>
              {this.state.lights.map((ival, ind) => {
                return (
                  <tbody><tr>
                    <td>
                      <p style={{ fontSize: "18px", color: lightjson[ind] && lightjson[ind].evaluation == "Repair" ? "red" : "black" }}>{ind + 1}) </p>
                    </td>
                    <td>
                      <p style={{ fontSize: "18px", color: lightjson[ind] && lightjson[ind].evaluation == "Repair" ? "red" : "black" }}>{ival}</p>
                    </td>
                    <td>
                      <p style={{ fontSize: "18px", color: lightjson[ind] && lightjson[ind].evaluation == "Repair" ? "red" : "black" }}>{lightjson[ind] && lightjson[ind].evaluation
                        ? lightjson[ind].evaluation
                        : "GOOD"}</p>
                    </td>
                    <td>
                      <p style={{ fontSize: "18px", color: lightjson[ind] && lightjson[ind].evaluation == "Repair" ? "red" : "black" }}>{lightjson[ind] && lightjson[ind].remarks
                        ? lightjson[ind].remarks
                        : "-"}</p>
                    </td>

                    <td>
                      <label

                        style={{ fontSize: "18px", color: lightjson[ind] && lightjson[ind].evaluation == "Repair" ? "red" : "black" }}
                      >{
                          lightjson[ind] && lightjson[ind].estimate
                            ? lightjson[ind].estimate
                            : "-"
                        }</label>
                    </td>

                  </tr>

                  </tbody>
                );
              })}
            </table>
          </div>
        </div>
      )
    }
    await this.setState({ modaldata })
  }
  async componentDidMount() {
    try {
      await this.MAinfunc()
    } catch (error) {
      console.error(error);
    }

  }
  form_filled = async () => {
    let { kmsfill, yearfill, checklist111json, checklist50json, checkListJson, Prosperities } = this.state
    let list = this.state.checkpoint;

    let answerJson = this.state.answerJson;
    if (list && list[0] && list[0].checkjson) {
      if (this.state.Prosperities.make == 9) {
        checkListJson = JSON.parse(list[0].checkjson)
      } else if ((kmsfill || yearfill)) {
        checklist111json = JSON.parse(list[0].checkjson)

      } else {
        checklist50json = JSON.parse(list[0].checkjson)
      }

    }
    answerJson.id = list[0].id;
    answerJson[453] = list[0].reg_no;
    answerJson[454] = list[0].vin_no;
    answerJson[455] = list[0].eng_no;
    if (list && list[0].dealer_code) {
      answerJson[456] = list[0].dealer_code;
    }
    if (list && list[0].deealer_name) {
      answerJson[457] = list[0].deealer_name;
    }
    if (list && list[0].rf_incharge_name && this.state.inopt) {
      this.state.inopt.map((ival, n) => {
        if (ival.label == list[0].rf_incharge_name) {
          answerJson[458] = ival;
        }
      });
    }
    answerJson[610] = list[0].Total;
    if (this.state.makeYearOption) {
      this.state.makeYearOption.map((ival, i) => {
        if (ival.value == list[0].Year || ival.label == list[0].Year) {
          answerJson[608] = ival;
        }
      });
    }
    await this.setState({
      button: "Update",
      checkListJson,
      checklist111json,
      checklist50json,
      button: "Update",
    });

    this.process();
  };

  async answers(
    label,
    index,
    options = null,
    key = null,
    type,
    questionId,
    formid
  ) {
    let answerJson = await this.state.answerJson;
    console.log(label, questionId);
    if (type == "text") {
      answerJson[questionId] = label;
    } else if (type == "date") {
      var d = new Date(label);
      var date = d.getDate();
      var month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      var year = d.getFullYear();
      var hr = d.getHours();
      var min = d.getMinutes();
      var sec = d.getSeconds();
      var dateStr =
        year + "-" + month + "-" + date + " " + hr + ":" + min + ":" + sec;
      answerJson[questionId] = dateStr;
    } else if (type == "textarea") {
      answerJson[questionId] = label;
    } else if (type == "selectbox") {
      answerJson[questionId] = label;
    } else if (type == "radio") {
      answerJson[questionId] = label;
    } else if (type == "checkbox") {
      let check = [];
      options.map((ival, i) => {
        var checkBox = document.getElementById(`inlineCheckbox${index}${i}`);
        if (checkBox.checked == true) {
          check.push(ival);
        }
      });
      answerJson[questionId] = check.toString();
      //console.log(" answerJson[questionId]", answerJson[questionId]);
    }
    let { isfillform } = this.state
    if (isfillform) {
      if (localStorage.getItem("formFillData")) {
        let formJson = await JSON.parse(localStorage.getItem("formFillData"))

        if (formJson && formJson.length) {
          let Index = await formJson.findIndex(e => e.prosid == this.state.customerId && e.ref == '350_CHECKLIST')
          if (Index > -1) {
            let object = formJson[Index]
            object.prosid = this.state.customerId
            object.ref = '350_CHECKLIST'
            object.data = answerJson
            formJson[Index] = object
            await localStorage.setItem("formFillData", JSON.stringify(formJson))
          } else {
            let formJson = []
            let object = {}
            object.prosid = this.state.customerId
            object.ref = '350_CHECKLIST'
            object.data = answerJson
            formJson.push(object)
            await localStorage.setItem("formFillData", JSON.stringify(formJson))
          }
        }
      } else {
        let formJson = []
        let object = {}
        object.prosid = this.state.customerId
        object.ref = '350_CHECKLIST'
        object.data = answerJson
        formJson[0] = object
        await localStorage.setItem("formFillData", JSON.stringify(formJson))
      }
    }
    await this.setState({ answerJson });
    await this.process()
  }
  process = async () => {
    let len = this.state.len;
    let data = await this.state.data;
    let updateCheckList = this.state;

    let Totaljson = this.state.Totaljson;
    let kmsfill = this.state.kmsfill;
    let yearfill = this.state.yearfill
    let checkListJson = this.state.checkListJson


    if (data) {

      let arr = data;
      let contentJson = [];

      if (arr && arr.length) {
        let answerJson = await this.state.answerJson;

        let json = await arr.map(async (item, index) => {
          if (item.type == "text") {
            await contentJson.push(
              // <div>
              <div className="bgcolor">
                <div className="row">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="position">{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={item.placeholder}
                      onChange={e =>
                        this.answers(
                          e.target.value,
                          index,
                          null,
                          null,
                          "text",
                          item.id,
                          item.parentid
                        )
                      }
                      value={answerJson[item.id]}
                    />
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }

          if (item.type == "textarea") {
            await contentJson.push(
              // <div>
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder={item.placeholder}
                      onChange={e =>
                        this.answers(
                          e.target.value,
                          index,
                          null,
                          null,
                          "textarea",
                          item.id,
                          item.parentid
                        )
                      }
                      value={answerJson[item.id]}
                    ></textarea>
                  </div>
                  <div className="col-sm-3">
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                </div>
              </div>
            );
          }
          if (item.type == "selectbox") {
            let option = [];
            //console.log(item.options);
            if (item.options != "" && item.options != "-") {
              item.options.map((ival, i) => {
                option.push(ival);
              });
            }
            this.state.selectbox = answerJson[item.id];
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <SingleSelect
                      options={option}
                      placeholder={item.placeholder}
                      handleChange={e => {
                        this.answers(
                          e,
                          index,
                          null,
                          null,
                          "selectbox",
                          item.id,
                          item.parentid
                        );
                      }}
                      selectedService={this.state.selectbox}
                    />
                  </div>
                  <span className="error-shows" id={`${index}`}>
                    {/* {this.state.error[index]} */}
                  </span>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
        });
        if (this.state.modaldata1) {
          await contentJson.push(
            <div className="bgcolor">
              <div className="row">
                <div className="col-sm-3"></div>
                {this.state.modaldata && <div className="col-sm-3 text-center" >
                  <LoginModal
                    buttonTitle="Evaluator Estimation"
                    title="Evaluator Estimation"
                    extraStyle={{ width: 'auto' }}
                    id="evaluation"
                    extraClass="btn btn-sm btn-primary"
                    //reset={this.reset}
                    bodyText={this.state.modaldata && this.state.modaldata}

                  />
                </div>}
                {this.state.modaldata1 && <div className="col-sm-3 text-center">
                  <LoginModal
                    buttonTitle="Bad Summary"
                    extraStyle={{ width: 'auto' }}
                    title="Bad Summary"
                    extraClass="btn btn-sm btn-danger"

                    id="BadSummary"
                    disable={this.state.button == "Update" ? false : true}
                    bodyText={this.state.modaldata1 && this.state.modaldata1}

                  />
                </div>}

                <div className="col-sm-3"></div>
              </div>
            </div>)
        }
        if (this.state.makeopt) {
          let display350 = 350;
          let display111 = 111;
          let displayPoints = this.state.displayPoints
          if ((displayPoints == display350) && this.state.Prosperities.make == 9) {
            await checklist350.map((ival, i) => {
              contentJson.push(
                <div>
                  <div className="bgcolor">
                    <div className="row form-group">
                      <div
                        className="col-sm-12"
                        style={{ textAlign: "center" }}
                      >
                        <h3>{ival.title}</h3>
                      </div>
                    </div>
                    {this.innercontent350Json(ival, i)}
                  </div>
                </div>
              );
              // })
            });
          }

          else if ((displayPoints == display111) && (kmsfill || yearfill)) {
            checklist111.map((ival, i) => {
              // console.log(ival.data)
              // ival.data.map((jval, j) => {
              contentJson.push(
                <div>
                  <div className="bgcolor">
                    <div className="row form-group">
                      <div
                        className="col-sm-12"
                        style={{ textAlign: "center" }}
                      >
                        <h3>{ival.title}</h3>
                        <br></br>
                        <h3>{ival.subtitle ? ival.subtitle : null}</h3>
                      </div>
                    </div>
                    {this.inner111json(ival, i)}
                  </div>
                </div>
              );
            });

          }
          else {
            Checklist50 && Checklist50.map((ival, i) => {
              // console.log(ival.data)
              // ival.data.map((jval, j) => {
              contentJson.push(
                <div>
                  <div className="bgcolor">
                    <div className="row form-group">
                      <div
                        className="col-sm-12"
                        style={{ textAlign: "center" }}
                      >
                        <h3>{ival.title}</h3>
                        <br></br>
                        <h3>{ival.subtitle ? ival.subtitle : null}</h3>
                      </div>
                    </div>
                    {this.inner50json(ival, i)}
                  </div>
                </div>
              );
            });
          }
        }
        json = await arr.map(async (item, index) => {
          // console.log(answerJson[item.id])
          if (item.type == "json") {
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="position">{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={item.placeholder}
                      onChange={e =>
                        this.answers(
                          e.target.value,
                          index,
                          null,
                          null,
                          "json",
                          item.id,
                          item.parentid
                        )
                      }
                      value={answerJson[item.id]}
                    />
                    <span className="error-shows" id={`${index}`}></span>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
        });
        //console.log("json", json);

        await Promise.all(json);
        await this.setState({ json: contentJson, answerJson, Totaljson });

      }
    }

  };

  inner50json = (ival, index) => {
    console.log('50')
    let innercontent111 = [];
    let checklist111json = this.state.checklist50json;
    //coconsole.log('check', checklist111json[index])nsole.log(ival)
    //console.log('check', checklist111json[index])

    ival.data.map((jval, j) => {
      innercontent111.push(
        <div>
          <div className="row form-group">
            <div className="col-sm-5">
              {jval.value}
              {")"}
              {jval.name}
            </div>
            <div className="col-sm-2">
              {this.state.button == "Update" ?
                <div>{checklist111json &&
                  checklist111json[index] && checklist111json[index][j] &&
                  checklist111json[index][j].status &&
                  checklist111json[index][j].status}
                </div>
                : <SingleSelect
                  options={this.state.status}
                  placeholder="Good"
                  onChange={e =>
                    this.check50Json(
                      ival.title,
                      ival.subtitle,
                      e.label,
                      jval.name,
                      index,
                      j,
                      "status"
                    )
                  }
                  // selectedService={this.state.statusselect}
                  selectedService={
                    checklist111json && checklist111json[index] && checklist111json[index][j] &&
                    checklist111json[index][j].status &&
                    this.state.status.filter(e => e.label == checklist111json[index][j].status)[0]
                  }
                />}
            </div>
            {checklist111json[index][j].status &&
              checklist111json[index][j].status == "Good" && window.innerWidth > 768 ? (
              <div className="col-sm-3">
                <input
                  type="textarea"
                  className="form-control"
                  placeholder="Remarks"
                  onChange={e =>
                    this.check50Json(
                      ival.title,
                      ival.subtitle,
                      e.target.value,
                      jval.name,
                      index,
                      j,
                      "remarks"
                    )
                  }
                  disabled={true}
                  value={
                    checklist111json[index][j] &&
                      checklist111json[index][j].remarks
                      ? checklist111json[index][j].remarks
                      : null
                  }
                ></input>
              </div>
            ) : checklist111json[index][j].status &&
              checklist111json[index][j].status != "Good" ? (
              <div className="col-sm-3">
                <input
                  type="textarea"
                  className="form-control"
                  placeholder="Remarks"
                  onChange={e =>
                    this.check50Json(
                      ival.title,
                      ival.subtitle,
                      e.target.value,
                      jval.name,
                      index,
                      j,
                      "remarks"
                    )
                  }
                  defaultValue={
                    checklist111json[index][j] &&
                      checklist111json[index][j].remarks
                      ? checklist111json[index][j].remarks
                      : null
                  }
                ></input>
              </div>
            ) : null}
            {checklist111json[index][j].status &&
              checklist111json[index][j].status == "Good" && window.innerWidth > 768 ? (
              <div className="col-sm-2">
                <input
                  type="textarea"
                  className="form-control"
                  placeholder="Estimate"
                  onChange={e =>
                    this.check50Json(
                      ival.title,
                      ival.subtitle,
                      e.target.value,
                      jval.name,
                      index,
                      j,
                      "estimate"
                    )
                  }
                  disabled={true}
                  value={
                    checklist111json[index][j] &&
                      checklist111json[index][j].estimate
                      ? checklist111json[index][j].estimate
                      : null
                  }
                ></input>
              </div>
            ) : checklist111json[index][j].status &&
              checklist111json[index][j].status != "Good" ? (
              <div className="col-sm-2">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Estimate"
                  onChange={e =>
                    this.check50Json(
                      ival.title,
                      ival.subtitle,
                      e.target.value,
                      jval.name,
                      index,
                      j,
                      "estimate"
                    )
                  }
                  // disabled={true}
                  defaultValue={
                    checklist111json[index][j] &&
                      checklist111json[index][j].estimate
                      ? checklist111json[index][j].estimate
                      : null
                  }
                ></input>
              </div>
            ) : null}
          </div>
        </div>
      );
    });
    this.setState({ preLoader: false })
    return innercontent111;
  };

  inner111json = (ival, index) => {
    let innercontent111 = [];
    let checklist111json = this.state.checklist111json;
    console.log('111');
    //console.log(this.state.status)
    //coconsole.log('check', checklist111json[index])nsole.log(ival)
    //console.log('check', checklist111json[index])
    let val = ""
    let rem = "Remarks"
    let est = "Estimate"
    ival.data.map((jval, j) => {
      if (checklist111json[index][j].status == null) {
        // val = "Good"
        checklist111json[index][j].status = 'Good'
      }
      // console.log(jval);
      innercontent111.push(
        <div>
          <div className="row form-group">
            <div className="col-sm-5">
              {jval.value}
              {")"}
              {jval.name}
            </div>
            <div className="col-sm-2">
              {this.state.button == "Update" ?
                <div>{
                  <SingleSelect
                    options={this.state.status}
                    // placeholder={checklist111json[index] && checklist111json[index][j] &&
                    //   checklist111json[index][j].status && 
                    //   checklist111json[index][j].status}
                    placeholder='Good'
                    onChange={e =>
                      this.check111Json(
                        ival.title,
                        ival.subtitle,
                        e.label,
                        jval.name,
                        index,
                        j,
                        "status"
                      )
                    }
                    //value={checklist111json[index][j].status}
                    // selectedService={this.state.statusselect}
                    selectedService={checklist111json[index] && checklist111json[index][j] &&
                      checklist111json[index][j].status &&
                      this.state.status.filter(e => e.label == checklist111json[index][j].status)[0]
                    }
                  />

                }
                </div>
                :
                <SingleSelect
                  options={this.state.status}
                  placeholder="Good"
                  onChange={e =>
                    this.check111Json(
                      ival.title,
                      ival.subtitle,
                      e.label,
                      jval.name,
                      index,
                      j,
                      "status"
                    )
                  }
                  //value={checklist111json[index][j].status}
                  // selectedService={this.state.statusselect}
                  selectedService={
                    checklist111json[index] && checklist111json[index][j] &&
                    checklist111json[index][j].status &&
                    this.state.status.filter(e => e.label == checklist111json[index][j].status)[0]
                  }
                />
              }
            </div>
            {checklist111json[index][j].status &&
              checklist111json[index][j].status == "Good" && window.innerWidth > 768 ? (
              <div className="col-sm-3">
                <input
                  type="textarea"
                  className="form-control"
                  placeholder="Remarks"
                  onChange={e =>
                    this.check111Json(
                      ival.title,
                      ival.subtitle,
                      e.target.value,
                      jval.name,
                      index,
                      j,
                      "remarks"
                    )
                  }
                  disabled={true}
                  value={
                    checklist111json[index][j] &&
                      checklist111json[index][j].remarks
                      ? checklist111json[index][j].remarks
                      : null
                  }
                ></input>
              </div>
            ) : checklist111json[index][j].status &&
              checklist111json[index][j].status != "Good" ? (
              <div className="col-sm-3">
                <input
                  type="textarea"
                  className="form-control"
                  placeholder={
                    "Remarks"}
                  onChange={e =>
                    this.check111Json(
                      ival.title,
                      ival.subtitle,
                      e.target.value,
                      jval.name,
                      index,
                      j,
                      "remarks"
                    )
                  }
                  defaultValue={checklist111json &&
                    checklist111json[index][j] &&
                    checklist111json[index][j].remarks
                    ? checklist111json[index][j].remarks
                    : null
                  }
                ></input>
              </div>
            ) : null}
            {checklist111json && checklist111json[index][j].status &&
              checklist111json[index][j].status == "Good" && window.innerWidth > 768 ? (
              <div className="col-sm-2">
                <input
                  type="textarea"
                  className="form-control"
                  placeholder="Estimate"
                  onChange={e =>
                    this.check111Json(
                      ival.title,
                      ival.subtitle,
                      e.target.value,
                      jval.name,
                      index,
                      j,
                      "estimate"
                    )
                  }
                  disabled={true}
                  value={
                    checklist111json[index][j] &&
                      checklist111json[index][j].estimate
                      ? checklist111json[index][j].estimate
                      : null
                  }
                ></input>
              </div>
            ) : checklist111json && checklist111json[index][j].status &&
              checklist111json[index][j].status != "Good" ? (
              <div className="col-sm-2">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Estimate"
                  onChange={e =>
                    this.check111Json(
                      ival.title,
                      ival.subtitle,
                      e.target.value,
                      jval.name,
                      index,
                      j,
                      "estimate"
                    )
                  }
                  // disabled={true}
                  defaultValue={
                    checklist111json[index][j] &&
                      checklist111json[index][j].estimate
                      ? checklist111json[index][j].estimate
                      : null
                  }
                ></input>
              </div>
            ) : null}
          </div>
        </div>
      );
    });
    this.setState({ preLoader: false })
    return innercontent111;
  };
  innercontent350Json = (ival, index) => {
    let innercontentJson = [];
    let checkListJson = this.state.checkListJson;
    let val = ""
    //console.log(this.state.checkListJson, 'checkListJson')
    ival.data.map((jval, j) => {
      //console.log(index,j,'index,j');
      if (checkListJson[index][j].status == null) {
        //checkListJson[index][j].status = {}
        checkListJson[index][j].status = "Good"
      }
      console.log('350');
      innercontentJson.push(
        <div>
          <div className="row form-group">
            <div className="col-sm-5">
              {jval.value}
              {")"}
              {jval.name}
            </div>

            <div className="col-sm-2">
              {
                (false && this.state.button == "Update") ? (
                  <div>
                    {/* {checkListJson[index][j] &&
                      checkListJson[index][j].status &&
                      checkListJson[index][j].status.value
                    } */}
                  </div>
                ) :
                  (
                    <SingleSelect
                      options={this.state.status}
                      placeholder="Good"
                      onChange={e =>
                        this.checkJson(ival.title, e.label, jval.name, index, j, "status")
                      }
                      // selectedService={this.state.statusselect}
                      // selectedService={
                      //   checkListJson[index][j] && checkListJson[index][j].status
                      //     ? checkListJson[index][j].status
                      //     : null
                      // }
                      selectedService={
                        checkListJson[index] &&
                        checkListJson[index][j] &&
                        checkListJson[index][j].status &&
                        this.state.status.filter(e => e.label == checkListJson[index][j].status)[0]
                        //checkListJson[index][j].status.value
                      }
                    />
                  )
              }
            </div>

            {checkListJson[index] && checkListJson[index][j].status &&
              checkListJson[index][j].status == "Good" && window.innerWidth > 768 ? (
              <div className="col-sm-3">
                <input
                  type="textarea"
                  className="form-control"
                  placeholder="Remarks"
                  onChange={e =>
                    this.checkJson(
                      ival.title,
                      e.target.value,
                      jval.name,
                      index,
                      j,
                      "remarks"
                    )
                  }
                  disabled={true}
                  value={checkListJson && checkListJson[index] &&
                    checkListJson[index][j] && checkListJson[index][j].remarks
                    ? checkListJson[index][j].remarks
                    : null
                  }
                ></input>
              </div>
            ) : (
              <div className="col-sm-3">
                {checkListJson[index] && checkListJson[index][j].status &&
                  checkListJson[index][j].status != "Good" ? <input
                    type="textarea"
                    className="form-control"
                    placeholder={"Remarks"}
                    onChange={e =>
                      this.checkJson(
                        ival.title,
                        e.target.value,
                        jval.name,
                        index,
                        j,
                        "remarks"
                      )
                    }
                    defaultValue={
                      checkListJson[index] && checkListJson[index][j] && checkListJson[index][j].remarks
                        ? checkListJson[index][j].remarks
                        : null
                    }
                  ></input> : null}
              </div>
            )}
            {checkListJson[index] && checkListJson[index][j].status &&
              checkListJson[index][j].status == "Good" && window.innerWidth > 768 ? (
              <div className="col-sm-2">
                <input
                  type="textarea"
                  className="form-control"
                  placeholder="Estimate"
                  onChange={e =>
                    this.checkJson(
                      ival.title,
                      e.target.value,
                      jval.name,
                      index,
                      j,
                      "estimate"
                    )
                  }
                  disabled={true}
                  value={
                    checkListJson[index][j] && checkListJson[index][j] && checkListJson[index][j].estimate
                      ? checkListJson[index][j].estimate
                      : null
                  }
                ></input>
              </div>
            ) : (
              <div className="col-sm-2">
                {checkListJson[index] && checkListJson[index][j].status &&
                  checkListJson[index][j].status != "Good" ? <input
                    type="number"
                    className="form-control"
                    placeholder={"Estimate"}
                    onChange={e =>
                      this.checkJson(
                        ival.title,
                        e.target.value,
                        jval.name,
                        index,
                        j,
                        "estimate"
                      )
                    }
                    // disabled={true}
                    defaultValue={
                      checkListJson[index] && checkListJson[index][j] && checkListJson[index][j].estimate
                        ? checkListJson[index][j].estimate
                        : null
                    }
                  ></input> : null}
              </div>
            )}
          </div>
        </div>
      );
    });
    this.setState({ preLoader: false })
    return innercontentJson;
  };
  checkJson = async (title, label, jname, index, j, type) => {
    let checkListJson = this.state.checkListJson;
    let answerJson = this.state.answerJson;
    console.log("checkbefore", checkListJson[index]);
    checkListJson[index][j].name = jname;
    checkListJson[index][j][type] = label;
    let total = 0;
    let wait = checkListJson.map((ival, i) => {
      //console.log(ival)
      ival.map((jval, j) => {
        if (!jval.estimate) {
          total = total + 0;
        } else {
          total = total + parseInt(jval.estimate);
        }
      });
    });
    //console.log(total)
    answerJson[610] = total;
    await Promise.all(wait);
    let { isfillform } = this.state
    if (isfillform) {
      if (localStorage.getItem("formFillData")) {
        let formJson = await JSON.parse(localStorage.getItem("formFillData"))

        if (formJson && formJson.length) {
          let Index = await formJson.findIndex(e => e.prosid == this.state.customerId && e.ref == '350_CHECKLIST')
          if (Index > -1) {
            let object = formJson[Index]
            object.prosid = this.state.customerId
            object.ref = '350_CHECKLIST'
            object.data = answerJson
            object.checklist = checkListJson
            formJson[Index] = object
            await localStorage.setItem("formFillData", JSON.stringify(formJson))
          } else {
            let formJson = []
            let object = {}
            object.prosid = this.state.customerId
            object.ref = '350_CHECKLIST'
            object.data = answerJson
            object.checklist = checkListJson
            formJson.push(object)
            await localStorage.setItem("formFillData", JSON.stringify(formJson))
          }
        }
      } else {
        let formJson = []
        let object = {}
        object.prosid = this.state.customerId
        object.ref = '350_CHECKLIST'
        object.data = answerJson
        object.checklist = checkListJson
        formJson[0] = object
        await localStorage.setItem("formFillData", JSON.stringify(formJson))
      }
    }
    this.setState({ checkListJson, answerJson });
    console.log(checkListJson, 'after');
    if (type == "remarks" || type == "estimate") {
      this.TypingControl(this.process);//avoid many time call
    }
    else { this.process() }
    this.checkpoint();
  };
  check111Json = async (title, subtitle, label, jname, index, j, type) => {
    let checklist111json = this.state.checklist111json;
    let answerJson = this.state.answerJson;
    checklist111json[index][j].name = jname;
    checklist111json[index][j][type] = label;

    let total = 0;
    let wait = checklist111json.map((ival, i) => {
      ival.map((jval, j) => {
        if (!jval.estimate) {
          total = total + 0;
        } else {
          total = total + parseInt(jval.estimate);
        }
      });
    });
    answerJson[610] = total;
    await Promise.all(wait);
    let { isfillform } = this.state
    if (isfillform) {
      if (localStorage.getItem("formFillData")) {
        let formJson = await JSON.parse(localStorage.getItem("formFillData"))

        if (formJson && formJson.length) {
          let Index = await formJson.findIndex(e => e.prosid == this.state.customerId && e.ref == '350_CHECKLIST')
          if (Index > -1) {
            let object = formJson[Index]
            object.prosid = this.state.customerId
            object.ref = '350_CHECKLIST'
            object.data = answerJson
            object.checklist = checklist111json
            formJson[Index] = object
            await localStorage.setItem("formFillData", JSON.stringify(formJson))
          } else {
            let formJson = []
            let object = {}
            object.prosid = this.state.customerId
            object.ref = '350_CHECKLIST'
            object.data = answerJson
            object.checklist = checklist111json
            formJson.push(object)
            await localStorage.setItem("formFillData", JSON.stringify(formJson))
          }
        }
      } else {
        let formJson = []
        let object = {}
        object.prosid = this.state.customerId
        object.ref = '350_CHECKLIST'
        object.data = answerJson
        object.checklist = checklist111json
        formJson[0] = object
        await localStorage.setItem("formFillData", JSON.stringify(formJson))
      }
    }
    this.setState({ checklist111json, total, answerJson });
    console.log(total, checklist111json);
    if (type == "remarks" || type == "estimate") {
      this.TypingControl(this.process);//avoid many time call
    }
    else { this.process() }
    this.checkpoint();
  };
  check50Json = async (title, subtitle, label, jname, index, j, type) => {
    let checklist50json = this.state.checklist50json;
    let answerJson = this.state.answerJson;
    console.log("answerJson", answerJson[610]);
    checklist50json[index][j].name = jname;
    checklist50json[index][j][type] = label;

    let total = 0;
    let wait = checklist50json.map((ival, i) => {
      ival.map((jval, j) => {
        if (!jval.estimate) {
          total = total + 0;
        } else {
          total = total + parseInt(jval.estimate);
        }
      });
    });
    console.log(total);
    answerJson[610] = total;
    await Promise.all(wait);
    let { isfillform } = this.state
    if (isfillform) {
      if (localStorage.getItem("formFillData")) {
        let formJson = await JSON.parse(localStorage.getItem("formFillData"))

        if (formJson && formJson.length) {
          let Index = await formJson.findIndex(e => e.prosid == this.state.customerId && e.ref == '350_CHECKLIST')
          if (Index > -1) {
            let object = formJson[Index]
            object.prosid = this.state.customerId
            object.ref = '350_CHECKLIST'
            object.data = answerJson
            object.checklist = checklist50json
            formJson[Index] = object
            await localStorage.setItem("formFillData", JSON.stringify(formJson))
          } else {

            let object = {}
            object.prosid = this.state.customerId
            object.ref = '350_CHECKLIST'
            object.data = answerJson
            object.checklist = checklist50json
            formJson.push(object)
            await localStorage.setItem("formFillData", JSON.stringify(formJson))
          }
        } else if (formJson && Array.isArray(formJson)) {
          let object = {}
          object.prosid = this.state.customerId
          object.ref = '350_CHECKLIST'
          object.data = answerJson
          object.checklist = checklist50json
          formJson.push(object)
          await localStorage.setItem("formFillData", JSON.stringify(formJson))
        }
      } else {
        let formJson = []
        let object = {}
        object.prosid = this.state.customerId
        object.ref = '350_CHECKLIST'
        object.data = answerJson
        object.checklist = checklist50json
        formJson[0] = object
        await localStorage.setItem("formFillData", JSON.stringify(formJson))
      }
    }
    this.setState({ checklist50json, total, answerJson });
    if (type == "remarks" || type == "estimate") {
      this.TypingControl(this.process);//avoid many time call
    }
    else { this.process() }
    this.checkpoint();
  };
  checkpoint = async () => {
    let len = [];
    const { checkListJson, checklist111json, checklist50json } = this.state;
    //console.log('check', checklist111json)
    if (checkListJson && this.state.makeopt) {

      checkListJson.map((kval, c) => {
        kval.map((jval, b) => {
          //console.log(jval)
          if (jval.status != null) {
            if (jval.status.label == "Good") {
              len.push(jval);
            }
          }
        });
      });
    }
    if (checklist111json) {
      checklist111json.map((kval, k) => {
        kval.map((jval, b) => {
          if (jval.status != null) {
            if (jval.status.label == "Good") {
              len.push(jval);
            }
          }
        });
      });
    }
    if (checklist50json) {
      checklist50json.map((kval, k) => {
        kval.map((jval, b) => {
          if (jval.status != null) {
            if (jval.status.label == "Good") {
              len.push(jval);
            }
          }
        });
      });
    }
    // console.log(len.length)
    await this.setState({ len: len });
    //console.log("len", len);
  };
  update = async () => {
    const { answerJson, checkListJson, checklist111json, checklist50json } = this.state;
    let categoryArray = {};
    let id = answerJson.id;
    categoryArray.reg_no = answerJson[453];
    categoryArray.vin_no = answerJson[454];
    categoryArray.eng_no = answerJson[455];
    if (answerJson[606]) {
      categoryArray.Make = answerJson[606].value;
    }
    if (answerJson[607]) {
      categoryArray.Model = answerJson[607].value;
    }
    if (answerJson[608]) {
      categoryArray.Year = answerJson[608].value;
    }
    categoryArray.dealer_code = answerJson[456];
    categoryArray.deealer_name = answerJson[457];

    if (checkListJson.length) {
      categoryArray.checkjson = JSON.stringify(checkListJson);
    }
    if (checklist111json.length) {
      categoryArray.checkjson = JSON.stringify(checklist111json);
    }
    if (checklist50json.length) {
      categoryArray.checkjson = JSON.stringify(checklist50json);
    }
    categoryArray.Total = answerJson[610];
    categoryArray.customerid = this.state.customerId;
    categoryArray.userid = this.state.userid.id;
    categoryArray.status = "active";

    console.log(categoryArray, 'categoryArray');
    this.setState({ btnDisable: true })
    try {
      let result = await CmsContent.updateMaster(
        "tbl_checklist350",
        id,
        categoryArray
      );
      if (result) {
        await this.setState({
          btnDisable: false,
          answerJson: [],
          checkListJson: [],
          alertVisible: true,
          textalert: "Your form has been updated successfully",
          color: "success"
        });
        setTimeout(() => this.setState({ alertVisible: false }), 4000);
        // this.process();
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
        window.location.reload()
      }
    } catch (error) {
      console.log(error);
    }

  };
  formSubmit = async () => {
    // let error = [...this.state.error];
    // let e = [];
    // this.state.data.map((item, i) => {
    //   this.state.answerJson.map((element, key) => {
    //     if (
    //       item.required == 1 &&
    //       item.id == element.questionId &&
    //       (element.answer == null || element.answer == "-")
    //     ) {
    //       error[i] = "Value must not be empty";
    //       e.push(error[i]);
    //     } else if (
    //       item.required == 1 &&
    //       item.id == element.questionId &&
    //       (element.answer != null || element.answer != "-")
    //     ) {
    //       error[i] = null;
    //       e.push(error[i]);
    //     }
    //   });
    // });
    // this.setState({error: error });
    // this.process();

    // for (var i = 0; i < e.length; {
    //   if (e[i] != null) {
    //     return false;
    //   }
    // }
    const { answerJson, checkListJson, checklist111json, checklist50json } = this.state;
    let categoryArray = {};
    //console.log(checkListJson)
    categoryArray.reg_no = answerJson[453];
    categoryArray.vin_no = answerJson[454];
    categoryArray.eng_no = answerJson[455];
    if (answerJson[606]) {
      categoryArray.Make = answerJson[606].value;
    }
    if (answerJson[607]) {
      categoryArray.Model = answerJson[607].value;
    }
    if (answerJson[608]) {
      categoryArray.Year = answerJson[608].value;
    }
    categoryArray.dealer_code = answerJson[456];
    categoryArray.deealer_name = answerJson[457];
    if (answerJson[458]) {
      categoryArray.rf_incharge_name = answerJson[458].label;
    }
    if (checkListJson.length) {
      categoryArray.checkjson = JSON.stringify(checkListJson);
    }
    if (checklist111json.length) {
      categoryArray.checkjson = JSON.stringify(checklist111json);
    }
    if (checklist50json.length) {
      categoryArray.checkjson = JSON.stringify(checklist50json);
    }
    categoryArray.Total = answerJson[610];
    categoryArray.customerid = this.state.customerId;
    categoryArray.userid = this.state.userid.id;
    categoryArray.status = "active";
    categoryArray.approval = 0;
    categoryArray.points = this.state.displayPoints;

    if (answerJson[610] <= 5000) {
      categoryArray.gotoapproval = 1;
    } else if (answerJson[610] > 5000) {
      categoryArray.gotoapproval = 1;
    }
    console.log("categoryArray", categoryArray);
    //console.log(categoryArray)
    this.setState({ btnDisable: true })
    try {
      // let result = [];
      let result = await CmsContent.addMaster(
        "tbl_checklist350",
        categoryArray
      );
      if (result) {
        let formJson = await JSON.parse(localStorage.getItem("formFillData"))
        if (formJson && formJson.length) {
          let Index = await formJson.findIndex(e => e.prosid == this.state.customerId && e.ref == '350_CHECKLIST')
          if (Index > -1) formJson.splice(Index, 1)
          localStorage.setItem("formFillData", JSON.stringify(formJson))
        }
        console.log(result, "result");
        if (result.data.insertId) {
          let { Prosperities } = this.state
          let sub = {}
          if (checkListJson.length) {
            sub.checklist_json = JSON.stringify(checkListJson);
          }
          if (checklist111json.length) {
            sub.checklist_json = JSON.stringify(checklist111json);
          }
          if (checklist50json.length) {
            sub.checklist_json = JSON.stringify(checklist50json);
          }
          sub.checklist_form = this.state.displayPoints
          sub.checklist_id = result.data.insertId
          let result1 = await CmsContent.updateMaster("tbl_dfcars_RF", Prosperities.RFtable_id, sub)
          if (result1) {
            await this.setState({
              btnDisable: false,
              answerJson: [],
              // checkListJson: [],
              alertVisible: true,
              textalert: "Your form has been submitted successfully",
              color: "success"
            });
            setTimeout(() => this.setState({ alertVisible: false }), 3000);
            this.process();

            window.scroll({
              top: 0,
              left: 0,
              behavior: "smooth"
            });
            window.location.reload()
          }
        }

      }
    } catch (error) {
      console.log(error);
    }
  };
  download = () => {
    let name = [];
    let { kmsfill, yearfill } = this.state
    if (this.state.makeopt.label == "HONDA") {
      name = "checklist";
      window.open(`${ACCESS_POINT}/cmsContent/checklist/${name}`, "_self");
    } else if ((kmsfill || yearfill)) {
      name = "checklist111";
      window.open(`${ACCESS_POINT}/cmsContent/checklist111/${name}`, "_self");
    }
    else {
      name = "checklist50";
      window.open(`${ACCESS_POINT}/cmsContent/checklist50/${name}`, "_self");
    }
  };
  generatepdf = async id => {
    let exportpdf = await CmsContent.checklist("tbl_checklist350", id);
    if (exportpdf.data == "success") {
      await this.download();
    }
  };
  formSubmitPdf = async () => {
    // let error = [...this.state.error];
    // let e = [];
    // this.state.data.map((item, i) => {
    //   this.state.answerJson.map((element, key) => {
    //     if (
    //       item.required == 1 &&
    //       item.id == element.questionId &&
    //       (element.answer == null || element.answer == "-")
    //     ) {
    //       error[i] = "Value must not be empty";
    //       e.push(error[i]);
    //     } else if (
    //       item.required == 1 &&
    //       item.id == element.questionId &&
    //       (element.answer != null || element.answer != "-")
    //     ) {
    //       error[i] = null;
    //       e.push(error[i]);
    //     }
    //   });
    // });
    // this.setState({error: error });
    // this.process();

    // for (var i = 0; i < e.length; {
    //   if (e[i] != null) {
    //     return false;
    //   }
    // }
    const { answerJson, checkListJson, checklist111json, checklist50json } = this.state;
    let categoryArray = {};
    //console.log(checkListJson)
    categoryArray.reg_no = answerJson[453];
    categoryArray.vin_no = answerJson[454];
    categoryArray.eng_no = answerJson[455];
    if (answerJson[606]) {
      categoryArray.Make = answerJson[606].value;
    }
    if (answerJson[607]) {
      categoryArray.Model = answerJson[607].value;
    }
    if (answerJson[608]) {
      categoryArray.Year = answerJson[608].label;
    }
    categoryArray.dealer_code = answerJson[456];
    categoryArray.deealer_name = answerJson[457];
    if (answerJson[458]) {
      categoryArray.rf_incharge_name = answerJson[458].label;
    }
    if (checkListJson.length) {
      categoryArray.checkjson = JSON.stringify(checkListJson);
    }
    if (checklist111json.length) {
      categoryArray.check111json = JSON.stringify(checklist111json);
    }
    if (checklist50json.length) {
      categoryArray.check50json = JSON.stringify(checklist50json);
    }
    categoryArray.Total = answerJson[610];
    categoryArray.customerid = this.state.customerId;
    categoryArray.userid = this.state.userid.id;
    categoryArray.status = "active";
    categoryArray.approval = 0;
    categoryArray.points = this.state.len;

    try {
      let result = await CmsContent.addMaster(
        "tbl_checklist350",
        categoryArray
      );
      if (result) {
        this.generatepdf(result.data.insertId);
        await this.setState({
          answerJson: [],
          checkListJson: [],
          alertVisible: true,
          textalert: "Your form has been submitted successfully",
          color: "success"
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
        this.process();

        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  exportpdf = async () => {
    let name = "checklist";
    window.open(`${ACCESS_POINT}/cmsContent/downloadsellpdf/${name}`, "_self");
  };
  render() {
    const { resSelected, json, button, Totaljson, displayPoints } = this.state;
    //console.log("return", json, Totaljson);

    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div
                  className="card"
                  style={{
                    margin: "-20px",
                    marginTop: "5px",
                    boxShadow: "0px 0px 10px #b9b3b3",
                    borderRadius: "8px"
                  }}
                >
                  <div className="card-header">
                    <h1>CheckList {this.state.displayPoints}<p style={{ border: "none", color: "black" }}>
                      {this.state.preLoader && (
                        <i
                          style={{ marginRight: 5 }}
                          className="fa fa-spinner fa-spin"
                        ></i>
                      )}
                    </p></h1>
                  </div>
                  <div className="card-body">
                    <div>
                      <Alert
                        className="badge-content"
                        color={this.state.color}
                        isOpen={this.state.alertVisible}
                        toggle={this.onDismiss}
                      >
                        {this.state.textalert}
                      </Alert>
                      {json && json.length ? json : null}
                      {Totaljson && Totaljson.length ? Totaljson : null}
                      {/* {json && json.length && json} */}

                      <div>
                        {/* <table id="myTable" class="table table-bordered">
                          <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th>REMARKS</th>
                          </tr> */}
                        {/* <div>sdkfnm,dnf,k</div> */}
                        {/* {Object.keys(checklist350).forEach(key => {
                          checklist350[key].map((ival, i) => {
                            return (
                              // Object.keys(ival).forEach(keys => {
                              //   console.log(keys);
                              <div>sdkfnm,dnf,k</div>
                              // ival[keys].map((jval, j) => {
                              //   // console.log(jval);
                              // });
                              // });
                            );
                          });
                        })} */}
                        {/* </table> */}
                      </div>
                      {json.length > 0 && (
                        <div>
                          {this.state.global && (
                            <div className="row form-group">
                              {/* <div className="col-sm-2" />
                              <div className="col-sm-2" /> */}
                              <div className="col text-center">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={
                                    button == "Save"
                                      ? this.formSubmit
                                      : this.update
                                  }
                                  disabled={this.state.btnDisable}
                                //onClick={this.update}
                                // onClick={this.formSubmit}
                                >
                                  {this.state.btnDisable ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;
                                  {button == "Save" ? "Save" : "update"}
                                </button>
                              </div>
                            </div>
                          )}
                          {/* {this.state.global && (
                            <div className="row form-group">
                              <div className="col-sm-2" />
                              <div className="col-sm-2" />
                              <div className="col-sm-5">
                                {this.state.button == "Save" && (
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={
                                      button == "Save"
                                        ? this.formSubmitPdf
                                        : null
                                    }
                                  //onClick={this.update}
                                  // onClick={this.formSubmit}
                                  >
                                    {button == "Save"
                                      ? "Save and Export PDF"
                                      : null}
                                  </button>
                                )}
                              </div>
                            </div>
                          )} */}
                          {/* {this.state.global == false && (
                            <div className="row form-group">
                              <div className="col-sm-2" />
                              <div className="co  l-sm-2" />
                              <div className="col-sm-5">
                                <button
                                  style={{ marginLeft: "200px" }}
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => this.print()}
                                >
                                  Print
                                </button>
                              </div>
                            </div>
                          )} */}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

      </React.Fragment>
    );
  }
}
export default CERTIFICATION_350_CheckPoints;
