import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
//import DfCarsEnquiry from "./DfCarsEnquiry";
import moment from "moment";
import Datatable from "../../../../components/Datatable/Datatable";
import { SingleSelect } from "../../../../components/Form";
import BankJson from "./bank.json";
class BillUpload extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      User: JSON.parse(localStorage.getItem("userlog")),
      userId: localStorage.getItem("userId"),
      serviceId: localStorage.getItem("currentService"),
      EnquiryData: [],
      EnquiryData: [],
      alertVisible: false,
      selectedMode: null,
      bank_json: BankJson,
      receipt: null,
      userOption: [],
      mode: [
        { label: "CASH", value: 0 },
        { label: "CHEQUE", value: 1 },
        { label: "NET BANKING", value: 2 },
        { label: "UPI", value: 3 }
      ],
      type: [{ label: "Selling", value: 0 }, { label: "Buying", value: 1 }]
    };
  }

  async componentDidMount() {
    try {
      let result = await cmsContent.getFreedom(
        "tbl_dfcarz_prosperities.*,tbl_Inventory_Sheet_Reproof.id as value,tbl_Inventory_Sheet_Reproof.approval,tbl_user_web.userName as jobroleName",
        `tbl_dfcarz_prosperities,tbl_Inventory_Sheet_Reproof,tbl_user_web`,
        `tbl_dfcarz_prosperities.id=tbl_Inventory_Sheet_Reproof.customerid and tbl_user_web.id = tbl_Inventory_Sheet_Reproof.userid`,
        1
      );
      if (result) {
        result.data.map((ival, i) => {
          ival.FormFilled = "no";
        });

        this.setState({ Data: result.data });
      }
      // let seller = await cmsContent.getFreedom(
      //   "tbl_dfcarz_prosperities.name",
      //   `tbl_dfcarz_prosperities,tbl_Specifications_Sheet_Proof`,
      //   `tbl_dfcarz_prosperities.type = "seller" and tbl_dfcarz_prosperities.enquiry_id=tbl_Specifications_Sheet_Proof.customerid`,
      //   "tbl_Specifications_Sheet_Proof.id",
      //   "tbl_Specifications_Sheet_Proof.id"
      // );
      // console.log(seller);
      let buyer = await cmsContent.getFreedom(
        "tbl_dfcarz_prosperities.name",
        `tbl_dfcarz_prosperities,tbl_checklist350`,
        `tbl_dfcarz_prosperities.type = "buyer" and tbl_dfcarz_prosperities.enquiry_id=tbl_checklist350.customerid and tbl_checklist350.approval=1`,
        "tbl_checklist350.id",
        "tbl_checklist350.id"
      );
      console.log(buyer);
      // tbl_dfcarz_prosperities
      // tbl_Specifications_Sheet_Proof
    } catch (error) {
      console.log(error);
    }
  }
  selectType = async e => {
    let result = await cmsContent.getFreedom(
      "prospectid as value , customer as label",
      "tbl_dfcarz_customers",
      `type = "seller"`,
      "prospectid",
      "prospectid"
      // "tbl_dfcarz_prosperities.*",
      // "tbl_checklist350,tbl_dfcarz_prosperities",
      // `tbl_checklist350.approval = 1 and tbl_checklist350.customerid = tbl_dfcarz_prosperities.enquiry_id`,
      // "tbl_dfcarz_prosperities.id",
      // "tbl_dfcarz_prosperities.id"
    );

    this.setState({ selectedType: e, userOption: result.data });
  };
  userSelect = e => {
    this.setState({ userSelected: e });
  };
  selectMode = e => {
    this.setState({ selectedMode: e });
  };
  selectBank = e => {
    this.setState({ selectedBank: e });
  };
  amounthandle = e => {
    this.setState({ amount: e });
  };
  refhandle = e => {
    this.setState({ reference: e });
  };
  cheqhandle = e => {
    this.setState({ cheque: e });
  };
  branchhandle = e => {
    this.setState({ branch: e });
  };
  receipthandle = e => {
    this.setState({ receipt: e });
  };

  paySubmit = async () => {
    const {
      mode,
      selectedMode,
      bank_json,
      amount,
      selectedBank,
      reference,
      receipt,
      branch,
      cheque,
      selectedType,
      userSelected
    } = this.state;
    if (selectedMode === null) {
      return false;
    } else {
    }

    let banking = bank_json.filter(item => {
      if (
        (selectedMode.value === 1 || selectedMode.value === 1) &&
        selectedBank.value === item.value
      ) {
        return item;
      }
    });
    let paymentMode = mode.filter(item => {
      if (
        // (selectedMode.value === 1 || selectedMode.value === 1) &&
        selectedMode.value === item.value
      ) {
        return item;
      }
    });

    let body = {};
    body.mode = paymentMode[0].label;

    if (selectedMode.value === 0) {
      body.amount = amount;
    }
    if (selectedMode.value === 1) {
      body.bank = banking[0].label;
      body.branch = branch;
      body.cheque = cheque;
    }
    if (selectedMode.value === 2) {
      body.bank = banking[0].label;
      body.ref_no = reference;
    }
    body.type = selectedType.label;
    body.prospectid = userSelected.value;
    body.receipt_no = receipt;
    body.Update_by_user = this.state.userId;
    body.serviceid = 10;

    try {
      const result = await cmsContent.addMaster("tbl_payment", body);
      if (result) {
        this.setState({
          selectedMode: null,
          amount: "",
          selectedType: "",
          userSelected: "",
          selectedBank: "",
          reference: "",
          receipt: "",
          branch: "",
          cheque: ""
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const {
      mode,
      selectedMode,
      bank_json,
      amount,
      selectedBank,
      reference,
      receipt,
      cheque,
      branch,
      type,
      selectedType,
      userOption,
      userSelected
    } = this.state;
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Upload Bill</h1>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="exampleInputEmail1">Type</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          options={type}
                          handleChange={d => this.selectType(d)}
                          selectedService={selectedType}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="exampleInputEmail1">User</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          options={userOption}
                          handleChange={d => this.userSelect(d)}
                          selectedService={userSelected}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="exampleInputEmail1">
                          Transaction Type
                        </label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          options={mode}
                          handleChange={d => this.selectMode(d)}
                          selectedService={selectedMode}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    {selectedMode !== null && selectedMode.value === 0 && (
                      <div className="row form-group">
                        <div className="col-sm-2" />
                        <div className="col-sm-2">
                          <label htmlFor="exampleInputEmail1">Amount</label>
                        </div>
                        <div className="col-sm-5">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Amount in Rs"
                            onChange={e => this.amounthandle(e.target.value)}
                            value={amount}
                          />
                        </div>
                        <div className="col-sm-3" />
                      </div>
                    )}
                    {selectedMode !== null &&
                      (selectedMode.value === 1 ||
                        selectedMode.value === 2) && (
                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2">
                            <label htmlFor="exampleInputEmail1">
                              Select Bank
                            </label>
                          </div>
                          <div className="col-sm-5">
                            <SingleSelect
                              options={bank_json}
                              handleChange={d => this.selectBank(d)}
                              selectedService={selectedBank}
                            />
                          </div>
                          <div className="col-sm-3" />
                        </div>
                      )}
                    {selectedMode !== null && selectedMode.value === 1 && (
                      <div>
                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2">
                            <label htmlFor="exampleInputEmail1">
                              Branch Name
                            </label>
                          </div>
                          <div className="col-sm-5">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Branch Name"
                              onChange={e => this.branchhandle(e.target.value)}
                              value={branch}
                            />
                          </div>
                          <div className="col-sm-3" />
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2">
                            <label htmlFor="exampleInputEmail1">
                              Cheque Number
                            </label>
                          </div>
                          <div className="col-sm-5">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Cheque Number"
                              onChange={e => this.cheqhandle(e.target.value)}
                              value={cheque}
                            />
                          </div>
                          <div className="col-sm-3" />
                        </div>
                      </div>
                    )}
                    {selectedMode !== null &&
                      (selectedMode.value === 2 ||
                        selectedMode.value === 3) && (
                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2">
                            <label htmlFor="exampleInputEmail1">
                              Reference Number
                            </label>
                          </div>
                          <div className="col-sm-5">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Reference Number"
                              onChange={e => this.refhandle(e.target.value)}
                              value={reference}
                            />
                          </div>
                          <div className="col-sm-3" />
                        </div>
                      )}
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="exampleInputEmail1">
                          Receipt Number
                        </label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Receipt Number"
                          onChange={e => this.receipthandle(e.target.value)}
                          value={receipt}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-3" />
                      <div className="col-sm-5">
                        <button
                          type="button"
                          className="btn btn-primary"
                          // disabled={this.state.disableValue}
                          onClick={this.paySubmit}
                        >
                          Submit
                        </button>
                      </div>
                      <div className="col-sm-2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
export default BillUpload;
