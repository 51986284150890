import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
//import UpdateStatus from "./UpdateStatus"
import moment from "moment";
import LoginModal from "../../../../components/Modal/Modal";
import UsertypeComments from "../UserMaster/UsertypeComments";
import Datatable from "../../../../components/Datatable/Datatable";
import { ACCESS_POINT } from "../../../../config/index";
import { ic_check_box } from 'react-icons-kit/md/ic_check_box'
import { basket_remove } from 'react-icons-kit/ikons/basket_remove'
import Icon from "react-icons-kit";
import { shopping_cart_delete } from "react-icons-kit/ikons/shopping_cart_delete";
import { time } from "react-icons-kit/ikons/time";
import { calendar } from "react-icons-kit/ikons/calendar";
import { shuffle } from "react-icons-kit/ikons/shuffle";
class Delivery extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      Datas: [],
      customerId: localStorage.getItem("userId"),
      LocationID: localStorage.getItem(`LocationID`),
      userid: JSON.parse(localStorage.getItem("userlog")),
      EnquiryData: [],
      alertVisible: false,
      loading: false,
      dashboard: [{ "fill": 0, "view": 0 }],
      data2: [],
      MakeOptions: [],
      coloropt: [],
      variantopt: [],
      alldelivery: [],
      deliveryFTD: [],
      deliveredMTD: [],
      modelopt: [],
      data3: [],
      column: [
        {
          Header: "Form",
          accessor: "mobile",
          Cell: d => this.Specification(d),
          width: 120,
          className: "text-center"
        },
        {
          Header: "Name",
          accessor: "name",
          width: 150
        },
        {
          Header: "Mobile",
          accessor: "mobile",
          Cell: d => this.Call(d.original.mobile),
          width: 150
        },
        {
          Header: "Date",
          accessor: "created_At"
        },
        {
          Header: "Make",
          accessor: "makeName",
          // Cell: (d) => this.getValueFromArray(d.original.make, this.state.MakeOptions),
        },
        {
          Header: "Model",
          accessor: "modelName",
          // Cell: (d) => this.getValueFromArray(d.original.model, this.state.modelopt)
        },
        {
          Header: "Variant",
          accessor: "variantName",
          // Cell: (d) => this.getValueFromArray(d.original.variant, this.state.variantopt)
        },
        {
          Header: "Color",
          accessor: "colourName",
          // Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)
        },
        {
          Header: "Year",
          accessor: "year",
        },

        {
          Header: "Print PDF",
          accessor: "pdf",
          Cell: d => this.viewpdf(d),
          width: 120,
          className: "text-center"
        },

        {
          Header: "Delivery Status",
          accessor: "status",
          Cell: d => this.Status(d),
          width: 150,
          className: "text-center"
        }
      ],
      excelColumn: [
        {
          label: "Name",
          key: "name",
        },
        {
          label: "Mobile",
          key: "mobile",
        },
        {
          label: "Date",
          key: "created_At"
        },
        {
          label: "Make",
          key: "makeName",
        },
        {
          label: "Model",
          key: "modelName",
          // Cell: (d) => this.getValueFromArray(d.original.model, this.state.modelopt)
        },
        {
          label: "Variant",
          key: "variantName",
          // Cell: (d) => this.getValueFromArray(d.original.variant, this.state.variantopt)
        },
        {
          label: "Color",
          key: "colourName",
          // Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)
        },
        {
          label: "Year",
          key: "year",
        }
      ],
      stage: "",
      CustomerId: ""
    };
  }
  viewpdf = d => {
    if (d.original.FormFilled == "yes" || d.original.FormFilled == "approved") {
      return (<button type="button" className="btn btn-sm btn-warning"
        onClick={() => this.generatepdf(d)}>Print PDF</button>)
    }
    else {
      return (
        <span className="label" style={{ backgroundColor: "white" }}></span>)
    }
  }
  generatepdf = async (d) => {
    const fproofid = await cmsContent.getFreedom("*", "tbl_Delivery_Note_Buyer_Reproof", `customerId=${d.original.id}`, 1, 1);
    console.log(fproofid)
    if (fproofid) {
      let fproofpdf = await cmsContent.Deliverybuyer("tbl_Delivery_Note_Buyer_Reproof", fproofid.data[0].customerid);
      console.log(fproofpdf)
      if (fproofpdf.data == "success") {
        window.open(`${ACCESS_POINT}/cmsContent/downloadbuyer/${fproofid.data[0].regname}`, '_self')

      }
    }
  }

  F_proofForm = async (d) => {
    window.open(`/DFCars/AT_Delivery_Note_Buyer_Reproof/${d.original.id}`, '_self')
  }

  Specification = (d) => {
    if (d.original.FormFilled == 'yes' || d.original.FormFilled == 'approved') {
      return (<button type="button" className="btn btn-sm btn-primary" style={{ width: "100px" }}
        onClick={() => this.F_proofForm(d)}
      >Edit Form</button>)
    } else {
      return (<button type="button" className="btn btn-sm btn-warning" style={{ width: "100px" }}
        onClick={() => this.F_proofForm(d)}
      >Fill Form</button>)
    }

  }
  Status = (d) => {

    if (d.original.FormFilled == "yes" || d.original.FormFilled == "approved") {
      return (<div>
        <button type="button" className="btn btn-sm btn-success" style={{ width: "130px" }}
        >Delivered</button>
      </div>)
    }
    else {
      return (
        <div> <span className="text-center btn btn-sm btn-danger" style={{ width: "130px" }}
        >Not Delivered</span></div>)
    }

    // if (d.original.status == 'active') {
    //   return (<button type="button" className="btn btn-sm btn-success" style={{ width: "100px" }}

    //   >Active </button>)
    // } else {
    //   return (<button type="button" className="btn btn-sm btn-warning" style={{ width: "100px" }}
    //   >inactive</button>)
    // }

  }

  edash = () => {
    return (
      <div className="card">
        {/* < div className="row" > */}
        <div className="card-header">
          <div className="col-sm-6" >
            <h2 className="mt-2 black">Dashboard</h2>
          </div>
        </div>
        <div className="card-body">
          <div className="">
            <div className="row col-12 text-center">
              <div className="col" >
                <div className="dashboardCard1" onClick={() => this.onClk(3)}>
                  <Icon icon={time} style={{ color: '#2F353A' }} size={35} /><br />
                  <h4 className="mt-2 black" >FTD Delivery</h4>
                  <div >
                    <h1 className="bold mt-4 mb-2 black" style={{ cursor: "pointer" }}><b>{this.state.dashboard[0].FTD ? this.state.dashboard[0].FTD : 0}</b></h1>
                  </div>
                </div>
              </div>
              <div className="col" >
                <div className="dashboardCard1" onClick={() => this.onClk(4)}>
                  <Icon icon={calendar} style={{ color: 'blue' }} size={35} /><br />
                  <h4 className="mt-2 black" >MTD Delivered</h4>
                  <div >
                    <h1 className="bold mt-4 mb-2 black" style={{ cursor: "pointer" }}><b>{this.state.dashboard[0].MTD ? this.state.dashboard[0].MTD : 0}</b></h1>
                  </div>
                </div>
              </div>
              {/* <div className="col">
                <div className="dashboardCard1" onClick={() => this.onClk(1)} >
                  <Icon icon={ic_check_box} style={{ color: 'green' }} size={35} />
                  <h2 className="mt-2 black" >Delivered</h2>
                  <div >
                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.dashboard[0].view ? this.state.dashboard[0].view : 0}</b></h1>
                  </div>
                </div>
              </div> */}
              <div className="col" >
                <div className="dashboardCard1" onClick={() => this.onClk(2)}>
                  <Icon icon={shopping_cart_delete} style={{ color: 'red' }} size={35} /><br />
                  <h4 className="mt-2 black" >Delivery Pending</h4>
                  <div >
                    <h1 className="bold mt-4 mb-2 black" style={{ cursor: "pointer" }}><b>{this.state.dashboard[0].fill ? this.state.dashboard[0].fill : 0}</b></h1>
                  </div>
                </div>
              </div>

              <div className="col" >
                <div className="dashboardCard1" onClick={() => this.onClk(9)}>
                  <Icon icon={shuffle} style={{ color: 'rgb(203 44 146)' }} size={35} /><br />
                  <h4 className="mt-2 black" >All Delivery</h4>
                  <div >
                    <h1 className="bold mt-4 mb-2 black" style={{ cursor: "pointer" }}><b>{this.state.alldelivery ? this.state.alldelivery.length : 0}</b></h1>
                  </div>
                </div>
              </div>
              {/* <div className="col">
                <div className="dashboardCard1" onClick={() => this.onClk(2)} >
                  <Icon icon={basket_remove} style={{ color: 'red' }} size={35} />
                  <h2 className="mt-2 black" >Yet to Delivered</h2>
                  <div >
                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.dashboard[0].fill ? this.state.dashboard[0].fill : 0}</b></h1>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

    )
  }
  onClk = (val) => {
    if (val === 1) { this.setState({ Datas: this.state.data2 }) }
    if (val === 2) { this.setState({ Datas: this.state.data3 }) }
    if (val === 3) { this.setState({ Datas: this.state.deliveryFTD }) }
    if (val === 4) { this.setState({ Datas: this.state.deliveredMTD }) }
    if (val === 9) { this.setState({ Datas: this.state.alldelivery }) }

    window.scroll({
      top: 400,
      left: 0,
      behavior: "smooth",
    });
  }
  MainFunction = async () => {
    this.setState({ loading: true })
    try {
      // let results = await cmsContent.BUYDFCARS(this.state.userid.id, 'buyerreport')
      let results1 = await cmsContent.BUYDFCARZ(this.state.userid.id, 'delreport')
      console.log(results1, "API");
      if (results1) {
        // console.log(results1, "apiiiiii");
        let fulldata = []
        if (results1 && results1.fulldata) {
          fulldata = results1.fulldata;
        }

        await this.setState({
          alldelivery: fulldata,
          deliveryFTD: fulldata.length ? fulldata.filter(e => e.deliveryFTD == true) : [],
          deliveredMTD: fulldata.length ? fulldata.filter(e => e.deliveredMTD == true) : [],
          data2: fulldata.length ? fulldata.filter(e => e.filterdata2 == true) : [],
          data3: fulldata.length ? fulldata.filter(e => e.filterdata3 == true) : [],
          dashboard: results1.data_for_f,
          loading: false
        })
      }

    } catch (error) {
      console.log(error);
    }
  }
  vals = async () => {
    let allOpt = await this.decryption('getcar')
    if (allOpt) {
      this.setState({
        MakeOptions: allOpt.makeopt,
        coloropt: allOpt.coloropt,
        variantopt: allOpt.variantopt,
        modelopt: allOpt.modelopt
      })
    }
  }
  async componentDidMount() {
    this.setState({ loading: true })
    this.vals()
    this.MainFunction()

  }
  render() {
    let { loading } = this.state;
    return (<React.Fragment>
      <main className="main my-4">
        <div className="container-fluid">
          {this.edash()}
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h1>Delivery Report</h1>
                </div>
                <div className="card-body">
                  <div className="row form-group">
                    <div className="col-sm-4">
                      <LoginModal
                        buttonTitle="Add Enquiry"
                        title="Update Commets"
                        id="status"
                        extraClass="btn btn-sm btn-primary"
                        onClick={this.reset}
                        extraStyle={{ width: 'auto' }}
                        buttonStyle={{ backgroundColor: "white", border: "white" }}
                        bodyText={
                          <UsertypeComments
                            stage={this.state.stage}
                            customerid={this.state.CustomerId}
                          />}
                      />
                    </div>
                  </div>
                  {loading ? <div className="text-center"> <i
                    className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                  ></i></div>
                    :
                    this.state.Datas.length ?
                      <Datatable
                        data={this.state.Datas}
                        columnHeading={this.state.column}
                        excelColumn={this.state.excelColumn}
                      /> : null
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
    )
  }
}
export default Delivery;