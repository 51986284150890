import React, { Component } from "react";
import SingleSelect from "../../../components/Form/SingleSelect";
import CmsContent from "../../../MiddleWare/CmsContent";
import Datatable from "../../../components/Datatable/Datatable";
import { Alert } from "reactstrap";
import { FormMiddleWare } from "../../../components/Form";
import { confirmAlert } from "react-confirm-alert";
import redisMiddleware from "../../../MiddleWare/redisMiddleware";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import SFAPDF from "../../../components/PDF/SFAPDF";

class TransactionHistory extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      alertVisible: false,
      formAlertdelete: false,
      disableValue: false,
      error: null,
      customerId: localStorage.getItem("userId"),
      serviceId: localStorage.getItem("currentService"),
      prosId: this.props.match.params.prosId,
      button: "Submit",
      disabled: false,
      textalert: null,
      color: "success",
      makeYearOption: [],
      modeldataOption: []
    };
  }
  async componentDidMount() {
    try {
      //   console.log(this.props.match.params);

      let userlog = JSON.parse(localStorage.getItem("userlog"));

      let result = await CmsContent.getFreedom(
        "tbl_dfcarz_prosperities.*,tbl_payment.*",
        "tbl_payment,tbl_dfcarz_prosperities",
        `tbl_payment.prospectid = ${this.state.prosId} and tbl_dfcarz_prosperities.id = ${this.state.prosId}`,
        "tbl_payment.id",
        "tbl_payment.id"
      );
      console.log(result);

      const make = await CmsContent.getFreedom(
        "Make as label , id as value ",
        "tbl_make_master",
        `ServiceId=10`,
        "id",
        "id"
      );
      if (make) {
        this.setState({ makedata: make.data });
      }
      const model = await CmsContent.getFreedom(
        "Model as label , id as value,makeid",
        "tbl_dfcarsmodel",
        `ServiceId=10`,
        "id",
        "id"
      );
      if (model) {
        this.setState({ modeldata: model.data });
      }

      let date = new Date();
      var year = date.getFullYear();
      let mini_year = 1990;

      var difference = year - mini_year;

      let makeYearOption = [];
      for (var i = 0; i <= difference; i++) {
        makeYearOption.push({
          label: mini_year + i,
          value: i
        });
      }

      this.setState({ makeYearOption });
      // tbl_prize_list
      const colordata = await CmsContent.getFreedom(
        "colour as label ,id as value ",
        "tbl_colour",
        `ServiceId=10`,
        "id",
        "id"
      );
      if (colordata) {
        this.setState({ colorOption: colordata.data });
      }
      //   const prizedata = await CmsContent.getFreedom(
      //     "*",
      //     "tbl_prize_list",
      //     1,
      //     "id",
      //     "id"
      //   );
      if (result) {
        this.setState({ data: result.data });
      }
    } catch (error) {
      console.log(error);
    }
  }
  column = [
    {
      Header: "Props",
      accessor: "name"
    },
    {
      Header: "Type",
      accessor: "type"
    },
    {
      Header: "Amount",
      accessor: "amount"
    }
  ];

  edit = d => {
    let value = d;
    return (
      <center>
        <button
          type="button"
          className="btn btn-info"
          onClick={() => this.edition(value)}
        >
          Edit
        </button>
      </center>
    );
  };
  edition = d => {
    let data = [...this.state.data];
    let index = d.index;

    let value = d.original;

    // const { modelSelected, makeSelected, makeyearSelected } = this.state;

    let makeSelected = {};
    makeSelected.value = value.make;
    makeSelected.label = this.getValueFromArray(
      value.make,
      this.state.makedata
    );

    let modelSelected = {};
    modelSelected.value = value.model;
    modelSelected.label = this.getValueFromArray(
      value.model,
      this.state.modeldataOption
    );

    let makeyearSelected = {};
    makeyearSelected.label = value.make_year;

    let colorSelected = {};
    colorSelected.value = value.color;
    colorSelected.label = this.getValueFromArray(
      value.color,
      this.state.colorOption
    );

    this.setState({
      disabled: true,
      index,
      error: null,
      button: "Update",
      ownertype: value.owner_type,
      expdate: value.make_year,
      regno: value.reg_no,
      location: value.location,
      price: value.price,
      kms: value.kms,
      modelSelected,
      makeSelected,
      makeyearSelected,
      colorSelected
    });
  };

  buttonDeletes = value => {
    this.setState({});
    confirmAlert({
      title: "Confirmation to Delete",
      message: "Are you sure you want to delete this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.buttonDelete(value)
        },
        {
          label: "No"
        }
      ]
    });
  };

  buttonDelete = async value => {
    const previousData = [...this.state.data];
    let id = value.id;

    try {
      const result = await CmsContent.getSingleConditionedValue(
        "tbl_prize_list",
        "id",
        id,
        "Delete"
      );
      if (result) {
        let datas = previousData.filter((delelteid, i) => {
          if (delelteid.id !== value.id) {
            return delelteid;
          }
        });
        this.setState({
          data: datas,
          formAlertdelete: true,
          alertVisible: true,
          textalert: "Selected Usertype Deleted",
          color: "danger"
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  handlechange = e => {
    let value = e.target.value;

    this.setState({ editvalue: value });
  };
  addnew = async () => {
    // let { categorySelected, data } = this.state;
    // const { editvalue: value, categoryid: programid } = this.state;

    // if (value != undefined && value.trim() != "" && value != null) {
    //   this.setState({ error: "" });
    // } else {
    //   this.setState({ error: "Please Fill The Usertype", selectboxerror: "" });
    //   return true;
    // }

    // let filtered_item =
    //   data && data.length > 0
    //     ? data.filter(i => {
    //         return i.usertype === value;
    //       })
    //     : [];

    // if (filtered_item.length > 0) {
    //   this.setState({ error: "Usertype not be repeated" });
    //   return false;
    // }

    // this.setState({ error: "" });

    const {
      modelSelected,
      makeSelected,
      makeyearSelected,
      ownertype,
      expdate,
      regno,
      location,
      price,
      kms,
      colorSelected,
      data
    } = this.state;

    let groupArray = {};
    if (makeSelected) {
      groupArray.make = makeSelected.value;
    }
    if (modelSelected) {
      groupArray.model = modelSelected.value;
    }
    if (colorSelected) {
      groupArray.color = colorSelected.value;
    }
    if (makeyearSelected) {
      groupArray.make_year = makeyearSelected.label;
    }
    groupArray.owner_type = ownertype;
    if (expdate) {
      groupArray.insurance_exp = expdate;
    }
    if (regno) {
      groupArray.reg_no = regno;
    }
    if (location) {
      groupArray.location = location;
    }
    if (price) {
      groupArray.price = price;
    }
    if (kms) {
      groupArray.kms = kms;
    }

    console.log(groupArray);

    // groupArray.kms = this.state.customerId;
    // groupArray.serviceid = this.state.serviceId;

    try {
      this.setState({ disableValue: true });
      const result = await CmsContent.addMaster("tbl_prize_list", groupArray);
      if (result) {
        let valueArray = {};
        // valueArray.id = result.data.insertId;
        // valueArray.usertype = value.trim();
        const newData = [valueArray, ...this.state.data];

        this.setState({
          data: newData,
          makeSelected: "",
          modelSelected: "",
          colorSelected: "",
          makeyearSelected: "",
          ownertype: "",
          expdate: "",
          regno: "",
          location: "",
          price: "",
          alertVisible: true,
          disableValue: false,
          button: "Submit",
          index: null,
          textalert: "New Price list added",
          color: "success"
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  update = async () => {
    const { editvalue: value } = this.state;
    const {
      modelSelected,
      makeSelected,
      makeyearSelected,
      ownertype,
      expdate,
      regno,
      location,
      price,
      kms,
      colorSelected,

      data
    } = this.state;

    // if (value === "") {
    //   this.setState({ error: "Value Cannot Be Empty" });
    //   return false;
    // } else {
    this.setState({ disableValue: true });
    try {
      let previousdata = [...this.state.data];
      const { index, editid: id } = this.state;

      let groupArray = {};
      groupArray.make = makeSelected.value;
      groupArray.model = modelSelected.value;
      groupArray.color = colorSelected.value;
      groupArray.make_year = makeyearSelected.label;
      groupArray.owner_type = ownertype;
      groupArray.insurance_exp = expdate;
      groupArray.reg_no = regno;
      groupArray.location = location;
      groupArray.price = price;
      groupArray.kms = kms;

      console.log(groupArray);
      const result = await CmsContent.updateMaster(
        "tbl_prize_list",
        id,
        groupArray
      );

      if (result) {
        // previousdata[index].usertype = value.trim();
        this.setState({
          data: previousdata,
          alertVisible: true,
          disableValue: false,
          addNewSubgroup: false,
          index: null,
          button: "Submit",
          disabled: false,
          textalert: "New Price list Updated",
          color: "success"
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      }
    } catch (error) {
      console.log(error);
    }
    // }
  };
  onDismiss = () => {
    this.setState({ alertVisible: false });
    this.setState({ alertVisible1: false });
    this.setState({ formAlertdelete: false });
  };
  programSelect = e => {
    this.setState({ categorySelected: e, categoryid: e.value });
  };
  makeSelect = e => {
    const { modeldata } = this.state;

    let modeldataOption = [];
    modeldata.map((ival, i) => {
      if (e.value == ival.makeid) {
        modeldataOption.push({ label: ival.label, value: ival.value });
      }
    });
    this.setState({ makeSelected: e, modeldataOption });
  };
  modelSelect = e => {
    this.setState({ modelSelected: e });
  };
  makeyearSelect = e => {
    this.setState({ makeyearSelected: e });
  };
  ownerChange = e => {
    let value = e.target.value;
    this.setState({ ownertype: value });
  };
  regChange = e => {
    let value = e.target.value;
    this.setState({ regno: value });
  };
  pricechange = e => {
    let value = e.target.value;
    this.setState({ price: value });
  };
  locationchange = e => {
    let value = e.target.value;
    this.setState({ location: value });
  };
  expchange = e => {
    let value = e.target.value;
    this.setState({ expdate: value });
  };
  kmschange = e => {
    let value = e.target.value;
    this.setState({ kms: value });
  };
  ColorSelect = e => {
    this.setState({ colorSelected: e });
  };

  render() {
    const {
      alertVisible,
      alertVisible1,
      formAlertdelete,
      editvalue,
      disableValue,
      button,
      textalert,
      makeSelected,
      modeldataOption,
      modelSelected,
      makeyearSelected,
      ownertype,
      regno,
      price,
      location,
      expdate,
      kms,
      colorOption,
      colorSelected
    } = this.state;
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Transaction History</h1>
                  </div>
                  <div className="card-body">
                    {this.state.data && (
                      <Datatable
                        data={this.state.data}
                        columnHeading={this.column}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default TransactionHistory;
