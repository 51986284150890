import React from "react";
import './camera.css'

export default class Camera extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false,
      camid: this.props.id && this.props.id ? this.props.id : 1
    }
    this.videoRef = React.createRef(null)
    this.photoRef = React.createRef(null)
  }
  async componentDidMount() {
    this.getVideo()
  }
  getVideo = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: {
          facingMode: 'environment'
        }
      })
      .then((stream) => {
        let video = this.videoRef.current;
        video.srcObject = stream;
        video.play();
      })
      .catch((err) => {
        console.error(err);
      });
  };
  takePicture = () => {
    this.setState({ clicked: true })
    const twidth = this.props.width && this.props.width ? this.props.width : 400
    const theight = this.props.height && this.props.height ? this.props.height : twidth * (16 / 9)
    let video = this.videoRef.current
    let photo = this.photoRef.current
    photo.width = twidth
    photo.height = theight
    let ctx = photo.getContext('2d')
    ctx.drawImage(video, 0, 0, twidth, theight)
  }
  clearImage = () => {
    let photo = this.photoRef.current
    let ctx = photo.getContext('2d')
    ctx.clearRect(0, 0, photo.width, photo.height)
    this.setState({ clicked: false })
    this.getVideo()
  }
  sendImage = () => {
    var canvas = document.getElementById(`image${this.state.camid}`);
    var dataURL = canvas.toDataURL("image/png");
    this.props.image(dataURL)
    this.clearImage()
  }
  render() {
    let { clicked } = this.state
    return (
      <div className="">
        {/* <div className="row"> */}
        <div className="row">
          <div id='image'>
            <canvas className={`${clicked ? 'clicked' : 'rest'}`} id={`image${this.state.camid}`} ref={this.photoRef}></canvas>
          </div>
        </div>
        {clicked ? <>
          <br />
          <br />
          <div className="row">
            <div className="col">
              <button onClick={this.clearImage} className="btn btn-primary container">Clear </button>
            </div>
            <div className="col">
              <button onClick={this.sendImage} className="btn btn-primary container">OK</button>
            </div>
          </div>
        </> : <>
          <video ref={this.videoRef} className="" style={{ width: '100%' }} ></video>
          <br />
          <button onClick={this.takePicture} className="btn btn-danger container">Take Picture</button>
        </>
        }
        {/* </div> */}
      </div>
    )
  }
}
