import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./SideNav.css";

export default class DifuzaCarsNavTemp extends Component {
    render() {
        let serviceId = localStorage.getItem("currentService");
        let userlog = JSON.parse(localStorage.getItem("userlog"))
        console.log(userlog, "userloguserloguserlog");
        if (userlog && userlog.survey == "SEO") {
            return (
                <div className="sidebar">
                    <nav className="sidebar-nav">
                        <ul className="nav">
                            <li className="nav-item nav-dropdown">
                                <Link className="nav-link nav-dropdown-toggle" href="#">
                                    <i className="nav-icon icon-puzzle" /> Site
                                </Link>
                                <ul className="nav-dropdown-items">
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/DFCarz/SEO">
                                            <i className="nav-icon fa fa-car" />
                                            <span className="link-white">SEO</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </nav>
                </div >
            );
        } else {
            return (
                <div className="sidebar">
                    <nav className="sidebar-nav">
                        <ul className="nav">
                            <li className="nav-item">
                                <Link className="nav-link" to={`/superAdmin`}>
                                    <i className="nav-icon icon-speedometer" />
                                    <span className="link-white">Service Dashboard</span>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link
                                    className="nav-link"
                                    to={`/DifuzaCarz/${serviceId}`}
                                >
                                    <i className="nav-icon icon-speedometer" />
                                    <span className="link-white">Dashboard</span>
                                </Link>
                            </li>
                            <li className="nav-item nav-dropdown">
                                <Link className="nav-link nav-dropdown-toggle" href="#">
                                    <i className="nav-icon icon-puzzle" /> Master
                                </Link>
                                <ul className="nav-dropdown-items">
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/DFCarz/DfCaresUsertype">
                                            <i className="nav-icon fa fa-user" />
                                            <span className="link-white">UserType</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/DFCarz/dfCarsAddDesignation">
                                            <i className="nav-icon fa fa-user" />
                                            <span className="link-white">Map Usertype-Location</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/DFCarz/DfCarsLocation">
                                            <i className="nav-icon fa fa-user" />
                                            <span className="link-white">Location Master</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/DFCarz/DfCarsBranch">
                                            <i className="nav-icon fa fa-user" />
                                            <span className="link-white">Branch Master</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/DFCarz/DfCarsAddUsers">
                                            <i className="nav-icon fa fa-user" />
                                            <span className="link-white">Add User</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/DFCarz/Dfcolormaster">
                                            <i className="nav-icon fa fa-user" />
                                            <span className="link-white">Colour Master</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/DFCarz/Dfmakemaster">
                                            <i className="nav-icon fa fa-user" />
                                            <span className="link-white">Make Master</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/DFCarz/Dfmodelmaster">
                                            <i className="nav-icon fa fa-user" />
                                            <span className="link-white">Model Master</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/DFCarz/Dfvariantmaster">
                                            <i className="nav-icon fa fa-user" />
                                            <span className="link-white">Variant Master</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/DFCarz/Dfnewmakemaster">
                                            <i className="nav-icon fa fa-user" />
                                            <span className="link-white">New Make Master</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/DFCarz/DfcarzSalesCon">
                                            <i className="nav-icon fa fa-user" />
                                            <span className="link-white">Sales consultant</span>
                                        </Link>
                                    </li>

                                </ul>
                            </li>
                            <li className="nav-item nav-dropdown">
                                <Link className="nav-link nav-dropdown-toggle" href="#">
                                    <i className="nav-icon icon-puzzle" /> Site
                                </Link>
                                <ul className="nav-dropdown-items">
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/DFCarz/cmsAddPage">
                                            <i className="nav-icon fa fa-car" />
                                            <span className="link-white">CMS</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/DFCarz/SEO">
                                            <i className="nav-icon fa fa-car" />
                                            <span className="link-white">SEO</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/DFCarz/SiteImages">
                                            <i className="nav-icon fa fa-car" />
                                            <span className="link-white">Images</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/DFCarz/DfCarAdminEnquiry">
                                            <i className="nav-icon fa fa-file" />
                                            <span className="link-white">Enquiry</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/DFCarz/DfCarAdminContact">
                                            <i className="nav-icon fa fa-phone" />
                                            <span className="link-white">Contact Us</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/DFCarz/DfCarAdminAppointment">
                                            <i className="nav-icon fa fa-car" />
                                            <span className="link-white">Appointment</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/DFCarz/sitehome">
                                            <i className="nav-icon fa fa-car" />
                                            <span className="link-white">Featured Car</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/DFCarz/images">
                                            <i className="nav-icon fa fa-car" />
                                            <span className="link-white">Files</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </nav>
                </div >
            );
        }
    }
}
