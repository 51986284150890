import React, { Component } from "react";
import { Alert } from "reactstrap";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import SingleSelect from "../../../../components/Form/SingleSelect";
import redisMiddleware from "../../../../MiddleWare/redisMiddleware";
import CmsContent from "../../../../MiddleWare/CmsContent";
import Preloader from "../preloader.js";
import Datatable from "../../../../components/Datatable/Datatable";
import { ACCESS_POINT } from "../../../../config/index";
import MultiSelect from "../../../../components/Form/MultiSelect";
import moment from "moment";
import { Link } from "react-router-dom";

class Autoterrace_enquiry_form extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      followup: [{ value: 1 }],
      emailmessage: false,
      isEmail: true,
      replace: false,
      mobvalLoader: false,
      LocationID: localStorage.getItem(`LocationID`),
      userid: JSON.parse(localStorage.getItem("userlog")),
      usertypelog: JSON.parse(localStorage.getItem("usertypelog")),
      // ecd: [{ value: 1 }, { value: 2 }, { value: 3 }],
      ecd: [{ value: 1 }],
      mcp: [{ value: 1 }],
      checkbox: false,
      fueloptions: [
        { label: "Petrol", value: 1 },
        { label: "Diesel", value: 2 },
        // { label: "Hybrid", value: 3 },
        // { label: "LPG", value: 4 },
        // { label: "CNG", value: 5 },
      ],
      Kmtoptions: [
        { label: "10000 KMs & Less", value: 1 },
        { label: "30000 KMs & Less", value: 2 },
        { label: "50000 KMs & Less", value: 3 },
        { label: "75000 KMs & Less", value: 4 },
        { label: "100000 KMs & Less", value: 5 },
      ],
      Budgetoptions: [
        { label: "Upto 1Lac", value: 1 },
        { label: "Between 2-3 Lac", value: 2 },
        { label: "Between 3-5 Lac", value: 3 },
        { label: "Between 5-7 Lac", value: 4 },
        { label: "Above 7 Lac", value: 5 }
      ],
      ExpectedDayoptions: [
        { label: "< 7 Days", value: 1 },
        { label: "< 15 Days", value: 2 },
        { label: "< 30 Days", value: 3 },
        { label: "> 30 Days", value: 4 }
      ],
      vechpurchaseopt: [
        { label: "New", value: 1 },
        { label: "Honda", value: 2 },
        { label: "New Honda", value: 3 },
        { label: "From Us", value: 4 },
        { label: "From Competition", value: 5 },
        { label: "Other Organized Player", value: 6 },
        { label: "Local Market", value: 7 },
        { label: "Web Portal", value: 8 },
        { label: "Friend", value: 9 }
      ],
      sources: [{ label: "Walkin Showroom", value: 1 }, { label: "Walk in Event", value: 2 }, { label: "Tele in", value: 3 }, { label: "Tele Out / Sms", value: 4 }, { label: "Web Leads", value: 5 }, { label: "OLX", value: 6 }, { label: "Car Trade", value: 7 }, { label: "Car Dekho", value: 8 }, { label: "Workshop", value: 9 }, { label: "Other Portals", value: 10 }],

      At: [{ label: "AT", value: 1 }, { label: "MT", value: 2 }],
      cartype: [{ label: "Hatch", value: 1 }, { label: "Sedan", value: 2 }, { label: "MUV/SUV", value: 3 }, { label: "Luxury", value: 4 }],
      otherreq: [
        {
          "label": "Finance",
          "value": 0
        },
        {
          "label": "Exchange",
          "value": 1
        },
        {
          "label": "Warranty",
          "value": 2
        },
        {
          "label": "Honda Assure",
          "value": 3
        },
        {
          "label": "RSA",
          "value": 4
        },
        {
          "label": "Accessories",
          "value": 5
        }
      ],
      classificationopt: [
        {
          "label": "Replacement",
          "value": 0
        },
        {
          "label": "Only Selling",
          "value": 1
        },
        {
          "label": "First Time Buying",
          "value": 2
        }
      ],
      newpreownedopt: [
        { label: "New", value: 1 },
        { label: "Pre - Owned", value: 2 },
        { label: "Lost", value: 3 }
      ],
      Reasonoflostopt: [
        { label: "Stock Not Available", value: 1 },
        { label: "Finance Rejected", value: 2 },
        { label: "No Paper for Registration", value: 3 },
        { label: "Better Deal by Co-Dealer/Competition", value: 4 },
        { label: "Better Deal on New Car", value: 5 },
        { label: "Plan Cancelled", value: 6 }
      ],
      status1: [{ label: "Yes, Interested in Buying", value: 0 },
      { label: "Yes, but not interested, Mark closed", value: 1 },
      { label: "Couldn’t speak to customer", value: 2 }
      ],
      status2: [[{ label: "Schedule walkin", value: 0 },
      { label: "Schedule follow up", value: 1 }], [
        { label: "Fake Lead", value: 0 },
        { label: "Customer Bought another car", value: 1 },
        { label: "Customer not picking the call", value: 2 },
        { label: "Desired car not available", value: 3 },
        { label: "price issue", value: 4 },
        { label: "Other", value: 5 }],
      [{ label: "Schedule followup", value: 0 },
      { label: "Mark closed", value: 1 }],
      ],
      yearopn: [],
      carShow: [{ value: 1 }],
      followJson: [],
      statusArray: [],
      answerJson: [],
      json: [],
      button: "Submit",
      alertVisible: false,
      disable: false,
      coloropt: [],
      width: 200

    };
    // console.log(this.state.status2[0][1])
  }
  mainFun = async () => {
    try {
      let service = 10;
      console.log(this.state.userid, "this.state.userid")
      let custumerID = 0, enquiry
      // const form = await redisMiddleware.singleTable("tbl_form", 78, "id");
      const reqopt = await CmsContent.getFreedom("*", "tbl_formquestion", `id=367`, 1, 1);
      this.setState({ reqopt: reqopt.data[0].options })

      let allOpt = await this.decryption('getcar')
      if (allOpt) {
        this.setState({
          makopt: allOpt.makeopt,
          coloropt: allOpt.coloropt,
          varopt: allOpt.variantopt,
          modopt: allOpt.modelopt,
          locopt: allOpt.locopt,
          yearopn: allOpt.yearopt
        })
      }
      const formQues = await CmsContent.getFreedom("*", "tbl_formquestion", `parentid=78`, 1, 1);

      formQues.data.map((ival, i) => {
        if (ival.Orderby == null) {
          ival.Orderby = 1000 + i;
        }
      });
      console.log(formQues.data)
      formQues.data = formQues.data.sort(await CmsContent.compareValues("Orderby", "int", "asc"));
      if (formQues && formQues.data.length > 0) {
        formQues.data.map((ival, n) => {
          if (ival.id == 363) {
            ival.options = this.state.fueloptions;
          }
          if (ival.id == 683) {
            ival.options = this.state.Kmtoptions
          }
          if (ival.id == 358) {
            ival.options = this.state.classificationopt
          }

          if (ival.id == 351) {
            ival.type = ""
          }
          // Vehicle Purchase field
          if (ival.id == 387) {
            ival.type = ""
          }
          // New/Pre Owned Feild
          if (ival.id == 388) {
            ival.type = ""
          }
          if (ival.id == 355) {
            ival.type = "number"
          }
          if (ival.id == 360) {
            ival.options = this.state.makopt;
          }
          if (ival.id == 361) {
            ival.options = this.state.modopt;
          }
          if (ival.id == 513) {
            ival.options = this.state.varopt;
          }
          if (ival.id === 684) {
            ival.options = this.state.yearopn
          }
          if (ival.id == 365) {
            ival.options = this.state.Budgetoptions
          }
          if (ival.id == 366) {
            ival.options = this.state.ExpectedDayoptions
          } if (ival.id === 682) {
            ival.options = this.state.coloropt
          }
          if (ival.id == 387) {
            ival.options = this.state.vechpurchaseopt
          }
          if (ival.id == 388) {
            ival.options = this.state.newpreownedopt;
          }
          if (ival.id == 389) {
            ival.options = this.state.Reasonoflostopt;
          }
          if (ival.id == 357) {
            ival.options = this.state.sources
          }
          if (ival.id == 362) {
            ival.options = this.state.At;
          }
          if (ival.id == 364) {
            ival.options = this.state.cartype;
          }
          if (ival.id == 685) {
            //ival.options = this.state.locopt;
            ival.type = '';
          }
          if (ival.id == 350) {
            //ival.options = this.state.locopt;
            //ival.type = "text";
            var d = new Date();
            // console.log(d)
            var date = d.getDate();
            var month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
            var year = d.getFullYear();
            var hr = d.getHours();
            var min = d.getMinutes();
            var sec = d.getSeconds();
            if (date < 10) date = "0" + date;
            if (month < 10) month = "0" + month;
            var dateStr = year + "-" + month + "-" + date;
            console.log(dateStr, 'datestr');
            this.setState({ date: dateStr })
          }
          if (ival.id == 677) {
            ival.options = this.state.status1;
          }
        });
      }
      this.setState({ global: true })
      if (this.props.cusid) {
        this.setState({ enqId: this.props.cusid, global: false })
      }
      else if (this.props.match.params.cusid) {
        this.setState({ enqId: this.props.match.params.cusid, global: true });
      }
      this.setState({ data: formQues.data });
      let answerJson = this.state.answerJson;
      if (this.state.enqId) {

        enquiry = await CmsContent.getFreedom(
          "*",
          "tbl_dfcarz_enquiryform",
          `id=${this.state.enqId}`,
          1,
          1
        );

        if (enquiry) {
          let pro = enquiry.data[0];
          custumerID = pro.id
          answerJson[351] = this.state.userid.userName;
          answerJson[353] = pro.name;
          answerJson[354] = pro.email;
          answerJson[355] = pro.mobile;
          let enq = enquiry.data[0];
          var dateStr = moment(new Date()).format("YYYY-MM-DD")
          answerJson[350] = dateStr;
          answerJson[356] = enq.address
          if (enq.sources) {
            this.state.sources.map((ival, i) => {
              if (ival.label == enq.sources) {
                answerJson[357] = ival;
              }
            })
          }
          this.state.makopt.map((mval, i) => {
            if (mval.value == enq.Make) {
              answerJson[360] = mval;
            }
          });

          this.state.modopt.map((val, i) => {
            if (val.value == enq.Model) {
              answerJson[361] = val;
            }
          });

          this.state.varopt.map((val, i) => {
            if (val.value == enq.Variant) {
              answerJson[513] = val;
            }
          });


          //Existing Car details
          // if (enq.Make && enq.Model) {
          //   const itemData = await CmsContent.getFreedom(
          //     "*",
          //     "tbl_Dfcars_Garage",
          //     `make=${enq.Make} and model=${enq.Model}`,
          //     "id",
          //     "id"
          //   );
          //   if (itemData) {
          //     this.setState({ item_data: itemData.data, existcar: itemData.data });
          //   }
          // }

          // this.setState({ answerJson })
        }
        ///Matching CCars in pipeline table
        if (answerJson[360] && answerJson[361] && answerJson[513]) {

          const carshow = await CmsContent.getFreedom(
            "*",
            "tbl_Delivery_Seller_Proof",
            `make=${answerJson[360].value} and model=${answerJson[361].value}`,
            1,
            1
          );

          if (carshow.data) {
            this.setState({ secondhand: carshow.data, matchingcar: carshow.data });
            carshow.data.map(async (ival, n) => {
              const approve = await CmsContent.getFreedom(
                "*",
                "tbl_checklist350",
                `id=${ival.id} and approval=0`
              );
              if (approve.data.length) {
              }
            });
          }

        }

      }
      let followJson = [];
      this.state.followup.map((ival, i) => {
        followJson.push({
          date: new Date(),
          remarks: "",
          follow: ""
        });
      });
      let ecdJson = [];
      this.state.ecd.map((ival, i) => {
        ecdJson.push({
          reg: "",
          make: "",
          model: "",
          variant: "",
          my_reg: "",
          km: "",
          eval: ""
        });
      });
      let mcpJson = [];
      this.state.mcp.map((ival, i) => {
        mcpJson.push({
          make: "",
          model: "",
          variant: "",
          fuel: ""
        });
      });
      let carShowJson = [];
      this.state.carShow.map((ival, i) => {
        carShowJson.push({
          reg: "",
          make: "",
          model: "",
          year: "",
          price: ""
        });
      });
      this.setState({ followJson, ecdJson, mcpJson, carShowJson, answerJson });
      if (custumerID) {
        if (enquiry && enquiry.data.length > 0) {
          this.setState({ auto: enquiry.data });
          this.formfilled();
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  async componentDidMount() {
    try {
      if (localStorage.getItem('filled')) {
        let filled = JSON.parse(localStorage.getItem('filled'))
        await this.mainFun()
        let answerJson = []
        for (let index = 0; index < filled.length; index++) {
          let element = filled[index];
          if (element !== null) {
            if (index == 684) {
              answerJson[index] = element
              await this.answers(element, 6, null, null, null, index, 78)
            } else if (index == 354) {
              answerJson[index] = element
              await this.setState({ answerJson })
              await this.emailval()
            } else if (index == 355) {
              answerJson[index] = element
              await this.answers(element, 4, null, null, null, index, 78)
            } else if (index == 361) {
              answerJson[index] = element
              await this.answers(element, 8, null, null, 'selectbox', index, 78)
            } else if (index == 513) {
              answerJson[index] = element
            }
            else {
              answerJson[index] = element
            }
          }
        }
        console.log(answerJson, 'answerJson from didmount')
        this.setState({ answerJson })
        await this.process();
      } else {
        await this.mainFun()
        await this.process();
      }


      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.handleSuccess,
          this.handleError
        );
      } else {
        this.setState({ error: 'Geolocation is not supported' });
      }


    } catch (error) {
      console.error(error);
    }
  }

  handleSuccess = (position) => {
    this.setState({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      error: null
    });
  };

  handleError = (error) => {
    this.setState({ error: error.message });
  };

  existColumn = [
    {
      Header: "Make",
      accessor: "make",
      Cell: d => this.getValueFromArray(d.original.make, this.state.makopt)
    },
    {
      Header: "Model",
      accessor: "model",
      Cell: d => this.getValueFromArray(d.original.model, this.state.modopt)
    },
    {
      Header: "Variant",
      accessor: "variant",
      Cell: d => this.getValueFromArray(d.original.variant, this.state.varopt)
    },
    {
      Header: "Reg",
      accessor: "Reg_no"
    },
    {
      Header: "Price",
      accessor: "price"
    },
    {
      Header: "KMS",
      accessor: "kms"
    },
    {
      Header: "View",
      accessor: "id",
      Cell: d => this.viewPro(d)
    }
  ];
  carshowcolumn = [
    {
      Header: "Make",
      accessor: "make",
      Cell: d => this.getValueFromArray(d.original.make, this.state.makopt)
    },
    {
      Header: "Model",
      accessor: "model",
      Cell: d => this.getValueFromArray(d.original.model, this.state.modopt)
    },
    {
      Header: "variant",
      accessor: "variant",
      Cell: d => this.getValueFromArray(d.original.variant, this.state.varopt)
    },
    {
      Header: "Reg",
      accessor: "reg_no"
    },
    {
      Header: "Price in Rs",
      accessor: "price"
    },
    {
      Header: "Km",
      accessor: "kms"
    }
  ];

  viewPro = d => {
    // console.log(d)
    let id = d.original.id;
    return (
      <center>
        <div>
          <a href={`/DFCars/AT_Specifications_Sheet_Proof/${id}`}>view</a>
        </div>
      </center>
    );
  };

  formfilled = () => {
    console.log("enter");
    let answerJson = this.state.answerJson;
    let followJson = this.state.followJson;
    let ecdJson = this.state.ecdJson;
    let mcpJson = this.state.mcpJson;
    let carShowJson = this.state.carShowJson;
    let auto = this.state.auto[0];
    console.log(auto, 'auto');
    this.setState({ checkbox: true })
    answerJson.id = auto.id;
    // if (auto.date) {
    //   let date = auto.date.split(" ")[0]
    //   console.log(date)
    //   answerJson[350] = date;
    // }
    // answerJson[350] = auto.date;
    if (auto.at_seller) {
      answerJson[351] = auto.at_seller;
    }
    answerJson[352] = auto.dlite_id;
    // answerJson[353] = auto.name;


    if (auto.at_mt) {
      this.state.At.map((ival, i) => {
        if (ival.label == auto.at_mt) {
          answerJson[362] = ival
        }
      })
    }
    this.state.fueloptions.map((fval, n) => {
      console.log(fval);
      if (auto.fuel == fval.label) {
        answerJson[363] = fval;
      }
    });
    if (auto.Km) {
      this.state.Kmtoptions.map((kmval, i) => {
        if (kmval.value == auto.Km) {
          answerJson[683] = kmval;
        }
      });
    }
    if (auto.classification) {
      this.state.classificationopt.map((cval, i) => {
        if (cval.label == auto.classification) {
          answerJson[358] = cval;
        }
      });
    }

    if (this.state.makopt) {
      this.state.makopt.map((mval, i) => {
        if (mval.value == auto.make) {
          answerJson[360] = mval;
        }
      });
    }
    if (this.state.modopt) {
      this.state.modopt.map((kval, m) => {

        if (kval.value == auto.Model) {
          answerJson[361] = kval;
        }
      });
    }
    if (this.state.varopt) {
      this.state.varopt.map((val, n) => {
        if (val.value == auto.variant) {
          answerJson[513] = val;
        }
      });
    }
    //answerJson[363] = auto.fuel;
    if (auto.source) {
      this.state.sources.map((ival, i) => {
        if (ival.label == auto.source) {
          answerJson[357] = ival;
        }
      })
    }
    if (auto.color) {
      this.state.coloropt.map((cval, c) => {
        if (parseInt(cval.value) === parseInt(auto.color)) {
          answerJson[682] = cval
        }
      })
    }
    if (auto.yom) {
      this.state.yearopn.map((cval, c) => {
        if (cval.label == auto.yom) {
          answerJson[684] = cval
        }
      })
    }

    if (auto.requirement) {
      let User = []
      JSON.parse(auto.requirement).map((ival) => {
        console.log(ival);
        this.state.otherreq.map((mval) => {

          if (ival == mval.label) {
            User.push(mval)


            answerJson[367] = User
          }
        })
      });
    }

    // if (auto.classification) {
    //   let User1 = []
    //   JSON.parse(auto.classification).map((ival) => {
    //     console.log(ival);
    //     this.state.classificationopt.map((mval) => {

    //       if (ival == mval.label) {
    //         User1.push(mval)
    //         answerJson[358] = User1
    //       }
    //     })
    //   });
    // }
    if (auto.if_buying) {
      answerJson[359] = auto.if_buying;
    }
    console.log('2');
    // if (auto.Km) { answerJson[683] = auto.Km }
    if (auto.car_type) {
      this.state.cartype.map((ival, i) => {
        if (ival.label == auto.car_type) {
          answerJson[364] = ival;
        }
      })
    }
    if (auto.budget) {
      this.state.Budgetoptions.map((ival, b) => {
        if (ival.label == auto.budget) {
          answerJson[365] = ival
        }
      })
    }
    if (auto.expected_day) {
      this.state.ExpectedDayoptions.map((jval, v) => {
        if (jval.label == auto.expected_day) {
          answerJson[366] = jval
        }
      })
    }

    if (this.state.reqopt) {
      let reqopt = this.state.reqopt.split(",");
      this.setState({ reqopt })
    }
    if (auto.requirement) {
      let opt = auto.requirement.split(",");
      this.setState({ opt })
    }

    if (auto.vech_purchase) {
      this.state.vechpurchaseopt.map((kval, v) => {
        if (kval.label == auto.vech_purchase) {
          answerJson[387] = kval
        }
      })
    }

    if (auto.new_pre) {
      this.state.newpreownedopt.map((lval, b) => {
        if (lval.label == auto.new_pre) {
          answerJson[388] = lval
        }
      })
    }
    if (auto.lost_sale) {
      this.state.Reasonoflostopt.map((jval, b) => {
        if (jval.label === auto.lost_sale) {
          answerJson[389] = jval
        }
      })
    }
    if (auto.pendingStatus1) {
      this.state.status1.map((ival, i) => {
        if (ival.label == auto.pendingStatus1) {
          answerJson[677] = ival
        }
        if (auto.pendingStatus2) {
          this.state.status2[i].map((jval, j) => {
            if (jval.label == auto.pendingStatus2) {
              answerJson[678] = jval
            }
          })
        }
      })
    }


    if (auto.followup) {
      followJson = JSON.parse(auto.followup);
    }

    if (auto.ecd) {
      ecdJson = JSON.parse(auto.ecd);

    }

    if (auto.mcp) {
      mcpJson = JSON.parse(auto.mcp);

    }


    if (auto.car_shown) carShowJson = JSON.parse(auto.car_shown);


    this.setState({
      button: "Update",
      answerJson,
      followJson,
      ecdJson,
      mcpJson,
      carShowJson
    });
    this.process();
  };
  update = async () => {
    const {
      answerJson,
      ecdJson,
      followJson,
      mcpJson,
      carShowJson,
      usertypelog
    } = this.state;
    // console.log(this.state.latitude,"latitude",this.state.longitude,"longitude");
    if (this.state.latitude == '' || this.state.longitude == '' || this.state.latitude == undefined || this.state.longitude == undefined) {
      alert('Please allow permission to access your location');;
      // return false;
    }
    if (!answerJson[355]) {
      alert('Please Enter Mobile Number')
      // this.setState({
      //   alertVisible: true,
      //   textalert: 'Please Enter Mobile Number',
      //   color: 'danger'
      // })
      // this.ScrollTop()
      return false
    }
    if (answerJson[355]) {
      if (!this.phonenumber(answerJson[355])) {
        alert('Please Check Enter Mobile Number')
        // this.setState({
        //   alertVisible: true,
        //   textalert: 'Please Check Entered Mobile Number',
        //   color: 'danger'
        // })
        // this.ScrollTop()
        return false
      }
    }

    this.setState({ btnDisable: true })
    let categoryArray = {};
    let id = answerJson.id;
    console.log(answerJson);

    if (answerJson[351]) {
      categoryArray.at_seller = answerJson[351];
    }
    // categoryArray.dlite_id = answerJson[352];
    if (answerJson[353]) {
      categoryArray.name = answerJson[353];
    }
    if (answerJson[354]) {
      categoryArray.email = answerJson[354];
    }
    if (answerJson[355]) {
      categoryArray.mobile = answerJson[355];
    }
    if (answerJson[356]) {
      categoryArray.address = answerJson[356];
    }
    if (answerJson[360]) {
      categoryArray.Make = answerJson[360].value;
    }
    if (answerJson[682]) {
      categoryArray.color = answerJson[682].value;
    }
    if (answerJson[683]) {
      categoryArray.Km = answerJson[683].value;
    }
    if (answerJson[358]) {
      categoryArray.classification = answerJson[358].label;
    }
    if (answerJson[361]) {
      categoryArray.Model = answerJson[361].value;
    }
    if (answerJson[362]) {
      categoryArray.at_mt = answerJson[362].label;
    }
    if (answerJson[363]) {
      categoryArray.fuel = answerJson[363].label;
    }
    if (answerJson[357]) {
      categoryArray.sources = answerJson[357].label;
    }
    // if (answerJson[358]) {
    //   if (answerJson[358]) {
    //     let check = []
    //     answerJson[358].map((ival) => {
    //       console.log(ival);
    //       if (ival.label) {
    //         check.push(ival.label)
    //       }
    //     })
    //     categoryArray.classification = JSON.stringify(check)
    //   }

    // }
    categoryArray.if_buying = answerJson[359];
    if (answerJson[364]) {
      categoryArray.car_type = answerJson[364].label;
    }
    if (answerJson[365]) {
      categoryArray.budget = answerJson[365].label;
    }
    if (answerJson[366]) {
      categoryArray.expected_day = answerJson[366].label;
    }
    if (answerJson[367]) {
      if (answerJson[367]) {
        let check1 = []
        answerJson[367].map((ival) => {
          if (ival.label) {
            check1.push(ival.label)
          }
        })
        categoryArray.requirement = JSON.stringify(check1)
      }
      // categoryArray.requirement = JSON.stringify(answerJson[367]);
    }
    if (answerJson[387]) {
      categoryArray.vech_purchase = answerJson[387].label;
    }
    if (answerJson[677]) {
      categoryArray.pendingStatus1 = answerJson[677].label
    }
    if (answerJson[678]) {
      categoryArray.pendingStatus2 = answerJson[678].label
    }
    if (answerJson[679]) {
      categoryArray.pendingStatus3 = answerJson[679].label
    }
    if (answerJson[680]) {
      categoryArray.remarks = answerJson[680]
    }

    if (answerJson[684]) {
      categoryArray.yom = answerJson[684].label
    }
    if (answerJson[513]) {
      categoryArray.Variant = answerJson[513].value;
    }
    // categoryArray.companyid = this.state.userid.enqId;
    // categoryArray.enquiry_status = "active"
    categoryArray.companyid = this.state.userid.customerId;
    // categoryArray.salesid = this.state.userid.id;
    if (usertypelog.orderBy == 8) categoryArray.salesid = this.state.userid.id
    categoryArray.updatedby = usertypelog.orderBy != 8 ? this.state.userid.id : null;
    if (answerJson[388]) {
      categoryArray.new_pre = answerJson[388].label;
    }
    if (answerJson[389]) {
      categoryArray.lost_sale = answerJson[389].label;
    }
    // categoryArray.ecd = JSON.stringify(ecdJson);
    // categoryArray.mcp = JSON.stringify(mcpJson);
    // categoryArray.car_shown = JSON.stringify(carShowJson);
    categoryArray.followup = JSON.stringify(followJson);
    categoryArray.type = "buyer";
    categoryArray.location = this.state.LocationID;
    categoryArray.remainder = 1

    categoryArray.latitude = this.state.latitude;
    categoryArray.longitude = this.state.longitude;

    console.log(categoryArray);
    if (answerJson && answerJson[677] && answerJson[677].label == "Yes, Interested in Buying") {
      if (!followJson[0].followup) {
        await this.setState({
          btnDisable: false
        })
        alert("Please Select Next Date to Followup")
        return false
      }
    } else {
      if (!categoryArray.pendingStatus1) {
        await this.setState({
          btnDisable: false
        })
        alert("Please Select Status")
        return false
      }
    }
    try {
      let result = await CmsContent.UpdateSaleEnquiryadd(
        "tbl_dfcarz_enquiryform",
        id,
        categoryArray
      );
      if (result) {
        localStorage.removeItem('filled')
        console.log(result)
      }
      await this.setState({
        btnDisable: false,
        answerJson: "",
        json: "",
        alertVisible: true,
        // textalert: "Your form have been Updated successfully",
        // color: "success"
        textalert: result.data.msg,
        color: result.data.color
      });
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
      setTimeout(() => { this.setState({ alertVisible: false }); window.location.reload() }, 3000);

    } catch (error) {
      console.log(error);
      this.setState({ btnDisable: false })
    }

  };
  async answers(
    label,
    index,
    options = null,
    key = null,
    type,
    questionId,
    formid
  ) {
    console.log("label: ", label)
    console.log('index', index);
    console.log('optons:', options);
    console.log('key: ', key);
    console.log('type: ', type)
    console.log("question: ", questionId);
    console.log('formid: ', formid);

    let get = []
    let varNewOPT = []
    let { answerJson, modopt, data, usertypelog, userid } = await this.state

    if (questionId && questionId == 684) {
      //yom
      let dateofregyear = ""
      dateofregyear = label.label
      await this.setState({ dateofregyear })
      if (answerJson[361]) answerJson[361] = null; //model
      if (answerJson[513]) answerJson[513] = null;//varient


      if (answerJson[360]) {
        //make
        modopt.map((mval, i) => {
          if (mval.makeid == parseInt(answerJson[360].value)) {
            get.push(mval);
          }
        });
        if (get.length && questionId == 684) {
          const manfuc = this.state.yearopn.filter(e => e.value == parseInt(label.value))[0]
          if (manfuc && manfuc != "undefined") {
            get = get.filter(e => e.syear <= manfuc.label && e.eyear >= manfuc.label)
          } else {
            get = []
          }
        }

        data.map((ival, k) => {
          if (ival.id == 361) {
            ival.options = get;
          }
        });

      }
    }
    if (questionId == 360) {
      //make
      if (answerJson[361]) answerJson[361] = null; //model
      if (answerJson[513]) answerJson[513] = null;//varient
      modopt.map((mval, i) => {
        if (mval.makeid == label.value) {
          get.push(mval);
        }
      });
      if (get.length) {
        if (answerJson[684]) {
          const manfuc = this.state.yearopn.filter(e => e.value == parseInt(answerJson[684].value))[0];
          if (manfuc && manfuc != "undefined") {
            get = get.filter(e => e.syear <= manfuc.label && e.eyear >= manfuc.label)
          } else {
            get = []
          }
        }
      }
      data.map((ival, k) => {
        if (ival.id == 361) {
          ival.options = get;
        }
      });

    }
    if (questionId == 361) {
      answerJson[513] = []
      this.state.varopt.map((vval, i) => {
        if (vval.modelid == label.value) {
          varNewOPT.push(vval);
        }
      });
      data.map((ival, k) => {
        if (ival.id == 513) {
          ival.options = varNewOPT;
        }
      });

    }
    //mobile num
    if (questionId == 358) {
      if (label == "Replacement") {
        this.setState({ replace: true })
      }
      else {
        this.setState({ replace: false })
      }
    }
    if (questionId == 388) {
      if (label.value == 1 || label.value == 2) {
        this.state.data.map((ival, i) => {
          if (ival.id == 389) {
            ival.type = ""
          }
        })
      }
      else {
        this.state.data.map((ival, i) => {
          if (ival.id == 389) {
            ival.type = "selectbox"
          }
        })
      }
    }
    if (type == "text") {
      answerJson[questionId] = label;
    } else if (type == "number") {
      answerJson[questionId] = questionId == 355 ? label = label.slice(0, 10) : label;
    } else if (type == "date") {
      var d = new Date(label);
      answerJson[questionId] = label;
    } else if (type == "textarea") {
      answerJson[questionId] = label;
    } else if (type == "selectbox") {
      answerJson[questionId] = label;
      if (questionId === 677) {
        answerJson[678] = ""
        answerJson[679] = ""
        this.state.data.map((ival, i) => {
          if (ival.id === 678) {
            ival.options = this.state.status2[answerJson[677].value]
          } if ((ival.id === 679) && (answerJson[677].value) === 2) {
            ival.options = this.state.status2[1]
          }
        })
      }
    } else if (type == "radio") {
      answerJson[questionId] = label;
    } else if (type == "checkbox") {

      let check = [];
      label.map((ival, i) => {
        check.push(ival.label)
      })//cbm
      this.setState({ opt: check })
      answerJson[questionId] = label
      console.log(check, questionId)
      if (questionId === 367) {
        answerJson[questionId] = label;
      }
      // if (questionId === 358) {
      //   answerJson[questionId] = label;
      // }
    }
    if (questionId == 355) {
      console.log(label, label.length, "mobilenumber");
      if (label.length == 10) {
        await this.setState({ mobvalLoader: true })
      } else {
        await this.setState({ suggestOne: null, suggest: null, mobvalLoader: false })
      }
    }
    console.log(answerJson, 'answerJson');
    await this.setState({ answerJson, data });

    if (questionId == 355) {
      console.log(label, label.length, "mobilenumber");
      if (label.length == 10) {
        await this.setState({ mobvalLoader: true })
        await this.process();
        let query = ''
        // if (![1, 2, 3].includes(usertypelog.orderBy)) {
        //   query = `and userid=${userid.id}`
        // }
        let { data: suggest } = await CmsContent.getFreedom('id as prosid,enquiry_id,userid,mobile', 'tbl_dfcarz_prosperities', `mobile='${label}'  ${query}`)
        if (suggest.length > 0) {
          await this.setState({ suggest })
        } else {
          await this.setState({ suggest: null })
          let { data: suggestOne } = await CmsContent.getFreedom('tbl_user_web.userName,tbl_dfcarz_enquiryform.Make as make,tbl_dfcarz_enquiryform.Model as model,tbl_dfcarz_enquiryform.type as EnqType,tbl_dfcarz_enquiryform.reg_no as reg_no,tbl_dfcarz_enquiryform.name as customerName,tbl_dfcarz_enquiryform.companyid as userid,tbl_dfcarz_enquiryform.mobile', 'tbl_dfcarz_enquiryform,tbl_user_web', `tbl_user_web.id=tbl_dfcarz_enquiryform.companyid and tbl_dfcarz_enquiryform.mobile='${label}'`, 'tbl_dfcarz_enquiryform.id', 'tbl_dfcarz_enquiryform.id desc')
          if (suggestOne.length > 0) {
            await this.setState({ suggestOne })
          } else this.setState({ suggestOne: null })
        }
        await this.setState({ mobvalLoader: false })
      }
      // else this.setState({ suggestOne: null, suggest: null, mobvalLoader: false })
    }
    if ([677, 684, 360, 361, 513, 682, 678, 355, 683, 362, 363, 366, 357, 364, 365, 358, 367, 679].includes(questionId)) { await this.process(); }

  }
  process = async () => {
    console.log('entered process');
    let data = await this.state.data;
    const { ecdJson, followJson, carShowJson, mcpJson, userid, usertypelog } = this.state;

    if (data) {
      let arr = data;
      let contentJson = [];
      if (localStorage.getItem('filled')) {
        await contentJson.push(
          <div className="row">
            <div className="col-sm-10"></div>
            <div className="col-sm-2">
              <button className="btn-sm btn-primary" onClick={() => {
                localStorage.removeItem('filled')
                window.location.reload()
              }}>Clear Form</button>
            </div>
          </div>
        )
      }
      if (arr && arr.length) {
        let answerJson = await this.state.answerJson;
        let answerJsons = answerJson.length;
        console.log(arr, "arr");
        let json = await arr.map(async (item, index) => {
          if (item.type == "text" || item.type == "number") {
            if ((item.id === 680) && (answerJson[677] === this.state.status1[1])) {
              await contentJson.push(
                <div className="bgcolor">
                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                      <label htmlFor="position">{item.question}</label>
                    </div>
                    <div className="col-sm-5">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={item.placeholder}
                        onChange={e =>
                          this.answers(
                            e.target.value,
                            index,
                            null,
                            null,
                            "text",
                            item.id,
                            item.parentid
                          )
                        }
                        value={answerJson[item.id]}


                      />
                      <span className="error-shows" id={`${index}`}>
                        {/* {this.state.error[index]} */}
                      </span>
                    </div>
                    <div className="col-sm-3"></div>
                  </div>
                </div>
              );
            } else if (item.id !== 680) {
              await contentJson.push(
                <div className="bgcolor">
                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                      <label htmlFor="position" style={{ color: item.id == 353 || item.id == 355 ? "orange" : '' }}>{item.question}</label>
                    </div>
                    <div className="col-sm-5">
                      {item.type === "text" ?
                        <input
                          type="text"
                          className="form-control"
                          placeholder={item.placeholder}
                          onChange={e =>
                            this.answers(
                              e.target.value,
                              index,
                              null,
                              null,
                              "text",
                              item.id,
                              item.parentid
                            )
                          }
                          onBlur={item && item.id === 354 ? this.emailval : ""}
                          defaultValue={answerJson[item.id]}
                        // value={answerJson[item.id]}
                        /> : <input
                          type="number"
                          className="form-control"
                          placeholder={item.placeholder}
                          onChange={e =>
                            this.answers(
                              e.target.value,
                              index,
                              null,
                              null,
                              "number",
                              item.id,
                              item.parentid
                            )
                          }
                          value={answerJson[item.id]}
                        />}
                      {item.id == 355 && this.state.mobvalLoader ? "Checking. Please Wait.." : this.state.suggest && item.id == 355 && answerJson[355] && answerJson[355].length == 10 ?
                        <Link to={{
                          pathname: "/DFCars/CustomerHistory",
                          state: { suggest: this.state.suggest, type: 'buyer' }
                        }}
                          onClick={() => {
                            window.$('#status').modal('hide')
                            localStorage.setItem('filled', JSON.stringify(this.state.answerJson))
                          }}
                        >Customer already exists, view more</Link>
                        : this.state.suggestOne && item.id == 355 && answerJson[355] && answerJson[355].length == 10 ? <Link to={{
                          pathname: "/DFCars/CustomerHistory",
                          state: { suggestOne: this.state.suggestOne, suggest: null, type: 'seller' }
                        }}
                          onClick={() => window.$('#status').modal('hide')}
                        >Customer already exists, view more</Link> : answerJson[355] && answerJson[355].length == 10 && item.id == 355 ? "It's a new customer" : ''}
                      <span className="error-shows" id={`${index}`}>
                        {/* {this.state.error[index]} */}
                        {item && item.id === 354 ? this.state.emailmessage : ""}
                      </span>
                    </div>
                    <div className="col-sm-3"></div>
                  </div>
                </div>
              );
            }
          }
          if (item.type == "date") {
            if (answerJson[item.id]) { this.state.date = answerJson[item.id]; }
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="position">{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <input
                      type="date"
                      id="myDate"
                      disabled={usertypelog.orderBy == 2 ? false : true}
                      className="form-control"
                      onChange={e =>
                        this.answers(
                          e.target.value,
                          index,
                          null,
                          null,
                          "date",
                          item.id,
                          item.parentid
                        )
                      }
                      value={this.state.date}
                    // onChange={this.fromDateSelect}
                    // value={selectedstartdate}
                    />
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "textarea") {
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder={item.placeholder}
                      // onChange={e =>
                      //   this.answers(
                      //     e.target.value,
                      //     index,
                      //     null,
                      //     null,
                      //     "textarea",
                      //     item.id,
                      //     item.parentid
                      //   )
                      // }
                      onBlur={e =>
                        this.answers(
                          e.target.value,
                          index,
                          null,
                          null,
                          "textarea",
                          item.id,
                          item.parentid
                        )
                      }
                      defaultValue={answerJson[item.id]}
                    // value={answerJson[item.id]}
                    ></textarea>
                  </div>
                  <div className="col-sm-3">
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                </div>
              </div>
            );
          }
          if (item.type == "radio") {
            let arr1 = [];
            await item.options.split(",").map((element, key) => {
              arr1.push(
                <div className="bgcolor">
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      name={`element${index}`}
                      // value={`${element}`}
                      id={`inlineCheckbox${key}`}
                      onChange={e =>
                        this.answers(
                          element,
                          key,
                          null,
                          key,
                          "radio",
                          item.id,
                          item.parentid
                        )
                      }
                      checked={element == answerJson[item.id]}
                    />
                    <label
                      className="form-check-label"
                      for={`inlineCheckbox${key}`}
                      style={{ marginLeft: "10px" }}
                    >
                      {`${element}`}{" "}
                    </label>
                  </div>
                </div>
              );
            });
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    {arr1}
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "selectbox") {

            let option = [];
            if (item.options) {
              item.options.map((ival, k) => {
                option.push(ival);
              });
            }
            this.state.selectbox = answerJson[item.id];

            if ((answerJson[677] == this.state.status1[2]) && (item.id == 679) && (answerJson[678].value === 1)) {
              await contentJson.push(
                < div className="bgcolor" >
                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                      <label style={{ color: item.id == 357 ? "orange" : '' }}>{item.question}</label>
                    </div>
                    <div className="col-sm-5">
                      <SingleSelect
                        options={option}
                        placeholder={item.placeholder}
                        //disabled={this.state.disable}
                        handleChange={e => {
                          this.answers(
                            e,
                            index,
                            null,
                            null,
                            "selectbox",
                            item.id,
                            item.parentid
                          );
                        }}
                        selectedService={this.state.selectbox}
                      />
                    </div>
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                    <div className="col-sm-3"></div>
                  </div>
                </div >
              );
            }
            else if (item.id !== 679) {
              if (item.id !== 389) {
                await contentJson.push(
                  < div className="bgcolor" >
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label style={{ color: item.id == 677 || item.id == 678 || item.id == 357 ? "orange" : '' }} >{item.question}</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          options={option}
                          placeholder={item.placeholder}
                          //disabled={this.state.disable}
                          handleChange={e => {
                            this.answers(
                              e,
                              index,
                              null,
                              null,
                              "selectbox",
                              item.id,
                              item.parentid
                            );
                          }}
                          selectedService={this.state.selectbox}
                        />
                      </div>
                      <span className="error-shows" id={`${index}`}>
                        {/* {this.state.error[index]} */}
                      </span>
                      <div className="col-sm-3"></div>
                    </div>
                  </div >
                );
              }
            }
          }
          if (item.type == "checkbox") {
            let arr1 = [];
            // console.log(item.options, 'options');
            item.options.split(",").map((element, key) => { arr1.push({ label: element, value: key }) })

            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <MultiSelect
                      options={arr1}
                      handleChange={e => {
                        this.answers(
                          e,
                          index,
                          null,
                          null,
                          "checkbox",
                          item.id,
                          item.parentid)
                      }}
                      selectedService={answerJson[item.id]}
                    />
                  </div>
                  <span className="error-shows" id={`${index}`}>
                    {/* {this.state.error[index]} */}
                  </span>
                  <div className="col-sm-3"></div>
                </div>
              </div>)
          }
        });
        // contentJson.push(
        //   <div>
        //     {this.state.replace && (
        //       <div className="bgcolor">
        //         <div class="set-form">
        //           Existing Car Details
        //           {/* {this.state.item_data && this.state.item_data.length > 0 ? (
        //             <Datatable
        //               data={this.state.item_data}
        //               columnHeading={this.existColumn}
        //             />
        //           ) : null} */}
        //         </div>
        //       </div>
        //     )}
        //   </div>
        // );

        // contentJson.push(
        //   <div>
        //     {this.state.replace && (
        //       <div className="bgcolor">
        //         <div class="set-form">
        //           Matching Cars in pipeline

        //           {this.state.secondhand && this.state.secondhand.length > 0 ? (
        //             <Datatable
        //               data={this.state.secondhand}
        //               columnHeading={this.carshowcolumn}
        //             />
        //           ) : null}
        //         </div>
        //       </div>
        //     )}
        //   </div>
        // );


        if ((answerJson[677] === this.state.status1[0]) || (answerJson[678] === this.state.status2[2][0])) {
          contentJson.push(
            <div className="bgcolor">
              <div class="set-form">
                <table id="myTable" class="table table-bordered">
                  <tr>
                    {/* <th>Date</th> */}
                    <th>REMARKS</th>
                    <th style={{ color: 'orange' }}>Next Date of Follow-up *</th>
                  </tr>
                  {this.state.followJson && this.state.followJson.map((ival, index) => {
                    let date1
                    if (followJson[index] && followJson[index].date) {
                      date1 = moment(new Date(followJson[index].date)).format('YYYY-MM-DD')
                    }
                    return (
                      <tr>
                        {/* <td>
                          <input
                            type="date"
                            id="myDate"
                            className="form-control" s
                            onChange={e =>
                              this.ans(e.target.value, index, "date")
                            }
                            value={
                              followJson[index] && followJson[index].date
                                ? date1
                                : new Date().toLocaleDateString()
                            }
                          // value={new Date().toLocaleDateString()}
                          // onChange={this.fromDateSelect}
                          // value={selectedstartdate}
                          />
                        </td> */}
                        <td>
                          <input
                            type="textarea"
                            name="REMARKS"
                            placeholder="Enter remarks"
                            id="remarks"
                            className="form-control"
                            onChange={e => {
                              this.ans(e.target.value, index, "estimate");
                            }}
                            // value={followJson[index] && followJson[index].remarks ? followJson[index].remarks : null}
                            value={
                              followJson[index] && followJson[index].estimate
                                ? followJson[index].estimate
                                : null
                            }
                            // value={
                            //   bodyJson[ind] && bodyJson[ind].remarks
                            //     ? bodyJson[ind].remarks
                            //     : null
                            // }
                            style={{ resize: "none", width: "100%" }}
                          ></input>
                        </td>
                        <td>
                          <input
                            type="date"
                            name=""
                            placeholder=""
                            className="form-control"
                            // id=""
                            min={moment().format('YYYY-MM-DD')}
                            onChange={e =>
                              this.ans(e.target.value, index, "followup")
                            }
                            style={{ resize: "none", width: "100%" }}
                            value={
                              followJson[index] && followJson[index].followup
                                ? followJson[index].followup
                                : null
                            }
                          // value={followJson[index] && followJson[index].followup ? followJson[index].followup : null}
                          ></input>
                        </td>
                      </tr>
                    );
                  })}
                </table>
                <div className="col-sm-3">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.Addfollow()}
                  >
                    Add More
                  </button>
                </div>
              </div>
            </div>
          );
        }
        await Promise.all(json);
        await this.setState({ json: contentJson, answerJson });
      }
    }
  };
  emailval = async () => {
    let { answerJson } = this.state;
    let emailmessage = this.state
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    console.log(pattern.test(answerJson[354]), "pattern.test(email)", answerJson[354]);
    if (pattern.test(answerJson[354])) {
      console.log("email:   ok");
      emailmessage = (
        <div >
          <em style={{ color: "green" }}><i className="fa fa-check"></i>Valid Email</em>
        </div>
      )
      this.setState({ emailmessage, isEmail: true })
      setTimeout(
        () => this.setState({ emailmessage: false }),
        2000
      );
    } else {
      emailmessage = (
        <div >
          <em style={{ color: "red" }}><i className="fa fa-times "></i>Invalid Email</em>
        </div>
      )
      this.setState({ emailmessage, isEmail: false })
    }
    this.process()
  }
  ans = (label, value, type, index = null) => {
    let followJson = this.state.followJson;
    // followJson[value].name = type;
    followJson[value][type] = label;
    this.setState({ followJson });
  };

  ecdFun = (label, value, type, index = null) => {
    let ecdJson = this.state.ecdJson;
    ecdJson[value][type] = label;
    this.setState({ ecdJson });
  };
  mcpFun = (label, value, type, index = null) => {
    let mcpJson = this.state.mcpJson;
    mcpJson[value][type] = label;
    this.setState({ mcpJson });
  };
  carShowFun = (label, value, type, index = null) => {

    let answerJson = this.state.answerJson;
    let carShowJson = this.state.carShowJson;
    carShowJson[value]["make"] = answerJson[360];
    carShowJson[value]["model"] = answerJson[361];
    carShowJson[value][type] = label;
    console.log(carShowJson)
    this.setState({ carShowJson });
  };
  AddMore = () => {
    let ecdJson = [];
    const { ecd } = this.state;
    ecd.push({ value: 1 + 1 });
    this.state.ecd.map((ival, i) => {
      ecdJson.push({
        reg: "",
        make: "",
        model: "",
        variant: "",
        my_reg: "",
        km: "",
        eval: ""
      });
    });
    this.setState({ ecdJson });
    this.process();
  };
  Addmcp = () => {
    const { mcp } = this.state;
    let mcpJson = [];
    mcp.push({ value: 1 + 1 });
    this.state.mcp.map((ival, i) => {
      mcpJson.push({
        make: "",
        model: "",
        variant: "",
        fuel: ""
      });
    });
    this.setState({ mcpJson });
    this.process();
  };
  Addcar = () => {
    const { carShow } = this.state;
    let carShowJson = this.state.carShowJson;

    carShowJson.push({
      reg: "",
      make: "",
      model: "",
      year: "",
      price: ""
    });
    this.setState({ carShowJson });
    this.process();
  };
  Addfollow = () => {
    const { followup } = this.state;
    let followJson = this.state.followJson;
    //followup.push({ value: 1 + 1 });

    // this.state.followup.map((ival, i) => {

    // });
    followJson.push({
      date: "",
      remarks: "",
      follow: ""
    });
    this.setState({ followJson });
    this.process();
  };
  formSubmit = async () => {
    // let error = [...this.state.error];
    // let e = [];
    // this.state.data.map((item, i) => {
    //   this.state.answerJson.map((element, key) => {
    //     if (
    //       item.required == 1 &&
    //       item.id == element.questionId &&
    //       (element.answer == null || element.answer == "-")
    //     ) {
    //       error[i] = "Value must not be empty";
    //       e.push(error[i]);
    //     } else if (
    //       item.required == 1 &&
    //       item.id == element.questionId &&
    //       (element.answer != null || element.answer != "-")
    //     ) {
    //       error[i] = null;
    //       e.push(error[i]);
    //     }
    //   });
    // });
    // this.setState({ error: error });
    // this.process();

    // for (var i = 0; i < e.length; i++) {
    //   if (e[i] != null) {
    //     return false;
    //   }
    // }
    const {
      answerJson,
      followJson,
      ecdJson,
      mcpJson,
      carShowJson,
      existcar,
      matchingcar,
      usertypelog, isEmail,
      statusArray
    } = this.state;
    // if (!isEmail) {
    //   alert('Please Enter Valid Email Address')
    //   return false
    // }
    let categoryArray = {};
    // console.log(this.state.latitude,"latitude",this.state.longitude,"longitude");
    if (this.state.latitude == '' || this.state.longitude == '' || this.state.latitude == undefined || this.state.longitude == undefined) {
      alert('Please allow permission to access your location');;
      // return false;
    }
    if (!answerJson[353]) {
      alert('Please Enter Buyer name')
      return false;
    }
    if (!answerJson[355]) {
      alert('Please Enter Mobile Number')
      // this.setState({
      //   alertVisible: true,
      //   textalert: 'Please Enter Mobile Number',
      //   color: 'danger'
      // })
      // this.ScrollTop()
      return false
    }
    if (answerJson[355]) {
      if (!this.phonenumber(answerJson[355])) {
        alert('Please Check Entered Mobile Number')
        // this.setState({
        //   alertVisible: true,
        //   textalert: 'Please Check Entered Mobile Number',
        //   color: 'danger'
        // })
        // this.ScrollTop()
        return false
      }
    }
    if (!answerJson[357]) {
      alert('Please Select Source')
      return false
    }
    this.setState({ btnDisable: true })
    if (answerJson[350]) {
      var dateStr = moment(new Date(answerJson[350])).format('YYYY-MM-DD HH:mm:ss');
      // categoryArray.date = dateStr;
    }
    categoryArray.enquiry_Source = "web"
    if (answerJson[351]) {
      categoryArray.at_seller = answerJson[351];
    }
    if (answerJson[353]) {
      categoryArray.name = answerJson[353];
    }
    if (answerJson[354]) {
      categoryArray.email = answerJson[354];
    }
    if (answerJson[355]) {
      categoryArray.mobile = answerJson[355];
    }
    if (answerJson[356]) {
      categoryArray.address = answerJson[356];
    }
    categoryArray.location = this.state.LocationID;
    if (answerJson[684]) {
      categoryArray.yom = answerJson[684].label
    }
    if (answerJson[682]) {
      categoryArray.color = answerJson[682].value;
    }
    categoryArray.remainder = 1
    categoryArray.type = "buyer";
    if (answerJson[360]) {
      categoryArray.Make = answerJson[360].value;
    }
    if (answerJson[361]) {
      categoryArray.Model = answerJson[361].value;
    }
    if (answerJson[513]) {
      categoryArray.Variant = answerJson[513].value;
    }
    if (answerJson[362]) {
      categoryArray.at_mt = answerJson[362].label;
    }
    if (answerJson[363]) {
      categoryArray.fuel = answerJson[363].label;
    }
    if (answerJson[357]) {
      categoryArray.sources = answerJson[357].label;
    }
    // if (answerJson[358]) {
    //   if (answerJson[358]) {
    //     let check = []
    //     answerJson[358].map((ival) => {
    //       if (ival.label) {
    //         check.push(ival.label)
    //       }
    //     })
    //     categoryArray.classification = JSON.stringify(check)
    //   }
    // }
    if (answerJson[359]) {
      categoryArray.if_buying = answerJson[359];
    }
    if (answerJson[364]) {
      categoryArray.car_type = answerJson[364].label;
    }
    if (answerJson[365]) {
      categoryArray.budget = answerJson[365].label;
    }
    if (answerJson[366]) {
      categoryArray.expected_day = answerJson[366].label;
    }
    if (answerJson[367]) {
      if (answerJson[367]) {
        let check1 = []
        answerJson[367].map((ival) => {
          if (ival.label) {
            check1.push(ival.label)
          }
        })
        categoryArray.requirement = JSON.stringify(check1)
      }
    }
    if (answerJson[387]) {
      categoryArray.vech_purchase = answerJson[387].label;
    }
    if (answerJson[388]) {
      categoryArray.new_pre = answerJson[388].label;
    }
    if (answerJson[389]) {
      categoryArray.lost_sale = answerJson[389].label;
    }
    if (answerJson[677]) {
      categoryArray.pendingStatus1 = answerJson[677].label
    }
    if (answerJson[678]) {
      categoryArray.pendingStatus2 = answerJson[678].label
    }
    if (answerJson[679]) {
      categoryArray.pendingStatus3 = answerJson[679].label
    }
    if (answerJson[680]) {
      categoryArray.remarks = answerJson[680]
    }
    if (answerJson[683]) {
      categoryArray.Km = answerJson[683].value
    }
    if (answerJson[358]) {
      categoryArray.classification = answerJson[358].label
    }
    if (this.state.userid) {
      categoryArray.downline = this.state.userid.downline
      categoryArray.downline_type = this.state.userid.userType
    }
    let Userlog = JSON.parse(localStorage.getItem("userlog"))
    if (statusArray && statusArray.length == 0) {
      let obj = {}
      if (answerJson[677]) {
        obj.pendingStatus1 = answerJson[677].label
      }
      else {
        obj.pendingStatus1 = "-"
      }
      if (answerJson[678]) {
        obj.pendingStatus2 = answerJson[678].label;
      }
      else {
        obj.pendingStatus2 = "-"
      }
      if (answerJson[679]) {
        obj.pendingStatus3 = answerJson[679].label;
      }
      else {
        obj.pendingStatus3 = "-"
      }
      if (followJson[0].followup) {
        obj.Next_Contact_date = moment(followJson[0].followup).format('DD-MM-YYYY')
      }
      else {
        obj.Next_Contact_date = "-"
      }
      if (followJson[0].estimate) {
        obj.remarks = followJson[0].estimate
      }
      else {
        obj.remarks = "-"
      }
      obj.sino = statusArray.length + 1
      obj.userName = Userlog.userName
      if (obj) {
        statusArray.unshift(obj)
      }
    }
    // categoryArray.ecd = JSON.stringify(existcar);
    categoryArray.mcp = JSON.stringify(matchingcar);
    categoryArray.car_shown = JSON.stringify(carShowJson);
    categoryArray.followup = JSON.stringify(statusArray);
    // categoryArray.followup = JSON.stringify(followJson);
    if (followJson[0].followup) {
      categoryArray.Next_Contact_date = followJson[0].followup
    }
    categoryArray.companyid = this.state.userid.customerId;
    categoryArray.salesid = usertypelog.orderBy == 8 ? this.state.userid.id : null;
    categoryArray.updatedby = usertypelog.orderBy != 8 ? this.state.userid.id : null;
    categoryArray.branch = usertypelog.orderBy == 1 || usertypelog.orderBy == 2 || usertypelog.orderBy == 3 ? this.state.userid.sub_loc_id : null;
    categoryArray.enquiry_status = "active"

    if (answerJson && answerJson[677] && answerJson[677].label == "Yes, Interested in Buying") {
      if (!followJson[0].followup) {
        await this.setState({
          btnDisable: false
        })
        alert("Please Select Next Date to Followup")
        return false
      }
    } else {
      if (!categoryArray.pendingStatus1) {
        await this.setState({
          btnDisable: false
        })
        alert("Please Select Status")
        return false
      }
    }
    //not available
    // categoryArray.approval = 0;
    // categoryArray.status = "active";
    // categoryArray.enqId = this.state.enqId;
    //categoryArray.mcp = JSON.stringify(mcpJson);
    //categoryArray.ecd = JSON.stringify(ecdJson);
    // categoryArray.requirement = JSON.stringify(answerJson[367]);
    // categoryArray.classification = JSON.stringify(answerJson[358]);
    // categoryArray.at_mt = answerJson[362];
    // categoryArray.location = answerJson[685].value;
    // categoryArray.dlite_id = answerJson[352];

    categoryArray.latitude = this.state.latitude;
    categoryArray.longitude = this.state.longitude;


    console.log(categoryArray);

    try {
      let result = await CmsContent.SalesEnquiryadd(
        "tbl_dfcarz_enquiryform",
        categoryArray
      );
      if (result) {
        localStorage.removeItem('filled')
        await this.setState({
          btnDisable: false,
          answerJson: "",
          json: "",
          alertVisible: true,
          // textalert: "Your form have been submitted successfully",
          textalert: result.data.msg,
          color: result.data.color
        });
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        setTimeout(() => { this.setState({ alertVisible: false }); window.location.reload() }, 3000);
      }
    } catch (error) {
      console.log(error);
      this.setState({ btnDisable: false })
    }
  };
  download = (name) => {
    window.open(`${ACCESS_POINT}/cmsContent/downloadauto/${name}`, '_self')
  }
  generatepdf = async (id, name) => {
    let exportpdf = await CmsContent.autoterrace("tbl_Autoterrace_enquiry_form", id);
    if (exportpdf.data == "success") {
      await this.download(name)
    }
  }


  exportpdf = () => {
    let answerJson = this.state.answerJson;
    let name = answerJson[353]
    window.open(`${ACCESS_POINT}/cmsContent/downloadsellpdf/${name}`, '_self')
  }
  render() {
    const { resSelected, json, button } = this.state;

    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div
                  className="card"
                  style={{
                    margin: "-20px",
                    marginTop: "5px",
                    boxShadow: "0px 0px 10px #b9b3b3",
                    borderRadius: "8px"
                  }}
                >
                  <div className="card-header">
                    <h1>Pre Owned Car Enquiry</h1>
                  </div>
                  <div className="card-body">
                    <div>
                      <Alert
                        className="badge-content"
                        color={this.state.color}
                        isOpen={this.state.alertVisible}
                        toggle={this.onDismiss}
                      >
                        {this.state.textalert}
                      </Alert>
                      {json && json.length ? json : <div>Loading...</div>}
                      {json.length > 0 && (
                        <div>
                          {this.state.global && (
                            <div className="row form-group ">
                              {/* <div className="col-sm-2" />
                              <div className="col-sm-3" /> */}
                              <div className="col text-center">

                                {[1, 2, 3].includes(this.state.usertypelog.orderBy) ? <button
                                  type="button"
                                  style={{ minWidth: 100 }}
                                  className="btn btn-primary"
                                  disabled={this.state.btnDisable}
                                  onClick={
                                    button == "Submit" ? this.formSubmit : this.update
                                  }
                                >
                                  {this.state.btnDisable ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;
                                  {button == "Submit" ? "Submit" : "Update"}
                                </button> : button == "Submit" ? <button
                                  type="button"
                                  style={{ minWidth: 100 }}
                                  className="btn btn-primary"
                                  disabled={this.state.btnDisable}
                                  onClick={
                                    button == "Submit" ? this.formSubmit : this.update
                                  }
                                >
                                  {this.state.btnDisable ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;
                                  {button == "Submit" ? "Submit" : "Update"}
                                </button> : null}

                              </div>

                            </div>
                          )}


                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default Autoterrace_enquiry_form;
