import React, { Component } from "react";
import { Alert } from "reactstrap";
import CmsContent from "../../../../MiddleWare/CmsContent";
import Datatable from "../../../../components/Datatable/Datatable";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import { confirmAlert } from "react-confirm-alert";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import SFAPDF from "../../../../components/PDF/SFAPDF";
import redisMiddleware from "../../../../MiddleWare/redisMiddleware";
import { CopyToClipboard } from "react-copy-to-clipboard";

class DfCarsLocation extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      alertVisible: false,
      alertVisible1: false,
      editvalue: "",
      editid: null,
      longitude: "",
      latitude: "",
      disableValue: false,
      addNewSubgroup: false,
      error: null,
      button: "Submit",
      index: null,
      customerId: localStorage.getItem("userId"),
      serviceId: localStorage.getItem("currentService"),
      formAlertdelete: false,
      textalert: null,
      color: "success"
    };
  }
  async componentWillMount() {
    try {
      this.setState({ loading: true })
      // const result = await redisMiddleware.singleTable(
      //   "tbl_location",
      //   this.state.customerId,
      //   "customerId"
      // );
      const result = await CmsContent.getFreedom("*", 'tbl_location', `customerId=${this.state.customerId} and status='active'`, 1, 1)
      let data = [];
      if (result) {
        result.data.map((item, i) => {
          if (item.serviceid == this.state.serviceId) {
            data.push(item);
          }
        });
        this.setState({ data: data });
      }
      if (result.data.length > 0) {
        const header = ["SI.NO", "LOCATION"];
        const excelHead = [{ label: "LOCATION", key: "name" }];
        this.setState({
          excelHead,
          header,
          excel: result.data,
          title: "LOCATION",
          loading: false
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  column = [
    {
      Header: "Location",
      accessor: "name"
    },
    {
      Header: "Latitude",
      accessor: "latitude"
    },
    {
      Header: "Longitude",
      accessor: "longitude"
    },
    {
      Header: "Edit",
      accessor: "edit",
      Cell: d => this.edit(d)
    },
    // {
    //   Header: <b>Delete</b>,
    //   selector: "delete",
    //   Cell: d =>
    //     this.dataTableButton("danger", "Delete", () => {
    //       this.buttonDeletes(d);
    //     })
    // },
    {
      Header: "Share",
      accessor: "file",
      Cell: d => this.sharelink(d)
    }
  ];
  sharelink = (d) => {
    console.log(d)
    return (
      <div>
        <CopyToClipboard
          text={d.original.name}
        // onCopy={() => this.setState({ copied: true })}
        >
          <button type="button" className="btn btn-primary">
            Copy to clipboard
          </button>
        </CopyToClipboard>
        <br />
        {
          this.state.copied == true && (
            <div>
              <span style={{ color: "green" }}>
                URL is Copied
              </span>
            </div>
          )
        }
      </div>
    )
  }
  edit = d => {
    let value = d;
    return (
      <center>
        <button
          type="button"
          className="btn btn-info"
          onClick={() => this.edition(value)}
        >
          Edit
        </button>
      </center>
    );
  };
  edition = async(value) => {
    let values = value.original;

    let editvalue = values.name;

    await this.setState({
      index: value.index,
      editvalue: values.name,
      editid: values.id,
      latitude: values.latitude ? values.latitude : "",
      longitude: values.longitude ? values.longitude : "",
      error: null,
      button: "Update"
    });
  };
  buttonDeletes = value => {
    confirmAlert({
      title: "Confirmation to Delete",
      message: "Are you sure you want to delete this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.buttonDelete(value)
        },
        {
          label: "No"
        }
      ]
    });
  };
  buttonDelete = async value => {
    const previousData = [...this.state.data];
    let id = value.original.id;
    try {
      // const result = await CmsContent.getSingleConditionedValue(
      //   "tbl_location",
      //   "id",
      //   id,
      //   "Delete"
      // );
      let subgroupArray = {};
      subgroupArray.status = "deactive";
      const result = await CmsContent.updateMaster(
        "tbl_location",
        id,
        subgroupArray
      );
      if (result) {
        let datas = previousData.filter((delelteid, i) => {
          if (delelteid.id !== value.original.id) {
            return delelteid;
          }
        });
        this.setState({
          data: datas,
          alertVisible: true,
          textalert: "Selected Location Deleted",
          color: "danger"
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  handlechange = e => {
    let value = e.target.value;
    this.setState({ editvalue: value });
  };
  handleUserInput = e => {
    this.setState({ [e.target.name]: e.target.value.toUpperCase() });
  };
  addnew = async () => {
    const { editvalue: value, data, latitude, longitude } = this.state;

    if (value === null || value.trim() === "") {
      this.setState({ error: "Please Fill Location" });
      return false;
    }

    // this.checkRepeat(value);
    let filtered_item =
      data && data.length > 0
        ? data.filter(i => {
          return i.name === value;
        })
        : [];

    if (filtered_item.length > 0) {
      this.setState({ error: "Location not be repeated" });
      return false;
    }

    this.setState({ error: "" });

    let groupArray = {};
    groupArray.name = value.trim();
    groupArray.status = "active";
    groupArray.customerId = this.state.customerId;
    groupArray.serviceid = this.state.serviceId;
    groupArray.latitude = latitude;
    groupArray.longitude = longitude;

    try {
      this.setState({ disableValue: true });
      const result = await CmsContent.addMaster("tbl_location", groupArray);

      if (result) {
        let valueArray = {};
        valueArray.id = result.data.insertId;
        valueArray.name = groupArray.name;
        const newData = [valueArray, ...this.state.data];
        this.setState({
          data: newData,
          alertVisible: true,
          editvalue: "",
          editid: null,
          disableValue: false,
          addNewSubgroup: false,
          error: null,
          button: "Submit",
          index: null,
          textalert: "New Location Added",
          color: "success"
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  update = async () => {
    const { editvalue: value, latitude, longitude } = this.state;

    if (value === "") {
      this.setState({ error: "Value Cannot Be Empty" });
      return false;
    } else {
      this.setState({ disableValue: true });
      try {
        let previousdata = [...this.state.data];
        const { index, editid: id } = this.state;

        let subgroupArray = {};
        subgroupArray.name = value;
        subgroupArray.status = "active";
        subgroupArray.latitude = latitude;
        subgroupArray.longitude = longitude;

        const result = await CmsContent.updateMaster(
          "tbl_location",
          id,
          subgroupArray
        );
        if (result) {
          previousdata[index].name = value;
          setTimeout(() => this.setState({ data: previousdata }), 500);
        }
      } catch (error) {
        console.log(error);
      }
      this.setState({
        alertVisible: true,
        longitude: "",
        latitude:"",
        editvalue: "",
        disableValue: false,
        editid: null,
        addNewSubgroup: false,
        error: null,
        button: "Submit",
        index: null,
        textalert: "Location Updated",
        color: "success"
      });
      setTimeout(() => this.setState({ alertVisible: false }), 3000);
    }
  };
  onDismiss = () => {
    this.setState({ alertVisible: false });
    this.setState({ alertVisible1: false });
    this.setState({ formAlertdelete: false });
  };
  render() {
    const {
      editvalue,
      alertVisible,
      disableValue,
      error,
      latitude, longitude,
      button,
      textalert
    } = this.state;
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Location Master</h1>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-7">
                        <Alert
                          className="badge-content"
                          color={this.state.color}
                          isOpen={alertVisible}
                          toggle={this.onDismiss}
                        >
                          {textalert}
                        </Alert>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="subgroup">Location</label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type="text"
                          name="Location"
                          placeholder="Enter Location"
                          className="form-control"
                          value={editvalue}
                          onChange={e => this.handlechange(e)}
                        />
                        <span className="error-shows">{error}</span>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="subgroup">Latitude</label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type="number"
                          name="latitude"
                          placeholder="Enter Latitude"
                          className="form-control"
                          value={latitude}
                          onChange={e => this.handleUserInput(e)}
                        />

                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="subgroup">Longitude</label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type="number"
                          name="longitude"
                          placeholder="Enter Longitude"
                          className="form-control"
                          value={longitude}
                          onChange={e => this.handleUserInput(e)}
                        />

                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2" />
                      <div className="col-sm-5">
                        <button
                          type="button"
                          className="btn btn-primary"
                          disabled={disableValue}
                          onClick={
                            button === "Update" ? this.update : this.addnew
                          }
                        >
                          {this.state.button}
                        </button>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div style={{ marginTop: "5px" }}> </div>
                    {this.state.excel && this.state.excel.length > 0 ? (
                      <PDFDownloadLink
                        style={{ color: "white" }}
                        document={
                          <SFAPDF
                            title={this.state.title}
                            header={this.state.header}
                            data={this.state.excel}
                          />
                        }
                        fileName="SFALocation.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? "Loading document..." : "Download Pdf"
                        }
                        <button className="btn btn-warning">PDF</button>
                      </PDFDownloadLink>
                    ) : null}
                    {this.state.data && this.state.data.length ? (
                      <CSVLink
                        data={this.state.data}
                        headers={this.state.excelHead}
                      >
                        <button
                          className="btn btn-success"
                          style={{ marginLeft: "5px" }}
                        >
                          Excel
                        </button>
                      </CSVLink>
                    ) : null}
                    <br></br>
                    <br></br>

                    <span>
                      Total of {Object.keys(this.state.data).length} records
                    </span>
                    {this.state.loading ? <div className="text-center"> <i
                      className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                    ></i></div> : this.state.data ? (
                      <Datatable
                        data={this.state.data}
                        columnHeading={this.column}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default DfCarsLocation;
