import React, { Component } from "react";
import { ACCESS_POINT } from "../../../../config";
import { Alert } from "reactstrap";
import LoginModal from '../../../../components/Modal/Modal';
import CmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import redisMiddleware from "../../../../MiddleWare/redisMiddleware";
import SingleSelect from "../../../../components/Form/SingleSelect";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { MDBBtn, MDBIcon } from "mdbreact";
import Camera from '../../../../MiddleWare/camera'
// Register the plugin

class Rcform extends FormMiddleWare {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            data1: [],
            json: [],
            makopt: [],
            button: "Submit",
            imagesrc: false,
            varopt: [],
            coloropt: [],
            modopt: [],
            files1: [],
            alertVisible: false,
            id: this.props.match.params.id,
            LocationID: localStorage.getItem(`LocationID`),
            usertypelog: JSON.parse(localStorage.getItem("usertypelog")),
            userid: JSON.parse(localStorage.getItem("userlog")),
            sources: [{ label: "Walkin Showroom", value: 1 }, { label: "Walk in Event", value: 2 }, { label: "Tele in", value: 3 }, { label: "Tele Out / Sms", value: 4 }, { label: "Web Leads", value: 5 }, { label: "OLX", value: 6 }, { label: "Car Trade", value: 7 }, { label: "Car Dekho", value: 8 }, { label: "Workshop", value: 9 }, { label: "Other Portals", value: 10 }],
            Kmtoptions: [
                { label: "10000 KMs & Less", value: 1 },
                { label: "30000 KMs & Less", value: 2 },
                { label: "50000 KMs & Less", value: 3 },
                { label: "75000 KMs & Less", value: 4 },
                { label: "100000 KMs & Less", value: 5 },
            ],
            answersJson: []
        }
    }


    async componentDidMount() {

        try {
            await this.Mainfunc()
        } catch (error) {
            console.log(error);
        }
    }

    Mainfunc = async () => {
        try {
            let service = 10;
            let formQues = await CmsContent.getFreedom("*", "tbl_formquestion", `parentid=107`, 1, 1);
            let makopt = []
            let varopt = []
            let modopt = []
            let coloropt = []
            let { answersJson } = this.state
            formQues.data.map((ival, i) => {
                if (ival.Orderby == null) {
                    ival.Orderby = 1000 + i;
                }
            });
            let allOpt = await this.decryption('getcar')
            if (allOpt) {
                this.setState({
                    makopt: allOpt.makeopt,
                    coloropt: allOpt.coloropt,
                    varopt: allOpt.variantopt,
                    modopt: allOpt.modelopt,
                    makeYearOption: allOpt.yearopt
                })
            }
            formQues.data = formQues.data.sort(await CmsContent.compareValues("Orderby", "int", "asc"));
            if (formQues && formQues.data.length > 0) {
                formQues.data.map((ival, n) => {
                    if (ival.id == 690) {
                        ival.options = this.state.makopt;
                    }
                    if (ival.id == 691) {
                        ival.options = this.state.modopt
                    }
                    if (ival.id == 693) {
                        ival.options = this.state.varopt;
                    }
                    if (ival.id == 695) {
                        ival.options = this.state.coloropt;
                    }
                    if (ival.id == 696) {
                        ival.options = this.state.sources;
                    }
                });
            }
            try {
                let { Kmtoptions } = this.state
                let { data: deliveryrc } = await CmsContent.getFreedom('tbl_dfcarz_prosperities.*,tbl_user_web.userName,tbl_Delivery_Note_Buyer_Reproof.address,price,kms,deli_date,regno,source',
                    'tbl_dfcarz_prosperities,tbl_Delivery_Note_Buyer_Reproof,tbl_user_web',
                    `tbl_user_web.id = tbl_Delivery_Note_Buyer_Reproof.userid and tbl_dfcarz_prosperities.type='buyer' and tbl_dfcarz_prosperities.id = tbl_Delivery_Note_Buyer_Reproof.customerid`,
                    1,
                    "tbl_dfcarz_prosperities.id desc");
                let data1 = [];
                if (deliveryrc) {
                    deliveryrc.map((ival, i) => {
                        if (ival.prospective_status != 'inactive' && parseInt(ival.id) === parseInt(this.state.id)) {
                            data1.push(ival);
                        }
                    });
                }
                let resultrc2 = await CmsContent.getFreedom('*', `tbl_Delivery_RC`, `customerid = ${this.state.id}`, 1, 1);

                if (resultrc2.data) {
                    let imagesrc = []
                    resultrc2.data.map((ival, i) => {
                        if (parseInt(ival.customerid) === parseInt(this.state.id)) {
                            if (ival.Files) {
                                var image = JSON.parse(ival.Files)
                                image.map((item) => {
                                    imagesrc.push(ACCESS_POINT + "/superAdmin/sharefile?filename=" + item)
                                })
                            }
                            this.setState({ button: "Update", imagesrc, editid: ival.id })
                        }
                    })
                }
                if (data1.length && Kmtoptions) {
                    this.setState({ Salesid: data1[0].salesid })
                    answersJson[687] = data1[0].mobile
                    answersJson[688] = data1[0].name
                    answersJson[689] = data1[0].address
                    answersJson[692] = data1[0].year
                    answersJson[694] = data1[0].regno
                    // answersJson[698] = data1[0].kms
                    Kmtoptions.map((ival, i) => {
                        if (data1[0].kms == ival.lab) {
                            answersJson[698] = ival.label
                        }
                    })
                    answersJson[699] = data1[0].price
                    let dateee = data1[0].deli_date;
                    let v = dateee.split(" ")
                    answersJson[700] = v[0];


                    if (data1[0].make) {
                        this.state.makopt.map((ival, i) => {
                            if (parseInt(ival.value) === parseInt(data1[0].make)) {
                                answersJson[690] = ival
                            }
                        })
                        if (!answersJson[690]) {
                            answersJson[690] = data1[0].make
                        }
                    }
                    if (data1[0].model) {
                        this.state.modopt.map((ival, i) => {
                            if (parseInt(ival.value) === parseInt(data1[0].model)) {
                                answersJson[691] = ival
                            }
                        })
                        if (!answersJson[691]) {
                            answersJson[691] = data1[0].model
                        }
                    }
                    if (data1[0].color) {
                        this.state.coloropt.map((ival, i) => {
                            if (parseInt(ival.value) == parseInt(data1[0].color)) {
                                answersJson[695] = ival
                            }
                        })
                        if (!answersJson[695]) {
                            answersJson[695] = data1[0].color
                        }
                    }
                    if (data1[0].variant != null) {
                        this.state.varopt.map((ival, i) => {
                            if (parseInt(ival.value) === parseInt(data1[0].variant)) {
                                answersJson[693] = ival
                            }
                        })
                        if (!answersJson[693]) {
                            answersJson[693] = data1[0].variant
                        }
                    }
                    if (data1[0].source) {
                        this.state.sources.map((ival, i) => {
                            if (ival.label === data1[0].source) {
                                answersJson[696] = ival
                            }
                        })
                        if (!answersJson[696]) {
                            answersJson[696] = data1[0].source
                        }
                    }
                    if (data1) {
                        var d = new Date()
                        var date = d.getDate();
                        if (date < 10) {
                            date = "0" + date
                        }
                        var month = d.getMonth() + 1;
                        if (month < 10) {
                            month = "0" + month
                        }
                        var year = d.getFullYear();
                        answersJson[686] = year + "/" + month + "/" + date

                    }
                }
                console.log(answersJson, 'answersJson');
                await this.setState({ data: formQues.data, answersJson });
                this.process()
            } catch (error) {
                console.log(error)
            }
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    this.handleSuccess,
                    this.handleError
                );
            } else {
                this.setState({ error: 'Geolocation is not supported' });
            }
        } catch (error) {
            console.log(error);
        }
    }

    handleSuccess = (position) => {
        this.setState({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            error: null
        });
    };

    handleError = (error) => {
        this.setState({ error: error.message });
    };

    formsubmit = async () => {
        const { files1, answersJson } = this.state

        // let imagealeart = ''
        // if (files1 && files1.length) {
        //     const fileSize = files1[0].size / 1024 / 1024;
        //     const maxFileSize = 3; // Maximum file size in MB
        //     // Validate file size
        //     if (fileSize > maxFileSize) {
        //         alert("Please Check Image is Greater than 300KB");
        //         imagealeart = true
        //         return true;
        //     }
        // }
        // if (imagealeart == true) {
        //     return false;
        // }
        // console.log(this.state.latitude,"latitude",this.state.longitude,"longitude");
        if (this.state.latitude == '' ||this.state.longitude == '' || this.state.latitude == undefined ||this.state.longitude == undefined  ) {
                  alert('Please allow permission to access your location');;
            // return false;
          }
        const formData = new FormData()
        this.setState({ btnDisable: true })
        formData.append("mobile", answersJson[687]);
        formData.append("from_name", answersJson[688]);
        formData.append("address", answersJson[689]);
        formData.append("year", answersJson[692]);
        formData.append("regno", answersJson[694]);
        formData.append("kms", answersJson[698]);
        formData.append("price", answersJson[699]);
        formData.append("deli_date", answersJson[700]);
        formData.append("customerid", this.state.id);
        // formData.append("userid", this.state.usertypelog.id);
        //formData.append("ip", ip);
        if (answersJson[690] && answersJson[690].value) {
            formData.append("make", answersJson[690].value);
        } else {
            formData.append("make", answersJson[690]);
        }
        if (answersJson[691] && answersJson[691].value) {
            formData.append("model", answersJson[691].value);
        } else {
            formData.append("model", answersJson[691]);
        }
        if (answersJson[693] && answersJson[693].value) {
            formData.append("variant", answersJson[693].value);
        } else {
            formData.append("variant", answersJson[693]);
        }
        if (answersJson[695] && answersJson[695].value) {
            formData.append("color", answersJson[695].value);
        } else {
            formData.append("color", answersJson[695]);
        }
        if (answersJson[696] && answersJson[696].label) {
            formData.append("source", answersJson[696].label);
        } else {
            formData.append("source", answersJson[696]);
        }
        if (files1.length) {
            files1.map((jval, j) => {
                formData.append(`files`, jval);
            });
        }
        if ([1, 2, 3].includes(this.state.usertypelog.orderBy)) {
            formData.append("userid", parseInt(this.state.Salesid));
        } else {
            formData.append("userid", parseInt(this.state.userid.id));
        }
        if ([1, 2, 3].includes(this.state.usertypelog.orderBy)) {
            formData.append("UpdatedBy", this.state.userid.id)
        }
               
    formData.append("latitude", this.state.latitude);

    formData.append("longitude", this.state.longitude);
   
        console.log([...formData])
        try {
            let result = await CmsContent.MultiFileUpload("tbl_Delivery_RC", formData);
            if (result) {
                await this.setState({
                    btnDisable: false,
                    alertVisible: true,
                    textalert: "Your form has been submitted successfully",
                    color: "success"
                });
                setTimeout(() => window.location.reload(), 3000);
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: "smooth"
                });
            }

        } catch (error) {
            console.log(error)
            this.setState({ btnDisable: false })
        }
    }
    formupdate = async () => {
        const { files1, answersJson } = this.state

        // let imagealeart = ''
        // if (files1 && files1.length) {
        //     const fileSize = files1[0].size / 1024 / 1024;
        //     const maxFileSize = 0.31; // Maximum file size in MB
        //     // Validate file size
        //     if (fileSize > maxFileSize) {
        //         alert("Please Check Image is Greater than 300KB");
        //         imagealeart = true
        //         return true;
        //     }
        // }
        // if (imagealeart == true) {
        //     return false;
        // }
     
// console.log(this.state.latitude,"latitude",this.state.longitude,"longitude");
if (this.state.latitude == '' ||this.state.longitude == ''  || this.state.latitude == undefined ||this.state.longitude == undefined ) {
          alert('Please allow permission to access your location');;
    // return false;
  }
        const formData = new FormData()
        this.setState({ btnDisable: true })
        formData.append("id", this.state.editid);
        formData.append("mobile", answersJson[687]);
        formData.append("from_name", answersJson[688]);
        formData.append("address", answersJson[689]);
        formData.append("year", answersJson[692]);
        formData.append("regno", answersJson[694]);
        formData.append("kms", answersJson[698]);
        formData.append("price", answersJson[699]);
        formData.append("deli_date", answersJson[700]);
        formData.append("customerid", this.state.id);
        // formData.append("userid", this.state.usertypelog.id);
        //formData.append("ip", ip)
        if (answersJson[690] && answersJson[690].value) {
            formData.append("make", answersJson[690].value);
        } else {
            formData.append("make", answersJson[690]);
        }
        if (answersJson[691] && answersJson[691].value) {
            formData.append("model", answersJson[691].value);
        } else {
            formData.append("model", answersJson[691]);
        }
        if (answersJson[693] && answersJson[693].value) {
            formData.append("variant", answersJson[693].value);
        } else {
            formData.append("variant", answersJson[693]);
        }
        if (answersJson[695] && answersJson[695].value) {
            formData.append("color", answersJson[695].value);
        } else {
            formData.append("color", answersJson[695]);
        }
        if (answersJson[696] && answersJson[696].label) {
            formData.append("source", answersJson[696].label);
        } else {
            formData.append("source", answersJson[696]);
        }
        if (files1.length) {
            files1.map((jval, j) => {
                formData.append(`files`, jval);
            });
        }
        if ([1, 2, 3].includes(this.state.usertypelog.orderBy)) {
            formData.append("userid", parseInt(this.state.Salesid));
        } else {
            formData.append("userid", parseInt(this.state.userid.id));
        }
        if ([1, 2, 3].includes(this.state.usertypelog.orderBy)) {
            formData.append("UpdatedBy", this.state.userid.id)
        }
               
    formData.append("latitude", this.state.latitude);

    formData.append("longitude", this.state.longitude);
   
        console.log([...formData])
        try {
            let result = await CmsContent.MultiFileUpload("tbl_Delivery_RC", formData, this.state.editid);
            if (result) {
                await this.setState({
                    btnDisable: false,
                    alertVisible: true,
                    textalert: "Your form has been Updated successfully",
                    color: "success"
                });
                setTimeout(() => window.location.reload(), 3000);
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: "smooth"
                });
            }
        } catch (error) {
            console.log(error)
            this.setState({ btnDisable: false })
        }
    }
    answers = async (label, index, type, questionId) => {
        const answersJson = []
        if (type === "date") { answersJson[questionId] = label }
        if (type === "text") { answersJson[questionId] = label }
        if (type === "textarea") { answersJson[questionId] = label }
        if (type === "number") { answersJson[questionId] = label }
        if (type === "selectbox") { answersJson[questionId] = label }
        await this.setState({ answersJson });
        this.process();
    }
    webcam2 = (e, field, ival = null) => {
        let Files = this.state[field];
        if (e) {
            Files = Files ? Files : []
            Files.push({ source: e })
            this.setState({ [field]: Files })
            if (ival) this.setState({ [ival]: false })
            this.process()
        }
    }
    proOnclick = (Field, value) => {
        this.setState({ [Field]: value })
        this.process()
    }
    process = async () => {
        let data = await this.state.data;
        if (data.length) {
            let contentJson = [];
            const { answersJson } = this.state
            let json = await data.map(async (item, index) => {
                if (item.type === "date") {
                    await contentJson.push(<div className="bgcolor">
                        <div className="row form-group">
                            <div className="col-sm-2" />
                            <div className="col-sm-2">
                                <label htmlFor="position">{item.question}</label>
                            </div>
                            <div className="col-sm-5">
                                {item.id === 686 ? <label htmlFor="position">{answersJson[686]}</label> : <input
                                    type="text"
                                    disabled
                                    className="form-control"
                                    // placeholder={item.placeholder}
                                    Value={answersJson[item.id]}
                                // onChange={e => this.answers(
                                //     e.target.value, index, "date", item.id
                                // )}
                                />}
                                <span className="error-shows" id={`${index}`}>
                                </span>
                            </div>
                            <div className="col-sm-3"></div>
                        </div>
                    </div>)
                }
                if (item.type === "text") {
                    await contentJson.push(<div className="bgcolor">
                        <div className="row form-group">
                            <div className="col-sm-2" />
                            <div className="col-sm-2">
                                <label htmlFor="position">{item.question}</label>
                            </div>
                            <div className="col-sm-5">
                                <input
                                    type="text"
                                    disabled
                                    className="form-control"
                                    placeholder={item.placeholder}
                                    value={answersJson[item.id]}
                                    onChange={e => this.answers(
                                        e.target.value, index, "text", item.id
                                    )}
                                />
                                <span className="error-shows" id={`${index}`}>
                                </span>
                            </div>
                            <div className="col-sm-3"></div>
                        </div>
                    </div>)
                }
                if (item.type === "textarea") {
                    await contentJson.push(<div className="bgcolor">
                        <div className="row form-group">
                            <div className="col-sm-2" />
                            <div className="col-sm-2">
                                <label htmlFor="position">{item.question}</label>
                            </div>
                            <div className="col-sm-5">
                                <textarea
                                    disabled
                                    className="form-control"
                                    placeholder={item.placeholder}
                                    value={answersJson[item.id]}
                                    onChange={e => this.answers(
                                        e.target.value, index, "textarea", item.id
                                    )} />
                                <span className="error-shows" id={`${index}`}>
                                </span>
                            </div>
                            <div className="col-sm-3"></div>
                        </div>
                    </div>)
                }
                if (item.type === "selectbox") {
                    let option = [];
                    if (item.options) {
                        item.options.map((ival, k) => {
                            option.push(ival);
                        });
                    }
                    await contentJson.push(<div className="bgcolor">
                        <div className="row form-group">
                            <div className="col-sm-2" />
                            <div className="col-sm-2">
                                <label htmlFor="position">{item.question}</label>
                            </div>
                            <div className="col-sm-5">
                                <SingleSelect
                                    disabled
                                    options={option}
                                    placeholder={item.placeholder}
                                    selectedService={answersJson[item.id]}
                                    handleChange={e => {
                                        this.answers(
                                            e, index, "selectbox", item.id
                                        )
                                    }} />
                                <span className="error-shows" id={`${index}`}>
                                </span>
                            </div>
                            <div className="col-sm-3"></div>
                        </div>
                    </div>)
                }
                if (item.type === "number") {
                    await contentJson.push(<div className="bgcolor">
                        <div className="row form-group">
                            <div className="col-sm-2" />
                            <div className="col-sm-2">
                                <label htmlFor="position">{item.question}</label>
                            </div>
                            <div className="col-sm-5">
                                <input
                                    type="number"
                                    disabled
                                    className="form-control"
                                    placeholder={item.placeholder}
                                    value={answersJson[item.id]}
                                    onChange={e => this.answers(
                                        e.target.value, index, "number", item.id
                                    )}
                                />
                                <span className="error-shows" id={`${index}`}>
                                </span>
                            </div>
                            <div className="col-sm-3"></div>
                        </div>
                    </div>)
                }
            })
            contentJson.push(<div className="bgcolor">
                <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                        <label htmlFor="position">RC File {<button className="btn btn-default btn-sm" value={this.state.Isfiles1Cam} onClick={() => this.proOnclick("Isfiles1Cam", !this.state.Isfiles1Cam)}>
                            <MDBIcon icon="camera" size="lg" />
                        </button>}
                        </label>
                    </div>
                    <div className="col-sm-5">
                        {this.state.Isfiles1Cam ? <Camera
                            image={(e) => { this.webcam2(e, 'files1', 'Isfiles1Cam') }}
                            width={300}
                            height={400}
                            id={'Isfiles1Cam'}
                        /> :
                            <div><FilePond
                                files={this.state.files1}
                                allowReorder={true}
                                allowMultiple={true}
                                allowImageResize={true}
                                imagePreviewMinHeight="50"
                                imagePreviewMaxHeight="50"
                            //    maxFileSize={'3072KB'}
                                // imageResizeMode="cover"
                                acceptedFileTypes={["image/*", "application/pdf"]}
                                onupdatefiles={fileItems => {
                                    this.fileupload(fileItems);
                                }}
                                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                            />
                                {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label> &nbsp;&nbsp;
                                <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                            </div>} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        {this.state.imagesrc.length ?

                            < LoginModal
                                buttonTitle="View Rc file"
                                title="Image"
                                id='model'
                                extraClass="btn btn-primary"
                                onClick=""
                                extraStyle={{ width: '100%' }}
                                bodyText={<div>
                                    <div className="card-body">
                                        {this.state.imagesrc.map((ival, i) => {
                                            if (ival) {
                                                let spliting = ival.slice(ival.length - 3)
                                                // console.log(ival, spliting);
                                                if (['pdf', 'doc', 'xlsx', 'csv'].includes(spliting)) {
                                                    let a = null
                                                    a = "false/pdfIconTrans_1599478202382.png";

                                                    return (
                                                        <div >
                                                            <div >
                                                                <img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + a} onClick={() => { window.open(ival) }} style={{
                                                                    width: "200px", height: "200px", display: "inline-block", padding: "10px", float: "left"
                                                                }} ></img>
                                                            </div>
                                                        </div>)
                                                } else {

                                                    return (
                                                        <div >
                                                            <div >
                                                                <img src={ival} onClick={() => { window.open(ival) }} style={{
                                                                    width: "200px", height: "200px", display: "inline-block", padding: "10px", float: "left"
                                                                }} ></img>
                                                            </div>
                                                        </div>)
                                                }
                                            }
                                        })
                                        }
                                    </div>
                                </div>
                                }
                            />
                            : null}
                    </div>
                    <div className="col-sm-3"></div>
                </div>
            </div >
            )
            await Promise.all(json);
            await this.setState({ json: contentJson, answersJson })
        }
    }
    fileupload = (e) => {
        let files1 = this.state.files1;
        if (e) {
            files1 = e.map(fileItem => fileItem.file)
            this.setState({ files1 });
        }
    }
    onDismiss = () => {
        this.setState({ alertVisible: false });
    };
    render() {
        //console.log(this.state.imagesrc)
        const { json, button } = this.state
        return (<React.Fragment>
            <main className="main my-4">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h1>Rc Form</h1>
                                </div>
                                <div className="card-body">
                                    <Alert
                                        className="badge-content"
                                        color={this.state.color}
                                        isOpen={this.state.alertVisible}
                                        toggle={this.onDismiss}
                                    >
                                        {this.state.textalert}
                                    </Alert>

                                    {json && json.length ? json : <div>Loading...</div>}
                                </div>
                                <div className="card-footer">
                                    <center>
                                        {json && json.length ? [1, 2, 3,14].includes(this.state.usertypelog.orderBy) ? <button
                                            className="btn btn-primary"
                                            disabled={this.state.btnDisable}
                                            onClick={button == "Submit" ? this.formsubmit : this.formupdate}>

                                            {this.state.btnDisable ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;
                                            {button}
                                        </button> : button == "Submit" ? <button
                                            className="btn btn-primary"
                                            disabled={this.state.btnDisable}
                                            onClick={this.formsubmit}>
                                            {this.state.btnDisable ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;
                                            {button}
                                        </button> : null : null}
                                    </center>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </React.Fragment>)
    }
}
export default Rcform;