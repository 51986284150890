import React, { Component } from 'react';
import Utilities from '../../../../MiddleWare/Utilities';

export default class DifuzaCarsDashboard extends Component {
  componentDidMount() {
    Utilities.serviceList(this.props.match.params.id);
  }

  render() {
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3>Difuza-Cars Buy/Sell</h3>
                  </div>
                  <div className="card-body">
                    <h4>Difuza-Cars Buy/Sell Admin dashboard</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
