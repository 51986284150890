import React, { Component } from "react";
import redisMiddleware from "../../../../MiddleWare/redisMiddleware";
import CmsContent from "../../../../MiddleWare/CmsContent";
import ReportCharts from "../TL/ReportCharts";
import moment from "moment";


export default class Manager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      usertypeName: "",
      TodayNotifiy: [],
      PendingTask: [],
      TodayEvaluation: [],
      PendingEvalutaion: [],
      PendingDocumenatation: [],
      PendingRefurbishment: [],
      PendingPayment: [],
      PendingProcess: [],
      PendingContracted: [],
      PendingDelivery: [],
      LostSale: [],
      PendingRefurbishmentApproval: [],
      RC_bookprovided: [],
      TodayContractedSourcing: [],
      TodayEnquiryBrand: [],
      PendingEnquiryBrand: [],
      Cars: [],
      datamore: [],
      dataless: [],
      buy: true,
      sell: false,
      LocationID: localStorage.getItem(`LocationID`),
    };
  }
  async componentDidMount() {
    let Userlog = JSON.parse(localStorage.getItem("userlog"));
    console.log(Userlog)
    // console.log(this.state.LocationID)
    try {
      const result = await redisMiddleware.singleTable(
        "tbl_UserType",
        Userlog.userType,
        "id"
      );
      if (result) {
        this.setState({
          data: result.data,
          usertypeName: result.data[0].usertype,
        });
      }

      let Result = await CmsContent.getFreedom(
        `tbl_dfcarz_prosperities.*,tbl_user_web.userName,tbl_FProof.id as F_id,tbl_BProof.id as B_id,tbl_FProof.approval as F_approval,tbl_BProof.approval as B_approval ,tbl_BProof.createdAt as B_date,tbl_FProof.createdAt as F_date`,
        `tbl_dfcarz_prosperities,tbl_FProof,tbl_BProof,tbl_user_web`,
        `tbl_dfcarz_prosperities.id = tbl_FProof.customerid and tbl_dfcarz_prosperities.id = tbl_BProof.customerid and tbl_BProof.userid = tbl_user_web.id`,
        1,
        1
      );

      let Result1 = await CmsContent.getFreedom(
        `*`,
        `tbl_dfcarz_prosperities`,
        1
      );
      // console.log(Result1)
      // let Result2 = await redisMiddleware.singleTable(
      //   "tbl_dfcarz_prosperities",
      //   "id",
      //   "id"
      // );
      //console.log(Result2)
      if (Result1) {
        let PendingEvalutaion = [];
        Result1.data.map(async (ival, i) => {
          if (ival.type == null || ival.type == "seller") {
            let Result2 = await CmsContent.getFreedom(
              `tbl_FProof.*`,
              `tbl_FProof,tbl_BProof`,
              `tbl_FProof.customerid = ${ival.id} and tbl_BProof.customerid=${ival.id}`,
              1
            );
            if (!Result2.data.length) {
              PendingEvalutaion.push(ival);
            }
          }
        });
        this.setState({ PendingEvalutaion });
      }

      // console.log(Result1.data);
      //  if(Result){
      //    let TodayEvaluation=[];
      //    let PendingEvalutaion=[];
      //      //console.log(Result.data);
      //       Result.data.map((ival,i)=>{
      //           let lastdate =  new Date(new Date(ival.B_date).getTime() + 60 * 60 * 24 * 1000);
      //           let currentdate = new Date();
      //           let a = lastdate.getDate();
      //           let b = currentdate.getDate();
      //           //console.log(a,b)
      //           if(b<=a){
      //             TodayEvaluation.push(ival);
      //           }else{
      //             //console.log(ival)
      //             PendingEvalutaion.push(ival);
      //           }
      //       });
      //this.setState({TodayEvaluation,PendingEvalutaion})
      const Product = await CmsContent.getFreedom(
        "*",
        "tbl_dfcarz_enquiryform",
        1
      );
      let TodayNotifiy = [];
      let PendingTask = [];
      let CompletedTasks = [];
      if (Product) {
        Product.data.map((ival, i) => {
          let EnquiryDate = new Date();
          let d = new Date(ival.createdAt);
          // (
          //   new Date(ival.createdAt).getTime() + 60 * 60 * 24 * 1000
          // );
          //
          let a = EnquiryDate.getDate();
          let b = d.getDate();

          let Datetime1 = EnquiryDate.getTime();
          let Datetime2 = d.getTime();
          // console.log(Datetime2,Datetime1);
          if (ival.enquiry_status == "active") {
            if (b == a) {
              TodayNotifiy.push(ival);
              //.log(ival);
            } else if (Datetime1 > Datetime2) {
              PendingTask.push(ival);
            }
          } else {
            CompletedTasks.push(ival);
          }
        });
        this.setState({ TodayNotifiy, PendingTask, CompletedTasks });
        this.Documentation();
        this.AT_Inventory_Sheet_Reproof();
        this.Refurbishment();
        this.Delivery_Note_Seller_Proof();
        this.tbl_Delivery_Note_Buyer_Reproof();
        this.tbl_Booking_Confirmation_Reproof();
        this.PendingRefurbishmentApproval();
        this.tbl_Autoterrace_enquiry_form();
        this.Lostsells();
        this.RC_bookprovided();
        this.ReadyForSale();
        this.CheckPoint();

        // let EnquiryData = await CmsContent.getFreedom(
        //   `tbl_dfcarz_prosperities.*,tbl_FProof.trade_in_price,tbl_make_master.Make,tbl_dfcarsmodel.Model,tbl_colour.colour as ColourName`,
        //   `tbl_dfcarz_prosperities,tbl_make_master,tbl_dfcarsmodel,tbl_colour,tbl_FProof`,
        //   `tbl_FProof.customerid = tbl_dfcarz_prosperities.id and tbl_colour.id = tbl_dfcarz_prosperities.color and tbl_make_master.id = tbl_dfcarz_prosperities.Make and tbl_dfcarsmodel.id = tbl_dfcarz_prosperities.Model`,
        //   1,
        //   `tbl_dfcarz_prosperities.id DESC`
        // );
        // console.log(EnquiryData)
        let EnquiryData = await redisMiddleware.Dashboard("TodayEvaluation", 2, this.state.LocationID);
        // console.log(Data)
        let PriceList = await CmsContent.getFreedom(`*`, `tbl_prize_list`, 1);
        //  console.log(EnquiryData.data);
        // console.log(PriceList.data);
        let sum = "";
        let Finaldata = [];
        EnquiryData.data.map((ival, i) => {
          PriceList.data.map((jval, j) => {
            if (
              ival.make == jval.make &&
              ival.model == jval.model
            ) {
              // &&
              // ival.color == jval.color
              // sum = ival.Budget - jval.price;
              // console.log(sum);
              ival.CompanyPrice = jval.price;
              ival.CustomerPrice = ival.trade_in_price;
              // if (sum >= 30000) {
              //   Finaldata.push(ival);
              // }

              if (ival.trade_in_price < jval.price && ival.trade_in_price != null) {
                sum = jval.price - ival.trade_in_price;
                if (sum >= 30000) {
                  Finaldata.push(ival);
                }
              }
            }
          });
        });
        // console.log(Finaldata);
        if (Finaldata) {
          let TodayEnquiryBrand = [];
          let PendingEnquiryBrand = [];
          Finaldata.map((ival, i) => {
            let lastdate = new Date(ival.created_At);
            let currentdate = new Date();
            let Ltime = new Date(
              new Date(ival.createdAt).getTime() + 60 * 60 * 24 * 1000
            );
            // console.log(currentdate.getTime(),lastdate.getTime());
            if (currentdate.getDate() == lastdate.getDate()) {
              TodayEnquiryBrand.push(ival);
            } else if (currentdate.getTime() > Ltime.getTime()) {
              // console.log(ival);
              PendingEnquiryBrand.push(ival);
            }
            ival.sum = sum;
          });

          this.setState({ TodayEnquiryBrand, PendingEnquiryBrand });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  CheckPoint = async () => {
    let len = [];
    // console.log("check")
    // let Refurbishment = await CmsContent.getFreedom("tbl_checklist350.*,tbl_checklist350.Points,tbl_dfcarz_prosperities.id as value,tbl_dfcarz_prosperities.name,tbl_dfcarz_prosperities.email,tbl_dfcarz_prosperities.mobile",
    //   `tbl_dfcarz_prosperities,tbl_checklist350`,
    //   `tbl_dfcarz_prosperities.id=tbl_checklist350.customerid and tbl_dfcarz_prosperities.location=${this.state.LocationID}`, 1
    // );
    // console.log(Refurb)
    let Refurbishment = await redisMiddleware.Dashboard("CheckPoint", 2, this.state.LocationID)
    console.log(Refurbishment.data)
    if (Refurbishment.data.length) {
      let data = Refurbishment.data;
      let json = [];
      let final = [];
      let dataless = [];
      let datamore = [];
      let less = [];

      Refurbishment.data.map((ival, i) => {
        if (ival.Points < 40 || ival.Points == null || ival.Points == "-") {
          ival.Points = 0
          dataless.push(ival)
        }
      })
      this.setState({ dataless })
      Refurbishment.data.map((ival, i) => {
        if (ival.Points > 40) {
          datamore.push(ival)
        }
      })
      this.setState({ datamore })
    }
  }
  ReadyForSale = async () => {
    try {
      // let result = await CmsContent.getFreedom(
      //   `tbl_items.*,tbl_make_master.Make as makename,tbl_dfcarsmodel.Model as modelname,tbl_colour.colour as ColourName`,
      //   `tbl_items,tbl_make_master,tbl_dfcarsmodel,tbl_colour`,
      //   `tbl_items.make=tbl_make_master.id and tbl_dfcarsmodel.id = tbl_items.model and tbl_colour.id = tbl_items.colour`,
      //   1,
      //   1
      // );
      let result = await redisMiddleware.Dashboard("ReadyForSale", 2, this.state.LocationID)
      if (result) {
        let Cars = [];
        result.data.map((ival, i) => {
          if (ival.status != "inactive") {
            let Date1 = new Date(ival.created_At);
            let Currentdate = new Date();
            if (
              Date1.getDate() == Currentdate.getDate() &&
              Date1.getMonth() == Currentdate.getMonth()
            ) {
              Cars.push(ival);
            }
          }
        });

        this.setState({ Cars });
        //  console.log(Cars);
      }
    } catch (error) {
      console.log(error);
    }
  };

  Lostsells = async () => {
    try {
      // let Result = await CmsContent.getFreedom(
      //   "tbl_dfcarz_prosperities.*,tbl_user_web.userName as jobroleName",
      //   `tbl_dfcarz_prosperities,tbl_user_web`,
      //   `tbl_dfcarz_prosperities.type='buyer' and tbl_dfcarz_prosperities.prospective_status ='inactive' and tbl_user_web.id = tbl_dfcarz_prosperities.userid`,
      //   1,
      //   1
      // );
      // console.log('lost', Result)
      let Result = await redisMiddleware.Dashboard("Lostsells", 2, this.state.LocationID)
      // console.log(Result)
      if (Result) {
        let LostSale = [];
        //console.log(Result);
        this.setState({ LostSale: Result.data });
      }
    } catch (error) {
      console.log(error);
    }
  };

  PendingRefurbishmentApproval = async () => {
    try {
      // let Result = await CmsContent.getFreedom(
      //   `tbl_dfcarz_prosperities.*,tbl_checklist350.approval,tbl_user_web.userName as jobroleName,tbl_checklist350.reg_no as regno`,
      //   `tbl_checklist350,tbl_dfcarz_prosperities,tbl_user_web`,
      //   `tbl_checklist350.customerid = tbl_dfcarz_prosperities.id and tbl_user_web.id = tbl_checklist350.userid and tbl_dfcarz_prosperities.location=${this.state.LocationID}`
      // );
      // console.log('penapr', Result)
      let Result = await redisMiddleware.Dashboard("PendingRefurbishmentApproval", 2, this.state.LocationID)
      // console.log(Result)
      const Fproof = await CmsContent.getFreedom(
        `recondition_est,customerid`,
        `tbl_FProof`,
        1
      );
      //  if(Fproof){
      // console.log(Fproof.data)
      // console.log(Result.data)
      //   }

      Result.data.map((ival, i) => {
        Fproof.data.map((jval, j) => {
          if (ival.id == jval.customerid) {
            ival.recondition_est = jval.recondition_est;
          }
        });
      });

      let PendingRefurbishmentApproval = [];

      if (Result) {
        Result.data.map((ival, i) => {
          // console.log(ival);
          //  console.log(ival.recondition_est);
          if (ival.approval == 0 || ival.approval == null) {
            if (ival.recondition_est > 10000) {
              //console.log(ival);
              PendingRefurbishmentApproval.push(ival);
            }
          }
        });
      }

      this.setState({ PendingRefurbishmentApproval });
    } catch (error) {
      console.log(error);
    }
  };

  RC_bookprovided = async () => {
    try {
      // let Buyer = await CmsContent.getFreedom(
      //   `tbl_dfcarz_customers.*,tbl_user_web.userName as salesname`,
      //   `tbl_dfcarz_customers,tbl_user_web`,
      //   `tbl_dfcarz_customers.type='buyer' and tbl_dfcarz_customers.documents_received=0 and tbl_user_web.id = tbl_dfcarz_customers.sales_id`
      // );
      //console.log(Buyer.data);
      let Buyer = await redisMiddleware.Dashboard("RC_bookprovided", 2, this.state.LocationID)
      //console.log(Buyer)
      if (Buyer) {

        this.setState({ RC_bookprovided: Buyer.data });
      }
    } catch (error) {
      console.log(error);
    }
  };

  Documentation = async () => {
    try {
      // console.log('oc', this.state.LocationID)
      // let Result = await CmsContent.getFreedom(
      //   "tbl_dfcarz_prosperities.*,tbl_AT_Booklet_Purchase_Contract_Proof.id as value,tbl_AT_Booklet_Purchase_Contract_Proof.approval,tbl_user_web.userName as jobroleName,tbl_AT_Booklet_Purchase_Contract_Proof.Reg_No as regno",
      //   `tbl_dfcarz_prosperities,tbl_AT_Booklet_Purchase_Contract_Proof,tbl_user_web`,
      //   `tbl_dfcarz_prosperities.id=tbl_AT_Booklet_Purchase_Contract_Proof.customerid and tbl_user_web.id = tbl_AT_Booklet_Purchase_Contract_Proof.userid and tbl_dfcarz_prosperities.location=${this.state.LocationID}`,
      //   1
      // );
      let Result = await redisMiddleware.Dashboard("Documentation", 2, this.state.LocationID);
      //console.log(Result)
      if (Result) {
        let PendingDocumenatation = [];
        Result.data.map((ival, i) => {
          if (ival.approval == 0) {
            PendingDocumenatation.push(ival);
          }
        });
        this.setState({ PendingDocumenatation });
      }
    } catch (error) {
      console.log(error);
    }
  };

  AT_Inventory_Sheet_Reproof = async () => {
    try {
      // let Result = await CmsContent.getFreedom(
      //   "tbl_dfcarz_prosperities.*,tbl_Inventory_Sheet_Reproof.id as value,tbl_Inventory_Sheet_Reproof.reg_no as regno,tbl_Inventory_Sheet_Reproof.approval,tbl_user_web.userName as jobroleName",
      //   `tbl_dfcarz_prosperities,tbl_Inventory_Sheet_Reproof,tbl_user_web`,
      //   `tbl_dfcarz_prosperities.id=tbl_Inventory_Sheet_Reproof.customerid and tbl_user_web.id = tbl_Inventory_Sheet_Reproof.userid and tbl_dfcarz_prosperities.location=${this.state.LocationID}`,
      //   1
      // );
      let Result = await redisMiddleware.Dashboard("AT_Inventory_Sheet_Reproof", 2, this.state.LocationID);
      // console.log(Result);
      //console.log('inv', Result)
      if (Result) {
        //console.log(Result);
        let PendingPayment = [];
        Result.data.map((ival, i) => {
          if (ival.approval == 0) {
            PendingPayment.push(ival);
          }
        });
        this.setState({ PendingPayment });
      }
    } catch (error) {
      console.log(error);
    }
  };

  Delivery_Note_Seller_Proof = async () => {
    try {
      // let Result = await CmsContent.getFreedom(
      //   "tbl_dfcarz_prosperities.*,tbl_Delivery_Seller_Proof.id as value,tbl_Delivery_Seller_Proof.reg_no as regno,tbl_Delivery_Seller_Proof.approval,tbl_user_web.userName as jobroleName",
      //   `tbl_dfcarz_prosperities,tbl_Delivery_Seller_Proof,tbl_user_web`,
      //   `tbl_dfcarz_prosperities.id=tbl_Delivery_Seller_Proof.customerid and tbl_user_web.id = tbl_Delivery_Seller_Proof.userid and tbl_dfcarz_prosperities.location=${this.state.LocationID}`,
      //   1
      // );
      let Result = await redisMiddleware.Dashboard("Delivery_Note_Seller_Proof", 2, this.state.LocationID);
      // console.log(Result)
      // console.log('del', Result)
      if (Result) {
        //console.log(Result);
        let PendingProcess = [];
        Result.data.map((ival, i) => {
          if (ival.approval == 0) {
            PendingProcess.push(ival);
          }
        });
        this.setState({ PendingProcess });
      }
    } catch (error) {
      console.log(error);
    }
  };

  Refurbishment = async () => {
    try {
      let PendingRefurbishment = [];

      // let Result = await CmsContent.getFreedom(
      //   "tbl_dfcarz_prosperities.*,tbl_Delivery_Seller_Proof.Approved_date,tbl_Delivery_Seller_Proof.id as value,tbl_Delivery_Seller_Proof.approval,tbl_user_web.userName as jobroleName,tbl_Delivery_Seller_Proof.reg_no as regno",
      //   `tbl_dfcarz_prosperities,tbl_Delivery_Seller_Proof,tbl_user_web`,
      //   `tbl_dfcarz_prosperities.id=tbl_Delivery_Seller_Proof.customerid and tbl_user_web.id = tbl_Delivery_Seller_Proof.userid and tbl_dfcarz_prosperities.location=${this.state.LocationID}`,
      //   1
      // );
      // console.log('ref', Result)
      let Result = await redisMiddleware.Dashboard("Refurbishment", 2, this.state.LocationID);
      console.log(Result)
      Result.data.map(async (ival, i) => {
        ival.Approved_date = new Date(ival.Approved_date);
        ival.Approved_date.setDate(ival.Approved_date.getDate() + 3);
        //  ival.Approved_date = moment( ival.Approved_date).format("YYYY-MM-DD");
        // console.log(ival.Approved_date.getTime())
        let date = new Date();

        //   if(ival.Approved_date.getTime() >= date.getTime()){
        //console.log(ival.id)
        let Refurbishment = await CmsContent.getFreedom(
          `tbl_checklist350.*`,
          `tbl_checklist350,tbl_user_web`,
          `tbl_checklist350.customerid=${ival.id}`
        );
        // console.log(Refurbishment)
        // let refurb = await redisMiddleware.singleTable("tbl_checklist350", `${ival.id}`, "customerid");
        // console.log(refurb)
        if (!Refurbishment.data.length) {
         // console.log(ival)
          //console.log(Refurbishment);
          // Refurbishment.data.map((jval,j)=>{
          //   jval.jobroleName = ival.jobroleName;
          // })

          PendingRefurbishment.push(ival);

          this.setState({ PendingRefurbishment });
          //  }

          //PendingRefurbishment.push(ival);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  tbl_Autoterrace_enquiry_form = async () => {
    try {
      // let Buyer = await CmsContent.getFreedom(
      //   "tbl_dfcarz_prosperities.*,tbl_user_web.userName as jobroleName",
      //   "tbl_dfcarz_prosperities,tbl_Specifications_Sheet_Proof,tbl_user_web",
      //   `tbl_user_web.id = tbl_Specifications_Sheet_Proof.userid and tbl_dfcarz_prosperities.type='buyer' and tbl_dfcarz_prosperities.id = tbl_Specifications_Sheet_Proof.customerid and tbl_dfcarz_prosperities.location=${this.state.LocationID}`
      // );
      //console.log('auto', Buyer)
      let Buyer = await redisMiddleware.Dashboard("tbl_Autoterrace_enquiry_form", 2, this.state.LocationID);
      // console.log(Buyer)
      if (Buyer) {
        let Fulldata = [];
        Buyer.data.map((ival, i) => {
          if (ival.prospective_status != "inactive") {
            ival.FormFilled = "no";
            Fulldata.push(ival);
          }
          //ival.followup=moment(ival.followup).format("YYYY-MM-DD");
        });
        // console.log(Buyer.data);
        // console.log(Fulldata);
        //   let result = await CmsContent.getFreedom(
        //     `*`,
        //     `tbl_Autoterrace_enquiry_form`,
        //     1
        //  );

        let TodayContractedSourcing = [];
        //  Buyer.data.map((ival,i)=>{
        // result.data.map((kval,j)=>{
        //   TodayContractedSourcing = Buyer.data.filter(value => value.id !== kval.customerid);
        // })
        let wait1 = await Fulldata.map(async (ival, i) => {
          let result = await CmsContent.getFreedom(
            `*`,
            `tbl_Autoterrace_enquiry_form`,
            `customerid=${ival.id}`
          );

          if (!result.data.length) {
            //console.log(result.data)
            TodayContractedSourcing.push(ival);
          }
        });

        await Promise.all(wait1);
        //  console.log(TodayContractedSourcing);
        this.setState({ TodayContractedSourcing });
      }
    } catch (error) {
      console.log(error);
    }
  };

  tbl_Booking_Confirmation_Reproof = async () => {
    try {
      let PendingContracted = [];
      // let Buyer = await CmsContent.getFreedom(
      //   "tbl_dfcarz_prosperities.*,tbl_user_web.userName as jobroleName",
      //   "tbl_dfcarz_prosperities,tbl_Autoterrace_enquiry_form,tbl_user_web",
      //   `tbl_user_web.id = tbl_Autoterrace_enquiry_form.userid and tbl_dfcarz_prosperities.type='buyer' and tbl_dfcarz_prosperities.id = tbl_Autoterrace_enquiry_form.customerid and tbl_dfcarz_prosperities.location=${this.state.LocationID}`
      // );
      // console.log('confor', Buyer)
      let Buyer = await redisMiddleware.Dashboard("tbl_Booking_Confirmation_Reproof", 2, this.state.LocationID);
      //console.log(Buyer)
      if (Buyer) {
        //console.log(Buyer);
        Buyer.data.map(async (ival, i) => {
          let result = await CmsContent.getFreedom(
            `*`,
            `tbl_Booking_Confirmation_Reproof`,
            `customerid=${ival.id}`
          );
          if (!result.data.length) {
            PendingContracted.push(ival);
          }
        });
      }
      this.setState({ PendingContracted });
    } catch (error) {
      console.log(error);
    }
  };
  tbl_Delivery_Note_Buyer_Reproof = async () => {
    try {
      let PendingDelivery = [];
      // let Buyer = await CmsContent.getFreedom(
      //     "tbl_dfcarz_prosperities.*,tbl_user_web.userName,tbl_Booking_Confirmation_Reproof.regno as regno",
      //     "tbl_dfcarz_prosperities,tbl_Booking_Confirmation_Reproof,tbl_user_web",
      //     `tbl_user_web.id = tbl_Booking_Confirmation_Reproof.userid and tbl_dfcarz_prosperities.type='buyer' and tbl_dfcarz_prosperities.id = tbl_Booking_Confirmation_Reproof.customerid and tbl_dfcarz_prosperities.location=${this.state.LocationID}`
      //   );
      //   console.log('buyer', Buyer)
      let Buyer = await redisMiddleware.Dashboard("tbl_Delivery_Note_Buyer_Reproof", 2, this.state.LocationID);
      // console.log(Buyer)
      if (Buyer) {
        Buyer.data.map(async (ival, i) => {
          let result = await CmsContent.getFreedom(
            `*`,
            `tbl_Delivery_Note_Buyer_Reproof`,
            `customerid=${ival.id}`
          );
          if (!result.data.length) {
            PendingDelivery.push(ival);
          }
        });
      }
      this.setState({ PendingDelivery });
    } catch (error) {
      console.log(error);
    }
  };
  MovetoSite = (d) => {
    console.log(d);
    if (d == "sourcing") {
      //window.open(`/DFCars/DfCarsEnquiry`);
      window.location.href = `/DFCars/TLSourcing`;
    } else if (d == "eval") {
      window.location.href = `/DFCars/Evaluationreport`;
    } else if (d == "sale") {
      window.location.href = `/DFCars/PriceList`;
    } else if (d == "payment") {
      window.location.href = `/DFCars/TLpaymentprocess`;
    } else if (d == "process") {
      window.location.href = `/DFCars/DfCarsphysicalprocess`;
    } else if (d == "refurbishment") {
      window.location.href = `/DFCars/TLRefurbishment`;
    } else if (d == "readyforsale") {
      window.location.href = `/DFCars/PriceList`;
    } else if (d == "contracted") {
      window.location.href = `/DFCars/TLContracted`;
    } else if (d == "delivery") {
      window.location.href = `/DFCars/TLDelivery`;
    } else if (d == "rc") {
      window.location.href = `/DFCars/TLDocumentation`;
    }
  };
  buyer = () => {
    this.setState({ buy: true, sell: false })
  }
  seller = () => {
    this.setState({ sell: true, buy: false })
  }

  render() {
    console.log(this.state.datamore)
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3>Welcome {this.state.usertypeName}</h3>
                  </div>
                  <br />
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <ReportCharts />
                        <ul class="nav nav-tabs">
                          <li class="nav-item">
                            <a
                              class="nav-link active"
                              data-toggle="tab"
                              href="#buy"
                              onClick={() => this.buyer()}
                            >
                              Buying
                            </a>
                          </li>
                          <li class="nav-item">
                            <a
                              class="nav-link"
                              data-toggle="tab"
                              href="#sell"
                              onClick={() => this.seller()}
                            >
                              Selling
                            </a>
                          </li>
                        </ul>
                        <div className="row form-group">
                          {this.state.TodayEnquiryBrand.length ? (
                            <div className="col-sm-6">
                              <React.Fragment>
                                <div className="card">
                                  <div
                                    className="card-header"
                                    style={{ backgroundColor: "lightgray" }}
                                  >
                                    <h3>
                                      {"Esimated Amount > 30000"} (Comp{" "}
                                      {this.state.CompletedTasks.length} - pend{" "}
                                      {this.state.TodayEnquiryBrand.length})
                                    </h3>
                                  </div>
                                  <div className="card-body">
                                    {/* {this.state.TodayEvaluation.length ?  */}
                                    {this.state.TodayEnquiryBrand.map(
                                      (ival, i) => {
                                        if (i < 5) {
                                          return (
                                            <React.Fragment>
                                              <div className="row-form-group">
                                                <div className="col-sm-1" />
                                                <div className="col-sm-12">
                                                  <a onClick={() => this.MovetoSite("penquiry")}>
                                                    <table className="dfcarsport">
                                                      <tr>
                                                        <td className="dfcarsportname">
                                                          {ival.name}
                                                        </td>
                                                        {/* <td className="dfcarsportmobicon">
                                                          <i class="fa fa-mobile" aria-hidden="true"></i>
                                                        </td> */}

                                                        <td className="dfcarsportnumber">
                                                          {ival.Make}
                                                        </td>
                                                        <td className="dfcarsportreg">
                                                          {ival.Model}
                                                        </td>
                                                      </tr>
                                                    </table>
                                                  </a>
                                                </div>
                                              </div>
                                            </React.Fragment>
                                            // <React.Fragment>
                                            //   <div
                                            //     className="card-body"
                                            //     style={{}}
                                            //   >
                                            //     <h3>
                                            //       Prospect Name : {ival.name}
                                            //     </h3>
                                            //     <h3>Type : {ival.type}</h3>
                                            //     <h3>Make : {ival.Make}</h3>
                                            //     <h3>Model : {ival.Model}</h3>
                                            //     <h3>
                                            //       Colour : {ival.ColourName}
                                            //     </h3>
                                            //     <h3>
                                            //       Company-Bugdet :{" "}
                                            //       {ival.CompanyPrice}
                                            //     </h3>
                                            //     <h3>
                                            //       Customer-Bugdet :{" "}
                                            //       {ival.CustomerPrice}
                                            //     </h3>
                                            //   </div>
                                            //   <hr />
                                            // </React.Fragment>
                                          );
                                        }
                                      }
                                    )}
                                    {this.state.TodayEnquiryBrand.length > 0 ? (
                                      <h5
                                        className="Readmore1"
                                        onClick={() =>
                                          this.MovetoSite("penquiry")
                                        }
                                      >
                                        View More
                                      </h5>
                                    ) : null}
                                  </div>
                                </div>
                              </React.Fragment>
                            </div>
                          ) : null}

                          {this.state.PendingEnquiryBrand.length ? (
                            <div className="col-sm-6">
                              <React.Fragment>
                                <div className="card">
                                  <div
                                    className="card-header"
                                    style={{ backgroundColor: "lightgray" }}
                                  >
                                    <h3>
                                      Pending Enquiry (Comp{" "}
                                      {this.state.CompletedTasks.length} - pend{" "}
                                      {this.state.PendingEnquiryBrand.length})
                                    </h3>
                                  </div>
                                  <div className="card-body">
                                    {/* {this.state.TodayEvaluation.length ?  */}
                                    {this.state.PendingEnquiryBrand.map(
                                      (ival, i) => {
                                        if (i < 5) {
                                          return (
                                            <React.Fragment>
                                              <div className="row-form-group">
                                                <div className="col-sm-1" />
                                                <div className="col-sm-12">
                                                  <a onClick={() => this.MovetoSite("penquiry")}>
                                                    <table className="dfcarsport">
                                                      <tr>
                                                        <td className="dfcarsportname">
                                                          {ival.name}
                                                        </td>
                                                        <td className="dfcarsportmobicon">
                                                          <i class="fa fa-mobile" aria-hidden="true"></i>
                                                        </td>

                                                        <td className="dfcarsportnumber">
                                                          {ival.mobile}
                                                        </td>
                                                        <td className="dfcarsportreg">
                                                          {ival.regno}
                                                        </td>
                                                      </tr>
                                                    </table>
                                                  </a>
                                                </div>
                                              </div>
                                            </React.Fragment>
                                            // <React.Fragment>
                                            //   <div
                                            //     className="card-body"
                                            //     style={{}}
                                            //   >
                                            //     <p>
                                            //       Prospect Name : {ival.name}
                                            //     </p>
                                            //     <p>
                                            //       Job Role Name :{" "}
                                            //       {ival.jobroleName}
                                            //     </p>
                                            //   </div>
                                            //   <hr />
                                            // </React.Fragment>
                                          );
                                        }
                                      }
                                    )}
                                    {this.state.PendingEnquiryBrand.length >
                                      0 ? (
                                        <h5
                                          className="Readmore"
                                          onClick={() =>
                                            this.MovetoSite("penquiry")
                                          }
                                        >
                                          Read More
                                        </h5>
                                      ) : null}
                                  </div>
                                </div>
                              </React.Fragment>
                            </div>
                          ) : null}
                          {this.state.buy && (
                            <div class="tab-content">
                              <div id="buy" class="container tab-pane active">
                                <div className="row form-group">
                                  {this.state.PendingEvalutaion.length ? (
                                    <div className="col-sm-6">
                                      <React.Fragment>
                                        <div className="card">
                                          <div
                                            className="card-header"
                                            style={{ backgroundColor: "lightgray" }}
                                          >
                                            <h3>
                                              PendingEvalutaion (Comp{" "}
                                              {this.state.CompletedTasks.length} - Pend{" "}
                                              {this.state.PendingEvalutaion.length})
                                    </h3>
                                          </div>
                                          <div className="card-body">
                                            {/* {this.state.TodayEvaluation.length ?  */}
                                            {this.state.PendingEvalutaion.map(
                                              (ival, i) => {
                                                if (i < 8) {
                                                  return (
                                                    <React.Fragment>
                                                      <div className="row-form-group">
                                                        <div className="col-sm-1" />
                                                        <div className="col-sm-12">
                                                          <a onClick={() => this.MovetoSite("eval")}>
                                                            <table className="dfcarsport">
                                                              <tr>
                                                                <td className="dfcarsportname">
                                                                  {ival.name}
                                                                </td>
                                                                <td className="dfcarsportmobicon">
                                                                  <i class="fa fa-mobile" aria-hidden="true"></i>
                                                                </td>

                                                                <td className="dfcarsportnumber">
                                                                  {ival.mobile}
                                                                </td>
                                                                <td className="dfcarsportreg">
                                                                  {ival.regno}
                                                                </td>
                                                              </tr>
                                                            </table>
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </React.Fragment>
                                                  );
                                                }
                                              }
                                            )}
                                            {this.state.PendingEvalutaion.length > 0 ? (
                                              <h5
                                                className="Readmore1"
                                                onClick={() => this.MovetoSite("eval")}
                                              >
                                                View More
                                              </h5>
                                            ) : null}
                                          </div>
                                        </div>
                                      </React.Fragment>
                                    </div>
                                  ) : null}
                                  {this.state.dataless.length ? (
                                    <div className="col-sm-6">
                                      <React.Fragment>
                                        <div className="card">
                                          <div
                                            className="card-header"
                                            style={{ backgroundColor: "lightgray" }}
                                          >
                                            <h3>
                                              {"Checklist Point < 40"}
                                              {/* {this.state.lenless} */}
                                            </h3>
                                          </div>
                                          <div className="card-body">
                                            {/* {this.state.TodayEvaluation.length ?  */}
                                            {this.state.dataless.map(
                                              (ival, i) => {
                                                if (i < 5) {
                                                  return (
                                                    <React.Fragment>
                                                      <div className="row-form-group">
                                                        <div className="col-sm-1" />
                                                        <div className="col-sm-12">
                                                          <a onClick={() => this.MovetoSite("refurbishment")}>
                                                            <table className="dfcarsport">
                                                              <tr>
                                                                <td className="dfcarsportname">
                                                                  {ival.name}
                                                                </td>
                                                                <td className="dfcarsportmobicon">
                                                                  <i class="fa fa-mobile" aria-hidden="true"></i>
                                                                </td>

                                                                <td className="dfcarsportnumber">
                                                                  {ival.mobile}
                                                                </td>
                                                                <td className="dfcarsportreg">
                                                                  {ival.Points}
                                                                </td>
                                                              </tr>
                                                            </table>
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </React.Fragment>
                                                  );
                                                }
                                              }
                                            )}
                                            {this.state.dataless.length
                                              ? (
                                                <h5
                                                  className="Readmore1"
                                                  onClick={() =>
                                                    this.MovetoSite("refurbishment")
                                                  }
                                                >
                                                  View More
                                                </h5>
                                              ) : null}
                                          </div>
                                        </div>
                                      </React.Fragment>
                                    </div>
                                  ) : null}
                                  {this.state.datamore.length ? (
                                    <div className="col-sm-6">
                                      <React.Fragment>
                                        <div className="card">
                                          <div
                                            className="card-header"
                                            style={{ backgroundColor: "lightgray" }}
                                          >
                                            <h3>
                                              {"Checklist Point > 40"}

                                            </h3>
                                          </div>
                                          <div className="card-body">
                                            {/* {this.state.TodayEvaluation.length ?  */}
                                            {this.state.datamore.map(
                                              (ival, i) => {
                                                //console.log(ival)
                                                if (i < 5) {
                                                  return (
                                                    <React.Fragment>
                                                      <div className="row-form-group">
                                                        <div className="col-sm-1" />
                                                        <div className="col-sm-12">
                                                          <a onClick={() => this.MovetoSite("refurbishment")}>
                                                            <table className="dfcarsport">
                                                              <tr>
                                                                <td className="dfcarsportname">
                                                                  {ival.name}
                                                                </td>
                                                                <td className="dfcarsportmobicon">
                                                                  <i class="fa fa-mobile" aria-hidden="true"></i>
                                                                </td>

                                                                <td className="dfcarsportnumber">
                                                                  {ival.mobile}
                                                                </td>
                                                                <td className="dfcarsportreg">
                                                                  {ival.Points}
                                                                </td>
                                                              </tr>
                                                            </table>
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </React.Fragment>
                                                  );
                                                }
                                              }
                                            )}
                                            {this.state.datamore.length
                                              ? (
                                                <h5
                                                  className="Readmore1"
                                                  onClick={() =>
                                                    this.MovetoSite("refurbishment")
                                                  }
                                                >
                                                  View More
                                                </h5>
                                              ) : null}
                                          </div>
                                        </div>
                                      </React.Fragment>
                                    </div>
                                  ) : null}
                                  {this.state.PendingDocumenatation.length ? (
                                    <div className="col-sm-6">
                                      <React.Fragment>
                                        <div className="card">
                                          <div
                                            className="card-header"
                                            style={{ backgroundColor: "lightgray" }}
                                          >
                                            <h3>
                                              PendingDocumenatation (Comp{" "}
                                              {this.state.CompletedTasks.length} - Pend{" "}
                                              {this.state.PendingDocumenatation.length})
                                    </h3>
                                          </div>
                                          <div className="card-body">
                                            {/* {this.state.TodayEvaluation.length ?  */}
                                            {this.state.PendingDocumenatation.map(
                                              (ival, i) => {
                                                if (i < 8) {
                                                  return (
                                                    <React.Fragment>
                                                      <div className="row-form-group">
                                                        <div className="col-sm-1" />
                                                        <div className="col-sm-12">
                                                          <a onClick={() => this.MovetoSite("payment")}>
                                                            <table className="dfcarsport">
                                                              <tr>
                                                                <td className="dfcarsportname">
                                                                  {ival.name}
                                                                </td>
                                                                <td className="dfcarsportmobicon">
                                                                  <i class="fa fa-mobile" aria-hidden="true"></i>
                                                                </td>

                                                                <td className="dfcarsportnumber">
                                                                  {ival.mobile}
                                                                </td>
                                                                <td className="dfcarsportreg">
                                                                  {ival.regno}
                                                                </td>
                                                              </tr>
                                                            </table>
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </React.Fragment>
                                                  );
                                                }
                                              }
                                            )}
                                            {this.state.PendingPayment.length > 0 ? (
                                              <h5
                                                className="Readmore1"
                                                onClick={() =>
                                                  this.MovetoSite("payment")
                                                }
                                              >
                                                View More
                                              </h5>
                                            ) : null}
                                          </div>
                                        </div>
                                      </React.Fragment>
                                    </div>
                                  ) : null}
                                  {this.state.PendingPayment.length ? (
                                    <div className="col-sm-6">
                                      <React.Fragment>
                                        <div className="card">
                                          <div
                                            className="card-header"
                                            style={{ backgroundColor: "lightgray" }}
                                          >
                                            <h3>
                                              PendingPayment (Comp{" "}
                                              {this.state.CompletedTasks.length} - Pend{" "}
                                              {this.state.PendingPayment.length})
                                    </h3>
                                          </div>
                                          <div className="card-body">
                                            {/* {this.state.TodayEvaluation.length ?  */}
                                            {this.state.PendingPayment.map(
                                              (ival, i) => {
                                                if (i < 8) {
                                                  return (
                                                    <React.Fragment>
                                                      <div className="row-form-group">
                                                        <div className="col-sm-1" />
                                                        <div className="col-sm-12">
                                                          <a onClick={() => this.MovetoSite("payment")}>
                                                            <table className="dfcarsport">
                                                              <tr>
                                                                <td className="dfcarsportname">
                                                                  {ival.name}
                                                                </td>
                                                                <td className="dfcarsportmobicon">
                                                                  <i class="fa fa-mobile" aria-hidden="true"></i>
                                                                </td>

                                                                <td className="dfcarsportnumber">
                                                                  {ival.mobile}
                                                                </td>
                                                                <td className="dfcarsportreg">
                                                                  {ival.regno}
                                                                </td>
                                                              </tr>
                                                            </table>
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </React.Fragment>
                                                  );
                                                }
                                              }
                                            )}
                                            {this.state.PendingPayment.length > 0 ? (
                                              <h5
                                                className="Readmore1"
                                                onClick={() =>
                                                  this.MovetoSite("payment")
                                                }
                                              >
                                                View More
                                              </h5>
                                            ) : null}
                                          </div>
                                        </div>
                                      </React.Fragment>
                                    </div>
                                  ) : null}
                                  {this.state.PendingProcess.length ? (
                                    <div className="col-sm-6">
                                      <React.Fragment>
                                        <div className="card">
                                          <div
                                            className="card-header"
                                            style={{ backgroundColor: "lightgray" }}
                                          >
                                            <h3>
                                              Pending Physical Process (Comp{" "}
                                              {this.state.CompletedTasks.length} - Pend{" "}
                                              {this.state.PendingProcess.length})
                                    </h3>
                                          </div>
                                          <div className="card-body">
                                            {/* {this.state.TodayEvaluation.length ?  */}
                                            {this.state.PendingProcess.map(
                                              (ival, i) => {
                                                if (i < 8) {
                                                  return (
                                                    <React.Fragment>
                                                      <div className="row-form-group">
                                                        <div className="col-sm-1" />
                                                        <div className="col-sm-12">
                                                          <a onClick={() => this.MovetoSite("process")}>
                                                            <table className="dfcarsport">
                                                              <tr>
                                                                <td className="dfcarsportname">
                                                                  {ival.name}
                                                                </td>
                                                                <td className="dfcarsportmobicon">
                                                                  <i class="fa fa-mobile" aria-hidden="true"></i>
                                                                </td>

                                                                <td className="dfcarsportnumber">
                                                                  {ival.mobile}
                                                                </td>
                                                                <td className="dfcarsportreg">
                                                                  {ival.regno}
                                                                </td>
                                                              </tr>
                                                            </table>
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </React.Fragment>
                                                  );
                                                }
                                              }
                                            )}
                                            {this.state.PendingProcess.length > 0 ? (
                                              <h5
                                                className="Readmore1"
                                                onClick={() =>
                                                  this.MovetoSite("process")
                                                }
                                              >
                                                View More
                                              </h5>
                                            ) : null}
                                          </div>
                                        </div>
                                      </React.Fragment>
                                    </div>
                                  ) : null}
                                  {this.state.PendingRefurbishment.length ? (
                                    <div className="col-sm-6">
                                      <React.Fragment>
                                        <div className="card">
                                          <div
                                            className="card-header"
                                            style={{ backgroundColor: "lightgray" }}
                                          >
                                            <h3>
                                              PendingRefurbishment (Comp{" "}
                                              {this.state.CompletedTasks.length} - Pend{" "}
                                              {this.state.PendingRefurbishment.length})
                                    </h3>
                                          </div>
                                          <div className="card-body">
                                            {/* {this.state.TodayEvaluation.length ?  */}
                                            {this.state.PendingRefurbishment.map(
                                              (ival, i) => {
                                                if (i < 8) {
                                                  return (
                                                    <React.Fragment>
                                                      <div className="row-form-group">
                                                        <div className="col-sm-1" />
                                                        <div className="col-sm-12">
                                                          <a onClick={() => this.MovetoSite("refurbishment")}>
                                                            <table className="dfcarsport">
                                                              <tr>
                                                                <td className="dfcarsportname">
                                                                  {ival.name}
                                                                </td>
                                                                <td className="dfcarsportmobicon">
                                                                  <i class="fa fa-mobile" aria-hidden="true"></i>
                                                                </td>

                                                                <td className="dfcarsportnumber">
                                                                  {ival.mobile}
                                                                </td>
                                                                <td className="dfcarsportreg">
                                                                  {ival.regno}
                                                                </td>
                                                              </tr>
                                                            </table>
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </React.Fragment>
                                                  );
                                                }
                                              }
                                            )}
                                            {this.state.PendingRefurbishment.length >
                                              0 ? (
                                                <h5
                                                  className="Readmore1"
                                                  onClick={() =>
                                                    this.MovetoSite("refurbishment")
                                                  }
                                                >
                                                  View More
                                                </h5>
                                              ) : null}
                                          </div>
                                        </div>
                                      </React.Fragment>
                                    </div>
                                  ) : null}
                                  {this.state.LostSale.length ? (
                                    <div className="col-sm-6">
                                      <React.Fragment>
                                        <div className="card">
                                          <div
                                            className="card-header"
                                            style={{ backgroundColor: "lightgray" }}
                                          >
                                            <h3>
                                              LostSale (Comp{" "}
                                              {this.state.CompletedTasks.length} - Pend{" "}
                                              {this.state.LostSale.length})
                                    </h3>
                                          </div>
                                          <div className="card-body">
                                            {/* {this.state.TodayEvaluation.length ?  */}
                                            {this.state.LostSale.map((ival, i) => {
                                              if (i < 8) {
                                                return (
                                                  <React.Fragment>
                                                    <div className="row-form-group">
                                                      <div className="col-sm-1" />
                                                      <div className="col-sm-12">
                                                        <a onClick={() => this.MovetoSite("sale")}>
                                                          <table className="dfcarsport">
                                                            <tr>
                                                              <td className="dfcarsportname">
                                                                {ival.name}
                                                              </td>
                                                              <td className="dfcarsportmobicon">
                                                                <i class="fa fa-mobile" aria-hidden="true"></i>
                                                              </td>

                                                              <td className="dfcarsportnumber">
                                                                {ival.mobile}
                                                              </td>
                                                              <td className="dfcarsportreg">
                                                                {ival.email}
                                                              </td>
                                                            </tr>
                                                          </table>
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </React.Fragment>
                                                );
                                              }
                                            })}
                                            {this.state.LostSale.length > 0 ? (
                                              <h5
                                                className="Readmore1"
                                                onClick={() => this.MovetoSite("sale")}
                                              >
                                                View More
                                              </h5>
                                            ) : null}
                                          </div>
                                        </div>
                                      </React.Fragment>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          )}

                          {this.state.sell && (
                            <div id="sell" class="container tab-pane active">
                              {this.state.TodayContractedSourcing.length ? (
                                <div className="col-sm-6">
                                  <React.Fragment>

                                    <div className="card">
                                      <div
                                        className="card-header"
                                        style={{ backgroundColor: "lightgray" }}
                                      >
                                        <h3>
                                          sourcig in pending (Comp{" "}
                                          {this.state.CompletedTasks.length} - Pend{" "}
                                          {
                                            this.state.TodayContractedSourcing
                                              .length
                                          }
                                      ) )
                                    </h3>
                                      </div>
                                      <div className="card-body">
                                        {/* {this.state.TodayEvaluation.length ?  */}
                                        {this.state.TodayContractedSourcing.map(
                                          (ival, i) => {
                                            if (i < 8) {
                                              return (
                                                <React.Fragment>
                                                  <div className="row-form-group">
                                                    <div className="col-sm-1" />
                                                    <div className="col-sm-12">
                                                      <a onClick={() => this.MovetoSite("sourcing")}>
                                                        <table className="dfcarsport">
                                                          <tr>
                                                            <td className="dfcarsportname">
                                                              {ival.name}
                                                            </td>
                                                            <td className="dfcarsportmobicon">
                                                              <i class="fa fa-mobile" aria-hidden="true"></i>
                                                            </td>

                                                            <td className="dfcarsportnumber">
                                                              {ival.mobile}
                                                            </td>
                                                            <td className="dfcarsportreg">
                                                              {ival.regno}
                                                            </td>
                                                          </tr>
                                                        </table>
                                                      </a>
                                                    </div>
                                                  </div>
                                                </React.Fragment>
                                              );
                                            }
                                          }
                                        )}
                                        {this.state.TodayContractedSourcing.length >
                                          0 ? (
                                            <h5
                                              className="Readmore1"
                                              onClick={() =>
                                                this.MovetoSite("sourcing")
                                              }
                                            >
                                              View More
                                            </h5>
                                          ) : null}
                                      </div>
                                    </div>
                                  </React.Fragment>
                                </div>
                              ) : null}
                              {this.state.PendingContracted.length ? (
                                <div className="col-sm-6">
                                  <React.Fragment>
                                    <div className="card">
                                      <div
                                        className="card-header"
                                        style={{ backgroundColor: "lightgray" }}
                                      >
                                        <h3>
                                          ContractedPending (Comp{" "}
                                          {this.state.CompletedTasks.length} - Pend{" "}
                                          {this.state.PendingContracted.length})
                                    </h3>
                                      </div>
                                      <div className="card-body">
                                        {/* {this.state.TodayEvaluation.length ?  */}
                                        {this.state.PendingContracted.map(
                                          (ival, i) => {
                                            if (i < 8) {
                                              return (
                                                <React.Fragment>
                                                  <div className="row-form-group">
                                                    <div className="col-sm-1" />
                                                    <div className="col-sm-12">
                                                      <a onClick={() => this.MovetoSite("contrated")}>
                                                        <table className="dfcarsport">
                                                          <tr>
                                                            <td className="dfcarsportname">
                                                              {ival.name}
                                                            </td>
                                                            <td className="dfcarsportmobicon">
                                                              <i class="fa fa-mobile" aria-hidden="true"></i>
                                                            </td>

                                                            <td className="dfcarsportnumber">
                                                              {ival.mobile}
                                                            </td>
                                                            <td className="dfcarsportreg">
                                                              {ival.email}
                                                            </td>
                                                          </tr>
                                                        </table>
                                                      </a>
                                                    </div>
                                                  </div>
                                                </React.Fragment>
                                              );
                                            }
                                          }
                                        )}
                                        {this.state.PendingContracted.length > 0 ? (
                                          <h5
                                            className="Readmore1"
                                            onClick={() =>
                                              this.MovetoSite("contrated")
                                            }
                                          >
                                            View More
                                          </h5>
                                        ) : null}
                                      </div>
                                    </div>
                                  </React.Fragment>
                                </div>
                              ) : null}
                              {this.state.PendingDelivery.length ? (
                                <div className="col-sm-6">
                                  <React.Fragment>
                                    <div className="card">
                                      <div
                                        className="card-header"
                                        style={{ backgroundColor: "lightgray" }}
                                      >
                                        <h3>
                                          Delivery pending (Comp{" "}
                                          {this.state.CompletedTasks.length} - Pend{" "}
                                          {this.state.PendingDelivery.length})
                                    </h3>
                                      </div>
                                      <div className="card-body">
                                        {/* {this.state.TodayEvaluation.length ?  */}
                                        {this.state.PendingDelivery.map(
                                          (ival, i) => {
                                            if (i < 8) {
                                              return (
                                                <React.Fragment>
                                                  <div className="row-form-group">
                                                    <div className="col-sm-1" />
                                                    <div className="col-sm-12">
                                                      <a onClick={() => this.MovetoSite("delivery")}>
                                                        <table className="dfcarsport">
                                                          <tr>
                                                            <td className="dfcarsportname">
                                                              {ival.name}
                                                            </td>
                                                            <td className="dfcarsportmobicon">
                                                              <i class="fa fa-mobile" aria-hidden="true"></i>
                                                            </td>

                                                            <td className="dfcarsportnumber">
                                                              {ival.mobile}
                                                            </td>
                                                            <td className="dfcarsportreg">
                                                              {ival.regno}
                                                            </td>
                                                          </tr>
                                                        </table>
                                                      </a>
                                                    </div>
                                                  </div>
                                                </React.Fragment>
                                              );
                                            }
                                          }
                                        )}
                                        {this.state.PendingDelivery.length > 0 ? (
                                          <h5
                                            className="Readmore1"
                                            onClick={() =>
                                              this.MovetoSite("delivery")
                                            }
                                          >
                                            View More
                                          </h5>
                                        ) : null}
                                      </div>
                                    </div>
                                  </React.Fragment>
                                </div>
                              ) : null}
                              {this.state.RC_bookprovided.length ? (
                                <div className="col-sm-6">
                                  <React.Fragment>
                                    <div className="card">
                                      <div
                                        className="card-header"
                                        style={{ backgroundColor: "lightgray" }}
                                      >
                                        <h3>
                                          RC pending (Comp{" "}
                                          {this.state.CompletedTasks.length} - Pend{" "}
                                          {this.state.RC_bookprovided.length})
                                    </h3>
                                      </div>
                                      <div className="card-body">
                                        {/* {this.state.TodayEvaluation.length ?  */}
                                        {this.state.RC_bookprovided.map(
                                          (ival, i) => {
                                            if (i < 8) {
                                              return (
                                                <React.Fragment>
                                                  <div className="row-form-group">
                                                    <div className="col-sm-1" />
                                                    <div className="col-sm-12">
                                                      <a onClick={() => this.MovetoSite("rc")}>
                                                        <table className="dfcarsport">
                                                          <tr>
                                                            <td className="dfcarsportname">
                                                              {ival.customer}
                                                            </td>
                                                            <td className="dfcarsportmobicon">
                                                              <i class="fa fa-mobile" aria-hidden="true"></i>
                                                            </td>

                                                            <td className="dfcarsportnumber">
                                                              {ival.mobile}
                                                            </td>
                                                            <td className="dfcarsportreg">
                                                              {ival.email}
                                                            </td>
                                                          </tr>
                                                        </table>
                                                      </a>
                                                    </div>
                                                  </div>
                                                </React.Fragment>
                                              );
                                            }
                                          }
                                        )}
                                        {this.state.RC_bookprovided.length > 0 ? (
                                          <h5
                                            className="Readmore1"
                                            onClick={() => this.MovetoSite("rc")}
                                          >
                                            View More
                                          </h5>
                                        ) : null}
                                      </div>
                                    </div>
                                  </React.Fragment>
                                </div>
                              ) : null}

                              {this.state.TodayEvaluation.length ? (
                                <div className="col-sm-6">
                                  <React.Fragment>
                                    <div className="card">
                                      <div
                                        className="card-header"
                                        style={{ backgroundColor: "lightgray" }}
                                      >
                                        <h3>
                                          Evaluation to be Approved today (Comp{" "}
                                          {this.state.CompletedTasks.length} - Pend{" "}
                                          {this.state.TodayEvaluation.length})
                                    </h3>
                                      </div>
                                      <div className="card-body">
                                        {/* {this.state.TodayEvaluation.length ?  */}
                                        {this.state.TodayEvaluation.map(
                                          (ival, i) => {
                                            if (i < 1) {
                                              return (
                                                <React.Fragment>
                                                  <div
                                                    className="card-body"
                                                    style={{}}
                                                  >
                                                    <p>
                                                      Prospect Name : {ival.name}
                                                    </p>
                                                    <p>
                                                      Job Role Name :{" "}
                                                      {ival.jobroleName}
                                                    </p>
                                                  </div>
                                                  <hr />
                                                </React.Fragment>
                                              );
                                            }
                                          }
                                        )}
                                        {this.state.TodayEvaluation.length > 0 ? (
                                          <h5
                                            className="Readmore"
                                            onClick={() => this.MovetoSite("eval")}
                                          >
                                            Read More
                                          </h5>
                                        ) : null}
                                      </div>
                                    </div>
                                  </React.Fragment>
                                </div>
                              ) : null}
                              {this.state.PendingRefurbishmentApproval.length ? (
                                <div className="col-sm-6">
                                  <React.Fragment>
                                    <div className="card">
                                      <div
                                        className="card-header"
                                        style={{ backgroundColor: "lightgray" }}
                                      >
                                        <h3>
                                          Pending Refurbishment Approval (Comp{" "}
                                          {this.state.CompletedTasks.length} - Pend{" "}
                                          {
                                            this.state.PendingRefurbishmentApproval
                                              .length
                                          }
                                      )
                                    </h3>
                                      </div>
                                      <div className="card-body">
                                        {/* {this.state.TodayEvaluation.length ?  */}
                                        {this.state.PendingRefurbishmentApproval.map(
                                          (ival, i) => {
                                            if (i < 8) {
                                              return (
                                                <React.Fragment>
                                                  <div className="row-form-group">
                                                    <div className="col-sm-1" />
                                                    <div className="col-sm-12">
                                                      <a onClick={() => this.MovetoSite("refurbishment")}>
                                                        <table className="dfcarsport">
                                                          <tr>
                                                            <td className="dfcarsportname">
                                                              {ival.name}
                                                            </td>
                                                            <td className="dfcarsportmobicon">
                                                              <i class="fa fa-mobile" aria-hidden="true"></i>
                                                            </td>

                                                            <td className="dfcarsportnumber">
                                                              {ival.mobile}
                                                            </td>
                                                            <td className="dfcarsportreg">
                                                              {ival.regno}
                                                            </td>
                                                          </tr>
                                                        </table>
                                                      </a>
                                                    </div>
                                                  </div>
                                                </React.Fragment>
                                              );
                                            }
                                          }
                                        )}
                                        {this.state.PendingRefurbishmentApproval
                                          .length > 0 ? (
                                            <h5
                                              className="Readmore1"
                                              onClick={() =>
                                                this.MovetoSite("refurbishment")
                                              }
                                            >
                                              View More
                                            </h5>
                                          ) : null}
                                      </div>
                                    </div>
                                  </React.Fragment>
                                </div>
                              ) : null}
                              {this.state.Cars.length ? (
                                <div className="col-sm-6">
                                  <React.Fragment>
                                    <div className="card">
                                      <div
                                        className="card-header"
                                        style={{ backgroundColor: "lightgray" }}
                                      >
                                        <h3>
                                          Ready For Sale (Comp{" "}
                                          {this.state.CompletedTasks.length} - Pend{" "}
                                          {this.state.Cars.length}) )
                                    </h3>
                                      </div>
                                      <div className="card-body">
                                        {/* {this.state.TodayEvaluation.length ?  */}
                                        {this.state.Cars.map((ival, i) => {
                                          if (i < 1) {
                                            return (
                                              <React.Fragment>
                                                <div
                                                  className="card-body"
                                                  style={{}}
                                                >
                                                  <p>Make : {ival.makename}</p>
                                                  <p>Model: {ival.modelname}</p>
                                                  <p>Price: {ival.price}</p>
                                                  <p>Colour : {ival.ColourName}</p>
                                                </div>
                                                <hr />
                                              </React.Fragment>
                                            );
                                          }
                                        })}
                                        {this.state.Cars.length > 0 ? (
                                          <h5
                                            className="Readmore"
                                            onClick={() =>
                                              this.MovetoSite("readyforsale")
                                            }
                                          >
                                            Read More
                                          </h5>
                                        ) : null}
                                      </div>
                                    </div>
                                  </React.Fragment>
                                </div>
                              ) : null}
                            </div>
                          )}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main >
      </React.Fragment >
    );
  }
}