import React, { Component } from 'react';
import { Alert } from "reactstrap";
import LoginModal from '../../../../components/Modal/Modal';
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import redisMiddleware from "../../../../MiddleWare/redisMiddleware";
import SingleSelect from '../../../../components/Form/SingleSelect'
import Datatable from "../../../../components/Datatable/Datatable";
import { confirmAlert } from "react-confirm-alert";

export default class ClosedCustomer extends FormMiddleWare {
  constructor(props) {
    super(props)
    this.state = {
      LocationID: localStorage.getItem(`LocationID`),
      userid: JSON.parse(localStorage.getItem("userlog")),
      dataval: [],
      loading: false
    }

  }
  column = [
    {
      Header: "Id",
      accessor: "id"
    },
    {
      Header: "Name",
      accessor: "name"
    },
    {
      Header: "Type",
      accessor: "type",
      //Cell: (d) => this.getValueFromArray(d.original.Make, this.state.MakeOptions)
    },

    {
      Header: "Comments",
      accessor: "comments",
      // Cell: (d) => this.getValueFromArray(d.original.Variant, this.state.variantopt)
    }
    // {
    //   Header: "Convert to Prospect",
    //   accessor: "id",
    //   Cell: (d) => this.conversion(d)
    // }
  ];
  MainFunction = async () => {
    this.setState({ loading: true })
    try {
      let results = await cmsContent.BUYDFCARS(this.state.userid.id, 'closedenq')
      if (results) {
        let logos = results[0];
        let dataval = logos.closedEnquiry;
        console.log(dataval)
        this.setState({ dataval, loading: false });
      }
    } catch (error) {
      console.log(error);
    }
  }
  async componentDidMount() {
    this.MainFunction()
    // this.setState({ loading: true })
    // let closedEnquiry = await cmsContent.getFreedom(
    //   `*`,
    //   `tbl_dfcars_enquiryClosed`,
    //   1,
    //   //`customerid`,
    //   1,
    //   `id DESC`

    // );
    // // let { data: dataval1 } = await cmsContent.getFreedom(
    // //     "*",
    // //     `tbl_dfcarz_enquiryform`,
    // //     `enquiry_status='active' and location =${this.state.LocationID} AND (pendingStatus1='Closed' OR pendingStatus1='Yes, but not interested, Mark closed' OR pendingStatus1='Couldn’t speak to customer')`,
    // //     1,
    // //     `id DESC`
    // // );
    // console.log(closedEnquiry.data);
    // this.setState({ dataval: closedEnquiry.data, loading: false })
  }
  conversion = d => {

    if (d.original.status == "active") {
      return (
        <button
          type="button"
          className="btn btn-sm btn-primary"
          onClick={() => this.buttonDeletes(d)}
        >
          Convert to Prospect
        </button>
      );
    }
    else if (d.original.status == "inactive") {
      return (
        <button
          type="button"
          className="btn btn-sm btn-warning"
        >
          Converted to Prospect
        </button>
      );
    }
  }
  buttonDeletes = d => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <div style={{ textAlign: "center" }}>
          <h1>Convert to Prospect confirmation</h1>
          <h4>Are you sure you want to Convert to Prospect this ?</h4><br />
          <button className="btn btn-sm btn-primary" onClick={() => this.convertUser(d)} >Yes</button> &nbsp;  &nbsp;
          <button className="btn btn-sm btn-danger" onClick={onClose} >No</button>
        </div>
      }
    })
  };
  convertUser = async d => {
    try {
      const id = d.original.id;
      console.log(id);
      let enquiry = await cmsContent.getFreedom(
        `*`,
        `tbl_dfcars_enquiryClosed`,
        `id=${id}`, 1, 1

      );
      console.log("enquiry", enquiry.data[0].enquiry_id);

      if (enquiry && enquiry.data[0].enquiry_id) {
        let enquiryid = await cmsContent.getFreedom(
          `*`,
          `tbl_dfcarz_enquiryform`,
          `id=${enquiry.data[0].enquiry_id}`,
          1,
          "id"

        );
        console.log("enquiryid", enquiryid.data);

        if (enquiryid.data[0]) {
          let data = enquiryid.data[0]
          let groupArray1 = {};
          groupArray1.name = data.name;
          groupArray1.mobile = data.mobile;
          groupArray1.email = data.email;
          groupArray1.status = "active";
          groupArray1.companyid = data.companyid;
          groupArray1.enquiry_id = data.id;
          groupArray1.userid = data.userid;
          groupArray1.type = data.type;
          groupArray1.model = data.Model;
          groupArray1.make = data.Make;
          groupArray1.variant = data.Variant;
          groupArray1.budget = data.budget;
          groupArray1.color = data.color;
          groupArray1.prospective_status = "active";
          groupArray1.location = data.location;
          groupArray1.salesid = data.salesid;
          groupArray1.evaluatorid = data.evaluatorid;
          groupArray1.reg_no = data.Reg_no;
          groupArray1.year = data.yom;

          const result = await cmsContent.addMaster("tbl_dfcarz_prosperities", groupArray1);

          console.log(result);
          if (result) {
            let gp = {}
            gp.prosid = result.insertId
            const result1 = await cmsContent.updateMaster("tbl_dfcarz_enquiryform", data.id, gp);
            let gp1 = {}
            gp1.status = "inactive"
            const result2 = await cmsContent.updateMaster("tbl_dfcars_enquiryClosed", id, gp1);

          }
        }
      }
    } catch (error) {
      console.log(error);
    }






    // let URL =
    //   Config.ACCESS_POINT + Config.ConvertUser + data;
    // alert("Convert to Prespect")
    // console.log(URL);
    // fetch(URL)
    //   .then(async json => {
    //     console.log(json);
    //     if (json) {
    //       toast(`${d.name} successfully converted as prospective`);
    //       this.setState({ data: [] })
    //       this.MainFun()
    //     } else {
    //       toast("Error")
    //       return false;
    //     }
    //   })
    //   .catch(error => toast(error));

    //   const history = await cmsContent.addMaster(
    //     "tbl_dfcarz_prosperities",
    //     groupArray
    //   );
  };
  render() {
    const { dataval, loading } = this.state
    return (
      <main className="main my-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h1>Closed Enquiries</h1>
                </div>
                <div className="card-body">
                  <div className="row form-group"></div>
                  {loading ? <div className="text-center"> <i
                    className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                  ></i></div>

                    : dataval && dataval.length > 0 ?
                      <Datatable data={dataval} columnHeading={this.column} /> : (<div style={{ textAlign: "center" }} ><b>No Data Found</b></div>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>)
  }
}