import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import moment from "moment";
import UsertypeComments from "../UserMaster/UsertypeComments";
import LoginModal from "../../../../components/Modal/Modal";
import Datatable from "../../../../components/Datatable/Datatable";
import Icon from "react-icons-kit";
import { calendar_ok } from 'react-icons-kit/ikons/calendar_ok'
import { checkmark } from 'react-icons-kit/icomoon/checkmark'
import { confirmAlert } from "react-confirm-alert";
import { ACCESS_POINT } from "../../../../config";

class PocRefurbishment extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      User: JSON.parse(localStorage.getItem("userlog")),
      usertypelog: JSON.parse(localStorage.getItem("usertypelog")),
      LocationID: localStorage.getItem(`LocationID`),
      EnquiryData: [],
      MakeOptions: [],
      coloropt: [],
      variantopt: [],
      SelectCurrentStatus: [{ label: "Work yet start", value: 0 }, { label: "Work in progress", value: 1 }, { label: "Waiting for parts", value: 2 }, { label: "Completed", value: 3 }],
      price: "",
      modelopt: [],
      BilluploadChanges: 0,
      pricealert: "",
      alertVisible: false,
      loading: false,
      column: [
        {
          Header: "Name",
          accessor: "name"
        },
        {
          Header: "REG NO",
          accessor: "reg_no"
        },
        {
          Header: "Mobile",
          accessor: "mobile"
        },
        {
          Header: "evaluator name ",
          accessor: "jobroleName"
        },
        {
          Header: "Make",
          accessor: "make",
          Cell: (d) => this.getValueFromArray(d.original.make, this.state.MakeOptions)
        },
        {
          Header: "Model",
          accessor: "model",
          Cell: (d) => this.getValueFromArray(d.original.model, this.state.modelopt)
        },
        {
          Header: "Variant",
          accessor: "variant",
          Cell: (d) => this.getValueFromArray(d.original.variant, this.state.variantopt)
        },
        {
          Header: "Color",
          accessor: "color",
          Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)
        },
        {
          Header: "RF Incharge",
          accessor: "RFInchargeName"
        },
        {
          Header: "RF Technician",
          accessor: "RFTechnicianName"
        },
        {
          Header: "Bad Summary",
          accessor: "name",
          minWidth: 120,
          Cell: d => this.FormFill1(d.original)
        },
        {
          Header: "Form",
          accessor: "mobile",
          Cell: d => this.Document(d)
        },
        {
          Header: "Approval",
          minWidth: 120,
          Cell: d => this.Approval(d),
        },
      ],
      column2: [
        {
          Header: "Name",
          accessor: "name"
        },
        {
          Header: "REG NO",
          accessor: "reg_no"
        },
        {
          Header: "Mobile",
          accessor: "mobile"
        },
        {
          Header: "evaluator name ",
          accessor: "jobroleName"
        },
        {
          Header: "Make",
          accessor: "make",
          Cell: (d) => this.getValueFromArray(d.original.make, this.state.MakeOptions)
        },
        {
          Header: "Model",
          accessor: "model",
          Cell: (d) => this.getValueFromArray(d.original.model, this.state.modelopt)
        },
        {
          Header: "Variant",
          accessor: "variant",
          Cell: (d) => this.getValueFromArray(d.original.variant, this.state.variantopt)
        },
        {
          Header: "Color",
          accessor: "color",
          Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)
        },
        {
          Header: "RF Incharge",
          accessor: "RFInchargeName"
        },
        {
          Header: "RF Technician",
          accessor: "RFTechnicianName"
        },
        {
          Header: "Bad Summary",
          accessor: "name",
          Cell: d => this.FormFill1(d.original)
        },
        {
          Header: "Form",
          accessor: "mobile",
          Cell: d => this.Document(d)
        },
        {
          Header: "Status",
          accessor: "mobile",
          Cell: d => this.WorkStatus(d)
        },
      ],
      stage: "",
      CustomerId: "",
      data_for_f: [{ "fill": 0, "view": 0, "Live": 0, "today": 0, "MTD": 0, "ftd": 0 }]
    };
    this.vals()
  }

  MainFunction = async () => {
    let { usertypelog } = this.state;
    try {
      this.setState({ loading: true })
      let results = await cmsContent.BUYDFCARS(this.state.User.id, 'Rf')
      // console.log(results, "Dataaaaaa");
      if (results) {
        this.setState({
          data_for_f: results.data_for_f, data2: results.data2, data3: results.data3, data4: results.data4,
          userId: results.userId,
          data5: results.data5, loading: false
        })
      }
    } catch (error) {
      console.log(error);
    }
  }
  async componentDidMount() {
    this.MainFunction()
  }
  async vals() {

    let { data: Make } = await cmsContent.getFreedom(
      `Make as label , id as value`,
      `tbl_make_master`, 1,
      1, 1
    );
    if (Make) {
      this.setState({ MakeOptions: Make })
    }
    let { data: Colour } = await cmsContent.getFreedom(
      `colour as label , id as value`,
      `tbl_colour`,
      `ServiceId=10`,
      1,
      `id DESC`
    );
    if (Colour) {
      this.setState({ coloropt: Colour })
    }
    let { data: variant } = await cmsContent.getFreedom(
      "variant as label , id as value",
      "tbl_variant_master",
      `ServiceId=10`,
      1
    );
    if (variant) {
      this.setState({ variantopt: variant })

    }
    let { data: model } = await cmsContent.getFreedom(
      "Model as label , id as value",
      "tbl_dfcarsmodel",
      `ServiceId=10`,
      1
    );
    if (model) {
      this.setState({ modelopt: model })
    }
  }
  UpdateSTatus = d => {
    return (
      <button
        type="button"
        className="btn btn-warning"
        data-toggle="modal"
        data-target={"#status"}
        onClick={() => this.Editdata(d)}
      >
        View Comments
      </button>
    );
  };

  Editdata = async d => {
    this.setState({ stage: "refurbishment", CustomerId: d.original.id });
  };
  Approval = d => {
    if (d.original.approval == 0 || d.original.FormFilled === "yes") {
      return (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => this.buttonApproval1(d)}
        >
          Approve
        </button>
      );
    } else if (d.original.approval == 1 || d.original.FormFilled === "approved") {

      return (
        this.View(d)
      )
    }
    else {
      return (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => this.buttonApproval1(d)}
        >
          Approve
        </button>
      )
    }
  };
  buttonApproval1 = d => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <div style={{ textAlign: "center" }}>
          <h1>Approval confirmation</h1>
          <h4>Are you sure you want to approve this ?</h4><br />
          <button className="btn btn-primary" onClick={() => {
            this.Approval1(d, 1);
            onClose();
          }} >Yes</button> &nbsp;  &nbsp;
          <button className="btn btn-danger" onClick={onClose} >No</button>
        </div>
      }
    })
  }
  Approval1 = async d => {
    let data = d.original
    let body = {};
    body.customerid = data.id;
    body.approval = 2
    body.status1 = 0
    try {
      let result = await cmsContent.UpdateApproval("tbl_checklist350", body);
      await this.setState({ reloadfun: true })
      if (this.state.reloadfun == true) {
        window.location.reload()
      }
    } catch (error) {
      console.log(error);
    }

  };
  Approval2 = async d => {

    d = d.original
    let { pricealert, price, WorkData } = this.state
    if (!price) {
      let content = "* Please Enter Price"
      await this.setState({ pricealert: content })
      return true

    }
    let groupArray1 = {}
    groupArray1.userId = this.state.userId
    if (WorkData && WorkData) {
      groupArray1.data = JSON.stringify(WorkData)
    }

    if (price) {
      groupArray1.finalprice = price
    }
    else {
      groupArray1.finalprice = 0
    }

    try {
      console.log(groupArray1);
      let result = await cmsContent.CheckListAddProduct1("tbl_checklist350", groupArray1);
      if (result) {
        await this.setState({ reloadfun: true })
        if (this.state.reloadfun == true) {
          window.location.reload()
        }
      }
    } catch (error) {
      console.log(error);
    }

  };
  FormFill1 = (d) => {
    // console.log(d);
    return (
      <button
        class='btn btn-danger'
        data-toggle="modal"
        data-target="#badmodal"
        onClick={() => this.badsummarymodal(d)}
      >
        Bad Summary
      </button>
    )
  }
  badsummarymodal = async (d) => {
    // console.log(d, "enter");
    let js = []
    // js = JSON.parse(d.checkjson)
    js = d.checkjson
    let badselected = []
    // console.log(js);
    js.map((ival, i) => {
      ival.map((jval) => {
        if (jval.status == "Bad" || jval.status == "Average") {
          badselected.push(jval)
        }
      })
    })
    // console.log(badselected, "badselected");
    let modaldata1 = []
    modaldata1.push(
      <div className="bgcolor">
        <div class="set-form">
          <table id="myTable" class="table table-bordered">
            <tr>
              <th>Name</th>
              <th>Status</th>
              <th>Remarks</th>
              <th>Estimate</th>
            </tr>
            {badselected.map((item, i) => {
              return (
                <tbody ><tr>
                  <td className="text-left " >
                    {item.status && item.status == "Bad" ?
                      <p style={{ color: "red", fontSize: "18px" }}>{i + 1 + ")"} {item.name}</p>
                      :
                      <p style={{ fontSize: "18px" }}>{i + 1 + ")"} {item.name}</p>
                    }
                  </td>

                  <td>
                    {item.status && item.status == "Bad" ?
                      <label
                        style={{ color: "red", fontSize: "18px" }}
                      >{
                          item.status
                        }</label>
                      :
                      <label
                        style={{ fontSize: "18px" }}
                      >{
                          item.status
                        }</label>
                    }
                  </td>
                  <td>
                    {item.status && item.status == "Bad" ?
                      <p style={{ color: "red", fontSize: "18px" }}>{item.remarks && item.remarks != null ? item.remarks : "-"}</p>
                      :
                      <p style={{ fontSize: "18px" }}>{item.remarks && item.remarks != null ? item.remarks : "-"}</p>
                    }
                  </td>
                  <td>
                    {item.status && item.status == "Bad" ?
                      <p style={{ color: "red", fontSize: "18px" }}>{item.estimate && item.estimate != null ? item.estimate : "-"}</p>
                      :
                      <p style={{ fontSize: "18px" }}>{item.estimate && item.estimate != null ? item.estimate : "-"}</p>
                    }
                  </td>
                </tr>
                </tbody>
              );
            })}
          </table>
        </div>
      </div >
    )

    // console.log(modaldata1, "itemitemitemitemitemitemitemitemitem");
    this.setState({ modaldata1, data_come: d })
  }
  reset1 = async (d) => {
    // console.log(d.original);
    await this.setState({ modaldata1: [] })

  }
  Document = d => {
    return (
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => this.F_proofForm(d)}
      >
        View Form
      </button>
    );
  };

  F_proofForm = async d => {
    window.open(
      `/DFCars/CERTIFICATION_350_CheckPoints/${d.original.id}`,
      "_blank"
    );
  };
  pricechg = async (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      await this.setState({ price: e.target.value, pricealert: "" })
    }
  }
  pricereset = async () => {
    await this.setState({ pricealert: "", price: "", BilluploadChanges: 0, isToggleOn: false })
  }
  WorkStatus = d => {
    if (parseInt(d.original.status1) == 3) {
      return (
        this.View(d)
      );
    }
    else {
      return (
        <span>{this.state.SelectCurrentStatus.map((ival, i) => {
          if (d.original.status1 == ival.value) {
            return <span class="label"> {ival.label}</span>
          }
        })
        }</span>
      )
    }
  };
  StatusAutoPopulate = async (d) => {
    if (d && d) {
      await this.setState({ WorkData: d })

      let images = this.state.images;
      images = JSON.parse(d.bill);
      let img = [];
      if (images != null) {
        images.map((ival, i) => {
          if (ival) {
            let spliting = ival.split(".");
            if (['pdf', 'doc', 'xlsx', 'csv'].includes(spliting[1])) {
              let a = null
              a = "false/pdfIconTrans_1599478202382.png";
              let wait = img.push(<img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + a}
                style={{
                  width: "200px", height: "200px", display: "inline-flex", padding: "10px"
                }}
                onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }} />
              )
            } else {
              let wait = img.push(<img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + ival}
                style={{
                  width: "200px", height: "200px", display: "inline-flex", padding: "10px"
                }}
                onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }} />
              )
            }
          }
        })
        this.setState({ img })
      }
      // console.log(img, "img");
    }
  }
  BilluploadChanges = async () => {
    await this.setState(state => ({ isToggleOn: !state.isToggleOn }));
    if (this.state.isToggleOn === true) {
      await this.setState({ BilluploadChanges: 1 })
    } else (
      await this.setState({ BilluploadChanges: 0 })
    )
  };
  View = (d) => {
    // console.log();
    let { orderdata, users, WorkData, BilluploadChanges } = this.state
    console.log(d.original, "BilluploadChanges");
    let data = d.original
    if (d) {
      return (
        <LoginModal
          buttonTitle={data && data.FormFilled == "approved" ? "Approved" : "Completed"
          }
          title={`Evaluator Estimation ${WorkData && WorkData.name}`
          }
          extraClass={data && data.FormFilled == "approved" ? "success" : "btn btn-primary"}
          onClick={() => this.StatusAutoPopulate(data)}
          id="evaluation"
          reset={() => this.pricereset()}
          bodyText={
            < div >


              < section className="container-fluid" >
                <div className="row">
                  <div className="col-sm-12">
                    {this.state.BilluploadChanges === 0 && (<table class="table table-striped">
                      <tbody>
                        <tr>
                          <th scope="row">NAME</th>
                          <td>{WorkData && WorkData.name}</td>
                        </tr>
                        <tr>
                          <th scope="row">MOBILE</th>
                          <td>{WorkData && WorkData.mobile}</td>
                        </tr>
                        <tr>
                          <th scope="row">EMAIL</th>
                          <td>{WorkData && WorkData.email}</td>
                        </tr>
                        <tr>
                          <th scope="row">REG NO</th>
                          <td>{WorkData && WorkData.reg_no}</td>
                        </tr>
                        <tr>
                          <th scope="row">MAKE</th>
                          <td>{this.getValueFromArray(WorkData && WorkData.make, this.state.MakeOptions)}</td>
                        </tr>
                        <tr>
                          <th scope="row">MODAL</th>
                          <td>{this.getValueFromArray(WorkData && WorkData.model, this.state.modelopt)}</td>
                        </tr>
                        <tr>
                          <th scope="row">COLOR</th>
                          <td>{this.getValueFromArray(WorkData && WorkData.color, this.state.coloropt)}</td>
                        </tr>
                        <tr>
                          <th scope="row">TOTAL RECONDITIONING ESTIMATE (B)</th>
                          <td>{WorkData && WorkData.recondition_est && WorkData.recondition_est}</td>
                        </tr>
                        <tr>
                          <th scope="row">STANDARD SELLING PRICE (A)</th>
                          <td>{WorkData && WorkData.std_sell_price && WorkData.std_sell_price}</td>
                        </tr>
                        <tr>
                          <th scope="row">MARGIN (C)</th>
                          <td>{WorkData && WorkData.margin && WorkData.margin}</td>
                        </tr>
                        <tr>
                          <th scope="row">WARRANTY POOL (D)</th>
                          <td>{WorkData && WorkData.warranty_pool && WorkData.warranty_pool}</td>
                        </tr>
                        <tr>
                          <th scope="row">GST (E)</th>
                          <td>{WorkData && WorkData.gst && WorkData.gst}</td>
                        </tr>
                        <tr>
                          <th scope="row">EXCHANGE BONUS (F)</th>
                          <td>{WorkData && WorkData.exchange_bonus && WorkData.exchange_bonus}</td>
                        </tr>
                        <tr>
                          <th scope="row">BODY SHOP (G)</th>
                          <td>{WorkData && WorkData.body_shop && WorkData.body_shop}</td>
                        </tr>
                        <tr>
                          <th scope="row">TRADE-IN PRICE (A-B-C-D-E+F+G)</th>
                          <td>{WorkData && WorkData.trade_in_price && WorkData.trade_in_price}</td>
                        </tr>
                        {WorkData && WorkData.FormFilled == "approved" ?
                          <React.Fragment>
                            <tr>
                              <th scope="row">Actual RF Cost</th>
                              <td>{WorkData && WorkData.rfcost && WorkData.rfcost}</td>
                            </tr>
                            <tr>
                              <th scope="row">PRICE</th>
                              <td>{WorkData && WorkData.price && WorkData.price}</td>
                            </tr>
                          </React.Fragment>
                          :
                          <tr>
                            <th scope="row">PRICE</th>
                            <td>  <input type="text" placeholder="price" value={this.state.price} className="form-control" name="price"
                              onChange={(e) => this.pricechg(e)} /></td>
                          </tr>
                        }
                        {this.state.pricealert && this.state.pricealert != null && <tr>
                          <th scope="row"></th>
                          <td> <p style={{ color: "red" }}>{this.state.pricealert && this.state.pricealert}</p></td>
                        </tr>
                        }
                      </tbody>

                    </table>)}
                    <div class="d-flex justify-content-center">
                      <button onClick={this.BilluploadChanges}
                        className="btn btn-primary btn-sm ">   {this.state.isToggleOn ? "Close Bill Upload Images" : "View Bill Upload Images"}
                      </button>
                    </div>
                    {
                      this.state.BilluploadChanges === 1 && (
                        <div>
                          {this.state.img ? this.state.img : null}

                        </div>
                      )
                    }
                  </div>
                </div>
                {WorkData && WorkData.FormFilled != "approved" &&
                  this.state.BilluploadChanges === 0 && (<div className="row text-center" >
                    <div className="col-sm-6">
                      <button className="btn btn-success" onClick={() => this.Approval2(d)} style={{ width: 180 }}>APPROVAL</button> &nbsp; &nbsp; &nbsp;
                    </div>
                    <div className="col-sm-6">
                      <button className="btn btn-danger" data-dismiss="modal" onClick={() => this.pricereset()} style={{ width: 180 }}>CANCEL</button>
                    </div>
                  </div>
                  )
                }
              </ section >

            </div >
          }
        />
      )
    } else {
      return true
    }
  }
  edash = () => {
    return (<div className="container-fluid">
      <div className="card">
        {/* < div className="row" > */}
        <div className="card-header">
          <div className="col-sm-6" >
            <h2 className="mt-2 black">Dashboard</h2>
          </div>
        </div>
        <div className="card-body">
          <div className="row col-12 mt-4 text-center">
            <div className="col ">
              <div className="dashboardCard1" onClick={() => this.onClk(1)} >
                <Icon icon={checkmark} style={{ color: 'green' }} size={35} />
                <h4 className="mt-2 black" >Approved</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b> {this.state.data_for_f[0].today ? this.state.data_for_f[0].today : 0}</b></h1>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="dashboardCard1" onClick={() => this.onClk(2)} >
                <Icon icon={calendar_ok} style={{ color: 'red' }} size={35} />
                <h4 className="mt-2 black" >Waiting For Approval</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b>{this.state.data_for_f[0].fill ? this.state.data_for_f[0].fill : 0}</b></h1>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="dashboardCard1" onClick={() => this.onClk(3)} >
                <Icon icon={calendar_ok} style={{ color: 'red' }} size={35} />
                <h4 className="mt-2 black" >Working</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b>{this.state.data_for_f[0].work ? this.state.data_for_f[0].work : 0}</b></h1>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="dashboardCard1" onClick={() => this.onClk(4)} >
                <Icon icon={calendar_ok} style={{ color: 'red' }} size={35} />
                <h4 className="mt-2 black" >Work Completed</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b>{this.state.data_for_f[0].completed ? this.state.data_for_f[0].completed : 0}</b></h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
  onClk = (val) => {
    if (val === 1) { this.setState({ Data: this.state.data3, workproc: false, }) }
    if (val === 2) { this.setState({ Data: this.state.data2, workproc: false, }) }
    if (val === 3) { this.setState({ Data: this.state.data4, workproc: true, }) }
    if (val === 4) { this.setState({ Data: this.state.data5, workproc: true, }) }
    window.scroll({
      top: 400,
      left: 0,
      behavior: "smooth",
    });
  }
  render() {
    const { modaldata1, data_come } = this.state;
    // let data = btoa('hello')
    // let data1 = atob(data)
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            {/* {Modmodaldata1 && Modmodaldata1 == true && */}
            <div class="modal fade" id="badmodal" tabindex="-1" role="dialog" aria-labelledby="exampleModal" aria-hidden="true" >
              <div class="modal-dialog " role="document">
                <div class="modal-content text-center" style={{ minWidth: "800px", marginLeft: "-150px" }}>
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">      Bad Summary ({data_come && data_come.Points && data_come.Points})</h5>
                    <button type="button" class="close" onClick={() => { this.reset1() }} data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    {modaldata1 && modaldata1}
                  </div>
                </div>
              </div>
            </div>
            {/* } */}
            {this.edash()}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Refurbishment</h1>
                    {/* {data1}{data} */}
                  </div>
                  <div className="card-body">

                    {this.state.loading ? <div className="text-center"> <i
                      className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                    ></i></div>
                      : this.state.Data && this.state.Data.length ? (
                        <Datatable
                          data={this.state.Data}
                          columnHeading={this.state.workproc ? this.state.column2 : this.state.column}
                        />
                      ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
export default PocRefurbishment;
