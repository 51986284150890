import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import Datatable from "../../../../components/Datatable/Datatable";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import { confirmAlert } from "react-confirm-alert";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import DfmasterPDF from "../../../../components/PDF/DfmasterPDF";
import LoginModal from "../../../../components/Modal/Modal";
import redisMiddleware from "../../../../MiddleWare/redisMiddleware";
import SingleSelect from "../../../../components/Form/SingleSelect";
import CmsContent from "../../../../MiddleWare/CmsContent";
import swal from "sweetalert";
import { Link } from "react-router-dom";

class NewVariantMaster extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            ServiceId: localStorage.getItem("currentService"),
            customerId: localStorage.getItem("userId"),
            alertVisible: false,
            color: "",
            textalert: "",
            ExistedDataVisible: false,
            ColourName: "",
            samplecsv: 0,
            errorColourName: "",
            isEdit: false,
            disableValue: false,
        };
    }

    column = [
        {
            Header: "SINO",
            Cell: d => { return (d.index + 1) }
        },
        {
            Header: "Variant",
            accessor: "variant",
        },

        {
            Header: "Model",
            accessor: "modelid",
        },
        {
            Header: "Start year",
            accessor: "syear",
        },
        {
            Header: "End year",
            accessor: "eyear",
        },
        {
            Header: "Edit",
            accessor: "edit",
            Cell: (d) => this.edit(d),
        },
        // {
        //   Header: <b>Delete</b>,
        //   selector: "delete",
        //   Cell: (d) =>
        //     this.DataTableButton(d.original, "Delete", () => {
        //       this.buttonDeletes(d);
        //     }),
        // },
    ];

    async componentDidMount() {
        await this.main()
    }
    main = async () => {
        try {
            this.setState({ loading: true })
            let modeloptions = [];
            // const variant = await redisMiddleware.singleTable(
            //   "tbl_variant_master",
            //   this.state.ServiceId,
            //   "ServiceId"
            // );
            const variant = await CmsContent.getFreedom(
                "*",
                "tbl_variant_master",
                `ServiceId = 10`,
                1,
                "id DESC"
            )
            const model = await CmsContent.getFreedom(
                "*",
                "tbl_dfcarsmodel",
                `ServiceId = 10`,
                1,
                "id DESC"
            )
            // co
            if (model) {
                model.data.map((mval, k) => {
                    modeloptions.push({
                        label: mval.Model + ` (${mval.syear ? mval.syear : "-"} - ${mval.eyear ? mval.eyear : "-"} )`,
                        value: mval.id
                    });
                });
                await this.setState({ modeloptions });
            }
            let data = [];
            if (variant) {
                console.log(model.data);
                variant.data.map((ival, i) => {
                    model.data.map((mval, j) => {
                        if (ival.modelid == mval.id) {
                            ival.modelid = mval.Model;
                            ival.modelId = mval.id
                            ival.syear = mval.syear
                            ival.eyear = mval.eyear
                        }
                    });
                });
                this.setState({ data: variant.data });
            }

            if (variant.data.length > 0) {
                const header = ["SI.NO", "variant", "Model"];
                const excelHead = [
                    { label: "variant", key: "variant" },
                    { label: "Model", key: "modelid" },
                ];
                this.setState({
                    excelHead,
                    header,
                    excel: variant.data,
                    title: "Variant",
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
        }
    }
    DataTableButton = (info, con, func) => {
        let classes = 'btn btn-';
        // classes += info;
        if (info.status == "active") {
            classes += "danger";
            return (
                <center>
                    <button type="button" className={classes} onClick={func}>
                        {con}
                    </button>
                </center>
            );
        } else {
            classes += "info";
            return (
                <center>
                    <button type="button" className={classes}>
                        Deleted
                    </button>
                </center>
            );
        }
    };
    buttonDeletes = (value) => {
        console.log(value);
        confirmAlert({
            title: "Confirmation to Delete",
            message: "Are you sure you want to delete this?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => this.buttonDelete(value),
                },
                {
                    label: "No",
                },
            ],
        });
    };

    buttonDelete = async (value) => {
        const previousData = [...this.state.data];
        let id = value.original.id;
        let Index = value.index;
        try {
            const result = await cmsContent.getSingleConditionedValue(
                "tbl_variant_master",
                "id",
                id,
                "Delete"
            );
            // let subgroupArray = {};
            // subgroupArray.status = "inactive";

            // const result = await cmsContent.updateMaster(
            //   "tbl_variant_master",
            //   id,
            //   subgroupArray
            // )
            if (result) {
                let datas = previousData.filter((delelteid, i) => {
                  if (delelteid.id !== value.original.id) {
                    return delelteid;
                  }
                });
                let previousdata = [...this.state.data];
                previousdata[Index].status = "inactive";
                this.setState({
                    data: datas,
                    alertVisible: true,
                    textalert: "Selected variant Deleted",
                    color: "danger",
                    ColourName: "",
                    selectedmodel:""

                });
                setTimeout(() => this.setState({ alertVisible: false }), 3000);
            }
        } catch (error) {
            console.log(error);
        }
    };

    edit = (d) => {
        let value = d;
        return (
            <center>
                <button
                    type="button"
                    className="btn btn-info"
                    onClick={() => this.edition(value)}
                >
                    Edit
                </button>
            </center>
        );
    };

    handlechangeData = async (e) => {
        let value = e.target.value;
        this.setState({ [e.target.name]: e.target.value, value });
    };

    submitCategory = async () => {
        const {
            ColourName,
            customerId,
            value,
            data,
            ServiceId,
            selectedmodel,
        } = this.state;
        if (!ColourName) {
            this.setState({ errorColourName: "Enter variant Name" });
            return false;
        }
        if (!selectedmodel) {
            this.setState({ errormodel: "Select Model" });
            return false;
        }
        let filtered_item =
            data && data.length > 0
                ? data.filter((i) => {
                    return i.variant === value && i.modelId == selectedmodel.value;
                })
                : [];

        if (filtered_item.length > 0) {
            this.setState({ error: "variant not be repeated" });
            return false;
        }
        let groupArray = {};
        groupArray.variant = ColourName.trim();
        groupArray.status = "active";
        groupArray.customerId = 620;
        groupArray.ServiceId = 10;
        groupArray.modelid = selectedmodel.value;
        try {
            this.setState({ disableValue: true });
            const result = await cmsContent.addMaster(
                "tbl_variant_master",
                groupArray
            );
            if (result) {
                // let valueArray = {};
                // valueArray.id = result.data.insertId;
                // valueArray.variant = groupArray.variant;
                // valueArray.status = "active";
                // valueArray.customerId = customerId;
                // valueArray.ServiceId = ServiceId;
                // valueArray.modelid = selectedmodel.value;
                // const newData = [valueArray, ...this.state.data];
                this.setState({
                    // data: newData,
                    alertVisible: true,
                    disableValue: false,
                    textalert: "New variant Added",
                    color: "success",
                    errorColourName: "",
                    error: "",
                    ColourName: "",
                    selectedmodel: "",
                });
                this.main()
                setTimeout(() => this.setState({ alertVisible: false }), 3000);
            }
        } catch (error) {
            console.log(error);
        }
    };

    onDismiss = () => {
        this.setState({ alertVisible: false, formAlertdelete: false });
    };

    edition = (d) => {
        // console.log(d,"ddddd");
        let Index = d.index;
        let value = d.original;
        let editid = value.id;
        let ColourName = value.variant;
        let selectedmodel = this.state.modeloptions.filter(x => x.value == value.modelId)
        this.setState({
            editid,
            Index,
            ColourName,
            isEdit: true,
            selectedmodel
        });
    };

    UpdateCategory = async () => {
        const { editid, ColourName, Index, selectedmodel } = this.state;
        if (!ColourName) {
            this.setState({ errorColourName: "Enter variant Name" });
            return false;
        }
        let subgroupArray = {};
        subgroupArray.variant = ColourName;
        subgroupArray.status = "active";
        subgroupArray.modelid = selectedmodel.value;
        try {
            const result = await cmsContent.updateMaster(
                "tbl_variant_master",
                editid,
                subgroupArray
            );
            if (result) {
                // let previousdata = [...this.state.data];
                // console.log(previousdata);
                // previousdata[Index].variant = ColourName;
                // setTimeout(() => this.setState({ data: previousdata }), 500);
                this.setState({
                    alertVisible: true,
                    disableValue: false,
                    editid: null,
                    ColourName: "",
                    errorColourName: "",
                    error: "",
                    Index: null,
                    textalert: "Variant Updated",
                    color: "success",
                    isEdit: false,
                    selectedmodel:""
                });
                this.main()
                setTimeout(() => this.setState({ alertVisible: false }), 3000);
            }
        } catch (error) {
            console.log(error);
        }
    };
    handlemodel = async (e) => {
        console.log(e);
        this.setState({ selectedmodel: e });
    };

    CSVbuttonChange = async () => {
        await this.setState(state => ({ isToggleOn: !state.isToggleOn }));
        if (this.state.isToggleOn === true) {
            await this.setState({ samplecsv: 1 })
        } else (
            await this.setState({ samplecsv: 0 })
        )
    };
    fileupload = e => {
        this.setState({ csv: e.target.files[0] })
    }
    submitcsv = async () => {
        let { customerId, ServiceId } = this.state
        const files = this.state.csv;
        if (!files) {
            this.setState({ fileerror: "Please Select CSV File" });
            return false;
        }
        const formData = new FormData();
        formData.append("file", files);
        formData.append("ServiceId", ServiceId ? ServiceId : null);
        formData.append("customerId", customerId ? customerId : null);
        console.log([...formData])
        try {
            const result = await cmsContent.DFCARSVARIANTCSV("tbl_variant_master", formData);
            console.log(result);
            if (result && result.data.color == "success") {
                swal({
                    title: `${result.data.content1 ? result.data.content1 : result.data.content}`,
                    icon: `success`,
                    button: {
                        text: "Ok",
                        closeModal: false,
                    },
                }).then(async (willDelete) => {
                    await this.main()
                    swal.close()
                });
            }
            else if (result && result.data.color1 == "danger" && result.data.ExistedData.length) {
                await this.setState({ ExistedData: result.data.ExistedData, ExistedDataLabel: result.data.content1 })
                swal({
                    title: `${result.data.content1 ? result.data.content1 : result.data.content}`,
                    icon: `warning`,
                    button: {
                        text: "Ok",
                        closeModal: false,
                    },
                }).then(async (willDelete) => {
                    swal.close();
                    this.ExistedDataAllignment()
                });
            }
            else {
                swal({
                    title: `${result.data.content1 ? result.data.content1 : result.data.content}`,
                    icon: `warning`,
                    button: {
                        text: "Ok",
                        closeModal: false,
                    },
                }).then(async (willDelete) => {
                    window.location.reload();
                });
            }
        }

        catch (error) {
            console.log(error)
        }
    };
    ExistedDataAllignment = async () => {
        let { ExistedData, ExistedDataLabel } = this.state
        let ExistedDataContent = []
        if (ExistedData) {
            ExistedData.map((ival, i) => {
                console.log(ival);
                ExistedDataContent.push(
                    <tr>
                        <th scope="row">{ival.variant}</th>
                        <td>{ival.model}</td>
                        <td>{ival.syear}</td>
                        <td>{ival.eyear}</td>
                        <td>{ival.issue ? ival.issue : ExistedDataLabel}</td>
                    </tr>
                )
            })
        }
        await this.setState({ ExistedDataContent, ExistedDataVisible: true })
    }
    reset = async () => {
        await this.setState({ ExistedDataVisible: false, ExistedDataContent: [], ExistedDataLabel: "" })
    }
    render() {
        const { excelHead, header, excel, title, alertVisible, textalert, badgeColor, badgeText, fileerror, ExistedDataVisible, ExistedDataContent, ExistedDataLabel } = this.state;
        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h1>Variant Master</h1>
                                    </div>
                                    <div className="card-body">
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-7">
                                                <Alert
                                                    className="badge-content"
                                                    color={this.state.color}
                                                    isOpen={alertVisible}
                                                    toggle={this.onDismiss}
                                                >
                                                    {textalert}
                                                </Alert>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="exampleInputEmail1">Select Model</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <SingleSelect
                                                    placeholder="Select Model"
                                                    options={this.state.modeloptions}
                                                    onChange={(e) => this.handlemodel(e)}
                                                    selectedService={this.state.selectedmodel}
                                                />
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-4" />
                                            <span className="error-show ">
                                                {this.state.errormodel}
                                            </span>
                                            <span className="error-show ">{this.state.error}</span>
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="exampleInputEmail1">Variant</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="ColourName"
                                                    name="ColourName"
                                                    placeholder="Enter Variant"
                                                    value={this.state.ColourName}
                                                    onChange={this.handlechangeData}
                                                />
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-4" />
                                            <span className="error-show ">
                                                {this.state.errorColourName}
                                            </span>
                                            <span className="error-show ">{this.state.error}</span>
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-3" />
                                            <div className="col-sm-5">
                                                <button
                                                    type="button"
                                                    className={`btn btn-${this.state.isEdit ? "secondary" : "primary"
                                                        }`}
                                                    disabled={this.state.disableValue}
                                                    onClick={
                                                        this.state.isEdit
                                                            ? this.UpdateCategory
                                                            : this.submitCategory
                                                    }
                                                >
                                                    {this.state.isEdit ? "Update Variant" : "Add Variant"}
                                                </button>
                                            </div>
                                            <div className="col-sm-2" />
                                        </div>
                                        <br></br>
                                        <div className="row form-group">
                                            {this.state.excel && this.state.excel.length > 0 && <Link className="btn btn-warning"
                                                to={{ pathname: "/Lead/Pdfloader", state: { data: excel, title: title, excelHead: excelHead, side: 0, type: 1 } }}
                                            >Export</Link>}&nbsp;&nbsp;&nbsp;
                                            {/* &nbsp;&nbsp;&nbsp; {this.state.excel && this.state.excel.length > 0 ? (
                        <PDFDownloadLink
                          style={{ color: "white" }}
                          document={
                            <DfmasterPDF
                              title={this.state.title}
                              header={this.state.header}
                              data={this.state.excel}
                            />
                          }
                          fileName="AddVariant.pdf"
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? "Loading document..." : "Download Pdf"
                          }
                          <button className="btn btn-warning">PDF</button>
                        </PDFDownloadLink>
                      ) : null}&nbsp;&nbsp;&nbsp;
                      {this.state.data && this.state.data.length ? (
                        <CSVLink
                          data={this.state.data}
                          headers={this.state.excelHead}
                        >
                          <button
                            className="btn btn-success"
                            style={{ marginLeft: "5px" }}
                          >
                            Excel
                          </button>
                        </CSVLink>
                      ) : null}&nbsp;&nbsp;&nbsp; */}
                                            {this.state.data && this.state.data.length ? (
                                                <LoginModal
                                                    buttonTitle="Import Csv"
                                                    title="Csv file upload"
                                                    // buttonStyle={{ marginLeft: "-105px" }}
                                                    extraClass="info"
                                                    onClick={this.reset}
                                                    reset={this.reset}
                                                    id="import"
                                                    bodyText={
                                                        <div className="container">
                                                            {ExistedDataVisible && ExistedDataContent && ExistedDataLabel ?
                                                                <React.Fragment>
                                                                    <h3>{ExistedDataLabel} </h3>
                                                                    <br />
                                                                    <table class="table table-hover">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col">VARIANT</th>
                                                                                <th scope="col">MODEL</th>
                                                                                <th scope="col">SYEAR</th>
                                                                                <th scope="col">EYEAR</th>
                                                                                <th scope="col">ISSUES</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {ExistedDataContent}
                                                                        </tbody>
                                                                    </table >
                                                                </React.Fragment> :
                                                                <React.Fragment>
                                                                    <div className="row form-group">
                                                                        <div className="col-sm-2" />
                                                                        <div className="col-sm-2">
                                                                            <label for="exampleInputEmail1">
                                                                                <b>CSV Upload</b>
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-sm-5">
                                                                            <input
                                                                                type="file"
                                                                                className="custom-file-input"
                                                                                id="customFile"
                                                                                accept='.csv'
                                                                                // value={this.state.csv}
                                                                                onChange={this.fileupload}
                                                                            />
                                                                            <span className="error-shows">{fileerror}</span>
                                                                            <br />
                                                                            <label
                                                                                className="custom-file-label"
                                                                                htmlFor="customFile"
                                                                            >
                                                                                {this.state.csv ? this.state.csv.name : 'Choose File'}
                                                                            </label>
                                                                            <br />
                                                                            <br />
                                                                        </div>
                                                                        <div className="col-sm-3" />
                                                                    </div>
                                                                    <div className="row form-group">
                                                                        <div className="col-sm-1" />
                                                                        {this.state.samplecsv === 0 && (
                                                                            <div className="col">
                                                                                <a href="../../files/SamplevariantCSVUpload.csv" download="SamplevariantCSVUpload.csv">
                                                                                    download sample.csv
                                                                                </a>
                                                                            </div>
                                                                        )}
                                                                        {this.state.samplecsv === 1 && (
                                                                            <div className="col">
                                                                                <table id="example" className="table table-striped table-bordered" style={{ width: "100%" }}>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>model</th>
                                                                                            <th>syear</th>
                                                                                            <th>eyear</th>
                                                                                            <th>variant</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>CITY</td>
                                                                                            <td>2012</td>
                                                                                            <td>2015</td>
                                                                                            <td>1.5 DX</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>CITY</td>
                                                                                            <td>2016</td>
                                                                                            <td>2020</td>
                                                                                            <td>1.3 EXI</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>CITY</td>
                                                                                            <td>2021</td>
                                                                                            <td>2022</td>
                                                                                            <td>1.3 LXI</td>
                                                                                        </tr>

                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        )}
                                                                    </div>

                                                                    <div className="row ">
                                                                        <div className="col" />
                                                                        <div className="col">
                                                                            <button className="btn btn-warning waves-effect d-flex justify-content-center" onClick={this.CSVbuttonChange}
                                                                                type="button"
                                                                            >
                                                                                {this.state.isToggleOn ? "Download Samples" : "Samples In Table"}
                                                                            </button>
                                                                        </div>
                                                                        <div className="col">
                                                                            <button
                                                                                type="button"
                                                                                class="btn btn-primary waves-effect d-flex justify-content-center"
                                                                                onClick={(e) => this.submitcsv(e)}
                                                                            >
                                                                                Submit
                                                                            </button>
                                                                        </div>
                                                                        <div className="col" />
                                                                    </div>
                                                                    <br />
                                                                </React.Fragment>
                                                            }
                                                        </div>
                                                    }
                                                />
                                            ) : null}
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="row form-group">
                                            <div className="col-sm-12">
                                                {this.state.loading == true ? <div className="text-center"> <i
                                                    className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                                                ></i></div> :
                                                    this.state.data.length ? (
                                                        <Datatable
                                                            data={this.state.data}
                                                            columnHeading={this.column}
                                                        />
                                                    ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}
export default NewVariantMaster;
