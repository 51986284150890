import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
//import DfCarsEnquiry from "./DfCarsEnquiry";
import UpdateStatus from "../SalesMaster/UpdateStatus";
import LoginModal from "../../../../components/Modal/Modal";
import moment from "moment";
import Datatable from "../../../../components/Datatable/Datatable";
import { sortedLastIndexBy } from "lodash";
import UsertypeComments from "../UserMaster/UsertypeComments";
import Icon from "react-icons-kit";
import { calendar_ok } from 'react-icons-kit/ikons/calendar_ok'

class PocProspectBuyerList extends FormMiddleWare {
    constructor(props) {
      super(props);
      this.state = {
        Data: [],
        Current_Date: new Date(),
        User: JSON.parse(localStorage.getItem("userlog")),
        LocationID:localStorage.getItem(`LocationID`),
        EnquiryData:[],
        alertVisible:false,
        column:[
            {
                Header: "Prospect",
                accessor: "name"
              },
              {
                Header: "Contact",
                accessor: "mobile"
              },
              {
              Header: "HandledBy",
              accessor: "Jobrole"
              },
              {
                Header: "Next-Action-plan",
                accessor: "followup",
                Cell: d=>this.followup(d)
              
              },
              {
                Header: "Location",
                accessor: "Location"
              },
               {
                Header: "Communication",
                accessor: "mobile",
                Cell: d => this.UpdateSTatus23(d)
              },
            // {
            //    Header: "Specification",
            //    accessor: "mobile",
            //    Cell: d => this.Specifications(d)
            // },
            {
                Header: "Sourcing",
                accessor: "mobile",
                Cell: d => this.Sourcing(d)
             },
             {
                Header: "Contracted",
                accessor: "mobile",
                Cell: d => this.Contracted(d)
             },
            {
                Header: "Delivery",
                accessor: "mobile",
                Cell: d => this.Delivery(d)
             },
             {
                Header: "Status",
                accessor: "mobile",
                Cell: d => this.UpdateStatus(d)
             },
              {
                Header: "Close",
                accessor: "mobile",
                Cell: d => this.Approval(d)
              }
           
        ],
        valueofstatus: [{ "live": 0, "follow": 0, "MTD": 0 }],
        EditData: {},
      IsClose:false,
      CustomerId:null
      }
    };

    followup=(d)=>{
        return( <span style={{cursor:'pointer',textDecoration: "underline"}} data-toggle="modal" data-target={"#status"}
        onClick={()=>this.Editdata43(d)}
        >{d.original.followup}</span>)
        }
  
        Editdata43 = async (d) => {
         // console.log(111)
          this.setState({ EditData: d.original,CustomerId:d.original.id,IsClose:false, isEdit: true, Index: d.index })
        }

    UpdateSTatus23 = (d) => {
      return (<button type="button" className="btn btn-warning"
        data-toggle="modal" data-target={"#ccc"}
        onClick={() => this.Editdata23(d)}
       >View</button>);
    }
  
    Editdata23=async(d)=>{
      
      this.setState({stage:"action",CustomerId:d.original.id})
    }

    Approval = (d) => {
      return (<button type="button" className="btn btn-danger"
          data-toggle="modal" data-target={"#status"}
           onClick={()=>this.F_proofForm3(d)}
          >Close</button>)
      }

      F_proofForm3=async(d)=>{
        this.setState({ EditData: d.original,IsClose:true,Index: d.index });
   }

    UpdateStatus=(d)=>{
        if(d.original.current_status=='hot'|| d.original.current_status==null){
        return(
            <button type="button" className="btn btn-success"
                onClick={()=>this.ChangeStatus(d)}
                >Hot</button>
            )
        }else{
            return(
                <button type="button" className="btn btn-primary"
                    onClick={()=>this.ChangeStatus(d)}
                    >Warm</button>
                )
        }

    };

    changeModelstate = async (s, v) => {
        this.setState({ [s]: v });
      }

    ChangeStatus=async(d)=>{
        let subgroupArray={}
        if(d.original.current_status=='hot'|| d.original.current_status==null){
            subgroupArray.current_status='warm';
        }else{
            subgroupArray.current_status='hot';
        }
        
        try{
             let data = d.original;
             let Index = d.index;
            const result = await cmsContent.updateMaster(
                "tbl_dfcarz_prosperities",
                d.original.id,
                subgroupArray
              );
              if(result){
                 let newData = [...this.state.Data];
                 newData[Index].current_status = subgroupArray.current_status;
                 this.setState({
                     Data:newData
                 })
              }

        }catch(error){
            console.log(error);
        }
    }

    Specifications=(d)=>{
        if(d.original.Specifications==1){
            return(
                <button type="button" className="btn btn-primary"
                    onClick={()=>this.tbl_Specifications_Sheet_Proof(d)}
                    >View</button>
                )
        }else{
            return(
                <button type="button" className="btn btn-warning"
                   >Pending</button>
                );
        }
       
      };

      Sourcing=(d)=>{
        if(d.original.Sourcing==1){
            return(
                <button type="button" className="btn btn-primary"
                    onClick={()=>this.tbl_Autoterrace_enquiry_form(d)}
                    >View</button>
                )
        }else{
            return(
                <button type="button" className="btn btn-warning"
                   >Pending</button>
                );
        }
      }

      Contracted=(d)=>{
        if(d.original.Contracted==1){
            return(
                <button type="button" className="btn btn-primary"
                    onClick={()=>this.tbl_Booking_Confirmation_Reproof(d)}
                    >View</button>
                )
        }else{
            return(
                <button type="button" className="btn btn-warning"
                   >Pending</button>
                );
        }
      };

      Delivery=(d)=>{
        if(d.original.Delivery==1){
            return(
                <button type="button" className="btn btn-primary"
                    onClick={()=>this.tbl_Delivery_Note_Buyer_Reproof(d)}
                    >View</button>
                )
        }else{
            return(
                <button type="button" className="btn btn-warning"
                   >Pending</button>
                );
        }
      };

      tbl_Booking_Confirmation_Reproof=async(d)=>{
        window.open(`/DFCars/AT_Booking_Confirmation_Reproof/${d.original.id}`,'_blank')
      }
      
      tbl_Autoterrace_enquiry_form=async(d)=>{
        window.open(`/DFCars/Autoterrace_enquiry_form/${d.original.id}`,'_blank')
      }

      tbl_Specifications_Sheet_Proof=async(d)=>{
        window.open(`/DFCars/AT_Specifications_Sheet_Proof/${d.original.id}`, '_blank')
      }

      tbl_Delivery_Note_Buyer_Reproof=async(d)=>{
        window.open(`/DFCars/AT_Delivery_Note_Buyer_Reproof/${d.original.id}`,'_blank')
      }
 
    async componentDidMount() {
        try{
         let result = await cmsContent.getFreedom(
                `tbl_dfcarz_prosperities.*,tbl_location.name as Location,tbl_user_web.userName as Jobrole`,
                `tbl_dfcarz_prosperities,tbl_user_web,tbl_location`,
                `tbl_dfcarz_prosperities.location = tbl_location.id and  tbl_dfcarz_prosperities.type='buyer' and tbl_dfcarz_prosperities.salesid = tbl_user_web.id` 
                );
                //and tbl_dfcarz_prosperities.userid=${this.state.User.id}
                if(result){
                    //console.log(result.data);
                    result.data.map((ival,i)=>{
                        ival.Specifications=0;
                        ival.Sourcing=0;
                        ival.Contracted=0;
                        ival.Delivery=0;
                    })
                   
                    let Specifications = await cmsContent.getFreedom(
                        `tbl_Specifications_Sheet_Proof.customerid`,
                        `tbl_dfcarz_prosperities,tbl_Specifications_Sheet_Proof`,
                        `tbl_Specifications_Sheet_Proof.customerid =tbl_dfcarz_prosperities.id`,
                        1,
                        1 
                        );
                     let wait = await result.data.map(async(ival,i)=>{
                            Specifications.data.map((jval,j)=>{
                                if(ival.id==jval.customerid){
                                    ival.Specifications=1;
                                }
                            });
                        });
                        await Promise.all(wait);

                        let Sourcing = await cmsContent.getFreedom(
                            `tbl_Autoterrace_enquiry_form.customerid`,
                            `tbl_dfcarz_prosperities,tbl_Autoterrace_enquiry_form`,
                            `tbl_Autoterrace_enquiry_form.customerid =tbl_dfcarz_prosperities.id`,
                            1,
                            1 
                            );

                        let wait1 = await result.data.map(async(ival,i)=>{
                            Sourcing.data.map((jval,j)=>{
                                if(ival.id==jval.customerid){
                                    ival.Sourcing=1;
                                }
                            });
                        });
                            await Promise.all(wait1);

                            let Contracted = await cmsContent.getFreedom(
                                `tbl_Booking_Confirmation_Reproof.customerid`,
                                `tbl_dfcarz_prosperities,tbl_Booking_Confirmation_Reproof`,
                                `tbl_Booking_Confirmation_Reproof.customerid =tbl_dfcarz_prosperities.id`,
                                1,
                                1 
                                );

                                let wait2 = await result.data.map(async(ival,i)=>{
                                    Contracted.data.map((jval,j)=>{
                                        if(ival.id==jval.customerid){
                                            ival.Contracted=1;
                                        }
                                    });
                                });
                                    await Promise.all(wait2);

                let Delivery = await cmsContent.getFreedom(
                    `tbl_Delivery_Note_Buyer_Reproof.customerid`,
                    `tbl_dfcarz_prosperities,tbl_Delivery_Note_Buyer_Reproof`,
                    `tbl_Delivery_Note_Buyer_Reproof.customerid =tbl_dfcarz_prosperities.id`,
                    1,
                    1 
                    );

                    let wait3 = await result.data.map(async(ival,i)=>{
                        Delivery.data.map((jval,j)=>{
                            if(ival.id==jval.customerid){
                                ival.Delivery=1;
                            }
                        });
                    });
                        await Promise.all(wait3);        
        

                  console.log(result.data);
                    this.setState({Data:result.data})
                    let v1 = 0
                    let v2 = 0
                    let v3 = 0
                    let v4 = 0
                    let v5 = 0
                    let v6 = 0
                    let dd = this.state.Current_Date;
                    console.log(dd);
                    let DA = await dd.getDate();
                    if (DA < 10) {
                      DA = await "0" + DA
                    }
                    let DT = await dd.getMonth() + 1;
                    let DT1 = DT
                    if (DT < 10) {
                      DT = await "0" + DT
                    }
                    let YR = await dd.getFullYear();
                    let data1 = []
                    let dataof = []
                    let livedata = []
                    let data2 = []
                    let FullDate = await YR + "-" + DT + "-" + DA
          
                    await result.data.map((ival, i) => {
          
                      if ("Yes, Interested in Buying" == ival.pendingStatus1 && "Schedule walkin" == ival.pendingStatus2) {
                        v1 = ++v1
                        livedata.push(ival)
                      } else if ("Yes, Interested in Buying" == ival.pendingStatus1 && "Schedule followup" == ival.pendingStatus2) {
                        v2 = ++v2
                        data2.push(ival)
                      }
                      // if (ival.remainder == 1) {
                      //   v6 = ++v6
                      //   remainder.push(ival)
                      // }
                      //  })
                      if (ival.Next_Contact_date) {
                        let date = ival.Next_Contact_date.split(" ");
                        let monthof = date[0].split("-")
                        if (date[0] == FullDate) {
                          v4 = ++v4;
                          data1.push(ival)
                        }
                        console.log(monthof[1], DT, DT1);
                        if (monthof[1] == DT || monthof[1] == DT1) {
                          v5 = ++v5;
                          dataof.push(ival)
                        }
                      }
                    })
                    console.log(v4);
                    let valueofstatus = [{ "live": v1, "remainder": v6, "follow": v2, "today": v4, "MTD": v5 }]
                    console.log(valueofstatus);
                    this.setState({
                      livedata,  data1, dataof, valueofstatus                   
                    });
                }
        }catch(error){
            console.log(error)
        }
    }
    dashArr = [
      { name: "Scheduled walkin ", color: "#013220", value: 1, label: "live" },
      { name: "FTD ", color: "red", value: 6, label: "ftd" },
      { name: "MTD ", color: "#013220", value: 5, label: "MTD" },
      { name: "Scheduled followup", color: "orange", value: 2, label: "today" },
      // { name: "Remainders", color: "blue", value: 3, label: "remainder" },
    ]
    edash = () => {
      return (<div className="container-fluid">
        <div className="card">
          {/* < div className="row" > */}
          <div className="card-header">
            <div className="col-sm-6" >
              <h2 className="mt-2 black">Dashboard</h2>
            </div>
          </div>
          <div className="card-body">
            <div className="row col-12 mt-4">
            <div className="col-sm-2 "/>
              <div className="col-sm-2 ">
                <div className="dashboardCard1" onClick={() => this.onClk(1)} style={{ textAlign: 'center' }}>
                  <Icon className="dashboardBadge" icon={calendar_ok} style={{ color: 'green' }} size={35} />
                  <h4 className="mt-2 black" style={{ paddingLeft: "30px" }}>Scheduled walkin</h4>
                  <div style={{ textAlign: 'center' }}>
                    <h1 className="bold mt-4 mb-2 black"><b> {this.state.valueofstatus[0].live ? this.state.dashboard[0].live : 0}</b></h1>
                  </div>
                </div>
              </div>
              <div className="col-sm-2 ">
                <div className="dashboardCard1" onClick={() => this.onClk(2)} style={{ textAlign: 'center' }}>
                  <Icon className="dashboardBadge" icon={calendar_ok} style={{ color: 'green' }} size={35} />
                  <h4 className="mt-2 black" style={{ paddingLeft: "30px" }}>Scheduled followup</h4>
                  <div style={{ textAlign: 'center' }}>
                    <h1 className="bold mt-4 mb-2 black"><b> {this.state.valueofstatus[0].today ? this.state.dashboard[0].today : 0}</b></h1>
                  </div>
                </div>
              </div>           
             
              <div className="col-sm-2 ">
                <div className="dashboardCard1" onClick={() => this.onClk(5)} style={{ textAlign: 'center' }}>
                  <Icon className="dashboardBadge" icon={calendar_ok} style={{ color: 'green' }} size={35} />
                  <h4 className="mt-2 black" style={{ paddingLeft: "30px" }}>MTD</h4>
                  <div style={{ textAlign: 'center' }}>
                    <h1 className="bold mt-4 mb-2 black"><b> {this.state.valueofstatus[0].MTD ? this.state.dashboard[0].MTD : 0}</b></h1>
                  </div>
                </div>
              </div>
              <div className="col-sm-2 ">
                <div className="dashboardCard1"  style={{ textAlign: 'center' }}>
                  <Icon className="dashboardBadge" icon={calendar_ok} style={{ color: 'green' }} size={35} />
                  <h4 className="mt-2 black" style={{ paddingLeft: "30px" }}>FTD</h4>
                  <div style={{ textAlign: 'center' }}>
                  <div style={{ textAlign: 'center' }}>
                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.ftd !== 0 ? this.state.ftd : <b></b>}</b></h1>
                  </div>
                  <div>
                    <h1 className="bold mt-4 mb-3"><b><input type="date" style={{ maxWidth: "80%" }} className="form-control" onChange={this.cng} name="dateval"></input></b></h1>
                  </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-2 "/>
            </div>
          </div>
        </div>
      </div>
      )
    }
    cng = async (e) => {
      let d = e.target.value
      console.log(d);
      const { Data } = this.state;
      let v4 = 0;
      let data1 = []
      let datas = await Data.map((ival, i) => {
        if (ival.Next_Contact_date) {
          let date = ival.Next_Contact_date.split(" ");
          console.log(date[0], d);
          if (date[0] == d) {
            v4 = ++v4;
            data1.push(ival)
          }
        }
      })    
      await this.setState({ ftd: v4, Data: data1, ftddate: d })
      setTimeout(() => {
        window.scroll({ top: 400,left:0,behavior: "smooth" })
      }, 1000);  
    
     
     
      // window.scroll({
      //   top: 400,
      //   left: 0,
      //   behavior: "smooth",
      // });
    }
    onClk = (val) => {
      if (val === 1) { this.setState({ Data: this.state.livedata, ftd: 0 }) }
      // if (val === 3) { this.setState({ Data: this.state.dataof, ftd: 0 }) }
      // if (val === 4) { this.setState({ Data: this.state.data1, ftd: 0 }) }
      if (val === 5) { this.setState({ Data: this.state.dataof, ftd: 0 }) }
      //if (val === 6) { this.setState({ Data: this.state.data2, ftd: 0 }) }
      if (val === 2) { this.setState({  Data: this.state.data1 }) }
      window.scroll({
        top: 400,
        left: 0,
        behavior: "smooth",
      });
    }

    render(){

        return( <React.Fragment>
            <main className="main my-4">
            <div className="container-fluid">
              {this.edash()}
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h1>Prospective Buyer List</h1>
                    </div>
                    <div className="card-body">
                    <div className="row form-group">
                    <div className="col-sm-4">
                      <LoginModal
                        buttonTitle="Add Enquiry"
                        title="Update Commets"
                        id="ccc"
                        extraClass="btn btn-primary"
                        extraStyle={{width:'1000px'}}
                        onClick={this.reset}
                        buttonStyle={{ backgroundColor: "white", border: "white" }}
                        bodyText={
                          <UsertypeComments
                          stage={this.state.stage}
                          customerid={this.state.CustomerId}
                          />}
                      />
                    </div>
                    <div className="col-sm-4">
                      <LoginModal
                        buttonTitle="Add Enquiry"
                        title="Update Status"
                        id="status"
                        extraClass="btn btn-primary"
                        onClick={this.reset}
                        buttonStyle={{ backgroundColor: "white", border: "white" }}
                        bodyText={
                          <UpdateStatus
                            EditData={this.state.EditData}
                            these={this.changeModelstate}
                            isEdit={this.state.isEdit}
                            FullData={this.state.Data}
                            Index={this.state.Index}
                            IsClose={this.state.IsClose}
                            CustomerId={this.state.CustomerId}
                          />}
                      />
                    </div>
                  </div>
                    {this.state.Data.length ? (
                                  <Datatable
                                    data={this.state.Data}
                                    columnHeading={this.state.column}
                                  />
                                ) : (<div style={{ textAlign: "center" }} ><b>No Data Found</b></div>)}
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>    
                    </main>
                    </React.Fragment>)
        }
    }
    export default PocProspectBuyerList;