import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import DfCarsImageUpload from "./DfCarsImageUpload"
import moment from "moment";
import LoginModal from "../../../../components/Modal/Modal";
import Datatable from "../../../../components/Datatable/Datatable";


class MyInventory1 extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            filterdata: [],
            leadsavailable: [],
            customerId: localStorage.getItem("userId"),
            EnquiryData: [],
            isView: false,
            alertVisible: false,
            column: [
                {
                    Header: "NAME",
                    accessor: "name"
                },
                {
                    Header: "MOBILE",
                    accessor: "mobile"
                },
                {
                    Header: "EMAIL",
                    accessor: "email"
                },
                {
                    Header: "MAKE",
                    accessor: "makename"
                },
                {
                    Header: "MODEL",
                    accessor: "modelname"
                },
                {
                    Header: "COLOUR",
                    accessor: "ColourName"
                },
                {
                    Header: "Price",
                    accessor: "budget"
                },

            ],
            EditData: {},
            Index: null
        }
    }




    DataPass = async (d) => {
        this.setState({ EditData: d.original, Index: d.index })
    }








    async componentDidMount() {
        const { match: { params } } = this.props;
        let result = (await cmsContent.getFreedom("tbl_dfcarz_prosperities.*,tbl_make_master.Make as makename,tbl_dfcarsmodel.Model as modelname,tbl_colour.colour as ColourName",
            `tbl_dfcarz_prosperities,tbl_make_master,tbl_dfcarsmodel,tbl_colour`, `tbl_dfcarz_prosperities.make="${params.make}" and tbl_dfcarz_prosperities.model="${params.model}" and tbl_dfcarz_prosperities.location="${params.location}" and tbl_dfcarz_prosperities.make = tbl_make_master.id and tbl_dfcarsmodel.id = tbl_dfcarz_prosperities.model and tbl_colour.id = tbl_dfcarz_prosperities.color
        and tbl_dfcarz_prosperities.type="buyer" and tbl_dfcarz_prosperities.status="active"`, 1, 1)).data

        if (result) {
            this.setState({ leadsavailable: result })
        }
        console.log("res", result);
    }



    changeModelstate = async (s, v) => {
        this.setState({ [s]: v });
    }


    render() {
        const { match: { params } } = this.props;
        const makeid = localStorage.getItem('makeid');
        return (<React.Fragment>
            <main className="main my-4">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h1>My Inventory</h1>
                                </div>
                                <div className="card-body">
                                    <div className="row form-group">
                                        <div className="col-sm-4">
                                            <LoginModal
                                                buttonTitle="Add Enquiry"
                                                title="Upload Images"
                                                id="images"
                                                extraStyle={{ width: 'auto' }}
                                                extraClass="btn btn-primary"
                                                onClick={this.reset}
                                                buttonStyle={{ backgroundColor: "white", border: "white" }}
                                                bodyText={
                                                    <DfCarsImageUpload
                                                        EditData={this.state.EditData}
                                                        these={this.changeModelstate}
                                                        FullData={this.state.Data}
                                                        Index={this.state.Index}
                                                    />}
                                            />
                                        </div>
                                    </div>
                                    {this.state.leadsavailable.length ? (
                                        <Datatable
                                            data={this.state.leadsavailable}
                                            columnHeading={this.state.column} />
                                    ) :
                                        (<label >NO DATA FOUND</label>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </React.Fragment>
        )
    }
}
export default MyInventory1;