﻿import http from "./httpMiddleWare";
import { ACCESS_POINT, REDIS_ACCESS_POINT } from "../config";

const singleTable = async (tableName, customerid, column) => {
  const result = await http.get(ACCESS_POINT + `/redis/singleTable/${tableName}/${column}/${customerid}`);
  return result;
};
const table=async(tableName)=>
{
  const result = await http.get(REDIS_ACCESS_POINT + `/crm/get/singleTable/${tableName}`);
  return result; 
}
const withOutCondition = async (t) => {
  const result = await http.get(ACCESS_POINT + `/redis/withOutCondition/${t}`);
  return result;
};
const Dashboard = async (process, usertype, location) => {
  const result = await http.get(ACCESS_POINT + `/redis/DashboardFunction/${process}/${usertype}/${location}`)
  return result;
}
export default {
  singleTable,
  withOutCondition,
  Dashboard,
  table
};
