import React, { Component } from 'react'
import CmsContent from '../../../../MiddleWare/CmsContent';
import Datatable from "../../../../components/Datatable/Datatable";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import cmsContent from "../../../../MiddleWare/CmsContent";

class DfcarzCustomerHistory extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            page: 1,
            themeId: null,
            changenumber: null,
            fullData: [],
            MakeOptions: [],
            coloropt: [],
            variantopt: [],
            MakeOptions: [],
            coloropt: [],
            variantopt: [],
            modelopt: [],
            index: null,
            alertVisible: false,
            customerId: localStorage.getItem("userId"),
            usertypelog: JSON.parse(localStorage.getItem('usertypelog')),
            Process: [
                { label: "Enquiry Process", value: 1, tbl: "tbl_dfcarz_enquiryform", type: 'seller' },
                { label: "Evaluation Process", value: 2, tbl: "tbl_FProof", tbl1: "tbl_BProof", type: 'seller' },
                { label: "Evaluation Process", value: 2, tbl: "tbl_BProof", type: 'seller' },
                { label: "Documentation Process", value: 3, tbl: "tbl_AT_Booklet_Purchase_Contract_Proof", type: 'seller' },
                { label: "Payment Process", value: 4, tbl: "tbl_Inventory_Sheet_Reproof", type: 'seller' },
                { label: "Physical Process", value: 5, tbl: "tbl_Delivery_Seller_Proof", type: 'seller' },
                { label: "Autoterrace", value: 6, tbl: "tbl_dfcarz_enquiryform", type: "buyer" },
                { label: "Contracted Process", value: 7, tbl: "tbl_Booking_Confirmation_Reproof", type: "buyer" },
                { label: "Delivery Process", value: 8, tbl: "tbl_Delivery_Note_Buyer_Reproof", type: "buyer" },
                { label: "RC Process", value: 9, tbl: "tbl_Delivery_RC", type: "buyer" },
            ],
            column1: [
                {
                    Header: "Customer Name",
                    accessor: "customerName"
                },
                {
                    Header: "Form",
                    accessor: "tablename",
                    Cell: (d) => this.getValueFromArray(d.original.tablename, this.state.FormOpt, d.original.tablename == 'tbl_BProof' ? 'tbl1' : "tbl")
                },
                {
                    Header: "Created At",
                    accessor: "createdAt",
                },
                // {
                //     Header: "View Details",
                //     Cell: d => d.original.data.length ? this.dataTableButton('primary btn-warning', 'View Changes', () => this.ViewOne(d.original, 3, 'FormChangeData')) : '-',

                // }

            ],
            column: [
                {
                    Header: "Customer Name",
                    accessor: "customerName"
                },
                {
                    Header: "User Name",
                    accessor: "userName"
                },
                {
                    Header: "User Id",
                    accessor: "userid"
                },
                {
                    Header: "Register Number",
                    accessor: "reg_no",
                    Cell: d => d.original.reg_no ? d.original.reg_no : '-'
                },
                {
                    Header: "Mobile",
                    accessor: "mobile",
                },
                {
                    Header: "Make",
                    accessor: "make",
                    Cell: d => this.getValueFromArray(d.original.make, this.state.makopt)
                },
                {
                    Header: "Model",
                    accessor: "model",
                    Cell: d => this.getValueFromArray(d.original.model, this.state.modopt)
                },
                {
                    Header: "Type",
                    accessor: "EnqType",
                },
                {
                    Header: "Current Stage",
                    // accessor: "",
                    Cell: d => this.getValueFromArray(d.original?.data[0]?.tablename, this.state.FormOpt, d.original.tablename == 'tbl_BProof' ? 'tbl1' : "tbl")
                },
                {
                    Header: "View Details",
                    accessor: "process_type",
                    Cell: d => this.dataTableButton('primary btn-sm', 'View Details', () => this.ViewOne(d.original, 2, 'formGroupData')),
                }

            ],
            column2: [
                {
                    Header: "Customer Name",
                    accessor: "customerName"
                },
                {
                    Header: "Fieldname",
                    accessor: "fieldname",
                    Cell: d => d.original.fieldname ? d.original.fieldname : '-'
                },
                {
                    Header: "Before",
                    accessor: "beforeupdate",
                    Cell: d => d.original.beforeupdate ? d.original.beforeupdate : d.original.process_type == 'update' ? 'No change' : '-'
                },
                {
                    Header: "After",
                    accessor: "afterupdate",
                    Cell: d => d.original.afterupdate ? d.original.afterupdate : d.original.process_type == 'update' ? 'No change' : '-'
                },
                {
                    Header: "Updated By",
                    accessor: "userName",
                },
                {
                    Header: "Process Type",
                    accessor: "process_type",
                },

            ],
            columnOne: [
                {
                    Header: "Customer Name",
                    accessor: "customerName"
                },
                {
                    Header: "User Name",
                    accessor: "userName"
                },
                {
                    Header: "User Id",
                    accessor: "userid"
                },
                {
                    Header: "Register Number",
                    accessor: "reg_no",
                    Cell: d => d.original.reg_no ? d.original.reg_no : '-'
                },
                {
                    Header: "Mobile",
                    accessor: "mobile",
                },
                {
                    Header: "Make",
                    accessor: "make",
                    Cell: d => this.getValueFromArray(d.original.make, this.state.makopt)
                },
                {
                    Header: "Model",
                    accessor: "model",
                    Cell: d => this.getValueFromArray(d.original.model, this.state.modopt)
                },
                {
                    Header: "Type",
                    accessor: "EnqType",
                }

            ],

        };
    }
    async componentDidMount() {
        let allOpt = await this.decryption('getcar')
        if (allOpt) {
            await this.setState({
                makopt: allOpt.makeopt,
                coloropt: allOpt.coloropt,
                varopt: allOpt.variantopt,
                modopt: allOpt.modelopt,
                locopt: allOpt.locopt,
                yearopn: allOpt.yearopt
            })
        }
        await this.MainFun()
    }
    MainFun = async () => {
        try {
            this.setState({ loading: true })
            // const params = new URLSearchParams(window.location.search)
            const params = this.props.location.state.suggest
            const paramsOne = this.props.location.state.suggestOne
            const params1 = this.props.location.state.suggest1
            const paramsOne1= this.props.location.state.suggestOne1
            let body = {}
            body.orderBy = this.state.usertypelog.orderBy
            // if (params && params.get('userid')) {
            if (params && params.length) {
                body.prosid = await params.map((val) => val.prosid).join(',')
                body.type = this.props.location.state.type
                let result = await cmsContent.BUYDFCARZ(this.state.customerId, 'Viewhistory_prospective', body)
                if (result) {
                    console.log(result, "result");
                    let FormOpt = []
                    if (result.process && result.process.length) {
                        FormOpt = [...result.process]
                    }

                    await this.setState({ fullData: result.history, FormOpt })
                }
            } else if (paramsOne && paramsOne.length) {
                console.log(paramsOne, 'paramsOne');
                await this.setState({ fullData: paramsOne, FormOpt: this.state.Process })
            }
            else if (params1 && params1.length) {
                body.prosid = await params1.map((val) => val.prosid).join(',')
                body.type = this.props.location.state.type
                let result = await cmsContent.BUYDFCARZ(this.state.customerId, 'Viewhistory_prospective', body)
                if (result) {
                    // console.log(result, "result");
                    let FormOpt = []
                    if (result.process && result.process.length) {
                        FormOpt = [...result.process]
                    }

                    await this.setState({ fullData: result.history, FormOpt })
                }
            } else if (paramsOne1 && paramsOne1.length) {
                // console.log(paramsOne1, 'paramsOne1');
                await this.setState({ fullData: paramsOne1, FormOpt: this.state.Process })
            }
            this.setState({ loading: false })
        } catch (err) {
            console.log(err);
            this.setState({ loading: false })
        }
    }

    ViewOne = async (val, page, field) => {
        if (val && val.data && val.data.length) {
            this.setState({
                [field]: val.data,
                page: page,
                ProcessTitle: page == 3 ? this.getValueFromArray(val.tablename, this.state.FormOpt, 'tbl') : null
            })
        } else alert('No Data Found')
    }
    OnBack = val => {
        this.setState({ page: val - 1 })
    }
    render() {
        const { fullData, formGroupData, FormChangeData, ProcessTitle, page, } = this.state;
        var tableData = null
        var column = null
        if (page === 1) {
            tableData = fullData;
            column = this.props.location.state.suggestOne || this.props.location.state.suggestOne1 ? this.state.columnOne : this.state.column;
        } else if (page === 2) {
            tableData = formGroupData;
            column = this.state.column1;
        } else if (page === 3) {
            tableData = FormChangeData;
            column = this.state.column2;
        }
        console.log(this.props, "props")
        return (
            <main className="main my-4">
                <main className="container-fluid">

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h1>History</h1>
                                </div>
                                <div className="row form-group">
                                    <div className="col-sm-1">
                                        <button className='btn btn-sm btn-primary' style={{ marginTop: "10px", marginLeft: "10px" }} onClick={() => {
                                            this.props.history.goBack()
                                        }}> Back </button>
                                    </div>
                                    <div className="col-sm-2">

                                    </div>
                                    <div className="col-sm-9"></div>
                                </div>
                                <div className="card-body">
                                    <div className="row form-group ml-0 mb-0">
                                        <div className="col-sm-1 p-2" >
                                            {page !== 1 && this.dataTableButton('danger', 'Back', () => {
                                                this.OnBack(page);
                                            })}
                                        </div>
                                        <div className="col-sm-11 h2"><center>{page == 3 && `${ProcessTitle}`}</center></div>
                                    </div>
                                    {this.state.loading ? <div className="text-center"> <i
                                        className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                                    ></i></div> : tableData && tableData.length ? (
                                        <Datatable data={tableData} columnHeading={column} />
                                    ) : <div className="text-center">No data Found</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </main>
        );
    }
}

export default DfcarzCustomerHistory;