import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import Datatable from "../../../../components/Datatable/Datatable";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import { confirmAlert } from "react-confirm-alert";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import DfMODELPDF from "../../../../components/PDF/ModelPDF";
import redisMiddleware from "../../../../MiddleWare/redisMiddleware";
import SingleSelect from "../../../../components/Form/SingleSelect";
import LoginModal from "../../../../components/Modal/Modal";
import CmsContent from "../../../../MiddleWare/CmsContent";
import swal from "sweetalert";
import { Link } from "react-router-dom";

class CarsmodelMaster extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      customerId: localStorage.getItem("userId"),
      ServiceId: localStorage.getItem("currentService"),
      alertVisible: false,
      color: "",
      textalert: "",
      ColourName: "",
      ExistedDataVisible: false,
      errorColourName: "",
      csv: [],
      samplecsv: 0,
      isEdit: false,
      disableValue: false,
      ModelYearOption: []
    };
  }

  column = [
    {
      Header: "Model",
      accessor: "Model",
    },
    {
      Header: "Make",
      accessor: "Make",
    },
    {
      Header: "Start Year",
      accessor: "syear",
      // Cell: (d) => this.year(d.original,'start'),
    },
    {
      Header: "End year",
      accessor: "eyear",
      // Cell: (d) => this.year(d.original,'end'),
    },
    {
      Header: "Edit",
      accessor: "edit",
      Cell: (d) => this.edit(d),
    },

    // {
    //   Header: <b>Delete</b>,
    //   selector: "delete",
    //   Cell: (d) =>
    //     this.DataTableButton(d.original, "Delete", () => {
    //       this.buttonDeletes(d);
    //     }),
    // },
  ];
  async componentDidMount() {
    await this.main()
  }
  main = async () => {
    try {
      this.setState({ loading: true })
      let makeoptions = [];
      //Model Master
      // const model = await redisMiddleware.singleTable(
      //   "tbl_dfcarsmodel",
      //   this.state.ServiceId,
      //   "ServiceId"
      // );
      const model = await CmsContent.getFreedom(
        "*",
        "tbl_dfcarsmodel",
        `ServiceId = ${this.state.ServiceId}`,
        1,
        "id DESC"
      )
      // let data = [];

      //Make master
      const make = await cmsContent.getFreedom(
        "*",
        "tbl_make_master",
        `ServiceId = ${this.state.ServiceId}`,
        1,
        "id DESC"
      );
      console.log(make);
      if (make) {
        make.data.map((ival, i) => {
          // model.data.map((jval, j) => {
          //   if (jval.makeid === ival.id) {
          //     jval.makeid = ival.Make;
          //   }
          // });
          // make.data.map((jval, j) => {
          //   model.data.map((val, i) => {
          //     if (jval.id == val.makeid) {
          //       val.makeid = jval.Make;
          //     }
          //   });
          // });

          makeoptions.push({ label: ival.Make, value: ival.id });
        });
      }
      if (makeoptions) {
        this.setState({ makeoptions });
      }
      if (model) {
        make.data.map((jval, j) => {
          model.data.map((val, i) => {
            if (jval.id == val.makeid) {
              val.Make = jval.Make;
            }
          });
        });

        this.setState({ data: model.data });
      }
      let date = new Date();
      var year = date.getFullYear();
      let mini_year = 1990;
      var difference = year - mini_year;
      let ModelYearOption = [];
      for (var i = 0; i <= difference; i++) {
        ModelYearOption.push({
          label: mini_year + i,
          value: i
        });
      }

      this.setState({ ModelYearOption });
      // let makelabel = [];
      // if (make) {
      //   make.data.map((ival, i) => {
      //     model.data.map((jval, j) => {
      //       if (ival.id == jval.makeid) {
      //         jval.makeid = ival.Make;
      //       }
      //     });
      //   });
      //   // this.setState({ makelabel: make.data });

      if (model.data.length > 0) {
        const header = ["SI.NO", "Model", "Make"];
        const excelHead = [
          { label: "Model", key: "Model" },
          { label: "Make", key: "makeid" },
        ];
        this.setState({
          excelHead,
          header,
          excel: model.data,
          title: "Model",
          loading: false
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  year = (d, col) => {
    if (col == 'start') {
      if (d.syear) {
        return d.syear
      } else {
        return null
      }
    } else {
      if (d.eyear) {
        return d.eyear
      } else {
        return null
      }
    }
  }
  DataTableButton = (info, con, func) => {
    let classes = 'btn btn-';
    // classes += info;
    if (info.status == "active") {
      classes += "danger";
      return (
        <center>
          <button type="button" className={classes} onClick={func}>
            {con}
          </button>
        </center>
      );
    } else {
      classes += "info";
      return (
        <center>
          <button type="button" className={classes}>
            Deleted
          </button>
        </center>
      );
    }
  };
  buttonDeletes = (value) => {
    console.log(value);
    confirmAlert({
      title: "Confirmation to Delete",
      message: "Are you sure you want to delete this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.buttonDelete(value),
        },
        {
          label: "No",
        },
      ],
    });
  };

  buttonDelete = async (value) => {
    const previousData = [...this.state.data];
    let id = value.original.id;
    let Index = value.index;
    try {
      const result = await cmsContent.getSingleConditionedValue(
        "tbl_dfcarsmodel",
        "id",
        id,
        "Delete"
      );
      // let subgroupArray = {};
      // subgroupArray.status = "inactive";

      // const result = await cmsContent.updateMaster(
      //   "tbl_dfcarsmodel",
      //   id,
      //   subgroupArray
      // )
      if (result) {
        // let datas = previousData.filter((delelteid, i) => {
        //   if (delelteid.id !== value.original.id) {
        //     return delelteid;
        //   }
        // });
        let previousdata = [...this.state.data];
        previousdata[Index].status = "inactive";
        this.setState({
          data: previousdata,
          alertVisible: true,
          textalert: "Selected Model Deleted",
          color: "danger",
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  edit = (d) => {
    let value = d;
    return (
      <center>
        <button
          type="button"
          className="btn btn-info"
          onClick={() => this.edition(value)}
        >
          Edit
        </button>
      </center>
    );
  };

  handlechangeData = async (e) => {
    let value = e.target.value.toUpperCase();
    this.setState({ [e.target.name]: e.target.value.toUpperCase(), value });
    let result = this.state.data.filter((a) => a.Model == e.target.value.toUpperCase())
    if (result.length > 0) {
      console.log(result[0]);
      let value = {}
      value.original = result[0]
      value.index =  this.state.data.findIndex(i => i.id == result[0].id);
      this.edition(value)
    }
  };

  submitCategory = async () => {
    const {
      ColourName,
      customerId,
      data,
      value,
      ServiceId,
      selectedmake,
      Syear,
      Eyear,
    } = this.state;
    if (!ColourName) {
      this.setState({ errorColourName: "Enter Model Name" });
      return false;
    } else if (!selectedmake) {
      this.setState({ errorselect: "Select Make" });
      return false;
    } else if (!Syear) {
      this.setState({ errorYear: "Select Start Year" });
      return false;
    } else if (!Eyear) {
      this.setState({ errorYear: "Select End Year" });
      return false;
    }
    let filtered_item =
      data && data.length > 0
        ? data.filter((i) => {
          return i.Model === value;
        })
        : [];

    if (filtered_item.length > 0) {
      this.setState({ error: "Model not be repeated" });
      return false;
    }
    let groupArray = {};
    groupArray.Model = ColourName.trim();
    groupArray.status = "active";
    groupArray.customerId = customerId;
    groupArray.ServiceId = ServiceId;
    groupArray.makeid = selectedmake.value;
    groupArray.syear = Syear.label;
    groupArray.eyear = Eyear.label;
    try {
      this.setState({ disableValue: true });
      const result = await cmsContent.addMaster("tbl_dfcarsmodel", groupArray);
      if (result) {
        // let valueArray = {};
        // valueArray.id = result.data.insertId;
        // valueArray.Model = ColourName;
        // valueArray.status = "active";
        // valueArray.customerId = customerId;
        // valueArray.ServiceId = ServiceId;
        // valueArray.makeid = selectedmake.value;
        // valueArray.syear = Syear.label;
        // valueArray.eyear = Eyear.label;
        // const newData = [valueArray, ...this.state.data];
        this.setState({
          // data: newData,
          alertVisible: true,
          disableValue: false,
          textalert: "New Model Added",
          color: "success",
          errorColourName: "",
          error: "",
          ColourName: "",
          selectedmake: "",
          Syear: "",
          Eyear: ""
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  onDismiss = () => {
    this.setState({ alertVisible: false, formAlertdelete: false });
  };

  edition = (d) => {
    console.log(d.original, d.index);
    let Index = d.index;
    let value = d.original;
    let editid = value.id;
    let ColourName = value.Model;
    let selectedmake = { value: value.makeid, label: this.getValueFromArray(value.makeid, this.state.makeoptions) }
    let Syear = value.syear ? { label: value.syear, value: this.getValueFromArray(value.syear, this.state.ModelYearOption, 'label', 'value') } : null
    let Eyear = value.eyear ? { label: value.eyear, value: this.getValueFromArray(value.eyear, this.state.ModelYearOption, 'label', 'value') } : null
    // console.log(Syear);
    this.setState({
      editid,
      Index,
      ColourName,
      selectedmake,
      Syear,
      Eyear,
      isEdit: true,
    });
  };

  UpdateCategory = async () => {
    const { editid, ColourName, Index, selectedmake, Syear, Eyear } = this.state;
    if (!ColourName) {
      this.setState({ errorColourName: "Enter Model Name" });
      return false;
    }
    let subgroupArray = {};
    subgroupArray.Model = ColourName;
    subgroupArray.status = "active";
    subgroupArray.makeid = selectedmake.value;
    subgroupArray.syear = Syear.label;
    subgroupArray.eyear = Eyear.label;
    try {
      const result = await cmsContent.updateMaster(
        "tbl_dfcarsmodel",
        editid,
        subgroupArray
      );
      if (result) {
        let previousdata = [...this.state.data];
        previousdata[Index].Model = ColourName;
        previousdata[Index].makeid = selectedmake.value;
        previousdata[Index].syear = Syear.label;
        previousdata[Index].eyear = Eyear.label;
        setTimeout(() => this.setState({ data: previousdata }), 500);
        this.setState({
          alertVisible: true,
          disableValue: false,
          editid: null,
          ColourName: "",
          selectedmake: "",
          Syear: "",
          Eyear: "",
          errorColourName: "",
          error: "",
          Index: null,
          textalert: "Model Updated",
          color: "success",
          isEdit: false,
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  handlemake = async (e, name) => {
    console.log(e);
    this.setState({ [name]: e });
  };
  CSVbuttonChange = async () => {
    await this.setState(state => ({ isToggleOn: !state.isToggleOn }));
    if (this.state.isToggleOn === true) {
      await this.setState({ samplecsv: 1 })
    } else (
      await this.setState({ samplecsv: 0 })
    )
  };
  fileupload = e => {
    this.setState({ csv: e.target.files[0] })
  }
  submitcsv = async () => {
    let { customerId, ServiceId } = this.state
    const files = this.state.csv;
    console.log(files, "files");
    if (!files) {
      this.setState({ fileerror: "Please Select CSV File" });
      return false;
    }
    const formData = new FormData();
    formData.append("file", files);
    formData.append("ServiceId", ServiceId ? ServiceId : null);
    formData.append("customerId", customerId ? customerId : null);
    console.log([...formData])
    try {
      const result = await cmsContent.DFCARSMODELCSV("tbl_dfcarsmodel", formData);
      console.log(result, "result");
      if (result && result.data.color == "success") {
        swal({
          title: `${result.data.content1 ? result.data.content1 : result.data.content}`,
          icon: `success`,
          button: {
            text: "Ok",
            closeModal: false,
          },
        }).then(async (willDelete) => {
          await this.main()
          swal.close()
        });
      }
      else if (result && result.data.color1 == "danger" && result.data.ExistedData.length) {
        await this.setState({ ExistedData: result.data.ExistedData, ExistedDataLabel: result.data.content1 })
        swal({
          title: `${result.data.content1 ? result.data.content1 : result.data.content}`,
          icon: `warning`,
          button: {
            text: "Ok",
            closeModal: false,
          },
        }).then(async (willDelete) => {
          swal.close();
          this.ExistedDataAllignment()
        });
      }
      else {
        swal({
          title: `${result.data.content1 ? result.data.content1 : result.data.content}`,
          icon: `warning`,
          button: {
            text: "Ok",
            closeModal: false,
          },
        }).then(async (willDelete) => {
          window.location.reload();
        });
      }
    }
    catch (error) {
      console.log(error)
    }
  };
  ExistedDataAllignment = async () => {
    let { ExistedData, ExistedDataLabel } = this.state
    console.log(ExistedData, "ExistedData");
    let ExistedDataContent = []
    if (ExistedData) {
      ExistedData.map((ival, i) => {
        ExistedDataContent.push(
          <tr>
            <th scope="row">{ival.make}</th>
            <td>{ival.model}</td>
            <td>{ival.syear}</td>
            <td>{ival.eyear}</td>
            <td>{ival.issue ? ival.issue : ExistedDataLabel}</td>
          </tr>
        )
      })
    }
    await this.setState({ ExistedDataContent, ExistedDataVisible: true })
  }
  reset = async () => {
    await this.setState({ ExistedDataVisible: false, ExistedDataContent: [], ExistedDataLabel: "" })
  }
  render() {
    const {  excelHead, header,excel,title,alertVisible, textalert, badgeColor, badgeText, fileerror, ExistedDataVisible, ExistedDataContent, ExistedDataLabel } = this.state;
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Model Master</h1>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-7">
                        <Alert
                          className="badge-content"
                          color={this.state.color}
                          isOpen={alertVisible}
                          toggle={this.onDismiss}
                        >
                          {textalert}
                        </Alert>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="exampleInputEmail1">Select Make</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          placeholder="Select Make"
                          options={this.state.makeoptions}
                          onChange={(e) => this.handlemake(e, 'selectedmake')}
                          selectedService={this.state.selectedmake}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">
                        {this.state.errorselect}
                      </span>
                      <span className="error-show ">{this.state.error}</span>
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="exampleInputEmail1">Model</label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type="text"
                          className="form-control"
                          id="ColourName"
                          name="ColourName"
                          placeholder="Enter Model"
                          value={this.state.ColourName}
                          onChange={this.handlechangeData}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">
                        {this.state.errorColourName}
                      </span>
                      <span className="error-show ">{this.state.error}</span>
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="exampleInputEmail1">Year</label>
                      </div>
                      <div className="col-sm-3">
                        <SingleSelect
                          placeholder="Select Start Year"
                          options={this.state.ModelYearOption}
                          onChange={(e) => this.handlemake(e, 'Syear')}
                          selectedService={this.state.Syear}
                        />
                      </div>
                      <div className="col-sm-2">
                        <SingleSelect
                          placeholder="End Year"
                          options={this.state.Syear ? this.state.ModelYearOption.filter(e => e.label >= this.state.Syear.label) : this.state.ModelYearOption}
                          onChange={(e) => this.handlemake(e, 'Eyear')}
                          selectedService={this.state.Eyear}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">
                        {this.state.errorEYear}
                      </span>
                      <span className="error-show ">{this.state.error}</span>
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-3" />
                      <div className="col-sm-5">
                        <button
                          type="button"
                          className={`btn btn-${this.state.isEdit ? "secondary" : "primary"
                            }`}
                          disabled={this.state.disableValue}
                          onClick={
                            this.state.isEdit
                              ? this.UpdateCategory
                              : this.submitCategory
                          }
                        >
                          {this.state.isEdit ? "Update Model" : "Add Model"}
                        </button>
                      </div>
                      <div className="col-sm-2" />
                    </div>
                    <br></br>
                    <div className="row form-group">
                    {this.state.excel && this.state.excel.length > 0  && <Link className="btn btn-warning"
                        to={{ pathname: "/Lead/Pdfload", state: { data: excel, title: title, excelHead: excelHead, side: 0,type:1} }}
                      >Export</Link>}&nbsp;&nbsp;&nbsp;
                    
                      {/* &nbsp;&nbsp;&nbsp;  {this.state.excel && this.state.excel.length > 0 ? (
                        <PDFDownloadLink
                          style={{ color: "white" }}
                          document={
                            <DfMODELPDF
                              title={this.state.title}
                              header={this.state.header}
                              data={this.state.excel}
                            />
                          }
                          fileName="AddBrand.pdf"
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? "Loading document..." : "Download Pdf"
                          }
                          <button className="btn btn-warning">PDF</button>
                        </PDFDownloadLink>
                      ) : null}&nbsp;&nbsp;&nbsp;
                      {this.state.data && this.state.data.length ? (
                        <CSVLink
                          data={this.state.data}
                          headers={this.state.excelHead}
                        >
                          <button
                            className="btn btn-success"
                            style={{ marginLeft: "5px" }}
                          >
                            Excel
                          </button>
                        </CSVLink>
                      ) : null}&nbsp;&nbsp;&nbsp; */}
                      {this.state.data && this.state.data.length ? (
                        <LoginModal
                          buttonTitle="Import Csv"
                          title="Csv file upload"
                          // buttonStyle={{ marginLeft: "-105px" }}
                          extraClass="info"
                          onClick={this.reset}
                          reset={this.reset}
                          id="import"
                          bodyText={
                            <div className="container">
                              {ExistedDataVisible && ExistedDataContent && ExistedDataLabel ?
                                <React.Fragment>
                                  <h3>{ExistedDataLabel} </h3>
                                  <br />
                                  <table class="table table-hover">
                                    <thead>
                                      <tr>
                                        <th scope="col">MAKE</th>
                                        <th scope="col">MODEL</th>
                                        <th scope="col">SYEAR</th>
                                        <th scope="col">EYEAR</th>
                                        <th scope="col">ISSUES</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {ExistedDataContent}
                                    </tbody>
                                  </table >
                                </React.Fragment> :
                                <React.Fragment>
                                  <div className="row form-group">
                                    <div className="col-sm-2" />
                                    <div className="col-sm-2">
                                      <label for="exampleInputEmail1">
                                        <b>CSV Upload</b>
                                      </label>
                                    </div>
                                    <div className="col-sm-5">
                                      <input
                                        type="file"
                                        className="custom-file-input"
                                        id="customFile"
                                        accept='.csv'
                                        // value={this.state.csv}
                                        onChange={this.fileupload}
                                      />
                                      <span className="error-shows">{fileerror}</span>
                                      <br />
                                      <label
                                        className="custom-file-label"
                                        htmlFor="customFile"
                                      >
                                        {this.state.csv ? this.state.csv.name : 'Choose File'}
                                      </label>
                                      <br />
                                      <br />
                                    </div>
                                    <div className="col-sm-3" />
                                  </div>
                                  <div className="row form-group">
                                    <div className="col-sm-1" />
                                    {this.state.samplecsv === 0 && (
                                      <div className="col">
                                        <a href="../../files/SampleModelCSVUpload.csv" download="SampleModelCSVUpload.csv">
                                          download sample.csv
                                        </a>
                                      </div>
                                    )}
                                    {this.state.samplecsv === 1 && (
                                      <div className="col">
                                        <table id="example" className="table table-striped table-bordered" style={{ width: "100%" }}>
                                          <thead>
                                            <tr>
                                              <th>make</th>
                                              <th>model</th>
                                              <th>syear</th>
                                              <th>eyear</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>Honda</td>
                                              <td>JAZZ</td>
                                              <td>2012</td>
                                              <td>2015</td>
                                            </tr>
                                            <tr>
                                              <td>Honda</td>
                                              <td>JAZZ</td>
                                              <td>2016</td>
                                              <td>2020</td>
                                            </tr>
                                            <tr>
                                              <td>Honda</td>
                                              <td>CITY</td>
                                              <td>2021</td>
                                              <td>2022</td>

                                            </tr>

                                          </tbody>
                                        </table>
                                      </div>
                                    )}
                                  </div>

                                  <div className="row ">
                                    <div className="col" />
                                    <div className="col">
                                      <button className="btn btn-warning waves-effect d-flex justify-content-center" onClick={this.CSVbuttonChange}
                                        type="button"
                                      >
                                        {this.state.isToggleOn ? "Download Samples" : "Samples In Table"}
                                      </button>
                                    </div>
                                    <div className="col">
                                      <button
                                        type="button"
                                        class="btn btn-primary waves-effect d-flex justify-content-center"
                                        onClick={(e) => this.submitcsv(e)}
                                      >
                                        Submit
                                      </button>
                                    </div>
                                    <div className="col" />
                                  </div>
                                </React.Fragment>
                              }
                              <br />
                            </div>
                          }
                        />
                      ) : null}
                    </div>
                    <br></br>
                    <div className="row form-group">
                      <div className="col-sm-12">
                        {this.state.loading == true ? <div className="text-center"> <i
                          className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                        ></i></div> :
                          this.state.data.length ? (
                            <Datatable
                              data={this.state.data}
                              columnHeading={this.column}
                            />
                          ) : null
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
export default CarsmodelMaster;
