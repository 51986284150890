import React, { Component } from 'react';
import CmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleware from '../../../../components/Form/FormMiddleware'
import { ACCESS_POINT } from '../../../../config';
import Carousel from 'react-multi-carousel';

class BidShare extends FormMiddleware {
    // socket = socketIOClient.connect(ACCESS_POINT);
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            Fileslist: [],
            usertypelog: JSON.parse(localStorage.getItem("usertypelog")),
            userlog: JSON.parse(localStorage.getItem("userlog")),
            column: [
                {
                    Header: 'Bidamount',
                    accessor: "Bidamount",
                },
                {
                    Header: 'ColourName',
                    accessor: "ColourName",
                },
                {
                    Header: 'ins_validity',
                    accessor: "ins_validity",

                },
                {
                    Header: 'insurance',
                    accessor: "insurance",

                },
                {
                    Header: 'km_travel',
                    accessor: "km_travel",

                },
                {
                    Header: 'makename',
                    accessor: "makename",

                },
                {
                    Header: 'modelname',
                    accessor: "modelname",


                }]
        }
    }
    async componentDidMount() {
        try {
            await this.Mainfunc()
        } catch (error) {
            console.log(error);
        }
    }

    Mainfunc = async () => {
        try {
            const pathname = window.location.pathname

            let path = pathname.split("/")
            let id = path && path[3] ? path[3] : ""
            // console.log(id, "id")

            let result = await CmsContent.BUYDFCARZ(id, 'shareBid')
            console.log(result, "API Result")
            await this.setState({ data: result[0], Fileslist: result[0].Fileslist })

        } catch (error) {
            console.log(error);
        }
    }
    routeChanger = async () => {
        try {
            let { usertypelog } = this.state

            if (!usertypelog) {
                alert("Please login..!")
                window.location.href = "/"
            } else {
                if (usertypelog && usertypelog.orderBy == 1 || usertypelog.orderBy == 2 || usertypelog.orderBy == 10 || usertypelog.orderBy == 3) {
                    window.location.href = "/DFCars/ExternalUserform"
                } else {
                    alert("Auction can only be accessed by POC, Manager and Team Leader.")
                }
            }
        } catch (error) {
            console.log(error);
        }
    }
    render() {
        let { data, Fileslist, usertypelog } = this.state

        const responsive = {
            desktop: {
                breakpoint: { max: 3000, min: 600 },
                items: 1,
                slidesToSlide: 1
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 1,
                slidesToSlide: 1
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
                slidesToSlide: 1
            }
        };

        return (
            <React.Fragment>

                <div className="container">
                    <div className='row'>
                        <div className="col-sm-12">
                            <center>
                                <div className='d-flex justify-content-center'>
                                    <img style={{ height: 100 }}
                                        src="https://exp.difuza.com/superAdmin/file?fileurl=Steer Auto/steerautologo_1600862593264.jpg"
                                    />
                                </div>
                            </center>
                        </div>
                        {/* <div className="col-sm-6">
                            <div className='d-flex justify-content-center'>
                                <img style={{ height: 80 }}
                                    src="https://steerimages.difuza.com/DFCARS_46bfd8d3_8036a00e_1661152554088.png"
                                />
                            </div> */}
                        {/* </div> */}
                    </div>
                    <br></br>
                    <br></br>
                    <div className='row'>
                        <div className="col-sm-12">
                            <div className='d-flex justify-content-center'>
                                <br></br>
                                <br></br>
                                <h1 style={{ color: "black" }}>Auction Share</h1>
                            </div>
                        </div>
                    </div>
                    <br />
                    {
                        data && data.makename && Fileslist ?
                            <div>
                                <center>
                                    <div className='row'>
                                        <div className="col-sm-6">
                                            
                                            <table class="LeadTable">
                                                <thead>
                                                    <tr class="LeadTable-head" style={{ background: "#07278f" }}>
                                                        <th class="column1" style={{textAlign : "center"}}>Information Type</th>
                                                        <th></th>
                                                        <th class="column2">Data</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td class="column1" style={{textAlign : "center"}}> Make </td>
                                                        <td>: </td>
                                                        <td class="column2">{data.makename ? data.makename : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="column1" style={{textAlign : "center"}}> Model Name </td>
                                                        <td>: </td>
                                                        <td class="column2">{data.modelname ? data.modelname : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="column1" style={{textAlign : "center"}}> Variant </td>
                                                        <td>: </td>
                                                        <td class="column2">{data.variant ? data.variant : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="column1" style={{textAlign : "center"}}> Colour </td>
                                                        <td>: </td>
                                                        <td class="column2">{data.ColourName ? data.ColourName : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <th class="column1" style={{textAlign : "center"}}> Register Number </th>
                                                        <th>: </th>
                                                        <td class="column2">{data.reg_no ? data.reg_no : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="column1" style={{textAlign : "center"}}> No. of Owners </td>
                                                        <td>: </td>
                                                        <td class="column2">{data.ownership_no ? data.ownership_no : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="column1" style={{textAlign : "center"}}> Fuel </td>
                                                        <td>: </td>
                                                        <td class="column2">{data.fueloption ? data.fueloption : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="column1" style={{textAlign : "center"}}> Insurance </td>
                                                        <td>: </td>
                                                        <td class="column2">{data.insurance ? data.insurance : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="column1" style={{textAlign : "center"}}> Insurance Expiry </td>
                                                        <td>: </td>
                                                        <td class="column2">{data.ins_validity ? data.ins_validity : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="column1" style={{textAlign : "center"}}> KM Travelled </td>
                                                        <td>: </td>
                                                        <td class="column2">{data.km_travel ? data.km_travel : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="column1" style={{textAlign : "center"}}> Bid Amount </td>
                                                        <td>: </td>
                                                        <td class="column2">{data.Bidamount ? data.Bidamount : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="column1" style={{textAlign : "center"}}> Current Status </td>
                                                        <td>: </td>
                                                        <td class="column2"> {
                                                            data.Auction == "1" ?
                                                                <h3 style={{ color: "green" }}>Auction in Live</h3>
                                                                : data.Auction == "2" ?
                                                                    <h3 style={{ color: "green" }}>{data.auctionowner ? data.auctionowner : ""} won this auction for ₹{data.AuctionPrice ? data.AuctionPrice : ""}.</h3>
                                                                    : data.Auction == "3" ?
                                                                        <h3 style={{ color: "red" }}>Auction Rejected</h3>
                                                                        :
                                                                        data.Auction == "4" ?
                                                                            <h3 style={{ color: "red" }}>Auction Rejected</h3>
                                                                            :
                                                                            "-"
                                                        }</td>
                                                    </tr>
                                                </tbody>


                                            </table>

                                        </div>
                                        <div className='col-sm-6'>

                                            {Fileslist && Fileslist && Fileslist.length > 0 &&
                                                <>
                                                    <h3 class="text-center" style={{ color: "black" }}>
                                                        Car Images
                                                    </h3>
                                                    <br />
                                                    <br />
                                                    <Carousel
                                                        responsive={responsive}
                                                    >
                                                        {Fileslist.map((file => {
                                                            return <div style={{ alignSelf: "center" }}>
                                                                <center>
                                                                    {file &&
                                                                        <img style={{ objectFit: "contain", width: 600, height: 400 }}
                                                                            src={ACCESS_POINT + "/superAdmin/file?fileurl=" + file}

                                                                            onError={({ currentTarget }) => {
                                                                                currentTarget.onerror = null;
                                                                                currentTarget.src = "https://steerimages.difuza.com/DFCARS_daa63c54_4b15d8d6_1662965206825.jpg";
                                                                            }}

                                                                        />}
                                                                </center>
                                                            </div>
                                                        }))}
                                                    </Carousel>
                                                </>
                                            }
                                            <br></br>
                                            {(data.Auction != "3" && data.Auction != "4") ? <button class="btn btn-primary" onClick={() => {
                                                this.routeChanger()
                                            }}> Join Auction </button> : ""}

                                        </div>
                                    </div>
                                </center>
                            </div>
                            :
                            ""
                    }
                    <br />
                    <br />  <br />
                    <br />  <br />
                </div>


            </React.Fragment >
        )
    }
}

export default BidShare;