import React from "react";
import { Alert } from "reactstrap";
import LoginModal from "../../../components/Modal/Modal";
import AddUser from "../../Modal/AddCustomer";
import UserData from "../../../MiddleWare/User";
import Datatable from "../../../components/Datatable/Datatable";
import { FormMiddleWare } from "../../../components/Form";
import CmsContent from "../../../MiddleWare/CmsContent";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Redirect } from "react-router-dom";
import { ACCESS_POINT } from "../../../config";
import MentorsPDF from "../../../components/PDF/MentorsPDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";

class Mentors extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.type,
      mapgroup: {},
      groups: [],
      categorySelected: [],
      userIndex: "",
      userName: "",
      mobileNumber: "",
      email: "",
      errorUserName: "",
      errorMobileNumber: "",
      errorEmail: "",
      alertVisible: false,
      formAlertdelete: false,
      updateData: false,
      updateEmail: false,
      button: "Submit",
      disableValue: false,
      redirect: false,
      data: [],
      uploadimg: [],
      logoname: "",
      textview: null,
      color: "success",
    };
  }

  async componentDidMount() {
    this.userList();
  }

  userList = async () => {
    let userlog = localStorage.getItem("userlog");

    let serviceEnabled = JSON.parse(userlog).serviceEnabled;
    let usercount = JSON.parse(userlog).usercount;

    let customerId = localStorage.getItem("userId");

    let serviceId = await localStorage.getItem("currentService");

    let counting = [];

    serviceEnabled.split(",").map((item, i) => {
      if (serviceId == item) {
        counting.push(usercount.split(",")[i]);
      }
    });

    const userType = 8;
    this.setState({ customerId, serviceId, userType });
    try {
      let result = await CmsContent.getAllSubtitleList("tbl_maptogroup");
      if (result) {
        this.setState({ mapgroup: result.data });
      }
      const mentor = await CmsContent.getTwoConditionedValue(
        "tbl_user_web",
        "customerId",
        customerId,
        "status",
        "active",
        "count(*) as counts"
      );

      if (mentor) {
        this.setState({
          mentors: mentor.data,
          counts: mentor.data[0].counts,
          counting: counting,
        });
      }

      result = await CmsContent.getMasterValues("tbl_group");
      if (result) {
        this.setState({ groups: result.data });
      }

      result = await UserData.customerIdFullLists(
        customerId,
        userType,
        serviceId
      );
      if (result) {
        // this.setState({ data: result.data });
        console.log("hello");
        console.log(result.data);
      }

      if (result.data.length > 0) {
        const header = ["SI.No", "USERNAME", "MOBILE", "EMAIL_ID"];

        const excelHead = [
          { label: "USERNAME", key: "userName" },
          { label: "EMAILID", key: "email" },
          { label: "MOBILE", key: "mobileNumber" },

          { label: "STATUS", key: "status" },
        ];

        this.setState({
          header,
          excelHead,
          data: result.data,
          title: "MENTORS",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * column will be main for the table to dispay the selected value
   * Header : title for the table
   * accessor : key in the josn which was sent to the table
   * Cell: used to create custom button for the filed in the table {
      Header: 'Cohorts',
      accessor: ''
    },
   * {
      Header: 'Cohorts',
      accessor: ''
    },
   * link : Ref this link for more info : https://github.com/tannerlins {
      Header: 'Cohorts',
      accessor: ''
    },ley/react-table/tree/v6
   */
  column = [
    {
      Header: "UserName",
      accessor: "userName",
      //Cell : d => this.getValueFromArray(d.original.userid,this.state.mapgroup)
    },
    {
      Header: "image",
      accessor: "image",
      Cell: (d) => this.viewimage(d, "image"),
    },
    // {
    //   Header: 'Cohorts',
    //   accessor: 'view',
    //   Cell: d => this.view(d, 'group')
    // },

    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "MobileNumber",
      accessor: "mobileNumber",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Visibility",
      accessor: "edit",
      Cell: (d) => this.showVisibility(d),
    },
    {
      Header: "Edit",
      accessor: "edit",
      Cell: (d) => this.edit(d, "customer"),
    },
    {
      Header: "Delete",
      accessor: "delete",
      Cell: (d) =>
        this.dataTableButton("danger", "Delete", () => {
          this.buttonDeletes(d.original);
        }),
    },
  ];

  columns = [
    {
      Header: "Cohorts",
      accessor: "label",
    },
    {
      Header: "Remove",
      Cell: (d) => this.remove(d),
    },
  ];
  wait = (d) => {
    return null;
  };
  showVisibility = (id) => {
    let status = id.original.status == "active" ? "Active" : "Inactive";
    let colour = id.original.status == "active" ? "warning" : "danger";
    return this.dataTableButton(colour, status, () =>
      this.updateVisibility(id)
    );
  };

  updateVisibility = async (value) => {
    let values = value.original;
    const index = value.index;
    const previousData = [...this.state.data];
    const newData = { ...previousData[index] };

    let subCategoryArray = {};
    if (newData.status === "active") {
      newData.status = "Inactive";
      subCategoryArray.status = "Inactive";
      this.setState({ button: "Inactive" });
    } else {
      newData.status = "active";
      subCategoryArray.status = "active";
      this.setState({ button: "Active" });
    }

    const id = newData.id;

    const data = previousData.filter((value) => value.id !== id);
    data.splice(index, 0, newData);

    this.setState({ data });

    let datas = {};
    datas.id = values.id;
    datas.name = values.userName;
    datas.email = values.email;
    datas.mobileNumber = values.mobileNumber;
    datas.status = newData.status;
    datas.customerId = values.customerId;

    try {
      const result = await CmsContent.updateMaster(
        "tbl_user_web",
        newData.id,
        subCategoryArray,
        "id"
      );
      if (result) {
        this.setState({ data: data });
      }
    } catch (error) {
      this.setState({ data: previousData });
      console.log(error);
    }
  };

  view = (value, modalWindowId) => {
    return (
      <center>
        <button
          type="button"
          data-toggle="modal"
          data-target={`#${modalWindowId}`}
          className="btn btn-warning"
          onClick={() => this.buttonView(value)}
        >
          View Cohorts
        </button>
      </center>
    );
  };
  buttonView = (value) => {
    const { mapgroup } = this.state;
    let all = mapgroup
      .map((ival, i) => {
        if (ival.userid == value.original.id) {
          let returnArray = {};
          returnArray.label = this.getValueFromArray(
            ival.groupid,
            this.state.groups
          );
          returnArray.value = ival.groupid;
          returnArray.getid = ival.id;

          return returnArray;
        }
      })
      .filter(function (element) {
        return element !== undefined;
      });

    this.setState({ buttonView: all });
  };

  getValueFromArrays = (d, array) => {
    if (array.length > 0) {
      if (array.length !== 0) {
        let filtered = array.filter(function (item) {
          return item.userid == d;
        });
        let v = filtered[0];
        if (v != undefined) {
          return v.groupid;
        } else {
          return "-";
        }
      }
    } else {
      return "-";
    }
  };

  remove = (value, modalWindowId) => {
    return (
      <center>
        <button
          type="button"
          data-toggle="modal"
          data-target={`#${modalWindowId}`}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          className="btn btn-danger"
          onClick={() => this.buttonRemoves(value)}
        >
          Remove
        </button>
      </center>
    );
  };
  buttonRemoves = (value) => {
    this.setState({});
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.buttonRemove(value),
          //onClick: () => alert("Group removed from")
        },
        {
          label: "No",
          onClick: () => alert("Click No"),
        },
      ],
    });
  };
  buttonRemove = async (value) => {
    let values = value.original;

    let index = value.index;

    let id = values.getid;
    let previousData = [...this.state.mapgroup];

    try {
      let result = await CmsContent.getSingleConditionedValue(
        "tbl_user_web",
        "id",
        id,
        "Delete"
      );
      if (result) {
        const datas = previousData.filter((delelteid) => delelteid.id !== id);
        //this.setState({ buttonView: datas });
        this.userList();
      }
    } catch (error) {
      console.log(error);
    }
  };

  onSubmit = async () => {
    let userArray = {};
    let userName = this.state.userName;
    let mobileNumber = this.state.mobileNumber;
    let email = this.state.email;
    //let status = this.state.status;
    //let serviceId = await localStorage.getItem('currentService');
    let imgupload = this.state.imgupload;
    // userArray.serviceId = serviceId;
    // userArray.userName = userName;
    // userArray.mobileNumber = mobileNumber;
    // userArray.email = email;
    // userArray.status = 'active';
    // userArray.userType = this.state.user === 'Trainer' ? 8 : 6;
    // userArray.customerId = localStorage.getItem('userId');
    if (!email) {
      this.setState({ errorEmail: "Enter emailid" });
    }
    if (mobileNumber && email && userName) {
      const formData = new FormData();

      formData.append("serviceId", this.state.serviceId);
      formData.append("userName", userName);
      formData.append("mobileNumber", mobileNumber);
      formData.append("email", email);
      formData.append("status", "active");
      formData.append("userType", this.state.userType);
      formData.append("customerId", this.state.customerId);
      formData.append("image", imgupload);

      const previousData = [...this.state.data];
      try {
        //const result = await UserData.userInsert(userArray);
        const result = await UserData.adminuserInsert("tbl_user_web", formData);
        if (result) {
          //  this.componentDidMount();
          const userData = {
            id: parseInt(result.data.insertId),
            userName: userName,
            mobileNumber: mobileNumber,
            email: email,
            status: "active",
            image: result.data.image,
          };
          const newData = [...this.state.data, userData];
          this.setState({
            // data: newData,
            alertVisible: true,
            userName: "",
            mobileNumber: "",
            email: "",
            errorUserName: "",
            errorMobileNumber: "",
            errorEmail: "",
            imgupload: "",
            logoname: "",
          });
          this.userList();
          setTimeout(() => this.setState({ alertVisible: false }), 3000);
        }
      } catch (error) {
        this.setState({
          data: previousData,
        });
        console.log(error);
      }
    }
  };
  buttonEdit = (value) => {
    //console.log('1')
    const index = value.index;

    const editid = value.original.id;
    //.log(editid)
    const previousData = [...this.state.data];
    const getData = { ...previousData[index] };

    var logoname = "";
    if (getData.image != "" && getData.image != undefined) {
      logoname = getData.image.split("SDAS/Video/");
    } else {
      logoname = "";
    }
    this.setState({
      userIndex: index,
      editid,
      userName: getData.userName,
      mobileNumber: getData.mobileNumber,
      email: getData.email,
      errorUserName: "",
      errorMobileNumber: "",
      errorEmail: "",
      alertVisible: false,
      updateData: true,
      imgupload: getData.image,
      logoname: logoname,
    });
  };
  onUpdate = async () => {
    // get all the state value and assign the variable
    let userIndex = this.state.userIndex;
    let userName = this.state.userName;
    let mobileNumber = this.state.mobileNumber;
    let email = this.state.email;
    let imgupload = this.state.imgupload;
    /**
     * create a new json array with the update value from the form
     * this array will be passed to the backend for updating the user
     */

    const formData = new FormData();
    formData.append("userName", userName);
    formData.append("mobileNumber", mobileNumber);
    formData.append("email", email);
    formData.append("image", imgupload);
    let id = this.state.editid;

    let userArray = {};
    userArray.userName = userName;
    userArray.mobileNumber = mobileNumber;
    userArray.email = email;
    // get all the user
    const previousData = [...this.state.data];
    // get only the edit user from the list of user

    try {
      const result = await UserData.updateadminuserInsert(id, formData);
      if (result) {
        const getData = { ...previousData[userIndex] };
        const id = getData.id;
        getData.userName = userName;
        getData.mobileNumber = mobileNumber;
        getData.email = email;
        getData.image = result.data.image;
        // get all the user except the edit user
        const data = previousData.filter((value) => value.id !== id);
        data.splice(userIndex, 0, getData); // update the user value in the list at particular index
        this.setState({
          data,
          // alertVisible: true,
          userName: "",
          mobileNumber: "",
          email: "",
          userIndex: "",
          errorUserName: "",
          errorMobileNumber: "",
          errorEmail: "",
          imgupload: "",
          logoname: "",
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      this.setState({
        data: previousData,
      });
      console.log(error);
    }
  };

  buttonDeletes = (value) => {
    this.setState({});
    confirmAlert({
      title: "Confirmation to Delete",
      message: "Are you sure you want to delete this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.buttonDelete(value),
          //onClick: () => alert("Group removed from")
        },
        {
          label: "No",
        },
      ],
    });
  };
  buttonDelete = async (value) => {
    const id = value.id;
    const previousData = [...this.state.data];
    const data = previousData.filter((value) => value.id !== id);
    this.setState({
      data,
      formAlertdelete: true,
      textalert: "Selected Mentor Deleted",
      color: "danger",
    });
    setTimeout(() => this.setState({ formAlertdelete: false }), 3000);
    try {
      let result = await CmsContent.getSingleConditionedValue(
        "tbl_user_web",
        "id",
        id,
        "Delete"
      );
      if (result) {
        const datas = previousData.filter((delelteid) => delelteid.id !== id);
        //this.setState({ data: previousData });
        this.userList();
      }
    } catch (error) {
      console.log(error);
    }
  };
  reset = () => {
    this.setState({
      userIndex: "",
      userName: "",
      mobileNumber: "",
      email: "",
      imgupload: "",
      logoname: "",
      errorUserName: "",
      errorMobileNumber: "",
      errorEmail: "",
      // alertVisible: true,
      updateData: false,
    });
  };
  onDismiss = () => {
    this.setState({ formAlertdelete: false });
  };
  programSelect = (e) => {
    this.setState({ categorySelected: e, groupid: e.value });
  };
  submited = () => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => alert("Click Yes"),
        },
        {
          label: "No",
          onClick: () => alert("Click No"),
        },
      ],
    });
  };

  setRedirect = () => {
    this.setState({
      redirect: true,
    });
  };
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/DF/MappingToGroup" />;
    }
  };

  viewimage = (d) => {
    let link = ACCESS_POINT + "/superAdmin/file?fileurl=" + d.original.image;
    return (
      // <a target="_blank" href={link}>
      //   {d.original.image}
      // </a>
      <center>
        <img
          className="navbar-brand-full"
          src={link}
          width="30"
          height="30"
          alt="Logo"
        />
      </center>
    );
  };
  submitcsv = async (e) => {
    if (!this.state.csv) {
      this.setState({ cserror: "Please Upload Files" });
    } else {
      this.setState({ cserror: " " });

      const file = this.state.csv;

      let customerId = localStorage.getItem("userId");
      let serviceid = localStorage.getItem("currentService");
      let usertype = 8;
      console.log(customerId);
      console.log(serviceid);
      const formData = new FormData();
      formData.append("customerId", customerId);
      formData.append("serviceId", serviceid);
      formData.append("file", file);
      // console.log([...formData]);
      const result = await CmsContent.learnerfile(usertype, formData);
      console.log(result);
      // if (result) {
      //   this.setState({
      //     formAlertdelete: true,
      //     textalert: "File uploaded Successfully",
      //     color: "success",
      //     csv: " ",
      //     resp: result.data,
      //   });
      // }
      if (result.data.msg == "Incorrect File Format") {
        this.setState({
          formAlertdelete: true,
          textalert: "Incorrect File Format",
          color: "danger",
          csv: null,
          resp: result.data,
        });
      }
      if (result.data.alreadymapped.length > 0) {
        this.setState({
          formAlertdelete: true,
          textalert: "UserAlreadyExist",
          color: "danger",
          resp: result.data,
        });
      } else {
        this.setState({
          formAlertdelete: true,
          textalert: "File uploaded Successfully",
          color: "success",
          csv: null,
          resp: result.data,
        });
      }
      setTimeout(() => this.setState({ formAlertdelete: false }), 3000);
    }
  };
  render() {
    const {
      formAlertdelete,
      counting,
      categorySelected,
      updateData,
      textalert,
      resp,
    } = this.state;
    let userType = localStorage.getItem("userType");
    const userData = {
      userName: this.state.userName,
      mobileNumber: this.state.mobileNumber,
      email: this.state.email,
    };

    const errorData = {
      errorUserName: this.state.errorUserName,
      errorMobileNumber: this.state.errorMobileNumber,
      errorEmail: this.state.errorEmail,
    };
    let title = updateData === false ? `Add Mentor` : `Update Mentor`;
    return (
      <main className="main my-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h1>Mentors</h1>
                </div>

                <div className="card-body">
                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-7">
                      <Alert
                        className="badge-content"
                        color={this.state.color}
                        isOpen={formAlertdelete}
                        toggle={this.onDismiss}
                      >
                        {/* Deleted Successfully */}
                        {textalert}
                      </Alert>
                    </div>
                    <div className="col-sm-3" />
                  </div>
                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2"></div>
                    <div className="col-sm-5">
                      <LoginModal
                        title="Group"
                        atagLink={true}
                        id="group"
                        onClick={this.buttonView}
                        bodyText={
                          <div>
                            {this.state.buttonView && (
                              <Datatable
                                data={this.state.buttonView}
                                columnHeading={this.columns}
                              />
                            )}
                          </div>
                        }
                      />
                    </div>
                    <div className="col-sm-3" />
                  </div>

                  <LoginModal
                    buttonTitle="Add Mentor"
                    title={title}
                    id="customer"
                    onClick={this.reset}
                    bodyText={
                      <div>
                        {this.state.counts >= counting ? (
                          <div>
                            You have exhausted your limit to add more mentors
                          </div>
                        ) : (
                            <AddUser
                              userData={userData}
                              errorData={errorData}
                              userType={userType}
                              handleInput={this.handleUserInput}
                              handleSubmit={this.handleSubmit}
                              onDismiss={this.onDismiss}
                              alertVisible={this.state.alertVisible}
                              formAlertdelete={this.state.formAlertdelete}
                              checkMobileNumber={this.checkMobileNumber}
                              checkEmail={this.checkEmail}
                              updateData={updateData}
                              updateEmail={this.state.updateEmail}
                              uploadimg={this.uploadimg}
                              logoname={this.state.logoname}
                            />
                          )}
                      </div>
                    }
                  />
                  <br />

                  {this.state.data && this.state.data.length > 0 && (
                    <PDFDownloadLink
                      style={{ color: "white" }}
                      document={
                        <MentorsPDF
                          title={this.state.title}
                          header={this.state.header}
                          data={this.state.data}
                        />
                      }
                      fileName="sample.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? "Loading document..." : "Download Pdf"
                      }
                      <button className="btn btn-warning">PDF</button>
                    </PDFDownloadLink>
                  )}
                  {this.state.data && this.state.data.length > 0 && (
                    <CSVLink
                      data={this.state.data}
                      headers={this.state.excelHead}
                    >
                      <button
                        className="btn btn-success"
                        style={{ marginLeft: "9px" }}
                      >
                        Excel
                      </button>
                    </CSVLink>
                  )}
                  <br></br>
                  <br></br>
                  {this.state.data && this.state.data.length ? (
                    <LoginModal
                      buttonTitle="Import Csv"
                      title="Csv file upload"
                      id="import"
                      maxwidth="sm"
                      onClick={this.fileupload}
                      bodyText={
                        <div>
                          <Alert
                            className="badge-content"
                            color={this.state.color}
                            isOpen={formAlertdelete}
                            toggle={this.onDismiss}
                          >
                            {textalert}
                          </Alert>
                          {resp && (
                            <div className=" col-sm-4 col">
                              <div>
                                <b>UsersAlreadyExists</b>
                              </div>
                              {resp.alreadymapped.map((ival, n) => (
                                <div>{ival}</div>
                              ))}
                            </div>
                          )}
                          <label>Upload file:</label>
                          <input
                            type="file"
                            className="form-control"
                            accept=".csv"
                            onChange={(e) =>
                              this.setState({ csv: e.target.files[0] })
                            }
                          />
                          <span className="error-shows">
                            {this.state.cserror}
                          </span>
                          <br />
                          <div className="col-md-5">
                            <a href="/public/assets/files/Sample.csv" download="Sample.csv">
                              download sample.csv
                            </a>
                          </div>
                          <button
                            type="button"
                            class="btn btn-primary waves-effect"
                            onClick={() => this.submitcsv()}
                          >
                            Submit
                          </button>
                        </div>
                      }
                    />
                  ) : null}
                  <span style={{ marginLeft: "5px" }}>
                    Total of {Object.keys(this.state.data).length} records
                  </span>
                  <br />
                  <br />

                  {this.state.data && (
                    <Datatable
                      data={this.state.data}
                      columnHeading={this.column}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Mentors;
