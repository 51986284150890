import React, { Component } from "react";
import { FormMiddleWare } from "../../../components/Form";
import "./assets/profiledfcars.css"
import CmsContent from "../../../MiddleWare/CmsContent";
import swal from "sweetalert";
import { ACCESS_POINT } from "../../../config";
var pjson = require('../../../../package.json');


class DfCarsProfile extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            userDetail: JSON.parse(localStorage.getItem("userlog")),
            updateProcess: false, progress: ""
        };
    }
    async componentDidMount() {
        try {
            let userDetail = this.state.userDetail
            if (userDetail && userDetail.userType && userDetail.userType != "undefined") {
                let usertype = await CmsContent.getFreedom(
                    "*", "tbl_UserType", `id = ${userDetail.userType}`, 1, 1
                )
                if (usertype && usertype.data && usertype.data.length) {
                    userDetail.userTypename = usertype.data[0].usertype
                }
            }

            if (userDetail && userDetail.locationid && userDetail.locationid != "undefined") {

                let location = await CmsContent.getFreedom(
                    "*", "tbl_location", `id = ${userDetail.locationid}`, 1, 1
                )

                if (location && location.data && location.data.length) {
                    userDetail.locname = location.data[0].name
                }
            }
            if (userDetail && userDetail.downline && userDetail.downline != "undefined") {

                let useuserdownline = await CmsContent.getFreedom(
                    "*", "tbl_user_web", `id = ${userDetail.downline}`, 1, 1
                )
                if (useuserdownline && useuserdownline.data && useuserdownline.data.length) {
                    userDetail.downlinename = useuserdownline.data[0].userName
                }
            }




            await this.setState({ userDetail })

        } catch (error) {
            console.log(error);
        }
    }
    resetCache1 = async (auto = false) => {
        try {
            this.setState({ progress: "15%", updateProcess: true })
            if (auto == false) {
                this.autoUpdate1(false)
            }

            if (auto == true) {
                this.autoUpdate1(true)
            }
        } catch (error) {
            this.setState({ progress: "0%", updateProcess: false })
            console.log(error);
        }
    }
    autoUpdate1 = async (auto = false) => {
        try {
            this.setState({ progress: "15%" })

            if (auto == false && process.env.NODE_ENV === 'production') {
                this.setState({ progress: "35%" })
                swal({
                    title: "Are You Sure?",
                    text: "You Want to Upgrade This",
                    icon: "warning",
                    buttons: ["Cancel", "Confirm"],
                    dangerMode: true,
                }).then(async (willDelete) => {
                    if (willDelete) {
                        this.setState({ progress: "45%" })
                        const registration = await navigator.serviceWorker.ready;
                        this.setState({ progress: "55%" })
                        if (registration) {
                            try {
                                const newSW = await registration.installing;
                                if (newSW) {
                                    this.setState({ progress: "65%" })
                                    newSW.addEventListener("statechange", event => {
                                        if (newSW.state == "installed") {
                                            this.setState({ progress: "75%" })
                                        }
                                        if (newSW.state == "activated") {
                                            this.setState({ progress: "100%" })
                                            setTimeout(() => {
                                                window.location.reload()
                                            }, 2000);
                                        }
                                    });
                                } else {
                                    // this.setState({ progress: "0%", updateProcess: false })
                                    // window.location.reload()
                                    this.resetCache1(true)
                                }
                            } catch (error) {
                                window.location.reload()
                                console.log(error)
                            }
                        }
                        else {
                            this.resetCache1(true)
                        }
                    } else {
                        this.setState({ progress: "0%", updateProcess: false })
                    }
                });
            }
            if (auto == true) {
                this.setState({ progress: "55%", updateProcess: true })
                const registration = await navigator.serviceWorker.ready;
                try {
                    const newSW = await registration.installing;
                    this.setState({ progress: "65%" })
                    newSW.addEventListener("statechange", event => {
                        if (newSW.state == "installed") {
                            this.setState({ progress: "75%" })
                        }
                        if (newSW.state == "activated") {
                            this.setState({ progress: "100%" })
                            setTimeout(() => {
                                window.location.reload()
                            }, 2000);
                        }
                    });

                } catch (error) {
                    window.location.reload()
                    console.log(error)
                }
            }

        } catch (error) {
            this.setState({ progress: "0%", updateProcess: false })
        }
    }
    render() {
        const { userDetail } = this.state;
        console.log(userDetail);
        return (
            <React.Fragment>
                <main className="main my-4">
                    <div class="page-content page-container" id="page-content">
                        <div class="padding">
                            <div class="row container d-flex justify-content-center">
                                <div class="col-xl-12 col-md-12">
                                    <div class="card user-card-full">
                                        <div class="row m-l-0 m-r-0" style={{ height: "85vh", overflow: "auto" }}>
                                            <div class="col-sm-4 bg-c-lite-green user-profile">
                                                <div class="card-block text-center text-white">
                                                    <div class="m-b-25 carded__border">
                                                        <img id="carded__img"
                                                            src={userDetail && userDetail.image ? `${ACCESS_POINT}/superAdmin/file?fileurl=${userDetail.image}` : "https://foodtruckexp.difuza.com/admin/file?name=admin/unnamed_1683386327259.png"}
                                                            class="img-radius"
                                                            alt="User-Profile-Image" />
                                                    </div>
                                                    <h3 class="f-w-600" style={{ marginTop: "10px" }}>{userDetail.userName ? userDetail.userName : ""}</h3>
                                                    {/* <i class=" mdi mdi-square-edit-outline feather icon-edit m-t-10 f-16"></i> */}
                                                </div>
                                            </div>
                                            <div class="col-sm-8">
                                                <div class="card-block">
                                                    <h6 class="m-b-20 p-b-5 b-b-default f-w-600">User Details</h6>
                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <p class="m-b-10 f-w-600">Email</p>
                                                            <h6 class="text-muted f-w-400">{userDetail.email ? userDetail.email : "-"}</h6>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <p class="m-b-10 f-w-600">Phone</p>
                                                            <h6 class="text-muted f-w-400">{userDetail.mobileNumber ? userDetail.mobileNumber : "-"}</h6>
                                                        </div>
                                                    </div>
                                                    <h6 class="m-b-20 m-t-40 p-b-5 b-b-default f-w-600">Employee Details</h6>
                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <p class="m-b-10 f-w-600">Designation</p>
                                                            <h6 class="text-muted f-w-400">{userDetail.userTypename ? userDetail.userTypename : "-"}</h6>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <p class="m-b-10 f-w-600">Joined Date</p>
                                                            <h6 class="text-muted f-w-400">{userDetail.createdAt ? userDetail.createdAt : "-"}</h6>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <p class="m-b-10 f-w-600">Location </p>
                                                            <h6 class="text-muted f-w-400">{userDetail.locname ? userDetail.locname : "-"}</h6>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <p class="m-b-10 f-w-600">Report to </p>
                                                            <h6 class="text-muted f-w-400">{userDetail.downlinename ? userDetail.downlinename : "-"}</h6>
                                                        </div>

                                                    </div>
                                                    {this.state.updateProcess ?
                                                        <div id="bot_update_div">
                                                            <div class="progress" style={{ height: "20px" }}>
                                                                <div class="progress-bar progress-bar-striped progress-bar-animated bg-danger" role="progressbar"
                                                                    style={{ width: this.state.progress ? this.state.progress : "25%", fontWeight: "600" }}
                                                                    aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"
                                                                >{this.state.progress ? this.state.progress : "25%"}</div>
                                                            </div>
                                                            <h6 style={{ color: "red", fontSize: "15px", textTransform: "capitalize" }}>Please Do Not Refresh or Close this Tab untill Upgrade process is Completed</h6>
                                                            <br />
                                                            <br />
                                                            <div className="d-flex align-items-center justify-content-end " id="bot_update_div"
                                                            >
                                                                <div className='justify-content-start align-self-center'
                                                                    style={{ color: "#1d096d" }}>
                                                                    <h4> Current App Version: {pjson && pjson.version}.</h4>
                                                                </div>
                                                                &nbsp;&nbsp;&nbsp;
                                                                <h4>Click to Cancel Upgrade Process</h4> &nbsp;&nbsp;&nbsp;
                                                                <button class="btn btn-warning btn-sm" type="button"
                                                                    onClick={() => { this.setState({ updateProcess: false }) }}>
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="d-flex align-items-center justify-content-end " id="bot_update_div"
                                                        >
                                                            <div className='justify-content-start align-self-center'
                                                                style={{ color: "#1d096d" }}>
                                                                <h4> Current App Version: {pjson && pjson.version}.</h4>
                                                            </div>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <h4>Click to Update Latest Version</h4> &nbsp;&nbsp;&nbsp;
                                                            <button class="btn btn-danger btn-sm" type="button"
                                                                onClick={() => { this.resetCache1(false) }}>
                                                                Update
                                                            </button>
                                                        </div>

                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>


            </React.Fragment >
        );
    }
}
export default DfCarsProfile;
