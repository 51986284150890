import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import { FormMiddleWare, MultiSelect } from "../../../../components/Form";
import UpdateStatus from "../SalesMaster/UpdateStatus";
import LoginModal from "../../../../components/Modal/Modal";
import moment from "moment";
import Datatable from "../../../../components/Datatable/Datatable";
import UsertypeComments from "../UserMaster/UsertypeComments";
import Icon from "react-icons-kit";
import { ic_directions_walk } from 'react-icons-kit/md/ic_directions_walk'
// import { calendar_ok } from 'react-icons-kit/ikons/calendar_ok'
// import { calendar } from 'react-icons-kit/ikons/calendar'
import UpdateLeadStatus from "../SalesMaster/UpdateLeadStatus";
import { ACCESS_POINT } from "../../../../config";
import { calendar_ok } from 'react-icons-kit/ikons/calendar_ok'
import { calendar } from 'react-icons-kit/ikons/calendar'
import { users } from 'react-icons-kit/ikons/users'
import { user_ok } from 'react-icons-kit/ikons/user_ok'
import { close } from 'react-icons-kit/ikons/close'
import { shuffle } from "react-icons-kit/ikons/shuffle";
import { time } from 'react-icons-kit/ikons/time'


class TLProspectBuyerList extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      Datas: [],
      leadFTD: [],
      leadMTD: [],
      followupftd: [],
      walkinFTD: [],
      walkinMTD: [],
      alllead: [],
      closed: [],
      Current_Date: new Date(),
      User: JSON.parse(localStorage.getItem("userlog")),
      LocationID: localStorage.getItem(`LocationID`),
      EnquiryData: [],
      alertVisible: false,
      dateVisible: false,
      isEdit: false,
      loading: false,
      dataof: [],
      livedata: [],
      MakeOptions: [],
      coloropt: [],
      coming_data: [],

      imgmdl: [],
      variantopt: [],
      modelopt: [],
      data2: [],
      InventoryData: [],
      dashboard: [{ "live": 0, "follow": 0, "MTD": 0 }],
      dataenq: [],
      dataVisible: false,
      ftd: null,
      column: [
        {
          Header: "Status",
          accessor: "status",
          width: 120,
          Cell: d => this.Status(d),
          className: "text-center"

        },
        {
          Header: "Matched Cars",
          accessor: "",
          minWidth: 130,
          Cell: (d) => this.matchedCarlist(d),
          className: "text-center"
        },
        {
          Header: 'Source',
          accessor: 'leadSource',
          Cell: d => this.sourceButton(d.original)
        },
        {
          Header: "Name",
          accessor: "name"
        },
        {
          Header: "Mobile",
          accessor: "mobile",
          Cell: d => this.Call(d.original.mobile)
        },
        {
          Header: "Contact",
          accessor: "mobile"
        },
        {
          Header: "Next-Contact-Date",
          accessor: "createdAt",
          // accessor: "next_followup",
          // Cell: d => this.nextcontact(d)

        },
        {
          Header: "Make",
          accessor: "makeName",
          // Cell: (d) => this.getValueFromArray(d.original.Make, this.state.MakeOptions)

        },
        {
          Header: "Model",
          accessor: "modelName",
          // Cell: (d) => this.getValueFromArray(d.original.Model, this.state.modelopt)
        },
        {
          Header: "Variant",
          accessor: "variantName",
          // Cell: (d) => this.getValueFromArray(d.original.Variant, this.state.variantopt)

        },
        {
          Header: "Color",
          accessor: "colourName",
          // Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)

        },
        {
          Header: "Sales Consultant",
          minWidth: 180,
          accessor: "Sales_employee_name"
        },

      ],
      excelColumn: [
        {
          label: "Name",
          key: "name"
        },
        {
          label: "Mobile",
          key: "mobile",
        },

        {
          label: "Next-Contact-Date",
          key: "createdAt",
          // key: "next_followup",

        },
        {
          label: "Make",
          key: "makeName",

        },
        {
          label: "Model",
          key: "modelName",
        },
        {
          label: "Variant",
          key: "variantName",
        },
        {
          label: "Color",
          key: "colourName",

        },
        {
          label: "Sales Consultant",
          key: "Sales_employee_name"
        },
      ],
      column1:
        [
          {
            Header: "REG NO",
            accessor: "Reg_no"
          },
          {
            Header: "Make",
            accessor: "make",
            Cell: (d) => this.getValueFromArray(d.original.make, this.state.MakeOptions)
          },
          {
            Header: "Model",
            accessor: "model",
            Cell: (d) => this.getValueFromArray(d.original.model, this.state.modelopt)
          },
          {
            Header: "Variant",
            accessor: "modelname",
            Cell: (d) => this.getValueFromArray(d.original.variant, this.state.variantopt)
          },
          {
            Header: "Color",
            accessor: "colour",
            Cell: (d) => this.getValueFromArray(d.original.colour, this.state.coloropt)
          },
          {
            Header: "KMS",
            accessor: "kms"
          },
          {
            Header: "Images",
            accessor: "file",
            Cell: d => this.viewimage(d.original.filesArray)
          },

          {
            Header: "Price ",
            accessor: "price"
          },
          {
            Header: "Update",
            accessor: "file",
            Cell: d => this.Approval1(d.original),
            minWidth: 180
          },
        ],
      EditData: {},
      IsClose: false,
      CustomerId: null,
      locationOpt: [],
      filteredLoc: "",
      filteredMake: "",
      FilterData: [],

    }
  };
  sourceButton = (d) => {
    if (d.leadSource) {
      return (
        <center>
          <button className={d && d.leadSource == 'Enquiry' ? 'btn btn-success' : 'btn btn-danger'}>
            {d.leadSource}
          </button>
        </center>
      )
    } else {
      return (
        <center>
          <button className='btn btn-success'>
            Enquiry
          </button>
        </center>
      )
    }

  }
  matchedCarlist = (d) => {
    return (
      <button
        type="button"
        // data-target="#modal"
        data-toggle="modal" data-target={"#matchcar"}
        className="btn btn-sm btn-warning"
        onClick={() => this.modelview(d)}
      >
        matchedCarlist
      </button>
    )
  }
  viewimage = d => {

    if (d) {
      let image = JSON.parse(d)
      let imgArray = []
      // console.log(image, 'image');
      if (image) {
        let w = Object.keys(image).map((key, i) => {
          if (image[key] && image[key].length) {
            image[key].map((ival) => {
              if (imgArray.length < 5) {
                imgArray.push(<>
                  <img
                    src={`${ACCESS_POINT}/superAdmin/file?fileurl=${ival}`}
                    onClick={() => window.open(`${ACCESS_POINT}/superAdmin/file?fileurl=${ival}`)}
                    alt="Error" style={{ width: 200, height: 200, marginRight: "10px" }}
                  ></img>
                </>
                )
              }
            })
          }
        })
        if (imgArray.length) {
          return (
            <button className="btn btn-sm btn-primary" onClick={() => this.openImageModal(imgArray)}>View Image</button>
          )
        } else return <center>-</center>
      } else {
        return <center>-</center>
      }

    } else {
      return <center>-</center>
    }
  };
  openImageModal = async (d) => {

    await this.setState({ isImageModalActive: true, imgmdl: d })

  }
  Approval1 = d => {
    let { coming_data } = this.state
    if (this.state.Iscontract) {

      if (this.state.prosid == d.Lead) {

        return <button type="button" class="btn btn-sm btn-success" style={{ minWidth: "150px" }} data-container="body" data-toggle="popover" data-placement="right" data-content="Vehicle already reserved. Please select another vehicle">
          Selected
        </button>

      } else {

        return (
          <button
            type="button"
            className="btn btn-sm btn-danger"
            style={{ minWidth: "150px" }}
          >Contract Filled</button>
        );

      }

    }

    else {

      if (this.state.prosid == d.Lead) {
        return <button type="button" class="btn btn-sm btn-success" style={{ minWidth: "150px" }} data-container="body" data-toggle="popover" data-placement="right" data-content="Vehicle already reserved. Please select another vehicle">
          Selected
        </button>
      }

      else if (d.Lead) {
        return <button type="button" class="btn btn-sm btn-warning" style={{ minWidth: "150px" }} data-container="body" data-toggle="popover" data-placement="right" data-content="Vehicle already reserved. Please select another vehicle">
          Somebody Selected
        </button>

      } else {
        return (
          <button
            type="button"
            className="btn btn-sm btn-primary"
            style={{ minWidth: "150px" }}
            onClick={() => this.alert_Submit(this.Approval_in_tl, d, "You want to take this car")}
          >Select</button>
        );

      }

    }


  }
  Approval_in_tl = async (d, text) => {
    let data = d;
    let body = {};
    body.Make = data.make;
    body.Model = data.model;
    body.color = data.colour;
    body.Variant = data.variant;
    body.Reg_no = data.Reg_no;

    try {
      let results = await cmsContent.updateMaster(
        'tbl_dfcarz_enquiryform',
        this.state.enquiry_id,
        body)
      if (results) {
        let subgroup = {}
        subgroup.make = data.make;
        subgroup.model = data.model;
        subgroup.color = data.colour;
        subgroup.variant = data.variant;
        subgroup.reg_no = data.Reg_no;
        let results1 = await cmsContent.updateMaster(
          'tbl_dfcarz_prosperities',
          this.state.prosid,
          subgroup
        )
        if (results1) {
          if (this.state.prosid) {
            let selected = await cmsContent.getFreedom(
            `tbl_Dfcars_Garage.*`,
              `tbl_Dfcars_Garage`,
              `tbl_Dfcars_Garage.Lead = ${this.state.prosid}`,
              "tbl_Dfcars_Garage.id",
              "tbl_Dfcars_Garage.id DESC"
            )
            if (selected && selected.data && selected.data.length > 0) {
              let grouparray = {}
              grouparray.Lead = null
              let nullResult = await cmsContent.updateMaster(
                'tbl_Dfcars_Garage',
                selected.data[0].id,
                grouparray
              )
              console.log(nullResult,"nullResult");
              console.log(d.id,"d.id");
              if (nullResult && d.id) {
                let Object = {}
                Object.Lead = this.state.prosid
                let selectResult = await cmsContent.updateMaster(
                  'tbl_Dfcars_Garage',
                  d.id,
                  Object
                )
                console.log(selectResult,"selectResult");
                return false
                if (selectResult) {
                  this.setState({
                    enquiry_id: null,
                    prosid: null,
                    Iscontract: false
                  })
                  window.location.reload()
                }
              }
            } else {
              let Object = {}
              Object.Lead = this.state.prosid
              let selectResult = await cmsContent.updateMaster(
                'tbl_Dfcars_Garage',
                d.id,
                Object
              )
              if (selectResult) {
                this.setState({
                  enquiry_id: null,
                  prosid: null,
                  Iscontract: false
                })
                window.location.reload()
              }
            }
          }
        }
      }
    }
    catch (error) {
      console.log(error);
    }

  };
  modelview = async (d, index) => {
    await this.setState({ coming_data: [], Inventory: [] })
    let coming_data = d.original

    let data = this.state.InventoryData;

    let Inventory = await data.filter(x => coming_data.Make == x.make && coming_data.Model == x.model)
    await this.setState({ Inventory, enquiry_id: d.original.id, prosid: d.original.prosid, Iscontract: coming_data.contractid ? true : false })
  }
  getCarlistdata = async () => {
    try {
      let result = await cmsContent.getFreedom(
        `tbl_Dfcars_Garage.*,tbl_make_master.Make as makename,tbl_dfcarsmodel.Model as modelname,tbl_colour.colour as ColourName`,
        `tbl_Dfcars_Garage,tbl_make_master,tbl_dfcarsmodel,tbl_colour`,
        `tbl_Dfcars_Garage.status IS NULL and tbl_Dfcars_Garage.ongoing IS NULL and tbl_Dfcars_Garage.make=tbl_make_master.id and tbl_dfcarsmodel.id = tbl_Dfcars_Garage.model and tbl_colour.id = tbl_Dfcars_Garage.colour`,
        "tbl_Dfcars_Garage.id",
        "tbl_Dfcars_Garage.id DESC"
      );

      if (result) {

        await this.setState({ InventoryData: result.data })
      }
    } catch (error) {
      console.log(error);
    }
  }
  Communication1 = (d) => {
    return (<button type="button" className="btn btn-sm btn-warning"
      data-toggle="modal" data-target={"#ccc"}
      onClick={() => this.Editdata34(d)}
    >View</button>);
  }

  Editdata34 = async (d) => {

    this.setState({ stage: "action", CustomerId: d.original.id })
  }

  followup = (d) => {
    return (<button className="btn btn-sm btn-primary" style={{ cursor: 'pointer' }} data-toggle="modal" data-target={"#status"}
      onClick={() => this.Editdata(d)}
    >Next Plan</button>)
  }
  Status = (d) => {
    if (d.original.filterclosed == true) {
      return (<button className="btn btn-danger btn-sm" style={{ cursor: 'pointer', width: "100px" }}
      // onClick={() => this.Editstatus(d)}
      >Closed</button>)
    }
    else {
      return (<button className="btn btn-sm btn-primary" style={{ cursor: 'pointer', width: "100px" }}
        onClick={() => this.Editstatus(d)}
      >View</button>)
    }
  }
  Editstatus = async (d) => {
    window.$('#status').modal("show");
    if (d.original.Form_Filled == 'yes') {
      this.setState({ isEdit: true })
    } else {
      this.setState({ isEdit: false })
    }
    let coming_data = d.original
    let data = this.state.InventoryData;
    let Inventory = data.filter(x => coming_data.Make == x.make && coming_data.Model == x.model)
    this.setState({ EditData: d.original, CustomerId: d.original.id, Index: d.index, Inventory })
  }
  nextcontact = (d) => {
    let date = d.original.followup
    //console.log("followup", date);
    if (date && date[0].followup) {
      date = date[0].followup.split(" ")
      return (
        <span style={{ textDecoration: null }}
        >
          {date}
        </span>
      )
    }
    else {
      return (
        <span style={{ textDecoration: null }}
        >
          -
        </span>
      )
    }

  }

  Editdata = async (d) => {
    // console.log(111)
    this.setState({ EditData: d.original, CustomerId: d.original.id, IsClose: false, isEdit: true, Index: d.index })
  }

  Approval = (d) => {
    return (<button type="button" className="btn btn-sm btn-danger"
      data-toggle="modal" data-target={"#status"}
      onClick={() => this.F_proofForm3(d)}
    >Close</button>)
  }

  F_proofForm3 = async (d) => {
    this.setState({ EditData: d.original, IsClose: true, Index: d.index });
  }

  NAPstatus = (d) => {

    if (d.original.pendingStatus == 'completed') {
      return (<button type="button" className="btn btn-sm btn-success" style={{ width: "100px" }}
        onClick={() => this.ChangeStatus1(d)}
      >Completed</button>)
    } else {
      return (<button type="button" className="btn btn-sm btn-primary" style={{ width: "100px" }}
        onClick={() => this.ChangeStatus1(d)}
      >Pending</button>)
    }
  }

  ChangeStatus1 = async (d) => {
    try {
      // console.log(d.original.id);

      let result = await cmsContent.getFreedom(
        `tbl_dfcars_comments.*,tbl_UserType.usertype,tbl_user_web.userName as Name,tbl_user_web.id as UserID`,
        `tbl_dfcars_comments,tbl_user_web,tbl_MaptoLocation,tbl_UserType`,
        `tbl_dfcars_comments.customerid = ${d.original.id} and tbl_dfcars_comments.stage='actionplan'and tbl_user_web.id = tbl_dfcars_comments.userid and tbl_user_web.userportid = tbl_MaptoLocation.id and tbl_UserType.id = tbl_MaptoLocation.userTypeId`,
        1,
        `tbl_dfcars_comments.id DESC`,
      );
      if (result.data.length) {


        let CommentData = result.data[0];
        // console.log(CommentData);

        let arr = {};
        if (d.original.pendingStatus == 'pending') {
          arr.status = 'completed';
        } else {
          arr.status = 'pending';
        }

        const result1 = await cmsContent.updateMaster(
          "tbl_dfcars_comments",
          CommentData.id,
          arr
        );
        if (result1) {
          // console.log(result1);

          let newData = [...this.state.Data];
          newData[d.index].pendingStatus = arr.status;
          this.setState({
            Data: newData
          })
        }

      }

    } catch (error) {
      console.log(error);
    }
  }

  UpdateStatus = (d) => {
    if (d.original.current_status == 'hot' || d.original.current_status == null) {
      return (
        <button type="button" className="btn btn-sm btn-success" style={{ width: "75px" }}
          onClick={() => this.ChangeStatus(d)}
        >Hot</button>
      )
    } else {
      return (
        <button type="button" className="btn btn-sm btn-primary" style={{ width: "75px" }}
          onClick={() => this.ChangeStatus(d)}
        >Warm</button>
      )
    }

  };

  ChangeStatus = async (d) => {
    let subgroupArray = {}
    if (d.original.current_status == 'hot' || d.original.current_status == null) {
      subgroupArray.current_status = 'warm';
    } else {
      subgroupArray.current_status = 'hot';
    }

    try {
      let data = d.original;
      let Index = d.index;
      const result = await cmsContent.updateMaster(
        "tbl_dfcarz_prosperities",
        d.original.id,
        subgroupArray
      );
      if (result) {
        let newData = [...this.state.Data];
        newData[Index].current_status = subgroupArray.current_status;
        this.setState({
          Data: newData
        })
      }

    } catch (error) {
      console.log(error);
    }
  }
  async componentDidMount() {
    this.getCarlistdata()

    this.mainFun()
    let allOpt = await this.decryption('getcar')
    if (allOpt) {
      this.setState({
        MakeOptions: allOpt.makeopt,
        coloropt: allOpt.coloropt,
        variantopt: allOpt.variantopt,
        modelopt: allOpt.modelopt,
        locationOpt: allOpt.locopt,
      })
    }
  }
  mainFun = async () => {
    try {
      this.setState({ loading: true })
      const result = await cmsContent.BUYDFCARZ(this.state.User.id, 'Lead')
      console.log(result, "API");
      let fulldata = []
      if (result && result.Lead) {
        fulldata = await result.Lead;
        this.setState({ Data: result.Lead })
      }
      await this.setState({
        leadFTD: fulldata.length ? fulldata.filter(e => e.leadFTD == true) : [],
        leadMTD: fulldata.length ? fulldata.filter(e => e.leadMTD == true) : [],
        walkinFTD: fulldata.length ? fulldata.filter(e => e.walkinFTD == true) : [],
        walkinMTD: fulldata.length ? fulldata.filter(e => e.walkinMTD == true) : [],
        followupftd: fulldata.length ? fulldata.filter(e => e.filterfollowupftd == true) : [],
        closed: fulldata.length ? fulldata.filter(e => e.filterclosed == true) : [],
        walkinDone: fulldata.length ? fulldata.filter(e => e.walkinDone == true) : [],
        alllead: fulldata,
        // Walkin: fulldata.length ? fulldata.filter(e => e.filterwalkin == true) : [],
        // Followup: fulldata.length ? fulldata.filter(e => e.filterfollowup == true) : [],
        // MTD: fulldata.length ? fulldata.filter(e => e.filtermtd == true) : [],
        // dashboard: result.dashboard,
        loading: false
      });
      let dashboard = [{ "FTD": this.state.leadFTD.length, "MTD": this.state.leadMTD.length, "followupftd": this.state.followupftd.length, "walkinftd": this.state.walkinFTD.length, "walkinmtd": this.state.walkinMTD.length, "closed": this.state.closed.length, 'WalkinDone': this.state.walkinDone.length }]
      await this.setState({ dashboard })
    } catch (error) {
      this.setState({ loading: false })
      console.log(error)
    }
  }
  changeModelstate = async (s, v) => {
    this.setState({ [s]: v });
  }
  edash = () => {
    return (<div className="">
      <div className="card">
        {/* < div className="row" > */}
        <div className="card-header">
          <div className="col-sm-6" >
            <h2 className="mt-2 black">Dashboard</h2>
          </div>
        </div>
        <div className="card-body">
          <div className="">
            <div className="row mt-4 text-center">
              <div className="col">
                <div className="dashboardCard1" onClick={() => this.onClk(1)}>
                  <Icon icon={time} style={{ color: '#2F353A' }} size={35} /><br />
                  <h4 className="mt-2 black" >FTD Lead</h4>
                  <div >
                    <h1 className="bold mt-4 mb-2 black" style={{cursor:"pointer"}}><b>{this.state.dashboard[0].FTD ? this.state.dashboard[0].FTD : 0}</b></h1>
                  </div>
                </div>
              </div>
              <div className="col" >
                <div className="dashboardCard1" onClick={() => this.onClk(2)}>
                  <Icon icon={calendar} style={{ color: 'blue' }} size={35} /><br />
                  <h4 className="mt-2 black" >MTD Lead</h4>
                  <div >
                    <h1 className="bold mt-4 mb-2 black" style={{cursor:"pointer"}}><b>{this.state.dashboard[0].MTD ? this.state.dashboard[0].MTD : 0}</b></h1>
                  </div>
                </div>
              </div>
              <div className="col" onClick={() => this.onClk(3)} >
                <div className="dashboardCard1">
                  <Icon icon={calendar_ok} size={35} style={{ color: 'green' }} />
                  <h3 className="mt-2 black" >FTD Follow up  </h3>
                  <div>
                    <h1 className="bold mt-4 mb-2 black" style={{cursor:"pointer"}}><b>{this.state.dashboard[0].followupftd ? this.state.dashboard[0].followupftd : 0}</b></h1>
                  </div>
                </div>
              </div>
              <div className="col" onClick={() => this.onClk(4)} >
                <div className="dashboardCard1">
                  <Icon icon={user_ok} size={35} style={{ color: 'purple' }} />
                  <h3 className="mt-2 black" >FTD Walkin  </h3>
                  <div>
                    <h1 className="bold mt-4 mb-2 black" style={{cursor:"pointer"}}><b>{this.state.dashboard[0].walkinftd ? this.state.dashboard[0].walkinftd : 0}</b></h1>
                  </div>
                </div>
              </div>
              <div className="col" onClick={() => this.onClk(5)} >
                <div className="dashboardCard1">
                  <Icon icon={users} size={35} style={{ color: 'red' }} />
                  <h3 className="mt-2 black" >MTD Walkin  </h3>
                  <div>
                    <h1 className="bold mt-4 mb-2 black" style={{cursor:"pointer"}}><b>{this.state.dashboard[0].walkinmtd ? this.state.dashboard[0].walkinmtd : 0}</b></h1>
                  </div>
                </div>
              </div>
              <div className="col" onClick={() => this.onClk(7)} >
                <div className="dashboardCard1">
                  <Icon icon={users} size={35} style={{ color: 'red' }} />
                  <h3 className="mt-2 black" > Walkin Done  </h3>
                  <div>
                    <h1 className="bold mt-4 mb-2 black" style={{cursor:"pointer"}}><b>{this.state.dashboard[0].WalkinDone ? this.state.dashboard[0].WalkinDone : 0}</b></h1>
                  </div>
                </div>
              </div>
              <div className="col" >
                <div className="dashboardCard1" onClick={() => this.onClk(6)}>
                  <Icon icon={close} style={{ color: 'red' }} size={35} /><br />
                  <h4 className="mt-2 black" >Closed</h4>
                  <div >
                    <h1 className="bold mt-4 mb-2 black" style={{cursor:"pointer"}}><b>{this.state.dashboard[0].closed ? this.state.dashboard[0].closed : 0}</b></h1>
                  </div>
                </div>
              </div>
              <div className="col" >
                <div className="dashboardCard1" onClick={() => this.onClk(9)}>
                  <Icon icon={shuffle} style={{ color: 'rgb(203 44 146)' }} size={35} /><br />
                  <h4 className="mt-2 black" >All Leads</h4>
                  <div >
                    <h1 className="bold mt-4 mb-2 black" style={{cursor:"pointer"}}><b>{this.state.alllead ? this.state.alllead.length : 0}</b></h1>
                  </div>
                </div>
              </div>

              {/* <div className="col" >
                <div className="dashboardCard1" onClick={() => this.onClk(1)} >
                  <Icon icon={ic_directions_walk} style={{ color: 'blue' }} size={35} />
                  <h3 className="mt-2 black" >Scheduled walkin</h3>
                  <div >
                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.dashboard[0].walkin ? this.state.dashboard[0].walkin : 0}</b></h1>
                  </div>
                </div>
              </div>

              <div className="col" onClick={() => this.onClk(3)} >
                <div className="dashboardCard1">
                  <Icon icon={calendar} size={35} style={{ color: 'red' }} />
                  <h3 className="mt-2 black">MTD</h3>
                  <div>
                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.dashboard[0].MTD ? this.state.dashboard[0].MTD : 0}</b></h1>
                  </div>
                </div>
              </div>
              <div className="col" onClick={() => this.onClk(4)} >
                <div className="dashboardCard1">
                  <Icon icon={calendar_ok} size={35} style={{ color: 'green' }} />
                  <h3 className="mt-2 black" > Scheduled followup </h3>
                  <div>
                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.dashboard[0].followup ? this.state.dashboard[0].followup : 0}</b></h1>
                  </div>
                </div>
              </div> */}

            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
  cng = async (e) => {
    let d = e.target.value
    let v4 = 0;
    let data1 = []
    this.state.dataenq.map((ival, i) => {
      if (ival.Next_Contact_date) {
        let date = ival.Next_Contact_date.split(" ");
        // console.log(date[0], d);
        if (date[0] == d) {
          v4 = ++v4;
          data1.push(ival)
        }
      }
    })
    this.setState({ Data: data1, ftd: v4 })
    window.scroll({
      top: 400,
      left: 0,
      behavior: "smooth",
    });
  }

  onClk = (val) => {

    if (val === 1) { this.setState({ Datas: this.state.leadFTD, isfollowup: false, dateVisible: false, filteredMake: "", filteredLoc: "", FilterData: this.state.leadFTD }) }
    if (val === 2) { this.setState({ Datas: this.state.leadMTD, isfollowup: false, dateVisible: false, filteredMake: "", filteredLoc: "", FilterData: this.state.leadMTD }) }
    if (val === 3) { this.setState({ Datas: this.state.followupftd, isfollowup: true, dateVisible: false, filteredMake: "", filteredLoc: "", FilterData: this.state.followupftd }) }
    if (val === 4) { this.setState({ Datas: this.state.walkinFTD, isfollowup: false, dateVisible: false, filteredMake: "", filteredLoc: "", FilterData: this.state.walkinFTD }) }
    if (val === 5) { this.setState({ Datas: this.state.walkinMTD, isfollowup: false, dateVisible: false, filteredMake: "", filteredLoc: "", FilterData: this.state.walkinMTD }) }
    if (val === 6) { this.setState({ Datas: this.state.closed, isfollowup: false, dateVisible: false, filteredMake: "", filteredLoc: "", FilterData: this.state.closed }) }
    if (val === 9) { this.setState({ Datas: this.state.alllead, isfollowup: false, dateVisible: false, filteredMake: "", filteredLoc: "", FilterData: this.state.alllead }) }
    if (val === 7) { this.setState({ Datas: this.state.walkinDone, isfollowup: false, dateVisible: false, filteredMake: "", filteredLoc: "", FilterData: this.state.alllead }) }


    // if (val === 1) { this.setState({ Datas: this.state.Walkin, dateVisible: false, filteredMake: "", filteredLoc: "", FilterData: this.state.Walkin }) }
    // if (val === 3) { this.setState({ Datas: this.state.MTD, dateVisible: false, filteredMake: "", filteredLoc: "", FilterData: this.state.MTD }) }
    // if (val === 4) { this.setState({ Datas: this.state.Followup, dateVisible: false, filteredMake: "", filteredLoc: "", FilterData: this.state.Followup }) }
    window.scroll({
      top: 400,
      left: 0,
      behavior: "smooth",
    });
  }
  resetModal = (Modalid) => {
    window.$(`#${Modalid}`).modal("hide");
    this.setState({
      EditData: [],
      isEdit: false
    })
  }
  makeFilter = async (d) => {
    let { filteredMake, EnquiryDatas, MakeOptions, filteredLoc, FilterData } = this.state;

    //   console.log(d, "d")

    await this.setState({ filteredMake: d })

    if (d && d.length == 0 && filteredLoc.length == 0) {
      await this.setState({ Datas: this.state.FilterData })
    }
    else if (d && d.length == 0 && filteredLoc.length != 0) {
      var filtered_data = await FilterData.filter(x => filteredLoc.some(y => x.location == y.value))
      await this.setState({ Datas: filtered_data })
    }
    else if (d && d.length != 0 && filteredLoc && filteredLoc.length != 0) {
      var filtered_make = await FilterData.filter(fd => d.some(d => fd.Make == d.value))
      var filtered_loc = await FilterData.filter(fd => filteredLoc.some(fl => fd.location == fl.value))
      // console.log(filtered_make, "filtered_make", filtered_loc, "filtered_loc'")
      var filtered_data = await filtered_make.filter(fm => filtered_loc.some(fl => fm.Make == fl.Make && fm.location == fl.location))
      // console.log(filtered_data, "filtered_data")
      await this.setState({ Datas: filtered_data })
    }

    else if (d && d.length != 0 && filteredLoc.length == 0) {
      var filtered_data = await FilterData.filter(x => d.some(y => x.Make == y.value))
      await this.setState({ Datas: filtered_data })
    }
    else {
      //nothing
    }
  }
  locationFilter = async (d) => {
    let { locationOpt, filteredLoc, FilterData, filteredMake, EnquiryDatas } = this.state
    // console.log(d, "d")

    await this.setState({ filteredLoc: d })

    if (d && d.length == 0 && filteredMake.length == 0) {
      await this.setState({ Datas: this.state.FilterData })
    }

    else if (d && d.length == 0 && filteredMake.length != 0) {
      var filtered_data = await FilterData.filter(x => filteredMake.some(y => x.Make == y.value))
      await this.setState({ Datas: filtered_data })
    }
    else if (d && d.length != 0 && filteredMake.length != 0) {
      var filtered_loc = await FilterData.filter(fd => d.some(d => fd.location == d.value))
      var filtered_make = await FilterData.filter(fd => filteredMake.some(d => fd.Make == d.value))
      // console.log(filtered_make, "filtered_make", filtered_loc, "filtered_loc'")
      var filtered_data = await filtered_loc.filter(fl => filtered_make.some(fm => fl.location == fm.location && fl.Make == fm.Make))
      // console.log(filtered_data, "filtered_dataaa")
      await this.setState({ Datas: filtered_data })
    }

    else if (d && d.length != 0 && filteredMake.length == 0) {
      var filtered_data = await FilterData.filter(x => d.some(y => x.location == y.value))
      await this.setState({ Datas: filtered_data })
    }
    else {
      //nothing
    }

  }
  clearFilter = async () => {
    await this.setState({ filteredMake: "", filteredLoc: "", Datas: this.state.FilterData })
  }
  render() {
    let { loading } = this.state;
    let { Inventory, InventoryData, isImageModalActive, imgmdl, locationOpt, MakeOptions } = this.state;
    // console.log(this.state.Datas, "this.state.Datas")
    return (<React.Fragment>
      <main className="main my-4">
        <div className="container-fluid">
          {this.edash()}
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h1>Lead</h1>
                </div>
                <div className="card-body">
                  <div className="row form-group">
                    <div className="col-sm-4">
                      <LoginModal
                        buttonTitle="Add Enquiry"
                        title="Update Commets"
                        extraStyle={{ width: 'auto' }}
                        id="ccc"
                        extraClass="btn btn-sm btn-primary"
                        onClick={this.reset}
                        // extraStyle={{ width: '1000px' }}
                        buttonStyle={{ backgroundColor: "white", border: "white" }}
                        bodyText={
                          <UsertypeComments
                            stage={this.state.stage}
                            customerid={this.state.CustomerId}
                          />}
                      />
                    </div>

                    <div className="col-sm-4">
                      <LoginModal
                        buttonTitle="Add"
                        title="Update Status"
                        id="status"
                        extraClass="btn btn-sm btn-primary"
                        onClick={this.reset}
                        extraStyle={{ width: 'auto' }}
                        buttonStyle={{ backgroundColor: "white", border: "white" }}
                        bodyText={
                          <UpdateLeadStatus
                            EditData={this.state.EditData}
                            these={this.mainFun}
                            isEdit={this.state.isEdit}
                            FullData={this.state.Data}
                            userid={this.state.User.id}
                            isfollowup={this.state.isfollowup}
                            statusarray={[{ status: "", statusname: '', followup: '', remark: '', username: '', userid: '', index: 0, error: null }]}
                            close={this.resetModal}
                          />}
                      />
                    </div>
                  </div>
                  {
                    this.state.FilterData && this.state.FilterData.length
                      ?
                      <div className="container">
                        <div className="row">
                          <div className="col-sm-6">
                            <label htmlFor="locationFilter" style={{ fontWeight: 500, fontSize: "medium" }} > Location filter: </label>
                          </div>
                          <div className="col-sm-6">
                            <label htmlFor="makefilter" style={{ fontWeight: 500, fontSize: "medium" }}> Make filter: </label>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-6">
                            <MultiSelect
                              id="locationFilter"
                              name="location"
                              placeholder="Filter Location"
                              options={locationOpt}
                              handleChange={d => this.locationFilter(d)}
                              selectedService={this.state.filteredLoc}
                            />
                          </div>
                          <div className="col-sm-6">
                            <MultiSelect
                              id="makefilter"
                              name="make"
                              placeholder="Filter Make"
                              options={MakeOptions}
                              handleChange={d => this.makeFilter(d)}
                              selectedService={this.state.filteredMake}
                            />
                          </div>
                        </div>
                        <br></br>
                        <div className="row">
                          <div className="col-sm-5" />
                          <div className="col-sm-2">
                            <button type="button" className="btn btn-sm btn-primary" onClick={() => {
                              this.clearFilter()
                            }}> Clear filter</button>
                          </div>
                          <div className="col-sm-5"></div>
                        </div>
                        <br></br>

                      </div>
                      :
                      null
                  }
                  {loading
                    ?
                    <div className="text-center">
                      <i className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}></i>
                    </div>
                    :
                    this.state.Data.length
                      ?
                      (
                        this.state.Datas.length
                          ?
                          <Datatable
                            data={this.state.Datas}
                            columnHeading={this.state.column}
                            excelColumn={this.state.excelColumn}
                          />
                          :
                          <>
                            <div style={{ textAlign: "center", fontSize: "larger" }} ><b>No rows to show</b></div>
                            <br></br>
                          </>
                      )
                      :
                      (<div style={{ textAlign: "center" }} ><b>No Data Found</b></div>)}
                  <div>
                    {
                      <LoginModal
                        // buttonTitle="Matched Car List"
                        maxwidth="lg"
                        title="Matched Car List"
                        id='matchcar'
                        extraStyle={{ width: 'auto' }}
                        extraClass="btn btn-sm btn-warning"
                        // extraStyle={{ width: 1200, marginLeft: -200 }}
                        buttonStyle={{ backgroundColor: "white", border: "white" }}
                        bodyText={
                          <div>
                            <div className="card-body">
                              {isImageModalActive ?
                                <>
                                  <div className="d-flex justify-content-center">
                                    {imgmdl && imgmdl}

                                  </div>
                                  <br />
                                  <br />
                                  <div className="row form-group d-flex justify-content-center">
                                    <button type="button" className="btn btn-sm btn-warning"
                                      onClick={() => this.setState({ isImageModalActive: false })}
                                    >bact to matched car
                                    </button>
                                  </div>

                                </> :
                                <>
                                  <div className="row form-group">
                                    <div className="col-sm-12">
                                      <div>{Inventory && Inventory.length > 0 ?
                                        <Datatable
                                          data={this.state.Inventory}
                                          columnHeading={this.state.column1}
                                          excel={false}
                                        /> : <h3 style={{ color: "#fc4c0d" }}>No matched Car Found </h3>}
                                      </div>
                                    </div>
                                  </div>
                                  <br />
                                  <div className="row form-group">
                                    <div className="col-sm-12">
                                      Suggestion :
                                      <br /><br />
                                      <>
                                        {InventoryData && InventoryData.length > 0 ?
                                          <Datatable
                                            data={this.state.InventoryData}
                                            columnHeading={this.state.column1}
                                            excel={false}
                                          /> : null}
                                      </>
                                    </div>
                                  </div>
                                </>
                              }
                            </div>
                          </div>
                        }
                      />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>)
  }
}
export default TLProspectBuyerList;