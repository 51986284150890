import React, { Component } from "react";
import { Alert } from "reactstrap";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import SingleSelect from "../../../../components/Form/SingleSelect";
import redisMiddleware from "../../../../MiddleWare/redisMiddleware";
import CmsContent from "../../../../MiddleWare/CmsContent";
import iconClose from "../../../../images/CMS/icon-hamburger-close.svg";
import LoginModal from "../../../../components/Modal/Modal";
import moment from "moment";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Preloader from "../preloader.js";
import { ACCESS_POINT } from "../../../../config/index";
//import Fileupload from '../../../MiddleWare/Fileupload';
import { FilePond, File, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { isNull } from "lodash-es";
import { MDBBtn, MDBIcon } from "mdbreact";
import Camera from '../../../../MiddleWare/camera'
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
var converter = require("number-to-words");

class Delivery_Note_Seller_Proof extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      respect: [{ label: "Sir", value: 1 }, { label: "Madam", value: 1 }],
      answerJson: [],
      Fproof_Data: [],
      inventory_data: [],
      json: [],
      filesArray: [{ LHS: [], RHS: [], FRONT: [], BACK: [] }],
      alertVisible: false,
      userid: JSON.parse(localStorage.getItem("userlog")),
      usertypelog: JSON.parse(localStorage.getItem("usertypelog")),
      button: "Save",
      files: [],
      images: [],
      sources: [{ label: "Walkin Showroom", value: 1 }, { label: "Walk in Event", value: 2 }, { label: "Tele in", value: 3 }, { label: "Tele Out / Sms", value: 4 }, { label: "Web Leads", value: 5 }, { label: "OLX", value: 6 }, { label: "Car Trade", value: 7 }, { label: "Car Dekho", value: 8 }, { label: "Workshop", value: 9 }, { label: "Other Portals", value: 10 }],
    };
  }
  async componentDidMount() {
    try {

      let allOpt = await this.decryption('getcar')
      if (allOpt) {
        await this.setState({
          makeopt: allOpt.makeopt,
          colopt: allOpt.coloropt,
          varopt: allOpt.variantopt,
          modelopt: allOpt.modelopt,
          makeYearOption: allOpt.yearopt,
        })
      }

      const formQues = await CmsContent.getFreedom(
        "*",
        "tbl_formquestion",
        "parentid = 80"
      );
      formQues.data.map((ival, i) => {
        if (ival.Orderby == null) {
          ival.Orderby = 1000 + i;
        }
      });
      formQues.data = formQues.data.sort(await CmsContent.compareValues("Orderby", "int", "asc"));
      console.log(formQues.data);
      this.setState({ data: formQues.data });
      if (formQues) {
        formQues.data.map((qval, q) => {

          if (qval.id == 414) {
            qval.options = this.state.makeopt;
          }
          if (qval.id == 418) {
            qval.options = this.state.colopt;
          }
          if (qval.id == 422) {

            qval.type = ''
          }
          if (qval.id == 415) {
            qval.options = this.state.modelopt;
          }
          if (qval.id == 416) {
            qval.type = 'selectbox'
            qval.options = this.state.makeYearOption;
          }
          if (qval.id == 545) {
            qval.options = this.state.varopt;
          }
          if (qval.id == 421) {
            qval.options = this.state.sources;
          }
        });
      }
      if (this.props.cusid) {
        this.setState({ customerId: this.props.cusid, global: false })
      }
      else if (this.props.match.params.cusid) {
        this.setState({ customerId: this.props.match.params.cusid, global: true });
      }

      let answerJson = this.state.answerJson;
      let fproof = await CmsContent.getFreedom(
        "*",
        "tbl_FProof",
        `customerid=${this.state.customerId}`,
        "id",
        "id desc"
      );

      if (fproof && fproof.data.length > 0) {
        let proof = fproof.data[0];
        await this.setState({ Fproof_Data: proof })
        answerJson[400] = proof.customer_name;
        answerJson[410] = proof.trade_in_price;

        answerJson[406] = proof.customer_name;
        answerJson[407] = proof.address;
        answerJson[403] = proof.mobile;
        answerJson[409] = proof.engine_no

        if (proof.source) {
          this.state.sources.map((ival, i) => {
            if (ival.label == proof.source) {
              answerJson[421] = ival;
            }
          })
        }

        this.state.colopt.map((cval, j) => {
          if (cval.value == proof.color) {
            answerJson[418] = cval;
          }
        });
      }
      let Delivery = await CmsContent.getFreedom(
        "*",
        "tbl_Delivery_Seller_Proof",
        `customerid=${this.state.customerId}`,
        "id",
        "id desc"
      );
      let booklet = await CmsContent.getFreedom(
        "*",
        " tbl_AT_Booklet_Purchase_Contract_Proof",
        `customerId=${this.state.customerId}`,
        "id",
        "id desc"
      );

      let inventory = await CmsContent.getFreedom(
        "*",
        " tbl_Inventory_Sheet_Reproof",
        `customerid=${this.state.customerId}`,
        "id",
        "id"
      );

      if (booklet && booklet.data.length > 0) {
        let book = booklet.data[0];

        var dateStr = moment(new Date()).format('YYYY-MM-DD')
        this.setState({ Evaluatorid: book.userid })
        answerJson[399] = dateStr;
        answerJson[405] = book.Reg_No;
        answerJson[400] = book.name;
        answerJson[402] = book.To_address;
        answerJson[406] = book.name;
        answerJson[408] = book.chassis_no;
        answerJson[409] = book.Engine_No;
        this.state.makeYearOption.map((ival, j) => {
          if (ival.label == parseInt(book.yom)) {
            answerJson[416] = ival
          }
        });

        if (book.bankname == "null") {
          answerJson[420] = ""
        }
        else {
          answerJson[420] = book.bankname;
        }
        this.state.makeopt.map((val, n) => {
          if (val.value == book.make) {
            answerJson[414] = val;
          }
        });
        await this.state.modelopt.map((kval, m) => {
          if (kval.value == book.model) {
            answerJson[415] = kval;
          }
        });
        this.state.varopt.map((val, n) => {
          if (val.value == book.variant) {
            answerJson[545] = val;
          }
        });
        this.state.makeopt.map((val, n) => {
          if (val.value == book.make) {
            answerJson[404] = val.label;
          }
        });

      }
      if (inventory && inventory.data.length > 0) {
        let inv = inventory.data[0];

        if (inv.address) {
          answerJson[402] = inv.address;
        }
        answerJson[409] = inv.eng_no ? inv.eng_no : answerJson[409];

        let today = new Date()
        answerJson[412] = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        if (inv.delivery_date) {
          let date = inv.delivery_date.split(" ")[0];

          answerJson[413] = date;
        } else {
          answerJson[413] = moment().format("YYYY-MM-DD");

        }
        ;
        answerJson[419] = inv.km;
        this.state.makeopt.map((val, n) => {
          if (val.value == inv.make) {
            answerJson[404] = val.label;
          }
        });


        this.state.varopt.map((val, n) => {
          if (val.value == inv.variant) {
            answerJson[545] = val;
          }
        });

        this.state.modelopt.map((kval, m) => {
          if (kval.value == inv.model) {
            answerJson[415] = kval;
          }
        });

        answerJson[417] = inv.ownership;
      }
      let isfillform = true
      if (Delivery && Delivery.data.length > 0) {
        isfillform = false
        await this.setState({ Delivery: Delivery.data });
        await this.form_filled();
      }
      this.setState({ answerJson, inventory_data: inventory.data[0], isfillform });

      this.process();
      if (isfillform) {
        if (localStorage.getItem("formFillData")) {
          let formFillData = JSON.parse(localStorage.getItem("formFillData"))
          const Data = formFillData.filter(e => e.prosid == this.state.customerId && e.ref == 'PHYSICAL_PROCESS')
          if (Data && Data.length) {
            answerJson = Data[0].data
            this.setState({ answerJson })
          }
        }
      }
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            this.handleSuccess,
            this.handleError
        );
    } else {
        this.setState({ error: 'Geolocation is not supported' });
    }
    
    } catch (error) {
      console.error(error);
    }
  }

  handleSuccess = (position) => {
    this.setState({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        error: null
    });
};

handleError = (error) => {
    this.setState({ error: error.message });
};

  form_filled = async () => {
    let answerJson = this.state.answerJson;
    let del = this.state.Delivery[0];

    let date = new Date(del.delivery_time);

    if (del.date) {
      let date = moment(del.date).format("YYYY-MM-DD");

      answerJson[413] = date;
    }

    answerJson.id = del.id;
    answerJson[406] = del.reg_name;
    answerJson[407] = del.reg_address;
    answerJson[409] = del.engine_no;
    answerJson[402] = del.address;
    this.state.makeYearOption.map((ival, j) => {
      if (ival.label == parseInt(del.year)) {
        answerJson[416] = ival;
      }
    });


    this.state.makeopt.map((val, n) => {
      if (val.value == del.make) {
        answerJson[414] = val;
      }
    });
    console.log(answerJson[414], "answerJson[414] form fromfilled");
    this.state.modelopt.map((kval, m) => {
      if (kval.value == del.model) {
        answerJson[415] = kval;
      }
    });
    this.state.colopt.map((val, n) => {
      if (val.value == del.color) {
        answerJson[418] = val;
      }
    });
    this.state.varopt.map((val, n) => {
      if (val.value == del.variant) {
        answerJson[545] = val;
      }
    });

    answerJson[400] = del.from_name;
    if (del.s_o == "undefined") {
      answerJson[401] = null
    }
    else {
      answerJson[401] = del.s_o;
    }
    answerJson[403] = del.phno;
    answerJson[404] = del.usedCarName;
    answerJson[408] = del.chassis_no;
    answerJson[410] = del.price;
    answerJson[411] = del.price_in_words;
    answerJson[412] = del.delivery_time;
    answerJson[420] = del.finance;
    this.state.sources.map((ival, i) => {
      if (ival.label == del.source) {
        answerJson[421] = ival
      }
    })

    let images = this.state.images;
    images = JSON.parse(del.filesArray);

    let img = { LHS: [], RHS: [], FRONT: [], BACK: [] };
    if (images != null) {
      Object.keys(images).map((key) => {
        images[key].map((ival, i) => {
          if (ival) {
            let spliting = ival.split(".");
            if (['pdf', 'doc', 'xlsx', 'csv'].includes(spliting[1])) {
              let a = null
              a = "false/pdfIconTrans_1599478202382.png";
              let wait = img[key].push(
                <div>
                  <div style={{ paddingLeft: '160px', position: 'relative' }} >
                    <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                      onClick={() => this.removeimg(key, i)}></img>
                  </div>
                  <img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + a}
                    onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }}
                    style={{
                      width: "200px", height: "200px", display: "inline-block", padding: "10px"

                    }} />
                </div>
              )
            } else {
              let wait = img[key].push(
                <div>
                  <div style={{ paddingLeft: '160px', position: 'relative' }} >
                    <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                      onClick={() => this.removeimg(key, i)}></img>
                  </div>
                  <img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + ival}
                    onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }}
                    style={{
                      width: "200px", height: "200px", display: "inline-block", padding: "10px"

                    }} />
                </div>
              )
            }
          }
        })
      })
      this.setState({ img, oldFiles: images })
    }

    //--OLD--
    //answerJson[405] = del.reg_no;
    //answerJson[421] = del.source;
    //answerJson[422] = del.consult_name;
    //answerJson[412] = strTime;
    // answerJson[417] = del.owner;
    //answerJson[416] = del.year;
    //--OLD END--


    this.process();
    this.setState({ button: "Update", answerJson, images });
    this.imgFun()
  };
  imgFun = () => {
    let { images } = this.state
    let img = { LHS: [], RHS: [], FRONT: [], BACK: [] };
    if (images != null) {
      Object.keys(images).map((key) => {
        images[key].map((ival, i) => {
          if (ival) {
            let spliting = ival.split(".");
            if (['pdf', 'doc', 'xlsx', 'csv'].includes(spliting[1])) {
              let a = null
              a = "false/pdfIconTrans_1599478202382.png";
              let wait = img[key].push(
                <div>
                  <div style={{ paddingLeft: '160px', position: 'relative' }} >
                    <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                      onClick={() => this.removeimg(key, i)}></img>
                  </div>
                  <img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + a}
                    onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }}
                    style={{
                      width: "200px", height: "200px", display: "inline-block", padding: "10px"

                    }} />
                </div>
              )
            } else {
              let wait = img[key].push(
                <div>
                  <div style={{ paddingLeft: '160px', position: 'relative' }} >
                    <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                      onClick={() => this.removeimg(key, i)}></img>
                  </div>
                  <img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + ival}
                    onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }}
                    style={{
                      width: "200px", height: "200px", display: "inline-block", padding: "10px"

                    }} />
                </div>
              )
            }
          }
        })
      })
      this.setState({ img, oldFiles: images })
    }
  }
  removeimg = (key, i) => {
    let { oldFiles, img, images } = this.state;
    if (oldFiles || img) {
      // console.log(oldFiles[key], key,i);
      images[key] && images[key].splice(i, 1)
      img[key] && img[key].splice(i, 1)
      this.setState({ images, img })
      this.imgFun()

    }
  }
  async answers(
    label,
    index,
    options = null,
    key = null,
    type,
    questionId,
    formid
  ) {
    const { data, modelopt, varopt, answerJson, isfillform } = this.state;
    console.log(label, questionId, 'ans');
    let model = [];
    let variant = [];

    let get = []
    if (questionId == 416) {
      if (answerJson[414]) answerJson[414] = null;
      if (answerJson[415]) answerJson[415] = null;
      if (answerJson[545]) answerJson[545] = null;
      if (answerJson[418]) answerJson[418] = null;
    }
    if (answerJson[416] && questionId == 414) { //make
      if (answerJson[415]) answerJson[415] = null;
      if (answerJson[545]) answerJson[545] = null;
      modelopt.map((mval, i) => {
        if (mval.makeid == label.value) {
          get.push(mval);
        }
      });

      if (get.length) {
        const manfuc = this.state.makeYearOption.filter(e => e.label == answerJson[416].label)[0];
        if (manfuc && manfuc != "undefined") {
          get = get.filter(e => e.syear <= manfuc.label && e.eyear >= manfuc.label)

        } else {
          get = []
        }
      }
      data.map((ival, k) => {
        if (ival.id == 415) {
          ival.options = get;
        }
      });
    }

    if (questionId == 415) { //model
      if (answerJson[545]) answerJson[545] = null;
      varopt.map((val, i) => {
        if (val.modelid == parseInt(label.value)) {
          get.push(val);
        }
      });
      data.map((jval, j) => {
        if (jval.id == 545) {
          jval.options = get;
        }
      });
    }


    if (type == "text") {
      answerJson[questionId] = label;
    } else if (type == "date") {
      var d = new Date(label);

      answerJson[questionId] = label;
    } else if (type == "textarea") {
      answerJson[questionId] = label;
    } else if (type == "selectbox") {
      answerJson[questionId] = label;
    } else if (type == "radio") {
      answerJson[questionId] = label;
    } else if (type == "checkbox") {
      let check = [];
      options.map((ival, i) => {
        var checkBox = document.getElementById(`inlineCheckbox${index}${i}`);

        if (checkBox.checked == true) {
          check.push(ival);
        }
      });
      answerJson[questionId] = check.toString();
    }
    if (isfillform) { await this.formSave('PHYSICAL_PROCESS', this.state.customerId, answerJson) }
    await this.setState({ answerJson, data });
    if (type == "textarea" || type == "text") {
      this.TypingControl(this.process);//avoid many time call
    }
    else { this.process() }
  }


  fileupload = async e => {
    const { files } = this.state;
    // console.log('upload', files)
    let fileArray = [];
    for (let i = 0; i < files.length; i++) {
      fileArray.push({ file: files[i], type: files[i].type });
      // console.log(fileArray)
    }
    files.map((ival, n) => {
      if (
        ival.type !== "image/png" &&
        ival.type !== "image/jpg" &&
        ival.type !== "image/jpeg" &&
        ival.type !== "application/pdf"
      ) {
        this.setState({
          errfiles: "Please upload file format of .jpg,.jpeg,.png,.pdf"
        });
        return false;
      } else {
      }
    });

  };

  process = async () => {
    let data = await this.state.data;
    let { usertypelog } = this.state
    console.log("data", data, usertypelog);
    if (data) {
      let arr = data;
      let contentJson = [];
      if (arr && arr.length) {
        let answerJson = await this.state.answerJson;
        let answerJsons = answerJson.length;
        let json = await arr.map(async (item, index) => {
          if (item.id == 399 || item.id == 411) {
            item.type = ""
          }
          if (item.type == "text") {
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="position">{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      // disabled={item.type == "" && true}
                      className="form-control"
                      placeholder={item.placeholder}
                      id="text"
                      disabled={usertypelog && usertypelog.orderBy && usertypelog.orderBy == 9 && item && item.id && item.id == 412 && true}
                      onChange={e =>
                        this.answers(
                          e.target.value,
                          index,
                          null,
                          null,
                          "text",
                          item.id,
                          item.parentid
                        )
                      }
                      defaultValue={answerJson[item.id] && answerJson[item.id].label ? answerJson[item.id].label : answerJson[item.id]} />

                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "number") {
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="position">{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <input
                      type="number"
                      className="form-control"
                      placeholder={item.placeholder}
                      id="text"
                      onChange={e =>
                        this.answers(
                          e.target.value,
                          index,
                          null,
                          null,
                          "text",
                          item.id,
                          item.parentid
                        )
                      }
                      disabled={item.id == 403 ? true : false}
                      defaultValue={answerJson[item.id]}
                    />

                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "date") {
            this.state.date = answerJson[item.id];
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="position">{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <input
                      type="date"
                      id="myDate"
                      className="form-control"
                      disabled={usertypelog && usertypelog.orderBy && usertypelog.orderBy == 9 && item && item.id && item.id == 413 && true}

                      onChange={e =>
                        this.answers(
                          e.target.value,
                          index,
                          null,
                          null,
                          "date",
                          item.id,
                          item.parentid
                        )
                      }
                      value={this.state.date}
                    //value={answerJson[item.id]}
                    // onChange={this.fromDateSelect}
                    // value={selectedstartdate}
                    />
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "textarea") {
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder={item.placeholder}
                      onChange={e =>
                        this.answers(
                          e.target.value,
                          index,
                          null,
                          null,
                          "textarea",
                          item.id,
                          item.parentid
                        )
                      }
                      defaultValue={answerJson[item.id]}
                    ></textarea>
                  </div>
                  <div className="col-sm-3">
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                </div>
              </div>
            );
          }
          if (item.type == "radio") {
            let arr1 = [];
            await item.options.split(",").map((element, key) => {
              arr1.push(
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      name={`element${index}`}
                      // value={`${element}`}
                      id={`inlineCheckbox${key}`}
                      onChange={e =>
                        this.answers(
                          element,
                          key,
                          null,
                          key,
                          "radio",
                          item.id,
                          item.parentid
                        )
                      }
                      checked={element == answerJson[item.id]}
                    />
                    <label
                      className="form-check-label"
                      for={`inlineCheckbox${key}`}
                      style={{ marginLeft: "10px" }}
                    >
                      {`${element}`}{" "}
                    </label>
                  </div>
                </div>
              );
            });
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    {arr1}
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "selectbox") {
            let option = [];
            // console.log(item.options);
            if (item.options != null && item.options != " ") {
              item.options.map((ival, i) => {
                option.push(ival);
              });
            }
            // item.options.split(",").map((ival, i) => {
            //   option.push({ label: ival, value: i });
            // });
            this.state.selectbox = answerJson[item.id];
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <SingleSelect
                      options={option}
                      placeholder={item.placeholder}
                      handleChange={e => {
                        this.answers(
                          e,
                          index,
                          null,
                          null,
                          "selectbox",
                          item.id,
                          item.parentid
                        );
                      }}
                      selectedService={this.state.selectbox}
                    />
                  </div>
                  <span className="error-shows" id={`${index}`}>
                    {/* {this.state.error[index]} */}
                  </span>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "checkbox") {
            let arr1 = [];
            await item.options.split(",").map((element, key) => {
              arr1.push(
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`inlineCheckbox${index}${key}`}
                    onChange={e => {
                      this.answers(
                        element,
                        index,
                        item.options.split(","),
                        key,
                        "checkbox",
                        item.id,
                        item.parentid
                      );
                    }}
                    value={element}
                    checked={element == answerJson[item.id]}
                  />
                  <label
                    className="form-check-label"
                    for={`inlineCheckbox${index}${key}`}
                    style={{ marginLeft: "10px" }}
                  >{`${element}`}</label>
                </div>
              );
            });
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">{arr1}</div>
                  <div className="col-sm-3">
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                </div>
              </div>
            );
          }
        });
        await Promise.all(json);
        await this.setState({ json: contentJson, answerJson });
      }
    }
  };


  update = async () => {
    const { answerJson } = this.state;
    let categoryArray = {};
    let id = answerJson.id;

    //categoryArray.date = answerJson[399];
    categoryArray.from_name = answerJson[400];
    categoryArray.s_o = answerJson[401];
    categoryArray.address = answerJson[402];
    categoryArray.phno = answerJson[403];
    categoryArray.usedCarName = answerJson[404];
    categoryArray.reg_no = answerJson[405];
    categoryArray.reg_name = answerJson[406];
    categoryArray.reg_address = answerJson[407];
    categoryArray.chassis_no = answerJson[408];
    categoryArray.engine_no = answerJson[409];
    categoryArray.price = answerJson[410];
    //categoryArray.price_in_words = answerJson[411];
    categoryArray.delivery_time = answerJson[412];
    categoryArray.delivery_date = answerJson[413];
    if (answerJson[414]) {
      categoryArray.make = answerJson[414].value;
    }
    if (answerJson[415]) {
      categoryArray.model = answerJson[415].value;
    }
    if (answerJson[545]) {
      categoryArray.variant = answerJson[545].value;
    }
    categoryArray.year = answerJson[416] ? answerJson[416].label : null;
    categoryArray.owner = answerJson[417];
    if (answerJson[418]) {
      categoryArray.color = answerJson[418].value;
    }
    categoryArray.kms = answerJson[419];
    categoryArray.finance = answerJson[420];
    if (answerJson[421]) {
      categoryArray.source = answerJson[421];
    }
    if (answerJson[422]) {
      categoryArray.consult_name = answerJson[422].label;
    }
    let result = await CmsContent.updateMaster(
      "tbl_Delivery_Seller_Proof",
      id,
      categoryArray
    );
    if (result) {
      await this.setState({
        answerJson: "",
        json: "",
        alertVisible: true,
        textalert: "Your form has been updated successfully",
        color: "success"
      });

      setTimeout(() => this.setState({ alertVisible: false }), 3000);

      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }
  };
  // resetvalues = async () => {
  //   document.getElementById("text").reset();
  // }

  update1 = async () => {

    const { answerJson, files, filesArray } = this.state;
    this.setState({ btnDisable: true })

    // let imagealeart = ''
    // if (filesArray) {
    //   let obj = Object.values(filesArray).map(e => e && e.length && e.map(x => x.size))
    //   obj.map((ival) => {
    //     if (ival !== undefined) {
    //       const fileSize = ival[0] / 1024 / 1024;
    //       const maxFileSize = 3; // Maximum file size in MB
    //       // Validate file size
    //       if (fileSize > maxFileSize) {
    //         this.setState({ btnDisable: false })
    //         // alert("Please Check Image is Greater than 300KB");
    //         imagealeart = true;
    //         return true;
    //       }
    //     }
    //     else { console.log(ival, "ival"); }
    //   })
    // }
    // if (imagealeart == true) {
    //   alert("Please Check Image is Greater than 3MB");
    //   return false;
    // }
    // console.log(this.state.latitude,"latitude",this.state.longitude,"longitude");
    if (this.state.latitude == '' ||this.state.longitude == ''  || this.state.latitude == undefined ||this.state.longitude == undefined ) {
            alert('Please allow permission to access your location');;
      // return false;
    }
    const formData = new FormData();
    let id = answerJson.id;
    if (files) {
      files.map((ival, i) => {
        if (
          ival.type !== "image/png" &&
          ival.type !== "image/jpg" &&
          ival.type !== "image/jpeg" &&
          ival.type !== "application/pdf"
        ) {
          this.setState({
            errfiles: "Please upload file format of .jpg,.jpeg,.png,.pdf"
          });
          return false;
        } else {
          console.log(ival, 'files');
          formData.append(`files${i}`, ival);
        }
      });
    }


    if (answerJson[399]) {
      var d = new Date(answerJson[399]);
      var date = d.getDate();
      var month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      var year = d.getFullYear();
      var hr = d.getHours();
      var min = d.getMinutes();
      var sec = d.getSeconds();
      if (date < 10) date = "0" + date;
      if (month < 10) month = "0" + month;
      var dateStr =
        year + "-" + month + "-" + date + " " + hr + ":" + min + ":" + sec;
      //console.log(dateStr);
      //formData.append("date", dateStr);
      //categoryArray.date = dateStr;
    }

    formData.append("latitude", this.state.latitude);

    formData.append("longitude", this.state.longitude);

    formData.append("from_name", answerJson[400]);
    formData.append("s_o", answerJson[401]);
    formData.append("address", answerJson[402]);
    formData.append("phno", answerJson[403]);
    formData.append("usedCarName", answerJson[404]);
    formData.append("reg_no", answerJson[405]);
    formData.append("reg_name", answerJson[406]);
    formData.append("reg_address", answerJson[407]);
    formData.append("chassis_no", answerJson[408]);
    formData.append("engine_no", answerJson[409]);
    formData.append("price", answerJson[410]);
    //formData.append("price_in_words", answerJson[411]);
    formData.append("delivery_time", answerJson[412]);
    if (answerJson[413]) {
      var d1 = new Date(answerJson[413]);
      var date1 = d.getDate();
      var month1 = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      var year1 = d.getFullYear();
      var hr1 = d.getHours();
      var min1 = d.getMinutes();
      var sec1 = d.getSeconds();
      if (date1 < 10) date1 = "0" + date1;
      if (month1 < 10) month1 = "0" + month1;
      var dateStr1 =
        year1 +
        "-" +
        month1 +
        "-" +
        date1 +
        " " +
        hr1 +
        ":" +
        min1 +
        ":" +
        sec1;
      console.log(dateStr);
      formData.append("delivery_date", dateStr1);

    }
    if (answerJson[414]) {
      formData.append("make", answerJson[414].value);
    }
    if (answerJson[415]) {
      formData.append("model", answerJson[415].value);
    }
    if (answerJson[545]) {
      formData.append("variant", answerJson[545].value);
    }
    formData.append("year", answerJson[416] ? answerJson[416].label : null);

    formData.append("owner", answerJson[417]);

    if (answerJson[418]) {
      formData.append("color", answerJson[418].value);
    }
    formData.append("kms", answerJson[419]);

    formData.append("finance", answerJson[420]);

    if (answerJson[421]) {
      formData.append("source", answerJson[421].label);
    }

    if (answerJson[422]) {
      formData.append("consult_name", answerJson[422].label);

    }
    formData.append("customerid", this.state.customerId);
    // console.log('userid', this.state.userid.id);
    // formData.append("userid", this.state.userid.id);
    if ([1, 2, 3].includes(this.state.usertypelog.orderBy)) {
      formData.append("userid", this.state.Evaluatorid);
    } else {
      formData.append("userid", this.state.userid.id);
    }
    if ([1, 2, 3].includes(this.state.usertypelog.orderBy)) {
      formData.append("UpdatedBy", this.state.userid.id)
    }
    formData.append("status", "active");

    formData.append("approval", 0);

    if (this.state.filesArray) {
      if (this.state.filesArray.LHS) {
        this.state.filesArray.LHS.map((ival, i) => {
          formData.append(`LHS`, ival);
        })
      }
      if (this.state.filesArray.RHS) {
        this.state.filesArray.RHS.map((jval, j) => {
          formData.append(`RHS`, jval);
        })
      }
      if (this.state.filesArray.FRONT) {
        this.state.filesArray.FRONT.map((kval, k) => {
          formData.append(`FRONT`, kval);
        })
      }
      if (this.state.filesArray.BACK) {
        this.state.filesArray.BACK.map((mval, m) => {
          formData.append(`BACK`, mval);
        })
      }
    }
    if (this.state.oldFiles) {
      formData.append(`oldFiles`, JSON.stringify(this.state.oldFiles))
    }

    console.log([...formData]);
    try {
      let result = await CmsContent.MultiFileUpload(
        "tbl_Delivery_Seller_Proof",
        formData,
        id
      );
      //console.log(result);
      // let result = await CmsContent.addMaster(
      //   "tbl_Delivery_Seller_Proof",
      //   categoryArray
      // );
      if (result) {
        await this.setState({
          btnDisable: false,
          answerJson: "",
          json: "",
          alertVisible: true,
          textalert: "Your form have been Updated successfully",
          color: "success"
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
        this.process();
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
        if (this.state.alertVisible == true) {
          setTimeout(
            () => window.location.reload(),
            2000
          );
        }
      }
    } catch (error) {
      console.log(error);
      this.setState({ btnDisable: false })
    }
  };

  formSubmit = async () => {

    const { answerJson, files, Fproof_Data, inventory_data, filesArray } = this.state;
    this.setState({ btnDisable: true })

    // let imagealeart = ''
    // if (filesArray) {
    //   let obj = Object.values(filesArray).map(e => e && e.length && e.map(x => x.size))
    //   obj.map((ival) => {
    //     if (ival !== undefined) {
    //       const fileSize = ival[0] / 1024 / 1024;
    //       const maxFileSize = 3; // Maximum file size in MB
    //       // Validate file size
    //       if (fileSize > maxFileSize) {
    //         this.setState({ btnDisable: false })
    //         // alert("Please Check Image is Greater than 300KB");
    //         imagealeart = true
    //         return true;
    //       }
    //     }
    //     else { console.log(ival, "ival"); }
    //   })
    // }
    // if (imagealeart == true) {
    //   alert("Please Check Image is Greater than 3MB");
    //   return false;
    // }
    // console.log(this.state.latitude,"latitude",this.state.longitude,"longitude");
    if (this.state.latitude == '' ||this.state.longitude == ''  || this.state.latitude == undefined ||this.state.longitude == undefined ) {
            alert('Please allow permission to access your location');;
      // return false;
    }
    const formData = new FormData();
    if (files) {
      files.map((ival, i) => {
        if (
          ival.type !== "image/png" &&
          ival.type !== "image/jpg" &&
          ival.type !== "image/jpeg" &&
          ival.type !== "application/pdf"
        ) {
          this.setState({
            errfiles: "Please upload file format of .jpg,.jpeg,.png,.pdf"
          });
          return false;
        } else {
          console.log(ival, 'files');
          formData.append(`files${i}`, ival);
        }
      });
    }

    let categoryArray = {};
    if (answerJson[399]) {
      var d = new Date(answerJson[399]);
      var date = d.getDate();
      var month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      var year = d.getFullYear();
      var hr = d.getHours();
      var min = d.getMinutes();
      var sec = d.getSeconds();
      if (date < 10) date = "0" + date;
      if (month < 10) month = "0" + month;
      var dateStr =
        year + "-" + month + "-" + date + " " + hr + ":" + min + ":" + sec;
      //console.log(dateStr);
      //formData.append("date", dateStr);
      //categoryArray.date = dateStr;
    }

    formData.append("latitude", this.state.latitude);

    formData.append("longitude", this.state.longitude);

    formData.append("from_name", answerJson[400]);

    formData.append("s_o", answerJson[401]);

    formData.append("address", answerJson[402]);

    formData.append("phno", answerJson[403]);

    formData.append("usedCarName", answerJson[404]);

    formData.append("reg_no", answerJson[405]);

    formData.append("reg_name", answerJson[406]);

    formData.append("reg_address", answerJson[407]);

    formData.append("chassis_no", answerJson[408]);

    formData.append("engine_no", answerJson[409]);

    formData.append("price", answerJson[410]);

    //formData.append("price_in_words", answerJson[411]);
    formData.append("delivery_time", answerJson[412]);
    // categoryArray.delivery_time = answerJson[412];
    if (answerJson[413]) {
      var d1 = new Date(answerJson[413]);
      var date1 = d.getDate();
      var month1 = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      var year1 = d.getFullYear();
      var hr1 = d.getHours();
      var min1 = d.getMinutes();
      var sec1 = d.getSeconds();
      if (date1 < 10) date1 = "0" + date1;
      if (month1 < 10) month1 = "0" + month1;
      var dateStr1 =
        year1 +
        "-" +
        month1 +
        "-" +
        date1 +
        " " +
        hr1 +
        ":" +
        min1 +
        ":" +
        sec1;
      console.log(dateStr);
      formData.append("delivery_date", dateStr1);

    }
    if (answerJson[414]) {
      formData.append("make", answerJson[414].value);
    }
    if (answerJson[415]) {
      formData.append("model", answerJson[415].value);
    }
    if (answerJson[545]) {
      formData.append("variant", answerJson[545].value);
    }
    formData.append("year", answerJson[416] ? answerJson[416].label : null);

    formData.append("owner", answerJson[417]);

    if (answerJson[418]) {
      formData.append("color", answerJson[418].value);
    }
    formData.append("kms", answerJson[419]);

    formData.append("finance", answerJson[420]);


    if (answerJson[421]) {
      formData.append("source", answerJson[421].label);
    }

    if (answerJson[422]) {
      formData.append("consult_name", answerJson[422].label);

    }
    formData.append("customerid", this.state.customerId);

    console.log('userid', this.state.userid.id);
    // formData.append("userid", this.state.userid.id);

    if ([1, 2, 3].includes(this.state.usertypelog.orderBy)) {
      formData.append("userid", this.state.Evaluatorid);
    } else {
      formData.append("userid", this.state.userid.id);
    }
    if ([1, 2, 3].includes(this.state.usertypelog.orderBy)) {
      formData.append("UpdatedBy", this.state.userid.id)
    }

    formData.append("status", "active");

    formData.append("approval", 0);

    if (this.state.filesArray) {
      if (this.state.filesArray.LHS) {
        this.state.filesArray.LHS.map((ival, i) => {
          formData.append(`LHS`, ival);
        })
      }
      if (this.state.filesArray.RHS) {
        this.state.filesArray.RHS.map((jval, j) => {
          formData.append(`RHS`, jval);
        })
      }
      if (this.state.filesArray.FRONT) {
        this.state.filesArray.FRONT.map((kval, k) => {
          formData.append(`FRONT`, kval);
        })
      }
      if (this.state.filesArray.BACK) {
        this.state.filesArray.BACK.map((mval, m) => {
          formData.append(`BACK`, mval);
        })
      }
    }

    try {
      let result = await CmsContent.MultiFileUpload(
        "tbl_Delivery_Seller_Proof",
        formData
      );
      if (result) {
        let formJson = await JSON.parse(localStorage.getItem("formFillData"))
        if (formJson && formJson.length) {
          let Index = await formJson.findIndex(e => e.prosid == this.state.customerId && e.ref == 'PHYSICAL_PROCESS')
          if (Index > -1) formJson.splice(Index, 1)
          localStorage.setItem("formFillData", JSON.stringify(formJson))
        }
        if (Fproof_Data && Fproof_Data.Auction == 2) {
          if (Fproof_Data) {
            let arr = {};
            arr.process = 2
            if (inventory_data && inventory_data.buying_price) {
              arr.price = parseInt(inventory_data.buying_price)
            } else {
              arr.price = parseInt(Fproof_Data.exchange_bonus)
            }
            if (Fproof_Data.make) {
              arr.make = Fproof_Data.make;
            }
            if (Fproof_Data.model) {
              arr.model = Fproof_Data.model;
            }
            if (Fproof_Data.variant) {
              arr.variant = Fproof_Data.variant;
            }
            if (Fproof_Data.color) {
              arr.colour = Fproof_Data.color;
            }
            if (Fproof_Data.year_of_manu) {
              arr.make_year = Fproof_Data.year_of_manu;
            }
            if (Fproof_Data.ins_validity) {
              arr.Insurance_expiry = Fproof_Data.ins_validity;
            }
            if (Fproof_Data.ownership_no) {
              arr.owner_type = parseInt(Fproof_Data.ownership_no);
            }
            if (Fproof_Data.km_travel) {
              arr.kms = parseInt(Fproof_Data.km_travel);
            }
            if (Fproof_Data.fueloption) {
              arr.fueloption = Fproof_Data.fueloption;
            }
            arr.ServiceId = 10;

            if (d.location) {
              arr.location = d.location;
            }
            if (Fproof_Data.reg_no) {
              arr.Reg_no = Fproof_Data.reg_no;
            }
            if (Fproof_Data.Files) {
              arr.filesArray = Fproof_Data.Files
            }
            if (Fproof_Data.customerid) {
              arr.customerId = Fproof_Data.customerid;
            }
            arr.userid = 620;
            if (Fproof_Data.engine_no) {
              arr.Engine_No = Fproof_Data.engine_no;
            }
            if (Fproof_Data.chassis_no) {
              arr.Chassis_no = Fproof_Data.chassis_no;
            }
            let ProductList_result = await CmsContent.addMaster("	tbl_Dfcars_Garage", arr);
            if (ProductList_result) {
              await this.setState({
                answerJson: "",
                json: "",
                alertVisible: true,
                textalert: "Your form have been Submitted successfully",
                color: "success"
              });
              setTimeout(() => this.setState({ alertVisible: false }), 3000);
              this.process();
              window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth"
              });
              if (this.state.alertVisible == true) {
                setTimeout(
                  () => window.location.reload(),
                  2000
                );
              }
            }

          }
        } else {
          await this.setState({
            btnDisable: false,
            answerJson: "",
            json: "",
            alertVisible: true,
            textalert: "Your form have been Submitted successfully",
            color: "success"
          });
          setTimeout(() => this.setState({ alertVisible: false }), 3000);
          this.process();
          window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
          if (this.state.alertVisible == true) {
            setTimeout(
              () => window.location.reload(),
              2000
            );
          }
        }
      }
    } catch (error) {
      console.log(error);
      this.setState({ btnDisable: false })
    }
  };
  download = (name) => {
    window.open(`${ACCESS_POINT}/cmsContent/downloadsellpdf/${name}`, '_self')
  }
  generatepdf = async (id, name) => {
    let exportpdf = await CmsContent.deliverseller("tbl_Delivery_Seller_Proof", id);
    if (exportpdf.data == "success") {
      await this.download(name)
    }
  }

  newtab = e => {
    window.open(e, "_self");
  };
  exportpdf = async () => {
    let answerJson = this.state.answerJson;
    let name = answerJson[400];
    window.open(`${ACCESS_POINT}/cmsContent/downloadsellpdf/${name}`, '_self')
  }
  vehicleupload = (e, side) => {
    let filesArray = this.state.filesArray;
    if (e) {
      filesArray[side] = e.map(fileItem => fileItem.file)
      this.setState({ filesArray });
      console.log(filesArray);
    }
  }
  webcam2 = (e, field, side, ival = null) => {
    let Files = this.state[field];
    if (e) {
      Files[side] = Files[side] ? Files[side] : []
      Files[side].push({ source: e })
      this.setState({ [field]: Files })
      if (ival) this.setState({ [ival]: false })
    }
  }


  render() {
    const { resSelected, json, button, files, images, filesArray } = this.state;

    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div
                  className="card"
                  style={{
                    margin: "-20px",
                    marginTop: "5px",
                    boxShadow: "0px 0px 10px #b9b3b3",
                    borderRadius: "8px"
                  }}
                >
                  <div className="card-header">
                    <h1>Delivery Note Seller Proof</h1>
                  </div>
                  <div className="card-body">
                    <div>
                      <Alert
                        className="badge-content"
                        color={this.state.color}
                        isOpen={this.state.alertVisible}
                        toggle={this.onDismiss}
                      >
                        {this.state.textalert}
                      </Alert>
                      {/* {json && json.length && json} */}
                      {json && json.length ? json : <div>Loading...</div>}

                      {/* Terms and Conditions:
                      <p>We find the vehicle is as per the condition agreed upon and its documents are complete as per our knowledge and information shared by the seller.</p>
                      <p>We shall be responsible for all types of traffic offences / Police cases / Legal litigations / Accident / RTO taxes / Insurance premium occurring or due from this hour & day onwards.</p>
                      <p>However, any of the above mentioned offences or acts, prior to this hour & day shall be an absolute and unconditional responsibility of the above named previous registered owner of the subject vehicle.</p> */}
                      {json.length > 0 ? (<div className="bgcolor">
                        <div className="row form-group">
                          <div className="col-sm-2"></div>
                          <div className="col-sm-2">
                            <label htmlFor="exampleInputEmail1">
                              Front View{<button className="btn btn-default btn-sm" value={this.state.IsFrontCam} onClick={e => { this.setState({ IsFrontCam: !this.state.IsFrontCam }) }}>
                                <MDBIcon icon="camera" size="lg" />
                              </button>}:
                            </label>
                          </div>
                          {this.state.IsFrontCam ? <div className="col-sm-3">
                            <Camera
                              image={(e) => { this.webcam2(e, 'filesArray', 'FRONT', 'IsFrontCam') }}
                              width={300}
                              height={400}
                              id={'IsFrontCam'}
                            />
                          </div> :
                            <div className="col-sm-3">
                              <FilePond
                                files={filesArray.FRONT}
                                allowReorder={true}
                                allowMultiple={true}
                                allowImageResize={true}
                                imagePreviewMinHeight="50"
                                imagePreviewMaxHeight="50"
                              //  maxFileSize={'3072KB'}
                                acceptedFileTypes={["image/*", "application/pdf"]}
                                onupdatefiles={fileItems => {
                                  this.vehicleupload(fileItems, "FRONT");
                                }}
                                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                              />
                              {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label> &nbsp;&nbsp;
                              <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                            </div>}
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-4"></div>
                          <div className="col">
                            {this.state.img && this.state.img.FRONT}
                          </div>
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-2"></div>
                          <div className="col-sm-2">
                            <label htmlFor="exampleInputEmail1">
                              Back View{<button className="btn btn-default btn-sm" value={this.state.IsbackCam} onClick={e => { this.setState({ IsbackCam: !this.state.IsbackCam }) }}>
                                <MDBIcon icon="camera" size="lg" />
                              </button>}:
                            </label>
                          </div>
                          {this.state.IsbackCam ? <div className="col-sm-3">
                            <Camera
                              image={(e) => { this.webcam2(e, 'filesArray', 'BACK', 'IsbackCam') }}
                              width={300}
                              height={400}
                              id={'IsbackCam'}
                            />
                          </div> :
                            <div className="col-sm-3">
                              <FilePond
                                files={filesArray.BACK}
                                allowReorder={true}
                                allowMultiple={true}
                                allowImageResize={true}
                                imagePreviewMinHeight="50"
                                imagePreviewMaxHeight="50"
                              //  maxFileSize={'3072KB'}
                                acceptedFileTypes={["image/*", "application/pdf"]}
                                onupdatefiles={fileItems => {
                                  this.vehicleupload(fileItems, "BACK");
                                }}
                                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                              />
                              {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label> &nbsp;&nbsp;
                              <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                            </div>}
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-4"></div>
                          <div className="col">
                            {this.state.img && this.state.img.BACK}
                          </div>
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-2"></div>
                          <div className="col-sm-2">
                            <label htmlFor="exampleInputEmail1">
                              LHS View{<button className="btn btn-default btn-sm" value={this.state.IsLhsCam} onClick={e => { this.setState({ IsLhsCam: !this.state.IsLhsCam }) }}>
                                <MDBIcon icon="camera" size="lg" />
                              </button>}:
                            </label>
                          </div>
                          {this.state.IsLhsCam ? <div className="col-sm-3">
                            <Camera
                              image={(e) => { this.webcam2(e, 'filesArray', 'LHS', 'IsLhsCam') }}
                              width={300}
                              height={400}
                              id={'IsLhsCam'}
                            />
                          </div> :
                            <div className="col-sm-3">
                              <FilePond
                                files={filesArray.LHS}
                                allowReorder={true}
                                allowMultiple={true}
                                allowImageResize={true}
                                imagePreviewMinHeight="50"
                                imagePreviewMaxHeight="50"
                              //  maxFileSize={'3072KB'}
                                acceptedFileTypes={["image/*", "application/pdf"]}
                                onupdatefiles={fileItems => {
                                  this.vehicleupload(fileItems, "LHS");
                                }}
                                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                              />
                              {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label> &nbsp;&nbsp;
                              <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                            </div>}
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-4"></div>
                          <div className="col">
                            {this.state.img && this.state.img.LHS}
                          </div>
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-2"></div>
                          <div className="col-sm-2">
                            <label htmlFor="exampleInputEmail1">
                              RHS View{<button className="btn btn-default btn-sm" value={this.state.IsRhsCam} onClick={e => { this.setState({ IsRhsCam: !this.state.IsRhsCam }) }}>
                                <MDBIcon icon="camera" size="lg" />
                              </button>}:
                            </label>
                          </div>
                          {this.state.IsRhsCam ? <div className="col-sm-3">
                            <Camera
                              image={(e) => { this.webcam2(e, 'filesArray', 'RHS', 'IsRhsCam') }}
                              width={300}
                              height={400}
                              id={'IsRhsCam'}
                            />
                          </div> :
                            <div className="col-sm-3">
                              <FilePond
                                files={filesArray.RHS}
                                allowReorder={true}
                                allowMultiple={true}
                                allowImageResize={true}
                                imagePreviewMinHeight="50"
                                imagePreviewMaxHeight="50"
                              //  maxFileSize={'3072KB'}
                                acceptedFileTypes={["image/*", "application/pdf"]}
                                onupdatefiles={fileItems => {
                                  this.vehicleupload(fileItems, "RHS");
                                }}
                                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                              />
                              {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label> &nbsp;&nbsp;
                              <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                            </div>}
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-4"></div>
                          <div className="col">
                            {this.state.img && this.state.img.RHS}
                          </div>
                        </div>
                      </div>
                      ) : null}
                      {json.length > 0 && (
                        <div>
                          {this.state.global && (
                            <div className="row form-group">
                              {/* <div className="col-sm-2" />
                              <div className="col-sm-2" /> */}
                              <div className="col text-center">
                                {[1, 2, 3].includes(this.state.usertypelog?.orderBy) ? (<button
                                  type="button"
                                  className="btn btn-primary"
                                  disabled={this.state.btnDisable}
                                  onClick={
                                    button == "Save" ? this.formSubmit : this.update1
                                  }

                                >
                                  {this.state.btnDisable ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;
                                  {button == "Save" ? "Save" : "Update"}

                                </button>) : button == "Save" ? (<button
                                  type="button"
                                  className="btn btn-primary"
                                  disabled={this.state.btnDisable}
                                  onClick={
                                    button == "Save" ? this.formSubmit : this.update1
                                  }

                                >
                                  {this.state.btnDisable ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;
                                  {button == "Save" ? "Save" : "Update"}

                                </button>) : null}
                              </div>
                            </div>
                          )}
                          {this.state.global && (
                            <div className="row form-group">
                              <div className="col-sm-2" />
                              <div className="col-sm-2" />
                              <div className="col-sm-5">
                                {/* {this.state.button == "Save" && (
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    //onClick={this.update}
                                    //onClick={this.formSubmit}
                                    onClick={
                                      button == "Save" ? this.formSubmitPdf : null
                                    }
                                  >
                                    {button == "Save" ? "Save and Export PDF" : null}
                                  </button>
                                )} */}
                              </div>
                            </div>
                          )}
                          {/* {this.state.global == false && (
                            <div className="row form-group">
                              <div className="col-sm-2" />
                              <div className="co  l-sm-2" />
                              <div className="col-sm-5">
                                <button
                                  style={{ marginLeft: "200px" }}
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => this.print()}
                                >
                                  Print
                                </button>
                              </div>
                            </div>
                          )} */}
                        </div>

                      )}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment >
    );
  }
}

export default Delivery_Note_Seller_Proof;
