import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import UpdateStatus from "../SalesMaster/UpdateStatus";
import moment from "moment";
import LoginModal from "../../../../components/Modal/Modal";
import UsertypeComments from "../UserMaster/UsertypeComments";
import Datatable from "../../../../components/Datatable/Datatable";

class PocBuyerSpecification extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      customerId: localStorage.getItem("userId"),
      LocationID: localStorage.getItem(`LocationID`),
      EnquiryData: [],
      alertVisible: false,
      column: [
        {
          Header: "Prospect",
          accessor: "name"
        },
        {
          Header: "Contact",
          accessor: "mobile"
        },
        {
          Header: "Sales",
          accessor: "userName"
        },
        {
          Header: "Location",
          accessor: "Location"
        },
        {
          Header: "Budget",
          accessor: "Budget"
        },
        {
          Header: "Next-Followup",
          accessor: "followup"
        },
        {
          Header: "Update Status",
          accessor: "status",
          Cell: d => this.UpdateSTatus(d)
        },
        {
          Header: "Specification-Sheet",
          accessor: "mobile",
          Cell: d => this.Specification(d)
        },
        {
          Header: "Comments",
          accessor: "mobile",
          Cell: d => this.UpdateSTatus1(d)
        },
        {
          Header: "Approval",
          accessor: "mobile",
          Cell: d => this.Approval(d)
        },

      ],
      EditData: {},
      IsClose: false

    }
  }

  UpdateSTatus1 = (d) => {
    return (<button type="button" className="btn btn-warning"
      data-toggle="modal" data-target={"#comment"}
      onClick={() => this.Editdata2(d)}
    >View Comments</button>);
  }

  Editdata2 = async (d) => {

    this.setState({ stage: "buyerenquery", CustomerId: d.original.id })
  }


  Specification = (d) => {
    //  if(d.original.FormFilled=='yes'||d.original.FormFilled=='approved'){
    return (<button type="button" className="btn btn-primary"
      onClick={() => this.F_proofForm(d)}
    >View Form</button>)
    // }else{
    //   return(<button type="button" className="btn btn-warning"
    //   onClick={()=>this.F_proofForm(d)}
    //   >Fill Form</button>)
    // }

  }

  UpdateSTatus = (d) => {
    return (<button type="button" className="btn btn-warning"
      data-toggle="modal" data-target={"#status"}
      onClick={() => this.Editdata(d)}
    >update</button>)
  }

  Editdata = async (d) => {
    this.setState({ EditData: d.original, isEdit: true, Index: d.index })
  }

  F_proofForm = async (d) => {
    window.open(`/DFCars/AT_Specifications_Sheet_Proof/${d.original.id}`, '_blank')
  }

  Approval = (d) => {
    //console.log(d.original);
    if (d.original.price_approval == '1') {
      //   return(<button type="button" className="btn btn-success"
      //  // onClick={()=>this.F_proofForm3(d)}
      //   >Approved</button>)
      return (<span className="label">Approved</span>)
    } else {
      return (<button type="button" className="btn btn-primary"
        onClick={() => this.F_proofForm3(d)}
      >Waiting for Approval</button>)
    }
  }
  F_proofForm3 = async (d) => {
    try {

      let arr = {};
      arr.price_approval = 1;

      const result = await cmsContent.updateMaster(
        "tbl_Specifications_Sheet_Proof",
        d.original.value,
        arr
      );
      if (result) {
        console.log(result);
        let newData = [];
        // newData[d1.index].approval=sub.approval;
        this.state.Data.map((ival, i) => {
          if (ival.id != d.original.id) {
            newData.push(ival);
          }
        })
        this.setState({
          Data: newData
        })
      }

    } catch (error) {
      console.log(error);
    }
  }

  async componentDidMount() {
    try {

      let Buyer = await cmsContent.getFreedom(
        'tbl_dfcarz_prosperities.*,tbl_location.name as Location,tbl_user_web.userName,tbl_Specifications_Sheet_Proof.id as value,tbl_Specifications_Sheet_Proof.price_approval',
        'tbl_dfcarz_prosperities,tbl_Specifications_Sheet_Proof,tbl_user_web,tbl_location',
        `tbl_dfcarz_prosperities.location = tbl_location.id and tbl_user_web.id = tbl_dfcarz_prosperities.salesid and tbl_dfcarz_prosperities.type='buyer' and tbl_Specifications_Sheet_Proof.customerid = tbl_dfcarz_prosperities.id`,
        1,
        `tbl_dfcarz_prosperities.id DESC`
      )
      if (Buyer) {
        console.log(Buyer);
        Buyer.data.map((ival, i) => {
          ival.FormFilled = 'no';
          ival.followup = moment(ival.followup).format("YYYY-MM-DD");
        })
        // let result = await cmsContent.getFreedom(
        //     `*`,
        //     `tbl_Specifications_Sheet_Proof`,
        //       1
        // );
        // Buyer.data.map((ival,i)=>{
        //     result.data.map((jval,j)=>{
        //       if(ival.id==jval.customerid && jval.approval==1){
        //           ival.FormFilled='approved';
        //       }else if(ival.id==jval.customerid){
        //         ival.FormFilled='yes';
        //       }
        //     })
        //    })

        this.setState({ Data: Buyer.data })
      }

    } catch (error) {
      console.log(error)
    }
  }

  changeModelstate = async (s, v) => {
    this.setState({ [s]: v });
  }


  render() {

    return (<React.Fragment>
      <main className="main my-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h1>Buyer Report</h1>
                </div>
                <div className="card-body">
                  <div className="row form-group">
                    <div className="col-sm-4">
                      <LoginModal
                        buttonTitle="Add Enquiry"
                        title="Update Status"
                        id="status"
                        extraClass="btn btn-primary"
                        onClick={this.reset}
                        buttonStyle={{ backgroundColor: "white", border: "white" }}
                        bodyText={
                          <UpdateStatus
                            EditData={this.state.EditData}
                            these={this.changeModelstate}
                            isEdit={this.state.isEdit}
                            FullData={this.state.Data}
                            IsClose={this.state.IsClose}
                            Index={this.state.Index}
                          />}
                      />
                    </div>
                    <div className="col-sm-4">
                      <LoginModal
                        buttonTitle="Add Enquiry"
                        title="Update Commets"
                        id="comment"
                        extraClass="btn btn-primary"
                        onClick={this.reset}
                        extraStyle={{ width: '1000px' }}
                        buttonStyle={{ backgroundColor: "white", border: "white" }}
                        bodyText={
                          <UsertypeComments
                            stage={this.state.stage}
                            customerid={this.state.CustomerId}
                          />}
                      />
                    </div>
                  </div>
                  {/* <div className="row form-group"> */}

                  {/* </div> */}
                  <div className="card-body">
                    {this.state.Data.length ? (
                      <Datatable
                        data={this.state.Data}
                        columnHeading={this.state.column}
                      />
                    ) : (<div style={{ textAlign: "center" }} ><b>No Data Found</b></div>)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>)
  }
}
export default PocBuyerSpecification;