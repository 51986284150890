import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import SingleSelect from "../../../../components/Form/SingleSelect";
//import DfCarsEnquiry from "./DfCarsEnquiry";
import UpdateComments from "./UpdateComments";
import LoginModal from "../../../../components/Modal/Modal";
import moment, { relativeTimeRounding } from "moment";
import { circle_ok } from 'react-icons-kit/ikons/circle_ok'
import Datatable from "../../../../components/Datatable/Datatable";
//import Fproofadd from "./Fproofpdf";
import { ACCESS_POINT } from "../../../../config/index"
import { PDFDownloadLink } from "@react-pdf/renderer";
import CmsContent from "../../../../MiddleWare/CmsContent";
import UsertypeComments from "../UserMaster/UsertypeComments";
// import Fproofpdf from "./Fproofpdf"
import { Icon } from 'react-icons-kit'
import { calendar_ok } from 'react-icons-kit/ikons/calendar_ok'
import { calendar } from 'react-icons-kit/ikons/calendar'

class DfCarsFproof extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      FullData: [],
      dateVisible: false,
      ftd: null,
      data4: [],
      Datas: [],
      data1: [],
      dashboard: [{ "fill": 0, "view": 0, "Live": 0, "today": 0, "MTD": 0 }],
      data2: [],
      data3: [],
      MakeOptions: [],
      ftdDashDate: moment(new Date()).format("YYYY-MM-DD"),
      usertypelog: JSON.parse(localStorage.getItem("usertypelog")),
      ftdval: false,
      coloropt: [],
      variantopt: [],
      StatusEditData: [],
      modelopt: [],
      dataof: [],
      docval: [],
      datalive: [],
      User: JSON.parse(localStorage.getItem("userlog")),
      LocationID: localStorage.getItem(`LocationID`),
      EnquiryData: [],
      status1options: [{ value: 0, label: "Live" }, { value: 1, label: "Closed" }, { value: 2, label: "Deal Closed" }],
      status2options: [],
      status3options: [],
      status1: null,
      status2: null,
      status3: null,
      followJson1: [{
        date: "",
        status1: "",
        status2: "",
        status3: "",
        remarks: "",
        follow: ""
      }],
      alertVisible: false,
      loading: false,
      column: [
        {
          Header: "Evaluation F-proof",
          accessor: "mobile",
          minWidth: 150,
          Cell: d => this.ViewLead(d)
        },
        {
          Header: "View Status",
          accessor: "Auction",
          minWidth: 200,
          Cell: d => this.Status(d)
        },
        {
          Header: "Name",
          accessor: "name"
        },
        {
          Header: "Mobile",
          accessor: "mobile",
          Cell: d => this.Call(d.original.mobile)
        },
        {
          Header: "Make",
          accessor: "makeName",
          // Cell: (d) => this.getValueFromArray(d.original.make, this.state.MakeOptions)

        },
        {
          Header: "Model",
          accessor: "modelName",
          // Cell: (d) => this.getValueFromArray(d.original.model, this.state.modelopt)
        },
        {
          Header: "Variant",
          accessor: "variantName",
          // Cell: (d) => this.getValueFromArray(d.original.variant, this.state.variantopt)

        },
        {
          Header: "Color",
          accessor: "colourName",
          // Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)

        },
        {
          Header: "Source",
          accessor: "sources"
        },
        {
          Header: "Evaluation Date",
          accessor: "followup_date",
          className: "text-center",
          minWidth: 140,
        },
        // {
        //   Header: "Price in Rs.",
        //   accessor: "Budget"
        // },
        {
          Header: "Data Type",
          accessor: "Auction",
          minWidth: 140,
          Cell: (d) => this.Auction(d.original),
        },

        {
          Header: "Print PDF",
          accessor: "pdf",
          minWidth: 115,
          Cell: d => this.viewpdf(d)
        },
        // {
        //   Header: "Push Auction",
        //   accessor: "auction",
        //   Cell: d => this.auction(d)
        // },
        // {
        //   Header: "Evaluation B-proof",
        //   accessor: "mobile",
        //   Cell: d => this.ViewLead1(d)
        // },
        // {
        //   Header: "Print PDF",
        //   accessor: "pdf",
        //   Cell: d => this.viewbpdf(d)
        // },
        {
          Header: "Status 1",
          accessor: "mobile",
          minWidth: 140,
          Cell: d => this.Status1(d)
        },
        // {
        //   Header: "Approval",
        //   accessor: "mobile",
        //   Cell: d => this.Approval(d)
        // },
        // {
        //   Header: "Raise Enquiry",
        //   accessor: "type",
        //   Cell: d => this.Comments(d)
        // },
        // {
        //   Header: "Comments",
        //   accessor: "mobile",
        //   Cell: (d) => this.UpdateSTatus(d),
        // },
      ],
      excelColumn: [
        {
          label: "Name",
          key: "name"
        },
        {
          label: "Mobile",
          key: "mobile",
        },

        {
          label: "Make",
          key: "makeName",
          // Cell: (d) => this.getValueFromArray(d.original.make, this.state.MakeOptions)

        },
        {
          label: "Model",
          key: "modelName",
          // Cell: (d) => this.getValueFromArray(d.original.model, this.state.modelopt)
        },
        {
          label: "Variant",
          key: "variantName",
          // Cell: (d) => this.getValueFromArray(d.original.variant, this.state.variantopt)

        },
        {
          label: "Color",
          key: "colourName",
          // Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)

        },
        {
          label: "Source",
          key: "sources"
        },
        {
          label: "Evaluation Date",
          key: "followup_date",
        },
        {
          label: "Data Type",
          key: "Auction_Status",
        },
      ],

      evalcolumn: [
        // {
        //   Header: "Prospect",
        //   accessor: "name",
        // },
        // {
        //   Header: "Contact",
        //   accessor: "mobile",
        // },
        {
          Header: "Form",
          accessor: "mobile",
          Cell: (d) => this.Document(d),
        },
        {
          Header: "Register No",
          accessor: "regno",
        },
        {
          Header: "evaluator name ",
          accessor: "jobroleName",

        },
        {
          Header: "Evaluation Date",
          accessor: "created_At"
        },
        {
          Header: "Make",
          accessor: "makeName",
          // Cell: (d) => this.getValueFromArray(d.original.make, this.state.MakeOptions)
        },
        {
          Header: "Model",
          accessor: "modelName",
          // Cell: (d) => this.getValueFromArray(d.original.model, this.state.modelopt)
        },
        {
          Header: "Variant",
          accessor: "variantName",
          // Cell: (d) => this.getValueFromArray(d.original.variant, this.state.variantopt)
        },

        {
          Header: "Color",
          accessor: "colourName",
          // Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)
        },
        {
          Header: "Source",
          accessor: "sources"
        },
        // {
        //   Header: "Price in Rs.",
        //   accessor: "Budget",
        // },

      ],
      excelEvalColumn: [
        {
          label: "Register No",
          key: "regno",
        },
        {
          label: "evaluator name ",
          key: "jobroleName",
        },
        {
          label: "Evaluation Date",
          key: "created_At"
        },
        {
          label: "Make",
          key: "makeName",
          // Cell: (d) => this.getValueFromArray(d.original.make, this.state.MakeOptions)
        },
        {
          label: "Model",
          key: "modelName",
          // Cell: (d) => this.getValueFromArray(d.original.model, this.state.modelopt)
        },
        {
          label: "Variant",
          key: "variantName",
          // Cell: (d) => this.getValueFromArray(d.original.variant, this.state.variantopt)
        },

        {
          label: "Color",
          key: "colourName",
          // Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)
        },
        {
          label: "Source",
          key: "sources"
        },
      ],
      EditData: {},
      Index: null,
    }
    this.vals()
  }
  async vals() {
    let allOpt = await this.decryption('getcar')
    if (allOpt) {
      this.setState({
        MakeOptions: allOpt.makeopt,
        coloropt: allOpt.coloropt,
        variantopt: allOpt.variantopt,
        modelopt: allOpt.modelopt
      })
    }

  }
  UpdateSTatus = (d) => {
    return (
      <button
        type="button"
        className="btn btn-warning btn-sm"
        data-toggle="modal"
        data-target={"#comments"}
        onClick={() => this.Editdata(d)}
      >
        View Comments
      </button>
    );
  };
  Document = (d) => {
    if (d.original.FormFilled == "yes" || d.original.FormFilled == "approved") {
      return (
        <center>
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={() => this.Documentationform(d)}
          >
            Edit Form
          </button>
        </center>

      );
    } else {
      return (<center>
        <button
          type="button"
          className="btn btn-warning btn-sm"
          onClick={() => this.Documentationform(d)}
        >
          Fill Form
        </button>
      </center>
      );
    }
  };
  Documentationform = async (d) => {
    window.open(
      `/DFCars/AT_Booklet_Purchase_Contract_Proof/${d.original.id}`,
      "_self"
    );
  };
  Editdata = async (d) => {
    this.setState({ stage: "Evaluation", CustomerId: d.original.id });
  };
  Comments = (d) => {
    return (
      <button type="button" className="btn btn-primary btn-sm"
        data-toggle="modal" data-target={"#status"}
        onClick={() => this.PlaceComments(d)}
      >Enquiry</button>
    )
  };
  Status1 = (d) => {
    // console.log(d);

    if (d.original.F_formfilled == 'yes' || d.original.F_formfilled == 'approved') {
      return (<center><span className="btn btn-secondary btn-sm" style={{ minWidth: "100px" }}>{d.original.status1}</span></center>)
    }
    else {
      return (
        <span className="text-center">-</span>)
    }
  }
  Auction = (d) => {
    if (d && d.Auction_Status) {
      return (
        <center>
          <span className={`btn btn-${d.Auction == 0 ? "secondary" : d.Auction == 1 ? "warning" : d.Auction == 2 ? "success" : "danger"} btn-sm text-center`}
            style={{ minWidth: "140px" }}>
            {d.Auction_Status}
          </span>
        </center>
      )
    } else {
      return (
        <center>
          <span className={`btn btn-${d.Auction == 0 ? "secondary" : d.Auction == 1 ? "warning" : d.Auction == 2 ? "success" : "danger"} btn-sm text-center`}
            style={{ minWidth: "140px" }}>
            {d.Auction_Status}
          </span>
        </center>
      )
    }
  };
  PlaceComments = async (d) => {
    this.setState({ EditData: d.original, Index: d.index })
  }
  //   var doc = new jsPDF();
  // var specialElementHandlers = {
  //     '#editor': function (element, renderer) {
  //         return true;
  //     }
  // };
  // $('#submit').click(function () {
  //     doc.fromHTML($('#content').html(), 15, 15, {
  //         'width': 190,
  //             'elementHandlers': specialElementHandlers
  //     });
  //     doc.save('sample-page.pdf');
  // });
  viewpdf = d => {
    if (d.original.status1 == 'Live') {

      return (<center ><button type="button" className="btn btn-warning btn-sm text-center" style={{ minWidth: "100px" }}
        onClick={() => this.generatepdf(d)}>Print PDF</button></center>)
    }
    else {
      if (d.original.F_formfilled == 'yes' || d.original.F_formfilled == 'approved') {
        return (<center><button type="button" className="btn btn-warning btn-sm text-center" style={{ minWidth: "100px" }}
          onClick={() => this.generatepdf(d)}>Print PDF</button></center>)
      }
      else {
        return (
          <span className="text-center">-</span>)
      }
    }
  }


  auction = d => {
    //  this.setState({amount:d.original.Bidamount})
    if (d.original.F_formfilled == 'yes' || d.original.F_formfilled == 'approved') {
      console.log(d.original);
      if (!d.original.Bidamount) {
        return (
          <LoginModal
            buttonTitle="Push To Auction"
            title="Push To Auction"
            id="auction"
            extraClass="btn btn-primary btn-sm"
            onClick={this.reset}
            //  buttonStyle={{ backgroundColor: "white", border: "white" }}
            bodyText={
              <div>
                <div className="card-body">
                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-7">
                      <Alert
                        className="badge-content"
                        color={this.state.color}
                        isOpen={this.state.alertVisible}
                        toggle={this.onDismiss}
                      >
                        {this.state.textalert}
                      </Alert>
                    </div>
                    <div className="col-sm-3" />
                  </div>

                  <div className="row form-group">
                    <div className="col-sm-1" />
                    <div className="col-sm-3">
                      <label htmlFor="exampleInputEmail1">
                        Bid Amount
                      </label>
                    </div>
                    <div className="col-sm-5">
                      <input
                        type="text"
                        className="form-control"
                        // id="registerName"
                        name="amount"
                        placeholder="Enter Amount"
                        value={this.state.amount}
                        onChange={this.handlechangeData}
                      />
                    </div>
                    <div className="col-sm-3" />
                    <div className="row-form-group">

                      <div className="col-sm-2" />
                      <div className="col-sm-3" />
                      <div className="col-sm-4">
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                          onClick={() => this.submitauction(d)}
                          style={{ marginLeft: "250px" }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          />)
      } else {
        return (
          <div>
            <span className="label">Pushed</span>
          </div>
        )
      }

    }
    else {
      return (
        <span className="label" style={{ backgroundColor: "white" }}></span>)
    }
  }

  onDismiss = () => {
    this.setState({ alertVisible: false, formAlertdelete: false });
  };
  submitauction = async d => {
    const { amount } = this.state;
    console.log(d);
    let id = await d.original.id;

    const fproofid = await CmsContent.getFreedom("*", "tbl_FProof", `customerid=${id}`, 1, 1);
    let CategoryArray = {};
    CategoryArray.Bidamount = parseInt(amount);
    CategoryArray.Auction = 1;
    // console.log(CategoryArray);
    try {
      let result = await CmsContent.updateMaster(
        "tbl_FProof",
        fproofid.data[0].id,
        CategoryArray
      );
      if (result) {
        this.setState({
          amount: "",
          alertVisible: true,
          color: "success",
          textalert: "Success",

        })
      }
    } catch (error) {
      console.log(error);
    }

  }
  handlechangeData = async e => {
    //  if(e.target.type=="date"){
    console.log(e.target.value);
    //  }
    console.log(e.target.name);
    this.setState({ [e.target.name]: e.target.value });
  };
  generatepdf = async (d) => {
    console.log(d.original.id)

    const fproofid = await CmsContent.getFreedom("*", "tbl_FProof", `customerId=${d.original.id}`, 1, 1);
    console.log(fproofid.data[0].customerid, 'cusid');
    if (fproofid) {
      let fproofpdf = await CmsContent.Fproofpdf("tbl_FProof", fproofid.data[0].customerid);
      console.log(fproofpdf, "fproofpdf")
      if (fproofpdf.data == "success") {
        console.log('pdf');
        window.open(`${ACCESS_POINT}/cmsContent/downloadfproof/${fproofid.data[0].customer_name}`, '_self')
      }
      // setTimeout(() => {
      //   this.generatebpdf(d)
      // }, 3000);
    }

  }

  viewbpdf = d => {
    if (d.original.B_formfilled == 'yes' || d.original.B_formfilled == 'approved') {
      return (<button type="button" className="btn btn-warning btn-sm"
        onClick={() => this.generatebpdf(d)}>Print PDF</button>)
    }
    else {
      return (
        <span className="label" style={{ backgroundColor: "white" }}></span>)
    }
  }
  generatebpdf = async (d) => {
    const bproofid = await CmsContent.getFreedom("*", "tbl_BProof", `customerId=${d.original.id}`, 1, 1);
    console.log(bproofid)
    if (bproofid) {
      let bproofpdf = await CmsContent.bproof("tbl_BProof", bproofid.data[0].customerid);
      //console.log(bproofpdf)
      if (bproofpdf.data == "success") {
        window.open(`${ACCESS_POINT}/cmsContent/downloadbproof/${bproofid.data[0].id}`, '_self')
      }
    }
  }

  ViewLead = (d) => {
    if (d.original.F_formfilled == 'yes' || d.original.F_formfilled == 'approved') {
      return (<center><button type="button" className="btn btn-primary btn-sm text-center"
        onClick={() => this.F_proofForm(d)}
        style={{ minWidth: "100px" }}
      >View Form</button></center>)
    } else {
      return (<center><button type="button" className="btn btn-warning btn-sm text-center"
        onClick={() => this.F_proofForm(d)}
        style={{ minWidth: "100px" }}
      >Form</button></center>)
    }

  }
  ViewLead1 = (d) => {
    if (d.original.B_formfilled == 'yes' || d.original.B_formfilled == 'approved') {
      return (<button type="button" className="btn btn-primary btn-sm text-center"
        onClick={() => this.B_proofForm(d)}
        style={{ minWidth: "100px" }}
      >View Form</button>)
    } else {
      return (<button type="button" className="btn btn-warning btn-sm text-center"
        onClick={() => this.B_proofForm(d)}
        style={{ minWidth: "100px" }}
      >Form</button>)
    }

  }

  B_proofForm = async (d) => {
    //console.log(d);
    window.open(`/DFCars/Evaluation_Checklist_B_Proof/${d.original.id}`, '_self')
  }

  F_proofForm = async (d) => {
    //console.log(d);
    window.open(`/DFCars/Evaluation_Checklist_F_Proof/${d.original.id}`, '_self')
  }

  Status = (d) => {

    if (d.original && d.original.status == "active" && d.original.F_formfilled == 'yes' || d.original.F_formfilled == 'approved' && d.original.Auction) {
      if (d.original.Auction == 1 && d.original.status1 == "Live") {
        return (
          <center>
            <button
              type="button"
              className="btn btn-info btn-sm"
              data-toggle="modal"
              style={{ minWidth: "150px" }}
              data-target={"#matchcarmodal"}
              onClick={() => this.StatusData(d)}
            >
              In Auction
            </button>
          </center>
        );

      }
      else if (d.original.Auction == 1 && d.original.status1 == "Deal Closed") {
        return (<center>
          <button
            type="button"
            className="btn btn-info btn-sm"
            data-toggle="modal"
            style={{ minWidth: "150px" }}
            data-target={"#matchcarmodal"}
            onClick={() => this.StatusData(d)}
          >
            In Auction
          </button>
        </center >
        );

      }
      else if (d.original.Auction == 2 && d.original.status1 == "Live") {
        return (
          <center>

            <button
              type="button"
              className="btn btn-info btn-sm"
              data-toggle="modal"
              style={{ minWidth: "150px" }}
              data-target={"#matchcarmodal"}
              onClick={() => this.StatusData(d)}
            >
              View Auction Completed
            </button>
          </center >
        );
      }
      else if (d.original.Auction == 2 && d.original.status1 == "Deal Closed") {
        return (<center>
          <button
            type="button"
            className="btn btn-success btn-sm"
            data-toggle="modal"
            style={{ minWidth: "150px" }}
            data-target={"#matchcarmodal"}
            onClick={() => this.StatusData(d)}
          >
            View Auction Completed
          </button>
        </center >
        );
        // return (<span className="btn btn-sm text-center btn-success" style={{ minWidth: "150px" }}>Auction Completed</span>

        // );
      }
      else if (d.original.Auction == 3) {
        return (<center>
          <span className="btn btn-sm text-center btn-danger" style={{ minWidth: "150px" }}>Auction Rejected</span></center >

        );
      }
      else if (d.original.Auction == 4) {
        return (<center>
          <span className="btn btn-sm text-center btn-danger" style={{ minWidth: "150px" }}>Auction Rejected</span></center >

        );
      }
      else if (d.original.Auction == 0) {
        return (
          <center>

            <button
              type="button"
              className="btn btn-primary btn-sm"
              data-toggle="modal"
              style={{ minWidth: "150px" }}
              data-target={"#matchcarmodal"}
              onClick={() => this.StatusData(d)}
            >
              View
            </button>
          </center >
        );
      }
      else {
        return (
          <center>

            <button
              type="button"
              className="btn btn-primary btn-sm"
              data-toggle="modal"
              style={{ minWidth: "150px" }}
              data-target={"#matchcarmodal"}
              onClick={() => this.StatusData(d)}
            >
              View
            </button>
          </center >
        );
      }
    }
    if (d.original.status == "active" && d.original.F_formfilled == 'yes' || d.original.F_formfilled == 'approved') {
      return (
        <center>

          <button
            type="button"
            className="btn btn-primary btn-sm"
            data-toggle="modal"
            style={{ minWidth: "150px" }}
            data-target={"#matchcarmodal"}
            onClick={() => this.StatusData(d)}
          >
            View
          </button>
        </center >
      );
    }
    else if (d.original.status == "active") {
      return (<center>
        <span className="btn btn-sm text-center btn-success" style={{ minWidth: "150px" }}>Active</span></center >)
    }
    else {
      return (<center>
        <span className="btn btn-sm text-center btn-danger" style={{ minWidth: "150px" }}>InActive</span></center >)
    }
  }
  StatusData = (d) => {
    console.log(d.original, 'd');
    this.setState({ StatusEditData: d.original })
    this.form_filled(d)
  }
  Approval = (d) => {
    // console.log(d.original)
    if (d.original.F_formfilled == 'approved' || d.original.B_formfilled == 'approved') {
      // return (<button type="button" className="btn btn-success"
      // >Approved</button>)
      return (<span className="label">Approved</span>)
    } else if (d.original.F_formfilled == 'yes' && d.original.B_formfilled == 'yes') {
      // return (<button type="button" className="btn btn-danger"
      // >Waiting for Approval</button>)
      return (<span className="label">Waiting for Approval</span>)
    } else if (d.original.F_formfilled == 'no' || d.original.B_formfilled == 'no') {
      // console.log(d.original);
      // return (<button type="button" className="btn btn-primary"
      // >Fill the form</button>)
      return (<span className="label">Fill the form</span>)
    }
    //if(d.original.F_formfilled=='no')
  }
  MainFunction = async () => {
    try {
      this.setState({ Datas: [] })
      let results = await cmsContent.BUYDFCARZ(this.state.User.id, 'evaluation')
      console.log("data results", results);
      let dd = new Date();
      // console.log(dd);
      let DA = await dd.getDate();
      if (DA < 10) {
        DA = await "0" + DA
      }
      let DT = await dd.getMonth() + 1;
      let DT1 = DT
      if (DT < 10) {
        DT = await "0" + DT
      }
      let YR = await dd.getFullYear();
      let FullDate1 = await YR + "-" + DT + "-" + DA
      let FullDate = await DA + "-" + DT + "-" + YR

      let fulldata = []
      if (results && results.Evaluation_Prospective) {
        fulldata = results.Evaluation_Prospective;
      }

      await this.setState({
        Data: results.Evaluation_Prospective,
        // Evalution
        data3: results.docval ? results.Evaluation_Prospective.filter(e => e.filterEvalution == true) : [],
        // MTD
        dataof: results.docval ? results.Evaluation_Prospective.filter(e => e.filterMTD == true) : [],
        // live..
        data1: results.docval ? results.Evaluation_Prospective.filter(e => e.filterlive == true) : [],
        // docs pending
        docval: results.docval ? results.docval : [],
        // Dashboard 
        dashboard: results.data_for_f,
        loading: false,
        FullDate, FullDate1,

      })
    } catch (error) {
      await this.setState({ loading: false })
      console.log(error);
    }
  }
  async componentDidMount() {
    try {
      this.setState({ loading: true });
      this.MainFunction()

      console.log(JSON.parse(localStorage.getItem("userlog")))

    } catch (error) {
      console.log(error);
    }
    // //  console.log(Product)
  }
  changeModelstate = async (s, v) => {
    this.setState({ [s]: v });
  }
  edash = () => {
    return (
      <div className="card">
        {/* < div className="row" > */}
        <div className="card-header">
          <div className="col-sm-6" >
            <h2 className="mt-2 black">Dashboard</h2>
          </div>
        </div>
        <div className="card-body">
          <div className=''>
            <div className="row col-12 mt-2 text-center">
              <div className="col ">
                <div className="dashboardCard1" onClick={() => this.onClk(1)} >
                  <Icon icon={calendar_ok} style={{ color: 'green' }} size={35} />
                  <h4 className="mt-2 black" >Scheduled Evaluation</h4>
                  <div >
                    <h1 className="bold mt-4 mb-2 black" style={{cursor:"pointer"}}><b> {this.state.dashboard[0].view ? this.state.dashboard[0].view : 0}</b></h1>
                  </div>
                </div>
              </div>

              <div className="col ">
                <div className="dashboardCard1" onClick={() => this.onClk(3)} >
                  <Icon icon={calendar} style={{ color: '#2A9D8F' }} size={35} />
                  <h4 className="mt-2 black" >MTD Evaluation</h4>
                  <div >
                    <h1 className="bold mt-4 mb-2 black" style={{cursor:"pointer"}}><b>{this.state.dashboard[0].MTD ? this.state.dashboard[0].MTD : 0}</b></h1>
                  </div>
                </div>
              </div>

              <div className="col ">
                <div className="dashboardCard1" onClick={() => this.onClk(6)} >
                  <Icon icon={calendar_ok} style={{ color: 'red' }} size={35} />
                  <h4 className="mt-2 black" >Live Evaluation</h4>
                  <div >
                    <h1 className="bold mt-4 mb-2 black" style={{cursor:"pointer"}}><b>{this.state.dashboard[0].Live ? this.state.dashboard[0].Live : 0}</b></h1>
                  </div>
                </div>
              </div>
              <div className="col " >
                <div className="dashboardCard1" onClick={() => this.onClk(7)}>
                  <Icon icon={circle_ok} size={35} style={{ color: 'green' }} />
                  <h4 className="mt-2 black" >Docs pending</h4>
                  <div>
                    <h1 className="bold mt-4 mb-2 black" style={{cursor:"pointer"}}><b>{this.state.dashboard[0].docval ? this.state.dashboard[0].docval : 0}</b></h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>)
  }
  cng = async (e) => {
    this.setState({ ftdval: true })
    console.log(e.target.value);
    let { usertypelog } = this.state
    // console.log(usertypelog.orderBy);
    let d = e.target.value
    let d1 = {}
    d1.date = e.target.value
    d1.orderBy = usertypelog.orderBy
    // d1.id = this.state.UserId
    d1.locationId = this.state.LocationID
    // console.log(d1, 'd1');
    let result = await cmsContent.GetFTD(d, d1)
    if (result.data.length) {
      console.log(result, "ftddate");
      let v8 = 0;
      let data2 = []
      result.data.map((ival, i) => {
        if (ival.createdAt) {
          let date11 = new Date(ival.createdAt)
          let d1 = date11.getDate()
          let m1 = date11.getMonth() + 1
          let y1 = date11.getFullYear()
          if (m1 < 10) {
            m1 = "0" + m1
          }
          let date2 = y1 + "-" + m1 + "-" + d1
          if (d && date2) {
            // console.log("enterr");
            v8 = ++v8;
          }
        }
      })
      // console.log(v3 && v3);
      await this.setState({ ftd: v8, Datas: result.data, ftdDashDate: d })
    }
    else {
      // console.log("olkkkkkkk");
      let data = []
      await this.setState({ ftd: "0", Datas: data })
      console.log(this.state.ftd, "ftddd");
    }
    window.scroll({
      top: 400,
      left: 0,
      behavior: "smooth",
    });

  }
  onClk = (val) => {
    if (val === 1) { this.setState({ Datas: this.state.data3, dateVisible: false, Eval: false }) }
    if (val === 3) { this.setState({ Datas: this.state.dataof, dateVisible: false, Eval: false }) }
    if (val === 4) { this.setState({ Datas: this.state.data2, dateVisible: false, Eval: false }) }
    //if (val === 5) { this.setState({ Data: this.state.data3, dateVisible: false }) }
    if (val === 6) { this.setState({ Datas: this.state.data1, dateVisible: false, Eval: false }) }
    if (val === 7) { this.setState({ Datas: this.state.docval, dateVisible: false, Eval: true }) }
    if (val === 8) { this.setState({ Datas: this.state.ftddatas, Eval: false }) }
    //if (val === 2) { this.setState({ dateVisible: true, Data: [] }) }
    window.scroll({
      top: 400,
      left: 0,
      behavior: "smooth",
    });
  }
  form_filled = async (d) => {
    let ans = d.original;
    let followJson1 = this.state.followJson1;
    if (ans.status1) {
      let followJson1 = this.state.followJson1;
      let w1 = await this.state.status1options.map(async (ival, i) => {
        if (ival.label == ans.status1) {
          await this.ans(ival, 0, "status1")
        }
      })
      await Promise.all(w1)
    }
    if (ans.status2 && this.state.status2options && this.state.status2options[0]) {
      let w = await this.state.status2options[0].map(async (ival, i) => {
        if (ival.label == ans.status2) {
          await this.ans(ival, 0, "status2")
        }
      })
      await Promise.all(w)
    }
    if (ans.follow) {
      let follow = JSON.parse(ans.follow);
      // console.log(follow, 'folloe')
      followJson1[0].remarks = follow.length ? follow[0].remarks : ''
      followJson1[0].date = follow.length ? follow[0].date : ''
      followJson1[0].follow = follow.length ? follow[0].follow : ''
      this.setState({ followJson1 })
    }
  }
  ans = (label, value, type, index = null) => {
    console.log(label, value, type, index, 'label, i, type, index');

    let followJson1 = this.state.followJson1;
    followJson1[value][type] = label;
    let status2options = [];
    let status3options = [];
    this.setState({ followJson1 });
    if (type == "status1") {
      followJson1[value].status2 = followJson1[value].status2 != "" && ""
      this.setState({ followJson1 })
      if (followJson1[value].status1 && followJson1[value].status1.label == "Live") {
        status2options[value] = [
          //{ label: "Appointment taken", value: 0 },
          { label: "Under Follow up", value: 1 },
          { label: "Ringing no response", value: 2 },
          { label: "Researching", value: 3 },
          { label: "Not attempted", value: 4 }];
        status3options[value] = [{ label: "Negotitation", value: 0 }, { label: " consideration", value: 1 }]
        this.setState({ status2options, status3options })
        //   this.setState({status2options:[{label:"Appointment taken",value:0},

      } else if (followJson1[value].status1 && followJson1[value].status1.label == "Closed") {
        status2options[value] = [
          { label: "Lost", value: 2 },
          { label: 'Dropped', value: 3 }

        ];
        this.setState({ status2options })
      } else if (followJson1[value].status1 && followJson1[value].status1.label == "Deal Closed") {
        status2options[value] = [
          { label: "Trade in", value: 0 },
          { label: "Docs pending", value: 1 },
          { label: "Awaiting NC delivery", value: 2 },
          { label: "Physically Recieved", value: 3 },
        ];
        followJson1[value].status2 = { label: "Trade in", value: 0 }
        this.setState({ status2options })
        this.setState({ followJson1 })
      }
    }
    if (type == "status2") {
      // this.setState({ followJson1 });
      followJson1[value].status3 = followJson1[value].status3 != "" && ""
      this.setState({ followJson1 })
      if (followJson1[value].status2 && followJson1[value].status2.label == "Lost") {
        status3options[value] = [
          { label: "Competitor", value: 0 },
          { label: "Co Dealer", value: 1 },
          { label: "End Customer", value: 2 },
          { label: "User Car Dealer", value: 3 },
          { label: "Online Aggression", value: 4 },
        ];
        this.setState({ status3options });
      }
    };

    console.log(followJson1);
  };
  StatusUpdate = async (StatusEditData) => {

    const { followJson1 } = this.state;

    let groupArray = {}
    let groupArray1 = {}
    const Fid = this.state.StatusEditData.Fid
    const Bid = this.state.StatusEditData.Bid
    groupArray.status1 = followJson1[0].status1 ? followJson1[0].status1.label : null;
    groupArray.status2 = followJson1[0].status2 ? followJson1[0].status2.label : null;
    if (followJson1[0].status1.label == "Deal Closed" && followJson1[0].status2.label == "Trade in") {
      groupArray.gotoapproval = 1
    } else {
      groupArray.gotoapproval = 0
    }
    groupArray1.follow = JSON.stringify(followJson1)
    console.log(groupArray1, Fid);
    console.log(groupArray);
    console.log('log', Fid, Bid);
    this.setState({ statusbtnDisable: true })
    try {
      let result = await CmsContent.updateMaster("tbl_FProof", Fid, groupArray);
      let Bproof = await CmsContent.updateMaster("tbl_BProof", Bid, groupArray1);

      console.log(result, Bproof);
      if (result && Bproof) {
        await this.setState({
          alertVisible: true,
          statusbtnDisable: false,
          textalert: "Your form has been updated successfully",
          color: "success",
          StatusEditData: null
        });
        // window.location.reload()
        setTimeout(() => this.setState({ alertVisible: false }), 2000)
        this.MainFunction()
        const elements = document.getElementsByClassName('modal-backdrop');
        elements[0].parentNode.removeChild(elements[0]);
      }
    } catch (err) {
      console.log(err);
    }

  }
  statusModal = (StatusEditData) => {
    const { status1, status2, status3, followJson1 } = this.state;
    console.log(StatusEditData, "StatusEditData");
    return (
      <React.Fragment>
        <main>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1> View Status </h1>
                  </div>
                  <div className="card-body">
                    <div className="row" style={{ justifyContent: 'center' }}>
                      <div className="col-sm-7"
                      // style={{ left: "25%" }}
                      >
                        <Alert
                          className="badge-content"
                          color={this.state.color}
                          isOpen={this.state.alertVisible}
                          toggle={this.onDismiss}
                        >
                          {this.state.textalert}
                        </Alert>
                        <div  >
                          &nbsp;
                          {StatusEditData.status1 != null && <h4>Status 1: </h4>
                          } &nbsp;
                          &nbsp;
                          <SingleSelect
                            placeholder="Enter Status1"
                            options={this.state.status1options}
                            handleChange={e => {
                              this.ans(e, 0, "status1")
                            }}
                            // selectedService={followJson[index].status1}
                            selectedService={followJson1[0] && followJson1[0].status1
                              ? followJson1[0].status1
                              : null}
                            style={{ resize: "none", width: "100%" }}
                          />
                          <br />
                          {StatusEditData.status2 != null && (followJson1[0] && followJson1[0].status1 && followJson1[0].status1.label != 'Deal Closed') && < h4 > Status 2: </h4>}
                          &nbsp;
                          {followJson1[0] && followJson1[0].status1 && followJson1[0].status1.label != 'Deal Closed' ?
                            < SingleSelect
                              placeholder="Enter Status2"
                              options={this.state.status2options[0]}
                              handleChange={e => {
                                this.ans(e, 0, "status2")
                              }
                              }
                              // selectedService={followJson[index].status1}
                              selectedService={followJson1[0] && followJson1[0].status2
                                ? followJson1[0].status2
                                : null}
                              style={{ resize: "none", width: "100%" }}
                            /> : null}
                          &nbsp;
                          {/* {followJson1[0].status2 && followJson1[0].status2.label == "Lost" || followJson1[0].status2 && followJson1[0].status2.label == "Non contactable" ? (
                            <div><label>Status 3</label>
                              <SingleSelect
                                placeholder="Enter Status3"
                                options={this.state.status3options[0]}
                                handleChange={e => {
                                  this.ans(e, 0, "status3")
                                }}
                                // selectedService={followJson[index].status1}
                                selectedService={followJson1[0] && followJson1[0].status3
                                  ? followJson1[0].status3
                                  : null}
                                style={{ resize: "none", width: "100%" }}
                              /> </div>
                          ) : null} */}
                          {followJson1[0].status1 && followJson1[0].status1.label == "Live" ?
                            (<div class="container">
                              <div class="row">
                                {/* <div class="col-sm-6">
                                  <lable>Date</lable> <input
                                    type="date"
                                    id="myDate"
                                    className="form-control"
                                    onChange={e =>
                                      this.ans(e.target.value, 0, "date")
                                    }
                                    value={
                                      followJson1[0] && followJson1[0].date
                                        ? followJson1[0].date
                                        : null
                                    }
                                    // onChange={this.fromDateSelect}
                                    // value={selectedstartdate}
                                    style={{ resize: "none", width: "100%" }}
                                  />
                                </div> */}
                                <div class="col-sm-12">
                                  <h4>Follow up date: </h4>
                                  &nbsp;
                                  <input
                                    type="date"
                                    className="form-control"
                                    min={moment().format("YYYY-MM-DD")}
                                    onChange={e => this.ans(e.target.value, 0, "follow")}
                                    style={{ resize: "none", width: "100%" }}
                                    value={
                                      followJson1[0] && followJson1[0].follow
                                        ? followJson1[0].follow
                                        : null
                                    }
                                  // value={followJson[index] && followJson[index].followup ? followJson[index].followup : null}
                                  ></input> </div>
                              </div>
                            </div>
                            ) : null}
                          <br></br>
                          <h4>REMARKS:</h4>
                          &nbsp;
                          <input
                            type="textarea"
                            name="REMARKS"
                            className="form-control"
                            placeholder="Enter remarks"
                            id="remarks"
                            onChange={e => {
                              this.ans(e.target.value, 0, "remarks");
                            }}
                            // value={followJson[index] && followJson[index].remarks ? followJson[index].remarks : null}
                            value={
                              followJson1[0] && followJson1[0].remarks
                                ? followJson1[0].remarks
                                : null
                            }
                            style={{ resize: "none", width: "100%" }}
                          ></input>
                          <br />

                          {StatusEditData && StatusEditData.Auction && StatusEditData.Auction == 1 && StatusEditData.view_status == true ?
                            <h3 className="text-center" style={{ color: "#fc4c0d" }}> Auction process Already Started</h3>
                            :
                            StatusEditData && StatusEditData.Auction && StatusEditData.Auction == 1 && StatusEditData.view_status == false ?
                              <h3 className="text-center" style={{ color: "#fc4c0d" }}> Auction process Already Started</h3>
                              :
                              StatusEditData && StatusEditData.Auction && StatusEditData.Auction == 2 && StatusEditData.view_status == true ?
                                <h3 className="text-center" style={{ color: "#07278f" }}> Auction process Already Completed</h3>
                                :

                                StatusEditData && ((StatusEditData.view_status && StatusEditData.view_status == true) || StatusEditData.status1 == "Live") ?

                                  followJson1[0] && followJson1[0].status2 &&
                                  <button className="btn btn-block btn-warning" disabled={this.state.statusbtnDisable} onClick={() => this.StatusUpdate(StatusEditData)}>{this.state.statusbtnDisable ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;Update</button>
                                  :
                                  <h3 className="text-center" style={{ color: "#07278f" }}> Documentation Form Already Filled</h3>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment >
    )
  }
  render() {
    // console.log(this.state.Data);
    // console.log(this.state.followJson1, 'follow');
    let { loading, StatusEditData } = this.state;
    console.log(this.state.Datas, "Datas")
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            {this.edash()}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Evaluation Report</h1>
                  </div>
                  <div className="card-body">
                    {this.state.StatusEditData != null && <div>
                      <LoginModal
                        extraStyle={{ width: '100%' }}
                        title={StatusEditData.name}
                        id="matchcarmodal"
                        buttonStyle={{ backgroundColor: "white", border: "white" }}
                        reset={() => { this.setState({ statuschg1: false, status2visible: false, StatusEditData: [] }) }}
                        bodyText={this.statusModal(StatusEditData)}
                      />
                    </div>}
                    {/* <div className="row form-group">
                      <div className="col-sm-4">
                        <LoginModal
                          //buttonTitle="Add Enquiry"
                          title="Comments"
                          id="status"
                          extraClass="btn btn-primary"
                          onClick={this.reset}
                          buttonStyle={{ backgroundColor: "white", border: "white" }}
                          bodyText={<UpdateComments
                            FullData={this.state.Data}
                            these={this.changeModelstate}
                            EditData={this.state.EditData}
                            Index={this.state.Index}
                          />}
                        />
                      </div>
                    </div> */}
                    {/* <div className="row form-group">
                      <div className="col-sm-4">
                        <LoginModal
                          buttonTitle="Add Enquiry"
                          title="Update Commets"
                          id="comments"
                          extraClass="btn btn-primary"
                          onClick={this.reset}
                          extraStyle={{ width: "1000px" }}
                          buttonStyle={{
                            backgroundColor: "white",
                            border: "white",
                          }}
                          bodyText={
                            <UsertypeComments
                              stage={this.state.stage}
                              customerid={this.state.CustomerId}
                            />
                          }
                        />
                      </div>
                    </div> */}
                    {loading ? <div className="text-center"> <i
                      className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                    ></i></div> : this.state.Data.length ? (
                      this.state.Datas.length ? <Datatable
                        data={this.state.Datas}
                        columnHeading={this.state.Eval ? this.state.evalcolumn : this.state.column}
                        excelColumn={this.state.Eval ? this.state.excelEvalColumn : this.state.excelColumn}
                      /> : null
                    ) : (<div style={{ textAlign: "center" }} ><b>No Data Found</b></div>)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment >
    )
  }
}
export default DfCarsFproof;