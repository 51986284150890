import React, { Component } from "react";
import { Link } from "react-router-dom";


class Test extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="sidebar">
          <nav className="sidebar-nav">
            <ul className="nav">
              <li className="nav-item">
                <Link className="nav-link" to={"/DifuzaCarsSample/DfUserpage"}>
                  <i className="nav-icon fa fa-user" />
                  <span className="link-white">User Page</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={"/DifuzaCarsSample/DfMakemaster"}>
                  <i className="nav-icon fa fa-user" />
                  <span className="link-white">Make Master</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={"/DifuzaCarsSample/DfModelmaster"}>
                  <i className="nav-icon fa fa-user" />
                  <span className="link-white">Model Master</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={"/DifuzaCarsSample/Dfvariantmaster"}>
                  <i className="nav-icon fa fa-user" />
                  <span className="link-white">Variant Master</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/DifuzaCarsSample/DfcarzSalesCon">
                  <i className="nav-icon fa fa-user" />
                  <span className="link-white">Sales consultant</span>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </React.Fragment>
    );
  }
}

export default Test;
