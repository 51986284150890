const env = {
  // ACCESS_POINT: "http://localhost:21005",
  ACCESS_POINT: "https://steerexp.difuza.com",
  // ACCESS_POINT: "https://df2exp.difuza.com",
  REDIS_ACCESS_POINT: "https://is.rem.coach",
  EXPRESS_REDIS_ACCESS_POINT: "https://is.difuza.com",
  // EXPRESS_REDIS_ACCESS_POINT: "http://localhost:5011",
  ACCESS_SAMPLE: "http://35.169.94.138:3030",
  PYTHON_ACCESS_POINT: "http://3.228.46.250:5050",
  //SteerAuto
  // STEERAUTO: "http://35.169.94.138:3006",
  STEERAUTO: "https://www.steerauto.com",
  // ImgSTEERAUTO: "http://www.steerauto.com:8085/",
  SITEBUILDER_ACCESS_POINT: "http://localhost:3018",
  ImgSTEERAUTO: "https://steerexp.difuza.com/superAdmin/file?fileurl=steer_images/",
  FILEPATH: "https://video.rem.coach/~remcoach/vidhyaonline/"
};

module.exports = env;
