import React, { Component } from "react";
import { SingleSelect } from "../../../../components/Form";
import { withRouter } from 'react-router-dom';
import { data } from "jquery";
import CmsContent from "../../../../MiddleWare/CmsContent";
import Datatable from "../../../../components/Datatable/Datatable";
import { CSVLink } from "react-csv";
import moment from "moment";
export default class ViewDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "Sathesh",
      data: [
      ],
      pageName: "",
      pageHeader: "",
      column: [

        {
          Header: "Name",
          accessor: "name"
        },
        {
          Header: "Mobile",
          accessor: "mobile",
        },
        {
          Header: "Reg_no",
          accessor: "regno",
        },
        {
          Header: "Make",
          accessor: "makeName",
        },
        {
          Header: "Model",
          accessor: "modelName",
        },
        {
          Header: "Variant",
          accessor: "variantName",
        },
        {
          Header: "Year",
          accessor: "year",
        },
        {
          Header: "Color",
          accessor: "colourName",
        },
        {
          Header: "Email",
          accessor: "email",
        }

      ],
      excelcolumn1: [

        {
          label: "Name",
          key: "name"
        },
        {
          label: "Mobile",
          key: "mobile",
        },
        {
          label: "Reg_no",
          key: "regno",
        },
        {
          label: "Make",
          key: "makeName",
        },
        {
          label: "Model",
          key: "modelName",
        },
        {
          label: "Variant",
          key: "variantName",
        },
        {
          label: "Year",
          key: "year",
        },
        {
          label: "Color",
          key: "colourName",
        },
        {
          label: "Email",
          key: "email",
        }

      ],
      column2: [
        {
          Header: "Reg_no",
          accessor: "Reg_no",
        },
        {
          Header: "Make",
          accessor: "makename",
        },
        {
          Header: "Model",
          accessor: "modelname",
        },
        {
          Header: "Variant",
          accessor: "variantname",
        },
        {
          Header: "Make Year",
          accessor: "make_year",
        },
        {
          Header: "Color",
          accessor: "ColourName",
        },
        {
          Header: "Km",
          accessor: "kms",
        },
        {
          Header: "Price",
          accessor: "price",
        },
        {
          Header: "Location",
          accessor: "locationname",
        },
        {
          Header: "Branch",
          accessor: "branchname",
        }

      ],
      excelcolumn2: [
        {
          label: "Reg_no",
          key: "Reg_no",
        },
        {
          label: "Make",
          key: "makename",
        },
        {
          label: "Model",
          key: "modelname",
        },
        {
          label: "Variant",
          key: "variantname",
        },
        {
          label: "Make Year",
          key: "make_year",
        },
        {
          label: "Color",
          key: "ColourName",
        },
        {
          label: "Km",
          key: "kms",
        },
        {
          label: "Price",
          key: "price",
        },
        {
          label: "Location",
          key: "locationname",
        },
        {
          label: "Branch",
          key: "branchname",
        }

      ],
    };
  }
  componentWillMount() {
    this.mainFunc()
  }
  mainFunc = async () => {
    const pathname = window.location.pathname;
    const pageName = pathname.split('/').pop();
    this.setState({ pageName: pageName })
    console.log("parhname", pageName)
    let user = JSON.parse(localStorage.getItem(`userlog`));
    let id = user.id
    if (pageName === "buyingenquiry") {
      let result1 = await CmsContent.getDfcarzEnquiry(id, "seller")
      let enquiryData = result1.statusdata[0].fulldata
      let data = enquiryData
        .filter(ival => ival.yom ? (ival.year = ival.yom, ival.regno = ival.Reg_no, delete ival.yom, ival) : ival)
        .map(ival => {
          for (let key in ival) {
            ival[key] = ival[key] === "" || ival[key] === null || ival[key] === undefined ? "-" : ival[key];
          }
          return ival;
        });
      Promise.all(data)
      await this.setState({ data: data, pageHeader: "Buying Enquiry" })
    }
    if (pageName === "evaluation") {
      let result2 = await CmsContent.BUYDFCARZ(user.id, 'evaluation')
      let data = result2.Evaluation_Prospective
      this.setState({ data: data, pageHeader: "Buying Evaluation" })
    }
    if (pageName === "documentation") {
      let result = await CmsContent.BUYDFCARZ(user.id, 'Documents')
      let data = result.Documentation_Prospective
      this.setState({ data: data, pageHeader: "Buying Documentation" })
    }
    if (pageName == "physicalprocess") {
      let result = await CmsContent.BUYDFCARZ(user.id, 'physical')
      console.log(result, "result")
      let data = result.Physical_Prospective
      this.setState({ data: data, pageHeader: "Buying Physical Process" })

    }
    if (pageName === "refurbishment") {
      let result = await CmsContent.BUYDFCARZ(user.id, 'Refurbishment')
      let data = result.Refurbishment_Prospective.filter((ival, index) => {
        return ival.reg_no != "" ? ival.regno = ival.reg_no : ival.regno = "-"
      })

      this.setState({ data: data, pageHeader: "Buying Refurbishment" })
    }
    if (pageName === "sellingenquiry") {
      let result = await CmsContent.getDfcarzEnquiry(user.id, "buyer")
      let enquiryData = result.statusdata[0].fulldata
      let data = enquiryData
        .filter(ival => ival.yom ? (ival.year = ival.yom, ival.regno = ival.Reg_no, delete ival.yom, ival) : ival)
        .map(ival => {
          for (let key in ival) {
            ival[key] = ival[key] === "" || ival[key] === null || ival[key] === undefined ? "-" : ival[key];
          }
          return ival;
        });
      this.setState({ data: data, pageHeader: "Selling Enquiry" })
    }
    if (pageName === "lead") {
      let result = await CmsContent.BUYDFCARZ(user.id, 'Lead')
      let data = result.Lead
        .filter(ival => ival.yom ? (ival.year = ival.yom, ival.regno = ival.Reg_no, delete ival.yom, ival) : ival)
        .map(ival => {
          for (let key in ival) {
            ival[key] = ival[key] === "" || ival[key] === null || ival[key] === undefined ? "-" : ival[key];
          }
          return ival;
        });
      this.setState({ data: data, pageHeader: "Selling Lead" })
    }
    if (pageName === "contract") {
      let result = await CmsContent.BUYDFCARZ(user.id, 'contract')
      let data = result.fulldata.filter((ival, index) => {
        return ival.reg_no != "" ? ival.regno = ival.reg_no : ival.regno = "-"
      })
      this.setState({ data: data, pageHeader: "Selling Contract" })
    }
    if (pageName === "delivery") {
      let result = await CmsContent.BUYDFCARZ(user.id, 'delreport')
      let data = result.fulldata.filter((ival, index) => {
        return ival.reg_no != "" ? ival.regno = ival.reg_no : ival.regno = "-"
      })
      this.setState({ data: data, pageHeader: "Selling Delivery Report" })
    }
    if (pageName === "rctransfer") {
      let result = await CmsContent.BUYDFCARZ(user.id, 'RCdel')
      let data = result.fulldata.filter((ival, index) => {
        return ival.reg_no != "" ? ival.regno = ival.reg_no : ival.regno = "-"
      })
      this.setState({ data: data, pageHeader: "Selling Rc Transfer" })
    }

    if (pageName === "availablecars") {
      let result = await CmsContent.BUYDFCARZ(user.id, 'inventlist')
      let availableCars = result.Inventory.filter(a => a.status != "inactive" && a.car_status == "Live")
      this.setState({ data: availableCars, pageHeader: "Available Cars" })
    }
    if (pageName === "soldcars") {
      let result = await CmsContent.BUYDFCARZ(user.id, 'inventlist')
      let soldCars = result.Inventory.filter(a => (a.delete_status == "Sold" && a.car_status == "delete") || (a.status == "inactive"))
      console.log(soldCars, "soldcars")
      this.setState({ pageHeader: "Available Cars", data: soldCars })
    }
    if (pageName === "allcars") {
      let result = await CmsContent.BUYDFCARZ(user.id, 'inventlist')
      this.setState({ pageHeader: "All Cars", data: result.Inventory })
    }
  }

  render() {
    console.log(this.state.data, "data");
    const { pageName, } = this.state
    return (
      <section id="sec-viewDashboard" className="px-5 py-5" style={{ backgroundColor: "white", height: "100vh", width: "100%" }}>
        <div className="para-head-1 text-center">
          <p>{this.state.pageHeader}</p>
        </div>
       {this.state.data&&this.state.data.length ? <div className="d-flex justify-content-end align-items-center">
        <CSVLink
              filename={`${this.state.pageName}(${moment().format("DD-MM-YYYY hh:mm a")}).csv`}
              data={this.state.data}
              headers={pageName === "allcars" || pageName === "availablecars" || pageName === "soldcars" ? this.state.excelcolumn2 :this.state.excelcolumn1}
            >
              <button
                className="btn btn-success"
                style={{ marginLeft: "5px" }}
              >
                Export to Excel
              </button>
            </CSVLink>
       </div>:<div></div>}
        {pageName === "allcars" || pageName === "availablecars" || pageName === "soldcars" ? <>

          {this.state.data && this.state.data.length &&
            <Datatable
              data={this.state.data}
              columnHeading={this.state.column2}
            />
          }
        </> :
          <>
            {this.state.data && this.state.data.length &&
              <Datatable
                data={this.state.data}
                columnHeading={this.state.column}
              />
            }
          </>}
      </section>

    );
  }
}
