import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

const Pricelistpdf = ({ header, data, title }) => {

  console.log(header);

  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.title}>
          <Text>{title}</Text>
        </View>




        <View style={styles.table}>
          <View style={styles.tableRow}>
            {header &&
              header.map(value => {
                return (
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value}</Text>
                  </View>
                );
              })}
          </View>

          {data &&
            data.map((value, index) => {

              return (

                <View style={styles.tableRow}>

                  {/* <View><text>{title}</text> </View> */}
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{index + 1}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value.make}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value.model}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value.color}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value.price}</Text>
                  </View>

                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value.location}</Text>
                  </View>


                </View>
              );
            })}
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  body: {
    padding: 10
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 0.8,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row'
  },
  tableColHeader: {
    width: '15%',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderBottomColor: '#000',
    borderWidth: 0.8,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol: {
    width: '15%',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 0.8,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCellHeader: {
    margin: 'auto',
    fontSize: 11,
    fontWeight: 500
  },
  tableCell: {
    margin: 'auto',
    margin: 5,
    fontSize: 8
  },
  title: {
    fontSize: 19,
    fontWeight: "bold",
    marginLeft: '35%',

  }
});

export default Pricelistpdf;

