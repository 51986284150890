import React, { Component } from "react";
import Utilities from "../../../../MiddleWare/Utilities";
import redisMiddleware from "../../../../MiddleWare/redisMiddleware";
import CmsContent from "../../../../MiddleWare/CmsContent";
import ReportCharts from "../TL/ReportCharts";

export default class Salesconsultant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      usertypeName: "",
      TodayNotifiy: [],
      PendingTask: [],
      TodayEvaluation: [],
      Sourcing: [],
      Prospect: [],
      TodayContracted: [],
      SpectificationPending1: [],
      LocationID: localStorage.getItem(`LocationID`),
      TodayDelivery: [],
      Cars: [],
    };
  }

  async componentDidMount() {
    let Userlog = JSON.parse(localStorage.getItem("userlog"));
    try {
      const result = await redisMiddleware.singleTable(
        "tbl_UserType",
        Userlog.userType,
        "id"
      );
      if (result) {
        this.setState({
          data: result.data,
          usertypeName: result.data[0].usertype,
        });
      }
      const Prospect = await CmsContent.getFreedom(
        "tbl_dfcarz_prosperities.*",
        "tbl_dfcarz_prosperities",
        `tbl_dfcarz_prosperities.type='buyer'`
      );

      const Product = await CmsContent.getFreedom(
        "*",
        "tbl_dfcarz_enquiryform",
        1
      );
      let TodayNotifiy = [];
      let PendingTask = [];
      let CompletedTasks = [];
      if (Product) {
        Product.data.map((ival, i) => {
          let EnquiryDate = new Date();
          let d = new Date(ival.createdAt);
          // (
          //   new Date(ival.createdAt).getTime() + 60 * 60 * 24 * 1000
          // );
          //
          let a = EnquiryDate.getDate();
          let b = d.getDate();

          let Datetime1 = EnquiryDate.getTime();
          let Datetime2 = d.getTime();
          // console.log(Datetime2,Datetime1);
          if (ival.enquiry_status == "active" && ival.location == this.state.LocationID) {
            if (b == a) {
              TodayNotifiy.push(ival);
              console.log(ival);
            } else if (Datetime1 > Datetime2) {
              PendingTask.push(ival);
            }
          } else {
            CompletedTasks.push(ival);
          }

          // console.log(TodayNotifiy);
        });
      }
      this.setState({ TodayNotifiy, PendingTask, CompletedTasks });

      this.setState({ Prospect: Prospect.data });
      this.tbl_Specifications_Sheet_Proof();
      this.Autoterrace_enquiry_form();
      this.tbl_Booking_Confirmation_Reproof();
      this.tbl_Delivery_Note_Buyer_Reproof();
      this.SpectificationPending();
      this.ReadyForSale();
    } catch (error) {
      console.log(error);
    }
  }

  SpectificationPending = async () => {
    let SpectificationPending1 = [];
    try {
      // let Buyer = await CmsContent.getFreedom(
      //   "tbl_dfcarz_prosperities.*",
      //   "tbl_dfcarz_prosperities",
      //   `tbl_dfcarz_prosperities.location =${this.state.LocationID} and tbl_dfcarz_prosperities.type='buyer' and tbl_dfcarz_prosperities.prospective_status != 'inactive'`,
      //   1,
      //   `tbl_dfcarz_prosperities.id DESC`
      // );
      let Buyer = await redisMiddleware.Dashboard("SpectificationPending", 5, this.state.LocationID);
      if (Buyer) {
        // console.log(Buyer.data);
        let wait = await Buyer.data.map(async (ival, i) => {
          let result = await CmsContent.getFreedom(
            `*`,
            `tbl_Specifications_Sheet_Proof`,
            `customerid = ${ival.id}`
          );
          this.setState({ spec: result.data })
          // console.log(result);
          if (!result.data.length) {
            //console.log(result);
            SpectificationPending1.push(ival);
          }
        });

        await Promise.all(wait);
        //console.log(SpectificationPending1);
        this.setState({ SpectificationPending1 });
      }
    } catch (error) {
      console.log(error);
    }
  };

  ReadyForSale = async () => {
    try {
      // let result = await CmsContent.getFreedom(
      //   `tbl_items.*,tbl_make_master.Make as makename,tbl_dfcarsmodel.Model as modelname,tbl_colour.colour as ColourName`,
      //   `tbl_items,tbl_make_master,tbl_dfcarsmodel,tbl_colour`,
      //   `tbl_items.make=tbl_make_master.id and tbl_dfcarsmodel.id = tbl_items.model and tbl_colour.id = tbl_items.colour`,
      //   1,
      //   1
      // );
      let result = await redisMiddleware.Dashboard("ReadyForSale", 5, this.state.LocationID);
      if (result) {
        let Cars = [];
        result.data.map((ival, i) => {
          if (ival.status != "inactive") {
            let Date1 = new Date(ival.created_At);
            let Currentdate = new Date();
            if (
              Date1.getDate() == Currentdate.getDate() &&
              Date1.getMonth() == Currentdate.getMonth()
            ) {
              Cars.push(ival);
            }
          }
        });

        this.setState({ Cars });
        console.log(Cars);
      }
    } catch (error) {
      console.log(error);
    }
  };

  tbl_Specifications_Sheet_Proof = async () => {
    const { Prospect } = this.state;
    let TodayEvaluation1 = [];
    let TodayEvaluation = [];
    // let wait = await result.data.map(async(jval,j)=>{
    //      TodayEvaluation1 = Prospect.filter(value => value.id != jval.customerid);
    //     });
    //     await Promise.all(wait);
    TodayEvaluation1.map((ival, i) => {
      if (ival.prospective_status != "inactive") {
        TodayEvaluation.push(ival);
      }
    });

    TodayEvaluation1.map(async (ival, i) => {
      let result = await CmsContent.getFreedom(
        `*`,
        `tbl_Specifications_Sheet_Proof`,
        `customerid=${ival.id}`
      );
      if (!result.data.length) {
        TodayEvaluation.push(ival);
      }
    });

    this.setState({ TodayEvaluation });
    //  }
  };

  Autoterrace_enquiry_form = async () => {
    // const{Prospect}=this.state;
    // const Prospect = await CmsContent.getFreedom(
    //   "tbl_dfcarz_prosperities.*",
    //   "tbl_dfcarz_prosperities,tbl_Specifications_Sheet_Proof",
    //   `tbl_dfcarz_prosperities.type='buyer' and tbl_Specifications_Sheet_Proof.customerid = tbl_dfcarz_prosperities.id and tbl_dfcarz_prosperities.location=${this.state.LocationID}`
    // );
    let Prospect = await redisMiddleware.Dashboard("Autoterrace_enquiry_form", 5, this.state.LocationID)
    let TodayEvaluation1 = [];
    Prospect.data.map((ival, i) => {
      if (ival.prospective_status != "inactive") {
        TodayEvaluation1.push(ival);
      }
    });
    let Sourcing = [];
    // console.log(TodayEvaluation1);
    let wait = await TodayEvaluation1.map(async (jval, j) => {
      let result = await CmsContent.getFreedom(
        `*`,
        `tbl_Autoterrace_enquiry_form`,
        `customerid=${jval.id}`
      );

      if (!result.data.length) {
        Sourcing.push(jval);
      }
    });
    await Promise.all(wait);
    // console.log(Sourcing)
    this.setState({ Sourcing });
    // }
  };

  tbl_Booking_Confirmation_Reproof = async () => {
    // const{Prospect}=this.state;
    // const Prospect = await CmsContent.getFreedom(
    //   "tbl_dfcarz_prosperities.*",
    //   "tbl_dfcarz_prosperities,tbl_Autoterrace_enquiry_form",
    //   `tbl_dfcarz_prosperities.type='buyer' and tbl_Autoterrace_enquiry_form.customerid = tbl_dfcarz_prosperities.id and tbl_dfcarz_prosperities.location=${this.state.LocationID}`
    // );
    //console.log(Prospect);
    let Prospect = await redisMiddleware.Dashboard("tbl_Booking_Confirmation_Reproof", 5, this.state.LocationID);
    let result = await CmsContent.getFreedom(
      `*`,
      `tbl_Booking_Confirmation_Reproof`,
      1
    );
    this.setState({ contract: result.data })
    if (result) {
      //  console.log(result.data);
      let TodayContracted = [];
      result.data.map((jval, j) => {
        TodayContracted = Prospect.data.filter(
          (value) => value.id !== jval.customerid
        );
      });
      this.setState({ TodayContracted });
    }
  };

  tbl_Delivery_Note_Buyer_Reproof = async () => {
    // const{Prospect}=this.state;
    // const Prospect = await CmsContent.getFreedom(
    //   "tbl_dfcarz_prosperities.*",
    //   "tbl_dfcarz_prosperities,tbl_Booking_Confirmation_Reproof",
    //   `tbl_dfcarz_prosperities.type='buyer' and tbl_Booking_Confirmation_Reproof.customerid = tbl_dfcarz_prosperities.id and tbl_dfcarz_prosperities.location=${this.state.LocationID}`
    // );
    // console.log(Prospect);
    let Prospect = await redisMiddleware.Dashboard("tbl_Delivery_Note_Buyer_Reproof", 5, this.state.LocationID)
    let result = await CmsContent.getFreedom(
      `*`,
      `tbl_Delivery_Note_Buyer_Reproof`,
      1
    );
    this.setState({ delivery: result.data })
    if (result) {
      let TodayDelivery = [];
      result.data.map((jval, j) => {
        TodayDelivery = Prospect.data.filter(
          (value) => value.id !== jval.customerid
        );
      });
      this.setState({ TodayDelivery });
    }
  };
  MovetoSite = (d) => {
    console.log(d);
    if (d == "penquiry") {
      //window.open(`/DFCars/DfCarsEnquiry`);
      window.location.href = `/DFCars/DfCarsEnquiry`;
    } else if (d == "inventry") {
      window.location.href = `/DFCars/InventoryList`;
    } else if (d == "spec") {
      window.location.href = `/DFCars/BuyerEnquiryform`;
    } else if (d == "delivery") {
      window.location.href = `/DFCars/Delivery`;
    } else if (d == "sourcing") {
      window.location.href = `/DFCars/Sourcing`;
    } else if (d == "contracted") {
      window.location.href = `/DFCars/Contracted`;
    }
  };
  getcontregno = (id) => {
    const { contract } = this.state;
    let regno = [];
    contract.map((ival, b) => {
      if (ival.customerid == id) {
        regno.push(ival.regno)
      }
    })
    return regno;
  }
  getbuyregno = (id) => {
    const { delivery } = this.state;
    let regno = [];
    delivery.map((jval, c) => {
      if (jval.customerid == id) {
        regno.push(jval.regno)
      }
    })
    return regno;
  }
  render() {
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3>Welcome {this.state.usertypeName}</h3>
                  </div>
                  <br />
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <ReportCharts />

                        <div className="row form-group">
                          {this.state.TodayNotifiy.length ? (
                            <div className="col-sm-6">
                              <React.Fragment>
                                <div className="card">
                                  <div
                                    className="card-header"
                                    style={{ backgroundColor: "lightgray" }}
                                  >
                                    <h3>
                                      Enquiries to be approved today (Comp{" "}
                                      {this.state.CompletedTasks.length} - Pend{" "}
                                      {this.state.TodayNotifiy.length})
                                    </h3>
                                  </div>
                                  <div className="card-body">
                                    {/* {this.state.TodayEvaluation.length ?  */}
                                    {this.state.TodayNotifiy.map((ival, i) => {
                                      if (i < 8) {
                                        return (
                                          <React.Fragment>
                                            <div className="row-form-group">
                                              <div className="col-sm-1" />
                                              <div className="col-sm-12">
                                                <a onClick={() => this.MovetoSite("penquiry")}>
                                                  <table className="dfcarsport">
                                                    <tr>
                                                      <td className="dfcarsportname">
                                                        {ival.name}
                                                      </td>
                                                      <td className="dfcarsportmobicon">
                                                        <i class="fa fa-mobile" aria-hidden="true"></i>
                                                      </td>

                                                      <td className="dfcarsportnumber">
                                                        {ival.mobile}
                                                      </td>
                                                      <td className="dfcarsportreg">
                                                        {ival.email}
                                                      </td>
                                                    </tr>
                                                  </table>
                                                </a>
                                              </div>
                                            </div>
                                          </React.Fragment>
                                        );
                                      }
                                    })}
                                    {this.state.TodayNotifiy.length > 0 ? (
                                      <h5
                                        className="Readmore1"
                                        onClick={() =>
                                          this.MovetoSite("penquiry")
                                        }
                                      >
                                        View More
                                      </h5>
                                    ) : null}
                                  </div>
                                </div>
                              </React.Fragment>
                            </div>
                          ) : null}

                          {this.state.Cars.length ? (
                            <div className="col-sm-6">
                              <React.Fragment>
                                <div className="card">
                                  <div
                                    className="card-header"
                                    style={{ backgroundColor: "lightgray" }}
                                  >
                                    <h3>
                                      Ready For Sale (Comp{" "}
                                      {this.state.CompletedTasks.length} - Pend{" "}
                                      {this.state.Cars.length})
                                    </h3>
                                  </div>
                                  <div className="card-body">
                                    {/* {this.state.TodayEvaluation.length ?  */}
                                    {this.state.Cars.map((ival, i) => {
                                      if (i < 1) {
                                        return (
                                          <React.Fragment>
                                            <div
                                              className="card-body"
                                              style={{}}
                                            >
                                              <p>Make : {ival.makename}</p>
                                              <p>Model: {ival.modelname}</p>
                                              <p>Price: {ival.price}</p>
                                              <p>Colour : {ival.ColourName}</p>
                                            </div>
                                            <hr />
                                          </React.Fragment>
                                        );
                                      }
                                    })}
                                    {this.state.Cars.length > 0 ? (
                                      <h5
                                        className="Readmore"
                                        onClick={() =>
                                          this.MovetoSite("inventry")
                                        }
                                      >
                                        Read More
                                      </h5>
                                    ) : null}
                                  </div>
                                </div>
                              </React.Fragment>
                            </div>
                          ) : null}
                          {this.state.SpectificationPending1.length ? (
                            <div className="col-sm-6">
                              <React.Fragment>
                                <div className="card">
                                  <div
                                    className="card-header"
                                    style={{ backgroundColor: "lightgray" }}
                                  >
                                    <h3>
                                      Specifications Sheet Pendings (Comp{" "}
                                      {this.state.CompletedTasks.length} - Pend{" "}
                                      {this.state.SpectificationPending1.length}
                                      )
                                    </h3>
                                  </div>
                                  <div className="card-body">
                                    {/* {this.state.TodayEvaluation.length ?  */}
                                    {this.state.SpectificationPending1.map(
                                      (ival, i) => {

                                        if (i < 8) {
                                          return (
                                            <React.Fragment>
                                              <div className="row-form-group">
                                                <div className="col-sm-1" />
                                                <div className="col-sm-12">
                                                  <a onClick={() => this.MovetoSite("spec")}>
                                                    <table className="dfcarsport">
                                                      <tr>
                                                        <td className="dfcarslportname">
                                                          {ival.name}
                                                        </td>
                                                        <td className="dfcarsportmobicon">
                                                          <i class="fa fa-mobile" aria-hidden="true"></i>
                                                        </td>

                                                        <td className="dfcarscontportnumber">
                                                          {ival.mobile}
                                                        </td>
                                                        <td className="dfcarsportreg">
                                                          {ival.email}
                                                        </td>
                                                      </tr>
                                                    </table>
                                                  </a>
                                                </div>
                                              </div>
                                            </React.Fragment>
                                          );
                                        }
                                      }
                                    )}
                                    {this.state.SpectificationPending1.length >
                                      0 ? (
                                        <h5
                                          className="Readmore1"
                                          onClick={() => this.MovetoSite("spec")}
                                        >
                                          View More
                                        </h5>
                                      ) : null}
                                  </div>
                                </div>
                              </React.Fragment>
                            </div>
                          ) : null}
                          {this.state.Sourcing.length ? (
                            <div className="col-sm-6">
                              <React.Fragment>
                                <div className="card">
                                  <div
                                    className="card-header"
                                    style={{ backgroundColor: "lightgray" }}
                                  >
                                    <h3>
                                      Sourcing Pendings (Comp{" "}
                                      {this.state.CompletedTasks.length} - Pend{" "}
                                      {this.state.Sourcing.length})
                                    </h3>
                                  </div>
                                  <div className="card-body">
                                    {/* {this.state.TodayEvaluation.length ?  */}
                                    {this.state.Sourcing.map((ival, i) => {
                                      console.log(ival)
                                      if (i < 8) {
                                        return (
                                          <React.Fragment>
                                            <div className="row-form-group">
                                              <div className="col-sm-1" />
                                              <div className="col-sm-12">
                                                <a onClick={() => this.MovetoSite("sourcing")}>
                                                  <table className="dfcarsport">
                                                    <tr>
                                                      <td className="dfcarsportname">
                                                        {ival.name}
                                                      </td>
                                                      <td className="dfcarsportmobicon">
                                                        <i class="fa fa-mobile" aria-hidden="true"></i>
                                                      </td>

                                                      <td className="dfcarsportnumber">
                                                        {ival.mobile}
                                                      </td>
                                                      <td className="dfcarsportreg">
                                                        {ival.email}
                                                      </td>
                                                    </tr>
                                                  </table>
                                                </a>
                                              </div>
                                            </div>
                                          </React.Fragment>
                                        );
                                      }
                                    })}
                                    {this.state.Sourcing.length > 0 ? (
                                      <h5
                                        className="Readmore1"
                                        onClick={() =>
                                          this.MovetoSite("sourcing")
                                        }
                                      >
                                        View More
                                      </h5>
                                    ) : null}
                                  </div>
                                </div>
                              </React.Fragment>
                            </div>
                          ) : null}

                          {this.state.TodayContracted.length ? (
                            <div className="col-sm-6">
                              <React.Fragment>
                                <div className="card">
                                  <div
                                    className="card-header"
                                    style={{ backgroundColor: "lightgray" }}
                                  >
                                    <h3>
                                      Contracts Pendings (Comp{" "}
                                      {this.state.CompletedTasks.length} - Pend{" "}
                                      {this.state.TodayContracted.length})
                                    </h3>
                                  </div>
                                  <div className="card-body">
                                    {/* {this.state.TodayEvaluation.length ?  */}
                                    {this.state.TodayContracted.map(
                                      (ival, i) => {
                                        console.log(ival)
                                        if (i < 8) {
                                          return (
                                            <React.Fragment>
                                              <div className="row-form-group">
                                                <div className="col-sm-1" />
                                                <div className="col-sm-12">
                                                  <a onClick={() => this.MovetoSite("contracted")}>
                                                    <table className="dfcarsport">
                                                      <tr>
                                                        <td className="dfcarsportname">
                                                          {ival.name}
                                                        </td>
                                                        <td className="dfcarsportmobicon">
                                                          <i class="fa fa-mobile" aria-hidden="true"></i>
                                                        </td>

                                                        <td className="dfcarsportnumber">
                                                          {ival.mobile}
                                                        </td>
                                                        <td className="dfcarsportreg">
                                                          {this.getcontregno(ival.id)}
                                                        </td>
                                                      </tr>
                                                    </table>
                                                  </a>
                                                </div>
                                              </div>

                                            </React.Fragment>
                                          );
                                        }
                                      }
                                    )}
                                    {this.state.TodayContracted.length > 0 ? (
                                      <h5
                                        className="Readmore1"
                                        onClick={() =>
                                          this.MovetoSite("contracted")
                                        }
                                      >
                                        View More
                                      </h5>
                                    ) : null}
                                  </div>
                                </div>
                              </React.Fragment>
                            </div>
                          ) : null}
                          {this.state.TodayDelivery.length ? (
                            <div className="col-sm-6">
                              <React.Fragment>
                                <div className="card">
                                  <div
                                    className="card-header"
                                    style={{ backgroundColor: "lightgray" }}
                                  >
                                    <h3>
                                      Delivery Pendings (Comp{" "}
                                      {this.state.CompletedTasks.length} - Pend{" "}
                                      {this.state.TodayDelivery.length})
                                    </h3>
                                  </div>
                                  <div className="card-body">
                                    {/* {this.state.TodayEvaluation.length ?  */}
                                    {this.state.TodayDelivery.map((ival, i) => {
                                      if (i < 8) {
                                        return (
                                          <React.Fragment>
                                            <div className="row-form-group">
                                              <div className="col-sm-1" />
                                              <div className="col-sm-12">
                                                <a onClick={() => this.MovetoSite("delivery")}>
                                                  <table className="dfcarsport">
                                                    <tr>
                                                      <td className="dfcarsportname">
                                                        {ival.name}
                                                      </td>
                                                      <td className="dfcarsportmobicon">
                                                        <i class="fa fa-mobile" aria-hidden="true"></i>
                                                      </td>

                                                      <td className="dfcarsportnumber">
                                                        {ival.mobile}
                                                      </td>
                                                      <td className="dfcarsportreg">
                                                        {this.getbuyregno(ival.id)}
                                                      </td>
                                                    </tr>
                                                  </table>
                                                </a>
                                              </div>
                                            </div>
                                          </React.Fragment>
                                        );
                                      }
                                    })}
                                    {this.state.TodayDelivery.length > 0 ? (
                                      <h5
                                        className="Readmore1"
                                        onClick={() =>
                                          this.MovetoSite("delivery")
                                        }
                                      >
                                        View More
                                      </h5>
                                    ) : null}
                                  </div>
                                </div>
                              </React.Fragment>
                            </div>
                          ) : null}
                        </div>

                        {/* <h2 style={{ color: "black" }}>Contract Pendings :</h2>
                      <br />
                      {this.state.TodayContracted.length
                        ? this.state.TodayContracted.map((ival, i) => {
                            return (
                              <React.Fragment>
                                <div className="row">
                                  <div className="col-12">
                                    <div className="card">
                                      <div
                                        className="card-header"
                                        style={{ backgroundColor: "lightblue" }}
                                      >
                                        <h3>Name : {ival.name}</h3>
                                      </div>
                                      <div className="card-body">
                                        <h4>Type: {ival.type}</h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                            );
                          })
                        : <p>No Pendings</p>} */}

                        {/* <h2 style={{ color: "black" }}>Delivery Pendings :</h2>
                      <br />
                      {this.state.TodayDelivery.length
                        ? this.state.TodayDelivery.map((ival, i) => {
                            return (
                              <React.Fragment>
                                <div className="row">
                                  <div className="col-12">
                                    <div className="card">
                                      <div
                                        className="card-header"
                                        style={{ backgroundColor: "lightblue" }}
                                      >
                                        <h3>Name : {ival.name}</h3>
                                      </div>
                                      <div className="card-body">
                                        <h4>Type: {ival.type}</h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                            );
                          })
                        : <p>No Pendings</p>} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
