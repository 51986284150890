import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
//import UpdateStatus from "./UpdateStatus";
import CanvasJSReact from "../../../../canvas/canvasjs.react";
import moment from "moment";
import SingleSelect from "../../../../components/Form/SingleSelect";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var CanvasJS = CanvasJSReact.CanvasJS;

class ReportCharts extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      User: JSON.parse(localStorage.getItem("userlog")),
      EnquiryData: [],
      fromdate: "",
      todate: "",
      disableValue: false,
      formchart1: null,
      formchart2: null,
      formchart3: null,
      formchart4: null,
      GraphTypeSelect: {},
      GraphType: [
        { value: 0, label: "Refurbishment" },
        { value: 1, label: "Leads Vs Conversions" },
        { value: 2, label: "Buys Vs Sales" }
      ]
    };
  }
  async componentDidMount() {
    try {
      this.ChartRefurishment();
    } catch (error) {
      console.log(error);
    }
  }

  ChartRefurishment = async () => {
    try {
      let fromdate = new Date();

      let todate = new Date();

      let year = todate.getFullYear();
      let month = todate.getMonth() - 1;
      let DATe = todate.getDate();
      let TODate = new Date(`${year}-${month + 1}-${DATe}`);
      fromdate = moment(fromdate).format("YYYY-MM-DD");
      TODate = moment(TODate).format("YYYY-MM-DD");

      this.ViewChart(TODate, fromdate);
      this.ViewChart1(TODate, fromdate);
      this.ViewChart2(TODate, fromdate);
    } catch (error) {
      console.log(error);
    }
  };

  handlechangeData = async e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleSelect = async e => {
    this.setState({ GraphTypeSelect: e });
  };

  Section2Date = async e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  Section2Select = async e => {
    this.setState({ GraphTypeSelect: e });
  };

  ViewChart = async (Fromdate, ToDATE) => {
    const { fromdate, todate } = this.state;
    //console.log(fromdate);
    var date1 = new Date(fromdate ? fromdate : Fromdate);
    var date2 = new Date(todate ? todate : ToDATE);
    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    var monthShortNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    var arr = [];
    var datFrom = new Date(fromdate ? fromdate : Fromdate);
    var datTo = new Date(todate ? todate : ToDATE);
    var fromYear = datFrom.getFullYear();
    var toYear = datTo.getFullYear();
    var diffYear = 12 * (toYear - fromYear) + datTo.getMonth();

    for (var i = datFrom.getMonth(); i <= diffYear; i++) {
      arr.push(monthShortNames[i % 12]);
    }
    let Result = await cmsContent.getFreedom(
      `*`,
      `tbl_checklist350`,
      `approval=1 and createdAt between '${
        fromdate ? fromdate : Fromdate
      }' and '${todate ? todate : ToDATE}'`
    );
    var json = [];
    if (Result) {
      let data_date = [];
      Result.data.map((ival, i) => {
        var data = this.getMonth(ival.createdAt);
        data_date[i] = data;
      });
      var array = data_date;
      var hist = {};
      array.map(function(a) {
        if (a in hist) hist[a]++;
        else hist[a] = 1;
      });
      arr.map((jval, j) => {
        // var count = 0;
        Object.keys(hist).forEach(key => {
          if (jval === key) {
            json.push({ y: hist[key], label: key, color: "green" });
          }
        });
      });
    }
    let dataPoints = [];

    dataPoints.push({ type: "stackedColumn", dataPoints: json });
    let chart1 = this.canvaschart2("Refurbishment", dataPoints);
    let formchart1 = [];
    formchart1.push(<CanvasJSChart options={chart1} />);
    this.setState({ formchart1 });
  };

  getMonth = d => {
    var monthShortNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    var t1 = new Date(d);
    return monthShortNames[t1.getMonth()];
    // t.getDate() + ' ' + monthShortNames[t.getMonth()] + ', ' + t.getFullYear();
  };

  canvaschart = (title, array) => {
    let options = {
      animationEnabled: true,
      title: {
        text: title,
        fontSize: 20
      },
      axisY: {
        title: "Lakhs",
        includeZero: true
      },
      legend: {
        cursor: "pointer"
        // itemclick : toggleDataSeries
      },
      toolTip: {
        shared: true
        // content: toolTipFormatter
      },
      data: array
    };
    return options;
  };

  ViewChart1 = async (Fromdate, ToDATE) => {
    const { fromdate, todate } = this.state;
    try {
      var date1 = new Date(fromdate ? fromdate : Fromdate);
      var date2 = new Date(todate ? todate : ToDATE);
      var Difference_In_Time = date2.getTime() - date1.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      var monthShortNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ];
      var arr = [];
      var datFrom = new Date(fromdate ? fromdate : Fromdate);
      var datTo = new Date(todate ? todate : ToDATE);
      var fromYear = datFrom.getFullYear();
      var toYear = datTo.getFullYear();
      var diffYear = 12 * (toYear - fromYear) + datTo.getMonth();

      for (var i = datFrom.getMonth(); i <= diffYear; i++) {
        arr.push(monthShortNames[i % 12]);
      }

      let prosperities = await cmsContent.getFreedom(
        `*`,
        `tbl_dfcarz_prosperities`,
        `created_At between '${fromdate ? fromdate : Fromdate}' and '${
          todate ? todate : ToDATE
        }'`
      );
      let Customer = await cmsContent.getFreedom(
        `*`,
        `tbl_dfcarz_customers`,
        `createdAt between '${fromdate ? fromdate : Fromdate}' and '${
          todate ? todate : ToDATE
        }'`
      );

      let pros = [];
      let cus = [];
      let LvsC = [];
      prosperities.data.map((ival, i) => {
        Customer.data.map((jval, j) => {
          if (ival.id !== jval.prospectid) {
            LvsC.push({
              info: "prospective",
              name: ival.name,
              createdAt: ival.created_At
            });
          } else if (ival.id === jval.prospectid) {
            //ival.info=='customer';/
            LvsC.push({
              info: "customer",
              name: jval.customer,
              createdAt: jval.createdAt
            });
          }
        });
      });

      // var json=[];
      let dataPoints = [];
      var json = [];
      var json1 = [];
      if (LvsC) {
        let data_date = [{ pros: [], cus: [] }];
        LvsC.map((ival, i) => {
          var data = this.getMonth(ival.createdAt);
          if (ival.info === "prospective") {
            // data_date[i].pros= []
            data_date[0].pros.push(data);
          }
          if (ival.info === "customer") {
            // data_date[i].cus= []
            data_date[0].cus.push(data);
          }
        });
        var array = data_date;

        var hist_pos = {};
        array[0].pros.map(function(a) {
          if (a in hist_pos) hist_pos[a]++;
          else hist_pos[a] = 1;
        });
        var hist_cus = {};
        array[0].cus.map(function(a) {
          if (a in hist_cus) hist_cus[a]++;
          else hist_cus[a] = 1;
        });
        arr.map((jval, j) => {
          // var count = 0;
          Object.keys(hist_pos).forEach(key => {
            if (jval === key) {
              //       count = count +1
              json.push({ y: hist_pos[key], label: key });
            }
            // dataPoints.push({type:"column",dataPoints:json})
          });

          Object.keys(hist_cus).forEach(key => {
            if (jval === key) {
              //       count = count +1
              json1.push({ y: hist_cus[key], label: key });
            }
          });
        });

        dataPoints.push(
          { type: "column", dataPoints: json1, color: "blue" },
          { type: "column", dataPoints: json, color: "green" }
        );
      }

      let chart1 = this.canvaschart("Leads Vs Conversions", dataPoints);
      let formchart2 = [];
      formchart2.push(<CanvasJSChart options={chart1} />);
      this.setState({ formchart2 });
    } catch (error) {
      console.log(error);
    }
  };

  ViewChart2 = async (Fromdate, ToDATE) => {
    const { fromdate, todate } = this.state;

    var date1 = new Date(fromdate ? fromdate : Fromdate);
    var date2 = new Date(todate ? todate : ToDATE);
    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    var monthShortNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    var arr = [];
    var datFrom = new Date(fromdate ? fromdate : Fromdate);
    var datTo = new Date(todate ? todate : ToDATE);
    var fromYear = datFrom.getFullYear();
    var toYear = datTo.getFullYear();
    var diffYear = 12 * (toYear - fromYear) + datTo.getMonth();

    for (var i = datFrom.getMonth(); i <= diffYear; i++) {
      arr.push(monthShortNames[i % 12]);
    }

    let sell = await cmsContent.getFreedom(
      `tbl_AT_Booklet_Purchase_Contract_Proof.*`,
      `tbl_checklist350,tbl_AT_Booklet_Purchase_Contract_Proof`,
      `tbl_checklist350.customerid = tbl_AT_Booklet_Purchase_Contract_Proof.customerid   and tbl_checklist350.approval = 1 and tbl_AT_Booklet_Purchase_Contract_Proof.approval = 1 and tbl_checklist350.createdAt between '${
        fromdate ? fromdate : Fromdate
      }' and '${todate ? todate : ToDATE}'`,
      "tbl_checklist350.id",
      "tbl_checklist350.id"
    );

    let buy = await cmsContent.getFreedom(
      `*`,
      `tbl_Delivery_Note_Buyer_Reproof`,
      `approval = 1 and date between '${fromdate ? fromdate : Fromdate}' and '${
        todate ? todate : ToDATE
      }'`,
      "id",
      "id"
    );

    let array = [{ sell: sell.data, buy: buy.data }];

   // console.log(array);

    let modified_json = {sell:[],buy:[]};

    array.map((ival, i) => {
      // console.log(ival);
      if (ival.sell.length) {
        modified_json.sell = [];
        ival.sell.map((jval, j) => {
          var dat = new Date(jval.date);
          var month = dat.getMonth();
          modified_json.sell.push({
            total: jval.total_exchange_price,
            month: monthShortNames[month]
          });
        });
      }
    });
    array.map((ival, i) => {
      if (ival.buy.length) {
        modified_json.buy = [];
        ival.buy.map((jval, j) => {
          var dat = new Date(jval.date);
          var month = dat.getMonth();
          modified_json.buy.push({
            total: jval.price,
            month: monthShortNames[month]
          });
        });
      }
    });
   // console.log(modified_json);
   // console.log()
   var groupBy_Sell =[];
     if(modified_json.sell.length >0){
     groupBy_Sell = modified_json.sell.reduce(function(rv, x) {
      rv[x.month] = [...(rv[x.month] || []), x];
      return rv;
    }, {});
  }
  var groupBy_Buy =[]
   if(modified_json.buy.length>0){  
  groupBy_Buy = modified_json.buy.reduce(function(rv, x) {
      rv[x.month] = [...(rv[x.month] || []), x];
      return rv;
    }, {});
  }
    let new_json = {};
    new_json.sell = groupBy_Sell;
    new_json.buy = groupBy_Buy;

   // console.log(new_json);

    let dataPoints = [];
    let json = [];
    let json_1 = [];

    arr.map((jval, j) => {
      Object.keys(new_json.sell).forEach(key => {
        if (jval === key) {
          let total = 0;
          new_json.sell[key].map((ival, i) => {
            total = total + parseInt(ival.total);
          });
          json.push({ y: total, label: key });
        }
      });
      Object.keys(new_json.buy).forEach(key => {
        if (jval === key) {
          let total = 0;
          new_json.buy[key].map((ival, i) => {
            total = total + parseInt(ival.total);
          });
          json_1.push({ y: total, label: key });
        }
      });
    });

    //     if (jval === key) {
    //       //       count = count +1
    //       json_1.push({ y: hist_buy[key], label: key });
    //     }
    // });

    // console.log(json);
    // console.log(json_1);

    // let sell_total = 0;
    // let buy_total = 0;

    // if (sell && sell.data) {
    //   sell.data.map((ival, i) => {
    //     let a = 0;
    //     if (
    //       ival.total_exchange_price === undefined ||
    //       ival.total_exchange_price === null
    //     ) {
    //       a = 0;
    //     } else {
    //       a = parseInt(ival.total_exchange_price);
    //     }
    //     sell_total = sell_total + a;
    //   });
    // }
    // if (buy && buy.data) {
    //   buy.data.map((ival, i) => {
    //     let a = 0;
    //     if (
    //       ival.price === "undefined" ||
    //       ival.price === undefined ||
    //       ival.price === null
    //     ) {
    //       a = 0;
    //     } else {
    //       a = parseInt(ival.price);
    //     }
    //     buy_total = buy_total + a;
    //   });
    // }

    // let dataPoints = [];

    // let json = [
    //   {
    //     y: sell_total,
    //     label: "Buy vs Sell"
    //   }
    // ];
    // let json_1 = [
    //   {
    //     y: buy_total,
    //     label: "Buy vs Sell"
    //   }
    // ];

    // showInLegend: true,

    dataPoints.push(
      { type: "column", dataPoints: json_1, color: "blue", name: "Buy" },
      { type: "column", dataPoints: json, color: "green", name: "Sell" }
    );

    let chart = this.canvaschart("Buy Vs Sell", dataPoints);
    let formchart3 = [];
    formchart3.push(<CanvasJSChart options={chart} />);
    this.setState({ formchart3 });
  };

  canvaschart2 = (title, array) => {
    let options = {
      theme: "light1",
      animationEnabled: true,
      title: {
        text: title,
        fontSize: 20
      },
      legend: {
        verticalAlign: "bottom",
        horizontalAlign: "center"
      },
      toolTip: {
        content: "{label} - {y}"
      },
      // data: array
      data: array
    };
    return options;
  };

  render() {
    return (
      <React.Fragment>
        <main>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Report Graphs </h1>
                  </div>
                  <div className="card-body">
                    {/* <div className="row form-group">
                      <div className="col-sm-1" />
                      <div className="col-sm-3">
                        <label htmlFor="exampleInputEmail1">Graph Type</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          name="model"
                          options={this.state.GraphType}
                          handleChange={d => this.handleSelect(d)}
                          selectedService={this.state.GraphTypeSelect}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-1" />
                      <div className="col-sm-3">
                        <label htmlFor="exampleInputEmail1">FROM-Date</label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type="date"
                          className="form-control"
                          id="ColourName"
                          name="fromdate"
                          placeholder="Enter price amount"
                          value={this.state.fromdate}
                          onChange={this.handlechangeData}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                       
                    <div className="row form-group">
                      <div className="col-sm-1" />
                      <div className="col-sm-3">
                        <label htmlFor="exampleInputEmail1">TO-Date</label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type="date"
                          className="form-control"
                          id="ColourName"
                          name="todate"
                          placeholder="Enter price amount"
                          value={this.state.todate}
                          onChange={this.handlechangeData}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-3" />
                      <div className="col-sm-5">
                       {this.state.GraphTypeSelect.value==0 ? <React.Fragment>
                        <button
                          type="button"
                          className={`btn btn-success`}
                          disabled={this.state.disableValue}
                          onClick={this.ViewChart}
                        >
                         SUBMIT
                        </button>
                       </React.Fragment> :null }
                       {this.state.GraphTypeSelect.value==1 ? <React.Fragment>
                        <button
                          type="button"
                          className={`btn btn-success`}
                          disabled={this.state.disableValue}
                          onClick={this.ViewChart1}
                        >
                         SUBMIT
                        </button>
                       </React.Fragment> :null }
                       {this.state.GraphTypeSelect.value==2 ? <React.Fragment>
                        <button
                          type="button"
                          className={`btn btn-success`}
                          disabled={this.state.disableValue}
                          onClick={this.ViewChart2}
                        >
                         SUBMIT
                        </button>
                       </React.Fragment> :null }
                      </div>
                      <div className="col-sm-2" />
                    </div> */}
                    <div className="row form-group">
                      <div className="col-sm-6">
                        <div className="row form-group">
                          <div className="col-sm-1" />
                          <div className="col-sm-3">
                            <label htmlFor="exampleInputEmail1">
                              From-Date
                            </label>
                          </div>
                          <div className="col-sm-5">
                            <input
                              type="date"
                              className="form-control"
                              id="ColourName"
                              name="fromdate"
                              placeholder="Enter price amount"
                              value={this.state.fromdate}
                              onChange={this.handlechangeData}
                            />
                          </div>
                          <div className="col-sm-3" />
                        </div>

                        <div className="row form-group">
                          <div className="col-sm-1" />
                          <div className="col-sm-3">
                            <label htmlFor="exampleInputEmail1">To-Date</label>
                          </div>
                          <div className="col-sm-5">
                            <input
                              type="date"
                              className="form-control"
                              id="ColourName"
                              name="todate"
                              placeholder="Enter price amount"
                              value={this.state.todate}
                              onChange={this.handlechangeData}
                            />
                          </div>
                          <div className="col-sm-3" />
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-4" />
                          <div className="col-sm-3">
                            <button
                              type="button"
                              className={`btn btn-success`}
                              disabled={this.state.disableValue}
                              onClick={this.ViewChart}
                            >
                              SUBMIT
                            </button>
                          </div>
                        </div>
                        {this.state.formchart1 !== null
                          ? this.state.formchart1
                          : null}
                      </div>
                      <div className="col-sm-6">
                        <div className="row form-group">
                          <div className="col-sm-1" />
                          <div className="col-sm-3">
                            <label htmlFor="exampleInputEmail1">
                              From-Date
                            </label>
                          </div>
                          <div className="col-sm-5">
                            <input
                              type="date"
                              className="form-control"
                              name="fromdate"
                              placeholder="Enter price amount"
                              value={this.state.fromdate}
                              onChange={this.Section2Date}
                            />
                          </div>
                          <div className="col-sm-3" />
                        </div>

                        <div className="row form-group">
                          <div className="col-sm-1" />
                          <div className="col-sm-3">
                            <label htmlFor="exampleInputEmail1">To-Date</label>
                          </div>
                          <div className="col-sm-5">
                            <input
                              type="date"
                              className="form-control"
                              name="todate"
                              placeholder="Enter price amount"
                              value={this.state.todate}
                              onChange={this.Section2Date}
                            />
                          </div>
                          <div className="col-sm-3" />
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-4" />
                          <div className="col-sm-3">
                            <button
                              type="button"
                              className={`btn btn-success`}
                              disabled={this.state.disableValue}
                              onClick={this.ViewChart1}
                            >
                              SUBMIT
                            </button>
                          </div>
                        </div>
                        <ul class="nav nav-tabs">
                          <li class="nav-item">
                            <a
                              class="nav-link active"
                              data-toggle="tab"
                              href="#buying"
                            >
                              Buying
                            </a>
                          </li>
                          <li class="nav-item">
                            <a
                              class="nav-link"
                              data-toggle="tab"
                              href="#selling"
                            >
                              Selling
                            </a>
                          </li>
                        </ul>

                        <div class="tab-content">
                          <div id="buying" class="container tab-pane active">
                            <br />
                            <h3>Leads Vs Conversions</h3>
                            <div
                              className="row form-group"
                              style={{ width: "100%" }}
                            >
                              {this.state.formchart2 !== null
                                ? this.state.formchart2
                                : null}
                            </div>
                          </div>
                          <div id="selling" class="container tab-pane fade">
                            <br />
                            <h3>selling</h3>
                            <div
                              className="row form-group"
                              style={{ width: "100%" }}
                            >
                              {this.state.formchart2 !== null
                                ? this.state.formchart2
                                : null}
                            </div>
                          </div>
                        </div>
                        {/* </div> */}
                      </div>
                      {/* {this.state.formchart3 !== null
                        ? this.state.formchart3
                        : null} */}
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-6">
                        <div className="row form-group">
                          <div className="col-sm-1" />
                          <div className="col-sm-3">
                            <label htmlFor="exampleInputEmail1">
                              From-Date
                            </label>
                          </div>
                          <div className="col-sm-5">
                            <input
                              type="date"
                              className="form-control"
                              id="ColourName"
                              name="fromdate"
                              placeholder="Enter price amount"
                              value={this.state.fromdate}
                              onChange={this.handlechangeData}
                            />
                          </div>
                          <div className="col-sm-3" />
                        </div>

                        <div className="row form-group">
                          <div className="col-sm-1" />
                          <div className="col-sm-3">
                            <label htmlFor="exampleInputEmail1">To-Date</label>
                          </div>
                          <div className="col-sm-5">
                            <input
                              type="date"
                              className="form-control"
                              id="ColourName"
                              name="todate"
                              placeholder="Enter price amount"
                              value={this.state.todate}
                              onChange={this.handlechangeData}
                            />
                          </div>
                          <div className="col-sm-3" />
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-4" />
                          <div className="col-sm-3">
                            <button
                              type="button"
                              className={`btn btn-success`}
                              disabled={this.state.disableValue}
                              onClick={() => this.ViewChart2}
                            >
                              SUBMIT
                            </button>
                          </div>
                        </div>
                        {this.state.formchart3 !== null
                          ? this.state.formchart3
                          : null}
                      </div>
                      <div className="col-sm-6"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default ReportCharts;
