import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import DfCarsEnquiry from "../Evaluator/DfCarsEnquiry";
import SingleSelect from "../../../../components/Form/SingleSelect";
import moment from "moment";

class DfCarsAddEnquiry extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      customerId: "",
      alertVisible: false,
      textalert: "",
      leadname: "",
      editid: null,
      mobile: "",
      email: '',
      errormobile: "",
      erroremail: "",
      errorleadname: "",
      isEdit: false,
      Index: null,
      disableValue: false,
      status: '',
      errorstatus: "",
      Typelist: [{ label: "seller", value: "1" }, { label: "buyer", value: 2 }],
      type: {},
      EnquiryData: [],
      Budget: "",
      sources: '',
      Next_Contact_date: "",
      Model: "",
      CloseEnquiry: false,
      comments1: "",
      ModelData: [],
      MakeData: [],
      ColourList: [],
      colour: {},
      userid: JSON.parse(localStorage.getItem("userlog"))
    };
  }

  handlechangeData = async (e) => {
    //  if(e.target.type=="date"){
    //    console.log(e.target.value);
    //  }
    this.setState({ [e.target.name]: e.target.value });
  };
  handleDescription = async e => {
    this.setState({ type: e });
  }

  submitCategory = async () => {
    const { leadname, mobile, email, type, status, customerId, Budget, sources, Model, Next_Contact_date } = this.state;
    console.log(this.state.userid.id)
    let groupArray = {};
    groupArray.name = leadname;
    groupArray.mobile = mobile;
    groupArray.email = email;
    groupArray.type = type.label;
    groupArray.status = status;
    groupArray.Budget = Budget;
    groupArray.Model = this.state.model.value;
    groupArray.Make = this.state.make.value;
    groupArray.Next_Contact_date = Next_Contact_date;
    groupArray.sources = sources;
    groupArray.companyid = this.state.customerId;
    groupArray.enquiry_status = 'active';
    groupArray.color = this.state.colour.value;
    groupArray.userid = this.state.userid.id;

    try {
      this.setState({ disableValue: true });
      const result = await cmsContent.addMaster("tbl_dfcarz_enquiryform", groupArray);
      console.log(result);
      if (result) {
        groupArray.id = result.data.insertId;
        //  const newData = [groupArray, ...this.state.ColourList];
        let newData = [];
        if (this.state.EnquiryData.length) {
          groupArray.createdAt = new Date();
          groupArray.createdAt = moment(groupArray.createdAt).format("DD-MM-YYYY");
          newData = [groupArray, ...this.state.EnquiryData];
        } else {
          newData.push(groupArray);
        }
        this.props.these("EnquiryData", newData);
        this.setState({
          // data: newData,
          alertVisible: true,
          disableValue: false,
          textalert: "New Enquiry Added",
          color: "success",
          errorColourName: "",
          leadname: "",
          errorstatus: "",
          errormobile: '',
          erroremail: '',
          status: '',
          mobile: '',
          email: '',
          type: {},
          ModelData: [],
          model: {},
          make: {},
          colour: {}

        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  onDismiss = () => {
    this.setState({ alertVisible: false, formAlertdelete: false });
  };

  async componentWillReceiveProps(props) {
    try {
      if (props) {
        console.log(props);

        let user = JSON.parse(localStorage.getItem(`userlog`));
        this.setState({ customerId: user.id })
        this.setState({ EnquiryData: props.EnquiryData, isEdit: props.isEdit });
        let Make = await cmsContent.getFreedom(
          `Make as label , id as value`,
          `tbl_make_master`,
          1
        );

        let Colour = await cmsContent.getFreedom(
          `colour as label , id as value`,
          `tbl_colour`,
          1
        );

        //console.log(Colour)
        this.setState({ MakeData: Make.data, ColourList: Colour.data });
        if (props.CloseEnquiry == true) {
          let d = props.EditData;
          this.setState({ CloseEnquiry: props.CloseEnquiry, EditData1: d, comments: "" })
        } else if (props.CloseEnquiry == false) {
          if (props.isEdit == true) {
            //this.setState({EditData:props.EditData});
            let d = props.EditData;
            this.state.Typelist.map((ival, i) => {
              console.log(ival)
              if (d.type == ival.label) {
                //console.log(ival)
                this.setState({ type: ival })
              }
            });

            let wait3 = await Colour.data.map(async (ival, i) => {
              //console.log(ival);
              if (ival.value == d.color) {
                console.log(ival);
                this.setState({ colour: ival });
              }
            });

            await Promise.all(wait3);

            let wait = await Make.data.map(async (ival, i) => {
              if (ival.value == d.Make) {
                //console.log(d.Make,ival)
                this.setState({ make: ival });
                let Model = await cmsContent.getFreedom(
                  `Model as label , id as value`,
                  `tbl_dfcarsmodel`,
                  `makeid=${d.Make}`
                );
                if (Model.data.length) {

                  let wait1 = await Model.data.map(async (jval, j) => {
                    // console.log(jval);
                    if (d.Model == jval.value) {

                      this.setState({ model: jval });
                    }
                  });

                  await Promise.all(wait1);


                }
                this.setState({ ModelData: Model.data });

              }
            })
            await Promise.all(wait);


            this.setState({
              leadname: d.name,
              mobile: d.mobile,
              email: d.email,
              status: d.status,
              editid: d.id,
              Index: props.Index,
              DataEDit: d,
              comments: d.comments,
              sources: d.sources,
              Budget: d.Budget,
              // Model:d.Model,
              Next_Contact_date: d.Next_Contact_date
            })
          } else {
            this.setState({
              leadname: "",
              mobile: "",
              email: "",
              status: "",
              editid: "",
              comments: "",
              isEdit: false,
              type: {},
              Budget: "",
              sources: "",
              Model: {},
              model: {},
              Next_Contact_date: "",
              make: {}
            })
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  async componentDidMount() {
    console.log(this.props);

    let Make = await cmsContent.getFreedom(
      `*`,
      `tbl_make_master`,
      1
    );
    console.log(Make);
  }

  UpdateCategory = async () => {
    const { leadname, Budget, sources, Model, Next_Contact_date, mobile, email, Index, comments, type, status, customerId } = this.state;
    var d = new Date();
    d = moment(d).format("DD-MM-YYYY");
    // console.log(d);
    let subgroupArray = {};
    subgroupArray.status = status;
    subgroupArray.comments = comments;
    subgroupArray.type = type.label;
    subgroupArray.Model = this.state.model.value;
    subgroupArray.Make = this.state.make.value;
    subgroupArray.Next_Contact_date = Next_Contact_date;
    subgroupArray.Budget = Budget;
    subgroupArray.sources = sources;
    subgroupArray.color = this.state.colour.value;
    //subgroupArray.enquiry_status='active'
    console.log(subgroupArray);
    try {
      const result = await cmsContent.updateMaster(
        "tbl_dfcarz_enquiryform",
        this.state.editid,
        subgroupArray
      );
      console.log(result);
      if (result) {
        subgroupArray.name = leadname;
        subgroupArray.mobile = mobile;
        subgroupArray.email = email;
        subgroupArray.type = type.label;
        // subgroupArray.Budget=Budget;
        // subgroupArray.Make=this.state.make.value;
        // subgroupArray.sources=sources;
        // subgroupArray.Model=this.state.model.value;
        subgroupArray.id = this.state.editid;
        // subgroupArray.Next_Contact_date=Next_Contact_date;
        subgroupArray.companyid = this.state.customerId;
        subgroupArray.createdAt = this.state.DataEDit.createdAt;
        //subgroupArray.enquiry_status='active'
        let previousdata = [...this.state.EnquiryData];
        previousdata[Index] = subgroupArray;
        // console.log(previousdata)
        this.props.these('EnquiryData', previousdata);
        let groupArray = {};
        groupArray.status = status;
        groupArray.comments = comments;
        groupArray.enquiryid = this.state.editid;
        groupArray.userid = this.state.customerId;
        groupArray.Next_Contact_date = Next_Contact_date;
        groupArray.Budget = Budget;
        groupArray.sources = sources;
        groupArray.Model = this.state.model.value;
        // console.log(groupArray);
        const history = await cmsContent.addMaster("tbl_dfcars_enquiryhistory", groupArray);
        console.log(history)
        this.setState({
          // data: newData,
          alertVisible: true,
          disableValue: false,
          textalert: "Enquiry Updated",
          color: "success",
          errorColourName: "",
          leadname: "",
          errorstatus: "",
          errormobile: '',
          erroremail: '',
          status: '',
          mobile: '',
          email: '',
          type: {},
          comments: "",
          Model: {},
          model: {},
          make: {},
          Budget: "",
          isEdit: false,
          Next_Contact_date: "",
          sources: "",
          colour: {}
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  }

  CloseEnquiry = async () => {
    const { comments1, CloseEnquiry, EditData1 } = this.state;
    console.log(EditData1);
    let groupArray = {};
    groupArray.enquiry_status = 'inactive';
    groupArray.comments = comments1;
    try {
      const result = await cmsContent.updateMaster(
        "tbl_dfcarz_enquiryform",
        EditData1.id,
        groupArray
      );
      console.log(result);
      if (result) {
        let groupArray1 = {};
        groupArray1.comments = comments1;
        groupArray1.enquiryid = EditData1.id;
        groupArray1.userid = this.state.customerId;
        const history = await cmsContent.addMaster("tbl_dfcars_enquiryhistory", groupArray1);
        let previousdata = [];
        this.state.EnquiryData.map((ival, i) => {
          if (ival.id != EditData1.id) {
            previousdata.push(ival);
          }
        })
        this.props.these('EnquiryData', previousdata);
        this.props.these('CloseEnquiry', false);
        this.setState({
          comments: "",
          CloseEnquiry: false,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  handleDescription1 = async (e) => {
    // console.log(e)
    this.setState({ make: e });
    let Model = await cmsContent.getFreedom(
      `Model as label , id as value`,
      `tbl_dfcarsmodel`,
      `makeid=${e.value}`
    );
    if (Model.data.length) {
      //console.log(Model);
      this.setState({ ModelData: Model.data })
    }
    // console.log(Model);
  }

  handleModel = async (e) => {
    this.setState({ model: e });
  }
  handleColour = async (e) => {
    this.setState({ colour: e });
  }






  render() {
    const { alertVisible, textalert, CloseEnquiry } = this.state;
    if (CloseEnquiry) {
      return (<React.Fragment>
        <main >
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Close Enquiry</h1>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-1" />
                      <div className="col-sm-3">
                        <label htmlFor="exampleInputEmail1">Reason for Closing</label>
                      </div>
                      <div className="col-sm-5">
                        <textarea
                          type="text"
                          className="form-control"
                          id="ColourName"
                          name="comments1"
                          placeholder="Enter Comments"
                          value={this.state.comments1}
                          onChange={this.handlechangeData}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-3" />
                      <div className="col-sm-5">
                        <button
                          type="button"
                          className={`btn btn-danger`}
                          disabled={this.state.disableValue}
                          onClick={() => this.CloseEnquiry()}
                        >
                          Close Enquiry
                        </button>
                      </div>
                      <div className="col-sm-2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
      )
    } else {
      return (<React.Fragment>
        <main >
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Enquiry Master</h1>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-7">
                        <Alert
                          className="badge-content"
                          color={this.state.color}
                          isOpen={alertVisible}
                          toggle={this.onDismiss}
                        >
                          {textalert}
                        </Alert>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    {this.state.isEdit == false ?
                      <React.Fragment>
                        <div className="row form-group">
                          <div className="col-sm-1" />
                          <div className="col-sm-3">
                            <label htmlFor="exampleInputEmail1">Leads Name</label>
                          </div>
                          <div className="col-sm-5">
                            <input
                              type="text"
                              className="form-control"
                              id="ColourName"
                              name="leadname"
                              placeholder="Enter Leads Name"
                              value={this.state.leadname}
                              onChange={this.handlechangeData}
                            />
                          </div>
                          <div className="col-sm-3" />
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-4" />
                          <span className="error-show ">
                            {this.state.errorleadname}
                          </span>
                        </div>

                        <div className="row form-group">
                          <div className="col-sm-1" />
                          <div className="col-sm-3">
                            <label htmlFor="exampleInputEmail1">Mobile Number</label>
                          </div>
                          <div className="col-sm-5">
                            <input
                              type="text"
                              className="form-control"
                              id="ColourName"
                              name="mobile"
                              placeholder="Enter MobileNumber"
                              value={this.state.mobile}
                              maxlength="10"
                              onChange={this.handlechangeData}
                            />
                          </div>
                          <div className="col-sm-3" />
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-4" />
                          <span className="error-show ">
                            {this.state.errormobile}
                          </span>
                        </div>

                        <div className="row form-group">
                          <div className="col-sm-1" />
                          <div className="col-sm-3">
                            <label htmlFor="exampleInputEmail1">Email-Id</label>
                          </div>
                          <div className="col-sm-5">
                            <input
                              type="text"
                              className="form-control"
                              id="ColourName"
                              name="email"
                              placeholder="Enter Email-Address"
                              value={this.state.email}
                              onChange={this.handlechangeData}
                            />
                          </div>
                          <div className="col-sm-3" />
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-4" />
                          <span className="error-show ">
                            {this.state.erroremail}
                          </span>
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-1" />
                          <div className="col-sm-3">
                            <label htmlFor="exampleInputEmail1">Type</label>
                          </div>
                          <div className="col-sm-5">
                            <SingleSelect
                              name="TaxList"
                              options={this.state.Typelist}
                              handleChange={d => this.handleDescription(d)}
                              selectedService={this.state.type}
                            />
                          </div>
                        </div> </React.Fragment> : null}


                    <div className="row form-group">
                      <div className="col-sm-1" />
                      <div className="col-sm-3">
                        <label htmlFor="exampleInputEmail1">Make</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          name="make"
                          options={this.state.MakeData}
                          handleChange={d => this.handleDescription1(d)}
                          selectedService={this.state.make}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-1" />
                      <div className="col-sm-3">
                        <label htmlFor="exampleInputEmail1">Model</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          name="model"
                          options={this.state.ModelData}
                          handleChange={d => this.handleModel(d)}
                          selectedService={this.state.model}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-1" />
                      <div className="col-sm-3">
                        <label htmlFor="exampleInputEmail1">Colour</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          name="model"
                          options={this.state.ColourList}
                          handleChange={d => this.handleColour(d)}
                          selectedService={this.state.colour}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-1" />
                      <div className="col-sm-3">
                        <label htmlFor="exampleInputEmail1">Sources</label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type="text"
                          className="form-control"
                          id="ColourName"
                          name="sources"
                          placeholder="Enter sources"
                          value={this.state.sources}
                          onChange={this.handlechangeData}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-1" />
                      <div className="col-sm-3">
                        <label htmlFor="exampleInputEmail1">Budget</label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type="text"
                          className="form-control"
                          id="ColourName"
                          name="Budget"
                          placeholder="Enter price amount"
                          value={this.state.Budget}
                          onChange={this.handlechangeData}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-1" />
                      <div className="col-sm-3">
                        <label htmlFor="exampleInputEmail1">Next-Followup</label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type="date"
                          className="form-control"
                          id="ColourName"
                          name="Next_Contact_date"
                          placeholder="Enter price amount"
                          value={this.state.Next_Contact_date}
                          onChange={this.handlechangeData}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-1" />
                      <div className="col-sm-3">
                        <label htmlFor="exampleInputEmail1">Status</label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type="text"
                          className="form-control"
                          id="ColourName"
                          name="status"
                          placeholder="Enter status"
                          value={this.state.status}
                          onChange={this.handlechangeData}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">
                        {this.state.errorstatus}
                      </span>
                    </div>

                    {this.state.isEdit ? <React.Fragment>
                      <div className="row form-group">
                        <div className="col-sm-1" />
                        <div className="col-sm-3">
                          <label htmlFor="exampleInputEmail1">Comments</label>
                        </div>
                        <div className="col-sm-5">
                          <textarea
                            type="text"
                            className="form-control"
                            id="ColourName"
                            name="comments"
                            placeholder="Enter Comments"
                            value={this.state.comments}
                            onChange={this.handlechangeData}
                          />
                        </div>
                        <div className="col-sm-3" />
                      </div>
                      <div className="row form-group">
                        <div className="col-sm-4" />
                        <span className="error-show ">
                          {this.state.errorcommet}
                        </span>
                      </div>
                    </React.Fragment> : null}

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-3" />
                      <div className="col-sm-5">
                        <button
                          type="button"
                          className={`btn btn-${
                            this.state.isEdit ? "secondary" : "primary"
                            }`}
                          disabled={this.state.disableValue}
                          onClick={
                            this.state.isEdit
                              ? this.UpdateCategory
                              : this.submitCategory
                          }
                        >
                          {this.state.isEdit
                            ? "Update Enquiry"
                            : "Add New Enquiry"}
                        </button>
                      </div>
                      <div className="col-sm-2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>)
    }
  }
}

export default DfCarsAddEnquiry;

