import React, { Component } from "react";
import { Alert } from "reactstrap";
import CmsContent from "../../../../MiddleWare/CmsContent";
import Datatable from "../../../../components/Datatable/Datatable";
import { FormMiddleWare, MultiSelect } from "../../../../components/Form";
import { confirmAlert } from "react-confirm-alert";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import LoginModal from "../../../../components/Modal/Modal";
//import SFAPDF from "../../../components/PDF/SFAPDF";
import redisMiddleware from "../../../../MiddleWare/redisMiddleware";
import moment from "moment";
import DfCarsAddEnquiry from "../Screen/DfCarsAddEnquiry";
import { alert } from 'react-icons-kit/ionicons/alert'
import cmsContent from "../../../../MiddleWare/CmsContent";
//import CheckListJSON from "./Dfcars350checklist.json"
import Icon from "react-icons-kit";

import { calendar_ok } from 'react-icons-kit/ikons/calendar_ok'
import { SingleSelect } from "../../../../components/Form";
import { user_delete } from "react-icons-kit/ikons/user_delete";



class UserEnquiryreport extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            AllocateType: "",
            data: [],
            Datas: [],
            statusbtn: false,
            bulkAllocate: false,
            reallocatebtn: false,
            Current_Date: new Date(),
            alertVisible: false,
            alertVisible1: false,
            editvalue: "",
            editid: null,
            MakeOptions: [],
            unallocated: [],
            coloropt: [],
            variantopt: [],
            followupDateVisible: false,
            Next_Contact_date: "",
            modelopt: [],
            isEdit: false,
            EnquiryData: [],
            workproc: false,
            LocationDetail: [],
            EditData: {},
            FilteredDatas: [],
            EnquiryDatas: [],
            Index: null,
            ftdval: false,
            Show: false,
            loading: false,
            customerId: localStorage.getItem("userId"),
            usertypelog: JSON.parse(localStorage.getItem("usertypelog")),
            status1options: [
                { value: 0, label: "Scheduled Follow up" },
                { label: "Scheduled Evaluation", value: 1 },
                { value: 2, label: "Mark Closed" },
                // {value:2,label:"Evaluated"},
            ],
            status2options: {},
            status3options: {},
            status1: {},
            EvalutorSelect: [],
            statusArray: [],
            ftdDashDate: moment(new Date()).format("YYYY-MM-DD"),
            status2: {},
            status3: {},
            StatusupdatealertVisible: false,
            CloseEnquiry: false,
            StatusEditData: {},
            column: [
                {
                    Header: "View Status",
                    accessor: "edit",
                    width: 110,
                    Cell: d => this.StatusView(d),
                    className: "text-center"
                },
                {
                    Header: "UpdateStatus",
                    accessor: "edit",
                    width: 110,
                    Cell: d => this.UpdateStatus(d),
                    className: "text-center"
                },
                {
                    Header: "View Details",
                    accessor: "view",
                    width: 120,
                    Cell: d => this.fullview(d),
                    className: "text-center"
                },
                {
                    Header: "Name",
                    accessor: "name"
                },
                {
                    Header: "Mobile",
                    accessor: "mobile",
                    Cell: d => this.Call(d.original.mobile)
                },

                // {
                //   Header: "Email_id",
                //   accessor: "email"
                // },
                {
                    Header: "Type",
                    accessor: "type"
                },
                {
                    Header: "EnquriryDate",
                    accessor: "createdAt"
                },
                {
                    Header: "Make",
                    accessor: "make",
                    Cell: (d) => this.getValueFromArray(d.original.Make, this.state.MakeOptions)

                },
                {
                    Header: "Model",
                    accessor: "model",
                    Cell: (d) => this.getValueFromArray(d.original.Model, this.state.modelopt)
                },
                {
                    Header: "Variant",
                    accessor: "variant",
                    Cell: (d) => this.getValueFromArray(d.original.Variant, this.state.variantopt)

                },
                {
                    Header: "Location",
                    accessor: "location",
                    Cell: (d) => this.getValueFromArray(d.original.location, this.state.locationOpt)

                },
                {
                    Header: "Color",
                    accessor: "color",
                    Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)

                },
                // {
                //   Header: "Price",
                //   accessor: "Budget"
                // },
                {
                    Header: "Evalutor Name",
                    minWidth: 180,
                    accessor: "Evalutor_employee_name"
                },
                // {
                //     Header: "Reallocate Dotcue",
                //     // minWidth: 180,
                //     width: 150,

                //     accessor: "edit",
                //     Cell: d => this.Reallocate(d),
                // },
                // {
                //     Header: "View History",
                //     accessor: "edit",
                //     width: 110,
                //     Cell: d => this.ViewLead(d),
                //     className: "text-center"
                // },
                //   {
                //     Header: "Convert to Prospect",
                //     accessor: "edit",
                //     Cell: d => this.ViewLead1(d)
                //   },
                //   {
                //     Header: "Close Enquiry",
                //     accessor: "type",
                //     Cell: d => this.CloseEnquiry(d)
                //   },

            ],
            statuscolumn: [
                {
                    Header: "View Status",
                    accessor: "edit",
                    Cell: d => this.StatusView(d),
                    className: "text-center"
                },
                {
                    Header: "UpdateStatus",
                    accessor: "edit",
                    Cell: d => this.UpdateStatus(d),
                    className: "text-center"
                },
                {
                    Header: "View Details",
                    accessor: "view",
                    width: 150,
                    Cell: d => this.fullview(d),
                    className: "text-center"
                },
                {
                    Header: "Name",
                    accessor: "name"
                },
                {
                    Header: "Mobile",
                    accessor: "mobile",
                    Cell: d => this.Call(d.original.mobile)
                },
                {
                    Header: "Type",
                    accessor: "type"
                },
                {
                    Header: "EnquriryDate",
                    accessor: "createdAt"
                },
                {
                    Header: "Make",
                    accessor: "make",
                    Cell: (d) => this.getValueFromArray(d.original.Make, this.state.MakeOptions)

                },
                {
                    Header: "Model",
                    accessor: "model",
                    Cell: (d) => this.getValueFromArray(d.original.Model, this.state.modelopt)
                },
                {
                    Header: "Variant",
                    accessor: "variant",
                    Cell: (d) => this.getValueFromArray(d.original.Variant, this.state.variantopt)

                },
                {
                    Header: "Location",
                    accessor: "location",
                    Cell: (d) => this.getValueFromArray(d.original.location, this.state.locationOpt)

                },
                {
                    Header: "Color",
                    accessor: "color",
                    Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)

                },
                {
                    Header: "Evalutor Name",
                    minWidth: 180,
                    accessor: "Evalutor_employee_name"
                },
                {
                    Header: "Status",
                    accessor: "pendingStatus1"
                },
                // {
                //     Header: "Reallocate Dotcue",
                //     accessor: "edit",
                //     Cell: d => this.Reallocate(d),
                // },
                // {
                //     Header: "View History",
                //     accessor: "edit",
                //     Cell: d => this.ViewLead(d),
                //     className: "text-center"
                // },
            ],
            excelColumn: [
                {
                    label: "Name",
                    key: "name"
                },
                {
                    label: "Mobile",
                    key: "mobile"
                },

                {
                    label: "Type",
                    key: "type"
                },
                {
                    label: "EnquriryDate",
                    key: "createdAt"
                },
                {
                    label: "Make",
                    key: "makeName",

                },
                {
                    label: "Model",
                    key: "modelName",
                },
                {
                    label: "Variant",
                    key: "variantName",

                },
                {
                    label: "Location",
                    key: "locationname",

                },
                {
                    label: "Color",
                    key: "colourName",

                },
                {
                    label: "Evalutor Name",
                    key: "Evalutor_employee_name"
                },

            ],
            column1: [
                {
                    Header: "Name",
                    accessor: "name"
                },
                {
                    Header: "Reg No",
                    accessor: "Reg_no",
                },
                {
                    Header: "Mail",
                    accessor: "email"
                },
                {
                    Header: "Mobile",
                    accessor: "mobile",
                    Cell: d => this.Call(d.original.mobile)
                },
                {
                    Header: "Type",
                    accessor: "type"
                },
                {
                    Header: "EnquriryDate",
                    accessor: "createdAt"
                },
                {
                    Header: "Make",
                    accessor: "make",
                    Cell: (d) => this.getValueFromArray(d.original.Make, this.state.MakeOptions)
                },
                {
                    Header: "Model",
                    accessor: "model",
                    Cell: (d) => this.getValueFromArray(d.original.Model, this.state.modelopt)
                },
                {
                    Header: "Variant",
                    accessor: "variant",
                    Cell: (d) => this.getValueFromArray(d.original.Variant, this.state.variantopt)
                },
                {
                    Header: "Color",
                    accessor: "color",
                    Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)
                },
                {
                    Header: "Enquiry From",
                    accessor: "source",
                    width: 120,
                    Cell: (d) => this.fromEnq(d.original)
                },
                {
                    Header: "Allocate",
                    accessor: "edit",
                    Cell: (d) => this.DatatableCheckboxx(d, this.updateMyData, this.state.SelectedEnquiries)
                }
            ],
            excelColumn1: [
                {
                    label: "Name",
                    key: "name"
                },
                {
                    label: "Reg No",
                    key: "Reg_no",
                },
                {
                    label: "Mail",
                    key: "email"
                },
                {
                    label: "Mobile",
                    key: "mobile",
                },
                {
                    label: "Type",
                    key: "type"
                },
                {
                    label: "EnquriryDate",
                    key: "createdAt"
                },
                {
                    label: "Make",
                    key: "makeName",
                },
                {
                    label: "Model",
                    key: "modelName",
                },
                {
                    label: "Variant",
                    key: "variantName",
                },
                {
                    label: "Color",
                    key: "colourName",
                },
            ],
            LocationID: localStorage.getItem(`LocationID`),
            SelectedEnquiries: [],
            valueofstatus: [{ "live": 0, "close": 0, "evaluated": 0, "today": 0, "MTD": 0, 'rmainder': 0 }],
            CustomerList: [],
            locationOpt: [],
            filteredLoc: "",
            filteredMake: "",
            FilterData: [],
        }
        // this.vals()
    }
    fromEnq = (d) => {
        if (d.enquiry_Source == "web") {
            return (
                <button className="btn btn-sm btn-success" style={{ width: "100px" }}>Web</button>
            )
        }
        else if (d.enquiry_Source == "csv") {
            return (
                <button className="btn btn-sm btn-danger" style={{ width: "100px" }}>CSV</button>
            )
        }
        else {
            return (
                <button className="btn btn-sm btn-primary" style={{ width: "100px" }}>Site</button>
            )
        }
    }
    Usertype = (d) => {
        return (
            <div style={{ 'text-align': 'center' }}>
                {d.original.updatedby ? d.original.updatedby : "-"}
            </div>)
    };
    async vals() {
        let decr = this.Decrypt(localStorage.getItem('getcar'))
        let allOpt = JSON.parse(decr)
        if (allOpt) {
            this.setState({
                MakeOptions: allOpt.makeopt,
                coloropt: allOpt.coloropt,
                variantopt: allOpt.variantopt,
                modelopt: allOpt.modelopt,
                locationOpt: allOpt.locopt
            })
        }
        let { data: dataval1 } = await CmsContent.getFreedom(
            "*",
            `tbl_dfcarz_enquiryform`,
            `enquiry_status='active' and location =${this.state.LocationID} AND (pendingStatus1='Closed' OR pendingStatus1='Yes, but not interested, Mark closed' OR pendingStatus1='Couldn’t speak to customer')`,
            1,
            `id DESC`
        );
        if (dataval1) {
            this.setState({ dataval: dataval1 })
        }
    }
    DatatableCheckboxx = (d, func, SelectedEnquiries) => {
        // console.log(d, "SelectedEnquiries[d.original.id]");
        return (
            <div style={{ 'text-align': 'center' }}>
                <input type="checkbox"
                    checked={SelectedEnquiries && SelectedEnquiries[d.original.id] &&
                        SelectedEnquiries[d.original.id] == true ? true : false}
                    onChange={(event) => func(parseInt(d.original.id), d.column.id, event.target.checked ? "Yes" : "No")} />
            </div>)
    };

    updateMyData = async (d, f, event) => {
        let { SelectedEnquiries } = this.state
        // console.log(d, "d", event, "event");
        let AllocateType = "unallocate"
        const index = SelectedEnquiries.indexOf(d)
        if (index > -1 && event == "No") {
            SelectedEnquiries.splice(index, 1)
            SelectedEnquiries[d] = false
            if (index == 0) {
                SelectedEnquiries.length = 0;
            }

        }
        else {
            SelectedEnquiries.push(d)
            SelectedEnquiries[d] = true
        }
        this.setState({ SelectedEnquiries, AllocateType, reallocatebtn: false })

    }

    MainFunction = async () => {
        let { usertypelog } = this.state

        this.setState({ loading: true, EnquiryDatas: [] })
        let user = JSON.parse(localStorage.getItem(`userlog`));
        console.log(user, "user");
        let id = user.id
        // let Evalopt
        // if (user) {
        //     Evalopt = await CmsContent.getFreedom(
        //         `tbl_user_web.*,tbl_user_web.userName as label,tbl_user_web.id as value`,
        //         `tbl_user_web`,
        //         // `tbl_dfcars_comments.customerid = ${d.original.id} and tbl_dfcars_comments.stage='actionplan'and tbl_user_web.id = tbl_dfcars_comments.userid and tbl_user_web.userportid = tbl_MaptoLocation.id and tbl_UserType.id = tbl_MaptoLocation.userTypeId`,
        //         `tbl_user_web.downline=${user.downline} and tbl_user_web.userType=123`,
        //         1,
        //         `tbl_user_web.id DESC`,
        //     );
        //     console.log(Evalopt, "Evalopt form get freedom");
        // }
        console.log(id, "id");
        let logos = await cmsContent.getDfCarsTeleBuyingEnquiry(id, "seller")
        console.log(logos, "logos");

        this.setState({ EnquiryData: logos.statusdata[0].fulldata })
        let data = []
        console.log(logos, "API")
        let valueofstatus = logos.status
        let fulldata = []
        if (logos.statusdata[0].fulldata && logos.statusdata[0].fulldata.length > 0) {
            fulldata = logos.statusdata[0].fulldata
        }
        console.log(fulldata, "fulldata");

        let Evalopt = await this.decryption("Downline_Details")
        console.log(Evalopt, "Evalopt");
        let unallocated = []
        if (usertypelog.orderBy == 1) {
            unallocated = fulldata.length ? fulldata.filter(e => e.filterunallocated == true) : []
        }
        else if (usertypelog.orderBy == 2) {
            if (fulldata.length > 0) {
                unallocated = await fulldata.filter(e => e.filterunallocated == true && (user.id == e.updatedby || user.id == e.downline))
                await fulldata.filter((e) => {
                    if (e.filterunallocated == true && e.updatedby == null && user.locationid == e.location && user.sub_loc_id == e.branch) {
                        unallocated.push(e)
                    }
                })
            } else {
                unallocated = []
            }
        }
        else if (usertypelog.orderBy == 3 || usertypelog.orderBy == 15) {
            if (fulldata.length > 0) {
                unallocated = await fulldata.filter(e => e.filterunallocated == true && (user.id == e.updatedby || user.downline == e.updatedby))
                await fulldata.filter((e) => {
                    if (e.filterunallocated == true && e.updatedby == null && user.locationid == e.location && user.sub_loc_id == e.branch) {
                        unallocated.push(e)
                    }
                })
            } else {
                unallocated = []
            }
        }
        else {
            unallocated = fulldata.length ? fulldata.filter(e => e.filterunallocated == true) : []
        }
        console.log(Evalopt, "Evalopt main");
        await this.setState({
            livedata: fulldata.length ? fulldata.filter(e => e.filterfollowup == true) : [],
            closeddata: fulldata.length ? fulldata.filter(e => e.filterclosed == true) : [],
            noncontact: fulldata.length ? fulldata.filter(e => e.filternoncontact == true) : [],
            data1: fulldata.length ? fulldata.filter(e => e.filterToday == true) : [],
            dataof: fulldata.length ? fulldata.filter(e => e.filtermtd == true) : [],
            unallocated: unallocated,
            valueofstatus,
            ftddatas: logos.statusdata[0].ftd,
            remainder: logos.statusdata[0].remainder,
            evalopt: Evalopt && Evalopt.EVALUTOROPT ? Evalopt.EVALUTOROPT : [],
            loading: false,
        });
    }
    async componentDidMount() {
        if (localStorage.getItem('filled')) {
            window.$('#status').modal('show')
        }

    }
    async componentWillMount() {
        this.setState({ loading: true })
        this.vals()
        this.MainFunction()
    }

    ViewLead = (d) => {
        return (<center><button type="button" className="btn btn-sm btn-primary"
            onClick={() => this.ViewHistory(d)}
        >View</button></center>)
    }

    CloseEnquiry = (d) => {
        return (<button type="button" className="btn btn-sm btn-danger"
            data-toggle="modal" data-target={"#status"}
            onClick={() => this.ClosethisEnquiry(d)}
        >Close</button>)
    }

    ViewLead1 = (d) => {
        // if(d.original.intabel==1){
        //   return(<button type="button" className="btn btn-danger"
        //   >Added</button>)
        // }else{
        return (<button type="button" className="btn btn-sm btn-success"
            onClick={() => this.ViewHistory2(d)}
        >Add</button>)
        //  }
    }

    ClosethisEnquiry = async (d) => {
        this.setState({ CloseEnquiry: true })
        this.setState({ Index: d.index, EditData: d.original });
    }

    UpdateStatus = (d) => {
        return (<button type="button" className="btn btn-sm btn-warning"
            data-toggle="modal" data-target={"#status"}
            onClick={() => this.ViewHistory1(d)}
        >Edit</button>)
    }
    fullview = (d) => {
        return (<button type="button" className="btn btn-sm btn-success"
            data-toggle="modal" data-target={"#fullview"}
            onClick={() => this.ViewHistory1(d)}
        >Details</button>)
    }

    changeModelstate = async (s, v) => {
        this.setState({ [s]: v });
    }

    ViewHistory2 = async (d) => {
        let data = d.original;
        let groupArray = {};
        groupArray.name = data.name;
        groupArray.mobile = data.mobile;
        groupArray.email = data.email;
        groupArray.status = "active";
        groupArray.companyid = this.state.customerId;
        groupArray.enquiry_id = data.id;
        groupArray.userid = this.state.UserId;
        groupArray.type = data.type;
        groupArray.model = data.Model;
        groupArray.make = data.Make;
        groupArray.variant = data.variant;
        groupArray.budget = data.Budget;
        groupArray.color = data.color;
        groupArray.prospective_status = "active";
        groupArray.location = data.location;
        groupArray.salesid = data.salesid;
        groupArray.variant = data.variant;
        groupArray.evaluatorid = data.evaluatorid;
        let DaTE = new Date();
        groupArray.followup = moment(DaTE).format("YYYY-MM-DD");
        console.log(groupArray)
        try {
            const history = await CmsContent.addMaster("tbl_dfcarz_prosperities", groupArray);
            if (history) {
                let subgroupArray = {}
                subgroupArray.enquiry_status = 'inactive';
                const result = await CmsContent.updateMaster(
                    "tbl_dfcarz_enquiryform",
                    data.id,
                    subgroupArray
                );
                let newData = []
                this.state.EnquiryData.map((ival, i) => {
                    if (ival.id != data.id) {
                        newData.push(ival);
                    }
                })
                // console.log(d.index)
                //newData[d.index].intabel=1;
                this.setState({
                    EnquiryData: newData
                })
            }
        } catch (error) {
            console.log(error)
        }

    }

    ViewHistory1 = async (d) => {
        this.setState({ Index: d.index });
        this.setState({ isEdit: true, EditData: d.original })
    }

    ViewHistory = async (d) => {
        window.open(`/DFCars/DfCarsViewhistory/${d.original.id}`, '_self')
    }
    reset = async () => {
        localStorage.removeItem('filled')
        this.setState({ isEdit: false })
    }
    dashArr = [
        { name: "Scheduled Appointments", color: "#013220", value: 1, label: "live" },
        { name: "FTD Enquiry", color: "red", value: 6, label: "ftd" },
        { name: "MTD Enquiry", color: "#013220", value: 5, label: "MTD" },
        { name: "Today's Follow up", color: "orange", value: 4, label: "today" },
        { name: "Remainders", color: "blue", value: 3, label: "remainder" },
        { name: "Closed Enquiry", color: "#013220", value: 2, label: "close" },
    ]
    edash = () => {

        return (<div className="">
            <div className="card">
                {/* < div className="row" > */}
                <div className="card-header">
                    <div className="col-sm-6" >
                        <h2 className="mt-2 black">Dashboard</h2>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row col-12">
                        <div className="col-sm" ><center>
                            <div className="col-sm-4">
                                <LoginModal
                                    buttonTitle="Add Enquiry"
                                    title="Enquiry"
                                    id="status"
                                    extraStyle={{ width: '100%' }}
                                    //extraClass="btn btn-primary"
                                    reset={this.reset}
                                    bodyText={
                                        <DfCarsAddEnquiry
                                            EnquiryData={this.state.EnquiryData}
                                            these={this.changeModelstate}
                                            isEdit={this.state.isEdit}
                                            EditData={this.state.EditData}
                                            Index={this.state.Index}
                                            mainFun={this.MainFunction}
                                            CloseEnquiry={this.state.CloseEnquiry}
                                            LocationDetail={
                                                this.state.LocationDetail.length
                                                    ? this.state.LocationDetail[0].id
                                                    : null
                                            }
                                            suggestion={this.state.suggestion}
                                            suggestion1={this.state.suggestion1}
                                            CustomerList={this.state.CustomerList}
                                        />
                                    }
                                />
                            </div>
                        </center>
                        </div>
                    </div><br />
                    <div className="row col-12 mt-4 text-center">
                        <div className="col ">
                            <div className="dashboardCard1" onClick={() => this.onClk(5)}>
                                <Icon icon={calendar_ok} style={{ color: 'green' }} size={35} />
                                <h4 className="mt-2 black" >MTD Enquiry</h4>
                                <div>
                                    <h1 className="bold mt-4 mb-2 black"><b> {this.state.valueofstatus[0].MTD ? this.state.valueofstatus[0].MTD : 0}</b></h1>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col ">
              <div className="dashboardCard1" onClick={() => this.onClk(6)} >
                <Icon icon={calendar_ok} style={{ color: 'green' }} size={35} />
                <h4 className="mt-2 black" >FTD Enquiry</h4>
                <div>
                  <div>
                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.ftd && this.state.ftd != null && this.state.ftdval == true ? this.state.ftd : this.state.ftdval == false && this.state.valueofstatus[0].ftd}</b></h1>

                  </div>
                  <div>
                    <h1 className="bold mt-4 mb-3"><b><input type="date" className="form-control" defaultValue={this.state.ftdDashDate} onChange={this.cng} name="dateval"></input></b></h1>
                  </div>
                </div>
              </div>
            </div> */}
                        <div className="col ">
                            <div className="dashboardCard1" onClick={() => this.onClk(1)}>
                                <Icon icon={calendar_ok} style={{ color: 'green' }} size={35} />
                                <h4 className="mt-2 black" >Scheduled Followup</h4>
                                <div>
                                    <h1 className="bold mt-4 mb-2 black"><b> {this.state.valueofstatus[0].live ? this.state.valueofstatus[0].live : 0}</b></h1>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col ">
              <div className="dashboardCard1" onClick={() => this.onClk(4)}>
                <Icon icon={calendar_ok} style={{ color: 'green' }} size={35} />
                <h4 className="mt-2 black" >Today's Follow up</h4>
                <div>
                  <h1 className="bold mt-4 mb-2 black"><b> {this.state.valueofstatus[0].today ? this.state.valueofstatus[0].today : 0}</b></h1>
                </div>
              </div>
            </div> */}
                        <div className="col ">
                            <div className="dashboardCard1" onClick={() => this.onClk(2)}>
                                <Icon icon={calendar_ok} style={{ color: 'green' }} size={35} />
                                <h4 className="mt-2 black" >Closed Enquiry</h4>
                                <div>
                                    <h1 className="bold mt-4 mb-2 black"><b> {this.state.valueofstatus[0].Closed ? this.state.valueofstatus[0].Closed : 0}</b></h1>
                                </div>
                            </div>
                        </div>
                        <div className="col" >
                            <div className="dashboardCard1" onClick={() => this.onClk(11)}>
                                <Icon icon={user_delete} style={{ color: 'purple' }} size={35} /><br />
                                <h4 className="mt-2 black" >Non-Contactable</h4>
                                <div >
                                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.valueofstatus[0].noncontact ? this.state.valueofstatus[0].noncontact : 0}</b></h1>
                                </div>
                            </div>
                        </div>
                        <div className="col ">
                            <div className="dashboardCard1" onClick={() => this.onClk(3)}>
                                <Icon icon={calendar_ok} style={{ color: 'green' }} size={35} />
                                <h4 className="mt-2 black" >Remainders</h4>
                                <div>
                                    <h1 className="bold mt-4 mb-2 black"><b> {this.state.valueofstatus[0].remainder ? this.state.valueofstatus[0].remainder : 0}</b></h1>
                                </div>
                            </div>
                        </div>
                        <div className="col " onClick={() => this.onClk(10)} >
                            <div className="dashboardCard1">
                                <Icon icon={alert} size={35} style={{ color: 'red' }} />
                                <h4 className="mt-2 black" > Un-Allocated Enquiries</h4>
                                <div>
                                    <h1 className="bold mt-4 mb-2 black"><b> {
                                        this.state.unallocated && this.state.unallocated.length > 0 ? this.state.unallocated.length : 0
                                        // this.state.valueofstatus[0].unallocated ? this.state.valueofstatus[0].unallocated : 0
                                    }</b></h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
    cng = async (e) => {
        this.setState({ ftdval: true })
        console.log(e.target.value);
        let { usertypelog } = this.state
        let d = e.target.value
        let d1 = {}
        d1.date = e.target.value
        d1.orderBy = usertypelog.orderBy
        d1.locationId = this.state.LocationID
        // console.log(d1, 'd1');
        let result = await cmsContent.GetFTD(d, d1)
        if (result.data.length) {
            // console.log(result);
            let v4 = 0;
            let data2 = []
            result.data.map((ival, i) => {
                if (ival.createdAt) {
                    let date11 = new Date(ival.createdAt)
                    let d1 = date11.getDate()
                    let m1 = date11.getMonth() + 1
                    let y1 = date11.getFullYear()
                    if (m1 < 10) {
                        m1 = "0" + m1
                    }
                    let date2 = y1 + "-" + m1 + "-" + d1
                    if (d && date2) {
                        // console.log("enterr");
                        v4 = ++v4;
                    }
                }
            })

            await this.setState({ ftd: v4, EnquiryDatas: result.data, ftddate: d, ftdDashDate: d })
        }
        else {
            let data = []
            await this.setState({ ftd: "0", EnquiryDatas: data })
            console.log(this.state.ftd, "ftddd");
        }
        window.scroll({
            top: 400,
            left: 0,
            behavior: "smooth",
        });
    }
    onClk = (val) => {
        if (val === 1) { this.setState({ EnquiryDatas: this.state.livedata, FilterData: this.state.livedata, workproc: false, filteredLoc: "", filteredMake: "", bulkAllocate: false, statusbtn: false }) }
        if (val === 3) { this.setState({ EnquiryDatas: this.state.remainder, FilterData: this.state.remainder, workproc: false, filteredLoc: "", filteredMake: "", bulkAllocate: false, statusbtn: false }) }
        if (val === 4) { this.setState({ EnquiryDatas: this.state.data1, FilterData: this.state.data1, workproc: false, filteredLoc: "", filteredMake: "", bulkAllocate: false, statusbtn: false }) }
        if (val === 5) { this.setState({ EnquiryDatas: this.state.dataof, FilterData: this.state.dataof, workproc: false, filteredLoc: "", filteredMake: "", bulkAllocate: false, statusbtn: true }) }
        if (val === 6) { this.setState({ EnquiryDatas: this.state.ftddatas, FilterData: this.state.ftddatas, workproc: false, filteredLoc: "", filteredMake: "", bulkAllocate: false, statusbtn: false }) }
        if (val === 2) { this.setState({ EnquiryDatas: this.state.closeddata, FilterData: this.state.closeddata, workproc: false, filteredLoc: "", filteredMake: "", bulkAllocate: false, statusbtn: false }) }
        if (val === 10) { this.setState({ EnquiryDatas: this.state.unallocated, FilterData: this.state.unallocated, workproc: true, filteredLoc: "", filteredMake: "", bulkAllocate: true, reallocatebtn: false, statusbtn: false }) }
        if (val === 11) { this.setState({ EnquiryDatas: this.state.noncontact, FilterData: this.state.noncontact, workproc: true, filteredLoc: "", filteredMake: "", bulkAllocate: true, reallocatebtn: false, statusbtn: false }) }

        window.scroll({
            top: 400,
            left: 0,
            behavior: "smooth",
        });
    }
    StatusView = (d) => {
        return (
            <button
                type="button"
                className="btn btn-sm btn-primary"
                data-toggle="modal"
                data-target={"#matchcarmodal"}
                onClick={() => this.Status(d)}
            >
                View
            </button>
        );
    }
    updatereallocate = async (d) => {
        let { EnquiryDatas1 } = this.state
        let AllocateType = "reallocate"
        if (d) {
            EnquiryDatas1 = {}
            EnquiryDatas1 = d.original
        }
        await this.setState({ EnquiryDatas1, AllocateType, reallocatebtn: true, AllocateSelect1: EnquiryDatas1 })
    }
    Reallocate = (d) => {
        return (
            <center>
                <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    data-toggle="modal"
                    data-target={"#AllocateModel"}
                    onClick={() => this.updatereallocate(d)}
                >
                    Re-Allocate
                </button>
            </center>
        );
    }
    AllocateModel = () => {
        return (
            <center>
                <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    data-toggle="modal"
                    data-target={"#AllocateModel"}
                >
                    Allocate
                </button>
            </center>
        );
    }
    selectalldata = async (event) => {
        let { unallocated } = this.state
        let SelectedEnquiries = []

        if (event == "Yes") {
            for (let index = 0; index < 10; index++) {
                const element = unallocated[index];
                SelectedEnquiries[element.id] = true
                SelectedEnquiries.push(element.id)
            }

            await this.setState({ isSelectUnallocated: true })
            // console.log(SelectedEnquiries.length, "SelectedEnquiries length");
        }

        else {
            await this.setState({ isSelectUnallocated: false })
        }
        await this.setState({ SelectedEnquiries })
    };

    Status = async (d) => {
        let statusArray = []
        if (d.original.statusArray) {
            statusArray = JSON.parse(d.original.statusArray)
        }
        await this.setState({ StatusEditData: d.original, statusArray })
    }

    status1 = () => {
        return (
            <div>
                <SingleSelect
                    placeholder="Select..."
                    options={this.state.status1options}
                    handleChange={d => this.handlestatus1(d)}
                    selectedService={this.state.status1}
                />

            </div>
        )
    }
    AllocateSelect = () => {
        // console.log(this.state.evalopt, "change");
        return (
            <div>
                <SingleSelect
                    placeholder="Select..."
                    options={this.state.evalopt}
                    handleChange={d => this.handleEvalutorOPT(d)}
                    selectedService={this.state.EvalutorSelect}
                />
            </div>
        )
    }
    AllocateSelect1 = (EnquiryDatas1) => {
        console.log(EnquiryDatas1, "EnquiryDatas1EnquiryDatas1EnquiryDatas1");

        let EvaluatorOptions = this.state.evalopt
        let evalopt = []
        console.log(EvaluatorOptions, "EvaluatorOptions");
        let allocatedEvaluator = parseInt(EnquiryDatas1.evaluatorid)
        EvaluatorOptions.map((ival) => {
            if (ival.value !== allocatedEvaluator) {
                evalopt.push(ival)
                console.log("sathesh")
            }
        })
        return (
            <div>
                <SingleSelect
                    placeholder="Select..."
                    options={evalopt}
                    handleChange={d => this.handleEvalutorOPT(d)}
                    selectedService={this.state.EvalutorSelect}
                />
            </div>
        )

    }
    status2 = () => {
        return (
            <div>
                <SingleSelect
                    placeholder="Select..."
                    options={this.state.status2options}
                    handleChange={d => this.handlestatus2(d)}
                    selectedService={this.state.status2}
                />
            </div >
        )
    }
    // status3 = () => {
    //   return (
    //     <div>
    //       <SingleSelect
    //         placeholder="Select..."
    //         options={this.state.status3options}
    //         handleChange={d => this.handlestatus3(d)}
    //         selectedService={this.state.status3}
    //       />
    //     </div >
    //   )

    // }
    handleEvalutorOPT = async (e) => {
        this.setState({
            EvalutorSelect: e, EvalutorSelectError: ""
        })
    }
    handlestatus1 = async e => {
        await this.setState({ status1: e, status2: {} });
        if (e.label == "Scheduled Follow up") {
            await this.setState({
                followupDateVisible: true,
                Next_Contact_date: "",
                status2visible: true, status2options: [
                    { label: "Under Follow up", value: 1 },
                    { label: " Ringing no response", value: 2 },
                    { label: "Researching", value: 3 },
                    { label: "  Not attempted", value: 4 }
                ], status3options: [{ label: "Negotitation", value: 0 }, { label: " consideration", value: 1 }], status3visible: true
            })
        } else if (e.label == "Scheduled Evaluation") {
            await this.setState({
                followupDateVisible: true,
                Next_Contact_date: "",
                status2visible: true, status2options: [{ label: "Appointment taken", value: 1 }],
                status3visible: false
            })
        } else if (e.label == "Mark Closed") {
            await this.setState({
                followupDateVisible: false, Next_Contact_date: "",
                status2visible: true, status2options: [{ label: " Plan dropped", value: 0 },
                { label: "Non contactable", value: 1 },
                { label: "Lost", value: 2 },
                { label: "Wrong capture", value: 3 },
                { label: "No response", value: 4 },
                { label: "Huge Price Gap", value: 5 },
                ], status3visible: false
            })
        } else if (e.label == "Evaluated") {
            this.setState({
                status2visible: true, status2options: [{ label: " Trade in", value: 0 },
                { label: "Follow-up", value: 1 },
                { label: "Researching", value: 2 },
                { label: "Postponned", value: 3 },
                { label: "Dropped", value: 4 },
                { label: "Not attempted", value: 5 },
                { label: "Non contactable", value: 6 },
                { label: "Price not offered", value: 7 },
                { label: "Huge Price gap", value: 8 },
                { label: "Lost", value: 9 },
                ], status3visible: false
            })
            console.log(this.state.status2options);
        }
        // else{this.setState({status2visible:false,status3visible:false})}
    };

    handlestatus2 = async e => {
        this.setState({ status2: e, status3: {} });
        if (e.label == "Lost") {
            this.setState({
                status3visible: true, status3options: [{ label: " Co dealer", value: 0 },
                { label: "Competition", value: 1 },
                { label: "End user", value: 2 },
                { label: "Broker", value: 3 },
                { label: "Not known", value: 4 }
                ]
            })
        } else if (e.label == "Non contactable") {
            this.setState({
                status3visible: true, status3options: [{ label: "RNR-3", value: 0 },
                { label: "Wrong number", value: 1 },
                ]
            })
        } else {
            // this.setState({status3visible:false})
        }
    };
    // handlestatus3 = async e => {
    //   this.setState({ status3: e });
    // }
    StatusUpdate = async () => {
        const { status1, status2, status3, Next_Contact_date, statusArray } = this.state
        let groupArray = {}
        let Userlog = JSON.parse(localStorage.getItem("userlog"))
        if (statusArray && statusArray.length) {
            let obj = {}
            obj.status1 = status1 && status1.label == "Scheduled Evaluation" ? 'Schedule walkin' : status1.label;
            obj.status2 = status2 ? status2.label : null;
            obj.status3 = status3 ? status3.label : null;
            // obj.Next_Contact_date = Next_Contact_date && Next_Contact_date.length && Next_Contact_date
            if (Next_Contact_date != "") {
                obj.Next_Contact_date = Next_Contact_date && Next_Contact_date.length && moment(Next_Contact_date).format('YYYY-MM-DD')
            }
            else {
                obj.Next_Contact_date = "-"
            }
            obj.sino = statusArray.length + 1
            obj.userName = Userlog.userName
            if (obj) {
                statusArray.unshift(obj)
            }
        }
        else if (statusArray && statusArray.length == 0) {
            let obj = {}
            obj.status1 = status1 && status1.label == "Scheduled Evaluation" ? 'Schedule walkin' : status1.label;
            obj.status2 = status2 ? status2.label : null;
            obj.status3 = status3 ? status3.label : null;
            // obj.Next_Contact_date = Next_Contact_date && Next_Contact_date.length && Next_Contact_date
            if (Next_Contact_date != "") {
                obj.Next_Contact_date = Next_Contact_date && Next_Contact_date.length && moment(Next_Contact_date).format('YYYY-MM-DD')
            }
            else {
                obj.Next_Contact_date = "-"
            }
            obj.sino = statusArray.length + 1
            obj.userName = Userlog.userName
            if (obj) {
                statusArray.unshift(obj)
            }
        }
        else {
            statusArray = []
            let obj = {}
            obj.status1 = status1 && status1.label == "Scheduled Evaluation" ? 'Schedule walkin' : status1.label;
            obj.status2 = status2 ? status2.label : null;
            obj.status3 = status3 ? status3.label : null;
            if (Next_Contact_date != "") {
                obj.Next_Contact_date = Next_Contact_date && Next_Contact_date.length && moment(Next_Contact_date).format('YYYY-MM-DD')
            }
            else {
                obj.Next_Contact_date = "-"
            }
            obj.sino = 1
            obj.userName = Userlog.userName
            if (obj) {
                statusArray.unshift(obj)
            }
        }


        groupArray.statusArray = JSON.stringify(statusArray);
        groupArray.pendingStatus1 = status1 && status1.label == "Scheduled Evaluation" ? 'Schedule walkin' : status1.label;
        // groupArray.pendingStatus1 = status1 && status1.label;
        groupArray.pendingStatus2 = status2 ? status2.label : null;
        groupArray.pendingStatus3 = status3 ? status3.label : null;
        groupArray.Next_Contact_date = Next_Contact_date && Next_Contact_date.length && moment(Next_Contact_date).format('YYYY-MM-DD')
        let id = this.state.StatusEditData.id
        console.log(groupArray, "groupArray", statusArray, "statusarraysss")
        this.setState({ statusbtnDisable: true })
        try {
            const result = await cmsContent.UpdateEnquiryadd(
                id,
                groupArray
            );
            this.setState({
                statusbtnDisable: false,
                StatusupdatealertVisible: true,
                Statusupdataetextalert: "Your Status have been Updated successfully",
                Statusupdatecolor: "success",
                errorColourName: "",
                status1: "",
                status2: "",
                status3: "",
                Next_Contact_date: "",
                followupDateVisible: false,
                modelPresent: false,
                status1visible: false,
                status2visible: false,
                status3visible: false
            });
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth"
            });
            this.MainFunction()
            setTimeout(() => { this.setState({ StatusupdatealertVisible: false }); }, 3000);
        } catch (error) {
            console.log(error);
        }
    }
    AllocateUpdate = async () => {
        const { EvalutorSelect, SelectedEnquiries, AllocateType, EnquiryDatas1 } = this.state
        console.log(SelectedEnquiries, EvalutorSelect, EnquiryDatas1);
        if (!EvalutorSelect.value) {
            await this.setState({ EvalutorSelectError: "Please Select Evalutor" })

            return false
        }
        let EnquiryDatas2 = JSON.stringify(EnquiryDatas1)
        const formData = new FormData();
        formData.append("SelectedEnquiries", SelectedEnquiries);
        formData.append("SelectedEnquiries", EnquiryDatas2);
        formData.append("SelectedID", EvalutorSelect.value);
        formData.append("AllocateType", AllocateType);
        this.setState({ statusbtnDisable: true })
        console.log(...formData);
        try {
            let result = await cmsContent.DFcarsManagerAllocate("tbl_dfcarz_enquiryform", "seller", formData)
            if (result) {
                await this.setState({
                    statusbtnDisable: false,
                    StatusupdatealertVisible: true,
                    Statusupdataetextalert: "Enquiry have been Allocated successfully",
                    Statusupdatecolor: "success",
                    EvalutorSelectError: "", EnquiryDatas: [], SelectedEnquiries: [], isSelectUnallocated: false,

                });
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: "smooth"
                });
                this.MainFunction()
                setTimeout(() => { this.setState({ StatusupdatealertVisible: false, EvalutorSelect: "" }) }, 3000);
            }
        }
        catch (err) {
            console.log(err);
            this.setState({ statusbtnDisable: false })
        }
    }
    StatusReset = () => {
        this.setState({
            statuschg1: false, status2visible: false, status2: "",
            status1: null, StatusEditData: {}, status3visible: false,
            sellerstatus1: {}, sellerstatus2: {}, sellerstatus3: {}, EvalutorSelect: [], EvalutorSelectError: ""
        })
    }
    makeFilter = async (d) => {
        let { filteredMake, EnquiryDatas, MakeOptions, filteredLoc, FilterData } = this.state;

        //   console.log(d, "d")

        await this.setState({ filteredMake: d })

        if (d && d.length == 0 && filteredLoc.length == 0) {
            await this.setState({ EnquiryDatas: this.state.FilterData })
        }
        else if (d && d.length == 0 && filteredLoc.length != 0) {
            var filtered_data = await FilterData.filter(x => filteredLoc.some(y => x.location == y.value))
            await this.setState({ EnquiryDatas: filtered_data })
        }
        else if (d && d.length != 0 && filteredLoc && filteredLoc.length != 0) {
            var filtered_make = await FilterData.filter(fd => d.some(d => fd.Make == d.value))
            var filtered_loc = await FilterData.filter(fd => filteredLoc.some(fl => fd.location == fl.value))
            // console.log(filtered_make, "filtered_make", filtered_loc, "filtered_loc'")
            var filtered_data = await filtered_make.filter(fm => filtered_loc.some(fl => fm.Make == fl.Make && fm.location == fl.location))
            // console.log(filtered_data, "filtered_data")
            await this.setState({ EnquiryDatas: filtered_data })
        }

        else if (d && d.length != 0 && filteredLoc.length == 0) {
            var filtered_data = await FilterData.filter(x => d.some(y => x.Make == y.value))
            await this.setState({ EnquiryDatas: filtered_data })
        }
        else {
            //nothing
        }
    }
    locationFilter = async (d) => {
        let { locationOpt, filteredLoc, FilterData, filteredMake, EnquiryDatas } = this.state
        // console.log(d, "d")

        await this.setState({ filteredLoc: d })

        if (d && d.length == 0 && filteredMake.length == 0) {
            await this.setState({ EnquiryDatas: this.state.FilterData })
        }

        else if (d && d.length == 0 && filteredMake.length != 0) {
            var filtered_data = await FilterData.filter(x => filteredMake.some(y => x.Make == y.value))
            await this.setState({ EnquiryDatas: filtered_data })
        }
        else if (d && d.length != 0 && filteredMake.length != 0) {
            var filtered_loc = await FilterData.filter(fd => d.some(d => fd.location == d.value))
            var filtered_make = await FilterData.filter(fd => filteredMake.some(d => fd.Make == d.value))
            // console.log(filtered_make, "filtered_make", filtered_loc, "filtered_loc'")
            var filtered_data = await filtered_loc.filter(fl => filtered_make.some(fm => fl.location == fm.location && fl.Make == fm.Make))
            // console.log(filtered_data, "filtered_dataaa")
            await this.setState({ EnquiryDatas: filtered_data })
        }

        else if (d && d.length != 0 && filteredMake.length == 0) {
            var filtered_data = await FilterData.filter(x => d.some(y => x.location == y.value))
            await this.setState({ EnquiryDatas: filtered_data })
        }
        else {
            //nothing
        }

    }
    clearFilter = async () => {
        await this.setState({ filteredMake: "", filteredLoc: "", EnquiryDatas: this.state.FilterData })
    }

    changeHandler = (e, name) => {
        console.log(e.target.value, "e")
        this.setState({ [name]: e.target.value })
    }

    render() {
        console.log(this.state.noncontact, "noncontactnoncontact");
        let { locationOpt, StatusEditData, SelectedEnquiries, EditData, EnquiryDatas, EnquiryDatas1, MakeOptions, FilteredDatas, statusArray, statusbtn } = this.state;
        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        {this.edash()}
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h1>Enquiry Report</h1>
                                    </div>
                                    <div className="card-body">
                                        {SelectedEnquiries && SelectedEnquiries.length ? this.AllocateModel() : ""}
                                        {this.state.StatusEditData != null && <div>
                                            <LoginModal
                                                title={StatusEditData.name}
                                                id="matchcarmodal"
                                                extraStyle={{ width: '100%' }}
                                                reset={this.StatusReset}
                                                buttonStyle={{ backgroundColor: "white", border: "white" }}
                                                bodyText={
                                                    <React.Fragment>
                                                        <main>
                                                            <div className="container-fluid">
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <div className="card">
                                                                            <div className="card-header">
                                                                                <h1> Edit Status </h1>
                                                                            </div>
                                                                            <div className="card-body">
                                                                                <div className="row form-group">
                                                                                    <div className="col-sm-2" />
                                                                                    <div className="col-sm-7">
                                                                                        <Alert
                                                                                            className="badge-content"
                                                                                            color={this.state.Statusupdatecolor}
                                                                                            isOpen={this.state.StatusupdatealertVisible}
                                                                                            toggle={this.onDismiss}
                                                                                        >
                                                                                            {this.state.Statusupdataetextalert}
                                                                                        </Alert>
                                                                                    </div>
                                                                                    <div className="col-sm-3" />
                                                                                </div>
                                                                                <div>
                                                                                    {statusArray && statusArray.length ?
                                                                                        <div className="row " style={{ overflow: "auto" }}>
                                                                                            <table class="table table-bordered">
                                                                                                <thead style={{ background: '#151E80', color: 'white' }}>
                                                                                                    <tr>
                                                                                                        <th scope="col">SiNo</th>
                                                                                                        <th scope="col">Status1</th>
                                                                                                        <th scope="col">Status2</th>
                                                                                                        <th scope="col">Followup Date</th>
                                                                                                        <th scope="col">Update By</th>

                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {statusArray && statusArray.map((jval, i) => {
                                                                                                        return (
                                                                                                            < tr >
                                                                                                                <th scope="row">{jval.sino}</th>
                                                                                                                <td>{jval.status1}</td>
                                                                                                                <td>{jval.status2}</td>
                                                                                                                <td>{jval.Next_Contact_date}</td>
                                                                                                                <td>{jval.userName}</td>
                                                                                                            </tr>
                                                                                                        )
                                                                                                    })
                                                                                                    }
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div> : null}
                                                                                </div>
                                                                                <div className="row" style={{ justifyContent: 'center' }}>
                                                                                    <div className="col-sm-7"
                                                                                    // style={{ left: "25%" }}
                                                                                    >
                                                                                        <div  >
                                                                                            &nbsp;
                                                                                            {StatusEditData.pendingStatus1 != null && <h4>Status 1 : <span className="btn btn-sm btn-primary" >{StatusEditData.pendingStatus1 == "Schedule walkin" ? "Scheduled Evaluation" : StatusEditData.pendingStatus1}</span></h4>
                                                                                            }&nbsp;
                                                                                            {this.status1()}
                                                                                            &nbsp;
                                                                                            {StatusEditData.pendingStatus2 != null && <h4>Status 2 : <span className="btn btn-sm btn-primary" >{StatusEditData.pendingStatus2}</span></h4>
                                                                                            }
                                                                                            &nbsp;
                                                                                            {this.state.status2visible == true && this.status2()}
                                                                                            <br />
                                                                                            {/* {this.state.status3visible == true && <h4>Status 3 :  </h4>
                                              }
                                              &nbsp;
                                              {this.state.status3visible == true && this.status3()} */}
                                                                                            {
                                                                                                this.state.followupDateVisible == true &&
                                                                                                <>
                                                                                                    <label style={{ fontWeight: 500 }}> Next-follow-up : <span className="btn btn-sm btn-primary" >{StatusEditData.Next_Contact_date}</span></label>
                                                                                                    <br></br>
                                                                                                    <br></br>
                                                                                                    <input
                                                                                                        type="date"
                                                                                                        placeholder="Follow-up date"
                                                                                                        min={moment().format('YYYY-MM-DD')}
                                                                                                        value={this.state.Next_Contact_date}
                                                                                                        className="form-control"
                                                                                                        onChange={(e) => { this.changeHandler(e, "Next_Contact_date") }}
                                                                                                    />
                                                                                                    <br></br>
                                                                                                </>
                                                                                            }
                                                                                            <br />
                                                                                            {this.state.status2visible == true && <button className="btn btn-sm btn-block btn-warning" disabled={this.state.statusbtnDisable} onClick={() => { this.StatusUpdate() }}>{this.state.statusbtnDisable ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;Update</button>
                                                                                            }
                                                                                            &nbsp;
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </main>
                                                    </React.Fragment>
                                                }
                                            /></div>
                                        }
                                        {this.state.StatusEditData != null && <div>
                                            <LoginModal
                                                title={StatusEditData.name}
                                                id="AllocateModel"
                                                reset={this.StatusReset}
                                                extraStyle={{ width: '100%' }}
                                                buttonStyle={{ backgroundColor: "white", border: "white" }}
                                                bodyText={
                                                    <React.Fragment>
                                                        <main>
                                                            <div className="container-fluid">
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <div className="card">
                                                                            <div className="card-header">
                                                                                <h1> Edit Status </h1>
                                                                            </div>
                                                                            <div className="card-body">
                                                                                <div className="row form-group">
                                                                                    <div className="col-sm-2" />
                                                                                    <div className="col-sm-7">
                                                                                        <Alert
                                                                                            className="badge-content"
                                                                                            color={this.state.Statusupdatecolor}
                                                                                            isOpen={this.state.StatusupdatealertVisible}
                                                                                            toggle={this.onDismiss}
                                                                                        >
                                                                                            {this.state.Statusupdataetextalert}
                                                                                        </Alert>
                                                                                    </div>
                                                                                    <div className="col-sm-3" />
                                                                                </div>

                                                                                <div className="row">
                                                                                    <div className="col-sm-2" />
                                                                                    <div className="col-sm-7">
                                                                                        <h4>Allocate Evalutor:</h4>
                                                                                        {this.state.reallocatebtn == true ? this.AllocateSelect1(EnquiryDatas1) : this.AllocateSelect()}
                                                                                        {/* {this.AllocateSelect()} */}
                                                                                        <span className="error-shows" style={{ color: "red" }}>
                                                                                            {this.state.EvalutorSelectError}
                                                                                        </span>
                                                                                        <br />
                                                                                        <br />
                                                                                        <button className="btn btn-sm btn-block btn-warning" disabled={this.state.statusbtnDisable} onClick={() => { this.AllocateUpdate() }}>{this.state.statusbtnDisable ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;Update</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </main>
                                                    </React.Fragment>
                                                }
                                            /></div>
                                        }
                                        {this.state.EditData != null && <div>
                                            <LoginModal
                                                title={EditData.name}
                                                id="fullview"

                                                extraStyle={{ width: '100%' }}
                                                buttonStyle={{ backgroundColor: "white", border: "white" }}
                                                bodyText={
                                                    <div className="table-responsive">
                                                        <table class="table  table-striped">
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">NAME</th>
                                                                    <td>{EditData && EditData.name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">MOBILE</th>
                                                                    <td>{EditData && EditData.mobile}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">MAKE</th>
                                                                    <td>{this.getValueFromArray(EditData && EditData.Make, this.state.MakeOptions)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">MODEL</th>
                                                                    <td>{this.getValueFromArray(EditData && EditData.Model, this.state.modelopt)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">VARIANT</th>
                                                                    <td>{this.getValueFromArray(EditData && EditData.Variant, this.state.variantopt)}</td>
                                                                </tr>

                                                                <tr>
                                                                    <th scope="row">OWNER</th>
                                                                    <td>{EditData && EditData.owner_type}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">KMS</th>
                                                                    <td>{EditData && EditData.Km}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">EMAIL</th>
                                                                    <td>{EditData && EditData.email}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">REG NO</th>
                                                                    <td>{EditData && EditData.Reg_no}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">STATUS 1</th>
                                                                    <td>{EditData && EditData.pendingStatus1}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">STATUS 2</th>
                                                                    <td>{EditData && EditData.pendingStatus2}</td>
                                                                </tr>
                                                            </tbody>

                                                        </table>
                                                    </div>
                                                }
                                            /></div>
                                        }
                                        {/* <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-7">
                        <Alert
                          className="badge-content"
                          color={this.state.color}
                          isOpen={this.state.alertVisible}
                          toggle={this.onDismiss}
                        >
                          {this.state.textalert}
                        </Alert>
                      </div>
                      <div className="col-sm-3" />
                    </div> */}
                                        {
                                            this.state.FilterData && this.state.FilterData.length && !this.state.workproc
                                                ?
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <label htmlFor="locationFilter" style={{ fontWeight: 500, fontSize: "medium" }} > Location filter: </label>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <label htmlFor="makefilter" style={{ fontWeight: 500, fontSize: "medium" }}> Make filter: </label>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <MultiSelect
                                                                id="locationFilter"
                                                                name="location"
                                                                placeholder="Filter Location"
                                                                options={locationOpt}
                                                                handleChange={d => this.locationFilter(d)}
                                                                selectedService={this.state.filteredLoc}
                                                            />
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <MultiSelect
                                                                id="makefilter"
                                                                name="make"
                                                                placeholder="Filter Make"
                                                                options={MakeOptions}
                                                                handleChange={d => this.makeFilter(d)}
                                                                selectedService={this.state.filteredMake}
                                                            />
                                                        </div>
                                                    </div>
                                                    <br></br>
                                                    <div className="row">
                                                        <div className="col-sm-5" />
                                                        <div className="col-sm-2">
                                                            <button type="button" className="btn btn-sm btn-primary" onClick={() => {
                                                                this.clearFilter()
                                                            }}> Clear filter</button>
                                                        </div>
                                                        <div className="col-sm-5"></div>
                                                    </div>
                                                    <br></br>
                                                </div>
                                                :
                                                null
                                        }



                                        {this.state.loading ? <div className="text-center"> <i
                                            className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                                        ></i></div> : this.state.EnquiryData &&
                                            this.state.EnquiryData.length ? (
                                            this.state.EnquiryDatas && this.state.EnquiryDatas.length
                                                ?
                                                <>
                                                    {this.state.bulkAllocate == true ?
                                                        <div className="d-flex justify-content-end">
                                                            <h3 style={{ marginTop: '4px', fontWeight: '600px', fontSize: 'larger' }}>Bulk Allocate</h3><span>
                                                                &nbsp; &nbsp;

                                                                <input type="checkbox"
                                                                    checked={this.state.isSelectUnallocated}
                                                                    onChange={(event) => this.selectalldata(event.target.checked ? "Yes" : "No")} /></span>
                                                        </div>
                                                        : <></>
                                                    }
                                                    <br></br>
                                                    <Datatable
                                                        data={FilteredDatas && FilteredDatas.length ? FilteredDatas : EnquiryDatas}
                                                        columnHeading={this.state.workproc ? this.state.column1 : statusbtn == true ? this.state.statuscolumn : this.state.column}
                                                        // columnHeading={this.state.workproc ? this.state.column1 : this.state.column}
                                                        excelColumn={this.state.workproc ? this.state.excelColumn1 : this.state.excelColumn}
                                                    />
                                                </>
                                                :
                                                <>
                                                    <div style={{ textAlign: "center", fontSize: "larger" }} ><b>No rows to show</b></div>
                                                    <br></br>
                                                </>

                                        ) : (<div style={{ textAlign: "center" }} ><b>No Data Found</b></div>)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </main >
            </React.Fragment >)
    }
}
export default UserEnquiryreport;