import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
//import UpdateStatus from "./UpdateStatus"
import moment from "moment";
import LoginModal from "../../../../components/Modal/Modal";
import UsertypeComments from "../UserMaster/UsertypeComments";
import Datatable from "../../../../components/Datatable/Datatable";
import Icon from "react-icons-kit";
import { calendar_ok } from 'react-icons-kit/ikons/calendar_ok'
import { confirmAlert } from "react-confirm-alert";

class PocContracted extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      User: JSON.parse(localStorage.getItem("userlog")),
      LocationID: localStorage.getItem(`LocationID`),
      EnquiryData: [],
      alertVisible: false,
      column: [
        {
          Header: "Prospect",
          accessor: "name"
        },
        {
          Header: "Contact",
          accessor: "mobile"
        },
        {
          Header: "Location",
          accessor: "Location"
        },
        {
          Header: "HandledBy",
          accessor: "userName"
        },
        {
          Header: "Specification-Sheet",
          accessor: "mobile",
          Cell: d => this.Specification(d)
        },
        {
          Header: "Approval",
          accessor: "mobile",
          minWidth:120,
          Cell: d => this.Approval(d)
        },
        {
          Header: "Comments",
          accessor: "mobile",
          Cell: d => this.UpdateSTatus(d)
        },
      ],
      data_for_f: [{ "fill": 0, "view": 0, "approved": 0 }],
      stage: "",
      CustomerId: ""
    };
  }

  UpdateSTatus = (d) => {
    return (<button type="button" className="btn btn-warning"
      data-toggle="modal" data-target={"#status"}
      onClick={() => this.Editdata(d)}
    >View Comments</button>);
  }

  Editdata = async (d) => {

    this.setState({ stage: "contracted", CustomerId: d.original.id })
  }

  F_proofForm = async (d) => {
    window.open(`/DFCars/AT_Booking_Confirmation_Reproof/${d.original.id}`, '_blank')
  }

  Specification = (d) => {
    // if(d.original.FormFilled=='yes'||d.original.FormFilled=='approved'){
    return (<button type="button" className="btn btn-primary"
      onClick={() => this.F_proofForm(d)}
    >View Form</button>)
    // }else{
    //   return(<button type="button" className="btn btn-warning"
    //   onClick={()=>this.F_proofForm(d)}
    //   >Fill Form</button>)
    // }

  }

  Approval = (d) => {
    if (d.original.approval == 0) {
      return (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => this.buttonDeletes(d)}
        >
          Waiting for Approval
        </button>
      );
    } else if (d.original.approval == 1) {
      // return (
      //   <button
      //     type="button"
      //     className="btn btn-warning"
      //     onClick={() => this.F_proofForm1(d)}
      //   >
      //     Approved
      //   </button>
      // );
      return (<span className="label">Approved</span>)
    }
  };
  buttonDeletes = d => {
    //console.log(value);
    confirmAlert({
      title: "Confirmation to Approval",
      message: "Are you sure you want to approval this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.F_proofForm1(d)
        },
        {
          label: "No"
        }
      ]
    });
  };
  F_proofForm1 = async d1 => {
    // window.open(
    //   `/DFCars/AT_Booklet_Purchase_Contract_Proof/${d.original.id}`,
    //   "_blank"
    // );
    let d = d1.original;
    let approval = d1.original.approval;
    // console.log(d);
    let sub = {};
    if (approval == 0) {
      sub.approval = "1";
    } else {
      sub.approval = "0";
    };
    //sub.Approved_date
    let CurrentDate = new Date();
    CurrentDate = moment(CurrentDate).format("YYYY-MM-DD");
    //  sub.Approved_date = CurrentDate;
    //  sub.Approved_by = this.state.User.id;
    // console.log(sub);
    const result = await cmsContent.updateMaster(
      "tbl_Booking_Confirmation_Reproof",
      d.value,
      sub
    );
    if (result) {
      let newData = [...this.state.Data];
      newData[d1.index].approval = sub.approval;
      this.setState({
        Data: newData
      })
      window.location.reload();
    }
  };


  async componentDidMount() {
    try {

      let Buyer = await cmsContent.getFreedom(
        'tbl_dfcarz_prosperities.*,tbl_location.name as Location,tbl_user_web.userName,tbl_Booking_Confirmation_Reproof.approval,tbl_Booking_Confirmation_Reproof.id as value',
        'tbl_dfcarz_prosperities,tbl_Booking_Confirmation_Reproof,tbl_user_web,tbl_location',
        `tbl_dfcarz_prosperities.location = tbl_location.id and tbl_user_web.id = tbl_Booking_Confirmation_Reproof.userid and tbl_dfcarz_prosperities.type='buyer' and tbl_dfcarz_prosperities.id = tbl_Booking_Confirmation_Reproof.customerid`,
        1,
        `tbl_dfcarz_prosperities.id DESC`
      )
      if (Buyer) {
        Buyer.data.map((ival, i) => {
          ival.FormFilled = 'no';
          ival.followup = moment(ival.followup).format("YYYY-MM-DD");
        })
        // console.log(Buyer);
        // let result = await cmsContent.getFreedom(
        //     `*`,
        //     `tbl_Booking_Confirmation_Reproof`,
        //       1
        // );
        // Buyer.data.map((ival,i)=>{
        //     result.data.map((jval,j)=>{
        //       if(ival.id==jval.customerid && jval.approval==1){
        //           ival.FormFilled='approved';
        //       }else if(ival.id==jval.customerid){
        //         ival.FormFilled='yes';
        //       }
        //     })
        //    })
        this.setState({ Data: Buyer.data })
        let v1 = 0
        let v2 = 0
        let v3 = 0

        let data2 = []
        let data3 = []
        let data4 = []
        await Buyer.data.map((ival, i) => {
          if (ival.FormFilled == "yes") {
            v1 = ++v1
            data2.push(ival)
          } else if (ival.FormFilled == "no") {
            v2 = ++v2
            data3.push(ival)
          } else if (ival.FormFilled == "approved") {
            v3 = ++v3
            data4.push(ival)
          }
        })
        let data_for_f = [{ "fill": v2, "view": v1, "approved": v3 }]
        await this.setState({ data2, data3, data4, data_for_f })
      }

    } catch (error) {
      console.log(error);
    }
  }
  edash = () => {

    return (<div className="container-fluid">
      <div className="card">
        {/* < div className="row" > */}
        <div className="card-header">
          <div className="col-sm-6" >
            <h2 className="mt-2 black">Dashboard</h2>
          </div>
        </div>
        <div className="card-body">
          <div className="row col-12 mt-4 text-center">
            <div className="col-sm-4 ">
              <div className="dashboardCard1" onClick={() => this.onClk(1)} >
                <Icon icon={calendar_ok} style={{ color: 'green' }} size={35} />
                <h4 className="mt-2 black" >Filled Form</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b>{this.state.data_for_f[0].view ? this.state.data_for_f[0].view : 0}</b></h1>
                </div>
              </div>
            </div>
            <div className="col-sm-4 ">
              <div className="dashboardCard1" onClick={() => this.onClk(2)} >
                <Icon icon={calendar_ok} style={{ color: 'green' }} size={35} />
                <h4 className="mt-2 black" >Non -Filled Form</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b> {this.state.data_for_f[0].fill ? this.state.data_for_f[0].fill : 0}</b></h1>
                </div>
              </div>
            </div>
            <div className="col-sm-4 ">
              <div className="dashboardCard1" onClick={() => this.onClk(3)} >
                <Icon icon={calendar_ok} style={{ color: 'green' }} size={35} />
                <h4 className="mt-2 black" >Approved</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b> {this.state.data_for_f[0].approved ? this.state.data_for_f[0].approved : 0}</b></h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
  onClk = (val) => {
    if (val === 1) { this.setState({ Data: this.state.data2 }) }
    if (val === 3) { this.setState({ Data: this.state.data4 }) }
    if (val === 2) { this.setState({ Data: this.state.data3 }) }
    window.scroll({
      top: 400,
      left: 0,
      behavior: "smooth",
    });
  }

  render() {
    return (<React.Fragment>
      <main className="main my-4">
        <div className="container-fluid">
          {this.edash()}
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h1>Contracted</h1>
                </div>
                <div className="card-body">
                  <div className="row form-group">
                    <div className="col-sm-4">
                      <LoginModal
                        buttonTitle="Add Enquiry"
                        title="Update Commets"
                        id="status"
                        extraClass="btn btn-primary"
                        onClick={this.reset}
                        extraStyle={{ width: '1000px' }}
                        buttonStyle={{ backgroundColor: "white", border: "white" }}
                        bodyText={
                          <UsertypeComments
                            stage={this.state.stage}
                            customerid={this.state.CustomerId}
                          />}
                      />
                    </div>
                  </div>
                  {this.state.Data.length ? (
                    <Datatable
                      data={this.state.Data}
                      columnHeading={this.state.column}
                    />
                  ) : (<div style={{ textAlign: "center" }} ><b>No Data Found</b></div>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
    )
  }
}
export default PocContracted;