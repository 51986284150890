import React from 'react';
import Datatable from '../../../components/Datatable/Datatable';
import Video from './Video';
import { Alert } from 'reactstrap';
//fileupload

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginImageResize,
    FilePondPluginFileValidateType,
    FilePondPluginFileValidateSize,
    FilePondPluginImageValidateSize
);
function FromdfLibrary({ errorFile, datas, columnHeading, type, selectImage, fileName, handleChange, dismiss, alertVisible, submit, hint, disableValue, ...props }) {
    return (
        <div style={{ textAlign: 'center' }}>
            <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-7">
                    <Alert
                        className="badge-content"
                        color={"success"}
                        isOpen={alertVisible}
                        toggle={dismiss}
                    >
                        File Uploaded Successfully
                    </Alert>
                </div>
                <div className="col-sm-3" />
            </div>
            <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                    <label for="exampleInputEmail1">Upload File</label>
                </div>
                <div className="col-sm-5 custom-file">
                    <input
                        type="file"
                        className="custom-file-input"
                        id="customFile"
                        onChange={selectImage}
                    />
                    <label className="custom-file-label" htmlFor="customFile">
                        {fileName}
                    </label>
                </div>
                <div className="col-sm-3" />
            </div>
            <div className="row form-group">
                <div className="col-sm-4" />
                <span className="error-show ">{errorFile}</span>
            </div>
            {/* <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-2" >
                    <label>Upload File </label>
                </div>
                <div className="col-sm-5">
                    <FilePond
                        files={fileName && fileName}
                        allowReorder={true}
                        allowImageResize={true}
                        allowMultiple={true}
                        imagePreviewMinHeight="50"
                        imagePreviewMaxHeight="50"
                        maxFiles={1}
                        acceptedFileTypes={type == 'video' ? ["video/*"] : ["image/*"]}
                        onupdatefiles={fileItems => {
                            selectImage(fileItems);

                        }}
                        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                    />
                </div>
                <div className="col-sm-3" />
            </div>
            <div className="row form-group">
                <div className="col-sm-4" />
                <span className="error-show ">{errorFile}</span>
            </div> */}
            <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                    <label for="exampleInputEmail1">Search Hint</label>
                </div>
                <div className="col-sm-5 custom-file">
                    <input
                        type="text"
                        name="searchhint"
                        placeholder="Enter  hint"
                        className="form-control"
                        value={hint}
                        onChange={handleChange}
                    />
                </div>
                <div className="col-sm-3" />
            </div>
            <div className="row form-group">
                <div className="col-sm-4" />
                {/* <span className="error-show ">{errorHint}</span> */}
            </div>

            <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-2" />
                <div className="col-sm-5">
                    <button
                        type="button"
                        class="btn btn-primary"
                        disabled={disableValue}
                        onClick={submit}
                    >
                        {disableValue ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;
                        Submit
                    </button>
                </div>
                <div className="col-sm-3" />
            </div>{
                props.visible == true ?

                    < Datatable data={datas} columnHeading={columnHeading} /> : null
            }
            < br />
        </div >
    );
};

export default FromdfLibrary;
