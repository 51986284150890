import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
//import DfCarsEnquiry from "./DfCarsEnquiry";
import SingleSelect from "../../../../components/Form/SingleSelect";
import moment from "moment";

class UpdateLeadStatus extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            comment: "",
            followup: "",
            alertVisible: false,
            textalert: "",
            isEdit: false,
            EditId: "",
            Index: "",
            EditData: {},
            showReason: false,
            tableArray: [],
            IsClose: false,
            Userlog: JSON.parse(localStorage.getItem("userlog")),
            usertypelog: JSON.parse(localStorage.getItem("usertypelog")),
            Budget: null,
            CommentList: [],
            status1options: [
                { value: 1, label: "Scheduled Follow up" },
                { value: 4, label: "Scheduled Walkin" },
                { value: 2, label: "Walkin Done", },
                { value: 3, label: "Mark Closed" }
            ],
            statusArray: [{ status: "", statusname: '', followup: '', remark: '', username: '', userid: '', index: 0, error: null }],
        }
    }

    async componentDidMount() {
    }

    async componentWillReceiveProps(props) {
        try {
            await this.setState({
                isEdit: false,
                FullData: [],
                EditId: null,
                EditData: [],
                statusArray: [],
                userid: [],
                isfollowup: [],
                CommentList: [],
                tableArray: []
            });
            if (props) {
                if (props.isEdit) {
                    let d = props.EditData;
                    await this.setState({
                        tableArray: d.LeadstatusJson ? d.LeadstatusJson : [{ status: "", statusname: '', followup: '', remark: '', username: '', userid: '', index: 0, error: null }],
                    })

                }
                await this.setState({
                    isEdit: props.isEdit,
                    FullData: props.FullData,
                    EditId: props.EditData.id,
                    EditData: props.EditData,
                    statusArray: props.statusarray,
                    userid: props.userid,
                    isfollowup: props.isfollowup,
                    CommentList: []
                });


            }
        } catch (error) {
            console.log(error)
        }
    }
    handlechangeData = async (e, filed, i) => {
        let { statusArray, tableArray } = this.state;
        statusArray[i][filed] = e
        if (filed == 'status') statusArray[i]['statusname'] = e.label
        if (filed == 'status') statusArray[i]['followupno'] = tableArray.length + 1
        this.setState({ statusArray, [filed]: e })
    };

    onDismiss = () => {
        this.setState({ alertVisible: false, formAlertdelete: false });
    };

    UpdateCategory = async () => {
        const { remark, followup, status, EditId, EditData, Userlog, isEdit, userid } = this.state;
        let { statusArray, tableArray } = this.state;
        console.log(status.label, "status.label;");
        statusArray.map((ival) => {
            if (status && status.label == "Mark Closed") {
                if (!ival.status) {
                    ival.error = 'Please select status'
                }
                else if (!ival.remark) {
                    ival.error = 'Please Enter remark'
                }
                else ival.error = null
            } else {
                if (!ival.status) {
                    ival.error = 'Please select status'
                }
                else if (!ival.followup) {
                    ival.error = 'Please select followup'
                }
                else if (!ival.remark) {
                    ival.error = 'Please select remark'
                } else ival.error = null
            }

        })
        this.setState({ statusArray })
        let val = statusArray.some(e => e.error != null)
        if (val) return true
        await statusArray.map((ival) => { ival.username = Userlog.userName; ival.userid = Userlog.id })
        let json
        if (tableArray && tableArray.length) {
            json = await [...statusArray, ...tableArray]
        } else json = statusArray
        let GroupArray = {};
        GroupArray.enquiry_id = EditId;
        GroupArray.prosid = EditData.prosid;
        GroupArray.status = status.label;
        GroupArray.followup = followup;
        GroupArray.remark = remark;
        GroupArray.statusJson = JSON.stringify(json)
        GroupArray.salesid = userid;
        const id = EditData.LeadId
        this.setState({ statusbtnDisable: true })
        try {
            const result = isEdit ? await cmsContent.updateMaster(
                "tbl_dfcars_lead",
                id,
                GroupArray
            ) : await cmsContent.addMaster(
                "tbl_dfcars_lead",
                GroupArray
            )
            if (result) {
                this.setState({
                    alertVisible: true,
                    textalert: 'Status successfully updated',
                    isEdit: false,
                    statusArray: [{ status: "", statusname: '', followup: '', remark: '', username: '', userid: '', index: 0, error: null }],
                    tableArray: [{ status: "", statusname: '', followup: '', remark: '', username: '', userid: '', index: 0, error: null }],
                    Remark: "",
                    followup: "",
                    EditData: {},
                    status1: "",
                    userid: "",
                    color: "green",
                    statusbtnDisable: false
                })
                setTimeout(() => {
                    this.setState({ alertVisible: false });
                    this.props.close('status')
                    console.log("sathesh loading",)
                }, 1000)
                this.props.these();
            }
        } catch (error) {
            console.log(error);
            this.setState({ statusbtnDisable: false })
        }
    }
    AddMore = async (i) => {
        let { statusArray } = this.state;

        if (i == 1) {
            statusArray.push({ status: "", followup: null, remark: null, index: statusArray.length, error: null })
        } else {
            statusArray.pop()
        }
        this.setState({ statusArray })
    }

    validate = () => {
        const { statusArray } = this.state;
        let lastobject = statusArray ? statusArray[statusArray.length - 1] : null
        if (lastobject) {
            if (lastobject.statusname == 'Contract') {
                return false
            } else return true
        } else return true
    }
    render() {
        let { usertypelog, status1, isfollowup, statusArray, tableArray, EditData, showReason } = this.state;
        return (<React.Fragment>
            <main >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h1>Status</h1>
                                </div>
                                <div className="card-body" style={{ backgroundColor: "#ebedef" }}>
                                    <div className="row form-group">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-7">
                                            <Alert
                                                className="badge-content"
                                                color={this.state.color}
                                                style={{ color: "white", backgroundColor: "#50C878" }}
                                                isOpen={this.state.alertVisible}
                                                toggle={this.onDismiss}
                                            >
                                                {this.state.textalert}
                                            </Alert>
                                        </div>
                                        <div className="col-sm-3" />
                                    </div>
                                    {

                                        (EditData && EditData.leadSource == 'Contract' && EditData.reasonForCancel != null && !showReason)
                                            ?
                                            <>
                                                <center>
                                                    <button
                                                        className="btn btn-danger"
                                                        onClick={() => { this.setState({ showReason: true }) }}>
                                                        Reason
                                                    </button>

                                                </center>
                                                <br></br>
                                            </>
                                            : (EditData.reasonForCancel && showReason) ?

                                                <div>
                                                    <button
                                                        className="btn btn-sm btn-danger"
                                                        onClick={() => { this.setState({ showReason: false }) }}
                                                    >
                                                        back
                                                    </button>
                                                    <br></br>
                                                    <div className="row">
                                                        <div className="col-sm-4" />
                                                        <div calssName='col-sm-6'>
                                                            <table class="table table-bordered">
                                                                <thead style={{ background: '#151E80', color: 'white' }}>
                                                                    <tr>
                                                                        <th scope="col"><center> Reason for contract cancel</center></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <p>
                                                                                {EditData.reasonForCancel}
                                                                            </p>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <br></br>
                                                </div>

                                                :
                                                <></>

                                    }
                                    {tableArray && tableArray.length ?
                                        <div className="row " style={{ overflow: "auto" }}>
                                            <table class="table table-bordered">
                                                <thead style={{ background: '#151E80', color: 'white' }}>
                                                    <tr>
                                                        <th scope="col">Followup no.</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Followup date</th>
                                                        <th scope="col">Remarks</th>
                                                        <th scope="col">Updated by</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {tableArray && tableArray.map((jval, i) => {
                                                        return (
                                                            < tr >
                                                                <th scope="row">{jval.followupno}</th>
                                                                <td>{jval.statusname}</td>
                                                                <td>{jval.followup}</td>
                                                                <td>{jval.remark}</td>
                                                                <td>{jval.username}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    }

                                                </tbody>
                                            </table>
                                        </div> : null}
                                    {EditData && EditData.contractid == null || EditData.contractid == "empty" ?
                                        <>
                                            {
                                                statusArray.map((ival, i) => {
                                                    return (
                                                        <React.Fragment>

                                                            <div className="row form-group">
                                                                <div className="col-sm-1" />
                                                                <div className="col-sm-3">
                                                                    <label htmlFor="exampleInputEmail1" style={{ color: 'orange' }}>Status *</label>
                                                                </div>
                                                                <div className="col-sm-5">
                                                                    <SingleSelect
                                                                        placeholder="Select..."
                                                                        options={this.state.status1options}
                                                                        handleChange={d => this.handlechangeData(d, 'status', i)}
                                                                        selectedService={ival.status}
                                                                    />
                                                                </div>
                                                                <div className="col-sm-3" />
                                                            </div>
                                                            <div className="row form-group">
                                                                <div className="col-sm-4" />
                                                                <span className="error-show ">
                                                                    {this.state.errorstatus}
                                                                </span>
                                                            </div>

                                                            <div className="row form-group">
                                                                <div className="col-sm-1" />
                                                                <div className="col-sm-3">
                                                                    <label htmlFor="exampleInputEmail1">Next follow up </label>
                                                                </div>
                                                                <div className="col-sm-5">
                                                                    <input
                                                                        type="date"
                                                                        className="form-control"
                                                                        id="ColourName"
                                                                        name="followup"
                                                                        min={moment(new Date()).format("YYYY-MM-DD")}
                                                                        value={ival.followup}
                                                                        onChange={(e) => this.handlechangeData(e.target.value, 'followup', i)}
                                                                    />
                                                                </div>
                                                                <div className="col-sm-3" />
                                                            </div>
                                                            <div className="row form-group">
                                                                <div className="col-sm-4" />
                                                                <span className="error-show ">
                                                                    {this.state.errorfollow}
                                                                </span>
                                                            </div>


                                                            <div className="row form-group">
                                                                <div className="col-sm-1" />
                                                                <div className="col-sm-3">
                                                                    <label htmlFor="exampleInputEmail1">Remarks</label>
                                                                </div>
                                                                <div className="col-sm-5">
                                                                    <textarea
                                                                        type="textarea"
                                                                        className="form-control"
                                                                        id="remark"
                                                                        name="Remark"
                                                                        value={ival.remark}
                                                                        onChange={(e) => this.handlechangeData(e.target.value, 'remark', i)}
                                                                    />
                                                                </div>
                                                                <div className="col-sm-3" />
                                                            </div>
                                                            <div className="row form-group">
                                                                <div className="col-sm-4" />
                                                                <span className="error-show ">
                                                                    {ival.error}
                                                                </span>
                                                            </div>



                                                        </React.Fragment>)
                                                })
                                            }

                                            < br />
                                            <div className="row form-group">
                                                <div className="col-sm-2" />
                                                <div className="col-sm-3" />
                                                <div className="col-sm-5">
                                                    <button
                                                        type="button"
                                                        className={`btn btn-${!this.state.isEdit ? "primary" : "danger"
                                                            }`}
                                                        disabled={EditData.Reg_no ? !this.state.statusbtnDisable ? false : true : true}
                                                        onClick={this.UpdateCategory}
                                                    >
                                                        {this.state.statusbtnDisable ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;
                                                        Update Status
                                                    </button>
                                                </div>
                                                <div className="col-sm-2" />
                                            </div>
                                        </>
                                        :
                                        <div className="text-center">
                                            <h3 style={{ color: "#07278f" }}>Contract Form Already Filled</h3>
                                        </div>}
                                    {!EditData.Reg_no &&
                                        <div className="text-center">
                                            <h3 style={{ color: "#fc4c0d" }}>Please Select Matched Car First</h3>
                                        </div>
                                    }
                                    {/* <br />
                                    <br />
                                    <div className="row clearfix">
                                        <div className="col-sm-4 " />
                                        {isfollowup && this.validate() ? <div className="col-sm-2">
                                            <button
                                                type="button"
                                                class="btn btn-secondary waves-effect"
                                                onClick={() => this.AddMore(1)}
                                            >
                                                Add More
                                            </button>

                                        </div> : null}
                                        {statusArray && statusArray.length > 1 && <div className="col-sm-2">
                                            <button
                                                type="button"
                                                class="btn btn-warning waves-effect"
                                                onClick={() => this.AddMore(2)}

                                            >
                                                Remove
                                            </button>
                                        </div>}
                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </React.Fragment >
        )
    }
}
export default UpdateLeadStatus;