import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
//import UpdateStatus from "./UpdateStatus"
import moment from "moment";
//import LoginModal from "../../../../components/Modal/Modal";
import Datatable from "../../../../components/Datatable/Datatable";

class EvaluatorDelivery extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      customerId: localStorage.getItem("userId"),
      LocationID: localStorage.getItem(`LocationID`),
      EnquiryData: [],
      loading: false,
      alertVisible: false,
      MakeOptions: [],
      coloropt: [],
      variantopt: [],
      modelopt: [],
      CustomerDetails: [],
      column: [
        {
          Header: "Name",
          accessor: "name"
        },
        {
          Header: "Contact",
          accessor: "mobile"
        },
        {
          Header: "ApprovedBy",
          accessor: "userName"
        },
        {
          Header: "Make",
          accessor: "make",
          Cell: (d) => this.getValueFromArray(d.original.make, this.state.MakeOptions)

        },
        {
          Header: "Model",
          accessor: "model",
          Cell: (d) => this.getValueFromArray(d.original.model, this.state.modelopt)
        },
        {
          Header: "Variant",
          accessor: "variant",
          Cell: (d) => this.getValueFromArray(d.original.variant, this.state.variantopt)

        },
        // {
        //   Header: "Color",
        //   accessor: "color",
        //   Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)

        // },
        {
          Header: "Form",
          accessor: "mobile",
          minWidth: 120,
          Cell: d => this.Specification(d)
        },

        {
          Header: "Rc-Book",
          accessor: "mobile",
          Cell: d => this.ProvideRC(d)
        },
        {
          Header: "Status",
          accessor: "mobile",
          Cell: d => this.Approval(d)
        }
        // {
        //   Header: "Status",
        //   accessor: "status",
        //   Cell: d => this.status(d)
        // },

      ],
    }
    this.vals()
  }
  async vals() {
    let { data: Make } = await cmsContent.getFreedom(
      `Make as label , id as value`,
      `tbl_make_master`, 1,
      1, 1
    );
    if (Make) {
      this.setState({ MakeOptions: Make })
    }
    let { data: Colour } = await cmsContent.getFreedom(
      `colour as label , id as value`,
      `tbl_colour`,
      `ServiceId=10`,
      1,
      `id DESC`
    );
    if (Colour) {
      this.setState({ coloropt: Colour })
    }
    let { data: variant } = await cmsContent.getFreedom(
      "variant as label , id as value",
      "tbl_variant_master",
      `ServiceId=10`,
      1
    );
    if (variant) {
      this.setState({ variantopt: variant })

    }
    let { data: model } = await cmsContent.getFreedom(
      "Model as label , id as value",
      "tbl_dfcarsmodel",
      `ServiceId=10`,
      1
    );
    if (model) {
      this.setState({ modelopt: model })
    }
  }
  status = (d) => {
    if (d.original.status == "active") {
      // return(<button type="button" className="btn btn-success"
      // //onClick={()=>this.F_proofForm(d)}
      // >Approved</button>);
      return (<span className="label bg-success">Active</span>)
    } else {
      // return (<button type="button" className="btn btn-danger"
      // //onClick={()=>this.F_proofForm(d)}
      // >Waiting for approval</button>);
      return (<span className="label">InActive</span>)
    }
  }

  Approval = (d) => {
    if (d.original.approval == 1) {
      // return(<button type="button" className="btn btn-success"
      // //onClick={()=>this.F_proofForm(d)}
      // >Approved</button>);
      return (<span className="label bg-success">Approved</span>)
    } else {
      // return (<button type="button" className="btn btn-danger"
      // //onClick={()=>this.F_proofForm(d)}
      // >Waiting for approval</button>);
      return (<span className="label bg-primary">Approve</span>)
    }
  }

  F_proofForm = async (d) => {
    window.open(`/DFCars/AT_Delivery_Note_Buyer_Reproof/${d.original.id}`, '_self')
  }

  ProvideRC = (d) => {
    if (d.original.documents_received == 1) {
      return (<button type="button" className="btn btn-success"
      //onClick={()=>this.PRovideRCBook(d)}
      >Provided</button>);
    } else if (d.original.approval == 1) {
      return (<button type="button" className="btn btn-primary"
        onClick={() => this.PRovideRCBook(d)}
      >Provide</button>);
    } else {
      return (<button type="button" className="btn btn-secondary"
      //onClick={()=>this.F_proofForm(d)}
      >Provide</button>);
    }
  };

  PRovideRCBook = async (d) => {
    try {
      let sub = {};
      sub.documents_received = 1;

      const result = await cmsContent.updateMaster(
        "tbl_dfcarz_customers",
        d.original.value,
        sub
      );
      if (result) {
        console.log(result);
        let newData = [...this.state.Data];
        newData[d.index].documents_received = 1;
        this.setState({
          Data: newData
        })
      }
    } catch (error) {
      console.log(error);
    }
  }

  Specification = (d) => {
    //  if(d.original.FormFilled=='yes'||d.original.FormFilled=='approved'){
    return (<button type="button" className="btn btn-primary"
      onClick={() => this.F_proofForm(d)}
    >View Form</button>)
    // }else{
    //   return(<button type="button" className="btn btn-warning"
    //   onClick={()=>this.F_proofForm(d)}
    //   >Fill Form</button>)
    // }

  }

  async componentDidMount() {
    try {
      this.setState({ loading: true });
      let Buyer = await cmsContent.getFreedom(
        'tbl_dfcarz_prosperities.*,tbl_user_web.userName,tbl_Delivery_Note_Buyer_Reproof.id as value,tbl_Delivery_Note_Buyer_Reproof.approval',
        'tbl_dfcarz_prosperities,tbl_Delivery_Note_Buyer_Reproof,tbl_user_web',
        `tbl_user_web.id = tbl_Delivery_Note_Buyer_Reproof.userid and tbl_dfcarz_prosperities.type='buyer' and tbl_dfcarz_prosperities.id = tbl_Delivery_Note_Buyer_Reproof.customerid and tbl_dfcarz_prosperities.location =${this.state.LocationID}`
      )
      if (Buyer) {
        Buyer.data.map((ival, i) => {
          ival.FormFilled = 'no';
          ival.value = null;
          ival.documents_received = 0;
          ival.followup = moment(ival.followup).format("YYYY-MM-DD");
        })
        //console.log(Buyer);

        //  }

        let Customer = await cmsContent.getFreedom(
          `*`,
          `tbl_dfcarz_customers`,
          `type='buyer'`
        );
        if (Customer) {
          Buyer.data.map((ival, i) => {
            Customer.data.map((jval, j) => {
              // console.log(ival);
              // console.log(jval);
              if (ival.id == jval.prospectid && jval.documents_received == 1) {
                ival.documents_received = 1;
              } else if (ival.id == jval.prospectid) {
                ival.value = jval.id;
              }
            });
          });
          // console.log( Buyer.data);

          // this.setState({CustomerDetails:Customer.data})
        }
        this.setState({ Data: Buyer.data, loading: false })
      }

    } catch (error) {
      this.setState({ loading: false })
      console.log(error);
    }
  }

  render() {
    let { loading } = this.state;
    return (<React.Fragment>
      <main className="main my-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h1>Delivery Report</h1>
                </div>
                <div className="card-body">
                  {loading ? <div className="text-center"> <i
                    className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                  ></i></div> :
                    this.state.Data.length ? (
                      <Datatable
                        data={this.state.Data}
                        columnHeading={this.state.column}
                      />
                    ) : <h1>No data found</h1>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
    )
  }
}
export default EvaluatorDelivery;