import React from 'react'
import { Alert } from "reactstrap";
import CmsContent from "../../../../MiddleWare/CmsContent";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import Datatable from "../../../../components/Datatable/Datatable";
import { ACCESS_POINT } from "../../../../config/index"
import Icon from "react-icons-kit";
import LoginModal from "../../../../components/Modal/Modal";
import { wrench } from 'react-icons-kit/iconic/wrench'
import { task } from 'react-icons-kit/iconic/task'
import { calendar_ok } from 'react-icons-kit/ikons/calendar_ok'
import FormModal from '../../../../components/Modal/FormModal';
class RFtechnicianlist extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            usertypelog: JSON.parse(localStorage.getItem("usertypelog")),
            User: JSON.parse(localStorage.getItem("userlog")),
            Data: [],
            data2: [],
            data3: [],
            data4: [],
            data5: [],
            data6: [],
            data7: [],
            Datas: [],
            make: [],
            data_for_f: [],
            model: [],
            variant: [],
            MakeOptions: [],
            modelopt: [],
            yearopt: [],
            coloropt: [],
            variantopt: [],
            loading: false,
            color: [],
            RFTopt: [],
            data_for_f: [{ "fill": 0, "view": 0, "approve": 0, "assign": 0, "comp": 0, "pend": 0 }],
            column: [
                // {
                //     Header: "Name",
                //     accessor: "name",
                //     Cell: d => this.name(d.original)
                // },
                {
                    Header: "Reg No",
                    accessor: "REGNO",
                    Cell: d => this.regno(d.original)

                },
                {
                    Header: "Date",
                    accessor: "created_At"
                },
                {
                    Header: "MAKE",
                    accessor: "make",
                    Cell: (d) => this.getValueFromArray(d.original.make, this.state.MakeOptions)
                },
                {
                    Header: "MODEL",
                    accessor: "model",
                    Cell: (d) => this.getValueFromArray(d.original.model, this.state.modelopt)
                },
                {
                    Header: "Color",
                    accessor: "color",
                    Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)
                },
                {
                    Header: "Variant",
                    accessor: "variant",
                    Cell: (d) => this.getValueFromArray(d.original.variant, this.state.variantopt)
                },
                {
                    Header: "Year",
                    accessor: "year"
                },
                {
                    Header: "RF Incharge",
                    minWidth: 120,
                    accessor: "RFInchargeName"
                },
                {
                    Header: "Bad Summary",
                    accessor: "name",
                    minWidth: 130,
                    Cell: d => this.FormFill1(d.original)
                },
                {
                    Header: "Print PDF",
                    accessor: "pdf",
                    minWidth: 130,
                    Cell: d => this.viewpdf(d.original)
                },
                {
                    Header: "Form",
                    accessor: "name",
                    minWidth: 130,
                    Cell: d => this.FormFill(d.original)
                },
                {
                    Header: "View",
                    accessor: "approval",
                    width: 180,
                    Cell: d => this.view_Approval(d)
                },
            ]
        }
        this.vals()
    }
    regno = (d) => {
        if (d.reg_no) {
            return d.reg_no
        } else if (d.REGNO) {
            return d.REGNO
        } else {
            return "-"
        }
    }
    async vals() {

        let ALL_User_Details = await this.decryption('Downline_Details')
        if (ALL_User_Details) {
            console.log(ALL_User_Details, 'ALL_User_Details.TECHNICIANOPT');
        }
        let allOpt = await this.decryption('getcar')
        if (allOpt) {
            await this.setState({
                MakeOptions: allOpt.makeopt,
                coloropt: allOpt.coloropt,
                variantopt: allOpt.variantopt,
                modelopt: allOpt.modelopt
            })
        }

    }
    MainFunction = async () => {

        try {
            let Stage5_Prospective = await CmsContent.BUYDFCARZ(this.state.User.id, 'Technician_RF')
            console.log(Stage5_Prospective, "Stage5_Prospective");
            if (Stage5_Prospective.Refurbishment_Prospective) {

                await this.setState({
                    // Datas: Stage5_Prospective.Refurbishment_Prospective,
                    Datas: [],
                    data2: Stage5_Prospective.Refurbishment_Prospective.filter(x => x.filter_ispection_completed == true),
                    data4: Stage5_Prospective.Refurbishment_Prospective.filter(x => x.filter_work == true),
                    data5: Stage5_Prospective.Refurbishment_Prospective.filter(x => x.filter_assinged == true), // assi
                    data6: Stage5_Prospective.Refurbishment_Prospective.filter(x => x.filter_complete == true), //comp
                    data7: Stage5_Prospective.Refurbishment_Prospective.filter(x => x.filter_filled == true), //comp
                    data_for_f: Stage5_Prospective.data_for_f,
                    loading: false
                })
            }
        } catch (error) {
            this.setState({ loading: false })
            console.log(error);
        }
    }
    async componentDidMount() {
        this.setState({ loading: true })

        await this.MainFunction()
    }
    view_Approval = d => {
        return (
            <button
                type="button"
                className="btn btn-sm btn-primary"
                style={{ minWidth: "100px" }}
                onClick={() => this.opentable(d.original)}
            >
                View Details
            </button>
        )
    };
    opentable = async (d) => {
        if (d) {
            let Edit_Data = d
            await this.setState({ Edit_Data })
            window.$("#formmodel").modal("show")
        }
    }
    generatepdf = async (d) => {
        console.log("geneee", d);
        const document = await cmsContent.getFreedom("*", "tbl_checklist350", `customerId=${d.id}`, 1, 1);
        console.log(document.data)
        if (document) {
            if (document.data[0].Points == 350) {
                let documentpdf = await cmsContent.checklist("tbl_checklist350", document.data[0].customerid);
                console.log(documentpdf)
                if (documentpdf.data == "success") {
                    window.open(`${ACCESS_POINT}/cmsContent/downloadchecklist/${document.data[0].reg_no}`, '_self')
                }
            }
            else if (document.data[0].Points == 111) {
                let documentpdf = await cmsContent.checklist("tbl_checklist350", document.data[0].customerid);
                console.log(documentpdf)
                if (documentpdf.data == "success") {
                    window.open(`${ACCESS_POINT}/cmsContent/downloadchecklist111/${document.data[0].reg_no}`, '_self')
                }
            }
            else {
                let documentpdf = await cmsContent.checklist("tbl_checklist350", document.data[0].customerid);
                console.log(documentpdf)
                if (documentpdf.data == "success") {
                    window.open(`${ACCESS_POINT}/cmsContent/downloadchecklist50/${document.data[0].reg_no}`, '_self')
                }
            }
        }
    }
    viewpdf = d => {
        if (d.FormFilled == "yes" || d.FormFilled == "approved" || d.FormFilled == "work" || d.FormFilled == "inspection_completed") {
            return (<button type="button" className="btn btn-sm btn-warning"
                onClick={() => this.generatepdf(d)}>Print PDF</button>)
        }
        else {
            return (
                <div className="text-center">-</div>
            )
        }
    }

    name = (d) => {
        console.log(d);
        return (
            <div>
                <LoginModal
                    atagLink={true}
                    buttonTitle={d.name}
                    extraStyle={{ width: 'auto' }}
                    title={d.name}
                    id="view"
                    extraClass="btn-width"
                    bodyText={this.form(d)} />
            </div>);
    }
    form = (d) => {
        return (
            <div><form>
                <div class="row">
                    <div class="col">
                        <label>Mobile</label>
                    </div>
                    <div class="col">
                        <label>{d.mobile}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label>Name</label>
                    </div>
                    <div class="col">
                        <label>{d.name}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label>Make</label>
                    </div>
                    <div class="col">
                        <label>{d.make && this.getValueFromArray(d.make, this.state.make)}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label>Model</label>
                    </div>
                    <div class="col">
                        <label>{d.model && this.getValueFromArray(d.model, this.state.model)}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label>Variant</label>
                    </div>
                    <div class="col">
                        <label>{d.variant && this.getValueFromArray(d.variant, this.state.variant)}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label>Color</label>
                    </div>
                    <div class="col">
                        <label>{d.color && this.getValueFromArray(d.color, this.state.color)}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label>Year</label>
                    </div>
                    <div class="col">
                        <label>{d.year}</label>
                    </div>
                </div>

            </form>
            </div>
        )
    }
    FormFill = (d) => {
        if (d.FormFilled == "yes" || d.FormFilled == "inspection_completed") {
            return (
                <div>
                    <button
                        class='btn btn-sm btn-warning'
                        onClick={() => this.screenNavigate(d)}
                    >
                        View Form
                    </button>
                </div>
            );
        } else if (d.FormFilled == "work") {
            return (
                <div>
                    <button
                        class='btn btn-sm btn-warning'
                        onClick={() => this.screenNavigate(d)}
                    >
                        View Form
                    </button>
                </div>
            );
        }
        else if (d.FormFilled == "no") {
            return (
                <div>
                    <button
                        class='btn btn-sm btn-primary'
                        onClick={() => this.screenNavigate(d)}
                    >
                        Fill Form
                    </button>
                </div>
            );
        } else if (d.FormFilled == "approved") {
            return (
                <div>
                    <button
                        class='btn btn-sm btn-success'
                        onClick={() => this.screenNavigate(d)}
                    >
                        Approved
                    </button>
                </div>
            );
        } else {
            return (
                <div>
                    <button
                        class='btn btn-sm btn-primary'
                        onClick={() => this.screenNavigate(d)}
                    >
                        Fill Form
                    </button>
                </div>
            );
        }
    }
    FormFill1 = (d) => {
        if (d.FormFilled == "yes" || d.FormFilled == "inspection_completed" || d.FormFilled == "approved" || d.FormFilled == "work") {
            return (
                <div>
                    <button
                        class='btn btn-sm btn-danger'

                        onClick={() => this.BAdSumaryData(d)}
                    >
                        Bad Summary
                    </button>
                </div>
            );
        }
        else {
            return (
                <div>
                    -
                </div>
            );
        }
    }
    reset = async () => {
        await this.setState({ modaldata1: [] })
    }
    BAdSumaryData = async (d) => {
        let js = []
        js = d.checkjson
        let badselected = []
        let work_checklist = false
        let total_estimation_COst = 0
        let Selected_estimation_COst = 0
        let Work_technician = d.second_technician
        let Inspection_technician = d.RFTechnicianName

        if (js && js.length) {
            await js.map((ival, i) => {
                ival.map((jval) => {
                    if (jval.status == "Repair" || jval.status == "Average") {
                        badselected.push(jval)
                        if (jval && jval.estimate && jval.estimate) {
                            total_estimation_COst = parseInt(total_estimation_COst) + parseInt(jval.estimate)
                        }
                        if (jval && jval.estimate && jval.estimate && jval.work == true) {
                            Selected_estimation_COst = parseInt(Selected_estimation_COst) + parseInt(jval.estimate)
                        }
                        if (jval.work == true && jval.workProcess == false) {
                            work_checklist = true
                        }
                    }
                })
            })
            await this.setState({ data_come: d, badselected, Inspection_technician, total_estimation_COst, Work_technician, work_checklist, Selected_estimation_COst })
            await this.badsummarymodal(badselected, d)
            window.$("#badmodal").modal("show")
        }
    }
    badsummarymodal = async (badselected, d) => {
        let modaldata1 = []
        let { total_estimation_COst, work_checklist, Selected_estimation_COst, Work_technician, Inspection_technician } = this.state
        // console.log(badselected, "badselected", d, "d");
        modaldata1.push(
            <>
                <div className="bgcolor" style={{ width: "100%", overflow: "auto" }}>
                    <div class="set-form">
                        <table id="myTable" class="table table-bordered">
                            <tr>
                                <th>Name</th>
                                <th>Status</th>
                                <th>Remarks</th>
                                <th>Estimate</th>
                                <th>Work</th>
                                {d && d.FormFilled && (d.FormFilled != "yes" && d.FormFilled != "no" && d.FormFilled != "inspection_completed") && <th>Process</th>}
                            </tr>
                            {badselected.map((item, i) => {
                                return (
                                    <tbody ><tr>
                                        <td className="text-left " >
                                            {item.status && item.status == "Repair" ?
                                                <p style={{ color: "red", fontSize: "18px" }}>{i + 1 + ")"} {item.name}</p>
                                                :
                                                <p style={{ fontSize: "18px" }}>{i + 1 + ")"} {item.name}</p>
                                            }
                                        </td>

                                        <td>
                                            {item.status && item.status == "Repair" ?
                                                <label
                                                    style={{ color: "red", fontSize: "18px" }}
                                                >{
                                                        item.status
                                                    }</label>
                                                :
                                                <label
                                                    style={{ fontSize: "18px" }}
                                                >{
                                                        item.status
                                                    }</label>
                                            }
                                        </td>
                                        <td>
                                            {item.status && item.status == "Repair" ?
                                                <p style={{ color: "red", fontSize: "18px" }}>{item.remarks && item.remarks != null ? item.remarks : "-"}</p>
                                                :
                                                <p style={{ fontSize: "18px" }}>{item.remarks && item.remarks != null ? item.remarks : "-"}</p>
                                            }
                                        </td>
                                        <td>
                                            {item.status && item.status == "Repair" ?
                                                <p style={{ color: "red", fontSize: "18px" }}>{item.estimate && item.estimate != null ? item.estimate : "-"}</p>
                                                :
                                                <p style={{ fontSize: "18px" }}>{item.estimate && item.estimate != null ? item.estimate : "-"}</p>
                                            }
                                        </td>
                                        <td>
                                            <input
                                                style={{ marginTop: "-1.2px" }}
                                                className=""
                                                // id={`badselect${i}`}
                                                checked={item && item.work && item.work == true ? true : false}
                                                type='checkbox'
                                            />

                                        </td>
                                        {d && d.FormFilled && (d.FormFilled != "yes" && d.FormFilled != "no" && d.FormFilled != "inspection_completed") && <td>
                                            <input
                                                style={{ marginTop: "-1.2px" }}
                                                className=""
                                                id={`badselect${i}`}
                                                checked={item && item.workProcess && item.workProcess == true ? true : false}
                                                type='checkbox'
                                                onClick={(e) => this.Selected_work(e, 'workProcess', `badselect${i}`, true, i, item.name, item.work)}
                                            />

                                        </td>
                                        }
                                    </tr>
                                    </tbody>
                                );
                            })}
                        </table>
                    </div>
                </div >
                <div className="text-right"><b>Total Work Estimation : {total_estimation_COst} RS</b></div>
                <div className="text-right"><b>Selected Work Estimation : {Selected_estimation_COst} RS</b></div>
                {Inspection_technician ? <div className="text-right"><b style={{ color: "#07278f" }}>Inspection Technician : {Inspection_technician}</b></div> : ""}
                {Work_technician ? <div className="text-right"><b style={{ color: "#fc4c0d" }}>Work Technician : {Work_technician}</b></div> : ""}
                <br />
                {Work_technician && Inspection_technician && Inspection_technician == Work_technician ? "" : Work_technician ? <div className="text-right"><b style={{ color: "red", fontSize: "14px" }}>* Your Next Process is Handover to Another Technician</b></div> : ""}
                <br />
                {Work_technician && Inspection_technician && Inspection_technician == Work_technician ?
                    <div className="text-right" style={{ color: work_checklist == false ? "#07278f" : "#fc4c0d" }}><b>{work_checklist == false ? "Process Completed" : "Process OnGoing"}</b></div>
                    :
                    <div className="text-right" style={{ color: "#07278f" }}><b>{"Your Inspection Process Completed"}</b></div>
                }
                {work_checklist && d && d.FormFilled && (d.FormFilled != "yes" && d.FormFilled != "no" && d.FormFilled != "inspection_completed") &&
                    <button
                        type="button"
                        style={{ minWidth: "250px" }}
                        className="btn btn-sm btn-primary"
                        onClick={() => this.alert_Submit(this.Approval1, d)}
                    >
                        Update Work
                    </button>
                }
            </>
        )
        this.setState({ modaldata1 })
    }
    Approval1 = async (d, text) => {
        let data = d
        let body = {};
        body.checkjson = JSON.stringify(data.checkjson)
        try {
            let result = await cmsContent.updateMaster("tbl_checklist350", data.Formid, body);
            console.log(result);
            await this.setState({ reloadfun: true })

            if (result) {
                window.$("#badmodal").modal("hide")
                await this.MainFunction()
            }
        } catch (error) {
            console.log(error);
        }
    };
    Selected_work = async (e, name = null, id, value, index, data_find_name, work) => {
        let { badselected, data_come } = this.state
        if (work == true) {
            var checkBox = document.getElementById(id);
            if (checkBox.checked == true) {
                badselected[index][`${name}`] = value
            } else {
                badselected[index][`${name}`] = false
            }
            let data = data_come.checkjson
            data_come.checkjson = data
            await this.setState({ badselected })
            await this.badsummarymodal(badselected, data_come)

        }
    }
    screenNavigate = d => {
        console.log("id", d.id);
        if (d.FormFilled == "yes" || d.FormFilled == "approved" || d.FormFilled == "inspection_completed" || d.FormFilled == "work") {
            window.open(`/DFCars/CERTIFICATION_350_CheckPoints/${d.id}`, '_self')


        } else if (d.FormFilled == "no") {
            window.open(`/DFCars/CERTIFICATION_350_CheckPoints/${d.id}`, '_self')
        }
    };
    edash = () => {

        return (<div className="">
            <div className="card">
                {/* < div className="row" > */}
                <div className="card-header">
                    <div className="col-sm-6" >
                        <h2 className="mt-2 black">Dashboard</h2>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row col-12 mt-4">
                        <div className="col ">
                            <div className="dashboardCard1" onClick={() => this.onClk(4)} style={{ textAlign: 'center' }}>
                                <Icon className="dashboardBadge" icon={wrench} style={{ color: 'red', position: 'static', height: '40px' }} size={35} />
                                <h4 className="mt-2 black" style={{ paddingLeft: "" }}>Assigned Inspection</h4>
                                <div style={{ textAlign: 'center' }}>
                                    <h1 className="bold mt-4 mb-2 black" style={{cursor:"pointer"}}><b> {this.state.data_for_f && this.state.data_for_f[0] && this.state.data_for_f[0].assign ? this.state.data_for_f[0].assign : 0}</b></h1>
                                </div>
                            </div>
                        </div>
                        <div className="col ">
                            <div className="dashboardCard1" onClick={() => this.onClk(6)} style={{ textAlign: 'center' }}>
                                <Icon className="dashboardBadge" icon={calendar_ok} style={{ color: 'red', position: 'static', height: '40px' }} size={35} />
                                <h4 className="mt-2 black" style={{ paddingLeft: "" }}>Filled</h4>
                                <div style={{ textAlign: 'center' }}>
                                    <h1 className="bold mt-4 mb-2 black" style={{cursor:"pointer"}}><b> {this.state.data_for_f && this.state.data_for_f[0] && this.state.data_for_f[0].filled ? this.state.data_for_f[0].filled : 0}</b></h1>
                                </div>
                            </div>
                        </div>
                        <div className="col ">
                            <div className="dashboardCard1" onClick={() => this.onClk(2)} style={{ textAlign: 'center' }}>
                                <Icon className="dashboardBadge" icon={wrench} style={{ color: 'green', position: 'static', height: '40px' }} size={35} />
                                <h4 className="mt-2 black" style={{ paddingLeft: "" }}>Inspection Completed</h4>
                                <div style={{ textAlign: 'center' }}>
                                    <h1 className="bold mt-4 mb-2 black" style={{cursor:"pointer"}}><b> {this.state.data_for_f && this.state.data_for_f[0] && this.state.data_for_f[0].ins_comp ? this.state.data_for_f[0].ins_comp : 0}</b></h1>
                                </div>
                            </div>
                        </div>
                        <div className="col ">
                            <div className="dashboardCard1" onClick={() => this.onClk(3)} style={{ textAlign: 'center' }}>
                                <Icon className="dashboardBadge" icon={calendar_ok} style={{ color: 'green', position: 'static', height: '40px' }} size={35} />
                                <h4 className="mt-2 black" style={{ paddingLeft: "" }}>Working</h4>
                                <div style={{ textAlign: 'center' }}>
                                    <h1 className="bold mt-4 mb-2 black" style={{cursor:"pointer"}}><b> {this.state.data_for_f && this.state.data_for_f[0] && this.state.data_for_f[0].work ? this.state.data_for_f[0].work : 0}</b></h1>
                                </div>
                            </div>
                        </div>
                        <div className="col ">
                            <div className="dashboardCard1" onClick={() => this.onClk(5)} style={{ textAlign: 'center' }}>
                                <Icon className="dashboardBadge" icon={task} style={{ color: 'green', position: 'static', height: '40px' }} size={35} />
                                <h4 className="mt-2 black" style={{ paddingLeft: "" }}>Completed work</h4>
                                <div style={{ textAlign: 'center' }}>
                                    <h1 className="bold mt-4 mb-2 black" style={{cursor:"pointer"}}><b> {this.state.data_for_f && this.state.data_for_f[0] && this.state.data_for_f[0].comp ? this.state.data_for_f[0].comp : 0}</b></h1>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-sm-4 ">
                            <div className="dashboardCard1" onClick={() => this.onClk(6)} style={{ textAlign: 'center' }}>
                                <Icon className="dashboardBadge" icon={calendar_ok} style={{ color: 'green', position: 'static', height: '40px' }} size={35} />
                                <h4 className="mt-2 black" style={{ paddingLeft: "" }}>Pending work</h4>
                                <div style={{ textAlign: 'center' }}>
                                    <h1 className="bold mt-4 mb-2 black"><b> {this.state.data_for_f[0].pend ? this.state.data_for_f[0].pend : 0}</b></h1>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
        )
    }

    onClk = (val) => {
        // if (val === 1) { this.setState({ Data: this.state.data2 }) }
        if (val === 2) { this.setState({ Datas: this.state.data2 }) }
        if (val === 3) { this.setState({ Datas: this.state.data4 }) }
        if (val === 4) { this.setState({ Datas: this.state.data5 }) }
        if (val === 5) { this.setState({ Datas: this.state.data6 }) }
        if (val === 6) { this.setState({ Datas: this.state.data7 }) }

        window.scroll({
            top: 400,
            left: 0,
            behavior: "smooth",
        });
    }
    render() {
        const { Data, modaldata1, Edit_Data } = this.state;
        return (
            <React.Fragment>
                <FormModal
                    id={"formmodel"}
                    extraStyle={{ width: 'auto' }}
                    bodyText={
                        <div>
                            {this.state.Edit_Data &&
                                <table className="LeadTable">
                                    <thead>
                                        <tr class="LeadTable-head" style={{ background: "#07278f" }}>
                                            <th class="column1">Type</th>
                                            <th class="column2">Data</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Edit_Data.name && <tr>
                                            <td class="column1">Name </td>
                                            <td class="column2"> {Edit_Data.name} </td>
                                        </tr>}
                                        {Edit_Data.mobile && <tr>
                                            <td class="column1">Mobile Number </td>
                                            <td class="column2"> {Edit_Data.mobile} </td>
                                        </tr>}
                                        {Edit_Data.make && <tr>
                                            <td class="column1">Make</td>
                                            <td class="column2"> {this.getValueFromArray(Edit_Data.make, this.state.MakeOptions)} </td>
                                        </tr>}
                                        {Edit_Data.model && <tr>
                                            <td class="column1">Model </td>
                                            <td class="column2"> {this.getValueFromArray(Edit_Data.model, this.state.modelopt)} </td>
                                        </tr>}
                                        {Edit_Data.variant && <tr>
                                            <td class="column1">Variant </td>
                                            <td class="column2"> {this.getValueFromArray(Edit_Data.variant, this.state.variantopt)} </td>
                                        </tr>}
                                        {Edit_Data.color && <tr>
                                            <td class="column1">Color </td>
                                            <td class="column2"> {this.getValueFromArray(Edit_Data.color, this.state.coloropt)} </td>
                                        </tr>}
                                        {Edit_Data && Edit_Data.commit == 1 && <tr>
                                            <td class="column1">Commit</td>
                                            <td class="column2"> Yes </td>
                                        </tr>}
                                        {Edit_Data && Edit_Data.commit_work && Edit_Data.commit_work.length > 0 && <tr>
                                            <td class="column1">Commit Work  </td>
                                            <td class="column2">
                                                {Edit_Data.commit_work && Edit_Data.commit_work.length && Edit_Data.commit_work.map((ival, i) => {
                                                    return (
                                                        <>
                                                            {i + 1}: {ival}<br />
                                                        </>
                                                    )
                                                })}
                                            </td>
                                        </tr>}

                                    </tbody>
                                </table>
                            }
                        </div>
                    }
                />
                <main className="main my-4">
                    <div className="container-fluid">
                        {/* {Modmodaldata1 && Modmodaldata1 == true && */}
                        <div class="modal fade" id="badmodal" tabindex="-1" role="dialog" aria-labelledby="exampleModal" aria-hidden="true" >
                            <div class="modal-dialog modal-lg" role="document">
                                <div class="modal-content text-center" style={{ width: "auto" }}>
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLongTitle">      Bad Summary</h5>
                                        <button type="button" class="close" onClick={() => { this.reset() }} data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body" >
                                        {modaldata1 && modaldata1}
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-sm btn-secondary" onClick={() => { this.reset() }} data-dismiss="modal">Close</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* } */}
                        {this.edash()}
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h1>Refurbishment</h1>
                                    </div>
                                    <div className="card-body">
                                        {this.state.loading ? <div className="text-center"> <i
                                            className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                                        ></i></div> : this.state.Datas && this.state.Datas.length > 0 ? (
                                            this.state.Datas.length > 0 ?
                                                <Datatable
                                                    data={this.state.Datas}
                                                    columnHeading={this.state.column}
                                                /> : null
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>)
    }
}
export default RFtechnicianlist;