import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../components/Form/FormMiddleware";
import DfCarsEnquiry from "./Evaluator/DfCarsEnquiry";
import moment from "moment";
import Datatable from "../../../components/Datatable/Datatable";

class DfCarsViewhistory extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      customerId: localStorage.getItem("userId"),
      EnquiryData:[],
      alertVisible:false,
      column:[
        {
            Header: "comments",
            accessor: "comments"
          },
          {
            Header: "status",
            accessor: "status"
          },
          {
            Header: "Next_Contact_date",
            accessor: "Next_Contact_date"
          },
          {
            Header: "Budget",
            accessor: "Budget"
          },
          {
            Header: "EditedBy",
            accessor: "userName"
          },
          {
            Header: "EditedAt",
            accessor: "createdAt"
          },
      ]
    }
}

async componentDidMount() {
     //console.log(this.props);
     let id = this.props.match.params.id;
     const result = await cmsContent.getFreedom(
        'tbl_dfcars_enquiryhistory.*,tbl_user_web.userName',
        'tbl_dfcars_enquiryhistory,tbl_user_web',
        `tbl_dfcars_enquiryhistory.enquiryid=${id} and tbl_user_web.id=tbl_dfcars_enquiryhistory.userid`
        );
        if(result){
            console.log(result.data)
            this.setState({EnquiryData:result.data});
        }

        const result1 = await cmsContent.getFreedom(`*`,`tbl_dfcars_enquiryhistory`,1);
        console.log(result1)
     
} 


render(){
    return( <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Enquiry Report</h1>
                  </div>
                  <div className="card-body">
                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-7">
                            <Alert
                              className="badge-content"
                              color={this.state.color}
                              isOpen={this.state.alertVisible}
                              toggle={this.onDismiss}
                            >
                              {this.state.textalert}
                            </Alert>
                          </div>
                          <div className="col-sm-3" />
                        </div>
                        {this.state.EnquiryData.length ? (
                              <Datatable
                                data={this.state.EnquiryData}
                                columnHeading={this.state.column}
                              />
                            ) : null}
                    </div>    
                  </div>
                  </div>
                  </div>
                  </div>
                  </main>
                  </React.Fragment>)
}

}
export default DfCarsViewhistory;