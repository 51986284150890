import React, { useEffect, useState } from "react";
import http from "./httpMiddleWare";
export default function Geolocationfun({ ...params }) {
    let [coords, setcoords] = useState(null)
    let [errmsg, seterrmsg] = useState(null)
    let [loading, setloading] = useState(true)
    useEffect(async () => {
        getlocation()
    }, [])
    const getlocation = async () => {
        await navigator.geolocation.getCurrentPosition(async (e) => {
            setcoords(e.coords)
            seterrmsg(null)
            if (params.location && params.location == true) {
                let URL = `https://us1.locationiq.com/v1/reverse.php?key=pk.0754aed4ef01925ec3cc780d9a711ef2&format=json&lat=${e.coords.latitude}&lon=${e.coords.longitude}`;
                let { data: data } = await http.get(URL)
                if (data && data) {
                    params.address(data.display_name)
                }
            } else {
                params.coords(e.coords)
            }
            setloading(false)
        }, (err) => {
            // params.coords(err)
            seterrmsg(err)
            setloading(false)
        }, { enableHighAccuracy: true }
        )
    }
    const realod = () => {
        setloading(true)
        seterrmsg(null)
        getlocation()
    }
    return (
        <div className="row">
           
            <div className="col-sm-4 text-center">
                {loading && loading ?
                    <div class="DFCARS_REFRESH text-left"></div> :
                    <i className="icon-refresh" onClick={realod} > Reload Location</i>
                }
            </div>

            <div className="col-sm-4 text-center">
                {errmsg && errmsg.message ? <>
                    <span style={{ color: 'red', margin: 10 }}>{errmsg.message}</span>
                </> : ""}
            </div>
            <div className="col-sm-2 text-center" />

        </div>
    )
}
