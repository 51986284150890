import React, { Component } from "react";
import { Alert } from "reactstrap";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import SingleSelect from "../../../../components/Form/SingleSelect";
import redisMiddleware from "../../../../MiddleWare/redisMiddleware";
import CmsContent from "../../../../MiddleWare/CmsContent";
import Preloader from "../preloader.js";
import { ACCESS_POINT } from "../../../../config/index";
import { FilePond, File, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

class Evaluation_Checklist_B_Proof extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      respect: [{ label: "Sir", value: 1 }, { label: "Madam", value: 1 }],
      answerJson: [],
      filesArray1: [],
      filesArray2: [],
      filesArray3: [],
      fileJson1: [],
      fileJson2: [],
      fileJson3: [],
      json: [],
      followup: ["FIRST FOLLOW UP", "SECOND FOLLOW UP", "THIRD FOLLOW UP"],
      userid: JSON.parse(localStorage.getItem("userlog")),
      follow: [
        { label: "DENTS", key: "D" },
        { label: "SCRATCHES", key: "S" },
        { label: "RUST", key: "RU" },
        { label: "RE PAINT", key: "RE" }
      ],
      view: ["FRONT", "REAR"],
      lights: [
        "HEADLAMPS",
        "TAIL LAMPS",
        "INDICATORS-FRONT",
        "INDICATORS-REAR",
        "DOOR MIRRORS"
      ],
      option: [{ label: "Good", value: 1 }, { label: "Average", value: 2 }, { label: "Bad", value: 3 }],
      pro: ["EMBLEMS", "WEATHER STRIPS", "RUN CHANNEL", "DOOR PROTECTION"],
      wheel: ["WHEEL CAPS", "WHEEL RIMS", "TRUNK AREA", "TOOL KIT&JACK"],
      interiorJson: [],
      interior_ans_Json: [],
      bodyJson: [],
      bumperJson: [{ name: "", body: "", remarks: "" }],
      windJson: [{ name: "", body: "", remarks: "" }],
      lightJson: [{ name: "", rhs: "", lhs: "", remarks: "" }],
      tyersJson: [{ name: "", rhs: "", lhs: "", remarks: "" }],
      wheeklJson: [{ name: "", body: "", remarks: "" }],
      proJson: [{ name: "", body: "", remarks: "" }],
      yesorno: [{ label: "Yes", value: 1 }, { label: "No", value: 2 }],
      windChecked: false,
      alertVisible: false,
      button: "Save"
    };
  }

  async componentDidMount() {
    try {
      const form = await redisMiddleware.singleTable("tbl_form", 73, "id");
      const formQues = await redisMiddleware.singleTable(
        "tbl_formquestion",
        73,
        "parentid"
      );
      // console.log(formQues)
      formQues.data.map((ival, i) => {
        if (ival.Orderby == null) {
          ival.Orderby = 1000 + i;
        }
      });

      formQues.data = formQues.data.sort(await CmsContent.compareValues("Orderby", "int", "asc"));
      console.log(formQues)
      formQues.data.map((qval, q) => {

        if (qval.id == 630) {
          qval.type = "selectbox";
          qval.options = this.state.yesorno;
        }
        if (qval.id == 629) {
          qval.type = "selectbox";
          qval.options = this.state.yesorno;
        }
      })
      if (this.props.cusid) {
        this.setState({ customerId: this.props.cusid, global: false })
      }
      else if (this.props.match.params.cusid) {
        this.setState({ customerId: this.props.match.params.cusid, global: true });
      }
      // if (this.props.match.params.cusid) {
      //   this.setState({ customerId: this.props.match.params.cusid });
      // }

      let interior_ans_Json = [];
      let bodyJson = [];
      this.state.follow.map((ival, i) => {
        bodyJson.push({
          name: null,
          remarks: null
        });
        interior_ans_Json.push({
          index: "",
          name: "",
          date: "",
          remarks: ""
        });
      });
      let bumperJson = [];
      let tyersJson = [];
      this.state.view.map((ival, ind) => {
        bumperJson.push({
          name: "",
          body: "",
          remarks: ""
        });
        tyersJson.push({
          name: "",
          rhs: "",
          lhs: "",
          remarks: ""
        });
      });
      console.log(tyersJson);
      let proJson = [];
      this.state.pro.map((ival, i) => {
        proJson.push({ name: "", body: "", remarks: "" });
      });
      let lightJson = [];
      this.state.lights.map((ival, i) => {
        lightJson.push({ name: "", rhs: "", lhs: "", remarks: "" });
      });
      let wheeklJson = [];
      this.state.wheel.map((ival, i) => {
        wheeklJson.push({ name: "", body: "", remarks: "" });
      });
      this.setState({
        data: formQues.data,
        interior_ans_Json,
        bumperJson,
        proJson,
        tyersJson,
        lightJson,
        wheeklJson
      });
      let result = await CmsContent.getFreedom(
        "*",
        "tbl_BProof",
        `customerid = ${this.state.customerId}`
      );
      console.log(result);

      if (result && result.data.length > 0) {
        await this.setState({ ans: result.data });
        this.form_filled();
      } else {
        this.process();
      }

      this.process();
    } catch (error) {
      console.error(error);
    }
  }



  form_filled = async () => {
    let ans = this.state.ans[0];
    console.log(ans);
    let answerJson = this.state.answerJson;
    let bodyJson = this.state.bodyJson;
    let lightJson = this.state.lightJson;
    let bumperJson = this.state.bumperJson;
    let tyersJson = this.state.tyersJson;
    let wheeklJson = this.state.wheeklJson;
    let windJson = this.state.windJson;
    let interiorJson = this.state.interiorJson;
    let proJson = this.state.proJson;
    let interior_ans_Json = this.state.interior_ans_Json;
    answerJson.id = ans.id;
    answerJson[207] = ans.accident;
    answerJson[208] = ans.carriage;
    answerJson[630] = JSON.parse(ans.flood);
    answerJson[629] = JSON.parse(ans.tampered);

    bodyJson = JSON.parse(ans.body);
    lightJson = JSON.parse(ans.lights);
    bumperJson = JSON.parse(ans.bumper);
    tyersJson = JSON.parse(ans.tyres);
    wheeklJson = JSON.parse(ans.wheels);
    proJson = JSON.parse(ans.protection);
    windJson = JSON.parse(ans.windscreen);
    interior_ans_Json = JSON.parse(ans.follow);

    console.log(interior_ans_Json);

    // ans.body = JSON.stringify(bodyJson);
    // ans.windscreen = JSON.stringify(windJson);
    // ans.bumper = JSON.stringify(bumperJson);
    // ans.lights = JSON.stringify(lightJson);
    // ans.protection = JSON.stringify(proJson);
    // ans.tyres = JSON.stringify(tyersJson);
    // ans.wheels = JSON.stringify(wheeklJson);
    // ans.follow = JSON.stringify(interiorJson);

    await this.setState({
      answerJson,
      interior_ans_Json,
      interiorJson,
      bodyJson,
      lightJson,
      bumperJson,
      tyersJson,
      wheeklJson,
      windJson,
      proJson,
      button: "Update"
    });

    this.process();
  };

  async answers(
    label,
    index,
    options = null,
    key = null,
    type,
    questionId,
    formid
  ) {
    let answerJson = await this.state.answerJson;

    if (type == "text") {
      answerJson[questionId] = label;
    } else if (type == "date") {
      var d = new Date(label);
      var date = d.getDate();
      var month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      var year = d.getFullYear();
      var hr = d.getHours();
      var min = d.getMinutes();
      var sec = d.getSeconds();
      var dateStr =
        year + "-" + month + "-" + date + " " + hr + ":" + min + ":" + sec;
      answerJson[questionId] = dateStr;
    } else if (type == "textarea") {
      answerJson[questionId] = label;
    } else if (type == "selectbox") {
      answerJson[questionId] = label;

    } else if (type == "radio") {
      answerJson[questionId] = label;
    } else if (type == "checkbox") {
      let check = [];
      options.map((ival, i) => {
        var checkBox = document.getElementById(`inlineCheckbox${index}${i}`);
        if (checkBox.checked == true) {
          check.push(ival);
        }
      });
      answerJson[questionId] = check.toString();
    }
    await this.setState({ answerJson });
    this.process();
  }
  process = async () => {
    let data = await this.state.data;
    if (data) {
      let arr = data;
      let contentJson = [];
      if (arr && arr.length) {
        let answerJson = await this.state.answerJson;
        let answerJsons = answerJson.length;
        let json = await arr.map(async (item, index) => {
          if (item.type == "text") {
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="position">{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={item.placeholder}
                      onChange={e =>
                        this.answers(
                          e.target.value,
                          index,
                          null,
                          null,
                          "text",
                          item.id,
                          item.parentid
                        )
                      }
                      value={answerJson[item.id]}
                    />
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "date") {
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="position">{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <input
                      type="date"
                      id="myDate"
                      className="form-control"
                      onChange={e =>
                        this.answers(
                          e.target.value,
                          index,
                          null,
                          null,
                          "date",
                          item.id,
                          item.parentid
                        )
                      }
                      value={answerJson[item.id]}
                    // onChange={this.fromDateSelect}
                    // value={selectedstartdate}
                    />
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "textarea") {
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder={item.placeholder}
                      onChange={e =>
                        this.answers(
                          e.target.value,
                          index,
                          null,
                          null,
                          "textarea",
                          item.id,
                          item.parentid
                        )
                      }
                      value={answerJson[item.id]}
                    ></textarea>
                  </div>
                  <div className="col-sm-3">
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                </div>
              </div>
            );
          }
          if (item.type == "radio") {
            let arr1 = [];
            await item.options.split(",").map((element, key) => {
              arr1.push(
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      name={`element${index}`}
                      // value={`${element}`}
                      id={`inlineCheckbox${key}`}
                      onChange={e =>
                        this.answers(
                          element,
                          key,
                          null,
                          key,
                          "radio",
                          item.id,
                          item.parentid
                        )
                      }
                    />
                    <label
                      className="form-check-label"
                      for={`inlineCheckbox${key}`}
                      style={{ marginLeft: "10px" }}
                    >
                      {`${element}`}{" "}
                    </label>
                  </div>
                </div>
              );
            });
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    {arr1}
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "selectbox") {
            // let option = [];
            // item.options.split(",").map((ival, i) => {
            //   option.push({ label: ival, value: i });
            // });
            let option = [];
            if (item.options != null) {
              if (item.options) {
                item.options.map((ival, i) => {
                  option.push(ival);
                });
              }
            }

            this.state.selectbox = answerJson[item.id];

            await contentJson.push(
              <div>
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <SingleSelect
                      options={option}
                      placeholder={item.placeholder}
                      handleChange={e => {
                        this.answers(
                          e,
                          index,
                          null,
                          null,
                          "selectbox",
                          item.id,
                          item.parentid
                        );
                      }}
                      selectedService={this.state.selectbox}
                    />
                  </div>
                  <span className="error-shows" id={`${index}`}>
                    {/* {this.state.error[index]} */}
                  </span>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "checkbox") {
            let arr1 = [];
            await item.options.split(",").map((element, key) => {
              arr1.push(
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`inlineCheckbox${index}${key}`}
                    onChange={e => {
                      this.answers(
                        element,
                        index,
                        item.options.split(","),
                        key,
                        "checkbox",
                        item.id,
                        item.parentid
                      );
                    }}
                    value={element}
                  />
                  <label
                    className="form-check-label"
                    for={`inlineCheckbox${index}${key}`}
                    style={{ marginLeft: "10px" }}
                  >{`${element}`}</label>
                </div>
              );
            });
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">{arr1}</div>
                  <div className="col-sm-3">
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                </div>
              </div>
            );
          }
        });

        await contentJson.push(
          <div className="bgcolor">
            <div class="set-form">
              <table id="myTable" class="table table-bordered">
                <tr>
                  <th>FOLLOW UP STATUS</th>
                  <th>DATE</th>
                  <th>REMARKS</th>
                </tr>
                {this.state.followup.map((ival, ind) => {
                  let interior_ans_Json = this.state.interior_ans_Json;
                  console.log(ind);
                  console.log(this.state.interior_ans_Json);
                  if (ival.date) {
                    this.state.date = interior_ans_Json;
                  }
                  return (
                    <tr>
                      <td>
                        <p>{ival}</p>
                      </td>
                      <td>
                        <input
                          type="date"
                          name="Date"
                          onChange={e => {
                            this.interiorEva(ival, e.target.value, ind, "date");
                          }}
                          style={{ resize: "none", width: "100%" }}
                          value={
                            interior_ans_Json[ind] &&
                              interior_ans_Json[ind].date
                              ? interior_ans_Json[ind].date
                              : null
                          }
                        ></input>
                      </td>
                      <td>
                        <input
                          type="textarea"
                          name="REMARKS"
                          placeholder="REMARKS"
                          id="remarks"
                          onChange={e => {
                            this.interiorEva(
                              ival,
                              e.target.value,
                              ind,
                              "remarks"
                            );
                          }}
                          value={
                            interior_ans_Json[ind] &&
                              interior_ans_Json[ind].remarks
                              ? interior_ans_Json[ind].remarks
                              : null
                          }
                          style={{ resize: "none", width: "100%" }}
                        ></input>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        );
        // await Promise.all(json);

        await this.setState({ json: contentJson, answerJson });
      }
    }
  };

  interiorEva = async (name, value, index, type) => {
    let interior_ans_Json = this.state.interior_ans_Json;
    // var as = document.getElementById("evaluation");
    // console.log(as);
    //interiorJson[index].index = index;
    interior_ans_Json[index].name = name;
    interior_ans_Json[index][type] = value;
    //   name: name,
    //   [type]: value
    // };
    // console.log()
    await this.setState({ interior_ans_Json });
  };
  interiorRem = async (name, value, index, type) => {
    console.log(value);
    let interiorJson = this.state.interiorJson;
    interiorJson[index] = {
      index: index,
      name: name
    };
    interiorJson[index].remarks = value;
    console.log(interiorJson);
    await this.setState({ interiorJson });
  };
  update = async () => {
    const {
      answerJson,
      interiorJson,
      bodyJson,
      interior_ans_Json,
      windJson,
      lightJson,
      bumperJson,
      tyersJson,
      wheeklJson,
      proJson
    } = this.state;
    let categoryArray = {};
    let id = answerJson.id;
    categoryArray.flood = JSON.stringify(answerJson[630])
    categoryArray.tampered = JSON.stringify(answerJson[629])

    categoryArray.accident = answerJson[207];
    categoryArray.carriage = answerJson[208];
    categoryArray.body = JSON.stringify(bodyJson);
    categoryArray.windscreen = JSON.stringify(windJson);
    categoryArray.bumper = JSON.stringify(bumperJson);
    categoryArray.lights = JSON.stringify(lightJson);
    categoryArray.protection = JSON.stringify(proJson);
    categoryArray.tyres = JSON.stringify(tyersJson);
    categoryArray.wheels = JSON.stringify(wheeklJson);
    categoryArray.follow = JSON.stringify(interior_ans_Json);
    let result = await CmsContent.updateMaster("tbl_BProof", id, categoryArray);
    console.log(result);
    if (result) {
      await this.setState({
        answerJson: "",
        json: "",
        alertVisible: true,
        textalert: "Your form has been updated successfully",
        color: "success"
      });
      setTimeout(() => this.setState({ alertVisible: false }), 3000);
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  };
  download = (id) => {
    window.open(`${ACCESS_POINT}/cmsContent/downloadbproof/${id}`, '_blank')
  }
  generatepdf = async (id) => {
    let exportpdf = await CmsContent.bproof("tbl_BProof", id);
    if (exportpdf.data == "success") {
      this.download(id)
    }
  }
  formSubmitPdf = async () => {
    const {
      answerJson,
      interiorJson,
      interior_ans_Json,
      bodyJson,
      windJson,
      lightJson,
      bumperJson,
      tyersJson,
      wheeklJson,
      proJson
    } = this.state;
    let categoryArray = {};
    categoryArray.flood = answerJson[630]
    categoryArray.tampered = answerJson[629]
    categoryArray.accident = answerJson[207];
    categoryArray.carriage = answerJson[208];
    categoryArray.body = JSON.stringify(bodyJson);
    categoryArray.windscreen = JSON.stringify(windJson);
    categoryArray.bumper = JSON.stringify(bumperJson);
    categoryArray.lights = JSON.stringify(lightJson);
    categoryArray.protection = JSON.stringify(proJson);
    categoryArray.tyres = JSON.stringify(tyersJson);
    categoryArray.wheels = JSON.stringify(wheeklJson);
    categoryArray.follow = JSON.stringify(interior_ans_Json);
    categoryArray.customerid = this.state.customerId;
    categoryArray.userid = this.state.userid.id;
    // categoryArray.customerid;
    // categoryArray.userid;
    // categoryArray.serviceid;
    categoryArray.status = "active";
    categoryArray.approval = 0;

    try {
      let result = await CmsContent.addMaster("tbl_BProof", categoryArray);
      console.log(result);
      if (result) {
        // this.generatepdf(result.data.insertId)
        this.generatepdf(this.state.customerId);
        await this.setState({
          answerJson: "",
          alertVisible: true,
          textalert: "Your form has been submitted successfully",
          color: "success"
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  formSubmit = async () => {
    // let error = [...this.state.error];
    // let e = [];
    // this.state.data.map((item, i) => {
    //   this.state.answerJson.map((element, key) => {
    //     if (
    //       item.required == 1 &&
    //       item.id == element.questionId &&
    //       (element.answer == null || element.answer == "-")
    //     ) {
    //       error[i] = "Value must not be empty";
    //       e.push(error[i]);
    //     } else if (
    //       item.required == 1 &&
    //       item.id == element.questionId &&
    //       (element.answer != null || element.answer != "-")
    //     ) {
    //       error[i] = null;
    //       e.push(error[i]);
    //     }
    //   });
    // });
    // this.setState({ error: error });
    // this.process();

    // for (var i = 0; i < e.length; i++) {
    //   if (e[i] != null) {
    //     return false;
    //   }
    // }
    const {
      answerJson,
      interiorJson,
      interior_ans_Json,
      bodyJson,
      windJson,
      lightJson,
      bumperJson,
      tyersJson,
      wheeklJson,
      proJson
    } = this.state;
    let categoryArray = {};
    categoryArray.flood = answerJson[630]
    categoryArray.tampered = answerJson[629]
    categoryArray.accident = answerJson[207];
    categoryArray.carriage = answerJson[208];
    categoryArray.body = JSON.stringify(bodyJson);
    categoryArray.windscreen = JSON.stringify(windJson);
    categoryArray.bumper = JSON.stringify(bumperJson);
    categoryArray.lights = JSON.stringify(lightJson);
    categoryArray.protection = JSON.stringify(proJson);
    categoryArray.tyres = JSON.stringify(tyersJson);
    categoryArray.wheels = JSON.stringify(wheeklJson);
    categoryArray.follow = JSON.stringify(interior_ans_Json);
    categoryArray.customerid = this.state.customerId;
    categoryArray.userid = this.state.userid.id;
    // categoryArray.customerid;
    // categoryArray.userid;
    // categoryArray.serviceid;
    categoryArray.status = "active";
    categoryArray.approval = 0;

    try {
      let result = await CmsContent.addMaster("tbl_BProof", categoryArray);
      console.log(result);
      if (result) {

        await this.setState({
          answerJson: "",
          alertVisible: true,
          textalert: "Your form has been submitted successfully",
          color: "success"
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  body = (label, value, index, type) => {
    let bodyJson = this.state.bodyJson;
    bodyJson[index] = {
      name: label.label,
      remarks: value
    };
    this.setState({ bodyJson });
  };
  wind = (label, value, type, index = "") => {
    console.log(label);
    let windJson = this.state.windJson;
    // let follow = this.state.follow;
    // console.log(follow[index]);
    // let check = [];
    // // options.map((ival, i) => {
    // var checkBox =
    //document.getElementById(`windscreenCheckbox${index}`).checked = true;
    // if (checkBox.checked == true) {
    // //     check.push(ival);
    //   this.setState({ windChecked: true });
    // }
    // // });
    // // answerJson[questionId] = check.toString();
    windJson[0].name = label;
    windJson[0][type] = value;
    console.log(windJson);
    this.setState({ windJson });
  };
  interiros = async (iname, value, index, type) => {
    let interiorJson = this.state.interiorJson;
    interiorJson[index].name = iname;
    interiorJson[index][type] = value;
    console.log(interiorJson);
    this.setState({ interiorJson });
  };

  lights = (label, value, index, type) => {
    let lightJson = this.state.lightJson;
    lightJson[index].name = label;
    lightJson[index][type] = value;
    console.log(lightJson);
    this.setState({ lightJson });
  };
  bump = (label, value, index, type) => {
    let bumperJson = this.state.bumperJson;
    bumperJson[index].name = label;
    bumperJson[index][type] = value;
    this.setState({ bumperJson });
  };

  profun = (label, value, index, type) => {
    let proJson = this.state.proJson;
    proJson[index].name = label;
    proJson[index][type] = value;
    console.log(proJson);
    this.setState({ proJson });
  };
  tyers = (label, value, index, type) => {
    let tyersJson = this.state.tyersJson;
    console.log(tyersJson);
    tyersJson[index].name = label;
    tyersJson[index][type] = value;
    this.setState({ tyersJson });
  };
  wheeelfun = (label, value, index, type) => {
    let wheeklJson = this.state.wheeklJson;
    wheeklJson[index].name = label;
    wheeklJson[index][type] = value;
    console.log(wheeklJson);

    this.setState({ wheeklJson });
  };
  exportpdf = async () => {
    let answerJson = this.state.answerJson;
    let id = answerJson.id;
    window.open(`${ACCESS_POINT}/cmsContent/downloadbproof/${id}`, '_blank')
  }
  print = () => {

  }

  fileupload1 = async e => {
    let files = this.state.files;
    let filesArray1 = this.state.filesArray1;
    let fileJson1 = [];
    this.setState({ fileJson1 });
    e.map((fileItem, index) => {
      fileJson1.push({
        index: index,
        file: fileItem.file,
        name: fileItem.file.name
      });
    });
    this.setState({
      filesArray1: e.map(fileItem => fileItem.file),
      fileJson1
    });
  };
  fileupload2 = async e => {
    let files = this.state.files;
    let filesArray2 = this.state.filesArray2;
    let fileJson2 = [];
    this.setState({ fileJson2 });
    e.map((fileItem, index) => {
      fileJson2.push({
        index: index,
        file: fileItem.file,
        name: fileItem.file.name
      });
    });
    this.setState({
      filesArray2: e.map(fileItem => fileItem.file),
      fileJson2
    });
  };
  fileupload3 = async e => {
    let files = this.state.files;
    let filesArray3 = this.state.filesArray3;
    let fileJson3 = [];
    this.setState({ fileJson3 });
    e.map((fileItem, index) => {
      fileJson3.push({
        index: index,
        file: fileItem.file,
        name: fileItem.file.name
      });
    });
    this.setState({
      filesArray3: e.map(fileItem => fileItem.file),
      fileJson3
    });
  };
  render() {
    const {
      resSelected,
      json,
      bodyJson,
      lightJson,
      bumperJson,
      tyersJson,
      interiorJson,
      wheeklJson,
      proJson,
      windChecked,
      button
    } = this.state;
    //console.log(bodyJson);
    //console.log(windChecked)

    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div
                  className="card"
                  style={{
                    margin: "-20px",
                    marginTop: "5px",
                    boxShadow: "0px 0px 10px #b9b3b3",
                    borderRadius: "8px"
                  }}
                >
                  <div className="card-header">
                    <h1>Evaluation Checklist B Proof</h1>
                  </div>
                  <div className="card-body">
                    <div>
                      <Alert
                        className="badge-content"
                        color={this.state.color}
                        isOpen={this.state.alertVisible}
                        toggle={this.onDismiss}
                      >
                        {this.state.textalert}
                      </Alert>
                      {json && json.length > 0 && json ? json : <div>Loading...</div>}
                      <div className="bgcolor">
                        <div class="set-form">
                          <table id="myTable" class="table table-bordered">
                            {/* <tr>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th>REMARKS</th>
                            </tr>
                            <tr>
                              <td rowspan="5">Body</td>
                            </tr>
                            {this.state.follow.map((ival, ind) => {
                              // console.log(ind);
                              return (
                                <tr>
                                  <td>
                                    <p>{ival.label}</p>
                                  </td>
                                  <td>
                                    <p>{ival.key}</p>
                                  </td>
                                  <td>
                                    <input
                                      type="textarea"
                                      name="REMARKS"
                                      placeholder="Enter remarks"
                                      id="remarks"
                                      onChange={e => {
                                        this.body(
                                          ival,
                                          e.target.value,
                                          ind,
                                          "remarks"
                                        );
                                      }}
                                      value={
                                        bodyJson[ind] && bodyJson[ind].remarks
                                          ? bodyJson[ind].remarks
                                          : null
                                      }
                                      style={{ resize: "none", width: "100%" }}
                                    ></input>
                                  </td>
                                </tr>
                              );
                            })}
                            <tr>
                              <td></td>
                              <td>
                                <p>WINDSCREEN</p>
                              </td>
                              <td>
                                {this.state.windJson.map((val, index) =>
                                  this.state.follow.map((jval, j) => {
                                    console.log(jval);
                                    return (
                                      <div className="form-check form-check-inline">
                                        <input
                                          type="radio"
                                          name={`ele${0}`}
                                          // value={`${jval}`}
                                          id={`windscreenCheckbox${j}`}
                                          onChange={e =>
                                            this.wind(
                                              "WINDSCREEN",
                                              jval.label,
                                              "body",
                                              j
                                            )
                                          }
                                          checked={jval.label == val.body}
                                        />
                                        <label
                                          className="form-check-label"
                                          for={`windscreenCheckbox${j}`}
                                          style={{ marginLeft: "10px" }}
                                        >
                                          {`${jval.label}`}{" "}
                                        </label>
                                      </div>
                                    );
                                  })
                                )}
                              </td>
                              <td>
                                {this.state.windJson.map((kval, n) => (
                                  <input
                                    type="textarea"
                                    name="REMARKS"
                                    placeholder="Enter remarks"
                                    id="remarks"
                                    onChange={e => {
                                      this.wind(
                                        "WINDSCREEN",
                                        e.target.value,
                                        "remarks"
                                      );
                                    }}
                                    value={kval.remarks}
                                    style={{ resize: "none", width: "100%" }}
                                  ></input>
                                ))}
                              </td>
                            </tr> */}
                            <tr>
                              <td rowspan="3">BUMPERS</td>
                            </tr>
                            {this.state.view.map((ival, ind) => {
                              return (
                                <tr>
                                  <td>
                                    <p>{ival}</p>
                                  </td>
                                  <td>
                                    <input
                                      type="textarea"
                                      name="REMARKS"
                                      placeholder="Enter remarks"
                                      id="remarks"
                                      onChange={e => {
                                        this.bump(
                                          ival,
                                          e.target.value,
                                          ind,
                                          "body"
                                        );
                                      }}
                                      value={
                                        bumperJson[ind]
                                          ? bumperJson[ind].body
                                          : null
                                      }
                                      style={{ resize: "none", width: "100%" }}
                                    ></input>
                                  </td>
                                  <td>
                                    <input
                                      type="textarea"
                                      name="REMARKS"
                                      placeholder="Enter remarks"
                                      id="remarks"
                                      onChange={e => {
                                        this.bump(
                                          ival,
                                          e.target.value,
                                          ind,
                                          "remarks"
                                        );
                                      }}
                                      value={
                                        bumperJson[ind]
                                          ? bumperJson[ind].remarks
                                          : null
                                      }
                                      style={{ resize: "none", width: "100%" }}
                                    ></input>
                                  </td>
                                </tr>
                              );
                            })}
                            <label
                              htmlFor="exampleInputEmail1"
                              style={{ marginLeft: "26px" }}
                            >
                              BUMPERS File Upload:
                            </label>
                            <center><FilePond
                              files={this.state.filesArray1}
                              allowReorder={true}
                              allowMultiple={true}
                              allowImageResize={true}
                              imagePreviewMinHeight="50"
                              imagePreviewMaxHeight="50"
                            //  maxFileSize={'3072KB'}
                              // imageResizeMode="cover"
                              acceptedFileTypes={["image/*"]}
                              onupdatefiles={fileItems => {
                                this.fileupload1(fileItems);

                              }}

                              labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                            />
                            {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label> &nbsp;&nbsp;
                              <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                              </center>
                            <tr>
                              <td>LIGHTS</td>
                              <td>RHS</td>
                              <td>LHS</td>
                              <td>REMARKS</td>
                            </tr>
                            {this.state.lights.map((ival, ind) => {
                              return (
                                <tr>
                                  <td>
                                    <p>{ival}</p>
                                  </td>
                                  <td>
                                    <SingleSelect
                                      options={this.state.option}
                                      placeholder="Enter RHS"

                                      handleChange={e => {
                                        this.lights(
                                          ival,
                                          e,
                                          ind,
                                          "rhs"
                                        );
                                      }}

                                      selectedService={
                                        lightJson[ind]
                                          ? lightJson[ind].rhs
                                          : this.state.option[0]
                                      }
                                      style={{ resize: "none", width: "100%" }}
                                    />

                                  </td>
                                  <td>

                                    <SingleSelect
                                      options={this.state.option}
                                      placeholder="Enter RHS"

                                      handleChange={e => {
                                        this.lights(
                                          ival,
                                          e,
                                          ind,
                                          "lhs"
                                        );
                                      }}

                                      selectedService={
                                        lightJson[ind]
                                          ? lightJson[ind].lhs
                                          : this.state.option[0]
                                      }
                                      style={{ resize: "none", width: "100%" }}
                                    />

                                    {/* <input
                                      type="textarea"
                                      name="LHS"
                                      placeholder="Enter LHS"
                                      id="LHS"
                                      onChange={e => {
                                        this.lights(
                                          ival,
                                          e.target.value,
                                          ind,
                                          "lhs"
                                        );
                                      }}
                                      value={
                                        lightJson[ind]
                                          ? lightJson[ind].lhs
                                          : null
                                      }
                                      style={{ resize: "none", width: "100%" }}
                                    ></input> */}
                                  </td>
                                  <td>
                                    <input
                                      type="textarea"
                                      name="REMARKS"
                                      placeholder="Enter remarks"
                                      id="remarks"
                                      onChange={e => {
                                        this.lights(
                                          ival,
                                          e.target.value,
                                          ind,
                                          "remarks"
                                        );
                                      }}
                                      value={
                                        lightJson[ind]
                                          ? lightJson[ind].remarks
                                          : null
                                      }
                                      style={{ resize: "none", width: "100%" }}
                                    ></input>
                                  </td>
                                </tr>
                              );
                            })}


                            {this.state.pro.map((ival, ind) => {
                              return (
                                <tr>
                                  <td></td>
                                  <td>
                                    <p>{ival}</p>
                                  </td>
                                  <td>
                                    {/* <input
                                    type="textarea"
                                    placeholder=""
                                    onChange={e => {
                                      this.profun(
                                        ival,
                                        e.target.value,
                                        ind,
                                        "body"
                                      );
                                    }}
                                  ></input> */}

                                    {this.state.follow.map((jval, j) => {
                                      return (
                                        <div className="form-check form-check-inline">
                                          <input
                                            type="radio"
                                            name={`element${ind}`}
                                            // value={`${jval}`}
                                            id={`inlineCheckbox${j}`}
                                            onChange={e =>
                                              this.profun(
                                                ival,
                                                jval.label,
                                                ind,
                                                "body"
                                              )
                                            }
                                            checked={
                                              proJson[ind] && proJson[ind].body
                                                ? proJson[ind].body ==
                                                jval.label
                                                : null
                                            }
                                          // checked={jval.label == proJson[ind] && proJson[ind].body}
                                          // value={
                                          //   proJson[ind] ? proJson[ind].remarks : null
                                          // }
                                          // checked={wheeklJson[ind] && wheeklJson[ind].body ? wheeklJson[ind].body == jval.label : null}
                                          />
                                          <label
                                            className="form-check-label"
                                            for={`inlineCheckbox${j}`}
                                            style={{ marginLeft: "10px" }}
                                          >
                                            {`${jval.label}`}{" "}
                                          </label>
                                        </div>
                                      );
                                    })}
                                  </td>
                                  <td>
                                    <input
                                      type="textarea"
                                      name="REMARKS"
                                      placeholder="Enter remarks"
                                      id="remarks"
                                      onChange={e => {
                                        this.profun(
                                          ival,
                                          e.target.value,
                                          ind,
                                          "remarks"
                                        );
                                      }}
                                      value={
                                        proJson[ind]
                                          ? proJson[ind].remarks
                                          : null
                                      }
                                      style={{ resize: "none", width: "100%" }}
                                    ></input>
                                  </td>
                                </tr>
                              );
                            })}
                            <label
                              htmlFor="exampleInputEmail1"
                              style={{ marginLeft: "26px" }}
                            >
                              LIGHTS File Upload:
                            </label>
                            <center><FilePond
                              files={this.state.filesArray2}
                              allowReorder={true}
                              allowMultiple={true}
                              allowImageResize={true}
                              imagePreviewMinHeight="50"
                              imagePreviewMaxHeight="50"
                            //  maxFileSize={'3072KB'}
                              // imageResizeMode="cover"
                              acceptedFileTypes={["image/*"]}
                              onupdatefiles={fileItems => {
                                this.fileupload2(fileItems);

                              }}

                              labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                            />
                            {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label> &nbsp;&nbsp;
                              <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                              </center>
                            <tr>
                              <td>TYRES</td>
                              <td>RHS</td>
                              <td>LHS</td>
                              <td>REMARKS</td>
                            </tr>
                            {this.state.view.map((ival, ind) => {
                              //console.log(ival)
                              return (
                                <tr>
                                  <td>
                                    <p>{ival}</p>
                                  </td>
                                  <td>
                                    {/* <input
                                      type="textarea"
                                      placeholder="Enter RHS"
                                      id="RHS"
                                      onChange={e => {
                                        this.tyers(
                                          ival,
                                          e.target.value,
                                          ind,
                                          "rhs"
                                        );
                                      }}
                                      value={
                                        tyersJson[ind]
                                          ? tyersJson[ind].rhs
                                          : null
                                      }
                                      style={{ resize: "none", width: "100%" }}
                                    ></input> */}
                                    <SingleSelect
                                      options={this.state.option}
                                      placeholder="Enter RHS"

                                      handleChange={e => {
                                        this.tyers(
                                          ival,
                                          e,
                                          ind,
                                          "rhs"
                                        );
                                      }}

                                      selectedService={
                                        tyersJson[ind]
                                          ? tyersJson[ind].rhs
                                          : this.state.option[0]
                                      }
                                      style={{ resize: "none", width: "100%" }}
                                    />
                                  </td>
                                  <td>
                                    <SingleSelect
                                      options={this.state.option}
                                      placeholder="Enter LHS"

                                      handleChange={e => {
                                        this.tyers(
                                          ival,
                                          e,
                                          ind,
                                          "lhs"
                                        );
                                      }}

                                      selectedService={
                                        tyersJson[ind]
                                          ? tyersJson[ind].lhs
                                          : this.state.option[0]
                                      }
                                      style={{ resize: "none", width: "100%" }}
                                    />

                                    {/* <input
                                      type="textarea"
                                      name="LHS"
                                      placeholder="Enter LHS"
                                      id="LHS"
                                      onChange={e => {
                                        this.tyers(
                                          ival,
                                          e.target.value,
                                          ind,
                                          "lhs"
                                        );
                                      }}
                                      value={
                                        tyersJson[ind]
                                          ? tyersJson[ind].lhs
                                          : null
                                      }
                                      style={{ resize: "none", width: "100%" }}
                                    ></input> */}
                                  </td>
                                  <td>
                                    <input
                                      type="textarea"
                                      name="REMARKS"
                                      placeholder="Enter remarks"
                                      id="remarks"
                                      onChange={e => {
                                        this.tyers(
                                          ival,
                                          e.target.value,
                                          ind,
                                          "remarks"
                                        );
                                      }}
                                      value={
                                        tyersJson[ind]
                                          ? tyersJson[ind].remarks
                                          : null
                                      }
                                      style={{ resize: "none", width: "100%" }}
                                    ></input>
                                  </td>
                                </tr>
                              );
                            })}
                            {this.state.wheel.map((ival, ind) => {
                              return (
                                <tr>
                                  <td></td>
                                  <td>
                                    <p>{ival}</p>
                                  </td>
                                  <td>
                                    {/* <input
                                    type="textarea"
                                    placeholder=""
                                    onChange={e => {
                                      this.wheeelfun(
                                        ival,
                                        e.target.value,
                                        ind,
                                        "remarks"
                                      );
                                    }}
                                  ></input> */}
                                    {this.state.follow.map((jval, j) => {
                                      return (
                                        <div className="form-check form-check-inline">
                                          <input
                                            type="radio"
                                            name={`elem${ind}`}
                                            // value={`${jval}`}
                                            id={`inlineCheckbox${j}`}
                                            onChange={e =>
                                              this.wheeelfun(
                                                ival,
                                                jval.label,
                                                ind,
                                                "body"
                                              )
                                            }
                                            checked={
                                              wheeklJson[ind] &&
                                                wheeklJson[ind].body
                                                ? wheeklJson[ind].body ==
                                                jval.label
                                                : null
                                            }
                                          />
                                          <label
                                            className="form-check-label"
                                            for={`inlineCheckbox${j}`}
                                            style={{ marginLeft: "10px" }}
                                          >
                                            {`${jval.label}`}{" "}
                                          </label>
                                        </div>
                                      );
                                    })}
                                  </td>
                                  <td>
                                    <input
                                      type="textarea"
                                      name="REMARKS"
                                      placeholder="Enter remarks"
                                      id="remarks"
                                      onChange={e => {
                                        this.wheeelfun(
                                          ival,
                                          e.target.value,
                                          ind,
                                          "remarks"
                                        );
                                      }}
                                      value={
                                        wheeklJson[ind]
                                          ? wheeklJson[ind].remarks
                                          : null
                                      }
                                      style={{ resize: "none", width: "100%" }}
                                    ></input>
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        </div>
                      </div>

                      <label
                        htmlFor="exampleInputEmail1"
                        style={{ marginLeft: "26px" }}
                      >
                        Tyres File Upload:
                      </label>
                      <center><FilePond
                        files={this.state.filesArray3}
                        allowReorder={true}
                        allowMultiple={true}
                        allowImageResize={true}
                        imagePreviewMinHeight="50"
                        imagePreviewMaxHeight="50"
                      //  maxFileSize={'3072KB'}
                        // imageResizeMode="cover"
                        acceptedFileTypes={["image/*"]}
                        onupdatefiles={fileItems => {
                          this.fileupload3(fileItems);

                        }}

                        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                      />
                      {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label> &nbsp;&nbsp;
                              <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                              </center>
                      <br />

                      {this.state.global && (

                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2" />
                          <div className="col-sm-5">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={
                                button == "Save" ? this.formSubmit : this.update
                              }
                            //onClick={this.formSubmit}
                            //onClick={this.update}
                            >
                              {button == "Save" ? "Save" : "Update"}
                            </button>
                          </div>
                        </div>
                      )}
                      {this.state.global && (
                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2" />
                          <div className="col-sm-5">
                            {this.state.button == "Save" && (
                              <button
                                type="button"
                                style={{ minWidth: 100 }}
                                className="btn btn-primary"
                                // style={{ marginLeft: "10px" }}
                                onClick={
                                  button == "Save" ? this.formSubmitPdf : null
                                }
                              >
                                {button == "Save" ? "Save and Export PDF" : null}
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                      {/* {this.state.global == false && (
                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="co  l-sm-2" />
                          <div className="col-sm-5">
                            <button
                              style={{ marginLeft: "200px" }}
                              type="button"
                              className="btn btn-primary"
                              onClick={() => this.print()}
                            >
                              Print
                                </button>
                          </div>
                        </div>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default Evaluation_Checklist_B_Proof;
