import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
//import DfCarsEnquiry from "./DfCarsEnquiry";
import moment from "moment";
import UsertypeComments from "../UserMaster/UsertypeComments";
import LoginModal from "../../../../components/Modal/Modal";
import Datatable from "../../../../components/Datatable/Datatable";
import Icon from "react-icons-kit";
import { calendar_ok } from 'react-icons-kit/ikons/calendar_ok'
import { confirmAlert } from "react-confirm-alert";

class TLpaymentprocess extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      User: JSON.parse(localStorage.getItem("userlog")),
      usertypelog: JSON.parse(localStorage.getItem("usertypelog")),
      LocationID: localStorage.getItem(`LocationID`),
      EnquiryData: [],
      EnquiryData: [],
      Datas: [],
      MakeOptions: [],
      coloropt: [],
      variantopt: [],
      modelopt: [],
      alertVisible: false,
      loading: false,
      column: [
        {
          Header: "Name",
          accessor: "name"
        },
        {
          Header: "REG NO",
          accessor: "reg_no"
        },
        // {
        //   Header: "Mobile",
        //   accessor: "mobile"
        // },
        {
          Header: "evaluator name ",
          accessor: "jobroleName"
        },
        {
          Header: "Make",
          accessor: "make",
          Cell: (d) => this.getValueFromArray(d.original.make, this.state.MakeOptions)

        },
        {
          Header: "Model",
          accessor: "model",
          Cell: (d) => this.getValueFromArray(d.original.model, this.state.modelopt)
        },
        {
          Header: "Variant",
          accessor: "variant",
          Cell: (d) => this.getValueFromArray(d.original.variant, this.state.variantopt)

        },
        {
          Header: "Color",
          accessor: "color",
          Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)

        },
        {
          Header: "Year",
          accessor: "year"
        },
        // {
        //   Header: "Form",
        //   accessor: "mobile",
        //   Cell: d => this.Document(d)
        // },
        {
          Header: "Approval",
          accessor: "",
          Cell: d => this.Approval(d)
        },
        // {
        //   Header: "Comments",
        //   accessor: "mobile",
        //   Cell: d => this.UpdateSTatus(d)
        // },
      ],
      data_for_f: [{ "fill": 0, "today": 0, "view": 0, "approved": 0 }],
      stage: "",
      CustomerId: ""
    };
    this.vals()
  }
  vals = async () => {
    let { data: Make } = await cmsContent.getFreedom(
      `Make as label , id as value`,
      `tbl_make_master`, 1,
      1, 1
    );
    if (Make) {
      this.setState({ MakeOptions: Make })
    }
    let { data: Colour } = await cmsContent.getFreedom(
      `colour as label , id as value`,
      `tbl_colour`,
      `ServiceId=10`,
      1,
      `id DESC`
    );
    if (Colour) {
      this.setState({ coloropt: Colour })
    }
    let { data: variant } = await cmsContent.getFreedom(
      "variant as label , id as value",
      "tbl_variant_master",
      `ServiceId=10`,
      1
    );
    if (variant) {
      this.setState({ variantopt: variant })

    }
    let { data: model } = await cmsContent.getFreedom(
      "Model as label , id as value",
      "tbl_dfcarsmodel",
      `ServiceId=10`,
      1
    );
    if (model) {
      this.setState({ modelopt: model })
    }
  }
  UpdateSTatus = (d) => {
    return (<button type="button" className="btn btn-sm btn-warning"
      data-toggle="modal" data-target={"#status"}
      onClick={() => this.Editdata(d)}
    >View Comments</button>);
  }

  Editdata = async (d) => {
    this.setState({ stage: "payment", CustomerId: d.original.id })
  }

  Approval = (d) => {
    // if (d.original.approval == 0) {
    //   return (
    //     <button
    //       type="button"
    //       className="btn btn-primary"
    //       onClick={() => this.F_proofForm(d)}
    //     >
    //       Waiting for Approval
    //     </button>
    //   );
    // } else if (d.original.approval == 1) {
    //   // return (
    //   //   <button
    //   //     type="button"
    //   //     className="btn btn-warning"
    //   //     onClick={() => this.F_proofForm(d)}
    //   //   >
    //   //     Approved
    //   //   </button>
    //   // );
    //   return (<span className="label">Approved</span>);

    // }
    if (d.original.FormFilled === "yes") {
      return (
        <button
          type="button"
          className="btn btn-sm btn-primary"
          onClick={() => this.buttonDeletes(d)}
        >Approve</button>
      );
    } else if (d.original.FormFilled === "approved") {
      return (<span className="label">Approved</span>);
    } else {
      return (
        <button
          type="button"
          className="btn btn-sm btn-primary"
        //onClick={() => this.buttonDeletes(d)}
        >
          Approve
        </button>
      )
    }
  }
  buttonDeletes = d => {
    //console.log(value);
    // confirmAlert({
    //   title: "Confirmation to Approval",
    //   message: "Are you sure you want to approval this?",
    //   buttons: [
    //     {
    //       label: "Yes",
    //       onClick: () => this.F_proofForm(d)
    //     },
    //     {
    //       label: "No"
    //     }
    //   ]
    // });
    confirmAlert({
      customUI: ({ onClose }) => {
        return <div style={{ textAlign: "center" }}>
          <h1>Approval confirmation</h1>
          <h4>Are you sure you want to approve this ?</h4><br />
          <button className="btn btn-sm btn-primary" onClick={() => {
            this.F_proofForm(d);
            onClose();
          }}  >Yes</button> &nbsp;  &nbsp;
          <button className="btn btn-sm btn-danger" onClick={onClose} >No</button>
        </div>
      }
    })
  };
  Document = (d) => {
    return (
      <button
        type="button"
        className="btn btn-sm btn-warning"
        onClick={() => this.F_proofForm1(d)}
      >
        View Form
      </button>
    );
  }

  F_proofForm1 = async (d) => {
    window.open(`/DFCars/AT_Inventory_Sheet_Reproof/${d.original.id}`, '_blank')
  }

  F_proofForm = async d => {
    let data = d.original;
    const date = new Date();
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    var h = date.getHours();
    var m = date.getMinutes();
    var s = date.getSeconds();
    var returnDate = year + "-" + month + "-" + day + " " + h + ":" + m + ":" + s;

    let body = {};
    body.customerid = data.id;
    body.approval = 1;
    body.Approved_date = returnDate
    body.Approved_by = this.state.userId
    body = JSON.stringify(body)
    try {
      let results = await cmsContent.UpdateApproval(
        'tbl_Inventory_Sheet_Reproof',
        body)
      if (results) {
        window.location.reload()
      }
    }
    catch (error) {
      console.log(error);
    }
    // window.open(
    //   `/DFCars/AT_Booklet_Purchase_Contract_Proof/${d.original.id}`,
    //   "_blank"
    // );
    //   let d = d1.original;
    //   console.log(d, 'dd');
    //   // let approval = d1.original.approval;
    //   // console.log(d);
    //   let sub = {};

    //   sub.approval = "1";

    //   //sub.Approved_date
    //   let CurrentDate = new Date();
    //   CurrentDate = moment(CurrentDate).format("YYYY-MM-DD");
    //   sub.Approved_date = CurrentDate;
    //   sub.Approved_by = this.state.User.id;
    //   console.log(sub);
    //   const result = await cmsContent.updateMaster(
    //     "tbl_Inventory_Sheet_Reproof",
    //     d.id,
    //     sub,
    //     'customerid'
    //   );
    //   if (result) {
    //     let newData = [...this.state.Data];
    //     newData[d1.index].FormFilled = 'approved';
    //     this.setState({
    //       Data: newData
    //     })
    //     window.location.reload();
    //   }
    // };
  }
  orderBy4 = async () => {
    try {
      let results = await cmsContent.BUYDFCARS(this.state.User.id, 'Buy')
      if (results) {
        console.log({ results });
        let logos = results[0];
        let Data = logos.Payment_Prospective;
        this.setState({ Data });

        let data3 = []
        await Data.map((ival, i) => {
          if (ival.FormFilled == "approved") {
            data3.push(ival)

          }
        })
        await this.setState({
          data3,
          loading: false
        })
      }
    } catch (error) {
      console.log(error);
    }
  }
  // orderBy4 = async () => {
  //   let Stage3_Prospective = await cmsContent.getFreedom(
  //     'tbl_dfcarz_prosperities.*,tbl_AT_Booklet_Purchase_Contract_Proof.Reg_No as regno,tbl_user_web.userName as jobroleName',
  //     `tbl_dfcarz_prosperities,tbl_AT_Booklet_Purchase_Contract_Proof,tbl_user_web`,
  //     `tbl_dfcarz_prosperities.location =${this.state.LocationID} and  tbl_dfcarz_prosperities.type='seller' and tbl_AT_Booklet_Purchase_Contract_Proof.approval=1 and tbl_dfcarz_prosperities.id=tbl_AT_Booklet_Purchase_Contract_Proof.customerid and tbl_user_web.id = tbl_dfcarz_prosperities.userid`,
  //     1,
  //     "tbl_dfcarz_prosperities.id DESC"
  //   )
  //   if (Stage3_Prospective) {
  //     Stage3_Prospective.data.map((ival, i) => {
  //       ival.FormFilled = 'no';
  //     })
  //     const Fproof = await cmsContent.getFreedom(
  //       `*`,
  //       `tbl_Inventory_Sheet_Reproof`,
  //       1,
  //       1,
  //       1
  //     );
  //     Stage3_Prospective.data.map((ival, i) => {
  //       Fproof.data.map((jval, j) => {
  //         if (jval.approval == 1 && ival.id == jval.customerid) {
  //           ival.FormFilled = 'approved'
  //           ival.status1 = jval.status1;
  //           ival.status2 = jval.status2;
  //           ival.follow = jval.follow
  //         } else if (ival.id == jval.customerid) {
  //           ival.FormFilled = 'yes'
  //           ival.status1 = jval.status1;
  //           ival.status2 = jval.status2;
  //           ival.follow = jval.follow
  //         }
  //       })
  //     })
  //     await this.setState({ Data: Stage3_Prospective.data })
  //     let data3 = []
  //     await Stage3_Prospective.data.map((ival, i) => {
  //       if (ival.FormFilled == "approved") {
  //         data3.push(ival)
  //       }
  //     })
  //     this.setState({
  //       data3, loading: false
  //     })
  //     console.log(this.state.data3, "data3");
  //   }
  // }
  MainFunction = async () => {
    try {
      this.setState({ loading: true })
      // let results = await cmsContent.BUYDFCARS(this.state.User.id, 'Buy')
      let results = await cmsContent.BUYDFCARS(this.state.User.id, 'payment')
      if (results) {
        let logos = results;
        let Data = logos.data2;
        console.log(logos, "apiii");
        this.setState({ Data });
        // let v1 = 0;
        // let v2 = 0;
        // let v3 = 0;
        // let data2 = []
        // let data3 = []
        // await Data.map((ival, i) => {
        //   if (ival.FormFilled == "yes") {
        //     v1 = ++v1;
        //     data2.push(ival)
        //   } else if (ival.FormFilled == "approved") {
        //     v2 = ++v2
        //     data3.push(ival)
        //   } else {
        //     v3 = ++v3
        //   }
        // })
        // let data_for_f = [{ "fill": v1, "today": v2, "Live": v3, }]
        // this.setState({
        //   data_for_f, data2, data3, loading: false
        // })
        await this.setState({
          data2: results.data2,
          data3: results.data3,
          userId: results.userid,
          data_for_f: results.data_for_f, loading: false
        })
      }
    } catch (error) {
      console.log(error);
    }
  }
  async componentDidMount() {
    this.setState({ loading: true })
    if (this.state.usertypelog.orderBy == 4) {
      this.orderBy4()
    }
    else {
      this.MainFunction()
    }
  }
  //     try {
  //       // let result = await cmsContent.getFreedom(
  //       //   "tbl_dfcarz_prosperities.*,tbl_Inventory_Sheet_Reproof.id as value,tbl_Inventory_Sheet_Reproof.approval,tbl_user_web.userName as jobroleName",
  //       //   `tbl_dfcarz_prosperities,tbl_Inventory_Sheet_Reproof,tbl_user_web`,
  //       //   `tbl_dfcarz_prosperities.location =${this.state.LocationID} and tbl_dfcarz_prosperities.id=tbl_Inventory_Sheet_Reproof.customerid and tbl_user_web.id = tbl_Inventory_Sheet_Reproof.userid`,
  //       //   1,
  //       //   `tbl_dfcarz_prosperities.id DESC`
  //       // );
  //       // if (result) {
  //       //   result.data.map((ival, i) => {
  //       //     ival.FormFilled = 'no';
  //       //   })
  //       //       const Fproof = await cmsContent.getFreedom(
  //       //         `*`,
  //       //         `tbl_Inventory_Sheet_Reproof`,
  //       //         1,
  //       //         `id`
  //       //      );
  //       //      result.data.map((ival,i)=>{
  //       //        Fproof.data.map((jval,j)=>{
  //       //          console.log(jval);
  //       //          if(jval.approval==1 && ival.id==jval.customerid){
  //       //           ival.FormFilled='approved'
  //       //          }else if(ival.id==jval.customerid){
  //       //            ival.FormFilled='yes'
  //       //          }
  //       //        })
  //       //      })
  //       //   //console.log(result,'reee');
  //       //  this.setState({ Data: result.data });
  //       if (this.state.usertypelog.orderBy != 4) {
  //         let result = await cmsContent.getFreedom(
  //           'tbl_dfcarz_prosperities.*,tbl_user_web.userName as jobroleName',
  //           `tbl_dfcarz_prosperities,tbl_AT_Booklet_Purchase_Contract_Proof,tbl_user_web`,
  //           `tbl_dfcarz_prosperities.id=tbl_AT_Booklet_Purchase_Contract_Proof.customerid and tbl_user_web.id = tbl_dfcarz_prosperities.userid`,
  //           1,
  //           "tbl_dfcarz_prosperities.id DESC"
  //         )
  //         if (result) {
  //           result.data.map((ival, i) => {
  //             ival.FormFilled = 'no';
  //           })
  //           const { data: Fproof } = await cmsContent.getFreedom(
  //             `*`,
  //             `tbl_Inventory_Sheet_Reproof`,
  //             1,
  //             1,
  //             1
  //           );
  //           result.data.map((ival, i) => {
  //             Fproof.map((jval, j) => {
  //               if (jval.approval == 1 && ival.id == jval.customerid) {
  //                 ival.FormFilled = 'approved'
  //                 ival.status1 = jval.status1;
  //                 ival.status2 = jval.status2;
  //                 ival.follow = jval.follow
  //               } else if (ival.id == jval.customerid) {
  //                 ival.FormFilled = 'yes'
  //                 ival.status1 = jval.status1;
  //                 ival.status2 = jval.status2;
  //                 ival.follow = jval.follow;
  //               }
  //             })
  //           })
  //           // if (this.state.usertypelog.orderBy == 4) {
  //           //   await this.setState({ Datas: result.data })
  //           // }
  //           await this.setState({ Data: result.data, })
  //           let v1 = 0;
  //           let v2 = 0;
  //           let v3 = 0;
  //           let data2 = []
  //           let data3 = []
  //           await result.data.map((ival, i) => {
  //             if (ival.FormFilled == "yes") {
  //               v1 = ++v1;
  //               data2.push(ival)
  //             } else if (ival.FormFilled == "approved") {
  //               v2 = ++v2
  //               data3.push(ival)
  //             } else {
  //               v3 = ++v3
  //             }
  //           })
  //           let data_for_f = [{ "fill": v1, "today": v2, "Live": v3, }]
  //           this.setState({
  //             data_for_f, data2, data3, loading: false
  //           })
  //           console.log(this.state.data3);
  //         }
  //       }
  //     } catch (error) {
  //       this.setState({ loading: false })
  //       console.log(error)
  //     }
  //   }
  // }

  edash = () => {
    if (this.state.usertypelog.orderBy == 4) {
      return (
        <div></div>
      )
    }
    else {
      return (<div className="">
        <div className="card">
          {/* < div className="row" > */}
          <div className="card-header">
            <div className="col-sm-6" >
              <h2 className="mt-2 black">Dashboard</h2>
            </div>
          </div>
          <div className="card-body">
            <div className="row col-12 mt-4 text-center">
              <div className="col-sm-6 ">
                <div className="dashboardCard1" onClick={() => this.onClk(1)} >
                  <Icon icon={calendar_ok} style={{ color: 'green' }} size={35} />
                  <h4 className="mt-2 black" >Approved</h4>
                  <div >
                    <h1 className="bold mt-4 mb-2 black"><b> {this.state.data_for_f[0].today ? this.state.data_for_f[0].today : 0}</b></h1>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 ">
                <div className="dashboardCard1" onClick={() => this.onClk(2)} >
                  <Icon icon={calendar_ok} style={{ color: 'green' }} size={35} />
                  <h4 className="mt-2 black" >Waiting For Approval</h4>
                  <div >
                    <h1 className="bold mt-4 mb-2 black"><b> {this.state.data_for_f[0].fill ? this.state.data_for_f[0].fill : 0}</b></h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      )
    }
  }
  onClk = (val) => {
    if (val === 1) { this.setState({ Datas: this.state.data3, ftd: 0 }) }
    if (val === 2) { this.setState({ Datas: this.state.data2, ftd: 0 }) }
    // if (val === 3) { this.setState({ Data: this.state.remainder, ftd: 0 }) }
    // if (val === 4) { this.setState({ Data: this.state.data1, ftd: 0 }) }
    // if (val === 5) { this.setState({ Data: this.state.dataof, ftd: 0 }) }
    // if (val === 6) { this.setState({ Data: [], dateVisible: true }) }
    // if (val === 7) { this.setState({ Data: this.state.datapprove, ftd: 0 }) }
    window.scroll({
      top: 400,
      left: 0,
      behavior: "smooth",
    });
  }
  table = () => {
    if (this.state.usertypelog.orderBy == 4) {
      {
        return (
          this.state.loading ? <div className="text-center"> <i
            className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
          ></i></div>
            : this.state.Data && this.state.Data.length > 0 ? (this.state.data3 &&
              this.state.data3.length > 0 ? <Datatable
              data={this.state.data3}
              columnHeading={this.state.column}
            /> : null
            ) : <h1>No Data found</h1>
        )
      }

    } else {
      {
        return (
          this.state.loading ? <div className="text-center"> <i
            className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
          ></i></div>
            : this.state.Data && this.state.Data.length > 0 ? (this.state.Datas &&
              this.state.Datas.length > 0 ? <Datatable
              data={this.state.Datas}
              columnHeading={this.state.column}
            /> : null
            ) : <h1>No Data found</h1>
        )
      }
    }
  }
  render() {
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            {this.edash()}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Payment Process</h1>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-4">
                        <LoginModal
                          buttonTitle="Add Enquiry"
                          title="Update Commets"
                          extraStyle={{ width: 'auto' }}
                          id="status"
                          extraClass="btn btn-sm btn-primary"
                          onClick={this.reset}
                          // extraStyle={{ width: '1000px' }}
                          buttonStyle={{ backgroundColor: "white", border: "white" }}
                          bodyText={
                            <UsertypeComments
                              stage={this.state.stage}
                              customerid={this.state.CustomerId}
                            />}
                        />
                      </div>
                    </div>
                    {this.table()}
                    {/* {this.state.loading ? <div class="loader" /> : this.state.Data.length > 0 ? (
                      this.state.Datas.length > 0 ? <Datatable
                        data={this.state.Datas}
                        columnHeading={this.state.column}
                      /> : null
                    ) : <h1>No Data found</h1>} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    )
  }
}
export default TLpaymentprocess;