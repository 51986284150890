import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../components/Form/FormMiddleware";
//import DfCarsEnquiry from "./DfCarsEnquiry";
import SingleSelect from "../../../components/Form/SingleSelect";
import moment from "moment";

class ChangeFormOrder extends FormMiddleWare {
    constructor(props) {
      super(props);
      this.state = {
        FormQuestions: [],
        customerId: "",
        alertVisible: false,
        color:"",
        textalert: "",
        leadname: null,
        OrderBy:null,
        Editdata:{},
        Index:null
      }
    }

    componentWillReceiveProps(props){
        try{
           // console.log(props);
            if(props){
                this.setState({
                    FormQuestions:props.FormQuestions,
                    Editdata:props.Editdata,
                    Index:props.Index,
                    OrderBy:props.Editdata.Orderby

                })
            }

        }catch(error){
            console.log(error);
        }
    }

    handlechangeData=async(e)=>{
        this.setState({OrderBy:e.target.value})
    }

    Submit=async()=>{
        try{
            const{Editdata,OrderBy,FormQuestions,Index}=this.state;
            
            let subgroupArray={};
            subgroupArray.Orderby=OrderBy;

            const result = await cmsContent.updateMaster(
                "tbl_formquestion",
                 Editdata.id,
                subgroupArray
              );
              if(result){
                  console.log(result);
                 let newData =[...this.state.FormQuestions];
                // console.log(newData);
                 newData[Index].Orderby=OrderBy;
               //  console.log(newData);
                 this.props.these("FormQuestions",newData);
                 this.setState({
                     OrderBy:null,
                     alertVisible: true,
                    disableValue: false,
                    textalert: "Order Updated",
                    color: "success",
                 });
                 setTimeout(() => this.setState({ alertVisible: false }), 3000);
              }
           }catch(error){
            console.log(error);
        }
    }

    onDismiss = () => {
        this.setState({ alertVisible: false, formAlertdelete: false });
      };

    render(){
        const{alertVisible,textalert}=this.state;
        return (
            <React.Fragment>
              <main>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-header">
                          <h1>Change Form Order</h1>
                        </div>
                        <div className="card-body">
                      <div className="row form-group">
                        <div className="col-sm-2" />
                        <div className="col-sm-7">
                          <Alert
                            className="badge-content"
                            color={this.state.color}
                            isOpen={alertVisible}
                            toggle={this.onDismiss}
                          >
                            {textalert}
                          </Alert>
                        </div>
                        <div className="col-sm-3" />
                      </div>
                        <div className="row form-group">
                            <div className="col-sm-1" />
                            <div className="col-sm-3">
                              <label htmlFor="exampleInputEmail1">
                                Order Number
                              </label>
                            </div>
                            <div className="col-sm-5">
                              <input
                                type="number"
                                className="form-control"
                                id="ColourName"
                                name="OrderBy"
                                placeholder="Enter order number"
                                value={this.state.OrderBy}
                                onChange={this.handlechangeData}
                              />
                            </div>
                            <div className="col-sm-3" />
                          </div>
                          <div className="row form-group">
                        <div className="col-sm-2" />
                        <div className="col-sm-3" />
                        <div className="col-sm-5">
                          <button
                            type="button"
                            className={`btn btn-primary`}
                            disabled={this.state.disableValue}
                            onClick={() => this.Submit()}
                          >
                           Submit
                          </button>
                        </div>
                        <div className="col-sm-2" />
                      </div>
                        </div>
                            </div>
                            </div>
                            </div>
                            </div>
                            </main>
                            </React.Fragment>
                            )
    }
}

export default ChangeFormOrder;
