import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import UpdateStatus from "./UpdateStatus"
import moment from "moment";
import LoginModal from "../../../../components/Modal/Modal";
import UsertypeComments from "../UserMaster/UsertypeComments";
import Datatable from "../../../../components/Datatable/Datatable";
import { ACCESS_POINT } from "../../../../config/index";
import Icon from "react-icons-kit";
import { circle_ok } from 'react-icons-kit/ikons/circle_ok'
import { notepad_ok } from 'react-icons-kit/ikons/notepad_ok'
import { notepad_remove } from 'react-icons-kit/ikons/notepad_remove'
import PaymentModal from '../SalesMaster/ContractPaymentProcess'
import FormModal from "../../../../components/Modal/FormModal";
import { time } from "react-icons-kit/ikons/time";
import { calendar } from "react-icons-kit/ikons/calendar";
import { cog } from 'react-icons-kit/ikons/cog'
import { user_delete } from 'react-icons-kit/ikons/user_delete'
import { shopping_cart_delete } from 'react-icons-kit/ikons/shopping_cart_delete'
import { shuffle } from 'react-icons-kit/ikons/shuffle'
class Contracted extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      Datas: [],
      loading: false,
      customerId: localStorage.getItem("userId"),
      LocationID: localStorage.getItem(`LocationID`),
      userid: JSON.parse(localStorage.getItem("userlog")),
      EnquiryData: [],
      alertVisible: false,
      dasboard: [{ "fill": 0, "view": 0, "approved": 0 }],
      data2: [],
      contractFTD: [],
      contractMTD: [],
      contractcommit: [],
      contract_approval_Pending: [],
      delivery_pending: [],
      allcontract: [],
      data3: [],
      Edit_Data: [],
      MakeOptions: [],
      coloropt: [],
      variantopt: [],
      modelopt: [],
      data4: [],
      column: [
        {
          Header: "Form",
          accessor: "mobile",
          minWidth: 150,
          Cell: d => this.Specification(d),
          className: "text-center"
        },
        {
          Header: "View",
          accessor: "approval",
          width: 120,
          Cell: d => this.view_Approval(d)
        },
        {
          Header: "Payment Status",
          minWidth: 150,
          Cell: d => this.Status(d),
          className: "text-center"

        },
        {
          Header: "Prospect",
          accessor: "name",
          width: 150
        },
        {
          Header: "Mobile",
          accessor: "mobile",
          Cell: d => this.Call(d.original.mobile),
          width: 150
        },
        {
          Header: "Date",
          accessor: "created_At"
        },
        {
          Header: "Make",
          accessor: "makeName",
          // Cell: (d) => this.getValueFromArray(d.original.make, this.state.MakeOptions)

        },
        {
          Header: "Model",
          accessor: "modelName",
          // Cell: (d) => this.getValueFromArray(d.original.model, this.state.modelopt)
        },
        {
          Header: "Variant",
          accessor: "variantName",
          // Cell: (d) => this.getValueFromArray(d.original.variant, this.state.variantopt)

        },
        {
          Header: "Colour",
          accessor: "colourName",
          // Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)

        },
        {
          Header: "Source",
          accessor: "sources"
        },
        // {
        //   Header: "Status",
        //   accessor: "status"
        // },
        {
          Header: "Approval Status",
          minWidth: 100,
          accessor: "contract_Status",
          width: 150,
          Cell: d => this.contract_Status(d.original),
          className: "text-center"
        },

        {
          Header: "type",
          accessor: "data_type",
          minWidth: 110,

        },

        {
          Header: "Print PDF",
          accessor: "print",
          minWidth: 110,
          Cell: d => this.viewpdf(d),
          className: "text-center"
        },


      ],
      excelColumn: [
        {
          label: "Prospect",
          key: "name",

        },
        {
          label: "SalesConsultantName",
          key: "userName",

        },
        {
          label: "Mobile",
          key: "mobile",

        },
        {
          label: "Date",
          key: "created_At"
        },
        {
          label: "Make",
          key: "makeName",

        },
        {
          label: "Model",
          key: "modelName",
          // Cell: (d) => this.getValueFromArray(d.original.model, this.state.modelopt)
        },
        {
          label: "Variant",
          key: "variantName",
          // Cell: (d) => this.getValueFromArray(d.original.variant, this.state.variantopt)

        },
        {
          label: "Colour",
          key: "colourName",
          // Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)

        },
        {
          label: "Source",
          key: "sources"
        },
        {
          label: "type",
          key: "data_type",

        },



      ],
      column2: [
        {
          Header: "Prospect",
          accessor: "name"
        },
        {
          Header: "Mobile",
          accessor: "mobile",
          Cell: d => this.Call(d.original.mobile)
        },
        {
          Header: "Date",
          accessor: "created_At"
        },
        {
          Header: "Make",
          accessor: "make",
          Cell: (d) => this.getValueFromArray(d.original.make, this.state.MakeOptions)

        },
        {
          Header: "Model",
          accessor: "model",
          Cell: (d) => this.getValueFromArray(d.original.model, this.state.modelopt)
        },
        {
          Header: "Variant",
          accessor: "variant",
          Cell: (d) => this.getValueFromArray(d.original.variant, this.state.variantopt)

        },
        {
          Header: "Color",
          accessor: "color",
          Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)

        },

        {
          Header: "Status",
          accessor: "status"
        },
        {
          Header: "Approval Status",
          minWidth: 150,
          accessor: "contract_Status",
          width: 150,
          Cell: d => this.contract_Status(d.original)
        },
        {
          Header: "type",
          accessor: "data_type",
          minWidth: 100,

        },
        {
          Header: "Form",
          accessor: "mobile",
          minWidth: 150,
          Cell: d => this.Specification(d)
        },
        {
          Header: "View",
          accessor: "approval",
          width: 180,
          Cell: d => this.view_Approval(d)
        },

      ],
      stage: "",
      CustomerId: ""

    };
  }
  view_Approval = d => {

    return (
      <button
        type="button"
        className="btn btn-sm btn-primary"
        style={{ minWidth: "100px" }}
        onClick={() => this.opentable(d.original)}
      >
        View Details
      </button>
    )
  };
  opentable = async (d) => {
    if (d) {
      let Edit_Data = d
      console.log(Edit_Data, "Djdgjdgdjgddd");
      await this.setState({ Edit_Data })
      window.$("#formmodel").modal("show")
    }
  }
  contract_Status = (d) => {
    if (d.FormFilled == "yes" && d.paymentStatus == 0) {
      return (
        <button
          type="button"
          className="btn btn-sm btn-secondary"
          style={{ cursor: 'pointer', width: "130px" }}
        >Payment Pending</button>

      );
    }
    if (d.contract_Status == "Approved") {
      return (<button className="btn btn-sm btn-success" style={{ cursor: 'pointer', width: "130px" }}
      >{d.contract_Status}</button>)
    }
    else if (d.contract_Status == "pending") {
      return (<button className="btn btn-sm btn-warning" style={{ cursor: 'pointer', width: "130px" }}

      >{d.contract_Status}</button>)
    }
    else {
      return (<button className="btn btn-sm btn-danger" style={{ cursor: 'pointer', width: "130px" }}
      >{d.contract_Status}</button>)
    }
  }
  Status = (d) => {

    return (<button className="btn btn-sm btn-primary" style={{ cursor: 'pointer' }}
      onClick={() => this.editdatareset(d)}
    >Payment Details</button>)
  }
  editdatareset = async (d) => {
    await this.setState({ EditData: null, formid: null, Index: null, isEdit: false })
    await this.Editstatus(d)
  }
  Editstatus = async (d) => {
    if (d.original.paymentStatus == 0) {
      await this.setState({ isEdit: true })
    } else {
      await this.setState({ isEdit: false })
    }
    await this.setState({ EditData: d.original, formid: d.original.formid, Index: d.index })
    window.$('#Payment').modal("show");
  }
  viewpdf = d => {
    if (d.original.FormFilled == "yes" || d.original.FormFilled == "approved") {
      return (<button type="button" className="btn btn-sm btn-warning"
        onClick={() => this.generatepdf(d)}>Print PDF</button>)
    }
    else {
      return (
        <span className="label" style={{ backgroundColor: "white" }}></span>)
    }
  }
  generatepdf = async (d) => {
    const fproofid = await cmsContent.getFreedom("*", "tbl_Booking_Confirmation_Reproof", `customerId=${d.original.id}`, 1, 1);
    console.log(fproofid)
    if (fproofid) {
      let fproofpdf = await cmsContent.Bookingconfirm("tbl_Booking_Confirmation_Reproof", fproofid.data[0].customerid);
      console.log(fproofpdf)
      if (fproofpdf.data == "success") {
        window.open(`${ACCESS_POINT}/cmsContent/downloadbookconfirm/${fproofid.data[0].name}`, '_self')
        // window.open(`${ACCESS_POINT}/cmsContent/downloadbookconfirm/${fproofid.data[0].name}`, '_self')
      }
    }
  }

  F_proofForm = async (d) => {
    window.open(`/DFCars/AT_Booking_Confirmation_Reproof/${d.original.id}`, '_self')
  }

  Specification = (d) => {
    if (d.original.FormFilled == 'yes' || d.original.FormFilled == 'approved') {
      return (<button type="button" className="btn btn-sm btn-primary" style={{ width: "100px" }}
        onClick={() => this.F_proofForm(d)}
      >Edit Form</button>)
    } else {
      return (<button type="button" className="btn btn-sm btn-warning" style={{ width: "100px" }}
        onClick={() => this.F_proofForm(d)}
      >Fill Form</button>)
    }

  }
  MainFunction = async () => {
    this.setState({ loading: true, Datas: [] })
    try {
      // let results = await cmsContent.BUYDFCARS(this.state.userid.id, 'Sell')
      let results1 = await cmsContent.BUYDFCARZ(this.state.userid.id, 'contract')
      let fulldata = []
      if (results1 && results1.fulldata) {
        fulldata = results1.fulldata;
      }
      console.log(fulldata, "fulldata");
      if (results1) {
        await this.setState({

          contractFTD: fulldata.length ? fulldata.filter(e => e.contractFTD == true) : [],
          contractMTD: fulldata.length ? fulldata.filter(e => e.contractMTD == true) : [],
          contractcommit: fulldata.length ? fulldata.filter(e => e.contractcommit == true) : [],
          contract_approval_Pending: fulldata.length ? fulldata.filter(e => e.contract_approval_Pending == true) : [],
          delivery_pending: fulldata.length ? fulldata.filter(e => e.delivery_pending == true) : [],
          allcontract: fulldata,
          // data2: fulldata.length ? fulldata.filter(e => e.filterdata2 == true) : [],

          // data3: fulldata.length ? fulldata.filter(e => e.filterdata3 == true) : [],
          // data4: fulldata.length ? fulldata.filter(e => e.filterdata4 == true) : [],
          dasboard: results1.data_for_f, loading: false
        })
      }

    } catch (error) {
      console.log(error);
    }
  }
  vals = async () => {
    let allOpt = await this.decryption('getcar')
    if (allOpt) {
      this.setState({
        MakeOptions: allOpt.makeopt,
        coloropt: allOpt.coloropt,
        variantopt: allOpt.variantopt,
        modelopt: allOpt.modelopt
      })
    }
  }
  async componentDidMount() {
    this.vals()
    this.MainFunction()
  }
  edash = () => {
    return (<div className="">
      <div className="card">
        {/* < div className="row" > */}
        <div className="card-header">
          <div className="col-sm-6" >
            <h2 className="mt-2 black">Dashboard</h2>
          </div>
        </div>
        <div className="card-body">
          <div className="">
            <div className="row col-12 mt-4 text-center">
              <div className="col" >
                <div className="dashboardCard1" onClick={() => this.onClk(4)}>
                  <Icon icon={time} style={{ color: '#2F353A' }} size={35} /><br />
                  <h4 className="mt-2 black" >FTD Contract</h4>
                  <div >
                    <h1 className="bold mt-4 mb-2 black" style={{cursor:"pointer"}}><b>{this.state.dasboard[0].FTD ? this.state.dasboard[0].FTD : 0}</b></h1>
                  </div>
                </div>
              </div>
              <div className="col" >
                <div className="dashboardCard1" onClick={() => this.onClk(5)}>
                  <Icon icon={calendar} style={{ color: 'blue' }} size={35} /><br />
                  <h4 className="mt-2 black" >MTD Contract</h4>
                  <div >
                    <h1 className="bold mt-4 mb-2 black" style={{cursor:"pointer"}}><b>{this.state.dasboard[0].MTD ? this.state.dasboard[0].MTD : 0}</b></h1>
                  </div>
                </div>
              </div>
              <div className="col" >
                <div className="dashboardCard1" onClick={() => this.onClk(6)}>
                  <Icon icon={cog} style={{ color: 'purple' }} size={35} /><br />
                  <h4 className="mt-2 black" >Under RF Comitement</h4>
                  <div >
                    <h1 className="bold mt-4 mb-2 black" style={{cursor:"pointer"}}><b>{this.state.dasboard[0].Comitement ? this.state.dasboard[0].Comitement : 0}</b></h1>
                  </div>
                </div>
              </div>
              <div className="col" >
                <div className="dashboardCard1" onClick={() => this.onClk(7)}>
                  <Icon icon={user_delete} style={{ color: '#4285F4' }} size={35} /><br />
                  <h4 className="mt-2 black" >Approval Pending</h4>
                  <div >
                    <h1 className="bold mt-4 mb-2 black" style={{cursor:"pointer"}}><b>{this.state.dasboard[0].approval_Pending ? this.state.dasboard[0].approval_Pending : 0}</b></h1>
                  </div>
                </div>
              </div>
              <div className="col" >
                <div className="dashboardCard1" onClick={() => this.onClk(8)}>
                  <Icon icon={shopping_cart_delete} style={{ color: 'red' }} size={35} /><br />
                  <h4 className="mt-2 black" >Delivery Pending</h4>
                  <div >
                    <h1 className="bold mt-4 mb-2 black" style={{cursor:"pointer"}}><b>{this.state.dasboard[0].delivery_pending ? this.state.dasboard[0].delivery_pending : 0}</b></h1>
                  </div>
                </div>
              </div>
              <div className="col" >
                <div className="dashboardCard1" onClick={() => this.onClk(9)}>
                  <Icon icon={shuffle} style={{ color: 'rgb(203 44 146)' }} size={35} /><br />
                  <h4 className="mt-2 black" >All Contract</h4>
                  <div >
                    <h1 className="bold mt-4 mb-2 black" style={{cursor:"pointer"}}><b>{this.state.allcontract ? this.state.allcontract.length : 0}</b></h1>
                  </div>
                </div>
              </div>
              {/* <div className="col">
                <div className="dashboardCard1" onClick={() => this.onClk(1)} >
                  <Icon icon={notepad_ok} style={{ color: 'green' }} size={35} />
                  <h2 className="mt-2 black" >Filled form</h2>
                  <div >
                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.dasboard[0].filled ? this.state.dasboard[0].filled : 0}</b></h1>
                  </div>
                </div>
              </div>
              <div className="col" onClick={() => this.onClk(2)} >
                <div className="dashboardCard1">
                  <Icon icon={notepad_remove} size={35} style={{ color: 'red' }} />
                  <h2 className="mt-2 black" >Non-filled Form</h2>
                  <div>
                    <h1 className="bold mt-4 mb-2 black" ><b>{this.state.dasboard[0].nonfilled ? this.state.dasboard[0].nonfilled : 0}</b></h1>
                  </div>
                </div>
              </div>
              <div className="col" onClick={() => this.onClk(3)} >
                <div className="dashboardCard1">
                  <Icon icon={circle_ok} size={35} style={{ color: 'green' }} />
                  <h2 className="mt-2 black">Approved</h2>
                  <div>
                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.dasboard[0].approved ? this.state.dasboard[0].approved : 0}</b></h1>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
  onClk = (val) => {
    // if (val === 1) { this.setState({ Datas: this.state.data2, columns: this.state.column }) }
    // if (val === 2) { this.setState({ Datas: this.state.data3, columns: this.state.column2 }) }
    // if (val === 3) { this.setState({ Datas: this.state.data4, columns: this.state.column }) }
    if (val === 4) { this.setState({ Datas: this.state.contractFTD, columns: this.state.column }) }
    if (val === 5) { this.setState({ Datas: this.state.contractMTD, columns: this.state.column }) }
    if (val === 6) { this.setState({ Datas: this.state.contractcommit, columns: this.state.column }) }
    if (val === 7) { this.setState({ Datas: this.state.contract_approval_Pending, columns: this.state.column }) }
    if (val === 8) { this.setState({ Datas: this.state.delivery_pending, columns: this.state.column }) }
    if (val === 9) { this.setState({ Datas: this.state.allcontract, columns: this.state.column }) }

    window.scroll({
      top: 400,
      left: 0,
      behavior: "smooth",
    });
  }
  resetModal = (Modalid) => {
    window.$(`#${Modalid}`).modal("hide");
    this.setState({
      EditData: [],
      isEdit: false
    })
  }
  render() {
    let { loading, Edit_Data } = this.state;
    return (<React.Fragment>
      <FormModal
        id={"formmodel"}
        extraStyle={{ width: 'auto' }}
        bodyText={
          <div>
            {this.state.Edit_Data &&
              <table className="LeadTable">
                <thead>
                  <tr class="LeadTable-head" style={{ background: "#07278f" }}>
                    <th class="column1">Type</th>
                    <th class="column2">Data</th>
                  </tr>
                </thead>
                <tbody>
                  {Edit_Data.name && <tr>
                    <td class="column1">Name </td>
                    <td class="column2"> {Edit_Data.name} </td>
                  </tr>}
                  {Edit_Data.mobile && <tr>
                    <td class="column1">Mobile Number </td>
                    <td class="column2"> {this.Call(Edit_Data.mobile)} </td>
                  </tr>}
                  {Edit_Data.make && <tr>
                    <td class="column1">Make</td>
                    <td class="column2"> {this.getValueFromArray(Edit_Data.make, this.state.MakeOptions)} </td>
                  </tr>}
                  {Edit_Data.model && <tr>
                    <td class="column1">Model </td>
                    <td class="column2"> {this.getValueFromArray(Edit_Data.model, this.state.modelopt)} </td>
                  </tr>}
                  {Edit_Data.variant && <tr>
                    <td class="column1">Variant </td>
                    <td class="column2"> {this.getValueFromArray(Edit_Data.variant, this.state.variantopt)} </td>
                  </tr>}
                  {Edit_Data.color && <tr>
                    <td class="column1">Color </td>
                    <td class="column2"> {this.getValueFromArray(Edit_Data.color, this.state.coloropt)} </td>
                  </tr>}
                  {Edit_Data && Edit_Data.commit == 1 && <tr>
                    <td class="column1">Commit</td>
                    <td class="column2"> Yes </td>
                  </tr>}
                  {Edit_Data && Edit_Data.commit_work && Edit_Data.commit_work.length > 0 && <tr>
                    <td class="column1">Commit Work  </td>
                    <td class="column2">
                      {Edit_Data.commit_work && Edit_Data.commit_work.length && Edit_Data.commit_work.map((ival, i) => {
                        return (
                          <>
                            {i + 1}: {ival}<br />
                          </>
                        )
                      })}
                    </td>
                  </tr>}
                  {Edit_Data.Sales_employee_name && <tr>
                    <td class="column1">Sales Consultant </td>
                    <td class="column2"> {Edit_Data.Sales_employee_name} </td>
                  </tr>}
                  {Edit_Data.model && <tr>
                    <td class="column1">Approval </td>
                    <td class="column2"> {Edit_Data.paymentStatus == 1 ? Edit_Data.approval == 1 ? "Approved" : Edit_Data.approval == 2 ? "Rejected" : Edit_Data.approval == 0 ? "Waiting for Approval" : "-" : "Please Fill Payment Details"} </td>
                  </tr>}
                  {Edit_Data.model && <tr>
                    <td class="column1">Accounts Approval  </td>
                    <td class="column2"> {Edit_Data.paymentStatus == 1 ? Edit_Data.accounts_approval == 1 ? "Approved" : Edit_Data.accounts_approval == 2 ? "Rejected" : Edit_Data.accounts_approval == 0 ? "In Progress" : "-" : "Please Fill Payment Details"} </td>
                  </tr>}
                </tbody>
              </table>
            }
          </div>
        }
      />
      <main className="main my-4">
        <div className="container-fluid">
          {this.edash()}
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h1>Contracted</h1>
                </div>
                <div className="card-body">
                  {/* <div className="row form-group">
                    <div className="col-sm-4">
                      <LoginModal
                        buttonTitle="Add Enquiry"
                        title="Update Commets"
                        id="status"
                        extraClass="btn btn-primary"
                        onClick={this.reset}
                        extraStyle={{ width: '1000px' }}
                        buttonStyle={{ backgroundColor: "white", border: "white" }}
                        bodyText={
                          <UsertypeComments
                            stage={this.state.stage}
                            customerid={this.state.CustomerId}
                          />}
                      />
                    </div>
                  </div> */}
                  <div className="row form-group">
                    <div className="col-sm-4">
                      <LoginModal
                        title="Payment Process"
                        id="Payment"
                        buttonStyle={{ backgroundColor: "white", border: "white" }}
                        extraStyle={{ width: 'auto' }}
                        extraClass="lg"
                        bodyText={
                          <PaymentModal
                            EditData={this.state.EditData}
                            these={this.MainFunction}
                            isEdit={this.state.isEdit}
                            FullData={this.state.Data}
                            close={this.resetModal}
                            statusarray={[{ paymentfrom: "", paymentFrom: '', mop: '', modeofpayment: '', meterialnum: '', amount: "", paymentdate: '', username: '', userid: '', balAmount: '', index: 0, error: null }]}
                          />}
                      />
                    </div>
                  </div>
                  {loading ? <div className="text-center"> <i
                    className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                  ></i></div>
                    :
                    this.state.Datas.length ?
                      <Datatable
                        data={this.state.Datas}
                        columnHeading={this.state.columns}
                        excelColumn={this.state.excelColumn}
                      /> : null
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
    )
  }
}
export default Contracted;