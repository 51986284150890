import React, { Component } from "react";
import Utilities from "../../../../MiddleWare/Utilities";
import redisMiddleware from "../../../../MiddleWare/redisMiddleware";
import CmsContent from "../../../../MiddleWare/CmsContent";
import ReportCharts from "../TL/ReportCharts";
import SingleSelect from "../../../../components/Form/SingleSelect";

export default class PocHead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      usertypeName: "",
      TodayNotifiy: [],
      PendingTask: [],
      TodayEvaluation: [],
      PendingRefurbishment: [],
      PendingRefurbishmentApproval: [],
      PendingContracted: [],
      PendingDelivery: [],
      TodayEnquiryBrand: [],
      PendingEnquiryBrand: [],
      TodayContractedSourcing: [],
      CompletedTasks: [],
      LostSale: [],
      RC_bookprovided: [],
      Cars: [],
      sell: false,
      buy: true,
      dataless: [],
      datamore: [],
      location: [],
      LocationID: localStorage.getItem(`LocationID`),
    };
  }
  async componentDidMount() {
    let Userlog = JSON.parse(localStorage.getItem("userlog"));
    let service = Userlog.serviceId;
    let custid = Userlog.customerId;
    try {
      const result = await redisMiddleware.singleTable(
        "tbl_UserType",
        Userlog.userType,
        "id"
      );
      if (result) {
        this.setState({
          data: result.data,
          usertypeName: result.data[0].usertype,
        });
      }
      const Location = await CmsContent.getFreedom("*", "tbl_location", `serviceid=${service} and customerid=${custid}`, 1, 1);
      // console.log(Location.data)
      let locopt = [];
      if (Location.data) {
        Location.data.map((jval, c) => {
          locopt.push({ label: jval.name, value: jval.id })
        })
      }
      this.setState({ locopt })

      // let EnquiryData = await CmsContent.getFreedom(
      //   `tbl_dfcarz_prosperities.*,tbl_FProof.trade_in_price,tbl_make_master.Make,tbl_dfcarsmodel.Model,tbl_colour.colour as ColourName`,
      //   `tbl_dfcarz_prosperities,tbl_make_master,tbl_dfcarsmodel,tbl_colour,tbl_FProof`,
      //   `tbl_FProof.customerid = tbl_dfcarz_prosperities.id and tbl_colour.id = tbl_dfcarz_prosperities.color and tbl_make_master.id = tbl_dfcarz_prosperities.Make and tbl_dfcarsmodel.id = tbl_dfcarz_prosperities.Model`,
      //   1,
      //   `tbl_dfcarz_prosperities.id DESC`
      // );
      let EnquiryData = await redisMiddleware.Dashboard("TodayEnquiryBrand", 1, this.state.LocationID);
      let PriceList = await CmsContent.getFreedom(`*`, `tbl_prize_list`, 1);
      // console.log(EnquiryData.data);
      // console.log(PriceList.data);
      let sum = "";
      let Finaldata = [];
      EnquiryData.data.map((ival, i) => {
        PriceList.data.map((jval, j) => {
          if (
            ival.make == jval.make &&
            ival.model == jval.model
          ) {
            // &&
            // ival.color == jval.color
            // sum = ival.Budget - jval.price;
            // console.log(sum);
            ival.CompanyPrice = jval.price;
            ival.CustomerPrice = ival.trade_in_price;
            // if (sum >= 30000) {
            //   Finaldata.push(ival);
            // }

            if (ival.trade_in_price < jval.price && ival.trade_in_price != null) {
              sum = jval.price - ival.trade_in_price;
              if (sum >= 30000) {
                Finaldata.push(ival);
              }
            }
          }
        });
      });
      // console.log(Finaldata);
      if (Finaldata) {
        let TodayEnquiryBrand = [];
        let PendingEnquiryBrand = [];
        Finaldata.map((ival, i) => {
          let lastdate = new Date(ival.created_At);
          let currentdate = new Date();
          let Ltime = new Date(
            new Date(ival.createdAt).getTime() + 60 * 60 * 24 * 1000
          );
          // console.log(currentdate.getTime(),lastdate.getTime());
          if (currentdate.getDate() == lastdate.getDate()) {
            TodayEnquiryBrand.push(ival);
          } else if (currentdate.getTime() > Ltime.getTime()) {
            // console.log(ival);
            PendingEnquiryBrand.push(ival);
          }
          ival.sum = sum;
        });

        this.setState({ TodayEnquiryBrand, PendingEnquiryBrand });
      }

      // let Result = await CmsContent.getFreedom(
      //   `tbl_dfcarz_prosperities.*,tbl_user_web.userName,tbl_FProof.id as F_id,tbl_BProof.id as B_id,tbl_FProof.approval as F_approval,tbl_BProof.approval as B_approval ,tbl_BProof.createdAt as B_date,tbl_FProof.createdAt as F_date`,
      //   `tbl_dfcarz_prosperities,tbl_FProof,tbl_BProof,tbl_user_web`,
      //   `tbl_dfcarz_prosperities.id = tbl_FProof.customerid and tbl_dfcarz_prosperities.id = tbl_BProof.customerid and tbl_BProof.userid = tbl_user_web.id`,
      //   1,
      //   1
      // );
      let Result = await redisMiddleware.Dashboard("EvaluatorEnquiry", 1, this.state.LocationID);
      if (Result) {
        let TodayEvaluation = [];
        // console.log(Result.data);
        Result.data.map((ival, i) => {
          let lastdate = new Date(
            new Date(ival.B_date).getTime() + 60 * 60 * 24 * 1000
          );
          let currentdate = new Date();
          let a = lastdate.getDate();
          let b = currentdate.getDate();
          //console.log(a,b)
          if (ival.F_approval != 1 || ival.B_approval != 1) {
            if (b <= a) {
              TodayEvaluation.push(ival);
            }
          }
        });
        this.setState({ TodayEvaluation });

        // this.setState({barchartview});
        // this.content();
        const Product = await CmsContent.getFreedom(
          "*",
          "tbl_dfcarz_enquiryform",
          1
        );
        let TodayNotifiy = [];
        let PendingTask = [];
        let CompletedTasks = [];
        if (Product) {
          Product.data.map((ival, i) => {
            let EnquiryDate = new Date();
            let d = new Date(ival.createdAt);
            // (
            //   new Date(ival.createdAt).getTime() + 60 * 60 * 24 * 1000
            // );
            //
            let a = EnquiryDate.getDate();
            let b = d.getDate();

            let Datetime1 = EnquiryDate.getTime();
            let Datetime2 = d.getTime();
            // console.log(Datetime2,Datetime1);
            if (ival.enquiry_status == "active") {
              if (b == a) {
                TodayNotifiy.push(ival);
                //  console.log(ival);
              } else if (Datetime1 > Datetime2) {
                PendingTask.push(ival);
              }
            } else {
              CompletedTasks.push(ival);
            }
          });
          this.setState({ TodayNotifiy, PendingTask, CompletedTasks });
          // this.Refurbishment();
          // this.tbl_Delivery_Note_Buyer_Reproof();
          // this.tbl_Autoterrace_enquiry_form();
          // this.tbl_Booking_Confirmation_Reproof();
          // this.PendingRefurbishmentApproval();
          // this.Lostsells();
          // this.RC_bookprovided();
          this.ReadyForSale();
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  ReadyForSale = async () => {
    try {
      // let result = await CmsContent.getFreedom(
      //   `tbl_items.*,tbl_make_master.Make as makename,tbl_dfcarsmodel.Model as modelname,tbl_colour.colour as ColourName`,
      //   `tbl_items,tbl_make_master,tbl_dfcarsmodel,tbl_colour`,
      //   `tbl_items.make=tbl_make_master.id and tbl_dfcarsmodel.id = tbl_items.model and tbl_colour.id = tbl_items.colour`,
      //   1,
      //   1
      // );
      let result = await redisMiddleware.Dashboard("ReadyForSale", 1, this.state.LocationID)
      //console.log(result)
      if (result) {
        let Cars = [];
        result.data.map((ival, i) => {
          if (ival.status != "inactive") {
            let Date1 = new Date(ival.created_At);
            let Currentdate = new Date();
            if (
              Date1.getDate() == Currentdate.getDate() &&
              Date1.getMonth() == Currentdate.getMonth()
            ) {
              Cars.push(ival);
            }
          }
        });

        this.setState({ Cars });
        console.log(Cars);
      }
    } catch (error) {
      console.log(error);
    }
  };

  Lostsells = async (location) => {
    try {
      // let Result = await CmsContent.getFreedom(
      //   "tbl_dfcarz_prosperities.*,tbl_user_web.userName as jobroleName",
      //   `tbl_dfcarz_prosperities,tbl_user_web`,
      //   `tbl_dfcarz_prosperities.type='buyer' and tbl_dfcarz_prosperities.prospective_status ='inactive' and tbl_user_web.id = tbl_dfcarz_prosperities.userid and tbl_dfcarz_prosperities.location=${location}`,
      //   1,
      //   1
      // );
      let Result = await redisMiddleware.Dashboard("Lostsells", 1, location);
      if (Result) {
        let LostSale = [];
        //console.log(Result);
        this.setState({ LostSale: Result.data });
      }
    } catch (error) {
      console.log(error);
    }
  };

  Refurbishment = async (location) => {
    //console.log("Dgsdg")
    try {
      let PendingRefurbishment = [];
      //  console.log(location)
      // let Result = await CmsContent.getFreedom(
      //   "tbl_dfcarz_prosperities.*,tbl_Delivery_Seller_Proof.Approved_date,tbl_Delivery_Seller_Proof.id as value,tbl_Delivery_Seller_Proof.reg_no as regno,tbl_Delivery_Seller_Proof.approval,tbl_user_web.userName as jobroleName",
      //   `tbl_dfcarz_prosperities,tbl_Delivery_Seller_Proof,tbl_user_web`,
      //   `tbl_dfcarz_prosperities.id=tbl_Delivery_Seller_Proof.customerid and tbl_user_web.id = tbl_Delivery_Seller_Proof.userid and tbl_dfcarz_prosperities.location=${location}`,
      //   1
      // );
      let Result = await redisMiddleware.Dashboard("Refurbishment", 1, location)
      console.log(Result)
      if (Result.data.length < 1) {
        this.setState({ PendingRefurbishment: "" })
      }

      // let Result = await CmsContent.getFreedom(
      //   `tbl_dfcarz_prosperities.*,tbl_checklist350.approval`,
      //   `tbl_checklist350,tbl_dfcarz_prosperities`,
      //   `tbl_checklist350.userid = tbl_dfcarz_prosperities.id`
      // )

      Result.data.map(async (ival, i) => {
        ival.Approved_date = new Date(ival.Approved_date);
        ival.Approved_date.setDate(ival.Approved_date.getDate() + 3);
        //  ival.Approved_date = moment( ival.Approved_date).format("YYYY-MM-DD");
        // console.log(ival.Approved_date.getTime())
        let date = new Date();

        // if(ival.Approved_date.getTime() >= date.getTime()){

        let Refurbishment = await CmsContent.getFreedom(
          `tbl_checklist350.*`,
          `tbl_checklist350,tbl_user_web`,
          `tbl_checklist350.customerid=${ival.id}`
        );
        if (!Refurbishment.data.length) {
          //console.log(Refurbishment);
          // Refurbishment.data.map((jval,j)=>{
          //   jval.jobroleName = ival.jobroleName;
          // })
          PendingRefurbishment.push(ival);
          this.setState({ PendingRefurbishment });
        }
      });

    } catch (error) {
      console.log(error);
    }
  };

  PendingRefurbishmentApproval = async (location) => {
    try {
      // let Result = await CmsContent.getFreedom(
      //   `tbl_dfcarz_prosperities.*,tbl_checklist350.approval,tbl_user_web.userName as jobroleName,tbl_checklist350.reg_no as regno`,
      //   `tbl_checklist350,tbl_dfcarz_prosperities,tbl_user_web`,
      //   `tbl_checklist350.customerid = tbl_dfcarz_prosperities.id and tbl_user_web.id = tbl_checklist350.userid and tbl_dfcarz_prosperities.location=${location}`
      // );
      let Result = await redisMiddleware.Dashboard("PendingRefurbishmentApproval", 1, location)
      //console.log(Result)
      if (Result.data.length < 1) {
        this.setState({ PendingRefurbishmentApproval: "" })
      }
      const Fproof = await CmsContent.getFreedom(
        `recondition_est,customerid`,
        `tbl_FProof`,
        1
      );
      //  if(Fproof){
      // console.log(Fproof.data)
      // console.log(Result.data)
      //   }

      Result.data.map((ival, i) => {
        Fproof.data.map((jval, j) => {
          if (ival.id == jval.customerid) {
            ival.recondition_est = jval.recondition_est;
          }
        });
      });

      let PendingRefurbishmentApproval = [];

      if (Result) {
        Result.data.map((ival, i) => {
          // console.log(ival);
          //  console.log(ival.recondition_est);
          if (ival.approval == 0 || ival.approval == null) {
            if (ival.recondition_est > 10000) {
              //console.log(ival);
              PendingRefurbishmentApproval.push(ival);
            }
          }
        });
      }

      this.setState({ PendingRefurbishmentApproval });
    } catch (error) {
      console.log(error);
    }
  };

  tbl_Autoterrace_enquiry_form = async (location) => {
    try {
      // let Buyer = await CmsContent.getFreedom(
      //   "tbl_dfcarz_prosperities.*,tbl_user_web.userName as jobroleName",
      //   "tbl_dfcarz_prosperities,tbl_Specifications_Sheet_Proof,tbl_user_web",
      //   `tbl_user_web.id = tbl_Specifications_Sheet_Proof.userid and tbl_dfcarz_prosperities.type='buyer' and tbl_dfcarz_prosperities.id = tbl_Specifications_Sheet_Proof.customerid and tbl_dfcarz_prosperities.location=${location}`
      // );
      let Buyer = await redisMiddleware.Dashboard("tbl_Autoterrace_enquiry_form", 1, location)
      //console.log(Buyer)
      if (Buyer.data.length < 1) {
        this.setState({ TodayContractedSourcing: "" })
      }
      if (Buyer) {
        let Fulldata = [];
        Buyer.data.map((ival, i) => {
          if (ival.prospective_status != "inactive") {
            ival.FormFilled = "no";
            Fulldata.push(ival);
          }
          //ival.followup=moment(ival.followup).format("YYYY-MM-DD");
        });
        // console.log(Buyer.data);
        // console.log(Fulldata);
        //   let result = await CmsContent.getFreedom(
        //     `*`,
        //     `tbl_Autoterrace_enquiry_form`,
        //     1
        //  );

        let TodayContractedSourcing = [];
        //  Buyer.data.map((ival,i)=>{
        // result.data.map((kval,j)=>{
        //   TodayContractedSourcing = Buyer.data.filter(value => value.id !== kval.customerid);
        // })
        let wait1 = await Fulldata.map(async (ival, i) => {
          let result = await CmsContent.getFreedom(
            `*`,
            `tbl_Autoterrace_enquiry_form`,
            `customerid=${ival.id}`
          );

          if (!result.data.length) {
            //console.log(result.data)
            TodayContractedSourcing.push(ival);
          }
        });

        await Promise.all(wait1);
        //  console.log(TodayContractedSourcing);
        this.setState({ TodayContractedSourcing });
      }
    } catch (error) {
      console.log(error);
    }
  };
  tbl_Booking_Confirmation_Reproof = async (location) => {

    try {
      let PendingContracted = [];
      // let Buyer = await CmsContent.getFreedom(
      //   "tbl_dfcarz_prosperities.*,tbl_user_web.userName as jobroleName",
      //   "tbl_dfcarz_prosperities,tbl_Autoterrace_enquiry_form,tbl_user_web",
      //   `tbl_user_web.id = tbl_Autoterrace_enquiry_form.userid and tbl_dfcarz_prosperities.type='buyer' and tbl_dfcarz_prosperities.id = tbl_Autoterrace_enquiry_form.customerid and tbl_dfcarz_prosperities.location=${location}`
      // );
      let Buyer = await redisMiddleware.Dashboard("tbl_Booking_Confirmation_Reproof", 1, location)
      //console.log(Buyer)
      if (Buyer.data.length < 1) {
        this.setState({ PendingContracted: "" })
      }
      if (Buyer) {
        let Fulldata = [];
        Buyer.data.map((ival, i) => {
          if (ival.prospective_status != "inactive") {
            // ival.FormFilled='no';
            Fulldata.push(ival);
          }
          //ival.followup=moment(ival.followup).format("YYYY-MM-DD");
        });
        let wait1 = await Fulldata.map(async (ival, i) => {
          let result = await CmsContent.getFreedom(
            `* `,
            `tbl_Booking_Confirmation_Reproof`,
            `customerid = ${ival.id}`
          );
          if (!result.data.length) {
            PendingContracted.push(ival);
          }
        });
        await Promise.all(wait1);
      }
      this.setState({ PendingContracted });
    } catch (error) {
      console.log(error);
    }
  };

  tbl_Delivery_Note_Buyer_Reproof = async (location) => {
    try {
      let PendingDelivery = [];
      // let Buyer = await CmsContent.getFreedom(
      //   "tbl_dfcarz_prosperities.*,tbl_user_web.userName as jobroleName,tbl_Booking_Confirmation_Reproof.regno as regno",
      //   "tbl_dfcarz_prosperities,tbl_Booking_Confirmation_Reproof,tbl_user_web",
      //   `tbl_user_web.id = tbl_Booking_Confirmation_Reproof.userid and tbl_dfcarz_prosperities.type = 'buyer' and tbl_dfcarz_prosperities.id = tbl_Booking_Confirmation_Reproof.customerid and tbl_dfcarz_prosperities.location = ${location}`
      // );
      let Buyer = await redisMiddleware.Dashboard("tbl_Delivery_Note_Buyer_Reproof", 1, location);
      // console.log(Buyer)
      if (Buyer.data.length < 1) {
        this.setState({ PendingDelivery: "" })
      }
      if (Buyer) {
        let Fulldata = [];
        Buyer.data.map((ival, i) => {
          if (ival.prospective_status != "inactive") {
            // ival.FormFilled='no';
            Fulldata.push(ival);
          }
          //ival.followup=moment(ival.followup).format("YYYY-MM-DD");
        });
        Fulldata.map(async (ival, i) => {
          let result = await CmsContent.getFreedom(
            `* `,
            `tbl_Delivery_Note_Buyer_Reproof`,
            `customerid = ${ival.id}`
          );
          if (!result.data.length) {
            PendingDelivery.push(ival);
          }
        });
      }
      this.setState({ PendingDelivery });
    } catch (error) {
      console.log(error);
    }
  };
  CheckPoint = async (location) => {
    // let Refurbishment = await CmsContent.getFreedom(
    let len = [];
    console.log("check")
    // let Refurbishment = await CmsContent.getFreedom(
    // let Refurbishment = await CmsContent.getFreedom("tbl_checklist350.*,tbl_checklist350.Points,tbl_dfcarz_prosperities.id as value,tbl_dfcarz_prosperities.name,tbl_dfcarz_prosperities.email,tbl_dfcarz_prosperities.mobile",
    //   `tbl_dfcarz_prosperities,tbl_checklist350`,
    //   `tbl_dfcarz_prosperities.id=tbl_checklist350.customerid and tbl_dfcarz_prosperities.location=${location}`, 1
    // );
    let Refurbishment = await redisMiddleware.Dashboard("CheckPoint", 1, location)
    //console.log(Refurbishment.data)
    if (Refurbishment.data.length) {
      let data = Refurbishment.data;
      let json = [];
      let final = [];
      let dataless = [];
      let datamore = [];
      let less = [];

      Refurbishment.data.map((ival, i) => {
        if (ival.Points < 40 || ival.Points == null || ival.Points == "-") {
          ival.Points = 0
          dataless.push(ival)
        }
      })
      this.setState({ dataless })
      Refurbishment.data.map((ival, i) => {
        if (ival.Points > 40) {
          datamore.push(ival)
        }
      })
      this.setState({ datamore })
    }
  }
  RC_bookprovided = async (location) => {
    try {
      // let Buyer = await CmsContent.getFreedom(
      //   `tbl_dfcarz_customers.*, tbl_user_web.userName as salesname`,
      //   `tbl_dfcarz_customers, tbl_user_web,tbl_dfcarz_prosperities`,
      //   `tbl_dfcarz_customers.type = 'buyer' and tbl_dfcarz_customers.documents_received = 0 and tbl_user_web.id = tbl_dfcarz_customers.sales_id and  tbl_dfcarz_prosperities.id=tbl_dfcarz_customers.prospectid and tbl_dfcarz_prosperities.location=${location}`
      // );
      let Buyer = await redisMiddleware.Dashboard("RC_bookprovided", 1, location)
      console.log(Buyer)
      if (Buyer) {
        //console.log(Buyer.data);
        this.setState({ RC_bookprovided: Buyer.data });
      }
    } catch (error) {
      console.log(error);
    }
  };
  MovetoSite = (d) => {
    console.log(d);
    if (d == "penquiry") {
      //window.open(`/ DFCars / DfCarsEnquiry`);
      window.location.href = `/ DFCars / UserEnquiryreport`;
    } else if (d == "sourcing") {
      window.location.href = `/ DFCars / TLSourcing`;
    } else if (d == "delivery") {
      window.location.href = `/ DFCars / TLDelivery`;
    } else if (d == "sale") {
      window.location.href = `/ DFCars / PriceList`;
    } else if (d == "rc") {
      window.location.href = `/ DFCars / BuyerEnquiryform`;
    } else if (d == "refurbishment") {
      window.location.href = `/ DFCars / TLRefurbishment`;
    } else if (d == "readyforsale") {
      window.location.href = `/ DFCars / InventoryList`;
    } else if (d == "contracted") {
      window.location.href = `/ DFCars / TLContracted`;
    } else if (d == "evaluation") {
      window.location.href = `/ DFCars / EvaluationReport`;
    }
  };
  buyer = () => {
    this.setState({ buy: true, sell: false })
  }
  seller = () => {
    this.setState({ sell: true, buy: false })
  }
  handlelocation = e => {
    //console.log(e)
    this.setState({ location: e.value })
    this.Refurbishment(e.value);
    this.tbl_Delivery_Note_Buyer_Reproof(e.value)
    this.tbl_Autoterrace_enquiry_form(e.value);
    this.tbl_Booking_Confirmation_Reproof(e.value);
    this.PendingRefurbishmentApproval(e.value);
    this.Lostsells(e.value);
    this.RC_bookprovided(e.value);
    this.CheckPoint(e.value);
  }
  render() {
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3>Welcome {this.state.usertypeName}</h3>
                  </div>
                  <br />
                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                      <label>Select Location:</label>
                    </div>
                    <div className="col-sm-5">
                      <SingleSelect
                        options={this.state.locopt}
                        placeholder="Select Location"
                        handleChange={(e) => this.handlelocation(e)}
                      />
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        {/* {(!this.state.barchartview) && <SpinKit></SpinKit>} */}
                        {/* {this.state.barchartview && this.content()} */}
                        <ReportCharts />
                        <ul class="nav nav-tabs">
                          <li class="nav-item">
                            <a
                              class="nav-link active"
                              data-toggle="tab"
                              href="#buy"
                              onClick={() => this.buyer()}
                            >
                              Buying
                            </a>
                          </li>
                          <li class="nav-item">
                            <a
                              class="nav-link"
                              data-toggle="tab"
                              href="#sell"
                              onClick={() => this.seller()}
                            >
                              Selling
                            </a>
                          </li>
                        </ul>


                        <div class="tab-content">
                          <div id="buy" class="container tab-pane active">
                            {this.state.buy && (
                              <div className="row form-group">
                                {this.state.TodayEnquiryBrand.length ?
                                  <div className="col-sm-6">
                                    <React.Fragment>
                                      <div className="card">
                                        <div
                                          className="card-header"
                                          style={{ backgroundColor: "lightgray" }}
                                        >
                                          <h3>
                                            {"Esimated Amount > 30000"} (Comp{" "}
                                            {this.state.CompletedTasks.length} - pend{" "}
                                            {this.state.TodayEnquiryBrand.length})
                                    </h3>
                                        </div>
                                        <div className="card-body">
                                          {/* {this.state.TodayEvaluation.length ?  */}
                                          {this.state.TodayEnquiryBrand.map(
                                            (ival, i) => {
                                              if (i < 5) {
                                                return (
                                                  <React.Fragment>
                                                    <div className="row-form-group">
                                                      <div className="col-sm-1" />
                                                      <div className="col-sm-12">
                                                        <a onClick={() => this.MovetoSite("penquiry")}>
                                                          <table className="dfcarsport">
                                                            <tr>
                                                              <td className="dfcarsportname">
                                                                {ival.name}
                                                              </td>
                                                              {/* <td className="dfcarsportmobicon">
                                                                <i class="fa fa-mobile" aria-hidden="true"></i>
                                                              </td> */}

                                                              <td className="dfcarsportnumber">
                                                                {ival.Make}
                                                              </td>
                                                              <td className="dfcarsportreg">
                                                                {ival.Model}
                                                              </td>
                                                            </tr>
                                                          </table>
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </React.Fragment>
                                                  // <React.Fragment>
                                                  //   <div
                                                  //     className="card-body"
                                                  //     style={{}}
                                                  //   >
                                                  //     <h3>
                                                  //       Prospect Name : {ival.name}
                                                  //     </h3>
                                                  //     <h3>Type : {ival.type}</h3>
                                                  //     <h3>Make : {ival.Make}</h3>
                                                  //     <h3>Model : {ival.Model}</h3>
                                                  //     <h3>
                                                  //       Colour : {ival.ColourName}
                                                  //     </h3>
                                                  //     <h3>
                                                  //       Company-Bugdet :{" "}
                                                  //       {ival.CompanyPrice}
                                                  //     </h3>
                                                  //     <h3>
                                                  //       Customer-Bugdet :{" "}
                                                  //       {ival.CustomerPrice}
                                                  //     </h3>
                                                  //   </div>
                                                  //   <hr />
                                                  // </React.Fragment>
                                                );
                                              }
                                            }
                                          )}
                                          {this.state.TodayEnquiryBrand.length > 0 ? (
                                            <h5
                                              className="Readmore1"
                                              onClick={() =>
                                                this.MovetoSite("penquiry")
                                              }
                                            >
                                              View More
                                            </h5>
                                          ) : null}
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  </div>
                                  : null}
                                {this.state.dataless.length ? (
                                  <div className="col-sm-6">
                                    <React.Fragment>
                                      <div className="card">
                                        <div
                                          className="card-header"
                                          style={{ backgroundColor: "lightgray" }}
                                        >
                                          <h3>
                                            {"Checklist Point < 40"}
                                            {/* {this.state.lenless} */}
                                          </h3>
                                        </div>
                                        <div className="card-body">
                                          {/* {this.state.TodayEvaluation.length ?  */}
                                          {this.state.dataless.map(
                                            (ival, i) => {
                                              if (i < 5) {
                                                return (
                                                  <React.Fragment>
                                                    <div className="row-form-group">
                                                      <div className="col-sm-1" />
                                                      <div className="col-sm-12">
                                                        <a onClick={() => this.MovetoSite("refurbishment")}>
                                                          <table className="dfcarsport">
                                                            <tr>
                                                              <td className="dfcarsportname">
                                                                {ival.name}
                                                              </td>
                                                              <td className="dfcarsportmobicon">
                                                                <i class="fa fa-mobile" aria-hidden="true"></i>
                                                              </td>

                                                              <td className="dfcarsportnumber">
                                                                {ival.mobile}
                                                              </td>
                                                              <td className="dfcarsportreg">
                                                                {ival.Points}
                                                              </td>
                                                            </tr>
                                                          </table>
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </React.Fragment>
                                                );
                                              }
                                            }
                                          )}
                                          {this.state.dataless.length
                                            ? (
                                              <h5
                                                className="Readmore1"
                                                onClick={() =>
                                                  this.MovetoSite("refurbishment")
                                                }
                                              >
                                                View More
                                              </h5>
                                            ) : null}
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  </div>
                                ) : null}
                                {this.state.datamore.length ? (
                                  <div className="col-sm-6">
                                    <React.Fragment>
                                      <div className="card">
                                        <div
                                          className="card-header"
                                          style={{ backgroundColor: "lightgray" }}
                                        >
                                          <h3>
                                            {"Checklist Point > 40"}

                                          </h3>
                                        </div>
                                        <div className="card-body">
                                          {/* {this.state.TodayEvaluation.length ?  */}
                                          {this.state.datamore.map(
                                            (ival, i) => {
                                              //console.log(ival)
                                              if (i < 5) {
                                                return (
                                                  <React.Fragment>
                                                    <div className="row-form-group">
                                                      <div className="col-sm-1" />
                                                      <div className="col-sm-12">
                                                        <a onClick={() => this.MovetoSite("refurbishment")}>
                                                          <table className="dfcarsport">
                                                            <tr>
                                                              <td className="dfcarsportname">
                                                                {ival.name}
                                                              </td>
                                                              <td className="dfcarsportmobicon">
                                                                <i class="fa fa-mobile" aria-hidden="true"></i>
                                                              </td>

                                                              <td className="dfcarsportnumber">
                                                                {ival.mobile}
                                                              </td>
                                                              <td className="dfcarsportreg">
                                                                {ival.Points}
                                                              </td>
                                                            </tr>
                                                          </table>
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </React.Fragment>
                                                );
                                              }
                                            }
                                          )}
                                          {this.state.datamore.length
                                            ? (
                                              <h5
                                                className="Readmore1"
                                                onClick={() =>
                                                  this.MovetoSite("refurbishment")
                                                }
                                              >
                                                View More
                                              </h5>
                                            ) : null}
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  </div>
                                ) : null}
                                {this.state.PendingEnquiryBrand.length ? (
                                  <div className="col-sm-6">
                                    <React.Fragment>
                                      <div className="card">
                                        <div
                                          className="card-header"
                                          style={{ backgroundColor: "lightgray" }}
                                        >
                                          <h3>
                                            Pending Enquiry (Comp{" "}
                                            {this.state.CompletedTasks.length} - pend{" "}
                                            {this.state.PendingEnquiryBrand.length})
                                    </h3>
                                        </div>
                                        <div className="card-body">
                                          {/* {this.state.TodayEvaluation.length ?  */}
                                          {this.state.PendingEnquiryBrand.map(
                                            (ival, i) => {
                                              if (i < 1) {
                                                return (
                                                  <React.Fragment>
                                                    <div
                                                      className="card-body"
                                                      style={{}}
                                                    >
                                                      <p>
                                                        Prospect Name : {ival.name}
                                                      </p>
                                                      <p>
                                                        Job Role Name :{" "}
                                                        {ival.jobroleName}
                                                      </p>
                                                    </div>
                                                    <hr />
                                                  </React.Fragment>
                                                );
                                              }
                                            }
                                          )}
                                          {this.state.PendingEnquiryBrand.length >
                                            0 ? (
                                              <h5
                                                className="Readmore"
                                                onClick={() =>
                                                  this.MovetoSite("penquiry")
                                                }
                                              >
                                                Read More
                                              </h5>
                                            ) : null}
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  </div>
                                ) : null}

                                {this.state.Cars.length ? (
                                  <div className="col-sm-6">
                                    <React.Fragment>
                                      <div className="card">
                                        <div
                                          className="card-header"
                                          style={{ backgroundColor: "lightgray" }}
                                        >
                                          <h3>
                                            Ready For Sale (Comp{" "}
                                            {this.state.CompletedTasks.length} - pend{" "}
                                            {this.state.Cars.length})
                                    </h3>
                                        </div>
                                        <div className="card-body">
                                          {/* {this.state.TodayEvaluation.length ?  */}
                                          {this.state.Cars.map((ival, i) => {
                                            if (i < 1) {
                                              return (
                                                <React.Fragment>
                                                  <div
                                                    className="card-body"
                                                    style={{}}
                                                  >
                                                    <h3>
                                                      Prospect Name : {ival.name}
                                                    </h3>
                                                    <h3>Type : {ival.type}</h3>
                                                    <h3>Make : {ival.Make}</h3>
                                                    <h3>Model : {ival.Model}</h3>
                                                    <h3>
                                                      Colour : {ival.ColourName}
                                                    </h3>
                                                    <h3>
                                                      Company-Bugdet :{" "}
                                                      {ival.CompanyPrice}
                                                    </h3>
                                                    <h3>
                                                      Customer-Bugdet :{" "}
                                                      {ival.CustomerPrice}
                                                    </h3>
                                                  </div>
                                                  <hr />
                                                </React.Fragment>
                                              );
                                            }
                                          })}
                                          {this.state.Cars.length > 0 ? (
                                            <h5
                                              className="Readmore"
                                              onClick={() =>
                                                this.MovetoSite("readyforsale")
                                              }
                                            >
                                              Read More
                                            </h5>
                                          ) : null}
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  </div>
                                ) : null}
                                {this.state.TodayEvaluation.length ? (
                                  <div className="col-sm-6">
                                    <React.Fragment>
                                      <div className="card">
                                        <div
                                          className="card-header"
                                          style={{ backgroundColor: "lightgray" }}
                                        >
                                          <h3>
                                            Evaluation to be Approved (Comp{" "}
                                            {this.state.CompletedTasks.length} - pend{" "}
                                            {this.state.TodayEvaluation.length})
                                    </h3>
                                        </div>
                                        <div className="card-body">
                                          {/* {this.state.TodayEvaluation.length ?  */}
                                          {this.state.TodayEvaluation.map(
                                            (ival, i) => {
                                              if (i < 8) {
                                                return (
                                                  <React.Fragment>
                                                    <div className="row-form-group">
                                                      <div className="col-sm-1" />
                                                      <div className="col-sm-12">
                                                        <a onClick={() => this.MovetoSite("evaluation")}>
                                                          <table className="dfcarsport">
                                                            <tr>
                                                              <td className="dfcarsportname">
                                                                {ival.name}
                                                              </td>
                                                              <td className="dfcarsportmobicon">
                                                                <i class="fa fa-mobile" aria-hidden="true"></i>
                                                              </td>

                                                              <td className="dfcarsportnumber">
                                                                {ival.mobile}
                                                              </td>
                                                              <td className="dfcarsportreg">
                                                                {ival.regno}
                                                              </td>
                                                            </tr>
                                                          </table>
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </React.Fragment>
                                                );
                                              }
                                            }
                                          )}
                                          {this.state.TodayEvaluation.length > 0 ? (
                                            <h5
                                              className="Readmore1"
                                              onClick={() =>
                                                this.MovetoSite("evaluation")
                                              }
                                            >
                                              View More
                                            </h5>
                                          ) : null}
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  </div>
                                ) : null}

                                {this.state.PendingRefurbishment.length ? (
                                  <div className="col-sm-6">
                                    <React.Fragment>
                                      <div className="card">
                                        <div
                                          className="card-header"
                                          style={{ backgroundColor: "lightgray" }}
                                        >
                                          <h3>
                                            PendingRefurbishment (Comp{" "}
                                            {this.state.CompletedTasks.length} - pend{" "}
                                            {this.state.PendingRefurbishment.length})
                                    </h3>
                                        </div>
                                        <div className="card-body">
                                          {/* {this.state.TodayEvaluation.length ?  */}
                                          {this.state.PendingRefurbishment.map(
                                            (ival, i) => {
                                              //console.log(ival)
                                              if (i < 8) {
                                                return (
                                                  <React.Fragment>
                                                    <div className="row-form-group">
                                                      <div className="col-sm-1" />
                                                      <div className="col-sm-12">
                                                        <a onClick={() => this.MovetoSite("refurbishment")}>
                                                          <table className="dfcarsport">
                                                            <tr>
                                                              <td className="dfcarsportname">
                                                                {ival.name}
                                                              </td>
                                                              <td className="dfcarsportmobicon">
                                                                <i class="fa fa-mobile" aria-hidden="true"></i>
                                                              </td>

                                                              <td className="dfcarsportnumber">
                                                                {ival.mobile}
                                                              </td>
                                                              <td className="dfcarsportreg">
                                                                {ival.regno}
                                                              </td>
                                                            </tr>
                                                          </table>
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </React.Fragment>
                                                );
                                              }
                                            }
                                          )}
                                          {this.state.PendingRefurbishment.length >
                                            0 ? (
                                              <h5
                                                className="Readmore1"
                                                onClick={() =>
                                                  this.MovetoSite("refurbishment")
                                                }
                                              >
                                                View More
                                              </h5>
                                            ) : null}
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  </div>
                                ) : null}
                                {this.state.PendingRefurbishmentApproval.length ? (
                                  <div className="col-sm-6">
                                    <React.Fragment>
                                      <div className="card">
                                        <div
                                          className="card-header"
                                          style={{ backgroundColor: "lightgray" }}
                                        >
                                          <h3>
                                            Pending Refurbishment Approval (Comp{" "}
                                            {this.state.CompletedTasks.length} - pend{" "}
                                            {
                                              this.state.PendingRefurbishmentApproval
                                                .length
                                            }
                                      )
                                    </h3>
                                        </div>
                                        <div className="card-body">
                                          {/* {this.state.TodayEvaluation.length ?  */}
                                          {this.state.PendingRefurbishmentApproval.map(
                                            (ival, i) => {
                                              if (i < 8) {
                                                return (
                                                  <React.Fragment>
                                                    <div className="row-form-group">
                                                      <div className="col-sm-1" />
                                                      <div className="col-sm-12">
                                                        <a onClick={() => this.MovetoSite("refurbishment")}>
                                                          <table className="dfcarsport">
                                                            <tr>
                                                              <td className="dfcarsportname">
                                                                {ival.name}
                                                              </td>
                                                              <td className="dfcarsportmobicon">
                                                                <i class="fa fa-mobile" aria-hidden="true"></i>
                                                              </td>

                                                              <td className="dfcarsportnumber">
                                                                {ival.mobile}
                                                              </td>
                                                              <td className="dfcarsportreg">
                                                                {ival.regno}
                                                              </td>
                                                            </tr>
                                                          </table>
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </React.Fragment>
                                                );
                                              }
                                            }
                                          )}
                                          {this.state.PendingRefurbishmentApproval
                                            .length > 0 ? (
                                              <h5
                                                className="Readmore1"
                                                onClick={() =>
                                                  this.MovetoSite("refurbishment")
                                                }
                                              >
                                                View More
                                              </h5>
                                            ) : null}
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  </div>
                                ) : null}
                              </div>
                            )}
                          </div>
                          {this.state.sell && (
                            <div id="sell" class="container tab-pane active">
                              {this.state.LostSale.length ? (
                                <div className="col-sm-6">
                                  <React.Fragment>
                                    <div className="card">
                                      <div
                                        className="card-header"
                                        style={{ backgroundColor: "lightgray" }}
                                      >
                                        <h3>
                                          Lost Sale (Comp{" "}
                                          {this.state.CompletedTasks.length} - pend{" "}
                                          {this.state.LostSale.length})
                                    </h3>
                                      </div>
                                      <div className="card-body">
                                        {/* {this.state.TodayEvaluation.length ?  */}
                                        {this.state.LostSale.map((ival, i) => {
                                          if (i < 8) {
                                            return (
                                              <React.Fragment>
                                                <div className="row-form-group">
                                                  <div className="col-sm-1" />
                                                  <div className="col-sm-12">
                                                    <a onClick={() => this.MovetoSite("sale")}>
                                                      <table className="dfcarsport">
                                                        <tr>
                                                          <td className="dfcarslportname">
                                                            {ival.name}
                                                          </td>
                                                          <td className="dfcarsportmobicon">
                                                            <i class="fa fa-mobile" aria-hidden="true"></i>
                                                          </td>
                                                          <td className="dfcarsportnumber">
                                                            {ival.mobile}
                                                          </td>
                                                          <td className="dfcarsportreg">
                                                            {ival.email}
                                                          </td>
                                                        </tr>
                                                      </table>
                                                    </a>
                                                  </div>
                                                </div>
                                              </React.Fragment>
                                            );
                                          }
                                        })}
                                        {this.state.LostSale.length > 0 ? (
                                          <h5
                                            className="Readmore1"
                                            onClick={() => this.MovetoSite("sale")}
                                          >
                                            View More
                                          </h5>
                                        ) : null}
                                      </div>
                                    </div>
                                  </React.Fragment>
                                </div>
                              ) : null}
                              {this.state.TodayContractedSourcing.length ? (
                                <div className="col-sm-6">
                                  <React.Fragment>
                                    <div className="card">
                                      <div
                                        className="card-header"
                                        style={{ backgroundColor: "lightgray" }}
                                      >
                                        <h3>
                                          {" "}
                                      Sourcing in Pending (Comp{" "}
                                          {this.state.CompletedTasks.length} - pend{" "}
                                          {
                                            this.state.TodayContractedSourcing
                                              .length
                                          }
                                      )
                                    </h3>
                                      </div>
                                      <div className="card-body">
                                        {this.state.TodayContractedSourcing.map((ival, i) => {
                                          if (i < 8) {
                                            return (
                                              <React.Fragment>
                                                <div className="row-form-group">
                                                  <div className="col-sm-1" />
                                                  <div className="col-sm-12">
                                                    <a onClick={() => this.MovetoSite("sourcing")}>
                                                      <table className="dfcarsport">
                                                        <tr>
                                                          <td className="dfcarsportname">
                                                            {ival.name}
                                                          </td>
                                                          <td className="dfcarsportmobicon">
                                                            <i class="fa fa-mobile" aria-hidden="true"></i>
                                                          </td>

                                                          <td className="dfcarsportnumber">
                                                            {ival.mobile}
                                                          </td>
                                                          <td className="dfcarsportreg">
                                                            {ival.email}
                                                          </td>
                                                        </tr>
                                                      </table>
                                                    </a>
                                                  </div>
                                                </div>
                                              </React.Fragment>
                                            );
                                          }
                                        })}
                                        {this.state.TodayContractedSourcing.length > 0 ? (
                                          <h5
                                            className="Readmore1"
                                            onClick={() => this.MovetoSite("sourcing")}
                                          >
                                            View More
                                          </h5>
                                        ) : null}
                                      </div>
                                    </div>
                                  </React.Fragment>
                                </div>
                              ) : null}

                              {this.state.PendingContracted.length ? (
                                <div className="col-sm-6">
                                  <React.Fragment>
                                    <div className="card">
                                      <div
                                        className="card-header"
                                        style={{ backgroundColor: "lightgray" }}
                                      >
                                        <h3>
                                          Contracted Pending (Comp{" "}
                                          {this.state.CompletedTasks.length} - pend{" "}
                                          {this.state.PendingContracted.length})
                                    </h3>
                                      </div>
                                      <div className="card-body">
                                        {/* {this.state.TodayEvaluation.length ?  */}
                                        {this.state.PendingContracted.map(
                                          (ival, i) => {
                                            if (i < 8) {
                                              return (
                                                <React.Fragment>
                                                  <div className="row-form-group">
                                                    <div className="col-sm-1" />
                                                    <div className="col-sm-12">
                                                      <a onClick={() => this.MovetoSite("contracted")}>
                                                        <table className="dfcarsport">
                                                          <tr>
                                                            <td className="dfcarslportname">
                                                              {ival.name}
                                                            </td>
                                                            <td className="dfcarsportmobicon">
                                                              <i class="fa fa-mobile" aria-hidden="true"></i>
                                                            </td>
                                                            <td className="dfcarscontportnumber">
                                                              {ival.mobile}
                                                            </td>
                                                            <td className="dfcarsportreg">
                                                              {ival.email}
                                                            </td>
                                                          </tr>
                                                        </table>
                                                      </a>
                                                    </div>
                                                  </div>
                                                </React.Fragment>
                                              );
                                            }
                                          }
                                        )}{" "}
                                        {this.state.PendingContracted.length > 0 ? (
                                          <h5
                                            className="Readmore1"
                                            onClick={() =>
                                              this.MovetoSite("contracted")
                                            }
                                          >
                                            View More
                                          </h5>
                                        ) : null}
                                      </div>
                                    </div>
                                  </React.Fragment>
                                </div>
                              ) : null}
                              {this.state.PendingDelivery.length ? (
                                <div className="col-sm-6">
                                  <React.Fragment>
                                    <div className="card">
                                      <div
                                        className="card-header"
                                        style={{ backgroundColor: "lightgray" }}
                                      >
                                        <h3>
                                          Pending Delivery (Comp{" "}
                                          {this.state.CompletedTasks.length} - pend{" "}
                                          {this.state.PendingDelivery.length})
                                    </h3>
                                      </div>
                                      <div className="card-body">
                                        {/* {this.state.TodayEvaluation.length ?  */}
                                        {this.state.PendingDelivery.map(
                                          (ival, i) => {
                                            if (i < 8) {
                                              return (
                                                <React.Fragment>
                                                  <div className="row-form-group">
                                                    <div className="col-sm-1" />
                                                    <div className="col-sm-12">
                                                      <a onClick={() => this.MovetoSite("delivery")}>
                                                        <table className="dfcarsport">
                                                          <tr>
                                                            <td className="dfcarsportname">
                                                              {ival.name}
                                                            </td>
                                                            <td className="dfcarsportmobicon">
                                                              <i class="fa fa-mobile" aria-hidden="true"></i>
                                                            </td>

                                                            <td className="dfcarsportnumber">
                                                              {ival.mobile}
                                                            </td>
                                                            <td className="dfcarsportreg">
                                                              {ival.regno}
                                                            </td>
                                                          </tr>
                                                        </table>
                                                      </a>
                                                    </div>
                                                  </div>
                                                </React.Fragment>
                                              );
                                            }
                                          }
                                        )}
                                        {this.state.PendingDelivery.length > 0 ? (
                                          <h5
                                            className="Readmore1"
                                            onClick={() =>
                                              this.MovetoSite("delivery")
                                            }
                                          >
                                            View More
                                          </h5>
                                        ) : null}
                                      </div>
                                    </div>
                                  </React.Fragment>
                                </div>
                              ) : null}
                              {this.state.RC_bookprovided.length ? (
                                <div className="col-sm-6">
                                  <React.Fragment>
                                    <div className="card">
                                      <div
                                        className="card-header"
                                        style={{ backgroundColor: "lightgray" }}
                                      >
                                        <h3>
                                          RC Pending (Comp{" "}
                                          {this.state.CompletedTasks.length} - pend{" "}
                                          {this.state.RC_bookprovided.length})
                                    </h3>
                                      </div>
                                      <div className="card-body">
                                        {/* {this.state.TodayEvaluation.length ?  */}
                                        {this.state.RC_bookprovided.map(
                                          (ival, i) => {
                                            if (i < 8) {
                                              return (
                                                <React.Fragment>
                                                  <div className="row-form-group">
                                                    <div className="col-sm-1" />
                                                    <div className="col-sm-12">
                                                      <a onClick={() => this.MovetoSite("rc")}>
                                                        <table className="dfcarsport">
                                                          <tr>
                                                            <td className="dfcarslportname">
                                                              {ival.customer}
                                                            </td>
                                                            <td className="dfcarsportmobicon">
                                                              <i class="fa fa-mobile" aria-hidden="true"></i>
                                                            </td>

                                                            <td className="dfcarsportnumber">
                                                              {ival.mobile}
                                                            </td>
                                                            <td className="dfcarsportreg">
                                                              {ival.email}
                                                            </td>
                                                          </tr>
                                                        </table>
                                                      </a>
                                                    </div>
                                                  </div>
                                                </React.Fragment>
                                              );
                                            }
                                          }
                                        )}
                                        {this.state.RC_bookprovided.length > 0 ? (
                                          <h5
                                            className="Readmore1"
                                            onClick={() => this.MovetoSite("rc")}
                                          >
                                            View More
                                          </h5>
                                        ) : null}
                                      </div>
                                    </div>
                                  </React.Fragment>
                                </div>
                              ) : null}
                            </div>
                          )}
                          {/* {this.state.PendingEnquiryBrand.length ? (
                          <React.Fragment>
                            <div className="card">
                              <div
                                className="card-header"
                                style={{ backgroundColor: "red" }}
                              >
                                <h3>Pending Enquiry</h3>
                              </div>
                              <div className="card-body"> */}
                          {/* {this.state.TodayEvaluation.length ?  */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment >
    );
  }
}