import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
//import UpdateStatus from "./UpdateStatus"
import moment from "moment";
import LoginModal from "../../../../components/Modal/Modal";
import UsertypeComments from "../UserMaster/UsertypeComments";
import Datatable from "../../../../components/Datatable/Datatable";

class TLSourcing extends FormMiddleWare {
    constructor(props) {
      super(props);
      this.state = {
        Data: [],
        customerId: localStorage.getItem("userId"),
        LocationID:localStorage.getItem(`LocationID`),
        EnquiryData:[],
        alertVisible:false,
        column:[
            {
                Header: "Prospect",
                accessor: "name"
              },
              {
                Header: "Contact",
                accessor: "mobile"
              },
            {
              Header: "Status",
              accessor: "status"
            },
            {
                Header: "HandledBy",
                accessor: "userName"
            },
            {
                Header: "Specification-Sheet",
                accessor: "mobile",
               Cell: d => this.Specification(d)
              },
              {
               Header: "Comments",
               accessor: "mobile",
               Cell: d => this.UpdateSTatus(d)
             },
           ],
           stage:"",
           CustomerId:""
         };
       }
     
       UpdateSTatus = (d) => {
         return (<button type="button" className="btn btn-sm btn-warning"
           data-toggle="modal" data-target={"#status"}
           onClick={() => this.Editdata(d)}
          >View Comments</button>);
       }
     
       Editdata=async(d)=>{
         
         this.setState({stage:"sourcing",CustomerId:d.original.id})
       }

    F_proofForm=async(d)=>{
      window.open(`/DFCars/Autoterrace_enquiry_form/${d.original.id}`,'_self')
    }

    Specification=(d)=>{
       // if(d.original.FormFilled=='yes'||d.original.FormFilled=='approved'){
          return(<button type="button" className="btn btn-primary"
          onClick={()=>this.F_proofForm(d)}
          >View Form</button>)
        // }else{
        //   return(<button type="button" className="btn btn-warning"
        //   onClick={()=>this.F_proofForm(d)}
        //   >Fill Form</button>)
        // }
        
      }

    async componentDidMount() {
        try{

            let Buyer = await cmsContent.getFreedom(
                'tbl_dfcarz_prosperities.*,tbl_user_web.userName',
                'tbl_dfcarz_prosperities,tbl_Autoterrace_enquiry_form,tbl_user_web',
                `tbl_dfcarz_prosperities.location =${this.state.LocationID} and tbl_user_web.id = tbl_Autoterrace_enquiry_form.userid and tbl_dfcarz_prosperities.type='buyer' and tbl_dfcarz_prosperities.id = tbl_Autoterrace_enquiry_form.customerid`,
                1,
                `tbl_dfcarz_prosperities.id DESC`
            )
            if(Buyer){
                Buyer.data.map((ival,i)=>{
                    ival.FormFilled='no';
                    ival.followup=moment(ival.followup).format("YYYY-MM-DD");
                 })
               // console.log(Buyer);
                // let result = await cmsContent.getFreedom(
                //     `*`,
                //     `tbl_Autoterrace_enquiry_form`,
                //       1
                // );
                // Buyer.data.map((ival,i)=>{
                //     result.data.map((jval,j)=>{
                //       if(ival.id==jval.customerid && jval.approval==1){
                //           ival.FormFilled='approved';
                //       }else if(ival.id==jval.customerid){
                //         ival.FormFilled='yes';
                //       }
                //     })
                //    })
                this.setState({Data:Buyer.data})
            }

        }catch(error){
            console.log(error);
        }
    }

    render(){
        return( <React.Fragment>
            <main className="main my-4">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h1>Sourcing</h1>
                    </div>
                    <div className="card-body">
                    <div className="row form-group">
                    <div className="col-sm-4">
                      <LoginModal
                        buttonTitle="Add Enquiry"
                        title="Update Commets"
                        id="status"
                            extraClass="btn btn-sm btn-primary"
                        extraStyle={{width:'1000px'}}
                        onClick={this.reset}
                        buttonStyle={{ backgroundColor: "white", border: "white" }}
                        bodyText={
                          <UsertypeComments
                          stage={this.state.stage}
                          customerid={this.state.CustomerId}
                          />}
                      />
                    </div>
                  </div>
                    {this.state.Data.length ? (
                      <Datatable
                        data={this.state.Data}
                        columnHeading={this.state.column}
                      />
                            ) : null}
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </main>
                    </React.Fragment>
                    )
    }
}
export default TLSourcing;