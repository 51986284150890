import React, { Component } from "react";
import CmsContent from "../../../MiddleWare/CmsContent";
import Datatable from "../../../components/Datatable/Datatable";
import { Alert } from "reactstrap";
import LoginModal from "../../../components/Modal/Modal";
import SingleSelect from "../../../components/Form/SingleSelect";
import redisMiddleware from "../../../MiddleWare/redisMiddleware";

class DfcarsAddForms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typeid: "",
      formtype: [],
      send_json: [],
      optionCount: "",
      optionJson: [],
      optionListArray: [],
      optionJsonArray: [],
      formJson: [],
      filetype: [],
      CustomerId: localStorage.getItem("userId"),
      serviceId: localStorage.getItem("currentService"),
      disabledValue: false,
      color: "danger",
      alertVisible: false
    };
  }
  async componentDidMount() {
    try {
      // let result = await CmsContent.getSingleConditionedValue(
      //   "tbl_form",
      //   "companyid",
      //   this.state.CustomerId
      // );

      let result = await redisMiddleware.singleTable(
        "tbl_form",
        this.state.CustomerId,
        "companyid"
      );

      if (result) {
        this.setState({ data: result.data });
      }
      //   let uom = await CmsContent.getFreedom(
      //     "id as value,name as label",
      //     "tbl_UOM",
      //     `customerid = ${this.state.CustomerId}`,
      //     "id",
      //     "id"
      //   );
      //   if (uom) {
      //     this.setState({ uom: uom.data });
      //   }
    } catch (error) {
      console.error(error);
    }
  }

  column = [
    {
      Header: "Form Name",
      accessor: "formname"
    },
    {
      Header: "Preview",
      Cell: d => this.preview(d)
    }
  ];

  preview = d => {
    return (
      <div>
        <center>
          <button
            className="btn btn-warning"
            onClick={() =>
              window.open(
                "FormView" +
                  "/" +
                  this.state.CustomerId +
                  "/" +
                  d.original.id +
                  "/" +
                  146,
                "_blank"
              )
            }
          >
            View
          </button>
        </center>
      </div>
    );
  };

  formfunction = async type => {
    var formJson = this.state.formJson;
    if (type == "text") {
      formJson.push({ type: type, name: "", placeholder: "", required: false });
    } else if (type == "textarea") {
      formJson.push({ type: type, name: "", placeholder: "", required: false });
    } else if (type == "radio") {
      formJson.push({ type: type, name: "", option: [], required: false });
    } else if (type == "selectbox") {
      formJson.push({
        type: type,
        option: [],
        name: "",
        placeholder: "",
        required: false
      });
    } else if (type == "checkbox") {
      formJson.push({ type: type, name: "", option: [], required: false });
    }
    await this.setState({ formJson: formJson });
    this.formbuild();
  };
  addSingleChoice = async type => {
    const { send_json } = this.state;
    send_json.push({
      text: null,
      textarea: null,
      type,
      answer: null,
      options: [],
      check: [],
      file: null,
      count: null
    });
    this.setState({ send_json });
  };
  boxtext = e => {
    this.setState({ boxtext: e.target.value });
  };
  text = e => {
    this.setState({ text: e.target.value });
  };
  formSelect = e => {
    this.setState({ typeSelected: e, typeid: e.value });
  };
  handlechange = e => {
    if (e.target.name === "optionCount") {
      this.setState({ optionJson: [] });
    }
    this.setState({ [e.target.name]: e.target.value });
  };
  selectoption = (index, key, value) => {
    var option = { label: value, value: "" };
    var formJson = this.state.formJson;
    var newJson = formJson[index];
    newJson.option.push(option);
    formJson[index] = newJson;
    this.setState({ formJson });
    this.formbuild();
  };
  removeoption = index => {
    var formJson = this.state.formJson;
    var newJson = formJson[index];
    newJson.option.pop();
    formJson[index] = newJson;
    this.setState({ formJson });
    this.formbuild();
  };

  optionvalue = (value, key, index) => {
    var option = { label: value, value: key };
    var formJson = this.state.formJson;
    var newJson = formJson[index];
    newJson.option[key] = option;
    formJson[index] = newJson;
    this.formbuild();
  };
  formvalue = async (value, name, index) => {
    var formJson = [...this.state.formJson];
    var newJson = formJson[index];
    newJson[name] = value;
    formJson[index] = newJson;
    await this.setState({ formJson });
    this.formbuild();
  };
  formradiocheck = async (name, required, index) => {
    var formJson = this.state.formJson;
    var newJson = formJson[index];
    newJson[name] = required;
    formJson[index] = newJson;
    this.setState({ formJson });
    this.formbuild();
  };
  removetype = async (index, type) => {
    if (type == "text") {
      this.setState({ alertVisible: true, error: "Text Field Removed" });
      setTimeout(() => {
        this.setState({ alertVisible: false });
      }, 1000);
    }
    if (type == "textarea") {
      this.setState({ alertVisible: true, error: "TextArea Field Removed" });
      setTimeout(() => {
        this.setState({ alertVisible: false });
      }, 1000);
    }
    if (type == "radio") {
      this.setState({ alertVisible: true, error: "Radio Field Removed" });
      setTimeout(() => {
        this.setState({ alertVisible: false });
      }, 1000);
    }
    if (type == "selectbox") {
      this.setState({ alertVisible: true, error: "Select Box Field Removed" });
      setTimeout(() => {
        this.setState({ alertVisible: false });
      }, 1000);
    }
    if (type == "checkbox") {
      this.setState({ alertVisible: true, error: "Check Box Field Removed" });
      setTimeout(() => {
        this.setState({ alertVisible: false });
      }, 1000);
    }

    const formJson = this.state.formJson;
    await formJson.splice(index, 1);
    setTimeout(async () => {
      await this.setState({ formJson });
    }, 1000);
    this.formbuild();
  };
  buttonPreview = async () => {
    let formJson = this.state.formJson;
    let modalJson = [];
    if (formJson && formJson.length) {
      let json1 = await formJson.map(async (item, index) => {
        if (item.type == "text") {
          await modalJson.push(
            <div className="row" style={{ marginTop: "25px" }}>
              <div className="col-sm-1" />
              <div className="col-sm-5">
                <label for="exampleInputEmail1">{item.name}</label>
              </div>
              <div className="col-sm-5">
                <input
                  type="text"
                  name="subgroup"
                  className="form-control"
                  placeholder={item.placeholder}
                />
              </div>
              <div className="col-sm-1"></div>
            </div>
          );
        }
        if (item.type == "textarea") {
          await modalJson.push(
            <div>
              <div className="row" style={{ marginTop: "25px" }}>
                <div className="col-sm-1" />
                <div className="col-sm-5">
                  <label>{item.name}</label>
                </div>
                <div className="col-sm-5">
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder={item.placeholder}
                    onChange={e => this.answers(e.target.value, index)}
                  ></textarea>
                </div>
                <div className="col-sm-1"></div>
              </div>
            </div>
          );
        }
        if (item.type == "radio") {
          let arr1 = [];
          item.option.map((element, key) => {
            arr1.push(
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name={element}
                  value={`${element.label}`}
                  id={`inlineCheckbox${key}`}
                  onChange={e =>
                    this.answers(e.target, index, item.option, key, item.type)
                  }
                />
                <label
                  className="form-check-label"
                  for={`inlineCheckbox${key}`}
                >{`${element.label}`}</label>
              </div>
            );
          });
          await modalJson.push(
            <div>
              <div className="row" style={{ marginTop: "25px" }}>
                <div className="col-sm-1" />
                <div className="col-sm-5">
                  <label>{item.name}</label>
                </div>
                <div className="col-sm-5">{arr1}</div>
                <div className="col-sm-1"></div>
              </div>
            </div>
          );
        }
        if (item.type == "selectbox") {
          await modalJson.push(
            <div>
              <div className="row" style={{ marginTop: "25px" }}>
                <div className="col-sm-1" />
                <div className="col-sm-5">
                  <label>{item.name}</label>
                </div>
                <div className="col-sm-5" style={{ fontSize: "15px" }}>
                  <SingleSelect
                    options={item.option}
                    placeholder={item.placeholder}
                    handleChange={e => {
                      this.answers(e.label, index, item.option, item.type);
                    }}
                    selectedService={item.label}
                  />
                </div>
                <div className="col-sm-1"></div>
              </div>
            </div>
          );
        }
        if (item.type == "checkbox") {
          let arr1 = [];
          item.option.map((element, key) => {
            arr1.push(
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={`${element.label}`}
                  id={`inlineCheckbox${key}`}
                  onChange={e => {
                    this.answers(e.target, index, item.option, key, item.type);
                  }}
                />
                <label
                  className="form-check-label"
                  for={`inlineCheckbox${key}`}
                >{`${element.label}`}</label>
              </div>
            );
          });
          await modalJson.push(
            <div>
              <div className="row" style={{ marginTop: "25px" }}>
                <div className="col-sm-1" />
                <div className="col-sm-5">
                  <label>{item.name}</label>
                </div>
                <div className="col-sm-5">{arr1}</div>
                <div className="col-sm-1"></div>
              </div>
            </div>
          );
        }
      });
      await Promise.all(modalJson);
      this.setState({ modalJson });
    }
  };

  buttonSave = async () => {
    const {
      formJson,
      boxtext,
      contentJson,
      CustomerId,
      serviceId
    } = this.state;
    // this.setState({disabledValue : true})
    if (!boxtext) {
      this.setState({ errortext: "Please enter Form Name" });
    }
    let today = new Date();
    let createdAt =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    let jsonContent = {};
    jsonContent.formname = boxtext;
    jsonContent.formjson = JSON.stringify(formJson);
    jsonContent.companyid = this.state.CustomerId;
    jsonContent.createdAt = createdAt;
    jsonContent.type = "active";

    try {
      let finalArr = [];
      formJson.map((ival, i) => {
        let option = [];
        if (ival.option && ival.option.length) {
          ival.option.map((jval, j) => {
            option.push(jval.label);
          });
        }
        let finalObj = {};
        finalObj.question = ival.name;
        finalObj.options = option.toString();
        finalObj.placeholder = ival.placeholder;
        finalObj.required = ival.required ? 1 : 0;
        finalObj.type = ival.type;
        finalObj.companyid = CustomerId;
        finalArr.push(finalObj);
      });
      let parentObj = {};
      parentObj.name = boxtext;
      parentObj.companyid = CustomerId;
      parentObj.status = "active";
      parentObj.serviceid = serviceId;

      const result = await CmsContent.chan(
        "tbl_form",
        parentObj,
        "tbl_formquestion",
        finalArr
      );
      if (result) {
        const formid = result.data.insertId;
        this.setState({
          formid,
          contentJson: [],
          formJson: [],
          boxtext: "",
          color: "success",
          disabledValue: false,
          alertVisible: true,
          error: "New Form Inserted Succesfully"
        });
        setTimeout(() => {
          this.setState({ alertVisible: false });
        }, 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  onDismiss = () => {
    this.setState({ alertVisible: false });
  };
  CheckBox = async () => {
    var check = document.getElementById("ShowChecked").checked;
    if (check === true) {
      await this.setState({ checked: 1 });
    } else if (check === false) {
      await this.setState({ checked: 0 });
    }
    this.formbuild();
  };
  uomSelect = e => {
    this.setState({ selectedUOM: e });
  };
  formbuild = async () => {
    let formJson = this.state.formJson;
    var contentJson = [];
    formJson.map((item, index) => {
      if (formJson[index].type == "text") {
        contentJson.push(
          <div style={{ background: "#73818f", marginBottom: "25px" }}>
            <div className="row">
              <div className="col-sm-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder={`Text ${index + 1} Label`}
                  value={item.name}
                  onChange={e => this.formvalue(e.target.value, "name", index)}
                  style={{ margin: "3px", fontSize: "14px", marginLeft: "8px" }}
                />
              </div>
              <div className="col-sm-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder={`Text ${index + 1} Placeholder`}
                  value={item.placeholder}
                  onChange={e =>
                    this.formvalue(e.target.value, "placeholder", index)
                  }
                  style={{
                    margin: "3px",
                    fontSize: "14px",
                    marginLeft: "-14px"
                  }}
                />
              </div>
              <div className="col-sm-2"></div>
              {/* <div className="col-sm-2">
                <div className="">
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="ShowChecked"
                      onClick={this.CheckBox}
                    />
                    <label
                      class="custom-control-label"
                      for="ShowChecked"
                      style={{ position: "initial" }}
                    >
                      suffix
                    </label>
                  </div>
                </div>
                <div className="">
                  {this.state.checked === 1 && (
                    <SingleSelect
                      options={this.state.uom}
                      handleChange={this.uomSelect}
                      selectedService={this.state.selectedUOM}
                    />
                  )}
                </div>
              </div> */}
              <div className="col-sm-3">
                <div className="row" style={{ position: "relative", top: 9 }}>
                  <label style={{ fontSize: "14px" }}> Required </label>
                  <div style={{ padding: 2, marginTop: -3 }}>
                    <input
                      type="radio"
                      // style={{ marginLeft: 3 }}
                      name={`validation ${index + 1}`}
                      value={item.required}
                      onChange={() =>
                        this.formradiocheck("required", true, index)
                      }
                    />{" "}
                    Yes
                  </div>
                  <div style={{ padding: 2, marginTop: -3 }}>
                    <input
                      type="radio"
                      // style={{ margin: "7px", marginLeft: "18px" }}
                      name={`validation ${index + 1}`}
                      onChange={() =>
                        this.formradiocheck("required", false, index)
                      }
                    />{" "}
                    No
                  </div>
                </div>
              </div>
              <div className="col-sm-1">
                <i
                  className="fa fa-window-close fa-1.2x"
                  style={{ padding: "3px", margin: "9px 50px 0px 9px" }}
                  aria-hidden="true"
                  onClick={() => this.removetype(index, item.type)}
                />
              </div>
            </div>
          </div>
        );
      }
      if (formJson[index].type == "textarea") {
        contentJson.push(
          <div style={{ background: "#73818f", marginBottom: "25px" }}>
            <div className="row">
              <div className="col-sm-3">
                <input
                  type="text"
                  className="form-control"
                  style={{ margin: "3px", fontSize: "14px", marginLeft: "8px" }}
                  placeholder={`TextArea ${index + 1} Label Name`}
                  onChange={e => this.formvalue(e.target.value, "name", index)}
                  value={item.name}
                />
              </div>
              <div className="col-sm-3">
                <input
                  type="text"
                  className="form-control"
                  style={{
                    margin: "3px",
                    fontSize: "14px",
                    marginLeft: "-14px"
                  }}
                  placeholder={`TextArea ${index + 1} Placeholder name`}
                  onChange={e =>
                    this.formvalue(e.target.value, "placeholder", index)
                  }
                  value={item.placeholder}
                />
              </div>
              <div className="col-sm-3">
                <div className="row" style={{ position: "relative", top: 9 }}>
                  <label style={{ fontSize: "14px" }}> Required </label>
                  <div style={{ padding: 2, marginTop: -3 }}>
                    <input
                      type="radio"
                      // style={{ marginLeft: 3 }}
                      name={`validation ${index + 1}`}
                      value={item.required}
                      onChange={() =>
                        this.formradiocheck("required", true, index)
                      }
                    />{" "}
                    Yes
                  </div>
                  <div style={{ padding: 2, marginTop: -3 }}>
                    <input
                      type="radio"
                      // style={{ margin: "7px", marginLeft: "18px" }}
                      name={`validation ${index + 1}`}
                      onChange={() =>
                        this.formradiocheck("required", false, index)
                      }
                    />{" "}
                    No
                  </div>
                </div>
              </div>
              <div className="col-sm-2"></div>
              <div className="col-sm-1">
                <i
                  className="fa fa-window-close fa-1.2x"
                  style={{ padding: "3px", margin: "9px 50px 0px 9px" }}
                  aria-hidden="true"
                  onClick={() => this.removetype(index, item.type)}
                />
              </div>
            </div>
          </div>
        );
      }
      if (formJson[index].type == "radio") {
        var optionList = [];
        formJson[index].option.forEach((element, key) => {
          optionList.push(
            <div className="form-group">
              <label className="radio-inline">
                <input type="radio" value={`optionradio ${key + 1}`} />
                <input
                  type="text"
                  className="form-control"
                  style={{ fontSize: "14px" }}
                  placeholder={`option ${key + 1}`}
                  onChange={e => this.optionvalue(e.target.value, key, index)}
                />
              </label>
            </div>
          );
        });
        contentJson.push(
          <div style={{ background: "#73818f", marginBottom: "25px" }}>
            <div className="row ">
              <div className="col-sm-3">
                <input
                  type="text"
                  className="form-control"
                  style={{
                    margin: "3px",
                    fontSize: "14px",
                    marginRight: "170px"
                  }}
                  placeholder={`Radio ${index + 1} Label name`}
                  onChange={e => this.formvalue(e.target.value, "name", index)}
                  value={item.name}
                />
              </div>
              <div className="col-sm-4">
                <div className="row">
                  <label style={{ margin: "9px", fontSize: "14px" }}>
                    Options
                  </label>
                  <div className="col-sm-2">
                    <div
                      class="cssCircle plusSign"
                      onClick={() => this.selectoption(index)}
                      style={{
                        margin: "9px",
                        lineHeight: "0.8em",
                        marginLeft: "-16px"
                      }}
                    >
                      &#43;
                    </div>
                    <div className="col-sm-1" />
                    <div
                      class="cssCircle minusSign"
                      onClick={() => this.removeoption(index)}
                      style={{
                        margin: "9px",
                        lineHeight: "0.8em",
                        marginLeft: "-20px",
                        marginTop: "-30px",
                        marginLeft: "27px"
                      }}
                    >
                      &#8722;
                    </div>
                  </div>
                  {optionList}
                </div>
              </div>
              <div className="col-sm-4">
                <label style={{ fontSize: "14px" }}>Required</label>
                <input
                  type="radio"
                  style={{ margin: "12px" }}
                  name={`validation ${index + 1}`}
                  onChange={() => this.formradiocheck("required", true, index)}
                  value={item.required}
                />{" "}
                Yes
                <input
                  type="radio"
                  style={{ margin: "8px" }}
                  name={`validation ${index + 1}`}
                  onChange={() => this.formradiocheck("required", false, index)}
                  value={item.required}
                />{" "}
                No
              </div>
              <div className="col-sm-1">
                <i
                  style={{ margin: "9px 50px 50px 9px" }}
                  className="fa fa-window-close fa-1.2x"
                  aria-hidden="true"
                  onClick={() => this.removetype(index, item.type)}
                />
              </div>
            </div>
          </div>
        );
      }
      if (formJson[index].type == "selectbox") {
        var optionList = [];
        formJson[index].option.forEach((element, key) => {
          optionList.push(
            <div className="form-group">
              <input
                key={key + 1}
                type="text"
                className="form-control"
                placeholder={`option ${key + 1}`}
                onChange={e => this.optionvalue(e.target.value, key, index)}
              />
            </div>
          );
        });
        contentJson.push(
          <div style={{ background: "#73818f", marginBottom: "25px" }}>
            <div className="row" style={{ margin: "6px" }}>
              <div className="col-sm-2">
                <input
                  type="text"
                  className="form-control"
                  style={{ margin: "3px", marginLeft: "-15px" }}
                  placeholder={`Selectbox ${index + 1} Label name`}
                  onChange={e => this.formvalue(e.target.value, "name", index)}
                  value={item.name}
                />
              </div>
              <div className="col-sm-3">
                <input
                  type="text"
                  className="form-control"
                  style={{ margin: "3px", marginLeft: "-14px" }}
                  placeholder={`Selectbox ${index + 1} Placeholder name`}
                  onChange={e =>
                    this.formvalue(e.target.value, "placeholder", index)
                  }
                  value={item.placeholder}
                />
              </div>
              <div className="col-sm-2">
                <div
                  className="row"
                  style={{ margin: "5px", marginLeft: "-25px" }}
                >
                  <label style={{ fontSize: "14px", margin: "6px" }}>
                    Option
                  </label>
                  <div
                    class="cssCircle plusSign"
                    onClick={() => this.selectoption(index)}
                    style={{ margin: "3px", lineHeight: "0.8em" }}
                  >
                    &#43;
                  </div>
                  <div
                    class="cssCircle minusSign"
                    style={{ margin: "4px", lineHeight: "0.8em" }}
                    onClick={() => this.removeoption(index)}
                  >
                    &#8722;
                  </div>
                </div>
                {optionList}
              </div>
              <div
                className="col-sm-4"
                style={{ margin: "8px", marginLeft: "-34px" }}
              >
                <label style={{ fontSize: "14px", margin: "3px" }}>
                  {" "}
                  Required{" "}
                </label>
                <input
                  type="radio"
                  style={{ margin: "3px" }}
                  name={`validation ${index + 1}`}
                  onChange={() => this.formradiocheck("required", true, index)}
                  value={item.required}
                />
                Yes
                <input
                  type="radio"
                  style={{ margin: "3px" }}
                  name={`validation ${index + 1}`}
                  onChange={() => this.formradiocheck("required", false, index)}
                  value={item.required}
                />
                No
              </div>
              <div className="col-sm-1">
                <i
                  className="fa fa-window-close fa-1.2x"
                  style={{ padding: "3px", margin: "9px 50px 0px 53px" }}
                  aria-hidden="true"
                  onClick={() => this.removetype(index, item.type)}
                />
              </div>
            </div>
          </div>
        );
      }
      if (formJson[index].type == "checkbox") {
        var optionList = [];
        formJson[index].option.forEach((element, key) => {
          optionList.push(
            <div className="row form-group">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox1"
                />
                <label className="form-check-label" for="inlineCheckbox1">
                  <input
                    style={{ fontSize: "14px" }}
                    type="text"
                    class="form-control"
                    placeholder={`Option ${key + 1}`}
                    onChange={e => this.optionvalue(e.target.value, key, index)}
                    value={element.label}
                  />
                </label>
              </div>
            </div>
          );
        });
        contentJson.push(
          <div style={{ background: "#73818f", marginBottom: "25px" }}>
            <div className="row" style={{ margin: "6px" }}>
              <div className="col-sm-3">
                <input
                  type="text"
                  style={{
                    margin: "3px",
                    fontSize: "14px",
                    marginLeft: "-12px"
                  }}
                  className="form-control"
                  placeholder={`Checkbox ${index + 1} Label name`}
                  onChange={e => this.formvalue(e.target.value, "name", index)}
                  value={item.value}
                />
              </div>
              <div className="col-sm-4">
                <label style={{ fontSize: "14px" }}> Required </label>
                <input
                  type="radio"
                  style={{ margin: "7px" }}
                  onChange={() => this.formradiocheck("required", true, index)}
                  value={item.required}
                />{" "}
                Yes
                <input
                  type="radio"
                  style={{ margin: "8px" }}
                  onChange={() => this.formradiocheck("required", false, index)}
                  value={item.required}
                />{" "}
                No
              </div>

              <div className="col-sm-4">
                <div className="row">
                  <label
                    style={{
                      fontSize: "14px",
                      margin: "5px",
                      marginRight: "30px"
                    }}
                  >
                    {" "}
                    Option{" "}
                  </label>
                  <br />
                  <div
                    class="cssCircle plusSign"
                    style={{ margin: "3px", lineHeight: "0.8em" }}
                    onClick={() => this.selectoption(index)}
                  >
                    &#43;
                  </div>
                  <div className="col-sm-1" />
                  <div
                    class="cssCircle minusSign"
                    style={{ margin: "3px", lineHeight: "0.8em" }}
                    onClick={() => this.removeoption(index)}
                  >
                    &#8722;
                  </div>
                </div>
                {optionList}
              </div>
              <div className="col-sm-1">
                <i
                  className="fa fa-window-close fa-1.2x"
                  style={{ padding: "3px", margin: "9px 50px 0px 28px" }}
                  aria-hidden="true"
                  onClick={() => this.removetype(index, item.type)}
                />
              </div>
            </div>
          </div>
        );
      }
    });
    this.setState({ contentJson });
  };
  render() {
    const {
      boxtext,
      error,
      alertVisible,
      contentJson,
      errortext,
      formJson
    } = this.state;

    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="card">
              <div className="card-header">
                <h1>Form </h1>
              </div>
              <div className="card-body" />
              <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="subgroup">Form Name</label>
                </div>
                <div className="col-sm-5">
                  <input
                    type="text"
                    name="subgroup"
                    placeholder="Enter Form Name"
                    className="form-control"
                    value={boxtext}
                    onChange={this.boxtext}
                  />
                  <span className="error-shows ">{errortext}</span>
                </div>
              </div>
              <div>
                <div className="row form-group">
                  <div className="col-sm-2">
                    <div
                      style={{
                        background: "#343a40",
                        border: "2px solid rgb(52, 58, 64)",
                        padding: "10px",
                        borderTopRightRadius: "25px",
                        borderBottomRightRadius: "25px"
                      }}
                    >
                      <div className="row form-group"></div>
                      <div>
                        <div className="row form-group">
                          <div className="col-sm-3">
                            <label
                              style={{
                                fontSize: "12px",
                                marginLeft: "5px",
                                color: "#fff",
                                width: "50px"
                              }}
                            >
                              Add Text
                            </label>
                          </div>
                          <div className="col-sm-1" />
                          <div
                            class="cssCircle plusSign"
                            onClick={() => this.formfunction("text")}
                            style={{
                              paddingTop: "8px",
                              marginLeft: "38px",
                              marginTop: "3px"
                            }}
                          >
                            &#43;
                          </div>
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-3">
                            <label
                              style={{
                                fontSize: "12px",
                                marginLeft: "5px",
                                color: "#fff",
                                width: "72px"
                              }}
                            >
                              Add TextArea
                            </label>
                          </div>
                          <div className="col-sm-1" />
                          <div
                            class="cssCircle plusSign"
                            onClick={() => this.formfunction("textarea")}
                            style={{
                              paddingTop: "8px",
                              marginLeft: "38px",
                              marginTop: "3px"
                            }}
                          >
                            &#43;
                          </div>
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-3">
                            <label
                              style={{
                                fontSize: "12px",
                                marginLeft: "5px",
                                color: "#fff",
                                width: "55px"
                              }}
                            >
                              Add Radio
                            </label>
                          </div>
                          <div className="col-sm-1" />
                          <div
                            class="cssCircle plusSign"
                            onClick={() => this.formfunction("radio")}
                            style={{
                              paddingTop: "8px",
                              marginLeft: "38px",
                              marginTop: "3px"
                            }}
                          >
                            &#43;
                          </div>
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-3">
                            <label
                              style={{
                                fontSize: "12px",
                                marginLeft: "5px",
                                color: "#fff",
                                width: "77px"
                              }}
                            >
                              Add Selectbox
                            </label>
                          </div>
                          <div className="col-sm-1" />
                          <div
                            class="cssCircle plusSign"
                            onClick={() => this.formfunction("selectbox")}
                            style={{
                              paddingTop: "8px",
                              marginLeft: "38px",
                              marginTop: "3px"
                            }}
                          >
                            &#43;
                          </div>
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-3">
                            <label
                              className="form-label"
                              style={{
                                fontSize: "12px",
                                marginLeft: "5px",
                                color: "#fff",
                                width: "77px"
                              }}
                            >
                              Add Checkbox
                            </label>
                          </div>
                          <div className="col-sm-1" />
                          <div
                            class="cssCircle plusSign"
                            onClick={() => this.formfunction("checkbox")}
                            style={{
                              paddingTop: "8px",
                              marginLeft: "38px",
                              marginTop: "3px"
                            }}
                          >
                            &#43;
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-sm-9"
                    style={{ overflowX: "hidden", height: "400px" }}
                  >
                    <Alert
                      className="badge-content"
                      color={this.state.color}
                      isOpen={alertVisible}
                      toggle={this.onDismiss}
                    >
                      {this.state.error}
                    </Alert>
                    <div className="row">
                      <div className="col-sm-12">{contentJson}</div>
                    </div>
                    <div className="col-sm-1"></div>
                  </div>
                </div>
              </div>
              <div>
                <div className="row">
                  <div className="col-sm-2" />
                  <div className="col-sm-2" />
                  <div className="col-sm-3">
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={this.state.disabledValue}
                      onClick={this.buttonSave}
                    >
                      Save Form
                    </button>
                  </div>
                  <div className="col-sm-3">
                    <LoginModal
                      buttonTitle="Preview"
                      extraClass="btn-warning"
                      extraStyle={{ width: 800, left: -97 }}
                      title="Form Preview"
                      atagLink={false}
                      id="group"
                      onClick={this.buttonPreview}
                      bodyText={
                        <div>
                          <div className="row">
                            <div className="col-12">
                              <div className="card">
                                <div className="card-header">
                                  <h1>{this.state.boxtext}</h1>
                                </div>
                                <div className="card-body">
                                  {this.state.modalJson}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-12" />
              <div style={{ margin: "12px" }}>
                {/* {this.state.data && (<Datatable data={this.state.data} columnHeading={this.column} />)} */}
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
export default DfcarsAddForms;
