import React, { Component } from "react";
import SingleSelect from "../../../components/Form/SingleSelect";
import CmsContent from "../../../MiddleWare/CmsContent";
import Datatable from "../../../components/Datatable/Datatable";
import { Alert } from "reactstrap";
import { FormMiddleWare } from "../../../components/Form";
import { confirmAlert } from "react-confirm-alert";
import BatchPDF from "../../../components/PDF/BatchPDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import redisMiddleware from "../../../MiddleWare/redisMiddleware";
import ColorPicker from 'react-color'
class drawerMenuLabel extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      color_for_icon:"#111",
      categoryOptions: [],
      categorySelected: [],
      editvalue: null,
      categoryId: null,
      alertVisible: false,
      alertVisible1: false,
      formAlertdelete: false,
      disableValue: false,
      error: null,
      selectboxerror: null,
      customerId: localStorage.getItem("userId"),
      serviceId: localStorage.getItem("currentService"),
      button: "Submit",
      disabled: false,
      textalert: null,
      color: "success"
    };
  }
  async componentDidMount() {
    try {
      //  let category = await CmsContent.getMasteractive('tbl_program');
      let userlog = JSON.parse(localStorage.getItem("userlog"));

      console.log(userlog);

      let companyid = userlog.id;
      // let category = await CmsContent.getMasteractive('tbl_program');

      let result = await CmsContent.getFreedom(
        "*",
        "tbl_DynamicDrawer",
        `customerid = ${this.state.customerId} and serviceid = ${this.state.serviceId}`,
        "id",
        "id"
      );

      console.log(result);

      const usertype = await redisMiddleware.singleTable(
        "tbl_UserType",
        this.state.customerId,
        "customerid"
      );

      let categoryOptions = [];

      if (usertype && usertype.data.length > 0) {
        usertype.data.map((ival, i) => {
          if (ival.serviceid == 10) {
            categoryOptions.push({
              value: ival.id,
              label: ival.usertype,
              order: ival.orderBy
            });
          }
        });
      }

      this.setState({ data: result.data, categoryOptions });
    } catch (error) {
      console.log(error);
    }
  }
  column = [
    // {
    //   Header: "Usertype",
    //   accessor: "usertypeid",
    //   Cell: d =>
    //     this.getValueFromArray(
    //       d.original.usertypeid,
    //       this.state.categoryOptions
    //     )
    // },
    {
      Header: "Drawer Label",
      accessor: "labelkey"
    },
    {
      Header: "Icon",
      accessor: "icon"
    },
    {
      Header: "Color",
      accessor: "color"
    },
    {
      Header: "Edit",
      accessor: "edit",
      Cell: d => this.edit(d)
    },
    {
      Header: "Delete",
      accessor: "delete",
      Cell: d =>
        this.dataTableButton("danger", "Delete", () => {
          this.buttonDeletes(d.original);
        })
    }
  ];

  edit = d => {
    let value = d.index;
    return (
      <center>
        <button
          type="button"
          className="btn btn-info"
          onClick={() => this.edition(value)}
        >
          Edit
        </button>
      </center>
    );
  };
  edition = value => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    let data = [...this.state.data];
    let categoryOptions = this.state.categoryOptions;
    let color_for_icon = this.state.color_for_icon
    let editvalue = data[value].labelkey;
    let icon = data[value].icon;
    let editid = data[value].id;
    let usertypeid = data[value].usertypeid;
    if(data[value].color){
    color_for_icon = data[value].color;}else{
      color_for_icon=""
    }
    let categorySelected = {};

    // categorySelected.label = categoryOptions[value].label;

    this.setState({
      disabled: true,
      icon,
      index: value,
      selectboxerror: null,
      categorySelected,
      color_for_icon,
      error: null,
      button: "Update",
      editvalue,
      editid,
      usertypeid
    });
  };

  buttonDeletes = value => {
    this.setState({});
    confirmAlert({
      title: "Confirmation to Delete",
      message: "Are you sure you want to delete this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.buttonDelete(value)
          //onClick: () => alert("Group removed from")
        },
        {
          label: "No"
        }
      ]
    });
  };

  buttonDelete = async value => {
    const previousData = [...this.state.data];
    let id = value.id;

    try {
      const result = await CmsContent.getSingleConditionedValue(
        "tbl_batch",
        "id",
        id,
        "Delete"
      );
      if (result) {
        let datas = previousData.filter((delelteid, i) => {
          if (delelteid.id !== value.id) {
            return delelteid;
          }
        });
        this.setState({
          data: datas,
          formAlertdelete: true,
          alertVisible: true,
          textalert: "Selected Batch Deleted",
          color: "danger"
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  getValueFromArrays = (d, array) => {
    if (array.length > 0) {
      if (array.length !== 0) {
        let filtered = array.filter(function(item) {
          return item.value == d;
        });
        let v = filtered[0];
        if (v != undefined) {
          return v.label;
        } else {
          return "---";
        }
      }
    } else {
      return "-";
    }
  };
  handlechange = e => {
    let value = e.target.value;

    this.setState({ editvalue: value });
  };

  handlechangeIcon = e => {
    let value = e.target.value;

    this.setState({ icon: value });
  };
  handleChangeColor = (color) => {
    if(color.hex){
       this.setState({ color_for_icon: color.hex });
    }else{
      this.setState({ color_for_icon: color.target.value});
    }
   
  };
  addnew = async () => {
    let { categorySelected } = this.state;
    //let value = this.state.editvalue;
    //let programid = this.state.categoryId;
    const { editvalue: value, categoryid: programid, icon ,color_for_icon } = this.state;

    // if (programid != undefined && programid != "" && programid != null) {
    //   this.setState({ selectboxerror: "" });
    // } else {
    //   this.setState({ selectboxerror: "Please fill select box" });
    //   return false;
    // }
    // if (value != undefined && value.trim() != "" && value != null) {
    //   this.setState({ error: "" });
    // } else {
    //   this.setState({ error: "Please Fill The Batch", selectboxerror: "" });
    //   return true;
    // }

    let groupArray = {};
    groupArray.labelkey = value.trim();
    // groupArray.usertypeid = programid;
    groupArray.icon = icon;
    //groupArray.label = categorySelected.label;
    groupArray.customerid = this.state.customerId;
    groupArray.serviceid = this.state.serviceId;
    groupArray.color=color_for_icon;

    try {
      this.setState({ disableValue: true });
      const result = await CmsContent.addMaster(
        "tbl_DynamicDrawer",
        groupArray
      );
      if (result) {
        let valueArray = {};
        valueArray.id = result.data.insertId;
        valueArray.labelkey = value.trim();
        // valueArray.usertypeid = programid;
        valueArray.icon = icon;
        valueArray.color=color_for_icon;
        const newData = [valueArray, ...this.state.data];

        this.setState({
          data: newData,
          alertVisible: true,
          editvalue: "",
          editid: null,
          icon: "",
          categoryId: "",
          categorySelected: "",
          color_for_icon:"",
          disableValue: false,
          addNewSubgroup: false,
          error: null,
          selectboxerror: null,
          button: "Submit",
          index: null,
          textalert: "New Batch Added",
          color: "success"
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  update = async () => {
    const {
      editvalue: value,
      categoryid: programid,
      icon,
      usertypeid,
      color_for_icon
    } = this.state;
    //this.setState({editvalue ,categoryId });
    if (value === "") {
      this.setState({ error: "Value Cannot Be Empty" });
      return false;
    } else if (programid === null) {
      return false;
    } else {
      this.setState({ disableValue: true });
      try {
        let previousdata = [...this.state.data];
        //let index = this.state.index;
        //let id = this.state.editid;
        const { index, editid: id } = this.state;

        let subgroupArray = {};
        subgroupArray.labelkey = value.trim();
        subgroupArray.usertypeid = programid;
        subgroupArray.icon = icon;
        subgroupArray.color= color_for_icon;

        const result = await CmsContent.updateMaster(
          "tbl_DynamicDrawer",
          id,
          subgroupArray
        );
        if (result) {
          previousdata[index].usertypeid = usertypeid;
          previousdata[index].labelkey = value.trim();
          previousdata[index].icon = icon;
          previousdata[index].color= color_for_icon;
          this.setState({
            data: previousdata,
            alertVisible: true,
            error: null,
            disableValue: false,
            categorySelected: null,
            editid: null,
            icon: "",
            color_for_icon:"",
            addNewSubgroup: false,
            index: null,
            button: "Submit",
            disabled: false,
            editvalue: "",
            textalert: "New Batch Updated",
            color: "success"
          });
          setTimeout(() => this.setState({ alertVisible: false }), 3000);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  onDismiss = () => {
    this.setState({ alertVisible: false });
    this.setState({ alertVisible1: false });
    this.setState({ formAlertdelete: false });
  };
  programSelect = e => {
    this.setState({ categorySelected: e, categoryid: e.value });
  };

  render() {
    const {
      alertVisible,
      alertVisible1,
      formAlertdelete,
      editvalue,
      disableValue,
      error,
      selectboxerror,
      button,
      categorySelected,
      textalert,
      icon
    } = this.state;
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Drawer Menu Label</h1>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-7">
                        <Alert
                          className="badge-content"
                          color={this.state.color}
                          isOpen={alertVisible}
                          toggle={this.onDismiss}
                        >
                          {/* New Batch Added */}
                          {textalert}
                        </Alert>
                        {/* <Alert
                          className="badge-content"
                          color="success"
                          isOpen={alertVisible1}
                          toggle={this.onDismiss}
                        >
                          New Batch Edited
                        </Alert>
                        <Alert
                          className="badge-content"
                          color="danger"
                          isOpen={formAlertdelete}
                          toggle={this.onDismiss}
                        >
                          Selected Batch Deleted
                        </Alert> */}
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    {/* <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label for="exampleInputEmail1">Usertype</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          options={this.state.categoryOptions}
                          handleChange={this.programSelect}
                          selectedService={categorySelected}
                        />
                        <span className="error-shows">{selectboxerror}</span>
                      </div>
                      <div className="col-sm-3" />
                    </div> */}
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="subgroup">Label Key</label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type="text"
                          name="subgroup"
                          placeholder="Enter Label Key"
                          className="form-control"
                          value={editvalue}
                          onChange={e => this.handlechange(e)}
                        />
                        <span className="error-shows">{error}</span>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="subgroup">Icon</label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type="text"
                          name="subgroup"
                          placeholder="Enter Icon Display"
                          className="form-control"
                          value={icon}
                          onChange={e => this.handlechangeIcon(e)}
                        />
                        <span className="error-shows">{error}</span>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="subgroup">Icon Color</label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type="text"
                          name="subgroup"
                          placeholder="Enter Icon Color"
                          className="form-control"
                          value={this.state.color_for_icon}
                          onChange={e => this.handleChangeColor(e)}
                        />
                         <div className="col-sm-2" >
                        <ColorPicker color={this.state.color_for_icon}  onChangeComplete={this.handleChangeColor} />
                        </div>
                        <span className="error-shows">{error}</span>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2" />
                      <div className="col-sm-5">
                        <button
                          type="button"
                          className="btn btn-primary"
                          disabled={disableValue}
                          onClick={
                            button === "Update" ? this.update : this.addnew
                          }
                        >
                          {this.state.button}
                        </button>
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    <span>
                      Total of {Object.keys(this.state.data).length} records
                    </span>
                    {this.state.data && (
                      <Datatable
                        data={this.state.data}
                        columnHeading={this.column}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default drawerMenuLabel;
