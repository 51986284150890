import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./SideNav.css";

export default class DifuzaCarsNav extends Component {
  render() {
    let serviceId = localStorage.getItem("currentService");
    return (
      <div className="sidebar">
        <nav className="sidebar-nav">
          <ul className="nav">
            <li className="nav-item">
              <Link className="nav-link" to={`/superAdmin`}>
                <i className="nav-icon icon-speedometer" />
                <span className="link-white">Service Dashboard</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link
                className="nav-link"
                to={`/DifuzaCars/${serviceId}`}
              >
                <i className="nav-icon icon-speedometer" />
                <span className="link-white">Dashboard</span>
              </Link>
            </li>
            <li className="nav-item nav-dropdown">
              <Link className="nav-link nav-dropdown-toggle" href="#">
                <i className="nav-icon icon-puzzle" /> Master
              </Link>
              <ul className="nav-dropdown-items">
                <li className="nav-item">
                  <Link className="nav-link" to="/DFCars/DfCaresUsertype">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">UserType</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/DFCars/DfCarsLocation">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Location</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/DFCars/DfCarsMapHierarchy">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">MapHierarchy</span>
                  </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/DFCars/dfCarsAddDesignation">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Map Usertype-Location</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/DFCars/DfCarsAddUsers">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Add User</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/DFCars/DfCarsAdminEnquiry">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Add Enquiry</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/DFCars/Dfcolormaster">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Colour Master</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/DFCars/Dfbrandmaster">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Brand Master</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/DFCars/Dfmakemaster">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Make Master</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/DFCars/Dfmodelmaster">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Model Master</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/DFCars/Dfvariantmaster">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Variant Master</span>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link" to="/DFCars/Dfcarswarranty">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Cars warranty</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/DFCars/pricelistmaster">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Price List</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li className="nav-item nav-dropdown">
              <Link className="nav-link nav-dropdown-toggle" href="#">
                <i className="nav-icon icon-puzzle" /> Form
              </Link>
              <ul className="nav-dropdown-items">
                <li className="nav-item">
                  <Link className="nav-link" to="/DFCars/DfcarsAddForms">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Form Generator</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/DFCars/DfCarsMapForm">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Map Form</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/DFCars/DfCarsFormOrder">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Form Order</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item nav-dropdown">
              <Link className="nav-link nav-dropdown-toggle" href="#">
                <i className="nav-icon icon-puzzle" /> Report
              </Link>
              <ul className="nav-dropdown-items">
                <li className="nav-item">
                  <Link className="nav-link" to="/DFCars/DfCarsEnquiry">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Enquiry Report</span>
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link className="nav-link" to="/DFCars/DfCarsEnquiry">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Buyier Report</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/DFCars/DfCarsEnquiry">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Seller Report</span>
                  </Link>
                </li> */}
              </ul>
            </li>
            <li className="nav-item nav-dropdown">
              <Link className="nav-link nav-dropdown-toggle" href="#">
                <i className="nav-icon icon-puzzle" /> Process
              </Link>
              <ul className="nav-dropdown-items">
                <li className="nav-item">
                  <Link className="nav-link" to="/DFCars/processdefinition">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Process Definition</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item nav-dropdown">
              <Link className="nav-link nav-dropdown-toggle" href="#">
                <i className="nav-icon icon-puzzle" /> Drawer Menu
              </Link>
              <ul className="nav-dropdown-items">
                <li className="nav-item">
                  <Link className="nav-link" to="/DFCars/drawerMenuLabel">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Drawer Menu </span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/DFCars/drawermapping">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Mapping Drawer</span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}
