import React, { Component } from 'react';
import CmsContent from '../../../../MiddleWare/CmsContent';
import Datatable from "../../../../components/Datatable/Datatable";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import cmsContent from "../../../../MiddleWare/CmsContent";

class DfCarAdminContact extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            themeId: null,
            changenumber: null,
            fullData: [],
            index: null,
            alertVisible: false,
            customerId: localStorage.getItem("userId"),
            column: [
                {
                    Header: "ID",
                    accessor: "id"
                },
                {
                    Header: "NAME",
                    accessor: "name"
                },
                {
                    Header: "MOBILE",
                    accessor: "mobile"
                },
                {
                    Header: "EMAIL",
                    accessor: "email"
                },
                {
                    Header: "ADDRESS",
                    accessor: "address"
                },
                {
                    Header: "COME FROM",
                    accessor: "type"
                },


            ],
        };
    }
    async componentDidMount() {
        this.MainFun()
    }
    MainFun = async () => {
        try {
            let result = await cmsContent.getFreedom("*", "tbl_steerauto_contact", 1, 1, "id desc")
            if (result) {
                await result.data.map((ival) => {
                    if (!ival.email) {
                        ival.email = "-"
                    }
                    if (!ival.address) {
                        ival.address = "-"
                    }
                    if (ival.type == 1) {
                        ival.type = "Contact Us"
                    }
                    if (ival.type == 3) {
                        ival.type = "Subscriber"
                    }
                })
                await this.setState({ fullData: result.data })
            }
        } catch (err) {
            console.log(err);
        }
    }
    render() {
        const { fullData, alertVisible } = this.state;
        return (
            <main className="main my-4">
                <main className="container-fluid">

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h1>Contact Us </h1>
                                </div>
                                <div className="row form-group">
                                    <div className="col-sm-2" />
                                    <div className="col-sm-7">

                                    </div>
                                    <div className="col-sm-3" />
                                </div>
                                <div className="card-body">
                                    {fullData && (
                                        <Datatable data={fullData} columnHeading={this.state.column} />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </main>
        );
    }
}

export default DfCarAdminContact;