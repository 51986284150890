import React,{Component} from 'react';



export default class Dashboard extends React.Component{
    render(){
        return(
            <main className="main my-4">
            <div>
                <center>
                     <h1> External User Dashboard</h1>
                </center>
              
            </div>
            </main>
        )
    }
}