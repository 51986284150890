import React, { Component } from "react";
import { Alert } from "reactstrap";
import CmsContent from "../../../../MiddleWare/CmsContent";
import Datatable from "../../../../components/Datatable/Datatable";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import { confirmAlert } from "react-confirm-alert";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import SFAPDF from "../../../../components/PDF/SFAPDF";
import redisMiddleware from "../../../../MiddleWare/redisMiddleware";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SingleSelect from "../../../../components/Form/SingleSelect";

class DfCarsBranch extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            alertVisible: false,
            alertVisible1: false,
            editvalue: "",
            editid: null,
            email: "",
            Mobile: "",
            Address: "",
            disableValue: false,
            addNewSubgroup: false,
            error: null,
            button: "Submit",
            index: null,
            customerId: localStorage.getItem("userId"),
            serviceId: localStorage.getItem("currentService"),
            formAlertdelete: false,
            textalert: null,
            latitude: "", longitude: "",
            color: "success"
        };
    }
    async componentWillMount() {
        this.mainFun()
    }
    mainFun = async () => {
        try {
            this.setState({ loading: true })
            const location = await CmsContent.getFreedom(
                "name as label, id as value",
                "tbl_location",
                `customerId=${this.state.customerId} and serviceid=${this.state.serviceId} and status='active'`,
                "id",
                "id desc"
            );
            if (location) {
                this.setState({ locationOpt: location.data });
            }
            const result = await CmsContent.getFreedom(
                "tbl_dfcars_Branch.*,tbl_location.name as location",
                "tbl_dfcars_Branch,tbl_location",
                `tbl_dfcars_Branch.customerId=${this.state.customerId} and tbl_dfcars_Branch.serviceid=${this.state.serviceId} and tbl_location.id = tbl_dfcars_Branch.locationId and tbl_dfcars_Branch.status='active'`,
                "tbl_dfcars_Branch.id",
                "tbl_dfcars_Branch.id desc"
            );
            if (result.data.length > 0) {
                const header = ["SI.NO", "BRANCH", "LOCATION"];
                const excelHead = [{ label: "BRANCH", key: "name" }, { label: "LOCATION", key: "location" }];
                this.setState({
                    excelHead,
                    header,
                    excel: result.data,
                    title: "BRANCH",
                    data: result.data,
                    loading: false
                });
            }
            // this.setState({loading:false})
        } catch (error) {
            console.log(error);
        }
    }

    column = [
        {
            Header: "Branch",
            accessor: "name"
        },
        {
            Header: "Location",
            accessor: "location",
        },
        {
            Header: "Mobile",
            accessor: "mobile",
        },
        {
            Header: "E-Mail",
            accessor: "email",
        },
        {
            Header: "Address",
            minWidth: 150,
            accessor: "address",
        },
        {
            Header: "Latitude",
            accessor: "latitude",
        },
        {
            Header: "Longitude",
            accessor: "longitude",
        },
        {
            Header: "Edit",
            accessor: "edit",
            Cell: d => this.edit(d)
        },
        {
            Header: <b>Delete</b>,
            selector: "delete",
            Cell: d =>
                this.dataTableButton("danger", "Delete", () => {
                    this.buttonDeletes(d);
                })
        },
        {
            Header: "Share",
            accessor: "file",
            Cell: d => this.sharelink(d)
        }
    ];
    sharelink = (d) => {
        // console.log(d)
        return (
            <div>
                <CopyToClipboard
                    text={d.original.name}
                // onCopy={() => this.setState({ copied: true })}
                >
                    <button type="button" className="btn btn-primary">
                        Copy to clipboard
                    </button>
                </CopyToClipboard>
                <br />
                {
                    this.state.copied == true && (
                        <div>
                            <span style={{ color: "green" }}>
                                URL is Copied
                            </span>
                        </div>
                    )
                }
            </div>
        )
    }
    edit = d => {
        let value = d;
        return (
            <center>
                <button
                    type="button"
                    className="btn btn-info"
                    onClick={() => this.edition(value)}
                >
                    Edit
                </button>
            </center>
        );
    };
    edition = async (value) => {
        let values = value.original;
        let editvalue = values.name;
        await this.setState({
            index: value.index ? value.index : "",
            branch: values.name ? values.name : "",
            Mobile: values.mobile ? values.mobile : "",
            email: values.email ? values.email : "",
            Address: values.address ? values.address : "",
            latitude: values.latitude,
            longitude: values.longitude,
            location: values.location ? { label: values.location, value: values.locationId } : null,
            editid: values.id,
            error: null,
            button: "Update"
        });
    };
    buttonDeletes = value => {
        confirmAlert({
            title: "Confirmation to Delete",
            message: "Are you sure you want to delete this?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => this.buttonDelete(value)
                },
                {
                    label: "No"
                }
            ]
        });
    };
    buttonDelete = async value => {
        const previousData = [...this.state.data];
        let id = value.original.id;
        try {
            // const result = await CmsContent.deleteMaster("tbl_dfcars_Branch", id);
            const result = await CmsContent.updateMaster("tbl_dfcars_Branch", id, { status: 'inactive' });
            if (result) {
                this.setState({
                    alertVisible: true,
                    textalert: "Selected Location Deleted",
                    color: "danger"
                });
                this.mainFun()
                setTimeout(() => this.setState({ alertVisible: false }), 3000);
            }
            // const result = await CmsContent.getSingleConditionedValue(
            //     "tbl_dfcars_Branch",
            //     "id",
            //     id,
            //     "Delete"
            // );
            // if (result) {
            //     let datas = previousData.filter((delelteid, i) => {
            //         if (delelteid.id !== value.original.id) {
            //             return delelteid;
            //         }
            //     });
            //     this.setState({
            //         data: datas,
            //         alertVisible: true,
            //         textalert: "Selected Location Deleted",
            //         color: "danger"
            //     });
            //     setTimeout(() => this.setState({ alertVisible: false }), 3000);
            // }
        } catch (error) {
            console.log(error);
        }
    };

    handleSelect = (e, name) => {
        this.setState({ [name]: e });
    };
    handleUserInput = e => {
        this.setState({ [e.target.name]: e.target.value });
    };
    reset = async () => {
        await this.setState({
            alertVisible: false,
            alertVisible1: false,
            editvalue: "",
            editid: null,
            disableValue: false,
            addNewSubgroup: false,
            branch: "",
            error: null,
            button: "Submit",
            index: null,
            formAlertdelete: false,
            textalert: null,
            latitude: "", longitude: "",
            color: "success", location: null,
        })
    }
    addnew = async () => {
        const { editvalue: value, data, location, branch, latitude, longitude, Mobile, email, Address } = this.state;
        if (!location) {
            this.setState({ error: "Please Select Location" })
            return false;
        }
        else if (branch === null || branch.trim() === "") {
            this.setState({ error: "Please Fill Branch" });
            return false;
        }

        this.setState({ error: "" });
        let groupArray = {};
        groupArray.name = branch.trim();
        groupArray.locationId = location ? location.value : null
        groupArray.mobile = Mobile ? Mobile : null
        groupArray.email = email ? email : null
        groupArray.address = Address ? Address : null
        groupArray.latitude = latitude == "" ? null : latitude
        groupArray.longitude = longitude == "" ? null : longitude
        groupArray.status = "active";
        groupArray.customerId = this.state.customerId;
        groupArray.serviceid = this.state.serviceId;

        try {
            this.setState({ disableValue: true });
            const result = await CmsContent.addMaster("tbl_dfcars_Branch", groupArray);

            if (result) {
                let valueArray = {};
                valueArray.id = result.data.insertId;
                valueArray.name = groupArray.name;
                const newData = [valueArray, ...this.state.data];
                this.setState({
                    data: newData,
                    alertVisible: true,
                    editvalue: "",
                    editid: null,
                    latitude: "",
                    email: "",
                    Mobile: "",
                    Address: "",
                    longitude: "",
                    disableValue: false,
                    addNewSubgroup: false,
                    error: null,
                    button: "Submit",
                    index: null,
                    branch: "",
                    location: null,
                    textalert: "New Location Added",
                    color: "success"
                });
                this.mainFun()
                setTimeout(() => this.setState({ alertVisible: false }), 3000);
            }
        } catch (error) {
            console.log(error);
        }
    };
    update = async () => {
        const { editvalue: value, data, location, branch, latitude, longitude, Mobile, email, Address } = this.state;

        if (!location) {
            this.setState({ error: "Please Select Location" })
            return false;
        }
        else if (branch === null || branch.trim() === "") {
            this.setState({ error: "Please Fill Branch" });
            return false;
        }

        else {

            this.setState({ error: "" });
            this.setState({ disableValue: true });
            try {
                let previousdata = [...this.state.data];
                const { index, editid: id } = this.state;

                let subgroupArray = {};
                subgroupArray.name = branch.trim();

                subgroupArray.mobile = Mobile ? Mobile : null
                subgroupArray.email = email ? email : null
                subgroupArray.address = Address ? Address : null


                subgroupArray.latitude = latitude == "" ? null : latitude
                subgroupArray.longitude = longitude == "" ? null : longitude
                subgroupArray.locationId = location ? location.value : null
                subgroupArray.status = "active";
                const result = await CmsContent.updateMaster(
                    "tbl_dfcars_Branch",
                    id,
                    subgroupArray
                );
                if (result) {
                    this.setState({
                        alertVisible: true,
                        editvalue: "",
                        disableValue: false,
                        editid: null,
                        addNewSubgroup: false,
                        error: null,
                        email: "",
                        Mobile: "",
                        Address: "",
                        button: "Submit",
                        latitude: "",
                        longitude: "",
                        index: null,
                        branch: "",
                        location: null,
                        textalert: "Location Updated",
                        color: "success"
                    });
                    this.mainFun()
                    setTimeout(() => this.setState({ alertVisible: false }), 3000);
                }
            } catch (error) {
                console.log(error);
            }

        }
    };
    onDismiss = () => {
        this.setState({ alertVisible: false });
        this.setState({ alertVisible1: false });
        this.setState({ formAlertdelete: false });
    };
    render() {
        const {
            editvalue,
            alertVisible,
            disableValue,
            error,
            button,
            textalert, latitude, longitude,
            branch,
            email,
            Mobile,
            Address
        } = this.state;
        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h1>Branch Master</h1>
                                    </div>
                                    <div className="card-body">
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-7">
                                                <Alert
                                                    className="badge-content"
                                                    color={this.state.color}
                                                    isOpen={alertVisible}
                                                    toggle={this.onDismiss}
                                                >
                                                    {textalert}
                                                </Alert>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="exampleInputEmail1">Select Location</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <SingleSelect
                                                    placeholder="Select Location"
                                                    options={this.state.locationOpt}
                                                    onChange={(e) => this.handleSelect(e, 'location')}
                                                    selectedService={this.state.location}
                                                />
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="subgroup">Branch</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <input
                                                    type="text"
                                                    name="branch"
                                                    placeholder="Enter Branch"
                                                    className="form-control"
                                                    value={branch}
                                                    onChange={e => this.handleUserInput(e)}
                                                />

                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="subgroup">E-Mail</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    placeholder="Enter E-Mail"
                                                    className="form-control"
                                                    value={email}
                                                    onChange={e => this.handleUserInput(e)}
                                                />

                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="subgroup">Mobile</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <input
                                                    type="number"
                                                    name="Mobile"
                                                    placeholder="Enter Mobile"
                                                    className="form-control"
                                                    value={Mobile}
                                                    onChange={e => this.handleUserInput(e)}
                                                />

                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="subgroup">Address</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <textarea
                                                    type="text"
                                                    name="Address"
                                                    placeholder="Enter Address"
                                                    className="form-control"
                                                    value={Address}
                                                    onChange={e => this.handleUserInput(e)}
                                                />
                                                <span color={{ color: "red" }}>{`Use ( , ) for Split Address`}</span>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="subgroup">Latitude</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <input
                                                    type="number"
                                                    name="latitude"
                                                    placeholder="Enter Latitude"
                                                    className="form-control"
                                                    value={latitude}
                                                    onChange={e => this.handleUserInput(e)}
                                                />

                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="subgroup">Longitude</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <input
                                                    type="number"
                                                    name="longitude"
                                                    placeholder="Enter Longitude"
                                                    className="form-control"
                                                    value={longitude}
                                                    onChange={e => this.handleUserInput(e)}
                                                />

                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    disabled={disableValue}
                                                    onClick={
                                                        button === "Update" ? this.update : this.addnew
                                                    }
                                                >
                                                    {this.state.button}
                                                </button>
                                            </div>
                                            <div className="col-sm-2" >
                                                <button
                                                    type="button"
                                                    className="btn btn-warning"
                                                    onClick={this.reset}
                                                >
                                                    Reset
                                                </button>
                                            </div>
                                        </div>
                                        <div style={{ marginTop: "5px" }}> </div>
                                        {this.state.excel && this.state.excel.length > 0 ? (
                                            <PDFDownloadLink
                                                style={{ color: "white" }}
                                                document={
                                                    <SFAPDF
                                                        title={this.state.title}
                                                        header={this.state.header}
                                                        data={this.state.excel}
                                                    />
                                                }
                                                fileName="SFALocation.pdf"
                                            >
                                                {({ blob, url, loading, error }) =>
                                                    loading ? "Loading document..." : "Download Pdf"
                                                }
                                                <button className="btn btn-warning">PDF</button>
                                            </PDFDownloadLink>
                                        ) : null}
                                        {this.state.data && this.state.data.length ? (
                                            <CSVLink
                                                data={this.state.data}
                                                headers={this.state.excelHead}
                                            >
                                                <button
                                                    className="btn btn-success"
                                                    style={{ marginLeft: "5px" }}
                                                >
                                                    Excel
                                                </button>
                                            </CSVLink>
                                        ) : null}
                                        <br></br>
                                        <br></br>

                                        <span>
                                            Total of {Object.keys(this.state.data).length} records
                                        </span>
                                        {this.state.loading ? <div className="text-center"> <i
                                            className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                                        ></i></div> : this.state.data ? (
                                            <Datatable
                                                data={this.state.data}
                                                columnHeading={this.column}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

export default DfCarsBranch;
