import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
// import DfCarsEnquiry from "..//DfCarsEnquiry";
import moment from "moment";
import Datatable from "../../../../components/Datatable/Datatable";
import FormModal from "../../../../components/Modal/FormModal";

class AccDocs extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      customerId: localStorage.getItem("userId"),
      userid: JSON.parse(localStorage.getItem("userlog")),
      EnquiryData: [],
      MakeOptions: [],
      Edit_Data: [],
      alertVisible: false,
      column: [
        {
          Header: "ID",
          width: 150,
          accessor: "doc_id"
        },
        {
          Header: "Prospect",
          accessor: "name"
        },
        {
          Header: "Date",
          accessor: "created_At"
        },
        {
          Header: "Contact",
          accessor: "mobile",
          Cell: d => this.Call(d.original.mobile)
        },
        {
          Header: "Make",
          accessor: "make",
          Cell: (d) => this.getValueFromArray(d.original.make, this.state.MakeOptions)
        },
        {
          Header: "evaluator name ",
          accessor: "evalutorname"
        },
        {
          Header: "Approved By",
          accessor: "approveduser"
        },

        {
          Header: "Documentation",
          accessor: "mobile",
          Cell: d => this.Document(d)
        },
        {
          Header: "Approval",
          accessor: "mobile",
          width: 170,
          Cell: d => this.Approval(d)
        },
        {
          Header: "View",
          accessor: "approval",
          width: 180,
          Cell: d => this.view_Approval(d)
        },
      ]
    };
  }

  Approval = d => {
    if (d.original.accounts_approval == 0) {
      return (
        <button
          type="button"
          style={{ minWidth: "150px", maxWidth: "150px" }}
          className="btn btn-sm btn-primary text-center"
          onClick={() => this.alert_validation(this.Approved_by_accouynts, d)}
        >
          Waiting for Approval
        </button>
      );
    }
    else if (d.original.accounts_approval == 1) {
      return (
        <button
          type="button"
          style={{ minWidth: "150px", maxWidth: "150px" }}
          className="btn btn-sm btn-success text-center"
        >
          Approved
        </button>
      );
    } else {
      return (
        <button
          type="button"
          style={{ minWidth: "150px", maxWidth: "150px" }}
          className="btn btn-sm btn-danger text-center"
          onClick={() => this.alert_validation(this.Approved_by_accouynts, d)}
        >
          Rejected by Accounts
        </button>
      );
    }
  };
  view_Approval = d => {
    return (
      <button
        type="button"
        className="btn btn-sm btn-primary"
        style={{ minWidth: "100px" }}
        onClick={() => this.opentable(d.original)}
      >
        View Details
      </button>
    )
  };
  opentable = async (d) => {
    if (d) {
      console.log(d, "d.original");
      await this.setState({ Edit_Data: d })
      window.$("#formmodel").modal("show")
    }
  }
  Document = d => {
    return (
      <button
        type="button"
        className="btn btn-sm btn-warning text-center"
        onClick={() => this.view_f_proofForm(d)}
      >
        View Form
      </button>
    );
  };
  view_f_proofForm = d => {
    window.open(
      `/DFCars/AT_Booklet_Purchase_Contract_Proof/${d.original.id}`,
      "_self"
    );
  };

  Approved_by_accouynts = async (d1, text = "yes") => {
    try {
      let d = d1.original;
      let approval = d1.original.approval;
      let sub = {};
      let userid = this.state.userid;
      sub.accounts_Approvedby = userid.id
      let AccountsApproval_Date = moment(new Date()).format('YYYY-MM-DD')
      if (text == "yes") {
        sub.accounts_approval = 1
        sub.AccountsApproval_Date = AccountsApproval_Date
      } else {
        sub.accounts_approval = 2
        sub.AccountsApproval_Date = AccountsApproval_Date
      }
      console.log("result", sub, d, text);
      const result = await cmsContent.updateMaster(
        "tbl_AT_Booklet_Purchase_Contract_Proof",
        d.doc_id,
        sub
      );

      if (result) {

        await this.Mainfunc()
      }
    } catch (error) {
      console.log(error);
    }

  };

  async componentDidMount() {
    try {
      await this.Mainfunc()
      let allOpt = await this.decryption('getcar')
      if (allOpt) {
        await this.setState({
          MakeOptions: allOpt.makeopt,
          coloropt: allOpt.coloropt,
          variantopt: allOpt.variantopt,
          modelopt: allOpt.modelopt
        })
      }

    } catch (error) {
      console.log(error);
    }
  }
  Mainfunc = async () => {
    try {
      this.setState({ loading: true })
      let results = await cmsContent.BUYDFCARZ(this.state.userid.id, 'AccountsDocs')
      if (results) {
        console.log(results, 'vvresults');
        await this.setState({ Data: results.buying, loading: false })
      }
    } catch (error) {
      console.log(error);
      this.setState({ loading: false })
    }
  }
  render() {
    let { Edit_Data } = this.state
    return (
      <React.Fragment>
        <FormModal
          extraStyle={{ width: 'auto' }}
          id={"formmodel"}
          bodyText={
            <div>
              {this.state.Edit_Data &&
                <table className="LeadTable">
                  <thead>
                    <tr class="LeadTable-head" style={{ background: "#07278f" }}>
                      <th class="column1">Type</th>
                      <th class="column2">Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Edit_Data.name && <tr>
                      <td class="column1">Name </td>
                      <td class="column2"> {Edit_Data.name} </td>
                    </tr>}
                    {Edit_Data.mobile && <tr>
                      <td class="column1">Mobile Number </td>
                      <td class="column2"> {this.Call(Edit_Data.mobile)} </td>
                    </tr>}
                    {Edit_Data.make && <tr>
                      <td class="column1">Make</td>
                      <td class="column2"> {this.getValueFromArray(Edit_Data.make, this.state.MakeOptions)} </td>
                    </tr>}
                    {Edit_Data.model && <tr>
                      <td class="column1">Model </td>
                      <td class="column2"> {this.getValueFromArray(Edit_Data.model, this.state.modelopt)} </td>
                    </tr>}
                    {Edit_Data.variant && <tr>
                      <td class="column1">Variant </td>
                      <td class="column2"> {this.getValueFromArray(Edit_Data.variant, this.state.variantopt)} </td>
                    </tr>}
                    {Edit_Data.color && <tr>
                      <td class="column1">Color </td>
                      <td class="column2"> {this.getValueFromArray(Edit_Data.color, this.state.coloropt)} </td>
                    </tr>}
                    {Edit_Data.jobroleName && <tr>
                      <td class="column1">Evaluator </td>
                      <td class="column2"> {Edit_Data.jobroleName} </td>
                    </tr>}
                    {Edit_Data.Auction_Status && <tr>
                      <td class="column1">Form Status</td>
                      <td class="column2">
                        <h4 className="" style={{ color: Edit_Data.Auction == 0 ? "#07278f" : Edit_Data.Auction == 1 ? "orange" : Edit_Data.Auction == 2 ? "green" : "red" }}>{Edit_Data.Auction_Status}</h4>
                      </td>
                    </tr>}
                    {Edit_Data.model && <tr>
                      <td class="column1">Manager Approved  </td>
                      <td class="column2"> {Edit_Data.approval == 1 ? "Approved" : Edit_Data.approval == 2 ? "Rejected" : Edit_Data.approval == 0 ? "In Progress" : ""} </td>
                    </tr>}

                  </tbody>
                </table>
              }
            </div>
          }
        />
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Documentation</h1>
                  </div>
                  <div className="card-body">
                    {this.state.loading ? <div className="text-center"> <i
                      className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                    ></i></div> : this.state.Data.length ? (
                      <Datatable
                        data={this.state.Data}
                        columnHeading={this.state.column}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
export default AccDocs;
