import React, { Component } from 'react';
import Icon from 'react-icons-kit';
import { mobilePhone } from 'react-icons-kit/fa/mobilePhone';
import { BrowserRouter as Router } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import login from '../../MiddleWare/Login';
import './Register.css';
export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertVisible: false,
      mobileNumber: '',
      errorMobileNumber: ''
    };
  }

  handleUserInput(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit = async () => {
    let mobileNumber = this.state.mobileNumber;
    if (mobileNumber === '') {
      this.setState({ errorMobileNumber: 'Enter Mobile Number' });
      return false;
    } else if (!/^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[6789]\d{9}$/.test(mobileNumber)) {
      this.setState({ errorMobileNumber: 'Invalid Mobile Number' });
      return false;
    } else {
      this.setState({
        errorMobileNumber: ''
      });
    }

    try {
      const result = await login.loginCheck(mobileNumber);
      console.log(result);
      if (result) {
        console.log(result.data);
        this.setState({ mobileNumber: '', errorMobileNumber: '' });
        localStorage.setItem('userlog', JSON.stringify(result.data[0]));
        let userlog = localStorage.getItem('userlog');
        userlog = JSON.parse(userlog);
        console.log(userlog)
        localStorage.setItem('userType', userlog.userType);
        if (parseInt(userlog.userType) === 1) {
          this.props.history.replace('/admin');
        } else if (parseInt(userlog.userType) === 2) {
          this.props.history.replace('/subAdmin');
        } else if (parseInt(userlog.userType) === 3) {
          //this.props.history.replace('/superAdmin');
          window.location.href = '/superAdmin';
        } else if (parseInt(userlog.userType) === 6 && parseInt(userlog.serviceId) === 8) {
          this.props.history.replace('/CMS/Home');
        } else if (parseInt(userlog.customerId) === 246 && parseInt(userlog.userType) === 6) {
          window.location.href = '/Crucible/dashborad';
        }
        else if (parseInt(userlog.serviceId) == 10 && parseInt(userlog.userType) != 2) {
          console.log(userlog)
          window.location.href = '/DFCars/UserDashboard';
        }
        else if (parseInt(userlog.serviceId) == 12) {
          console.log(userlog)
          window.location.href = '/R7/UserDashboard';
        }
      }
    } catch (error) {
      if (error.response.status === 404) {
        this.setState({ errorMobileNumber: 'Mobile Number does not exists' });

      }
      console.log(error);
    }
  };

  _handleKeyDown = e => {
    if (e.key === 'Enter') {
      this.handleSubmit();
    }
  };

  render() {
    return (
      <React.Fragment>
        <Router>
          <div className="app flex-row align-items-center">
            <Container>
              <Row className="justify-content-center">
                <Col md="9" lg="7" xl="6">
                  <Card className="mx-4 mt-15">
                    <CardHeader className="card-header-background">
                      <center>
                        <h2>Login</h2>
                      </center>
                    </CardHeader>
                    <CardBody className="p-4">
                      <InputGroup className={this.state.errorMobileNumber ? null : 'mb-3'}>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <Icon icon={mobilePhone} />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          name="mobileNumber"
                          placeholder="Mobile number"
                          maxlength="10"
                          autoComplete="Mobile number"
                          onChange={event => this.handleUserInput(event)}
                          value={this.state.mobileNumber}
                          onKeyDown={this._handleKeyDown}
                        />
                      </InputGroup>
                      <span className="mb-3 span-error">{this.state.errorMobileNumber}</span>
                      <Button type="button" color="primary card-header-background" onClick={this.handleSubmit} block>
                        Login
                      </Button>
                    </CardBody>
                    {/* <CardFooter className="p-4">
                      <Row>
                        <Col xs="12" sm="12">
                          <center>
                            Not have an account?
                            <Link to="/register">Register here</Link>
                          </center>
                        </Col>
                      </Row>
                    </CardFooter> */}
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </Router>
      </React.Fragment>
    );
  }
}
