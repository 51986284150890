import React, { Component } from 'react';
import { Alert } from "reactstrap";
import LoginModal from '../../../../components/Modal/Modal';
import CmsContent from "../../../../MiddleWare/CmsContent";
import Datatable from "../../../../components/Datatable/Datatable";
import FormMiddleWare from '../../../../components/Form/FormMiddleware';
import SingleSelect from '../../../../components/Form/SingleSelect'
import Icon from "react-icons-kit";
import { circle_ok } from 'react-icons-kit/ikons/circle_ok'
import { notepad_ok } from 'react-icons-kit/ikons/notepad_ok'
import { notepad_remove } from 'react-icons-kit/ikons/notepad_remove'
import moment from 'moment';

export default class TeleEnquiry extends FormMiddleWare {
    constructor(props) {
        super(props)
        this.state = {
            LocationID: localStorage.getItem(`LocationID`),
            User: JSON.parse(localStorage.getItem("userlog")),
            MakeOptions: [],
            coloropt: [],
            Editdata: [],
            variantopt: [],
            modelopt: [],
            dataval: [],
            loading: false,
            enquiry_option: [
                { label: "Not interested", value: 0 },
                { label: "Change to Live", value: 1 },
            ],
            enquiry_val: null,
            index: null,
            EditID: null,
            alertVisible: false,
        }
        // this.vals()
    }
    column = [
        {
            Header: "Name",
            accessor: "name"
        },
        {
            Header: "Mobile",
            accessor: "mobile",
            Cell: d => this.Call(d.original.mobile)
        },
        {
            Header: "Date",
            accessor: "createdAt"
        },
        {
            Header: "Make",
            accessor: "Make",
            Cell: (d) => this.getValueFromArray(d.original.Make, this.state.MakeOptions)
        },
        {
            Header: "Model",
            accessor: "Model",
            Cell: (d) => this.getValueFromArray(d.original.Model, this.state.modelopt)
        },
        {
            Header: "Variant",
            accessor: "Variant",
            Cell: (d) => this.getValueFromArray(d.original.Variant, this.state.variantopt)
        },
        {
            Header: "Color",
            accessor: "color",
            Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)
        },
        {
            Header: "Year",
            accessor: "yom"
        },
        {
            Header: "Close Status 1",
            accessor: "pendingStatus1"
        },
        {
            Header: "Close Status 2",
            accessor: "pendingStatus2"
        },
        {
            Header: "Type",
            accessor: "type",
            minWidth: 100,
            Cell: (d) => this.typebotton(d.original.type)
        },
        {
            Header: "Action",
            minWidth: 130,
            accessor: "button",
            Cell: (d) => this.modelview(d.original, d.original.id)
        }
    ]
    excelColumn = [
        {
            label: "Name",
            key: "name"
        },
        {
            label: "Mobile",
            key: "mobile",
        },
        {
            label: "Date",
            key: "createdAt"
        },
        {
            label: "Make",
            key: "makeName",
        },
        {
            label: "Model",
            key: "modelName",
        },
        {
            label: "Variant",
            key: "variantName",
        },
        {
            label: "Color",
            key: "colourName",
        },
        {
            label: "Year",
            key: "yom"
        },
        {
            label: "Close Status 1",
            key: "pendingStatus1"
        },
        {
            label: "Close Status 2",
            key: "pendingStatus2"
        },
    ]
    vals = async () => {
        let allOpt = await this.decryption('getcar')
        if (allOpt) {
            await this.setState({
                MakeOptions: allOpt.makeopt,
                coloropt: allOpt.coloropt,
                variantopt: allOpt.variantopt,
                modelopt: allOpt.modelopt
            })
        }
    }
    async componentDidMount() {
        console.log(this.state.User);
        await this.vals()
        await this.mainFun()
    }
    typebotton = (d) => {
        if (d == "buyer") {
            return (
                <button style={{ width: "80px" }} type="button" className={`btn btn-warning`}>
                    Buyer
                </button>
            )
        }
        else {
            return (
                <button style={{ width: "80px" }} type="button" className={`btn btn-danger`}>
                    Seller
                </button>
            )
        }
    }
    mainFun = async () => {
        this.setState({ loading: true })
        try {
            let { User } = this.state
            console.log("MAINFUNC");
            let body = {
                user: User.id,
                downline: User.downline,
                location: User.locationid
            }
            let results1 = await CmsContent.BUYDFCARZ(User.id, 'closedenquiries', body)
            if (results1) {
                this.setState({
                    EnquiryList: results1.EnquiryList, LeadList: results1.LeadList, loading: false
                })
            }
        } catch (error) {
            this.setState({ loading: false })
            console.log(error);
        }
    }
    cng = e => { this.setState({ enquiry_val: e }) }
    submit = async (id, d) => {
        let CategoryArray = {}
        let CategoryArray1 = {}
        let result = null
        try {
            this.setState({ btnDisable: true })
            if (this.state.DallValue && this.state.DallValue.leadStatus == "Mark Closed" &&
                this.state.enquiry_val && this.state.enquiry_val.value == 0) {
                if (this.state.enquiry_val.value == 0) {
                    CategoryArray1.LeadStatus = "Close"
                    let result1 = await CmsContent.updateMaster(
                        "tbl_dfcars_lead",
                        this.state.DallValue.Lead_id,
                        CategoryArray1)
                }

            } else if (this.state.DallValue && this.state.DallValue.leadStatus == "Mark Closed" &&
                this.state.enquiry_val && this.state.enquiry_val.value == 1) {
                CategoryArray1.LeadStatus = "Inactive"
                CategoryArray1.status = null
                CategoryArray1.followup = null
                let result1 = await CmsContent.updateMaster(
                    "tbl_dfcars_lead",
                    this.state.DallValue.Lead_id,
                    CategoryArray1)

            }
            CategoryArray.telecall_status = this.state.enquiry_val.value
            if (this.state.enquiry_val.value == 1) {
                CategoryArray.Next_Contact_date = this.state.Next_date
                CategoryArray.pendingStatus1 = (this.state.DallValue.type == 'seller') ? "Scheduled Follow up" : "Yes, Interested in Buying"
                CategoryArray.pendingStatus2 = (this.state.DallValue.type == 'seller') ? "Under Follow up" : "Schedule follow up"
                CategoryArray.evaluatorid = null
                CategoryArray.salesid = null
                CategoryArray.pendingStatus3 = null
            }
            if (this.state.enquiry_val.value == 0) {
                CategoryArray.status = "Inactive"
            }
            result = await CmsContent.updateMaster(
                "tbl_dfcarz_enquiryform",
                id,
                CategoryArray)
            console.log(result, "result");

            if (result) {
                this.setState({ btnDisable: false, alertVisible: true })
                setTimeout(() => {
                    this.setState({ alertVisible: false })
                    window.location.reload()
                }, 2000);
            }

        } catch (error) {
            console.log(error);
            this.setState({ btnDisable: false })
        }
    }
    reset = (d, d1) => {
        let EditID = d
        // let Editdata = d
        let enquiry_val = null
        this.setState({ EditID, Next_date: null, enquiry_val, DallValue: d1, Editdata: d1 })
    }
    modelview = (d, index) => {
        // console.log(d, index);
        let btnval = ""
        let btnclass = ""
        if (d.telecall_status === null) { btnval = "Action"; btnclass = "btn btn-primary" }
        else if (d.telecall_status == ("0" || 0)) { btnval = "Not interested"; btnclass = "btn btn-danger" }
        else if (d.telecall_status == ("1" || 1)) { btnval = "Action"; btnclass = "btn btn-warning" }
        if (index) {
            return (<div>
                <LoginModal
                    buttonTitle={btnval}
                    title="Enquiry"
                    id='model'
                    extraClass={btnclass}
                    buttonStyle={{ width: "100px" }}
                    // extraStyle={{ width: 'auto' }}
                    onClick={() => this.reset(index, d)}
                    bodyText={
                        <div>
                            <div className="card-body">
                                <div className="row form-group">
                                    <div className="col-sm-2" />
                                    <div className="col-sm-7">
                                        <Alert
                                            className="badge-content"
                                            color={'success'}
                                            isOpen={this.state.alertVisible}
                                            toggle={this.onDismiss}
                                        >
                                            Form successfully submited
                                        </Alert>
                                    </div>
                                    <div className="col-sm-3" />
                                </div>
                                <div className="row form-group">
                                    <div className="col-sm-1">  </div>
                                    <div className="col-sm-3"> Status : </div>
                                    <div className="col-sm-6">
                                        <SingleSelect
                                            options={this.state.enquiry_option}
                                            handleChange={e => this.cng(e)}
                                            selectedService={this.state.enquiry_val}
                                        />
                                    </div>
                                    {/* {this.state.enquiry_val != null ?
                                        <div className="col-sm-4">
                                            <button className="btn btn-primary" disabled={this.state.btnDisable}
                                                onClick={() => this.submit(this.state.EditID, d)}>
                                                {this.state.btnDisable ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;Submit</button>
                                        </div> : null} */}
                                </div>
                                {this.state.enquiry_val && this.state.enquiry_val.value == 1 ?
                                    < div className="row form-group">
                                        <div className="col-sm-1">  </div>
                                        <div className="col-sm-3"> Next followup date : </div>
                                        <div className="col-sm-6">
                                            <input
                                                type="date"
                                                className="form-control"
                                                id="ColourName"
                                                name="followup"
                                                min={moment(new Date()).format("YYYY-MM-DD")}
                                                value={this.state.Next_date}
                                                onChange={(e) => { this.setState({ Next_date: e.target.value }); console.log(e.target.value) }}
                                            />
                                        </div>
                                    </div> : ''}
                                < div className="row form-group">
                                    <div className="col-sm-12">
                                        {this.state.enquiry_val != null ?
                                            (this.state.Next_date && this.state.enquiry_val.value == 1) || (this.state.enquiry_val.value == 0) ?
                                                < center >
                                                    <button className="btn btn-primary" disabled={this.state.btnDisable}
                                                        onClick={() => this.submit(this.state.EditID, d)}>
                                                        {this.state.btnDisable ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;Submit</button>
                                                </center> : null : null}
                                    </div>
                                </div>
                            </div>
                            {this.state.Editdata && this.state.Editdata.telecall_status == 1 && <div className='' style={{ fontSize: "unset", fontWeight: "500", color: "red" }}>This Enquiry has already come up once..</div>
                            }
                        </div>
                    }
                />
            </div >
            )
        }
    }

    onClk = (val) => {
        if (val === 1) { this.setState({ Data: this.state.EnquiryList, column: this.column }) }
        if (val === 2) { this.setState({ Data: this.state.LeadList, column: this.column }) }
        window.scroll({
            top: 400,
            left: 0,
            behavior: "smooth",
        });
    }
    edash = () => {
        return (<div className="">
            <div className="card">
                {/* < div className="row" > */}
                <div className="card-header">
                    <div className="col-sm-6" >
                        <h2 className="mt-2 black">Dashboard</h2>
                    </div>
                </div>
                <div className="card-body">
                    <div className="">
                        <div className="row col-12 mt-4 text-center">
                            <div className="col">
                                <div className="dashboardCard1" onClick={() => this.onClk(1)} >
                                    <Icon icon={notepad_ok} style={{ color: 'green' }} size={35} />
                                    <h2 className="mt-2 black" >Enquires</h2>
                                    <div >
                                        <h1 className="bold mt-4 mb-2 black"><b>{this.state.EnquiryList && this.state.EnquiryList ? this.state.EnquiryList.length : 0}</b></h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col" onClick={() => this.onClk(2)} >
                                <div className="dashboardCard1">
                                    <Icon icon={notepad_remove} size={35} style={{ color: 'red' }} />
                                    <h2 className="mt-2 black" >Lead</h2>
                                    <div>
                                        <h1 className="bold mt-4 mb-2 black" ><b>{this.state.LeadList && this.state.LeadList.length ? this.state.LeadList.length : 0}</b></h1>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col" onClick={() => this.onClk(3)} >
                                <div className="dashboardCard1">
                                    <Icon icon={circle_ok} size={35} style={{ color: 'green' }} />
                                    <h2 className="mt-2 black">MTD</h2>
                                    <div>
                                        <h1 className="bold mt-4 mb-2 black"><b>{this.state.dasboard && this.state.dasboard[0].approved ? this.state.dasboard[0].approved : 0}</b></h1>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
    render() {
        const { Data } = this.state
        return (
            <main className="main my-4">
                <div className="container-fluid">
                    {this.edash()}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h1>Enquiry Report</h1>
                                </div>
                                <div className="card-body">
                                    <div className="row form-group"></div>
                                    {this.state.loading ? <div className="text-center"> <i
                                        className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                                    ></i></div> : Data && Data.length > 0 ?
                                        <Datatable data={this.state.Data} columnHeading={this.state.column}
                                        excelColumn={this.excelColumn} /> : (<div style={{ textAlign: "center" }} ><b>No Data Found</b></div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>)
    }
}