import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import moment from "moment";
import Datatable from "../../../../components/Datatable/Datatable";
import CmsContent from "../../../../MiddleWare/CmsContent";
import Fproof from "../Form/Evaluation_Checklist_F_Proof";
import Bproof from "../Form/Evaluation_Checklist_B_Proof";
import Documentation from "../Form/AT_Booklet_Purchase_Contract_Proof";
import Inventory from "../Form/AT_Inventory_Sheet_Reproof";
import Seller from "../Form/Delivery_Note_Seller_Proof";
import Refurbishment from "../Form/CERTIFICATION_350_CheckPoints";
import Autoterrace from "../Form/Autoterrace_enquiry_form";
import BookConfirm from "../Form/AT_Booking_Confirmation_Reproof";
import Buyer from "../Form/AT_Delivery_Note_Buyer_Reproof";

class ProductViewmore extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      customerId: localStorage.getItem("userId"),
      EnquiryData: [],
      alertVisible: false,
      fproof: false,
      bproof: false,
      document: false,
      invent: false,
      seller: false,
      auto: false,
      confirm: false,
      buyer: false,
      refurb: false,



      column: [
        {
          Header: "Registration No",
          accessor: "Reg_no"
        },
        {
          Header: "Make",
          accessor: "make"
        },
        {
          Header: "Model",
          accessor: "model"
        },
        {
          Header: "Variant",
          accessor: "variant"
        },
        {
          Header: "Colour",
          accessor: "color"
        },
        {
          Header: "CreatedAt",
          accessor: "createdAt"
        },
      ]
    }
  }

  async componentDidMount() {
    //console.log(this.props);
    let id = this.props.match.params.id;
    console.log(id)

    const result = await CmsContent.getFreedom("*", "tbl_dfcarz_prosperities", `id=${id}`, 1, 1)

    if (result) {
      console.log(result.data)
      this.setState({ name: result.data[0].name, type: result.data[0].type, id: result.data[0].id })
      this.setState({ EnquiryData: result.data });
    }
  }

  fproof = () => {
    this.setState({ fproof: true, bproof: false, document: false, invent: false, seller: false, refurb: false })
  }
  bproof = () => {
    this.setState({ bproof: true, fproof: false, document: false, invent: false, seller: false, refurb: false })
  }
  document = () => {
    this.setState({ fproof: false, bproof: false, document: true, invent: false, seller: false, refurb: false })
  }
  invent = () => {
    this.setState({ fproof: false, bproof: false, document: false, invent: true, seller: false, refurb: false })
  }
  seller = () => {
    this.setState({ fproof: false, bproof: false, document: false, invent: false, seller: true, refurb: false })
  }
  refurb = () => {
    this.setState({ fproof: false, bproof: false, document: false, invent: false, seller: false, refurb: true })
  }
  changestate = (a, s) => {
    console.log(a)
    this.setState({})
  }
  auto = () => {
    this.setState({ auto: true, confirm: false, buyer: false })
  }
  bookconfirm = () => {
    this.setState({ auto: false, confirm: true, buyer: false })
  }
  buyer = () => {
    this.setState({ auto: false, confirm: false, buyer: true })
  }
  render() {
    const { id } = this.state;
    console.log("fpor", this.state.fproof)
    console.log('bpro', this.state.bproof)
    return (<React.Fragment>
      <main className="main my-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h1>Transaction Summary of - {this.state.name}</h1>
                </div>
                <div className="card-body">
                  {this.state.type == "seller" && (
                    <ul class="nav nav-tabs">
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          data-toggle="tab"
                          href="#fproof"
                          onClick={() => this.fproof()}
                        >
                          F-Proof
                            </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-toggle="tab"
                          href="#bproof"
                          onClick={() => this.bproof()}
                        >
                          B-Proof
                            </a>

                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-toggle="tab"
                          href="#documentaion"
                          onClick={() => this.document()}
                        >
                          Documentation
                            </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-toggle="tab"
                          href="#inventory"
                          onClick={() => this.invent()}
                        >
                          Inventory
                            </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-toggle="tab"
                          href="#seller"
                          onClick={() => this.seller()}
                        >
                          Delivery Seller
                            </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-toggle="tab"
                          href="#refurb"
                          onClick={() => this.refurb()}
                        >
                          Refurbishment
                            </a>
                      </li>
                    </ul>
                    // <ul class="nav nav-tabs" id="myTab" role="tablist">
                    //   <li class="nav-item waves-effect waves-light">
                    //     <a class="nav-link" id="home-tab" data-toggle="tab" href="#fproof" role="tab" aria-controls="home" aria-selected="false">F-proof</a>
                    //   </li>
                    //   <li class="nav-item waves-effect waves-light">
                    //     <a class="nav-link" id="profile-tab" data-toggle="tab" href="#bproof" role="tab" aria-controls="profile" aria-selected="false">B-Proof</a>
                    //   </li>
                    //   <li class="nav-item waves-effect waves-light">
                    //     <a class="nav-link" id="doc-tab" data-toggle="tab" href="#document" role="tab" aria-controls="contact" aria-selected="true">Documentation</a>
                    //   </li>
                    //   <li class="nav-item waves-effect waves-light">
                    //     <a class="nav-link" id="contact-tab" data-toggle="tab" href="#invent" role="tab" aria-controls="contact" aria-selected="true">Inventory Sheet</a>
                    //   </li>
                    //   <li class="nav-item waves-effect waves-light">
                    //     <a class="nav-link" id="contact-tab" data-toggle="tab" href="#seller" role="tab" aria-controls="contact" aria-selected="true">Delivery Note Seller</a>
                    //   </li>
                    //   <li class="nav-item waves-effect waves-light">
                    //     <a class="nav-link" id="contact-tab" data-toggle="tab" href="#refurb" role="tab" aria-controls="contact" aria-selected="true">Refurbishment</a>
                    //   </li>
                    // </ul>
                  )}
                  {this.state.type == "buyer" && (
                    <ul class="nav nav-tabs">
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-toggle="tab"
                          href="#auto"
                          onClick={() => this.auto()}
                        >
                          Sourcing
                            </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-toggle="tab"
                          href="#confirm"
                          onClick={() => this.bookconfirm()}
                        >
                          Contract
                            </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-toggle="tab"
                          href="#buyer"
                          onClick={() => this.buyer()}
                        >
                          Delivery Buyer
                            </a>
                      </li>
                    </ul>
                  )}
                  {this.state.fproof == true && (
                    <div>
                      <div class="tab-content">
                        <div id="fproof" class="container tab-pane active" style={{
                          marginLeft: "-213px",
                          width: "1028px"
                        }}>
                          <Fproof
                            cusid={id}
                            these={this.changestate}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.bproof == true && (
                    <div>
                      <div class="tab-content">
                        <div id="bproof" class="container tab-pane active" style={{
                          marginLeft: "-213px",
                          width: "1028px"
                        }}>
                          <Bproof
                            cusid={id}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.document && (
                    <div>
                      <div class="tab-content">
                        <div id="documentaion" class="container tab-pane active" style={{
                          marginLeft: "-213px",
                          width: "1028px"
                        }}>
                          <Documentation
                            cusid={id}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.invent && (
                    <div>
                      <div class="tab-content">
                        <div id="inventory" class="container tab-pane active" style={{
                          marginLeft: "-213px",
                          width: "1028px"
                        }}>
                          <Inventory
                            cusid={id}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.seller && (
                    <div>
                      <div class="tab-content">
                        <div id="seller" class="container tab-pane active" style={{
                          marginLeft: "-213px",
                          width: "1028px"
                        }}>
                          <Seller
                            cusid={id}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.refurb && (
                    <div>
                      <div class="tab-content">
                        <div id="refurb" class="container tab-pane active" style={{
                          marginLeft: "-213px",
                          width: "1028px"
                        }}>
                          <Refurbishment
                            cusid={id}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.auto && (
                    <div>
                      <div class="tab-content">
                        <div id="auto" class="container tab-pane active" style={{
                          marginLeft: "-213px",
                          width: "1028px"
                        }}>
                          <Autoterrace
                            cusid={id}

                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.confirm && (
                    <div>
                      <div class="tab-content">
                        <div id="confirm" class="container tab-pane active" style={{
                          marginLeft: "-213px",
                          width: "1028px"
                        }}>
                          <BookConfirm
                            cusid={id}

                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.buyer && (
                    <div>
                      <div class="tab-content">
                        <div id="buyer" class="container tab-pane active" style={{
                          marginLeft: "-213px",
                          width: "1028px"
                        }}>
                          <Buyer
                            cusid={id}

                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment >)
  }

}
export default ProductViewmore;
