


import React, { Component } from 'react';
import CmsContent from '../../../../MiddleWare/CmsContent';
import Datatable from "../../../../components/Datatable/Datatable";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import cmsContent from "../../../../MiddleWare/CmsContent";
import { Link, PDFDownloadLink } from '@react-pdf/renderer';
import { CSVLink } from 'react-csv';
import SFAPDF from '../../../../components/PDF/SFAPDF';
import moment from 'moment';

class DfCarAdminAppointment extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            themeId: null,
            changenumber: null,
            fullData: [],
            excelHead: [],
            title: "Steer Auto Appointment",
            index: null,
            header: ["ID", "NAME", "MOBILE", "EMAIL", "ADDRESS", "APPOINTMENT TYPE", "VISIT TYPE", "LOCATION", "BRANCH", "DATE", "IP"],
            alertVisible: false,
            customerId: localStorage.getItem("userId"),
            column: [
                {
                    Header: "ID",
                    accessor: "id",
                    minWidth: 40,
                },
                {
                    Header: "NAME",
                    accessor: "name"
                },
                {
                    Header: "MOBILE",
                    accessor: "mobile"
                },
                {
                    Header: "EMAIL",
                    accessor: "email"
                },
                {
                    Header: "ADDRESS",
                    accessor: "address"
                },
                {
                    Header: "Appointment Type",
                    accessor: "appointment_type_name"
                },
                {
                    Header: "Visit Type",
                    accessor: "visit_name"
                },
                {
                    Header: "location",
                    accessor: "location"
                },
                {
                    Header: "branch",
                    accessor: "branch"
                },
                {
                    Header: "Date",
                    accessor: "Date"
                },
                {
                    Header: "Request Type",
                    accessor: "type",
                    minWidth: 150,
                },
                // {
                //     Header: "ip",
                //     accessor: "ip"
                // },

            ],
        };
    }

    async componentDidMount() {
        this.MainFun()
    }
    MainFun = async () => {
        try {
            this.setState({ loading: true })
            let location = await CmsContent.getFreedom(
                "name as label , id as value",
                "tbl_location",
                `serviceid = 10 and customerId = 620 and status = "active"`,
                1,
                1
            );
            let branch = await CmsContent.getFreedom(
                "name as label ,id as value,locationId as location",
                "tbl_dfcars_Branch",
                `serviceid = 10 and customerId = 620 and status = "active"`,
                1,
                1
            );
            console.log(location, "locbranch", branch);
            let result = await cmsContent.getFreedom(
                "*",
                "tbl_dfcars_Appointment",
                1,
                1,
                "id desc")
            if (result) {
                let wait1 = await result.data.map((ival) => {
                    if (!ival.email) {
                        ival.email = "-"
                    }
                    if (!ival.address) {
                        ival.address = "-"
                    }
                    if (ival.appointment_type == "1") {
                        ival.appointment_type_name = "Buy Cars"
                    }
                    else if (ival.appointment_type == "2") {
                        ival.appointment_type_name = "Sell Cars"
                    }
                    if (ival.visit_type == "1") {
                        ival.visit_name = "Home Visit"
                    }
                    if (ival.visit_type == "2") {
                        ival.visit_name = "Branch Visit"
                    }
                    if (ival.type == "1") {
                        ival.type = "Schedule Visit"
                    }
                    if (ival.type == "2") {
                        ival.type = "Quick Quote"
                    }
                    if (ival.Date) {
                        ival.Date = moment(ival.Date).format("DD-MM-YYYY-hh-mm-a")
                    }

                })
                let wait2 = await result.data.map((ival) => {
                    location.data.map((jval) => {
                        if (ival.location == jval.value) {
                            ival.location = jval.label
                        }
                    })
                })
                let wait3 = await result.data.map((ival) => {
                    branch.data.map((jval) => {
                        if (ival.branch == jval.value) {
                            ival.branch = jval.label
                        }
                    })
                })
                await Promise.all(wait1, wait2, wait3)
                const header = ["ID", "NAME", "MOBILE", "EMAIL", "ADDRESS", "APPOINTMENT TYPE", "VISIT TYPE", "LOCATION", "BRANCH", "DATE", "IP", "REQUEST TYPE"];
                const excelHead = [
                    {
                        label: "Id ",
                        key: "id",
                    },
                    {
                        label: "NAME",
                        key: "name"
                    },
                    {
                        label: "MOBILE",
                        key: "mobile"
                    },
                    {
                        label: "EMAIL",
                        key: "email"
                    },
                    {
                        label: "ADDRESS",
                        key: "address"
                    },
                    {
                        label: "Appointment Type",
                        key: "appointment_type_name"
                    },
                    {
                        label: "Visit Type",
                        key: "visit_name"
                    },
                    {
                        label: "location",
                        key: "location"
                    },
                    {
                        label: "branch",
                        key: "branch"
                    },
                    {
                        label: "Date",
                        key: "Date"
                    },
                    {
                        label: "ip",
                        key: "ip"
                    },
                    {
                        label: "Request Type",
                        key: "type"
                    }
                ];
                await this.setState({ excel: result.data, fullData: result.data, excelHead, title: "Steer Auto Appointment", header })
                this.setState({ loading: false })

            }
        } catch (err) {
            console.log(err);
            this.setState({ loading: false })
        }
    }
    render() {
        const { fullData, alertVisible, title, excelHead, excel } = this.state;
        return (
            <main className="main my-4">
                <main className="container-fluid">

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h1>Appointment</h1>
                                </div>
                                <div className="row form-group">
                                    <div className="col-sm-2" />
                                    <div className="col-sm-7">

                                    </div>
                                    <div className="col-sm-3" />
                                </div>
                                <div className="card-body">
                                    {this.state.fullData && this.state.fullData.length ? (
                                        <CSVLink
                                            filename={`Steer Auto Appointment(${moment().format("DD-MM-YYYY hh:mm a")}).csv`}
                                            data={this.state.fullData}
                                            headers={this.state.excelHead}
                                        >
                                            <button
                                                className="btn btn-success"
                                                style={{ marginLeft: "5px" }}
                                            >
                                                Excel
                                            </button>
                                        </CSVLink>
                                    ) : null}
                                    <br />

                                    <br />
                                    <br />
                                    {this.state.loading ? <div className="text-center"> <i
                                        className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                                    ></i></div> : fullData && fullData.length ? (
                                        <Datatable data={fullData} columnHeading={this.state.column} />
                                    ) : <div className="text-center">No data Found</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </main>
        );
    }
}

export default DfCarAdminAppointment;