import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import DfCarsImageUpload from "./DfCarsImageUpload"
import moment from "moment";
import LoginModal from "../../../../components/Modal/Modal";
import Datatable from "../../../../components/Datatable/Datatable";
import { ACCESS_POINT, ImgSTEERAUTO } from "../../../../config";
import { Carousel } from 'react-responsive-carousel';
import certified2 from "../../DifuzaCars/certified3.png"
import certified1 from "../../DifuzaCars/certified2.png"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Icon from "react-icons-kit";
import { ic_check_box } from 'react-icons-kit/md/ic_check_box'
import { shuffle } from "react-icons-kit/ikons/shuffle";
import { tick } from 'react-icons-kit/ikons/tick'
import { close } from 'react-icons-kit/ikons/close'
import { shopping_cart_ok } from 'react-icons-kit/ikons/shopping_cart_ok'
import { clock } from 'react-icons-kit/iconic/clock'
import { SingleSelect } from "../../../../components/Form";
import { cog } from "react-icons-kit/ikons/cog";
import { colors } from "react-select/lib/theme";


class MyInventory extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            All_Cars: [],
            Sold_Cars: [],
            Contract_Cars: [],
            Move_sit: [],
            Rf_Cars: [],
            filterdata: [],
            Car_Content: [],
            leadsavailable: [],
            customerId: localStorage.getItem("userId"),
            userid: JSON.parse(localStorage.getItem("userlog")),
            EnquiryData: [],
            isView: false,
            alertVisible: false,
            column: [
                {
                    Header: "REG NO",
                    accessor: "reg_no"

                },
                {
                    Header: "MAKE",
                    accessor: "makename"
                },
                {
                    Header: "MODEL",
                    accessor: "modelname"
                },
                {
                    Header: "YEAR",
                    accessor: "make_year"
                },
                // {
                //     Header: "KMS",
                //     accessor: "kms"
                // },
                {
                    Header: "COLOUR",
                    accessor: "ColourName"
                },
                {
                    Header: "Date",
                    accessor: "created_At"
                },
                {
                    Header: "OWNER_TYPE",
                    accessor: "owner_type"
                },
                {
                    Header: "PRICE",
                    accessor: "price"
                },
                // {
                //     Header: "UPLOAD IMAGE",
                //     accessor: "price",
                //     Cell: d => this.Image(d,"image")
                // },
                {
                    Header: "VIEW IMAGE",
                    minWidth: 100,
                    accessor: "price",
                    className: "text-center",
                    Cell: d => this.Image(d, "view_Image")
                },
                {
                    Header: "STATUS",
                    accessor: "status",
                    Cell: d => this.Status(d),
                    className: "text-center",
                    minWidth: 100,

                },
                // {
                //     Header: "DETAILS",
                //     minWidth: 120,
                //     accessor: "Movetosite",
                //     Cell: d => this.Details(d)
                // },
                // {
                //     Header: "Leads",
                //     accessor: "",
                //     Cell: d => this.leads(d)
                // },
                // {
                //     Header: "Share",
                //     accessor: "",
                //     Cell: d => this.Share(d)
                // }
            ],
            EditData: {},
            Index: null
        }
    }

    Image = (d, name) => {
        if (name == "image") {
            return (
                <button type="button" className="btn btn-primary btn-sm"
                    data-toggle="modal" data-target={"#images"}
                    onClick={() => this.DataPass(d)}
                >Upload</button>
            )
        }
        else if (name == "view_Image") {
            return (
                <button type="button" className="btn btn-warning btn-sm"
                    style={{ minWidth: "130px" }}
                    data-toggle="modal" data-target={"#Viewimage_modal"}
                    onClick={() => this.view_Image(d)}
                >View Image</button>
            )
        } else {
            return (
                <button type="button" className="btn btn-warning btn-sm"
                    style={{ minWidth: "130px" }}
                    data-toggle="modal" data-target={"#Viewimage_modal"}
                    onClick={() => this.view_Image(d)}
                >View Image</button>
            )
        }
    }
    view_Image = async (d) => {
        try {
            let EditData = d.original
            let Index = d.index
            let Car_Content = []
            console.log(EditData, "EditData");
            if (EditData && EditData.source == "site") {
                // EditData.map(async (ival, j) => {
                if (EditData && EditData.Cimages && EditData.Cimages.length > 0) {
                    Car_Content.push(
                        <div class="flexslider">
                            <ul class="slides" >
                                <div class="carousel-wrapper" style={{ border: "1px solid #00000012" }}>
                                    <Carousel
                                        arrow={false}
                                        useKeyboardArrows={true}
                                        thumbWidth={100}
                                        swipeable={true}
                                        showThumbs={true}
                                        showIndicators={false}
                                        // showThumbs={true}
                                        infiniteLoop={true} autoPlay={true}
                                        style={{
                                            position: "absolute", objectFit: "contain", maxHeight: '343px', border: "1px solid"
                                        }}>
                                        {EditData.Cimages && EditData.Cimages.length ? EditData.Cimages.map((mval, m) => {
                                            return (
                                                <div style={{ color: "#fff" }} id="singlecarlistimg">
                                                    {EditData && EditData.Certified && EditData.Certified == 1 &&
                                                        <div>
                                                            <img
                                                                id="certifiedimg"
                                                                className='d-flex justify-content-between'
                                                                style={{ position: "fixed", width: "70px" }}
                                                                src={certified2}
                                                                onError={({ currentTarget }) => {
                                                                    currentTarget.onerror = null; // prevents looping
                                                                    currentTarget.src = certified1;
                                                                }} />
                                                        </div>
                                                    }
                                                    <img src={ImgSTEERAUTO + mval.image}
                                                        alt={`${EditData.makename} ${EditData.modelname}`}
                                                        style={{ objectFit: "contain", maxHeight: '343px', width: "100%", borderRadius: "10px" }}

                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null; // prevents looping
                                                            currentTarget.src = "https://steerimages.difuza.com/DFCARS_daa63c54_4b15d8d6_1662965206825.jpg";
                                                        }}
                                                    />
                                                    {EditData.source == "site" &&
                                                        <h2 style={{ fontWeight: "600", color: "#07278f", fontFamily: "sans-serif", float: "center", textTransform: "uppercase", fontSize: "16px" }}>{mval && mval.imagesname && mval.imagesname}</h2>
                                                    }
                                                </div>
                                            )
                                        }) : (
                                            <div style={{ height: "350px", color: "#fff" }}>
                                                <li >
                                                    <img src="https://steerimages.difuza.com/DFCARS_daa63c54_4b15d8d6_1662965206825.jpg"
                                                        // style={{ minHeight: "350px", maxHeight: "350px" }}
                                                        style={{ objectFit: "contain", maxHeight: '343px', width: "100%", borderRadius: "10px" }}
                                                        data-webp=''
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null; // prevents looping
                                                            currentTarget.src = "https://steerimages.difuza.com/DFCARS_daa63c54_4b15d8d6_1662965206825.jpg";
                                                        }} />

                                                </li>
                                            </div>)
                                        }
                                    </Carousel>
                                </div>
                            </ul>
                        </div>
                    )
                }
                else {
                    Car_Content.push(<>
                        <h2 style={{ fontWeight: "600", color: "#07278f", fontFamily: "sans-serif", float: "center", textTransform: "uppercase", fontSize: "16px" }}>Image Not Found..</h2>
                    </>
                    )
                }
                // })
            } else {
                let image = JSON.parse(EditData.filesArray)
                let img_Contetnt = []
                let w = Object.keys(image).map((key, i) => {
                    if (image[key] && image[key].length) {
                        image && image[key].map((ival) => {
                            img_Contetnt.push(<>
                                <img
                                    src={`${ACCESS_POINT}/superAdmin/file?fileurl=${ival}`}
                                    style={{ objectFit: "contain", maxHeight: '343px', width: "100%", borderRadius: "10px" }}
                                    onClick={() => window.open(`${ACCESS_POINT}/superAdmin/file?fileurl=${ival}`)}
                                    alt="Error"
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = `https://steerimages.difuza.com/DFCARS_daa63c54_4b15d8d6_1662965206825.jpg`
                                    }}
                                ></img>
                            </>
                            )

                        })
                    }
                })
                console.log(img_Contetnt, "img_Contetnt");
                if (img_Contetnt && img_Contetnt.length > 0) {
                    Car_Content.push(<>
                        <div class="flexslider">
                            <ul class="slides" >
                                <div class="carousel-wrapper" style={{ border: "1px solid #00000012" }}>
                                    <Carousel
                                        arrow={false}
                                        useKeyboardArrows={true}
                                        thumbWidth={100}
                                        swipeable={true}
                                        showThumbs={true}
                                        showIndicators={false}
                                        // showThumbs={true}
                                        infiniteLoop={true} autoPlay={true}
                                        style={{
                                            position: "absolute", objectFit: "contain", maxHeight: '343px', border: "1px solid"
                                        }}>

                                        {img_Contetnt}
                                    </Carousel>
                                </div>
                            </ul>
                        </div>

                    </>
                    )
                } else {
                    Car_Content.push(<>
                        <h2 style={{ fontWeight: "600", color: "#07278f", fontFamily: "sans-serif", float: "center", textTransform: "uppercase", fontSize: "16px" }}>Image Not Found..</h2>
                    </>
                    )
                }
            }
            await this.setState({ Car_Content, EditData })
        } catch (error) {
            console.log(error);
        }
    }
    leads = (d) => {
        if (this.state.leadsavailable.length) {
            // console.log("item", item);
            return (<div><button type="button" className="btn btn-primary btn-sm"
                onClick={() => this.lead(d)}
            >View leads</button></div>)
        } else {
            return (
                <div><span className="label" style={{ backgroundColor: "white" }}></span></div>)
        }
    }
    lead = (d) => {
        let location = ""
        if (d.original.location === null) {
            location = localStorage.getItem("LocationID")
            window.open(`/DFCars/MyInventory/${d.original.make}/${d.original.model}/${location}`)
        } else {
            window.open(`/DFCars/MyInventory/${d.original.make}/${d.original.model}/${d.original.location}`)
        }
    }

    DataPass = async (d) => {
        this.setState({ EditData: d.original, Index: d.index })
    }
    Share = (d) => {
        return (<button type="button" className="btn btn-link btn-sm"
            onClick={() => this.Share1(d)}
        >share</button>)

    }
    Share1 = (d) => {
        window.open(`https://difuza.com/DFCars/Cardata/${d.original.id}`)
    }

    Movetosite = async (d) => {
        try {
            let body = d.original
            let Movetosite = await cmsContent.DFcarsMovedtosite("tbl_items", body)

            console.log(Movetosite);
            if (Movetosite) {
                this.Mainfunc();
            }

        } catch (err) { console.log(err); }
        this.Mainfunc();

    }
    Mainfunc = async () => {
        try {
            let { customerId, userid } = this.state
            //let  userid = this.state
            let result = await cmsContent.getFreedom(
                "tbl_Dfcars_Garage.*,tbl_make_master.Make as makename,tbl_dfcarsmodel.Model as modelname ,tbl_colour.colour as ColourName,tbl_Dfcars_Garage.Reg_no as reg_no",
                `tbl_Dfcars_Garage,tbl_dfcarz_customers,tbl_make_master,tbl_dfcarsmodel,tbl_colour`,
                `tbl_dfcarz_customers.productid=tbl_Dfcars_Garage.id and tbl_dfcarz_customers.type="seller" and tbl_dfcarz_customers.sales_id=${this.state.userid.id}  and tbl_Dfcars_Garage.make=tbl_make_master.id and  tbl_dfcarsmodel.id=tbl_Dfcars_Garage.model  and tbl_colour.id = tbl_Dfcars_Garage.colour`,
                1,
                "tbl_Dfcars_Garage.id desc"
            )

            let Sold = await cmsContent.getFreedom(
                "tbl_Dfcars_Garage.*,tbl_make_master.Make as makename,tbl_dfcarsmodel.Model as modelname ,tbl_colour.colour as ColourName,tbl_Dfcars_Garage.Reg_no as reg_no",
                `tbl_Dfcars_Garage,tbl_dfcarz_customers,tbl_make_master,tbl_dfcarsmodel,tbl_colour`,
                `tbl_dfcarz_customers.productid=tbl_Dfcars_Garage.id and tbl_dfcarz_customers.type="seller" and tbl_dfcarz_customers.sales_id=${this.state.userid.id}  and tbl_Dfcars_Garage.make=tbl_make_master.id and  tbl_dfcarsmodel.id=tbl_Dfcars_Garage.model  and tbl_colour.id = tbl_Dfcars_Garage.colour and tbl_Dfcars_Garage.status = 'inactive' and tbl_Dfcars_Garage.created_At > now() - INTERVAL 3 MONTH`,
                1,
                "tbl_Dfcars_Garage.id desc"
            )
            // let Contract = await cmsContent.getFreedom(
            //     'tbl_dfcarz_prosperities.*,tbl_dfcarz_prosperities.year as make_year,tbl_user_web.userName,tbl_make_master.Make as makename,tbl_dfcarsmodel.Model as modelname ,tbl_colour.colour as ColourName',
            //     'tbl_dfcarz_prosperities,tbl_user_web,tbl_make_master,tbl_dfcarsmodel,tbl_colour',
            //     `tbl_user_web.id = tbl_dfcarz_prosperities.userid and tbl_dfcarz_prosperities.type='buyer' and tbl_dfcarz_prosperities.userid =${this.state.userid.id} and tbl_make_master.id=tbl_dfcarz_prosperities.make and  tbl_dfcarsmodel.id = tbl_dfcarz_prosperities.model`,
            //     1,
            //     "tbl_dfcarz_prosperities.id desc"
            // )

            let Refurbishment = await cmsContent.getFreedom(
                'tbl_dfcarz_prosperities.*,tbl_user_web.userName as jobroleName,tbl_dfcars_RF.id as RFtable_id,tbl_Delivery_Seller_Proof.reg_no as REGNO,tbl_make_master.Make as makename,tbl_dfcarsmodel.Model as modelname,tbl_colour.colour as ColourName,tbl_dfcarz_prosperities.year as make_year',
                `tbl_dfcarz_prosperities,tbl_Delivery_Seller_Proof,tbl_user_web,tbl_dfcars_RF,tbl_make_master,tbl_dfcarsmodel,tbl_colour`,
                `tbl_dfcars_RF.prosid =tbl_dfcarz_prosperities.id and tbl_dfcarz_prosperities.id=tbl_Delivery_Seller_Proof.customerid and tbl_dfcarz_prosperities.userid =${this.state.userid.id} and tbl_make_master.id=tbl_dfcarz_prosperities.make and tbl_dfcarsmodel.id = tbl_dfcarz_prosperities.model and tbl_colour.id=tbl_dfcarz_prosperities.color`,
                1,
                "tbl_dfcarz_prosperities.id DESC"
            )
            if (result) {
                
                let AllCar = result.data
                let RFCar = Refurbishment.data
                let FilterCar_Rf = RFCar.filter((ival) => !AllCar.some((jval) => jval.customerId == ival.id)
                )
                let all_Cars = AllCar.concat(RFCar)
                let all_Cars2 = all_Cars.filter((obj, index, self) => index === self.findIndex(x => x.reg_no === obj.reg_no))
                
                let Result = all_Cars2.filter(x => x.status != 'inactive')

                 Result.sort((a, b) => {
                   if (b.created_At > a.created_At)
                   {
                       return 1
                   }
                   else if (b.created_At < a.created_At)
                   {
                       return -1
                   }
                   else 
                   {
                     return 0   
                    }
                });

                this.setState({ Data: Result, All_Cars: Result, Sold_Cars: Sold.data, Rf_Cars: FilterCar_Rf, loading: false });
            }

        } catch (error) {
            console.log(error);
        }
    }


    Details = (d) => {
        if (d.original.Movedtosite !== null && d.original.Movedtosite) {
            return (<div><button type="button" className="btn btn-success btn-sm" style={{ width: '100%' }}
            >Already Moved</button></div>);
        } else {
            return (<div><button type="button" className="btn btn-danger btn-sm" style={{ width: '100%' }}
                onClick={() => this.Movetosite(d)}
            >Move To Site</button></div>);
        }
    }
    Status = (d) => {
        if (d.original.status == 'inactive') {
            return (<div><button type="button" className="btn btn-danger btn-sm"
                style={{ minWidth: "100px" }}
            // onClick={()=>this.F_proofForm(d)}
            >SOLD</button></div>);
        } else if (d.original.Certified == '2' ){
            return (
                <div>
                    <button type="button" className="btn btn-success btn-sm"
                        style={{ minWidth: "140px" }}
                    >AVAILABLE</button>
                </div>
            );
        } else {
            return (
                <div>
                    <button type="button" className="btn btn-info btn-sm"
                        style={{ minWidth: "100px", color: 'dark' }}
                    >RF WORK PROCESS</button>
                </div>
            );
        }
    }
    View = () => {
        try {
            const data = this.state.Data;
            console.log(data, "data");
            const modelid = localStorage.getItem('modelid');
            const makeid = localStorage.getItem('makeid');
            console.log(makeid, modelid, "makesss");

            let result = data.filter(function (v, i) {
                return v.make == makeid && v.model == modelid;
            });

            console.log(result, "filter");
            this.setState({ filterdata: result });
        } catch (err) { console.log(err); }
    }




    componentDidMount() {
        this.setState({ loading: true })
        this.Mainfunc()
    }

    changeModelstate = async (s, v) => {
        this.setState({ [s]: v });
    }
    onClk = (val) => {
        if (val === 1) { this.setState({ Data: this.state.All_Cars, FilterData: this.state.All_Cars, filteredLoc: "", filteredMake: "" }) }
        if (val === 2) { this.setState({ Data: this.state.Sold_Cars, FilterData: this.state.Sold_Cars, filteredLoc: "", filteredMake: "" }) }
        if (val === 3) { this.setState({ Data: this.state.Move_sit, FilterData: this.state.Move_sit, filteredLoc: "", filteredMake: "" }) }
        if (val === 4) { this.setState({ Data: this.state.Rf_Cars, FilterData: this.state.Rf_Cars, filteredLoc: "", filteredMake: "" }) }
        if (val === 5) { this.setState({ Data: this.state.Contract_Cars, FilterData: this.state.Contract_Cars, filteredLoc: "", filteredMake: "" }) }
        
        window.scroll({
            top: 400,
            left: 0,
            behavior: "smooth",
        });
    }
    edash = () => {
        return (<div className="card">

            <div className="card-header">
                <div className="col-sm-6" >
                    <h2 className="mt-2 black">Dashboard</h2>
                </div>
            </div>
            <div className="card-body">

                <div className="">
                    <div className="row text-center">
                        <div className="col ">
                            <div className="dashboardCard1" onClick={() => this.onClk(1)} >
                                <Icon icon={ic_check_box} style={{ color: 'green' }} size={35} />
                                <h2 className="mt-2 black" >All Cars</h2>
                                <div >
                                    <h1 className="bold mt-4 mb-2 black" style={{cursor:"pointer"}}><b>{this.state.All_Cars && this.state.All_Cars.length > 0 ? this.state.All_Cars.length : 0}</b></h1>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="dashboardCard1" onClick={() => this.onClk(2)} >
                                <Icon icon={shopping_cart_ok} style={{ color: 'green' }} size={35} />
                                <h2 className="mt-2 black" >Sold</h2>
                                <div >
                                    <h1 className="bold mt-4 mb-2 black" style={{cursor:"pointer"}}><b>{this.state.Sold_Cars && this.state.Sold_Cars.length > 0 ? this.state.Sold_Cars.length : 0}</b></h1>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="dashboardCard1" onClick={() => this.onClk(3)} >
                                <Icon icon={tick} style={{ color: 'green' }} size={35} />
                                <h2 className="mt-2 black" >Moved To Site</h2>
                                <div >
                                    <h6 className="bold mt-4 mb-2 black" style={{ color: 'red',cursor:"pointer" }}><b>{this.state.Move_sit && this.state.Move_sit.length > 0 ? this.state.Move_sit.length : "On HOld "}</b></h6>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="dashboardCard1" onClick={() => this.onClk(4)} >
                                <Icon icon={cog} style={{ color: 'purple' }} size={35} /><br />
                                <h2 className="mt-2 black" >Refurbishment</h2>
                                <div >
                                    <h1 className="bold mt-4 mb-2 black" style={{cursor:"pointer"}}><b>{this.state.Rf_Cars && this.state.Rf_Cars.length > 0 ? this.state.Rf_Cars.length : 0}</b></h1>
                                </div>
                            </div>
                        </div>
                        <div className="col" >
                            <div className="dashboardCard1" onClick={() => this.onClk(5)}>
                                <Icon icon={shuffle} style={{ color: 'rgb(203 44 146)' }} size={35} /><br />
                                <h4 className="mt-2 black" >Contract</h4>
                                <div >
                                    <h6 className="bold mt-4 mb-2 black" style={{ color: 'red',cursor:"pointer" }}><b>{this.state.Contract_Cars && this.state.Contract_Cars.length > 0 ? this.state.Contract_Cars.length : "On HOld "}</b></h6>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/* <div className="row col-12 mt-2">
                    <div className="col-sm-5 ">
                        <h4 className="black">status :</h4>
                        <SingleSelect
                            options={this.state.opt1}
                            handleChange={e => this.cng(e, 1)}
                            selectedService={this.state.opt1s}
                        />
                    </div>
                    <div className="col-sm-5 ">
                        <h4 className="black">Web list :</h4>
                        <SingleSelect
                            options={this.state.opt2}
                            handleChange={e => this.cng(e, 2)}
                            selectedService={this.state.opt2s}
                        />
                    </div>
                    <div className="col-sm-2 " style={{ paddingTop: "17px" }}>
                        {/* <h4 className="black"> </h4> */}
                {/* <button className="btn btn-primary btn-sm" onClick={this.onall}>ALL</button>
                    </div>
                </div> */}
            </div>
        </div >)
    }
    render() {
        const { match: { params } } = this.props;
        let { loading, Car_Content } = this.state;
        const makeid = localStorage.getItem('makeid');
        return (<React.Fragment>
            <main className="main my-4">
                <div className="container-fluid">
                    {this.edash()}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h1>My Inventory</h1>
                                </div>
                                <div className="card-body">
                                    <div className="row form-group">
                                        <div className="col-sm-4">
                                            <LoginModal
                                                title="View Image"
                                                id="Viewimage_modal"
                                                extraStyle={{ width: 'auto' }}
                                                buttonStyle={{ backgroundColor: "white", border: "white" }}
                                                bodyText={
                                                    <>
                                                        {Car_Content && Car_Content ? Car_Content : ""}
                                                    </>
                                                }
                                            />

                                        </div>
                                    </div>
                                    <div className="row form-group">
                                        <div className="col-sm-4">
                                            <LoginModal
                                                buttonTitle="Add Enquiry"
                                                title="Upload Images"
                                                id="images"
                                                extraClass="btn btn-primary btn-sm"
                                                onClick={this.reset}
                                                extraStyle={{ width: 'auto' }}
                                                buttonStyle={{ backgroundColor: "white", border: "white" }}
                                                bodyText={
                                                    <DfCarsImageUpload
                                                        EditData={this.state.EditData}
                                                        these={this.changeModelstate}
                                                        FullData={this.state.Data}
                                                        Index={this.state.Index}
                                                    />}
                                            />
                                        </div>
                                    </div>
                                    {loading ? <div className="text-center"> <i
                                        className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                                    ></i></div> : params.id === makeid ?
                                        (
                                        <Datatable
                                            data={this.state.filterdata}
                                            columnHeading={this.state.column} />
                                        )
                                            :
                                        (<Datatable
                                            data={this.state.Data}
                                            columnHeading={this.state.column}
                                        />)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </React.Fragment>
        )
    }
}
export default MyInventory;