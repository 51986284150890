import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import Datatable from "../../../../components/Datatable/Datatable";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import { confirmAlert } from "react-confirm-alert";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import DFmakePDF from "../../../../components/PDF/Dfmake";
import redisMiddleware from "../../../../MiddleWare/redisMiddleware";

class NewMakeMaster extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            customerId: localStorage.getItem("userId"),
            ServiceId: localStorage.getItem("currentService"),
            alertVisible: false,
            color: "",
            textalert: "",
            ColourName: "",
            errorColourName: "",
            isEdit: false,
            disableValue: false,
        };
    }

    column = [
        {
            Header: "SINO",
            Cell: d => { return (d.index + 1) },
            width: 450
        },
        {
            Header: "Make",
            accessor: "Make",
        },
        {
            Header: "Edit",
            accessor: "edit",
            Cell: (d) => this.edit(d),
        },
        // {
        //   Header: <b>Delete</b>,
        //   selector: "delete",
        //   Cell: (d) =>
        //     this.dataTableButton("danger", "Delete", () => {
        //       this.buttonDeletes(d);
        //     }),
        // },
    ];
    async componentDidMount() {
        this.main()
    }
    main = async () => {
        try {
            this.setState({ loading: true })
            const make = await cmsContent.getFreedom(
                "*",
                "tbl_make_master",
                `customerId = 620 and ServiceId = 10 and status = 'active'`,
                "id",
                "id DESC"
            );

            // const make = await redisMiddleware.singleTable(
            //   "tbl_make_master",
            //   this.state.ServiceId,
            //   "ServiceId"
            // );
            console.log(make);
            if (make) {
                this.setState({ data: make.data });
            }
            if (make.data.length > 0) {
                const header = ["SI.NO", "MAKE"];
                const excelHead = [{ label: "MAKE", key: "make" }];
                this.setState({
                    excelHead,
                    header,
                    excel: make.data,
                    title: "MAKE",
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    buttonDeletes = (value) => {
        console.log(value);
        confirmAlert({
            title: "Confirmation to Delete",
            message: "Are you sure you want to delete this?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => this.buttonDelete(value),
                },
                {
                    label: "No",
                },
            ],
        });
    };

    buttonDelete = async (value) => {
        const previousData = [...this.state.data];
        let id = value.original.id;
        try {
            const result = await cmsContent.getSingleConditionedValue(
                "tbl_make_master",
                "id",
                id,
                "Delete"
            );
            if (result) {
                let datas = previousData.filter((delelteid, i) => {
                    if (delelteid.id !== value.original.id) {
                        return delelteid;
                    }
                });
                this.setState({
                    data: datas,
                    alertVisible: true,
                    textalert: "Selected Colour Deleted",
                    color: "danger",
                });
                setTimeout(() => this.setState({ alertVisible: false }), 3000);
            }
        } catch (error) {
            console.log(error);
        }
    };

    edit = (d) => {
        let value = d;
        return (
            <center>
                <button
                    type="button"
                    className="btn btn-info"
                    onClick={() => this.edition(value)}
                >
                    Edit
                </button>
            </center>
        );
    };

    handlechangeData = async (e) => {
        let value = e.target.value;
        this.setState({ [e.target.name]: e.target.value.toUpperCase(), value });
    };

    submitCategory = async () => {
        const { ColourName, customerId, data, value, ServiceId } = this.state;
        if (!ColourName) {
            this.setState({ errorColourName: "Enter Make Name" });
            return false;
        }
        let filtered_item =
            data && data.length > 0
                ? data.filter((i) => {
                    return i.Make === value;
                })
                : [];

        if (filtered_item.length > 0) {
            this.setState({ error: "Make not be repeated" });
            return false;
        }
        let groupArray = {};
        groupArray.Make = ColourName.trim();
        groupArray.status = "active";
        groupArray.customerId = 620;
        groupArray.ServiceId = 10;
        try {
            this.setState({ disableValue: true });
            const result = await cmsContent.addMaster("tbl_make_master", groupArray);
            if (result) {
                // let valueArray = {};
                // valueArray.id = result.data.insertId;
                // valueArray.Make = ColourName;
                // valueArray.status = "active";
                // valueArray.customerId = customerId;
                // valueArray.ServiceId = ServiceId;
                // const newData = [valueArray, ...this.state.data];
                this.setState({
                    // data: newData,
                    alertVisible: true,
                    disableValue: false,
                    textalert: "New make Added",
                    color: "success",
                    errorColourName: "",
                    error: "",
                    ColourName: "",
                });
                this.main()
                setTimeout(() => this.setState({ alertVisible: false }), 3000);
            }
        } catch (error) {
            console.log(error);
        }
    };

    onDismiss = () => {
        this.setState({ alertVisible: false, formAlertdelete: false });
    };

    edition = (d) => {
        console.log(d);
        let Index = d.index;
        let value = d.original;
        let editid = value.id;
        let ColourName = value.Make;
        this.setState({
            editid,
            Index,
            ColourName,
            isEdit: true,
        });
    };

    UpdateCategory = async () => {
        const { editid, ColourName, Index } = this.state;
        if (!ColourName) {
            this.setState({ errorColourName: "Enter Make Name" });
            return false;
        }
        let subgroupArray = {};
        subgroupArray.Make = ColourName;
        subgroupArray.status = "active";
        try {
            const result = await cmsContent.updateMaster(
                "tbl_make_master",
                editid,
                subgroupArray
            );
            if (result) {
                // let previousdata = [...this.state.data];
                // previousdata[Index].Make = ColourName;
                // setTimeout(() => this.setState({ data: previousdata }), 500);
                this.setState({
                    alertVisible: true,
                    disableValue: false,
                    editid: null,
                    ColourName: "",
                    errorColourName: "",
                    error: "",
                    Index: null,
                    textalert: "Make Updated",
                    color: "success",
                    isEdit: false,
                });
                this.main()
                setTimeout(() => this.setState({ alertVisible: false }), 3000);
            }
        } catch (error) {
            console.log(error);
        }
    };

    render() {
        console.log(this.state.data, "data");
        const { alertVisible, textalert } = this.state;
        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h1>Make Master</h1>
                                    </div>
                                    <div className="card-body">
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-7">
                                                <Alert
                                                    className="badge-content"
                                                    color={this.state.color}
                                                    isOpen={alertVisible}
                                                    toggle={this.onDismiss}
                                                >
                                                    {textalert}
                                                </Alert>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="exampleInputEmail1">Make</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="ColourName"
                                                    name="ColourName"
                                                    placeholder="Enter Make"
                                                    value={this.state.ColourName}
                                                    onChange={this.handlechangeData}
                                                />
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-4" />
                                            <span className="error-show ">
                                                {this.state.errorColourName}
                                            </span>
                                            <span className="error-show ">{this.state.error}</span>
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-3" />
                                            <div className="col-sm-5">
                                                <button
                                                    type="button"
                                                    className={`btn btn-${this.state.isEdit ? "secondary" : "primary"
                                                        }`}
                                                    disabled={this.state.disableValue}
                                                    onClick={
                                                        this.state.isEdit
                                                            ? this.UpdateCategory
                                                            : this.submitCategory
                                                    }
                                                >
                                                    {this.state.isEdit ? "Update Make" : "Add Make"}
                                                </button>
                                            </div>
                                            <div className="col-sm-2" />
                                        </div>
                                        <br></br>
                                        {this.state.excel && this.state.excel.length > 0 ? (
                                            <PDFDownloadLink
                                                style={{ color: "white" }}
                                                document={
                                                    <DFmakePDF
                                                        title={this.state.title}
                                                        header={this.state.header}
                                                        data={this.state.excel}
                                                    />
                                                }
                                                fileName="AddBrand.pdf"
                                            >
                                                {({ blob, url, loading, error }) =>
                                                    loading ? "Loading document..." : "Download Pdf"
                                                }
                                                <button className="btn btn-warning">PDF</button>
                                            </PDFDownloadLink>
                                        ) : null}
                                        {this.state.data && this.state.data.length ? (
                                            <CSVLink
                                                data={this.state.data}
                                                headers={this.state.excelHead}
                                            >
                                                <button
                                                    className="btn btn-success"
                                                    style={{ marginLeft: "5px" }}
                                                >
                                                    Excel
                                                </button>
                                            </CSVLink>
                                        ) : null}
                                        <br></br>
                                        <br></br>
                                        <div className="row form-group">
                                            <div className="col-sm-12">
                                                {this.state.loading == true ? <div className="text-center"> <i
                                                    className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                                                ></i></div> :
                                                    this.state.data.length ? (
                                                        <Datatable
                                                            data={this.state.data}
                                                            columnHeading={this.column}
                                                        />
                                                    ) : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}
export default NewMakeMaster;
