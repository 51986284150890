import React, { Component } from "react";
import StepZilla from "react-stepzilla";
// import Step1 from "./Step1";
// import Step2 from "./Step2";
// import Step3 from "./Step3";

import CmsContent from "../../../MiddleWare/CmsContent";
import Datatable from "../../../components/Datatable/Datatable";
import { Alert } from "reactstrap";
import LoginModal from "../../../components/Modal/Modal";
import SingleSelect from "../../../components/Form/SingleSelect";
import MultiSelect from "../../../components/Form/MultiSelect";
import { FormMiddleWare } from "../../../components/Form";

import redisMiddleware from "../../../MiddleWare/redisMiddleware";

class ProcessDefinition extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      customerId: localStorage.getItem("userId"),
      serviceId: localStorage.getItem("currentService"),
      formOption: [],
      formSelected: null,
      usercheck: false,
      data: [],
      stage: [],
      stepsData: [],
      step_push: [],
      viewStage:[]
    };
  }
  async componentDidMount() {
    try {
      const form = await redisMiddleware.singleTable(
        "tbl_form",
        this.state.serviceId,
        "serviceid"
      );

      let formOption = [];
      if (form && form.data) {
        form.data.map((ival, i) => {
          formOption.push({
            label: ival.name,
            value: ival.id
          });
        });
      }

      const FQ = await redisMiddleware.singleTable(
        "tbl_formquestion",
        this.state.serviceId,
        "serviceid"
      );
      console.log(FQ);

      let formQues = [];
      if (FQ && FQ.data) {
        FQ.data.map((ival, i) => {
          formQues.push({
            label: ival.question,
            value: ival.id,
            parentid: ival.parentid
          });
        });
      }

      const usertype = await redisMiddleware.singleTable(
        "tbl_UserType",
        this.state.serviceId,
        "serviceid"
      );
      let user = [];
      if (usertype && usertype.data) {
        usertype.data.map((ival, i) => {
          if (ival.serviceid == this.state.serviceId) {
            user.push({
              label: ival.usertype,
              value: ival.id
            });
          }
        });
      }

      const data = await redisMiddleware.singleTable(
        "tbl_user_web",
        this.state.serviceId,
        "serviceId"
      );
      const location = await redisMiddleware.singleTable(
        "tbl_location",
        this.state.customerId,
        "customerId"
      );
      var learnerOptions = [];
      if (location) {
        location.data.map(item => {
          if (item.serviceid == this.state.serviceId) {
            learnerOptions.push({ label: item.name, value: item.id });
          }
        });

        this.setState({ location: learnerOptions });
      }
      const design = await redisMiddleware.singleTable(
        "tbl_MaptoLocation",
        this.state.customerId,
        "customerid"
      );

      this.setState({
        userType: user,
        formOption,
        formQues,
        data: data.data,
        learnerOptions,
        design: design.data
      });
    } catch (error) {
      console.error(error);
    }
  }
  getStore() {
    return this.sampleStore;
  }

  updateStore(update) {
    this.sampleStore = {
      ...this.sampleStore,
      ...update
    };
  }
  formSelect = async e => {
    const steps = [];

    if (e) {
      await this.setState({ formSelected: e });
    }
  };
  usertypeSelect = e => {
    const { data, design, location } = this.state;
    let userOption = [];
    if (e) {
      e.map((ival, i) => {
        data.map((jval, i) => {
          if (jval.userType == ival.value) {
            design.map((kval, k) => {
              if (jval.userportid == kval.id) {
                userOption.push({
                  label:
                    jval.userName +
                    " - " +
                    ival.label +
                    " - " +
                    this.getValueFromArray(kval.locationId, location),
                  value: jval.id
                });
              }
            });
          }
        });
      });
      this.setState({ usertypeSelected: e, userOption });
    }
  };
  CheckBox = e => {
    if (this.state.usercheck == true) {
      this.setState({ usercheck: false });
    } else {
      this.setState({ usercheck: true });
    }
  };
  userOptionSelect = e => {
    this.setState({ userOptionSelected: e });
  };
  innerformSelect = e => {
    const { formQues } = this.state;
    let formQuesOption = [];
    formQues.map((ival, i) => {
      if (e.value == ival.parentid) {
        formQuesOption.push(ival);
      }
    });
    this.setState({ innerformSelected: e, formQuesOption });
  };

  formQuesOptionSelect = e => {
    if (e) {
      this.setState({ formQuesOptionSelected: e });
    }
  };

//   addStage = () => {
//     let stage = this.state.stage;
//     stage.push({
//       name: ""
//     });

//     this.setState({ stage });
//   };

  addStep = async () => {
    let step_push = this.state.step_push;
    step_push.push({
      name: ""
    });
    console.log(step_push);
    await this.setState({ step_push });
  };
  
  addStage=async(e)=>{
   const{viewStage}=this.state;
    viewStage[e].stepsData.push(
     {usertype:"",
      userid:"",
      formid:"",
      form_field:"",
      days:"" 
    }
    )
    console.log(viewStage)
    this.setState({viewStage})
  }


  render() {
    const {
      formOption,
      formSelected,
      formQuesOption,
      formQuesOptionSelected,
      userType,
      usertypeSelected,
      userOption,
      userOptionSelected,
      innerformSelected,
      stage,
      viewStage,
      //   steps,
      step_push
    } = this.state;

    let steps = [];

    if (step_push && step_push.length) {
      step_push.map((ival, i) => {
        steps.push({
          name: `Step ${i + 1}`,

          component: (
            <div className="col-sm-12">
              <br />
              <div className="row form-group">
                <div className="col-sm-3">
                  <label for="exampleInputEmail1">User Type</label>
                </div>
                <div className="col-sm-7">
                  <MultiSelect
                    options={userType}
                    handleChange={this.usertypeSelect}
                    selectedService={usertypeSelected}
                  />
                </div>
                <div className="col-sm-2">
                  <div class="form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="ShowChecked"
                      onClick={this.CheckBox}
                      checked={this.state.usercheck}
                    />
                    <label class="form-check-label" for="materialChecked">
                      User Show
                    </label>
                  </div>
                </div>
              </div>

              {this.state.usercheck === true && (
                <div className="row form-group">
                  <div className="col-sm-3">
                    <label for="exampleInputEmail1">User</label>
                  </div>
                  <div className="col-sm-7">
                    <MultiSelect
                      options={userOption}
                      handleChange={this.userOptionSelect}
                      selectedService={userOptionSelected}
                    />
                  </div>
                  <div className="col-sm-2"></div>
                </div>
              )}
              <div className="row form-group">
                <div className="col-sm-3">
                  <label for="exampleInputEmail1">Form</label>
                </div>
                <div className="col-sm-7">
                  <SingleSelect
                    options={formSelected}
                    handleChange={this.innerformSelect}
                    selectedService={innerformSelected}
                  />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-sm-3">
                  <label for="exampleInputEmail1">Form Field</label>
                </div>
                <div className="col-sm-7">
                  <MultiSelect
                    options={formQuesOption}
                    handleChange={this.formQuesOptionSelect}
                    selectedService={formQuesOptionSelected}
                  />
                </div>
              </div>
            </div>
          )
        });
      });
      steps.push({
        name: `Step ${step_push.length + 1}`,
        component: <div>Completed.</div>
      });
    }

    //let viewStage = [];
    if (formSelected && formSelected.length > 0) {
      formSelected.map((ival, i) => {
        viewStage.push({
          name: `Stage ${i + 1}`,
          stepsData:[],
          component: (
             <React.Fragment>
               {viewStage.length && 
               viewStage[i].stepsData.length &&
                viewStage[i].stepsData.map((jval,j)=>{
                  return(
                    <h3>Hello</h3>
                  )
               })}
            <div className="row form-group">
              <div className="col-sm-2" />
              <div className="col-sm-2"></div>
              <div className="col-sm-5">
                <button
                  type="button"
                  className="btn btn-primary"
                  // disabled={disableValue}
                  onClick={()=>this.addStage(i,ival)}
                >
                  Add Stage
                </button>
              </div>
              <div className="col-sm-3" />
            </div>
            </React.Fragment>
          )
        });
      });
    }

    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="card">
              <div className="card-header">
                <h1>Process Definition</h1>
              </div>
              <div className="card-body">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label for="exampleInputEmail1">Form</label>
                  </div>
                  <div className="col-sm-5">
                    <MultiSelect
                      options={formOption}
                      handleChange={this.formSelect}
                      selectedService={formSelected}
                    />
                  </div>
                  <div className="col-sm-3" />
                </div>
                {/* <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2"></div>
                  <div className="col-sm-5">
                    <button
                      type="button"
                      className="btn btn-primary"
                      // disabled={disableValue}
                      onClick={this.addStage}
                    >
                      Add Stage
                    </button>
                  </div>
                  <div className="col-sm-3" />
                </div> */}
                {/* {viewStage} */}
                {formSelected && formSelected.length > 0 && (
                  <div className="example">
                    <div className="step-progress">
                      <div className="">
                        <StepZilla
                          steps={viewStage}
                          preventEnterSubmission={true}
                          //   nextTextOnFinalActionStep={"Save"}
                          //   hocValidationAppliedTo={[6]}
                          //   startAtStep={
                          //     window.sessionStorage.getItem("step")
                          //       ? parseFloat(
                          //           window.sessionStorage.getItem("step")
                          //         )
                          //       : 0
                          //   }
                          //   onStepChange={step =>
                          //     window.sessionStorage.setItem("step", step)
                          //   }
                          stepsNavigation={true}
                          prevBtnOnLastStep={false}
                          startAtStep={0}
                          //   showSteps={false}
                          preventEnterSubmission={false}
                          nextTextOnFinalActionStep="Submit"
                          onStepChange={step => {
                            // this.submitQuiz(step);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
export default ProcessDefinition;
