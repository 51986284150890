import React, { Component } from "react";
import * as echarts from 'echarts';
import Utilities from "../../../../MiddleWare/Utilities";
import redisMiddleware from "../../../../MiddleWare/redisMiddleware";
import CmsContent from "../../../../MiddleWare/CmsContent";
import TeamLeader from "../usertypeDashboard/TeamLeader";
import Manager from "../usertypeDashboard/manager";
import Salesconsultant from "../usertypeDashboard/Salesconsultant";
import PocHead from "../usertypeDashboard/PocHead";
import RfIncharge from "../usertypeDashboard/RfIncharge";
import Accounts from "../usertypeDashboard/Accounts";
import moment from "moment";
import PortletsView from '../../Difuza/PortletsView';
import Externaluser from '../Externaluser/Dashboard'
import Teledashboard from "../telecaller/Teledashboard";
import Rftechnician from "../usertypeDashboard/RfIncharge";
import CanvasJSReact from "../../../../canvas/canvasjs.react";
import { FormMiddleWare, SingleSelect } from "../../../../components/Form";
import DfcarzCustomerHistory from "./DfcarzCustomerHistory";
import "./DashboardStyle.css"
import { Link } from "react-router-dom";
import loadingCar from '../../../../images/loading_car.webp'


var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var CanvasJS = CanvasJSReact.CanvasJS;
export default class DfcarsUserdash extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isRegno: false,
      isMobNo: false,
      suggest: "",
      SearchDash: false,
      RegisterNo: "",
      MobileNo: "",
      User: JSON.parse(localStorage.getItem("userlog")),
      usertypelog: JSON.parse(localStorage.getItem("usertypelog")),
      Chart1: [],
      Chart2: [],
      search: false,
      loading: false,
      usertypeName: "",
      TodayNotifiy: [],
      PendingTask: [],
      TodayEvaluationF: [],
      TodayDocumentation: [],
      TodayPaymentProcess: [],
      TodayPhysicalProcess: [],
      TodayRefurbishmentProcess: [],
      PendingContracted: [],
      PendingDelivery: [],
      TodayEvaluationB: [],
      RC_BookPending: [],
      finalchart: [],
      array1: [],
      array2: [],
      locationArray: [],
      newfromdate: "",
      newtodate: "",
      newfromdate1: "",
      newtodate1: "",
      newfromdate2: "",
      newtodate2: "",
      newfromdate3: "",
      newtodate3: "",
      newfromdate4: "",
      newtodate4: "",
      sellnewfromdate1: "",
      sellnewtodate1: "",
      sellnewfromdate2: "",
      sellnewtodate2: "",
      sellnewfromdate3: "",
      sellnewtodate3: "",
      sellnewfromdate4: "",
      sellnewtodate4: "",
      sellnewfromdate5: "",
      sellnewtodate5: "",
      field: null,
      fieldopt1: [{
        label: "Enquiry",
        value: "enq"
      },
      {
        label: "SELL",
        value: "sell"
      }],
      locatiofieldopt: [
        {
          label: "Enquiry",
          value: "1"
        },
        {
          label: "Inventory",
          value: "2"
        }
      ],
      fieldopt: [
        {
          label: "Enquiry",
          value: "1"
        },
        {
          label: "Evaluation",
          value: "2"
        },
        {
          label: "Documentation",
          value: "3"
        },
        {
          label: "Physicalprocess",
          value: "4"
        }
      ],
      sellingFieldOpt: [
        {
          label: "Enquiry",
          value: "1"
        },
        {
          label: "Lead",
          value: "2"
        },
        {
          label: "Contract",
          value: "3"
        },
        {
          label: "Delivery",
          value: "4"
        },
        {
          label: "Rc",
          value: "5"
        }
      ],
      sellingLocatFieldOpt: [
        {
          label: "Enquiry Cars",
          value: "1"
        },
        {
          label: "Sold Cars",
          value: "2"
        },
        {
          label: "Available Cars",
          value: "3"
        },
      ],
      fromdate: "",
      todate: "",
      Cars: [],
      buy: true,
      sell: false,
      dash: true,
      LocationID: localStorage.getItem(`LocationID`),
      isFlipped1: false,
      isFlipped2: false,
      isFlipped3: false,
      enquiryData: "",
      evaluationData: "",
      PhysicalProcessData: "",
      refurbishmentData: "",
      documentationData: "",
      sellingData: "",
      leadData: "",
      contractData: "",
      rcTransferData: "",
      deliveryData: "",
      allCarsData: "",
      soldCarsData: "",
      availableCarsData: "",
      dataLoading: true
    };
  }
  async componentDidMount() {
    try {
      this.setState({ dataLoading: true })
      let user = JSON.parse(localStorage.getItem('userlog'));
      let id = user.id;

      let today = new Date();
      let todayDtae = today.toISOString().split('T')[0];
      let fromdate = new Date(today);
      fromdate.setMonth(today.getMonth() - 1);
      let fromDate = fromdate.toISOString().split('T')[0];


      // Fetch all data in parallel
      const [
        result1,
        result2,
        result3,
        result4,
        result5,
        result6,
        result7,
        result8,
        result9,
        result10,
        result11,
        locchartdatas,
        EnquiryReport,
        allOpt,
      ] = await Promise.all([
        CmsContent.getDfcarzEnquiry(id, 'seller'),
        CmsContent.BUYDFCARZ(user.id, 'evaluation'),
        CmsContent.BUYDFCARZ(user.id, 'Documents'),
        CmsContent.BUYDFCARZ(id, 'physical'),
        CmsContent.BUYDFCARZ(id, 'Refurbishment'),
        CmsContent.getDfcarzEnquiry(id, 'buyer'),
        CmsContent.BUYDFCARZ(user.id, 'Lead'),
        CmsContent.BUYDFCARZ(user.id, 'contract'),
        CmsContent.BUYDFCARZ(user.id, 'delreport'),
        CmsContent.BUYDFCARZ(user.id, 'RCdel'),
        CmsContent.BUYDFCARZ(id, 'inventlist'),
        CmsContent.getlocationdata(fromDate, todayDtae, id),
        CmsContent.getDfcarzReportEnquiry('id', 'seller'),
        this.decryption('getcar'),
      ]);


      // Process the data
      let data = result1.status[0];
      let enquiery = data.Closed + data.Evaluation + data.FTD + data.MTD + data.live + data.noncontact + data.unallocated;
      let evaluation = result2.data_for_f[0].filled + result2.data_for_f[0].nonfilled;
      let documentation = result3.data_for_f[0].approval + result3.data_for_f[0].approved + result3.data_for_f[0].nonfilled + result3.data_for_f[0].reject;
      let PhysicalProcess = result4.data_for_f[0].filled + result4.data_for_f[0].nonfilled;
      let refurbishment = result5.data_for_f[0].Reject + result5.data_for_f[0].completed + result5.data_for_f[0].fill + result5.data_for_f[0].today + result5.data_for_f[0].work;

      if (enquiery) {
        this.setState({ enquiryData: enquiery, evaluationData: evaluation, documentationData: documentation, PhysicalProcessData: PhysicalProcess, refurbishmentData: refurbishment });
      }


      let sellingEnquiery = result6.status[0].Closed + result6.status[0].Evaluation + result6.status[0].FTD + result6.status[0].MTD + result6.status[0].live + result6.status[0].noncontact + result6.status[0].unallocated;
      let Lead = result7.Lead.length;
      let contract = result8.data_for_f[0].Comitement + result8.data_for_f[0].FTD + result8.data_for_f[0].MTD + result8.data_for_f[0].approval + result8.data_for_f[0].approved + result8.data_for_f[0].delivery_pending + result8.data_for_f[0].filled + result8.data_for_f[0].nonfilled + result8.data_for_f[0].reject;
      let delivery = result9.data_for_f[0].fill + result9.data_for_f[0].FTD + result9.data_for_f[0].MTD + result9.data_for_f[0].view;
      let rcTransfer = result10.data_for_f[0].fill + result10.data_for_f[0].pdocs7days + result10.data_for_f[0].postview + result10.data_for_f[0].view + result10.data_for_f[0].view1;

      let allCars = result11.data_for_f[0].all;
      let soldCars = result11.Inventory.filter(a => (a.delete_status === 'Sold' && a.car_status === 'delete') || (a.status === 'inactive')).length;
      let availableCars = result11.Inventory.filter(a => a.status !== 'inactive' && a.car_status === 'Live').length;
      let soldStateCars = result11.Inventory.filter(a => (a.delete_status === 'Sold' && a.car_status === 'delete') || (a.status === 'inactive'));
      let availableStageCars = result11.Inventory.filter(a => a.status !== 'inactive' && a.car_status === 'Live')

      if (sellingEnquiery && Lead && contract && delivery && rcTransfer && allCars && soldCars && availableCars) {
        this.setState({
          sellingData: sellingEnquiery,
          leadData: Lead,
          rcTransferData: rcTransfer,
          deliveryData: delivery,
          contractData: contract,
          allCarsData: allCars,
          soldCarsData: soldCars,
          availableCarsData: availableCars,
          soldStateCars: soldStateCars,
          availableStageCars: availableStageCars,
          sellingEnqData: result6.statusdata[0].fulldata,
          sellingLeadData: result7.Lead,
          sellingContractData: result8.fulldata,
          sellingDeliveryData: result9.fulldata,
          sellingRcData: result10.fulldata,
        });
      }


      // Update remaining state values
      // let today = new Date();
      // let todayDtae = today.toISOString().split('T')[0];
      // let fromdate = new Date(today);
      // fromdate.setMonth(today.getMonth() - 1);
      // let fromDate = fromdate.toISOString().split('T')[0];

      let BuyerInventroydata = result11.Inventory.filter(a => a.status !== 'inactive' && a.ongoing !== 1);
      let BuyerEnquiryData = EnquiryReport.statusdata[0].Buyer;
      this.setState({
        fromDate: fromDate,
        toDate: todayDtae,
        userid: id,
        locchartdatas,
        BuyerInventroydata,
        BuyerEnquiryData,
        ModalOptions: allOpt.modelopt,
        MakeOptions: allOpt.makeopt,
        dataLoading: false
      });

      // Call additional methods if necessary
      if (this.state.buy) {
        this.dashchart1();
        this.dashchart2();
        this.dashchart3();
        this.dashchart4();
        this.dashchart5();
      }

      this.cardCharts1();
      this.cardCharts2();
      this.cardCharts3();
      await this.selectField({
        label: "Enquiry Cars",
        value: "1"
      }, "sellingLocation")
      await this.selectField({
        label: "Enquiry",
        value: "1"
      }, "sellingStage")
      await this.handleChange({ target: { name: "", value: "" } }, "sellingColor")
      await this.handleChange({ target: { name: "", value: "" } }, "sellingMake")
      await this.handleChange({ target: { name: "", value: "" } }, "sellingprice")
      await this.updatetab("buying", "selling", "search")



    } catch (error) {
      console.log(error);
    }
  }
  async componentDidUpdate() {
    if (this.state.sell) {
      this.sellingCharts1()
      this.sellingCharts2()
      this.sellingCharts3()
      this.sellingCharts4()
      this.sellingCharts5()
    }
    // if (this.state.buy) {
    // this.dashchart1()
    // this.dashchart2()
    // this.dashchart3()
    // this.dashchart4()
    // this.dashchart5()
    // }
  }
  formforonmonth = async () => {
    var today = await new Date();
    var dd = await String(today.getDate()).padStart(2, '0');
    var mm = await String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var mm1 = await String(today.getMonth()).padStart(2, '0');
    var yyyy = await today.getFullYear();

    today = await yyyy + '-' + mm + '-' + "31";
    let smm = ''


    var onemonth = await yyyy + '-' + mm + '-' + "01";
    await this.onSubmitChart(2, onemonth, today)
    this.setState({ onemonth, today })
  }
  onSubmitChart = async (ch, f = null, t = null) => {

    let { fromdate, todate, FromDateBuy, stagefromdate, stagetoDate, fromDate, toDate, userid } = this.state;
    let groupArray = {};
    if (ch == 1) {
      // this.setState({ isloading: true })
      this.setState({ search: true, loading: true })
      groupArray.FromDate = stagefromdate ? stagefromdate : fromDate ? fromDate : fromdate;
      groupArray.ToDate = stagetoDate ? stagetoDate : toDate ? toDate : todate;
      // console.log(todate, fromdate);
    } else {
      // let groupArray = {};
      groupArray.FromDate = stagefromdate ? stagefromdate : fromDate ? fromDate : f;
      groupArray.ToDate = stagetoDate ? stagetoDate : toDate ? toDate : t;
    }
    let categoryArray = {}
    categoryArray.id = this.state.User.id;
    categoryArray.orderby = this.state.usertypelog.orderBy;

    // console.log(groupArray.FromDate, groupArray.ToDate, this.state.usertypelog.orderBy)
    let result = await CmsContent.ViewChartDFCarz(groupArray.FromDate, groupArray.ToDate, userid)
    // console.log(result,"result");
    if (result) {
      // console.log("res", result);
      let RefurbishmentChart = result.data;
      // console.log(RefurbishmentChart);
      if (this.state.search == true) {
        this.setState({ Chart2: RefurbishmentChart })
        this.dashchart2()
      } else {
        this.setState({ Chart1: RefurbishmentChart })
        this.dashchart2()

      }
      let sampleData4 = []

    }
    // console.log(JSON.stringify(sampleData4));
    // if (ch == 2) {
    //   this.setState({ sampleData5: sampleData4, sample: sampleData4 })
    // } else {
    //   this.setState({ RefurbishmentChart, sampleData4, sample1: sampleData4, isloading: false });
    // }
    // await this.getContent()
  }
  getDate = () => {
    // var dat = d.split(" ");
    var D = new Date();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    var date = D.getDate();
    var month = monthNames[D.getMonth()]; // Since getMonth() returns month from 0-11 not 1-12
    var year = D.getFullYear();
    var dateStr = month + "" + year;
    // console.log("datestr", dateStr);
    return dateStr;

  };
  getContent = async () => {
    let chart1 = this.state.Chart1[0]
    let chart2 = this.state.Chart2[0]
    // console.log(chart2, "chart2");
    // console.log(chart1, "chart1");
    let { fromdate, todate, onemonth, today, search, field } = this.state;
    if (search == true) {
      let field1 = field.value
      var date1 = new Date(fromdate ? fromdate : onemonth);
      var date2 = new Date(todate ? todate : today);
      var arr = [];
      var arr1 = [];
      let wait = chart2[field1].map((kval, k) => {
        // console.log(kval);
        arr.push(kval.x)
        arr1.push(kval.y)
        arr.map((zval) => {
          let barchartview2 = this.canvaschart("Chart",
            arr, arr1
          );
          this.setState({ barchartview2, loading: false })
        })
      })
      Promise.all(wait)
    }
    else if (this.state.usertypelog.orderBy == 11 || this.state.usertypelog.orderBy == 8) {
      let enq = 0;
      let sell = 0;
      if (chart1) {
        enq = chart1 && chart1.enq && chart1.enq[0] && chart1.enq[0].y
      }
      if (chart1) {
        sell = chart1 && chart1.sell && chart1.sell[0] && chart1.sell[0].y
      }
      let d = new Date()
      let barchartview = this.canvaschart("One Month Chart", [
        { label: "Enquiry", y: enq },
        { label: "SELL", y: sell }
      ]);
      let portListCount = this.donutDataStructure(
        [
          "Enquiry",
          "SELL"
        ],
        [
          enq,
          sell
        ],
        [
          "#FF6384",
          "#d9fd2a"
        ]
      );
      this.setState({ barchartview })
    }
    else {
      let evl = 0;
      let enq = 0;
      let doc = 0;
      let phy = 0;
      let buy = 0;
      let sell = 0;
      let urlcount = 0;
      let evalcount = 0;
      // console.log(evalcount)
      // let formcount = 0;
      let surveycount = 0;
      if (chart1) {
        enq = chart1 && chart1.enq && chart1.enq[0] ? chart1.enq[0].y : null
      }
      if (chart1) {
        evl = chart1 && chart1.eval && chart1.eval[0] ? chart1.eval[0].y : null
      }
      if (chart1) {
        doc = chart1 && chart1.doc && chart1.doc[0] ? chart1.doc[0].y : null
      }
      if (chart1) {
        phy = chart1 && chart1.phy && chart1.phy[0] ? chart1.phy[0].y : null
      }
      if (chart1) {
        buy = chart1 && chart1.buy && chart1.buy[0] ? chart1.buy[0].y : null
      }
      if (chart1) {
        sell = chart1 && chart1.sell && chart1.sell[0] ? chart1.sell[0].y : null
      }
      let d = new Date()
      let barchartview = this.canvaschart("One Month Chart", [
        { name: "Enquiry", value: enq },
        { name: "Evaluation", value: evl },
        { name: "Trade In", value: doc },
        { name: "Physical", value: phy },
        { name: "BUY", value: buy },
        { name: "SELL", value: sell }
      ]);
      // let portListCount = this.donutDataStructure(
      //   [
      //     "Enquiry",
      //     "Evaluation",
      //     "Trade In",
      //     "Physical",
      //     "BUY",
      //     "SELL"
      //   ],
      //   [
      //     enq,
      //     evl,
      //     doc,
      //     phy,
      //     buy,
      //     sell
      //   ],
      //   [
      //     "#FF6384",
      //     "#36A2EB",
      //     "#FFCE56",
      //     "#ff6347",
      //     "#3cb371",
      //     "#ffa500",
      //     "#d0839b",
      //     "#5a3636",
      //     "#d9fd2a"
      //   ]
      // );
      this.setState({ barchartview })
    }
  }
  canvaschart = (title, array1, array2) => {
    if (title === "Chart") {
      this.setState({ array1: array1, array2: array2 })
      // console.log("this function is working")
      this.show()
    }
    else {
      this.setState({ finalchart: array1 })
    }
  };

  donutDataStructure(label, data, color) {
    let portListCount = {
      labels: label,
      datasets: [
        {
          data: data,
          backgroundColor: color,
          barPercentage: 20
        }
      ]
    };
    return portListCount;
  }
  answers1 = async (e) => {
    // console.log(e.target.value);
    this.setState({ fromdate: e.target.value })
  }
  answers2 = async (e) => {
    // console.log(e.target.value);
    this.setState({ todate: e.target.value })
  }
  answers3 = async (e) => {
    // console.log(e);


    this.setState({ field: e })
  }
  answers4 = async (value, name) => {
    // console.log(value, "fsffsfssf");
    if (value) { this.setState({ isRegno: true }) }
    if (value == "" || !value) { this.setState({ isRegno: false }) }
    this.setState({ [name]: value })
    if (value.length > 3) {
      let query = ''
      let suggestReg = await CmsContent.getFreedom('id as prosid,enquiry_id,userid,mobile', 'tbl_dfcarz_prosperities', `reg_no LIKE '%${value}'  ${query}`)
      if (suggestReg && suggestReg.data) {
        await this.setState({ suggest: suggestReg.data })
      }
    }
  }
  answers5 = async (value, name) => {
    value = value.slice(0, 10);
    if (value) { this.setState({ isMobNo: true }) }
    if (value == "" || !value) { this.setState({ isMobNo: false }) }
    this.setState({ [name]: value })
    if (value.length > 9) {
      let query = ''
      let suggestMob = await CmsContent.getFreedom('id as prosid,enquiry_id,userid,mobile', 'tbl_dfcarz_prosperities', `mobile='${value}'  ${query}`)
      if (suggestMob && suggestMob.data) {
        await this.setState({ suggest: suggestMob.data })
      }
    }
  }
  ReadyForSale = async () => {
    try {
      // let result = await CmsContent.getFreedom(
      //   `tbl_items.*,tbl_make_master.Make as makename,tbl_dfcarsmodel.Model as modelname,tbl_colour.colour as ColourName`,
      //   `tbl_items,tbl_make_master,tbl_dfcarsmodel,tbl_colour`,
      //   `tbl_items.make=tbl_make_master.id and tbl_dfcarsmodel.id = tbl_items.model and tbl_colour.id = tbl_items.colour`,
      //   1,
      //   1
      // );
      let result = await redisMiddleware.Dashboard("ReadyForSale", 4, this.state.LocationID)
      // console.log(result,'userdas result');
      if (result) {
        let Cars = [];
        result.data.map((ival, i) => {
          if (ival.status != "inactive") {
            let Date1 = new Date(ival.created_At);
            let Currentdate = new Date();
            if (
              Date1.getDate() == Currentdate.getDate() &&
              Date1.getMonth() == Currentdate.getMonth()
            ) {
              Cars.push(ival);
            }
          }
        });

        this.setState({ Cars });
        // console.log(Cars);
      }
    } catch (error) {
      console.log(error);
    }
  };

  RC_BookPending = async () => {
    try {
      // let BuyerCustomer = await CmsContent.getFreedom(
      //   `tbl_dfcarz_customers.*,tbl_user_web.userName as Sales`,
      //   `tbl_dfcarz_prosperities,tbl_dfcarz_customers,tbl_user_web`,
      //   `tbl_dfcarz_customers.type='buyer' and tbl_dfcarz_customers.prospectid = tbl_dfcarz_prosperities.id and tbl_dfcarz_customers.documents_received=0 and tbl_dfcarz_customers.sales_id = tbl_user_web.id and tbl_dfcarz_prosperities.location=${this.state.LocationID}`,
      //   1,
      //   1
      // );
      // console.log(BuyerCustomer);
      let BuyerCustomer = await redisMiddleware.Dashboard("RC_BookPending", 4, this.state.LocationID)
      if (BuyerCustomer) {
        //  let RC_BookPending = [];
        this.setState({ RC_BookPending: BuyerCustomer.data });
      }
    } catch (error) {
      console.log(error);
    }
  };

  EvaluationReport = async () => {
    // const Result1 = await CmsContent.getFreedom(
    //   "tbl_dfcarz_prosperities.*,tbl_BProof.id as B_proof,tbl_FProof.id as F_proof",
    //   "tbl_dfcarz_prosperities,tbl_BProof,tbl_FProof",
    //   `tbl_dfcarz_prosperities.id = tbl_BProof.customerid and tbl_dfcarz_prosperities.id = tbl_FProof.customerid`
    // );
    // if(Result1){
    //   console.log(Result1);
    // }

    // const Result = await CmsContent.getFreedom(
    //   "tbl_dfcarz_prosperities.*,tbl_user_web.userName as jobroleName",
    //   "tbl_dfcarz_prosperities,tbl_user_web",
    //   `tbl_user_web.id = tbl_dfcarz_prosperities.userid and tbl_dfcarz_prosperities.location=${this.state.LocationID}`
    // );
    // console.log(Result)
    let Result = await redisMiddleware.Dashboard("EvaluationReport", 4, this.state.LocationID);
    //console.log(Res)
    if (Result) {
      let Data1 = [];
      Result.data.map((ival, i) => {
        if (ival.type == null || ival.type == "seller") {
          Data1.push(ival);
        }
      });
      //  console.log(Data1);

      const Result1 = await CmsContent.getFreedom("*", "tbl_FProof", 1);
      const Result2 = await CmsContent.getFreedom("*", "tbl_BProof", 1);
      this.setState({ Result1: Result1.data, Result2: Result2.data })
      let TodayEvaluationF = [];
      let TodayEvaluationB = [];

      Result2.data.map((kval, k) => {
        TodayEvaluationB = Data1.filter(
          (value) => value.id !== kval.customerid
        );
      });

      // console.log(TodayEvaluationB);

      Result1.data.map((jval, j) => {
        // if(ival.id == jval.customerid  ){
        TodayEvaluationF = Data1.filter(
          (value) => value.id !== jval.customerid
        );
        // }
      });


      // console.log(TodayEvaluation);

      this.setState({ TodayEvaluationF, TodayEvaluationB });
    }
  };

  Documentation = async () => {
    // const Result = await CmsContent.getFreedom(
    //   "tbl_dfcarz_prosperities.*,tbl_BProof.id as B_proof,tbl_FProof.id as F_proof,tbl_user_web.userName as jobroleName",
    //   "tbl_dfcarz_prosperities,tbl_BProof,tbl_FProof,tbl_user_web",
    //   `tbl_dfcarz_prosperities.id = tbl_BProof.customerid and tbl_dfcarz_prosperities.id = tbl_FProof.customerid and tbl_user_web.id = tbl_FProof.userid and tbl_dfcarz_prosperities.location=${this.state.LocationID}`
    // );
    // console.log(Result)
    let Result = await redisMiddleware.Dashboard("Documentation", 4, this.state.LocationID);
    //console.log(Res)
    if (Result) {
      let BookLet = await CmsContent.getFreedom(
        "*",
        `tbl_AT_Booklet_Purchase_Contract_Proof`,
        1,
        1
      );
      this.setState({ BookLet: BookLet.data })
      let TodayDocumentation = [];
      /// console.log(BookLet.data);
      BookLet.data.map((ival, i) => {
        TodayDocumentation = Result.data.filter(
          (value) => value.id !== ival.customerid
        );
      });
      // console.log(TodayDocumentation);
      this.setState({ TodayDocumentation });
    }
  };

  PaymentProcess = async () => {
    // const Result = await CmsContent.getFreedom(
    //   "tbl_dfcarz_prosperities.*,tbl_user_web.userName as jobroleName",
    //   "tbl_dfcarz_prosperities,tbl_AT_Booklet_Purchase_Contract_Proof,tbl_user_web",
    //   `tbl_AT_Booklet_Purchase_Contract_Proof.approval =1 and tbl_dfcarz_prosperities.id=tbl_AT_Booklet_Purchase_Contract_Proof.customerid and tbl_user_web.id = tbl_AT_Booklet_Purchase_Contract_Proof.userid and tbl_dfcarz_prosperities.location=${this.state.LocationID}`
    // );
    // console.log(Result)
    let Result = await redisMiddleware.Dashboard("PaymentProcess", 4, this.state.LocationID)
    // console.log(Res)
    if (Result) {

      let TodayPaymentProcess = [];
      //console.log(Fproof);
      // Fproof.data.map((ival, i) => {
      //   TodayPaymentProcess = Result.data.filter(
      //     value => value.id !== ival.customerid
      //   );
      // });
      Result.data.map(async (ival, i) => {
        let Fproof = await CmsContent.getFreedom(
          `*`,
          `tbl_Inventory_Sheet_Reproof`,
          `customerid=${ival.id}`,
          `id`
        );
        if (Fproof) {
          this.setState({ payment: Fproof.data })
        }
        if (!Fproof.data.length) {
          TodayPaymentProcess.push(ival);
        }
      });

      // console.log(TodayPaymentProcess);
      this.setState({ TodayPaymentProcess });
    }
  };

  TodayPhysicalProcess = async () => {
    let TodayPhysicalProcess = [];
    // const Result = await CmsContent.getFreedom(
    //   "tbl_dfcarz_prosperities.*,tbl_user_web.userName as jobroleName",
    //   "tbl_dfcarz_prosperities,tbl_Inventory_Sheet_Reproof,tbl_user_web",
    //   `tbl_Inventory_Sheet_Reproof.approval = 1 and tbl_dfcarz_prosperities.id=tbl_Inventory_Sheet_Reproof.customerid and tbl_user_web.id = tbl_Inventory_Sheet_Reproof.userid and tbl_dfcarz_prosperities.location=${this.state.LocationID} `
    // );
    // console.log(Result)
    let Result = await redisMiddleware.Dashboard("TodayPhysicalProcess", 4, this.state.LocationID)
    // console.log(Res)
    if (Result) {
      //let TodayPaymentProcess =[];
      // Fproof.data.map((ival, i) => {
      //   TodayPhysicalProcess = Result.data.filter(
      //     value => value.id !== ival.customerid
      //   );
      // });
      // console.log(TodayPaymentProcess);
      Result.data.map(async (ival, i) => {
        let Fproof = await CmsContent.getFreedom(
          `*`,
          `tbl_Delivery_Seller_Proof`,
          `customerid = ${ival.id}`,
          `id`
        );
        if (Fproof) {
          this.setState({ physical: Fproof.data })
        }
        if (!Fproof.data.length) {
          TodayPhysicalProcess.push(ival);
        }
      });
      // console.log(TodayPhysicalProcess)
      this.setState({ TodayPhysicalProcess });
    }
  };

  // TodayRefurbishmentProcess=async()=>{
  //   let TodayRefurbishmentProcess=[];
  //   const Result = await CmsContent.getFreedom(
  //     "tbl_dfcarz_prosperities.*",
  //     "tbl_dfcarz_prosperities,tbl_Delivery_Seller_Proof",
  //     `tbl_dfcarz_prosperities.id=tbl_Delivery_Seller_Proof.customerid`
  //   );
  //   if(Result){
  //      const Fproof = await CmsContent.getFreedom(
  //       `*`,
  //       `tbl_checklist350`,
  //       1,
  //       `id`
  //    );
  //    //let TodayPaymentProcess =[];
  //    Fproof.data.map((ival,i)=>{
  //     TodayRefurbishmentProcess = Result.data.filter(value => value.id !== ival.customerid);
  //   });
  //  // console.log(TodayPaymentProcess);
  //  this.setState({TodayRefurbishmentProcess});
  // }
  // };

  Refurbishment = async () => {
    try {
      let TodayRefurbishmentProcess = [];

      // let Result = await CmsContent.getFreedom(
      //   "tbl_dfcarz_prosperities.*,tbl_Delivery_Seller_Proof.Approved_date,tbl_Delivery_Seller_Proof.id as value,tbl_Delivery_Seller_Proof.approval,tbl_user_web.userName as jobroleName",
      //   `tbl_dfcarz_prosperities,tbl_Delivery_Seller_Proof,tbl_user_web`,
      //   `tbl_Delivery_Seller_Proof.approval = 1  and tbl_dfcarz_prosperities.id=tbl_Delivery_Seller_Proof.customerid and tbl_user_web.id = tbl_Delivery_Seller_Proof.userid and tbl_dfcarz_prosperities.location=${this.state.LocationID}`,
      //   1
      // );
      // console.log(Result)
      let Result = await redisMiddleware.Dashboard("Refurbishment", 4, this.state.LocationID)
      // console.log(Result)
      Result.data.map(async (ival, i) => {
        ival.Approved_date = new Date(ival.Approved_date);
        ival.Approved_date.setDate(ival.Approved_date.getDate() + 3);
        //  ival.Approved_date = moment( ival.Approved_date).format("YYYY-MM-DD");
        // console.log(ival.Approved_date.getTime())
        let date = new Date();

        // if(ival.Approved_date.getTime() >= date.getTime()){

        let Refurbishment = await CmsContent.getFreedom(
          `tbl_checklist350.*`,
          `tbl_checklist350,tbl_user_web`,
          `tbl_checklist350.customerid=${ival.id}`
        );
        let refurb = await CmsContent.getFreedom("*", "tbl_checklist350", 1, 1, 1);
        this.setState({ refurb: refurb.data })
        // console.log(Refurbishment)

        // console.log(Refurbishment)
        if (!Refurbishment.data.length) {
          //console.log(Refurbishment);
          // Refurbishment.data.map((jval,j)=>{
          //   jval.jobroleName = ival.jobroleName;
          // })

          TodayRefurbishmentProcess.push(ival);


          this.setState({ TodayRefurbishmentProcess });
        }

      });
    } catch (error) {
      console.log(error);
    }
  };

  tbl_Booking_Confirmation_Reproof = async () => {
    try {
      let PendingContracted = [];
      // let Buyer = await CmsContent.getFreedom(
      //   "tbl_dfcarz_prosperities.*,tbl_user_web.userName as jobroleName",
      //   "tbl_dfcarz_prosperities,tbl_Autoterrace_enquiry_form,tbl_user_web",
      //   `tbl_user_web.id = tbl_Autoterrace_enquiry_form.userid and tbl_dfcarz_prosperities.type='buyer' and tbl_dfcarz_prosperities.id = tbl_Autoterrace_enquiry_form.customerid and tbl_dfcarz_prosperities.location=${this.state.LocationID} `
      // );
      let Buyer = await redisMiddleware.Dashboard("tbl_Booking_Confirmation_Reproof", 4, this.state.LocationID);
      if (Buyer) {
        Buyer.data.map(async (ival, i) => {
          let result = await CmsContent.getFreedom(
            `*`,
            `tbl_Booking_Confirmation_Reproof`,
            `customerid=${ival.id}`
          );
          if (!result.data.length) {
            PendingContracted.push(ival);
          }
        });
      }
      this.setState({ PendingContracted });
    } catch (error) {
      console.log(error);
    }
  };

  tbl_Delivery_Note_Buyer_Reproof = async () => {
    try {
      let PendingDelivery = [];
      // let Buyer = await CmsContent.getFreedom(
      //   "tbl_dfcarz_prosperities.*,tbl_user_web.userName",
      //   "tbl_dfcarz_prosperities,tbl_Booking_Confirmation_Reproof,tbl_user_web",
      //   `tbl_user_web.id = tbl_Booking_Confirmation_Reproof.userid and tbl_dfcarz_prosperities.type='buyer' and tbl_dfcarz_prosperities.id = tbl_Booking_Confirmation_Reproof.customerid and tbl_dfcarz_prosperities.location=${this.state.LocationID}`
      // );
      let Buyer = await redisMiddleware.Dashboard("tbl_Delivery_Note_Buyer_Reproof", 4, this.state.LocationID);
      if (Buyer) {
        Buyer.data.map(async (ival, i) => {
          let result = await CmsContent.getFreedom(
            `*`,
            `tbl_Delivery_Note_Buyer_Reproof`,
            `customerid=${ival.id}`
          );
          if (!result.data.length) {
            PendingDelivery.push(ival);
          }
        });
      }
      this.setState({ PendingDelivery });
    } catch (error) {
      console.log(error);
    }
  };

  MovetoSite = (d) => {
    if (d == "penquiry") {
      //window.open(`/DFCars/DfCarsEnquiry`);
      window.location.href = `/DFCars/DfCarsEnquiry`;
    } else if (d == "F_proof") {
      window.location.href = `/DFCars/DfCarsFproof`;
    } else if (d == "document") {
      window.location.href = `/DFCars/DfCarsdocumentation`;
    } else if (d == "payment") {
      window.location.href = `/DFCars/DfCarspaymentprocess`;
    } else if (d == "physical") {
      window.location.href = `/DFCars/DfCarsphysicalprocess`;
    } else if (d == "refurbishment") {
      window.location.href = `/DFCars/Refurbishment`;
    } else if (d == "readyforsale") {
      window.location.href = `/DFCars/InventoryList`;
    } else if (d == "contracted") {
      window.location.href = `/DFCars/DFContracted`;
    }
  };
  getregno = (id) => {
    let reg = [];
    const { Result1 } = this.state;
    Result1.map((jval, v) => {
      if (jval.customerid == id) {
        reg.push(jval.reg_no)
      }
    })
    return reg;
  }
  getdocregno = (id) => {
    let regno = [];
    const { BookLet } = this.state;
    if (BookLet) {
      BookLet.map((ival, b) => {
        if (ival.customerid == id) {
          regno.push(ival.Reg_No);
        }
      })
    }
    return regno;
  }
  getinvregno = (id) => {
    let regno = [];
    const { payment } = this.state;
    if (payment) {
      payment.map((ival, n) => {
        if (ival.customerid == id) {
          regno.push(ival.reg_no);
        }
      })
    }
    return regno;
  }
  getphyregno = (id) => {
    let regno = [];
    const { physical } = this.state;
    if (physical) {
      physical.map((jval, b) => {
        if (jval.customerid == id) {
          regno.push(jval.reg_no);
        }
      })
    }
    return regno;
  }
  getrefregno = (id) => {
    let regno = [];
    const { refurb } = this.state;
    if (refurb) {
      refurb.map((kval, c) => {
        if (kval.customerid == id) {
          regno.push(kval.reg_no)
        }
      })
    }
    return regno;
  }

  DashboardStats = () => {
    this.setState({ sell: false, buy: false, dash: true })
  }

  handleChange = async (e, type) => {
    const { name, value } = e.target
    await this.setState({ [name]: value })
    if (type == "location") {
      this.setState({ selectlocatiofield: '', [name]: value })
    }
    if (type == "price") {
      await this.dashchart3()
    }
    if (type == "make") {
      await this.dashchart5()
    }
    if (type == "modal") {
      await this.dashchart4()
    }
    if (type == "sellingMake") {
      let { soldStateCars, fromDate, toDate, } = this.state
      let FROMDATE = name == "sellmakefromdate" ? value : fromDate
      let TODATE = name == "sellmaketodate" ? value : toDate
      const MakeMaster = await CmsContent.getFreedom(
        "*",
        "tbl_make_master",
        1
      );
      const filteredData = soldStateCars.filter(item => {
        const createdAt = new Date(item.created_At);
        return createdAt >= new Date(FROMDATE) && createdAt <= new Date(TODATE);
      });

      // Create a map to count the occurrences of each location ID within the date range
      const locationCountMap = filteredData.reduce((acc, curr) => {
        acc[curr.make] = (acc[curr.make] || 0) + 1;
        return acc;
      }, {});

      // Map the counts to the location names
      const result = MakeMaster.data
        .filter(loc => locationCountMap[loc.id]) // Filter out locations with 0 count
        .map(loc => ({ name: loc.Make, value: locationCountMap[loc.id] }));

      // console.log(result);
      if (result) {
        await this.setState({ sellingMakeChartData: result })
      }
    }
    if (type == "sellingColor") {
      let { soldStateCars, fromDate, toDate, } = this.state
      let FROMDATE = name == "sellcolorfromdate" ? value : fromDate
      let TODATE = name == "sellcolortodate" ? value : toDate
      const MakeMaster = await CmsContent.getFreedom(
        "*",
        "tbl_colour",
        1
      );
      const filteredData = soldStateCars.filter(item => {
        const createdAt = new Date(item.created_At);
        return createdAt >= new Date(FROMDATE) && createdAt <= new Date(TODATE);
      });

      // Create a map to count the occurrences of each location ID within the date range
      const locationCountMap = filteredData.reduce((acc, curr) => {
        acc[curr.colour] = (acc[curr.colour] || 0) + 1;
        return acc;
      }, {});

      // Map the counts to the location names
      const result = MakeMaster.data
        .filter(loc => locationCountMap[loc.id]) // Filter out locations with 0 count
        .map(loc => ({ name: loc.colour, value: locationCountMap[loc.id] }));

      // console.log(result);
      if (result) {
        await this.setState({ sellingColourChartData: result })
      }
    }

    if (type == "sellingprice") {
      let { soldStateCars, fromDate, toDate, } = this.state
      let FROMDATE = name == "sellpricefromdate" ? value : fromDate
      let TODATE = name == "sellpricetodate" ? value : toDate
      // const MakeMaster = await CmsContent.getFreedom(
      //   "*",
      //   "tbl_colour",
      //   1
      // );
      const filteredData = soldStateCars.filter(item => {
        const createdAt = new Date(item.created_At);
        return createdAt >= new Date(FROMDATE) && createdAt <= new Date(TODATE);
      });

      // console.log(result);
      if (filteredData) {
        await this.setState({ sellingPriceChartData: filteredData })
      }
    }
    // this.setState({ [name]: value })
  }

  submit = async () => {
    const { newfromdate, newtodate } = this.state
    // console.log("enter here", newfromdate, newtodate, this.state.User.customerId)
    let result = await CmsContent.locationChart(newfromdate, newtodate, this.state.User.customerId)
    // console.log(result, "result")
    this.setState({ locationArray: result.data })
    this.locationchart()
  }
  content = () => {
    const { barchartview, barchartview2 } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid">
          <h1 className="my-2 mt-4 black text-left">Dashboard Stats</h1>
          <hr className="hrColour" />
          <br />
          <br />
          <CanvasJSChart options={barchartview} />
        </div>
      </React.Fragment>
    )
  }
  // canvaschart = (title, array) => {
  //   const options = {
  //     theme: "light1",
  //     animationEnabled: true,
  //     title: {
  //       text: title
  //     },
  //     data: [
  //       {
  //         // Change type to "bar", "area", "spline", "pie",etc.
  //         type: "column",
  //         // click: e => {
  //         //   this.Form(e);
  //         // },
  //         dataPoints: array
  //       }
  //     ]
  //   };
  //   return options;
  // };
  content2 = () => {
    const { barchartview2 } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid">
          <br />
          <CanvasJSChart options={barchartview2} />
        </div>
      </React.Fragment>
    )
  }
  DashboardContent = () => {
    return (

      <div>
        <div className="row-form-group">
          <React.Fragment>
            <div className="card">
              <div
                className="card-header"
                style={{
                  backgroundColor: "lightgray",
                  fontSize: "14px",
                }}
              >
                <h3>
                  One Month Chart
                </h3>
              </div>
              <div className="card-body">
                {this.state.barchartview && this.content()}
                {this.getDate()}
              </div>
            </div>
          </React.Fragment>
        </div>
        <div className="row-form-group">
          <div className="col-sm-12">
            <React.Fragment>
              <div className="card">
                <div
                  className="card-header"
                  style={{
                    backgroundColor: "lightgray",
                    fontSize: "14px",
                  }}
                >
                  <h3>
                    Chart
                  </h3>
                </div>
                <div className="card-body">
                  <div className="row-form-group">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4">
                      <div>
                        <label>From Date </label>
                        <input
                          type="date"
                          id="myDate"
                          name="fromdate"
                          className="form-control"
                          onChange={e =>
                            this.answers1(e)
                          }
                          // value={this.state.date}
                          value={this.state.formdate}
                        // onChange={this.fromDateSelect}
                        // value={selectedstartdate}
                        />
                      </div>
                    </div>
                    <div className="col-sm-4"></div>
                  </div>
                  <br />
                  <div className="row-form-group">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4">
                      <div>
                        <label>To Date </label>
                        <input
                          type="date"
                          id="myDate"
                          name="todate"
                          className="form-control"
                          onChange={e =>
                            this.answers2(e)
                          }
                          // value={this.state.date}
                          value={this.state.formdate}
                        // onChange={this.fromDateSelect}
                        // value={selectedstartdate}
                        />
                      </div>
                    </div>
                    <div className="col-sm-4"></div>
                  </div>
                  <br />
                  <div className="row-form-group">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4">
                      <div>
                        {this.state.usertypelog && this.state.usertypelog.orderBy == 11 || this.state.usertypelog.orderBy == 8 ?
                          <SingleSelect
                            options={this.state.fieldopt1}
                            placeholder="Select Field"
                            handleChange={e => {
                              this.answers3(
                                e
                              );
                            }}
                          />
                          :
                          <SingleSelect
                            options={this.state.fieldopt}
                            placeholder="Select Field"
                            handleChange={e => {
                              this.answers3(
                                e
                              );
                            }}
                          />
                        }
                      </div>
                    </div>
                    <div className="col-sm-4"></div>
                  </div>
                  <br />
                  {this.state.field && this.state.field != null ? <div className="row-form-group">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4">
                      <div>
                        <button
                          type="button"
                          id="submit"
                          name="submit"
                          className="btn btn-primary"
                          onClick={() => {
                            this.onSubmitChart(1)
                          }
                          }
                        >Submit</button>
                      </div>
                    </div>
                    <div className="col-sm-4"></div>
                  </div> : null}
                  {/* {this.state.barchartview2 && this.state.search == true && this.content2()} */}
                  {this.state.loading && this.state.dataLoading === false ? <div className="text-center"> <i
                    className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                  ></i></div> : this.state.barchartview2 ? (
                    this.state.search == true ? <div> {this.content2()} <br /> <h2 className="text-center">{this.state.field.label}</h2></div> : null
                  ) : (<div style={{ textAlign: "center" }} ><b></b></div>)}
                </div>
              </div>
            </React.Fragment>
          </div>
        </div>
        {
          this.state.SearchDash ?
            <>
              <div className="row-form-group">
                <div className="col-sm-12">
                  <React.Fragment>
                    <div className="card">
                      <div
                        className="card-header"
                        style={{
                          backgroundColor: "lightgray",
                          fontSize: "14px",
                        }}
                      >
                        <h3>
                          Search
                        </h3>
                      </div>
                      <div className="card-body">
                        <div className="row-form-group">
                          <div className="col-sm-4"></div>
                          <div className="col-sm-4">
                            <div>
                              <label>Register Number </label>
                              <input
                                type="text"
                                id="myDate"
                                name="RegisterNo"
                                className="form-control"
                                onChange={(e) => this.answers4(e.target.value, 'RegisterNo')}
                                value={this.state.RegisterNo}
                              />
                            </div>
                          </div>
                          <div className="col-sm-4"></div>
                        </div>
                        <br />
                        <div className="row-form-group">
                          <div className="col-sm-4"></div>
                          <div className="col-sm-4">
                            <div>
                              <label>Mobile Number </label>
                              <input
                                type="number"
                                id="myDate"
                                name="MobileNo"
                                className="form-control"
                                onChange={(e) => this.answers5(e.target.value, 'MobileNo')}
                                value={this.state.MobileNo}
                              />
                            </div>
                          </div>
                          <div className="col-sm-4"></div>
                        </div>
                        <br />
                        <div className="row-form-group">
                          <div className="col-sm-4"></div>
                          <div className="col-sm-4">
                            {this.state.isRegno && this.state.isMobNo == false ?
                              <>
                                <div>
                                  <Link to={{
                                    pathname: "/DFCars/CustomerHistory",
                                    state: { suggest: this.state.suggest, type: 'seller' }
                                  }}
                                  > <button
                                    type="button"
                                    id="submit"
                                    name="submit"
                                    className="btn btn-primary"
                                  >Search</button></Link>
                                </div>
                              </>
                              :
                              this.state.isRegno == false && this.state.isMobNo && this.state.MobileNo.length == 10 ?
                                <>
                                  <div>
                                    <Link to={{
                                      pathname: "/DFCars/CustomerHistory",
                                      state: { suggest: this.state.suggest, type: 'seller' }
                                    }}
                                    // onClick={() => window.$('#status').modal('hide')}
                                    > <button
                                      type="button"
                                      id="submit"
                                      name="submit"
                                      className="btn btn-primary"
                                    >Search</button></Link>
                                  </div>
                                </>
                                :
                                this.state.isRegno && this.state.isMobNo && this.state.MobileNo.length == 10 ?
                                  <>
                                    <div>
                                      <Link to={{
                                        pathname: "/DFCars/CustomerHistory",
                                        state: { suggest: this.state.suggest, type: 'seller' }
                                      }}
                                      > <button
                                        type="button"
                                        id="submit"
                                        name="submit"
                                        className="btn btn-primary"
                                      >Search</button></Link>
                                    </div>
                                  </>
                                  :
                                  <></>}

                          </div>
                          <div className="col-sm-4"></div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                </div>
              </div>
            </>
            :
            <>
            </>
        }
      </div>
    )
  }
  AllLoginDashboardContent = () => {
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3>Welcome {this.state.usertypeName}</h3>
                  </div>
                  <br />
                  <ul class="nav nav-tabs">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        data-toggle="tab"
                        href="#dash"
                        onClick={() => this.DashboardStats()}
                      >
                        Dashboard Stats
                      </a>
                    </li>
                    {/* <li class="nav-item">
                      <a
                        class="nav-link"
                        data-toggle="tab"
                        href="#buy"
                        onClick={() => this.buyer()}
                      >
                        Buying
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        data-toggle="tab"
                        href="#sell"
                        onClick={() => this.seller()}
                      >
                        Selling
                      </a>
                    </li> */}

                  </ul>

                  <div className="card-body">
                    <div class="tab-content">
                      <div id="buy" class="container tab-pane ">
                        {this.state.buy && (
                          <div className="row form-group">
                            {this.state.TodayNotifiy.length ? (
                              <div className="col-sm-6">
                                <React.Fragment>
                                  <div className="card">
                                    <div
                                      className="card-header"
                                      style={{
                                        backgroundColor: "lightgray",
                                        fontSize: "14px",
                                      }}
                                    >
                                      <h3>
                                        Enquiries for approval (Comp{" "}
                                        {this.state.CompletedTasks.length} - Pend{" "}
                                        {this.state.TodayNotifiy.length})
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      {this.state.TodayNotifiy.map((ival, i) => {
                                        if (i < 8) {
                                          return (
                                            <React.Fragment>
                                              <div className="row-form-group">
                                                <div className="col-sm-1" />
                                                <div className="col-sm-12">
                                                  <a onClick={() => this.MovetoSite("penquiry")}>
                                                    <table className="dfcarsport">
                                                      <tr>
                                                        <td className="dfcarsportname">
                                                          {ival.name}
                                                        </td>
                                                        <td className="dfcarsportmobicon">
                                                          <i class="fa fa-mobile" aria-hidden="true"></i>
                                                        </td>

                                                        <td className="dfcarsportnumber">
                                                          {ival.mobile}
                                                        </td>
                                                        <td className="dfcarsportreg">
                                                          {ival.Reg_no}
                                                        </td>
                                                      </tr>
                                                    </table>
                                                  </a>
                                                </div>
                                              </div>
                                            </React.Fragment>
                                          );
                                        }
                                      })}
                                    </div>

                                    {this.state.TodayNotifiy.length > 0 ? (
                                      <h5
                                        className="Readmore1"
                                        onClick={() =>
                                          this.MovetoSite("penquiry")
                                        }
                                      >
                                        View more
                                      </h5>
                                    ) : null}
                                  </div>
                                </React.Fragment>
                              </div>
                            ) : null}

                            {this.state.PendingTask.length ? (
                              <div className="col-sm-6">
                                <React.Fragment>
                                  <div className="card">
                                    <div
                                      className="card-header"
                                      style={{
                                        backgroundColor: "lightgray",
                                        fontSize: "14px",
                                      }}
                                    >
                                      <h3>
                                        Untouched Enquiries(Comp{" "}
                                        {this.state.CompletedTasks.length} - pend{" "}
                                        {this.state.PendingTask.length})
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      {this.state.PendingTask.map((ival, i) => {

                                        if (i < 8) {
                                          return (
                                            <React.Fragment>
                                              <div className="row-form-group">
                                                <div className="col-sm-1" />
                                                <div className="col-sm-12">
                                                  <a onClick={() => this.MovetoSite("penquiry")}>
                                                    <table className="dfcarsport">
                                                      <tr>
                                                        <td className="dfcarsportname">
                                                          {ival.name}
                                                        </td>
                                                        <td className="dfcarsportmobicon">
                                                          <i class="fa fa-mobile" aria-hidden="true"></i>
                                                        </td>
                                                        <td className="dfcarsportnumber">
                                                          {ival.mobile}
                                                        </td>
                                                        <td className="dfcarsportreg">
                                                          {ival.Reg_no}
                                                        </td>
                                                      </tr>
                                                    </table>
                                                  </a>
                                                </div>
                                              </div>
                                            </React.Fragment>
                                          );
                                        }
                                      })}
                                      {this.state.PendingTask.length > 0 ? (
                                        <h5
                                          className="Readmore1"
                                          onClick={() =>
                                            this.MovetoSite("penquiry")
                                          }
                                        >
                                          View More
                                        </h5>
                                      ) : null}
                                    </div>
                                  </div>
                                </React.Fragment>
                              </div>
                            ) : null}

                            {this.state.TodayEvaluationF.length ? (
                              <div className="col-sm-6">
                                <React.Fragment>
                                  <div className="card">
                                    <div
                                      className="card-header"
                                      style={{
                                        backgroundColor: "lightgray",
                                        fontSize: "14px",
                                      }}
                                    >
                                      <h3>
                                        Evaluation F-proof (Comp{" "}
                                        {this.state.CompletedTasks.length} - Pend{" "}
                                        {this.state.TodayEvaluationF.length})
                                      </h3>
                                    </div>
                                    <div className="card-body">

                                      {this.state.TodayEvaluationF.map(
                                        (ival, i) => {

                                          if (i < 8) {
                                            return (
                                              <React.Fragment>
                                                <div className="row-form-group">
                                                  <div className="col-sm-1" />
                                                  <div className="col-sm-12">
                                                    <a onClick={() => this.MovetoSite("F_proof")}>
                                                      <table className="dfcarsport">
                                                        <tr>
                                                          <td className="dfcarsportname">
                                                            {ival.name}
                                                          </td>
                                                          <td className="dfcarsportmobicon">
                                                            <i class="fa fa-mobile" aria-hidden="true"></i>
                                                          </td>

                                                          <td className="dfcarsportnumber">
                                                            {ival.mobile}
                                                          </td>
                                                          <td className="dfcarsportreg">
                                                            {this.getregno(ival.id)}
                                                          </td>
                                                        </tr>
                                                      </table>
                                                    </a>
                                                  </div>
                                                </div>

                                              </React.Fragment>
                                            );
                                          }
                                        }
                                      )}
                                      {this.state.TodayEvaluationF.length > 0 ? (
                                        <h5
                                          className="Readmore1"
                                          onClick={() => this.MovetoSite("F_proof")}
                                        >
                                          View More
                                        </h5>
                                      ) : null}
                                    </div>
                                  </div>
                                </React.Fragment>
                              </div>
                            ) : null}

                            {this.state.TodayEvaluationB.length ? (
                              <div className="col-sm-6">
                                <React.Fragment>
                                  <div className="card">
                                    <div
                                      className="card-header"
                                      style={{
                                        backgroundColor: "lightgray",
                                        fontSize: "13px",
                                      }}
                                    >
                                      <h3>
                                        Evaluation B-proof (Comp{" "}
                                        {this.state.CompletedTasks.length} - Pend{" "}
                                        {this.state.TodayEvaluationB.length}){" "}
                                      </h3>
                                    </div>
                                    <div className="card-body">

                                      {this.state.TodayEvaluationB.map(
                                        (ival, i) => {
                                          if (i < 8) {
                                            return (
                                              <React.Fragment>
                                                <div className="row-form-group">
                                                  <div className="col-sm-1" />
                                                  <div className="col-sm-12">
                                                    <a onClick={() => this.MovetoSite("F_proof")}>
                                                      <table className="dfcarsport">
                                                        <tr>
                                                          <td className="dfcarsportname">
                                                            {ival.name}
                                                          </td>
                                                          <td className="dfcarsportmobicon">
                                                            <i class="fa fa-mobile" aria-hidden="true"></i>
                                                          </td>

                                                          <td className="dfcarsportnumber">
                                                            {ival.mobile}
                                                          </td>
                                                          <td className="dfcarsportreg">
                                                            {this.getregno(ival.id)}
                                                          </td>
                                                        </tr>
                                                      </table>
                                                    </a>
                                                  </div>
                                                </div>
                                              </React.Fragment>
                                            );
                                          }
                                        }
                                      )}
                                      {this.state.TodayEvaluationB.length > 0 ? (
                                        <h5
                                          className="Readmore1"
                                          onClick={() => this.MovetoSite("F_proof")}
                                        >
                                          View More
                                        </h5>
                                      ) : null}
                                    </div>
                                  </div>
                                </React.Fragment>
                              </div>
                            ) : null}

                            {this.state.TodayDocumentation.length ? (
                              <div className="col-sm-6">
                                <React.Fragment>
                                  <div className="card">
                                    <div
                                      className="card-header"
                                      style={{
                                        backgroundColor: "lightgray",
                                        fontSize: "14px",
                                      }}
                                    >
                                      <h3>
                                        Documentation to be Approve (Comp{" "}
                                        {this.state.CompletedTasks.length} - pend{" "}
                                        {this.state.TodayDocumentation.length})
                                      </h3>
                                    </div>
                                    <div className="card-body">

                                      {this.state.TodayDocumentation.map(
                                        (ival, i) => {

                                          if (i < 8) {
                                            return (
                                              <React.Fragment>
                                                <div className="row-form-group">
                                                  <div className="col-sm-1" />
                                                  <div className="col-sm-12">
                                                    <a onClick={() => this.MovetoSite("document")}>
                                                      <table className="dfcarsport">
                                                        <tr>
                                                          <td className="dfcarsportname">
                                                            {ival.name}
                                                          </td>
                                                          <td className="dfcarsportmobicon">
                                                            <i class="fa fa-mobile" aria-hidden="true"></i>
                                                          </td>

                                                          <td className="dfcarsportnumber">
                                                            {ival.mobile}
                                                          </td>
                                                          <td className="dfcarsportreg">
                                                            {this.getdocregno(ival.id)}
                                                          </td>
                                                        </tr>
                                                      </table>
                                                    </a>
                                                  </div>
                                                </div>
                                              </React.Fragment>
                                            );
                                          }
                                        }
                                      )}
                                      {this.state.TodayDocumentation.length > 0 ? (
                                        <h5
                                          className="Readmore1"
                                          onClick={() =>
                                            this.MovetoSite("document")
                                          }
                                        >
                                          View More
                                        </h5>
                                      ) : null}
                                    </div>
                                  </div>
                                </React.Fragment>
                              </div>
                            ) : null}

                            {this.state.TodayPaymentProcess.length ? (
                              <div className="col-sm-6">
                                <React.Fragment>
                                  <div className="card">
                                    <div
                                      className="card-header"
                                      style={{
                                        backgroundColor: "lightgray",
                                        fontSize: "14px",
                                      }}
                                    >
                                      <h3>
                                        Payment Process to be Approve (Comp{" "}
                                        {this.state.CompletedTasks.length} - pend{" "}
                                        {this.state.TodayPaymentProcess.length}){" "}
                                      </h3>
                                    </div>
                                    <div className="card-body">

                                      {this.state.TodayPaymentProcess.map(
                                        (ival, i) => {
                                          if (i < 8) {
                                            return (
                                              <React.Fragment>
                                                <div className="row-form-group">
                                                  <div className="col-sm-1" />
                                                  <div className="col-sm-12">
                                                    <a onClick={() => this.MovetoSite("payment")}>
                                                      <table className="dfcarsport">
                                                        <tr>
                                                          <td className="dfcarsportname">
                                                            {ival.name}
                                                          </td>
                                                          <td className="dfcarsportmobicon">
                                                            <i class="fa fa-mobile" aria-hidden="true"></i>
                                                          </td>

                                                          <td className="dfcarsportnumber">
                                                            {ival.mobile}
                                                          </td>
                                                          <td className="dfcarsportreg">
                                                            {this.getinvregno(ival.id)}
                                                          </td>
                                                        </tr>
                                                      </table>
                                                    </a>
                                                  </div>
                                                </div>
                                              </React.Fragment>
                                            );
                                          }
                                        }
                                      )}
                                      {this.state.TodayPaymentProcess.length > 0 ? (
                                        <h5
                                          className="Readmore1"
                                          onClick={() => this.MovetoSite("payment")}
                                        >
                                          View More
                                        </h5>
                                      ) : null}
                                    </div>
                                  </div>
                                </React.Fragment>
                              </div>
                            ) : null}

                            {this.state.TodayPhysicalProcess.length ? (
                              <div className="col-sm-6">
                                <React.Fragment>
                                  <div className="card">
                                    <div
                                      className="card-header"
                                      style={{
                                        backgroundColor: "lightgray",
                                        fontSize: "14px",
                                      }}
                                    >
                                      <h3>
                                        Physical Process to be Approve (Comp{" "}
                                        {this.state.CompletedTasks.length} - pend{" "}
                                        {this.state.TodayPhysicalProcess.length})
                                      </h3>
                                    </div>
                                    <div className="card-body">
                                      {this.state.TodayPhysicalProcess.map(
                                        (ival, i) => {
                                          if (i < 8) {
                                            return (
                                              <React.Fragment>
                                                <div className="row-form-group">
                                                  <div className="col-sm-1" />
                                                  <div className="col-sm-12">
                                                    <a onClick={() => this.MovetoSite("physical")}>
                                                      <table className="dfcarsport">
                                                        <tr>
                                                          <td className="dfcarsportname">
                                                            {ival.name}
                                                          </td>
                                                          <td className="dfcarsportmobicon">
                                                            <i class="fa fa-mobile" aria-hidden="true"></i>
                                                          </td>

                                                          <td className="dfcarsportnumber">
                                                            {ival.mobile}
                                                          </td>
                                                          <td className="dfcarsportreg">
                                                            {this.getphyregno(ival.id)}
                                                          </td>
                                                        </tr>
                                                      </table>
                                                    </a>
                                                  </div>
                                                </div>
                                              </React.Fragment>
                                            );
                                          }
                                        }
                                      )}
                                      {this.state.TodayPhysicalProcess.length >
                                        0 ? (
                                        <h5
                                          className="Readmore1"
                                          onClick={() =>
                                            this.MovetoSite("physical")
                                          }
                                        >
                                          View More
                                        </h5>
                                      ) : null}
                                    </div>
                                  </div>
                                </React.Fragment>
                              </div>
                            ) : null}

                            {this.state.TodayRefurbishmentProcess.length ? (
                              <div className="col-sm-6">
                                <React.Fragment>
                                  <div className="card">
                                    <div
                                      className="card-header"
                                      style={{
                                        backgroundColor: "lightgray",
                                        fontSize: "14px",
                                      }}
                                    >
                                      <h3>
                                        Refurbishment Process to be Approve (Comp{" "}
                                        {this.state.CompletedTasks.length} - pend{" "}
                                        {
                                          this.state.TodayRefurbishmentProcess
                                            .length
                                        }
                                        )
                                      </h3>
                                    </div>
                                    <div className="card-body">

                                      {this.state.TodayRefurbishmentProcess.map(
                                        (ival, i) => {
                                          if (i < 8) {
                                            return (
                                              <React.Fragment>
                                                <div className="row-form-group">
                                                  <div className="col-sm-1" />
                                                  <div className="col-sm-12">
                                                    <a onClick={() => this.MovetoSite("refurbishment")}>
                                                      <table className="dfcarsport">
                                                        <tr>
                                                          <td className="dfcarsportname">
                                                            {ival.name}
                                                          </td>
                                                          <td className="dfcarsportmobicon">
                                                            <i class="fa fa-mobile" aria-hidden="true"></i>
                                                          </td>

                                                          <td className="dfcarsportnumber">
                                                            {ival.mobile}
                                                          </td>
                                                          <td className="dfcarsportreg">
                                                            {this.getrefregno(ival.id)}
                                                          </td>
                                                        </tr>
                                                      </table>
                                                    </a>
                                                  </div>
                                                </div>
                                              </React.Fragment>
                                            );
                                          }
                                        }
                                      )}
                                      {this.state.TodayRefurbishmentProcess.length >
                                        0 ? (
                                        <h5
                                          className="Readmore1"
                                          onClick={() =>
                                            this.MovetoSite("refurbishment")
                                          }
                                        >
                                          View More
                                        </h5>
                                      ) : null}
                                    </div>
                                  </div>
                                </React.Fragment>
                              </div>
                            ) : null}

                            {this.state.Cars.length ? (
                              <div className="col-sm-6">
                                <React.Fragment>
                                  <div className="card">
                                    <div
                                      className="card-header"
                                      style={{
                                        backgroundColor: "lightgray",
                                        fontSize: "14px",
                                      }}
                                    >
                                      <h3>
                                        Ready For Sale (Comp{" "}
                                        {this.state.CompletedTasks.length} - pend{" "}
                                        {this.state.Cars.length})
                                      </h3>
                                    </div>
                                    <div className="card-body">

                                      {this.state.Cars.map((ival, i) => {
                                        if (i < 8) {
                                          return (
                                            <React.Fragment>
                                              <div
                                                className="card-body"
                                                style={{
                                                  marginLeft: "-30px",
                                                  marginTop: "-20px",
                                                }}
                                              >
                                                <table
                                                  class="card-table table"
                                                  align="left"
                                                >
                                                  <thead>
                                                    <tr>
                                                      <th scope="col">Make</th>
                                                      <th scope="col">Model</th>
                                                      <th scope="col">Price</th>
                                                      <th scope="col">Colour</th>
                                                      <br />
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr>
                                                      <td>{ival.makename}</td>
                                                      <td>{ival.modelname}</td>
                                                      <td>{ival.price}</td>
                                                      <td>{ival.ColourName}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>

                                              </div>
                                            </React.Fragment>
                                          );
                                        }
                                      })}
                                      {this.state.Cars.length > 0 ? (
                                        <h5
                                          className="Readmore"
                                          onClick={() =>
                                            this.MovetoSite("readyforsale")
                                          }
                                        >
                                          Read More
                                        </h5>
                                      ) : null}
                                    </div>
                                  </div>
                                </React.Fragment>{" "}
                              </div>
                            ) : null}

                            {this.state.PendingContracted.length ? (
                              <div className="col-sm-6">
                                <React.Fragment>
                                  <div className="card">
                                    <div
                                      className="card-header"
                                      style={{
                                        backgroundColor: "lightgray",
                                        fontSize: "14px",
                                      }}
                                    >
                                      <h3>
                                        Contracted Pending (Comp{" "}
                                        {this.state.CompletedTasks.length} - pend{" "}
                                        {this.state.PendingContracted.length})
                                      </h3>
                                    </div>
                                    <div className="card-body">

                                      {this.state.PendingContracted.map(
                                        (ival, i) => {
                                          if (i < 8) {
                                            return (
                                              <React.Fragment>
                                                <div className="row-form-group">
                                                  <div className="col-sm-1" />
                                                  <div className="col-sm-12">
                                                    <a onClick={() => this.MovetoSite("contracted")}>
                                                      <table className="dfcarsport">
                                                        <tr>
                                                          <td className="dfcarsportname">
                                                            {ival.name}
                                                          </td>
                                                          <td className="dfcarsportmobicon">
                                                            <i class="fa fa-mobile" aria-hidden="true"></i>
                                                          </td>

                                                          <td className="dfcarsportnumber">
                                                            {ival.mobile}
                                                          </td>
                                                          <td className="dfcarsportreg">
                                                            {ival.email}
                                                          </td>
                                                        </tr>
                                                      </table>
                                                    </a>
                                                  </div>
                                                </div>
                                              </React.Fragment>
                                            );
                                          }
                                        }
                                      )}
                                      {this.state.PendingContracted.length > 0 ? (
                                        <h5
                                          className="Readmore1"
                                          onClick={() =>
                                            this.MovetoSite("contracted")
                                          }
                                        >
                                          View More
                                        </h5>
                                      ) : null}
                                    </div>
                                  </div>
                                </React.Fragment>
                              </div>
                            ) : null}
                          </div>
                        )}
                      </div>
                      {this.state.sell && (
                        <div id="sell" class="container tab-pane ">
                          {this.state.PendingDelivery.length ? (
                            <div className="col-sm-6">
                              <React.Fragment>
                                <div className="card">
                                  <div
                                    className="card-header"
                                    style={{
                                      backgroundColor: "lightgray",
                                      fontSize: "14px",
                                    }}
                                  >
                                    <h3>
                                      Delivery Pending (Comp{" "}
                                      {this.state.CompletedTasks.length} - pend{" "}
                                      {this.state.PendingDelivery.length})
                                    </h3>
                                  </div>
                                  <div className="card-body">
                                    {/* {this.state.TodayEvaluation.length ?  */}
                                    {this.state.PendingDelivery.map((ival, i) => {
                                      return (
                                        <div className="row-form-group">
                                          <div className="col-sm-1" />
                                          <div className="col-sm-12">
                                            <React.Fragment>
                                              <a onClick={() => this.MovetoSite("contracted")}>
                                                <table className="dfcarsport">
                                                  <tr>
                                                    <td className="dfcarsportname">
                                                      {ival.name}
                                                    </td>
                                                    <td className="dfcarsportmobicon">
                                                      <i class="fa fa-mobile" aria-hidden="true"></i>
                                                    </td>

                                                    <td className="dfcarsportnumber">
                                                      {ival.mobile}
                                                    </td>
                                                    <td className="dfcarsportreg">
                                                      {ival.email}
                                                    </td>
                                                  </tr>
                                                </table>
                                              </a>
                                            </React.Fragment>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </React.Fragment>
                            </div>
                          ) : null}

                          {this.state.RC_BookPending.length ? (
                            <div className="col-sm-6">
                              <React.Fragment>
                                <div className="card">
                                  <div
                                    className="card-header"
                                    style={{
                                      backgroundColor: "lightgray",
                                      fontSize: "14px",
                                    }}
                                  >
                                    <h3>
                                      RC_Book Pending (Comp{" "}
                                      {this.state.CompletedTasks.length} - pend{" "}
                                      {this.state.RC_BookPending.length})
                                    </h3>
                                  </div>
                                  <div className="card-body">
                                    {/* {this.state.TodayEvaluation.length ?  */}
                                    {this.state.RC_BookPending.map((ival, i) => {
                                      return (
                                        <div className="row-form-group">
                                          <div className="col-sm-1" />
                                          <div className="col-sm-12">
                                            <a>
                                              <table className="dfcarsport">
                                                <tr>
                                                  <td className="dfcarsportname">
                                                    {ival.customer}
                                                  </td>
                                                  <td className="dfcarsportmobicon">
                                                    <i class="fa fa-mobile" aria-hidden="true"></i>
                                                  </td>

                                                  <td className="dfcarsportnumber">
                                                    {ival.mobile}
                                                  </td>
                                                  <td className="dfcarsportreg">
                                                    {ival.Sales}
                                                  </td>
                                                </tr>
                                              </table>
                                            </a>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </React.Fragment>
                            </div>
                          ) : null}
                        </div>
                      )}
                      {this.state.dash && (
                        this.DashboardContent()
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main >
      </React.Fragment >
    );
  }
  handleClick = (num) => {
    if (num == 1) {
      this.setState(prevState => ({
        isFlipped1: !prevState.isFlipped1
      }));
    }
    if (num == 2) {
      this.setState(prevState => ({
        isFlipped2: !prevState.isFlipped2
      }));
    }
    if (num == 3) {
      this.setState(prevState => ({
        isFlipped3: !prevState.isFlipped3
      }));
    }
  }
  dashchart1 = async () => {
    let { locchartdatas } = this.state
    // console.log(locchartdatas, "locchartdatas");

    var chartDom = document.getElementById('main');
    var myChart = echarts.init(chartDom);
    var option;
    option = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        top: '5%',
        left: 'center'
      },
      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 40,
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: locchartdatas
        }
      ]
    };
    option && myChart.setOption(option);
  }
  // dashchart2 = () => {
  //   console.log("it is working")
  //   var chartDom = document.getElementById('main1');
  //   var myChart = echarts.init(chartDom);
  //   var option;
  //   option = {
  //     tooltip: {
  //       trigger: 'axis',
  //       axisPointer: {
  //         type: 'shadow'
  //       }
  //     },
  //     grid: {
  //       left: '3%',
  //       right: '4%',
  //       bottom: '3%',
  //       containLabel: true
  //     },
  //     xAxis: [
  //       {
  //         type: 'category',
  //         data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  //         axisTick: {
  //           alignWithLabel: true
  //         }
  //       }
  //     ],
  //     yAxis: [
  //       {
  //         type: 'value'
  //       }
  //     ],
  //     series: [
  //       {
  //         name: 'Direct',
  //         type: 'bar',
  //         barWidth: '60%',
  //         data: [10, 52, 200, 334, 390, 330, 220]
  //       }
  //     ]
  //   };
  //   option && myChart.setOption(option);
  //   console.log(chartDom, "chartDom")
  // }
  dashchart2 = async () => {

    let { Chart1, Chart2, selectStageField } = this.state
    if (!selectStageField && Chart2 && Chart2.length == 0) {
      await this.onSubmitChart(1)
      return false
    }

    let selectStageField1 = selectStageField ? selectStageField : this.state.fieldopt[0]
    // console.log(selectStageField1,"selectStageField1");
    let xAxisData;
    let seriesData
    if (Chart2 && Chart2.length && selectStageField1 && selectStageField1.value == '1') {
      // console.log("ENTER 11");
      xAxisData = Chart2[0].enq.map(item => item.x);
      seriesData = Chart2[0].enq.map(item => item.y);

    } else if (Chart2 && Chart2.length && selectStageField1 && selectStageField1.value == '2') {
      xAxisData = Chart2[0].eval.map(item => item.x);
      seriesData = Chart2[0].eval.map(item => item.y);

    } else if (Chart2 && Chart2.length && selectStageField1 && selectStageField1.value == '3') {
      xAxisData = Chart2[0].doc.map(item => item.x);
      seriesData = Chart2[0].doc.map(item => item.y);

    } else if (Chart2 && Chart2.length && selectStageField1 && selectStageField1.value == '4') {
      xAxisData = Chart2[0].phy.map(item => item.x);
      seriesData = Chart2[0].phy.map(item => item.y);

    } else if (Chart1 && Chart1.length) {
      xAxisData = Chart1[0].enq.map(item => item.x);
      seriesData = Chart1[0].enq.map(item => item.y);

    }
    // console.log(xAxisData,"xAxisData");
    // console.log(seriesData,"seriesData");

    //  xAxisData = Chart1?Chart1:Chart2 .map(item => item.x);
    //  seriesData =  Chart1?Chart1:Chart2 .map(item => item.y);

    var chartDom = document.getElementById('main1');
    var myChart = echarts.init(chartDom);

    var option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: xAxisData,
          axisTick: {
            alignWithLabel: true
          }
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: [
        {
          name: 'Data',
          type: 'bar',
          barWidth: '60%',
          data: seriesData
        }
      ]
    };
    option && myChart.setOption(option);
    // console.log(chartDom, "chartDom");
  }
  dashchart3 = async () => {
    let { pricefromDate, pricetoDate, fromDate, toDate, BuyerInventroydata } = this.state

    //   let results = await CmsContent.BUYDFCARZ(this.state.User.id, 'inventlist')
    //  let BuyerInventroydata = results.Inventory.filter(a => a.status !== "inactive" && a.ongoing !== 1)

    let filter_price = BuyerInventroydata.filter((item) => {
      // const itemDate = new Date(item.created_At).toISOString().split('T')[0];
      const itemDate = new Date(item.created_At)
      let startDate, endDate;
      if (pricefromDate || pricetoDate || fromDate || toDate) {
        startDate = new Date(pricefromDate ? pricefromDate : fromDate);
        endDate = new Date(pricetoDate ? pricetoDate : toDate);
      }
      else {
        return true;
      }

      if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
        return false;
      }
      // return itemDate >= startDate.toISOString().split('T')[0] && itemDate <= endDate.toISOString().split('T')[0];
      return itemDate >= startDate && itemDate <= endDate

    });
    const dataPoints = [];

    let Data = filter_price.map((ival, i) => {
      dataPoints.push({ label: ival.created_At, y: ival.price })
    })
    // console.log(dataPoints,"dataPoints");

    // var chart = new window.CanvasJS.Chart("main2", {
    //   animationEnabled: true,
    //   // exportEnabled: true,
    //   theme: 'light1',
    //   // width: 1200,
    //   // title: {
    //   //     text: "price chart"
    //   // },
    //   axisX: {
    //     valueFormatString: "DD MMM",
    //     crosshair: {
    //       enabled: true,
    //       snapToDataPoint: true
    //     }
    //   },
    //   axisY: {
    //     valueFormatString: "₹ ##0.00",
    //     crosshair: {
    //       enabled: true,
    //       snapToDataPoint: true,
    //       labelFormatter: function (e) {
    //         return "$" + window.CanvasJS.formatNumber(e.value, "₹ ##0.00");
    //       }
    //     }
    //   },
    //   data: [{
    //     type: "area",
    //     xValueFormatString: "DD MMM",
    //     yValueFormatString: "₹ ##0.00",
    //     dataPoints: dataPoints,
    //   }]
    // });
    // chart.render();

    // // console.log("it is working")
    // Initialize the chart
    var chartDom = document.getElementById('main2');
    var myChart = echarts.init(chartDom);

    // Sample data array of objects
    // const data = [
    //   { label: '2024-08-19 13:00:38', y: 645000 },
    //   { label: '2024-08-19 12:52:56', y: 580000 },
    //   { label: '2024-08-19 12:48:59', y: 275000 }
    // ];

    // Map the data for ECharts
    const xAxisData = dataPoints.map(item => item.label); // Extract labels for xAxis
    const seriesData = dataPoints.map(item => item.y);    // Extract values for series

    var option = {
      tooltip: {
        trigger: 'axis', // Show tooltip for the entire axis
        axisPointer: {
          type: 'cross', // Type of the axis pointer
          crossStyle: {
            color: '#999'
          }
        },
        formatter: function (params) {
          // Format the tooltip content
          let tooltipContent = '';
          params.forEach(param => {
            tooltipContent += `${param.seriesName}: ${param.value} (${param.name})<br/>`;
          });
          return tooltipContent;
        }
      },
      xAxis: {
        type: 'category',
        data: xAxisData, // Use the mapped xAxis data
        // axisLabel: {
        //   rotate: 45, // Rotate labels if they are too long
        //   formatter: function (value) {
        //     // Format the date or timestamp if needed
        //     return value;
        //   }
        // }
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          name: 'Value', // Series name for the tooltip
          data: seriesData, // Use the mapped series data
          type: 'line',
          smooth: true
        }
      ]
    };

    // Set the chart options
    option && myChart.setOption(option);

    // var chartDom = document.getElementById('main2');
    // var myChart = echarts.init(chartDom);
    // var option;
    // option = {
    //   xAxis: {
    //     type: 'category',
    //     data: dataPoints && dataPoints.map(item =>item.created_At)
    //   },
    //   yAxis: {
    //     type: 'value'
    //   },
    //   series: [
    //     {
    //       data: dataPoints && dataPoints.map(item =>item.price),
    //       type: 'line',
    //       smooth: true
    //     }
    //   ]
    // };
    // option && myChart.setOption(option);
  }
  dashchart4 = async () => {
    let { selectModalField, fromDate, toDate, modalfromDate, modaltoDate, BuyerEnquiryData, ModalOptions, MakeOptions } = this.state
    // let EnquiryReport = await CmsContent.getDfcarzReportEnquiry("id", "seller")
    // let BuyerEnquiryData= EnquiryReport.statusdata[0].Buyer


    const filtered_Enqdata = BuyerEnquiryData.filter(item => {
      let itemDate = new Date(item.createdAt);

      let startDate, endDate;
      if (modalfromDate || modaltoDate || fromDate || toDate) {
        startDate = new Date(modalfromDate ? modalfromDate : fromDate);
        endDate = new Date(modaltoDate ? modaltoDate : toDate);
      }
      else {
        return true;
      }
      if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
        return false;
      }

      return itemDate >= new Date(startDate) && itemDate <= new Date(endDate);
    });
    let dataPoints1 = []
    MakeOptions.map((MakeOption, j) => {
      const filtered_BuyerEnquirydata = filtered_Enqdata.filter(item => {
        let itemMake = item.Make;

        if (itemMake == MakeOption.value) {
          return itemMake == MakeOption.value;
        }

      });

      if (filtered_BuyerEnquirydata && filtered_BuyerEnquirydata.length) {

        dataPoints1.push({ label: filtered_BuyerEnquirydata[0].makename, y: filtered_BuyerEnquirydata.length, value: filtered_BuyerEnquirydata[0].Make })
      }
    })
    await this.setState({ modalchartOption: dataPoints1 })
    // let allOpt = await this.decryption('getcar')
    // let ModalOptions= allOpt.modelopt  
    // console.log(dataPoints1, "dataPoints1");
    let selectModalField1 = selectModalField ? selectModalField : dataPoints1[0]

    const dataPoints = [];
    if (selectModalField1) {

      const datas = filtered_Enqdata.filter(item => {
        let itemMake = item.Make;
        // console.log(itemMake,"itemMake");
        if (selectModalField1) {
          return itemMake == selectModalField1.value;

        }

        return true;
      });
      ModalOptions.map((ModalOption, j) => {
        const filtered_BuyerEnquirydata = datas.filter(item => {
          let itemMake = item.Model;


          if (itemMake == ModalOption.value) {
            return itemMake == ModalOption.value;
          }

        });
        if (filtered_BuyerEnquirydata && filtered_BuyerEnquirydata.length) {

          dataPoints.push({ label: filtered_BuyerEnquirydata[0].modelname, y: filtered_BuyerEnquirydata.length })
        }
      })
      // console.log(dataPoints, "dataPoints");
    }


    var chartDom = document.getElementById('main3');
    // chartDom.style.width = '400px'; // Set the width
    // chartDom.style.height = '300px'; // Set the height

    var myChart = echarts.init(chartDom);



    const chartData = dataPoints.map(item => ({
      value: item.y,
      name: item.label
    }));

    var option = {
      tooltip: {
        trigger: 'item',
        formatter: function (params) {
          return `${params.name}: ${params.value}`;
        }
      },
      legend: {
        top: 'bottom'
      },
      toolbox: {
        show: true,
        feature: {
          mark: { show: true },
          dataView: { show: true, readOnly: false },
          restore: { show: true },
          saveAsImage: { show: true }
        }
      },
      series: [
        {
          name: 'Nightingale Chart',
          type: 'pie',
          radius: [50, 250],
          center: ['50%', '50%'],
          roseType: 'area',
          itemStyle: {
            borderRadius: 8
          },
          data: chartData
        }
      ]
    };

    option && myChart.setOption(option);

    // console.log("it is working")
    // var chartDom = document.getElementById('main3');
    // var myChart = echarts.init(chartDom);
    // var option;
    // option = {
    //   legend: {
    //     top: 'bottom'
    //   },
    //   toolbox: {
    //     show: true,
    //     feature: {
    //       mark: { show: true },
    //       dataView: { show: true, readOnly: false },
    //       restore: { show: true },
    //       saveAsImage: { show: true }
    //     }
    //   },
    //   series: [
    //     {
    //       name: 'Nightingale Chart',
    //       type: 'pie',
    //       radius: [50, 250],
    //       center: ['50%', '50%'],
    //       roseType: 'area',
    //       itemStyle: {
    //         borderRadius: 8
    //       },
    //       data: [
    //         { value: 40, name: 'rose 1' },
    //         { value: 38, name: 'rose 2' },
    //         { value: 32, name: 'rose 3' },
    //         { value: 30, name: 'rose 4' },
    //         { value: 28, name: 'rose 5' },
    //         { value: 26, name: 'rose 6' },
    //         { value: 22, name: 'rose 7' },
    //         { value: 18, name: 'rose 8' }
    //       ]
    //     }
    //   ]
    // };
    // option && myChart.setOption(option);
  }
  dashchart5 = async () => {
    let { makefromDate, maketoDate, fromDate, toDate, BuyerEnquiryData, MakeOptions } = this.state
    // let EnquiryReport = await CmsContent.getDfcarzReportEnquiry("id", "seller")
    // let BuyerEnquiryData= EnquiryReport.statusdata[0].Buyer


    const filtered_Enqdata = BuyerEnquiryData.filter(item => {
      let itemDate;
      itemDate = new Date(item.createdAt)

      let startDate, endDate;
      if (makefromDate || maketoDate || fromDate || toDate) {
        startDate = new Date(makefromDate ? makefromDate : fromDate);
        endDate = new Date(maketoDate ? maketoDate : toDate);
      }
      else {
        return true;
      }
      if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
        return false;
      }

      return itemDate >= new Date(startDate) && itemDate <= new Date(endDate);
    });

    // let allOpt = await this.decryption('getcar')
    // let  MakeOptions= allOpt.makeopt
    let dataPoints = []
    MakeOptions.map((MakeOption, j) => {
      const filtered_BuyerEnquirydata = filtered_Enqdata.filter(item => {
        let itemMake = item.Make;

        if (itemMake == MakeOption.value) {
          return itemMake == MakeOption.value;
        }

      });

      if (filtered_BuyerEnquirydata && filtered_BuyerEnquirydata.length) {

        dataPoints.push({ label: filtered_BuyerEnquirydata[0].makename, y: filtered_BuyerEnquirydata.length, value: filtered_BuyerEnquirydata[0].Make })
      }
    })


    var chartDom = document.getElementById('main4');
    var myChart = echarts.init(chartDom);


    const xAxisData = dataPoints.map(item => item.label);
    const seriesData = dataPoints.map(item => item.y);
    var option = {
      tooltip: {
        trigger: 'item',
        formatter: function (params) {
          const item = dataPoints[params.dataIndex];
          return `${item.label}: ${item.y}`;
        }
      },
      xAxis: {
        type: 'category',
        data: xAxisData
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          data: seriesData,
          type: 'bar',
          showBackground: true,
          backgroundStyle: {
            color: 'rgba(180, 180, 180, 0.2)'
          }
        }
      ]
    };

    option && myChart.setOption(option);


    // var chartDom = document.getElementById('main4');
    // var myChart = echarts.init(chartDom);
    // var option;
    // option = {
    //   xAxis: {
    //     type: 'category',
    //     data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    //   },
    //   yAxis: {
    //     type: 'value'
    //   },
    //   series: [
    //     {
    //       data: [120, 200, 150, 80, 70, 110, 130],
    //       type: 'bar',
    //       showBackground: true,
    //       backgroundStyle: {
    //         color: 'rgba(180, 180, 180, 0.2)'
    //       }
    //     }
    //   ]
    // };
    // option && myChart.setOption(option);
  }
  sellingCharts1 = () => {
    let { sellingStatgeChartData } = this.state
    console.log(sellingStatgeChartData, "sellingStatgeChartData")
    var chartDom = document.getElementById('sellmain1');
    var myChart = echarts.init(chartDom);
    var option;
    option = {
      xAxis: {
        type: 'category',
        data: sellingStatgeChartData && sellingStatgeChartData.map((item => item.name))
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          data: sellingStatgeChartData && sellingStatgeChartData.map((item => item.value)),
          type: 'bar',
          showBackground: true,
          backgroundStyle: {
            color: 'rgba(180, 180, 180, 0.2)'
          }
        }
      ]
    };
    option && myChart.setOption(option);
    // console.log(chartDom, "chartdom")
  }
  sellingCharts2 = () => {
    let { sellingLocatChartData } = this.state
    var chartDom = document.getElementById('sellmain2');
    var myChart = echarts.init(chartDom);
    var option;
    option = {
      // title: {
      //   text: 'Referer of a Website',
      //   subtext: 'Fake Data',
      //   left: 'center'
      // },
      tooltip: {
        trigger: 'item'
      },
      legend: {
        orient: 'vertical',
        left: 'left'
      },
      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: '50%',
          data: sellingLocatChartData,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };
    option && myChart.setOption(option);
    // console.log(chartDom, "chartdom")
  }
  sellingCharts3 = () => {
    let { sellingMakeChartData } = this.state
    let mapName = sellingMakeChartData && sellingMakeChartData.map(item => item.name)
    var chartDom = document.getElementById('sellmain3');
    var myChart = echarts.init(chartDom);
    var option;
    option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      xAxis: {
        type: 'category',
        axisTick: {
          alignWithLabel: true
        },
        data: sellingMakeChartData && sellingMakeChartData.map(item => item.name)
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          data: sellingMakeChartData && sellingMakeChartData.map(item => item.value),
          type: 'bar',
          showBackground: true,
          backgroundStyle: {
            color: 'rgba(180, 180, 180, 0.2)'
          }
        }
      ]
    };
    option && myChart.setOption(option);
    // console.log(chartDom, "chartdom")
  }
  sellingCharts4 = () => {
    let { sellingPriceChartData } = this.state
    var chartDom = document.getElementById('sellmain4');
    var myChart = echarts.init(chartDom);
    var option;
    option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      xAxis: {
        type: 'category',
        data: sellingPriceChartData && sellingPriceChartData.map(item => item.created_At)
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          data: sellingPriceChartData && sellingPriceChartData.map(item => item.price),
          type: 'line',
          smooth: true
        }
      ]
    };
    option && myChart.setOption(option);
    // console.log(chartDom, "chartdom")
  }
  sellingCharts5 = () => {
    let { sellingColourChartData } = this.state;

    // Define a color map for dynamic coloring
    const colorMap = {
      'Red': '#ff0000',
      'Green': '#00ff00',
      'Yellow': '#ffff00',
      'Blue': '#0000ff',
      'Purple': '#800080',
      'Silver': '#c0c0c0',
      'BLACK': '#000000',
      'WHITE': '#ffffff',
      'GREY': '#808080',
      'ORANGE': '#ffa500',
      'GOLD': '#ffd700',
      'BROWN': '#a52a2a'
    };

    // Assign colors to each data item
    const chartData = sellingColourChartData && sellingColourChartData.map(item => ({
      ...item,
      itemStyle: {
        color: colorMap[item.name] || '#000000'  // Fallback color if not found
      }
    }));

    var chartDom = document.getElementById('sellmain5');
    var myChart = echarts.init(chartDom);
    var option;

    option = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        top: '5%',
        left: 'center'
      },
      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: ['40%', '70%'],
          center: ['50%', '70%'],
          startAngle: 180,
          endAngle: 360,
          data: chartData // Use the updated chartData with colors
        }
      ]
    };

    option && myChart.setOption(option);
  };
  getMonthWiseData = (array, FROMDATE, TODATE, date) => {
    // Step 1: Filter data based on the date range
    const filteredData = array.filter(item => {
      const createdAt = new Date(item[date]); // Use dynamic key for the date field
      return createdAt >= new Date(FROMDATE) && createdAt <= new Date(TODATE);
    });
    // Step 2: Create a map to count the occurrences of each month
    const monthCountMap = filteredData.reduce((acc, curr) => {
      const month = new Date(curr[date]).toLocaleString('default', { month: 'short' }).toLowerCase(); // Get month in short format, e.g., 'aug'
      acc[month] = (acc[month] || 0) + 1;
      return acc;
    }, {});
    const monthOrder = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
    const dataArray = Object.entries(monthCountMap);
    const sortedArray = dataArray.sort((a, b) => monthOrder.indexOf(a[0]) - monthOrder.indexOf(b[0]));
    const sortedData = Object.fromEntries(sortedArray);
    const result = Object.keys(sortedData).map(month => ({
      name: month,
      value: sortedData[month]
    }));
    return result;
  }
  selectField = async (e, type) => {
    if (type == "location") {
      await this.setState({ selectlocatiofield: e })
      let { fromDate, toDate, locfromdate, loctodate, userid } = this.state
      if (locfromdate || loctodate || fromDate || toDate) {
        let datas = await CmsContent.getlocationdata(locfromdate ? locfromdate : fromDate, loctodate ? loctodate : toDate, userid, e.value)
        await this.setState({ locchartdatas: datas })
        await this.dashchart1()
      }

    }
    if (type == 'stage') {
      await this.setState({ selectStageField: e })
      await this.onSubmitChart(1)

    }
    if (type == 'modal') {
      await this.setState({ selectModalField: e })
      await this.dashchart4()
    }
    if (type == "sellingLocation") {
      this.setState({ selectSelLocatField: e })
      let { soldStateCars, availableStageCars, sellingEnqData, fromDate, toDate, sellloctodate, selllocfromdate } = this.state
      let FROMDATE = selllocfromdate ? selllocfromdate : fromDate
      let TODATE = sellloctodate ? sellloctodate : toDate

      const LocationMaster = await CmsContent.getFreedom(
        "*",
        "tbl_location",
        1
      );
      if (e.value == '1') {
        const filteredData = sellingEnqData.filter(item => {
          const createdAt = new Date(item.createdAt);
          return createdAt >= new Date(FROMDATE) && createdAt <= new Date(TODATE);
        });

        // Create a map to count the occurrences of each location ID within the date range
        const locationCountMap = filteredData.reduce((acc, curr) => {
          acc[curr.location] = (acc[curr.location] || 0) + 1;
          return acc;
        }, {});

        // Map the counts to the location names
        const result = LocationMaster.data
          .filter(loc => locationCountMap[loc.id]) // Filter out locations with 0 count
          .map(loc => ({ name: loc.name, value: locationCountMap[loc.id] }));

        // console.log(result);
        if (result) {
          await this.setState({ sellingLocatChartData: result })
        }

      } else if (e.value == "2") {
        const filteredData = soldStateCars.filter(item => {
          const createdAt = new Date(item.created_At);
          return createdAt >= new Date(FROMDATE) && createdAt <= new Date(TODATE);
        });

        // Create a map to count the occurrences of each location ID within the date range
        const locationCountMap = filteredData.reduce((acc, curr) => {
          acc[curr.location] = (acc[curr.location] || 0) + 1;
          return acc;
        }, {});

        // Map the counts to the location names
        const result = LocationMaster.data
          .filter(loc => locationCountMap[loc.id]) // Filter out locations with 0 count
          .map(loc => ({ name: loc.name, value: locationCountMap[loc.id] }));
        if (result) {
          await this.setState({ sellingLocatChartData: result })
        }

      } else if (e.value == "3") {
        const filteredData = availableStageCars.filter(item => {
          const createdAt = new Date(item.created_At);
          return createdAt >= new Date(FROMDATE) && createdAt <= new Date(TODATE);
        });

        // Create a map to count the occurrences of each location ID within the date range
        const locationCountMap = filteredData.reduce((acc, curr) => {
          acc[curr.location] = (acc[curr.location] || 0) + 1;
          return acc;
        }, {});

        // Map the counts to the location names
        const result = LocationMaster.data
          .filter(loc => locationCountMap[loc.id]) // Filter out locations with 0 count
          .map(loc => ({ name: loc.name, value: locationCountMap[loc.id] }));

        console.log(result);

        if (result) {
          await this.setState({ sellingLocatChartData: result })
        }

      }
    }
    if (type == "sellingStage") {
      let { sellingEnqData, fromDate, toDate, sellingRcData, sellingDeliveryData, sellingContractData, sellingLeadData, sellstagefromdate, sellstagetodate } = this.state
      let FROMDATE = sellstagefromdate ? sellstagefromdate : fromDate
      let TODATE = sellstagetodate ? sellstagetodate : toDate

      if (e.value == "1") {
        let result = this.getMonthWiseData(sellingEnqData, FROMDATE, TODATE, "createdAt")
        if (result) {
          await this.setState({ sellingStatgeChartData: result })
        }
      } else if (e.value == "2") {
        let result = this.getMonthWiseData(sellingLeadData, FROMDATE, TODATE, "createdAt")
        if (result) {
          await this.setState({ sellingStatgeChartData: result })
        }

      } else if (e.value == "3") {
        let result = this.getMonthWiseData(sellingContractData, FROMDATE, TODATE, "createdAt")
        if (result) {
          await this.setState({ sellingStatgeChartData: result })
        }

      } else if (e.value == "4") {
        let result = this.getMonthWiseData(sellingDeliveryData, FROMDATE, TODATE, "created_At")
        if (result) {
          await this.setState({ sellingStatgeChartData: result })
        }

      } else if (e.value == "5") {
        let result = this.getMonthWiseData(sellingRcData, FROMDATE, TODATE, "created_At")
        if (result) {
          await this.setState({ sellingStatgeChartData: result })
        }

      }
    }
  }
  updatetab = async (truId, falseId1, falsId2) => {
    let trueTab = document.getElementById(truId)
    let falsetab1 = document.getElementById(falseId1)
    let falseTab2 = document.getElementById(falsId2)

    trueTab.classList.add("Dashnav-item")
    trueTab.classList.remove("nav-item")
    falsetab1.classList.add("nav-item")
    falsetab1.classList.remove("Dashnav-item")
    falseTab2.classList.add("nav-item")
    falseTab2.classList.remove("Dashnav-item")

  }

  buyer = async () => {
    await this.setState({ buy: true, sell: false, search: false })
    await this.updatetab("buying", "selling", "search")

    await this.dashchart1()
    await this.dashchart2()
    await this.dashchart3()
    await this.dashchart4()
    await this.dashchart5()

  }
  seller = () => {
    this.setState({ sell: true, buy: false, search: false })
    this.updatetab("selling", "buying", "search")


  }
  search = () => {
    this.setState({ sell: false, buy: false, search: true, })
    this.updatetab("search", "selling", "buying")
  }
  cardCharts1 = () => {
    var chartDom = document.getElementById('cardChart1');
    var myChart = echarts.init(chartDom);
    var option;
    option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        left: '10%',
        right: '4%',
        bottom: '3%',
      },
      xAxis: [
        {
          type: 'category',
          data: ["enquiry ", "evaluation", "Documentation", 'physicalprocess', 'refrbishment'],
          axisTick: {
            alignWithLabel: true
          }
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: [
        {
          name: 'Direct',
          type: 'bar',
          barWidth: '25%',
          data: [this.state.enquiryData, this.state.evaluationData, this.state.documentationData, this.state.PhysicalProcessData, this.state.refurbishmentData]
        }
      ]
    };
    option && myChart.setOption(option);
  }
  cardCharts2 = () => {
    var chartDom = document.getElementById('cardChart2');
    var myChart = echarts.init(chartDom);
    var option;
    option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        left: '10%',
        right: '4%',
        bottom: '3%',
      },
      xAxis: [
        {
          type: 'category',
          data: ["Enquiry ", "Lead", "Contract", 'Delivery', 'Rc Tranfer', 'Referbishment'],
          axisTick: {
            alignWithLabel: true
          }
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: [
        {
          name: 'Direct',
          type: 'bar',
          barWidth: '25%',
          data: [this.state.sellingData, this.state.leadData, this.state.contractData, this.state.deliveryData, this.state.rcTransferData, this.state.refurbishmentData]
        }
      ]
    };
    option && myChart.setOption(option);
  }
  cardCharts3 = () => {
    var chartDom = document.getElementById('cardChart3');
    var myChart = echarts.init(chartDom);
    var option;
    option = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        top: '7%',
        left: 'center'
      },
      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: ['40%', '70%'],
          center: ['50%', '70%'],
          startAngle: 180,
          endAngle: 360,
          data: [
            { value: this.state.availableCarsData, name: 'Availablecars' },
            { value: this.state.soldCarsData, name: 'Soldcars' },
            { value: this.state.allCarsData, name: 'Allcars' },
          ]
        }
      ]
    };
    option && myChart.setOption(option);
  }
  AllLoginDashboardContentPoc = () => {
    if (this.state.dataLoading && !this.state.loading) {
      return (
        <React.Fragment>
          <main className="main" >
            <div class="Dashloader">
              <div class="DashcarWrapper">
                <div class="DashcarBody">
                  <img src={loadingCar} alt="Car" class="DashcarImage" />
                </div>
                <div class="DashcarTires">
                  <div class="Dashtext" > <pre>.  .  . Please wait</pre></div>
                </div>
              </div>
            </div>
          </main>
        </React.Fragment>
      )
    } else {

      return (
        <React.Fragment>
          <main className="main py-1 px-4" >
            <section id="sec-dashboard" style={{}}>
              <div className="para-dashboard py-1">
                <p>Dashboard</p>
              </div>
              <div className="row justify-content-between align-items-center my-0">
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-3 mt-sm-0 px-2">
                  <div className={`flip-card ${this.state.isFlipped1 ? "flipped" : ""}`} onClick={() => this.handleClick(1)}>
                    <div className="flip-card-inner">
                      <div className="flip-card-front">
                        <div className="para-head-1 my-2">
                          <p>Buying</p>
                        </div>
                        <div className="" id="cardChart1" style={{ height: "250px", width: "100%" }}>

                        </div>
                        <div className="card-border-1">
                          <p className="view-details">Click to see the details</p>
                          <div className="card-line-1">
                          </div>
                        </div>
                      </div>
                      <div className="flip-card-back">
                        <div className="px-3">
                          <div className="para-head-1 my-2">
                            <p>Buying Details</p>
                          </div>
                          <div className="back-card-details px-5">
                            <div className="row justify-content-between align-items-center back-card-para my-3">
                              <p>Enquiry</p>
                              <p><a href="/DFCars/viewDashboard/buyingenquiry" title="view data" target="blank">{this.state.enquiryData}</a></p>
                            </div>
                            <div className="row justify-content-between align-items-center back-card-para my-3">
                              <p>Evaluation</p>
                              <p><a href="/DFCars/viewDashboard/evaluation" title="view data" target="blank">{this.state.evaluationData}</a></p>
                            </div>
                            <div className="row justify-content-between align-items-center back-card-para my-3">
                              <p>Documentation</p>
                              <p><a href="/DFCars/viewDashboard/documentation" title="view data" target="blank">{this.state.documentationData}</a></p>
                            </div>
                            <div className="row justify-content-between align-items-center back-card-para my-3">
                              <p>PhysicalProcess</p>
                              <p><a href="/DFCars/viewDashboard/physicalprocess" title="view data" target="blank">{this.state.PhysicalProcessData}</a></p>
                            </div>
                            <div className="row justify-content-between align-items-center back-card-para my-3">
                              <p>Refurbishment</p>
                              <p><a href="/DFCars/viewDashboard/refurbishment" title="view data" target="blank">{this.state.refurbishmentData}</a></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-3 mt-sm-0">
                  <div className={`flip-card ${this.state.isFlipped3 ? "flipped" : ""}`} onClick={() => this.handleClick(3)}>
                    <div className="flip-card-inner">
                      <div className="flip-card-front">
                        <div className="para-head-1 my-2">
                          <p>Inventory List</p>
                        </div>
                        <div className="" id="cardChart3" style={{ height: "250px", width: "100%" }}>

                        </div>
                        <div className="card-border-1">
                          <p className="view-details">Click to see the details</p>
                          <div className="card-line-1">
                          </div>
                        </div>
                      </div>
                      <div className="flip-card-back">
                        <div className="para-head-1 my-2">
                          <p>Inventory  Details</p>
                        </div>
                        <div className="back-card-details px-5">
                          <div className="row justify-content-between align-items-center back-card-para my-3">
                            <p>All Cars</p>
                            <p><a href="/DFCars/viewDashboard/allcars" title="view data" target="blank">{this.state.allCarsData}</a></p>
                          </div>
                          <div className="row justify-content-between align-items-center back-card-para my-3">
                            <p>Sold cars</p>
                            <p><a href="/DFCars/viewDashboard/soldcars" title="view data" target="blank">{this.state.soldCarsData}</a></p>
                          </div>
                          <div className="row justify-content-between align-items-center back-card-para my-3">
                            <p>Available cars</p>
                            <p><a href="/DFCars/viewDashboard/availablecars" title="view data" target="blank">{this.state.availableCarsData}</a></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-3 mt-sm-0">
                  <div className={`flip-card ${this.state.isFlipped2 ? "flipped" : ""}`} onClick={() => this.handleClick(2)}>
                    <div className="flip-card-inner">
                      <div className="flip-card-front">
                        <div className="para-head-1 my-2">
                          <p>Selling</p>
                        </div>
                        <div className="" id="cardChart2" style={{ height: "250px", width: "100%" }}>
                        </div>

                        {/* <div className="card-image-1">
                        <img src="https://thumbs.dreamstime.com/b/buying-renting-new-used-speedy-sports-car-red-dealer-giving-keys-chain-to-buyer-hand-modern-flat-style-vector-82275560.jpg" />
                      </div> */}
                        <div className="card-border-1">
                          <p className="view-details">Click to see the details</p>
                          <div className="card-line-1">
                          </div>
                        </div>
                      </div>
                      <div className="flip-card-back">
                        <div className="para-head-1 my-2">
                          <p>Selling Details</p>
                        </div>
                        <div className="back-card-details px-5">
                          <div className="row justify-content-between align-items-center back-card-para my-3">
                            <p>Enquiry</p>
                            <p><a href="/DFCars/viewDashboard/sellingenquiry" title="view data" target="blank">{this.state.sellingData}</a></p>
                          </div>
                          <div className="row justify-content-between align-items-center back-card-para my-3">
                            <p>Lead</p>
                            <p><a href="/DFCars/viewDashboard/lead" title="view data" target="blank">{this.state.leadData}</a></p>
                          </div>
                          <div className="row justify-content-between align-items-center back-card-para my-3">
                            <p>Contract</p>
                            <p><a href="/DFCars/viewDashboard/contract" title="view data" target="blank">{this.state.contractData}</a></p>
                          </div>
                          <div className="row justify-content-between align-items-center back-card-para my-3">
                            <p>Delivery</p>
                            <p><a href="/DFCars/viewDashboard/delivery" title="view data" target="blank">{this.state.deliveryData}</a></p>
                          </div>
                          <div className="row justify-content-between align-items-center back-card-para my-3">
                            <p>Rc Transfer</p>
                            <p><a href="/DFCars/viewDashboard/rctransfer" title="view data" target="blank">{this.state.rcTransferData}</a></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <ul class="nav Dashnav-tabs edit-ul">
                <li class={`Dashnav-item edit-li`}
                  id="buying"
                >
                  <a
                    class="nav-link "
                    href="#buy"

                    onClick={() => this.buyer()}
                  >
                    Buying
                  </a>
                </li>
                <li class="Dashnav-item" id="selling"
                >
                  <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#sell"
                    onClick={() => this.seller()}
                  >
                    Selling
                  </a>
                </li>
                <li class="Dashnav-item" id="search"
                >
                  <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#sell"
                    onClick={() => this.search()}
                  >
                    Search
                  </a>
                </li>
              </ul>

              {this.state.buy && (
                <div className="row flex-wrap justify-content-between align-items-center ">
                  <div className="col-lg-6 col-12 col-md-12 col-sm-12 mb-5" >
                    <div className="chart1" style={{ width: "100%", height: "650px", backgroundColor: "white" }}>
                      <div className="chart-heading text-center col-12 mt-3">
                        <p>Location-Based Cars Chart</p>
                      </div>
                      <div className="row justify-content-center align-items-center px-3">
                        <div className="col-lg-5 col-md-6 col-sm-6 col-12 mt-4">
                          <input type="date" placeholder="enter the date" name="locfromdate" value={this.state.locfromdate ? this.state.locfromdate : this.state.fromDate} className="form-control" onChange={(e) => this.handleChange(e)} />
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-6 col-12 mt-4">
                          <input type="date" className="form-control" placeholder="enter the date" name="loctodate" value={this.state.loctodate ? this.state.loctodate : this.state.toDate} onChange={(e) => this.handleChange(e)} />
                        </div>
                        <div className=" col-lg-1 submit-btn mt-4">
                          <p className="fa fa-arrow-right"></p>
                        </div>
                        <div className="col-lg-6 col-md-4 col-sm-4 col-12 mt-3">
                          <SingleSelect
                            id="ListCarsFilter"
                            name="ListCars"
                            placeholder="Select field"
                            options={this.state.locatiofieldopt}
                            handleChange={e => this.selectField(e, "location")}
                            selectedService={this.state.selectlocatiofield ? this.state.selectlocatiofield : this.state.locatiofieldopt[0]}
                          />
                        </div>
                      </div>
                      <div className="my-2" id="main" style={{ width: "100%", height: "500px", backgroundColor: "white" }}>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 col-md-12 col-sm-12 mb-5">
                    <div className="chart1" style={{ width: "100%", height: "650px", backgroundColor: "white" }}>
                      <div className="chart-heading text-center col-12 mt-3">
                        <p>Stage-Based Cars Chart</p>
                      </div>
                      <div className="row justify-content-center align-items-center px-3">
                        <div className="col-lg-5 col-md-6 col-sm-6 col-12 mt-4">
                          <input type="date" placeholder="enter the date" name="stagefromdate" value={this.state.stagefromdate ? this.state.stagefromdate : this.state.fromDate} className="form-control" onChange={(e) => this.handleChange(e)} />
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-6 col-12 mt-4">
                          <input type="date" className="form-control" placeholder="enter the date" name="stagetoDate" value={this.state.stagetoDate ? this.state.stagetoDate : this.state.toDate} onChange={(e) => this.handleChange(e)} />
                        </div>
                        <div className=" col-lg-1 submit-btn mt-4">
                          <p className="fa fa-arrow-right"></p>
                        </div>
                        <div className="col-lg-6 col-md-4 col-sm-4 col-12 mt-3">
                          <SingleSelect
                            id="ListCarsFilter"
                            name="ListCars"
                            placeholder="Select field"
                            options={this.state.fieldopt}
                            handleChange={e => this.selectField(e, "stage")}
                            selectedService={this.state.selectStageField ? this.state.selectStageField : this.state.fieldopt[0]}
                          />
                        </div>
                      </div>
                      <div className="my-2" id="main1" style={{ width: "100%", height: "500px", backgroundColor: "white" }}>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 col-md-12 col-sm-12 my-0">
                    <div className="chart1" style={{ width: "100%", height: "650px", backgroundColor: "white" }}>
                      <div className="chart-heading text-center col-12 mt-3">
                        <p>Price-Based Chart</p>
                      </div>
                      <div className="row justify-content-center align-items-center px-3">
                        <div className="col-lg-5 col-md-6 col-sm-6 col-12 mt-4">
                          <input type="date" placeholder="enter the date" name="pricefromDate" value={this.state.pricefromDate ? this.state.pricefromDate : this.state.fromDate} className="form-control" onChange={(e) => this.handleChange(e, "price")} />
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-6 col-12 mt-4">
                          <input type="date" className="form-control" placeholder="enter the date" name="pricetoDate" value={this.state.pricetoDate ? this.state.pricetoDate : this.state.toDate} onChange={(e) => this.handleChange(e, "price")} />
                        </div>
                        <div className=" col-lg-1 submit-btn mt-4">
                          <p className="fa fa-arrow-right"></p>
                        </div>

                      </div>
                      <div className="my-2" id="main2" style={{ width: "100%", height: "555px", backgroundColor: "white" }}>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 col-md-12 col-sm-12 my-0">
                    <div className="chart1" style={{ width: "100%", height: "650px", backgroundColor: "white" }}>
                      <div className="chart-heading text-center col-12 mt-3">
                        <p>Model-Based Chart</p>
                      </div>
                      <div className="row justify-content-center align-items-center px-3">
                        <div className="col-lg-5 col-md-6 col-sm-6 col-12 mt-4">
                          <input type="date" placeholder="enter the date" name="modalfromDate" value={this.state.modalfromDate ? this.state.modalfromDate : this.state.fromDate} className="form-control" onChange={(e) => this.handleChange(e, "modal")} />
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-6 col-12 mt-4">
                          <input type="date" className="form-control" placeholder="enter the date" name="modaltoDate" value={this.state.modaltoDate ? this.state.modaltoDate : this.state.toDate} onChange={(e) => this.handleChange(e, "modal")} />
                        </div>
                        <div className=" col-lg-1 submit-btn mt-4">
                          <p className="fa fa-arrow-right"></p>
                        </div>
                        <div className="col-lg-6 col-md-4 col-sm-4 col-12 mt-3">
                          <SingleSelect
                            id="ListCarsFilter"
                            name="ListCars"
                            placeholder="Select field"
                            options={this.state.modalchartOption}
                            handleChange={e => this.selectField(e, "modal")}
                            selectedService={this.state.selectModalField ? this.state.selectModalField : this.state.modalchartOption ? this.state.modalchartOption[0] : ""}
                          />
                        </div>
                      </div>
                      <div className="my-2" id="main3" style={{ width: "100%", height: "500px", backgroundColor: "white" }}>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-12 col-md-12 col-sm-12 my-5">
                    <div className="chart1" style={{ width: "100%", height: "650px", backgroundColor: "white", borderRadius: "50px" }}>
                      <div className="chart-heading text-center col-12 mt-3">
                        <p>Make-Based Chart</p>
                      </div>
                      <div className="row justify-content-center align-items-center px-3">
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-4">
                          <input type="date" placeholder="enter the date" name="makefromDate" value={this.state.makefromDate ? this.state.makefromDate : this.state.fromDate} className="form-control" onChange={(e) => this.handleChange(e, "make")} />
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-4">
                          <input type="date" className="form-control" placeholder="enter the date" name="maketoDate" value={this.state.maketoDate ? this.state.maketoDate : this.state.toDate} onChange={(e) => this.handleChange(e, "make")} />
                        </div>
                        <div className=" col-lg-1 submit-btn mt-4">
                          <p className="fa fa-arrow-right"></p>
                        </div>
                      </div>
                      <div className="my-2" id="main4" style={{ width: "100%", height: "500px", backgroundColor: "white" }}>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {this.state.sell && (
                <div className="row flex-wrap justify-content-between align-items-center my-0">
                  <div className="col-lg-6 col-12 col-md-12 col-sm-12 mb-5">
                    <div className="chart1" style={{ width: "100%", height: "650px", backgroundColor: "white" }}>
                      <div className="chart-heading text-center col-12 mt-3">
                        <p>Stage-Based Chart</p>
                      </div>
                      <div className="row justify-content-center align-items-center px-3">
                        <div className="col-lg-5 col-md-6 col-sm-6 col-12 mt-4">
                          <input type="date" placeholder="enter the date" name="sellstagefromdate" value={this.state.sellstagefromdate ? this.state.sellstagefromdate : this.state.fromDate} className="form-control" onChange={(e) => this.handleChange(e)} />
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-6 col-12 mt-4">
                          <input type="date" className="form-control" placeholder="enter the date" name="sellstagetodate" value={this.state.sellstagetodate ? this.state.sellstagetodate : this.state.toDate} onChange={(e) => this.handleChange(e)} />
                        </div>
                        <div className=" col-lg-1 submit-btn mt-4">
                          <p className="fa fa-arrow-right"></p>
                        </div>
                        <div className="col-lg-6 col-md-4 col-sm-4 col-12 mt-3">
                          <SingleSelect
                            id="ListCarsFilter"
                            name="ListCars"
                            placeholder="Select page"
                            options={this.state.sellingFieldOpt}
                            handleChange={e => this.selectField(e, "sellingStage")}
                            selectedService={this.state.selectField}
                          />
                        </div>
                      </div>
                      <div className="my-2" id="sellmain1" style={{ width: "100%", height: "500px", backgroundColor: "white" }}>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 col-md-12 col-sm-12 mb-5">
                    <div className="chart1" style={{ width: "100%", height: "650px", backgroundColor: "white" }}>
                      <div className="chart-heading text-center col-12 mt-3">
                        <p>Location-Based chart</p>
                      </div>
                      <div className="row justify-content-center align-items-center px-3">
                        <div className="col-lg-5 col-md-6 col-sm-6 col-12 mt-4">
                          <input type="date" placeholder="enter the date" name="selllocfromdate" value={this.state.selllocfromdate ? this.state.selllocfromdate : this.state.fromDate} className="form-control" onChange={(e) => this.handleChange(e)} />
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-6 col-12 mt-4">
                          <input type="date" className="form-control" placeholder="enter the date" name="sellloctodate" value={this.state.sellloctodate ? this.state.sellloctodate : this.state.toDate} onChange={(e) => this.handleChange(e)} />
                        </div>
                        <div className=" col-lg-1 submit-btn mt-4">
                          <p className="fa fa-arrow-right"></p>
                        </div>
                        <div className="col-lg-6 col-md-4 col-sm-4 col-12 mt-3">
                          <SingleSelect
                            id="ListCarsFilter"
                            name="ListCars"
                            placeholder="Select page"
                            options={this.state.sellingLocatFieldOpt}
                            handleChange={e => this.selectField(e, "sellingLocation")}
                            selectedService={this.state.selectSelLocatField}
                          />
                        </div>
                      </div>
                      <div className="my-2" id="sellmain2" style={{ width: "100%", height: "500px", backgroundColor: "white" }}>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-12 col-md-12 col-sm-12 my-5">
                    <div className="chart1" style={{ width: "100%", height: "650px", backgroundColor: "white", borderRadius: "50px" }}>
                      <div className="chart-heading text-center col-12 mt-3">
                        <p>Make-Based Sold Cars Chart</p>
                      </div>
                      <div className="row justify-content-center align-items-center px-3">
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-4">
                          <input type="date" placeholder="enter the date" name="sellmakefromdate" value={this.state.sellmakefromdate ? this.state.sellmakefromdate : this.state.fromDate} className="form-control" onChange={(e) => this.handleChange(e, "sellingMake")} />
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-4">
                          <input type="date" className="form-control" placeholder="enter the date" name="sellmaketodate" value={this.state.sellmaketodate ? this.state.sellmaketodate : this.state.toDate} onChange={(e) => this.handleChange(e, "sellingMake")} />
                        </div>
                        <div className=" col-lg-1 submit-btn mt-4">
                          <p className="fa fa-arrow-right"></p>
                        </div>
                      </div>
                      <div className="my-2" id="sellmain3" style={{ width: "100%", height: "500px", backgroundColor: "white" }}>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 col-md-12 col-sm-12 my-5">
                    <div className="chart1" style={{ width: "100%", height: "650px", backgroundColor: "white" }}>
                      <div className="chart-heading text-center col-12 mt-3">
                        <p>Price-Based Sold Cars Chart</p>
                      </div>
                      <div className="row justify-content-center align-items-center px-3">
                        <div className="col-lg-5 col-md-6 col-sm-6 col-12 mt-4">
                          <input type="date" placeholder="enter the date" name="sellpricefromdate" value={this.state.sellpricefromdate ? this.state.sellpricefromdate : this.state.fromDate} className="form-control" onChange={(e) => this.handleChange(e, "sellingprice")} />
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-6 col-12 mt-4">
                          <input type="date" className="form-control" placeholder="enter the date" name="sellpricetodate" value={this.state.sellpricetodate ? this.state.sellpricetodate : this.state.toDate} onChange={(e) => this.handleChange(e, "sellingprice")} />
                        </div>
                        <div className=" col-lg-1 submit-btn mt-4">
                          <p className="fa fa-arrow-right"></p>
                        </div>
                        {/* <div className="col-lg-6 col-md-4 col-sm-4 col-12 mt-3">
          <SingleSelect
            id="ListCarsFilter"
            name="ListCars"
            placeholder="Select color"
            options={this.state.fieldopt}
            handleChange={e => this.selectField(e)}
            selectedService={this.state.selectField}
          />
        </div> */}
                      </div>
                      <div className="my-2" id="sellmain4" style={{ width: "100%", height: "500px", backgroundColor: "white" }}>

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 col-md-12 col-sm-12 my-5">
                    <div className="chart1" style={{ width: "100%", height: "650px", backgroundColor: "white" }}>
                      <div className="chart-heading text-center col-12 mt-3">
                        <p>Color-Based Sold Cars Chart</p>
                      </div>
                      <div className="row justify-content-center align-items-center px-3">
                        <div className="col-lg-5 col-md-6 col-sm-6 col-12 mt-4">
                          <input type="date" placeholder="enter the date" name="sellcolorfromdate" value={this.state.sellcolorfromdate ? this.state.sellcolorfromdate : this.state.fromDate} className="form-control" onChange={(e) => this.handleChange(e, "sellingColor")} />
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-6 col-12 mt-4">
                          <input type="date" className="form-control" placeholder="enter the date" name="sellcolortodate" value={this.state.sellcolortodate ? this.state.sellcolortodate : this.state.toDate} onChange={(e) => this.handleChange(e, "sellingColor")} />
                        </div>
                        <div className=" col-lg-1 submit-btn mt-4">
                          <p className="fa fa-arrow-right"></p>
                        </div>
                        {/* <div className="col-lg-6 col-md-4 col-sm-4 col-12 mt-3">
          <SingleSelect
            id="ListCarsFilter"
            name="ListCars"
            placeholder="Select color"
            options={this.state.fieldopt}
            handleChange={e => this.selectField(e)}
            selectedService={this.state.selectField}
          />
        </div> */}
                      </div>
                      <div className="my-2" id="sellmain5" style={{ width: "100%", height: "500px", backgroundColor: "white" }}>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {this.state.search && (
                <div>
                  <div className="row-form-group">
                    <div className="col-sm-12 my-3">
                      <React.Fragment>
                        <div className="card" style={{ marginLeft: "-16px" }}>
                          <div className="card-body">
                            <div className="row-form-group">
                              <div className="col-sm-4"></div>
                              <div className="col-sm-4">
                                <div>
                                  <label>Register Number </label>
                                  <input
                                    type="text"
                                    id="myDate"
                                    name="RegisterNo"
                                    className="form-control"
                                    onChange={(e) => this.answers4(e.target.value, 'RegisterNo')}
                                    value={this.state.RegisterNo}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-4"></div>
                            </div>
                            <br />
                            <div className="row-form-group">
                              <div className="col-sm-4"></div>
                              <div className="col-sm-4">
                                <div>
                                  <label>Mobile Number </label>
                                  <input
                                    type="number"
                                    id="myDate"
                                    name="MobileNo"
                                    className="form-control"
                                    onChange={(e) => this.answers5(e.target.value, 'MobileNo')}
                                    value={this.state.MobileNo}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-4"></div>
                            </div>
                            <br />
                            <div className="row-form-group">
                              <div className="col-sm-4"></div>
                              <div className="col-sm-4">
                                {this.state.isRegno && this.state.isMobNo == false ?
                                  <>
                                    <div>
                                      <Link to={{
                                        pathname: "/DFCars/CustomerHistory",
                                        state: { suggest: this.state.suggest, type: 'seller' }
                                      }}
                                      > <button
                                        type="button"
                                        id="submit"
                                        name="submit"
                                        className="btn btn-primary"
                                      >Search</button></Link>
                                    </div>
                                  </>
                                  :
                                  this.state.isRegno == false && this.state.isMobNo && this.state.MobileNo.length == 10 ?
                                    <>
                                      <div>
                                        <Link to={{
                                          pathname: "/DFCars/CustomerHistory",
                                          state: { suggest: this.state.suggest, type: 'seller' }
                                        }}
                                        // onClick={() => window.$('#status').modal('hide')}
                                        > <button
                                          type="button"
                                          id="submit"
                                          name="submit"
                                          className="btn btn-primary"
                                        >Search</button></Link>
                                      </div>
                                    </>
                                    :
                                    this.state.isRegno && this.state.isMobNo && this.state.MobileNo.length == 10 ?
                                      <>
                                        <div>
                                          <Link to={{
                                            pathname: "/DFCars/CustomerHistory",
                                            state: { suggest: this.state.suggest, type: 'seller' }
                                          }}
                                          > <button
                                            type="button"
                                            id="submit"
                                            name="submit"
                                            className="btn btn-primary"
                                          >Search</button></Link>
                                        </div>
                                      </>
                                      :
                                      <></>}

                              </div>
                              <div className="col-sm-4"></div>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    </div>
                  </div>
                </div>
              )}
            </section>
          </main>
        </React.Fragment >
      );
    }
  }

  render() {
    // console.log(this.state.fromDate, "fromDate", this.state.toDate, "toDate");
    // console.log(this.state.locationArray, "finalchart")
    let userlog = JSON.parse(localStorage.getItem("userlog"));
    let usertypelog = JSON.parse(localStorage.getItem("usertypelog"));
    if (usertypelog.orderBy == 9 || usertypelog.orderBy == 7) {
      return (
        this.AllLoginDashboardContent())
    } else if (usertypelog.orderBy == 3) {
      return (this.AllLoginDashboardContent())

      // return <TeamLeader />;
    } else if (usertypelog.orderBy == 2) {
      return (this.AllLoginDashboardContent())
      // return <Manager />;
    } else if (usertypelog.orderBy == 8) {
      return (this.AllLoginDashboardContent())
      // return <Salesconsultant />;
    } else if (usertypelog.orderBy == 1) {
      return (this.AllLoginDashboardContentPoc())
      // return <PocHead />;
    } else if (usertypelog.orderBy == 6) {
      return (this.AllLoginDashboardContent())
      // return <RfIncharge />;
    } else if (usertypelog.orderBy == 5) {
      return (this.AllLoginDashboardContent())
      // return <Rftechnician />;
    } else if (usertypelog.orderBy == 4) {
      return (this.AllLoginDashboardContent())
      // return <Accounts />;
    } else if (usertypelog.orderBy == 10 || usertypelog.orderBy == 12) {
      return (
        <Externaluser />
      )
    } else if (usertypelog.orderBy == 11) {
      return (
        this.AllLoginDashboardContent()
        // <Teledashboard />
      )
    }
    else if (usertypelog.orderBy == 14 || usertypelog.orderBy == 15) {
      return (this.AllLoginDashboardContent())
      // return <Manager />;
    }
    // else if (usertypelog.orderBy == 12) {
    //   return (
    //     <Externaluser />
    //   )
    // }
  }
}
