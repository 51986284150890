import http from "./httpMiddleWare";
import { ACCESS_POINT, EXPRESS_REDIS_ACCESS_POINT } from "../config";
//import { async } from 'q';
let device = JSON.parse(localStorage.getItem("info"));
let userId = JSON.parse(localStorage.getItem("userId"));
// if(userId){
//   device.id=userId;
// }

const themeInsert = async (tableName, formData) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};
const updateTheme = async (tableName, formData) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};

const addMaster = async (tableName, categoryArray) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/master/${tableName}`,
    categoryArray
  );
  return result;
};




const addStock = async (tableName, stocklistArray) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/stock/${tableName}`,
    stocklistArray
  );
  return result;
};
const Fproofinsert = async (tableName, form) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/fproof/${tableName}`,
    form
  );
  return result;
};
const deliverinsert = async (tableName, form) => {
  const result = await http.post(ACCESS_POINT + `/cmsContent/deliver/${tableName}`, form);
  return result;
}




const updateMaster = async (tableName, id, categoryArray, column = "id") => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/master/${tableName}/${column}`,
    { id: id, categoryArray }
  );
  return result;
};

const deleteMaster = async (tableName, id) => {
  const result = await http.delete(
    ACCESS_POINT + `/cmsContent/master/${tableName}/${id}`
  );
  return result;
};

const getMasterValues = async (tableName, columName = "id", order = "desc") => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/master/${tableName}/${columName}/${order}`
  );
  return result;
};

const getMasteractive = async tableName => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/masteractive/${tableName}`
  );
  return result;
};



const getThemeById = async themeid => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/${themeid}`);
  return result;
};



const getAllMappedpages = async () => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/dynamic/page`);
  return result;
};



const updatePage = async data => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/dynamic/pages`,
    data
  );
  return result;
};
const updatecatsubcat = async (t, data) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/CatSubcat/${t}`,
    data
  );
  return result;
};

const updateLoginStatus = async data => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/updateLoginStatus/pages`,
    data
  );
  return result;
};



const rightPanelAdd = async (tableName, formData) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/rightPanel/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};

const rightPanelUpdate = async (tableName, formData) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/rightPanel/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};



const getAllSerchKeyValue = async values => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/dynamic/search/${values}`
  );
  return result;
};

const subTitleAdd = async formData => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/subTitleMaster/subTitleAdd`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};


const getAllSubtitleList = async tablename => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/subTitleMaster/getAllList/${tablename}`
  );
  return result;
};


//ram
const insertImage = async (formData, tableName) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/library/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};
const SteerImage = async (formData, id) => {
  const result = await http.post(
    ACCESS_POINT + `/dfcarz/library/${id}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};
const getLibraryImage = async type => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/library/${type}`);
  return result;
};



const getScoreOfUser = async userId => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/userScore/${userId}`
  );
  return result;
};

const updateCategory = async data => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/dynamic/category`,
    data
  );
  return result;
};



const getquizlog = async () => {  //doubt
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/quizlogcontent/quizlog`
  );
  return result;
};


const getAllHomePanelList = async tableName => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/homePanel/${tableName}`
  );
  return result;
};

const authorinsert = async (formData, tableName) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/addauthor/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};

const authorinserts = async (formData, tableName) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/addauthors/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};

const updateMasters = async (formdata, tableName) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/updateMasters/${tableName}`,
    formdata,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};

const updateFProof = async (formData, t) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/updatefproof/${t}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};

const updateauthor = async (tableName, categoryArray) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/addauthor/${tableName}`,
    categoryArray,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};
const getCompanyUsers = async (companyid, usertype, serviceid = "false") => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/getusers/${companyid}/${usertype}/${serviceid}`
  );
  return result;
};

const UpdateOrder = async (id, listingorder, catid) => {
  //console.log(catid);
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/updateorder/${id}/${catid}`,
    { listingorder }
  );
  return result;
};


const getConditionedValuewithStatus = async (
  tablename,
  columname,
  value,
  id = "id",
  name = "name"
) => {
  const result = await http.get(
    ACCESS_POINT +
    `/cmsContent/getConditionedValuewithStatus/${tablename}/${columname}/${value}/${id}/${name}`
  );
  return result;
};

const getTwoConditionedValue = async (
  tableName,
  columName,
  value,
  columName1,
  value1,
  val = "*"
) => {
  const result = await http.put(
    ACCESS_POINT + "/cmsContent/getTwoConditionedValue/alternatestatus",
    { tableName, columName, value, columName1, value1, val }
  );
  return result;
};

const getTwoConditionedValueFree = async ( //doubt
  tableName,
  columName,
  value,
  columName1,
  value1,
  val = "*"
) => {
  const result = await http.put(
    ACCESS_POINT + "/cmsContent/getTwoConditionedValueFree/alternatestatus",
    { tableName, columName, value, columName1, value1, val }
  );
  return result;
};

const getThreeConditionedValue = async ( //doubt
  tableName,
  columName,
  value,
  columName1,
  value1,
  columName2,
  value2,
  val = "*"
) => {
  const result = await http.put(
    ACCESS_POINT + "/cmsContent/getThreeConditionedValue/alternatestatus",
    { tableName, columName, value, columName1, value1, columName2, value2, val }
  );
  return result;
};
const getThreeConditionedValueFree = async ( //doubt
  tableName,
  columName,
  value,
  columName1,
  value1,
  columName2,
  value2,
  val = "*"
) => {
  const result = await http.put(
    ACCESS_POINT + "/cmsContent/getThreeConditionedValueFree/alternatestatus",
    { tableName, columName, value, columName1, value1, columName2, value2, val }
  );
  return result;
};

const getInnerJoinList = async value => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/getInnerJoinList/getInnerJoin`,
    value
  );
  return result;
};


const learningObjectSubmit = async (tableName, formdata) => {  //doubt
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/learningObjectSubmit/${tableName}`,
    formdata,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};
const getUserJson = async id => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/getUserJson/${id}`);
  return result;
};
const mapUserToResource = async (tableName, formdata) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/mapUserToResource/${tableName}`,
    formdata,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};
const getSingleConditionedValue = async (
  tableName,
  columname,
  value,
  dothis = "SELECT *"
) => {
  const result = await http.get(
    ACCESS_POINT +
    `/cmsContent/getSingleConditionedValue/${dothis}/${tableName}/${columname}/${value}`
  );
  return result;
};


const getSingleConditioned = async (
  tableName,
  columName1,
  value,
  dothis = "SELECT *"
) => {
  const result = await http.get(
    ACCESS_POINT +
    `/cmsContent/getSingleConditioned/${dothis}/${tableName}/${columName1}/${value}/`
  );
  return result;
};

const mappingportlet = async (formData, tableName) => {  //doubt
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/mappingportlet/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};



const UpdateProductMaster = async formData => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/SFA/UpdateProductMaster`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};


const getLearner = async (
  tablename,
  columname,
  value,
  id = "id",
  name = "name",
  companyid
) => {
  const result = await http.get(
    ACCESS_POINT +
    `/cmsContent/getLearner/${tablename}/${columname}/${value}/${id}/${name}/${companyid}`
  );
  return result;
};

const getColoumtablevalue = async (
  tablename,
  columname,
  customerid,
  type,
  secondcolumn
) => {
  const result = await http.get(
    ACCESS_POINT +
    `/cmsContent/gettwocolumnvalue/${tablename}/${columname}/${customerid}/${type}/${secondcolumn}`
  );
  return result;
};

const getgroupbycohorts = async (tablename, columname, value, columname1) => {
  const result = await http.get(
    ACCESS_POINT +
    `/cmsContent/getgroupbycohorts/${tablename}/${columname}/${value}/${columname1}`
  );
  return result;
};

const getValuebyColumn = async (
  tablename,
  columname,
  value,
  columname1,
  value1
) => {
  const result = await http.get(
    ACCESS_POINT +
    `/cmsContent/getValuebyColumn/${tablename}/${columname}/${value}/${columname1}/${value1}`
  );

  return result;
};

const updateBox = async (tableName, formdata) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/updateBox/${tableName}`,
    formdata
  );

  return result;
};

const valueCustomerId = async (boxId, customerid) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/valuecompanyid/${boxId}/${customerid}`
  );

  return result;
};
const getSelectvalue = async (
  tableName,
  column,
  companyid,
  id = "id",
  name = "name"
) => {
  const result = await http.get(
    ACCESS_POINT +
    `/cmsContent/getSelectvalue/${tableName}/${column}/${companyid}/${id}/${name}`
  );

  return result;
};

const getLeanersFromCohorts = async value => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/getLeanersFromCohorts/getLearners`,
    { id: value }
  );

  return result;
};
const getFreedom = async (
  select,
  tableName,
  condition,
  groupby = "id",
  orderby = "id"
) => {
  let value = {};
  value.select = select;
  value.tableName = tableName;
  value.condition = condition;
  value.groupby = groupby;
  value.orderby = orderby;
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/getFullFreedom/getFreedom`,
    value
  );
  //console.log(result)
  if (result.data) {
    return result;
  } else {
    localStorage.clear();
    window.location = "/";
  }
};
const deleteFreedom = async (
  tableName,
  condition,
) => {
  let value = {};
  value.tableName = tableName;
  value.condition = condition;
  console.log({
    value, tableName,
    condition,
  });
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/delete/deleteFreedom/id`,
    value
  );
  //console.log(result)
  if (result.data) {
    return result;
  } else {
    localStorage.clear();
    window.location = "/";
  }
};

const chan = async (tn1, b1, tn2, b2) => {
  let value = {};
  value.tableName1 = tn1;
  value.body1 = b1;
  value.tableName2 = tn2;
  value.body2 = b2;
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/putArrayLoop/putSolo`,
    value
  );
  return result;
};
const chanAnswer = async (tn1, b1) => {
  let value = {};
  value.tableName = tn1;
  value.body = b1;
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/putArrayLoop/putSolo`,
    value
  );
  return result;
};

const AddDfcarsImages = async (t, value) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/AddDfcarsImages/${t}`,
    value
  );
  return result;
}

// const addpageupdate = async (tablename, data) => {
//   let value = {};
//   value.tablename = tablename;
//   value.data = data;

//   const result = await http.post(ACCESS_POINT + `/cmsContent/addpageupdate/update`, data);
//   return result;
// };

const addpageupdate = async (tableName, formdata, index, pageid, currentid) => {
  const result = await http.post(
    ACCESS_POINT +
    `/cmsContent/addpageupdate/update/${tableName}?index=${index}&pageid=${pageid}&currentid=${currentid}`,
    formdata,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};

const addpagemodelwindow = async (tableName, formdata, pageid) => {
  const result = await http.post(
    ACCESS_POINT +
    `/cmsContent/addpagemodelwindow/update/${tableName}?pageid=${pageid}`,
    formdata,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};
const addSurveyContent = async jsonContent => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/insertSurvey/addSurvey`,
    jsonContent
  );
  return result;
};
const addSurveyQuestion = async jsonContent => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/insertQuestionSurvey/addSurveyQuestion`,
    jsonContent
  );
  return result;
};
const addSurveyAnswer = async (tn1, b1) => {
  let value = {};
  value.tableName = tn1;
  value.body = b1;
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/insertResultSurvey/addSurveyAnswer`,
    value
  );
  return result;
};


const getoverallTopperdetails = async d => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/getoverallTopperdetails/${d}`
  );
  return result;
};

// const PortletAdded = async data => {
//   const result = await http.get(
//     ACCESS_POINT + `/cmsContent/portlets/Added`,
//     data,
//     { headers: { "Content-Type": "multipart/form-data" } }
//   );
//   return result;
// };
const PortletAdded = async (categoryArray, type) => {
  var id = categoryArray.userId;
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/portlets/${type}/${id}`,
    categoryArray
  );
  return result;
};

const getIP = async () => {
  const result = await http.post(ACCESS_POINT + `/cmsContent/getIP`);
  return result;
};
const pageViewCount = async d => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/getContent/pageViewCount`,
    d,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
  return result;
};

const createcaptcha = async () => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/createcaptcha/captcha`
  );
  return result;
};

const contactform = async d => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/contactFormmail/enquiry`,
    d,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
  return result;
};

const deleteUser = async d => {
  const result = await http.post(ACCESS_POINT + `/cmsContent/deleteUser/${d}`);
  return result;
};

const deleteRightPanel = async (tableName, id) => {
  const result = await http.delete(
    ACCESS_POINT + `/cmsContent/rightPanel/${tableName}/${id}`
  );
  return result;
};
const updateHierarchy = async (tableName, data) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/updateHierarchy/${tableName}`,
    data
  );
  return result;
};
const inserthome = async (tableName, data) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/inserthome/${tableName}`,
    data,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
  return result;
};
const learnerfile = async (usertype, formdata) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/learnercsv/${usertype}`,
    formdata,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};




const addFProof = async (tableName, formData) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/app/addFProof/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};
const UpdateFProof = async (tableName, formData) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/app/Updatefproof/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};
const UpdateBProof = async (tableName, formData, id = 'id') => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/app/addbProof/${tableName}/${id}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};


const SingleFileUpload = async (tableName, formData) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/SingleFileUpload/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};

const tbl_AT_Booklet_Purchase_Contract_Proof = async (tableName, formData, id) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/tbl_AT_Booklet_Purchase_Contract_Proof/${tableName}/${id}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};
const Multienquiry = async (tableName, formData) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/multiquery/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
}
const addDrawerMapping = async (tableName, categoryArray) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/addDrawerMapping/${tableName}`,
    categoryArray
  );
  return result;
};
const Fproofpdf = async (tableName, id) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/fproofpdf/${tableName}/${id}`,
  );
  return result;
};

const loadFproof = async (name) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/downloadfproof/${name}`,
  );
  return result;
};
const bproof = async (t, id) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/bproofpdf/${t}/${id}`,
  );
  return result;
}
const bproofpdf = async (id) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/downloadbproof/${id}`,
  );
  return result;
}
const document = async (t, id) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/documentpdf/${t}/${id}`,
  );
  return result;
}
const documentpdf = async (name) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/documentpdf/${name}`,
  );
  return result;
}
const inventory = async (t, id) => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/inventpdf/${t}/${id}`);
  return result;
}
const inventorydownload = async (name) => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/downloadInventory/${name}`);
  return result;
}
const deliverseller = async (t, id) => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/deliveryseller/${t}/${id}`);
  return result;
}
const downloadsellpdf = async (name) => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/downloadsellpdf/${name}`);
  return result;
}
const checklist = async (t, id) => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/checklist/${t}/${id}`);
  return result;
}
const checklistpdf = async (name) => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/downloadchecklist/${name}`);
  return result;
}
const autoterrace = async (t, id) => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/autoterrace/${t}/${id}`);
  return result;
}
const downloadterrace = async (name) => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/downloadauto/${name}`);
  return result;
}
const Bookingconfirm = async (t, id) => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/bookingconfirm/${t}/${id}`);
  return result;
}
const downloadbooking = async (name) => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/downloadbookconfirm/${name}`);
  return result;
}
const Deliverybuyer = async (t, id) => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/deliverybuyer/${t}/${id}`);
  return result;
}
const downloadbuyer = async (name) => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/downloadbuyer/${name}`);
  return result;
}
const compareValues = async (key, type, order = "asc") => {
  // console.log(1);
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }

    const varA = type === "int" ? parseInt(a[key]) : a[key].toUpperCase();
    const varB = type === "int" ? parseInt(b[key]) : b[key].toUpperCase();

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    //console.log(comparison);
    return order === "desc" ? comparison * -1 : comparison;
  };
}

const GetSingleCar = async (id) => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/app/getcardata/${id}`);
  return result;
}


const GoogleLogin = async (email) => {
  let categoryArray = {}
  categoryArray.email = email
  const result = await http.post(
    ACCESS_POINT + "/cmsContent/App/cars/google/email",
    categoryArray
  );

  return result;
};
const WithPassowrdlogin = async (email, pasword) => {
  let categoryArray = {}
  categoryArray.email = email
  categoryArray.password = pasword
  console.log(categoryArray);
  const result = await http.post(
    ACCESS_POINT + "/cmsContent/App/web/WebLoginWithoutPasword",
    categoryArray
  );

  return result;
};


const CommonAddMaster = async (tableName, categoryArray) => {
  console.log(tableName);
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/app/common/addMaster/` + `${tableName}`,
    categoryArray
  );
  return result;
};



const MultiFileUpload = async (tableName, data, id = "id") => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/web/MultiFileUpload/${tableName}/${id}`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};

const inventFileupload = async (tableName, data, id = "id") => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/web/inventFileupload/${tableName}/${id}`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};
const contractCancel = async (tableName, id, data) => {
  const result = await http.post(ACCESS_POINT + `/dfcarz/contractCancel/${tableName}/${id}`, data)
  return result
}
const GetUserHistory = async (no) => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/app/viewuserhistory/${no}`);
  return result.data;
}
const BUYDFCARS = async (id, api) => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/app/geteval/${id}/${api}`);
  return result.data;

}
const BUYDFCARZ = async (id, api, data) => {
  const result = await http.post(ACCESS_POINT + `/dfcarz/app/geteval/${id}/${api}`,
    data,
    {
      headers: {
        "Content-Type": "application/json"
      }
    });
  return result.data;

}
const getEnquiry = async (id, type = null) => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/DfCarsEnquiry/${id}/${type}`);
  return result.data;

}
const getDfcarzEnquiry = async (id, type = null) => {
  const result = await http.get(ACCESS_POINT + `/dfcarz/DfCarsEnquiry/${id}/${type}`);
  return result.data;

}
const getDfCarsteleEnquiry = async (id, type = null) => {
  const result = await http.get(ACCESS_POINT + `/dfcarz/DfCarsteleEnquiry/${id}/${type}`);
  return result.data;
}
const DfCarsAddEnquiry = async (data) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/app/enquiryadd`,
    data,
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
  return result;
};
const UpdateFApproval = async (data) => {
  const result = await http.put(ACCESS_POINT + `/cmsContent/app/UpdateFApproval`, data, {
    headers: {
      "Content-Type": "application/json"
    }
  })
  return result;
}
const UpdateApproval = async (tablename, data) => {
  const result = await http.put(ACCESS_POINT + `/cmsContent/app/UpdateApproval/${tablename}`, data, {
    headers: {
      "Content-Type": "application/json"
    }
  })
  return result;
}

const UpdateEnquiryadd = async (id, subgroupArray) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/app/updateenquiryadd/${id}`, subgroupArray
  );
  return result;
};
const UpdateSaleEnquiryadd = async (tableName, id, categoryArray) => {
  // console.log(tableName);
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/app/updatesaleenquiryadd/${id}` + `/${tableName}`,
    categoryArray
  );
  return result;
};
const SalesEnquiryadd = async (tableName, categoryArray) => {
  console.log(tableName);
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/app/common/SalesEnquiryadd/` + `${tableName}`,
    categoryArray
  );
  return result;
};
const ViewChartDFC = async (formdate, todate, orderby) => {
  // console.log("enter", formdate, todate, orderby);
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/app/ViewChartDFC/${formdate}` + `/${todate}` + `/${orderby}`,
  )
  return result;
}
const ViewChartDFCarz = async (formdate, todate, orderby) => {
  // console.log("enter", formdate, todate, orderby);
  const result = await http.get(
    ACCESS_POINT + `/dfcarz/app/ViewChartDFC/${formdate}` + `/${todate}` + `/${orderby}`,
  )
  return result;
}
const locationChart = async (formdate, todate, userid) => {
  console.log("enter cmscontent", formdate, todate);
  const result = await http.get(
    ACCESS_POINT + `/dfcarz/app/LocationChart/${formdate}` + `/${todate}` + `/${userid}`,
  )
  console.log(result,"result");
  return result;
}
const siteintegratedstatus = async (name, data, id = 'id') => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/app/siteintegratedstatus/${name}/${id}`,
    data
    // {
    //   headers: {
    //     "Content-Type": "application/json"
    //   }
    // }
  );
  return result;
};
const MultiCSVenquiry = async (tableName, formData) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/multiCSVquery/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
}
const GetFTD = async (data, data1) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/GetFTD/${data}`, data1

  );
  return result;
}

const DFcarsMovedtosite = async (tablename, data) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/app/DFcarsMovedtosite/${tablename}`,
    data
  );
  return result;
};


const DFCARSMODELCSV = async (tableName, formData) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/DFCARSMODELCSV/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
}
const DFCARSVARIANTCSV = async (tableName, formData) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/DFCARSVARIANTCSV/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
}

const DFcarsTelecallerAllocate = async (tableName, type, formData) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/DFcarsTelecallerAllocate/${tableName}/${type}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
}
const DFcarsManagerAllocate = async (tableName, type, formData) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/DFcarsManagerAllocate/${tableName}/${type}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
}
const mailtest1 = async (data) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/Coromandel/MailtestAPI`, data
  );
  return result.data;
};


const library_delete = async (id) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/app/library_delete/${id}`
  );
  return result.data;
}
const DfCarzAddEnquiry = async (data) => {
  const result = await http.post(
    ACCESS_POINT + `/dfcarz/app/enquiryadd`,
    data,
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
  return result;
};
const UpdateDfcarzEnquiry = async (id, subgroupArray) => {
  const result = await http.put(
    ACCESS_POINT + `/dfcarz/app/updateenquiryadd/${id}`, subgroupArray
  );
  return result;
};
const getAllOption = async (id) => {
  const result = await http.get(
    ACCESS_POINT + `/dfcarz/GetAllOption`
  );
  return result.data;
}
const getAllOption_Web = async (id) => {
  const result = await http.get(
    ACCESS_POINT + `/dfcarz/getAllOption_Web`
  );
  return result.data;
}
const RFUpdateMaster = async (tableName, id, formdata,) => {
  const result = await http.put(
    ACCESS_POINT + `/dfcarz/web/RFAppupdateMasterWeb/${tableName}/${id}`,
    formdata,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
}
const CheckListAddProduct1 = async (tblename, formData) => {
  const result = await http.post(
    ACCESS_POINT + `/dfcarz/CheckListAddProduct1/${tblename}`,
    formData,

  );

  return result;
};
const MultiArrayFileUpload = async (tableName, data, id = 'id') => {
  const result = await http.post(
    ACCESS_POINT + `/dfcarz/app/MultiArrayFileUpload/${tableName}/${id}`,
    data
    // {
    //   headers: {
    //     "Content-Type": "application/json"
    //   }
    // }
  );
  return result;
};
const GetCmdlDatas = async (id, type, data = null) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/Coromandel/GetCmdlDatas/${id}/${type}`,
    data
  );
  return result.data;
};
const CrucibleCms = async (type, id = 'id', data = null) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/Crucible/${id}/${type}`,
    data, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  }
  );
  return result.data;
};

const GetSteer = async (id = "id", api, data) => {
  const result = await http.post(ACCESS_POINT + `/dfcarz/app/getsteer/${id}/${api}`,
    data,
    {
      headers: {
        "Content-Type": "application/json"
      }
    });
  return result.data;
}
const SteerVehicleFileUpload = async (tableName, data, id = "id", username = "steer") => {
  const result = await http.post(ACCESS_POINT + `/dfcarz/SteerVehicleFileUpload/${tableName}/${id}/${username}`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};


//test updation
const updateToRedisLocal = async (content) => {
  const result = await http.get(`${EXPRESS_REDIS_ACCESS_POINT}/swtn/uploadToRedisLocal/${content}`);
  return result

}

const uploadToRedisLive = async (projectSelected) => {

  const result = await http.post(`${EXPRESS_REDIS_ACCESS_POINT}/swtn/uploadToRedisLive`, projectSelected);
  return result

}
const updateManualRedisLocal = async (objectArray) => {
  const result = await http.post(`${EXPRESS_REDIS_ACCESS_POINT}/swtn/manualDataUploadToRedis`, objectArray);
  return result

}
const sendOtp = async (formData, id) => {
  const result = await http.post(ACCESS_POINT + `/cmsContent/sendOtp/${id}`, formData)
  return result;
}

const getUserTypeOption = async (id, type = null) => {
  const result = await http.get(ACCESS_POINT + `/dfcarz/getUserTypeOption/${id}`);
  return result.data;

}
const getDfcarzReportEnquiry = async (id, type = null) => {
  const result = await http.get(ACCESS_POINT + `/dfcarz/DfCarsReportEnquiry/${id}/${type}`);
  return result.data;

}
const getDfCarsTeleBuyingEnquiry = async (id, type = null) => {
  const result = await http.get(ACCESS_POINT + `/dfcarz/DfCarsTeleBuyingEnquiry/${id}/${type}`);
  return result.data;

}

const getlocationdata = async (fromDate,toDate,id,type = null) => {
  const result = await http.get(ACCESS_POINT + `/dfcarz/app/LocationChart/${fromDate}/${toDate}/${id}/${type}`,
  {
    headers: {
      "Content-Type": "application/json"
    }
  });
  return result.data;

}

export default {
  getlocationdata,
  sendOtp,
  locationChart,
  uploadToRedisLive,
  updateToRedisLocal,
  updateManualRedisLocal,
  SteerVehicleFileUpload,
  mailtest1,
  UpdateFProof,
  getEnquiry,
  GetUserHistory,
  Multienquiry,
  autoterrace,
  downloadterrace,
  Bookingconfirm,
  Deliverybuyer,
  downloadbuyer,
  downloadbooking,
  Fproofpdf,
  checklist,
  checklistpdf,
  loadFproof,
  bproof,
  document,
  documentpdf,
  deliverseller,
  downloadsellpdf,
  bproofpdf,
  inventory,
  inventorydownload,
  CheckListAddProduct1,
  themeInsert,
  updateTheme,
  learnerfile,
  inserthome,
  Fproofinsert,
  deliverinsert,
  tbl_AT_Booklet_Purchase_Contract_Proof,
  compareValues,
  getThemeById,
  addMaster,
  addStock,
  getMasterValues,
  getAllMappedpages,
  updatePage,
  updateLoginStatus,
  rightPanelAdd,
  getAllSerchKeyValue,
  subTitleAdd,
  getAllSubtitleList,
  insertImage,
  getLibraryImage,
  getScoreOfUser,
  updateCategory,
  updateMaster,
  updateFProof,
  deleteMaster,
  updatecatsubcat,
  getAllHomePanelList,
  authorinsert,
  authorinserts,
  updateMasters,
  updateauthor,
  getCompanyUsers,
  UpdateOrder,
  getMasteractive,
  getquizlog,
  getConditionedValuewithStatus,
  getInnerJoinList,
  learningObjectSubmit,
  getUserJson,
  mapUserToResource,
  getSingleConditionedValue,
  getSingleConditioned,
  mappingportlet,
  getLearner,
  getgroupbycohorts,
  getColoumtablevalue,
  getValuebyColumn,
  updateBox,
  valueCustomerId,
  getSelectvalue,
  getTwoConditionedValue,
  getTwoConditionedValueFree,
  getThreeConditionedValue,
  getThreeConditionedValueFree,
  getLeanersFromCohorts,
  getFreedom,
  chan,
  chanAnswer,
  addpageupdate,
  addpagemodelwindow,
  addSurveyContent,
  addSurveyQuestion,
  addSurveyAnswer,
  getoverallTopperdetails,
  PortletAdded,
  getIP,
  pageViewCount,
  contactform,
  createcaptcha,
  deleteUser,
  rightPanelUpdate,
  deleteRightPanel,
  updateHierarchy,
  UpdateProductMaster,
  addFProof,
  SingleFileUpload,
  AddDfcarsImages,
  addDrawerMapping,
  deleteFreedom,
  GetSingleCar,
  GoogleLogin,
  CommonAddMaster,
  MultiFileUpload,
  WithPassowrdlogin,
  BUYDFCARS,
  UpdateBProof,
  DfCarsAddEnquiry,
  UpdateFApproval,
  UpdateApproval,
  UpdateEnquiryadd,
  UpdateSaleEnquiryadd,
  SalesEnquiryadd,
  ViewChartDFC,
  siteintegratedstatus,
  MultiCSVenquiry,
  GetFTD,
  RFUpdateMaster,
  DFcarsMovedtosite,
  inventFileupload,
  DFCARSMODELCSV,
  DFCARSVARIANTCSV,
  DFcarsManagerAllocate,
  //S3Delete
  library_delete,
  DfCarzAddEnquiry,
  UpdateDfcarzEnquiry,
  BUYDFCARZ,
  getDfcarzEnquiry,
  getDfCarsteleEnquiry,
  getAllOption,
  ViewChartDFCarz,
  MultiArrayFileUpload,
  SteerImage,
  GetCmdlDatas,
  CrucibleCms,
  GetSteer,
  getAllOption_Web,
  contractCancel,
  // global report
  getUserTypeOption,
  getDfcarzReportEnquiry,

  // telecaller
  getDfCarsTeleBuyingEnquiry,
  DFcarsTelecallerAllocate
};
