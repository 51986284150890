import React, { Component, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AdminHeader, UserHeader, DifuzaCarHeader } from "../screens/Header";
import Footer from "../screens/Footer";
// UserUnder 
import UserUnder from "../screens/Header/UserUnder";
import "./style.css";

// NAVBAR
import SideNavThirdAdmin from "../screens/SideNav/SideNavThirdAdmin";
import DifuzaNav from "../screens/SideNav/DifuzaNav";
import DifuzaUserNav from "../screens/SideNav/DifuzaUserNav";
import Login from "../screens/Login/Login";
import Hello from "../screens/Login/Hello";
import SuperAdminDashboard from "../screens/ThirdAdmin/SuperAdminDashboard";
import DifuzaDashboard from "../screens/ThirdAdmin/Difuza/DifuzaDashboard";
import Ehsportal from "../screens/Login/Ehsportal";

//Difuza screens
import BoxTypes from "../screens/ThirdAdmin/Difuza/BoxTypes";
import Group from "../screens/ThirdAdmin/Difuza/Groups";
import Program from "../screens/ThirdAdmin/Difuza/Program";
import Batch from "../screens/ThirdAdmin/Difuza/Batch";
import LearningObject from "../screens/ThirdAdmin/Difuza/LearningObject";
import Domain from "../screens/ThirdAdmin/Difuza/Domain";
import Location from "../screens/ThirdAdmin/Difuza/Location";
import Resources from "../screens/ThirdAdmin/Difuza/Resources";
import MappingToGroup from "../screens/ThirdAdmin/Difuza/MappingToGroup";
import MentorToGroup from "../screens/ThirdAdmin/Difuza/MentorToGroup";
import ManagerToMentor from "../screens/ThirdAdmin/Difuza/ManagerToMentor"
import MapPortletToGroup from "../screens/ThirdAdmin/Difuza/MapPortletToGroup";
import Reports from "../screens/ThirdAdmin/Difuza/Reports";
import SurveyReports from "../screens/ThirdAdmin/Difuza/SurveyReports";
import SurveyMapping from "../screens/ThirdAdmin/Difuza/SurveyMapping";
import UserLogReport from "../screens/ThirdAdmin/Difuza/UserLogReport";
import Survey from "../screens/ThirdAdmin/Difuza/Survey";
import SurveyPage from "../screens/ThirdAdmin/Difuza/SurveyPage";
import SurveyQuestion from "../screens/ThirdAdmin/Difuza/SurveyQuestion";
import AppLogo from "../screens/ThirdAdmin/Difuza/AppLogo";
import Viewtable from "../screens/ThirdAdmin/Difuza/viewtable";
import CohortsView from "../screens/ThirdAdmin/Difuza/CohortsView";
import PortletsView from "../screens/ThirdAdmin/Difuza/PortletsView";
import Learners from "../screens/ThirdAdmin/Difuza/Learners";
import Mentors from "../screens/ThirdAdmin/Difuza/Mentors";
import DifManager from "../screens/ThirdAdmin/Difuza/Manager";
import WizardForm from "../screens/ThirdAdmin/Difuza/WizardForm";
import LearningPath from "../screens/ThirdAdmin/Difuza/LearningPath";
import Forms from "../screens/ThirdAdmin/Difuza/Forms";
import SiteHome from "../screens/ThirdAdmin/Difuza/SiteHome";
import FormView from "../screens/ThirdAdmin/Difuza/FormView";
import FormReport from "../screens/ThirdAdmin/Difuza/FormReport";
import CMSFormReport from "../screens/ThirdAdmin/Difuza/FormReport";
import SurveyForm from "../screens/ThirdAdmin/Difuza/SurveyForm";
import PerformanceReport from "../screens/ThirdAdmin/Difuza/performanceReport";
import BoxTypesCopy from "../screens/ThirdAdmin/Difuza/BoxTypescopy";

// L4 User Difuza
import DifuzaUser from "../screens/ThirdAdmin/Difuza/DifuzaUser";
import DifuzaNextUser from "../screens/ThirdAdmin/Difuza/DifuzaNextUser";
import Privacy from "../screens/Login/privacy";
import DfUserSeeAll from "../screens/ThirdAdmin/Difuza/DfUserSeeAll";
import Rtreport from "../screens/ThirdAdmin/Difuza/Rtreport";

import Test from "../screens/test";

/////DifuzaCars
import CarsColourMaster from "../screens/ThirdAdmin/DifuzaCars/Master/colormaster";
import CarsBrandMaster from "../screens/ThirdAdmin/DifuzaCars/Master/Brandmaster";
import CarsmakeMaster from "../screens/ThirdAdmin/DifuzaCars/Master/makemaster";
import CarsmodelMaster from "../screens/ThirdAdmin/DifuzaCars/Master/Modelmaster";
import Carsvariantmaster from "../screens/ThirdAdmin/DifuzaCars/Master/Variantmaster";
import DifuzaCarsDashboard from "../screens/ThirdAdmin/DifuzaCars/usertypeDashboard/DifuzaCarsDashboard";
import DifuzaCarsNav from "../screens/SideNav/DifuzaCarsNav";
import DfCaresUsertype from "../screens/ThirdAdmin/DifuzaCars/Usertype";
import DfCarsLocation from "../screens/ThirdAdmin/DifuzaCars/Master/DfCarsLocation";
import DfCarsMapHierarchy from "../screens/ThirdAdmin/DifuzaCars/DfCarsMapHierarchy";
import DfCarsAddDesignation from "../screens/ThirdAdmin/DifuzaCars/dfCarsAddDesignation";
import DfCarsMapForm from "../screens/ThirdAdmin/DifuzaCars/DfCarsMapForm";
import DfCarsAddUsers from "../screens/ThirdAdmin/DifuzaCars/Screen/DfCarsAddUsers";
import DfCarsEnquiry from "../screens/ThirdAdmin/DifuzaCars/Evaluator/DfCarsEnquiry";
import DfcarsSalesEnquiry from "../screens/ThirdAdmin/DifuzaCars/SalesMaster/DfcarsSalesEnquiry"
import DfCarsViewhistory from "../screens/ThirdAdmin/DifuzaCars/DfCarsViewhistory";
import DfcarsAddForms from "../screens/ThirdAdmin/DifuzaCars/DfcarsAddForm";
import ProcessDefinition from "../screens/ThirdAdmin/DifuzaCars/ProcessDefinition";
import DfcarsdeliverySeller from "../screens/ThirdAdmin/DifuzaCars/Form/DfcarsdeliverySeller";
import DfcarsUserdash from "../screens/ThirdAdmin/DifuzaCars/Screen/DfcarsUserdash";
import ViewDashboard from "../screens/ThirdAdmin/DifuzaCars/Screen/ViewDashboard";
import NewCarCons from "../screens/ThirdAdmin/DifuzaCars/DfcarsUserTypeNav/NewCarCons";
import DfCarsFproof from "../screens/ThirdAdmin/DifuzaCars/Evaluator/DfcarsFproof";
import Evaluation_Checklist_F_Proof from "../screens/ThirdAdmin/DifuzaCars/Form/Evaluation_Checklist_F_Proof";
import UserEnquiryreport from "../screens/ThirdAdmin/DifuzaCars/UserMaster/EnquiryReport";
import DfCarsdocumentation from "../screens/ThirdAdmin/DifuzaCars/Evaluator/Dfcarsdocumentation";
import Todaytask from "../screens/ThirdAdmin/DifuzaCars/Screen/Todaytask";
import Pendingtask from "../screens/ThirdAdmin/DifuzaCars/Screen/Pendingtask";
import DfCarspaymentprocess from "../screens/ThirdAdmin/DifuzaCars/DfCarspaymentprocess";
import DfCarsphysicalprocess from "../screens/ThirdAdmin/DifuzaCars/Evaluator/Dfcarsphysicalprocess";
import Evaluationreport from "../screens/ThirdAdmin/DifuzaCars/UserMaster/Evaluationreport";
import Refurbishment from "../screens/ThirdAdmin/DifuzaCars/Screen/Refurbishment";
import DfCarsFormOrder from "../screens/ThirdAdmin/DifuzaCars/DfCarsFormOrder";
import DfCarsWarranty from "../screens/ThirdAdmin/DifuzaCars/DfCarsWarranty";
import DrawerMenuLabel from "../screens/ThirdAdmin/DifuzaCars/drawerMenuLabel";
import DrawerMapping from "../screens/ThirdAdmin/DifuzaCars/drawerMapping";
import UploadBill from "../screens/ThirdAdmin/DifuzaCars/TL/BillUpload";
import BidShare from "../screens/ThirdAdmin/DifuzaCars/Externaluser/BidShare";
import ExternalUserform from "../screens/ThirdAdmin/DifuzaCars/Externaluser/Externalform";
import TeleEnquiry from "../screens/ThirdAdmin/DifuzaCars/telecaller/TeleEnquiry";
import Teledashboard from "../screens/ThirdAdmin/DifuzaCars/telecaller/Teledashboard";
import RFinchargelist from "../screens/ThirdAdmin/DifuzaCars/Screen/RFinchargelist"
import RFtechnicianlist from "../screens/ThirdAdmin/DifuzaCars/Screen/RFtechinicainlist"
import RcTransfer from "../screens/ThirdAdmin/DifuzaCars/Screen/rctransfer"
import Rcform from '../screens/ThirdAdmin/DifuzaCars/Form/rcform'
import BidForm from "../screens/ThirdAdmin/DifuzaCars/Externaluser/Bidform";
import BidForm1 from "../screens/ThirdAdmin/DifuzaCars/Externaluser/Bodytext";
//DifuzaCars Static Form
//import AT_Booklet_Purchase_Contract_Proof from "../screens/ThirdAdmin/DifuzaCars/AT_Booklet_Purchase_Contract_Proof";
import Seller_enquiry_form from "../screens/ThirdAdmin/DifuzaCars/Form/Seller_enquiry_form";
import Evaluation_Checklist_B_Proof from "../screens/ThirdAdmin/DifuzaCars/Form/Evaluation_Checklist_B_Proof";
import AT_Booklet_Purchase_Contract_Proof from "../screens/ThirdAdmin/DifuzaCars/Form/AT_Booklet_Purchase_Contract_Proof";
import AT_Inventory_Sheet_Reproof from "../screens/ThirdAdmin/DifuzaCars/Form/AT_Inventory_Sheet_Reproof";
import Delivery_Note_Seller_Proof from "../screens/ThirdAdmin/DifuzaCars/Form/Delivery_Note_Seller_Proof";
import CERTIFICATION_350_CheckPoints from "../screens/ThirdAdmin/DifuzaCars/Form/CERTIFICATION_350_CheckPoints";
import AT_Specifications_Sheet_Proof from "../screens/ThirdAdmin/DifuzaCars/Form/AT_Specifications_Sheet_Proof";
import BuyerEnquiryform from "../screens/ThirdAdmin/DifuzaCars/SalesMaster/BuyerEnquiryform";
import Autoterrace_enquiry_form from "../screens/ThirdAdmin/DifuzaCars/Form/Autoterrace_enquiry_form";
import AT_Delivery_Note_Buyer_Reproof from "../screens/ThirdAdmin/DifuzaCars/Form/AT_Delivery_Note_Buyer_Reproof";
import AT_Booking_Confirmation_Reproof from "../screens/ThirdAdmin/DifuzaCars/Form/AT_Booking_Confirmation_Reproof";
import DfCarsCSVEnquiry from "../screens/ThirdAdmin/DifuzaCars/Screen/DfCarsCSVEnquiry";

//DF CARS TL Routes
import TLdocumentation from "../screens/ThirdAdmin/DifuzaCars/TL/TLdocumentation";
import TLRefurbishment from "../screens/ThirdAdmin/DifuzaCars/TL/TLRefurbishment";
import TLpaymentprocess from "../screens/ThirdAdmin/DifuzaCars/TL/TLpaymentprocess";
import TLphysicalprocess from "../screens/ThirdAdmin/DifuzaCars/TL/TLphysicalprocess";
import TLBuyerEnquiryform from "../screens/ThirdAdmin/DifuzaCars/TL/TLBuyerEnquiryform";
import TLSourcing from "../screens/ThirdAdmin/DifuzaCars/TL/TLSourcing";
import TLContracted from "../screens/ThirdAdmin/DifuzaCars/TL/TLContracted";
import TLDelivery from "../screens/ThirdAdmin/DifuzaCars/TL/TLDelivery";
import TLProspectBuyerList from "../screens/ThirdAdmin/DifuzaCars/TL/TLProspectBuyerList";
import DFContracted from "../screens/ThirdAdmin/DifuzaCars/UserMaster/DFContracted";

/// DF CARS TeleCaller Routes 

import TeleSellingEnquiry from "../screens/ThirdAdmin/DifuzaCars/telecaller/TeleSellingEnquiry"
import TeleProspectBuyerList from "../screens/ThirdAdmin/DifuzaCars/telecaller/TeleProspectBuyerList";
import TeleContracted from "../screens/ThirdAdmin/DifuzaCars/telecaller/TeleContracted"
import TeleDelivery from "../screens/ThirdAdmin/DifuzaCars/telecaller/TeleDelivery"
import TeleRcTransfer from "../screens/ThirdAdmin/DifuzaCars/telecaller/TeleRcTransfer"

import TeleUserEnquiry from "../screens/ThirdAdmin/DifuzaCars/telecaller/TeleUserEnquiry"
import TeleEvaluationreport from "../screens/ThirdAdmin/DifuzaCars/telecaller/TeleEvaluationreport"
import Teledocumentation from "../screens/ThirdAdmin/DifuzaCars/telecaller/Teledocumentation"
import Telephysicalprocess from "../screens/ThirdAdmin/DifuzaCars/telecaller/Telephysicalprocess"
import TeleInventoryList from "../screens/ThirdAdmin/DifuzaCars/telecaller/TeleInventoryList"



///Df cars poc head
import PocEvaluation from "../screens/ThirdAdmin/DifuzaCars/Poc_head/PocEvaluation";
import PocRefurbishment from "../screens/ThirdAdmin/DifuzaCars/Poc_head/PocRefurbishment";
import PocBuyerSpecification from "../screens/ThirdAdmin/DifuzaCars/Poc_head/PocBuyerSpecification";
import PocSourcing from "../screens/ThirdAdmin/DifuzaCars/Poc_head/PocSourcing";
import PocContracted from "../screens/ThirdAdmin/DifuzaCars/Poc_head/PocContracted";
import PocDelivery from "../screens/ThirdAdmin/DifuzaCars/Poc_head/PocDelivery";
import PocProspectBuyerList from "../screens/ThirdAdmin/DifuzaCars/Poc_head/PocProspectBuyerList";
import PocEnquiryreport from "../screens/ThirdAdmin/DifuzaCars/Poc_head/PocEnquiryReport";
import DfCarsGobalsearch from "../screens/ThirdAdmin/DifuzaCars/Poc_head/DfCarsGobalsearch";
import DfCarsViewMore from "../screens/ThirdAdmin/DifuzaCars/Poc_head/Viewmore";
import ProductViewmore from "../screens/ThirdAdmin/DifuzaCars/Poc_head/Productviewmore";
import GlobalReport from "../screens/ThirdAdmin/DifuzaCars/Poc_head/GlobalReport";
import DownlineReport from "../screens/ThirdAdmin/DifuzaCars/Poc_head/DownlineGlobalReport"


//DF CARS Evaluator
import EvaluatorDelivery from "../screens/ThirdAdmin/DifuzaCars/Evaluator/EvaluatorDelivery";

//DF CARS Acconts Routes
import Accdocumentation from "../screens/ThirdAdmin/DifuzaCars/Accounts/AccDocs";

import PriceList from "../screens/ThirdAdmin/DifuzaCars/SalesMaster/PriceList";
import Sourcing from "../screens/ThirdAdmin/DifuzaCars/SalesMaster/Sourcing";
import Contracted from "../screens/ThirdAdmin/DifuzaCars/SalesMaster/Contracted";
import Delivery from "../screens/ThirdAdmin/DifuzaCars/SalesMaster/Delivery";
import InventoryList from "../screens/ThirdAdmin/DifuzaCars/SalesMaster/InventoryList";
import MyInventory from "../screens/ThirdAdmin/DifuzaCars/SalesMaster/MyInventory";
import MyInventory1 from "../screens/ThirdAdmin/DifuzaCars/SalesMaster/MyInventory1";
import ProspectList from "../screens/ThirdAdmin/DifuzaCars/SalesMaster/ProspectList";
import ClosedCustomer from "../screens/ThirdAdmin/DifuzaCars/SalesMaster/ClosedCustomer";
import PriceListMaster from "../screens/ThirdAdmin/DifuzaCars/Screen/PriceList";
import TransactionHistory from "../screens/ThirdAdmin/DifuzaCars/TransactionHistory";
import InventCarData from "../screens/ThirdAdmin/DifuzaCars/ViewInventory"


//Demo project
import DemoSideNav from '../screens/Demo/DemoSideNav'
import DemoAdduser from "../screens/Demo/demoAdduser";
import NewMakeMaster from "../screens/ThirdAdmin/DifuzaCars/Master/CarMakeMaster";
import NewModelMaster from "../screens/ThirdAdmin/DifuzaCars/Master/NewModelMaster"
import NewVariantMaster from "../screens/ThirdAdmin/DifuzaCars/Master/NewVariantMaster"
import NewDfcarsUserPage from "../screens/ThirdAdmin/DifuzaCars/Screen/NewDfcarsUserPage"
import NewDfcarsSalesConsultant from "../screens/ThirdAdmin/DifuzaCars/Master/DfcarsSalesConsultant";
import DifuzaCarsNavTemp from "../screens/SideNav/DifuzCarsNavTemp";
import DfCarsBranch from "../screens/ThirdAdmin/DifuzaCars/Master/DfcarsBranch";
import CarsNewMakeMaster from "../screens/ThirdAdmin/DifuzaCars/Master/Newmakemaster";
import DfcarsSalesConsultant from "../screens/ThirdAdmin/DifuzaCars/Master/NewSalsesConsultant";
import DfCarAdminEnquiry from "../screens/ThirdAdmin/DifuzaCars/Site/DfCarAdminEnquiry";
import DfCarAdminContact from "../screens/ThirdAdmin/DifuzaCars/Site/DfCarAdminContact";
import DfCarAdminAppointment from "../screens/ThirdAdmin/DifuzaCars/Site/DfCarAdminAppointment";
import AccContract from "../screens/ThirdAdmin/DifuzaCars/Accounts/AccContract";
import SiteImages from "../screens/ThirdAdmin/Difuza/SiteImages";
import DfcarzCms from "../screens/ThirdAdmin/Difuza/DfcarzCms/DfcarzCms";
import Filesteeauto from "../screens/ThirdAdmin/Difuza/DfcarzCms/Fileimage";
import DfcarzHistory from "../screens/ThirdAdmin/DifuzaCars/TL/DfcarzHistory";
import SalesTodaytask from "../screens/ThirdAdmin/DifuzaCars/Screen/SalesTodaytask";
import SalesPendingtask from "../screens/ThirdAdmin/DifuzaCars/Screen/SalesPendingtask";
import DfcarzCustomerHistory from "../screens/ThirdAdmin/DifuzaCars/Screen/DfcarzCustomerHistory";
import { FormMiddleWare } from "../components/Form";
import DfCarsProfile from "../screens/ThirdAdmin/DifuzaCars/DfCarsProfile";
import DfcarzSEO from "../screens/ThirdAdmin/Difuza/DfcarzCms/DfcarzSEO"
import RedisUpdate from "../screens/redisUpdate/RedisUpdate";
import UserHome from "../screens/CMSUser/userHome"

// CMS Screen
import CMSDashboard from "../screens/ThirdAdmin/CMS/CMSDashboard";
import CMSNav from "../screens/SideNav/CMSNav";
import CMSCategory from "../screens/ThirdAdmin/CMS/Category";
import CmdlCategory from "../screens/ThirdAdmin/CMS/CmdlCategory"
import CMSAddPage from "../screens/ThirdAdmin/CMS/AddPage";
import AddPagebc from "../screens/ThirdAdmin/CMS/AddPage_BC";
import Preview from "../screens/ThirdAdmin/CMS/PreviewTemplate";
import Addform from "../screens/ThirdAdmin/CMS/addform";
import MapCatToPanel from "../screens/ThirdAdmin/CMS/MapCatToPanel";
import SubTitle from "../screens/ThirdAdmin/CMS/SubTitle";
import CMSUser from "../screens/ThirdAdmin/CMS/AddUser";
import CMSMentor from "../screens/ThirdAdmin/CMS/AddMentor";
import CMSDepartment from "../screens/ThirdAdmin/CMS/AddDepartment";
import AddImage from "../screens/ThirdAdmin/CMS/AddImage";
import CategoryMaster from "../screens/ThirdAdmin/CMS/CategoryMaster";
import SubCategoryMaster from "../screens/ThirdAdmin/CMS/SubCategoryMaster";
import AddAuthor from "../screens/ThirdAdmin/CMS/AddAuthor";
import ArrangeNav from "../screens/ThirdAdmin/CMS/ArrangeNav";
import ArrangeSubCategory from "../screens/ThirdAdmin/CMS/ArrangeSubCategory";
import CMSuserlog from "../screens/ThirdAdmin/CMS/Cmsuserlog";
import CmsAlllog from "../screens/ThirdAdmin/CMS/Cmsalllog";
import CMSuserlogFirstPage from "../screens/ThirdAdmin/CMS/CMSuserlogFirstPage";
import CmsLoginLog from "../screens/ThirdAdmin/CMS/CmsLoginLog";
import CMSalluserlog from "../screens/ThirdAdmin/CMS/cmsalluserlogs";
import DealerReport from "../screens/ThirdAdmin/CMS/DealerReport";
import About from "../screens/CMSUser/About";
import SingleContent from "../screens/CMSUser/SingleContent";
import SingleContent1 from "../screens/CMSUser/SingleContent1";
import CMSfooter from "../screens/Footer/CMSfooter";
import SearchList from "../screens/CMSUser/SearchList";
import Home from "../screens/CMSUser/Home";
import MapPageToHome from "../screens/ThirdAdmin/CMS/MapPageToHome";
import CategoryList from "../screens/CMSUser/CategoryList";
import UserEvaluation from "../screens/ThirdAdmin/CMS/UserEvaluation";
import Gamification from "../screens/CMSUser/Gamification";
import Dynamic from "../screens/CMSUser/Dynamic";
import DynamicTable from "../screens/ThirdAdmin/CMS/dynamicTable";
import SheetContent from "../screens/CMSUser/sheetContent";
import SheetContent2 from "../screens/CMSUser/sheetContent2";
import SheetContent3 from "../screens/CMSUser/SheetContent3";
import SheetContent4 from "../screens/CMSUser/SheetContent4";
import PublicationList from "../screens/CMSUser/publicationList";
import MessageComponent from "../screens/CMSUser/MessageComponent";
import MessageComponent1 from "../screens/CMSUser/MessageComponent1";




export default class AdminRoutes extends Component {
  // async componentDidMount() {
  //   try {
  //     console.log("componentDidMountcomponentDidMountcomponentDidMountcomponentDidMount");
  //     let eRR = new FormMiddleWare
  //     eRR.autoUpdate(true)
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  render() {

    let difuza = ["difuza.com", "www.difuza.com"];
    let ehs = [
      "ehsportal.in",
      "www.ehsportal.in",
      "murugappa.ehsportal.in",
      "www.murugappa.ehsportal.in"
    ];
    let path = window.location.href.split("/");
    let v = [];
    if (difuza.indexOf(path[2]) !== -1) {
      console.log('login 1');
      v.push(<Route exact path={"/"} render={props => <Hello {...props} />} />);
    } else if (ehs.indexOf(path[2]) !== -1) {
      console.log('login 2');
      v.push(
        <Route exact path={"/"} render={props => <UserHome {...props} />} />
      );
    } else {
      console.log('login 3');
      v.push(<Route exact path={"/"} render={props => <Hello {...props} />} />);
    }
    return (
      <Router>
        <Switch>
          {/*  Admin Routing */}
          {v}
          <Route exact path={"/Test"} render={props => <Test {...props} />} />
          <Route exact path={"/operate"} render={props => <Login {...props} />} />
          <Route
            exact
            path={"/ehsportal"}
            render={props => <Ehsportal {...props} />}
          />

          <Route
            exact
            path={"/redisButton/dotcue"}
            render={
              props => (
                <React.Fragment>
                  <RedisUpdate {...props} />
                </React.Fragment>
              )
            }
          />
          {/* L3 Super Admin Rouitng */}
          <Route
            exact
            path={"/superAdmin"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <SideNavThirdAdmin {...props} />
                  <SuperAdminDashboard {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          {/* CMS Routing */}
          <Route
            exact
            path={"/ContentManagementSystem/:id"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <CMSDashboard {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsCategory"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <CMSCategory {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmdlCategory"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <CmdlCategory {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/cmsuserlog"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <CMSuserlogFirstPage {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsuserlogSecond/:p/:sd/:ed"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <CMSuserlog {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsalluserlog/:userid/:sd/:ed"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <CMSalluserlog {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsallog/:userid/:pageid"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <CmsAlllog {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsloginog/:userid"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <CmsLoginLog {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/cmsAddPage"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <AddPagebc {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/PreviewTemplate"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <Preview {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/Cms/Forms"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <Forms {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCarz/sitehome"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNavTemp {...props} />
                  <SiteHome {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCarz/cmsAddPage"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNavTemp {...props} />
                  <DfcarzCms {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCarz/SEO"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNavTemp {...props} />
                  <DfcarzSEO {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCarz/images"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNavTemp {...props} />
                  <Filesteeauto {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCarz/SiteImages"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNavTemp {...props} />
                  <SiteImages {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/Cms/FormReport"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <CMSFormReport {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/addform"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <Addform {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsRightPanel"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <MapCatToPanel {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsHomeDownPanel"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <MapPageToHome {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsSubTitle"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <SubTitle {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsAddUser"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <CMSUser {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsAddMentor"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <CMSMentor {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />


          <Route
            exact
            path={"/cmsAddPage"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <CMSAddPage {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/dealerReport"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <DealerReport {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/cmsAddDepartment"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <CMSDepartment {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/cmsAddImage"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <AddImage {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/cmscategorymaster"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <CategoryMaster {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmssubcategorymaster"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <SubCategoryMaster {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsAddAuthor"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <AddAuthor {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/cmsArrangeNav"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <ArrangeNav {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/ArrangeSubCategory"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <ArrangeSubCategory {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/CMS/addTable"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <DynamicTable {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          {/* CMS User Routing */}
          <Route
            exact
            path={"/CMS/About/:id"}
            render={props => (
              <React.Fragment>
                <UserHeader {...props} />
                <div className="app-body">
                  <About {...props} />
                </div>
                <CMSfooter {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/CMS/singleContent/:id"}
            render={props => (
              <React.Fragment>
                <UserHeader {...props} />
                <div className="app-body ">
                  <SingleContent {...props} />
                </div>
                <CMSfooter {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/CMS/safetyHealth"}
            render={props => (
              <React.Fragment>
                <UserHeader {...props} />
                <div className="app-body ">
                  <SingleContent1 {...props} />
                </div>
                <CMSfooter {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/CMS/Gamification"}
            render={props => (
              <React.Fragment>
                <UserHeader {...props} />
                <div className="app-body ">
                  <Gamification {...props} />
                </div>
                <CMSfooter {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/CMS/Dynamic/:id"}
            render={props => (
              <React.Fragment>
                <UserHeader {...props} />
                <div className="app-body ">
                  <Dynamic {...props} />
                </div>
                <CMSfooter {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/home"}
            render={props => (
              <React.Fragment>
                <Home {...props} />
                <CMSfooter {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/CMS/Home"}
            render={props => <UserHome {...props} />}
          />

          <Route
            exact
            path={"/CMS/CategoryList"}
            render={props => (
              <React.Fragment>
                <UserHeader {...props} />
                <div className="app-body ">
                  <CategoryList {...props} />
                </div>
                <CMSfooter {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/CMS/SearchList"}
            render={props => (
              <React.Fragment>
                <UserHeader {...props} />
                <div className="app-body ">
                  <SearchList {...props} />
                </div>
                <CMSfooter {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/CMS/Evaluation"}
            render={props => (
              <React.Fragment>
                <UserHeader {...props} />
                <div className="app-body ">
                  <UserEvaluation {...props} />
                </div>
                <CMSfooter {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/CMS/Sheet"}
            render={props => (
              <React.Fragment>
                <UserHeader {...props} />
                <div className="app-body ">
                  <SheetContent {...props} />
                </div>
                <CMSfooter {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/CMS/Sheet2"}
            render={props => (
              <React.Fragment>
                <UserHeader {...props} />
                <div className="app-body ">
                  <SheetContent2 {...props} />
                </div>
                <CMSfooter {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/CMS/Sheet3"}
            render={props => (
              <React.Fragment>
                <UserHeader {...props} />
                <div className="app-body ">
                  <SheetContent3 {...props} />
                </div>
                <CMSfooter {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/CMS/Sheet4"}
            render={props => (
              <React.Fragment>
                <UserHeader {...props} />
                <div className="app-body ">
                  <SheetContent4 {...props} />
                </div>
                <CMSfooter {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/CMS/Publication"}
            render={props => (
              <React.Fragment>
                <UserHeader {...props} />
                <div className="app-body ">
                  <PublicationList {...props} />
                </div>
                <CMSfooter {...props} />
              </React.Fragment>
            )}
          />
          {/* DF routing */}
          <Route
            exact
            path={"/Difuza/:id"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <DifuzaDashboard {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/BoxTypes"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <BoxTypes {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/EditBoxTypes/:d"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <BoxTypes {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/BoxTypesCopy"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <BoxTypesCopy {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/BoxTypes/table/:customer/:usertype"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />

                  <Viewtable {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/MapPortletToGroup/:customer/:groupId/:contentId"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />

                  <CohortsView {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/Reports/:customer/:groupId/:contentId"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />

                  <CohortsView {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/SurveyReports/:customer/:groupId/:contentId"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />

                  <CohortsView {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/SurveyMapping/:customer/:groupId/:contentId"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />

                  <CohortsView {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/UserLogReport/:customer/:groupId/:contentId"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />

                  <CohortsView {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/Survey/:customer/:groupId/:contentId"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <CohortsView {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/SurveyPage/:customer/:groupId/:contentId"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <CohortsView {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/SurveyQuestion/:customer/:groupId/:contentId"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <CohortsView {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/SurveyMapping/:customer/:groupId/:contentId"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <CohortsView {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/CohortsView/:groupId/:contentId"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />

                  <PortletsView {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DF/Learners"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <Learners {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/Mentors"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <Mentors {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/Manager"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <DifManager {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DF/Group"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <Group {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/Program"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <Program {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/Batch"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <Batch {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/Domain"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <Domain {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/LearningObject"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <LearningObject {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/Location"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <Location {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/Resources"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <Resources {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/MappingToGroup"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <MappingToGroup {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/ManagerToMentor"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <ManagerToMentor {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/MentorToGroup"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <MentorToGroup {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DF/MapPortletToGroup"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <MapPortletToGroup {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/Reports"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <Reports {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/SurveyReports"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <SurveyReports {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/UserLogReport"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <UserLogReport {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/Survey"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <Survey {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/SurveyPage"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <SurveyPage {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/SurveyQuestion"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <SurveyQuestion {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/SurveyMapping"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <SurveyMapping {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/applogo"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <AppLogo {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DF/performanceReport"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <PerformanceReport {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/WizardForm/:userid/:quizid/:portid"}
            render={props => (
              <React.Fragment>
                <div className="app-body">
                  <WizardForm {...props} />
                </div>
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/LearningPath"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <LearningPath {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/Forms"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <Forms {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/FormView/:userid/:formid/:portid"}
            render={props => (
              <React.Fragment>
                <div className="app-body">
                  <FormView {...props} />
                </div>
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/FormReport"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <FormReport {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/SurveyForm/:userid/:quizid/:portid"}
            render={props => (
              <React.Fragment>
                <div className="app-body">
                  <SurveyForm {...props} />
                </div>
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/userUnder"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  {/* <DifuzaUserNav {...props} /> */}
                  <UserUnder {...props} />
                </div>
              </React.Fragment>
            )}
          />
          {/*Difuza User page*/}
          <Route
            exact
            path={"/difuzaUser"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaUserNav {...props} />
                  <DifuzaUser {...props} />
                </div>
              </React.Fragment>
            )}
          />
          {/*Difuza Next user page*/}
          <Route
            exact
            path={"/difuzaNextUser"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaUserNav {...props} />
                  <DifuzaNextUser {...props} />
                </div>
              </React.Fragment>
            )}
          />
          {/* Df user See All */}
          <Route
            exact
            path={"/dfUserSeeAll"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaUserNav {...props} />
                  <DfUserSeeAll {...props} />
                </div>
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/img"}
            render={props => (
              <React.Fragment>
                <div className="app-body">
                  <MessageComponent {...props} />
                </div>
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/img1"}
            render={props => (
              <React.Fragment>
                <div className="app-body">
                  <MessageComponent1 {...props} />
                </div>
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DF/RTReport"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body" style={{ backgroundColor: "#252B30" }}>
                  <DifuzaNav {...props} />
                  <Rtreport {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route exact path={"/difuzaPrivacy"} render={props => <Privacy />} />

          {/* DifuzaCars Master temp Routes */}
          <Route
            exact
            path={"/DifuzaCarz/:id"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNavTemp {...props} />
                  <DifuzaCarsDashboard {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCarz/DfCaresUsertype"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNavTemp {...props} />
                  <DfCaresUsertype {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCarz/dfCarsAddDesignation"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNavTemp {...props} />
                  <DfCarsAddDesignation {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCarz/DfCarsAddUsers"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNavTemp {...props} />
                  <DfCarsAddUsers {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCarz/DfCarsLocation"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNavTemp {...props} />
                  <DfCarsLocation {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCarz/DfCarsBranch"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNavTemp {...props} />
                  <DfCarsBranch {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCarz/DfCarAdminEnquiry"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNavTemp {...props} />
                  <DfCarAdminEnquiry {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCarz/DfCarAdminContact"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNavTemp {...props} />
                  <DfCarAdminContact {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCarz/DfCarAdminAppointment"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNavTemp {...props} />
                  <DfCarAdminAppointment {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCarz/Dfcolormaster"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNavTemp {...props} />
                  <CarsColourMaster {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCarz/Dfbrandmaster"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNavTemp {...props} />
                  <CarsBrandMaster {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCarz/Dfmakemaster"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNavTemp {...props} />
                  <CarsmakeMaster {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCarz/Dfvariantmaster"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNavTemp {...props} />
                  <Carsvariantmaster {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCarz/Dfmodelmaster"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNavTemp {...props} />
                  <CarsmodelMaster {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCarz/Dfnewmakemaster"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNavTemp {...props} />
                  <CarsNewMakeMaster {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCarz/DfcarzSalesCon"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNavTemp {...props} />
                  <DfcarsSalesConsultant {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          {/* DifuzaCars Routes */}
          <Route
            exact
            path={"/DifuzaCars/:id"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNav {...props} />
                  <DifuzaCarsDashboard {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/DfCaresUsertype"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNav {...props} />
                  <DfCaresUsertype {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/Dfcolormaster"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNav {...props} />
                  <CarsColourMaster {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/Dfbrandmaster"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNav {...props} />
                  <CarsBrandMaster {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/Dfmakemaster"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNav {...props} />
                  <CarsmakeMaster {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/Dfvariantmaster"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNav {...props} />
                  <Carsvariantmaster {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/Dfmodelmaster"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNav {...props} />
                  <CarsmodelMaster {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/Dfcarswarranty"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNav {...props} />
                  <DfCarsWarranty {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/DfCarsFormOrder"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNav {...props} />
                  <DfCarsFormOrder {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/PocEnquiryreport"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <PocEnquiryreport {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/DfCarsGobalsearch"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <DfCarsGobalsearch {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/DfcarsViewmore/:id"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <DfCarsViewMore {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/DfcarsproductViewmore/:id"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <ProductViewmore {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/DfCarsLocation"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNav {...props} />
                  <DfCarsLocation {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/DfCarsMapHierarchy"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNav {...props} />
                  <DfCarsMapHierarchy {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/dfCarsAddDesignation"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNav {...props} />
                  <DfCarsAddDesignation {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/DfCarsAddUsers"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNav {...props} />
                  <DfCarsAddUsers {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/DfCarsAdminEnquiry"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNav {...props} />
                  <DfCarsCSVEnquiry {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/DfCarsEnquiry"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <DfCarsEnquiry {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/DfCarsProfile"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <DfCarsProfile {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/DfcarsSalesEnquiry"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <DfcarsSalesEnquiry {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/DfCarsMapForm"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNav {...props} />
                  <DfCarsMapForm {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/DfCarsViewhistory/:id"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <DfCarsViewhistory {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/DfcarsAddForms"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNav {...props} />
                  <DfcarsAddForms {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/DfcarsdeliverySeller"}
            render={props => (
              <React.Fragment>
                {/* <AdminHeader {...props} /> */}
                <div className="app-body">
                  {/* <DifuzaCarsNav {...props} /> */}
                  <DfcarsdeliverySeller {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/processdefinition"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNav {...props} />
                  <ProcessDefinition {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/UserDashboard"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <DfcarsUserdash {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/viewDashboard/:name"}
            render={props => (
              <React.Fragment>
                   <ViewDashboard {...props} />
                   </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/ExternalUserform"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <ExternalUserform {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/BidShare/:id?"}
            render={props => (
              <React.Fragment>
                {/* <DifuzaCarHeader {...props} /> */}
                <div className="app-body">
                  {/* <NewCarCons {...props} /> */}
                  <BidShare {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/Teledashboard"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <DfcarsUserdash {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/TeleEnquiry"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <TeleEnquiry {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/RFtechnicianlist"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <RFtechnicianlist {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/RFinchargelist"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <RFinchargelist {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/DfCarsFproof"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <DfCarsFproof {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/Bidform"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <BidForm {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/Bidform1/:fid?"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <BidForm1 {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/Evaluation_Checklist_F_Proof/:cusid?/:bid?"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <Evaluation_Checklist_F_Proof {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/Evaluation_Checklist_B_Proof/:cusid?"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <Evaluation_Checklist_B_Proof {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/todaytask"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <Todaytask {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/pendingtask"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <Pendingtask {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/Salestodaytask"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <SalesTodaytask {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/Salespendingtask"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <SalesPendingtask {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/DfCarsdocumentation"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <DfCarsdocumentation {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/UserEnquiryreport"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <UserEnquiryreport {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/Evaluationreport"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <Evaluationreport {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/EvaluatorDelivery"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <EvaluatorDelivery {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/DFContracted"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <DFContracted {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/AT_Booklet_Purchase_Contract_Proof/:cusid?"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <AT_Booklet_Purchase_Contract_Proof {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/DfCarspaymentprocess"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <DfCarspaymentprocess {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/DfCarsphysicalprocess"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <DfCarsphysicalprocess {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/ProspectList"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <ProspectList {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/ClosedCustomer"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <ClosedCustomer {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/AT_Inventory_Sheet_Reproof/:cusid?"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <AT_Inventory_Sheet_Reproof {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/Delivery_Note_Seller_Proof/:cusid?"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <Delivery_Note_Seller_Proof {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/PocEvaluation"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <PocEvaluation {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/PocBuyerSpecification"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <PocBuyerSpecification {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/PocSourcing"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <PocSourcing {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/PocContracted"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <PocContracted {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/PocDelivery"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <PocDelivery {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/PocProspectBuyerList"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <PocProspectBuyerList {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/PocRefurbishment"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <PocRefurbishment {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/AT_Specifications_Sheet_Proof/:cusid?"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <AT_Specifications_Sheet_Proof {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />


          <Route
            exact
            path={"/DFCars/BuyerEnquiryform"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <BuyerEnquiryform {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/PriceList"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <PriceList {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/Sourcing"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <Sourcing {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/Contracted"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <Contracted {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/RcTransfer"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <RcTransfer {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/Rcform/:id?"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <Rcform {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/Delivery"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <Delivery {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/InventoryList"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <InventoryList {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/GlobalReport"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <GlobalReport {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          {/* DownlineReport */}
          <Route
            exact
            path={"/DFCars/DownlineGlobalReport"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <DownlineReport {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/MyInventory"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <MyInventory {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/MyInventory/:make/:model/:location"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <MyInventory1 {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/InventoryList/:id"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <InventoryList {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/Seller_enquiry_form"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNav {...props} />
                  <Seller_enquiry_form {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/CERTIFICATION_350_CheckPoints/:cusid?"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <CERTIFICATION_350_CheckPoints {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/TLdocumentation"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <TLdocumentation {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/TLProspectBuyerList"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <TLProspectBuyerList {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/TLRefurbishment"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <TLRefurbishment {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/History"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <DfcarzHistory {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/CustomerHistory"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <DfcarzCustomerHistory {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/TLpaymentprocess"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <TLpaymentprocess {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/TLBuyerEnquiryform"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <TLBuyerEnquiryform {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/TLSourcing"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <TLSourcing {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/TLContracted"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <TLContracted {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/TLDelivery"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <TLDelivery {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/TLphysicalprocess"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <TLphysicalprocess {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/Refurbishment"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <Refurbishment {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/Accdocumentation"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <Accdocumentation {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/Acccontract"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <AccContract {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/Autoterrace_enquiry_form/:cusid?"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <Autoterrace_enquiry_form {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/AT_Delivery_Note_Buyer_Reproof/:cusid?"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <AT_Delivery_Note_Buyer_Reproof {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/AT_Booking_Confirmation_Reproof/:cusid?"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <AT_Booking_Confirmation_Reproof {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/drawerMenuLabel"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNav {...props} />
                  <DrawerMenuLabel {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/drawermapping"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNav {...props} />
                  <DrawerMapping {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/billupload"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <UploadBill {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/pricelistmaster"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaCarsNav {...props} />
                  <PriceListMaster {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/TransactionHistory/:prosId"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <TransactionHistory {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/Cardata/:id"}
            render={props => (
              <React.Fragment>
                {/* <DifuzaCarHeader {...props} /> */}
                <div className="app-body">
                  <InventCarData {...props} />

                </div>
                {/* <Footer {...props} /> */}
              </React.Fragment>
            )}
          />

          {/* Difuza demo project*/}
          <Route
            exact
            path={"/DifuzaCarsample/:id"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DemoSideNav {...props} />
                  <NewDfcarsUserPage {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DifuzaCarsSample/DfMakemaster"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DemoSideNav {...props} />
                  <NewMakeMaster {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DifuzaCarsSample/Dfvariantmaster"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DemoSideNav {...props} />
                  <NewVariantMaster {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DifuzaCarsSample/DfModelmaster"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DemoSideNav {...props} />
                  <NewModelMaster {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DifuzaCarsSample/DfUserpage"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DemoSideNav {...props} />
                  <NewDfcarsUserPage {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DifuzaCarsSample/DfcarzSalesCon"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DemoSideNav {...props} />
                  <NewDfcarsSalesConsultant {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/demoAdduser"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DemoSideNav {...props} />
                  <DemoAdduser {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          {/* ///telecaller routes/// */}


          <Route
            exact
            path={"/DFCars/TeleSellingEnquiry"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <TeleSellingEnquiry {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/TeleUserEnquiry"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <TeleUserEnquiry {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/TeleEvaluationreport"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <TeleEvaluationreport {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/Teledocumentation"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <Teledocumentation {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/Telephysicalprocess"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <Telephysicalprocess {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/TeleInventoryList"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <TeleInventoryList {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/TeleProspectBuyerList"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <TeleProspectBuyerList {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/DFCars/TeleContracted"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <TeleContracted {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/TeleDelivery"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <TeleDelivery {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DFCars/TeleRcTransfer"}
            render={props => (
              <React.Fragment>
                <DifuzaCarHeader {...props} />
                <div className="app-body">
                  <NewCarCons {...props} />
                  <TeleRcTransfer {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          /> *

          {/* REouting End */}
        </Switch>
      </Router>

    );
  }
}
