import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
//import DfCarsEnquiry from "./DfCarsEnquiry";
import moment from "moment";
import Datatable from "../../../../components/Datatable/Datatable";
import SingleSelect from '../../../../components/Form/SingleSelect'

class PriceList extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      customerId: localStorage.getItem("userId"),
      modelopt: [],
      MakeOptions: [],
      EnquiryData: [],
      modeloption: [],
      fulldata: [],
      data: [],
      opt: [],
      alertVisible: false,
      column: [
        {
          Header: "MAKE",
          accessor: "makename"
        },
        {
          Header: "MODEL",
          accessor: "modelname"
        },
        {
          Header: "VARIANT",
          accessor: "variant"
        },
        {
          Header: "MAKE YEAR",
          accessor: "make_year"
        },
        {
          Header: "KMS",
          accessor: "kms"
        },
        {
          Header: "COLOUR",
          accessor: "ColourName"
        },
        {
          Header: "OWNER_TYPE",
          accessor: "owner_type"
        },
        {
          Header: "YOM",
          accessor: "make_year"
        },
        {
          Header: "INSURANCE_EXP",
          accessor: "insurance_exp"
        },
        {
          Header: "LOCATION",
          accessor: "location"
        },
        {
          Header: "PRICE",
          accessor: "price"
        }
      ]
    }
  }

  async componentDidMount() {
    try {
      this.setState({ loading: true })
      let result = await cmsContent.getFreedom(
        'tbl_prize_list.*,tbl_make_master.Make as makename,tbl_dfcarsmodel.Model as modelname,tbl_colour.colour as ColourName',
        `tbl_prize_list,tbl_make_master,tbl_dfcarsmodel,tbl_colour`,
        `tbl_make_master.id = tbl_prize_list.make and tbl_dfcarsmodel.id = tbl_prize_list.model and tbl_colour.id = tbl_prize_list.color`
      )
      if (result) {
        //console.log(result.data);
        this.setState({ Data: result.data, fulldata: result.data, loading: false })
      }
      let { data: Make } = await cmsContent.getFreedom(
        `Make as label , id as value`,
        `tbl_make_master`, 1,
        1, 1
      );
      if (Make) {
        this.setState({ MakeOptions: Make })
      }
      let { data: model } = await cmsContent.getFreedom(
        "Model as label , id as value , makeid as makeid",
        "tbl_dfcarsmodel",
        1,
        1
      );
      if (model) {
        this.setState({ modelopt: model })
      }
      //console.log(Make, model)

    } catch (error) {
      console.log(error)
    }
  }
  cng = (e, val) => {
    console.log(e, val)
    let data1 = []
    let data2 = []
    let modeloption = []
    if (val === "make") {
      this.state.modelopt.map((ival, i) => {
        if (ival.makeid === e.value) {
          modeloption.push(ival)
        }
      })
      this.state.fulldata.map((jval, i) => {
        if (jval.makename === e.label) {
          data1.push(jval)
        }
      })
      this.setState({ Data: data1, modeloption, opt: "" })
    }
    if (val === "model") {
      this.state.fulldata.map((jval, i) => {
        if (jval.modelname === e.label) {
          data2.push(jval)
        }
      })
      this.setState({ Data: data2, opt: e })
    }
  }
  edash = () => {
    return (<div className="card">

      <div className="card-header">
        <div className="col-sm-6" >
          <h2 className="mt-2 black">Dashboard</h2>
        </div>
      </div>
      <div className="card-body">
        <div className="row col-12 mt-2">
          <div className="col-sm-6 ">
            <h4 className="black">Make :</h4>
            <SingleSelect
              options={this.state.MakeOptions}
              handleChange={e => this.cng(e, "make")}
            />
          </div>
          <div className="col-sm-6 ">
            <h4 className="black"> Model :</h4>
            <SingleSelect
              options={this.state.modeloption}
              handleChange={e => this.cng(e, "model")}
              selectedService={this.state.opt}
            />
          </div>
        </div>
      </div>
    </div>)
  }
  render() {
    let { loading } = this.state;
    return (<React.Fragment>
      <main className="main my-4">
        <div className="container-fluid">
          {this.edash()}
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h1>Price List</h1>
                </div>
                <div className="card-body">
                  <div className="row mt-4">
                    <div className="col-sm-12">
                      {loading ? <div className="text-center"> <i
                        className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                      ></i></div> : this.state.Data.length ? (
                        <Datatable
                          data={this.state.Data}
                          columnHeading={this.state.column}
                        />
                      ) : <h1>No data found</h1>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>)
  }
}
export default PriceList;