import React, { Component } from "react";
import Utilities from "../../../../MiddleWare/Utilities";
import redisMiddleware from "../../../../MiddleWare/redisMiddleware";
import CmsContent from "../../../../MiddleWare/CmsContent";
import ReportCharts from "../TL/ReportCharts";

export default class Accounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      usertypeName: "",
      TodayNotifiy: [],
      PendingTask: [],
      TodayEvaluation: [],
      PendingContracted: [],
      PendingDelivery: [],
      PendiData:[]
       ,CompData:[],
      buy: true,
      tbl_Inventory_Sheet_Reproof:[],
      sell: false,
      LocationID: localStorage.getItem(`LocationID`),
    };
  }
  async componentDidMount() {
    let Userlog = JSON.parse(localStorage.getItem("userlog"));
    try {
      const result = await redisMiddleware.singleTable(
        "tbl_UserType",
        Userlog.userType,
        "id"
      );
      if (result) {
        this.setState({
          data: result.data,
          usertypeName: result.data[0].usertype,
        });
      }
      const Product = await CmsContent.getFreedom(
        "*",
        "tbl_dfcarz_enquiryform",
        1
      );
      let TodayNotifiy = [];
      let PendingTask = [];
      let CompletedTasks = [];
      if (Product) {
        Product.data.map((ival, i) => {
          let EnquiryDate = new Date();
          let d = new Date(ival.createdAt);
          // (
          //   new Date(ival.createdAt).getTime() + 60 * 60 * 24 * 1000
          // );
          //
          let a = EnquiryDate.getDate();
          let b = d.getDate();

          let Datetime1 = EnquiryDate.getTime();
          let Datetime2 = d.getTime();
          // console.log(Datetime2,Datetime1);
          if (ival.enquiry_status == "active") {
            if (b == a) {
              TodayNotifiy.push(ival);
             // console.log(ival);
            } else if (Datetime1 > Datetime2) {
              PendingTask.push(ival);
            }
          } else {
            CompletedTasks.push(ival);
          }
        });
        this.setState({ TodayNotifiy, PendingTask, CompletedTasks });
      }

      // let Result = await CmsContent.getFreedom(
      //   `tbl_dfcarz_prosperities.*,tbl_user_web.userName,tbl_FProof.id as F_id,tbl_BProof.id as B_id,tbl_FProof.approval as F_approval,tbl_FProof.reg_no as regno,tbl_BProof.approval as B_approval ,tbl_BProof.createdAt as B_date,tbl_FProof.createdAt as F_date`,
      //   `tbl_dfcarz_prosperities,tbl_FProof,tbl_BProof,tbl_user_web`,
      //   `tbl_dfcarz_prosperities.id = tbl_FProof.customerid and tbl_dfcarz_prosperities.id = tbl_BProof.customerid and tbl_BProof.userid = tbl_user_web.id and tbl_dfcarz_prosperities.location=${this.state.LocationID}`,
      //   1,
      //   1
      // );
      let Result = await redisMiddleware.Dashboard("TodayEvaluation", 6, this.state.LocationID);
      if (Result) {
        let TodayEvaluation = [];
        //  console.log(Result.data);
        Result.data.map((ival, i) => {
          let lastdate = new Date(
            new Date(ival.B_date).getTime() + 60 * 60 * 24 * 1000
          );
          let currentdate = new Date();
          let a = lastdate.getDate();
          let b = currentdate.getDate();
          //console.log(a,b)
          if (b <= a) {
            TodayEvaluation.push(ival);
          }
        });
        this.setState({ TodayEvaluation });
        this.tbl_Booking_Confirmation_Reproof();
        this.tbl_Delivery_Note_Buyer_Reproof();
        this.TBl_invertery_notify()
      }
    } catch (error) {
      console.log(error);
    }
  }

  TBl_invertery_notify=async()=>{
    try{
  
      let result = await CmsContent.getFreedom(
        "tbl_dfcarz_prosperities.* , tbl_Inventory_Sheet_Reproof.approval,tbl_Inventory_Sheet_Reproof.date",
        `tbl_dfcarz_prosperities,tbl_Inventory_Sheet_Reproof`,
        `tbl_dfcarz_prosperities.location =${this.state.LocationID}  and tbl_dfcarz_prosperities.id=tbl_Inventory_Sheet_Reproof.customerid`,
        1,
        `tbl_dfcarz_prosperities.id DESC`
      );
      if (result) {
       let CompData =[];
       let PendiData=[];
         console.log(result.data);
           result.data.map((ival,i)=>{
              if(ival.approval==1){
                 CompData.push(ival)
              }else{ 
                 PendiData.push(ival)
        
              }
           })
         this.setState({ tbl_Inventory_Sheet_Reproof : result.data ,PendiData ,CompData  })
      }

    }catch (error) {
      console.log(error);
    }
  }


  tbl_Booking_Confirmation_Reproof = async () => {
    try {
      let PendingContracted = [];
      // let Buyer = await CmsContent.getFreedom(
      //   "tbl_dfcarz_prosperities.*,tbl_user_web.userName as jobroleName",
      //   "tbl_dfcarz_prosperities,tbl_Autoterrace_enquiry_form,tbl_user_web",
      //   `tbl_user_web.id = tbl_Autoterrace_enquiry_form.userid and tbl_dfcarz_prosperities.type='buyer' and tbl_dfcarz_prosperities.id = tbl_Autoterrace_enquiry_form.customerid and tbl_dfcarz_prosperities.location=${this.state.LocationID} `
      // );
      let Buyer = await redisMiddleware.Dashboard("tbl_Booking_Confirmation_Reproof", 6, this.state.LocationID);
      if (Buyer) {
        //console.log(Buyer);
        Buyer.data.map(async (ival, i) => {
          let result = await CmsContent.getFreedom(
            `*`,
            `tbl_Booking_Confirmation_Reproof`,
            `customerid=${ival.id}`
          );
          if (!result.data.length) {
            PendingContracted.push(ival);
          }
        });
      }
      this.setState({ PendingContracted });
    } catch (error) {
      console.log(error);
    }
  };

  tbl_Delivery_Note_Buyer_Reproof = async () => {
    try {
      let PendingDelivery = [];
      // let Buyer = await CmsContent.getFreedom(
      //   "tbl_dfcarz_prosperities.*,tbl_user_web.userName,tbl_Booking_Confirmation_Reproof.regno as regno",
      //   "tbl_dfcarz_prosperities,tbl_Booking_Confirmation_Reproof,tbl_user_web",
      //   `tbl_user_web.id = tbl_Booking_Confirmation_Reproof.userid and tbl_dfcarz_prosperities.type='buyer' and tbl_dfcarz_prosperities.id = tbl_Booking_Confirmation_Reproof.customerid and tbl_dfcarz_prosperities.location=${this.state.LocationID}`
      // );
      let Buyer = await redisMiddleware.Dashboard("tbl_Delivery_Note_Buyer_Reproof", 6, this.state.LocationID);
      if (Buyer) {
        Buyer.data.map(async (ival, i) => {
          let result = await CmsContent.getFreedom(
            `*`,
            `tbl_Delivery_Note_Buyer_Reproof`,
            `customerid=${ival.id}`
          );
          if (!result.data.length) {
            PendingDelivery.push(ival);
          }
        });
      }
      this.setState({ PendingDelivery });
    } catch (error) {
      console.log(error);
    }
  };
  MovetoSite = (d) => {
    console.log(d);
    if (d == "eval") {
      //window.open(`/DFCars/DfCarsEnquiry`);
      window.location.href = `/DFCars/TLpaymentprocess`;
    } else if (d == "delivery") {
      window.location.href = `/DFCars/TLpaymentprocess`;
    } else if (d == "contracted") {
      window.location.href = `/DFCars/Contracted`;
    }else if(d=='payment'){
      window.location.href = `/DFCars/TLpaymentprocess`;
    }
  };
  buyer = () => {
    this.setState({ buy: true, sell: false })
  }
  seller = () => {
    this.setState({ sell: true, buy: false })
  }
  render() {
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3>Welcome {this.state.usertypeName}</h3>
                  </div>
                  <br />
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <ReportCharts />
                        <ul class="nav nav-tabs">
                          <li class="nav-item">
                            <a
                              class="nav-link active"
                              data-toggle="tab"
                              href="#buy"
                              onClick={() => this.buyer()}
                            >
                              Buying
                            </a>
                          </li>
                          <li class="nav-item">
                            <a
                              class="nav-link"
                              data-toggle="tab"
                              href="#sell"
                              onClick={() => this.seller()}
                            >
                              Selling
                            </a>
                          </li>
                        </ul>
                        <div class="tab-content">
                          <div id="buy" class="container tab-pane active">
                            {this.state.buy && (
                              <div className="row form-group">
                                {this.state.TodayEvaluation.length ? (
                                  <div className="col-sm-6">
                                    <React.Fragment>
                                      <div className="card">
                                        <div
                                          className="card-header"
                                          style={{ backgroundColor: "lightgray" }}
                                        >
                                          <h3>
                                            Evaluation Pending (Comp{" "}
                                            {this.state.CompletedTasks.length} - Pend{" "}
                                            {this.state.TodayEvaluation.length})
                                    </h3>
                                        </div>
                                        <div className="card-body">
                                          {/* {this.state.TodayEvaluation.length ?  */}
                                          {this.state.TodayEvaluation.map(
                                            (ival, i) => {
                                              if (i < 8) {
                                                return (
                                                  <React.Fragment>
                                                    <div className="row-form-group">
                                                      <div className="col-sm-1" />
                                                      <div className="col-sm-12">
                                                        <a onClick={() => this.MovetoSite("eval")}>
                                                          <table className="dfcarsport">
                                                            <tr>
                                                              <td className="dfcarsportname">
                                                                {ival.name}
                                                              </td>
                                                              <td className="dfcarsportmobicon">
                                                                <i class="fa fa-mobile" aria-hidden="true"></i>
                                                              </td>

                                                              <td className="dfcarsportnumber">
                                                                {ival.mobile}
                                                              </td>
                                                              <td className="dfcarsportreg">
                                                                {ival.regno}
                                                              </td>
                                                            </tr>
                                                          </table>
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </React.Fragment>
                                                );
                                              }
                                            }
                                          )}
                                          {this.state.TodayEvaluation.length > 0 ? (
                                            <h5
                                              className="Readmore1"
                                              onClick={() => this.MovetoSite("eval")}
                                            >
                                              View More
                                            </h5>
                                          ) : null}
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  </div>
                                ) : null}

                        {this.state.tbl_Inventory_Sheet_Reproof.length ? (
                                  <div className="col-sm-6">
                                    <React.Fragment>
                                      <div className="card">
                                        <div
                                          className="card-header"
                                          style={{ backgroundColor: "lightgray" }}
                                        >
                                          <h3>
                                            Payment Process Pending (Comp{" "}
                                            {this.state.CompData.length} - Pend{" "}
                                            {this.state.PendiData.length})
                                    </h3>
                                        </div>
                                        <div className="card-body">
                                          {/* {this.state.TodayEvaluation.length ?  */}
                                          {this.state.tbl_Inventory_Sheet_Reproof.map(
                                            (ival, i) => {
                                              if (i < 8) {
                                                return (
                                                  <React.Fragment>
                                                    <div className="row-form-group">
                                                      <div className="col-sm-1" />
                                                      <div className="col-sm-12">
                                                        <a onClick={() => this.MovetoSite("payment")}>
                                                          <table className="dfcarsport">
                                                            <tr>
                                                              <td className="dfcarsportname">
                                                                {ival.name}
                                                              </td>
                                                              <td className="dfcarsportmobicon">
                                                                <i class="fa fa-mobile" aria-hidden="true"></i>
                                                              </td>

                                                              <td className="dfcarsportnumber">
                                                                {ival.mobile}
                                                              </td>
                                                              <td className="dfcarsportreg">
                                                                {ival.approval == 1 ? 'approved'  : 'Waiting' }
                                                              </td>
                                                              {/* <td className="dfcarsportreg">
                                                                {ival.date}
                                                              </td> */}
                                                            </tr>
                                                          </table>
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </React.Fragment>
                                                );
                                              }
                                            }
                                          )}
                                          {this.state.tbl_Inventory_Sheet_Reproof.length > 0 ? (
                                            <h5
                                              className="Readmore1"
                                              onClick={() => this.MovetoSite("payment")}
                                            >
                                              View More
                                            </h5>
                                          ) : null}
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  </div>
                                ) : null}

                              </div>
                            )}
                            {this.state.sell && (
                              <div id="sell" class="container tab-pane active">
                                {this.state.PendingContracted.length ? (
                                  <div className="col-sm-6">
                                    <React.Fragment>
                                      <div className="card">
                                        <div
                                          className="card-header"
                                          style={{ backgroundColor: "lightgray" }}
                                        >
                                          <h3>
                                            Contracted Pending (Comp{" "}
                                            {this.state.CompletedTasks.length} - Pend{" "}
                                            {this.state.PendingContracted.length})
                                    </h3>
                                        </div>
                                        <div className="card-body">
                                          {/* {this.state.TodayEvaluation.length ?  */}
                                          {this.state.PendingContracted.map(
                                            (ival, i) => {
                                              if (i < 8) {
                                                return (
                                                  <React.Fragment>
                                                    <div className="row-form-group">
                                                      <div className="col-sm-1" />
                                                      <div className="col-sm-12">
                                                        <a onClick={() => this.MovetoSite("contracted")}>
                                                          <table className="dfcarsport">
                                                            <tr>
                                                              <td className="dfcarsportname">
                                                                {ival.name}
                                                              </td>
                                                              <td className="dfcarsportmobicon">
                                                                <i class="fa fa-mobile" aria-hidden="true"></i>
                                                              </td>

                                                              <td className="dfcarsportnumber">
                                                                {ival.mobile}
                                                              </td>
                                                              <td className="dfcarsportreg">
                                                                {ival.email}
                                                              </td>
                                                            </tr>
                                                          </table>
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </React.Fragment>
                                                );
                                              }
                                            }
                                          )}
                                          {this.state.PendingContracted.length > 0 ? (
                                            <h5
                                              className="Readmore1"
                                              onClick={() =>
                                                this.MovetoSite("contracted")
                                              }
                                            >
                                              View More
                                            </h5>
                                          ) : null}
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  </div>
                                ) : null}
                                {this.state.PendingDelivery.length ? (
                                  <div className="col-sm-6">
                                    <React.Fragment>
                                      <div className="card">
                                        <div
                                          className="card-header"
                                          style={{ backgroundColor: "lightgray" }}
                                        >
                                          <h3>
                                            PendingDelivery (Comp{" "}
                                            {this.state.CompletedTasks.length} - Pend{" "}
                                            {this.state.PendingDelivery.length})
                                    </h3>
                                        </div>
                                        <div className="card-body">
                                          {/* {this.state.TodayEvaluation.length ?  */}
                                          {this.state.PendingDelivery.map(
                                            (ival, i) => {
                                              if (i < 8) {
                                                return (
                                                  <React.Fragment>
                                                    <div className="row-form-group">
                                                      <div className="col-sm-1" />
                                                      <div className="col-sm-12">
                                                        <a onClick={() => this.MovetoSite("delivery")}>
                                                          <table className="dfcarsport">
                                                            <tr>
                                                              <td className="dfcarsportname">
                                                                {ival.name}
                                                              </td>
                                                              <td className="dfcarsportmobicon">
                                                                <i class="fa fa-mobile" aria-hidden="true"></i>
                                                              </td>

                                                              <td className="dfcarsportnumber">
                                                                {ival.mobile}
                                                              </td>
                                                              <td className="dfcarsportreg">
                                                                {ival.regno}
                                                              </td>
                                                            </tr>
                                                          </table>
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </React.Fragment>
                                                );
                                              }
                                            }
                                          )}
                                          {this.state.PendingDelivery.length > 0 ? (
                                            <h5
                                              className="Readmore1"
                                              onClick={() =>
                                                this.MovetoSite("delivery")
                                              }
                                            >
                                              View More
                                            </h5>
                                          ) : null}
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  </div>
                                ) : null}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment >
    );
  }
}
