import React, { Component } from "react";
import SingleSelect from "../../../components/Form/SingleSelect";
import CmsContent from "../../../MiddleWare/CmsContent";
import Datatable from "../../../components/Datatable/Datatable";
import { Alert } from "reactstrap";
import { FormMiddleWare } from "../../../components/Form";
import { confirmAlert } from "react-confirm-alert";
import redisMiddleware from "../../../MiddleWare/redisMiddleware";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import SFAPDF from "../../../components/PDF/SFAPDF";

class DfCaresUsertype extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      alertVisible: false,
      formAlertdelete: false,
      disableValue: false,
      error: null,
      ordererror: null,
      customerId: localStorage.getItem("userId"),
      serviceId: localStorage.getItem("currentService"),
      button: "Submit",
      disabled: false,
      textalert: null,
      color: "success"
    };
  }
  async componentDidMount() {
    try {
      this.setState({ loading: true })
      let userlog = JSON.parse(localStorage.getItem("userlog"));
      // let serviceId = localStorage.getItem("currentService");

      // const result = await redisMiddleware.singleTable(
      //   "tbl_UserType",
      //   this.state.customerId,
      //   "customerid"
      // );

      const result = await CmsContent.getFreedom(
        "*",
        "tbl_UserType",
        `customerid = ${this.state.customerId} and status = "Active"`,
        1,
        1
      )
      // console.log(this.state.serviceId);
      let Data = [];
      if (result) {

        result.data.map((ival, i) => {
          if (ival.serviceid == `${this.state.serviceId}`) {
            Data.push(ival)
          }
        })
        console.log(Data);
        this.setState({ data: Data });
      }

      if (Data.length > 0) {
        const header = ["SI.NO", "USERTYPE"];
        const excelHead = [{ label: "USERTYPE", key: "usertype" }];
        this.setState({
          excelHead,
          header,
          excel: Data,
          title: "USERTYPE",
          loading: false
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  column = [
    {
      Header: "User Type",
      accessor: "usertype"
    },
    {
      Header: "Order By",
      accessor: "orderBy",
    },

    {
      Header: "Edit",
      accessor: "edit",
      Cell: d => this.edit(d)
    },
    // {
    //   Header: "Delete",
    //   accessor: "delete",
    //   Cell: d =>
    //     this.dataTableButton("danger", "Delete", () => {
    //       this.buttonDeletes(d.original);
    //     })
    // }
  ];

  edit = d => {
    let value = d;
    return (
      <center>
        <button
          type="button"
          className="btn btn-info"
          onClick={() => this.edition(value)}
        >
          Edit
        </button>
      </center>
    );
  };
  edition = d => {
    let data = [...this.state.data];
    let index = d.index;

    let value = d.original;
    let editvalue = data[index].usertype;
    let orderBy = data[index].orderBy;
    let editid = data[index].id;

    this.setState({
      disabled: true,
      index,
      error: null,
      button: "Update",
      editvalue,
      orderBy,
      editid
    });
  };

  buttonDeletes = value => {
    this.setState({});
    confirmAlert({
      title: "Confirmation to Delete",
      message: "Are you sure you want to delete this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.buttonDelete(value)
        },
        {
          label: "No"
        }
      ]
    });
  };

  buttonDelete = async value => {
    console.log(value)
    const previousData = [...this.state.data];
    let id = value.id;

    try {
      const result = await CmsContent.getSingleConditionedValue(
        "tbl_UserType",
        "id",
        id,
        "Delete"
      );
      if (result) {
        let datas = previousData.filter((delelteid, i) => {
          if (delelteid.id !== value.id) {
            return delelteid;
          }
        });
        this.setState({
          data: datas,
          formAlertdelete: true,
          alertVisible: true,
          textalert: "Selected Usertype Deleted",
          color: "danger"
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  handlechange = e => {
    let value = e.target.value;
    let name = e.target.name;
    this.setState({ [name]: value });
  };
  addnew = async () => {
    let { categorySelected, data } = this.state;
    const { editvalue, orderBy } = this.state;
    if (editvalue != undefined && editvalue.trim() != "" && editvalue != null) {
      this.setState({ error: "" });
    }
    else {
      this.setState({ error: "Please Fill The Usertype", selectboxerror: "" });
      return true;
    }
    if (orderBy != undefined && orderBy.trim() != "" && orderBy != null) {
      this.setState({ ordererror: "" });
    }
    else {
      this.setState({ ordererror: "Please Fill orderby", selectboxerror: "" });
      return true;
    }


    let filtered_item =
      data && data.length > 0
        ? data.filter(i => {
          return i.usertype == editvalue;
        })
        : [];

    if (filtered_item.length > 0) {
      this.setState({ error: "Usertype not be repeated" });
      return false;
    }
    let orderby_item =
      data && data.length > 0
        ? data.filter(i => {
          return i.orderBy == orderBy;
        })
        : [];

    if (orderby_item.length > 0) {
      this.setState({ ordererror: "This Value is already exists" });
      return false;
    }
    this.setState({ error: "" });

    let groupArray = {};
    groupArray.usertype = editvalue.trim();
    groupArray.orderBy = orderBy.trim();
    groupArray.status = "active";
    groupArray.customerid = this.state.customerId;
    groupArray.serviceid = this.state.serviceId;

    try {
      this.setState({ disableValue: true });
      const result = await CmsContent.addMaster("tbl_UserType", groupArray);
      console.log(result, "result");
      if (result) {
        let valueArray = {};
        valueArray.id = result.data.insertId;
        valueArray.usertype = editvalue.trim()
        valueArray.orderBy = orderBy
        const newData = [valueArray, ...this.state.data];

        this.setState({
          data: newData,
          alertVisible: true,
          editvalue: "",
          orderBy: "",
          editid: null,
          categoryId: "",
          categorySelected: "",
          disableValue: false,
          addNewSubgroup: false,
          error: null,
          selectboxerror: null,
          button: "Submit",
          index: null,
          textalert: "New Usertype Added",
          color: "success"
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  update = async () => {
    const { editvalue, orderBy,data } = this.state;
    if (editvalue === "") {
      this.setState({ error: "Value Cannot Be Empty" });
      return false;
    }
    else if (orderBy === "") {
      this.setState({ ordererror: "Fill the order By" })
    }
    let orderby_item =
      data && data.length > 0
        ? data.filter(i => {
          return i.orderBy == orderBy;
        })
        : [];

    if (orderby_item.length > 0) {
      this.setState({ ordererror: "This Value is already exists" });
      return false;
    }

    else {
      this.setState({ disableValue: true });
      this.setState({ ordererror: "" })
      try {
        let previousdata = [...this.state.data];
        const { index, editid: id } = this.state;
        let subgroupArray = {};
        subgroupArray.usertype = editvalue.trim();
        subgroupArray.orderBy = orderBy;
        const result = await CmsContent.updateMaster(
          "tbl_UserType",
          id,
          subgroupArray
        );

        if (result) {
          previousdata[index].usertype = editvalue.trim();
          previousdata[index].orderBy = orderBy;
          this.setState({
            data: previousdata,
            alertVisible: true,
            error: null,
            disableValue: false,
            categorySelected: null,
            editid: null,
            addNewSubgroup: false,
            index: null,
            button: "Submit",
            disabled: false,
            editvalue: "",
            orderBy: "",
            textalert: "New Usertype Updated",
            color: "success"
          });
          setTimeout(() => this.setState({ alertVisible: false }), 3000);
          console.log("success sathesh")
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  onDismiss = () => {
    this.setState({ alertVisible: false });
    this.setState({ alertVisible1: false });
    this.setState({ formAlertdelete: false });
  };
  programSelect = e => {
    this.setState({ categorySelected: e, categoryid: e.value });
  };

  render() {
    const {
      alertVisible,
      alertVisible1,
      formAlertdelete,
      editvalue,
      orderBy,
      ordererror,
      disableValue,
      error,
      selectboxerror,
      button,
      categorySelected,
      textalert
    } = this.state;
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h1>User Type</h1>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-7">
                        <Alert
                          className="badge-content"
                          color={this.state.color}
                          isOpen={alertVisible}
                          toggle={this.onDismiss}
                        >
                          {textalert}
                        </Alert>
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="subgroup">User Type</label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type="text"
                          name="editvalue"
                          placeholder="Enter User Type"
                          className="form-control"
                          value={editvalue}
                          onChange={e => this.handlechange(e)}
                        />
                        <span className="error-shows">{error}</span>
                      </div>
                      <div className="col-sm-2" />
                      <div className="col-sm-2 " style={{ marginTop: "15px" }} />
                      <div className="col-sm-2" style={{ marginTop: "15px" }}>
                        <label htmlFor="orderby">Order By</label>
                      </div>
                      <div className="col-sm-5" style={{ marginTop: "15px" }}>
                        <input
                          type="number"
                          name="orderBy"
                          placeholder="Enter Order By "
                          className="form-control"
                          value={orderBy}
                          onChange={e => this.handlechange(e)}
                        />
                        <span className="error-shows" style={{ marginTop: "10px" }}>{ordererror}</span>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2" />
                      <div className="col-sm-5">
                        <button
                          type="button"
                          className="btn btn-primary"
                          disabled={disableValue}
                          onClick={
                            button === "Update" ? this.update : this.addnew
                          }
                        >
                          {this.state.button}
                        </button>
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    <span>
                      Total of {Object.keys(this.state.data).length} records
                    </span>
                    <br></br>
                    {this.state.excel && this.state.excel.length > 0 ? (
                      <PDFDownloadLink
                        style={{ color: "white" }}
                        document={
                          <SFAPDF
                            title={this.state.title}
                            header={this.state.header}
                            data={this.state.excel}
                          />
                        }
                        fileName="SFAUsertype.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? "Loading document..." : "Download Pdf"
                        }
                        <button className="btn btn-warning">PDF</button>
                      </PDFDownloadLink>
                    ) : null}
                    {this.state.data && this.state.data.length ? (
                      <CSVLink
                        data={this.state.data}
                        headers={this.state.excelHead}
                      >
                        <button
                          className="btn btn-success"
                          style={{ marginLeft: "5px" }}
                        >
                          Excel
                        </button>
                      </CSVLink>
                    ) : null}
                    <br></br>
                    <br></br>

                    {this.state.loading ? <div className="text-center"> <i
                      className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                    ></i></div> : this.state.data ? (
                      <Datatable
                        data={this.state.data}
                        columnHeading={this.column}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default DfCaresUsertype;
