import React, { Component } from "react";
import SingleSelect from "../../../components/Form/SingleSelect";
import MultiSelect from "../../../components/Form/MultiSelect";
import CmsContent from "../../../MiddleWare/CmsContent";
import Datatable from "../../../components/Datatable/Datatable";
import { Alert } from "reactstrap";
import { FormMiddleWare } from "../../../components/Form";
import { confirmAlert } from "react-confirm-alert";
import LoginModal from "../../../components/Modal/Modal";
import redisMiddleware from "../../../MiddleWare/redisMiddleware";

class DfCarsMapForm extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      customerId: localStorage.getItem("userId"),
      serviceId: localStorage.getItem("currentService"),
      textalert: null,
      alertVisible: false,
      errorUser: "",
      errorSurQues: "",
      userOption: [],
      userOptionSelected: "",
      formQuesOption: [],
      formQuesOptionSelected: ""
    };
  }
  async componentWillMount() {
    try {
      // const userType = await CmsContent.getFreedom(
      //   "usertype as label , id as value",
      //   "tbl_UserType",
      //   `customerid=${this.state.customerId} and serviceid = ${this.state.serviceId}`,
      //   "id",
      //   "id"
      // );
      const data = await redisMiddleware.singleTable(
        "tbl_user_web",
        this.state.serviceId,
        "serviceId"
      );

      const design = await redisMiddleware.singleTable(
        "tbl_MaptoLocation",
        this.state.serviceId,
        "serviceid"
      );

      const usertype = await redisMiddleware.singleTable(
        "tbl_UserType",
        this.state.customerId,
        "customerid"
      );
      let user = [];
      if (usertype && usertype.data) {
        usertype.data.map((ival, i) => {
          if (ival.serviceid == this.state.serviceId) {
            user.push({
              label: ival.usertype,
              value: ival.id
            });
          }
        });
      }
      const location = await redisMiddleware.singleTable(
        "tbl_location",
        this.state.customerId,
        "customerId"
      );
      var learnerOptions = [];
      if (location) {
        location.data.map(item => {
          if (item.serviceid == this.state.serviceId) {
            learnerOptions.push({ label: item.name, value: item.id });
          }
        });

        this.setState({ location: learnerOptions });
      }

      // const form = await CmsContent.getFreedom(
      //   "name as label , id as value",
      //   "tbl_form",
      //   `companyid = ${this.state.customerId} and serviceid = ${this.state.serviceId}`,
      //   "id",
      //   "id"
      // );

      const form = await redisMiddleware.singleTable(
        "tbl_form",
        this.state.serviceId,
        "serviceid"
      );

      let formOption = [];
      if (form && form.data) {
        form.data.map((ival, i) => {
          formOption.push({
            label: ival.name + " " + "(Static)",
            value: ival.id
          });
        });
      }

      const FQ = await redisMiddleware.singleTable(
        "tbl_formquestion",
        this.state.serviceId,
        "serviceid"
      );

      let formQues = [];
      if (FQ && FQ.data) {
        FQ.data.map((ival, i) => {
          formQues.push({
            label: ival.question,
            value: ival.id,
            parentid: ival.parentid
          });
        });
      }

      // const Mapped = await CmsContent.getFreedom(
      //   "*",
      //   "tbl_map_FormUserType",
      //   `customerid = ${this.state.customerId} and serviceid = ${this.state.serviceId}`,
      //   "id",
      //   "id"
      // );

      const Mapped = await redisMiddleware.singleTable(
        "tbl_map_FormUserType",
        this.state.customerId,
        "customerid"
      );

      let group = [];

      // FQ.data.map((ival, i) => {
      //   console.log(ival);
      //   Mapped.data.map((jval, j) => {
      //     if (ival.parentid == jval.formid) {
      //       if (ival.id == jval.formQueid) {
      //         group.push({
      //           customerid: jval.customerid,
      //           formQueid: jval.formQueid,
      //           formid: jval.formid,
      //           required: jval.required,
      //           serviceid: jval.serviceid,
      //           userid: jval.userid,
      //           usertypeid: jval.usertypeid
      //         });
      //       }
      //     } else {
      //       group.push({
      //         // customerid: jval.customerid,
      //         formQueid: ival.id,
      //         formid: ival.parentid,
      //         required: 0,
      //         serviceid: 10,
      //         userid: "",
      //         usertypeid: ""
      //       });
      //     }
      //   });
      // });

      this.setState({
        data: data.data,
        design: design.data,
        userType: user,
        form: formOption,
        Mapped: Mapped.data,
        formQues
      });
    } catch (error) {
      console.log(error);
    }
  }

  column = [
    {
      Header: "User Type",
      accessor: "label"
    },
    {
      Header: "Form",
      accessor: "Form",
      Cell: d => this.view(d, "view")
    }
  ];

  columns = [
    {
      Header: "Form",
      accessor: "label"
    }
  ];

  view = (value, modalWindowId) => {
    return (
      <center>
        <button
          type="button"
          data-toggle="modal"
          data-target={`#${modalWindowId}`}
          className="btn btn-warning"
          onClick={() => this.buttonView(value)}
        >
          View Form
        </button>
      </center>
    );
  };

  buttonView = value => {
    const { Mapped } = this.state;

    let all = Mapped.map((ival, i) => {
      if (ival.usertypeid == value.original.value) {
        let returnArray = {};
        returnArray.label = this.getValueFromArray(
          ival.formid,
          this.state.form
        );
        returnArray.value = ival.usertypeid;
        returnArray.id = ival.id;
        return returnArray;
      }
    }).filter(function(element) {
      return element !== undefined;
    });
    this.setState({ buttonView: all, name: value.original.label });
  };

  mapForm = async () => {
    const {
      formSelected,
      usertypeSelected,
      serviceId,
      customerId,
      userOptionSelected,
      formQuesOptionSelected
    } = this.state;
    let selectedValue = "";

    if (!usertypeSelected) {
      this.setState({ errorUser: "Select Usertype" });
      return false;
    } else if (!formSelected) {
      this.setState({ errorUser: "", errorForm: "Select Form" });
      return false;
    } else {
      this.setState({ errorUser: "", errorForm: "" });
    }

    const value = formQuesOptionSelected.map(
      values => (selectedValue += `${values.value},`)
    );

    selectedValue = selectedValue.replace(/,\s*$/, "");
    const formData = new FormData();
    formData.append("usertypeid", usertypeSelected.value);
    formData.append("formid", formSelected.value);
    formData.append("serviceid", serviceId);
    formData.append("customerid", customerId);
    formData.append("userid", userOptionSelected.value);
    formData.append("formQueid", selectedValue);
    formData.append("required", 1);

    console.log([...formData]);

    try {
      let result = await CmsContent.learningObjectSubmit(
        "tbl_map_FormUserType",
        formData
      );
      if (result) {
        this.setState({
          selectedValue: "",
          formSelected: "",
          usertypeSelected: "",
          alertVisible: true,
          textalert: "Form Mapped successfully to User",
          color: "success",
          error: ""
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  usertypeSelect = e => {
    const { data, design, location } = this.state;

    let userOption = [];
    if (data) {
      data.map((ival, i) => {
        if (ival.userType == e.value) {
          design.map((jval, j) => {
            if (ival.userportid == jval.id) {
              userOption.push({
                label:
                  ival.userName +
                  " - " +
                  e.label +
                  " - " +
                  this.getValueFromArray(jval.locationId, location),
                value: ival.id
              });
            }
          });
        }
      });
    }
    this.setState({ usertypeSelected: e, userOption });
  };
  userOptionSelect = e => {
    this.setState({ userOptionSelected: e });
  };

  formSelect = e => {
    const { formQues } = this.state;

    let formQuesOption = [];

    formQues.map((ival, i) => {
      if (ival.parentid == e.value) {
        formQuesOption.push(ival);
      }
    });

    this.setState({ formSelected: e, formQuesOption });
  };
  formQuesOptionSelect = e => {
    if (e) {
      this.setState({ formQuesOptionSelected: e });
    }
  };

  render() {
    const {
      userType,
      usertypeSelected,
      form,
      formSelected,
      alertVisible,
      errorUser,
      textalert,
      errorForm,
      userOption,
      userOptionSelected,
      formQuesOption,
      formQuesOptionSelected
    } = this.state;
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Map User To Form</h1>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-7">
                        <Alert
                          className="badge-content"
                          color={this.state.color}
                          isOpen={alertVisible}
                          toggle={this.onDismiss}
                        >
                          {textalert}
                        </Alert>
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label for="exampleInputEmail1">User Type</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          // disabled={this.state.disabled}
                          options={userType}
                          handleChange={this.usertypeSelect}
                          selectedService={usertypeSelected}
                        />
                        <span className="error-shows">{errorUser}</span>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label for="exampleInputEmail1">User</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          // disabled={this.state.disabled}
                          options={userOption}
                          handleChange={this.userOptionSelect}
                          selectedService={userOptionSelected}
                        />
                        <span className="error-shows">{errorUser}</span>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label for="exampleInputEmail1">Form</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          options={form}
                          handleChange={this.formSelect}
                          selectedService={formSelected}
                        />
                        <span className="error-shows">{errorForm}</span>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label for="exampleInputEmail1">Form Field</label>
                      </div>
                      <div className="col-sm-5">
                        <MultiSelect
                          options={formQuesOption}
                          handleChange={this.formQuesOptionSelect}
                          selectedService={formQuesOptionSelected}
                        />
                        <span className="error-shows">{errorForm}</span>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2" />
                      <div className="col-sm-5">
                        <button
                          type="button"
                          className="btn btn-primary"
                          // disabled={disableValue}
                          onClick={this.mapForm}
                        >
                          Map Form
                        </button>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2"></div>
                      <div className="col-sm-5">
                        <LoginModal
                          buttonTitle={this.view}
                          atagLink={true}
                          maxwidth="lg"
                          title={`Forms Mapped to ${this.state.name}`}
                          id="view"
                          onClick={this.buttonView}
                          extraStyle={{ width: "125%" }}
                          bodyText={
                            <div>
                              {this.state.buttonView && (
                                <Datatable
                                  data={this.state.buttonView}
                                  columnHeading={this.columns}
                                />
                              )}
                            </div>
                          }
                        />
                      </div>
                    </div>
                    {this.state.userType && (
                      <Datatable
                        data={this.state.userType}
                        columnHeading={this.column}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default DfCarsMapForm;
