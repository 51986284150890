import React, { useEffect, useRef, useState } from "react";
import lhs from '../images/lhs.png'
import rhs from '../images/rhs.png'
import top from '../images/top.png'
import back from '../images/back.png'
import front from '../images/front.png'
import dent from '../images/dent.png'
import paint from '../images/paint.png'
import rust from '../images/rust.png'
import scrach from '../images/scratch.png'

export default class Canvasdf extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lhsArr: [],
            rhsArr: [],
            frontArr: [],
            backArr: [],
            topArr: [],
            // side: []
            mark: "paint",
            side: [{ side: 'left', height: 150, width: 300, state: 'show', arr: 'lhsArr', update: 'lhs' },
            { side: 'right', height: 150, width: 300, state: '', arr: 'rhsArr', update: 'rhs' },
            { side: 'top', height: 150, width: 300, state: '', arr: 'topArr', update: 'top' },
            { side: 'front', height: 150, width: 300, state: '', arr: 'frontArr', update: 'front' },
            { side: 'back', height: 150, width: 300, state: '', arr: 'backArr', update: 'back' }]
        }
        this.canvasRef0 = React.createRef(null)
        this.canvasRef1 = React.createRef(null)
        this.canvasRef2 = React.createRef(null)
        this.canvasRef3 = React.createRef(null)
        this.canvasRef4 = React.createRef(null)

    }
    propsfunction = async () => {
        if (this.props.CanvasVals && this.props.CanvasVals) {
            let vals = this.props.CanvasVals
            console.log(vals,"vals");
            if (typeof vals === 'string' || vals instanceof String) {
                vals = await JSON.parse(vals)
            }
            await this.setState({
                lhsArr: vals.lhs,
                rhsArr: vals.rhs,
                frontArr: vals.front,
                backArr: vals.back,
                topArr: vals.top,
            })
        }
    }
    async componentDidMount() {
        await this.propsfunction()
        await this.mianfun()
    }
    mianfun = async () => {
        let { side } = this.state
        let p = await side && side.map(async (ival, i) => {
            if (ival.state == 'show') {
                document.getElementById('canvas' + i).addEventListener('click', (e) => this.onClickCanvas(e, 'canvas' + i, ival.arr));
                let canvas = document.getElementById("canvas" + i);
                let ctx = canvas.getContext("2d");
                let img = new Image()
                // img.src = 
                if (ival.side == 'left') { img.src = rhs }
                if (ival.side == 'right') { img.src = lhs }
                if (ival.side == 'top') { img.src = top }
                if (ival.side == 'front') { img.src = front }
                if (ival.side == 'back') { img.src = back }
                img.onload = async () => {
                    await ctx.drawImage(img, 0, 0, ival.width, ival.height)
                    if (this.state[`${ival.arr}`] && this.state[`${ival.arr}`].length) {
                        // let canvas1 = document.getElementById('canvas' + i);
                        // let ctx1 = canvas1.getContext("2d");
                        await this.state[`${ival.arr}`] && this.state[`${ival.arr}`].map(async (jval, j) => {
                            let img1 = new Image(10, 10)
                            if (jval.mark == 'paint') { img1.src = paint }
                            else if (jval.mark == 'scrach') { img1.src = scrach }
                            else if (jval.mark == 'dent') { img1.src = dent }
                            else if (jval.mark == 'rust') { img1.src = rust }
                            img1.onload = async () => {
                                await ctx.drawImage(img1, jval.x, jval.y, 20, 20)
                            }
                        })
                    }
                }

            }
        })
        await Promise.all(p)
    }
    onClickCanvas = async (e, id, arr) => {
        // let { mark } = this.state
        let { lhsArr, rhsArr, frontArr, backArr, topArr, side, mark } = this.state
        let canvas = document.getElementById(id);
        let value = canvas.getBoundingClientRect()
        let ctx = canvas.getContext("2d");
        const x = e.clientX - value.left
        const y = e.clientY - value.top
        let img = new Image(10, 10)
        // img.src = this.state.Msource
        if (mark == 'paint') { img.src = paint }
        else if (mark == 'scrach') { img.src = scrach }
        else if (mark == 'dent') { img.src = dent }
        else if (mark == 'rust') { img.src = rust }
        img.onload = () => {
            ctx.drawImage(img, x, y, 20, 20)
        }
        let array = this.state[`${arr}`]
        array.push({ x: x, y: y, mark: mark })
        // console.log(array);
        await this.setState({ [`${arr}`]: array })
        this.props.CanvasArr({ lhs: lhsArr, rhs: rhsArr, front: frontArr, back: backArr, top: topArr })
    }
    marks = (i) => {
        this.setState({ mark: i })
    }
    change = async (s) => {
        let { side } = this.state
        side && side.map((ival, i) => {
            if (ival.side == s) {
                ival.state = 'show'
            } else { ival.state = '' }
        })
        await this.setState({ side })
        await this.mianfun()
    }
    render() {
        let { lhsArr, rhsArr, frontArr, backArr, topArr, side, mark } = this.state
        return (
            <>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row">
                            <div class='col-sm-12' >
                                <center>
                                    <button onClick={() => { this.marks('paint') }} style={{ margin: 10, backgroundColor: mark == 'paint' ? "white" : 'transparent', minWidth: 60, }} class='btn'><i> <img src={paint} width="30" height='30' /> Paint</i></button>
                                    <button onClick={() => { this.marks('scrach') }} style={{ margin: 10, backgroundColor: mark == 'scrach' ? "white" : "transparent", minWidth: 60 }} class='btn'><i> <img src={scrach} width="30" height='30' /> Scrach</i></button>
                                    <button onClick={() => { this.marks('dent') }} style={{ margin: 10, backgroundColor: mark == 'dent' ? "white" : "transparent", minWidth: 60 }} class='btn'><i> <img src={dent} width="30" height='30' /> Dent</i></button>
                                    <button onClick={() => { this.marks('rust') }} style={{ margin: 10, backgroundColor: mark == 'rust' ? "white" : "transparent", minWidth: 60 }} class='btn'><i> <img src={rust} width="30" height='30' /> Rust</i></button>
                                </center>
                            </div>
                        </div>
                        <div className="row">
                            <div class='col-sm-12' >
                                <center>
                                    {side && side.map((ival, i) => {
                                        // if (ival.state == "show")
                                        return (<div style={{ display: ival.state == 'show' ? "block" : 'none' }}><canvas
                                            id={"canvas" + i}
                                            ref={this[`canvasRef${i}`]}
                                            // width={699}
                                            // height={150}
                                            style={{
                                                width: `${ival.width}px`,
                                                height: `${ival.height}px`
                                            }}
                                        ></canvas>
                                        </div>)
                                    })}
                                </center>
                            </div>

                        </div>
                        <div className="row">
                            <div class='col-sm-12' >
                                <center>
                                    {/*
                                     <button onClick={() => { this.change('left') }} style={{ margin: 10 }} class='btn btn-outline-primary'>Left</button>
                                    <button onClick={() => { this.change('right') }} style={{ margin: 10 }} class='btn btn-outline-primary'>Right</button>
                                    <button onClick={() => { this.change('top') }} style={{ margin: 10 }} class='btn btn-outline-primary'>Top</button>
                                    <button onClick={() => { this.change('front') }} style={{ margin: 10 }} class='btn btn-outline-primary'>Front</button>
                                    <button onClick={() => { this.change('back') }} style={{ margin: 10 }} class='btn btn-outline-primary'>Back</button>
                                     */}
                                    {side && side.map((ival, i) => {
                                        return <>
                                            <button onClick={() => { this.change(ival.side) }} style={{ margin: 10, textTransform: 'capitalize' }} class={`btn ${ival.state == 'show' ? 'btn-primary' : 'btn-outline-primary'}`}>{ival.side}</button>
                                        </>
                                    })
                                    }
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}