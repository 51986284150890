import React, { Component } from 'react';
import CmsContent from '../../../../MiddleWare/CmsContent';
import Datatable from "../../../../components/Datatable/Datatable";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import cmsContent from "../../../../MiddleWare/CmsContent";
import { CSVLink, CSVDownload } from "react-csv";
import moment from 'moment';

class DfCarAdminEnquiry extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            themeId: null,
            changenumber: null,
            fullData: [],
            MakeOptions: [],
            coloropt: [],
            variantopt: [],
            MakeOptions: [],
            coloropt: [],
            variantopt: [],
            modelopt: [],
            index: null,
            alertVisible: false,
            customerId: localStorage.getItem("userId"),
            column: [
                {
                    Header: "ID",
                    accessor: "id"
                },
                {
                    Header: "NAME",
                    accessor: "name"
                },
                {
                    Header: "EMAIL",
                    accessor: "email"
                },
                {
                    Header: "TYPE",
                    accessor: "type"
                },
                {
                    Header: "REG NO",
                    accessor: "Reg_no"
                },
                {
                    Header: "MOBILE",
                    accessor: "mobile"
                },
                {
                    Header: "ENQUIRY DATE",
                    accessor: "createdAt"
                },
                {
                    Header: "MAKE",
                    accessor: "makename"
                },
                {
                    Header: "MODEL",
                    accessor: "modelname"
                },
                {
                    Header: "VARIANT",
                    accessor: "variantname"
                },
            ],
            excelHead: [
                {
                    label: "ID",
                    key: "id"
                },
                {
                    label: "NAME",
                    key: "name"
                },
                {
                    label: "EMAIL",
                    key: "email"
                },
                {
                    label: "TYPE",
                    key: "type"
                },
                {
                    label: "REG NO",
                    key: "Reg_no"
                },
                {
                    label: "MOBILE",
                    key: "mobile"
                },
                {
                    label: "ENQUIRY DATE",
                    key: "createdAt"
                },
                {
                    label: "MAKE",
                    key: "makename"
                },
                {
                    label: "MODEL",
                    key: "modelname"
                },
                {
                    label: "VARIANT",
                    key: "variantname"
                },

            ]
        };
    }
    async componentDidMount() {
        this.MainFun()
    }
    MainFun = async () => {
        try {
            this.setState({ loading: true })
            let result = await cmsContent.getFreedom("*", "tbl_dfcarz_enquiryform", `sources = "web"`, 1, "id desc")
            if (result) {
                console.log(result, "result");
                let { data: Make } = await CmsContent.getFreedom(
                    `Make as label , id as value`,
                    `tbl_make_master`, 1,
                    1, 1
                );
                if (Make) {
                    result.data.map((ival) => {
                        Make.map((jval) => {
                            if (ival.Make == jval.value) {
                                ival.makename = jval.label
                            }
                        })

                    })

                    await this.setState({ MakeOptions: Make })
                }
                // let { data: Colour } = await CmsContent.getFreedom(
                //     `colour as label , id as value`,
                //     `tbl_colour`,
                //     `ServiceId = 10`,
                //     1,
                //     `id DESC`
                // );
                // if (Colour) {
                //     this.setState({ coloropt: Colour })
                // }
                let result1 = await CmsContent.getFreedom(
                    "*",
                    "tbl_variant_master",
                    `ServiceId = 10`,
                    1, 1
                );
                if (result1.data) {
                    let VARIANT = result1.data
                    result.data.map((ival) => {
                        VARIANT.map((jval) => {
                            if (ival.Variant == jval.id) {
                                ival.variantname = jval.variant
                            }
                        })

                    })

                    console.log(result1.data, "variant");
                    await this.setState({ variantopt: result1.data })
                }
                let { data: model } = await CmsContent.getFreedom(
                    "Model as label , id as value",
                    "tbl_dfcarsmodel",
                    `ServiceId = 10`

                );
                if (model) {
                    result.data.map((ival) => {
                        model.map((jval) => {
                            if (ival.Model == jval.value) {
                                ival.modelname = jval.label
                            }
                        })

                    })
                    console.log(model, "model");
                    await this.setState({ modelopt: model })
                }
                await this.setState({ fullData: result.data })
            }
            this.setState({ loading: false })
        } catch (err) {
            console.log(err);
            this.setState({ loading: false })
        }
    }
    render() {
        const { fullData, alertVisible } = this.state;
        return (
            <main className="main my-4">
                <main className="container-fluid">

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h1>Enquiry</h1>
                                </div>
                                <br />
                                {!this.state.loading && <div className="row form-group">
                                    <div className="col-sm-3 text-center">
                                        <CSVLink
                                            data={this.state.fullData}
                                            headers={this.state.excelHead}
                                            filename={`Enquiry Exported on ${moment(new Date()).format('DD-MM-YYYY')}`}
                                        >
                                            <button
                                                className="btn btn-sm btn-success"
                                                style={{ marginLeft: "5px" }}
                                            >
                                                Export as Excel
                                            </button>
                                        </CSVLink>
                                    </div>
                                    <div className="col-sm-3" />
                                </div>
                                }
                                <div className="card-body">
                                    {this.state.loading ? <div className="text-center"> <i
                                        className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                                    ></i></div> : fullData && fullData.length ? (
                                        <Datatable data={fullData} columnHeading={this.state.column} />
                                    ) : <div className="text-center">No data Found</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </main>
        );
    }
}

export default DfCarAdminEnquiry;