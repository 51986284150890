import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import DfCarsEnquiry from "../Evaluator/DfCarsEnquiry";
import moment from "moment";
import UsertypeComments from "../UserMaster/UsertypeComments";
import LoginModal from "../../../../components/Modal/Modal";
import Datatable from "../../../../components/Datatable/Datatable";
import { ACCESS_POINT } from "../../../../config/index";
import { awaitExpression } from "@babel/types";
class Refurbishment extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      customerId: localStorage.getItem("userId"),
      usertypelog: JSON.parse(localStorage.getItem("usertypelog")),
      LocationID: localStorage.getItem(`LocationID`),
      EnquiryData: [],
      alertVisible: false,
      column: [
        {
          Header: "Prospect",
          accessor: "name"
        },
        {
          Header: "Contact",
          accessor: "mobile"
        },
        {
          Header: "evaluator name ",
          accessor: "jobroleName"
        },
        {
          Header: "Price in Rs.",
          accessor: "Budget"
        },
        {
          Header: "Documentation",
          accessor: "mobile",
          Cell: d => this.Document(d)
        },
        {
          Header: "Print pdf",
          accessor: "pdf",
          Cell: d => this.viewpdf(d)
        },
        {
          Header: "Approval",
          accessor: "mobile",
          Cell: d => this.Approval(d)
        },
        {
          Header: "Comments",
          accessor: "mobile",
          Cell: d => this.UpdateSTatus(d)
        }
      ],
      stage: null,
      CustomerId: null
    };
  }
  viewpdf = d => {
    if (d.original.FormFilled == "yes") {
      return (
        <button
          type="button"
          className="btn btn-warning"
          onClick={() => this.generatepdf(d)}
        >
          Print PDF
        </button>
      );
    } else {
      return (
        <span className="label" style={{ backgroundColor: "white" }}></span>
      );
    }
  };
  generatepdf = async d => {
    const bproofid = await cmsContent.getFreedom(
      "*",
      "tbl_checklist350",
      `customerId=${d.original.id}`,
      1,
      1
    );
    // console.log(bproofid);
    if (bproofid) {
      let bproofpdf = await cmsContent.checklist(
        "tbl_checklist350",
        bproofid.data[0].customerid
      );
      // console.log(bproofpdf);
      if (bproofpdf.data == "success") {
        window.open(
          `${ACCESS_POINT}/cmsContent/downloadchecklist111/checklist111`,
          "_self"
        );
      }
    }
  };
  UpdateSTatus = d => {
    return (
      <button
        type="button"
        className="btn btn-warning"
        data-toggle="modal"
        data-target={"#status"}
        onClick={() => this.Editdata(d)}
      >
        View Comments
      </button>
    );
  };

  Editdata = async d => {
    this.setState({ stage: "refurbishment", CustomerId: d.original.id });
  };

  Approval = d => {
    //console.log(d.original);
    if (d.original.FormFilled == "approved") {
      // return (
      //   <button
      //     type="button"
      //     className="btn btn-success"
      //     //onClick={()=>this.F_proofForm(d)}
      //   >
      //     Approved
      //   </button>
      // );
      return <span className="label">Approved</span>;
    } else if (d.original.FormFilled == "yes") {
      // return (
      //   <button
      //     type="button"
      //     className="btn btn-primary"
      //   //onClick={()=>this.F_proofForm(d)}
      //   >
      //     Waiting for Approval
      //   </button>
      // );
      return <span className="label">Waiting for Approval</span>;
    } else {
      // return (
      //   <button
      //     type="button"
      //     className="btn btn-warning"
      //   //onClick={()=>this.F_proofForm(d)}
      //   >
      //     Form Not Filled
      //   </button>
      // );
      return <span className="label">Form Not Filled</span>;
    }
  };

  Document = d => {
    if (d.original.FormFilled == "yes" || d.original.FormFilled == "approved") {
      return (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => this.F_proofForm(d)}
        >
          Edit Form
        </button>
      );
    } else {
      return (
        <button
          type="button"
          className="btn btn-warning"
          onClick={() => this.F_proofForm(d)}
        >
          Fill Form
        </button>
      );
    }
  };

  F_proofForm = async d => {
    window.open(
      `/DFCars/CERTIFICATION_350_CheckPoints/${d.original.id}`,
      "_self"
    );
  };

  async componentDidMount() {
    try {
      let result = await cmsContent.getFreedom(
        "tbl_dfcarz_prosperities.*,tbl_user_web.userName as jobroleName",
        `tbl_dfcarz_prosperities,tbl_Delivery_Seller_Proof,tbl_user_web`,
        `tbl_dfcarz_prosperities.location =${this.state.LocationID} and  tbl_Delivery_Seller_Proof.approval=1 and tbl_dfcarz_prosperities.id=tbl_Delivery_Seller_Proof.customerid and tbl_user_web.id = tbl_dfcarz_prosperities.userid`,
        1,
        `tbl_dfcarz_prosperities.id DESC`
      );
      if (result) {
        result.data.map((ival, i) => {
          ival.FormFilled = "no";
        });
        // let BookLet = await cmsContent.getFreedom(
        //   "*",
        //   `tbl_checklist350`,
        //   1,
        //   1,
        //   `id DESC`
        // );
        //  if (BookLet) {
        // console.log(BookLet.data);
        //console.log(result.data);
        let wait = await result.data.map(async (ival, i) => {
          // BookLet.data.map((jval, j) => {
          //   if (ival.id == jval.customerid && jval.approval == 1) {
          //     ival.FormFilled = "approved";
          //   } else if (ival.id == jval.customerid) {
          //     ival.FormFilled = "yes";
          //   }
          // });
          //console.log(ival.id, ival.name);
          let BookLet = await cmsContent.getFreedom(
            "*",
            `tbl_checklist350`,
            `customerid = ${ival.id}`,
            1,
            `id DESC`
          );
          console.log(BookLet.data);
          if (BookLet.data.length) {
            if (BookLet.data[0].approval == 1) {
              ival.FormFilled = "approved";
            } else {
              ival.FormFilled = "yes";
            }
          }
        });
        await Promise.all(wait);
        // }
        // console.log(result.data);

        let FullData = [];
        result.data.map((ival, i) => {
          if (ival.FormFilled != "approved") {
            FullData.push(ival);
          }
        });

        this.setState({ Data: FullData });
        //  this.setState({Data:result.data})
      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Refurbishment</h1>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-4">
                        <LoginModal
                          buttonTitle="Add Enquiry"
                          title="Update Commets"
                          id="status"
                          extraClass="btn btn-primary"
                          onClick={this.reset}
                          extraStyle={{ width: "1000px" }}
                          buttonStyle={{
                            backgroundColor: "white",
                            border: "white"
                          }}
                          bodyText={
                            <UsertypeComments
                              stage={this.state.stage}
                              customerid={this.state.CustomerId}
                            />
                          }
                        />
                      </div>
                    </div>
                    {this.state.Data.length ? (
                      <Datatable
                        data={this.state.Data}
                        columnHeading={this.state.column}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
export default Refurbishment;
