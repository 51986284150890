import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import moment from "moment";

class UsertypeComments extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      CommentList: [],
      comments:"",
      alertVisible: false,
      textalert: "",
      isEdit:false,
      EditId:"",
      Index:"",
      Userlog:JSON.parse(localStorage.getItem("userlog")),
      stage:"",
      IsClose:false
      }
}

handlechangeData = async (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  async componentWillReceiveProps(props) {
    try {
        // console.log(props);
	    if(props.stage){
        // console.log(props);
        this.setState({CommentList:[]});
        await  this.CommentListSection(props.customerid,props.stage);
         this.setState({
            stage:props.stage,
            customerid:props.customerid
            });
        }
    }catch(error){
        console.log(error)
    }
}

async componentWillMount(){
    console.log(this.props);
    if(this.props.stage){
        await this.CommentListSection(this.props.customerid,this.props.stage);
    }
}

CommentListSection = async(Cus,Stage)=>{
    try{

        let result = await cmsContent.getFreedom(
            `tbl_dfcars_comments.*,tbl_UserType.usertype,tbl_user_web.userName as Name,tbl_user_web.id as UserID`,
            `tbl_dfcars_comments,tbl_user_web,tbl_MaptoLocation,tbl_UserType`,
            `tbl_dfcars_comments.customerid = ${Cus} and tbl_dfcars_comments.stage='${Stage}'and tbl_user_web.id = tbl_dfcars_comments.userid and tbl_user_web.userportid = tbl_MaptoLocation.id and tbl_UserType.id = tbl_MaptoLocation.userTypeId`,
            1,
            `tbl_dfcars_comments.id DESC`,

        );
        if(result.data.length){
            console.log(result.data);
              result.data.map((ival,i)=>{
                // ival.created_At = moment(ival.created_At).format("DD-MM-YYYY");
                let Hour_and_Minute = new Date(ival.created_At);
                  let DateDay =  moment(Hour_and_Minute).format("DD-MM-YYYY");
                //  console.log(`${Hour_and_Minute.getHours()}=>${Hour_and_Minute.getMinutes()}`)
                let min=0;
                  if(Hour_and_Minute.getHours()<10){
                    //Hour_and_Minute.getMinutes()
                    min = `0${Hour_and_Minute.getHours()}`
                  }else{
                    min =Hour_and_Minute.getHours();
                  }
                  let sec=0;
                  if(Hour_and_Minute.getMinutes()<10){
                     sec  = `0${Hour_and_Minute.getMinutes()}`
                  }else{
                      sec = Hour_and_Minute.getMinutes();
                  }
                  ival.created_At = `${DateDay} at ${min}:${sec}`
              })
            this.setState({CommentList:result.data});
        }


    }catch(error){
        console.log(error);
    }
}


Submit=async()=>{
    const{comments,Userlog,stage,CommentList,customerid}=this.state;
    try{

        let groupArray={};
        groupArray.comments=comments;
        groupArray.userid = Userlog.id;
        groupArray.stage = stage;
        groupArray.serviceid = 10;
        groupArray.customerid = customerid;

        const result = await cmsContent.addMaster("tbl_dfcars_comments", groupArray);
        if(result){
            console.log(result);
            let arr={};
            arr.id = result.insertid;
            arr.comments= comments;
            arr.userid = Userlog.id;
            arr.stage = stage;
            arr.serviceid = 10;
            arr.UserID = Userlog.id;
            arr.created_At = new Date();
            let Hour_and_Minute = new Date(arr.created_At);
            let DateDay =  moment(Hour_and_Minute).format("DD-MM-YYYY");
           // console.log(`${Hour_and_Minute.getHours()}=>${Hour_and_Minute.getMinutes()}`)
           let min=0;
                  if(Hour_and_Minute.getHours()<10){
                    //Hour_and_Minute.getMinutes()
                    min = `0${Hour_and_Minute.getHours()}`
                  }else{
                    min =Hour_and_Minute.getHours();
                  }
                  let sec=0;
                  if(Hour_and_Minute.getMinutes()<10){
                     sec  = `0${Hour_and_Minute.getMinutes()}`
                  }else{
                      sec = Hour_and_Minute.getMinutes();
                  }
            arr.created_At =`${DateDay} at ${min}:${sec}`
            arr.customerid = customerid;
            //arr.
              let newData =[arr,...CommentList];

            this.setState({
                comments:"",
                CommentList:newData
            })
        }


    }catch(error){
        console.log(error);
    }
}



render(){
    return(<React.Fragment>
        <main >
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h1>Comments</h1>
            </div>
            <div className="card-body" style={{backgroundColor:"#ebedef"}}>

            <div className="row form-group">
            <div className="col-sm-1"/>
             <div className="col-sm-10">
              {/* <div className="col-sm-4"> */}
                        <textarea
                          type="text"
                          className="form-control"
                          id="ColourName"
                          name="comments"
                          placeholder="Enter Comments"
                          value={this.state.comments}
                          onChange={this.handlechangeData}
                        />
                      </div>
                     <div className="col-sm-1" />
                    </div>
                    <div className="row form-group">
                    <div className="col-sm-5"/>
                    <div className="col-sm-2">
                      <button type="button" className="btn btn-primary"
                        onClick={this.Submit}
                        >send</button>
                      </div>
                      </div>

                    <div className="row form-group">
                          {/* <div className="col-sm-6"/> */}
                      <div className="col-sm-12">
                      <div className="card-body" >
          {this.state.CommentList.length ?
          this.state.CommentList.map((ival,i)=>{
              if(ival.UserID==this.state.Userlog.id){
                return(
                    <React.Fragment>
                          {/* <div className="row form-group">

                      <div className="col-sm-12">
                      <div className="card-body" > */}
                      <div><p style={{marginLeft:"89%",marginBottom:"-15px",fontSize:"10px"}}>{ival.created_At}</p></div>
                      <div className="row form-group">
                      <div className="col-sm-12">
                      <p style={{color:"blue"}}>You{" "}:</p> <p style={{padding:"12px",backgroundColor:"white",fontWeight:"100",fontSize:"14px"}}>{" "} {ival.comments} {" "}</p>
                      </div>
                      {/* <div className="col-sm-2">
                    <p style={{marginLeft:"-121px",fontSize:"10px"}}>{ival.created_At}</p> </div>   */}
                </div>
                    {/* </div>
                      </div>
                      </div> */}
                    </React.Fragment> )
              }else{
              return(
          <React.Fragment>
                {/* <div className="row form-group">
            <div className="col-sm-12">
            <div className="card-body"> */}
            <div><p style={{marginLeft:"89%",marginBottom:"-15px",fontSize:"10px"}}>{ival.created_At}</p></div>
            <div className="row form-group">
               <div className="col-sm-12">
               <p style={{color:"blue"}}>{ival.Name}({ival.usertype}){" "}:</p><p style={{padding:"12px",backgroundColor:"white",fontWeight:"100",fontSize:"14px"}}>{" "}{ival.comments}</p>
               </div>
              {/* <div className="col-sm-2">
                <p style={{marginLeft:"-121px",fontSize:"10px"}}>{ival.created_At}</p>
                 </div>    */}
                </div>

          {/* </div>
            </div>
            </div> */}
          </React.Fragment> )
              }
          }): null}
</div>
</div>
</div>


            </div>
            </div>
                </div>
                </div>
                </div>
                </main>
                </React.Fragment>
                )
}
}
export default UsertypeComments;