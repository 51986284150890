import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../components/Form/FormMiddleware";
//import DfCarsEnquiry from "./DfCarsEnquiry";
import UsertypeComments from "./UserMaster/UsertypeComments";
import LoginModal from "../../../components/Modal/Modal";
import moment from "moment";
import Datatable from "../../../components/Datatable/Datatable";
import { ACCESS_POINT } from "../../../config/index"

class DfCarspaymentprocess extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      customerId: localStorage.getItem("userId"),
      usertypelog: JSON.parse(localStorage.getItem("usertypelog")),
      LocationID: localStorage.getItem(`LocationID`),
      EnquiryData: [],
      alertVisible: false,
      column: [
        {
          Header: "Prospect",
          accessor: "name",
        },
        {
          Header: "Contact",
          accessor: "mobile",
        },
        {
          Header: "evaluator name ",
          accessor: "jobroleName",
        },
        {
          Header: "Price in Rs.",
          accessor: "Budget",
        },
        {
          Header: "Documentation",
          accessor: "mobile",
          Cell: (d) => this.Document(d),
        },
        {
          Header: "Print PDF",
          accessor: "pdf",
          Cell: d => this.viewpdf(d),
        },
        {
          Header: "Approval",
          accessor: "mobile",
          Cell: (d) => this.Approval(d),
        },
        {
          Header: "Comments",
          accessor: "mobile",
          Cell: (d) => this.UpdateSTatus(d),
        },
      ],
      stage: null,
      CustomerId: null,
    };
  }
  viewpdf = d => {
    if (d.original.FormFilled == "yes" || d.original.FormFilled == "approved") {
      return (<button type="button" className="btn btn-warning"
        onClick={() => this.generatepdf(d)}>Print PDF</button>)
    }
    else {
      return (
        <span className="label" style={{ backgroundColor: "white" }}></span>)
    }
  }
  generatepdf = async (d) => {
    const inventory = await cmsContent.getFreedom("*", "tbl_Inventory_Sheet_Reproof", `customerId=${d.original.id}`, 1, 1);
    console.log(inventory)
    if (inventory) {
      let inventpdf = await cmsContent.inventory("tbl_Inventory_Sheet_Reproof", inventory.data[0].customerid);
      console.log(inventpdf)
      if (inventpdf.data == "success") {
        window.open(`${ACCESS_POINT}/cmsContent/downloadInventory/${inventory.data[0].reg_ownerName}`, '_blank')
      }
    }
  }
  UpdateSTatus = (d) => {
    return (
      <button
        type="button"
        className="btn btn-warning"
        data-toggle="modal"
        data-target={"#status"}
        onClick={() => this.Editdata(d)}
      >
        View Comments
      </button>
    );
  };

  Editdata = async (d) => {
    this.setState({ stage: "payment", CustomerId: d.original.id });
  };

  Approval = (d) => {
    //  console.log(d.original);
    if (d.original.FormFilled == "approved") {
      // return (
      //   <button
      //     type="button"
      //     className="btn btn-success"
      //     //onClick={()=>this.F_proofForm(d)}
      //   >
      //     Approved
      //   </button>
      // );
      return (<span className="label">Approved</span>)
    } else if (d.original.FormFilled == "yes") {
      // return (
      //   <button
      //     type="button"
      //     className="btn btn-primary"
      //   //onClick={()=>this.F_proofForm(d)}
      //   >
      //     Waiting for Approval
      //   </button>
      // );
      return (<span className="label">Waiting for Approval</span>)
    } else {
      // return (
      //   <button
      //     type="button"
      //     className="btn btn-warning"
      //   //onClick={()=>this.F_proofForm(d)}
      //   >
      //     Form Not Filled
      //   </button>
      // );
      return (<span className="label">Fill the form</span>)
    }
  };

  Document = (d) => {
    if (d.original.FormFilled == "yes" || d.original.FormFilled == "approved") {
      return (
        <button
          type="button"
          className="btn btn-success"
          onClick={() => this.F_proofForm(d)}
        >
          Edit Form
        </button>
      );
    } else {
      return (
        <button
          type="button"
          className="btn btn-warning"
          onClick={() => this.F_proofForm(d)}
        >
          Fill Form
        </button>
      );
    }
  };

  F_proofForm = async (d) => {
    window.open(
      `/DFCars/AT_Inventory_Sheet_Reproof/${d.original.id}`,
      "_blank"
    );
  };

  async componentDidMount() {
    try {
      let result = await cmsContent.getFreedom(
        "tbl_dfcarz_prosperities.*,tbl_user_web.userName as jobroleName",
        `tbl_dfcarz_prosperities,tbl_AT_Booklet_Purchase_Contract_Proof,tbl_user_web`,
        `tbl_dfcarz_prosperities.location =${this.state.LocationID} and tbl_AT_Booklet_Purchase_Contract_Proof.approval=1 and tbl_dfcarz_prosperities.id=tbl_AT_Booklet_Purchase_Contract_Proof.customerid and tbl_user_web.id = tbl_dfcarz_prosperities.userid`,
        1,
        `tbl_dfcarz_prosperities.id DESC`
      );
      if (result) {
        result.data.map((ival, i) => {
          ival.FormFilled = "no";
        });
        const Fproof = await cmsContent.getFreedom(
          `*`,
          `tbl_Inventory_Sheet_Reproof`,
          1,
          `id`
        );
        result.data.map((ival, i) => {
          Fproof.data.map((jval, j) => {
            // console.log(jval);
            if (jval.approval == 1 && ival.id == jval.customerid) {
              ival.FormFilled = "approved";
            } else if (ival.id == jval.customerid) {
              ival.FormFilled = "yes";
            }
          });
        });

        let FullData = [];
        result.data.map((ival, i) => {
          if (ival.FormFilled != "approved") {
            FullData.push(ival);
          }
        });

        this.setState({ Data: FullData });
      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Payment Process</h1>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-4">
                        <LoginModal
                          buttonTitle="Add Enquiry"
                          title="Update Commets"
                          id="status"
                          extraClass="btn btn-primary"
                          onClick={this.reset}
                          extraStyle={{ width: "1000px" }}
                          buttonStyle={{
                            backgroundColor: "white",
                            border: "white",
                          }}
                          bodyText={
                            <UsertypeComments
                              stage={this.state.stage}
                              customerid={this.state.CustomerId}
                            />
                          }
                        />
                      </div>
                    </div>
                    {this.state.Data.length ? (
                      <Datatable
                        data={this.state.Data}
                        columnHeading={this.state.column}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
export default DfCarspaymentprocess;
