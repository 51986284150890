import React, { Component } from "react";
import { Alert } from "reactstrap";
import CmsContent from "../../../../MiddleWare/CmsContent";
import Datatable from "../../../../components/Datatable/Datatable";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import { confirmAlert } from "react-confirm-alert";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import SFAPDF from "../../../../components/PDF/SFAPDF";
import redisMiddleware from "../../../../MiddleWare/redisMiddleware";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SingleSelect from "../../../../components/Form/SingleSelect";

class DfcarsSalesConsultant extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            alertVisible: false,
            alertVisible1: false,
            editvalue: "",
            editid: null,
            disableValue: false,
            addNewSubgroup: false,
            BrandOpt: [
                { label: "HONDA", value: "9" },
                { label: "KIA", value: "26" },
                { label: "SKODA", value: "18" },
                { label: "VW", value: "21" },
                { label: "RENAULT", value: "17" }
            ],
            error: null,
            button: "Submit",
            index: null,
            customerId: localStorage.getItem("userId"),
            serviceId: localStorage.getItem("currentService"),
            formAlertdelete: false,
            textalert: null,
            color: "success"
        };
    }
    async componentWillMount() {
        this.mainFun()
    }
    mainFun = async () => {
        try {
            this.setState({ loading: true })
            const location = await CmsContent.getFreedom(
                "name as label, id as value",
                "tbl_location",
                `customerId=${this.state.customerId} and serviceid=${this.state.serviceId} and status='active'`,
                "id",
                "id desc"
            );
            if (location) {
                this.setState({ locationOpt: location.data });
            }
            const Branch = await CmsContent.getFreedom(
                "name as label, id as value,locationId as refid",
                "tbl_dfcars_Branch",
                `customerId=${this.state.customerId} and serviceid=${this.state.serviceId} and status='active'`,
                "id",
                "id desc"
            );
            if (Branch) {
                this.setState({ BranchOpt: Branch.data });
            }
            const Manager = await CmsContent.getFreedom(
                "userName as label, id as value",
                "tbl_user_web",
                `customerId=${this.state.customerId} and serviceid=${this.state.serviceId} and userType=130`,
                "id",
                "id desc"
            );
            if (Manager) {
                this.setState({ ManagerOpt: Manager.data });
            }
            //    const brand = await CmsContent.getFreedom("Make as label,id as value", "tbl_make_master", "customerId = 620 and ServiceId = 10 and status = 'active'", 1, 1);

            // if (brand) {
            //     this.setState({ BrandOpt: brand.data });
            // }      

            let result = await CmsContent.getFreedom(
                "tbl_dfcars_sales_consultant.* ,tbl_user_web.userName as manager,tbl_dfcars_Branch.name as branch,tbl_location.name as location",
                "tbl_dfcars_sales_consultant,tbl_user_web,tbl_dfcars_Branch,tbl_location",
                `tbl_dfcars_sales_consultant.status = "active" and tbl_dfcars_sales_consultant.branchId = tbl_dfcars_Branch.id and tbl_dfcars_sales_consultant.locationId = tbl_location.id`,
                "tbl_dfcars_sales_consultant.id",
                "tbl_dfcars_sales_consultant.id desc"
            );
            console.log(result, "resultttt")
            if (result && result.data && result.data.length) {
                await result.data.map((ival) => {
                    this.state.BrandOpt.map((jval) => {
                        if (ival.brandid == jval.value) {
                            ival.brand = jval.label
                        }
                    })
                })
            }
            // console.log(result, "result", Branch, Manager);
            if (result) {
                console.log(result.data, "result")
                this.setState({ data: result.data });
            }
            // this.setState({ loading: false })

            if (result.data.length > 0) {
                const header = ["SI.NO", "BRANCH", "LOCATION"];
                const excelHead = [{ label: "BRANCH", key: "name" }, { label: "LOCATION", key: "location" }];
                this.setState({
                    excelHead,
                    header,
                    excel: result.data,
                    title: "BRANCH",
                    data: result.data,
                    loading: false
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    column = [
        {
            Header: "Name",
            accessor: "name"
        },
        // {
        //     Header: "Email",
        //     accessor: "email",
        // },
        // {
        //     Header: "Phone",
        //     accessor: "phone",
        // },
        {
            Header: "Location",
            accessor: "location",
            // Cell: d => this.getValueFromArray(d.original.locationId, this.state.locationOpt)
        },
        {
            Header: "Branch",
            accessor: "branch",
            // Cell: d => this.getValueFromArray(d.original.branchId, this.state.BranchOpt)
        },
        // {
        //     Header: "Manager",
        //     accessor: "manager",
        //     // Cell: d => this.getValueFromArray(d.original.managerId, this.state.ManagerOpt)
        // },
        {
            Header: "Brand",
            accessor: "brand",
            // Cell: d => this.getValueFromArray(d.original.managerId, this.state.ManagerOpt)
        },
        {
            Header: "Edit",
            accessor: "edit",
            Cell: d => this.edit(d)
        },
        {
            Header: <b>Delete</b>,
            selector: "delete",
            Cell: d =>
                this.dataTableButton("danger", "Delete", () => {
                    this.buttonDeletes(d);
                })
        },
        // {
        //     Header: "Share",
        //     accessor: "file",
        //     Cell: d => this.sharelink(d)
        // }
    ];
    sharelink = (d) => {
        // console.log(d)
        return (
            <div>
                <CopyToClipboard
                    text={d.original.name}
                // onCopy={() => this.setState({ copied: true })}
                >
                    <button type="button" className="btn btn-primary">
                        Copy to clipboard
                    </button>
                </CopyToClipboard>
                <br />
                {
                    this.state.copied == true && (
                        <div>
                            <span style={{ color: "green" }}>
                                URL is Copied
                            </span>
                        </div>
                    )
                }
            </div>
        )
    }
    edit = d => {
        let value = d;
        return (
            <center>
                <button
                    type="button"
                    className="btn btn-info"
                    onClick={() => this.edition(value)}
                >
                    Edit
                </button>
            </center>
        );
    };
    edition = async (value) => {
        let values = value.original;
        let editvalue = values.name;
        await this.setState({
            index: value.index,
            Name: values.name,
            // Email: values.email,
            // Phone: values.phone,
            location: values.location ? { label: values.location, value: values.locationId } : null,
            branch: values.branch ? { label: values.branch, value: values.branchId } : null,
            // Manager: values.manager ? { label: values.manager, value: values.managerId } : null,
            Brand: values.brand ? { label: values.brand, value: values.brandid } : "",
            editid: values.id,
            error: null,
            button: "Update"
        });
    };
    buttonDeletes = value => {
        confirmAlert({
            title: "Confirmation to Delete",
            message: "Are you sure you want to delete this?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => this.buttonDelete(value)
                },
                {
                    label: "No"
                }
            ]
        });
    };
    buttonDelete = async value => {
        const previousData = [...this.state.data];
        let id = value.original.id;
        try {
            // const result = await CmsContent.deleteMaster("tbl_dfcars_Branch", id);
            const result = await CmsContent.updateMaster("tbl_dfcars_sales_consultant", id, { status: 'inactive' });
            if (result) {
                this.setState({
                    alertVisible: true,
                    textalert: "Selected Location Deleted",
                    color: "danger"
                });
                this.mainFun()
                setTimeout(() => this.setState({ alertVisible: false }), 3000);
            }
            // const result = await CmsContent.getSingleConditionedValue(
            //     "tbl_dfcars_Branch",
            //     "id",
            //     id,
            //     "Delete"
            // );
            // if (result) {
            //     let datas = previousData.filter((delelteid, i) => {
            //         if (delelteid.id !== value.original.id) {
            //             return delelteid;
            //         }
            //     });
            //     this.setState({
            //         data: datas,
            //         alertVisible: true,
            //         textalert: "Selected Location Deleted",
            //         color: "danger"
            //     });
            //     setTimeout(() => this.setState({ alertVisible: false }), 3000);
            // }
        } catch (error) {
            console.log(error);
        }
    };

    handleSelect = (e, name) => {
        this.setState({ [name]: e });
    };
    // handleUserInput = e => {
    //     this.setState({ [e.target.name]: e.target.value.toUpperCase() });
    // };

    addnew = async () => {
        const { editvalue: value, data, location, branch, Name, editid,
            Email,
            Phone,
            BranchOpt,
            Manager, Brand } = this.state;
        if (Name === null || Name.trim() === "") {
            this.setState({ error: "Please Fill Name" });
            return false;
        }
        // else if (Email === null || Email.trim() === "") {
        //     this.setState({ error: "Please Fill Email" });
        //     return false;
        // }
        // else if (Phone === null || Phone.trim() === "") {
        //     this.setState({ error: "Please Fill Phone" });
        //     return false;
        // }
        else if (!location) {
            this.setState({ error: "Please Select Location" })
            return false;
        }
        else if (!branch) {
            this.setState({ error: "Please Select branch" })
            return false;
        }
        // else if (!Manager) {
        //     this.setState({ error: "Please Select Manager" })
        //     return false;
        // }
        else if (!Brand) {
            this.setState({ error: "Please Select Brand" })
            return false;
        }



        // this.checkRepeat(value);
        // let filtered_item =
        //     data && data.length > 0
        //         ? data.filter(i => {
        //             return i.Phone === Phone;
        //         })
        //         : [];

        // if (filtered_item.length > 0) {
        //     this.setState({ error: "Phone not be repeated" });
        //     return false;
        // }

        this.setState({ error: "" });

        let groupArray = {};
        groupArray.name = Name.trim();
        // groupArray.email = Email.trim();
        // groupArray.phone = Phone.trim();
        groupArray.locationId = location ? location.value : null
        groupArray.branchId = branch ? branch.value : null
        // groupArray.managerId = Manager ? Manager.value : null
        groupArray.brandid = Brand ? Brand.value : null

        groupArray.status = "active";


        try {
            this.setState({ disableValue: true });
            const result = editid ? await CmsContent.updateMaster(
                "tbl_dfcars_sales_consultant",
                editid,
                groupArray
            ) : await CmsContent.addMaster("tbl_dfcars_sales_consultant", groupArray);

            if (result) {
                // let valueArray = {};
                // valueArray.id = result.data.insertId;
                // valueArray.name = groupArray.name;
                // const newData = [valueArray, ...this.state.data];
                this.setState({
                    // data: newData,
                    Name: "",
                    Email: "",
                    Phone: "",
                    Manager: null,
                    Brand: null,
                    alertVisible: true,
                    editvalue: "",
                    editid: null,
                    disableValue: false,
                    addNewSubgroup: false,
                    error: null,
                    button: "Submit",
                    index: null,
                    branch: "",
                    location: null,
                    textalert: "Sales consultant Successfully Updated",
                    color: "success"
                });
                this.mainFun()
                setTimeout(() => this.setState({ alertVisible: false }), 3000);
            }
        } catch (error) {
            console.log(error);
        }
    };

    onDismiss = () => {
        this.setState({ alertVisible: false });
        this.setState({ alertVisible1: false });
        this.setState({ formAlertdelete: false });
    };
    render() {
        const {
            editvalue,
            alertVisible,
            disableValue,
            error,
            button,
            textalert,
            branch,
            Name,
            Email,
            Phone,
            location, BranchOpt
        } = this.state;
        // console.log(this.state.brand, "braaaaaaaand");
        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h1>Sales Consultant Master</h1>
                                    </div>
                                    <div className="card-body">
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-7">
                                                <Alert
                                                    className="badge-content"
                                                    color={this.state.color}
                                                    isOpen={alertVisible}
                                                    toggle={this.onDismiss}
                                                >
                                                    {textalert}
                                                </Alert>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="subgroup">Name</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <input
                                                    type="text"
                                                    name="Name"
                                                    placeholder="Enter Name"
                                                    className="form-control"
                                                    value={Name}
                                                    onChange={e => this.handleUserInput(e)}
                                                />
                                                <span className="error-shows">{error}</span>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        {/* <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="subgroup">Email</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <input
                                                    type="text"
                                                    name="Email"
                                                    placeholder="Enter Email"
                                                    className="form-control"
                                                    value={Email}
                                                    onChange={e => this.handleUserInput(e)}
                                                />
                                                <span className="error-shows">{error}</span>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="subgroup">Phone</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <input
                                                    type="number"
                                                    name="Phone"
                                                    placeholder="Enter Phone"
                                                    className="form-control"
                                                    value={Phone}
                                                    onChange={e => this.handleUserInput(e)}
                                                />
                                                <span className="error-shows">{error}</span>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div> */}
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="exampleInputEmail1">Select Location</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <SingleSelect
                                                    placeholder="Select Location"
                                                    options={this.state.locationOpt}
                                                    onChange={(e) => this.handleSelect(e, 'location')}
                                                    selectedService={this.state.location}
                                                />
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="exampleInputEmail1">Select Branch</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <SingleSelect
                                                    placeholder="Select Branch"
                                                    options={location ? BranchOpt.filter(e => e.refid == location.value) : BranchOpt}
                                                    onChange={(e) => this.handleSelect(e, 'branch')}
                                                    selectedService={this.state.branch}
                                                />
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        {/* <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="exampleInputEmail1">Select Manager</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <SingleSelect
                                                    placeholder="Select Manager"
                                                    options={this.state.ManagerOpt}
                                                    onChange={(e) => this.handleSelect(e, 'Manager')}
                                                    selectedService={this.state.Manager}
                                                />
                                            </div>
                                            <div className="col-sm-3" />
                                        </div> */}
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="exampleInputEmail1">Select Brand</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <SingleSelect
                                                    placeholder="Select Brand"
                                                    options={this.state.BrandOpt}
                                                    onChange={(e) => this.handleSelect(e, 'Brand')}
                                                    selectedService={this.state.Brand}
                                                />
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2" />
                                            <div className="col-sm-5">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    disabled={disableValue}
                                                    onClick={
                                                        button === "Update" ? this.addnew : this.addnew
                                                    }
                                                >
                                                    {this.state.button}
                                                </button>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div style={{ marginTop: "5px" }}> </div>
                                        {this.state.excel && this.state.excel.length > 0 ? (
                                            <PDFDownloadLink
                                                style={{ color: "white" }}
                                                document={
                                                    <SFAPDF
                                                        title={this.state.title}
                                                        header={this.state.header}
                                                        data={this.state.excel}
                                                    />
                                                }
                                                fileName="SFALocation.pdf"
                                            >
                                                {({ blob, url, loading, error }) =>
                                                    loading ? "Loading document..." : "Download Pdf"
                                                }
                                                <button className="btn btn-warning">PDF</button>
                                            </PDFDownloadLink>
                                        ) : null}
                                        {this.state.data && this.state.data.length ? (
                                            <CSVLink
                                                data={this.state.data}
                                                headers={this.state.excelHead}
                                            >
                                                <button
                                                    className="btn btn-success"
                                                    style={{ marginLeft: "5px" }}
                                                >
                                                    Excel
                                                </button>
                                            </CSVLink>
                                        ) : null}
                                        <br></br>
                                        <br></br>

                                        <span>
                                            Total of {Object.keys(this.state.data).length} records
                                        </span>
                                        {this.state.loading ? <div className="text-center"> <i
                                            className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                                        ></i></div> : this.state.data ? (
                                            <Datatable
                                                data={this.state.data}
                                                columnHeading={this.column}
                                            />
                                        ) : <div className="text-center">No data Found</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

export default DfcarsSalesConsultant;
