import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../../../SideNav/SideNav.css";
import CmsContent from "../../../../MiddleWare/CmsContent";
import { FormMiddleWare } from "../../../../components/Form";

export default class NewCarCons extends FormMiddleWare {
  constructor(props) {
    super(props)
    this.state = {
      opentab: [],
      User: JSON.parse(localStorage.getItem("userlog")),
    }
  }

  async componentDidMount() {
    console.log("NEWCARCONS");
    try {
      let user = JSON.parse(localStorage.getItem("userlog"));
      let Menu = null
      Menu = localStorage.getItem("menu");
      if (Menu == null) {
        Menu = await CmsContent.BUYDFCARZ(user.id, 'menu')
        if (Menu) {
          await this.encryption("menu", Menu)
          await this.setState({ Menu })
        }
      }
      else {
        Menu = await this.decryption('menu')
        await this.setState({ Menu })
      }
      let Downline_Details = null
      Downline_Details = localStorage.getItem("Downline_Details");
      if (Downline_Details == null) {
        let getUserRelatedOPT = await CmsContent.BUYDFCARZ(this.state.User.id, "UserList")
        if (getUserRelatedOPT) {
          await this.encryption("Downline_Details", getUserRelatedOPT)
        }
      }
      let getcar = null
      getcar = localStorage.getItem("getcar");
      if (getcar == null) {
        let getcarmaster = await CmsContent.getAllOption_Web()
        if (getcarmaster) {
          await this.encryption("getcar", getcarmaster)
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  RenderFunction = () => {
    const { Menu } = this.state;
    let list = []
    try {
      if (Menu) {
        Object.keys(Menu).map((ival, i) => {
          if (Menu[ival].tab == true) {
            list.push(
              <li className="nav-item nav-dropdown">
                <Link className="nav-link nav-dropdown-toggle" href="#">
                  <i className={`nav-icon  ${Menu[ival].TabMenu[0].webIcon}`} />
                  {Menu[ival].TabMenu[0].navOptionName}
                </Link>
                <ul className="nav-dropdown-items">
                  {this.returnElement(Menu[ival].data)}
                </ul>
              </li>)

          } else {
            list.push(<li className="nav-item">
              <Link className="nav-link" to={Menu[ival].TabMenu[0].webRoute}>
                <i className={`nav-icon ${Menu[ival].TabMenu[0].webIcon}`} />
                <span className="link-white">{Menu[ival].TabMenu[0].navOptionName}</span>
              </Link>
            </li>)
          }
        })
      }
    } catch (error) {
      console.log(error);
    }

    return list
  }

  returnElement = items => {
    // console.log(items, 'items');
    let returnItem = [];
    items.map((item, key) => {
      returnItem.push(
        <li className="nav-item">
          <Link className="nav-link" to={item.WebRoutes}>
            <i className={`nav-icon ${item.webIcon ? item.webIcon : "fa fa-user"}`} />
            <span className="link-white">{item.labelkey}</span>
          </Link>
        </li>
      );
    });
    return returnItem;
  };

  render() {
    // return true
    return (
      <div className="sidebar">
        <nav className="sidebar-nav">
          <ul className="nav">
            {this.state.Menu ? this.RenderFunction() : null}
          </ul>
        </nav>
      </div>
    )
  }
}
