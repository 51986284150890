import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
//import DfCarsEnquiry from "./DfCarsEnquiry";
import SingleSelect from "../../../../components/Form/SingleSelect";
import moment from "moment";

class UpdateComments extends FormMiddleWare {
    constructor(props) {
      super(props);
      this.state = {
        data: [],
        comment:"",
        followup:"",
        alertVisible: false,
        textalert: "",
        isEdit:false,
        EditId:"",
        Index:"",
        EditData:{},
        IsClose:false
  
  
      }
  }

  async componentDidMount() {
    // console.log(this.props)
 }

 async componentWillReceiveProps(props) {
    try {
	    if(props){
         console.log(props);

         this.setState({
             isEdit:props.isEdit,
             FullData:props.FullData,
             EditId:props.EditData.id,
             Index:props.Index,
             EditData:props.EditData,
             comment:props.EditData.evaluator_comment ? props.EditData.evaluator_comment : ""
             //IsClose:props.IsClose
            });
        }
    }catch(error){
        console.log(error)
    }
}

handlechangeData = async (e) => {
    //  if(e.target.type=="date"){
    //    console.log(e.target.value);
    //  }
    this.setState({ [e.target.name]: e.target.value });
  };

  onDismiss = () => {
    this.setState({ alertVisible: false, formAlertdelete: false });
  };

  UpdateCategory=async()=>{
    const{comment,followup,EditId,EditData,Index,FullData}=this.state;
     let GroupArray={};
     GroupArray.evaluator_comment=comment;
     let Commentdate = new Date();

     GroupArray.evaluator_date=moment(Commentdate).format("YYYY-MM-DD");
     console.log(GroupArray);
     try{
      const result = await cmsContent.updateMaster(
          "tbl_dfcarz_prosperities",
          EditId,
          GroupArray
        );
        if(result){
        //   this.state.EditData.comment=comment;
        //   this.state.EditData.followup=followup;
        //     console.log(EditData);

            const newData = [...FullData];
            newData[Index].evaluator_comment=comment;
            this.props.these('Data',newData);
            this.setState({
                isEdit:false,
                comment:"",
                followup:"",
                EditData:{},
                alertVisible:true,
                textalert: "Comment Placed",
                color: "success"
            });
            setTimeout(() => this.setState({ alertVisible: false }), 3000);
        }
     }catch(error){
         console.log(error);
     }
}

  render(){
    return(<React.Fragment>
        <main >
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h1>{this.state.IsClose==false ? "Update Enquiry" : "Close Enquiry"}</h1>
            </div>
            <div className="card-body">
            <div className="row form-group">
                      <div className="col-sm-2"/>
                      <div className="col-sm-7">
                        <Alert
                          className="badge-content"
                          color={this.state.color}
                          isOpen={this.state.alertVisible}
                          toggle={this.onDismiss}
                        >
                          {this.state.textalert}
                        </Alert>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-1" />
                      <div className="col-sm-3">
                        <label htmlFor="exampleInputEmail1">Comments</label>
                      </div>
                      <div className="col-sm-5">
                        <textarea
                          type="text"
                          className="form-control"
                          id="ColourName"
                          name="comment"
                          placeholder="Enter Comments"
                          value={this.state.comment}
                          onChange={this.handlechangeData}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                   <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-3" />
                      <div className="col-sm-5">
                        <button
                          type="button"
                          className={`btn btn-${
                            this.state.IsClose==false ? "secondary" : "danger"
                          }`}
                          disabled={this.state.disableValue}
                          onClick={
                            this.state.IsClose==false
                              ? this.UpdateCategory
                              : this.submitCategory
                          }
                        >
                          {this.state.IsClose==false
                            ? "Update Enquiry"
                            : "Close Enquiry"}
                        </button>
                      </div>
                      <div className="col-sm-2" />
                    </div>
                </div>
                </div>
                </div>
                </div>
                </div>
                </main>
                </React.Fragment>
                )
}
}
export default UpdateComments;
