import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import { FormMiddleWare, MultiSelect } from "../../../../components/Form";
import Datatable from "../../../../components/Datatable/Datatable";
import LoginModal from "../../../../components/Modal/Modal";
import Icon from "react-icons-kit";
import { calendar_ok } from 'react-icons-kit/ikons/calendar_ok';
import { checkmark } from 'react-icons-kit/icomoon/checkmark';
import { confirmAlert } from "react-confirm-alert";
import $ from 'jquery'
import { SingleSelect } from "../../../../components/Form";
import CmsContent from "../../../../MiddleWare/CmsContent";
import socket from "../../../../MiddleWare/socketMiddleWare";
import { ACCESS_POINT } from "../../../../config";
import moment from "moment";

class Evaluationreport extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Datas: [],
            Current_Date: new Date(),
            AuctionData: [],
            customerId: localStorage.getItem("userId"),
            LocationID: localStorage.getItem(`LocationID`),
            userid: JSON.parse(localStorage.getItem("userlog")),
            usertypelog: JSON.parse(localStorage.getItem("usertypelog")),
            EnquiryData: [],
            FilterData: [],
            alertVisible: false,
            StatusEditData: [],
            status1options: [{ value: 0, label: "Live" }, { value: 1, label: "Closed" }, { value: 2, label: "Deal Closed" }],
            status2options: [],
            status3options: [],
            followJson1: [{
                date: "",
                status1: "",
                status2: "",
                status3: "",
                remarks: "",
                follow: ""
            }],
            MakeOptions: [],
            coloropt: [],
            variantopt: [],
            modelopt: [],
            Datas: [],
            loading: false,
            column1: [],
            excelfilter: [],
            SelectedEnquiries: [],
            StatusupdatealertVisible: false,
            reallocatebtn: false,


            column: [
                {
                    Header: "Evaluation F-proof",
                    accessor: "mobile",
                    minWidth: 140,
                    Cell: d => this.ViewLead(d)
                },
                {
                    Header: "View Status",
                    accessor: "Auction",
                    minWidth: 170,
                    Cell: d => this.Status(d)
                },
                {
                    Header: "Name",
                    minWidth: 150,
                    accessor: "name"
                },
                {
                    Header: "Mobile",
                    accessor: "mobile",
                    Cell: d => this.Call(d.original.mobile)
                },
                {
                    Header: "Evaluator Name ",
                    minWidth: 150,
                    accessor: "userName"
                },
                {
                    Header: "Make",
                    accessor: "make",
                    Cell: (d) => this.getValueFromArray(d.original.make, this.state.MakeOptions)
                },
                {
                    Header: "Model",
                    minWidth: 150,
                    accessor: "model",
                    Cell: (d) => this.getValueFromArray(d.original.model, this.state.modelopt)
                },
                {
                    Header: "Color",
                    accessor: "color",
                    Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)
                },
                {
                    Header: "Evaluation Date",
                    accessor: "created_At",
                    minWidth: 180,
                    className: "text-center"
                },
                {
                    Header: "Follow Up Date",
                    accessor: "followup_date",
                    minWidth: 150,
                    className: "text-center"
                },
                // {
                //     Header: "Data Type",
                //     accessor: "Auction",
                //     minWidth: 150,
                //     Cell: (d) => this.Auction(d.original),
                // },
                // {
                //   Header: "Data Type",
                //   accessor: "Auction_Status",
                // },
                {
                    Header: "Status 1",
                    accessor: "mobile",
                    minWidth: 140,
                    Cell: d => this.Status1(d)
                },
                {
                    Header: "Auction",
                    accessor: "mobile",
                    minWidth: 180,
                    Cell: d => this.auction(d)
                },
                // {
                //   Header: "Status 1",
                //   accessor: "status1"
                // },
                {
                    Header: "Print PDF",
                    accessor: "pdf",
                    minWidth: 130,
                    Cell: d => this.viewpdf(d)
                },
                {
                    Header: "Reallocate Dotcue",
                    // minWidth: 180,
                    width: 150,

                    accessor: "edit",
                    Cell: d => this.Reallocate(d),
                },
            ],
            excelColumn: [
                {
                    label: "Name",
                    key: "name"
                },
                {
                    label: "Mobile",
                    key: "mobile",
                },
                {
                    label: "Evaluator Name ",
                    key: "userName"
                },
                {
                    label: "Make",
                    key: "makeName",
                },
                {
                    label: "Model",
                    key: "modelName",
                },
                {
                    label: "Color",
                    key: "colourName",
                },
                {
                    label: "Evaluation Date",
                    key: "created_At",
                },
                {
                    label: "Follow Up Date",
                    key: "followup_date",
                },
                {
                    label: "Data Type",
                    key: "Auction_Status",
                },
                {
                    label: "Status 1",
                    key: "status1"
                },
            ],
            LocationOptions: [],
            filteredLoc: "",
            filteredMake: "",
            data_for_f: [{ "fill": 0, "view": 0, "Live": 0, "today": 0, "MTD": 0, "ftd": 0 }]
        }
        this.vals()
    };
    viewpdf = d => {
        if (d.original.status1 == 'Live') {
            return (
                <span className="text-center">-</span>
            )
        }
        else {
            if (d.original.F_formfilled == 'yes' || d.original.F_formfilled == 'approved') {
                return (<center><button type="button" className="btn btn-warning btn-sm text-center" style={{ minWidth: "100px" }}
                    onClick={() => this.generatepdf(d)}>Print PDF</button></center>)
            }
            else {
                return (
                    <span className="text-center">-</span>)
            }
        }

    }
    generatepdf = async (d) => {

        const fproofid = await CmsContent.getFreedom("*", "tbl_FProof", `customerId=${d.original.id}`, 1, 1);

        if (fproofid) {
            let fproofpdf = await CmsContent.Fproofpdf("tbl_FProof", fproofid.data[0].customerid);

            if (fproofpdf.data == "success") {
                console.log(fproofpdf);
                window.open(`${ACCESS_POINT}/cmsContent/downloadfproof/${fproofid.data[0].customer_name}`, '_self')
            }
            // setTimeout(() => {
            //   this.generatebpdf(d)ue

            // }, 3000);
        }

    }
    auction = d => {
        let { AuctionData } = this.state
        console.log(d.original,"d.original");
        if (d.original && d.original.F_formfilled == 'yes' && d.original.Auction == 0 && d.original.status1 == "Live") {
            if (d.original.Bidamount == null && d.original.Auction == 0) {
                return (
                    <LoginModal
                        buttonTitle="Push To Auction"
                        title="Push To Auction"
                        id={`auction${d.original.id}`}
                        extraClass="btn btn-sm btn-primary"
                        onClick={() => this.handle_auction(d.original)}
                        buttonStyle={{ minWidth: "160px" }}
                        extraStyle={{ width: '100%' }}
                        bodyText={
                            <div>
                                <div className="card-body">
                                    <div className="row form-group">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-7">
                                            <Alert
                                                className="badge-content"
                                                color={this.state.color}
                                                isOpen={this.state.alertVisible}
                                                toggle={this.onDismiss}
                                            >
                                                {this.state.textalert}
                                            </Alert>
                                        </div>
                                        <div className="col-sm-3" />
                                    </div>

                                    <div className="row form-group">
                                        <div className="col-sm-1" />
                                        <div className="col-sm-3 text-right">
                                            <label htmlFor="exampleInputEmail1">
                                                Bid Amount
                                            </label>
                                        </div>
                                        <div className="col-sm-5">
                                            <input
                                                type="text"
                                                className="form-control"
                                                // id="registerName"
                                                name="amount"
                                                placeholder="Enter Amount"
                                                value={this.state.amount}
                                                onChange={this.handleUserInput}
                                            />
                                        </div>
                                        <div className="col-sm-3" />
                                    </div>
                                    <br />
                                    <div className="row-form-group">

                                        <div className="col text-center">
                                            <button
                                                type="button"
                                                className="btn btn-sm btn-primary"
                                                onClick={() => this.alert_Submit(this.submitauction, null)}
                                            // style={{ marginLeft: "250px" }}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        }
                    />)
            }
        }
        if (d.original && d.original.F_formfilled == 'yes' && d.original.Auction == 0 && d.original.view_status == true) {

            if (d.original.Bidamount == null && d.original.Auction == 0) {
                return (
                    <LoginModal
                        buttonTitle="Push To Auction"
                        title="Push To Auction"
                        id={`auction${d.original.id}`}
                        extraClass="btn btn-sm btn-primary"
                        onClick={() => this.handle_auction(d.original)}
                        buttonStyle={{ minWidth: "160px" }}
                        extraStyle={{ width: '100%' }}
                        bodyText={
                            <div>
                                <div className="card-body">
                                    <div className="row form-group">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-7">
                                            <Alert
                                                className="badge-content"
                                                color={this.state.color}
                                                isOpen={this.state.alertVisible}
                                                toggle={this.onDismiss}
                                            >
                                                {this.state.textalert}
                                            </Alert>
                                        </div>
                                        <div className="col-sm-3" />
                                    </div>

                                    <div className="row form-group">
                                        <div className="col-sm-1" />
                                        <div className="col-sm-3 text-right">
                                            <label htmlFor="exampleInputEmail1">
                                                Bid Amount
                                            </label>
                                        </div>
                                        <div className="col-sm-5">
                                            <input
                                                type="text"
                                                className="form-control"
                                                // id="registerName"
                                                name="amount"
                                                placeholder="Enter Amount"
                                                value={this.state.amount}
                                                onChange={this.handleUserInput}
                                            />
                                        </div>
                                        <div className="col-sm-3" />
                                    </div>
                                    <br />
                                    <div className="row-form-group">

                                        <div className="col text-center">
                                            <button
                                                type="button"
                                                className="btn btn-sm btn-primary"
                                                onClick={() => this.alert_Submit(this.submitauction)}
                                            // style={{ marginLeft: "250px" }}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        }
                    />)
            }
        }
        else if (d.original && d.original.F_formfilled == 'yes' && d.original && d.original.Auction != 0) {
            if (d.original.Auction == 1) {
                return (<span className="btn btn-sm text-center btn-info" style={{ minWidth: "160px" }}>In Auction</span>

                )
            }

            else if (d.original.Auction == 2) {
                return (<span className="btn btn-sm text-center btn-secondary" style={{ minWidth: "160px" }}>Auction Completed</span>

                )
            }
            else {
                return (
                    <LoginModal
                        buttonTitle="Auction Rejected"
                        title="Auction Rejected"
                        id={`auction${d.original.id}`}
                        extraClass="btn btn-sm btn-danger "
                        buttonStyle={{ minWidth: "160px" }}
                        onClick={() => this.handle_auction(d.original)
                        }
                        extraStyle={{ width: '100%' }}
                        //  buttonStyle={{ backgroundColor: "white", border: "white" }}
                        bodyText={
                            < div className="container" >

                                {AuctionData && AuctionData.Auction && AuctionData.Auction == 4 ?
                                    <>   <h3 className="text-center" style={{ color: "#fc4c0d" }}> Auction process Already Moved to Inventory</h3>
                                        <br />
                                        <div className="d-flex justify-content-center">
                                            <a
                                                className="btn btn-warning btn-block btn-sm text-center"
                                                href="/DFCars/InventoryList"
                                                style={{ maxWidth: "120px" }}
                                            >
                                                Go to Inventory
                                            </a>
                                        </div>

                                    </>
                                    :
                                    <div className="row">
                                        <>
                                            <div className="col-sm-3" />
                                            <div className="col-sm-6 text-center">
                                                <button
                                                    type="button"
                                                    className="btn btn-danger btn-block btn-sm"
                                                    onClick={() => this.submitauctionRejected("inventory")}
                                                    style={{ minWidth: "60px" }}
                                                >
                                                    Move to Inventory
                                                </button>
                                            </div>
                                            <div className="col-sm-3" />
                                            {/* <div className="col-sm-4">
                        <button
                          type="button"
                          className="btn btn-primary btn-block btn-sm"
                          onClick={() => this.submitauctionRejected("pushtoauction")}
                          style={{ minWidth: "120px" }}
                        >
                          Push to Auction
                        </button>
                      </div>
                      <div className="col-sm-2" /> */}
                                        </>

                                    </div>
                                }

                            </div >
                        }
                    />)
            }
        }
        else if (d.original && d.original.F_formfilled == 'yes' && d.original.Auction == 0 && d.original.view_status == false) {
            return (<span className="btn btn-sm text-center btn-success" style={{ minWidth: "160px" }}>Documentation Form Filled</span>
            )
        }
        else if (d.original && d.original.F_formfilled == 'no') {
            return (<span className="btn btn-sm text-center btn-secondary" style={{ minWidth: "160px" }}>Waiting For Form Fill</span>
            )
        }
        else {
            return (
                <span className="text-center">-</span>)
        }
    }
    Status = (d) => {

        if (d.original && d.original.status == "active" && d.original.F_formfilled == 'yes' || d.original.F_formfilled == 'approved' && d.original.Auction) {
            if (d.original.Auction == 1 && d.original.status1 == "Live") {
                return (
                    <center>
                        <button
                            type="button"
                            className="btn btn-info btn-sm"
                            data-toggle="modal"
                            style={{ minWidth: "150px" }}
                            data-target={"#matchcarmodal"}
                            onClick={() => this.StatusData(d)}
                        >
                            In Auction
                        </button>
                    </center>
                );

            }
            else if (d.original.Auction == 1 && d.original.status1 == "Deal Closed") {
                return (<center><button
                    type="button"
                    className="btn btn-info btn-sm"
                    data-toggle="modal"
                    style={{ minWidth: "150px" }}
                    data-target={"#matchcarmodal"}
                    onClick={() => this.StatusData(d)}
                >
                    In Auction
                </button>
                </center>
                );

            }
            else if (d.original.Auction == 2 && d.original.status1 == "Live") {
                return (
                    <center>
                        <button
                            type="button"
                            className="btn btn-info btn-sm"
                            data-toggle="modal"
                            style={{ minWidth: "150px" }}
                            data-target={"#matchcarmodal"}
                            onClick={() => this.StatusData(d)}
                        >
                            View Auction Completed
                        </button>
                    </center>
                );
            }
            else if (d.original.Auction == 2 && d.original.status1 == "Deal Closed") {
                return (<center><button
                    type="button"
                    className="btn btn-success btn-sm"
                    data-toggle="modal"
                    style={{ minWidth: "150px" }}
                    data-target={"#matchcarmodal"}
                    onClick={() => this.StatusData(d)}
                >
                    View Auction Completed
                </button>
                </center>
                );
                // return (<span className="btn btn-sm text-center btn-success" style={{ minWidth: "150px" }}>Auction Completed</span>

                // );
            }
            else if (d.original.Auction == 3) {
                return (<span className="btn btn-sm text-center btn-danger" style={{ minWidth: "150px" }}>Auction Rejected</span>

                );
            }
            else if (d.original.Auction == 4) {
                return (<span className="btn btn-sm text-center btn-danger" style={{ minWidth: "150px" }}>Auction Rejected</span>

                );
            }
            else if (d.original.Auction == 0) {
                return (
                    <center>
                        <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            data-toggle="modal"
                            style={{ minWidth: "150px" }}
                            data-target={"#matchcarmodal"}
                            onClick={() => this.StatusData(d)}
                        >
                            View
                        </button>
                    </center>
                );
            }
            else {
                return (
                    <center>
                        <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            data-toggle="modal"
                            style={{ minWidth: "150px" }}
                            data-target={"#matchcarmodal"}
                            onClick={() => this.StatusData(d)}
                        >
                            View
                        </button>
                    </center>
                );
            }
        }
        if (d.original.status == "active" && d.original.F_formfilled == 'yes' || d.original.F_formfilled == 'approved') {
            return (
                <center>
                    <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        data-toggle="modal"
                        style={{ minWidth: "150px" }}
                        data-target={"#matchcarmodal"}
                        onClick={() => this.StatusData(d)}
                    >
                        View
                    </button>
                </center>
            );
        }
        else if (d.original.status == "active") {
            return (<center><span className="btn btn-sm text-center btn-success" style={{ minWidth: "150px" }}>Active</span></center>)
        }
        else {
            return (<center><span className="btn btn-sm text-center btn-danger" style={{ minWidth: "150px" }}>InActive</span></center >)
        }
    }
    Status1 = (d) => {
        console.log(d.original, "d.original");

        // if (d.original.F_formfilled == 'yes' || d.original.F_formfilled == 'approved') {
        //     return (<div><span className="btn btn-secondary btn-sm" style={{ minWidth: "100px" }}>{d.original.status1}</span></div>)
        // }
        // else {
        //     return (
        //         <span className="text-center">-</span>)
        // }
        if (d.original.F_formfilled == 'yes' || d.original.F_formfilled == 'approved') {
            if (d.original.status1 == "Deal Closed") {
                return (<center><span className="btn btn-secondary btn-sm" style={{ minWidth: "100px" }}>{d.original.status1}</span></center>)

            } else if (d.original.status1 == "Live") {
                return (<center><span className="btn btn-secondary btn-sm" style={{ minWidth: "100px" }}>{d.original.status1}</span></center>)

            } else if (d.original.status1 == "Closed") {
                return (<center><span className="btn btn-secondary btn-sm" style={{ minWidth: "100px" }}>{d.original.status1}</span></center>)

            } else {
                return (
                    <span className="text-center">-</span>)
            }

        } else {
            return (
                <span className="text-center">-</span>)
        }
    }
    Auction = (d) => {
        if (d && d.Auction_Status) {
            return (
                <div>
                    <span className={`btn btn-${d.Auction == 0 ? "secondary" : d.Auction == 1 ? "warning" : d.Auction == 2 ? "success" : "danger"} btn-sm text-center`}
                        style={{ minWidth: "140px" }}>
                        {d.Auction_Status}
                    </span>
                </div>
            )
        } else {
            return (
                <div>
                    <span className={`btn btn-${d.Auction == 0 ? "secondary" : d.Auction == 1 ? "warning" : d.Auction == 2 ? "success" : "danger"} btn-sm text-center`}
                        style={{ minWidth: "140px" }}>
                        {d.Auction_Status}
                    </span>
                </div>
            )
        }
    };
    StatusData = (d) => {
        console.log(d.original, 'd');
        this.setState({ StatusEditData: d.original })
        this.form_filled(d)
    }
    form_filled = async (d) => {
        let ans = d.original;
        let followJson1 = this.state.followJson1;
        if (ans.status1) {
            let followJson1 = this.state.followJson1;
            let w1 = await this.state.status1options.map(async (ival, i) => {
                if (ival.label == ans.status1) {
                    await this.ans(ival, 0, "status1")
                }
            })
            await Promise.all(w1)
        }
        if (ans.status2 && this.state.status2options && this.state.status2options[0]) {
            let w = await this.state.status2options[0].map(async (ival, i) => {
                if (ival.label == ans.status2) {
                    await this.ans(ival, 0, "status2")
                }
            })
            await Promise.all(w)
        }
        if (ans.follow) {
            let follow = JSON.parse(ans.follow);
            // console.log(follow, 'folloe')
            followJson1[0].remarks = follow.length ? follow[0].remarks : ''
            followJson1[0].date = follow.length ? follow[0].date : ''
            followJson1[0].follow = follow.length ? follow[0].follow : ''
            this.setState({ followJson1 })
        }
    }
    ans = (label, value, type, index = null) => {
        console.log(label, value, type, index, 'label, i, type, index');

        let followJson1 = this.state.followJson1;
        followJson1[value][type] = label;
        let status2options = [];
        let status3options = [];
        this.setState({ followJson1 });
        if (type == "status1") {
            followJson1[value].status2 = followJson1[value].status2 != "" && ""
            this.setState({ followJson1 })
            if (followJson1[value].status1 && followJson1[value].status1.label == "Live") {
                status2options[value] = [
                    //{ label: "Appointment taken", value: 0 },
                    { label: "Under Follow up", value: 1 },
                    { label: "Ringing no response", value: 2 },
                    { label: "Researching", value: 3 },
                    { label: "Not attempted", value: 4 }];
                status3options[value] = [{ label: "Negotitation", value: 0 }, { label: " consideration", value: 1 }]
                this.setState({ status2options, status3options })
                //   this.setState({status2options:[{label:"Appointment taken",value:0},

            } else if (followJson1[value].status1 && followJson1[value].status1.label == "Closed") {
                status2options[value] = [
                    { label: "Lost", value: 2 },
                    { label: 'Dropped', value: 3 }

                ];
                this.setState({ status2options })
            } else if (followJson1[value].status1 && followJson1[value].status1.label == "Deal Closed") {
                status2options[value] = [
                    { label: "Trade in", value: 0 },
                    { label: "Docs pending", value: 1 },
                    { label: "Awaiting NC delivery", value: 2 },
                    { label: "Physically Recieved", value: 3 },
                ];
                followJson1[value].status2 = { label: "Trade in", value: 0 }
                this.setState({ status2options })
                this.setState({ followJson1 })
            }
        }
        if (type == "status2") {
            // this.setState({ followJson1 });
            followJson1[value].status3 = followJson1[value].status3 != "" && ""
            this.setState({ followJson1 })
            if (followJson1[value].status2 && followJson1[value].status2.label == "Lost") {
                status3options[value] = [
                    { label: "Competitor", value: 0 },
                    { label: "Co Dealer", value: 1 },
                    { label: "End Customer", value: 2 },
                    { label: "User Car Dealer", value: 3 },
                    { label: "Online Aggression", value: 4 },
                ];
                this.setState({ status3options });
            }
        };

        // console.log(followJson1);
    };
    statusModal = (StatusEditData) => {
        const { status1, status2, status3, followJson1 } = this.state;
        // console.log(StatusEditData, "StatusEditData");
        return (
            <React.Fragment>
                <main>
                    <div className="container-fluid">
                        <div className="row" style={{ justifyContent: 'center' }}>
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h1> View Status </h1>
                                    </div>
                                    <div className="card-body">
                                        <div className="row" style={{ justifyContent: 'center' }}>
                                            <div className="col-sm-7"
                                            // style={{ left: "25%" }}
                                            >
                                                <Alert
                                                    className="badge-content"
                                                    color={this.state.color}
                                                    isOpen={this.state.alertVisible}
                                                    toggle={this.onDismiss}
                                                >
                                                    {this.state.textalert}
                                                </Alert>
                                                <div  >
                                                    &nbsp;
                                                    {StatusEditData.status1 != null && <h4>Status 1: </h4>
                                                    } &nbsp;
                                                    &nbsp;
                                                    <SingleSelect
                                                        placeholder="Enter Status1"
                                                        options={this.state.status1options}
                                                        handleChange={e => {
                                                            this.ans(e, 0, "status1")
                                                        }}
                                                        // selectedService={followJson[index].status1}
                                                        selectedService={followJson1[0] && followJson1[0].status1
                                                            ? followJson1[0].status1
                                                            : null}
                                                        style={{ resize: "none", width: "100%" }}
                                                    />
                                                    <br />
                                                    {StatusEditData.status2 != null && (followJson1[0] && followJson1[0].status1 && followJson1[0].status1.label != 'Deal Closed') && <h4>Status 2: </h4>}
                                                    &nbsp;
                                                    {followJson1[0] && followJson1[0].status1 && followJson1[0].status1.label != 'Deal Closed' ? <SingleSelect
                                                        placeholder="Enter Status2"
                                                        options={this.state.status2options[0]}
                                                        handleChange={e => {
                                                            this.ans(e, 0, "status2")
                                                        }
                                                        }
                                                        // selectedService={followJson[index].status1}
                                                        selectedService={followJson1[0] && followJson1[0].status2
                                                            ? followJson1[0].status2
                                                            : null}
                                                        style={{ resize: "none", width: "100%" }}
                                                    /> : null}
                                                    <br />
                                                    &nbsp;
                                                    {/* {followJson1[0].status2 && followJson1[0].status2.label == "Lost" || followJson1[0].status2 && followJson1[0].status2.label == "Non contactable" ? (
                            <div><label>Status 3</label>
                              <SingleSelect
                                placeholder="Enter Status3"
                                options={this.state.status3options[0]}
                                handleChange={e => {
                                  this.ans(e, 0, "status3")
                                }}
                                // selectedService={followJson[index].status1}
                                selectedService={followJson1[0] && followJson1[0].status3
                                  ? followJson1[0].status3
                                  : null}
                                style={{ resize: "none", width: "100%" }}
                              /> </div>
                          ) : null} */}
                                                    {followJson1[0].status1 && followJson1[0].status1.label == "Live" ?
                                                        (<div class="container">
                                                            <div class="row">
                                                                <div class="col-sm-6">
                                                                    <lable>Date </lable> <input
                                                                        type="date"
                                                                        id="myDate"
                                                                        className="form-control"
                                                                        onChange={e =>
                                                                            this.ans(e.target.value, 0, "date")
                                                                        }
                                                                        value={
                                                                            followJson1[0] && followJson1[0].date
                                                                                ? followJson1[0].date
                                                                                : null
                                                                        }
                                                                        // onChange={this.fromDateSelect}
                                                                        // value={selectedstartdate}
                                                                        style={{ resize: "none", width: "100%" }}
                                                                    />
                                                                </div>
                                                                <div class="col-sm-6">
                                                                    <lable>Follow up date</lable> <input
                                                                        type="date"
                                                                        className="form-control"
                                                                        onChange={e => this.ans(e.target.value, 0, "follow")}
                                                                        style={{ resize: "none", width: "100%" }}
                                                                        value={
                                                                            followJson1[0] && followJson1[0].follow
                                                                                ? followJson1[0].follow
                                                                                : null
                                                                        }
                                                                    // value={followJson[index] && followJson[index].followup ? followJson[index].followup : null}
                                                                    ></input> </div>
                                                            </div>
                                                        </div>
                                                        ) : null}
                                                    <label>REMARKS</label>
                                                    <input
                                                        type="textarea"
                                                        name="REMARKS"
                                                        className="form-control"
                                                        placeholder="Enter remarks"
                                                        id="remarks"
                                                        onChange={e => {
                                                            this.ans(e.target.value, 0, "remarks");
                                                        }}
                                                        // value={followJson[index] && followJson[index].remarks ? followJson[index].remarks : null}
                                                        value={
                                                            followJson1[0] && followJson1[0].remarks
                                                                ? followJson1[0].remarks
                                                                : null
                                                        }
                                                        style={{ resize: "none", width: "100%" }}
                                                    ></input>
                                                    <br />

                                                    {StatusEditData && StatusEditData.Auction && StatusEditData.Auction == 1 && StatusEditData.view_status == true ?
                                                        <h3 className="text-center" style={{ color: "#fc4c0d" }}> Auction process Already Started</h3>
                                                        :
                                                        StatusEditData && StatusEditData.Auction && StatusEditData.Auction == 1 && StatusEditData.view_status == false ?
                                                            <h3 className="text-center" style={{ color: "#fc4c0d" }}> Auction process Already Started</h3>
                                                            :
                                                            StatusEditData && StatusEditData.Auction && StatusEditData.Auction == 2 && StatusEditData.view_status == true ?
                                                                <h3 className="text-center" style={{ color: "#07278f" }}> Auction process Already Completed</h3>
                                                                :
                                                                StatusEditData && ((StatusEditData.view_status && StatusEditData.view_status == true) || StatusEditData.status1 == "Live" || StatusEditData.status1 == "Closed") ?
                                                                    followJson1[0] && followJson1[0].status2 &&
                                                                    <button className="btn btn-sm btn-block btn-warning" disabled={this.state.statusbtnDisable} onClick={() => this.StatusUpdate(StatusEditData)}>{this.state.statusbtnDisable ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;Update</button>
                                                                    :
                                                                    <h3 className="text-center" style={{ color: "#07278f" }}> Documentation Form Already Filled</h3>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        )
    }
    StatusUpdate = async (StatusEditData) => {

        const { followJson1 } = this.state;

        let groupArray = {}
        let groupArray1 = {}
        const Fid = this.state.StatusEditData.Fid
        const Bid = this.state.StatusEditData.Bid
        groupArray.status1 = followJson1[0].status1 ? followJson1[0].status1.label : null;
        groupArray.status2 = followJson1[0].status2 ? followJson1[0].status2.label : null;
        if (followJson1[0].status1.label == "Deal Closed" && followJson1[0].status2.label == "Trade in") {
            groupArray.gotoapproval = 1
        } else {
            groupArray.gotoapproval = 0
        }
        groupArray1.follow = JSON.stringify(followJson1)
        console.log(groupArray1, Fid);
        console.log(groupArray);
        console.log('log', Fid, Bid);
        this.setState({ statusbtnDisable: true })
        try {
            let result = await CmsContent.updateMaster("tbl_FProof", Fid, groupArray);
            let Bproof = await CmsContent.updateMaster("tbl_BProof", Bid, groupArray1);

            console.log(result, Bproof);
            if (result && Bproof) {
                await this.setState({
                    statusbtnDisable: false,
                    alertVisible: true,
                    textalert: "Your form has been updated successfully",
                    color: "success",
                    StatusEditData: null
                });
                this.MainFunction()
                // window.$("#matchcarmodal").modal("hide")
                const elements = document.getElementsByClassName('modal-backdrop');
                elements[0].parentNode.removeChild(elements[0]);
                // window.location.reload()
            }
        } catch (err) {
            console.log(err);
            this.setState({ statusbtnDisable: false })
        }

    }
    submitauction = async () => {
        const { amount } = this.state;
        if (!amount) {
            alert("please Enter Bid Amount")
            return false
        }
        let { AuctionData } = this.state
        let d = AuctionData
        try {
            // let id = AuctionData.id;
            // const fproofid = await cmsContent.getFreedom("*", "tbl_FProof", `customerid = ${id} `, 1, 1);
            // let CategoryArray = {};
            // CategoryArray.Bidamount = parseInt(amount);
            // CategoryArray.Auction = 1;
            // try {
            //   let result = await cmsContent.updateMaster(
            //     "tbl_FProof",
            //     fproofid.data[0].id,
            //     CategoryArray
            //   );
            //   if (result) {
            //     let CategoryArray1 = {}
            //     CategoryArray1.fproof_id = d.original.Formid
            //     CategoryArray1.customerid = "Price push"
            //     CategoryArray1.Time = 360
            //     CategoryArray1.new_bid_amount = parseInt(amount);
            //     CategoryArray1.ServiceId = 10
            //     let result1 = await cmsContent.addMaster('tbl_bid', CategoryArray1)
            //     if (result1) {
            //       await this.setState({
            //         amount: "",
            //         alertVisible: true,
            //         color: "success",
            //         textalert: "Success",
            //         Datas: []
            //       })
            //       await this.MainFunction()

            //     }
            //     this.MainFunction()
            //     window.location.reload()
            //     // $(`#auction${ d.original.id } `).modal().hide();
            //     // setTimeout(()=>window.location.reload())
            //   }
            let CategoryArray1 = {}
            CategoryArray1.fproof_id = d.Formid
            CategoryArray1.customerid = "Price push"
            CategoryArray1.Time = 360
            CategoryArray1.new_bid_amount = parseInt(amount);
            CategoryArray1.ServiceId = 10
            let Alldata = await CmsContent.BUYDFCARZ("subsubmit", 'Auction', CategoryArray1)
            if (Alldata) {
                await this.setState({
                    amount: "", alertVisible: true, color: "success", textalert: "Success", Datas: []
                })
                socket.emit('DfcarzBid', { "auction": true, "id": d.Formid })
                // await this.MainFunction()
                window.location.reload()
            }
        } catch (error) {
            console.log(error);
        }
    }

    submitauctionRejected = async (name) => {
        let { AuctionData } = this.state
        try {
            console.log(name, "name", AuctionData);
            let d = AuctionData
            let id = AuctionData.id;
            if (AuctionData) {
                let F_proof = await cmsContent.getFreedom(`*`, `tbl_FProof`, `customerid=${id}`, 1, 1);
                if (F_proof && F_proof.data) {
                    if (name == "pushtoauction") {

                    }

                    else {
                        let CategoryArray = {};
                        CategoryArray.Auction = 4;
                        let result = await cmsContent.updateMaster(
                            "tbl_FProof",
                            F_proof.data[0].id,
                            CategoryArray
                        );
                        if (result) {
                            // await this.MainFunction()
                            window.location.reload()
                        }
                    }
                }
            }

        } catch (error) {
            console.log(error);
        }
    }
    handle_auction = async (d) => {
        await this.setState({
            amount: "", AuctionData: d

        })
    }
    ViewLead = (d) => {
        return (<center><button type="button" className="btn btn-warning btn-sm text-center" style={{ minWidth: "100px" }}
            onClick={() => this.F_proofForm(d)}
        >View Form</button></center>)
    }
    ViewLead2 = (d) => {
        return (<center><button type="button" className="btn btn-warning btn-sm text-center" style={{ minWidth: "100px" }}
            onClick={() => this.F_proofForm3(d)}
        > View Form</button></center >)
    }

    F_proofForm = async (d) => {
        window.open(`/DFCars/Evaluation_Checklist_F_Proof/${d.original.id}`, '_self')
    }

    F_proofForm3 = async (d) => {
        window.open(`/DFCars/Evaluation_Checklist_B_Proof/${d.original.id}`, '_self')
    }

    UpdateSTatus = (d) => {
        return (
            <button
                type="button"
                className="btn btn-sm btn-warning"
                data-toggle="modal"
                data-target={"#comments"}
                onClick={() => this.Editdata(d)}
            >
                View Comments
            </button>
        );
    };

    Editdata = async (d) => {
        this.setState({ stage: "Evaluation", CustomerId: d.original.id });
    };
    FormApprove = async (d) => {
        let body = {};
        body.customerid = d.original.id;
        body.approval = 1;
        body = JSON.stringify(body)
        try {
            let results = await cmsContent.UpdateFApproval(body)
            console.log(results, 'r');
            if (results) {
                window.location.reload()
            }
        }
        catch (error) {
            console.log(error);
        }
    }
    async vals() {
        let allOpt = await this.decryption('getcar')
        if (allOpt) {
            console.log(allOpt, "allOpt")
            await this.setState({
                MakeOptions: allOpt.makeopt, coloropt: allOpt.coloropt, variantopt: allOpt.variantopt, modelopt: allOpt.modelopt, LocationOptions: allOpt.locopt,
            })
        }
    }

    MainFunction = async () => {
        let { userid } = this.state
        try {

            // let date =JSON.stringify(moment(new Date()).subtract(3, 'months').format("YYYY-MM-DD hh:mm:ss"))
            // console.log(date,"date");

            console.log(this.state.userid, "this.state.userid");
            this.setState({ loading: true, alertVisible: false, Data: [], Datas: [] })
            let results1 = await cmsContent.BUYDFCARZ(this.state.userid.id, 'TeleEvaluation')
            console.log("API", results1);
            // let Evalopt
            if (results1) {
                let Evalopt = await this.decryption("Downline_Details")
                console.log(Evalopt, "Evalopt");
                // Evalopt = await CmsContent.getFreedom(
                //     `tbl_user_web.*,tbl_user_web.userName as label,tbl_user_web.id as value`,
                //     `tbl_user_web`,
                //     // `tbl_dfcars_comments.customerid = ${d.original.id} and tbl_dfcars_comments.stage='actionplan'and tbl_user_web.id = tbl_dfcars_comments.userid and tbl_user_web.userportid = tbl_MaptoLocation.id and tbl_UserType.id = tbl_MaptoLocation.userTypeId`,
                //     `tbl_user_web.downline=${userid.downline} and tbl_user_web.userType=123`,
                //     1,
                //     `tbl_user_web.id DESC`,
                // );
                // console.log(Evalopt,"Evalopt mainFun");
                let fulldata = []
                if (results1 && results1.Evaluation_Prospective) {
                    fulldata = results1.Evaluation_Prospective;
                }
                let Data = results1.Evaluation_Prospective;
                let data_for_f = results1.data_for_f
                console.log(fulldata,"fulldata");
                await this.setState({
                    Data,
                    data2: fulldata.length ? fulldata.filter(e => e.filter_filled == true) : [],
                    data3: fulldata.length ? fulldata.filter(e => e.filternon_filled == true) : [],
                    data4: fulldata.length ? fulldata.filter(e => e.filter_closed == true) : [],
                    evalopt: Evalopt && Evalopt.EVALUTOROPT ? Evalopt.EVALUTOROPT : [],

                    data_for_f,
                    loading: false
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
    async componentDidMount() {
        await this.MainFunction()
    }
    edash = () => {
        return (<div className="">
            <div className="card">
                <div className="card-header">
                    <div className="col-sm-6" >
                        <h2 className="mt-2 black">Dashboard</h2>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row mt-4 text-center">
                        <div className="col">
                            <div className="dashboardCard1" onClick={() => this.onClk(1)} style={{ textAlign: 'center' }}>
                                <Icon className="dashboardBadge" icon={checkmark} style={{ color: 'green', position: 'static', height: '40px' }} size={35} />
                                <h4 className="mt-2 black" style={{ paddingLeft: "" }}>Filled</h4>
                                <div style={{ textAlign: 'center' }}>
                                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.data_for_f[0].filled ? this.state.data_for_f[0].filled : 0}</b></h1>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="dashboardCard1" onClick={() => this.onClk(3)} style={{ textAlign: 'center' }}>
                                <Icon className="dashboardBadge" icon={calendar_ok} style={{ color: 'red', position: 'static', height: '40px' }} size={35} />
                                <h4 className="mt-2 black" style={{ paddingLeft: "" }}>Closed Enquiry</h4>
                                <div style={{ textAlign: 'center' }}>
                                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.data_for_f[0].closed ? this.state.data_for_f[0].closed : 0}</b></h1>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="dashboardCard1" onClick={() => this.onClk(2)} style={{ textAlign: 'center' }}>
                                <Icon className="dashboardBadge" icon={calendar_ok} style={{ color: 'red', position: 'static', height: '40px' }} size={35} />
                                <h4 className="mt-2 black" style={{ paddingLeft: "" }}>Non-Filled</h4>
                                <div style={{ textAlign: 'center' }}>
                                    <h1 className="bold mt-4 mb-2 black"><b>{this.state.data_for_f[0].nonfilled ? this.state.data_for_f[0].nonfilled : 0}</b></h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
    onClk = async (val) => {

        // console.log(val, this.state.data2, this.state.Data,"exampletoday") ;
        if (val === 1) {

            let filter = this.state.column.filter(function (el) {
                return el.Header !== "Follow Up Date" && el.Header !== "Reallocate Dotcue";
            })

            let exfilter = this.state.excelColumn.filter(function (ex) { return ex.label !== "Follow Up Date" })

            this.setState({ Datas: this.state.data2, FilterData: this.state.data2, filteredLoc: "", filteredMake: "", column1: filter, excelfilter: exfilter, workproc: false })
        }

        if (val === 2) {

            let filter = this.state.column.filter(function (el) {
                return el.Header !== "Evaluation Date" && el.Header !== "Reallocate Dotcue";
            })

            let exfilter = this.state.excelColumn.filter(function (ex) { return ex.label !== "Evaluation Date" })

            this.setState({ Datas: this.state.data3, FilterData: this.state.data3, filteredLoc: "", filteredMake: "", column1: filter, excelfilter: exfilter, workproc: false })
        }
        if (val === 3) {

            let filter = this.state.column.filter(function (el) {
                return el.Header !== "Follow Up Date" && el.Header !== "Status 1";
            })

            let exfilter = this.state.excelColumn.filter(function (ex) { return ex.label !== "Follow Up Date" })

            this.setState({ Datas: this.state.data4, FilterData: this.state.data4, filteredLoc: "", filteredMake: "", column1: filter, excelfilter: exfilter, workproc: false })
        }
        window.scroll({
            top: 400,
            left: 0,
            behavior: "smooth",
        });
    }
    makeFilter = async (d) => {
        let { filteredMake, MakeOptions, filteredLoc, FilterData } = this.state;

        //   console.log(d, "d")

        await this.setState({ filteredMake: d })

        if (d && d.length == 0 && filteredLoc.length == 0) {
            await this.setState({ Datas: this.state.FilterData })
        }
        else if (d && d.length == 0 && filteredLoc.length != 0) {
            var filtered_data = await FilterData.filter(x => filteredLoc.some(y => x.location == y.value))
            await this.setState({ Datas: filtered_data })
        }
        else if (d && d.length != 0 && filteredLoc && filteredLoc.length != 0) {
            var filtered_make = await FilterData.filter(fd => d.some(d => fd.make == d.value))
            var filtered_loc = await FilterData.filter(fd => filteredLoc.some(fl => fd.location == fl.value))
            var filtered_data = await filtered_make.filter(fm => filtered_loc.some(fl => fm.make == fl.make && fm.location == fl.location))
            await this.setState({ Datas: filtered_data })
        }
        else if (d && d.length != 0 && filteredLoc.length == 0) {
            var filtered_data = await FilterData.filter(x => d.some(y => x.make == y.value))
            await this.setState({ Datas: filtered_data })
        }
        else {
            //nothing
        }
    }
    locationFilter = async (d) => {
        let { locationOpt, filteredLoc, FilterData, filteredMake, EnquiryDatas } = this.state
        await this.setState({ filteredLoc: d })

        if (d && d.length == 0 && filteredMake.length == 0) {
            await this.setState({ Datas: this.state.FilterData })
        }
        else if (d && d.length == 0 && filteredMake.length != 0) {
            var filtered_data = await FilterData.filter(x => filteredMake.some(y => x.make == y.value))
            await this.setState({ Datas: filtered_data })
        }
        else if (d && d.length != 0 && filteredMake.length != 0) {
            var filtered_loc = await FilterData.filter(fd => d.some(d => fd.location == d.value))
            var filtered_make = await FilterData.filter(fd => filteredMake.some(d => fd.make == d.value))
            var filtered_data = await filtered_loc.filter(fl => filtered_make.some(fm => fl.location == fm.location && fl.make == fm.make))
            await this.setState({ Datas: filtered_data })
        }
        else if (d && d.length != 0 && filteredMake.length == 0) {
            var filtered_data = await FilterData.filter(x => d.some(y => x.location == y.value))
            await this.setState({ Datas: filtered_data })
        }
        else {
            //nothing
        }

    }
    clearFilter = async () => {
        await this.setState({ filteredMake: "", filteredLoc: "", Datas: this.state.FilterData })
    }
    Reallocate = (d) => {
        return (
            <center>
                <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    data-toggle="modal"
                    data-target={"#AllocateModel"}
                    onClick={() => this.updatereallocate(d)}
                >
                    Re-Allocate
                </button>
            </center>
        );
    }
    updatereallocate = async (d) => {
        let { EnquiryDatas1 } = this.state
        console.log(d, "dddd");
        console.log(EnquiryDatas1, "EnquiryDatas1");
        let AllocateType = "reallocate"
        if (d) {
            EnquiryDatas1 = {}
            EnquiryDatas1 = d.original
            // console.log(EnquiryDatas1,"EnquiryDatas1 dddd");
        }
        await this.setState({ EnquiryDatas1, AllocateType, reallocatebtn: true, AllocateSelect1: EnquiryDatas1 })
    }
    StatusReset = () => {
        this.setState({
            statuschg1: false, status2visible: false, status2: "",
            status1: null, StatusEditData: {}, status3visible: false,
            sellerstatus1: {}, sellerstatus2: {}, sellerstatus3: {}, EvalutorSelect: [], EvalutorSelectError: ""
        })
    }
    handleEvalutorOPT = async (e) => {
        this.setState({
            EvalutorSelect: e, EvalutorSelectError: ""
        })
    }
    AllocateSelect = () => {
        return (
            <div>
                <SingleSelect
                    placeholder="Select..."
                    options={this.state.evalopt}
                    handleChange={d => this.handleEvalutorOPT(d)}
                    selectedService={this.state.EvalutorSelect}
                />
            </div>
        )
    }
    AllocateSelect1 = (EnquiryDatas1) => {
        console.log("enetr 1");
        console.log(EnquiryDatas1, "EnquiryDatas1");
        let EvaluatorOptions = this.state.evalopt
        console.log(EvaluatorOptions, "EvaluatorOptions");
        let evalopt = []
        let allocatedEvaluator = parseInt(EnquiryDatas1.evaluatorid)
        EvaluatorOptions.map((ival) => {
            if (ival.value == allocatedEvaluator) {
                evalopt.push(ival)
            }
        })
        console.log(evalopt, "evalopt");
        return (
            <div>
                <SingleSelect
                    placeholder="Select..."
                    options={evalopt}
                    handleChange={d => this.handleEvalutorOPT(d)}
                    selectedService={this.state.EvalutorSelect}
                />
            </div>
        )

    }
    AllocateUpdate = async () => {
        const { EvalutorSelect, SelectedEnquiries, AllocateType, EnquiryDatas1 } = this.state
        console.log(SelectedEnquiries, EvalutorSelect, EnquiryDatas1);
        // SelectedEnquiries = EnquiryDatas1;
        if (!EvalutorSelect.value) {
            await this.setState({ EvalutorSelectError: "Please Select Evalutor" })
            return false
        }
        console.log(EnquiryDatas1, "EnquiryDatas1");
        let EnquiryDatas2 = JSON.stringify(EnquiryDatas1)
        const formData = new FormData();
        // formData.append("SelectedEnquiries", SelectedEnquiries);
        formData.append("SelectedEnquiries", EnquiryDatas2);
        formData.append("SelectedID", EvalutorSelect.value);
        formData.append("AllocateType", AllocateType);
        this.setState({ statusbtnDisable: true })
        console.log(...formData,"formData");
        console.log(EnquiryDatas1, "EnquiryDatas1 EnquiryDatas1");
        // return false
        try {
            let result = await cmsContent.DFcarsTelecallerAllocate("tbl_FProof", "seller", formData)
            console.log(result, "re");
            if (result) {
                await this.setState({
                    statusbtnDisable: false,
                    StatusupdatealertVisible: true,
                    Statusupdataetextalert: "Enquiry have been Allocated successfully",
                    Statusupdatecolor: "success",
                    EvalutorSelectError: "", SelectedEnquiries: [], EnquiryDatas1: []
                });
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: "smooth"
                });
                this.MainFunction()
                setTimeout(() => { this.setState({ StatusupdatealertVisible: false }); }, 3000);
            }
        }
        catch (err) {
            console.log(err);
            this.setState({ statusbtnDisable: false })
        }
    }
    render() {
        let { StatusEditData, LocationOptions, MakeOptions, Datas, SelectedEnquiries, EnquiryDatas1 } = this.state
        console.log(Datas, "Datas")

        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        {this.edash()}
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h1>Evaluation Report</h1>
                                    </div>
                                    <div className="card-body">
                                        {this.state.StatusEditData != null && <div>
                                            <LoginModal
                                                title={StatusEditData.name}
                                                id="matchcarmodal"
                                                buttonStyle={{ backgroundColor: "white", border: "white" }}
                                                reset={() => { this.setState({ statuschg1: false, status2visible: false, StatusEditData: [] }) }}
                                                bodyText={this.statusModal(StatusEditData)}
                                                extraStyle={{ width: '100%' }}
                                            />
                                        </div>}
                                        {this.state.StatusEditData != null && <div>
                                            <LoginModal
                                                title={StatusEditData.name}
                                                id="AllocateModel"
                                                reset={this.StatusReset}
                                                extraStyle={{ width: '100%' }}
                                                buttonStyle={{ backgroundColor: "white", border: "white" }}
                                                bodyText={
                                                    <React.Fragment>
                                                        <main>
                                                            <div className="container-fluid">
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <div className="card">
                                                                            <div className="card-header">
                                                                                <h1> Edit Status </h1>
                                                                            </div>
                                                                            <div className="card-body">
                                                                                <div className="row form-group">
                                                                                    <div className="col-sm-2" />
                                                                                    <div className="col-sm-7">
                                                                                        <Alert
                                                                                            className="badge-content"
                                                                                            color={this.state.Statusupdatecolor}
                                                                                            isOpen={this.state.StatusupdatealertVisible}
                                                                                            toggle={this.onDismiss}
                                                                                        >
                                                                                            {this.state.Statusupdataetextalert}
                                                                                        </Alert>
                                                                                    </div>
                                                                                    <div className="col-sm-3" />
                                                                                </div>

                                                                                <div className="row">
                                                                                    <div className="col-sm-2" />
                                                                                    <div className="col-sm-7">
                                                                                        <h4>Allocate Evalutor:</h4>
                                                                                        {this.state.reallocatebtn == true ? this.AllocateSelect1(EnquiryDatas1) : this.AllocateSelect()}
                                                                                        {/* {this.AllocateSelect()} */}
                                                                                        <span className="error-shows" style={{ color: "red" }}>
                                                                                            {this.state.EvalutorSelectError}
                                                                                        </span>
                                                                                        <br />
                                                                                        <br />
                                                                                        <button className="btn btn-sm btn-block btn-warning" disabled={this.state.statusbtnDisable} onClick={() => { this.AllocateUpdate() }}>{this.state.statusbtnDisable ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;Update</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </main>
                                                    </React.Fragment>
                                                }
                                            /></div>
                                        }


                                        {
                                            this.state.FilterData && this.state.FilterData.length ?
                                                <>
                                                    <div className="container">
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <label htmlFor="locationFilter" style={{ fontWeight: 500, fontSize: "medium" }} > Location filter: </label>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <label htmlFor="makefilter" style={{ fontWeight: 500, fontSize: "medium" }}> Make filter: </label>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <MultiSelect
                                                                    id="locationFilter"
                                                                    name="location"
                                                                    placeholder="Filter Location"
                                                                    options={LocationOptions}
                                                                    handleChange={d => this.locationFilter(d)}
                                                                    selectedService={this.state.filteredLoc}
                                                                />
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <MultiSelect
                                                                    id="makefilter"
                                                                    name="make"
                                                                    placeholder="Filter Make"
                                                                    options={MakeOptions}
                                                                    handleChange={d => this.makeFilter(d)}
                                                                    selectedService={this.state.filteredMake}
                                                                />
                                                            </div>
                                                        </div>
                                                        <br></br>
                                                        <div className="row">
                                                            <div className="col-sm-5" />
                                                            <div className="col-sm-2">
                                                                <button type="button" className="btn btn-sm btn-primary" onClick={() => {
                                                                    this.clearFilter()
                                                                }}> Clear filter</button>
                                                            </div>
                                                            <div className="col-sm-5"></div>
                                                        </div>
                                                    </div>
                                                    <br></br>

                                                </>
                                                :
                                                ""

                                        }
                                        {/* {SelectedEnquiries && SelectedEnquiries.length ? this.AllocateModel() : ""} */}

                                        {this.state.loading
                                            ?
                                            <div className="text-center">
                                                <i className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}>
                                                </i>
                                            </div>
                                            :
                                            this.state.Data && this.state.Data.length
                                                ?
                                                this.state.Datas && this.state.Datas.length && this.state.FilterData
                                                    ?
                                                    <>


                                                        <Datatable
                                                            data={this.state.Datas}
                                                            columnHeading={this.state.column1}
                                                            excelColumn={this.state.excelfilter}
                                                        />
                                                    </>
                                                    :
                                                    <>
                                                        <div style={{ textAlign: "center", fontSize: "larger" }} ><b>No rows to show</b></div>
                                                        <br></br>
                                                    </>
                                                :
                                                (<div style={{ textAlign: "center" }} ><b>No Data Found</b></div>)
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        )
    }
}
export default Evaluationreport;