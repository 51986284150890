import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
//import DfCarsEnquiry from "./DfCarsEnquiry";
import SingleSelect from "../../../../components/Form/SingleSelect";
import moment from "moment";
import Camera from "../../../../MiddleWare/camera";
import { FilePond, registerPlugin } from "react-filepond";
import CmsContent from "../../../../MiddleWare/CmsContent";
import { ACCESS_POINT } from "../../../../config";
import iconClose from "../../../../images/CMS/icon-hamburger-close.svg"


class ContractPayment extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            comment: "",
            followup: "",
            statusTrue: false,
            validate_submit_payment: false,
            alertVisible: false,
            textalert: "",
            isEdit: false,
            EditId: "",
            Index: "",
            EditData: {},
            tableArray: [],
            IsClose: false,
            Userlog: JSON.parse(localStorage.getItem("userlog")),
            usertypelog: JSON.parse(localStorage.getItem("usertypelog")),
            Budget: null,
            CommentList: [],
            status1options: [
                { value: 1, label: "Finance payment" },
                { label: "Customer payment", value: 2 }
            ],
            status2options: [
                { value: 1, label: "DD" },
                { label: "Cheque", value: 2 },
                { label: "Card", value: 3 },
                { label: "Cash", value: 4 }
            ],
            statusArray: [{ paymentfrom: "", paymentFrom: '', mop: '', modeofpayment: '', meterialnum: '', amount: null, balAmount: '', paymentdate: '', username: '', userid: '', index: 0, error: null }],
            // tableArray:  [{ paymentfrom: "", paymentFrom: '', modeofpayment: '', mop: '', meterialnum: '', amount: null, paymentdate: '', index: 0, error: null }],
            filesArray: { recipt: [] },
            msg: '',
            msgShow: false,
            contractCancel: false,
            reasonForCancel: ''

        }
    }

    async componentDidMount() {
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    async componentWillReceiveProps(props) {
        try {
            await this.setState({
                isEdit: null, FullData: null, validate_submit_payment: false, EditId: null, EditData: null, balanceamount: null, Totalbalanceamount: null, CommentList: [], statusArray: []
            });
            if (props && props.EditData) {
                let d = props.EditData;
                console.log(d.id, "d.id");
                const book = await CmsContent.getFreedom(
                    "tbl_Booking_Confirmation_Reproof.Files",
                    "tbl_Booking_Confirmation_Reproof",
                    `customerid = ${d.id}`,
                    "id",
                    "id"
                );
                console.log(book.data, "book.dat");
                if (book && book.data && book.data.length) {
                    let Book_Data = book.data[0].Files
                    if (Book_Data && Book_Data !== null) {
                        let book_length = JSON.parse(Book_Data);
                        let Obj_file = Object.keys(book_length)
                        let Recipt_filter = Obj_file.filter((x) => x === "recipt")
                        if (Recipt_filter && Recipt_filter.length) {
                            let Book = book.data
                            this.imgFun(Book);
                        } else {
                            await this.setState({ img: {} })
                        }
                    }
                }

                await this.setState({
                    tableArray: d && d.paymentJson ? Array.isArray(d.paymentJson) ? d.paymentJson : JSON.parse(d.paymentJson) : []
                })
                await this.setState({
                    isEdit: props.isEdit,
                    FullData: props.FullData,
                    EditId: props.EditData?.formid,
                    statusArray: props.statusarray,
                    EditData: props.EditData,
                    balanceamount: props.EditData?.blc_amt_1,
                    Totalbalanceamount: props.EditData?.blc_amt_1,
                    CommentList: []
                });
                await this.content_Align(props.statusarray)
            }
        } catch (error) {
            console.log(error)
        }
    }
    imgFun = async (book) => {
        let images = book[0].Files ? JSON.parse(book[0].Files) : null;
        let img = {};
        if (images) {
            Object.keys(images).map((key) => {
                if (key) {
                    img[key] = []
                    images[key].map((ival, i) => {
                        if (ival) {
                            let spliting = ival.split(".");
                            if (['pdf', 'doc', 'xlsx', 'csv'].includes(spliting[1])) {
                                let a = null
                                a = "false/pdfIconTrans_1599478202382.png";

                                let wait = img[key].push(
                                    <div>
                                        <div style={{ paddingLeft: '160px', position: 'relative' }} >
                                            {/* <img src={iconClose} style={{ height: 15, width: 20, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                                                onClick={() => this.removeimg(key, i)}></img> */}
                                        </div>
                                        <img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + a}
                                            onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }}
                                            style={{
                                                width: "150px", height: "100px", display: "inline-block", padding: "10px"

                                            }} />
                                    </div>
                                )
                            } else {
                                let wait = img[key].push(
                                    <div>
                                        <div style={{ paddingLeft: '160px', position: 'relative' }} >
                                            {/* <img src={iconClose} style={{ height: 15, width: 20, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                                                onClick={() => this.removeimg(key, i)}></img> */}
                                        </div>
                                        <img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + ival}
                                            onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }}
                                            style={{
                                                width: "150px", height: "100px", display: "inline-block", padding: "10px"

                                            }} />
                                    </div>
                                )
                            }
                        }
                    })
                }
            })
            if (img && img.recipt) {
                img.recipt.reverse()
            } else {
                return true
            }
            await this.setState({ img, oldfiles: images })

        }
    }
    handlechangeData = async (e, filed, i) => {
        let { statusArray, tableArray, balanceamount, Totalbalanceamount } = this.state;
        if (filed == 'amount') {

            if (!e) {
                balanceamount = await parseInt(Totalbalanceamount) - 0
                await this.setState({ balanceamount, validate_submit_payment: false })
            }
            if (isNaN(e)) return true
            balanceamount = await parseInt(Totalbalanceamount) - parseInt(e)
            if (e < parseInt(Totalbalanceamount)) {

                let ev = (e == '' || e == null) ? 0 : e
                statusArray[i][filed] = e
                await this.setState({ validate_submit_payment: false })
            }
            if (e == parseInt(Totalbalanceamount)) {

                let ev = (e == '' || e == null) ? 0 : e
                statusArray[i][filed] = e
                await this.setState({ validate_submit_payment: false })
            }
            if (e > parseInt(Totalbalanceamount)) {

                let ev = (e == '' || e == null) ? 0 : e
                statusArray[i][filed] = e
                await this.setState({ validate_submit_payment: true })
            }
            if (!e) {
                statusArray[i][filed] = ""
                balanceamount = await parseInt(Totalbalanceamount) - 0
                await this.setState({ validate_submit_payment: false })
            }
            statusArray[i]['balAmount'] = balanceamount
        } else statusArray[i][filed] = e
        if (filed == 'paymentFrom') statusArray[i]['paymentfrom'] = e.label
        if (filed == 'mop') statusArray[i]['modeofpayment'] = e.label
        if (filed == 'paymentFrom') statusArray[i]['index'] = tableArray ? tableArray.length + 1 : 1

        await this.setState({ statusArray, balanceamount, [filed]: e })
        await this.content_Align(statusArray)
    };

    onDismiss = () => {
        this.setState({ alertVisible: false, formAlertdelete: false });
    };

    UpdateCategory = async () => {
        const { balanceamount, followup, status, EditId, Userlog, EditData, isEdit, userid, statusTrue } = this.state;

        let { statusArray, tableArray, filesArray } = this.state;

        // let imagealeart = ''
        // if (filesArray && filesArray.recipt.length) {
        //     const fileSize = filesArray.recipt[0].size / 1024 / 1024;
        //     const maxFileSize = 3; // Maximum file size in MB
        //     // Validate file size
        //     if (fileSize > maxFileSize) {
        //         alert("Please Check Image is Greater than 3MB");

        //         imagealeart = true
        //         return true;
        //     }
        // }
        // if (imagealeart == true) {
        //     return false;
        // }

        let StatusArray = statusArray[0]
        statusArray.map((ival) => {
            if (ival.paymentfrom == "") {
                ival.error = 'Please Select Payment From'
                this.setState({
                    statusTrue: true, statusArray
                })
                setTimeout(() => {
                    this.setState({ statusTrue: false })
                }, 5000);
                return false
            } else if (ival.mop == "") {
                ival.error = 'Please Select Mode of Payment'
                this.setState({
                    statusTrue: true, statusArray
                })
                setTimeout(() => {
                    this.setState({ statusTrue: false })
                }, 5000);
                return false

            } else if (ival.meterialnum == "") {
                ival.error = 'Please Select Meterial Number'
                this.setState({
                    statusTrue: true, statusArray
                })
                setTimeout(() => {
                    this.setState({ statusTrue: false })
                }, 5000);
                return false

            } else if (ival.amount == "") {
                ival.error = 'Please Select Amount'
                this.setState({
                    statusTrue: true, statusArray
                })
                setTimeout(() => {
                    this.setState({ statusTrue: false })
                }, 5000);
                return false
            }
            else if (!this.state.filesArray.recipt.length) {
                ival.error = 'Please Upload Receipt'
                this.setState({
                    statusTrue: true, statusArray
                })
                setTimeout(() => {
                    this.setState({ statusTrue: false })
                }, 5000);
                return false
            } else {
                ival.error = null
            }
        })
        await this.setState({ statusArray })

        let val = statusArray.some(e => e.error != null)
        if (val) return true
        await statusArray.map((ival) => {
            ival.paymentdate = moment(new Date()).format('YYYY-MM-DD');
            ival.username = Userlog.userName;
            ival.userid = Userlog.id;
        })
        let json
        if (tableArray && tableArray.length) {
            json = await [...statusArray, ...tableArray]
        } else {
            json = statusArray

        }
        const formData = new FormData()

        if (parseInt(balanceamount) <= 0) {
            formData.append("paymentStatus", 1)
        } else {
            formData.append("paymentStatus", 0)
        }
        formData.append("blc_amt_1", balanceamount)
        formData.append("paymentJson", JSON.stringify(json))

        if (this.state.filesArray) {
            Object.keys(filesArray).map(async (key, i) => {
                filesArray[key].map((ival) => {
                    formData.append(`${key}`, (ival))
                })
            })
        }
        formData.append('oldfiles', JSON.stringify(this.state.oldfiles))
        const id = EditId
        this.setState({ statusbtnDisable: true })
        try {
            const result = await CmsContent.siteintegratedstatus(
                "tbl_Booking_Confirmation_Reproof",
                formData,
                id
            )
            if (result) {
                this.setState({
                    alertVisible: true,
                    textalert: 'Status successfully updated',
                    isEdit: false,
                    statusArray: [{ paymentfrom: "", paymentFrom: '', mop: '', modeofpayment: '', meterialnum: '', amount: null, balAmount: '', paymentdate: '', username: '', userid: '', index: 0, error: null }],
                    tableArray: [{ paymentfrom: "", paymentFrom: '', mop: '', modeofpayment: '', meterialnum: '', amount: null, balAmount: '', paymentdate: '', username: '', userid: '', index: 0, error: null }],
                    Remark: "",
                    followup: "",
                    EditData: {},
                    status1: "",
                    userid: "",
                    color: "success",
                    filesArray: [],
                    statusbtnDisable: false,
                    statusTrue: false
                })
                setTimeout(() => {
                    this.setState({ alertVisible: false });
                    this.props.close('Payment')

                }, 2000)
                this.props.these();
            }
        } catch (error) {
            console.log(error);
            this.setState({ statusbtnDisable: false })
        }
    }
    AddMore = async (i) => {
        let { statusArray } = this.state;

        if (i == 1) {
            statusArray.push({ paymentfrom: "", paymentFrom: '', mop: '', modeofpayment: '', meterialnum: '', amount: null, paymentdate: '', index: 0, error: null })
        } else {
            statusArray.pop()
        }
        this.setState({ statusArray })
    }

    validate = () => {
        const { statusArray } = this.state;
        let lastobject = statusArray ? statusArray[statusArray.length - 1] : null
        if (lastobject) {
            if (lastobject.statusname == 'Contract') {
                return false
            } else return true
        } else return true
    }

    FILEUPLOAD = (e, doc) => {
        let filesArray = this.state.filesArray;
        filesArray[doc] = e.map(fileItem => fileItem.file)
        this.setState({
            filesArray
        });
    };
    PaymentApprovalContract = async (d, text) => {
        console.log(d, "payment d");
        console.log(text, "text");
        // let data = d.original;
        let approvaldatas = {};

        if (text == "yes") {
            approvaldatas.paymentApproval = 1;
        } else {
            approvaldatas.paymentApproval = 0;
        }
        try {
            let results = await cmsContent.updateMaster(
                'tbl_dfcarz_prosperities',
                d.id,
                approvaldatas)
            if (results) {
                window.location.reload()
            }
        }
        catch (error) {
            console.log(error);
        }
    }
    content_Align = async (statusArray) => {
        try {
            const { balanceamount, isEdit, tableArray, EditData } = this.state;
            let Process_COntent = []
            let w1 = await statusArray.map(async (ival, i) => {
                await Process_COntent.push(
                    <React.Fragment>
                        <div className="row form-group">
                            <div className="col-sm-1" />
                            <div className="col-sm-3">
                                <label htmlFor="exampleInputEmail1" >Payment from</label>
                            </div>
                            <div className="col-sm-5">
                                <SingleSelect
                                    placeholder="Select..."
                                    options={this.state.status1options}
                                    handleChange={d => this.handlechangeData(d, 'paymentFrom', i)}
                                    selectedService={ival.paymentFrom}
                                />
                            </div>
                            <div className="col-sm-3" />
                        </div>

                        <div className="row form-group">
                            <div className="col-sm-4" />
                            <span className="error-show ">
                                {this.state.errorstatus}
                            </span>
                        </div>

                        <div className="row form-group">
                            <div className="col-sm-1" />
                            <div className="col-sm-3">
                                <label htmlFor="exampleInputEmail1" >Mode of payment</label>
                            </div>
                            <div className="col-sm-5">
                                <SingleSelect
                                    placeholder="Select..."
                                    options={this.state.status2options}
                                    handleChange={d => this.handlechangeData(d, 'mop', i)}
                                    selectedService={ival.mop}
                                />
                            </div>
                            <div className="col-sm-3" />
                        </div>
                        <div className="row form-group">
                            <div className="col-sm-4" />
                            <span className="error-show ">
                                {this.state.errorstatus}
                            </span>
                        </div>


                        <div className="row form-group">
                            <div className="col-sm-1" />
                            <div className="col-sm-3">
                                <label htmlFor="exampleInputEmail1">Meterial number</label>
                            </div>
                            <div className="col-sm-5">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="meterialnum"
                                    name="Remark"
                                    value={ival.meterialnum}
                                    onChange={(e) => this.handlechangeData(e.target.value, 'meterialnum', i)}
                                />
                            </div>
                            <div className="col-sm-3" />
                        </div>
                        <div className="row form-group">
                            <div className="col-sm-1" />
                            <div className="col-sm-3">
                                <label htmlFor="exampleInputEmail1">Amount</label>
                            </div>
                            <div className="col-sm-5">
                                <input
                                    type="number"
                                    className="form-control"
                                    id="amount"
                                    name="amount"
                                    value={ival.amount}
                                    onChange={(e) => this.handlechangeData(e.target.value, 'amount', i)}
                                />
                            </div>
                            <div className="col-sm-3" >Balance : {balanceamount} ₹</div>

                        </div>
                        <div className="row form-group">
                            <div className="col-sm-1" />
                            <div className="col-sm-3">
                                <label htmlFor="exampleInputEmail1"> Receipt</label>
                            </div>
                            <div className="col-sm-5">
                                <FilePond
                                    files={this.state.filesArray.recipt}
                                    allowReorder={true}
                                    allowMultiple={true}
                                    allowImageResize={true}
                                    imagePreviewMinHeight="50"
                                    imagePreviewMaxHeight="50"
                                //    maxFileSize={'3072KB'}
                                    acceptedFileTypes={["image/*", "application/pdf"]}
                                    onupdatefiles={fileItems => {
                                        this.FILEUPLOAD(fileItems, 'recipt');
                                    }}
                                    labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                />
                                {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label> &nbsp;&nbsp;
                                <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                            </div>
                            <div className="col-sm-3" />
                        </div>
                        {/* <div className="row form-group">
                            <div className="col-sm-4" />
                            <span className="error-show ">
                                {ival.error}
                            </span>
                        </div> */}

                    </React.Fragment>
                )
            })
            await Promise.all(w1)
            await this.setState({ Process_COntent })
        } catch (error) {
            console.log(error);
        }
    }
    contractCancel = async (data, text) => {
        // console.log(data, 'data')
        // console.log(text, 'text');
        if (text == 'yes' && data && data.bookingConfirmationId) {
            let obj = {}
            obj.reasonForCancel = this.state.reasonForCancel
            try {
                const contractDeletion = await CmsContent.contractCancel('tbl_Booking_Confirmation_Reproof', data.bookingConfirmationId, obj)
                console.log(contractDeletion, 'contractDeletion');
                if (contractDeletion && contractDeletion.data) {
                    let msg = contractDeletion.data
                    this.setState({ msg, msgShow: true })
                    setTimeout(() => {
                        this.setState({ msg: "", msgShow: false })
                        window.$('#Payment').modal("hide");
                        this.props.these()
                    }, 2000)

                }

            } catch (error) {
                console.log('error: ', error);
            }
        }
    }
    render() {
        let { balanceamount, status1, isEdit, statusArray, tableArray, contractCancel, EditData, Process_COntent, validate_submit_payment, img, usertypelog } = this.state;
        return (<React.Fragment>
            <main >
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h1>Payment Process</h1>
                                </div>
                                <div className="card-body" style={{ backgroundColor: "#ebedef" }}>
                                    <div className="row form-group">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-7">
                                            <Alert
                                                className="badge-content"
                                                color={this.state.color}
                                                isOpen={this.state.alertVisible}
                                                toggle={this.onDismiss}
                                            >
                                                {this.state.textalert}
                                            </Alert>
                                        </div>
                                        <div className="col-sm-3" />
                                    </div>
                                    {statusArray && statusArray.length && EditData.FormFilled !== "no" ?
                                        <div >
                                            <div className="row " style={{ overflow: "auto" }}>
                                                <table class="table table-bordered">
                                                    <thead style={{ background: '#151E80', color: 'white' }}>
                                                        <tr>
                                                            <th scope="col">no.</th>
                                                            <th scope="col">Payment from</th>
                                                            <th scope="col">Mode of payment</th>
                                                            <th scope="col">Payment date</th>
                                                            <th scope="col">Meterial number</th>
                                                            <th scope="col">Paid Amount</th>
                                                            <th scope="col">Balance Amount</th>
                                                            <th scope="col">Updated by</th>
                                                            <th scope="col">Receipt</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {tableArray && tableArray.map((jval, i) => {
                                                            return (
                                                                < tr >
                                                                    <th scope="row">{jval.index}</th>
                                                                    <td>{jval.paymentfrom}</td>
                                                                    <td>{jval.modeofpayment}</td>
                                                                    <td>{jval.paymentdate}</td>
                                                                    <td>{jval.meterialnum}</td>
                                                                    <td>{jval.amount}</td>
                                                                    <td>{jval.balAmount}</td>
                                                                    <td>{jval.username}</td>
                                                                    <td>{img && img.recipt && i < img.recipt.length - 1 ? img.recipt[i] : "-"}</td>
                                                                </tr>
                                                            )
                                                        })
                                                        }
                                                        < tr >
                                                            <th scope="row">Advance</th>
                                                            <td>{EditData && EditData.finance == "yes" ? "Finance Payment " : "Customer Payment"}</td>
                                                            <td>{EditData && EditData.modeofpayment}</td>
                                                            <td>{EditData && moment(new Date(EditData.payment_date)).format('YYYY-MM-DD')}</td>
                                                            <td>{EditData && EditData.materialNumber}</td>
                                                            <td>{EditData && EditData.advance_amt}</td>
                                                            <td>{EditData && EditData.total && EditData.advance_amt ? EditData.total - EditData.advance_amt : 0}</td>
                                                            <td>{EditData && EditData.userName}</td>
                                                            <td>{img && img.recipt ? img.recipt[img.recipt.length - 1] : "-"}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <h4 className="text-right">Balance Amount :   {balanceamount} ₹</h4>
                                            <h4 className="text-right">Total Price :   {EditData?.total} ₹</h4>
                                        </div>
                                        : null}
                                    {

                                        EditData && EditData.FormFilled == "no" && EditData.paymentApproval == 0 ?
                                            <div className="text-center">
                                                <h3 style={{ color: "red" }}>Please Fill Contract Form </h3>
                                            </div>
                                            :
                                            EditData && isEdit && EditData.FormFilled == "yes" && EditData.paymentApproval == 0 && usertypelog.orderBy != 1 && usertypelog.orderBy != 2
                                                ?
                                                <>
                                                    <div className="text-center">
                                                        <h3 style={{ color: "red" }}>Form filled, waiting for approval</h3>
                                                    </div>

                                                </>
                                                :
                                                EditData && isEdit && EditData.FormFilled == "yes" && EditData.paymentApproval == 0 && usertypelog.orderBy != 8 ?
                                                    <>
                                                        <center>
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm btn-primary"
                                                                style={{ minWidth: "100px" }}
                                                                onClick={() => this.alert_validation(this.PaymentApprovalContract, EditData)}
                                                            >
                                                                Approve
                                                            </button>

                                                        </center>



                                                    </>
                                                    :
                                                    EditData && isEdit && EditData.FormFilled == "yes" && EditData.paymentApproval == 1 ?
                                                        <>
                                                            < br />

                                                            {Process_COntent && Process_COntent}


                                                            <div className="row form-group">
                                                                <div className="col-sm-4" />
                                                                <span className="error-show ">
                                                                    {this.state.statusTrue ? <span>{statusArray.map((ival) => {
                                                                        return (
                                                                            <>
                                                                                {ival.error}
                                                                            </>
                                                                        )
                                                                    })
                                                                    }</span> : <></>}
                                                                </span>
                                                            </div>
                                                            {/* <br /> */}

                                                            {/* {<div className='row'> {(this.state.statusTrue) ? <span>Please fill payment from</span> : <></>}</div>} */}
                                                            <div style={{ color: "red" }} className="text-center">{validate_submit_payment && "You Entered amount is Exceed"}</div>
                                                            <br />
                                                            {isEdit && <div className="row form-group">
                                                                <div className="col-sm-3" />
                                                                <div className="col-sm-6">
                                                                    <center>
                                                                        <button
                                                                            type="button"
                                                                            disabled={!this.state.statusbtnDisable ? validate_submit_payment : true}
                                                                            className={`btn btn-${!this.state.isEdit ? "primary" : "primary"
                                                                                }`}

                                                                            onClick={this.UpdateCategory}
                                                                        >
                                                                            {
                                                                                this.state.statusbtnDisable
                                                                                    ?
                                                                                    <i class="fa fa-circle-o-notch fa-spin"></i>
                                                                                    :
                                                                                    null
                                                                            }
                                                                            &nbsp;
                                                                            Update Status
                                                                        </button>
                                                                        {
                                                                            (usertypelog.orderBy != 8 && !contractCancel) &&
                                                                            <>
                                                                                &nbsp; &nbsp;
                                                                                <button
                                                                                    className="btn btn-danger"
                                                                                    onClick={() => { this.setState({ contractCancel: true }) }}
                                                                                // onClick={() => this.alert_validation(this.contractCancel, EditData)}
                                                                                >
                                                                                    Contract Cancel</button>
                                                                            </>
                                                                        }
                                                                    </center>

                                                                </div>
                                                                <div className="col-sm-3" />
                                                            </div>}
                                                            <br></br>
                                                            {
                                                                (contractCancel)
                                                                    ?
                                                                    <div>
                                                                        <div className="row form-group">
                                                                            <div className="col-sm-1" />
                                                                            <div className='col-sm-3'>
                                                                                <label htmlFor="exampleInputEmail1">Reason</label>
                                                                            </div>
                                                                            <div className="col-sm-5">
                                                                                <textarea
                                                                                    // type="textarea"
                                                                                    className="form-control"
                                                                                    id="reasonForCancel"
                                                                                    name="reasonForCancel"
                                                                                    placeholder="Enter reason for cancellation"
                                                                                    value={this.state.reasonForCancel}
                                                                                    onChange={(e) => this.handleChange(e)}
                                                                                />
                                                                            </div>
                                                                            <div className="col-sm-3">
                                                                                <i class="fa fa-times-circle"
                                                                                    style={{ fontSize: "25px", cursor: 'pointer' }}
                                                                                    onClick={() => { this.setState({ contractCancel: false, reasonForCancel: '' }) }}
                                                                                ></i>
                                                                            </div>
                                                                        </div>

                                                                        <br></br>
                                                                        <center>
                                                                            <button
                                                                                className="btn btn-danger"
                                                                                onClick={() => this.alert_validation(this.contractCancel, EditData)}
                                                                            >
                                                                                Contract Cancel
                                                                            </button>
                                                                        </center>
                                                                        <br></br>
                                                                        <center>
                                                                            <div>
                                                                                {
                                                                                    (this.state.msgShow)
                                                                                        ?
                                                                                        <div style={{ color: 'forestgreen', fontSize: 'medium', fontWeight: 600, backgroundColor: '#AAF7C2', borderRadius: '7px' }}> {this.state.msg}</div>
                                                                                        :
                                                                                        <div></div>

                                                                                }
                                                                            </div>
                                                                        </center>
                                                                    </div>
                                                                    :
                                                                    <></>
                                                            }

                                                        </>
                                                        :
                                                        <div className="text-center">
                                                            <h3 style={{ color: "#07278f" }}>Payment completed</h3>
                                                        </div>
                                    }
                                    {/* <br />
                                    <br />
                                    <div className="row clearfix">
                                        <div className="col-sm-4 " />
                                        {isfollowup && this.validate() ? <div className="col-sm-2">
                                            <button
                                                type="button"
                                                class="btn btn-secondary waves-effect"
                                                onClick={() => this.AddMore(1)}
                                            >
                                                Add More
                                            </button>

                                        </div> : null}
                                        {statusArray && statusArray.length > 1 && <div className="col-sm-2">
                                            <button
                                                type="button"
                                                class="btn btn-warning waves-effect"
                                                onClick={() => this.AddMore(2)}

                                            >
                                                Remove
                                            </button>
                                        </div>}
                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </React.Fragment >
        )
    }
}
export default ContractPayment;