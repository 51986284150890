import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import Datatable from "../../../../components/Datatable/Datatable";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import { confirmAlert } from "react-confirm-alert";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import DfMODELPDF from "../../../../components/PDF/ModelPDF";
import redisMiddleware from "../../../../MiddleWare/redisMiddleware";
import SingleSelect from "../../../../components/Form/SingleSelect";
import CmsContent from "../../../../MiddleWare/CmsContent";

class DfCarsCSVEnquiry extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            customerId: localStorage.getItem("userId"),
            ServiceId: localStorage.getItem("currentService"),
            alertVisible: false,
            color: "",
            textalert: "",
            usertypelog: JSON.parse(localStorage.getItem("usertypelog")),
            ColourName: "",
            errorColourName: "",
            userid: JSON.parse(localStorage.getItem("userlog")),
            isEdit: false,
            disableValue: false,
            loading: false,
            datatable: [],
            MakeOptions: [],
            userlist: [],
            // LocationID: localStorage.getItem(`LocationID`),
            status1options: [
                { value: 0, label: "Scheduled Follow up" },
                { label: "Scheduled Evaluation", value: 1 },
                { value: 2, label: "Mark Closed" },
                // {value:2,label:"Evaluated"},
            ],
            coloropt: [],
            variantopt: [],
            modelopt: []
        };
    }

    column = [

        {
            Header: "ID",
            accessor: "id"
        },
        {
            Header: "Name",
            accessor: "name"
        },
        {
            Header: "Mobile",
            accessor: "mobile"
        },
        {
            Header: "Email",
            accessor: "email"
        },
        {
            Header: "Mobile",
            accessor: "mobile"
        },
        {
            Header: "Sales_Consultant_Name",
            accessor: "salesid",
            Cell: (d) => this.getValueFromArray(d.original.salesid, this.state.userlist)

        },
        {
            Header: "Evalutor Name",
            accessor: "evaluatorid",
            Cell: (d) => this.getValueFromArray(d.original.evaluatorid, this.state.userlist)
        },
        {
            Header: "Type",
            accessor: "type"
        },
        {
            Header: "Location",
            accessor: "locationname"
        },
        // {
        //     Header: "Make",
        //     accessor: "make",
        //     Cell: (d) => this.getValueFromArray(d.original.Make, this.state.MakeOptions)

        // },
        // {
        //     Header: "Model",
        //     accessor: "model",
        //     Cell: (d) => this.getValueFromArray(d.original.Model, this.state.modelopt)
        // },
        // {
        //     Header: "Variant",
        //     accessor: "variant",
        //     Cell: (d) => this.getValueFromArray(d.original.Variant, this.state.variantopt)

        // },
        // {
        //     Header: "Color",
        //     accessor: "color",
        //     Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)

        // },
        {
            Header: "Delete",
            accessor: "mobile",
            minWidth: 120,
            Cell: d => this.Approval(d)
        },

    ];
    MainFun = async () => {
        this.setState({ loading: true })
        try {
            const enquiry = await cmsContent.getFreedom(
                " tbl_dfcarz_enquiryform.*,tbl_location.name as locationname",
                "tbl_dfcarz_enquiryform,tbl_location",
                "companyid = 620 and tbl_location.id = tbl_dfcarz_enquiryform.location",
                1, 'id desc'
            );
            console.log(enquiry);
            if (enquiry) {
                let result1 = await cmsContent.getFreedom(
                    `userName as label , id as value`,
                    "tbl_user_web",
                    "customerid = 620",
                    1, 1
                )
                console.log(result1);
                if (result1) {
                    this.setState({ userlist: result1.data })
                }
                console.log(result1);
                await this.setState({ datatable: enquiry.data, loading: false })
            }
        } catch (error) {
            console.log(error);
        }
    }
    async componentDidMount() {
        await this.MainFun()
    }
    Approval = d => {
        return (
            <button
                type="button"
                className="btn btn-danger"
                onClick={() => this.buttonDeletes(d)}
            >
                Delete
            </button>
        )

    };
    buttonDeletes = d => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return <div style={{ textAlign: "center" }}>
                    <h1>Delete confirmation</h1>
                    <h4>Are you sure you want to Delete this ?</h4><br />
                    <button className="btn btn-primary" onClick={() => {
                        this.Delete(d, 1);
                        onClose();
                    }} >Yes</button> &nbsp;  &nbsp;
                    <button className="btn btn-danger" onClick={onClose} >No</button>
                </div>
            }
        })
    }
    Delete = async d => {
        let deleteid = d.original.id;
        try {
            if (d.original.prosid) {
                let result = await cmsContent.deleteMaster("tbl_dfcarz_enquiryform", deleteid)
                if (result) {
                    let result1 = await cmsContent.deleteMaster("tbl_dfcarz_prosperities", d.original.prosid)
                    if (result1) {
                        this.MainFun()
                    }
                }
            } else {
                let result = await cmsContent.deleteMaster("tbl_dfcarz_enquiryform", deleteid)
                if (result) {
                    this.MainFun()
                }
            }
        }
        catch (err) {
            console.log(err);
        }
    };
    fileupload = e => {
        this.setState({ csv: e.target.files[0] })
    }
    submitcsv = async () => {
        const { usertypelog, status1, status2, status3 } = this.state;
        console.log(usertypelog);
        const files = this.state.csv;
        // var typelabel = usertypelog.orderBy == 9 ? "seller" : "buyer";
        const customerId = this.state.customerId;
        const ServiceId = 10;
        const formData = new FormData();
        formData.append("customerId", customerId);
        formData.append("ServiceId", ServiceId);
        formData.append("file", files);
        console.log([...formData])
        console.log('log');
        try {
            const result = await CmsContent.MultiCSVenquiry("tbl_dfcarz_enquiryform", formData);
            ////console.log(result)
            if (result.data) {
                console.log(result)
                let resname = result.data.resname.toString();
                this.setState({ alertVisible: true, textalert: " Enquiries added Successfully", color: "success", status1: '', status2: '', status3: '', csv: '' })
                setTimeout(() => {
                    this.setState({ alertVisible: false, textalert: "" }); this.MainFun();
                }, 2000);
            }
        }
        catch (error) {
            console.log(error)
        }
    };
    render() {
        const { alertVisible, textalert, loading } = this.state;
        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h1>Model Master</h1>
                                    </div>
                                    <div className="card-body">
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-7">
                                                <Alert
                                                    className="badge-content"
                                                    color={this.state.color}
                                                    isOpen={alertVisible}
                                                    toggle={this.onDismiss}
                                                >
                                                    {textalert}
                                                </Alert>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="exampleInputEmail1">
                                                    Upload Files:
                                                </label>
                                            </div>
                                            <div className="col-sm-5 custom-file">
                                                <input
                                                    type="file"
                                                    className="custom-file-input"
                                                    id="customFile"
                                                    accept='.csv'
                                                    // value={this.state.csv}
                                                    onChange={this.fileupload}
                                                />
                                                <label
                                                    className="custom-file-label"
                                                    htmlFor="customFile"
                                                >
                                                    {this.state.csv ? this.state.csv.name : 'Choose File'}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className="col-sm-4" />
                                            <div className="col-sm-5">
                                                <a href="../../files/AllEnquiry.csv" download="AllEnquiry.csv">
                                                    download sample.csv
                                                </a>
                                            </div>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <div className="row">
                                            <div className="col-sm-3" />
                                            <div className="col-sm-2">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={(e) => this.submitcsv(e)}
                                                    style={{ marginLeft: "250px" }}
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <div className="row form-group">
                                            <div className="col-sm-12">

                                                {loading ? <div className="text-center"> <i
                                                    className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                                                ></i></div> : this.state.datatable.length ? (
                                                    this.state.datatable.length ?
                                                        <Datatable
                                                            data={this.state.datatable}
                                                            columnHeading={this.column}
                                                        /> : null
                                                ) : (<div style={{ textAlign: "center" }} ><b>No Data Found</b></div>)}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}
export default DfCarsCSVEnquiry;
