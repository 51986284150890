import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
//import DfCarsEnquiry from "./DfCarsEnquiry";
import moment from "moment";
import Datatable from "../../../../components/Datatable/Datatable";
import Icon from "react-icons-kit";
import { calendar_ok } from 'react-icons-kit/ikons/calendar_ok'
import { checkmark } from 'react-icons-kit/icomoon/checkmark';
import { confirmAlert } from "react-confirm-alert";

class PocEvaluation extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      customerId: localStorage.getItem("userId"),
      LocationID: localStorage.getItem(`LocationID`),
      userid: JSON.parse(localStorage.getItem("userlog")),
      usertypelog: JSON.parse(localStorage.getItem("usertypelog")),
      EnquiryData: [],
      alertVisible: false,
      column: [
        {
          Header: "Prospect",
          accessor: "name"
        },
        {
          Header: "Contact",
          accessor: "mobile"
        },
        {
          Header: "evaluator name ",
          accessor: "userName"
        },
        {
          Header: "Location",
          accessor: "Location"
        },
        {
          Header: "Evaluation F-proof",
          accessor: "mobile",
          Cell: d => this.ViewLead(d)
        },
        {
          Header: "Evaluation B-proof",
          accessor: "mobile",
          Cell: d => this.ViewLead2(d)
        },
        {
          Header: "Approval",
          accessor: "mobile",
          Cell: d => this.Approval(d)
        },
      ],
      data_for_f: [{ "fill": 0, "view": 0, "Live": 0, "today": 0, "MTD": 0, "ftd": 0 }]
    }
  };

  ViewLead = (d) => {
    return (<button type="button" className="btn btn-primary"
      onClick={() => this.F_proofForm(d)}
    >View Form</button>)
  }

  ViewLead2 = (d) => {
    return (<button type="button" className="btn btn-primary"
      onClick={() => this.F_proofForm3(d)}
    > View Form</button>)
  }

  F_proofForm = async (d) => {
    //console.log(d);
    window.open(`/DFCars/Evaluation_Checklist_F_Proof/${d.original.id}`, '_blank')
  }

  F_proofForm3 = async (d) => {
    //console.log(d);
    window.open(`/DFCars/Evaluation_Checklist_B_Proof/${d.original.id}`, '_blank')
  }

  Approval = (d) => {
    // console.log(d.original)
    if (d.original.F_approval == '1' && d.original.B_approval == '1') {
      //     return(<button type="button" className="btn btn-success"
      // onClick={()=>this.FormApprove(d)}
      //   >Approved</button>)
      return (<span className="label">Approved</span>)
    } else {
      return (<button type="button" className="btn btn-warning"
        onClick={() => this.buttonDeletes(d)}
      >Approve</button>)
    }
  }
  buttonDeletes = d => {
    //console.log(value);
    // confirmAlert({
    //   title: "Confirmation to Approval",
    //   message: "Are you sure you want to approval this?",
    //   buttons: [
    //     {
    //       label: "Yes",
    //       onClick: () => this.FormApprove(d)
    //     },
    //     {
    //       label: "No"
    //     }
    //   ]
    // });
    confirmAlert({
      customUI: ({ onClose }) => {
        return <div style={{ textAlign: "center" }}>
          <h1>Approval confirmation</h1>
          <h4>Are you sure you want to approve this ?</h4><br />
          <button className="btn btn-primary" onClick={() => this.FormApprove(d)} >Yes</button> &nbsp;  &nbsp;
          <button className="btn btn-danger" onClick={onClose} >No</button>
        </div>
      }
    })
  };
  FormApprove = async (d1) => {
    let d = d1.original
    // console.log(d);
    let sub = {};
    if (d.B_approval == 1) {
      sub.approval = 0
    } else {
      sub.approval = 1;
    }
    const result = await cmsContent.updateMaster(
      "tbl_FProof",
      d.Formid,
      sub
    );
    if (result) {
      const result1 = await cmsContent.updateMaster(
        "tbl_BProof",
        d.BFormid,
        sub
      );
      let newData = [...this.state.Data];
      newData[d1.index].F_approval = sub.approval;
      newData[d1.index].B_approval = sub.approval;
      this.setState({
        Data: newData
      })
      window.location.reload();
    }

    //sub.
  }

  async componentDidMount() {

    try {
      // let Result = await cmsContent.getFreedom(
      //   `tbl_dfcarz_prosperities.*,tbl_location.name as Location,tbl_user_web.userName,tbl_FProof.id as F_id,tbl_BProof.id as B_id,tbl_FProof.approval as F_approval,tbl_BProof.approval as B_approval`,
      //   `tbl_dfcarz_prosperities,tbl_FProof,tbl_BProof,tbl_user_web,tbl_location`,
      //   `tbl_dfcarz_prosperities.location = tbl_location.id  and tbl_dfcarz_prosperities.id = tbl_FProof.customerid and tbl_dfcarz_prosperities.id = tbl_BProof.customerid and tbl_BProof.userid = tbl_user_web.id`,
      //   1,
      //   `tbl_dfcarz_prosperities.id DESC`
      // );
      // if (Result) {
      //   console.log(Result);
      //   this.setState({ Data: Result.data });
      // }
      // //  console.log(Result);

      // // let Product = await cmsContent.getFreedom(
      // //     'tbl_dfcarz_prosperities.*,tbl_user_web.userName',
      // //     'tbl_dfcarz_prosperities,tbl_user_web',
      // //       `tbl_user_web.id=tbl_dfcarz_prosperities.userid`,
      // //      `tbl_dfcarz_prosperities.id`,
      // //     `tbl_dfcarz_prosperities.id DESC`
      // //     );
      // //     if(Product){
      // //        // console.log(Product.data)             
      // //        Product.data.map((ival,i)=>{
      // //           ival.F_formfilled='no';
      // //           ival.B_formfilled='no';
      // //        })

      // //     }
      // //  const Fproof = await cmsContent.getFreedom(
      // //     `*`,
      // //     `tbl_FProof`,
      // //     1
      // //  );
      // //    if(Fproof){
      // //     // console.log(Fproof)
      // //       let F_proof =Fproof.data;
      // //       Product.data.map((ival,i)=>{
      // //          F_proof.map((jval,j)=>{
      // //           if(ival.id==jval.customerid && jval.approval=='1'){
      // //             ival.F_formfilled='approved'
      // //           }else  if(ival.id==jval.customerid){
      // //             // console.log(ival)
      // //              ival.F_formfilled='yes';
      // //            }
      // //          })
      // //       })
      // //    }
      // //    const Bproof = await cmsContent.getFreedom(
      // //     `*`,
      // //     `tbl_BProof`,
      // //     1
      // //    );
      // //    if(Bproof){
      // //      //console.log(Bproof)
      // //      Product.data.map((ival,i)=>{
      // //        Bproof.data.map((jval,j)=>{
      // //         if(ival.id==jval.customerid && jval.approval=='1'){
      // //           ival.B_formfilled='approved';
      // //         }
      // //         if(ival.id==jval.customerid ){
      // //            ival.B_formfilled='yes'
      // //          }
      // //        })
      // //      })
      // //    }
      let { data: Stage1_Prospective } = await cmsContent.getFreedom(
        'tbl_dfcarz_prosperities.*,tbl_user_web.userName',
        'tbl_dfcarz_prosperities,tbl_user_web',
        `tbl_user_web.id=tbl_dfcarz_prosperities.userid`,
        `tbl_dfcarz_prosperities.id`,
        `tbl_dfcarz_prosperities.id DESC`
      );
      if (Stage1_Prospective) {
        Stage1_Prospective.map((ival, i) => {
          ival.F_formfilled = 'no';
          ival.B_formfilled = 'no';
        });

        const { data: Fproof } = await cmsContent.getFreedom(
          `*`,
          `tbl_FProof`,
          1,
          // `gotoapproval=1`,
          1,
          1
        );
        if (Fproof) {
          let F_proof = Fproof;
          Stage1_Prospective.map((ival, i) => {
            F_proof.map((jval, j) => {
              if (ival.id == jval.customerid && jval.approval == '1') {
                ival.F_formfilled = 'approved'
                ival.status1 = jval.status1;
                ival.status2 = jval.status2;
                ival.Formid = jval.id
                ival.Auction = jval.Auction
                ival.Bidamount = jval.Bidamount
                ival.regno = jval.reg_no;

              } else if (ival.id == jval.customerid) {
                ival.F_formfilled = 'yes';
                ival.status1 = jval.status1;
                ival.status2 = jval.status2;
                ival.Formid = jval.id
                ival.Auction = jval.Auction
                ival.Bidamount = jval.Bidamount
                ival.regno = jval.reg_no;

              }
            })
          })
        }
        const { data: Bproof } = await cmsContent.getFreedom(
          `*`,
          `tbl_BProof`,
          1,
          1,
          1

        );
        if (Bproof) {
          Stage1_Prospective.map((ival, i) => {
            Bproof.map((jval, j) => {
              if (ival.id == jval.customerid && jval.approval == '1') {
                ival.B_formfilled = 'approved'
                ival.follow = jval.follow
                ival.BFormid = jval.id
              }
              if (ival.id == jval.customerid) {
                ival.B_formfilled = 'yes'
                ival.follow = jval.follow
                ival.BFormid = jval.id
              }
            })
          })
        }

      }

      let v1 = 0;
      let v2 = 0;
      let v3 = 0;
      let data2 = []
      let data3 = []
      await Stage1_Prospective.map((ival, i) => {
        console.log(ival, 'ival');

        if (ival.F_formfilled == "yes") {
          v1 = ++v1;
          data2.push(ival)
        } else if (ival.F_formfilled == "approved") {
          v2 = ++v2
          data3.push(ival)
        } else {
          v3 = ++v3
        }
      })
      let data_for_f = [{ "fill": v1, "today": v2, "Live": v3, }]
      console.log(data_for_f)
      this.setState({
        data_for_f, data2, data3
      })

    } catch (error) {
      console.log(error);
    }
    //  console.log(Product)
  }
  edash = () => {
    return (<div className="container-fluid">
      <div className="card">
        {/* < div className="row" > */}
        <div className="card-header">
          <div className="col-sm-6" >
            <h2 className="mt-2 black">Dashboard</h2>
          </div>
        </div>
        <div className="card-body">
          <div className="row col-12 mt-4 text-center">
            <div className="col-sm-6 ">
              <div className="dashboardCard1" onClick={() => this.onClk(1)} >
                <Icon icon={checkmark} style={{ color: 'green' }} size={35} />
                <h4 className="mt-2 black" >Approved</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b>{this.state.data_for_f[0].today ? this.state.data_for_f[0].today : 0}</b></h1>
                </div>
              </div>
            </div>
            <div className="col-sm-6 ">
              <div className="dashboardCard1" onClick={() => this.onClk(2)} >
                <Icon icon={calendar_ok} style={{ color: 'green' }} size={35} />
                <h4 className="mt-2 black" >Filled</h4>
                <div >
                  <h1 className="bold mt-4 mb-2 black"><b>{this.state.data_for_f[0].fill ? this.state.data_for_f[0].fill : 0}</b></h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
  onClk = (val) => {
    if (val === 1) { this.setState({ Data: this.state.data3 }) }
    if (val === 2) { this.setState({ Data: this.state.data2 }) }
    window.scroll({
      top: 400,
      left: 0,
      behavior: "smooth",
    });
  }

  render() {
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            {this.edash()}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Evaluation Report</h1>
                  </div>
                  <div className="card-body">

                    {this.state.Data.length ? (
                      <Datatable
                        data={this.state.Data}
                        columnHeading={this.state.column}
                      />
                    ) : (<div style={{ textAlign: "center" }} ><b>No Data Found</b></div>)}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    )
  }
}
export default PocEvaluation;