import React,{Component} from 'react';

import { Alert } from "reactstrap";
import LoginModal from "../../../../components/Modal/Modal";
import CmsContent from "../../../../MiddleWare/CmsContent";
import Datatable from "../../../../components/Datatable/Datatable";
import redisMiddleware from "../../../../MiddleWare/redisMiddleware";

export default class Bidform extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
        data:[],
        makeoptions:[],
        modeloptions:[],
        voptions:[],
        coptions:[],
        alertVisible:false,
        customerId: localStorage.getItem("userId"),
        ServiceId: JSON.parse(localStorage.getItem("userlog")),
        bidoptions:[],
            column:[
            {
                Header: "Make",
                accessor: "make",
                Cell: (d)=> this.getValueFromArray(d.original.make,this.state.makeoptions)
                
                
            },
            {
                Header: "Model",
                accessor: "model",
                Cell: (d)=> this.getValueFromArray(d.original.model,this.state.modeloptions)
            },
            {
                Header: "Variant",
                accessor: "variant",
                Cell: (d)=> this.getValueFromArray(d.original.variant,this.state.voptions)
                
            },
            {
                Header: "Color",
                accessor: "color",
                Cell: (d)=> this.getValueFromArray(d.original.color,this.state.coptions)
                
            },
            {
                Header: "Bid Amount",
                accessor: "Bidamount"
            },
            {
                Header: "Year Of Manufacture",
                accessor: "year_of_manu"
            },
            {
                Header: "View Form",
                accessor: "viewmodel",
                Cell:(d)=>this.formview(d)
            },
            {
                Header: "Total Bid",
                accessor: "total",
             
            },
            {
              Header: "View Bids",
              accessor: "total",
            Cell: (d)=>this.auction(d)
          },
                      
        ],
    }}

async componentDidMount(){
    let Cus = [];
    if (this.props.fid) {
      // this.setState({ CustomerId: this.props.cusid })
      Cus = this.props.fid;
    } else if (this.props.match.params.fid) {
      // this.setState({ CustomerId: this.props.match.params.cusid });
      Cus = this.props.match.params.fid;
    }
   await  this.setState({Cus})
//    console.log(thi.state.Cus);s
}

    onDismiss = () => {
        this.setState({ alertVisible: false, formAlertdelete: false });
        };
   submitauction = async d=>{
                        const{amount,customerId,
                            ServiceId
                        } =this.state;
                        //console.log(d);
                       // let id =await d.original.id;
        
                       // const fproofid = await CmsContent.getFreedom("*", "tbl_FProof", `customerid=${id}`, 1, 1);
                        let CategoryArray={};
                        CategoryArray.new_bid_amount=parseInt(amount);
                        CategoryArray.customerid=ServiceId.id;
                        CategoryArray.fproof_id=this.state.Cus;
                        CategoryArray.ServiceId=ServiceId.serviceId;
                        console.log(CategoryArray);
                        try {
                        let result = await CmsContent.addMaster(
                            "tbl_bid",
                            CategoryArray
                        );
                        if(result){
                            this.setState({
                            amount:"",
                            alertVisible:true,
                            color:"success",
                            textalert:"Your Bid has been submitted successfully",
                            
                            })
                        } 
                        setTimeout(() => this.setState({ alertVisible: false }), 3000);
                        window.scroll({
                          top: 0,
                          left: 0,
                          behavior: "smooth"
                        });
                        }catch (error) {
                            console.log(error);
                        }
        
        }
        handlechangeData = async e => {
                    //  if(e.target.type=="date"){
                    console.log(e.target.value);
                    //  }
                    console.log(e.target.name);
                    this.setState({ [e.target.name]: e.target.value });
        };
    render(){
        return(
            <main className="main my-4">
            <div className="card-body">
            <div className="row form-group">
                                  <div className="col-sm-2" />
                                  <div className="col-sm-7">
                                    <Alert
                                      className="badge-content"
                                      color={this.state.color}
                                      isOpen={this.state.alertVisible}
                                      toggle={this.onDismiss}
                                    >
                                      {this.state.textalert}
                                    </Alert>
                                  </div>
                                  <div className="col-sm-3" />
                                </div>
        
                      <div className="row form-group">
                        <div className="col-sm-1" />
                        <div className="col-sm-3">
                          <label htmlFor="exampleInputEmail1">
                           Bid Amount
                          </label>
                        </div>
                        <div className="col-sm-5">
                               <input
                                  type="text"
                                  className="form-control"
                                 // id="registerName"
                                  name="amount"
                                  placeholder="Enter Amount"
                                  value={this.state.amount}
                                  onChange={this.handlechangeData}
                                />
                        </div>
                        <div className="col-sm-3" />
                        <br/>
                        <div className="row-form-group">
                       
                            <div className="col-sm-2" />
                            <div className="col-sm-3" />
                            <div className="col-sm-4">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => this.submitauction()}
                                style={{ marginLeft: "250px" }}
                              >
                                Submit
                            </button>
                            </div>
                          </div>
                      </div>
                      </div>
         </main>
        )
    }
}