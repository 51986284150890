import React, { Component } from 'react';
import { Alert } from "reactstrap";
import LoginModal from "../../../../components/Modal/Modal";
import CmsContent from "../../../../MiddleWare/CmsContent";
import Datatable from "../../../../components/Datatable/Datatable";
import redisMiddleware from "../../../../MiddleWare/redisMiddleware";
export default class Teledashboard extends React.Component {
    render() {
        return (<main className="main my-4">
            <div>
                <h1>TeleCaller</h1>
            </div>
        </main>)
    }
}