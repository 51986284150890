
import React, { Component } from "react";
import SingleSelect from "../../../components/Form/SingleSelect";
import MultiSelect from "../../../components/Form/MultiSelect";
import CmsContent from "../../../MiddleWare/CmsContent";
import LoginModal from "../../../components/Modal/Modal";
import Addauthormodel from "./Addauthormodel";
import { Alert } from "reactstrap";
import { ACCESS_SAMPLE } from "../../../config";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import AddpagePDF from "../../../components/PDF/Addpagespdf";
import moment from 'moment';

//import Theme9 from './addPages/theme9';
import Datatable from "../../../components/Datatable/Datatable";
import { FormMiddleWare } from "../../../components/Form";
import { confirmAlert } from "react-confirm-alert";
import Paginationfun from "../pagination";
class DealerReport extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            SubmitBtn: false,
            SDCode: false,
            loading: false,
            dealerDisable: true,
            MODisable: true,
            selectedstartdateDisable: true,
            selectedenddateDisable: true,
            dealerOptions: [],
            FullDealerData: [],
            MOlist: [],
            SalesLocation: [],
            DatatableData: [],
            Sales: {},
            MO: {},
            totalrecords: '',
            selectedDealer: [],
            customerId: localStorage.getItem('userId'),
        };
    }
    column = [];

    async componentDidMount() {
        this.MainFun()
    }
    MainFun = async () => {
        try {
            let dealers = await CmsContent.GetCmdlDatas(this.state.customerId, "DealerReport")

            console.log(dealers, 'dealers')
            await this.setState({ loading: true })
            if (dealers && dealers.DealerOption) {
                await this.setState({
                    dealerOptions: dealers.DealerOption,
                    MOlist: dealers.MO,
                    SalesLocation: dealers.SalesLocation,
                    totalrecords: dealers.totalrecords,
                    FullDealerData: dealers.Fulldata,
                })
                if (this.state && this.state.dealerOptions) {
                    await this.setState({ loading: false })
                }


            }
        } catch (error) {
            console.log(error);
            await this.setState({ loading: false })
        }
    }
    column = [
        {
            Header: "Dealer Name",
            accessor: "dealerName",
        },
        {
            Header: "Dealer Code",
            accessor: "dealerCode",
        },
        {
            Header: "Course Name",
            accessor: "courseName",
        },
        {
            Header: "Language",
            accessor: "language"
        },
        {
            Header: "Watched Count",
            accessor: "videoSeenCount",
        },
        {
            Header: "Last Seen",
            accessor: "LastSeen",
        },

    ]
    optionSelect = async (selectedOption, name) => {
        let { Sales, FullDealerData, dealerOptions } = this.state
        // console.log(selectedOption, "selectedOption", name);
        if (name == 'Sales') {
            await this.setState({
                [name]: selectedOption, MODisable: false
            });
        } else if (name == 'MO') {
            await this.setState({ [name]: selectedOption });
            setTimeout(() => {
                if (this.state.MO && this.state.MO.value) {
                    dealerOptions = FullDealerData.filter((ival) => { return parseInt(ival.moid) == parseInt(this.state.MO.value) && ival.salesid == Sales.sales_code })
                    // console.log(dealerOptions, 'dealerOptions', dealerOptions.length)
                    this.setState({ dealerOptions, dealerDisable: false })
                }
            }, 500)
        }
        else if (name == 'selectedDealer') {
            
            await this.setState({ [name]: selectedOption, selectedstartdateDisable: false, selectedenddateDisable: false });
        }
        else {
            await this.setState({
                [name]: selectedOption
            });
        }

    };

    submitdata = async () => {
        let { selectedDealer, selectedstartdate, selectedenddate, DatatableData } = this.state
        // validation
        if (selectedstartdate == undefined && selectedstartdate == undefined) {
            alert("Must fill all fields")
        }

        let object = {}
        object.selectedDealer = selectedDealer
        object.selectedstartdate = selectedstartdate
        object.selectedenddate = selectedenddate
        console.log(object, "object");
        let submit = await CmsContent.GetCmdlDatas(this.state.customerId, "DealerDetails", object)

        console.log(submit.Details, "API result");
        let datas = submit.Details;

        DatatableData = []
        for (let i = 0; i < datas.length; i++) {
            let index = datas[i];
            // console.log(index, "index");
            if (index.length > 0) {
                let group = this.groupByArray(index, 'pageid')
                console.log(group, 'group');
                for (let ival of group) {
                    let obj = {}
                    ival.sort((a, b) => { return b.createdAt - a.createdAt })
                    console.log(ival, 'ival')
                    obj.dealerName = ival[0].dealer_name
                    obj.dealerCode = ival[0].dealer_code
                    obj.language = ival[0].language
                    obj.videoSeenCount = ival.length
                    obj.courseName = ival[0].pageName
                    obj.LastSeen = moment(ival[0].createdAt).format('DD-MM-YYYY hh:mm a')
                    DatatableData.push(obj)

                }
            }
            else {
                await this.setState({ loading: true })
            }

        }
        console.log(DatatableData, 'DatatableData')
        this.setState({ DatatableData, loading: false })

        // let datas1 = this.groupByArray(submit.Details, 'pageid')
        // console.log(datas, 'datas')

        // DatatableData = []

        // for (let index = 0; index < datas.length; index++) {
        //     let element = datas[index];
        //     let obj = {}
        //     element.sort((a, b) => { return b.createdAt - a.createdAt })
        //     // obj.from = element[0].selectedstartdate
        //     // obj.to = element[0].selectedenddate
        //     obj.dealerName = element[0].dealer_name
        //     obj.dealerCode = element[0].dealer_code
        //     obj.language = element[0].language
        //     obj.videoSeenCount = element.length
        //     obj.courseName = element[0].pageName
        //     // obj.LastSeen = element[0].createdAt
        //     // obj.LastSeen = moment(element[0].createdAt).format('DD-MM-YYYY hh:mm:ss a')
        //     // console.log(moment(element[0].createdAt).format('DD-MM-YYYY hh:mm:ss a'), "moment( element[0].createdAt).format('DD-MM-YYYY hh:mm:SS a')");
        //     DatatableData.push(obj, "obj")
        // }
        // console.log(DatatableData, 'DatatableData')
        // this.setState({ DatatableData, loading: false })
        // // }

    }
    fromDateSelect = (e) => {
        this.setState({ selectedstartdate: e.target.value });
        // console.log(this.state.selectedstartdate);
    };
    endDateSelect = async (e) => {
        this.setState({ selectedenddate: e.target.value, SubmitBtn: true });
        // console.log(e.target.value);
    };

    groupByArray = (array, key) => {
        return Array.from(array
            .reduce((m, o) => m.set(o[key], [...(m.get(o[key]) || []), o]), new Map)
            .values()
        );
    }
    render() {
        let { dealerOptions, selectedDealer, MOlist, MO, dealerDisable, SalesLocation, Sales, MODisable, DatatableData, selectedstartdateDisable, selectedenddateDisable,selectedstartdate } = this.state
        // console.log(selectedDealer, 'selectedDealer')
        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3>Dealer Report</h3>
                                    </div>
                                    <div className="card-body">

                                        <div className="">
                                            <div className="row form-group">

                                                <div className="col-sm-2" />
                                                <div className="col-sm-2">

                                                    <label htmlFor="exampleInputEmail1"><span style={{ color: 'red' }}>*</span>&nbsp;Sales District Code</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <SingleSelect
                                                        options={SalesLocation}
                                                        handleChange={(e) => this.optionSelect(e, 'Sales')}
                                                        selectedService={Sales}

                                                    />
                                                    <span style={{ color: 'red' }} >{this.state.SalesErr && this.state.SalesErr}</span>

                                                </div>
                                                <div className="col-sm-2">
                                                    {
                                                        Sales && Sales.sales_code &&
                                                        <i
                                                            class="fa fa-times-circle"
                                                            style={{ fontSize: "25px" }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    Sales: {},
                                                                    MODisable: true,
                                                                    MO: {},
                                                                    dealerDisable: true,
                                                                    selectedstartdateDisable: true,
                                                                    selectedenddateDisable: true,
                                                                    selectedDealer: [],
                                                                    dealerOptions: [],
                                                                    selectedstartdate: {},
                                                                    selectedenddate: {},
                                                                    SubmitBtn: false
                                                                })
                                                            }}>
                                                        </i>

                                                    }

                                                </div>
                                            </div>
                                            <div className="row form-group">
                                                <div className="col-sm-2" />
                                                <div className="col-sm-2">
                                                    <label htmlFor="exampleInputEmail1"><span style={{ color: 'red' }}>*</span>&nbsp;Marketing officer</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <SingleSelect
                                                        disabled={MODisable}
                                                        options={MOlist}
                                                        handleChange={(e) => this.optionSelect(e, 'MO')}
                                                        selectedService={MO}

                                                    />
                                                </div>
                                                <div className="col-sm-2">
                                                    {
                                                        MO && MO.value &&
                                                        <i
                                                            class="fa fa-times-circle"
                                                            style={{ fontSize: "25px" }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    MO: {},
                                                                    dealerDisable: true,
                                                                    selectedDealer: [],

                                                                    dealerOptions: [],
                                                                    selectedstartdateDisable: true,
                                                                    selectedenddateDisable: true,
                                                                    selectedstartdate: {},
                                                                    selectedenddate: {},
                                                                    SubmitBtn: false
                                                                })
                                                            }}>
                                                        </i>
                                                    }

                                                </div>
                                            </div>

                                            <div className="row form-group">
                                                <div className="col-sm-2" />
                                                <div className="col-sm-2">
                                                    <label for="exampleInputEmail1"><span style={{ color: 'red' }}>*</span>&nbsp;Select Dealer</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <MultiSelect
                                                        handleChange={(e) => { this.optionSelect(e, 'selectedDealer') }}
                                                        disabled={dealerDisable}
                                                        options={dealerOptions}
                                                        selectedService={selectedDealer}
                                                        

                                                    />
                                                    {/* <div class="validation">{this.state.selectedDealerErr&&this.state.selectedDealerErr}</div> */}
                                                </div>
                                                <div className="col-sm-2">
                                                    {
                                                        selectedDealer && selectedDealer.length != 0 &&
                                                        <i
                                                            class="fa fa-times-circle"
                                                            style={{ fontSize: "25px" }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    selectedDealer: [],
                                                                    selectedstartdateDisable: true,
                                                                    selectedenddateDisable: true,
                                                                    selectedstartdate: {},
                                                                    selectedenddate: {},
                                                                    SubmitBtn: false
                                                                })
                                                            }}>
                                                        </i>
                                                    }

                                                </div>
                                            </div>
                                            <br></br>

                                            <div className="row form-group">
                                                <div className="col-sm-2" />
                                                <div className="col-sm-2"><span style={{ color: 'red' }}>*</span>&nbsp;Date</div>
                                                <div className="col-sm-2" >
                                                    From Date
                                                    <input
                                                        type="date"
                                                        id="myDate"
                                                        disabled={selectedstartdateDisable}
                                                        className="form-control"
                                                        onChange={this.fromDateSelect}
                                                        value={this.state.selectedstartdate}

                                                    />
                                                    <span className="error-shows">
                                                        {this.state.startdateerror}
                                                    </span>
                                                </div>
                                                {/* <div className="col-sm-1"></div> */}
                                                <div className="col-sm-2" >
                                                    To Date
                                                    <input
                                                        type="date"
                                                        disabled={selectedenddateDisable}
                                                        className="form-control "
                                                        onChange={this.endDateSelect}
                                                        value={this.state.selectedenddate}


                                                    />
                                                    <span className="error-shows">
                                                        {this.state.endateerror}
                                                    </span>
                                                </div>
                                                <div className="col-sm-2">
                                                    {
                                                        selectedstartdate && selectedstartdate.length>0 &&
                                                        <i
                                                            class="fa fa-times-circle"
                                                            style={{ fontSize: "25px" }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    selectedstartdate: {},
                                                                    selectedenddate: {},
                                                                    SubmitBtn: false
                                                                })
                                                            }}>
                                                        </i>
                                                    }

                                                </div>
                                                <div className="col-sm-2 error-shows"> </div>
                                            </div>

                                            <div className="row form-group">
                                                <div className="col-sm-5" />
                                                {/* <div className="col-sm-2" /> */}
                                                <div className="col-sm-4">
                                                    {this.state.SubmitBtn && selectedDealer.length ?
                                                        <>
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary "
                                                                onClick={this.submitdata}
                                                            >
                                                                Submit
                                                            </button>
                                                        </> :
                                                        <>
                                                        </>}

                                                    &nbsp;&nbsp;
                                                    <button
                                                        type="button"
                                                        className="btn btn-warning "
                                                        onClick={() => {
                                                            this.setState({
                                                                Sales: {},
                                                                MODisable: true,
                                                                MO: {},
                                                                dealerDisable: true,
                                                                selectedstartdateDisable: true,
                                                                selectedenddateDisable: true,
                                                                selectedDealer: [],
                                                                dealerOptions: [],
                                                                DatatableData: [],
                                                                selectedstartdate: {},
                                                                selectedenddate: {},
                                                                SubmitBtn: false
                                                            })
                                                        }}>
                                                        Reset
                                                    </button>
                                                </div>
                                                {/* <div className="col-sm-2" >
                                                    
                                                </div> */}
                                            </div>
                                            {/* <span>
                            {tableData && `Total of ${Object.keys(this.state.tableData).length} records`}
                        </span> */}
                                            <br></br>
                                            <div className="row form-group">
                                                <div className="col-sm-12">
                                                    {this.state.loading
                                                        ?

                                                        <div style={{ marginLeft: "600px", fontWeight: "900", height: "30px", fontSize: "larger", marginTop: "50px" }}> No data to Found </div>

                                                        // this.setState({ loading: false })
                                                        // <div className="text-center">
                                                        //     < i className="fa fa-spinner fa-2x fa-spin" style={{ color: "blueviolet" }} >
                                                        //     </i>
                                                        // </div>
                                                        :
                                                        DatatableData && DatatableData.length > 0
                                                            ?
                                                            (
                                                                <Datatable
                                                                    data={DatatableData}
                                                                    columnHeading={this.column}
                                                                />

                                                            )
                                                            :
                                                            <div style={{ marginLeft: "580px", fontWeight: "600", height: "30px", fontSize: "larger", marginTop: "50px" }}> No data to show </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </main>
            </React.Fragment>

        )
    }
}

export default DealerReport;
