import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import UpdateStatus from "../SalesMaster/UpdateStatus";
import moment from "moment";
import LoginModal from "../../../../components/Modal/Modal";
import UsertypeComments from "../UserMaster/UsertypeComments";
import Datatable from "../../../../components/Datatable/Datatable";
import SingleSelect from "../../../../components/Form/SingleSelect";
import MultiSelect from "../../../../components/Form/MultiSelect";

class DfCarsproductsearch extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            SearchByList: [{ label: "Location", value: 7 }, { label: "Document", value: 1 }, { label: "Transaction", value: 2 }],
            DocumentByList: [{ label: "Register Number", value: 1 }, { label: "Insurance Policy Number", value: 2 }, { label: "RC Book Number", value: 3 }],
            mobile: "",
            TransactionByList: [{ label: "Pipeline", value: 1 }, { label: "Inventory", value: 2 }, { label: "Sold", value: 3 }],
            searchby: {},
            User: JSON.parse(localStorage.getItem("userlog")),
            SearchResult: [],
            LocationList: [],
            selectedService: [],
            MakeList: [],
            selectedmake: {},
            ModelList: [],
            selectedmodel: {},
            ColorList: [],
            selectedcolor: {},
            subdocument: {},
            subtransaction: {},
            column: [
                {
                    Header: "Make",
                    accessor: "makename"
                },
                {
                    Header: "Model",
                    accessor: "modelname"
                },
                {
                    Header: "Make Year",
                    accessor: "make_year"
                },
                {
                    Header: "Location",
                    accessor: "LocationName"
                },
                {
                    Header: "Colour",
                    accessor: "ColourName"
                },
                {
                    Header: "Status",
                    accessor: "status"
                },
                {
                    Header: "Price",
                    accessor: "price"
                },
                {
                    Header: "View More",
                    accessor: "price",
                    Cell: (d) => this.Viewmore(d)
                },

            ]
        }
    }

    Viewmore = (d) => {
        return (
            <button type="button" className="btn btn-primary"
                onClick={() => this.showButton(d)}
            >View</button>
        )
    }

    showButton = async (d) => {
        console.log(d.original)
        window.open(`/DFCars/DfcarsproductViewmore/${d.original.id}`)
    }

    HandleChange = async (e) => {
        this.setState({ [e.target.name]: e.target.value });

    }

    handleMultiLocation = async (e) => {
        //  console.log(e);
        this.setState({ selectedService: e });
    }

    async componentDidMount() {

        // c o n s o l e . l o g( t h i s . s t a t e .U s e r ) ;

    }


    handleModel = async (e) => {
        console.log(e)
        this.setState({ searchby: e, SearchResult: [] });
        if (e.value == 7) {
            this.getLocation(e);
        } else if (e.value == 1) {
            this.getDocument();
        }

    }


    getDocument = async () => {

    }

    handleMakeList = async (e) => {
        this.setState({ selectedmake: e, selectedmodel: {}, SearchResult: [] });
        let Model = await cmsContent.getFreedom(
            `Model as label , id as value`,
            `tbl_dfcarsmodel`,
            `serviceid = 10 and makeid=${e.value}`
        );
        //and customerId =${this.state.User.customerId}
        if (Model) {
            console.log(Model);
            this.setState({ ModelList: Model.data });
        }

    }



    handleModelList = async (e) => {
        const { selectedmake } = this.state;

        this.setState({ selectedmodel: e, SearchResult: [] });
        //  let Model = await cmsContent.getFreedom(
        //     `Model as label , id as value`,
        //     `tbl_dfcarsmodel`,
        //     `serviceid = 10 and makeid=${e.value}`,
        //     1,
        //         `id DESC`
        // );
    }

    handleColorList = async (e) => {
        this.setState({ selectedcolor: e })
    }

    getLocation = async (e) => {

        let Result = [];
        Result = await cmsContent.getFreedom(
            `name as label , id as value`,
            `tbl_location`,
            `serviceid = 10 `,
            1,
            `id DESC`
        );
        //and customerId =${this.state.User.customerId}
        if (e.value == 7) {


            let Make = await cmsContent.getFreedom(
                `make as label , id as value`,
                `tbl_make_master`,
                `serviceid = 10 `,
                1,
                `id DESC`
            );
            //and customerId =${this.state.User.customerId}


            let Colour = await cmsContent.getFreedom(
                `colour as label,id as value`,
                `tbl_colour`,
                `serviceid = 10`,
                1,
                `id DESC`
            );
            this.setState({
                selectedcolor: {},
                selectedmodel: {}, selectedmake: {}
                , MakeList: Make.data, ColorList: Colour.data
            });
        }
        this.setState({ LocationList: Result.data })
    }

    handlesubdocument = async (e) => {
        this.setState({ subdocument: e });
    }

    handlesubtransaction = async (e) => {
        this.setState({ subtransaction: e });
        let Result = [];
    }

    ProductSearch = async () => {
        try {
            const { searchby, selectedcolor, selectedService, selectedmodel, selectedmake } = this.state;
            let Conditon = ""
            selectedService.map((ival, i) => {
                if (i == 0) {
                    Conditon = `tbl_dfcarz_prosperities.location= ${ival.value} ${" "}`;
                } else {
                    Conditon = Conditon + `OR tbl_dfcarz_prosperities.location = ${ival.value} ${" "}`;
                }
            });
            ///console.log(selectedService);
            // console.log(Conditon);
            let Result = [];
            if (searchby.value == 7) {
                //console.log(selectedService);
                let Result1 = await cmsContent.getFreedom(
                    `tbl_items.*,tbl_make_master.Make as makename,tbl_dfcarsmodel.Model as modelname,tbl_colour.colour as ColourName`,
                    `tbl_items,tbl_make_master,tbl_dfcarsmodel,tbl_colour`,
                    `tbl_items.make=tbl_make_master.id and tbl_dfcarsmodel.id = tbl_items.model and tbl_colour.id = tbl_items.colour`,
                    1,
                    `tbl_items.id DESC`
                );

                Result = await cmsContent.getFreedom(
                    `tbl_items.*,tbl_dfcarz_customers.customer,tbl_dfcarz_prosperities.location as locationID,tbl_dfcarz_prosperities.id as prosid`,
                    `tbl_dfcarz_customers,tbl_items,tbl_dfcarz_prosperities`,
                    `${Conditon} and tbl_dfcarz_customers.productid = tbl_items.id and tbl_dfcarz_prosperities.id = tbl_dfcarz_customers.prospectid `,
                    1,
                    `tbl_items.id DESC`
                );
                ////and tbl_location.id = tbl_dfcarz_prosperities.location //,tbl_location.name as LocationName
                ///
                if (Result) {
                    let wait = await Result.data.map(async (ival, i) => {
                        Result1.data.map((jval, j) => {
                            if (ival.id == jval.id) {
                                ival.makename = jval.makename;
                                ival.modelname = jval.modelname;
                                ival.ColourName = jval.ColourName;
                            }
                        })
                    });
                    await Promise.all(wait);
                    let wait2 = await Result.data.map(async (ival, i) => {
                        selectedService.map((jval, j) => {
                            if (ival.locationID == jval.value) {
                                ival.LocationName = jval.label;
                                ival.status = ival.status ? ival.status : 'active';
                            }
                        })
                    });
                    await Promise.all(wait2);
                    if (selectedmake.label && selectedmodel.label && selectedcolor.label) {

                        let d = [];
                        let wait5 = await Result.data.map(async (ival, i) => {
                            if (ival.make == selectedmake.value && ival.colour == selectedcolor.value && ival.model == selectedmodel.value) {
                                d.push(ival);
                            }
                        });
                        await Promise.all(wait5);
                        this.setState({ SearchResult: d });
                    } else if (selectedmake.label && selectedmodel.label) {

                        let d = [];
                        let wait4 = await Result.data.map(async (ival, i) => {
                            if (ival.make == selectedmake.value && ival.model == selectedmodel.value) {
                                d.push(ival);
                            }
                        });
                        await Promise.all(wait4);
                        this.setState({ SearchResult: d });

                    } else if (selectedmake.label) {
                        let d = [];
                        let wait3 = await Result.data.map(async (ival, i) => {
                            if (ival.make == selectedmake.value) {
                                d.push(ival);
                            }
                        });
                        await Promise.all(wait3);
                        this.setState({ SearchResult: d });
                    } else {
                        this.setState({ SearchResult: Result.data })
                    }
                    console.log(Result.data);

                }
            } else if (searchby.value == 2) {
                this.Transactiontype();
            } else if (searchby.value == 1) {
                this.DocumentType();
            }

        } catch (error) {
            console.log(error);
        }
    }

    DocumentType = async () => {
        const { searchby, mobile, subdocument, LocationList } = this.state;
        try {
            let Result = [];
            if (subdocument.value == 1) {
                let Result1 = await cmsContent.getFreedom(
                    `tbl_items.*,tbl_make_master.Make as makename,tbl_dfcarsmodel.Model as modelname,tbl_colour.colour as ColourName`,
                    `tbl_items,tbl_make_master,tbl_dfcarsmodel,tbl_colour`,
                    `tbl_items.make=tbl_make_master.id and tbl_dfcarsmodel.id = tbl_items.model and tbl_colour.id = tbl_items.colour`,
                    1,
                    `tbl_items.id DESC`
                );

                Result = await cmsContent.getFreedom(
                    `tbl_items.*,tbl_dfcarz_customers.customer,tbl_dfcarz_prosperities.location as locationID`,
                    `tbl_dfcarz_customers,tbl_items,tbl_dfcarz_prosperities`,
                    `tbl_items.Reg_no = '${mobile}' and tbl_dfcarz_customers.productid = tbl_items.id and tbl_dfcarz_prosperities.id = tbl_dfcarz_customers.prospectid `,
                    1,
                    `tbl_items.id DESC`
                );
                ////and tbl_location.id = tbl_dfcarz_prosperities.location //,tbl_location.name as LocationName
                ///
                if (Result) {
                    console.log(Result);
                    let wait = await Result.data.map(async (ival, i) => {
                        Result1.data.map((jval, j) => {
                            if (ival.id == jval.id) {
                                ival.makename = jval.makename;
                                ival.modelname = jval.modelname;
                                ival.ColourName = jval.ColourName;
                            }
                        })
                    });
                    await Promise.all(wait);
                    console.log(LocationList);
                    let wait3 = await Result.data.map(async (ival, i) => {
                        LocationList.map(async (jval, j) => {
                            //console.log(jval)
                            if (ival.locationID == jval.value) {
                                ival.LocationName = jval.label;
                                ival.status = ival.status ? ival.status : 'active';
                            }
                        })
                    });
                    await Promise.all(wait3);

                    this.setState({ SearchResult: Result.data });
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    Transactiontype = async () => {
        const { subtransaction, LocationList } = this.state;
        try {
            let Result = [];
            if (subtransaction.value == 3) {
                let Result1 = await cmsContent.getFreedom(
                    `tbl_items.*,tbl_make_master.Make as makename,tbl_dfcarsmodel.Model as modelname,tbl_colour.colour as ColourName`,
                    `tbl_items,tbl_make_master,tbl_dfcarsmodel,tbl_colour`,
                    `tbl_items.make=tbl_make_master.id and tbl_dfcarsmodel.id = tbl_items.model and tbl_colour.id = tbl_items.colour`,
                    1,
                    `tbl_items.id DESC`
                );

                Result = await cmsContent.getFreedom(
                    `tbl_items.*,tbl_dfcarz_customers.customer,tbl_dfcarz_prosperities.location as locationID`,
                    `tbl_dfcarz_customers,tbl_items,tbl_dfcarz_prosperities`,
                    `tbl_items.status = 'inactive' and tbl_dfcarz_customers.productid = tbl_items.id and tbl_dfcarz_prosperities.id = tbl_dfcarz_customers.prospectid `,
                    1,
                    `tbl_items.id DESC`
                );
                ////and tbl_location.id = tbl_dfcarz_prosperities.location //,tbl_location.name as LocationName
                ///
                if (Result) {
                    let wait = await Result.data.map(async (ival, i) => {
                        Result1.data.map((jval, j) => {
                            if (ival.id == jval.id) {
                                ival.makename = jval.makename;
                                ival.modelname = jval.modelname;
                                ival.ColourName = jval.ColourName;
                                ival.status = ival.status ? ival.status : 'active';
                            }
                        })
                    });
                    await Promise.all(wait);
                    let wait2 = await Result.data.map(async (ival, i) => {
                        LocationList.map((jval, j) => {
                            if (ival.locationID == jval.value) {
                                ival.LocationName = jval.label;
                                ival.status = ival.status ? ival.status : 'active';
                            }
                        })
                    });
                    await Promise.all(wait2);
                    console.log(Result.d);
                    this.setState({ SearchResult: Result.data })
                }

            } else if (subtransaction.value == 2) {
                let Result1 = await cmsContent.getFreedom(
                    `tbl_items.*,tbl_make_master.Make as makename,tbl_dfcarsmodel.Model as modelname,tbl_colour.colour as ColourName`,
                    `tbl_items,tbl_make_master,tbl_dfcarsmodel,tbl_colour`,
                    `tbl_items.make=tbl_make_master.id and tbl_dfcarsmodel.id = tbl_items.model and tbl_colour.id = tbl_items.colour`,
                    1,
                    `tbl_items.id DESC`
                );

                Result = await cmsContent.getFreedom(
                    `tbl_items.*,tbl_dfcarz_customers.customer,tbl_dfcarz_prosperities.location as locationID`,
                    `tbl_dfcarz_customers,tbl_items,tbl_dfcarz_prosperities`,
                    `tbl_dfcarz_customers.productid = tbl_items.id and tbl_dfcarz_prosperities.id = tbl_dfcarz_customers.prospectid `,
                    1,
                    `tbl_items.id DESC`
                );
                ////and tbl_location.id = tbl_dfcarz_prosperities.location //,tbl_location.name as LocationName
                ///
                if (Result) {
                    let wait = await Result.data.map(async (ival, i) => {
                        Result1.data.map((jval, j) => {
                            if (ival.id == jval.id && ival.status != 'inactive') {
                                ival.makename = jval.makename;
                                ival.modelname = jval.modelname;
                                ival.ColourName = jval.ColourName;
                                ival.status = ival.status ? ival.status : 'active';
                            }
                        })
                    });
                    console.log(Result.data);
                    await Promise.all(wait);
                    let wait2 = await Result.data.map(async (ival, i) => {
                        LocationList.map((jval, j) => {
                            if (ival.locationID == jval.value) {
                                ival.LocationName = jval.label;
                                ival.status = ival.status ? ival.status : 'active';
                            }
                        })
                    });
                    await Promise.all(wait2);

                    this.setState({ SearchResult: Result.data })
                }
            } else if (subtransaction.value == 1) {
                let ProductPipleLine = await cmsContent.getFreedom(
                    `tbl_dfcarz_prosperities.*,tbl_dfcarz_prosperities.Budget as price,tbl_make_master.Make as makename,tbl_dfcarsmodel.Model as modelname,tbl_colour.colour as ColourName,tbl_location.name as LocationName`,
                    `tbl_Inventory_Sheet_Reproof,tbl_dfcarz_prosperities,tbl_location,tbl_make_master,tbl_dfcarsmodel,tbl_colour`,
                    `tbl_Inventory_Sheet_Reproof.customerid = tbl_dfcarz_prosperities.id and tbl_colour.id = tbl_dfcarz_prosperities.color and tbl_make_master.id = tbl_dfcarz_prosperities.make and tbl_dfcarz_prosperities.model = tbl_dfcarsmodel.id and tbl_location.id = tbl_dfcarz_prosperities.location`,
                    1,
                    `tbl_dfcarz_prosperities.id DESC`
                );


                if (ProductPipleLine) {
                    //console.log(ProductPipleLine.data);
                    let Fulldata = [];
                    let wait4 = await ProductPipleLine.data.map(async (ival, i) => {
                        let customer = await cmsContent.getFreedom(
                            `*`,
                            `tbl_dfcarz_customers`,
                            `prospectid=${ival.id}`,
                            1,
                            `id DESC`
                        );
                        if (!customer.data.length) {
                            Fulldata.push(ival);
                        }

                    });
                    await Promise.all(wait4);
                    console.log(Fulldata);
                    this.setState({ SearchResult: Fulldata });
                }

            }
            // }

        } catch (error) {
            console.log(error);
        }
    }

    render() {

        return (<React.Fragment>
            <div className="row form-group">
                <div className="col-sm-1" />
                <div className="col-sm-3">
                    <label htmlFor="exampleInputEmail1">Search By</label>
                </div>
                <div className="col-sm-5">
                    <SingleSelect
                        name="searchby"
                        options={this.state.SearchByList}
                        handleChange={d => this.handleModel(d)}
                        selectedService={this.state.searchby}
                    />
                </div>
            </div>

            {this.state.searchby.value == 7 && this.state.LocationList.length ? <React.Fragment>
                <div className="row form-group">
                    <div className="col-sm-1" />
                    <div className="col-sm-3">
                        <label htmlFor="exampleInputEmail1">
                            Location
                    </label>
                    </div>
                    <div className="col-sm-5">
                        <MultiSelect
                            options={this.state.LocationList}
                            handleChange={this.handleMultiLocation}
                            selectedService={this.state.selectedService}
                        />
                    </div>
                    <div className="col-sm-3" />
                </div>

                <div className="row form-group">
                    <div className="col-sm-1" />
                    <div className="col-sm-3">
                        <label htmlFor="exampleInputEmail1">Make</label>
                    </div>
                    <div className="col-sm-5">
                        <SingleSelect
                            name="searchby"
                            options={this.state.MakeList}
                            handleChange={d => this.handleMakeList(d)}
                            selectedService={this.state.selectedmake}
                        />
                    </div>
                </div>

                <div className="row form-group">
                    <div className="col-sm-1" />
                    <div className="col-sm-3">
                        <label htmlFor="exampleInputEmail1">Model</label>
                    </div>
                    <div className="col-sm-5">
                        <SingleSelect
                            name="searchby"
                            options={this.state.ModelList}
                            handleChange={d => this.handleModelList(d)}
                            selectedService={this.state.selectedmodel}
                        />
                    </div>
                </div>

                <div className="row form-group">
                    <div className="col-sm-1" />
                    <div className="col-sm-3">
                        <label htmlFor="exampleInputEmail1">Colour</label>
                    </div>
                    <div className="col-sm-5">
                        <SingleSelect
                            name="searchby"
                            options={this.state.ColorList}
                            handleChange={d => this.handleColorList(d)}
                            selectedService={this.state.selectedcolor}
                        />
                    </div>
                </div>

            </React.Fragment> : null}

            {this.state.searchby.value == 1 ? <React.Fragment>
                <div className="row form-group">
                    <div className="col-sm-1" />
                    <div className="col-sm-3">
                        <label htmlFor="exampleInputEmail1">Document type</label>
                    </div>
                    <div className="col-sm-5">
                        <SingleSelect
                            name="searchby"
                            options={this.state.DocumentByList}
                            handleChange={d => this.handlesubdocument(d)}
                            selectedService={this.state.subdocument}
                        />
                    </div>
                </div>
            </React.Fragment> : null}

            {this.state.subdocument.value && this.state.searchby.value == 1 ? <React.Fragment>
                <div className="row form-group">
                    <div className="col-sm-1" />
                    <div className="col-sm-3">
                        <label htmlFor="exampleInputEmail1">
                            {this.state.subdocument.label}
                        </label>
                    </div>
                    <div className="col-sm-5">
                        <input
                            type="text"
                            className="form-control"
                            id="registerName"
                            name="mobile"
                            placeholder={`Enter ${this.state.subdocument.label}`}
                            value={this.state.mobile}
                            onChange={this.HandleChange}
                        />
                    </div>
                    <div className="col-sm-3" />
                </div>
            </React.Fragment> : null}

            {this.state.searchby.value == 2 ? <React.Fragment>
                <div className="row form-group">
                    <div className="col-sm-1" />
                    <div className="col-sm-3">
                        <label htmlFor="exampleInputEmail1">Transaction type</label>
                    </div>
                    <div className="col-sm-5">
                        <SingleSelect
                            name="searchby"
                            options={this.state.TransactionByList}
                            handleChange={d => this.handlesubtransaction(d)}
                            selectedService={this.state.subtransaction}
                        />
                    </div>
                </div>
            </React.Fragment> : null}

            <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-3" />
                <div className="col-sm-5">
                    <button
                        type="button"
                        className={`btn btn-primary`}
                        disabled={this.state.disableValue}
                        onClick={() => this.ProductSearch()} >
                        Search
                          </button>
                </div>
                <div className="col-sm-2" />
            </div>

            {this.state.SearchResult.length ? (
                <Datatable
                    data={this.state.SearchResult}
                    columnHeading={this.state.column}
                />
            ) : null}
        </React.Fragment>)
    }
}
export default DfCarsproductsearch;