import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../MiddleWare/CmsContent";
import Datatable from "../../../components/Datatable/Datatable";
import FormMiddleWare from "../../../components/Form/FormMiddleware";
import { confirmAlert } from "react-confirm-alert";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import DfMODELPDF from "../../../components/PDF/ModelPDF";
import redisMiddleware from "../../../MiddleWare/redisMiddleware";
import SingleSelect from "../../../components/Form/SingleSelect";

class DfCarsWarranty extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      data1:[],
      data2:[],
      customerId: localStorage.getItem("userId"),
      ServiceId: localStorage.getItem("currentService"),
      alertVisible: false,
      color: "",
      textalert: "",
      Warranty: "",
      errorColourName: "",
      isEdit: false,
      disableValue: false,
      modalvisible:false,
      year:[]
    };
  }

  column = [
    {
      Header: "Model",
      accessor: "model",
      Cell: (d)=> this.getValueFromArray(d.original.model,this.state.modeloptions)
    },
    {
      Header: "Make",
      accessor: "make",
      Cell: (d)=> this.getValueFromArray(d.original.make,this.state.makeoptions)
    },
    {
        Header: "Price",
        accessor: "warranty",
      },
      {
        Header: "Year",
        accessor: "year",
      },
      
      
    {
      Header: "Edit",
      accessor: "edit",
      Cell: (d) => this.edit(d),
    },
    {
      Header: <b>Delete</b>,
      selector: "delete",
      Cell: (d) =>
        this.dataTableButton("danger", "Delete", () => {
          this.buttonDeletes(d);
        }),
    },
  ];
 
  async componentDidMount() {
      let yr = this.state.year
      for(let i=3;i<11;i++){
           yr.push({label: i})
      }
     console.log(yr);
     const{customerId,ServiceId}=this.state;
    try {
        
      let makeoptions = [];
      //Model Master
      const model = await redisMiddleware.singleTable(
        "tbl_dfcarsmodel",
        this.state.ServiceId,
        "ServiceId"
      );
      // let data = [];
      let modeloptions = [];
      if (model) {
        model.data.map((mval, k) => {
           // console.log(mval);
          modeloptions.push({ label: mval.Model, value: mval.id ,makeid:mval.makeid});
        });
        await this.setState({ modeloptions });
      }
      //Make master
      const make = await redisMiddleware.singleTable(
        "tbl_make_master",
        this.state.ServiceId,
        "ServiceId"
      );
      console.log(make);
      if (make) {
        make.data.map((ival, i) => {
         
          makeoptions.push({ label: ival.Make, value: ival.id });
        });
      }
      if (makeoptions) {
        this.setState({ makeoptions });
      }
      if (model) {
        make.data.map((jval, j) => {
          model.data.map((val, i) => {
            if (jval.id == val.makeid) {
              val.makeid = jval.Make;
            }
          });
        });

        this.setState({ data: model.data });
      }
      // let makelabel = [];
      // if (make) {
      //   make.data.map((ival, i) => {
      //     model.data.map((jval, j) => {
      //       if (ival.id == jval.makeid) {
      //         jval.makeid = ival.Make;
      //       }
      //     });
      //   });
      //   // this.setState({ makelabel: make.data });
      let data1=this.state.data1;
      let data2 =  this.state.data2;
        const dt=await cmsContent.getFreedom(
            "*","tbl_dfcars_warranty",
            `customerid=${customerId} AND serviceid=${ServiceId}`,1,1
        )
        if(dt){
            dt.data.map((sval,k)=>{
                data1.push({id:sval.id,make: sval.make, model:sval.model,warranty:sval.warranty,year:sval.year})
                data2.push({id:sval.id,
                    make: this.getValueFromArray(sval.make,this.state.makeoptions),
                     model:this.getValueFromArray(sval.model,this.state.modeloptions),
                     warranty:sval.warranty,
                     year:sval.year

                })
            })
        }


      if (data2.length > 0) {
        const header = ["SI.NO", "Model", "Make","Warranty","year"];
        const excelHead = [
          { label: "Model", key: "model" },
          { label: "Make", key: "make" },
          { label: "Price", key: "warranty" },
          { label: "Year", key: "year" },
        ];
        this.setState({
          excelHead,
          header,
          excel: this.state.data2,
          title: "Warranty",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  buttonDeletes = (value) => {
    console.log(value);
    confirmAlert({
      title: "Confirmation to Delete",
      message: "Are you sure you want to delete this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.buttonDelete(value),
        },
        {
          label: "No",
        },
      ],
    });
  };

  buttonDelete = async (value) => {
    const previousData = [...this.state.data1];
    let id = value.original.id;
    try {
      const result = await cmsContent.getSingleConditionedValue(
        "tbl_dfcars_warranty",
        "id",
        id,
        "Delete"
      );
      if (result) {
        let datas = previousData.filter((delelteid, i) => {
          if (delelteid.id !== value.original.id) {
            return delelteid;
          }
        });
        this.setState({
          data: datas,
          alertVisible: true,
          textalert: "Selected Model Deleted",
          color: "danger",
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  edit = (d) => {
    let value = d;
    return (
      <center>
        <button
          type="button"
          className="btn btn-info"
          onClick={() => this.edition(value)}
        >
          Edit
        </button>
      </center>
    );
  };

  handlechangeData = async (e) => {
    let value = e.target.value;
    console.log(value);
    this.setState({ [e.target.name]: e.target.value, value });
  };

  submitCategory = async () => {
    const {
      Warranty,
      yr,
      customerId,
      data,
      value,
      ServiceId,
      selectedmake,
      selectedmodel
    } = this.state;
    // if (!selectedmake) {
    //   this.setState({ errorselect: "Select Make" });
    //   return false;
    // } else if (!selectedmodel) {
    //   this.setState({ modelerror: "Select Model" });
    //   return false;
    // }
    // else if (!Warranty) {
    //     this.setState({ warerror: "Enter Price" });
    //     return false;
    //   }
    //   else if (!Warranty) {
    //     this.setState({ error: "Select Year" });
    //     return false;
    //   }
    // let filtered_item =
    //   data && data.length > 0
    //     ? data.filter((i) => {
    //         return i.Model === value;
    //       })
    //     : [];

    // if (filtered_item.length > 0) {
    //   this.setState({ error: "Model not be repeated" });
    //   return false;
    // }
    let groupArray = {};
    groupArray.make = selectedmake.value;
    groupArray.model = selectedmodel.value;
    groupArray.warranty = Warranty.trim();
    groupArray.year=yr.label;
  //  groupArray.status = "active";
    groupArray.customerid = customerId;
    groupArray.serviceid = ServiceId;
    console.log(groupArray);

    try {
      this.setState({ disableValue: true });
      const result = await cmsContent.addMaster("tbl_dfcars_warranty", groupArray);
      if (result) {
        let valueArray = {};
        valueArray.make = 9;
        //selectedmake.value;
    valueArray.model = 127
    //selectedmodel.value;
    valueArray.warranty = Warranty.trim();
    valueArray.year=yr.label;
  //  valueArray.status = "active";
    valueArray.customerid = customerId;
    valueArray.serviceid = ServiceId;
        const newData = [valueArray, ...this.state.data1];
        this.setState({
          data: newData,
          alertVisible: true,
          disableValue: false,
          textalert: "New Model Added",
          color: "success",
          errorColourName: "",
          error: "",
          Warranty: "",
          selectedmake: "",
          selectedmodel:"",
          yr:""
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  onDismiss = () => {
    this.setState({ alertVisible: false, formAlertdelete: false });
  };

  edition = (d) => {
    console.log(d);
    let Index = d.index;
    let value = d.original;
    let editid = value.id;
    let selectedmake = value.make;
    this.setState({
      editid,
      Index,
      selectedmake,
      isEdit: true,
    });
  };

  UpdateCategory = async () => {
    const { editid,  yr,Warranty, Index, selectedmake ,selectedmodel} = this.state;
    
      if (!selectedmake) {
        this.setState({ errorselect: "Select Make" });
        return false;
      } else if (!selectedmodel) {
        this.setState({ modelerror: "Select Model" });
        return false;
      }
      else if (!Warranty) {
          this.setState({ warerror: "Enter Price" });
          return false;
        }
        else if (!Warranty) {
          this.setState({ error: "Select Year" });
          return false;
        }
        let subgroupArray = {};
        subgroupArray.make = selectedmake.value;
        subgroupArray.model = selectedmodel.value;
        subgroupArray.warranty = Warranty.trim();
        subgroupArray.year=yr.label;
      //  subgroupArray.status = "active";
       
    
    try {
      const result = await cmsContent.updateMaster(
        "tbl_dfcars_warranty",
        editid,
        subgroupArray
      );
      if (result) {
        let previousdata = [...this.state.data1];
        previousdata[Index].Model = Warranty;
        setTimeout(() => this.setState({ data: previousdata }), 500);
        this.setState({
          alertVisible: true,
          disableValue: false,
          editid: null,
          errorColourName: "",
          error: "",
          Warranty: "",
          selectedmake: "",
          selectedmodel:"",
          yr:"",
          Index: null,
          textalert: "Model Updated",
          color: "success",
          isEdit: false,
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleprice = async(e)=>{
      this.setState({Warranty:e.target.value})
   
  }
  handleyr =async(e)=>{
    this.setState({yr:e})
}
  handlemodel =async(e)=>{
      this.setState({selectedmodel:e})
  }
  handlemake = async (e) => {
    console.log(e);
    this.setState({ selectedmake: e });
    this.modalview(e)
  };
  modalview=async(e)=>{
      const{selectedmake,modeloptions}=this.state;
        let modeloptions1 = []
        modeloptions.map((ival,i)=>{
           // console.log(ival);
            if(e.value==ival.makeid){
               // console.log(ival);
                modeloptions1.push(ival)
            }
        })
      this.setState({modalvisible:true,modeloptions1})
  }
  render() {
    const { alertVisible, textalert, modeloptions,modalvisible ,modeloptions1,yr} = this.state;

    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Warranty Master</h1>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-7">
                        <Alert
                          className="badge-content"
                          color={this.state.color}
                          isOpen={alertVisible}
                          toggle={this.onDismiss}
                        >
                          {textalert}
                        </Alert>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="exampleInputEmail1">Select Make</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          placeholder="Select Make"
                          options={this.state.makeoptions}
                          onChange={(e) => this.handlemake(e)}
                          selectedService={this.state.selectedmake}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">
                        {this.state.errorselect}
                      </span> 
                     
                    </div>
                  {modalvisible &&(
                      <div><div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="exampleInputEmail1">Model</label>
                      </div>
                      <div className="col-sm-5">
                      <SingleSelect
                          placeholder="Select Model"
                          options={this.state.modeloptions1}
                          onChange={(e) => this.handlemodel(e)}
                          selectedService={this.state.selectedmodel}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                     
                      <span className="error-show ">{this.state.modelerror}</span>
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="exampleInputEmail1">Price</label>
                      </div>
                      <div className="col-sm-5">
                      <input
                          type="text"
                          className="form-control"
                        //  id="Warranty"
                          name="Price"
                          placeholder="Enter Warranty"
                           value={this.state.Warranty}
                           onChange={this.handleprice}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
 
                      <span className="error-show ">{this.state.warerror}</span>
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="exampleInputEmail1">Year</label>
                      </div>
                      <div className="col-sm-5">
                      <SingleSelect
                          placeholder="Select Year"
                          options={this.state.year}
                          onChange={(e) => this.handleyr(e)}
                          selectedService={this.state.yr}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
  
                      <span className="error-show ">{this.state.yrerror}</span>
                    </div>
                          </div>
                    ) }


                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-3" />
                      <div className="col-sm-5">
                        <button
                          type="button"
                          className={`btn btn-${
                            this.state.isEdit ? "secondary" : "primary"
                          }`}
                          disabled={this.state.disableValue}
                          onClick={
                            this.state.isEdit
                              ? this.UpdateCategory
                              : this.submitCategory
                          }
                        >
                          {this.state.isEdit ? "Update Model" : "Add Model"}
                        </button>
                      </div>
                      <div className="col-sm-2" />
                    </div>
                    <br></br>
                 {this.state.excel && this.state.excel.length > 0 ? (
                      <PDFDownloadLink
                        style={{ color: "white" }}
                        document={
                          <DfMODELPDF
                            title={this.state.title}
                            header={this.state.header}
                            data={this.state.excel}
                          />
                        }
                        fileName="AddBrand.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? "Loading document..." : "Download Pdf"
                        }
                        <button className="btn btn-warning">PDF</button>
                      </PDFDownloadLink>
                    ) : null} 
                    {this.state.data2 && this.state.data2.length ? (
                      <CSVLink
                        data={this.state.data2}
                        headers={this.state.excelHead}
                      >
                        <button
                          className="btn btn-success"
                          style={{ marginLeft: "5px" }}
                        >
                          Excel
                        </button>
                      </CSVLink>
                    ) : null}
                    <br></br>
                    <br></br>
                    <div className="row form-group">
                      <div className="col-sm-12">
                        {this.state.data1.length ? (
                          <Datatable
                            data={this.state.data1}
                            columnHeading={this.column}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
export default DfCarsWarranty;
