import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import redisMiddleware from "../../../../MiddleWare/redisMiddleware";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import UpdateStatus from "../SalesMaster/UpdateStatus";
import moment from "moment";
import LoginModal from "../../../../components/Modal/Modal";
import UsertypeComments from "../UserMaster/UsertypeComments";
import Datatable from "../../../../components/Datatable/Datatable";
import SingleSelect from "../../../../components/Form/SingleSelect";
import DfCarsproductsearch from "./Dfcarsproductsearch";
import Dfcarsprogressearch from "./Dfcarsprogressearch";
import SearchBar from 'react-js-search';


class DfCarsGobalsearch extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      customerId: localStorage.getItem("userId"),
      LocationID: localStorage.getItem(`LocationID`),
      SearchByList: [{ label: "Name", value: 7 }, { label: "Email", value: 1 }, { label: "Mobile", value: 2 }, { label: "Bank account number", value: 3 },
      { label: "RC Number", value: 4 }, { label: "DL No", value: 5 }, { label: "Insurance Policy Number", value: 6 },
      ],
      mobile: "",
      searchby: {},
      SearchResult: [],
      column: [
        {
          Header: "Name",
          accessor: "name"
        },
        {
          Header: "Mobile",
          accessor: "mobile"
        },
        {
          Header: "Email_Id",
          accessor: "email"
        },
        {
          Header: "Type",
          accessor: "type"
        },
        {
          Header: "View More",
          accessor: "price",
          Cell: (d) => this.Viewmore(d)
        },

      ],
      data: [
        { number: 12, name: "Buffon", position: "ST", success: true },
        { number: 21, name: "Pirlo", position: "MC", success: false },
        { number: 10, name: "Ruiz", position: "MDI" },
        { number: 7, name: "Nesta", position: "RB", success: true },
        { number: 4, name: "Cannavaro", position: "CB" },
        { number: 2, name: "Puyol", position: "CB", success: false },
        { number: 15, name: "Abate", position: "LB" },
        { number: 16, name: "Locatelli", position: "MDI" },
        { number: 1, name: "Buffon", position: "GK" },
        { number: 21, name: "Pirlo", position: "MC" },
        { number: 10, name: "Ruiz", position: "MDI" },
        { number: 7, name: "Nesta", position: "RB" }
      ]
    }
  }

  Viewmore = (d) => {
    return (
      <button type="button" className="btn btn-primary"
        onClick={() => this.showButton(d)}
      >View</button>
    )
  }

  showButton = async (d) => {
    window.open(`/DFCars/DfCarsViewmore/${d.original.id}`)
  }


  HandleChange = async (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleModel = async (e) => {
    console.log(e);
    this.setState({ searchby: e, SearchResult: [] })
  }

  PeoplesSearch = async () => {
    try {
      const { mobile, searchby } = this.state;

      if (!mobile) {
        return false
      }
      let SearchResult = []
      if (searchby.value == 7) {
        //name
        let Prospect = await cmsContent.getFreedom(
          `*`,
          `tbl_dfcarz_enquiryform`,
          `name = '${mobile}'`
        );
        if (Prospect.data.length) {
          Prospect.data.map((ival, i) => {
            SearchResult.push(ival)
          });
        }
      } else if (searchby.value == 1) {
        ///email
        let Prospect = await cmsContent.getFreedom(
          `*`,
          `tbl_dfcarz_enquiryform`,
          `email = '${mobile}'`
        );
        if (Prospect.data.length) {
          Prospect.data.map((ival, i) => {
            SearchResult.push(ival)
          });
        }
      } else if (searchby.value == 2) {
        ///mobile
        let Prospect = await cmsContent.getFreedom(
          `*`,
          `tbl_dfcarz_enquiryform`,
          `mobile = '${mobile}'`
        );
        if (Prospect.data.length) {
          Prospect.data.map((ival, i) => {
            SearchResult.push(ival)
          });
        }
      } else if (searchby.value == 3) {
        ///bank account number
        let Prospect = await cmsContent.getFreedom(
          `tbl_dfcarz_prosperities.*`,
          `tbl_AT_Booklet_Purchase_Contract_Proof,tbl_dfcarz_prosperities`,
          `tbl_AT_Booklet_Purchase_Contract_Proof.old_bank_no='${mobile}' and tbl_dfcarz_prosperities.id = tbl_AT_Booklet_Purchase_Contract_Proof.customerid`
        );
        if (Prospect.data.length) {
          Prospect.data.map((ival, i) => {
            SearchResult.push(ival)
          });
        }
      } else if (searchby.value == 4) {
        ///Ec number
        let Prospect = await cmsContent.getFreedom(
          `*`,
          `tbl_dfcarz_enquiryform`,
          `mobile = '${mobile}'`
        );
        if (Prospect.data.length) {
          Prospect.data.map((ival, i) => {
            SearchResult.push(ival)
          });
        }
      }
      this.setState({ SearchResult })
    } catch (error) {
      console.log(error);
    }
  }

  async componentDidMount() {
    try {
      const Prospect = await redisMiddleware.withOutCondition("tbl_dfcarz_enquiryform");
     // console.log(make)
      // let Prospect = await cmsContent.getFreedom(
      //   `*`,
      //   `tbl_dfcarz_enquiryform`,
      //   1,
      //   1,
      //   `id DESC`
      // );
      if (Prospect) {

        this.setState({ Data: Prospect.data })
      }

    } catch (error) {
      console.log(error);
    }
  }

  onSearchChange = async (a, b) => {
    console.log(a);
    console.log(b);
    if (a == "") {
      this.setState({ SearchResult: [] })
    } else {
      this.setState({ SearchResult: b });
    }
  }



  render() {
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Global Search</h1>
                  </div>
                  <div className="card-body">

                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item waves-effect waves-light">
                        <a class="nav-link" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="false">People</a>
                      </li>
                      <li class="nav-item waves-effect waves-light">
                        <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Product</a>
                      </li>
                      <li class="nav-item waves-effect waves-light">
                        <a class="nav-link active" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="true">Process</a>
                      </li>
                    </ul>

                    {/* Search results by People */}
                    <div class="tab-content" id="myTabContent">
                      <div class="tab-pane fade" id="home" role="tabpanel" aria-labelledby="home-tab">

                        <div className="row form-group">
                          <div className="col-sm-1" />
                          <div className="col-sm-3">
                            <label htmlFor="exampleInputEmail1">Search By</label>
                          </div>
                          <div className="col-sm-5">
                            <SingleSelect
                              name="searchby"
                              options={this.state.SearchByList}
                              handleChange={d => this.handleModel(d)}
                              selectedService={this.state.searchby}
                            />
                          </div>
                          <div className="col-sm-3" />
                        </div>

                        {this.state.searchby.value ? <React.Fragment>
                          <div className="row form-group">
                            <div className="col-sm-1" />
                            <div className="col-sm-3">
                              <label htmlFor="exampleInputEmail1">
                                {this.state.searchby.label}
                              </label>
                            </div>
                            <div className="col-sm-5">
                              {this.state.searchby.value == 3 ?

                                <input
                                  type="text"
                                  className="form-control"
                                  id="registerName"
                                  name="mobile"
                                  placeholder={`Enter ${this.state.searchby.label}`}
                                  value={this.state.mobile}
                                  onChange={this.HandleChange}
                                />
                                :
                                <div className="row form-group">
                                  <div className="col-sm-12">
                                    <SearchBar
                                      onSearchTextChange={(term, hits) => { this.onSearchChange(term, hits) }}
                                      onSearchButtonClick={this.onSearchClick}
                                      placeHolderText={"Search here..."}
                                      data={this.state.Data}
                                    />
                                  </div>
                                </div>}
                            </div>
                            <div className="col-sm-3" />
                          </div>
                        </React.Fragment> : null}

                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-3" />
                          <div className="col-sm-5">
                            <button
                              type="button"
                              className={`btn btn-primary`}
                              disabled={this.state.disableValue}
                              onClick={() => this.PeoplesSearch()} >
                              Search
                          </button>
                          </div>
                          <div className="col-sm-2" />
                        </div>

                        {/* {this.state.SearchResult.length ?
                       this.state.SearchResult.map((ival,i)=>{
                       return(<React.Fragment>
                        <div className="row form-group">
                        <div className="col-sm-12">
                        <div className="card-body" style={{backgroundColor:"lightgray"}}>
                          Name:{ival.name}<br/>
                          Mobile:{ival.mobile}<br/>
                          Email_ID:{ival.email}<br/>
                          Type:{ival.type}<br/>

                        </div>
                         </div>
                        </div>
                      </React.Fragment> )}): null} */}

                        {this.state.SearchResult.length ? (
                          <Datatable
                            data={this.state.SearchResult}
                            columnHeading={this.state.column}
                          />
                        ) : null}

                      </div>


                      {/* Search results by People #End */}

                      <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        <DfCarsproductsearch />
                      </div>

                      <div class="tab-pane fade active show" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                        <Dfcarsprogressearch />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    )
  }
}

export default DfCarsGobalsearch;