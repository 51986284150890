import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import UpdateStatus from "../SalesMaster/UpdateStatus";
import moment from "moment";
import LoginModal from "../../../../components/Modal/Modal";
import UsertypeComments from "../UserMaster/UsertypeComments";
import Datatable from "../../../../components/Datatable/Datatable";
import SingleSelect from "../../../../components/Form/SingleSelect";
import MultiSelect from "../../../../components/Form/MultiSelect";

class Dfcarsprogressearch extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      User: JSON.parse(localStorage.getItem("userlog")),
      SearchByList: [{ label: "Process Phase", value: 1 }, { label: "Usertype", value: 2 }],
      ProcessType: [{ label: "Buying Car", value: 1 }, { label: "Selling Car", value: 2 }],
      searchby: {},
      LocationList1: [],
      selectedService: [],
      UserTypeList: [],
      selectedtype: {},
      ProcessList: [{ label: "Evaluation", value: 1 }, { label: " Documentation", value: 2 },
      { label: "Physical", value: 3 }, { label: "Payment", value: 4 },
        , { label: "Refurbishment", value: 5 },
      { label: "Specification", value: 6 }, { label: "Sourcing", value: 7 },
      { label: "Contracting", value: 8 }, { label: "Delivery", value: 9 },
      ],
      selectedProcess: {},
      SearchResult: [],
      newcolumn1: [{
        Header: "Prospect",
        accessor: "name"
      },
      {
        Header: "Contact",
        accessor: "mobile"
      },
      {
        Header: "evaluator name ",
        accessor: "userName"
      },
      {
        Header: "Location",
        accessor: "LocationName"
      },
      {
        Header: "Price in Rs.",
        accessor: "Budget"
      },
      {
        Header: "Evaluation F-proof",
        accessor: "mobile",
        Cell: d => this.ViewLead(d)
      },
      {
        Header: "Evaluation B-proof",
        accessor: "mobile",
        Cell: d => this.ViewLead1(d)
      },
      {
        Header: "Approval",
        accessor: "mobile",
        Cell: d => this.Approval(d)
      }],
      column: [{
        Header: "Prospect",
        accessor: "name"
      },
      {
        Header: "Contact",
        accessor: "mobile"
      },
      {
        Header: "evaluator name ",
        accessor: "userName"
      },
      {
        Header: "Price in Rs.",
        accessor: "Budget"
      },
      {
        Header: "Evaluation F-proof",
        accessor: "mobile",
        Cell: d => this.ViewLead(d)
      },
      {
        Header: "Evaluation B-proof",
        accessor: "mobile",
        Cell: d => this.ViewLead1(d)
      },
      {
        Header: "Approval",
        accessor: "mobile",
        Cell: d => this.Approval(d)
      },],
      column1: [{
        Header: "Prospect",
        accessor: "name"
      },
      {
        Header: "Contact",
        accessor: "mobile"
      },
      {
        Header: "evaluator name ",
        accessor: "userName"
      },
      {
        Header: "Price in Rs.",
        accessor: "Budget"
      },
      {
        Header: "Documentation",
        accessor: "mobile",
        Cell: (d) => this.Document(d),
      },
      {
        Header: "Approval",
        accessor: "mobile",
        Cell: (d) => this.Approval1(d),
      },
      ],
      newcolumn2: [
        {
          Header: "Prospect",
          accessor: "name"
        },
        {
          Header: "Contact",
          accessor: "mobile"
        },
        {
          Header: "evaluator name ",
          accessor: "userName"
        },
        {
          Header: "Price in Rs.",
          accessor: "Budget"
        },
        {
          Header: "Documentation",
          accessor: "mobile",
          Cell: (d) => this.Document(d),
        },
        {
          Header: "Approval",
          accessor: "mobile",
          Cell: (d) => this.Approval1(d),
        }
      ],
      column2: [{
        Header: "Prospect",
        accessor: "name"
      },
      {
        Header: "Contact",
        accessor: "mobile"
      },
      {
        Header: "evaluator name ",
        accessor: "userName"
      },
      {
        Header: "Price in Rs.",
        accessor: "Budget"
      },
      {
        Header: "Documentation",
        accessor: "mobile",
        Cell: (d) => this.Document(d),
      },
      {
        Header: "Approval",
        accessor: "mobile",
        Cell: (d) => this.Approval1(d),
      },
      ],
      UserNameList: [],
      selectedname: {},
      selectedTransactionList: {},
      TransactionList: [{ label: "Buying", value: 1 }, { label: "Selling", value: 2 }],
      ProcessTypeList: [],
      selectedProcessTypeList: {}
    }
  }

  Approval1 = (d) => {
    //console.log(d.original);
    if (d.original.FormFilled == "approved") {
      return (<span className="label">Approved</span>)
    } else if (d.original.FormFilled == "yes") {
      return (<span className="label">Waiting for Approval</span>)
    } else {
      return (<span className="label">Fill the form</span>)
    }
  };

  Document = (d) => {
    if (d.original.FormFilled == "yes" || d.original.FormFilled == "approved") {
      return (
        <span className="label">Form Filled</span>
      );
    } else {
      return (
        <span className="label">Form Not Filled</span>
      );
    }
  };

  ViewLead = (d) => {
    if (d.original.F_formfilled == 'yes' || d.original.F_formfilled == 'approved') {
      return (<span className="label">Form Filled</span>)
    } else {
      return (<span className="label">Form Not Filled</span>)
    }

  }
  ViewLead1 = (d) => {
    if (d.original.B_formfilled == 'yes' || d.original.B_formfilled == 'approved') {
      return (<span className="label">Form Filled</span>)
    } else {
      return (<span className="label">Form Not Filled</span>)
    }

  }

  Approval = (d) => {
    if (d.original.F_formfilled == 'approved' || d.original.B_formfilled == 'approved') {
      return (<span className="label">Approved</span>)
    } else if (d.original.F_formfilled == 'yes' && d.original.B_formfilled == 'yes') {
      return (<span className="label">Waiting for Approval</span>)
    } else if (d.original.F_formfilled == 'no' || d.original.B_formfilled == 'no') {
      return (<span className="label">Fill the form</span>)
    }
  }

  handleModel = async (e) => {
    this.setState({ searchby: e, SearchResult: [] });
    if (e.value == 2) {
      // this.UsertypeSearch();
    } else if (e.value == 1) {
      // this.getDocument();
    }

  }

  async componentDidMount() {
    try {
      let Result = [];
      Result = await cmsContent.getFreedom(
        `name as label,id as value`,
        `tbl_location`,
        `serviceid = 10`,
        1,
        `id`
      );
      if (Result) {
        this.setState({ LocationList1: Result.data })
      }

      let Usertype = await cmsContent.getFreedom(
        `usertype as label,id as value`,
        `tbl_UserType`,
        `serviceid = 10 and customerid = ${this.state.User.customerId}`,
        1,
        `id`
      );
      if (Usertype) {
        console.log(Usertype)
        this.setState({ UserTypeList: Usertype.data })
      }

    } catch (error) {
      console.log(error);
    }
  }

  UsertypeSearch = async (e) => {
    const { searchby, selectedProcess, selectedtype, selectedService } = this.state;
    try {
      let Conditon = ""
      selectedService.map((ival, i) => {
        if (i == 0) {
          Conditon = `tbl_MaptoLocation.locationId=${ival.value} ${" "}`;
        } else {
          Conditon = Conditon + `OR tbl_MaptoLocation.locationId = ${ival.value} ${" "}`;
        }
      });
      let UserList1 = await cmsContent.getFreedom(
        `tbl_MaptoLocation.*`,
        `tbl_MaptoLocation`,
        `${Conditon}  and tbl_MaptoLocation.userTypeId =${e.value} and tbl_MaptoLocation.serviceid = 10 and tbl_MaptoLocation.customerid =${this.state.User.customerId}`,
        1,
        1
      );
      if (UserList1) {

        let data1 = [];

        let wait = await UserList1.data.map(async (ival, i) => {
          if (e.value == ival.userTypeId) {
            data1.push(ival)
          }
        });

        await Promise.all(wait)
        if (data1.length) {
          let wait2 = await data1.map(async (ival, i) => {
            let Tbl_user = await cmsContent.getFreedom(
              `*`,
              `tbl_user_web`,
              `userportid = ${ival.id}`
            )
            if (Tbl_user.data.length) {
              //console.log(Tbl_user.data);
              ival.userName = Tbl_user.data[0].userName;
              ival.UserId = Tbl_user.data[0].id;
              ival.label = Tbl_user.data[0].userName;
              ival.value = Tbl_user.data[0].id;
            }
          });
          await Promise.all(wait2);
          this.setState({ UserNameList: data1 })
          //  await this.getProcess(data1);
        }
        console.log(data1);
      }

    } catch (error) {
      console.log(error)
    }
  }

  getProcess = async () => {
    const { selectedProcess, selectedname, UserNameList } = this.state;
    //console.log(d1);
    let d = selectedname;
    let Product = await cmsContent.getFreedom(
      'tbl_dfcarz_prosperities.*,tbl_user_web.userName',
      'tbl_dfcarz_prosperities,tbl_user_web',
      `tbl_dfcarz_prosperities.location=${d.locationId} and tbl_user_web.id=tbl_dfcarz_prosperities.userid and tbl_dfcarz_prosperities.userid =${d.UserId}`,
      `tbl_dfcarz_prosperities.id`,
      `tbl_dfcarz_prosperities.id DESC`
    );
    if (UserNameList.length) {
      if (selectedProcess.value == 1) {
        ///Evaluation
        if (Product) {
          //console.log(Product.data)
          Product.data.map((ival, i) => {
            ival.F_formfilled = 'no';
            ival.B_formfilled = 'no';
          })

        }
        const Fproof = await cmsContent.getFreedom(
          `*`,
          `tbl_FProof`,
          1
        );
        if (Fproof) {
          //console.log(Fproof)
          let F_proof = Fproof.data;
          Product.data.map((ival, i) => {
            F_proof.map((jval, j) => {
              if (ival.id == jval.customerid && jval.approval == '1') {
                ival.F_formfilled = 'approved'
              } else if (ival.id == jval.customerid) {
                // console.log(ival);
                ival.F_formfilled = 'yes';
              }
            })
          })
        }
        const Bproof = await cmsContent.getFreedom(
          `*`,
          `tbl_BProof`,
          1
        );
        if (Bproof) {
          // console.log(Bproof)
          Product.data.map((ival, i) => {
            Bproof.data.map((jval, j) => {
              if (ival.id == jval.customerid && jval.approval == '1') {
                ival.B_formfilled = 'approved'
              }
              if (ival.id == jval.customerid) {
                ival.B_formfilled = 'yes'
              }
            })
          })
        }
        // console.log(Product);
        this.setState({ SearchResult: Product.data });
      } else if (selectedProcess.value == 2) {
        Product.data.map((ival, i) => {
          ival.FormFilled = "no";
        });
        let BookLet = await cmsContent.getFreedom(
          "*",
          `tbl_AT_Booklet_Purchase_Contract_Proof`,
          1,
          1
        );
        if (BookLet) {
          // console.log(BookLet.data);
          Product.data.map((ival, i) => {
            BookLet.data.map((jval, j) => {
              if (ival.id == jval.customerid && jval.approval == 1) {
                ival.FormFilled = "approved";
              } else if (ival.id == jval.customerid) {
                ival.FormFilled = "yes";
              }
            });
          });
        }
        let FullData = [];
        Product.data.map((ival, i) => {
          if (ival.FormFilled != "approved") {
            FullData.push(ival);
          }
        });
        this.setState({ SearchResult: Product.data });
      } else if (selectedProcess.value == 3) {
        if (Product) {
          // console.log(result);
          Product.data.map((ival, i) => {
            ival.FormFilled = "no";
          });

          //tbl_Delivery_Seller_Proof
          let result1 = await cmsContent.getFreedom(
            `*`,
            `tbl_Delivery_Seller_Proof`,
            1
          );
          // console.log(result1)
          Product.data.map((ival, i) => {
            result1.data.map((jval, j) => {
              //console.log(jval);
              if (jval.approval == 1 && ival.id == jval.customerid) {
                ival.FormFilled = "approved";
              } else if (ival.id == jval.customerid) {
                ival.FormFilled = "yes";
              }
            });
          });

          let FullData = [];
          Product.data.map((ival, i) => {
            if (ival.FormFilled != "approved") {
              FullData.push(ival);
            }
          });

          this.setState({ SearchResult: Product.data });
        }

      } else if (selectedProcess.value == 4) {
        if (Product) {
          Product.data.map((ival, i) => {
            ival.FormFilled = "no";
          });
          const Fproof = await cmsContent.getFreedom(
            `*`,
            `tbl_Inventory_Sheet_Reproof`,
            1,
            `id`
          );
          Product.data.map((ival, i) => {
            Fproof.data.map((jval, j) => {
              // console.log(jval);
              if (jval.approval == 1 && ival.id == jval.customerid) {
                ival.FormFilled = "approved";
              } else if (ival.id == jval.customerid) {
                ival.FormFilled = "yes";
              }
            });
          });

          let FullData = [];
          Product.data.map((ival, i) => {
            if (ival.FormFilled != "approved") {
              FullData.push(ival);
            }
          });

          this.setState({ SearchResult: Product.data });
        }


      } else if (selectedProcess.value == 5) {

        if (Product) {
          Product.data.map((ival, i) => {
            ival.FormFilled = "no";
          });
          let BookLet = await cmsContent.getFreedom(
            "*",
            `tbl_checklist350`,
            1,
            1
          );
          if (BookLet) {
            //console.log(BookLet.data);
            Product.data.map((ival, i) => {
              BookLet.data.map((jval, j) => {
                if (ival.id == jval.customerid && jval.approval == 1) {
                  ival.FormFilled = "approved";
                } else if (ival.id == jval.customerid) {
                  ival.FormFilled = "yes";
                }
              });
            });
          }
          // console.log(result.data)

          let FullData = [];
          Product.data.map((ival, i) => {
            if (ival.FormFilled != "approved") {
              FullData.push(ival);
            }
          });
          console.log(Product.data)
          this.setState({ SearchResult: Product.data });
          // this.setState({ Data: FullData });
          //  this.setState({Data:result.data})  
        }
      } else if (selectedProcess.value == 6) {

        if (Product) {
          //console.log(localStorage.getItem(`LocationID`));
          let Fulldata = [];
          Product.data.map((ival, i) => {
            // console.log(ival.prospective_status);
            if (ival.prospective_status != "inactive") {
              ival.FormFilled = "no";
              ival.price_approval = 0;
              ival.followup = ival.followup
                ? moment(ival.followup).format("YYYY-MM-DD")
                : `-`;
              ival.comment = ival.comment ? ival.comment : `-`;
              Fulldata.push(ival);
            }
          });
          let result = await cmsContent.getFreedom(
            `*`,
            `tbl_Specifications_Sheet_Proof`,
            1
          );

          Fulldata.map((ival, i) => {
            result.data.map((jval, j) => {
              ival.price_approval = jval.price_approval;
              if (ival.id == jval.customerid && jval.approval == 1) {
                ival.FormFilled = "approved";
              } else if (ival.id == jval.customerid) {
                ival.FormFilled = "yes";
              }
            });
          });
          this.setState({ SearchResult: Product.data });
          //console.log(Fulldata);
          // this.setState({ Data: Fulldata });
        }
      } else if (selectedProcess.value == 7) {
        if (Product) {
          let Fulldata = [];
          Product.data.map((ival, i) => {
            if (ival.prospective_status != "inactive") {
              ival.FormFilled = "no";
              ival.followup = moment(ival.followup).format("YYYY-MM-DD");
              Fulldata.push(ival);
            }
          });
          // console.log(Fulldata);
          let result = await cmsContent.getFreedom(
            `*`,
            `tbl_Autoterrace_enquiry_form`,
            1
          );
          Fulldata.map((ival, i) => {
            result.data.map((jval, j) => {
              if (ival.id == jval.customerid && jval.approval == 1) {
                ival.FormFilled = "approved";
              } else if (ival.id == jval.customerid) {
                ival.FormFilled = "yes";
              }
            });
          });
          //this.setState({ Data: Fulldata });
          this.setState({ SearchResult: Product.data });
        }
      } else if (selectedProcess.value == 8) {

        if (Product) {
          let Fulldata = [];
          Product.data.map((ival, i) => {
            if (ival.prospective_status != 'inactive') {
              ival.FormFilled = 'no';
              ival.followup = moment(ival.followup).format("YYYY-MM-DD");
              Fulldata.push(ival);
            }
          })
          // console.log(Buyer);
          let result = await cmsContent.getFreedom(
            `*`,
            `tbl_Booking_Confirmation_Reproof`,
            1
          );
          Fulldata.map((ival, i) => {
            result.data.map((jval, j) => {
              if (ival.id == jval.customerid && jval.approval == 1) {
                ival.FormFilled = 'approved';
              } else if (ival.id == jval.customerid) {
                ival.FormFilled = 'yes';
              }
            })
          })
          // this.setState({Data:Fulldata})
          this.setState({ SearchResult: Product.data });
        }
      } else if (selectedProcess.value == 9) {
        if (Product) {
          let Fulldata = [];
          Product.data.map((ival, i) => {
            if (ival.prospective_status != 'inactive') {
              ival.FormFilled = 'no';
              ival.followup = moment(ival.followup).format("YYYY-MM-DD");
              Fulldata.push(ival);
            }
          });
          //console.log(Buyer);
          let result = await cmsContent.getFreedom(
            `*`,
            `tbl_Delivery_Note_Buyer_Reproof`,
            1
          );
          // console.log(result);
          Fulldata.map((ival, i) => {
            result.data.map((jval, j) => {
              if (ival.id == jval.customerid && jval.approval == 1) {
                ival.FormFilled = 'approved';
              } else if (ival.id == jval.customerid) {
                ival.FormFilled = 'yes';
              }
            })
          })
          //console.log(Buyer.data);
          //this.setState({ Data: Fulldata })
          this.setState({ SearchResult: Product.data });
        }
      }

    }
  }

  handleMultiLocation = async (e) => {
    //  console.log(e);
    this.setState({ selectedService: e });
  }

  handleselectedtype = async (e) => {
    this.setState({ selectedtype: e });
    this.UsertypeSearch(e);
  }

  handleselectedProcess = async (e) => {
    this.setState({ selectedProcess: e });
  }

  handleselectedname = async (e) => {
    this.setState({ selectedname: e });
  }


  ProcessSearch = async () => {
    const { searchby } = this.state;
    if (searchby.value == 2) {
      this.getProcess();
    } else if (searchby.value == 1) {
      this.setState({ selectedProcess: {} });
      this.getPhasesearh();

    }
  }

  getPhasesearh = async () => {

    try {

      const { selectedProcess, selectedService, selectedProcessTypeList, selectedname, UserNameList } = this.state;
      //console.log(d1);
      let d = selectedname;
      let Conditon = ""
      selectedService.map((ival, i) => {
        if (i == 0) {
          Conditon = `tbl_dfcarz_prosperities.location=${ival.value} ${" "}`;
        } else {
          Conditon = Conditon + `OR tbl_dfcarz_prosperities.location = ${ival.value} ${" "}`;
        }
      });
      let Product = await cmsContent.getFreedom(
        'tbl_dfcarz_prosperities.*,tbl_user_web.userName',
        'tbl_dfcarz_prosperities,tbl_user_web',
        `${Conditon} and tbl_user_web.id=tbl_dfcarz_prosperities.userid`,
        `tbl_dfcarz_prosperities.id`,
        `tbl_dfcarz_prosperities.id DESC`
      );
      // console.log(Product.data);
      //,tbl_location.name as LocationName
      let Locationfind = await cmsContent.getFreedom(
        `*`,
        `tbl_location`,
        1,
        1,
        `id DESC`
      );
      if (Locationfind) {
        Product.data.map((ival, i) => {
          Locationfind.data.map((jval, j) => {
            if (ival.location == jval.id) {
              ival.LocationName = jval.name;
            }
          })
        })

      }
      if (selectedProcessTypeList.value == 1) {
        if (Product) {
          //console.log(Product.data)
          Product.data.map((ival, i) => {
            ival.F_formfilled = 'no';
            ival.B_formfilled = 'no';
          })

        }
        const Fproof = await cmsContent.getFreedom(
          `*`,
          `tbl_FProof`,
          1
        );
        if (Fproof) {
          console.log(Fproof)
          let F_proof = Fproof.data;
          let wait = await Product.data.map(async (ival, i) => {
            F_proof.map((jval, j) => {
              if (ival.id == jval.customerid && jval.approval == '1') {
                ival.F_formfilled = 'approved'
              } else if (ival.id == jval.customerid) {
                // console.log(ival);
                ival.F_formfilled = 'yes';
              }
            })
          });
          await Promise.all(wait);
        }
        const Bproof = await cmsContent.getFreedom(
          `*`,
          `tbl_BProof`,
          1
        );
        if (Bproof) {
          // console.log(Bproof)
          let wait4 = await Product.data.map(async (ival, i) => {
            Bproof.data.map((jval, j) => {
              if (ival.id == jval.customerid && jval.approval == '1') {
                ival.B_formfilled = 'approved'
              }
              if (ival.id == jval.customerid) {
                ival.B_formfilled = 'yes'
              }
            })
          })
          await Promise.all(wait4);
        }
        console.log(Product);
        this.setState({ SearchResult: Product.data });
      } else if (selectedProcessTypeList.value == 2) {
        Product.data.map((ival, i) => {
          ival.FormFilled = "no";
        });
        let BookLet = await cmsContent.getFreedom(
          "*",
          `tbl_AT_Booklet_Purchase_Contract_Proof`,
          1,
          1
        );
        if (BookLet) {
          // console.log(BookLet.data);
          Product.data.map((ival, i) => {
            BookLet.data.map((jval, j) => {
              if (ival.id == jval.customerid && jval.approval == 1) {
                ival.FormFilled = "approved";
              } else if (ival.id == jval.customerid) {
                ival.FormFilled = "yes";
              }
            });
          });
        }
        let FullData = [];
        Product.data.map((ival, i) => {
          if (ival.FormFilled != "approved") {
            FullData.push(ival);
          }
        });
        this.setState({ SearchResult: Product.data });
      } else if (selectedProcessTypeList.value == 3) {
        if (Product) {
          // console.log(result);
          Product.data.map((ival, i) => {
            ival.FormFilled = "no";
          });

          //tbl_Delivery_Seller_Proof
          let result1 = await cmsContent.getFreedom(
            `*`,
            `tbl_Delivery_Seller_Proof`,
            1
          );
          // console.log(result1)
          Product.data.map((ival, i) => {
            result1.data.map((jval, j) => {
              //console.log(jval);
              if (jval.approval == 1 && ival.id == jval.customerid) {
                ival.FormFilled = "approved";
              } else if (ival.id == jval.customerid) {
                ival.FormFilled = "yes";
              }
            });
          });

          let FullData = [];
          Product.data.map((ival, i) => {
            if (ival.FormFilled != "approved") {
              FullData.push(ival);
            }
          });

          this.setState({ SearchResult: Product.data });
        }

      } else if (selectedProcessTypeList.value == 4) {
        if (Product) {
          Product.data.map((ival, i) => {
            ival.FormFilled = "no";
          });
          const Fproof = await cmsContent.getFreedom(
            `*`,
            `tbl_Inventory_Sheet_Reproof`,
            1,
            `id`
          );
          Product.data.map((ival, i) => {
            Fproof.data.map((jval, j) => {
              // console.log(jval);
              if (jval.approval == 1 && ival.id == jval.customerid) {
                ival.FormFilled = "approved";
              } else if (ival.id == jval.customerid) {
                ival.FormFilled = "yes";
              }
            });
          });

          let FullData = [];
          Product.data.map((ival, i) => {
            if (ival.FormFilled != "approved") {
              FullData.push(ival);
            }
          });

          this.setState({ SearchResult: Product.data });
        }


      } else if (selectedProcessTypeList.value == 5) {

        if (Product) {
          Product.data.map((ival, i) => {
            ival.FormFilled = "no";
          });
          let BookLet = await cmsContent.getFreedom(
            "*",
            `tbl_checklist350`,
            1,
            1
          );
          if (BookLet) {
            //console.log(BookLet.data);
            Product.data.map((ival, i) => {
              BookLet.data.map((jval, j) => {
                if (ival.id == jval.customerid && jval.approval == 1) {
                  ival.FormFilled = "approved";
                } else if (ival.id == jval.customerid) {
                  ival.FormFilled = "yes";
                }
              });
            });
          }
          // console.log(result.data)

          let FullData = [];
          Product.data.map((ival, i) => {
            if (ival.FormFilled != "approved") {
              FullData.push(ival);
            }
          });
          console.log(Product.data)
          this.setState({ SearchResult: Product.data });
          // this.setState({ Data: FullData });
          //  this.setState({Data:result.data})  
        }
      } else if (selectedProcessTypeList.value == 6) {

        if (Product) {
          //console.log(localStorage.getItem(`LocationID`));
          let Fulldata = [];
          Product.data.map((ival, i) => {
            // console.log(ival.prospective_status);
            if (ival.prospective_status != "inactive") {
              ival.FormFilled = "no";
              ival.price_approval = 0;
              ival.followup = ival.followup
                ? moment(ival.followup).format("YYYY-MM-DD")
                : `-`;
              ival.comment = ival.comment ? ival.comment : `-`;
              Fulldata.push(ival);
            }
          });
          let result = await cmsContent.getFreedom(
            `*`,
            `tbl_Specifications_Sheet_Proof`,
            1
          );

          Fulldata.map((ival, i) => {
            result.data.map((jval, j) => {
              ival.price_approval = jval.price_approval;
              if (ival.id == jval.customerid && jval.approval == 1) {
                ival.FormFilled = "approved";
              } else if (ival.id == jval.customerid) {
                ival.FormFilled = "yes";
              }
            });
          });
          this.setState({ SearchResult: Product.data });
          //console.log(Fulldata);
          // this.setState({ Data: Fulldata });
        }
      } else if (selectedProcessTypeList.value == 7) {
        if (Product) {
          let Fulldata = [];
          Product.data.map((ival, i) => {
            if (ival.prospective_status != "inactive") {
              ival.FormFilled = "no";
              ival.followup = moment(ival.followup).format("YYYY-MM-DD");
              Fulldata.push(ival);
            }
          });
          // console.log(Fulldata);
          let result = await cmsContent.getFreedom(
            `*`,
            `tbl_Autoterrace_enquiry_form`,
            1
          );
          Fulldata.map((ival, i) => {
            result.data.map((jval, j) => {
              if (ival.id == jval.customerid && jval.approval == 1) {
                ival.FormFilled = "approved";
              } else if (ival.id == jval.customerid) {
                ival.FormFilled = "yes";
              }
            });
          });
          //this.setState({ Data: Fulldata });
          this.setState({ SearchResult: Product.data });
        }
      } else if (selectedProcessTypeList.value == 8) {

        if (Product) {
          let Fulldata = [];
          Product.data.map((ival, i) => {
            if (ival.prospective_status != 'inactive') {
              ival.FormFilled = 'no';
              ival.followup = moment(ival.followup).format("YYYY-MM-DD");
              Fulldata.push(ival);
            }
          })
          // console.log(Buyer);
          let result = await cmsContent.getFreedom(
            `*`,
            `tbl_Booking_Confirmation_Reproof`,
            1
          );
          Fulldata.map((ival, i) => {
            result.data.map((jval, j) => {
              if (ival.id == jval.customerid && jval.approval == 1) {
                ival.FormFilled = 'approved';
              } else if (ival.id == jval.customerid) {
                ival.FormFilled = 'yes';
              }
            })
          })
          // this.setState({Data:Fulldata})
          this.setState({ SearchResult: Product.data });
        }
      } else if (selectedProcessTypeList.value == 9) {
        if (Product) {
          let Fulldata = [];
          Product.data.map((ival, i) => {
            if (ival.prospective_status != 'inactive') {
              ival.FormFilled = 'no';
              ival.followup = moment(ival.followup).format("YYYY-MM-DD");
              Fulldata.push(ival);
            }
          });
          //console.log(Buyer);
          let result = await cmsContent.getFreedom(
            `*`,
            `tbl_Delivery_Note_Buyer_Reproof`,
            1
          );
          // console.log(result);
          Fulldata.map((ival, i) => {
            result.data.map((jval, j) => {
              if (ival.id == jval.customerid && jval.approval == 1) {
                ival.FormFilled = 'approved';
              } else if (ival.id == jval.customerid) {
                ival.FormFilled = 'yes';
              }
            })
          })
          //console.log(Buyer.data);
          //this.setState({ Data: Fulldata })
          this.setState({ SearchResult: Product.data });
        }
      }


    } catch (error) {
      console.log(error);
    }
  }

  handleTransactionList = async (e) => {
    this.setState({ selectedTransactionList: e });
    if (e.value == 1) {
      this.setState({
        ProcessTypeList: [{ label: "Evaluation", value: 1 }, { label: " Documentation", value: 2 },
        { label: "Physical", value: 3 }, { label: "Payment", value: 4 },
          , { label: "Refurbishment", value: 5 }]
      });
    } else if (e.value == 2) {
      this.setState({
        ProcessTypeList: [{ label: "Specification", value: 6 }, { label: "Sourcing", value: 7 },
        { label: "Contracting", value: 8 }, { label: "Delivery", value: 9 },]
      });
    }
  }
  handleProcessTypeList = async (e) => {
    this.setState({ selectedProcessTypeList: e });
  }

  render() {
    const { selectedProcess, selectedProcessTypeList } = this.state;
    return (<React.Fragment>
      <div className="row form-group">
        <div className="col-sm-1" />
        <div className="col-sm-3">
          <label htmlFor="exampleInputEmail1">Search By</label>
        </div>
        <div className="col-sm-5">
          <SingleSelect
            name="searchby"
            options={this.state.SearchByList}
            handleChange={d => this.handleModel(d)}
            selectedService={this.state.searchby}
          />
        </div>
      </div>

      {this.state.LocationList1.length ? <React.Fragment>
        <div className="row form-group">
          <div className="col-sm-1" />
          <div className="col-sm-3">
            <label htmlFor="exampleInputEmail1">
              Location
            </label>
          </div>
          <div className="col-sm-5">
            <MultiSelect
              options={this.state.LocationList1}
              handleChange={this.handleMultiLocation}
              selectedService={this.state.selectedService}
            />
          </div>
          <div className="col-sm-3" />
        </div>
      </React.Fragment> : null}
      {this.state.searchby.value == 1 ? <React.Fragment>
        <div className="row form-group">
          <div className="col-sm-1" />
          <div className="col-sm-3">
            <label htmlFor="exampleInputEmail1">Transaction Type</label>
          </div>
          <div className="col-sm-5">
            <SingleSelect
              name="searchby"
              options={this.state.TransactionList}
              handleChange={d => this.handleTransactionList(d)}
              selectedService={this.state.selectedTransactionList}
            />
          </div>
        </div>

        <div className="row form-group">
          <div className="col-sm-1" />
          <div className="col-sm-3">
            <label htmlFor="exampleInputEmail1">Process Type</label>
          </div>
          <div className="col-sm-5">
            <SingleSelect
              name="searchby"
              options={this.state.ProcessTypeList}
              handleChange={d => this.handleProcessTypeList(d)}
              selectedService={this.state.selectedProcessTypeList}
            />
          </div>
        </div>

      </React.Fragment> : null}
      {this.state.searchby.value == 2 ? <React.Fragment>
        <div className="row form-group">
          <div className="col-sm-1" />
          <div className="col-sm-3">
            <label htmlFor="exampleInputEmail1">UserType</label>
          </div>
          <div className="col-sm-5">
            <SingleSelect
              name="searchby"
              options={this.state.UserTypeList}
              handleChange={d => this.handleselectedtype(d)}
              selectedService={this.state.selectedtype}
            />
          </div>
        </div>

        <div className="row form-group">
          <div className="col-sm-1" />
          <div className="col-sm-3">
            <label htmlFor="exampleInputEmail1">User Name</label>
          </div>
          <div className="col-sm-5">
            <SingleSelect
              name="searchby"
              options={this.state.UserNameList}
              handleChange={d => this.handleselectedname(d)}
              selectedService={this.state.selectedname}
            />
          </div>
        </div>


        <div className="row form-group">
          <div className="col-sm-1" />
          <div className="col-sm-3">
            <label htmlFor="exampleInputEmail1">Process</label>
          </div>
          <div className="col-sm-5">
            <SingleSelect
              name="searchby"
              options={this.state.ProcessList}
              handleChange={d => this.handleselectedProcess(d)}
              selectedService={this.state.selectedProcess}
            />
          </div>
        </div>
      </React.Fragment> : null}


      <div className="row form-group">
        <div className="col-sm-2" />
        <div className="col-sm-3" />
        <div className="col-sm-5">
          <button
            type="button"
            className={`btn btn-primary`}
            disabled={this.state.disableValue}
            onClick={() => this.ProcessSearch()} >
            Search
          </button>
        </div>
        <div className="col-sm-2" />
      </div>

      {selectedProcess.value == 1 && this.state.SearchResult.length ? (
        <Datatable
          data={this.state.SearchResult}
          columnHeading={this.state.column}
        />
      ) : null}
      {selectedProcessTypeList.value == 1 && this.state.SearchResult.length ?
        <Datatable
          data={this.state.SearchResult}
          columnHeading={this.state.newcolumn1}
        />
        : null}
      {selectedProcessTypeList.value != 1 && this.state.SearchResult.length ? (
        <Datatable
          data={this.state.SearchResult}
          columnHeading={this.state.newcolumn2}
        />
      ) : null}
      {selectedProcess.value == 2 && this.state.SearchResult.length ? (
        <Datatable
          data={this.state.SearchResult}
          columnHeading={this.state.column1}
        />
      ) : null}

      {selectedProcess.value == 3 && this.state.SearchResult.length ? (
        <Datatable
          data={this.state.SearchResult}
          columnHeading={this.state.column2}
        />
      ) : null}

      {selectedProcess.value == 4 && this.state.SearchResult.length ? (
        <Datatable
          data={this.state.SearchResult}
          columnHeading={this.state.column2}
        />
      ) : null}
      {selectedProcess.value == 5 && this.state.SearchResult.length ? (
        <Datatable
          data={this.state.SearchResult}
          columnHeading={this.state.column2}
        />
      ) : null}
      {selectedProcess.value == 6 && this.state.SearchResult.length ? (
        <Datatable
          data={this.state.SearchResult}
          columnHeading={this.state.column2}
        />
      ) : null}

      {selectedProcess.value == 7 && this.state.SearchResult.length ? (
        <Datatable
          data={this.state.SearchResult}
          columnHeading={this.state.column2}
        />
      ) : null}
      {selectedProcess.value == 8 && this.state.SearchResult.length ? (
        <Datatable
          data={this.state.SearchResult}
          columnHeading={this.state.column2}
        />
      ) : null}
      {selectedProcess.value == 9 && this.state.SearchResult.length ? (
        <Datatable
          data={this.state.SearchResult}
          columnHeading={this.state.column2}
        />
      ) : null}

    </React.Fragment>)
  }
}
export default Dfcarsprogressearch;