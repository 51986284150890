import React, { Component } from "react";
import { Alert } from "reactstrap";
// import { Editor } from "react-draft-wysiwyg";
import { Editor } from "@tinymce/tinymce-react";
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from "draftjs-to-html";
import cmsContent from "../../../../MiddleWare/CmsContent";
import Datatable from "../../../../components/Datatable/Datatable";
import LoginModal from "../../../../components/Modal/Modal";
import FromLibrary from "../FromLibrary";
import { ACCESS_POINT } from "../../../../config";
import { ACCESS_SAMPLE } from "../../../../config";
import http from "../../../../MiddleWare/httpMiddleWare";
import Progress from "../../../../components/Extra/Progress";
import htmlToDraft from 'html-to-draftjs';




class Theme1 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// editorState: EditorState.createEmpty(),
			editorState: "<p></p>",
			getlibrarydataImage: props.that.getlibrarydataImage,
			getlibrarydataVideo: props.that.getlibrarydataVideo,
			getlibrarydataDocs: props.that.getlibrarydataDocs,
			data: props.that.data,
			btnDisable: false,
			isEdit: false,
			uploadPercentage: 0,
			screen: window.screen.height,
		}
	}

	async componentWillMount() {
		try {
			//console.log(ACCESS_SAMPLE)

			//Datatable
			if (this.props.that.data) {

				let pages = [];
				this.props.that.data.map((ival, i) => {
					if (ival.themeId === 1) {
						pages.push(ival);
					}
				})
				this.setState({ data: pages })
			}

			//Edit without selecting theme states
			// if (this.props.that.isEdit) {
			// 	//content1
			// 	const { contentBlocks, entityMap } = htmlToDraft(this.props.that.content1);
			// 	const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
			// 	const editorState = EditorState.createWithContent(contentState);

			//FileNames
			if (this.props.that.fileName1 == "undefined") {
				this.setState({ file1: '', fileName1: '' })
			}
			else {
				this.setState({ file1: this.props.that.fileName1, fileName1: this.props.that.fileName1 })
			}
			if (this.props.that.thumbnailname == "undefined") {
				this.setState({ thumbnail: '', thumbnailname: '' })
			}
			else {
				this.setState({ thumbnail: this.props.that.thumbnailname, thumbnailname: this.props.that.thumbnailname })
			}

			//Link to
			if (this.props.that.linkto == "undefined") {
				this.setState({ linkto: '' })
			}
			else {
				this.setState({ linkto: this.props.that.linkto })
			}

			// 	this.setState({
			// 		contentTitle1: this.props.that.contentTitle1, content1: this.props.that.content1, searchTags: this.props.that.searchTags,
			// 		editorState,
			// 		editId: this.props.that.id,
			// 		isEdit: this.props.that.isEdit,
			// 		data: this.props.that.datatable
			// 	});
			// }
			if (this.props.that.isEdit) {
				let editorState = this.props.that.theme4 ? this.props.that.theme4 : this.props.that.content1
					.split("SDAS/Video/")
					.join(`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/`);

				this.setState({
					isEdit: this.props.that.isEdit, editId: this.props.that.id, editorState, data: this.props.that.datatable,
					contentTitle1: this.props.that.contentTitle1, content1: this.props.that.content1, searchTags: this.props.that.searchTags,
				});
			}
		} catch (e) {
			console.log(e);
		}
	}

	column = [
		{
			Header: "Page",
			accessor: "name"
		},
		{
			Header: "Status",
			accessor: "status"
		},
		{
			Header: "Theme",
			accessor: "edit",
			Cell: (d) => this.showTheme(d),
		},
		{
			Header: "Visibility",
			accessor: "edit",
			Cell: d => this.showVisibility(d)
		},
		{
			Header: "Require Login",
			accessor: "requireLogin"
		},
		{
			Header: "Change Login Status",
			accessor: "edit",
			Cell: d => this.changeLoginStatus(d)
		},
		{
			Header: "Edit",
			accessor: "edit",
			Cell: d => this.Editpages(d)
		},
		{
			Header: "Preview",
			accessor: "edit",
			Cell: d => this.PreviewPage(d)
		}
	];
	showTheme = (d) => {
		return `Theme-${d.original.themeId}(To Add Content for Page) `;
	};



	PreviewPage = (d) => {

		return (<button
			type="button"
			className="btn btn-info"
			onClick={() => this.PreviewRoute(d)}
		>
			Preview
		</button>
		);
	}

	PreviewRoute = (d) => {
		console.log(d);
		let id = d.original.id;
		let customerId = d.original.customerId;

		console.log(id)
		if (id) {
			//this.props.history.replace(`http://35.169.94.138:3030/samplesite/commonpg/${id}`);
			window.open(
				`${ACCESS_SAMPLE}/samplesite/commonpg/${id}/${customerId}`,
				'_blank'
			);

		}

	}

	handlechange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};
	selectImage1 = e => {
		this.setState({ file1: e.target.files[0], fileName1: e.target.files[0].name });
	};

	selectthumbnail = e => {
		this.setState({
			thumbnail: e.target.files[0],
			thumbnailname: e.target.files[0].name
		});
	};
	showVisibility = id => {
		return (
			<button
				type="button"
				className="btn btn-info"
				onClick={() => this.updateVisibility(id)}
			>
				Hide/Show
			</button>
		);
	};
	updateVisibility = async value => {
		const index = value.index;
		const previousData = [...this.state.data];
		console.log(previousData);
		const newData = { ...previousData[index] };

		if (newData.status === "show") {
			newData.status = "hide";
		} else {
			newData.status = "show";
		}
		const id = newData.id;
		const data = previousData.filter(value => value.id !== id);
		data.splice(index, 0, newData);
		try {
			const result = await cmsContent.updatePage(newData);
			if (result) {
				this.setState({ data: data });
			}
		} catch (error) {
			this.setState({ data: previousData });
			console.log(error);
		}
	};

	changeLoginStatus = id => {
		return (
			<button
				type="button"
				className="btn btn-info btn-lg col-lg-12" style={{ paddingLeft: 5, marginBottom: '4px', fontSize: '10px' }}
				onClick={() => this.updateLogin(id)}
			>
				Change Login Status
			</button>
		);
	};

	updateLogin = async value => {
		const index = value.index;
		const previousData = [...this.state.data];
		const newData = { ...previousData[index] };
		if (newData.requireLogin === "yes") {
			newData.requireLogin = "no";
		} else {
			newData.requireLogin = "yes";
		}
		const id = newData.id;
		const data = previousData.filter(value => value.id !== id);
		data.splice(index, 0, newData);
		this.setState({ data });
		try {
			const result = await cmsContent.updateLoginStatus(newData);
			if (result) {
				this.setState({ data: data });
			}
		} catch (error) {
			this.setState({ data: previousData });
			console.log(error);
		}
	};

	Editpages = value => {
		return (
			<button className="btn btn-info" onClick={() => this.onEdit(value)}>
				Edit
			</button>
		);
	};
	onEdit = d => {
		const org = d.original;
		const index = d.index;
		//Content1
		// const { contentBlocks, entityMap } = htmlToDraft(org.content1);
		// const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
		// const editorState = EditorState.createWithContent(contentState);
		const editorState = org.sheetContent ? org.sheetContent : org.content1
		console.log(d, "khdkjhdjhdjhdj");
		//During Edit theme Selection
		const options = [
			{ label: "Theme1", value: "1" },
			{ label: "Theme2", value: "2" },
			{ label: "Theme3", value: "3" },
			{ label: "Theme4", value: "4" },
			{ label: "Theme5", value: "5" },
			{ label: "Theme6", value: "6" },
			{ label: "Theme7", value: "7" },
			{ label: "Theme8", value: "8" },
			{ label: "Theme9", value: "9" }
		];
		if (typeof this.props.these === 'function') {
			this.props.these('pageName', org.name);
			this.props.these('autherSelected', this.props.that.authorlist.filter(ival => ival.value === org.authorid)[0]);
			this.props.these('themeSelected', options.filter(ival => ival.value == org.themeId)[0]);
			this.props.these('checked', org.show == 1 ? 1 : 0);
		}
		//FileName
		if (org.file == "undefined") {
			this.setState({ file1: '', fileName1: '' })
		}
		else {
			this.setState({ file1: org.file, fileName1: org.file, })
		}
		if (org.file2 == "undefined") {
			this.setState({ thumbnail: '', thumbnailname: '' })
		}
		else {
			this.setState({ thumbnail: org.file2, thumbnailname: org.file2, })
		}
		let username = JSON.parse(localStorage.getItem('userlog')).userName
		let imgsrcchange = editorState.split(username + '/').join(`${ACCESS_POINT}/superAdmin/file?fileurl=${username}/`)

		this.setState({
			contentTitle1: org.contentTitle1,
			searchTags: org.searchTags,
			linkto: org.linkto,
			editorState: imgsrcchange,
			isEdit: true,
			editId: org.id,
			Index: index
		});
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth"
		});
	}
	onEditorStateChange = editorState => {
		this.setState({
			editorState
		});
	};
	handleModelChange = content => {
		this.setState({
			editorState: content
		});
	};
	viewmedia = async value => {
		this.setState({
			videotype: "",
			viewmedia: ACCESS_POINT + `/superAdmin/file?fileurl=${value}`
		});
		var that = this;
		setTimeout(function () {
			if (value.split(".")[1] === "mp4") {
				that.setState({ videotype: "video" });
			} else if (
				value.split(".")[1] === "jpg" ||
				value.split(".")[1] === "png" ||
				value.split(".")[1] === "jpeg"
			) {
				that.setState({ videotype: "image" });
			} else {
				that.setState({ videotype: null });
			}
		}, 500);
	}
	contentHeading = [
		{
			Header: "Search By Hint",
			accessor: "hint"
		},
		{
			Header: "File Name",
			accessor: "label",
			Cell: d => this.aModalLink(d)
		},
		{
			Header: "Select",
			accessor: "edit",
			Cell: d => this.checktest(d)
		}
	];
	aModalLink = id => {
		let str = id.original.label;
		let res = str.split("/");
		return (
			<a href="#" onClick={() => this.viewmedia(id.original.label)}>
				{res[2]}
			</a>
		);
	};
	checktest = id => {
		return (
			<button
				type="button"
				className="btn btn-info"
				data-dismiss="modal"
				onClick={() =>
					this.setState({ [this.state.label]: id.original.label, [this.state.label1]: id.original.label })
				}
			>
				Select
			</button>
		);
	};

	submitCategory = async e => {
		console.log('1jjl')
		e.preventDefault();
		//Getting vaue from parent page
		const {
			checked,
			autherSelected,
			pageName,
			customerId
		} = this.props.that;

		//Getting vaue from current page
		const {
			contentTitle1,
			editorState,
			searchTags,
			linkto,
			file1,
			thumbnail,
			editId,
			Index
		} = this.state;

		// let editorContent = draftToHtml(
		// 	convertToRaw(editorState.getCurrentContent())
		// );
		if (!autherSelected) {
			this.props.these('errorAuthorName', 'Select Author');
			return false
		} else if (!pageName) {
			this.props.these('errorAuthorName', '')
			this.props.these('errorPageName', 'Enter Page name');
			return false;
		}
		//Use this for parent state change
		// if (typeof this.props.these === 'function') {
		// 	this.props.these('errorPageName','e.pag.value');
		// 	this.props.these('errorAuthorName','e.target.value');
		// }

		// if (!authorId) {
		//   this.setState({ errorAuthorName: "Select Author" });
		//   return false;
		// } else if (!pageName) {
		//   this.setState({ errorAuthorName: null, errorPageName: "Add Page Name" });
		//   return false;
		// } else if (!themeId) {
		//   this.setState({ errorPageName: "", errorThemeId: "Select Theme" });
		//   return false;
		// } else {
		if (!contentTitle1) {
			this.setState({
				errorThemeId: "",
				errorContentTitle1: "Enter Content Title"
			});
			return false;
		}
		// else if (!editorContent) {
		// 	this.setState({
		// 		errorContentTitle1: "",
		// 		errorContent1: "Enter Content"
		// 	});
		// 	return false;
		// }
		else if (!editorState) {
			this.setState({
				errorContentTitle1: "",
				errorContent1: "Enter Content"
			});
			return false;
		}
		else if (!searchTags) {
			this.setState({
				errorContentTitle1: "",
				errorSearchTags: "Enter Search Tags"
			});
			return false;
		} else if (!file1) {
			this.setState({
				errorSearchTags: "",
				errorFile1: "Select File to Upload"
			});
			return false;
		}
		this.setState({
			errorsubTitleSelected: "",
			errorFile2: "",
			btnDisable: false
		});
		this.setState({ btnDisable: true });
		let today = new Date();
		var dateformat = today.getDate();
		if (today.getDate() < 10) {
			dateformat = "0" + today.getDate();
		}
		let date =
			today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + dateformat;

		const formData = new FormData();
		formData.append("name", pageName.trim());
		formData.append("themeId", 1);
		if (this.props.that.isEdit) {
			console.log(this.props.that.autherSelected)
			console.log('authorid', this.props.that.autherSelected[0].value)
		}
		else {
			formData.append("authorid", autherSelected.value);
		}
		formData.append("contentTitle1", contentTitle1.trim());
		formData.append("content1", editorState);
		formData.append("sheetContent", editorState);
		formData.append("show", checked);
		formData.append("searchTags", searchTags);
		formData.append("linkto", linkto);
		formData.append("customerId", customerId);
		formData.append("Date", date);
		formData.append("status", "show");
		formData.append("file", file1);
		formData.append("file2", thumbnail);

		try {
			if (this.state.isEdit) {
				console.log('1')

				formData.append("id", editId);
				console.log([...formData])
				let { data } = await http.put(
					ACCESS_POINT + `/cmsContent/tbl_pages`,
					formData,
					{
						headers: {
							"Content-Type": "multipart/form-data"
						},
						onUploadProgress: progressEvent => {
							this.setState({
								uploadPercentage: parseInt(
									Math.round((progressEvent.loaded * 100) / progressEvent.total)
								)
							});
							setTimeout(() => this.setState({ uploadPercentage: 0 }), 3000);
							///10000
						}
					}
				);

				if (data && data.length) {
					data = data[0];
					const previousData = [...this.state.data];

					if (this.props.that.isEdit) {
						let index = this.props.that.data.indexOf(this.props.that.data1);
						previousData[index] = data;

					}
					else {
						previousData[Index] = data;
					}

					console.log('previous', previousData)
					setTimeout(() => this.setState({
						contentTitle1: '',
						// editorState: EditorState.createEmpty(),
						editorState: "<p></p>",
						searchTags: '',
						linkto: '',
						file1: '',
						thumbnail: '',
						btnDisable: false,
						isEdit: false,
						fileName1: "",
						label: "",
						thumbnailname: "",
						label1: "",
						errorContentTitle1: '',
						errorContent1: '',
						errorSearchTags: '',
						errorFile1: '',
						errorFile2: '',

						data: previousData
					}), 3000);
					this.props.these('pageName', '');
					this.props.these('checked', 0);
					this.props.these('errorPageName', '');
					this.props.these('autherSelected', '');
					this.props.these('themeSelected', '')
					this.props.these('isEdit', false)
					//this.props.these('alertVisible',true);

					setTimeout(() => this.props.these('alertVisible', true), 3000);
				}
			} else {
				formData.append("requireLogin", 'no');
				let { data } = await http.post(
					ACCESS_POINT + `/cmsContent/tbl_pages`,
					formData,
					{
						headers: {
							"Content-Type": "multipart/form-data"
						},
						onUploadProgress: progressEvent => {
							this.setState({
								uploadPercentage: parseInt(
									Math.round((progressEvent.loaded * 100) / progressEvent.total)
								)
							});
							setTimeout(() => this.setState({ uploadPercentage: 0 }), 3000);
							///10000
						}
					}
				);
				if (data && data.length) {
					data = data[0];
					const previousData = [data, ...this.state.data];
					// previousData[Index]=data;
					console.log(previousData);
					setTimeout(() => this.setState({
						contentTitle1: '',
						editorState: EditorState.createEmpty(),
						searchTags: '',
						linkto: '',
						file1: '',
						thumbnail: '',
						btnDisable: false,
						isEdit: false,
						fileName1: "",
						label: "",
						thumbnailname: "",
						label1: "",
						errorContentTitle1: '',
						errorContent1: '',
						errorSearchTags: '',
						errorFile1: '',
						errorFile2: '',
						data: previousData
					}), 3000);
					this.props.these('pageName', '');
					this.props.these('checked', 0);
					this.props.these('errorPageName', '');
					this.props.these('autherSelected', '');
					this.props.these('themeSelected', '')
					setTimeout(() => this.props.these('alertVisible', true), 3000);
				}


			}
		} catch (error) {
			console.log(error);
		}
	};
	render() {
		const { uploadPercentage } = this.state;
		return (
			<React.Fragment>
				<div className="row form-group">
					<div className="col-sm-2" />
					<div className="col-sm-2">
						<label htmlFor="exampleInputEmail1">
							Content Title
						</label>
					</div>
					<div className="col-sm-5">
						<textarea
							type="text"
							className="form-control"
							id="contentTitle1"
							name="contentTitle1"
							placeholder="Enter Content Title"
							value={this.state.contentTitle1}
							onChange={this.handlechange}
						/>
					</div>
					<div className="col-sm-3" />
				</div>
				<div className="row form-group">
					<div className="col-sm-4" />
					<span className="error-show ">
						{this.state.errorContentTitle1}
					</span>
				</div>

				<div className="row form-group">
					<div className="col-sm-2" />
					<div className="col-sm-2">
						<label htmlFor="exampleInputEmail1">Content 1</label>
					</div>
					<div className="col-sm-5">
						<Editor
							apiKey="sqrfu0ffkb8d8fdyjqgxuvtkcvru79noq34s32622j87etzv"
							// initialValue={this.state.content}
							value={this.state.editorState}
							init={{
								menubar: true,
								plugins: [
									"advlist autolink lists link image charmap print preview anchor",
									"searchreplace visualblocks code fullscreen",
									"insertdatetime media table paste code help wordcount"
								],
								toolbar:
									"undo redo | formatselect | bold italic backcolor | \
alignleft aligncenter alignright alignjustify | \
bullist numlist outdent indent | removeformat | code | preview | help"
							}}
							onEditorChange={this.handleModelChange}
						/>
						{/* <Editor
						editorState={this.state.editorState}
						wrapperClassName="wrapper-className"
						editorClassName="editor-className editor-border"
						toolbarClassName="toolbar-className"
						onEditorStateChange={this.onEditorStateChange}
						toolbar={{
							options: [
								"inline",
								"blockType",
								"fontSize",
								"fontFamily",
								"list",
								"link"
							]
						}}
						value={this.state.editorState}
					/> */}
					</div>
					<div className="col-sm-3" />
				</div>

				<div className="row form-group">
					<div className="col-sm-4" />
					<span className="error-show ">{this.state.errorContent1}</span>
				</div>

				<div className="row form-group">
					<div className="col-sm-2" />
					<div className="col-sm-2">
						<label htmlFor="exampleInputEmail1">Search Tags</label>
					</div>
					<div className="col-sm-5">
						<textarea
							type="text"
							className="form-control"
							id="searchTags"
							name="searchTags"
							placeholder="Enter Search Tags"
							value={this.state.searchTags}
							onChange={this.handlechange}
						/>
					</div>
					<div className="col-sm-3" />
				</div>
				<div className="row form-group">
					<div className="col-sm-4" />
					<span className="error-show ">{this.state.errorSearchTags}</span>
				</div>

				<div className="row form-group">
					<div className="col-sm-2" />
					<div className="col-sm-2">
						<label htmlFor="exampleInputEmail1">Upload Image</label>
					</div>
					<div className="col-sm-5 custom-file">
						<input
							type="file"
							className="custom-file-input"
							id="customFile"
							onChange={this.selectImage1}
						/>
						<label
							className="custom-file-label"
							htmlFor="customFile"
						>
							{/* {this.state.fileName1 ? this.state.fileName1.split("/")[2] : null} */}
							{/* {this.state.fileName1 ? this.state.fileName1 : null} */}
							Choose File
						</label>
						<span>
							<LoginModal
								buttonTitle="Select Image from Library"
								title="Select Image"
								id="fsdfsf"
								maxwidth="modal-xl"
								bodyText={
									<FromLibrary
										datas={this.state.getlibrarydataImage}
										columnHeading={this.contentHeading}
										imgsource={this.state.viewmedia}
										type={this.state.videotype}
									/>
								}
								atagLink={true}
								onClick={() => this.setState({ label1: 'file1', label: "fileName1" })}
							/>
						</span>
					</div>
					<div className="col-sm-3">
						<input
							type="text"
							className="form-control"
							placeholder="Direct to Url"
							onChange={d =>
								this.setState({ linkto: d.target.value })
							}
							value={this.state.linkto}
						/>
					</div>
				</div>
				<div className="row form-group">
					<div className="col-sm-4" />
					<span className="file-show">{this.state.fileName1}</span>
				</div>
				<div className="row form-group">
					<div className="col-sm-4" />
					<span className="error-show ">{this.state.errorFile1}</span>
				</div>

				<div className="row form-group pt-4">
					<div className="col-sm-2" />
					<div className="col-sm-2">
						<label htmlFor="exampleInputEmail1">Select Thumbnail</label>
					</div>

					<div className="col-sm-5">
						<input
							type="file"
							className="custom-file-input"
							id="customFile"
							onChange={this.selectthumbnail}
						/>
						<label
							className="custom-file-label"
							htmlFor="customFileThumbnail"
						>
							{/* {this.state.thumbnailname ? this.state.thumbnailname.split("/")[2] : null} */}
							{/* {this.state.thumbnailname ? this.state.thumbnailname : null} */}
							Choose File
						</label>
						<span>
							<LoginModal
								buttonTitle="Select Image from Library"
								title="Select Image"
								id="hfdsf"
								bodyText={
									<FromLibrary
										datas={this.state.getlibrarydataImage}
										columnHeading={this.contentHeading}
										imgsource={this.state.viewmedia}
										type={this.state.videotype}
									/>
								}
								atagLink={true}
								onClick={() => this.setState({ label1: 'thumbnail', label: "thumbnailname" })}
							/>
						</span>
					</div>
				</div>
				<div className="row form-group">
					<div className="col-sm-4" />
					<span className="file-show">{this.state.thumbnailname}</span>
				</div>
				<div className="row form-group">
					<div className="col-sm-2" />
					<div className="col-sm-7">
						{uploadPercentage > 0 && (
							<Progress percentage={uploadPercentage} />
						)}
					</div>
					<div className="col-sm-3" />
				</div>
				<div className="row form-group">
					<div className="col-sm-2" />
					<div className="col-sm-2" />
					<div className="col-sm-5">
						<button
							type="button"
							className={`btn ${this.state.isEdit ? 'btn-secondary' : 'btn-primary'}`}
							disabled={this.state.btnDisable}
							onClick={this.submitCategory}
						>
							{this.state.isEdit ? 'Update' : 'Add Page'}
						</button>
					</div>
					<div className="col-sm-3" />
				</div>

				<div className="row form-group">
					<div className="col-sm-12">
						{this.state.data ? (<Datatable
							data={this.state.data}
							columnHeading={this.column}
						/>) : null}
					</div>
				</div>

			</React.Fragment>
		);
	}
}

export default Theme1;