import React, { Component } from 'react';
import CmsContent from '../../../MiddleWare/CmsContent';
import Datatable from "../../../components/Datatable/Datatable";
import { Alert } from 'reactstrap';
import SingleSelect from "../../../components/Form/SingleSelect";
import { confirmAlert } from "react-confirm-alert";
import FormMiddleWare from "../../../components/Form/FormMiddleware";
import cmsContent from "../../../MiddleWare/CmsContent";
import { ACCESS_POINT, ImgSTEERAUTO } from '../../../config';
import iconClose from "../../../images/CMS/icon-hamburger-close.svg";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size'

// Register the plugins
registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginImageResize,
    FilePondPluginFileValidateType,
    FilePondPluginFileValidateSize,
    FilePondPluginImageValidateSize
);
class SiteImages extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            themeId: null,
            Model_data: [],
            changenumber: null,
            fullData: [],
            pageopt: [{ label: "HOME", value: "/" }, { label: "BUYCAR", value: "/buycars" }, { label: "SELLCAR", value: "/sellyourcars" },
            { label: "TESTIMONIAL", value: "/Testimonial" }, { label: "SERVICE", value: "/Service" },
            { label: "SOLDCARS", value: "/JustSold" }, { label: "UPCOMINGCARS", value: "/UpComingCars" },
            { label: "ABOUTUS", value: "/aboutus" }, { label: "CONTACTUS", value: "/Contactus" },
            { label: "TEST", value: "/test" }, { label: "POLICY", value: "/Policy" },
            { label: "SINGLECARLIST", value: "/SingleListCars" },
            ],
            imagecontent: [],
            index: null,
            alertVisible: false,
            active: 1,
            page: [],
            customerId: localStorage.getItem("userId"),
            column: [

                {
                    Header: "Page",
                    accessor: "name",
                    Cell: (d) => this.getValueFromArray(d.original.name, this.state.pageopt)
                },
                {
                    Header: "Web view",
                    accessor: "file",
                    Cell: d => this.viewimage(d.original.file)
                },

                {
                    Header: "Mobile view",
                    accessor: "file2",
                    Cell: d => this.viewimage(d.original.file2)
                },
                {
                    Header: "Edit",
                    accessor: "isImage",
                    Cell: d => this.dataTableButton('danger', 'Edit', () => this.buttonEdit(d.original), this.state.btnDisable)
                },


            ],
        };
    }
    async componentDidMount() {
        this.MainFun()
    }
    MainFun = async () => {
        try {
            this.setState({ loading: true })
            let steer = await cmsContent.getFreedom(
                "*",
                "tbl_pages",
                "customerId = 620 and status='hide'",
                1,
                1
            )
            if (steer && steer.data.length > 0) {
                this.setState({ fullData: steer.data })
            }
            this.setState({ loading: false })
        } catch (err) {
            console.log(err);
            this.setState({ loading: false })
        }
    }
    buttonEdit = async (d) => {
        let { page, pageopt } = this.state;
        this.setState({ webviewImg: null, mobileviewImg: null })
        if (d.name) {
            page = pageopt.filter(e => e.value == d.name)[0]
        }
        if (d.file) {
            this.imgFun("webviewImg", "webviewjson", 'file', d)
        }
        if (d.file2) {
            this.imgFun("mobileviewImg", "mobileviewjson", 'file2', d)
        }
        this.setState({
            page: d.name ? pageopt.filter(e => e.value == d.name)[0] : null,
            webviewjson: d.file ? JSON.parse(d.file) : null,
            Originalwebviewjson: d.file ? JSON.parse(d.file) : null,
            mobileviewjson: d.file2 ? JSON.parse(d.file2) : null,
            Originalmobileviewjson: d.file2 ? JSON.parse(d.file2) : null,
            editid: d.id
        })
    }
    removeimg = async (key, i, section, file) => {
        let images = null
        let img = null

        images = this.state[section] //images field
        img = this.state[file] //old file field

        if (images && img) {
            images && images.splice(i, 1)
            img && img.splice(i, 1)
            await this.setState({ [section]: images, [file]: img })
            this.imgFun(section, file, key)

        }


    }
    imgFun = async (imagestate, jsonstate, field = null, d = null) => {
        let ImageArray = []

        let images = d && d[field] ? JSON.parse(d[field]) : this.state[jsonstate];
        if (images && images.length > 0) {
            let w = images.map(async (ival, i) => {
                await ImageArray.push(
                    <div>
                        <div style={{ paddingLeft: '160px', position: 'relative' }} >
                            <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                                onClick={() => this.removeimg(field, i, imagestate, jsonstate)}></img>
                        </div>
                        <img src={ImgSTEERAUTO + ival}
                            onClick={() => { window.open(ImgSTEERAUTO + ival) }}
                            style={{
                                width: "200px", height: "200px", display: "inline-block", padding: "10px"

                            }} />
                    </div>
                )
            })
            await Promise.all(w)
            this.setState({ [imagestate]: ImageArray })
        }

    }
    handlemake = async (e) => {
        const { fullData } = this.state
        this.reset()
        this.setState({ page: e });
        if (fullData && fullData.length) {
            let val = await fullData.filter(x => x.name == e.value)
            if (val && val.length) {
                this.buttonEdit(val[0])
            }
        }
    };

    update = async () => {
        const { Originalwebviewjson, Originalmobileviewjson, page, webview, mobileview, webviewjson, mobileviewjson, editid } = this.state;
        if (!page) {
            this.setState({ alertVisible: true, textalert: 'Please Select', color: 'danger' })
            return true
        } else if ((!webview) && (!editid)) {
            this.setState({ alertVisible: true, textalert: 'Please select web view file', color: 'danger' })
            return true
        } else if ((!mobileview) && (page.value != '/') && (!editid)) {
            this.setState({ alertVisible: true, textalert: 'Please select mobile view file', color: 'danger' })
            return true
        }
        if (webview && webview.length) {
            let val = webview.every(x => x.size <= 300000)
            if (!val) {
                alert("Please Check Image is Greater than 300KB")
                return true
            }
        }
        if (mobileview && mobileview.length) {
            let val = mobileview.every(x => x.size <= 300000)
            if (!val) {
                alert("Please Check Image is Greater than 300KB")
                return true
            }
        }

        try {
            this.setState({ alertVisible: false, btnDisable: true })
            const formData = new FormData()

            formData.append('name', page.value)
            formData.append('customerId', 620)
            formData.append('status', 'hide')
            webview && webview.map((ival, i) => {
                formData.append('file', ival)
            });
            mobileview && mobileview.map((ival, i) => {
                formData.append('file2', ival)
            })
            if (webviewjson) formData.append('file', JSON.stringify(webviewjson))
            if (mobileviewjson) formData.append('file2', JSON.stringify(mobileviewjson))

            let deletefiles = []
            if (webviewjson && Originalwebviewjson) {
                const Files = Originalwebviewjson.filter(e => !webviewjson.some(x => e == x))
                if (Files.length) {
                    deletefiles = [...Files]
                }
            }
            if (mobileviewjson && Originalmobileviewjson) {
                const Files = Originalmobileviewjson.filter(e => !mobileviewjson.some(x => e == x))
                if (Files.length) {
                    deletefiles = [...deletefiles, ...Files]
                }
            }
            if (deletefiles.length > 0) formData.append('deleteArray', JSON.stringify(deletefiles))

            const id = editid ? editid : 'id'
            const result = await cmsContent.MultiArrayFileUpload('tbl_pages', formData, id)
            if (result) {
                this.setState({ alertVisible: true, btnDisable: false, textalert: 'Form successfully updated', color: 'success' })
                this.reset()
                this.MainFun()
                setTimeout(() => {
                    this.setState({ alertVisible: false, textalert: 'Form successfully updated', color: 'success' })
                }, 3000);
            }
        } catch (error) {
            console.log(error);
            this.setState({ btnDisable: false })
        }

    }
    viewimage = d => {

        if (d) {
            let image = JSON.parse(d)
            let imgArray = []
            if (image && image.length) {
                image.map((ival, i) => {
                    imgArray.push(<img src={ImgSTEERAUTO + ival} alt="Error" style={{ width: 80, height: 80, objectFit: 'contain' }} ></img >
                    )

                })
                return (
                    <div>
                        {imgArray}
                    </div >
                )
            } else return <center>-</center>
        } else {
            return <center>-</center>
        }
    };
    FILEUPLOAD = (e, doc) => {
        if (e) {
            this.setState({
                [doc]: e.map(fileItem => fileItem.file)
            });
        }
    };

    reset = () => {
        this.setState({
            page: null,
            webview: null,
            mobileview: null,
            webviewjson: null,
            mobileviewjson: null,
            editid: null,
            mobileviewImg: null,
            webviewImg: null,
            color: 'success'
        })
    }
    render() {
        const { fullData, textalert, alertVisible, editid, webviewImg, mobileviewImg, page } = this.state;


        return (
            <>

                <main className="main my-4">
                    <main className="container-fluid">

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h1>Image Assigning </h1>
                                    </div>
                                    <br />
                                    <div className="row form-group">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-7">
                                            <Alert
                                                className="badge-content"
                                                color={this.state.color}
                                                isOpen={alertVisible}
                                                toggle={this.onDismiss}
                                            >
                                                {textalert}
                                            </Alert>
                                        </div>
                                        <div className="col-sm-3" />
                                    </div>
                                    <br />
                                    <div className="row form-group">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2" >
                                            <label>Select Page :</label>
                                        </div>
                                        <div className="col-sm-4">
                                            <SingleSelect
                                                placeholder="Select Page"
                                                onChange={(e) => this.handlemake(e)}
                                                options={this.state.pageopt}
                                                selectedService={this.state.page} />
                                        </div>
                                        <div className="col-sm-3" />
                                    </div>
                                    {page && page.value &&
                                        <>
                                            <div className="row form-group">
                                                <div className="col-sm-2" />
                                                <div className="col-sm-2" >
                                                    <label>Web View :</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    {/* width:1200px and height:540px */}
                                                    <FilePond
                                                        files={this.state.webview}
                                                        allowReorder={true}
                                                        allowMultiple={true}
                                                        // allowImageResize={true}
                                                        allowImageValidateSize={true}
                                                        imagePreviewMinHeight="50"
                                                        imagePreviewMaxHeight="50"
                                                        // imageValidateSizeMaxWidth={1200}
                                                        // imageValidateSizeMaxHeight={540}
                                                    //    maxFileSize={'3072KB'}
                                                        acceptedFileTypes={["image/*"]}
                                                        onupdatefiles={fileItems => {
                                                            this.FILEUPLOAD(fileItems, 'webview');

                                                        }}

                                                        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                                    />
                                                    {page && page.value && page.value == "/" ?
                                                        <label style={{ color: "black" }} >Dimension : {`1200 * 540`}</label>
                                                        :
                                                        <label style={{ color: "black" }} >Dimension : {`1200 * 200`}</label>

                                                    }
                                                    <br />
                                                    {/* <label style={{ color: "black" }} >Maximum Size : 3MB</label><br /> */}

                                                </div>
                                                <div className="col-sm-3" />
                                            </div>
                                            <div className="row form-group">
                                                <div className="col-sm-2"></div>
                                                <div className="col-sm-2" />
                                                <div className="col">
                                                    {webviewImg ? webviewImg : null}
                                                </div>
                                            </div>
                                            {page && page.value && page.value != "/" &&
                                                <>
                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2" >
                                                            <label>Mobile View :</label>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            {/* width:360px and height:150px */}
                                                            <FilePond
                                                                files={this.state.mobileview}
                                                                allowReorder={true}
                                                                allowMultiple={true}
                                                                allowImageResize={true}
                                                                allowImageValidateSize={true}
                                                                // imageValidateSizeMaxWidth={360}
                                                                // imageValidateSizeMaxHeight={150}
                                                            //    maxFileSize={'3072KB'}
                                                                imagePreviewMinHeight="50"
                                                                imagePreviewMaxHeight="50"
                                                                acceptedFileTypes={["image/*"]}
                                                                onupdatefiles={fileItems => {
                                                                    this.FILEUPLOAD(fileItems, 'mobileview');

                                                                }}
                                                                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                                            />
                                                            {/* <label style={{ color: "black" }} >Dimension : {`360 * 150`}</label><br />
                                                            <label style={{ color: "black" }} >Maximum Size : 3MB</label><br /> */}

                                                        </div>
                                                        <div className="col-sm-3" />
                                                    </div>
                                                    <div className="row form-group">
                                                        <div className="col-sm-2"></div>
                                                        <div className="col-sm-2" />
                                                        <div className="col">
                                                            {mobileviewImg ? mobileviewImg : null}
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </>}

                                    <div className="row form-group">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2" >
                                        </div>
                                        <div className="col-sm-4">
                                            <button className='btn btn-sm btn-primary' disabled={this.state.btnDisable} onClick={() => this.update()}>
                                                {this.state.btnDisable ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;
                                                {editid ? "Update" : "Submit"}
                                            </button>
                                        </div>
                                        <div className="col-sm-3" />
                                    </div>
                                    <div className="card-body">
                                        {this.state.loading ? <div className="text-center"> <i
                                            className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                                        ></i></div> : fullData && fullData.length > 0 ? (
                                            <Datatable data={fullData} columnHeading={this.state.column} />
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </main>
            </>
        );
    }
}

export default SiteImages;