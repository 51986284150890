import React, { Component, useRef } from "react";
// import vidosrc from "./Hellovideo/home.mp4";
import { ACCESS_POINT } from "../../config";
import "./privacy.css";
import { FormMiddleWare } from "../../components/Form";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import lognLogo from "./brandMainLogo.png"

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  view
} from "reactstrap";
import login from "../../MiddleWare/Login";
import CmsContent from "../../MiddleWare/CmsContent";
import "./privacy.css";
let link =
  ACCESS_POINT +
  "/superAdmin/file?fileurl=/SDAS/Video/difuzaVideo_1572615730456.mp4";

var vidoimg = require("./Hellovideo/home.jpg");
var img2 = require("./img/login.png");
export default class Hello extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      alertVisible: false,
      mobileNumber: "",
      mobile: "",
      password: "",
      errorpassword: "",
      loginwpass: 0,
      isToggleOn: false,
      errorMobileNumber: "",
      errorMobileNumber1: "",
      errorlogin: "",
      set: false,
      preloader1: false,
      show: 1,
      placeholder: 1,
      local: window.location.href.split("/")[2] === "localhost:3000",
      button: "Sign In",
      browser: localStorage.getItem("browser"),
      Ip: localStorage.getItem("IP"),
      IPTime: localStorage.getItem("IPTime"),
      device: localStorage.getItem("device"),
      os: localStorage.getItem("os"),
      preloader: false,
      preloaderwp: false,
      contactname: "",
      contactalertvisible: false,
      contactloader: false,
      dataimage: "",
      cotacterrornumber: "", errorcaptchacapture: "", cotacterroremail: "",
      isToastOpen: false, Toastmsg: null, Toastcolor: null,
      generatedNumber: this.generateRandomNumber(),
      inputcaptcha: '',
      isCaptchaValid: false,
      isCaptchainValid: false,
    };
  }




  success = (response) => {
    var res = response.profileObj;

    console.log(res);

    // debugger;
    let email = res.email;
    this.setState({ preloader1: true });
    this.chechmail(email)
  }
  chechmail = async (email) => {
    if (email === null) {
      this.setState({ preloader1: false });
      this.toast("Email must not be Empty");
      return false;
    } else {
      let { data: result } = await CmsContent.GoogleLogin(email)
      console.log(result, email);
      if (result.code == "User Found") {
        let { data: login_otp_notsend } = await CmsContent.getFreedom(
          "*",
          "tbl_user_web",
          `email="${email}"`,
          1,
          1
        );
        console.log(login_otp_notsend);

        if (login_otp_notsend.length) {
          const result = await login.Freelogin(
            login_otp_notsend[0].mobileNumber,
            ""
          );
          let userOrder = await CmsContent.getFreedom(
            "*",
            "tbl_UserType",
            `id	 = ${login_otp_notsend[0].userType}`,
            "id",
            "id"
          );

          if (userOrder) {
            localStorage.setItem(`usertypelog`, JSON.stringify(userOrder.data[0]));
          }
          if (this.state.local || result) {
            let userlog = "";
            try {
              const result = await login.Freelogin(login_otp_notsend[0].mobileNumber, "");
              // this.setState({ preloader: false });
              if (result) {
                var id = result.data[0].id;
                let categoryArray = {};
                categoryArray.loginStatus = "online";
                const { data: data } = await CmsContent.updateMaster(
                  "tbl_user_web",
                  id,
                  categoryArray
                );

                if (data) {
                  const result1 = await CmsContent.getFreedom(
                    "*",
                    "tbl_user_web",
                    "loginStatus = 'online'",
                    1,
                    1
                  );
                  if (result1) {
                    result1.data.map((ival, i) => {
                      if (result.data[0].id === ival.id) {
                        localStorage.setItem(
                          `userlog${i}`,
                          JSON.stringify(result.data[0])
                        );
                        userlog = JSON.parse(localStorage.getItem(`userlog${i}`));
                      } else {
                        localStorage.setItem(
                          `userlog${i}`,
                          JSON.stringify(result1.data[i])
                        );
                      }
                    });
                  }
                  localStorage.setItem(`userlog`, JSON.stringify(userlog));

                  // // Here we set user is logged in
                  // let info =await JSON.parse(localStorage.getItem('info'));
                  // if(info && info.width){
                  //   info.id=userlog.id;
                  //   console.log(info)
                  //   await localStorage.setItem(`info`, JSON.stringify(info));
                  // }

                  userlog = JSON.parse(localStorage.getItem(`userlog`));

                  let userArray = {};
                  userArray.userId = userlog.id;
                  userArray.userName = userlog.userName;
                  userArray.ip = this.state.Ip;
                  userArray.browser = this.state.browser;
                  userArray.device = this.state.device;
                  userArray.os = this.state.os;
                  userArray.customerId = userlog.customerId;
                  userArray.userType = userlog.userType;

                  if (!this.state.IPTime) {
                    let date = new Date();
                    userArray.createdAt = this.date(date);
                  } else {
                    userArray.createdAt = this.date(this.state.IPTime);
                  }

                  let res = await CmsContent.addMaster(
                    "tbl_OriginalLog",
                    userArray
                  );
                  if (res) {
                    // window.location.href = "/subAdmin";
                    this.setState({ preloader: true });
                    if (parseInt(userlog.userType) === 1) {
                      // this.props.history.replace("/admin");
                      window.location.href = "/admin";
                    } else if (parseInt(userlog.userType) === 2) {
                      // this.props.history.replace("/subAdmin");
                      window.location.href = "/subAdmin";
                    } else if (parseInt(userlog.userType) === 3 && parseInt(userlog.id) == 620) {
                      window.location.href = "/DifuzaCarz/10";
                    }
                    else if (parseInt(userlog.userType) === 3 && parseInt(userlog.id) == 5985) {
                      window.location.href = "/DifuzaCarz/10";
                    }

                    else if (parseInt(userlog.userType) === 3) {
                      //this.props.history.replace('/superAdmin');
                      window.location.href = "/superAdmin";
                    } else if (parseInt(userlog.userType) === 160) {
                      //this.props.history.replace('/superAdmin');
                      window.location.href = "/DifuzaCarz/10";
                    } else if (
                      parseInt(userlog.userType) === 6 &&
                      parseInt(userlog.serviceId) === 8
                    ) {
                      if (this.state.link) {
                        window.location.href = this.state.link;
                      } else {
                        window.location.href = "/CMS/Home";
                      }
                    } else if (parseInt(userlog.customerId) === 246 && parseInt(userlog.userType) === 6) {
                      window.location.href = '/Crucible/dashborad';
                    }
                    else if (
                      (parseInt(userlog.userType) === 6 ||
                        parseInt(userlog.userType) === 8) &&
                      parseInt(userlog.serviceId) === 9
                    ) {
                      window.location.href = "/difuzaUser";
                    } else if (
                      parseInt(userlog.serviceId) == 10 &&
                      parseInt(userlog.userType) != 3 &&
                      parseInt(userlog.userType) != 3
                    ) {
                      //console.log(userlog)
                      window.location.href = "/DFCars/UserDashboard";
                    }
                    else if (parseInt(userlog.serviceId) == 11) {
                      window.location.href = "/crm/crmdashboard"
                    }
                    else if (parseInt(userlog.serviceId) == 12) {
                      console.log(userlog)
                      window.location.href = '/R7/UserDashboard';
                    }
                    else if (parseInt(userlog.serviceId) == 14) {
                      console.log(userlog)
                      window.location.href = '/Lead/UserDashboard';
                    }
                    else if (parseInt(userlog.userType) === 144) {
                      // window.open('https://eoi.ttsrinath.com/')
                      window.location.href = 'https://eoi.ttsrinath.com/'
                      this.setState({ preloader1: false });
                      //  '/EntrepreneurialOrientationInventory/13';
                      // this.props.history.replace('//EntrepreneurialOrientationInventory/');
                    }
                  }
                }
              }
            } catch (error) {
              if (error.response.status === 204) {
                this.setState({
                  errorMobileNumber: "Mobile number does not exist"
                });
              }
              console.log(error);
            }
          }
        }
      } else {
        this.setState({
          errorMobileNumber: "User Not Found"
        });
      }
    }
  }
  error = response => {
    console.error(response) // eslint-disable-line
  }

  handleUserInput(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  generateRandomNumber = () => {
    return Math.floor(Math.random() * 100000) + 10; // Adjust the range as needed
  };

  // passwordshow = async () => {
  //   const Passwordshows = document.getElementById('togglePassword');
  //   if (Passwordshows.classList[1] == 'fa-eye') {
  //     Passwordshows.classList.replace("fa-eye", "fa-eye-slash");
  //   } else {
  //     Passwordshows.classList.replace("fa-eye-slash", "fa-eye");
  //   }
  //   const password = document.querySelector('#password');
  //   const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
  //   password.setAttribute('type', type);
  // }
  componentDidMount() {
    let userlog = JSON.parse(localStorage.getItem(`userlog`));
    if (userlog) { this.Redirectpage(userlog) }
    const link = document.createElement("link");
    link.href = "../assets/Hellocss/bootstrap.min.css";
    link.rel = "stylesheet";
    document.body.appendChild(link);

    const link1 = document.createElement("link");
    link1.href = "../assets/Hellocss/style.css";
    link1.rel = "stylesheet";
    document.body.appendChild(link1);

    var body = document.getElementsByTagName("body");
    body[0].setAttribute("style", "justify-content: center");

    setTimeout(() => {
      this.setState({ set: true });
      const script = document.createElement("script");
      script.src = "../assets/Hellojs/script.js";
      script.async = true;
      document.body.appendChild(script);
    }, 300);
    localStorage.setItem("title", "steerapp");

    document.getElementsByTagName("title")[0].innerHTML = localStorage.getItem(
      "title"
    );
  }
  _handleKeyDown = e => {
    if (e.key === "Enter") {
      if (this.state.placeholder === 1) {
        this.handleSubmit();
      } else {
        this.handleSubmitOTP();
      }
    }
  };
  responseGoogle = (response) => {
    console.log(response);
  }
  Redirectpage = (userlog) => {
    // window.location.href = "/subAdmin";
    if (parseInt(userlog.userType) === 1) {
      // this.props.history.replace("/admin");
      window.location.href = "/admin";
    } else if (parseInt(userlog.userType) === 2) {
      // this.props.history.replace("/subAdmin");
      window.location.href = "/subAdmin";
    } else if (parseInt(userlog.userType) === 3 && parseInt(userlog.id) == 620) {
      window.location.href = "/DifuzaCarz/10";
    }
    else if (parseInt(userlog.userType) === 3 && parseInt(userlog.id) == 5985) {
      window.location.href = "/DifuzaCarz/10";
    }
    else if (parseInt(userlog.userType) === 3) {
      //this.props.history.replace('/superAdmin');
      window.location.href = "/superAdmin";
    } else if (parseInt(userlog.userType) === 160) {
      //this.props.history.replace('/superAdmin');
      window.location.href = "/DFCars/UserDashboard";
    }
    else if (
      parseInt(userlog.userType) === 6 &&
      parseInt(userlog.serviceId) === 8
    ) {
      if (this.state.link) {
        window.location.href = this.state.link;
      } else {
        window.location.href = "/CMS/Home";
      }
    } else if (parseInt(userlog.customerId) === 246 && parseInt(userlog.userType) === 6) {
      window.location.href = '/Crucible/dashborad';
    }
    else if (
      (parseInt(userlog.userType) === 6 ||
        parseInt(userlog.userType) === 8) &&
      parseInt(userlog.serviceId) === 9
    ) {
      window.location.href = "/difuzaUser";
    } else if (
      parseInt(userlog.serviceId) == 10 &&
      parseInt(userlog.userType) != 3 &&
      parseInt(userlog.userType) != 3
    ) {
      //console.log(userlog)
      window.location.href = "/DFCars/UserDashboard";
    }
    else if (parseInt(userlog.serviceId) == 11) {
      window.location.href = "/crm/crmdashboard"
    }
    else if (parseInt(userlog.serviceId) == 12) {
      console.log(userlog)
      window.location.href = '/R7/UserDashboard';
    }
    else if (parseInt(userlog.serviceId) == 14) {
      console.log(userlog)
      window.location.href = '/Lead/UserDashboard';
    }
    else if (parseInt(userlog.userType) === 144) {
      // window.open('https://eoi.ttsrinath.com/')
      window.location.href = 'https://eoi.ttsrinath.com/'
      this.setState({ preloader1: false });
      //  '/EntrepreneurialOrientationInventory/13';
      // this.props.history.replace('//EntrepreneurialOrientationInventory/');
    }
  }
  handleSubmitWithPass = async (e, type) => {
    const { generatedNumber, inputcaptcha } = this.state;


    if (type == "direct" || e.key == "Enter") {
      await this.setState({ errorlogin: "", preloader: false })
      await this.setState(
        {
          isToastOpen: true,
          Toastmsg: "Checking.. please Wait",
          Toastcolor: "bg-warning"
        })
      let mobile = this.state.mobile;
      let password = this.state.password;
      if (mobile === "") {
        await this.setState({ errorMobileNumber1: "Enter Mobile Number", isToastOpen: false, });
        setTimeout(
          () => this.setState({ errorMobileNumber1: "" }),
          3000
        );
        return false;
      } else if (
        !/^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[6789]\d{9}$/.test(mobile)
      ) {
        await this.setState({ errorMobileNumber1: "Invalid Mobile Number", isToastOpen: false, });
        setTimeout(
          () => this.setState({ errorMobileNumber1: "" }),
          3000
        );
        return false;
      } else if (password == '') {
        await this.setState({ errorpassword: "Enter Password", isToastOpen: false, });
        setTimeout(
          () => this.setState({ errorpassword: "" }),
          3000
        );
        return false;
      }

      else if (parseInt(inputcaptcha, 10) !== generatedNumber) {
        await this.setState({ isCaptchainValid: true });
        setTimeout(() => this.setState({
          isCaptchainValid: false
        }), 3000);

      }

      else {
        if (parseInt(inputcaptcha, 10) === generatedNumber) {
          this.setState({
            isCaptchaValid: true,
          });
          setTimeout(() => this.setState({
            isCaptchaValid: false
          }), 3000);
        }
        await this.setState({ preloaderwp: true, errorpassword: "", errorMobileNumber1: "" })
        let { data: result } = await CmsContent.WithPassowrdlogin(mobile, password)
        console.log(result, mobile);
        if (result.code == "User Found") {
          let { data: login_otp_notsend } = await CmsContent.getFreedom(
            "*",
            "tbl_user_web",
            `mobileNumber="${mobile}"`,
            1,
            1
          );
          // console.log(login_otp_notsend);

          if (login_otp_notsend.length) {
            const result = await login.Freelogin(
              login_otp_notsend[0].mobileNumber,
              ""
            );
            let userOrder = await CmsContent.getFreedom(
              "*",
              "tbl_UserType",
              `id	 = ${login_otp_notsend[0].userType}`,
              "id",
              "id"
            );

            if (userOrder) {
              localStorage.setItem(`usertypelog`, JSON.stringify(userOrder.data[0]));
            }
            if (this.state.local || result) {
              let userlog = "";
              try {
                const result = await login.Freelogin(login_otp_notsend[0].mobileNumber, "");
                // this.setState({ preloader: false });
                if (result) {
                  if (result && result.data[0].serviceId == 10) {
                    if (localStorage.getItem("menu")) {
                      localStorage.removeItem("menu")
                    }
                    if (localStorage.getItem("Downline_Details")) {
                      localStorage.removeItem("Downline_Details")
                    }
                  }
                  var id = result.data[0].id;
                  localStorage.setItem("userId", JSON.stringify(result.data[0].id));
                  let categoryArray = {};
                  categoryArray.loginStatus = "online";
                  const { data: data } = await CmsContent.updateMaster(
                    "tbl_user_web",
                    id,
                    categoryArray
                  );

                  if (data) {
                    const result1 = await CmsContent.getFreedom(
                      "*",
                      "tbl_user_web",
                      "loginStatus = 'online'",
                      1,
                      1
                    );
                    if (result1 && result.data[0].serviceId != 10) {
                      result1.data.map((ival, i) => {
                        if (result.data[0].id === ival.id) {
                          localStorage.setItem(
                            `userlog${i}`,
                            JSON.stringify(result.data[0])
                          );
                          userlog = JSON.parse(localStorage.getItem(`userlog${i}`));
                        } else {
                          localStorage.setItem(
                            `userlog${i}`,
                            JSON.stringify(result1.data[i])
                          );
                        }
                      });
                    }

                    localStorage.setItem(`userlog`, JSON.stringify(result.data[0]));

                    // // Here we set user is logged in
                    // let info =await JSON.parse(localStorage.getItem('info'));
                    // if(info && info.width){
                    //   info.id=userlog.id;
                    //   console.log(info)
                    //   await localStorage.setItem(`info`, JSON.stringify(info));
                    // }

                    userlog = JSON.parse(localStorage.getItem(`userlog`));

                    let userArray = {};
                    userArray.userId = userlog.id;
                    userArray.userName = userlog.userName;
                    userArray.ip = this.state.Ip;
                    userArray.browser = this.state.browser;
                    userArray.device = this.state.device;
                    userArray.os = this.state.os;
                    userArray.customerId = userlog.customerId;
                    userArray.userType = userlog.userType;

                    if (!this.state.IPTime) {
                      let date = new Date();
                      userArray.createdAt = this.date(date);
                    } else {
                      userArray.createdAt = this.date(this.state.IPTime);
                    }
                    await this.setState(
                      {
                        isToastOpen: true,
                        Toastmsg: `Welcome ${login_otp_notsend[0].userName}`,
                        Toastcolor: "bg-success"
                      })
                    let res = await CmsContent.addMaster(
                      "tbl_OriginalLog",
                      userArray
                    );
                    if (res) {
                      this.Redirectpage(userlog)
                    }
                  }
                }
              } catch (error) {
                if (error.response.status === 204) {
                  this.setState({
                    errorMobileNumber: "Mobile number does not exist"
                  });
                }
                console.log(error);
              }
            }
          }
          else {
            await this.setState(
              {
                isToastOpen: true,
                Toastmsg: "User Not Found",
                Toastcolor: "bg-danger"
              })
            setTimeout(() => {
              this.setState(
                { isToastOpen: false, Toastmsg: null, Toastcolor: null })
            }, 3000);
            // Error
          }
        } else {
          await this.setState({
            errorlogin: "User Not Found", preloaderwp: false
          });
          await this.setState(
            {
              isToastOpen: true,
              Toastmsg: "User Not Found",
              Toastcolor: "bg-danger"
            })
          setTimeout(() => {
            this.setState(
              { isToastOpen: false, Toastmsg: null, Toastcolor: null })
          }, 3000);
        }
      }
    }

  }
  handleSubmit = async () => {
    let mobileNumber = this.state.mobileNumber;
    await this.setState({ preloader: true })
    if (mobileNumber === "") {
      this.setState({ errorMobileNumber: "Enter Mobile Number", preloader: false });
      return false;
    } else if (
      !/^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[6789]\d{9}$/.test(mobileNumber)
    ) {
      this.setState({ errorMobileNumber: "Invalid Mobile Number", preloader: false });
      return false;
    } else {
      await this.setState({
        errorMobileNumber: "",
        actualNum: mobileNumber
      });
    }
    localStorage.clear();
    let login_otp_notsend = await CmsContent.getFreedom(
      "tbl_user_web.*",
      "tbl_mobile_otp_notsend,tbl_user_web",
      `tbl_mobile_otp_notsend.mobileNumber=${mobileNumber} and tbl_user_web.mobileNumber=${mobileNumber} `,
      1,
      1
    );

    if (login_otp_notsend.data.length) {
      const result = await login.Freelogin(
        login_otp_notsend.data[0].mobileNumber,
        ""
      );
      let userOrder = await CmsContent.getFreedom(
        "*",
        "tbl_UserType",
        `id	 = ${login_otp_notsend.data[0].userType}`,
        "id",
        "id"
      );
      if (userOrder) {
        localStorage.setItem(`usertypelog`, JSON.stringify(userOrder.data[0]));
      }
      if (this.state.local || result) {
        let userlog = "";
        try {
          const result = await login.Freelogin(mobileNumber, "");
          if (result) {
            var id = result.data[0].id;
            let categoryArray = {};
            categoryArray.loginStatus = "online";
            const { data: data } = await CmsContent.updateMaster(
              "tbl_user_web",
              id,
              categoryArray
            );
            if (result.data[0].serviceId == 10) {
              const Location = await CmsContent.getFreedom(
                `tbl_location.*`,
                `tbl_user_web,tbl_MaptoLocation,tbl_location`,
                `tbl_user_web.userportid = tbl_MaptoLocation.id and tbl_user_web.id=${id} and tbl_MaptoLocation.locationId = tbl_location.id`,
                1,
                1
              );
              if (Location) {
                localStorage.setItem("LocationID", JSON.stringify(Location.data[0].id));
              }
            }
            if (data) {
              const result1 = await CmsContent.getFreedom(
                "*",
                "tbl_user_web",
                "loginStatus = 'online'",
                1,
                1
              );
              if (result1) {
                result1.data.map((ival, i) => {
                  if (result.data[0].id === ival.id) {
                    localStorage.setItem(
                      `userlog${i}`,
                      JSON.stringify(result.data[0])
                    );
                    userlog = JSON.parse(localStorage.getItem(`userlog${i}`));
                  } else {
                    localStorage.setItem(
                      `userlog${i}`,
                      JSON.stringify(result1.data[i])
                    );
                  }
                });
              }
              localStorage.setItem(`userlog`, JSON.stringify(userlog));

              // // Here we set user is logged in
              // let info =await JSON.parse(localStorage.getItem('info'));
              // if(info && info.width){
              //   info.id=userlog.id;
              //   console.log(info)
              //   await localStorage.setItem(`info`, JSON.stringify(info));
              // }

              userlog = JSON.parse(localStorage.getItem(`userlog`));

              let userArray = {};
              userArray.userId = userlog.id;
              userArray.userName = userlog.userName;
              userArray.ip = this.state.Ip;
              userArray.browser = this.state.browser;
              userArray.device = this.state.device;
              userArray.os = this.state.os;
              userArray.customerId = userlog.customerId;
              userArray.userType = userlog.userType;

              if (!this.state.IPTime) {
                let date = new Date();
                userArray.createdAt = this.date(date);
              } else {
                userArray.createdAt = this.date(this.state.IPTime);
              }

              let res = await CmsContent.addMaster(
                "tbl_OriginalLog",
                userArray
              );
              if (res) {
                // window.location.href = "/subAdmin";
                this.setState({ preloader: true });
                this.Redirectpage(userlog)
                // if (parseInt(userlog.userType) === 1) {
                //   // this.props.history.replace("/admin");
                //   window.location.href = "/admin";
                // } else if (parseInt(userlog.userType) === 2) {
                //   // this.props.history.replace("/subAdmin");
                //   window.location.href = "/subAdmin";
                // } else if (parseInt(userlog.userType) === 3 && parseInt(userlog.id) == 620) {
                //   window.location.href = "/DifuzaCarz/10";
                // } else if (parseInt(userlog.userType) === 3) {
                //   //this.props.history.replace('/superAdmin');
                //   window.location.href = "/superAdmin";
                // } else if (parseInt(userlog.userType) === 160) {
                //   //this.props.history.replace('/superAdmin');
                //   window.location.href = '/DFCars/UserDashboard';
                // } else if (
                //   parseInt(userlog.userType) === 6 &&
                //   parseInt(userlog.serviceId) === 8
                // ) {
                //   if (this.state.link) {
                //     window.location.href = this.state.link;
                //   } else {
                //     window.location.href = "/CMS/Home";
                //   }
                // } else if (parseInt(userlog.customerId) === 246 && parseInt(userlog.userType) === 6) {
                //   window.location.href = '/Crucible/dashborad';
                // }
                // else if (
                //   (parseInt(userlog.userType) === 6 ||
                //     parseInt(userlog.userType) === 8) &&
                //   parseInt(userlog.serviceId) === 9
                // ) {
                //   window.location.href = "/difuzaUser";
                // } else if (
                //   parseInt(userlog.serviceId) == 10 &&
                //   parseInt(userlog.userType) != 3 &&
                //   parseInt(userlog.userType) != 3
                // ) {
                //   //console.log(userlog)
                //   window.location.href = "/DFCars/UserDashboard";
                // }
                // else if (parseInt(userlog.serviceId) == 11) {
                //   window.location.href = "/crm/crmdashboard"
                // }
                // else if (parseInt(userlog.serviceId) == 12) {
                //   console.log(userlog)
                //   window.location.href = '/R7/UserDashboard';
                // }
                // else if (parseInt(userlog.serviceId) == 14) {
                //   console.log(userlog)
                //   window.location.href = '/Lead/UserDashboard';
                // }
                // else if (parseInt(userlog.userType) === 144) {
                //   window.location.href = 'https://eoi.ttsrinath.com/'
                //   this.setState({ preloader1: false });
                //   // window.location.href = '/EntrepreneurialOrientationInventory/13';
                //   // this.props.history.replace('//EntrepreneurialOrientationInventory/');
                // }
              }
            }
          }
        } catch (error) {
          if (error.response.status === 204) {
            this.setState({
              errorMobileNumber: "Mobile number does not exist",
              preloader: false
            });
          }
          console.log(error);
        }
      }
    } else {
      try {
        const result = await login.sendOTP(mobileNumber);
        if (result) {
          if (result.data.res == false) {
            this.setState({
              errorMobileNumber: "Mobile number does not exist",
              preloader: false
            });
          } else {
            this.setState({
              mobileNumber: "",
              placeholder: 2,
              preloader: false
            });
          }
        }
      } catch (error) {
        if (error.response.status === 204) {
          this.setState({ errorMobileNumber: "Mobile number does not exist", preloader: false });
        }
        console.log(error);
      }
    }
  };

  date = dt => {
    var now = new Date(dt);
    var year = now.getFullYear();
    var month = now.getMonth() + 1;
    var day = now.getDate();
    var hour = now.getHours();
    var minute = now.getMinutes();
    var second = now.getSeconds();

    return (
      year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second
    );
  };


  handleSubmitOTP = async para => {
    this.setState({ preloader: false })

    let mobileNumber = this.state.mobileNumber;
    if (!mobileNumber) {
      this.setState({ errorMobileNumber: "Enter OTP", preloader: false });
      return false;
    } else {
      this.setState({ errorMobileNumber: "" });
    }
    try {
      const result = await login.Freelogin(this.state.actualNum, mobileNumber);
      // otp not enter check
      if (result.data.length == 0) {
        this.setState({
          errorMobileNumber: "Incorrect OTP entered ", preloader: false
        });
      }
      if (result && result.data[0].serviceId == 10) {
        if (localStorage.getItem("menu")) {
          localStorage.removeItem("menu")
        }
        if (localStorage.getItem("Downline_Details")) {
          localStorage.removeItem("Downline_Details")
        }
      }
      if (result && result.data.length > 0) {
        this.setState({
          mobileNumber: "",
          errorMobileNumber: "",
          preloader: true
        });
        localStorage.setItem("userlog", JSON.stringify(result.data[0]));
        localStorage.setItem("userId", JSON.stringify(result.data[0].id));

        let userlog = localStorage.getItem("userlog");
        userlog = JSON.parse(userlog);
        localStorage.setItem("userType", userlog.userType);

        var id = userlog.id;
        let categoryArray = {};
        categoryArray.loginStatus = "online";

        let userArray = {};
        userArray.userId = userlog.id;
        userArray.userName = userlog.userName;
        userArray.ip = this.state.Ip;
        userArray.browser = this.state.browser;
        userArray.device = this.state.device;
        userArray.os = this.state.os;
        userArray.customerId = userlog.customerId;
        userArray.userType = userlog.userType;

        if (!this.state.IPTime) {
          let date = new Date();
          userArray.createdAt = this.date(date);
        } else {
          userArray.createdAt = this.date(this.state.IPTime);
        }

        const { data: data } = await CmsContent.updateMaster(
          "tbl_user_web",
          id,
          categoryArray
        );
        if (data) {
          let res = await CmsContent.addMaster("tbl_OriginalLog", userArray);
          console.log(userArray.data);
          if (res) {
            this.setState({ preloader: true });
            if (parseInt(userlog.userType) === 1) {
              //this.props.history.replace('/admin');
              window.location.href = "/admin";
            } else if (parseInt(userlog.userType) === 2) {
              //this.props.history.replace('/subAdmin');
              window.location.href = "/subAdmin";
            } else if (parseInt(userlog.userType) === 3 && parseInt(userlog.id) == 620) {
              window.location.href = "/DifuzaCarz/10";
            }
            else if (parseInt(userlog.userType) === 3 && parseInt(userlog.id) == 5985) {
              window.location.href = "/DifuzaCarz/10";
            }
            else if (parseInt(userlog.userType) === 3) {
              //this.props.history.replace('/superAdmin');
              // window.location.href = "/superAdmin";

              window.location.href = "/superAdmin";
            } else if (parseInt(userlog.userType) === 160) {
              //this.props.history.replace('/superAdmin');
              window.location.href = "/DifuzaCarz/10";
            } else if (
              parseInt(userlog.userType) === 6 &&
              parseInt(userlog.serviceId) === 8
            ) {
              window.location.href = "/CMS/Home";
            } else if (parseInt(userlog.serviceId) == 14) {
              console.log(userlog)
              window.location.href = '/Lead/UserDashboard';
            }
            else if (parseInt(userlog.customerId) === 246 && parseInt(userlog.userType) === 6) {
              window.location.href = '/Crucible/dashborad';
            }
            else if (
              (parseInt(userlog.userType) === 6 ||
                parseInt(userlog.userType) === 8) &&
              parseInt(userlog.serviceId) === 9
            ) {
              window.location.href = "/difuzaUser";
            }
          } else {
            this.setState({
              show: 2,
              errorMobileNumber: "Incorrect Otp", preloader: false
            });
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  resend = async () => {
    this.setState({ mobileNumber: "" });
    const result = await login.loginOTPCheck(this.state.actualNum, null);
    if (result) {
      this.setState({
        show: 1,
        errorMobileNumber: "Your OTP has been sent",
        placeholder: 2
      });
    }
  };

  reset = () => {
    this.setState({
      preloader: false,
      show: 1,
      placeholder: 1,
      mobileNumber: "",
      errorMobileNumber: ""

    });
  };

  contactname = e => {
    console.log(e.target.value);
    this.setState({ contactname: e.target.value });
  };

  contactemail = e => {
    this.state = { contactemail: "" };
    this.setState({ contactemail: e.target.value });
    //  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    //   if (!e.target.value.match(mailformat)) {
    //     this.setState({ cotacterroremail: "Please Enter correct mail" });
    //   } else {
    //     this.setState({ cotacterroremail: "" });
    //   }
  };

  organization = e => {
    this.state = { organization: "" };
    this.setState({ organization: e.target.value });
  };

  contactnumber = e => {
    this.state = { contactnumber: "" };
    this.setState({ contactnumber: e.target.value });
    // if (e.target.value.length < 4) {
    //   this.setState({ cotacterrornumber: "Please enter number" });
    // } else {
    //   this.setState({ cotacterrornumber: "" });
    // }
  };
  submit = async () => {
    let cotacterroremailvalidate = ""
    if (!this.state.contactemail) {
      // this.setState({ cotacterroremail: "Please Enter Email" });
      cotacterroremailvalidate = "Please Enter Email"
    } else {
      var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!this.state.contactemail.match(mailformat)) {
        // this.setState({ cotacterroremail: "Please Enter correct mail" });
        cotacterroremailvalidate = "Please Enter correct mail"
      } else {
        // this.setState({ cotacterroremail: "" });
        cotacterroremailvalidate = ""
      }
    }
    await this.setState({ cotacterroremail: cotacterroremailvalidate })
    console.log(cotacterroremailvalidate)

    let contactnumbervalidate = ""
    if (!this.state.contactnumber) {
      // this.setState({ cotacterrornumber: "Please enter number" });
      contactnumbervalidate = "Please enter number"
    } else {
      if (this.state.contactnumber.length < 4 || (!this.state.contactnumber.match(/^\d+/))) {
        //  this.setState({ cotacterrornumber: "Please enter number" });
        contactnumbervalidate = "Please enter number"
      } else {
        // this.setState({ cotacterrornumber: "" });
        contactnumbervalidate = ""
      }
    }
    await this.setState({ cotacterrornumber: contactnumbervalidate })
    console.log(contactnumbervalidate)
    !this.state.contactname
      ? this.setState({ cotacterrorname: "Please enter Name" })
      : this.setState({ cotacterrorname: "" });
    !this.state.organization
      ? this.setState({ cotacterrorrorganization: "Please enter organization" })
      : this.setState({ cotacterrorrorganization: "" });

    let captchacapturevalidate = ""
    if (!this.state.captchacapture) {
      //this.setState({ errorcaptchacapture: "Please enter captcha" });
      captchacapturevalidate = "Please enter captcha"
    } else {
      if (this.state.captchacapturevalid != this.state.captchacapture) {
        //  this.setState({ errorcaptchacapture: "Please enter correct captcha" });
        captchacapturevalidate = "Please enter correct captcha"
      } else {
        // this.setState({ errorcaptchacapture: "" });
        captchacapturevalidate = ""
      }
    }
    await this.setState({ errorcaptchacapture: captchacapturevalidate });
    console.log(captchacapturevalidate)

    console.log("this.state.errorcaptchacapture", this.state.errorcaptchacapture)
    console.log("this.state.cotacterrornumber", this.state.cotacterrornumber)
    console.log("this.state.cotacterroremail", this.state.cotacterroremail);
    if (
      this.state.errorcaptchacapture == "" &&
      this.state.cotacterrornumber == "" &&
      this.state.contactname &&
      this.state.contactemail &&
      this.state.cotacterroremail == "" &&
      this.state.organization &&
      this.state.contactnumber
    ) {
      const formdata = new FormData();
      formdata.append("name", this.state.contactname);
      formdata.append("email", this.state.contactemail);
      formdata.append("organization", this.state.organization);
      formdata.append("contactnumber", this.state.contactnumber);
      this.setState({ contactloader: true });

      console.log('true');
      let result = await CmsContent.contactform(formdata);
      console.log(result.data);

      if (result) {
        this.setState({
          contactloader: false,
          captchacapture: "",
          contactname: "",
          contactemail: "",
          contactnumber: "",
          organization: "",
          contactalertvisible: true
        });
      }
    }
  };
  parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }
  Social_Login_Hub = async (response, type) => {
    try {
      await this.setState(
        {
          isToastOpen: true,
          Toastmsg: "Checking.. please Wait",
          Toastcolor: "bg-warning"
        })
      if (type == "Google" && response && response.credential) {
        let decode = await this.parseJwt(response.credential)
        console.log(decode, "decode");
        if (decode.email_verified) {
          let check = await CmsContent.getFreedom(
            "*",
            "tbl_user_web",
            `email = '${decode.email}' and status = "active"`,
            1, 1)
          if (check && check.data && check.data.length > 0) {
            console.log(check.data[0], "check");
            let mobile = check.data[0].mobileNumber
            if (check && check.data && check.data.length > 0) {
              let { data: login_otp_notsend } = await CmsContent.getFreedom(
                "*",
                "tbl_user_web",
                `mobileNumber="${mobile}"`,
                1,
                1
              );
              // console.log(login_otp_notsend);

              if (login_otp_notsend.length) {
                const result = await login.Freelogin(
                  login_otp_notsend[0].mobileNumber,
                  ""
                );
                let userOrder = await CmsContent.getFreedom(
                  "*",
                  "tbl_UserType",
                  `id	 = ${login_otp_notsend[0].userType}`,
                  "id",
                  "id"
                );

                if (userOrder) {
                  localStorage.setItem(`usertypelog`, JSON.stringify(userOrder.data[0]));
                }
                if (this.state.local || result) {
                  let userlog = "";
                  try {
                    const result = await login.Freelogin(login_otp_notsend[0].mobileNumber, "");
                    // this.setState({ preloader: false });
                    if (result) {
                      if (result && result.data[0].serviceId == 10) {
                        if (localStorage.getItem("menu")) {
                          localStorage.removeItem("menu")
                        }
                        if (localStorage.getItem("Downline_Details")) {
                          localStorage.removeItem("Downline_Details")
                        }
                      }
                      var id = result.data[0].id;
                      localStorage.setItem("userId", JSON.stringify(result.data[0].id));
                      let categoryArray = {};
                      categoryArray.loginStatus = "online";
                      const { data: data } = await CmsContent.updateMaster(
                        "tbl_user_web",
                        id,
                        categoryArray
                      );

                      if (data) {
                        const result1 = await CmsContent.getFreedom(
                          "*",
                          "tbl_user_web",
                          "loginStatus = 'online'",
                          1,
                          1
                        );
                        if (result1 && result.data[0].serviceId != 10) {
                          result1.data.map((ival, i) => {
                            if (result.data[0].id === ival.id) {
                              localStorage.setItem(
                                `userlog${i}`,
                                JSON.stringify(result.data[0])
                              );
                              userlog = JSON.parse(localStorage.getItem(`userlog${i}`));
                            } else {
                              localStorage.setItem(
                                `userlog${i}`,
                                JSON.stringify(result1.data[i])
                              );
                            }
                          });
                        }

                        localStorage.setItem(`userlog`, JSON.stringify(result.data[0]));

                        // // Here we set user is logged in
                        // let info =await JSON.parse(localStorage.getItem('info'));
                        // if(info && info.width){
                        //   info.id=userlog.id;
                        //   console.log(info)
                        //   await localStorage.setItem(`info`, JSON.stringify(info));
                        // }

                        userlog = JSON.parse(localStorage.getItem(`userlog`));

                        let userArray = {};
                        userArray.userId = userlog.id;
                        userArray.userName = userlog.userName;
                        userArray.ip = this.state.Ip;
                        userArray.browser = this.state.browser;
                        userArray.device = this.state.device;
                        userArray.os = this.state.os;
                        userArray.customerId = userlog.customerId;
                        userArray.userType = userlog.userType;

                        if (!this.state.IPTime) {
                          let date = new Date();
                          userArray.createdAt = this.date(date);
                        } else {
                          userArray.createdAt = this.date(this.state.IPTime);
                        }
                        await this.setState(
                          {
                            isToastOpen: true,
                            Toastmsg: `Welcome ${check.data[0].userName}`,
                            Toastcolor: "bg-success"
                          })
                        let res = await CmsContent.addMaster(
                          "tbl_OriginalLog",
                          userArray
                        );
                        if (res) {
                          this.Redirectpage(userlog)
                        }
                      }
                    }
                  } catch (error) {
                    if (error.response.status === 204) {
                      this.setState({
                        errorMobileNumber: "Mobile number does not exist"
                      });
                    }
                    console.log(error);
                  }
                }
              }
              else {
                await this.setState(
                  {
                    isToastOpen: true,
                    Toastmsg: "User Not Found",
                    Toastcolor: "bg-danger"
                  })
                setTimeout(() => {
                  this.setState(
                    { isToastOpen: false, Toastmsg: null, Toastcolor: null })
                }, 3000);
                // Error
              }
            }
            else {
              await this.setState(
                {
                  isToastOpen: true,
                  Toastmsg: "User Not Found",
                  Toastcolor: "bg-danger"
                })
              setTimeout(() => {
                this.setState(
                  { isToastOpen: false, Toastmsg: null, Toastcolor: null })
              }, 3000);
              // Error
            }
          }
          else {
            await this.setState(
              {
                isToastOpen: true,
                Toastmsg: "User Not Found",
                Toastcolor: "bg-danger"
              })
            setTimeout(() => {
              this.setState(
                { isToastOpen: false, Toastmsg: null, Toastcolor: null })
            }, 3000);
            // Error
          }
        } else {
          await this.setState(
            {
              isToastOpen: true,
              Toastmsg: "Something Went Wrong .. Please Try Again!",
              Toastcolor: "bg-danger"
            })
          setTimeout(() => {
            this.setState(
              { isToastOpen: false, Toastmsg: null, Toastcolor: null })
          }, 3000);
          // Error
        }
      }
      if (type == "Error") {
        // Error

        await this.setState(
          {
            isToastOpen: true,
            Toastmsg: "Something Went Wrong .. Please Try Again!",
            Toastcolor: "bg-danger"
          })
        setTimeout(() => {
          this.setState(
            { isToastOpen: false, Toastmsg: null, Toastcolor: null })
        }, 3000);
      }
    } catch (error) {
      this.setState({ Preloader: false })

      console.log(error);
    }
  }

  captchaview = async () => {
    console.log("captchaview");

    var captcha = await CmsContent.createcaptcha();

    console.log(captcha.data.text);

    this.setState({
      dataimage: captcha.data.data,
      captchacapturevalid: captcha.data.text
    });
  };

  captchacapture = e => {
    console.log("test");
    this.setState({ captchacapture: e.target.value });
    //  if (this.state.captchacapturevalid != e.target.value) {
    //    this.setState({ errorcaptchacapture: "Please enter correct captcha" });
    //  } else {
    //    this.setState({ errorcaptchacapture: "" });
    //  }
    console.log(this.state.captchacapturevalid);
  };

  resetfield = e => {
    console.log("reset ");
    this.setState({ placeholder: 1, errorMobileNumber: "", preloader: false });
  };

  resendotp = async e => {
    this.setState({ mobileNumber: "" });
    const result = await login.sendOTP(this.state.actualNum);
    if (result) {
      this.setState({
        errorMobileNumber: "OTP sent",
        placeholder: 2
      });
    }
  };
  loginchange = async () => {
    await this.setState(state => ({ isToggleOn: !state.isToggleOn }));
    if (this.state.isToggleOn === true) {
      await this.setState({ loginwpass: 1 })
    } else (
      await this.setState({ loginwpass: 0 })
    )
  };
  render() {
    const { generatedNumber, inputcaptcha, isCaptchaValid, isCaptchainValid } = this.state;
    return (
      <React.Fragment>
        <>
          <div className="container login_container">
            <div className="content">
              <div>
                <img src={lognLogo} className="login_logo"></img>
              </div>
              <div className="login_content__form">
                <div className="login_content__inputs">
                  <div>

                    <label>
                      <span style={{ color: "black", fontSize: "19px" }}>Mobile number</span>
                    </label>
                    <input type="text"
                      className="login_inputss"
                      name="mobile"
                      onChange={(event) => this.handleUserInput(event)}
                      value={this.state.mobile}
                      maxLength="10"
                      placeholder="Mobile" />
                    <p style={{ color: "red", fontSize: "17px" }}>
                      {this.state.errorMobileNumber1}
                    </p>
                  </div>
                  <div>

                    <label>
                      <span style={{ color: "black", fontSize: "19px" }}>Password</span>
                    </label>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      className="login_inputss"
                      onChange={(event) => this.handleUserInput(event)}
                      value={this.state.password}

                      placeholder="Password" />
                    {/* <i className="fa fa-eye" id="togglePassword" style={{ marginLeft: '300px', cursor: 'pointer', color: 'black' }} onClick={() => this.passwordshow()}></i> */}
                    <p style={{ color: "red", fontSize: "17px" }}>
                      {this.state.errorpassword}
                    </p>
                  </div>
                  <div>

                    <label> <span style={{ color: "black", fontSize: "19px" }}> Enter the captcha  : <span style={{ backgroundColor: "#e9a6a6", marginLeft: "160px", width: "60px", color: "black" }}>{this.state.generatedNumber}</span> </span> </label>
                    <input type="text"
                      className="login_inputss"
                      name="inputcaptcha"
                      onChange={(event) => this.handleUserInput(event)}
                      value={this.state.inputcaptcha}
                      maxLength="10"
                      placeholder="Enter a captcha" />
                    {isCaptchaValid && <p style={{ color: "green" }}>Captcha is valid!</p>}
                    {isCaptchainValid && <p style={{ color: "red" }}>Captcha is invalid. Please try again.</p>}
                  </div>

                </div>
                <div>
                  <button className="login_btnn"
                    onClick={(e) => this.handleSubmitWithPass(e, "direct")}
                  >Log In</button>
                  <p style={{ color: "red", paddingLeft: "40%", fontSize: "17px" }}>
                    {this.state.errorlogin}
                  </p>
                </div>
              </div>
              <div className="Powered_by">
                <span />
                <span>Powered by <a href="https://www.difuza.com" style={{ color: "black" }} target="_blank">difuza</a> </span>
                <span />
              </div>

            </div>
          </div>
        </>

      </React.Fragment>
    );
  }
}
