import React, { Component } from "react";
import Utilities from "../../../../MiddleWare/Utilities";
import redisMiddleware from "../../../../MiddleWare/redisMiddleware";
import CmsContent from "../../../../MiddleWare/CmsContent";
import ReportCharts from "../TL/ReportCharts";

export default class RfIncharge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      usertypeName: "",
      TodayNotifiy: [],
      PendingTask: [],
      TodayEvaluation: [],
      PendingRefurbishment: [],
      PendingDelivery: [],
      PendingContracted: [],
      EvaluatorEnquiry: [],
      buy: true,
      sell: false,
      LocationID: localStorage.getItem(`LocationID`),
    };
  }
  async componentDidMount() {
    let Userlog = JSON.parse(localStorage.getItem("userlog"));
    try {
      const Product = await CmsContent.getFreedom(
        "*",
        "tbl_dfcarz_enquiryform",
        1
      );
      let TodayNotifiy = [];
      let PendingTask = [];
      let CompletedTasks = [];
      if (Product) {
        Product.data.map((ival, i) => {
          let EnquiryDate = new Date();
          let d = new Date(ival.createdAt);
          // (
          //   new Date(ival.createdAt).getTime() + 60 * 60 * 24 * 1000
          // );
          //
          let a = EnquiryDate.getDate();
          let b = d.getDate();

          let Datetime1 = EnquiryDate.getTime();
          let Datetime2 = d.getTime();
          // console.log(Datetime2,Datetime1);
          if (ival.enquiry_status == "active") {
            if (b == a) {
              TodayNotifiy.push(ival);
              console.log(ival);
            } else if (Datetime1 > Datetime2) {
              PendingTask.push(ival);
            }
          } else {
            CompletedTasks.push(ival);
          }
        });
        this.setState({ TodayNotifiy, PendingTask, CompletedTasks });
      }
      const result = await redisMiddleware.singleTable(
        "tbl_UserType",
        Userlog.userType,
        "id"
      );
      if (result) {
        this.setState({
          data: result.data,
          usertypeName: result.data[0].usertype,
        });
      }

      // let Result = await CmsContent.getFreedom(
      //   `tbl_dfcarz_prosperities.*,tbl_user_web.userName,tbl_FProof.id as F_id,tbl_BProof.id as B_id,tbl_FProof.approval as F_approval,tbl_BProof.approval as B_approval ,tbl_BProof.createdAt as B_date,tbl_FProof.createdAt as F_date`,
      //   `tbl_dfcarz_prosperities,tbl_FProof,tbl_BProof,tbl_user_web`,
      //   `tbl_dfcarz_prosperities.id = tbl_FProof.customerid and tbl_dfcarz_prosperities.id = tbl_BProof.customerid and tbl_BProof.userid = tbl_user_web.id and tbl_dfcarz_prosperities.location=${this.state.LocationID}`,
      //   1,
      //   1
      // );
      let Result = await redisMiddleware.Dashboard("TodayEvaluation", 7, this.state.LocationID);
      if (Result) {
        let TodayEvaluation = [];
        // console.log(Result.data);
        Result.data.map((ival, i) => {
          let lastdate = new Date(
            new Date(ival.B_date).getTime() + 60 * 60 * 24 * 1000
          );
          let currentdate = new Date();
          let a = lastdate.getDate();
          let b = currentdate.getDate();
          //console.log(a,b)
          if (b <= a) {
            TodayEvaluation.push(ival);
          }
        });
        this.setState({ TodayEvaluation });
        this.Refurbishment();
        this.Delivery();
        this.EvaluatorEnquiry();
      }
    } catch (error) {
      console.log(error);
    }
  }

  EvaluatorEnquiry = async () => {
    try {
      let EvaluatorEnquiry = [];
      // let Buyer = await CmsContent.getFreedom(
      //   `tbl_dfcarz_prosperities.*,tbl_user_web.userName as jobroleName ,tbl_FProof.id as F_id,tbl_FProof.reg_no as regno,tbl_BProof.id as B_id,tbl_FProof.approval as F_approval,tbl_BProof.approval as B_approval ,tbl_BProof.createdAt as B_date,tbl_FProof.createdAt as F_date`,
      //   `tbl_dfcarz_prosperities,tbl_FProof,tbl_BProof,tbl_user_web`,
      //   `tbl_dfcarz_prosperities.id = tbl_FProof.customerid and tbl_dfcarz_prosperities.id = tbl_BProof.customerid and tbl_BProof.userid = tbl_user_web.id and tbl_dfcarz_prosperities.location=${this.state.LocationID}`,
      //   1,
      //   1
      // );
      let Buyer = await redisMiddleware.Dashboard("EvaluatorEnquiry", 7, this.state.LocationID);
      if (Buyer) {
        // console.log(Buyer);
        Buyer.data.map((ival, i) => {
          ival.evaluator_date = new Date(ival.evaluator_date);
          let currentdate = new Date();
          // console.log(ival.evaluator_date.getDate() , currentdate.getDate())
          if (ival.evaluator_date.getDate() == currentdate.getDate()) {
            //  console.log(ival);
            EvaluatorEnquiry.push(ival);
          }
        });
        console.log(EvaluatorEnquiry);
        this.setState({ EvaluatorEnquiry });
      }
    } catch (error) {
      console.log(error);
    }
  };

  Delivery = async () => {
    try {
      let PendingDelivery = [];
      // let Buyer = await CmsContent.getFreedom(
      //   "tbl_dfcarz_prosperities.*,tbl_user_web.userName as jobroleName,tbl_Booking_Confirmation_Reproof.regno as regno",
      //   "tbl_dfcarz_prosperities,tbl_Booking_Confirmation_Reproof,tbl_user_web",
      //   `tbl_user_web.id = tbl_Booking_Confirmation_Reproof.userid and tbl_dfcarz_prosperities.type='buyer' and tbl_dfcarz_prosperities.id = tbl_Booking_Confirmation_Reproof.customerid and tbl_dfcarz_prosperities.location=${this.state.LocationID} `
      // );
      let Buyer = await redisMiddleware.Dashboard("Delivery", 7, this.state.LocationID);
      if (Buyer.data.length) {
        Buyer.data.map(async (ival, i) => {
          let result = await CmsContent.getFreedom(
            `*`,
            `tbl_Delivery_Note_Buyer_Reproof`,
            `customerid =${ival.id}`
          );
          if (!result.data.length) {
            PendingDelivery.push(ival);
          }
        });
      }
      this.setState({ PendingDelivery });
    } catch (error) {
      console.log(error);
    }
  };

  Refurbishment = async () => {
    //console.log("Dgsdg")
    try {
      let PendingRefurbishment = [];

      // let Result = await CmsContent.getFreedom(
      //   "tbl_dfcarz_prosperities.*,tbl_Delivery_Seller_Proof.Approved_date,tbl_Delivery_Seller_Proof.id as value,tbl_Delivery_Seller_Proof.approval,tbl_user_web.userName as jobroleName,tbl_Delivery_Seller_Proof.reg_no as regno",
      //   `tbl_dfcarz_prosperities,tbl_Delivery_Seller_Proof,tbl_user_web`,
      //   `tbl_dfcarz_prosperities.id=tbl_Delivery_Seller_Proof.customerid and tbl_user_web.id = tbl_Delivery_Seller_Proof.userid and tbl_dfcarz_prosperities.location=${this.state.LocationID}`,
      //   1
      // );
      let Result = await redisMiddleware.Dashboard("Refurbishment", 7, this.state.LocationID);
      Result.data.map(async (ival, i) => {
        ival.Approved_date = new Date(ival.Approved_date);
        ival.Approved_date.setDate(ival.Approved_date.getDate() + 3);
        //  ival.Approved_date = moment( ival.Approved_date).format("YYYY-MM-DD");
        // console.log(ival.Approved_date.getTime())
        let date = new Date();

        // if(ival.Approved_date.getTime() >= date.getTime()){

        let Refurbishment = await CmsContent.getFreedom(
          `tbl_checklist350.*`,
          `tbl_checklist350,tbl_user_web`,
          `tbl_checklist350.customerid=${ival.id}`
        );
        if (!Refurbishment.data.length) {
          //console.log(Refurbishment);
          // Refurbishment.data.map((jval,j)=>{
          //   jval.jobroleName = ival.jobroleName;
          // })

          PendingRefurbishment.push(ival);

          this.setState({ PendingRefurbishment });
        }

        //PendingRefurbishment.push(ival);
        //  }
      });
    } catch (error) {
      console.log(error);
    }
  };
  MovetoSite = (d) => {
    console.log(d);
    if (d == "eval") {
      //window.open(`/DFCars/DfCarsEnquiry`);
      window.location.href = `/DFCars/DfCarsEnquiry`;
    } else if (d == "delivery") {
      window.location.href = `/DFCars/DfCarsFproof`;
    } else if (d == "refurbishment") {
      window.location.href = `/DFCars/Refurbishment`;
    }
  };
  buyer = () => {
    this.setState({ buy: true, sell: false })
  }
  seller = () => {
    this.setState({ sell: true, buy: false })
  }
  render() {
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3>Welcome {this.state.usertypeName}</h3>
                  </div>
                  <br />
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <ReportCharts />
                        <ul class="nav nav-tabs">
                          <li class="nav-item">
                            <a
                              class="nav-link active"
                              data-toggle="tab"
                              href="#buy"
                              onClick={() => this.buyer()}
                            >
                              Buying
                            </a>
                          </li>
                          <li class="nav-item">
                            <a
                              class="nav-link"
                              data-toggle="tab"
                              href="#sell"
                              onClick={() => this.seller()}
                            >
                              Selling
                            </a>
                          </li>
                        </ul>
                        <div class="tab-content">
                          <div id="buy" class="container tab-pane active">
                            {this.state.buy && (
                              <div className="row form-group">
                                {this.state.EvaluatorEnquiry.length ? (
                                  <div className="col-sm-6">
                                    <React.Fragment>
                                      <div className="card">
                                        <div
                                          className="card-header"
                                          style={{ backgroundColor: "lightgray" }}
                                        >
                                          <h3>
                                            Evaluator Enquiry ( Comp{" "}
                                            {this.state.CompletedTasks.length} - pend{" "}
                                            {this.state.EvaluatorEnquiry.length})
                                    </h3>
                                        </div>
                                        <div className="card-body">
                                          {/* {this.state.TodayEvaluation.length ?  */}
                                          {this.state.EvaluatorEnquiry.map(
                                            (ival, i) => {
                                              if (i < 8) {
                                                return (
                                                  <React.Fragment>
                                                    <div className="row-form-group">
                                                      <div className="col-sm-1" />
                                                      <div className="col-sm-12">
                                                        <a onClick={() => this.MovetoSite("eval")}>
                                                          <table className="dfcarsport">
                                                            <tr>
                                                              <td className="dfcarsportname">
                                                                {ival.name}
                                                              </td>
                                                              <td className="dfcarsportmobicon">
                                                                <i class="fa fa-mobile" aria-hidden="true"></i>
                                                              </td>

                                                              <td className="dfcarsportnumber">
                                                                {ival.mobile}
                                                              </td>
                                                              <td className="dfcarsportreg">
                                                                {ival.regno}
                                                              </td>
                                                            </tr>
                                                          </table>
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </React.Fragment>
                                                );
                                              }
                                            }
                                          )}
                                          {this.state.EvaluatorEnquiry.length > 0 ? (
                                            <h5
                                              className="Readmore1"
                                              onClick={() => this.MovetoSite("eval")}
                                            >
                                              View More
                                            </h5>
                                          ) : null}
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  </div>
                                ) : null}
                                {this.state.PendingRefurbishment.length ? (
                                  <div className="col-sm-6">
                                    <React.Fragment>
                                      <div className="card">
                                        <div
                                          className="card-header"
                                          style={{ backgroundColor: "lightgray" }}
                                        >
                                          <h3>
                                            PendingRefurbishment(Comp{" "}
                                            {this.state.CompletedTasks.length} - pend{" "}
                                            {this.state.PendingRefurbishment.length})
                                    </h3>
                                        </div>
                                        <div className="card-body">
                                          {/* {this.state.TodayEvaluation.length ?  */}
                                          {this.state.PendingRefurbishment.map(
                                            (ival, i) => {
                                              if (i < 8) {
                                                return (
                                                  <React.Fragment>
                                                    <div className="row-form-group">
                                                      <div className="col-sm-1" />
                                                      <div className="col-sm-12">
                                                        <a onClick={() => this.MovetoSite("refurbishment")}>
                                                          <table className="dfcarsport">
                                                            <tr>
                                                              <td className="dfcarsportname">
                                                                {ival.name}
                                                              </td>
                                                              <td className="dfcarsportmobicon">
                                                                <i class="fa fa-mobile" aria-hidden="true"></i>
                                                              </td>

                                                              <td className="dfcarsportnumber">
                                                                {ival.mobile}
                                                              </td>
                                                              <td className="dfcarsportreg">
                                                                {ival.regno}
                                                              </td>
                                                            </tr>
                                                          </table>
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </React.Fragment>
                                                );
                                              }
                                            }
                                          )}
                                          {this.state.PendingRefurbishment.length >
                                            0 ? (
                                              <h5
                                                className="Readmore1"
                                                onClick={() =>
                                                  this.MovetoSite("refurbishment")
                                                }
                                              >
                                                View More
                                              </h5>
                                            ) : null}
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  </div>
                                ) : null}
                              </div>
                            )}
                          </div>
                          {this.state.sell && (
                            <div id="sell" class="container tab-pane active">
                              {this.state.PendingDelivery.length ? (
                                <div className="col-sm-6">
                                  <React.Fragment>
                                    <div className="card">
                                      <div
                                        className="card-header"
                                        style={{ backgroundColor: "lightgray" }}
                                      >
                                        <h3>
                                          PendingDelivery(Comp{" "}
                                          {this.state.CompletedTasks.length} - pend{" "}
                                          {this.state.PendingDelivery.length})
                                    </h3>
                                      </div>
                                      <div className="card-body">
                                        {/* {this.state.TodayEvaluation.length ?  */}
                                        {this.state.PendingDelivery.map(
                                          (ival, i) => {
                                            if (i < 8) {
                                              return (
                                                <React.Fragment>
                                                  <div className="row-form-group">
                                                    <div className="col-sm-1" />
                                                    <div className="col-sm-12">
                                                      <a onClick={() => this.MovetoSite("refurbishment")}>
                                                        <table className="dfcarsport">
                                                          <tr>
                                                            <td className="dfcarsportname">
                                                              {ival.name}
                                                            </td>
                                                            <td className="dfcarsportmobicon">
                                                              <i class="fa fa-mobile" aria-hidden="true"></i>
                                                            </td>

                                                            <td className="dfcarsportnumber">
                                                              {ival.mobile}
                                                            </td>
                                                            <td className="dfcarsportreg">
                                                              {ival.regno}
                                                            </td>
                                                          </tr>
                                                        </table>
                                                      </a>
                                                    </div>
                                                  </div>
                                                </React.Fragment>
                                              );
                                            }
                                          }
                                        )}
                                        {this.state.PendingDelivery.length > 0 ? (
                                          <h5
                                            className="Readmore1"
                                            onClick={() =>
                                              this.MovetoSite("delivery")
                                            }
                                          >
                                            View More
                                          </h5>
                                        ) : null}
                                      </div>
                                    </div>
                                  </React.Fragment>
                                </div>
                              ) : null}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment >
    );
  }
}
