import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

const SFAPDF = ({ header, data, title }) => {
  var type = [];
  var loc = [];
  var city=[];
  var zone=[];
  var col = [];
  var size = [];
  var tax = [];
  var brand = [];
  var stock = [];
  var uom=[];
  if (data[0].usertype) {
    type.push(data);
  } else if (data[0].name) {
    loc.push(data);
  }
  else if (data[0].name) {
    city.push(data);
  }
  else if (data[0].name) {
    zone.push(data);
  } else if (data[0].colour) {
    col.push(data);
  } else if (data[0].Size) {
    size.push(data);
  } else if (data[0].tax) {
    tax.push(data);
  } else if (data[0].brand) {
    brand.push(data);
  }
  else if (data[0].uom) {
    uom.push(data);
  }
  else if (data[0].stock) {
    stock.push(data);
  }

  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.title}>
          <Text>{title}</Text>
        </View>

        <View style={styles.table}>
          <View style={styles.tableRow}>
            {header &&
              type.length > 0 &&
              header.map((value) => {
                return (
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value}</Text>
                  </View>
                );
              })}
            {header &&
              loc.length > 0 &&
              header.map((value) => {
                return (
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value}</Text>
                  </View>
                );
              })}
              {header &&
              city.length > 0 &&
              header.map((value) => {
                return (
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value}</Text>
                  </View>
                );
              })}
              {header &&
              zone.length > 0 &&
              header.map((value) => {
                return (
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value}</Text>
                  </View>
                );
              })}
            {header &&
              col.length > 0 &&
              header.map((value) => {
                return (
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value}</Text>
                  </View>
                );
              })}
            {header &&
              size.length > 0 &&
              header.map((value) => {
                return (
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value}</Text>
                  </View>
                );
              })}
            {header &&
              tax.length > 0 &&
              header.map((value) => {
                return (
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value}</Text>
                  </View>
                );
              })}
            {header &&
              brand.length > 0 &&
              header.map((value) => {
                return (
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value}</Text>
                  </View>
                );
              })}
              {header &&
              uom.length > 0 &&
              header.map((value) => {
                return (
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value}</Text>
                  </View>
                );
              })}
              {header &&
              stock.length > 0 &&
              header.map((value) => {
                return (
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value}</Text>
                  </View>
                );
              })}
          </View>

          {data &&
            type.length > 0 &&
            data.map((value, index) => {
              return (
                <View style={styles.tableRow}>
                  {/* <View><text>{title}</text> </View> */}
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{index + 1}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value.usertype}</Text>
                  </View>
                </View>
              );
            })}
          {data &&
            loc.length > 0 &&
            data.map((value, index) => {
              return (
                <View style={styles.tableRow}>
                  {/* <View><text>{title}</text> </View> */}
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{index + 1}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value.name}</Text>
                  </View>
                </View>
              );
            })}
            {data &&
            city.length > 0 &&
            data.map((value, index) => {
              return (
                <View style={styles.tableRow}>
                  {/* <View><text>{title}</text> </View> */}
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{index + 1}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value.name}</Text>
                  </View>
                </View>
              );
            })}
            {data &&
            zone.length > 0 &&
            data.map((value, index) => {
              return (
                <View style={styles.tableRow}>
                  {/* <View><text>{title}</text> </View> */}
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{index + 1}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value.name}</Text>
                  </View>
                </View>
              );
            })}
          {data &&
            col.length > 0 &&
            data.map((value, index) => {
              return (
                <View style={styles.tableRow}>
                  {/* <View><text>{title}</text> </View> */}
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{index + 1}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value.colour}</Text>
                  </View>
                </View>
              );
            })}
          {data &&
            size.length > 0 &&
            data.map((value, index) => {
              return (
                <View style={styles.tableRow}>
                  {/* <View><text>{title}</text> </View> */}
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{index + 1}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value.Size}</Text>
                  </View>
                </View>
              );
            })}
          {data &&
            tax.length > 0 &&
            data.map((value, index) => {
              return (
                <View style={styles.tableRow}>
                  {/* <View><text>{title}</text> </View> */}
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{index + 1}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value.tax}</Text>
                  </View>
                </View>
              );
            })}
          {data &&
            brand.length > 0 &&
            data.map((value, index) => {
              return (
                <View style={styles.tableRow}>
                  {/* <View><text>{title}</text> </View> */}
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{index + 1}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value.brand}</Text>
                  </View>
                </View>
              );
            })}
            {data &&
            uom.length > 0 &&
            data.map((value, index) => {
              return (
                <View style={styles.tableRow}>
                  {/* <View><text>{title}</text> </View> */}
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{index + 1}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value.uom}</Text>
                  </View>
                </View>
              );
            })}
             {data &&
            stock.length > 0 &&
            data.map((value, index) => {
              return (
                <View style={styles.tableRow}>
                  {/* <View><text>{title}</text> </View> */}
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{index + 1}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value.stock}</Text>
                  </View>
                </View>
              );
            })}
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  body: {
    padding: 10,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    textAlign: "center",
  },
  tableColHeader: {
    marginTop: "5px",
    width: "16.6%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol: {
    width: "50%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: "auto",
    margin: 5,
    fontSize: 12,
    fontWeight: 500,
  },
  tableCell: {
    margin: "auto",
    margin: 5,
    fontSize: 10,
  },
  title: {
    fontSize: 19,
    fontWeight: "bold",
    marginLeft: "42%",
    marginBottom: "2px",
  },
});

export default SFAPDF;
