import React, { Component } from 'react'
import { ACCESS_POINT, ImgSTEERAUTO } from '../../../../../config'
import CmsContent from '../../../../../MiddleWare/CmsContent'
import FromLibrary from '../../../CMS/CmndlFormLibrary'
import Progress from '../../../../../components/Extra/Progress'
import htmlToDraft from 'html-to-draftjs';
import moment from 'moment';
import http from '../../../../../MiddleWare/httpMiddleWare';
import { FormMiddleWare, SingleSelect } from '../../../../../components/Form'
import LoginModal from '../../../../../components/Modal/Modal'
import iconClose from "../../../../../images/CMS/icon-hamburger-close.svg";
//fileupload

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginImageResize,
    FilePondPluginFileValidateType,
    FilePondPluginFileValidateSize,
    FilePondPluginImageValidateSize
);

export default class Theme3 extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            btnDisable: false,
            uploadPercentage: 0,
            pageopt: [{ label: "HOME", value: "/" }, { label: "BUYCAR", value: "/buycars" }, { label: "SELLCAR", value: "/sellyourcars" }],
            uploadPercentage: 0,
            getlibrarydataImage: this.props.that.getlibrarydataImage,
            btnDisable: false,
            alertVisible: false,
            alertVisible1: false,
            customerId: localStorage.getItem("userId"),
            AllContent: [{ name: '', role: '', description: '', File: null, index: 1 }],
            AllContent2: [{ name: '', role: '', description: '', File: null, index: 1 }],
            fileName: null
        }
    }
    async componentDidMount(props) {
        console.log('did update didmount1');
        this.mainfun()
    }
    mainfun = async () => {
        try {
            if (this.props) {
                await this.setState({
                    getlibrarydataImage: this.props.that.getlibrarydataImage,
                    contentTitle: this.props.that.contentTitle1 ? this.props.that.contentTitle1 : null,
                    isEdit: this.props.that.isEdit,
                    editId: this.props.that.editid ? this.props.that.editid : null,
                    fileImg: this.props.that.fileImg ? this.props.that.fileImg : null,
                    fileJson: this.props.that.fileJson ? this.props.that.fileJson : null,
                    content2: this.props.that.content2 ? this.props.that.content2 : null,
                    content1: this.props.that.content1 ? this.props.that.content1 : null,
                    radio: this.props.that.subTitleId == 1 ? true : false,
                    contentTitle2: this.props.that.contentTitle2 ? this.props.that.contentTitle2 : null,
                });
                if (this.props.that.sheetContent) {
                    this.setState({
                        AllContent: this.props.that.sheetContent
                    })
                }
                if (this.props.that.videoOverlay) {
                    this.setState({
                        AllContent2: this.props.that.videoOverlay
                    })
                }


            }
        } catch (error) {
            console.log(error)
        }
    }

    contentHeading2 = [
        {
            Header: "Search By Hint",
            accessor: "hint"
        },
        {
            Header: "image",
            accessor: "label",
            Cell: d => d.original.label ? <img src={ImgSTEERAUTO + d.original.label} style={{ objectFit: 'contain', width: 100, height: 50 }} /> : null
        },
        {
            Header: "Select",
            accessor: "edit",
            Cell: d => this.checktest2(d)
        }
    ];
    contentHeading = [
        {
            Header: "Search By Hint",
            accessor: "hint"
        },
        {
            Header: "image",
            accessor: "label",
            Cell: d => d.original.label ? <img src={ImgSTEERAUTO + d.original.label} style={{ objectFit: 'contain', width: 100, height: 50 }} /> : null
        },
        {
            Header: "Select",
            accessor: "edit",
            Cell: d => this.checktest(d)
        }
    ];
    checktest2 = (id) => {
        return (
            <button
                type="button"
                className="btn btn-info"
                data-dismiss="modal"
                onClick={() => {
                    this.JSON_refill2(id.original.label, "File", this.state.Clickindex);
                }
                }
            >
                Select
            </button>
        );
    };
    checktest = (id) => {
        return (
            <button
                type="button"
                className="btn btn-info"
                data-dismiss="modal"
                onClick={() => {
                    this.JSON_refill(id.original.label, "File", this.state.Clickindex);
                }
                }
            >
                Select
            </button>
        );
    };
    JSON_refill = (value, Field, index) => {

        let { AllContent } = this.state;

        AllContent[index][Field] = value;

        this.setState({ AllContent });

    };
    JSON_refill2 = (value, Field, index) => {

        let { AllContent2 } = this.state;

        AllContent2[index][Field] = value;

        this.setState({ AllContent2 });

    };
    addMore2 = (type = null, i) => {
        let { AllContent2 } = this.state;
        if (type) {
            AllContent2.splice(i, 1)
        } else {

            AllContent2.push({ name: '', role: '', description: '', File: null, index: AllContent2.length + 1 })
        }
        this.setState({ AllContent2 })
    }
    addMore = (type = null, i) => {
        let { AllContent } = this.state;
        if (type) {
            AllContent.splice(i, 1)
        } else {

            AllContent.push({ name: '', role: '', description: '', File: null, index: AllContent.length + 1 })
        }
        this.setState({ AllContent })
    }
    submitCategory = async () => {

        const { pageName, customerId, pageSelected, orgsheetContent, orgvideoOverlay } = this.props.that;
        const { AllContent, File, editId, fileJson, content1, contentTitle, content2, contentTitle2, AllContent2 } = this.state;
        if (!pageSelected) {
            this.props.these('errorPage', 'Select Page')
            this.ScrollTop()
            return false;
        }
        else if (!pageName) {
            this.props.these('errorPageName', 'Enter Page name')
            this.ScrollTop()
            return false;
        } else if (!contentTitle) {
            this.setState({ HeaderErr: 'Enter Header Title' })
            this.ScrollTop()
            return false;
        } else if (!content2) {
            this.setState({ HeaderErr: '', conTitleErr: 'Enter Header Title' })
            this.ScrollTop()
            return true
        } else if (!content1) {
            this.setState({ HeaderErr: 'Enter Header Title' })
            this.ScrollTop()
            return true
        }


        try {
            this.props.these('btnDisable', true)
            this.setState({ btnDisable: true, conTitleErr: '' })
            const formData = new FormData();
            if (pageSelected) formData.append("searchTags", pageSelected.label);
            formData.append("name", pageName);
            formData.append("themeId", 3);

            formData.append("show", 0);
            if (content2) formData.append('content2', content2)
            if (content1) formData.append('content1', content1)
            if (contentTitle2) formData.append('contentTitle2', contentTitle2)

            formData.append("sheetContent", JSON.stringify(AllContent));
            formData.append("videoOverlay", JSON.stringify(AllContent2));
            formData.append("contentTitle1", contentTitle);
            formData.append("Date", moment().format('YYYY-MM-DD'));
            formData.append("status", "show");
            formData.append("customerId", customerId);
            formData.append("requireLogin", 'no');

            let deletefiles = []

            if (AllContent && orgsheetContent && orgsheetContent.length) {
                let newArry = AllContent.map(e => e.File)
                const Files = orgsheetContent.filter(e => !newArry.some(x => e == x))
                if (Files.length) {
                    deletefiles = [...Files]
                }
            }
            if (AllContent2 && orgvideoOverlay && orgvideoOverlay.length) {
                let newArry = AllContent2.map(e => e.File)
                const Files = orgvideoOverlay.filter(e => !newArry.some(x => e == x))
                if (Files.length) {
                    deletefiles = [...deletefiles, ...Files]
                }
            }

            if (deletefiles.length > 0) formData.append('deleteArray', JSON.stringify(deletefiles))


            const id = editId ? editId : 'id'

            const result = await CmsContent.MultiArrayFileUpload('tbl_pages', formData, id)
            if (result) {
                this.setState({
                    contentTitle: '',
                    content2: '',
                    file: null,
                    AllContent: [{ name: '', role: '', description: '', File: null, index: 1 }],
                    btnDisable: false,
                })
                this.props.these('pageSelected', '');
                this.props.these('pageName', '');
                this.props.these('checked', 0);
                this.props.these('errorPageName', '')
                this.props.these('errorPage', '')
                this.props.these('themeSelected', '')
                this.props.these('themeId', '')
                this.props.these('fileImg', null)
                this.props.these('file2Img', null)
                this.props.these('alertVisible', true)
                this.props.these('btnDisable', false)
                this.props.mainfun()
            }

        } catch (error) {
            console.log(error);
            this.setState({ btnDisable: false })
        }
    }
    selectImage = e => {
        this.setState({ file: e.target.files[0] });
        this.setState({ fileName: e.target.files[0].name });
        // if (e) {
        //     this.setState({
        //         file: e.map(fileItem => fileItem.file),
        //         fileName: e.map(fileItem => fileItem.file)
        //     })
        // }
    };
    submit = async e => {
        e.preventDefault();
        const { file, hint } = this.state;
        if (!file) {
            this.setState({ errorFile: "Select File to Upload" });
            return false;
        } else if (!hint) {
            this.setState({ errorFile: "", errorHint: "Enter Searchable hint" });
            return false;
        } else {
            this.setState({ errorFile: "", btnDisable: true });
            let customerId = this.state.customerId;
            const formData = new FormData();
            // if (file) {
            //     file.map((ival) => {
            //         formData.append("file", ival);
            //     })
            // }
            formData.append("file", file)
            formData.append("type", hint);
            formData.append("customerId", customerId);

            try {
                // let Result = await CmsContent.insertImage(formData, "tbl_image");
                let Result = await CmsContent.SteerImage(formData, 'singleInsert')
                if (Result) {
                    let list = this.state.getlibrarydataImage;
                    const id = list[list.length - 1].id + 1;
                    Result.data.value = id
                    Result.data.label = Result.data.file
                    Result.data.hint = Result.data.type
                    list = [Result.data, ...list]
                    this.setState({
                        getlibrarydataImage: list,
                        file: "",
                        errorFile: "",
                        btnDisable: false,
                        alertVisible1: true,
                        fileName: "",
                        hint: "",
                        errorHint: null
                    });
                    //remove success msg after 3 sec
                    setTimeout(() => this.setState({ alertVisible1: false }), 3000);
                }
            } catch (error) { }
        }
    };
    FILEUPLOAD = (e, doc) => {
        if (e) {
            this.setState({
                [doc]: e.map(fileItem => fileItem.file)
            });
        }
    };
    render() {
        let { AllContent, contentTitle, content2, content1, AllContent2, contentTitle2 } = this.state;
        const { fileImg } = this.props.that;
        console.log(contentTitle, content2, 'content2');
        return (
            <React.Fragment>
                <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                        <label htmlFor="position" >Title</label>
                    </div>
                    <div className="col-sm-5">
                        <input
                            type="text"
                            className="form-control"
                            placeholder={'Enter Title'}
                            name="contentTitle"
                            onChange={this.handleUserInput}
                            value={this.state.contentTitle}
                        />
                    </div>
                    <div className="col-sm-3" >
                        <span className="error-show ">
                            {this.state.HeaderErr}
                        </span>
                    </div>
                </div>

                <br />

                <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                        <label htmlFor="exampleInputEmail1">
                            Description
                        </label>
                    </div>
                    <div className="col-sm-5">
                        <textarea
                            type="text"
                            className="form-control"
                            style={{ minHeight: "200px" }}
                            id="content2"
                            name="content2"
                            placeholder="Enter Description"
                            value={content2}
                            onChange={this.handleUserInput}
                        />
                    </div>
                    <div className="col-sm-3" >
                        <span className="error-show ">
                            {this.state.conTitleErr}
                        </span>
                    </div>
                </div>
                <br />
                <hr className="hrColour" />
                <div className="row form-group">
                    <div className="col-sm-1" />
                    Meet The Officials:
                </div>
                <br />
                <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                        <label htmlFor="position" >Header Title</label>
                    </div>
                    <div className="col-sm-5">
                        <input
                            type="text"
                            className="form-control"
                            placeholder={'Enter Header Title'}
                            name="contentTitle2"
                            onChange={this.handleUserInput}
                            value={contentTitle2}
                        />
                    </div>
                    <div className="col-sm-3" >
                        <span className="error-show ">
                            {this.state.HeaderErr}
                        </span>
                    </div>
                </div>
                <br />
                {AllContent2 && AllContent2.map((ival, i) => {
                    let index = i + 1;

                    return (
                        <React.Fragment>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4>Section -{index}
                                            </h4>
                                        </div>
                                        <div className="card-body">
                                            <br />
                                            <div className="row form-group">
                                                <div className="col-sm-2" />
                                                <div className="col-sm-2">
                                                    Image
                                                </div>
                                                <div className="col-sm-3" >
                                                    <LoginModal
                                                        buttonTitle="Select Icon from Library"
                                                        title="Select Image"
                                                        id="iconu"
                                                        extraClass={'info'}
                                                        bodyText={
                                                            <FromLibrary
                                                                selectImage={this.selectImage}
                                                                fileName={this.state.fileName}
                                                                handleChange={e => this.setState({ hint: e.target.value })}
                                                                dismiss={this.onDismiss}
                                                                disableValue={this.state.btnDisable}
                                                                alertMsg="New Library Added"
                                                                submit={this.submit}
                                                                hint={this.state.hint}
                                                                alertVisible={this.state.alertVisible1}
                                                                datas={this.state.getlibrarydataImage}
                                                                columnHeading={this.contentHeading2}
                                                                imgsource={this.state.viewmedia}
                                                                errorFile={this.state.errorFile}
                                                            />
                                                        }

                                                        onClick={() => {
                                                            this.setState({ Clickindex: i });
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-sm-3">
                                                    {ival.File ?
                                                        <div style={{ position: 'relative', display: 'inline' }}>
                                                            <img src={ImgSTEERAUTO + ival.File} style={{ objectFit: 'contain', height: 76 }} />
                                                            <img src={iconClose} style={{ height: 20, width: 20, position: 'absolute', borderRadius: '70px', backgroundColor: 'black', right: 0 }}
                                                                onClick={() => this.JSON_refill2(null, "File", i)}></img>
                                                        </div>
                                                        : null}
                                                </div>
                                                <div className="col-sm-1">
                                                    <i
                                                        className="fa fa-window-close fa-3x"
                                                        aria-hidden="true"
                                                        onClick={() => this.addMore2(1, i)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row form-group">
                                                <div className="col-sm-2" />
                                                <div className="col-sm-2">
                                                    <label htmlFor="exampleInputEmail1">
                                                        Name
                                                    </label>
                                                </div>


                                                <div className="col-sm-5">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Name"
                                                        value={ival.name}
                                                        onChange={e => this.JSON_refill2(e.target.value, 'name', i)}
                                                    />
                                                </div>
                                                <div className="col-sm-3" />
                                            </div>

                                            <div className="row form-group">
                                                <div className="col-sm-2" />
                                                <div className="col-sm-2">
                                                    <label htmlFor="exampleInputEmail1">
                                                        Role
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter  Role"
                                                        value={ival.role}
                                                        onChange={e => this.JSON_refill2(e.target.value, 'role', i)}
                                                    />
                                                </div>
                                                <div className="col-sm-3" />
                                            </div>

                                            <div className="row form-group">
                                                <div className="col-sm-2" />
                                                <div className="col-sm-2">
                                                    <label htmlFor="exampleInputEmail1">
                                                        Description
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <textarea
                                                        type="text"
                                                        className="form-control"
                                                        style={{ minHeight: "200px" }}
                                                        placeholder="Enter Description"
                                                        value={ival.description}
                                                        onChange={e => this.JSON_refill2(e.target.value, 'description', i)}
                                                    />
                                                </div>
                                                <div className="col-sm-3" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                })
                }
                <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-6" />
                    <div className="col-sm-2">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => this.addMore2()}
                        >
                            Add More
                        </button>
                    </div>
                </div>
                <hr className="hrColour" />
                <div className="row form-group">
                    <div className="col-sm-1" />
                    Meet The Team:
                </div>
                <br />
                <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                        <label htmlFor="position" >Header Title</label>
                    </div>
                    <div className="col-sm-5">
                        <input
                            type="text"
                            className="form-control"
                            placeholder={'Enter Header Title'}
                            name="content1"
                            onChange={this.handleUserInput}
                            value={content1}
                        />
                    </div>
                    <div className="col-sm-3" >
                        <span className="error-show ">
                            {this.state.HeaderErr}
                        </span>
                    </div>
                </div>
                <br />
                {AllContent && AllContent.map((ival, i) => {
                    let index = i + 1;

                    return (
                        <React.Fragment>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4>Section -{index}
                                            </h4>
                                        </div>
                                        <div className="card-body">
                                            <br />
                                            <div className="row form-group">
                                                <div className="col-sm-2" />
                                                <div className="col-sm-2">
                                                    Image
                                                </div>
                                                <div className="col-sm-3" >
                                                    <LoginModal
                                                        buttonTitle="Select Icon from Library"
                                                        title="Select Image"
                                                        id="iconu"
                                                        extraClass={'info'}
                                                        bodyText={
                                                            <FromLibrary
                                                                selectImage={this.selectImage}
                                                                fileName={this.state.fileName}
                                                                handleChange={e => this.setState({ hint: e.target.value })}
                                                                dismiss={this.onDismiss}
                                                                disableValue={this.state.btnDisable}
                                                                alertMsg="New Library Added"
                                                                submit={this.submit}
                                                                hint={this.state.hint}
                                                                alertVisible={this.state.alertVisible1}
                                                                datas={this.state.getlibrarydataImage}
                                                                columnHeading={this.contentHeading}
                                                                imgsource={this.state.viewmedia}
                                                                errorFile={this.state.errorFile}
                                                            />
                                                        }

                                                        onClick={() => {
                                                            this.setState({ Clickindex: i });
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-sm-3">
                                                    {ival.File ?
                                                        <div style={{ position: 'relative', display: 'inline' }}>
                                                            <img src={ImgSTEERAUTO + ival.File} style={{ objectFit: 'contain', height: 76 }} />
                                                            <img src={iconClose} style={{ height: 20, width: 20, position: 'absolute', borderRadius: '70px', backgroundColor: 'black', right: 0 }}
                                                                onClick={() => this.JSON_refill(null, "File", i)}></img>
                                                        </div>
                                                        : null}
                                                </div>
                                                <div className="col-sm-1">
                                                    <i
                                                        className="fa fa-window-close fa-3x"
                                                        aria-hidden="true"
                                                        onClick={() => this.addMore(1, i)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row form-group">
                                                <div className="col-sm-2" />
                                                <div className="col-sm-2">
                                                    <label htmlFor="exampleInputEmail1">
                                                        Name
                                                    </label>
                                                </div>


                                                <div className="col-sm-5">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Name"
                                                        value={ival.name}
                                                        onChange={e => this.JSON_refill(e.target.value, 'name', i)}
                                                    />
                                                </div>
                                                <div className="col-sm-3" />
                                            </div>

                                            <div className="row form-group">
                                                <div className="col-sm-2" />
                                                <div className="col-sm-2">
                                                    <label htmlFor="exampleInputEmail1">
                                                        Role
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter  Role"
                                                        value={ival.role}
                                                        onChange={e => this.JSON_refill(e.target.value, 'role', i)}
                                                    />
                                                </div>
                                                <div className="col-sm-3" />
                                            </div>

                                            <div className="row form-group">
                                                <div className="col-sm-2" />
                                                <div className="col-sm-2">
                                                    <label htmlFor="exampleInputEmail1">
                                                        Description
                                                    </label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <textarea
                                                        type="text"
                                                        className="form-control"
                                                        style={{ minHeight: "200px" }}
                                                        placeholder="Enter Description"
                                                        value={ival.description}
                                                        onChange={e => this.JSON_refill(e.target.value, 'description', i)}
                                                    />
                                                </div>
                                                <div className="col-sm-3" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                })
                }
                <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-6" />
                    <div className="col-sm-2">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => this.addMore()}
                        >
                            Add More
                        </button>
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this.submitCategory()}
                        disabled={this.state.btnDisable}
                    >
                        {this.state.btnDisable ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;
                        {this.state.isEdit ? 'Update' : 'Submit'}
                    </button>

                </div>

            </React.Fragment >
        )
    }
}
