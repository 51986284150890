import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../components/Form/FormMiddleware";
//import DfCarsEnquiry from "./DfCarsEnquiry";
//import SingleSelect from "../../../../components/Form/SingleSelect";
import moment from "moment";

class UpdateStatus extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      comment: "",
      followup: "",
      alertVisible: false,
      textalert: "",
      isEdit: false,
      EditId: "",
      Index: "",
      EditData: {},
      IsClose: false,
      Userlog: JSON.parse(localStorage.getItem("userlog")),
      usertypelog: JSON.parse(localStorage.getItem("usertypelog")),
      Budget: null,
      CommentList: []


    }
  }

  async componentDidMount() {
    // console.log(this.props)
    //usertypelog.orderBy == 8
  }

  async componentWillReceiveProps(props) {
    try {
      if (props) {
        // console.log(props);

        this.setState({
          isEdit: props.isEdit,
          FullData: props.FullData,
          EditId: props.EditData.id,
          Index: props.Index,
          EditData: props.EditData,
          IsClose: props.IsClose,
          //Budget:props.EditData.Budget,
          //comment:props.EditData.comment,
          CustomerId: props.CustomerId,
          CommentList: []
        });
        if (props.IsClose == false) {
          this.GetComment(props.CustomerId);
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  GetComment = async (Cus) => {
    try {

      let result = await cmsContent.getFreedom(
        `tbl_dfcars_comments.*,tbl_UserType.usertype,tbl_user_web.userName as Name,tbl_user_web.id as UserID`,
        `tbl_dfcars_comments,tbl_user_web,tbl_MaptoLocation,tbl_UserType`,
        `tbl_dfcars_comments.customerid = ${Cus} and tbl_dfcars_comments.stage='actionplan'and tbl_user_web.id = tbl_dfcars_comments.userid and tbl_user_web.userportid = tbl_MaptoLocation.id and tbl_UserType.id = tbl_MaptoLocation.userTypeId`,
        1,
        `tbl_dfcars_comments.id DESC`,
      );
      if (result.data.length) {
        result.data.map((ival, i) => {
          // ival.created_At = moment(ival.created_At).format("DD-MM-YYYY");
          ival.followup = moment(ival.followup).format("DD-MM-YYYY");
          let Hour_and_Minute = new Date(ival.created_At);
          let DateDay = moment(Hour_and_Minute).format("DD-MM-YYYY");
          //  console.log(`${Hour_and_Minute.getHours()}=>${Hour_and_Minute.getMinutes()}`)
          let min = 0;
          if (Hour_and_Minute.getHours() < 10) {
            //Hour_and_Minute.getMinutes()
            min = `0${Hour_and_Minute.getHours()}`
          } else {
            min = Hour_and_Minute.getHours();
          }
          let sec = 0;
          if (Hour_and_Minute.getMinutes() < 10) {
            sec = `0${Hour_and_Minute.getMinutes()}`
          } else {
            sec = Hour_and_Minute.getMinutes();
          }
          ival.created_At = `${DateDay} at ${min}:${sec}`
        });

        //console.log(result.data);
        this.setState({ CommentList: result.data })
      }


    } catch (error) {
      console.log(error);
    }
  }

  handlechangeData = async (e) => {
    //  if(e.target.type=="date"){
    //    console.log(e.target.value);
    //  }
    this.setState({ [e.target.name]: e.target.value });
  };

  onDismiss = () => {
    this.setState({ alertVisible: false, formAlertdelete: false });
  };

  SubmitComment = async () => {
    try {
      const { comment, followup, CustomerId, Userlog, Budget, EditId, EditData, Index, FullData } = this.state;
      let groupArray = {};
      groupArray.comments = comment;
      groupArray.userid = Userlog.id;
      groupArray.stage = "actionplan";
      groupArray.serviceid = 10;
      groupArray.customerid = CustomerId;
      groupArray.followup = followup;
      groupArray.status = 'pending';

      const result = await cmsContent.addMaster("tbl_dfcars_comments", groupArray);
      if (result) {
        // console.log(result);
        let newData = [...FullData];
        newData[Index].pendingStatus = groupArray.status;
        this.props.these('Data', newData);
      }

    } catch (error) {
      console.log(error);
    }
  }

  UpdateCategory = async () => {
    const { comment, followup, Budget, EditId, EditData, Index, FullData } = this.state;
    let GroupArray = {};
    GroupArray.comment = comment;
    GroupArray.followup = followup;
    GroupArray.Budget = Budget;
    console.log(GroupArray);
    try {
      const result = await cmsContent.updateMaster(
        "tbl_dfcarz_prosperities",
        EditId,
        GroupArray
      );
      if (result) {
        this.SubmitComment();
        this.state.EditData.comment = comment;
        this.state.EditData.followup = followup;
        console.log(EditData);

        const newData = [...FullData];
        newData[Index] = EditData;
        this.props.these('Data', newData);
        this.setState({
          isEdit: false,
          comment: "",
          followup: "",
          EditData: {},
          Budget: "",
        })
      }
    } catch (error) {
      console.log(error);
    }
  }

  submitCategory = async () => {
    try {
      const { comment, EditId, EditData, FullData } = this.state;
      let GroupArray = {};
      GroupArray.comment = comment;
      GroupArray.prospective_status = 'inactive';
      GroupArray.last_approved_by = this.state.Userlog.id;
      const result = await cmsContent.updateMaster(
        "tbl_dfcarz_prosperities",
        EditId,
        GroupArray
      );
      if (result) {
        // console.log(result);
        let newData = [...FullData];
        newData = newData.filter(value => value.id !== EditId);
        // newData[Index]=EditData;
        this.props.these('Data', newData);
        this.setState({
          IsClose: false,
          comment: ""
        })
      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const { usertypelog } = this.state;
    // console.log(usertypelog)
    return (<React.Fragment>
      <main >
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h1>{this.state.IsClose == false ? "Add Next Action Plan" : "Close Enquiry"}</h1>
                </div>
                <div className="card-body" style={{ backgroundColor: "#ebedef" }}>
                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-7">
                      <Alert
                        className="badge-content"
                        color={this.state.color}
                        isOpen={this.state.alertVisible}
                        toggle={this.onDismiss}
                      >
                        {this.state.textalert}
                      </Alert>
                    </div>
                    <div className="col-sm-3" />
                  </div>
                  {usertypelog.orderBy == 9 && this.state.IsClose == false ? <React.Fragment>
                    <div className="row form-group">
                      <div className="col-sm-1" />
                      <div className="col-sm-3">
                        <label htmlFor="exampleInputEmail1">{this.state.IsClose == false ? "Plan" : "Comments"}</label>
                      </div>
                      <div className="col-sm-5">
                        <textarea
                          type="text"
                          className="form-control"
                          id="ColourName"
                          name="comment"
                          placeholder="Enter Comments"
                          value={this.state.comment}
                          onChange={this.handlechangeData}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    {this.state.IsClose == false ?
                      <React.Fragment>
                        <div className="row form-group">
                          <div className="col-sm-1" />
                          <div className="col-sm-3">
                            <label htmlFor="exampleInputEmail1">Preferred Price</label>
                          </div>
                          <div className="col-sm-5">
                            <input
                              type="text"
                              className="form-control"
                              id="ColourName"
                              name="Budget"
                              value={this.state.Budget}
                              onChange={this.handlechangeData}
                            />
                          </div>
                          <div className="col-sm-3" />
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-1" />
                          <div className="col-sm-3">
                            <label htmlFor="exampleInputEmail1">Next follow up</label>
                          </div>
                          <div className="col-sm-5">
                            <input
                              type="date"
                              className="form-control"
                              id="ColourName"
                              name="followup"
                              value={this.state.followup}
                              onChange={this.handlechangeData}
                            />
                          </div>
                          <div className="col-sm-3" />
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-3" />
                          <div className="col-sm-5">
                            <button
                              type="button"
                              className={`btn btn-${this.state.IsClose == false ? "primary" : "danger"
                                }`}
                              disabled={this.state.disableValue}
                              onClick={
                                this.state.IsClose == false
                                  ? this.UpdateCategory
                                  : this.submitCategory
                              }
                            >
                              {this.state.IsClose == false
                                ? "Update Enquiry"
                                : "Close Enquiry"}
                            </button>
                          </div>
                          <div className="col-sm-2" />
                        </div>
                      </React.Fragment>
                      : null}

                  </React.Fragment> : null}

                  {this.state.IsClose ? <React.Fragment>

                    <div className="row form-group">
                      <div className="col-sm-1" />
                      <div className="col-sm-3">
                        <label htmlFor="exampleInputEmail1">{this.state.IsClose == false ? "Plan" : "Comments"}</label>
                      </div>
                      <div className="col-sm-5">
                        <textarea
                          type="text"
                          className="form-control"
                          id="ColourName"
                          name="comment"
                          placeholder="Enter Comments"
                          value={this.state.comment}
                          onChange={this.handlechangeData}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-3" />
                      <div className="col-sm-5">
                        <button
                          type="button"
                          className={`btn btn-${this.state.IsClose == false ? "primary" : "danger"
                            }`}
                          disabled={this.state.disableValue}
                          onClick={
                            this.state.IsClose == false
                              ? this.UpdateCategory
                              : this.submitCategory
                          }
                        >
                          {this.state.IsClose == false
                            ? "Update Enquiry"
                            : "Close Enquiry"}
                        </button>
                      </div>
                      <div className="col-sm-2" />
                    </div>
                  </React.Fragment> : null}

                  <div className="row form-group">
                    {/* <div className="col-sm-6"/> */}
                    <div className="col-sm-12">
                      <div className="card-body" >
                        {this.state.CommentList.length ?
                          this.state.CommentList.map((ival, i) => {
                            //  if(ival.UserID==this.state.Userlog.id){
                            return (
                              <React.Fragment>
                                <div><p style={{ marginLeft: "89%", marginBottom: "-15px", fontSize: "10px" }}>{ival.created_At}</p></div>
                                <div className="row form-group">
                                  <div className="col-sm-12">
                                    <p style={{ color: "blue" }}>Action plan {" "} on {ival.followup}:</p> <p style={{ padding: "12px", backgroundColor: "white", fontWeight: "100", fontSize: "14px" }}>{" "} {ival.comments} {" "}</p>
                                    <p style={{ textAlign: "right", marginTop: "-32px" }}>{ival.status}</p>
                                  </div>
                                </div>
                              </React.Fragment>)
                          }) : null}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
    )
  }
}
export default UpdateStatus;