import { isNumber, isString } from 'lodash';
import React from 'react';
import ReactTable from 'react-table';
import Pagination from './Pagination';
import 'react-table/react-table.css';
import './styles.css'
import { CSVLink } from 'react-csv';
import moment from 'moment';
const Datatable = ({ data, columnHeading, result, excelColumn = null, excel = true, filterable = true, ...props }) => {
  // console.log("Datatable")
  // console.log(columnHeading, "columnHeading");
  const innerWidth = window.innerWidth
  const propsCom = innerWidth < 768 ? { PaginationComponent: Pagination } : {}
  let exelcol = []
  var count1 = 0
  let User = JSON.parse(localStorage.getItem("userlog"))
  let view_excel = false
  if (User && User.serviceId && User.serviceId == 10) {
    view_excel = true
  }
  // if (view_excel && columnHeading.length) {
  // columnHeading.map((ival) => {
  //   if (!ival.Cell || !ival.Header == "Mobile") {
  //     if (ival.Header && ival.accessor && ival.Header != "Contact") {
  //       exelcol.push({ label: ival.Header, key: ival.accessor })
  //     }
  //     if (ival.Header == "Contact") {
  //       if (ival.Header && ival.accessor) {
  //         exelcol.push({ label: "Mobile", key: ival.accessor })
  //       }
  //     }
  //   }

  // })
  // let index = columnHeading.findIndex(x => x.Header === "Contact");
  // columnHeading.splice(index, 1)
  // }
  // console.log(columnHeading, "columnHeading", exelcol, "exelcol");


  // function count(count, datacount) {
  //   console.log(count, datacount, "countcount");
  //   if (datacount == count) {
  //     document.getElementById("demo").innerHTML = "";
  //   }
  //   else if (datacount != count) {
  //     count1 = count
  //     document.getElementById("demo").innerHTML = `Filtered Records Found : ${count1}`;
  //   }
  // }

  // console.log(exelcol, "exelcol")
  return (
    <>
      {view_excel && <div className='row' style={{ marginBottom: "20px" }}>
        <p className='col-sm-3 text-left' style={{ marginBottom: "10px", fontSize: "19px" }}> Records Found : {data.length}</p>
        <p className='col-sm-3 text-left' style={{ marginBottom: "10px", fontSize: "19px" }}><span id='demo'></span></p>
        <div className='col-sm-6 text-right'>
          {excel = true && excelColumn && excelColumn.length > 0 ?
            <CSVLink
              filename={result ? result : `${User.userName}${window.location.pathname}(${moment().format("DD-MM-YYYY hh:mm a")}).csv`}
              data={data}
              headers={excelColumn}
            >
              <button
                className="btn btn-success"
                style={{ marginLeft: "5px" }}
              >
                Export to Excel
              </button>
            </CSVLink>
            : ""}
        </div>
      </div>
      }


      <ReactTable
        {...propsCom}
        data={data}
        columns={columnHeading}
        // onSortedChange={(newSorted, column, shiftKey) => { console.log(newSorted, column, shiftKey, "dataaa") }}
        // onSortedChange={(data) => { console.log(data, "dataaa"); }}
        getTableProps={(data) => {
          return {
            onKeyDown: (e, handleOriginal) => {
              // console.log(data.sortedData.length, "data.sortedData.length", data.data.length,"data.data.length");
              // count(data.sortedData.length, data.data.length)
            }
            // onChange: (e, handleOriginal) => {

            //   setTimeout(() => {
            //     console.log(data.sortedData.length, data.data.length, "data.sortedData.length, data.data.length");
            //     count(data.sortedData.length, data.data.length)
            //   }, 5000);
            // }
          };
        }}

        filterable={filterable}
        defaultPageSize={10}
        className="-striped-highlight"
        minRows={1}
        {...props}
        defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}





      />
    </>
  );
};
function filterCaseInsensitive(filter, row) {

  const id = filter.pivotId || filter.id;
  if (row[id] !== undefined && row[id] !== null && row[id] !== '') {
    if (isNaN(filter.value) && isString(row[id])) {
      return String(row[id].toLowerCase()).startsWith(
        filter.value.toLowerCase()
      );
    } else {
      return String(row[id]).startsWith(filter.value);
    }
  } else {
    return true;
  }
}


export default Datatable;
