import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

const DfmakePDF = ({ header, data, title }) => {
  //console.log(data);

  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.title}>
          <Text>{title}</Text>
        </View>

        <View style={styles.table}>
          <View style={styles.tableRow}>
            {header &&
              header.map((value) => {
                return (
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value}</Text>
                  </View>
                );
              })}
          </View>

          {data &&
            data.map((value, index) => {
              return (
                <View style={styles.tableRow}>
                  {/* <View><text>{title}</text> </View> */}
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{index + 1}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value.Make}</Text>
                  </View>
                  {/* <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value.modelid}</Text>
                  </View> */}
                </View>
              );
            })}
        </View>
      </Page>
    </Document>
  );
};
const styles = StyleSheet.create({
  body: {
    padding: 10,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    textAlign: "center",
  },
  tableColHeader: {
    marginTop: "5px",
    width: "16.6%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol: {
    width: "50%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: "auto",
    margin: 5,
    fontSize: 12,
    fontWeight: 500,
  },
  tableCell: {
    margin: "auto",
    margin: 5,
    fontSize: 10,
  },
  title: {
    fontSize: 19,
    fontWeight: "bold",
    marginLeft: "42%",
    marginBottom: "2px",
  },
});

export default DfmakePDF;
