import React, { Component } from "react";
import { SingleSelect, FormMiddleWare } from "../../../../components/Form";
import CmsContent from "../../../../MiddleWare/CmsContent";
import { Alert } from "reactstrap";
import { ACCESS_POINT, ImgSTEERAUTO } from "../../../../config";

//themes
import Theme1 from "./Pages/Theme1"
import Theme2 from "./Pages/Theme2"
import Theme3 from "./Pages/Theme3"
import Theme4 from "./Pages/Theme4"
import Theme5 from "./Pages/Theme5"


import swal from "sweetalert";
import { Datatable } from "../../../../components/Datatable";




class DfcarzCms extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            customerId: localStorage.getItem("userId"),
            alertVisible: false,
            options: [
                { label: "Theme1 (Header,Image,Description,Link,Image)", value: 1 },
                { label: "Theme2 (Benefits/Brands)", value: 2 },
                { label: "About us", value: 3 },
                { label: "Post", value: 4 },
                { label: "Theme3 (Header,Description)", value: 5 },

            ],
            pageopt: [{ label: "HOME", value: "/" }, { label: "BUYCAR", value: "/buycars" }, { label: "SELLCAR", value: "/sellyourcars" },
            { label: "SERVICES", value: "/Service" },
            { label: "ABOUTUS", value: "/aboutus" },
            { label: "BRANDS", value: "brand" },

            ],

        };
    }
    column = [
        {
            Header: "Page id",
            accessor: "id",
            // minWidth: 150
        },
        {
            Header: "Page",
            accessor: "searchTags",
            minWidth: 150
        },
        {
            Header: "Section",
            accessor: "name",
            minWidth: 250
        },
        {
            Header: "Status",
            accessor: "status",
            minWidth: 110
        },
        {
            Header: "Theme",
            accessor: "edit",
            Cell: (d) => this.getValueFromArray(d.original.themeId, this.state.options),
            minWidth: 250
        },
        {
            Header: "Visibility",
            accessor: "edit",
            Cell: (d) => this.dataTableButton('success btn-sm', 'Hide/Show', () => this.updateVisibility(d), this.state.btnDisable),
            minWidth: 150
        },
        {
            Header: "Edit",
            accessor: "edit",
            Cell: (d) => this.dataTableButton('danger btn-sm', 'Edit', () => this.onEdit(d), this.state.btnDisable),
            minWidth: 150
        },
        // {
        //     Header: "Delete",
        //     accessor: "delete",
        //     Cell: (d) => this.dataTableButton('danger', 'Delete', () => this.buttonDelete(d.original)),
        //     minWidth: 150
        // },
    ];


    async componentDidMount() {
        try {
            await this.MainFunc()
        } catch (error) {
            console.log(error);
        }
    }

    //MainFunction
    MainFunc = async () => {
        try {
            this.setState({ loading: true })
            let result = await CmsContent.BUYDFCARZ(this.state.customerId, "CmsData")
            if (result) {
                console.log(result, 'BUYDFCARZ');
                this.setState({
                    getlibrarydataImage: result.getlibrarydataImage ? result.getlibrarydataImage : [],
                    getlibrarydataVideo: result.getlibrarydataVideo ? result.getlibrarydataVideo : [],
                    data: result.data ? result.data : [],
                    themeId: 0,
                    loading: false
                })
            }

        } catch (error) {
            console.log(error);
            this.setState({ loading: false })
        }
    }


    //datatable Buttons  
    showTheme = (d) => {

        if (d.original.themeId) {
            return `Theme-${d.original.themeId}`;
        } else {
            return '-'
        }
    };


    //api Functions
    updateVisibility = async (value) => {
        const index = value.index;
        const previousData = [...this.state.data];
        const newData = { ...previousData[index] };

        if (newData.status === "show") {
            newData.status = "hide";
        } else {
            newData.status = "show";
        }
        const id = newData.id;
        const data = previousData.filter((value) => value.id !== id);
        data.splice(index, 0, newData);
        try {
            const result = await CmsContent.updatePage(newData);
            if (result) {
                this.setState({ data: data });
            }
        } catch (error) {
            this.setState({ data: previousData });
            console.log(error);
        }
    };
    updateLogin = async (value) => {

        const newData = value.original;
        if (newData.requireLogin === "yes") {
            newData.requireLogin = "no";
        } else {
            newData.requireLogin = "yes";
        }
        const id = newData.id;

        try {
            const result = await CmsContent.updateLoginStatus(newData);
            if (result) {
                this.MainFunc()
            }
        } catch (error) {
            console.log(error);
        }
    };
    buttonDelete = async (d) => {
        if (d && d.id) {

            swal({
                title: "Are you sure?",
                text: "Once deleted, you will not be able to recover this page",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then(async (willDelete) => {
                    if (willDelete) {
                        let result = await CmsContent.deleteMaster("tbl_pages", d.id)
                        if (result) {
                            await this.MainFunc()
                            swal("Your page has been deleted!", {
                                icon: "success",
                            });
                        } else {
                            await this.MainFunc()
                            swal("Something went wrong. Try Again", {
                                icon: "warning",
                            });
                        }
                    }
                });
        }
        // }
    }
    onEdit = async (value) => {
        await this.reset()
        let d = value.original;


        let themeSelected = [];
        let appendOverlayList = d.videoOverlay ? JSON.parse(d.videoOverlay) : null;

        let themeId = [];

        if (d.file) {
            this.imgFun("fileImg", "fileJson", 'file', d)
        }
        if (d.file2) {
            this.imgFun("file2Img", "file2Json", 'file2', d)
        }

        //Theme selection
        this.state.options.map((ival, k) => {
            if (ival.value == d.themeId) {
                themeSelected = ival;
                themeId = ival.value;
            }
        });

        //org json
        let orgsheetContent = []
        let orgvideoOverlay = []
        if (d.sheetContent) {
            let Array = JSON.parse(d.sheetContent)
            orgsheetContent = Array && Array.length ? Array.map(e => e.File) : null
        }
        if (d.videoOverlay) {
            let Array = JSON.parse(d.videoOverlay)
            orgvideoOverlay = Array && Array.length ? Array.map(e => e.File) : null
        }

        this.setState({
            pageSelected: d.searchTags ? this.state.pageopt.filter(e => e.label == d.searchTags)[0] : null,
            pageName: d.name,
            themeSelected,
            themeId,
            contentTitle1: d.contentTitle1,
            content1: d.content1,
            headDesc: d.headDesc,
            content2: d.content2,
            searchTags: d.searchTags,
            fileName1: d.file,
            fileText: d.filename,
            thumbnailname: d.file2,
            thumbnail: d.thumbnail,
            isEdit: true,
            contentTitle2: d.contentTitle2,
            appendOverlayList,
            sheetContent: d.sheetContent ? JSON.parse(d.sheetContent) : null,
            videoOverlay: d.videoOverlay ? JSON.parse(d.videoOverlay) : null,
            editid: d.id,
            linkto: d.linkto,
            subTitleId: d.subTitleId,
            data1: d,
            fileJson: d.file ? JSON.parse(d.file) : null,
            file2Json: d.file2 ? JSON.parse(d.file2) : null,
            orgfileJson: d.file ? JSON.parse(d.file) : null,
            orgfile2Json: d.file2 ? JSON.parse(d.file2) : null,
            orgsheetContent,
            orgvideoOverlay

        });
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };

    removeimg = async (key, i, section, file) => {
        let images = null
        let img = null

        images = this.state[section] //images field
        img = this.state[file] //old file field

        if (images && img) {
            images && images.splice(i, 1)
            img && img.splice(i, 1)
            await this.setState({ [section]: images, [file]: img })
            this.imgFun(section, file, key)

        }


    }
    imgFun = async (imagestate, jsonstate, field = null, d = null) => {
        let ImageArray = []

        let images = d && d[field] ? JSON.parse(d[field]) : this.state[jsonstate];
        if (images && images.length > 0) {
            let w = images.map(async (ival, i) => {
                await ImageArray.push(
                    <div>
                        <div style={{ paddingLeft: '160px', position: 'relative' }} >
                            <i
                                className="fa fa-window-close fa-3x"
                                aria-hidden="true"
                                style={{ height: 39, width: 40, position: 'absolute' }}
                                onClick={() => this.removeimg(field, i, imagestate, jsonstate)}
                            />

                        </div>
                        <img src={ImgSTEERAUTO + ival}
                            onClick={() => { window.open(ImgSTEERAUTO + ival) }}
                            style={{
                                width: "200px", height: "200px", display: "inline-block", padding: "10px"

                            }} />
                    </div>
                )
            })
            await Promise.all(w)
            this.setState({ [imagestate]: ImageArray })
        }
    }
    //handelers
    handleTheme = (selectedOption) => {
        this.setState({
            themeSelected: selectedOption,
            themeId: selectedOption.value,
            themeName: selectedOption.label,
            sheetContent: null
        });
    };

    pagenameError = async (s, v) => {
        this.setState({ [s]: v });
        if (this.state.alertVisible === true) {
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
            setTimeout(() => this.setState({ alertVisible: false }), 3000);
        }
    };


    reset = async () => {
        await this.setState({
            pageName: '',
            themeSelected: null,
            themeId: 0,
            contentTitle1: '',
            content1: '',
            searchTags: '',
            fileName1: '',
            fileText: '',
            thumbnailname: '',
            isEdit: false,
            appendOverlayList: null,
            sheetContent: null,
            videoOverlay: null,
            contentTitle2: null,
            editid: null,
            linkto: null,
            subTitleId: null,
            data1: null,
            pageSelected: null,
            fileImg: null,
            file2Img: null,
            fileJson: null,
            file2Json: null,
            subTitleId: null,
            orgsheetContent: null,
            orgvideoOverlay: null,
            orgfileJson: null,
            orgfile2Json: null
        });
    }

    render() {

        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3>Add Page</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-7">
                                                <Alert
                                                    className="badge-content"
                                                    color="success"
                                                    isOpen={this.state.alertVisible}
                                                    toggle={this.onDismiss}
                                                >
                                                    Page Added Successfully
                                                </Alert>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>

                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="exampleInputEmail1">Select Page</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <SingleSelect
                                                    options={this.state.pageopt}
                                                    handleChange={(e) => this.handleSelect(e, 'pageSelected')}
                                                    selectedService={this.state.pageSelected}
                                                />
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-4" />
                                            <span className="error-show ">
                                                {this.state.errorPage}
                                            </span>
                                        </div>

                                        <br />

                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="exampleInputEmail1">Section Name</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="pageName"
                                                    name="pageName"
                                                    placeholder="Enter Page Name"
                                                    value={this.state.pageName}
                                                    onChange={this.handleUserInput}
                                                />
                                            </div>
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-4" />
                                            <span className="error-show ">
                                                {this.state.errorPageName}
                                            </span>
                                        </div>

                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="exampleInputEmail1">Select Theme</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <SingleSelect
                                                    options={this.state.options}
                                                    handleChange={this.handleTheme}
                                                    selectedService={this.state.themeSelected}
                                                />
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-4" />
                                            <span className="error-show ">
                                                {this.state.errorThemeId}
                                            </span>
                                        </div>
                                        {this.state.themeId == 1 ? (
                                            <Theme1 that={this.state} these={this.pagenameError} mainfun={this.MainFunc} />
                                        ) : null}
                                        {this.state.themeId == 2 ? (
                                            <Theme2 that={this.state} these={this.pagenameError} mainfun={this.MainFunc} />
                                        ) : null}
                                        {this.state.themeId == 3 ? (
                                            <Theme3 that={this.state} these={this.pagenameError} mainfun={this.MainFunc} />
                                        ) : null}
                                        {this.state.themeId == 4 ? (
                                            <Theme4 that={this.state} these={this.pagenameError} mainfun={this.MainFunc} />
                                        ) : null}
                                        {this.state.themeId == 5 ? (
                                            <Theme5 that={this.state} these={this.pagenameError} mainfun={this.MainFunc} />
                                        ) : null}
                                        <br />

                                        <div className="row form-group">
                                            <div className="col-sm-12">
                                                <button
                                                    type="button"
                                                    className="btn btn-block btn-warning"
                                                    style={{ maxWidth: 150, float: "right", fontSize: "15px" }}
                                                    onClick={() => this.reset()}
                                                >
                                                    RESET
                                                </button>
                                            </div>
                                        </div>

                                        <br />
                                        <div className="row form-group">
                                            <div className="col-sm-12">
                                                {this.state.loading ? <div className="text-center"> <i
                                                    className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                                                ></i></div> : this.state.data && this.state.data.length > 0 ? (
                                                    <Datatable
                                                        data={this.state.data}
                                                        columnHeading={this.column}
                                                    />
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

export default DfcarzCms;
