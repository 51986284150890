import React, { Component } from 'react'
import { ACCESS_POINT, ImgSTEERAUTO } from '../../../../config'
import CmsContent from '../../../../MiddleWare/CmsContent'
import LoginModal from '../../../../components/Modal/Modal'
import FromdfLibrary from '../../CMS/dfcarzlibrary'
import { Datatable } from '../../../../components/Datatable'
import swal from "sweetalert";
import { FormMiddleWare } from '../../../../components/Form'
import { Alert } from 'reactstrap';
//fileupload

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginImageResize,
    FilePondPluginFileValidateType,
    FilePondPluginFileValidateSize,
    FilePondPluginImageValidateSize
);
export default class Filesteeauto extends FormMiddleWare {
    constructor(props) {
        super(props)
        this.state = {
            alertVisible1: false,
            SelectedFiles: [],
        }
    }
    contentHeading = [
        {
            Header: "ID",
            accessor: "id"
        },
        {
            Header: "Search By Hint",
            accessor: "type"
        },
        {
            Header: "image",
            accessor: "label",
            Cell: d => d.original.file ?
                <img src={ImgSTEERAUTO + `${d.original.file}`} onClick={() => window.open(ImgSTEERAUTO + `${d.original.file}`)} style={{ objectFit: 'contain', width: 100, height: 50 }} /> : null
        },
        {
            Header: "Created At",
            accessor: "createdAt"
        },
        {
            Header: "Delete",
            accessor: "type",
            Cell: d => this.delete(d.original)
        },
        {
            Header: "Select",
            Cell: (d) => this.checkbox(d)

        }
    ];
    async componentDidMount() {
        await this.mainfun()
    }

    checkbox = (d) => {
        // console.log(d, "d");
        return (
            <div style={{ 'text-align': 'center' }}>
                <input type="checkbox"
                    name='deleteCheck'
                    checked={
                        this.state.SelectedFiles.filter(x => x.id == d.original.id).length > 0 ? true : false
                    }
                    onChange={(event) => this.SelectItems(d.original, d.index)} />
            </div>
        )
    }
    SelectItems = async (d, f) => {
        let { SelectedFiles } = this.state
        if (d) {
            // const index = SelectedFiles.indexOf(d);
            const index = SelectedFiles.findIndex(e => e.id == d.id);
            if (index > -1) {
                console.log("already here");
                SelectedFiles.splice(index, 1);
            } else {
                console.log("new");
                SelectedFiles.push({
                    id: d.id,
                    file: d.file
                })
            }
            await this.setState({ SelectedFiles })
            console.log(SelectedFiles, 'SelectedFiles');
        }
    }

    Selectall = () => {
        let checkboxes = document.getElementsByName('deleteCheck')
        for (var checkbox of checkboxes) {
            checkbox.checked = true;
        }
        let SelectedFiles = []
        this.state.datas.map((ival) => {
            SelectedFiles.push({
                id: ival.id,
                file: ival.file
            })
        })
        this.setState({ SelectedFiles })

    }
    clearAll = () => {
        let checkboxes = document.getElementsByName('deleteCheck')
        for (var checkbox of checkboxes) {
            checkbox.checked = false;
        }
        this.setState({ SelectedFiles: [] })

    }
    DeleteAll = async () => {
        const { SelectedFiles } = this.state

        try {
            swal({
                title: "Are you sure?",
                text: "Once deleted, you will not be able to recover this page",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then(async (willDelete) => {
                    if (willDelete) {
                        this.setState({ btnDisable: true })
                        const form = new FormData()
                        const Files = await SelectedFiles.map(e => e.file)
                        form.append('deletefiles', JSON.stringify(Files))
                        let result = await CmsContent.SteerImage(form, 'Multidelete')
                        if (result) {
                            this.setState({
                                file: "",
                                errorFile: "",
                                btnDisable: false,
                                fileName: "",
                                hint: "",
                                errorHint: null
                            });
                            //remove success msg after 3 sec                          
                            await this.mainfun()
                            this.clearAll()
                            swal("Your Files has been deleted!", {
                                icon: "success",
                            });
                        } else {
                            await this.mainfun()
                            this.clearAll()
                            swal("Something went wrong. Try Again", {
                                icon: "warning",
                            });
                        }
                    }
                });


        } catch (err) {
            console.log(err);
        }
    }
    delete = (d) => {
        return (
            <button disabled={this.state.btnDisable} onClick={async () => { this.deleteapi(d) }} className="btn btn-danger">{this.state.btnDisable ? "Please Wait.." : "Delete"}</button>
        )
    }
    mainfun = async () => {
        this.setState({ loading: true })
        let { data: result } = await CmsContent.getFreedom('*', 'tbl_image', 'customerId=620 and storedAt="server"', 1, 'id desc')
        if (result) {
            this.setState({ datas: result, loading: false })
        }
        console.log(result, "result");
    }
    selectImage = e => {
        // this.setState({ file: e.target.files[0] });
        // this.setState({ fileName: e.target.files[0].name });
        if (e) {
            this.setState({
                file: e.map(fileItem => fileItem.file),
                // fileName: e.map(fileItem => fileItem.file)
            });
        }
    };
    deleteapi = async (d) => {
        try {
            swal({
                title: "Are you sure?",
                text: "Once deleted, you will not be able to recover this page",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then(async (willDelete) => {
                    if (willDelete) {
                        this.setState({ btnDisable: true })
                        let form = new FormData()
                        form.append('id', d.id)
                        form.append('file', d.file)
                        let result = await CmsContent.SteerImage(form, 'delete')
                        if (result) {
                            this.setState({
                                file: "",
                                errorFile: "",
                                btnDisable: false,
                                fileName: "",
                                hint: "",
                                errorHint: null
                            });
                            //remove success msg after 3 sec                          
                            await this.mainfun()
                            swal("Your page has been deleted!", {
                                icon: "success",
                            });
                        } else {
                            await this.mainfun()
                            swal("Something went wrong. Try Again", {
                                icon: "warning",
                            });
                        }
                    }
                });


        } catch (err) {
            console.log(err);
        }
    }
    submit = async e => {
        console.log("h1");
        e.preventDefault();
        const { file, hint } = this.state;
        if (file && file.length) {
            let val = file.every(x => x.size <= 300000)
            if (!val) {
                alert("Please Check Image is Greater than 300KB")
                return true
            }
        }
        if (!file) {
            this.setState({ errorFile: "Select File to Upload" });
            return false;
        } else if (!hint) {
            this.setState({ errorFile: "", errorHint: "Enter Searchable hint" });
            return false;
        } else {
            this.setState({ errorFile: "", btnDisable: true });
            const formData = new FormData();
            if (file) {
                file.map((ival) => {
                    formData.append("file", ival);
                })
            }
            // formData.append("file", file);
            formData.append("type", hint);
            try {
                let Result = await CmsContent.SteerImage(formData, 'insert')
                console.log("h2");
                if (Result) {
                    this.setState({
                        file: "",
                        errorFile: "",
                        btnDisable: false,
                        alertVisible1: true,
                        fileName: "",
                        hint: "",
                        errorHint: null
                    });
                    //remove success msg after 3 sec
                    setTimeout(() => this.setState({ alertVisible1: false }), 1000);
                    this.mainfun()
                }
            } catch (error) { console.log(error); }
        }
    };
    onDismiss = () => {
        this.setState({ alertVisible: false });
    };
    render() {
        let { AllContent, contentTitle, content2, content1, Maxlength, SelectedFiles } = this.state;
        // const {fileImg} = this.props.that;
        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3>Add Page</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="row ">
                                            <div class='col-lg-12'>
                                                <LoginModal
                                                    buttonTitle="Add images"
                                                    title="Add Image"
                                                    id="iconu"
                                                    extraClass={'info'}
                                                    bodyText={
                                                        <>
                                                            <div style={{ textAlign: 'center' }}>
                                                                <div className="row form-group">
                                                                    <div className="col-sm-2" />
                                                                    <div className="col-sm-7">
                                                                        <Alert
                                                                            className="badge-content"
                                                                            color={"success"}
                                                                            isOpen={this.state.alertVisible1}
                                                                            toggle={this.onDismiss}
                                                                        >
                                                                            File Uploaded Successfully
                                                                        </Alert>
                                                                    </div>
                                                                    <div className="col-sm-3" />
                                                                </div>

                                                                <div className="row form-group">
                                                                    <div className="col-sm-2" />
                                                                    <div className="col-sm-2" >
                                                                        <label>Upload File </label>
                                                                    </div>
                                                                    <div className="col-sm-5">
                                                                        <FilePond
                                                                            files={this.state?.file}
                                                                            allowReorder={true}
                                                                            allowImageResize={true}
                                                                            allowMultiple={true}
                                                                        //    maxFileSize={'3072KB'}
                                                                            imagePreviewMinHeight="50"
                                                                            imagePreviewMaxHeight="50"
                                                                            maxFiles={20}
                                                                            acceptedFileTypes={["image/*"]}
                                                                            onupdatefiles={fileItems => {
                                                                                this.selectImage(fileItems);

                                                                            }}
                                                                            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                                                        />
                                                                        {/* <label style={{ color: "black" }} >Maximum Size : 3MB</label><br /> */}
                                                                    </div>
                                                                    <div className="col-sm-3" />
                                                                </div>
                                                                <div className="row form-group">
                                                                    <div className="col-sm-4" />
                                                                    <span className="error-show ">{this.state.errorFile}</span>
                                                                </div>
                                                                <div className="row form-group">
                                                                    <div className="col-sm-2" />
                                                                    <div className="col-sm-2">
                                                                        <label for="exampleInputEmail1">Search Hint</label>
                                                                    </div>
                                                                    <div className="col-sm-5 custom-file">
                                                                        <input
                                                                            type="text"
                                                                            name="searchhint"
                                                                            placeholder="Enter  hint"
                                                                            className="form-control"
                                                                            value={this.state.hint}
                                                                            onChange={e => this.setState({ hint: e.target.value })}
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-3" />
                                                                </div>
                                                                <div className="row form-group">
                                                                    <div className="col-sm-4" />
                                                                    <span className="error-show ">{this.state.errorHint}</span>
                                                                </div>

                                                                <div className="row form-group">
                                                                    <div className="col-sm-2" />
                                                                    <div className="col-sm-2" />
                                                                    <div className="col-sm-5">
                                                                        <button
                                                                            type="button"
                                                                            class="btn btn-primary"
                                                                            disabled={this.state.btnDisable}
                                                                            onClick={this.submit}
                                                                        >
                                                                            {this.state.btnDisable ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;
                                                                            Submit
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-sm-3" />
                                                                </div>
                                                            </div >
                                                        </>
                                                    }
                                                />

                                            </div>
                                        </div>
                                        <br />
                                        {SelectedFiles && SelectedFiles.length ?
                                            <>
                                                <div className='d-flex flex-row-reverse'>
                                                    <div className='p-2'>
                                                        <button
                                                            type="button"
                                                            className="btn btn-info"
                                                            disabled={this.state.btnDisable}
                                                            onClick={() => this.clearAll()}
                                                        >
                                                            Clear All
                                                        </button>
                                                    </div>
                                                    <div className='p-2'>
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger"
                                                            disabled={this.state.btnDisable}
                                                            onClick={() => this.DeleteAll()}
                                                        >
                                                            Delete
                                                        </button>
                                                    </div>
                                                    <div className='p-2'>
                                                        <button
                                                            type="button"
                                                            className="btn btn-secondary"
                                                            disabled={this.state.btnDisable}
                                                            onClick={() => this.Selectall()}
                                                        >
                                                            Select all
                                                        </button>
                                                    </div>
                                                </div>
                                            </> : ""}
                                        <br />
                                        {this.state.loading ? <div className="text-center"> <i
                                            className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                                        ></i></div> : this.state.datas && this.state.datas.length ? < div className="row ">
                                            <div class='col-lg-12'>
                                                <Datatable data={this.state.datas} columnHeading={this.contentHeading} />
                                            </div>
                                        </div> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

            </React.Fragment >
        )
    }
}