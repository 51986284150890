import http from './httpMiddleWare';
import { ACCESS_POINT } from '../config';
let device =JSON.parse(localStorage.getItem("info"));
let userId =JSON.parse(localStorage.getItem("userId"));
// if(userId){
//   device.id=userId;
// }

const getAllService = async () => {
  // console.log(ACCESS_POINT + `/superAdmin/getAllServices`);
  const result = await http.get(ACCESS_POINT + `/superAdmin/getAllServices`);
  return result;
};

const getFreedom = async (
  select,
  tableName,
  condition,
  groupby = "id",
  orderby = "id"
) => {
  let value = {};
  value.select = select;
  value.tableName = tableName;
  value.condition = condition;
  value.groupby = groupby;
  value.orderby = orderby;
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/getFullFreedom/getFreedom`,
    value
  );
  console.log(result)
  if(result.data){
    return result;
  } else {
    localStorage.clear();
    window.location = "/";
  }
};

export default {
  getAllService,
  getFreedom
};
