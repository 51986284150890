import React, { Component } from "react";
import { Alert } from "reactstrap";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import SingleSelect from "../../../../components/Form/SingleSelect";
import redisMiddleware from "../../../../MiddleWare/redisMiddleware";
import CmsContent from "../../../../MiddleWare/CmsContent";
import CategoryList from "../../../CMSUser/CategoryList";
import Preloader from "../preloader.js";

class AT_Specifications_Sheet_Proof extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      respect: [{ label: "Sir", value: 1 }, { label: "Madam", value: 1 }],
      answerJson: [],
      userid: JSON.parse(localStorage.getItem("userlog")),
      json: [],
      alertVisible: false,
      button: "Save",
      ownershipOpt: [
        {
          label: "1",
          value: 0
        },
        {
          label: "2",
          value: 1
        },
        {
          label: "3",
          value: 2
        },
        {
          label: "4",
          value: 3
        }
      ]
    };
  }

  async componentDidMount() {
    try {
      let varopt = [];
      let makopt = [];
      let modopt = [];
      let colopt = [];
      let serviceid = this.state.userid.serviceId;
      const form = await redisMiddleware.singleTable("tbl_form", 79, "id");
      let ServiceId = this.state.userid.serviceId;
      const variant = await redisMiddleware.singleTable(
        "tbl_variant_master",
        ServiceId,
        "ServiceId"
      );

      const model = await redisMiddleware.singleTable(
        "tbl_dfcarsmodel",
        ServiceId,
        "ServiceId"
      );
      const color = await redisMiddleware.singleTable(
        "tbl_colour",
        ServiceId,
        "ServiceId"
      );

      if (color) {
        color.data.map((cval, n) => {
          colopt.push({ label: cval.colour, value: cval.id });
        });
        this.setState({ colopt });
      }
      if (model) {
        model.data.map((mval, n) => {
          modopt.push({ label: mval.Model, value: mval.id });
        });
        this.setState({ modopt });
      }
      const make = await redisMiddleware.singleTable(
        "tbl_make_master",
        ServiceId,
        "ServiceId"
      );
      if (make) {
        make.data.map((lval, n) => {
          makopt.push({ label: lval.Make, value: lval.id });
        });
        this.setState({ makopt });
      }
      //const dfprosp=await CmsContent.getFreedom("")

      if (variant && variant.data.length > 0) {
        variant.data.map((ival, i) => {
          varopt.push({ label: ival.variant, value: ival.id });
        });
        this.setState({ varopt });
      }
      const formQues = await redisMiddleware.singleTable(
        "tbl_formquestion",
        79,
        "parentid"
      );
      formQues.data.map((ival,i)=>{
        if(ival.Orderby==null){
           ival.Orderby =1000 + i ;
        }
     });
    formQues.data = formQues.data.sort(await CmsContent.compareValues("Orderby","int","asc"));

      let answerJson = this.state.answerJson;

      if (this.props.match.params.cusid) {
        this.setState({ customerId: this.props.match.params.cusid });
      }
      this.setState({ data: formQues.data });
      console.log(this.state.customerId);
      let date1 = new Date();
      var year = date1.getFullYear();
      let mini_year = 1990;

      var difference = year - mini_year;
      console.log(difference);

      let makeYearOption = [];
      for (var i = 0; i <= difference; i++) {
        makeYearOption.push({
          label: mini_year + i,
          value: i
        });
      }
      console.log(makeYearOption);
      this.setState({ makeYearOption });
      const prosp = await CmsContent.getFreedom(
        "*",
        "tbl_dfcarz_prosperities",
        `id = ${this.state.customerId}`,
        "id",
        "id"
      );
      console.log(prosp);
      let mak = [];
      let mod = [];
      let d2 = prosp.data[0];
      // let PriceList = await CmsContent.getFreedom(
      //   `*`,
      //   `tbl_prize_list`,
      //   `make=${d2.make} and model=${d2.model} and color =${d2.color}`
      // );
      let PriceList = await CmsContent.getFreedom(
        `*`,
        `tbl_prize_list`,
        `make=${d2.make} and model=${d2.model}`
      );
      if (PriceList) {
        console.log(PriceList.data);
        this.setState({ PriceCheck: PriceList.data });
      }

      const enquiry = await CmsContent.getFreedom(
        "*",
        "tbl_dfcarz_enquiryform",
        `id=${prosp.data[0].enquiry_id}`,
        1,
        1
      );
      console.log(enquiry);
      if (enquiry) {
        let enq = enquiry.data[0];
        answerJson[394] = enq.Km;
        this.state.makeYearOption.map((kval, n) => {
          if (kval.label == enq.yom) {
            answerJson[392] = kval;
          }
        });
        this.state.ownershipOpt.map((oval, b) => {
          if (oval.label == enq.owner_type) {
            answerJson[393] = oval;
          }
        });
      }
      if (formQues) {
        formQues.data.map((ival, i) => {
          //console.log(ival)
          if (ival.id == 391) {
            ival.options = varopt;
          }
          if (ival.id == 390) {
            ival.options = makopt;
          }
          if (ival.id == 512) {
            ival.options = modopt;
          }
          if (ival.id == 393) {
            ival.options = this.state.ownershipOpt;
          }
        });
      }

      const items = await CmsContent.getFreedom(
        "*",
        "tbl_items",
        `id =${this.state.customerId}`,
        1,
        1
      );

      this.setState({ items: items.data });
      if (items && items.data.length > 0) {
        let answerJson = this.state.answerJson;
        let item = this.state.items[0];
        answerJson[394] = item.kms;
        answerJson[514] = item.price;
        // item.data.map((ival, n) => {
        modopt.map((mval, m) => {
          if (item.model == mval.value) {
            mod.push(mval);
            answerJson[512] = mval;
          }
        });
        this.state.ownershipOpt.map((oval, b) => {
          if (oval.label == item.owner_type) {
            answerJson[393] = oval;
          }
        });

        // })
        // prosp.data.map((ival, m) => {
        makopt.map((kval, v) => {
          if (item.make == kval.value) {
            mak.push(kval);
            answerJson[390] = kval;
          }
        });
        // })
        // prosp.data.map((cval, m) => {
        varopt.map((jval, n) => {
          // console.log(cval)
          // console.log(jval)
          if (jval.value == item.variant) {
            answerJson[391] = jval;
          }
        });
        // })
        // let pro = prosp.data[0];
        this.state.makeYearOption.map((kval, b) => {
          if (kval.label == item.make_year) {
            answerJson[392] = kval;
          }
        });
      }

      // // console.log(items)
      // if (items) {
      //   items.data.map((ival, i) => {
      //     make.data.map((mval, j) => {
      //       if (ival.make == mval.id) {
      //         makopt.push({ label: mval.Make, value: mval.id })
      //       }
      //     })
      //     // this.setState({ makopt })
      //   })
      //   items.data.map((jval, j) => {
      //     model.data.map((kval, k) => {
      //       if (jval.model == kval.id) {
      //         modopt.push({ label: kval.Model, value: kval.id })
      //       }
      //     })
      //     // this.setState({ modopt })
      //   })
      //   this.setState({ items: items.data })
      // }

      // if (prosp && prosp.data.length > 0) {
      //   let pro = prosp.data[0];
      //   // items.data.map((ival, j) => {
      //   //   if (ival.Make == pro.make && ival.Model == pro.model) {

      //   //   }
      //   // })
      //   prosp.data.map((ival, n) => {
      //     modopt.map((mval, m) => {
      //       if (ival.model == mval.value) {
      //         mod.push(mval)
      //         answerJson[512] = mval
      //       }
      //     })
      //   })
      //   prosp.data.map((ival, m) => {
      //     makopt.map((kval, v) => {
      //       if (ival.make == kval.value) {
      //         mak.push(kval)
      //         answerJson[390] = kval
      //       }
      //     })
      //   })
      //   prosp.data.map((cval, m) => {
      //     varopt.map((jval, n) => {
      //       console.log(cval)
      //       console.log(jval)
      //       if (jval.value == cval.variant) {
      //         answerJson[391] = jval
      //       }
      //     })
      //   })
      //   // let pro = prosp.data[0];
      //   this.state.makeYearOption.map((kval, b) => {
      //     if (kval.label == pro.year) {
      //       answerJson[392] = kval
      //     }
      //   })
      //   answerJson[514] = pro.Budget;
      // }

      const spec = await CmsContent.getFreedom(
        "*",
        " tbl_Specifications_Sheet_Proof",
        `customerid = ${this.state.customerId}`,
        "id",
        "id"
      );
      // console.log(spec)
      this.setState({ spec: spec.data });
      if (spec && spec.data.length > 0) {
        this.formfilled();
      }
      this.process();
    } catch (error) {
      console.error(error);
    }
  }
  formfilled = () => {
    let answerJson = this.state.answerJson;
    let data = this.state.data;
    let spec = this.state.spec[0];
    console.log("sp", spec);
    answerJson.id = spec.id;
    this.state.makopt.map((ival, i) => {
      if (ival.value == spec.brand) {
        answerJson[390] = ival;
      }
    });
    this.state.varopt.map((val, j) => {
      if (val.value == spec.variant) {
        answerJson[391] = val;
      }
    });
    this.state.modopt.map((jval, n) => {
      if (jval.value == spec.model) {
        answerJson[512] = jval;
      }
    });
    // data.map((ival, i) => {
    //   console.log(ival)
    // })
    //await item.options.split(",").map((element, key) => {
    // this.state.check.map((ival, i) => {
    //   console.log(ival)
    //   if (ival == spec.attraction) {
    //     console.log(ival)
    //   }
    // })
    //answerJson[392] = spec.year;
    answerJson[393] = spec.owernship;
    // answerJson[394] = spec.kms;
    answerJson[395] = spec.insurance;
    answerJson[397] = spec.attraction;
    answerJson[396] = spec.features;
    answerJson[398] = spec.finance;

    console.log(answerJson);
    this.setState({ button: "Update", answerJson });
    this.process();
  };
  update = async () => {
    const { answerJson } = this.state;
    let categoryArray = {};
    let id = answerJson.id;
    if (answerJson[390]) {
      categoryArray.brand = answerJson[390].value;
    }
    if (answerJson[391]) {
      categoryArray.variant = answerJson[391].value;
    }
    categoryArray.price = answerJson[514];
    if (answerJson[514] < this.state.PriceCheck[0].price) {
      categoryArray.price_approval = 0;
    } else {
      categoryArray.price_approval = 1;
    }
    if (answerJson[392]) {
      categoryArray.year = answerJson[392].label;
    }
    if (answerJson[393]) {
      categoryArray.owernship = answerJson[393].label;
    }
    categoryArray.kms = answerJson[394];
    categoryArray.insurance = answerJson[395];
    categoryArray.attraction = answerJson[397];
    categoryArray.features = answerJson[396];
    categoryArray.finance = answerJson[398];
    if (answerJson[512]) {
      categoryArray.model = answerJson[512].value;
    }
    console.log(categoryArray);

    let result = await CmsContent.updateMaster(
      "tbl_Specifications_Sheet_Proof",
      id,
      categoryArray
    );
    if (result) {
      await this.setState({
        answerJson: "",
        json: "",
        alertVisible: true,
        textalert: "Your form has been updated successfully",
        color: "success"
      });
      setTimeout(() => this.setState({ alertVisible: false }), 3000);
      this.process();
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }
  };
  async answers(
    label,
    index,
    options = null,
    key = null,
    type,
    questionId,
    formid
  ) {
    let answerJson = await this.state.answerJson;
    let selectbox = [];
    if (type == "text") {
      answerJson[questionId] = label;
    } else if (type == "date") {
      var d = new Date(label);
      var date = d.getDate();
      var month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      var year = d.getFullYear();
      var hr = d.getHours();
      var min = d.getMinutes();
      var sec = d.getSeconds();
      var dateStr =
        year + "-" + month + "-" + date + " " + hr + ":" + min + ":" + sec;
      answerJson[questionId] = dateStr;
    } else if (type == "textarea") {
      answerJson[questionId] = label;
    } else if (type == "selectbox") {
      answerJson[questionId] = label;
      //selectbox = label.label
    } else if (type == "radio") {
      answerJson[questionId] = label;
    } else if (type == "checkbox") {
      let check = [];
      options.map((ival, i) => {
        var checkBox = document.getElementById(`inlineCheckbox${index}${i}`);

        if (checkBox.checked == true) {
          check.push(ival);
        }
      });
      console.log(check.toString());
      answerJson[questionId] = check.toString();
      // answerJson[questionId] = label
    }
    await this.setState({ answerJson, selectbox });
    this.process();
  }
  process = async () => {
    let data = await this.state.data;
    if (data) {
      let arr = data;
      let contentJson = [];
      if (arr && arr.length) {
        let answerJson = await this.state.answerJson;
        let answerJsons = answerJson.length;
        let json = await arr.map(async (item, index) => {
          if (item.type == "text") {
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="position">{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={item.placeholder}
                      onChange={e =>
                        this.answers(
                          e.target.value,
                          index,
                          null,
                          null,
                          "text",
                          item.id,
                          item.parentid
                        )
                      }
                      value={answerJson[item.id]}
                    />
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "date") {
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="position">{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <input
                      type="date"
                      id="myDate"
                      className="form-control"
                      onChange={e =>
                        this.answers(
                          e.target.value,
                          index,
                          null,
                          null,
                          "date",
                          item.id,
                          item.parentid
                        )
                      }
                      // onChange={this.fromDateSelect}
                      // value={selectedstartdate}
                    />
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "textarea") {
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder={item.placeholder}
                      onChange={e =>
                        this.answers(
                          e.target.value,
                          index,
                          null,
                          null,
                          "textarea",
                          item.id,
                          item.parentid
                        )
                      }
                      value={answerJson[item.id]}
                    ></textarea>
                  </div>
                  <div className="col-sm-3">
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                </div>
              </div>
            );
          }
          if (item.type == "radio") {
            let arr1 = [];
            await item.options.split(",").map((element, key) => {
              arr1.push(
                <div className="bgcolor">
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      name={`element${index}`}
                      //   value={`${element}`}
                      id={`inlineCheckbox${key}`}
                      onChange={e =>
                        this.answers(
                          element,
                          key,
                          null,
                          key,
                          "radio",
                          item.id,
                          item.parentid
                        )
                      }
                      checked={element == answerJson[item.id]}
                    />
                    <label
                      className="form-check-label"
                      for={`inlineCheckbox${key}`}
                      style={{ marginLeft: "10px" }}
                    >
                      {`${element}`}{" "}
                    </label>
                  </div>
                </div>
              );
            });
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    {arr1}
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "selectbox") {
            let option = [];
            if (item.options != null && item.options != "") {
              item.options.map((ival, i) => {
                option.push(ival);
              });
            }
            this.state.selectbox = answerJson[item.id];
            // console.log(answerJson[item.id])
            //console.log('mag', this.state.selectbox)
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <SingleSelect
                      id="select"
                      options={option}
                      placeholder={item.placeholder}
                      handleChange={e => {
                        this.answers(
                          e,
                          index,
                          null,
                          null,
                          "selectbox",
                          item.id,
                          item.parentid
                        );
                      }}
                      selectedService={this.state.selectbox}
                      //value={this.state.selectbox}
                    />
                  </div>
                  <span className="error-shows" id={`${index}`}>
                    {/* {this.state.error[index]} */}
                  </span>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "checkbox") {
            let arr1 = [];
            await item.options.split(",").map((element, key) => {
              //element = answerJson[item.id]
              //console.log(answerJson[item.id])
              arr1.push(
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`inlineCheckbox${index}${key}`}
                    onChange={e => {
                      this.answers(
                        element,
                        index,
                        item.options.split(","),
                        key,
                        "checkbox",
                        item.id,
                        item.parentid
                      );
                    }}
                    // checked={element == answerJson[item.id]}
                  />
                  <label
                    className="form-check-label"
                    for={`inlineCheckbox${index}${key}`}
                    style={{ marginLeft: "10px" }}
                  >{`${element}`}</label>
                </div>
              );
            });
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">{arr1}</div>
                  <div className="col-sm-3">
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                </div>
              </div>
            );
          }
        });
        await Promise.all(json);
        await this.setState({ json: contentJson, answerJson });
      }
    }
  };

  formSubmit = async () => {
    // let error = [...this.state.error];
    // let e = [];
    // this.state.data.map((item, i) => {
    //   this.state.answerJson.map((element, key) => {
    //     if (
    //       item.required == 1 &&
    //       item.id == element.questionId &&
    //       (element.answer == null || element.answer == "-")
    //     ) {
    //       error[i] = "Value must not be empty";
    //       e.push(error[i]);
    //     } else if (
    //       item.required == 1 &&
    //       item.id == element.questionId &&
    //       (element.answer != null || element.answer != "-")
    //     ) {
    //       error[i] = null;
    //       e.push(error[i]);
    //     }
    //   });
    // });
    // this.setState({ error: error });
    // this.process();

    // for (var i = 0; i < e.length; i++) {
    //   if (e[i] != null) {
    //     return false;
    //   }
    // }
    const { answerJson } = this.state;
    let categoryArray = {};
    if (answerJson[512]) {
      categoryArray.model = answerJson[512].value;
    }
    if (answerJson[391]) {
      categoryArray.variant = answerJson[391].value;
    }

    categoryArray.price = answerJson[514];
    if (this.state.PriceCheck[0].price) {
      if (answerJson[514] < this.state.PriceCheck[0].price) {
        categoryArray.price_approval = 0;
      } else {
        categoryArray.price_approval = 1;
      }
    }
    if (answerJson[392]) {
      categoryArray.year = answerJson[392].label;
    }
    if (answerJson[393]) {
      categoryArray.owernship = answerJson[393].label;
    }
    categoryArray.kms = answerJson[394];
    categoryArray.insurance = answerJson[395];
    categoryArray.attraction = answerJson[397];
    categoryArray.features = answerJson[396];
    categoryArray.finance = answerJson[398];
    if (answerJson[390]) {
      categoryArray.brand = answerJson[390].value;
    }
    categoryArray.customerid = this.state.customerId;
    categoryArray.userid = this.state.userid.id;
    categoryArray.status = "active";
    categoryArray.approval = 0;
    console.log(categoryArray);
    try {
      let result = await CmsContent.addMaster(
        "tbl_Specifications_Sheet_Proof",
        categoryArray
      );
      if (result) {
        await this.setState({
          answerJson: "",
          json: "",
          alertVisible: true,
          textalert: "Your form have been submitted successfully",
          color: "success"
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { resSelected, json, button } = this.state;

    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div
                  className="card"
                  style={{
                    margin: "-20px",
                    marginTop: "5px",
                    boxShadow: "0px 0px 10px #b9b3b3",
                    borderRadius: "8px"
                  }}
                >
                  <div className="card-header">
                    <h1>Specifications Sheet Proof</h1>
                  </div>
                  <div className="card-body">
                    <div>
                      <Alert
                        className="badge-content"
                        color={this.state.color}
                        isOpen={this.state.alertVisible}
                        toggle={this.onDismiss}
                      >
                        {this.state.textalert}
                      </Alert>
                      {json && json.length ? json : null}
                      {json.length > 0 && (
                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2" />
                          <div className="col-sm-5">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={
                                button == "Save" ? this.formSubmit : this.update
                              }
                              // onClick={this.formSubmit}
                            >
                              {button == "Save" ? "Save" : "Update"}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default AT_Specifications_Sheet_Proof;
