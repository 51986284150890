import React, { Component } from "react";
import SingleSelect from "../../../components/Form/SingleSelect";
import CmsContent from "../../../MiddleWare/CmsContent";
import MultiSelect from "../../../components/Form/MultiSelect";
import { Alert } from "reactstrap";
import Datatable from "../../../components/Datatable/Datatable";
import FormMiddleWare from "../../../components/Form/FormMiddleware";
import { confirmAlert } from "react-confirm-alert";
import redisMiddleware from "../../../MiddleWare/redisMiddleware";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import SFAPDF from "../../../components/PDF/SFAPDF";

class DfCarsAddDesignation extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      categoryOptions: [],
      categoryOption: [],
      categorySelected: [],
      learnerOptions: [],
      learnerOption: [],
      selectedlearner: [],
      editvalue: null,
      categoryId: null,
      alertVisible: false,
      disableValue: false,
      disableGroup: false,
      button: "Submit",
      textalert: null,
      color: "success",
      customerId: localStorage.getItem("userId"),
      serviceId: localStorage.getItem("currentService"),
    };
  }

  async componentDidMount() {
    try {
      this.setState({ loading: true })
      const { customerId, serviceId } = this.state;

      //let result = await CmsContent.getMasterValues('tbl_group');
      const result = await redisMiddleware.singleTable(
        "tbl_UserType",
        this.state.customerId,
        "customerid"
      );

      var categoryOptions = [];
      if (result) {
        result.data.map((item) => {
          if (this.state.serviceId == item.serviceid) {
            categoryOptions.push({ label: item.usertype, value: item.id });
          }
        });
        this.setState({ categoryOptions });
      }

      const location = await redisMiddleware.singleTable(
        "tbl_location",
        this.state.customerId,
        "customerId"
      );

      var learnerOptions = [];
      if (location) {
        location.data.map((item) => {
          if (item.serviceid == this.state.serviceId) {
            learnerOptions.push({ label: item.name, value: item.id });
          }
        });
      }
      this.setState({ learnerOptions });

      const mapped = await redisMiddleware.singleTable(
        "tbl_MaptoLocation",
        this.state.customerId,
        "customerid"
      );
      let Mapped = [];
      if (mapped) {
        mapped.data.map((ival, i) => {
          if (this.state.serviceId == ival.serviceid) {
            Mapped.push(ival);
          }
        });
        this.setState({ data: Mapped });
      }
      if (Mapped) {
        this.check();
      }
      this.setState({ loading: false })
    } catch (error) {
      console.log(error);
      this.setState({ loading: false })
    }
  }

  column = [
    {
      Header: "User Type",
      accessor: "label",
    },
    {
      Header: "Edit",
      accessor: "edit",
      Cell: (d) =>
        this.dataTableButton("warning", "Edit", () => {
          this.edition(d.original);
        }),
    }
    // {
    //   Header: "Delete",
    //   accessor: "delete",
    //   Cell: (d) =>
    //     this.dataTableButton("danger", "Delete", () => {
    //       this.buttonDeletes(d);
    //     }),
    // },
  ];
  edition = (d) => {
    this.setState({ button: "Update", selectedlearner: [] });
    let { data: mapToGroup, learnerOptions: learnerList } = this.state;
    this.programSelect(d);

    let learners = mapToGroup
      .map((ival, i) => {
        if (ival.userTypeId == d.value) {
          let returnArray = {};
          returnArray.value = ival.locationId;

          returnArray.label = this.getValueFromArray(
            ival.locationId,
            learnerList
          );

          return returnArray;
        }
      })
      .filter(function (element) {
        return element !== undefined;
      });
    this.learnerSelect(learners);
    this.setState({
      button: "Update",
      disableGroup: true,
      // alertVisible: true,
      // textalert: "Map To Group Updated",
      // color: "success"
    });
    // setTimeout(() => this.setState({ alertVisible: false }), 3000);
  };
  buttonDeletes = (value) => {
    this.setState({});
    confirmAlert({
      title: "Confirmation to Delete",
      message: "Are you sure you want to delete this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.deletion(value),
          //onClick: () => alert("Group removed from")
        },
        {
          label: "No",
        },
      ],
    });
  };
  deletion = async (d) => {
    let index = d.index;
    let value = d.original.value;
    let result = await CmsContent.getSingleConditionedValue(
      "tbl_MaptoLocation",
      "groupid",
      value,
      "Delete"
    );
    if (result) {
      result = await CmsContent.getAllSubtitleList("");
      if (result) {
        this.setState({
          data: result.data,
        });
        let categoryOptions = this.state.categoryOptions;
        this.setState({ categoryOption: [] });
        categoryOptions.splice(index, 1);
        this.setState({ categoryOptions });
        this.check();
      }
    }
  };
  check = () => {
    let { categoryOptions: learnerList, data: mapToGroup } = this.state;
    var newLearnerList = [];
    Object.keys(learnerList).forEach((key) => {
      var checkJson = this.filterBy(mapToGroup, {
        userTypeId: learnerList[key].value,
      });
      if (Object.keys(checkJson).length !== 0) {
        newLearnerList.push(learnerList[key]);
      }
    });
    this.setState({ categoryOption: newLearnerList });
  };
  programSelect = async (e) => {
    this.setState({ categorySelected: e, categoryId: e.value });

    if (this.state.button === "Submit" && this.state.data) {
      let { learnerOptions: learnerList, data: mapToGroup } = this.state;
      var newLearnerList = [];
      Object.keys(learnerList).forEach((key) => {
        if (mapToGroup) {
          var checkJson = this.filterBy(mapToGroup, {
            userTypeId: e.value,
            locationId: learnerList[key].value,
          });
          if (Object.keys(checkJson).length == 0) {
            newLearnerList.push(learnerList[key]);
          }
        }
      });
      console.log(newLearnerList);
      this.setState({ learnerOption: newLearnerList });
    } else {
      this.setState({
        learnerOption: this.state.learnerOptions,
        // disableGroup: true,
        // alertVisible: true,
        // textalert: "Map To Group Updated",
        // color: "success"
      });
    }
  };
  learnerSelect = async (e) => {
    if (e) {
      this.setState({ selectedlearner: e });
    }
  };

  onSubmit = async () => {
    const {
      categorySelected,
      selectedlearner,
      categoryId,
      UserTypeSelected,
      customerId,
      serviceId,
    } = this.state;
    let selectedValue = "";
    const value = selectedlearner.map(
      (values) => (selectedValue += `${values.value},`)
    );
    selectedValue = selectedValue.replace(/,\s*$/, "");
    const formData = new FormData();
    let status = this.state.button === "Submit" ? "active" : "deactive";
    formData.append("userTypeId", categoryId);
    formData.append("locationId", selectedValue);
    formData.append("customerid", customerId);
    formData.append("serviceid", serviceId);
    formData.append("status", status);
    if (
      categorySelected == "" ||
      categorySelected == null ||
      categorySelected == undefined
    ) {
      this.setState({ errorselect: "select usertype" });
      return false;
    } else if (
      selectedlearner == "" ||
      selectedlearner == null ||
      selectedlearner == undefined
    ) {
      this.setState({ errorselect: "", error: "select location" });
      return false;
    } else {
      this.setState({ error: "" });
    }
    this.setState({
      alertVisible: true,
      disableValue: true,
      selectedlearner: "",
      categorySelected: "",
    });
    setTimeout(() => this.setState({ alertVisible: false }), 3000);
    try {
      let result = await CmsContent.learningObjectSubmit(
        "tbl_MaptoLocation",
        formData
      );
      if (result) {
        console.log(result.data);
        // const newData = [...this.state.data, result.data];
        this.componentDidMount();
        this.setState({
          //   // data: newData,
          button: "Submit",
          disableValue: false,
          disableGroup: false,
          alertVisible: true,
          textalert: "New Map To Usertype Added",
          color: "success",
          selectedlearner: "",
          categorySelected: "",
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
        // this.check();
      }
    } catch (error) {
      console.log(error);
    }
  };

  onDismiss = () => {
    this.setState({ alertVisible: false });
  };

  resetbtn = () => {
    this.setState({
      button: "Submit",
      disableGroup: false,
      categorySelected: "",
      selectedlearner: [],
    });
  };
  // submitcsv = async (e) => {
  //   if (!this.state.csv) {
  //     this.setState({ cserror: "Please Upload Files" });
  //   } else {
  //     this.setState({ cserror: " " });

  //     const file = this.state.csv;

  //     let customerId = localStorage.getItem("userId");
  //     let serviceid = localStorage.getItem("currentService");
  //     let usertype = 8;
  //     console.log(customerId);
  //     console.log(serviceid);
  //     const formData = new FormData();
  //     formData.append("customerId", customerId);
  //     formData.append("serviceId", serviceid);
  //     formData.append("file", file);
  //     // console.log([...formData]);
  //     const result = await CmsContent.learnerfile(usertype, formData);
  //     console.log(result);
  //     // if (result) {
  //     //   this.setState({
  //     //     formAlertdelete: true,
  //     //     textalert: "File uploaded Successfully",
  //     //     color: "success",
  //     //     csv: " ",
  //     //     resp: result.data,
  //     //   });
  //     // }
  //     if (result.data.msg == "Incorrect File Format") {
  //       this.setState({
  //         formAlertdelete: true,
  //         textalert: "Incorrect File Format",
  //         color: "danger",
  //         csv: null,
  //         resp: result.data,
  //       });
  //     }
  //     if (result.data.alreadymapped.length > 0) {
  //       this.setState({
  //         formAlertdelete: true,
  //         textalert: "UserAlreadyExist",
  //         color: "danger",
  //         resp: result.data,
  //       });
  //     } else {
  //       this.setState({
  //         formAlertdelete: true,
  //         textalert: "File uploaded Successfully",
  //         color: "success",
  //         csv: null,
  //         resp: result.data,
  //       });
  //     }
  //     setTimeout(() => this.setState({ formAlertdelete: false }), 3000);
  //   }
  // };

  render() {
    const {
      categoryOptions,
      alertVisible,
      learnerOption,
      disableValue,
      errorselect,
      error,
      button,
      categorySelected,
      selectedlearner,
      disableGroup,
      textalert,
      resp,
      formAlertdelete,
    } = this.state;
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3>Map Usertype-Location</h3>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-7">
                        <Alert
                          className="badge-content"
                          color={this.state.color}
                          isOpen={alertVisible}
                          toggle={this.onDismiss}
                        >
                          {textalert}
                        </Alert>
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label for="exampleInputEmail1">User Type</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          disabled={disableGroup}
                          options={categoryOptions}
                          handleChange={this.programSelect}
                          selectedService={categorySelected}
                        />
                        <span className="error-shows">{errorselect}</span>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="subgroup">Location</label>
                      </div>
                      <div className="col-sm-5">
                        <MultiSelect
                          options={learnerOption}
                          handleChange={this.learnerSelect}
                          selectedService={selectedlearner}
                        />
                        <span className="error-shows">{error}</span>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <button
                          type="button"
                          className="btn btn-primary"
                          disabled={disableValue}
                          onClick={this.onSubmit}
                        >
                          {this.state.button}
                        </button>
                      </div>
                      {this.state.button === "Update" && (
                        <div className="col-sm-2">
                          <button
                            type="button"
                            className="btn btn-primary"
                            disabled={disableValue}
                            onClick={this.resetbtn}
                          >
                            Reset
                          </button>
                        </div>
                      )}
                    </div>
                    {this.state.excel && this.state.excel.length > 0 ? (
                      <PDFDownloadLink
                        style={{ color: "white" }}
                        document={
                          <SFAPDF
                            title={this.state.title}
                            header={this.state.header}
                            data={this.state.excel}
                          />
                        }
                        fileName="SFAUsertype.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? "Loading document..." : "Download Pdf"
                        }
                        <button className="btn btn-warning">PDF</button>
                      </PDFDownloadLink>
                    ) : null}
                    {this.state.data && this.state.data.length ? (
                      <CSVLink
                        data={this.state.data}
                        headers={this.state.excelHead}
                      >
                        <button
                          className="btn btn-success"
                          style={{ marginLeft: "5px" }}
                        >
                          Excel
                        </button>
                      </CSVLink>
                    ) : null}
                    <br></br>
                    <br></br>

                    <span>
                      Total of {Object.keys(this.state.categoryOption).length}{" "}
                      records
                    </span>
                    {this.state.loading ? <div className="text-center"> <i
                      className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                    ></i></div> : this.state.categoryOption ? (
                      <Datatable
                        data={this.state.categoryOption}
                        columnHeading={this.column}
                      />
                    ) : <div className="text-center">No data Found</div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default DfCarsAddDesignation;
