import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import DfCarsEnquiry from "./DfCarsEnquiry";
import moment from "moment";
import UsertypeComments from "../UserMaster/UsertypeComments";
import LoginModal from "../../../../components/Modal/Modal";
import Datatable from "../../../../components/Datatable/Datatable";
import { ACCESS_POINT } from "../../../../config/index";
import Icon from "react-icons-kit";
import { circle_ok } from 'react-icons-kit/ikons/circle_ok'
import { info } from 'react-icons-kit/icomoon/info'
import { notification } from 'react-icons-kit/icomoon/notification'
import { calendar_ok } from 'react-icons-kit/ikons/calendar_ok'
import { calendar } from 'react-icons-kit/ikons/calendar'

class DfCarsphysicalprocess extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      dashboard: [{ "fill": 0, "view": 0, "MTD": 0 }],
      FullData: [],
      data1: [],
      data2: [],
      data3: [],
      data4: [],
      Datas: [],
      MakeOptions: [],
      coloropt: [],
      ftdDashDate: moment(new Date()).format("YYYY-MM-DD"),
      ftdval: false,
      variantopt: [],
      modelopt: [],
      ftd: null,
      userid: JSON.parse(localStorage.getItem("userlog")),
      usertypelog: JSON.parse(localStorage.getItem("usertypelog")),
      LocationID: localStorage.getItem(`LocationID`),
      EnquiryData: [],
      dateVisible: false,
      alertVisible: false,
      column: [
        {
          Header: "Form",
          accessor: "mobile",
          Cell: (d) => this.Document(d),
        },
        {
          Header: "Name",
          accessor: "name",
        },
       
        {
          Header: "Register No",
          accessor: "reg_no",
        },
        {
          Header: "Date",
          accessor: "created_At"
        },
        {
          Header: "Make",
          accessor: "makeName",
          // Cell: (d) => this.getValueFromArray(d.original.make, this.state.MakeOptions)

        },
        {
          Header: "Model",
          accessor: "modelName",
          // Cell: (d) => this.getValueFromArray(d.original.model, this.state.modelopt)
        },
        {
          Header: "Variant",
          accessor: "variantName",
          // Cell: (d) => this.getValueFromArray(d.original.variant, this.state.variantopt)

        },
        {
          Header: "Color",
          accessor: "colourName",
          // Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)

        },
        {
          Header: "Data Type",
          accessor: "Auction",
          minWidth: 120,
          Cell: (d) => this.Auction(d.original),
        },
        {
          Header: "Print PDF",
          accessor: "pdf",
          width:120,
          Cell: d => this.viewpdf(d)
        },

      ],
      excelColumn: [
        {
          label: "Name",
          key: "name",
        },
       
        {
          label: "Register No",
          key: "reg_no",
        },
        {
          label: "Date",
          key: "created_At"
        },
        {
          label: "Make",
          key: "makeName",
          // Cell: (d) => this.getValueFromArray(d.original.make, this.state.MakeOptions)

        },
        {
          label: "Model",
          key: "modelName",
          // Cell: (d) => this.getValueFromArray(d.original.model, this.state.modelopt)
        },
        {
          label: "Variant",
          key: "variantName",
         // Cell: (d) => this.getValueFromArray(d.original.variant, this.state.variantopt)
 
        },
        {
          label: "Color",
          key: "colourName",
          // Cell: (d) => this.getValueFromArray(d.original.color, this.state.coloropt)

        },
        {
          label: "Data Type",
          key: "Auction_Status",
        }

      ],
      stage: null,
      CustomerId: null,
    };
    this.vals()
  }
  async vals() {
    let allOpt = await this.decryption('getcar')
    if (allOpt) {
      this.setState({
        MakeOptions: allOpt.makeopt,
        coloropt: allOpt.coloropt,
        variantopt: allOpt.variantopt,
        modelopt: allOpt.modelopt
      })
    }
  }
  viewpdf = d => {
    if (d.original.FormFilled == "yes" || d.original.FormFilled == "approved") {
      return (<center><button type="button" className="btn btn-warning btn-sm"
        onClick={() => this.generatepdf(d)}>Print PDF</button></center>)
    }
    else {
      return (
        <div> <span className="label" style={{ backgroundColor: "white" }}></span></div>)
    }
  }
  generatepdf = async (d) => {
    const seller = await cmsContent.getFreedom("*", "tbl_Delivery_Seller_Proof", `customerId=${d.original.id}`, 1, 1);
    console.log(seller)
    if (seller) {
      let sellpdf = await cmsContent.deliverseller("tbl_Delivery_Seller_Proof", seller.data[0].customerid);
      console.log(sellpdf)
      if (sellpdf.data == "success") {
        window.open(`${ACCESS_POINT}/cmsContent/downloadsellpdf/${seller.data[0].reg_name}`, '_self')
      }
    }
  }


  Editdata = async (d) => {
    this.setState({ stage: "physical", CustomerId: d.original.id });
  };
 
  Auction = (d) => {
    if (d && d.Auction_Status) {
      return (
        <center>
          <span className={`btn btn-${d.Auction == 0 ? "secondary" : d.Auction == 1 ? "warning" : d.Auction == 2 ? "success" : "danger"} btn-sm text-center`}
            style={{ minWidth: "130px" }}>
            {d.Auction_Status}
          </span>
        </center>
      )
    } else {
      return (
        <center>
          <span className={`btn btn-${d.Auction == 0 ? "secondary" : d.Auction == 1 ? "warning" : d.Auction == 2 ? "success" : "danger"} btn-sm text-center`}
            style={{ minWidth: "130px" }}>
            {d.Auction_Status}
          </span>
        </center>
      )
    }
  };
  Document = (d) => {
    if (d.original.FormFilled == "yes" || d.original.FormFilled == "approved") {
      return (<center>
        <button
          type="button"
          className="btn btn-success btn-sm"
          onClick={() => this.F_proofForm(d)}
        >
          Edit Form
        </button></center>
      );
    } else {
      return (<center>
        <button
          type="button"
          className="btn btn-warning btn-sm"
          onClick={() => this.F_proofForm(d)}
        >
          Fill Form
        </button></center>
      );
    }
  };

  F_proofForm = async (d) => {
    window.open(
      `/DFCars/Delivery_Note_Seller_Proof/${d.original.id}`,
      "_self"
    );
  };
  MainFunction = async () => {
    try {
      let results = await cmsContent.BUYDFCARZ(this.state.userid.id, 'physical')
      console.log(results);
      let fulldata = []
      if (results && results.Physical_Prospective) {
        fulldata = results.Physical_Prospective;
      }
      if (results) {
        await this.setState({
          Data: results.Physical_Prospective,
          data3: fulldata.length ? fulldata.filter(e => e.filterApproved == true) : [],
          dashboard: results.data_for_f,
          dataof: fulldata.length ? fulldata.filter(e => e.filtermtd == true) : [],
          loading: false,

          // currentid

        })
      }
    } catch (error) {
      await this.setState({ loading: false })
      console.log(error);
    }
  }
  async componentDidMount() {
    this.MainFunction()
    // try {
    this.setState({ loading: true })

  }
  edash = () => {
    return (<div className="">
      <div className="card">
        {/* < div className="row" > */}
        <div className="card-header">
          <div className="col-sm-6" >
            <h2 className="mt-2 black">Dashboard</h2>
          </div>
        </div>
        <div className="card-body">
          <div className="">
            <div className="row col-12 mt-4 text-center">
              <div className="col " onClick={() => this.onClk(2)} >
                <div className="dashboardCard1">
                  <Icon icon={calendar} size={35} style={{ color: '#2A9D8F'}} />
                  <h3 className="mt-2 black" >MTD</h3>
                  <div>
                    <h1 className="bold mt-4 mb-2 black" style={{cursor:"pointer"}}> {this.state.dashboard[0].MTD ? this.state.dashboard[0].MTD : 0}<b></b></h1>
                  </div>
                </div>
              </div>
              <div className="col " onClick={() => this.onClk(3)} >
                <div className="dashboardCard1">
                  <Icon icon={circle_ok} size={35} style={{ color: 'green' }} />
                  <h3 className="mt-2 black" >Approved</h3>
                  <div>
                    <h1 className="bold mt-4 mb-2 black" style={{cursor:"pointer"}}><b>{this.state.dashboard[0].view ? this.state.dashboard[0].view : 0}</b></h1>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div >
    )
  }

  onClk = (val) => {

    if (val === 2) { this.setState({ Datas: this.state.dataof, dateVisible: false }) }
    if (val === 3) { this.setState({ Datas: this.state.data3, dateVisible: false }) }
    window.scroll({
      top: 400,
      left: 0,
      behavior: "smooth",
    });
  }

  render() {
    let { loading } = this.state;
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            {this.edash()}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Vehicle Physical Process</h1>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-4">
                        <LoginModal
                          buttonTitle="Add Enquiry"
                          title="Update Commets"
                          id="status"
                          extraClass="btn btn-primary btn-sm"
                          onClick={this.reset}
                          extraStyle={{ width: "1000px" }}
                          buttonStyle={{
                            backgroundColor: "white",
                            border: "white",
                          }}
                          bodyText={
                            <UsertypeComments
                              stage={this.state.stage}
                              customerid={this.state.CustomerId}
                            />
                          }
                        />
                      </div>
                    </div>
                    {loading ? <div className="text-center"> <i
                      className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                    ></i></div> : this.state.Data.length ? (this.state.Datas.length ?
                      <Datatable
                        data={this.state.Datas}
                        columnHeading={this.state.column}
                        excelColumn={this.state.excelColumn}
                      /> : null
                    ) : (<div style={{ textAlign: "center" }} ><b>No Data Found</b></div>)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
export default DfCarsphysicalprocess;
