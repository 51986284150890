import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import UpdateStatus from "./UpdateStatus";
import moment from "moment";
import LoginModal from "../../../../components/Modal/Modal";
import UsertypeComments from "../UserMaster/UsertypeComments";
import Datatable from "../../../../components/Datatable/Datatable";
import AddEnquiry from "../Form/Dfcarsbuyers";

class BuyerEnquiryform extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      customerId: localStorage.getItem("userId"),
      LocationID: localStorage.getItem(`LocationID`),
      EnquiryData: [],
      alertVisible: false,
      column: [
        {
          Header: "Prospect",
          accessor: "name"
        },
        {
          Header: "Contact",
          accessor: "mobile"
        },
        // {
        //   Header: "Follow-Up",
        //   accessor: "followup",
        //   Cell: d => this.followup(d)
        // },
        {
          Header: "Comments",
          accessor: "comment"
        },
        {
          Header: "Budget",
          accessor: "Budget"
        },
        {
          Header: "Update Status",
          accessor: "status",
          Cell: d => this.UpdateSTatus(d)
        },
        {
          Header: "Specification-Sheet",
          accessor: "mobile",
          Cell: d => this.Specification(d)
        },
        {
          Header: "Approval",
          accessor: "mobile",
          Cell: d => this.Approval(d)
        },
        {
          Header: "Comments",
          accessor: "mobile",
          Cell: d => this.UpdateSTatus1(d)
        }
      ],
      EditData: {},
      IsClose: false,
      stage: null,
      CustomerId: null
    };
  }

  UpdateSTatus1 = d => {
    return (
      <button
        type="button"
        className="btn btn-sm btn-warning"
        data-toggle="modal"
        data-target={"#comment"}
        onClick={() => this.Editdata2(d)}
      >
        View Comments
      </button>
    );
  };

  Editdata2 = async d => {
    this.setState({ stage: "buyerenquery", CustomerId: d.original.id });
  };

  Specification = d => {
    if (d.original.FormFilled == "yes" || d.original.FormFilled == "approved") {
      return (
        <button
          type="button"
          className="btn btn-sm btn-primary"
          onClick={() => this.F_proofForm(d)}
        >
          Edit Form
        </button>
      );
    } else {
      return (
        <button
          type="button"
          className="btn btn-sm btn-warning"
          onClick={() => this.F_proofForm(d)}
        >
          Fill Form
        </button>
      );
    }
  };

  UpdateSTatus = d => {
    return (
      <button
        type="button"
        className="btn btn-sm btn-warning"
        data-toggle="modal"
        data-target={"#status"}
        onClick={() => this.Editdata(d)}
      >
        update
      </button>
    );
  };

  Editdata = async d => {
    this.setState({
      EditData: d.original,
      IsClose: false,
      isEdit: true,
      Index: d.index
    });
  };

  F_proofForm = async d => {
    window.open(
      `/DFCars/AT_Specifications_Sheet_Proof/${d.original.id}`,
      "_self"
    );
  };

  F_proofForm3 = async d => {
    this.setState({ EditData: d.original, IsClose: true, Index: d.index });
  };

  Approval = d => {
    //console.log(d.original);
    if (d.original.price_approval == 1) {
      return (
        <button
          type="button"
          className="btn btn-sm btn-success"
          //data-toggle="modal" data-target={"#status"}
          // onClick={()=>this.F_proofForm3(d)}
        >
          Approved
        </button>
      );
    } else {
      return (
        <button
          type="button"
          className="btn btn-sm btn-primary"
          //  data-toggle="modal" data-target={"#status"}
          // onClick={()=>this.F_proofForm3(d)}
        >
          Waiting for approval
        </button>
      );
    }
  };

  async componentDidMount() {
    try {
      let Buyer = await cmsContent.getFreedom(
        "tbl_dfcarz_prosperities.*",
        "tbl_dfcarz_prosperities",
        `tbl_dfcarz_prosperities.location =${this.state.LocationID} and tbl_dfcarz_prosperities.type='buyer' and tbl_dfcarz_prosperities.prospective_status != 'inactive'`,
        1,
        `tbl_dfcarz_prosperities.id DESC`
      );
      if (Buyer) {
        console.log(localStorage.getItem(`LocationID`));
        let Fulldata = [];
        Buyer.data.map((ival, i) => {
          // console.log(ival.prospective_status);
          if (ival.prospective_status != "inactive") {
            ival.FormFilled = "no";
            ival.price_approval = 0;
            ival.followup = ival.followup
              ? moment(ival.followup).format("YYYY-MM-DD")
              : `-`;
            ival.comment = ival.comment ? ival.comment : `-`;
            Fulldata.push(ival);
          }
        });
        let result = await cmsContent.getFreedom(
          `*`,
          `tbl_Specifications_Sheet_Proof`,
          1
        );

        Fulldata.map((ival, i) => {
          result.data.map((jval, j) => {
            ival.price_approval = jval.price_approval;
            if (ival.id == jval.customerid && jval.approval == 1) {
              ival.FormFilled = "approved";
            } else if (ival.id == jval.customerid) {
              ival.FormFilled = "yes";
            }
          });
        });
        console.log(Fulldata);
        this.setState({ Data: Fulldata });
      }
    } catch (error) {
      console.log(error);
    }
  }

  changeModelstate = async (s, v) => {
    this.setState({ [s]: v });
  };

  render() {
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Specification Report</h1>
                  </div>
                  {/* <div className="row form-group">
                  <div className="col-sm-4">
                    <LoginModal
                      buttonTitle="Add Enquiry"
                      title="Enquiry"
                      id="status"
                      extraClass="btn btn-primary"
                      onClick={this.reset}
                      bodyText={<AddEnquiry
                        EnquiryData={this.state.EnquiryData}
                        these={this.changeModelstate}
                        isEdit={this.state.isEdit}
                        EditData={this.state.EditData}
                        Index={this.state.Index}
                        CloseEnquiry={this.state.CloseEnquiry} />}
                    />
                  </div>
                </div> */}
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-4">
                        <LoginModal
                          buttonTitle="Add Enquiry"
                          title="Update Status"
                          id="status"
                          extraClass="btn btn-sm btn-primary"
                          onClick={this.reset}
                          extraStyle={{ width: 'auto' }}
                          buttonStyle={{
                            backgroundColor: "white",
                            border: "white"
                          }}
                          bodyText={
                            <UpdateStatus
                              EditData={this.state.EditData}
                              these={this.changeModelstate}
                              isEdit={this.state.isEdit}
                              FullData={this.state.Data}
                              Index={this.state.Index}
                              IsClose={this.state.IsClose}
                            />
                          }
                        />
                      </div>
                      <div className="col-sm-4">
                        <LoginModal
                          buttonTitle="Add Enquiry"
                          title="Update Commets"
                          id="comment"
                          extraClass="btn btn-sm btn-primary"
                          onClick={this.reset}
                          extraStyle={{ width: 'auto' }}
                          buttonStyle={{
                            backgroundColor: "white",
                            border: "white"
                          }}
                          bodyText={
                            <UsertypeComments
                              stage={this.state.stage}
                              customerid={this.state.CustomerId}
                            />
                          }
                        />
                      </div>
                    </div>
                    {/* <div className="row form-group">
                    
                  </div> */}
                    <div className="card-body">
                      {this.state.Data.length ? (
                        <Datatable
                          data={this.state.Data}
                          columnHeading={this.state.column}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
export default BuyerEnquiryform;
