import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import UpdateStatus from "./UpdateStatus";
import moment from "moment";
import LoginModal from "../../../../components/Modal/Modal";
import UsertypeComments from "../UserMaster/UsertypeComments";
import Datatable from "../../../../components/Datatable/Datatable";
import { ACCESS_POINT } from "../../../../config/index";
import SingleSelect from "../../../../components/Form/SingleSelect";
import Select from 'react-select';
import Icon from "react-icons-kit";
import { alert } from 'react-icons-kit/ionicons/alert'
import { clock } from 'react-icons-kit/iconic/clock'
import { checkmark } from 'react-icons-kit/icomoon/checkmark'
class Sourcing extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      customerId: localStorage.getItem("userId"),
      LocationID: localStorage.getItem(`LocationID`),
      EnquiryData: [],
      alertVisible: false,
      status: [{ label: "Live", value: 1 }, { label: "Closed", value: 2 }, { label: "Evaluated", value: 3 }],
      livestatus: [{ label: "Appointment taken", value: 1 }, { label: "Under Follow up", value: 2 }, { label: "Ringing no response", value: 3 }, { label: "Researching", value: 4 }, { label: "Not attempted", value: 5 }],
      closedstatus: [{ label: "Plan dropped", value: 1 }, { label: "Non contactable", value: 2 }, { label: "Lost", value: 3 }, { label: "Wrong capture", value: 4 }, { label: "No response", value: 5 }, { label: "Huge Price Gap", value: 6 }],
      evaluatedstatus: [{ label: "Trade in", value: 1 }, { label: "Follow-up", value: 2 }, { label: "Researching", value: 3 }, { label: "Postponed", value: 4 }, { label: "Dropped", value: 5 }, { label: "Not attempted", value: 6 }, { label: "Non contactable", value: 7 }, { label: "Price not offered", value: 8 }, { label: "Huge Price gap", value: 9 }, { label: "Lost", value: 10 }],
      droppedstatus: [{ label: "Due to price", value: 1 }, { label: "Budget constraint", value: 2 }, { label: "Just enquired", value: 3 }, { label: "Others", value: 4 }, { label: "Not known", value: 5 }],
      noncontact: [{ label: "RNR-3", value: 1 }, { label: "Wrong number", value: 2 }],
      Loststatus: [{ label: "Co dealer", value: 1 }, { label: "Competition", value: 2 }, { label: "End user", value: 3 }, { label: "Broker", value: 4 }, { label: "Not known", value: 5 }],
      Negotiation: [{ label: "Gap >30", value: 1 }, { label: "Gap 30-50", value: 2 }, { label: "<50", value: 3 }],
      Consideration: [{ label: "With Co dealer", value: 1 }, { label: "Competition", value: 2 }, { label: "Used car", value: 3 }, { label: "New entrant", value: 4 }],
      Postponed: [{ label: "Next month", value: 1 }, { label: "After 3 month", value: 2 }, { label: "Not known", value: 3 }],
      column: [
        {
          Header: "Prospect",
          accessor: "name"
        },
        {
          Header: "Contact",
          accessor: "mobile"
        },
        {
          Header: "Status",
          accessor: "status"
        },
        {
          Header: "Prize",
          accessor: "Budget"
        },
        {
          Header: "Specification-Sheet",
          accessor: "mobile",
          Cell: d => this.Specification(d)
        },
        // {
        //   Header: "Form status",
        //   accessor: "formstatus",
        //   Cell: d => this.formstatus(d)
        // },
        {
          Header: "Print PDF",
          accessor: "pdf",
          Cell: d => this.viewpdf(d)
        },
        {
          Header: "Comments",
          accessor: "mobile",
          Cell: d => this.UpdateSTatus(d)
        },
      ],
      stage: "",
      CustomerId: ""
    };
  }


  viewpdf = d => {
    if (d.original.FormFilled == "yes" || d.original.FormFilled == "approved") {
      return (<button type="button" className="btn btn-warning"
        onClick={() => this.generatepdf(d)}>Print PDF</button>)
    }
    else {
      return (
        <span className="label" style={{ backgroundColor: "white" }}></span>)
    }
  }
  generatepdf = async (d) => {
    const auto = await cmsContent.getFreedom("*", "tbl_Autoterrace_enquiry_form", `customerId=${d.original.id}`, 1, 1);
    console.log(auto)
    if (auto) {
      let autopdf = await cmsContent.autoterrace("tbl_Autoterrace_enquiry_form", auto.data[0].customerid);
      console.log(autopdf)
      if (autopdf.data == "success") {
        window.open(`${ACCESS_POINT}/cmsContent/downloadauto/${auto.data[0].name}`, '_self')
      }
    }
  }
  UpdateSTatus = d => {
    return (
      <button
        type="button"
        className="btn btn-warning"
        data-toggle="modal"
        data-target={"#status"}
        onClick={() => this.Editdata(d)}
      >
        View Comments
      </button>
    );
  };
  // formstatus = d => {
  //   //console.log(this.state.status)
  //   return (
  //     <div>
  //       <Select options={this.state.status} />
  //     </div>
  //   );
  // };
  Editdata = async d => {
    this.setState({ stage: "sourcing", CustomerId: d.original.id });
  };

  F_proofForm = async d => {
    window.open(`/DFCars/Autoterrace_enquiry_form/${d.original.id}`, "_self");
  };

  Specification = d => {
    if (d.original.FormFilled == "yes" || d.original.FormFilled == "approved") {
      return (
        <button
          type="button"
          style={{ width: "100px" }}
          className="btn btn-primary"
          onClick={() => this.F_proofForm(d)}
        >
          Edit Form
        </button>
      );
    } else {
      return (
        <button
          type="button"
          style={{ width: "100px" }}
          className="btn btn-warning"
          onClick={() => this.F_proofForm(d)}
        >
          Fill Form
        </button>
      );
    }
  };

  async componentDidMount() {
    try {
      let Buyer = await cmsContent.getFreedom(
        // 'tbl_dfcarz_prosperities.*,tbl_user_web.userName',
        // 'tbl_dfcarz_prosperities,tbl_Specifications_Sheet_Proof,tbl_user_web',
        // `tbl_Specifications_Sheet_Proof.price_approval = 1 and tbl_dfcarz_prosperities.location = ${ this.state.LocationID } and tbl_user_web.id = tbl_Specifications_Sheet_Proof.userid and tbl_dfcarz_prosperities.type = 'buyer' and tbl_dfcarz_prosperities.id = tbl_Specifications_Sheet_Proof.customerid`
        "tbl_dfcarz_prosperities.*",
        "tbl_dfcarz_prosperities",
        `tbl_dfcarz_prosperities.location = ${this.state.LocationID} and tbl_dfcarz_prosperities.type = 'buyer' and tbl_dfcarz_prosperities.prospective_status != 'inactive'`,
        1,
        `tbl_dfcarz_prosperities.id DESC`
      );
      if (Buyer) {
        let Fulldata = [];
        Buyer.data.map((ival, i) => {
          if (ival.prospective_status != "inactive") {
            ival.FormFilled = "no";
            ival.followup = moment(ival.followup).format("YYYY-MM-DD");
            Fulldata.push(ival);
          }
        });
        console.log(Fulldata);
        let result = await cmsContent.getFreedom(
          `* `,
          `tbl_Autoterrace_enquiry_form`,
          1
        );
        Fulldata.map((ival, i) => {
          result.data.map((jval, j) => {
            if (ival.id == jval.customerid && jval.approval == 1) {
              ival.FormFilled = "approved";
            } else if (ival.id == jval.customerid) {
              ival.FormFilled = "yes";
            }
          });
        });
        this.setState({ Data: Fulldata });
      }
    } catch (error) {
      console.log(error);
    }
  }
  // edash = () => {
  //   return (<div className="container-fluid">
  //     <div className="card">
  //       {/* < div className="row" > */}
  //       <div className="card-header">
  //         <div className="col-sm-6" >
  //           <h2 className="mt-2 black">Dashboard</h2>
  //         </div>
  //       </div>
  //       <div className="card-body">
  //         <div className="row col-12 mt-4">
  //           <div className="col-sm-4 ">
  //             <div className="dashboardCard1" onClick={() => this.onClk(1)}>
  //               <Icon className="dashboardBadge" icon={checkmark} style={{ color: 'green' }} size={45} />
  //               <h2 className="mt-2 black"> Completed</h2>
  //               <div style={{ textAlign: 'center' }}>
  //                 <h1 className="bold mt-4 mb-2 black"><b></b></h1>
  //               </div>
  //             </div>
  //           </div>
  //           <div className="col-sm-4 " onClick={() => this.onClk(2)}>
  //             <div className="dashboardCard1">
  //               <Icon icon={alert} size={45} className="dashboardBadge" style={{ color: 'red' }} />
  //               <h2 className="mt-2 black"> Pending 21 Days</h2>
  //               <div>
  //                 <h1 className="bold mt-4 mb-2 black" ><b></b></h1>
  //               </div>
  //             </div>
  //           </div>
  //           <div className="col-sm-4 " onClick={() => this.onClk(3)}>
  //             <div className="dashboardCard1">
  //               <Icon className="dashboardBadge" icon={clock} size={45} style={{ color: '#FFC300' }} />
  //               <h2 className="mt-2 black"> Awaiting Docs </h2>
  //               <div>
  //                 <h1 className="bold mt-4 mb-2 black"><b></b></h1>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  //   )
  // }
  render() {
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            {/* {this.edash()} */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Sourcing</h1>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-4">
                        <LoginModal
                          buttonTitle="Add Enquiry"
                          title="Update Commets"

                          id="status"
                          extraClass="btn btn-primary"
                          onClick={this.reset}
                          extraStyle={{ width: 'auto' }}
                          buttonStyle={{
                            backgroundColor: "white",
                            border: "white"
                          }}
                          bodyText={
                            <UsertypeComments
                              stage={this.state.stage}
                              customerid={this.state.CustomerId}
                            />
                          }
                        />
                      </div>
                    </div>
                    {this.state.Data.length ? (
                      <Datatable
                        data={this.state.Data}
                        columnHeading={this.state.column}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
export default Sourcing;
