import React, { Component } from 'react';

import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import { Alert } from "reactstrap";
import LoginModal from "../../../../components/Modal/Modal";
import CmsContent from "../../../../MiddleWare/CmsContent";
import Datatable from "../../../../components/Datatable/Datatable";
import redisMiddleware from "../../../../MiddleWare/redisMiddleware";

class Bidform extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      makeoptions: [],
      modeloptions: [],
      voptions: [],
      coptions: [],
      alertVisible: false,
      loading: false,
      customerId: localStorage.getItem("userId"),
      ServiceId: JSON.parse(localStorage.getItem("userlog")),
      bidoptions: [],
      enq: [],
      bidperson1: [],
      userlist: [],
      column: [
        {
          Header: "Make",
          accessor: "make",
          Cell: (d) => this.getValueFromArray(d.original.make, this.state.makeoptions)


        },
        {
          Header: "Model",
          accessor: "model",
          Cell: (d) => this.getValueFromArray(d.original.model, this.state.modeloptions)
        },
        {
          Header: "Variant",
          accessor: "variant",
          Cell: (d) => this.getValueFromArray(d.original.variant, this.state.voptions)

        },
        {
          Header: "Color",
          accessor: "color",
          Cell: (d) => this.getValueFromArray(d.original.color, this.state.coptions)

        },
        {
          Header: "Bid Amount",
          accessor: "Bidamount"
        },
        {
          Header: "Year Of Manufacture",
          accessor: "year_of_manu"
        },
        {
          Header: "View Form",
          accessor: "viewmodel",
          Cell: (d) => this.formview(d)
        },
        {
          Header: "Total Bid",
          accessor: "total",

        },
        {
          Header: "View Bids",
          accessor: "viewbids",
          Cell: d => this.auction(d)
        },

      ],
    }
  }

  async componentDidMount() {
    //    console.log( this.state.ServiceId);
    try {
      this.setState({ loading: true })
      let data = []
      const tbl_FProof = await CmsContent.getFreedom(
        "*",
        "tbl_FProof",
        `Auction=${1}`, 1, 1)
      //   console.log(location.data);
      const bid = await CmsContent.getFreedom("*", "tbl_bid", 1, 1, 1);
      let bidperson = []
      if (bid) {
        tbl_FProof.data.map((ival, i) => {
          let k = 0;
          bid.data.map((jval, j) => {

            if (ival.id == jval.fproof_id) {
              ival.total = ++k;

              bidperson.push(jval);
              //  ival.bids_by = jval;                  
            }
          })

        })
      }

      const users = await CmsContent.getFreedom("*", "tbl_user_web", `serviceId=${this.state.ServiceId.serviceId}`, 1, 1);
      let userlist = []
      // console.log(users);
      if (users) {
        users.data.map((nval, i) => {
          userlist.push({ label: nval.userName, value: nval.id })
        })
      }

      this.setState({ data: tbl_FProof.data, bidperson, userlist, loading: false });



      let makeoptions = [];
      //Model Master
      const model = await redisMiddleware.singleTable(
        "tbl_dfcarsmodel",
        this.state.ServiceId.serviceId,
        "ServiceId"
      );
      // let data = [];
      let modeloptions = [];
      if (model) {
        model.data.map((mval, k) => {
          // console.log(mval);
          modeloptions.push({ label: mval.Model, value: mval.id, makeid: mval.makeid });
        });
        await this.setState({ modeloptions });
      }
      //Make master
      const make = await redisMiddleware.singleTable(
        "tbl_make_master",
        this.state.ServiceId.serviceId,
        "ServiceId"
      );
      //   console.log(make);
      if (make) {
        make.data.map((ival, i) => {

          makeoptions.push({ label: ival.Make, value: ival.id });
        });
      }
      if (makeoptions) {
        await this.setState({ makeoptions });
      }
      //Varaint
      const variant = await redisMiddleware.singleTable(
        "tbl_variant_master",
        this.state.ServiceId.serviceId,
        "ServiceId"
      );
      let voptions = []
      //    console.log(variant,voptions);
      if (variant) {
        variant.data.map((ival, i) => {
          voptions.push({ label: ival.variant, value: ival.id })
        })
      }

      if (voptions) {
        await this.setState({ voptions });
      }
      //tbl_colour
      const color = await redisMiddleware.singleTable(
        "tbl_colour",
        this.state.ServiceId.serviceId,
        "ServiceId"
      );
      let coptions = []
      //  console.log(variant,voptions);
      if (color) {
        color.data.map((ival, i) => {
          coptions.push({ label: ival.colour, value: ival.id })
        })
      }
      // console.log(coptions);
      if (coptions) {
        await this.setState({ coptions });
      }

    } catch (error) {
      this.setState({ loading: false })
      console.log(error);
    }
  }
  getbid = (d) => {
    console.log(d);
    return 1;

  }

  getValueFromArray = (d, array) => {
    // console.log(d);
    if (array.length > 0) {
      if (array.length !== 0) {
        let filtered = array.filter(function (item) {
          return item.value == d;
        });
        let v = filtered[0];
        if (v != undefined) {
          return v.label;
        } else {
          return '-';
        }
      }
    } else {
      return '-';
    }
  };
  formview = (d) => {
    return (<button type="button" className="btn btn-warning"
      onClick={() => this.F_proofForm(d)}
    >View Form</button>)
  }

  F_proofForm = async (d) => {
    //console.log(d);
    window.open(`/DFCars/Evaluation_Checklist_F_Proof/${d.original.customerid}/${true}`, '_blank')
  }
  // auctionmodal= d=>
  // {
  //   // let enq = this.state.enq;
  //   // enq.push(d)
  //   // this.setState({enq:d})
  //   this.auction(d);
  // }
  auction = (d1) => {
    // console.log(d.
    // let d =this.state.enq;
    let d = d1;
    if (d.original.total) {
      return (
        <LoginModal
          buttonTitle="View Bid"
          title="View Bid"
          id="auction"
          extraClass="btn btn-primary"
          onClick={() => this.reset(d)}
          //  buttonStyle={{ backgroundColor: "white", border: "white" }}
          bodyText={<Form
            bidperson={this.state.bidperson1}
            d={d}
            getValueFromArray={this.getValueFromArray}
            userlist={this.state.userlist}
          />
          }
        />)
    } else { return null }

  }
  reset = async (d) => {
    let bidperson1 = [];
    let bidperson = this.state.bidperson;
    bidperson1 = bidperson.filter(bidperson => bidperson.fproof_id == d.original.id)
    console.log(bidperson);
    this.setState({ bidperson1 })
  }

  onDismiss = () => {
    this.setState({ alertVisible: false, formAlertdelete: false });
  };
  submitauction = async d => {
    const { amount, customerId,
      ServiceId
    } = this.state;
    //console.log(d);
    // let id =await d.original.id;

    // const fproofid = await CmsContent.getFreedom("*", "tbl_FProof", `customerid=${id}`, 1, 1);
    let CategoryArray = {};
    CategoryArray.new_bid_amount = parseInt(amount);
    CategoryArray.customerid = ServiceId.id;
    CategoryArray.fproof_id = d.original.id;
    // console.log(CategoryArray);
    try {
      let result = await CmsContent.addMaster(
        "tbl_bid",
        CategoryArray
      );
      if (result) {
        this.setState({
          amount: "",
          alertVisible: true,
          color: "success",
          textalert: "Success",

        })
      }
    } catch (error) {
      console.log(error);
    }

  }
  handlechangeData = async e => {
    //  if(e.target.type=="date"){
    // console.log(e.target.value);
    //  }
    // console.log(e.target.name);
    this.setState({ [e.target.name]: e.target.value });
  };
  render() {
    // console.log(this.state.bidperson);
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3>Auction Car List</h3>
                  </div>
                  <div className="card-body">

                    {this.state.loading ? <div class="loader" /> : this.state.data ? this.state.data && (
                      <Datatable
                        data={this.state.data}
                        columnHeading={this.state.column}
                      />
                    ) : (<div style={{ textAlign: "center" }} ><b>No Data Found</b></div>)}


                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    )
  }
}

export default Bidform;

export function Form(props) {
  let column = [
    {
      Header: "Username",
      accessor: "customerid",
      Cell: (e) => props.getValueFromArray(e.original.customerid, props.userlist)

    },
    {
      Header: "Amount",
      accessor: "new_bid_amount",

    }]
  return (

    <div>
      <div className="card-body">
        <Datatable
          data={props.bidperson}
          columnHeading={column}
        />

      </div>
    </div>
  )
}