import React, { Component } from "react";
import { SingleSelect, FormMiddleWare } from "../../../../components/Form";
import CmsContent from "../../../../MiddleWare/CmsContent";
import { Alert } from "reactstrap";
import { ACCESS_POINT, ImgSTEERAUTO } from "../../../../config";
import swal from "sweetalert";
import { Datatable } from "../../../../components/Datatable";


class DfcarzSEO extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            customerId: localStorage.getItem("userId"),
            alertVisible: false,
            isLocation: 0,
            pageopt: [{ label: "HOME", value: "/" },
            { label: "BUYCAR", value: "/buycars" },
            { label: "SELLCAR", value: "/sellyourcars" },
            { label: "TESTIMONIAL", value: "/Testimonial" },
            { label: "SERVICE", value: "/Service" },
            { label: "SOLDCARS", value: "/JustSold" },
            { label: "UPCOMINGCARS", value: "/UpComingCars" },
            { label: "ABOUTUS", value: "/aboutus" },
            { label: "CONTACTUS", value: "/Contactus" },
            { label: "TEST", value: "/test" },
            { label: "POLICY", value: "/Policy" },
            { label: "SINGLECARLIST", value: "/SingleListCars" },
            ],
            pageopt_Fil: []

        };
    }
    column = [
        {
            Header: "id",
            accessor: "id",
            // minWidth: 150
        },
        {
            Header: "Page",
            accessor: "page",
            Cell: (d) => this.getValueFromArray(d.original.page, this.state.pageopt),

            minWidth: 150
        },
        {
            Header: "Title",
            accessor: "title",
            minWidth: 110
        },
        {
            Header: "Keywords",
            accessor: "keyword",
            minWidth: 110
        },
        {
            Header: "Description",
            accessor: "description",
            minWidth: 110
        },
        {
            Header: "Title Tab",
            accessor: "titleTab",
            minWidth: 110
        },
        {
            Header: "Tab With Location",
            accessor: "isLocation",
            Cell: (d) => { return (<p>{d.original.isLocation == 0 ? "NO" : "YES"}</p>) },
            minWidth: 130
        },
        // {
        //     Header: "Date",
        //     accessor: "createdAt",
        //     minWidth: 110
        // },
        {
            Header: "Edit",
            accessor: "edit",
            Cell: (d) => this.dataTableButton('danger btn-sm', 'Edit', () => this.onEdit(d), this.state.btnDisable),
            minWidth: 150
        },
    ];


    async componentDidMount() {
        try {
            await this.MainFunc()
        } catch (error) {
            console.log(error);
        }
    }

    //MainFunction
    MainFunc = async () => {
        try {
            let { pageopt } = this.state
            this.setState({ loading: true, pageopt_Fil: [] })
            let result = await CmsContent.getFreedom("*", "tbl_Dfcars_SEO", 1, 1, "id desc")
            if (result && result.data) {
                let res = result.data
                for (let index = 0; index < res.length; index++) {
                    for (let j = 0; j < pageopt.length; j++) {
                        if (res[index].page == pageopt[j].value) {
                            pageopt[j].isDisabled = true
                        }
                    }
                }
                await this.setState({
                    data: result.data ? result.data : [],
                    loading: false,
                    pageopt_Fil: pageopt
                })
            }

        } catch (error) {
            console.log(error);
            this.setState({ loading: false })
        }
    }
    onEdit = async (value) => {
        await this.reset()
        let d = value.original;
        this.setState({
            pageSelected: d.page ? this.state.pageopt.filter(e => e.value == d.page)[0] : null,
            title: d.title,
            keyword: d.keyword,
            description: d.description,
            titleTab: d.titleTab,
            isEdit: true,
            editid: d.id,
            isLocation: d.isLocation,
            data1: d,
        });
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };
    reset = async () => {
        await this.setState({
            title: '',
            keyword: "",
            description: "",
            pageSelected: "",
            titleTab: "",
            isLocation: 0,
            isEdit: false,
            editid: null

        });
    }
    handleSubmit = async () => {
        try {
            let { pageSelected, title, keyword, description, isEdit, editid, titleTab, isLocation } = this.state
            let body = {}
            if (pageSelected && pageSelected.value) {
                body.page = pageSelected.value
            } else {
                alert("Select Page")
                return false
            }
            body.title = title
            body.keyword = keyword
            body.description = description
            body.titleTab = titleTab
            body.isLocation = isLocation
            let result
            if (isEdit) {
                result = await CmsContent.updateMaster("tbl_Dfcars_SEO", editid, body)
            } else {
                result = await CmsContent.addMaster("tbl_Dfcars_SEO", body)
            }
            if (result) {
                await this.reset()
                await this.MainFunc()
            }

        } catch (error) {
            console.log(error);
        }
    }
    render() {
        let { pageopt_Fil, isLocation } = this.state
        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3>Add Page</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-7">
                                                <Alert
                                                    className="badge-content"
                                                    color="success"
                                                    isOpen={this.state.alertVisible}
                                                    toggle={this.onDismiss}
                                                >
                                                    Page Added Successfully
                                                </Alert>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="exampleInputEmail1">Select Page</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <SingleSelect
                                                    options={pageopt_Fil}
                                                    handleChange={(e) => this.handleSelect(e, 'pageSelected')}
                                                    selectedService={this.state.pageSelected}
                                                />
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        {this.state.pageSelected && this.state.pageSelected.value &&
                                            <div className="row form-group">
                                                <div className="col-sm-4" />
                                                <span className="error-show " style={{ textTransform: "capitalize" }}>
                                                    Canonical tag will be automatically Generated in this Page

                                                </span>
                                            </div>
                                        }
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="exampleInputEmail1">Meta Title </label>
                                            </div>
                                            <div className="col-sm-5">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="title"
                                                    name="title"
                                                    placeholder="Enter Meta Title"
                                                    value={this.state.title}
                                                    onChange={this.handleUserInput}
                                                />
                                            </div>
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="exampleInputEmail1">Meta Keywords </label>
                                            </div>
                                            <div className="col-sm-5">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="keyword"
                                                    name="keyword"
                                                    placeholder="Enter Meta Keyword"
                                                    value={this.state.keyword}
                                                    onChange={this.handleUserInput}
                                                />
                                            </div>
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="exampleInputEmail1">Meta Description </label>
                                            </div>
                                            <div className="col-sm-5">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="description"
                                                    name="description"
                                                    placeholder="Enter Meta Description"
                                                    value={this.state.description}
                                                    onChange={this.handleUserInput}
                                                />
                                            </div>
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="exampleInputEmail1">Tab Title</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="titleTab"
                                                    name="titleTab"
                                                    placeholder="Enter Tab Title"
                                                    value={this.state.titleTab}
                                                    onChange={this.handleUserInput}
                                                />
                                            </div>
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="exampleInputEmail1"></label>
                                            </div>
                                            <div className="col-sm-5">
                                                <div class="form-check">
                                                    <input type="checkbox"
                                                        class="form-check-input" id="exampleCheck1"
                                                        checked={isLocation == 1 ? true : false}
                                                        onChange={() => { this.setState({ isLocation: 1 }) }} />
                                                    <label class="form-check-label" for="exampleCheck1">Tab Title With Location</label>
                                                </div>

                                            </div>
                                        </div>


                                        <div className="row form-group">
                                            <div className="col-sm-3"></div>
                                            <div className="col-sm-3 d-flex justify-content-center">
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-warning"
                                                    style={{ maxWidth: 150, fontSize: "15px" }}
                                                    onClick={() => this.reset()}
                                                >
                                                    RESET
                                                </button>
                                            </div>
                                            <div className="col-sm-3 d-flex justify-content-center">

                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-primary"
                                                    style={{ maxWidth: 150, fontSize: "15px" }}
                                                    onClick={() => this.handleSubmit()}
                                                >
                                                    SUBMIT
                                                </button>
                                            </div>
                                            <div className="col-sm-3"></div>
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-4" />
                                            <span className="error-show ">
                                                {this.state.errortitle}
                                            </span>
                                        </div>


                                        <br />
                                        <div className="row form-group">
                                            <div className="col-sm-12">
                                                {this.state.loading ? <div className="text-center"> <i
                                                    className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                                                ></i></div> : this.state.data && this.state.data.length > 0 ? (
                                                    <Datatable
                                                        data={this.state.data}
                                                        columnHeading={this.column}
                                                    />
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

export default DfcarzSEO;
