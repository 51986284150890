import React, { Component } from "react";
import { Alert } from "reactstrap";
import MultiSelect from "../../../components/Form/MultiSelect";
import CmsContent from "../../../MiddleWare/CmsContent";
import Datatable from "../../../components/Datatable/Datatable";
import FormMiddleWare from "../../../components/Form/FormMiddleware";
import { confirmAlert } from "react-confirm-alert";
import ScriptTag from "react-script-tag";
import redisMiddleware from "../../../MiddleWare/redisMiddleware";

class DfCarsMapHierarchy extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      option: [],
      optionFilled: [],
      usertypeSelected: [],
      alertVisible: false,
      alertVisible1: false,
      editvalue: "",
      jobRole: "",
      editid: null,
      disableValue: false,
      addNewSubgroup: false,
      error: null,
      errorJob: null,
      button: "Submit",
      index: null,
      customerId: localStorage.getItem("userId"),
      serviceId: localStorage.getItem("currentService"),
      formAlertdelete: false,
      textalert: null,
      color: "success"
    };
  }
  async componentDidMount() {
    try {
      // let result = await CmsContent.getFreedom(
      //   "*",
      //   "tbl_design_job",
      //   `customerId = ${this.state.customerId} and serviceid = ${this.state.serviceId}`,
      //   "id",
      //   "id"
      // );
      // let Options = [];
      // if (result) {
      //   result.data.map((value, key) => {
      //     let label = value.designName + "-" + value.jobRole;
      //     Options.push({ label: label, value: value.id });
      //   });

      //   this.setState({ designation: Options });
      // }
      // let usertype = await CmsContent.getFreedom(
      //   "id as value , usertype as label",
      //   "tbl_UserType",
      //   `customerId = ${this.state.customerId} and serviceid = ${this.state.serviceId}`,
      //   "id",
      //   "id"
      // );

      const user = await redisMiddleware.singleTable(
        "tbl_UserType",
        this.state.customerId,
        "customerid"
      );
      let MappedUser = [];
      console.log(user);
      if (user) {
        user.data.map((ival, i) => {
          if (this.state.serviceId == ival.serviceid) {
            if (ival.orderBy !== "-" || ival.orderBy !== null) {
              MappedUser.push({
                label: ival.usertype,
                value: ival.id,
                order: ival.orderBy
              });
            } else {
              MappedUser.push({
                label: ival.usertype,
                value: ival.id,
                order: null
              });
            }
          }
        });
        console.log(MappedUser);
        let option = [];
        let optionFilled = [];
        if (MappedUser.length > 0) {
          MappedUser.map((val, key) => {
            if (val.order === "-") {
              console.log(val);
              option.push(<option value={val.value}>{val.label}</option>);
              document.getElementById("multiselect").innerHTML = option;
            } else {
              optionFilled.push(<option value={val.value}>{val.label}</option>);
              document.getElementById(
                "multiselect_to"
              ).innerHTML = optionFilled;
            }
          });
          this.setState({ option, optionFilled });
        }
      }
      console.log(MappedUser);
      this.setState({ MappedUser });

      let UserOption = [];
      if (user) {
        user.data.map((ival, i) => {
          if (this.state.serviceId == ival.serviceid) {
            if (
              ival.orderBy === NaN ||
              ival.orderBy === "-" ||
              ival.orderBy === null
            ) {
              UserOption.push({
                label: ival.usertype,
                value: ival.id
              });
            }
          }
        });
        UserOption.map((val, key) => {
          // option.push(<option value={val.value}>{val.label}</option>);
          // document.getElementById("multiselect").innerHTML = option;
          // // option.push(<option value={val.value}>{val.label}</option>);
          // // document.getElementById("multiselect_to").innerHTML = option;
          // optionFilled.push(<option value={""}>{""}</option>);
          // document.getElementById("multiselect_to").innerHTML = optionFilled;
        });
      }

      this.setState({ usertype: UserOption });
    } catch (error) {
      console.log(error);
    }
  }

  handlechange = e => {
    let value = e.target.value;
    this.setState({ editvalue: value });
  };
  jobRole = e => {
    let value = e.target.value;
    this.setState({ jobRole: value });
  };

  update = async () => {};
  onDismiss = () => {
    this.setState({ alertVisible: false });
    this.setState({ alertVisible1: false });
    this.setState({ formAlertdelete: false });
  };

  usertypeSelect = e => {
    let option = [];
    if (e) {
      e.map((val, key) => {
        option.push(<option value={val.value}>{val.label}</option>);
      });
      this.setState({ option, usertypeSelected: e });
    }
    console.log(this.state.usertypeSelected);
  };
  // designationSelect = e => {
  //   this.setState({ designationSelected: e });
  // };

  onSubmit = async () => {
    var selected = [];
    for (var option of document.getElementById("multiselect_to").options) {
      if (option) {
        selected.push(option.value);
      }
    }
    let selectedValue = "";
    const value = selected.map(values => (selectedValue += `${values},`));
    selectedValue = selectedValue.replace(/,\s*$/, "");

    let body = {};
    body.order = selectedValue;
    this.setState({ disableValue: true });
    let result = await CmsContent.updateHierarchy("tbl_UserType", body);
    if (result) {
      let option = [];
      this.state.usertypeSelected.map((val, key) => {
        option.push(<option value=""></option>);
        document.getElementById("multiselect_to").innerHTML = option;
      });
      this.setState({
        disableValue: false,
        usertypeSelected: [],
        alertVisible: true,
        selectedValue: "",
        button: "Submit",
        textalert: "Hierarchy Mapped Successfully",
        color: "success"
      });
      setTimeout(() => this.setState({ alertVisible: false }), 3000);
    }
  };

  render() {
    const {
      designation,
      usertype,
      usertypeSelected,
      designationSelected,
      optionFilled,
      alertVisible,
      disableValue,
      error,
      errorJob,
      button,
      textalert
    } = this.state;
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Map Usertype Hierarchy</h1>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-7">
                        <Alert
                          className="badge-content"
                          color={this.state.color}
                          isOpen={alertVisible}
                          toggle={this.onDismiss}
                        >
                          {textalert}
                        </Alert>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    {/* <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label for="exampleInputEmail1">User Type</label>
                      </div>
                      <div className="col-sm-5">
                        <MultiSelect
                          options={usertype}
                          handleChange={this.usertypeSelect}
                          selectedService={usertypeSelected}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div> */}
                    <br />
                    <br />
                    {/* <br />
                    <br /> */}
                    <div class="row form-group">
                      <div class="col-sm-5">
                        <select
                          name="from[]"
                          id="multiselect"
                          class="form-control"
                          size="8"
                          multiple="multiple"
                          style={{ height: "73%" }}
                        >
                          {this.state.option}
                        </select>
                      </div>

                      <div class="col-sm-2">
                        <button
                          type="button"
                          id="multiselect_rightAll"
                          class="btn btn-block"
                        >
                          <i class="fa fa-angle-double-right"></i>
                        </button>
                        <button
                          type="button"
                          id="multiselect_rightSelected"
                          class="btn btn-block"
                        >
                          <i class="fa fa-angle-right"></i>
                        </button>
                        <button
                          type="button"
                          id="multiselect_leftSelected"
                          class="btn btn-block"
                        >
                          <i class="fa fa-angle-left"></i>
                        </button>
                        <button
                          type="button"
                          id="multiselect_leftAll"
                          class="btn btn-block"
                        >
                          <i class="fa fa-angle-double-left"></i>
                        </button>
                      </div>

                      <div class="col-sm-5">
                        <select
                          name="to[]"
                          id="multiselect_to"
                          class="form-control"
                          size="8"
                          multiple="multiple"
                          style={{ height: "73%" }}
                        >
                          {this.state.optionFilled}
                        </select>

                        <div class="row form-group">
                          <div class="col-sm-6" style={{ padding: 15 }}>
                            <button
                              type="button"
                              id="multiselect_move_up"
                              class="btn btn-block"
                            >
                              <i class="fa fa-angle-up"></i>
                            </button>
                          </div>
                          <div class="col-sm-6" style={{ padding: 15 }}>
                            <button
                              type="button"
                              id="multiselect_move_down"
                              class="btn btn-block col-sm-6"
                            >
                              <i class="fa fa-angle-down"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-3" />
                      <div className="col-sm-5">
                        <button
                          type="button"
                          className="btn btn-primary"
                          disabled={disableValue}
                          onClick={this.onSubmit}
                        >
                          {this.state.button}
                        </button>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <ScriptTag type="text/javascript" src="multiselect.js" />
        <ScriptTag type="text/javascript" src="multiselect.min.js" />
      </React.Fragment>
    );
  }
}

export default DfCarsMapHierarchy;
