import React, { Component } from 'react';

import superAdmin from '../../MiddleWare/SuperAdmin';
export default class SuperAdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      servicesList: []
    };

    let userlog = localStorage.getItem('userlog');
    userlog = JSON.parse(userlog);
    localStorage.setItem('userId', userlog.id);
    localStorage.setItem('userName', userlog.userName);
    localStorage.setItem('userEmail', userlog.email);
    localStorage.setItem('userMobile', userlog.mobileNumber);
    localStorage.setItem('servicesEnabled', userlog.serviceEnabled);
  }

  //WARNING! To be deprecated in React v17. Use componentDidMount instead.
  async componentWillMount() {
    let services = localStorage.getItem('servicesEnabled');
    try {
      const { data: servicesList } = await superAdmin.getFreedom(
        'servicesName as name,id',
        'tbl_services',
        `id in (${services})`,
        'id',
        'id desc'
      );
      console.log(servicesList);
      this.setState({ servicesList });
    } catch (error) {
      console.log(error);
    }
  }

  handleClick = url => {
    window.location.href = url;
  };

  render() {
    const { servicesList } = this.state;

    return (
      <main className="main my-4">
        <div className="container-fluid">
          <div className="row">
            {servicesList.length
              ? servicesList.map(values => {
                return (
                  <div className="col-sm-6 col-lg-6">
                    <div className="card text-white bg-primary">
                      <div className="card-body" style={{ cursor: 'pointer' }} onClick={e => this.handleClick(values.name.replace(/ +/g, '') + `/${values.id}`)}>
                        <center>
                          <h3>{values.name}</h3>
                        </center>
                      </div>
                    </div>
                  </div>
                );
              })
              : null}
          </div>
        </div>
      </main>
    );
  }
}
